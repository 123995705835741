import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { Datepicker as ReactDatepicker } from "@mobiscroll/react";
import cx from "classnames";

import styles from "./Datepicker.module.scss";

const Datepicker = forwardRef(
  (
    {
      dateFormat,
      className,
      onChange,
      placeholder,
      value,
      select,
      rangeStartLabel,
      rangeEndLabel,
      disabled,
      transparent,
      maxRange,
      max,
      ...rest
    },
    ref
  ) => (
    <div
      className={cx(styles.wrapper, className, {
        [styles.transparent]: transparent,
        [styles.disabled]: disabled,
      })}
    >
      <ReactDatepicker
        ref={ref}
        dateFormat={dateFormat}
        themeVariant="light"
        controls={["calendar"]}
        theme="ios"
        // defaultValue={value}
        value={value}
        onChange={onChange}
        select={select}
        rangeStartLabel={rangeStartLabel}
        rangeEndLabel={rangeEndLabel}
        // onClose={onChange}
        disabled={disabled}
        inputProps={{
          placeholder,
        }}
        maxRange={maxRange}
        max={max}
        {...rest}
      />
    </div>
  )
);

Datepicker.defaultProps = {
  dateFormat: "DD/MM/YY",
  transparent: false,
  disabled: false,
  className: "",
  placeholder: "",
  select: "date",
  rangeStartLabel: "From",
  rangeEndLabel: "To",
  value: "",
  maxRange: 500,
  max: null,
};

Datepicker.propTypes = {
  dateFormat: PropTypes.string,
  max: PropTypes.instanceOf(Date),
  maxRange: PropTypes.number,
  transparent: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  select: PropTypes.oneOf(["date", "range"]),
  rangeStartLabel: PropTypes.string,
  rangeEndLabel: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
};

export default Datepicker;
