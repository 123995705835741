import { nanoid } from "nanoid";

import { handleError } from "../utils/error";
import * as api from "../../apiv2/labels";

import * as selectors from "./labels.selectors";
import {
  SET_IS_LABELS_DROPDOWN_VISIBLE,
  SET_IS_SAVING_LABEL,
  SET_IS_FETCHING_LABELS,
  SET_LABELS,
  SET_SELECTED_LABELS,
  SET_UNSELECTED_LABELS,
  SET_ACTIVE_LABEL,
  SET_IS_CREATE_LABEL_FORM_VISIBLE,
  SET_IS_DELETE_LABEL_ALERT_VISIBLE,
  RESET,
} from "./labels.actionTypes";

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setIsLabelsDropdownVisible = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_LABELS_DROPDOWN_VISIBLE,
    payload: status,
  });
};

export const setIsSavingLabel = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_SAVING_LABEL,
    payload: status,
  });
};

export const setIsFetchingLabels = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_LABELS,
    payload: status,
  });
};

export const setLabels = (data) => (dispatch) => {
  dispatch({
    type: SET_LABELS,
    payload: data,
  });
};

export const setSelectedLabels = (data) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_LABELS,
    payload: data,
  });
};

export const setUnSelectedLabels = (data) => (dispatch) => {
  dispatch({
    type: SET_UNSELECTED_LABELS,
    payload: data,
  });
};

export const setActiveLabel = (data) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_LABEL,
    payload: data,
  });
};

export const setIsDeleteLabelAlertVisible = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_DELETE_LABEL_ALERT_VISIBLE,
    payload: status,
  });
};

export const setIsCreateLabelFormVisible = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_CREATE_LABEL_FORM_VISIBLE,
    payload: status,
  });
};

export const toggleLabels = (uid) => (dispatch, getState) => {
  const state = getState();
  const selectedLabelUids = selectors.getSelectedLabels(state);
  const unSelectedLabelUids = selectors.getUnSelectedLabels(state);

  if (selectedLabelUids.includes(uid)) {
    if (!unSelectedLabelUids.includes(uid)) {
      dispatch(setUnSelectedLabels([...unSelectedLabelUids, uid]));
    }
  }

  if (!selectedLabelUids.includes(uid)) {
    if (unSelectedLabelUids.includes(uid)) {
      dispatch(setUnSelectedLabels(unSelectedLabelUids.filter((id) => id !== uid)));
    }
  }

  const newSelectedLabelUids = selectedLabelUids.includes(uid)
    ? selectedLabelUids.filter((id) => id !== uid)
    : [...selectedLabelUids, uid];

  dispatch(setSelectedLabels(newSelectedLabelUids));
};

export const saveLabel = (data) => async (dispatch, getState) => {
  const state = getState();
  const labels = selectors.getLabels(state);
  const activeLabel = selectors.getActiveLabel(state);

  try {
    dispatch(setIsSavingLabel(true));
    const response = activeLabel
      ? await api.patchLabel({
          data: {
            name: data.label,
            colour: data.colour,
          },
          uid: activeLabel.uid,
        })()
      : await api.postLabel({
          name: data.label,
          colour: data.colour,
        })();

    if (activeLabel) {
      dispatch(setLabels(labels.map((l) => (l.uid === response.data.uid ? response.data : l))));
    } else {
      dispatch(setLabels([...labels, { ...response.data }]));
      dispatch(toggleLabels(response.data.uid));
    }

    dispatch(setActiveLabel(null));
    dispatch(setIsCreateLabelFormVisible(false));
    return response.data;
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsSavingLabel(false));
  }
};

export const fetchLabels = () => async (dispatch) => {
  try {
    dispatch(setIsFetchingLabels(true));
    const response = await api.getLabels()();
    dispatch(setLabels(response.data));
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetchingLabels(false));
  }
};

export const assignLabelsToOrder = (data) => async (dispatch) => {
  try {
    await api.postAssignLabelsToOrder(data)();
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const removeLabel = (uid) => async (dispatch, getState) => {
  const state = getState();
  const labels = selectors.getLabels(state);
  const selectedLabelUids = selectors.getSelectedLabels(state);
  const unSelectedLabelUids = selectors.getUnSelectedLabels(state);

  try {
    await api.deleteLabel(uid)();
    dispatch(setIsDeleteLabelAlertVisible(false));
    dispatch(setIsCreateLabelFormVisible(false));
    dispatch(setIsLabelsDropdownVisible(true));

    dispatch(setSelectedLabels(selectedLabelUids.filter((labelUid) => labelUid !== uid)));

    dispatch(setUnSelectedLabels(unSelectedLabelUids.filter((labelUid) => labelUid !== uid)));

    dispatch(setLabels(labels.filter((l) => l.uid !== uid)));
    dispatch(setActiveLabel(null));
  } catch (error) {
    console.log(error);
    handleError(error, dispatch);
  }
};
