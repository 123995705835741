import queryString from "query-string";

import { createApiV2 } from "./common";

export const getWidgetsApi = () => (token) => createApiV2("GET", "widgets/")(token);

export const getChartsApi = () => (token) => createApiV2("GET", "charts/")(token);

export const postAddUserWidgetApi = (data) => (token) =>
  createApiV2("POST", "user_widgets/")(token, data);

export const getUserWidgetsApi = () => (token) => createApiV2("GET", "user_widgets/")(token);

export const getUserRoleWidgetsApi = () => (token) =>
  createApiV2("GET", "user_role_widgets/")(token);

export const patchUserWidgetApi = (uid, data) => (token) =>
  createApiV2("PATCH", `user_widgets/${uid}/`)(token, data);

export const patchBulkUserWidgetApi = (data) => (token) =>
  createApiV2("PATCH", "user_widgets/bulk_update/")(token, data);

export const deleteUserWidgetApi = (uid) => (token) =>
  createApiV2("DELETE", `user_widgets/${uid}/`)(token);

export const getUserWidgetChartsDataApi =
  (widgetCode, params = {}) =>
  (token) => {
    const queryParams = queryString.stringify(params);
    return createApiV2(
      "GET",
      `dashboard/api/v1/user_widgets/chart_data/${widgetCode}/?${queryParams}`,
      {
        useApiGatewayUrl: true,
      }
    )(token);
  };

export const getUserWidgetChartsDataApiInDb =
  (widgetCode, params = {}) =>
  (token) => {
    const queryParams = queryString.stringify(params);
    return createApiV2("GET", `user_widgets/chart_data/${widgetCode}/?${queryParams}`)(token);
  };

export const getOrder =
  ({ orderUid, customerUid }) =>
  () =>
    createApiV2("GET", `customers/${customerUid}/orders/${orderUid}/`)();

export const getWidgetsCategories = () => (token) =>
  createApiV2("GET", "widget_categories/")(token);
