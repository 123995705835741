import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { isColorLight, hex2Rgba } from "@/utils/color";

import styles from "./Avatar.module.scss";

const Avatar = ({ children, className, backgroundColor }) => (
  <div
    className={cx(styles.avatar, className)}
    style={{
      backgroundColor,
      color: isColorLight(hex2Rgba({ hex: backgroundColor })) ? "#000" : "#fff",
    }}
  >
    {children}
  </div>
);

Avatar.defaultProps = {
  className: "",
};

Avatar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  backgroundColor: PropTypes.string.isRequired,
};

export default Avatar;
