import qs from "query-string";

import { createApiV2 } from "./common";

export const getTimeTrackingSummary =
  ({ customerUid, orderUid }) =>
  (token) =>
    createApiV2("GET", `customers/${customerUid}/orders/${orderUid}/time_tracking/summary/`)(token);

export const getTimeTrackingEntries =
  ({ customerUid, orderUid }) =>
  (token) =>
    createApiV2("GET", `customers/${customerUid}/orders/${orderUid}/time_tracking/`)(token);

export const postTimeEntry =
  ({ customerUid, orderUid, data }) =>
  (token) =>
    createApiV2("POST", `customers/${customerUid}/orders/${orderUid}/time_tracking/`)(token, data);

export const postTimeEntryV2 =
  ({ data }) =>
  (token) =>
    createApiV2("POST", `timesheets/`)(token, data);

export const patchTimeEntry =
  ({ customerUid, orderUid, timeTrackingUid, data }) =>
  (token) =>
    createApiV2(
      "PATCH",
      `customers/${customerUid}/orders/${orderUid}/time_tracking/${timeTrackingUid}/`
    )(token, data);

export const patchTimeEntryV2 =
  ({ timeTrackingUid, data }) =>
  (token) =>
    createApiV2("PATCH", `timesheets/${timeTrackingUid}/`)(token, data);

export const getPlannerTimeEntries =
  ({ orderUid }) =>
  (token) =>
    createApiV2("GET", `planner/time_tracking/?order=${orderUid}`)(token);

export const getActiveTimeEntries = () => (token) =>
  createApiV2("GET", "timesheets/active_tracking/")(token);

export const getOrder =
  ({ orderUid, customerUid }) =>
  () =>
    createApiV2("GET", `customers/${customerUid}/orders/${orderUid}/`)();

export const deleteTimeEntry =
  ({ customerUid, orderUid, uid }) =>
  (token) =>
    createApiV2(
      "DELETE",
      `customers/${customerUid}/orders/${orderUid}/time_tracking/${uid}`
    )(token);

export const getOrderNumbers = (params) => (token) =>
  createApiV2("GET", `order_number/?${qs.stringify(params)}`)(token);

export const postValidateUserPin = (data) => (token) =>
  createApiV2("POST", `users/pin/`)(token, data);

export default {};
