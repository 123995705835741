import {
  SET_IS_FETCHING_LABELS,
  SET_LABELS,
  SET_SELECTED_LABELS,
  RESET,
} from "./labelFilter.actionTypes";

const initialState = {
  isFetchingLabels: false,
  labels: [],
  selectedLabels: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LABELS: {
      return { ...state, labels: action.payload };
    }

    case SET_SELECTED_LABELS: {
      return { ...state, selectedLabels: action.payload };
    }

    case SET_IS_FETCHING_LABELS: {
      return { ...state, isFetchingLabels: action.payload };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
