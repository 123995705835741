import React, { useState, useEffect, useRef, forwardRef } from "react";
import cx from "classnames";

import { Input as BaseInput } from "@/ui-lib/FormFields/Input";

import styles from "./Input.module.scss";

const Input = forwardRef(
  (
    {
      shouldDisplayOldValueWhenEmpty = false,
      value,
      label,
      onChange,
      onBlur,
      onKeyDown,
      onKeyPress,
      placeholder,
      className,
      id,
      inputClassName,
      debouncedTime = 500,
      disabled = false,
      autoFocus = false,
    },
    ref
  ) => {
    const [time, setTime] = useState(null);
    const [buffer, setBuffer] = useState(value);
    const inputRef = useRef();

    useEffect(() => {
      setBuffer(value);
    }, [value]);

    const inputChangeHandler = (event) => {
      const newValue = event.target.value;
      if (time) clearTimeout(time);

      setTime(
        setTimeout(() => {
          if (onChange) onChange(newValue);
        }, debouncedTime)
      );

      setBuffer(newValue);
    };

    const inputBlurHandler = (event) => {
      const newValue =
        shouldDisplayOldValueWhenEmpty && !event.target.value ? value : event.target.value;

      if (onBlur) {
        onBlur(newValue);
        setBuffer(newValue);
      }
    };

    const inputKeyDownHandler = (event) => {
      if (onKeyDown) {
        onKeyDown(event);
      }
    };

    const inputKeyPressHandler = (event) => {
      if (onKeyPress) {
        onKeyPress(event);
      }
    };

    return (
      <div className={cx(styles.inputContainer, className)}>
        {label && <label htmlFor={id}>{label}</label>}
        <BaseInput
          ref={ref || inputRef}
          placeholder={placeholder}
          inputClassName={inputClassName}
          id={id || ""}
          onChange={inputChangeHandler}
          onBlur={inputBlurHandler}
          onKeyDown={inputKeyDownHandler}
          onKeyPress={inputKeyPressHandler}
          value={buffer}
          disabled={disabled}
          autoFocus={autoFocus}
        />
      </div>
    );
  }
);

export default Input;
