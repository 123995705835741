import queryString from "query-string";

import { createApiV2 } from "../common";

export const postAssignSchedule = (data) => (token) =>
  createApiV2("POST", "planner/assignment_schedule_entries/")(token, data);

export const patchAssignSchedule =
  ({ uid, data }) =>
  (token) =>
    createApiV2("PATCH", `planner/assignment_schedule_entries/${uid}/`)(token, data);

export const getAssignSchedule = (data) => (token) =>
  createApiV2("POST", "planner/assignment_schedule_entries/search")(token, data);

export const deleteAssignSchedule = (uid) => (token) =>
  createApiV2("DELETE", `planner/assignment_schedule_entries/${uid}/`)(token);

export const getCustomEvents =
  (params = {}) =>
  (token) => {
    const queryParams = queryString.stringify(params);
    return createApiV2("GET", `planner/events/?${queryParams}`)(token);
  };

export const getCustomEvent = (uid) => (token) =>
  createApiV2("GET", `planner/events/${uid}/`)(token);

export const postCustomEvent = (data) => (token) =>
  createApiV2("POST", `planner/events/`)(token, data);

export const putCustomEvent = (uid, data) => (token) =>
  createApiV2("PUT", `planner/events/${uid}/`)(token, data);

export const duplicateCustomEvent =
  (uid, params = {}) =>
  (token) => {
    const queryParams = queryString.stringify(params);
    return createApiV2("GET", `planner/events/${uid}/duplicate_event/?${queryParams}`)(token);
  };

export const deleteCustomEventInstanceApi = (uid) => (token) =>
  createApiV2("DELETE", `planner/events/${uid}/delete_instance/`)(token);

export const deleteThisCustomEventApi = (uid, date) => (token) =>
  createApiV2("DELETE", `planner/events/${uid}/delete_this/${date}/`)(token);

export const deleteThisAndFollowingCustomEventApi = (uid, date) => (token) =>
  createApiV2("DELETE", `planner/events/${uid}/delete_this_and_following/${date}/`)(token);

export const deleteCustomAllEventApi = (uid) => (token) =>
  createApiV2("DELETE", `planner/events/${uid}/delete_all/`)(token);

export const getUndoDeleteCustomEventInstance = (uid) => (token) =>
  createApiV2("GET", `planner/events/${uid}/undo_delete_instance/`)(token);

export const getUndoDeleteThisCustomEvent = (uid, date) => (token) =>
  createApiV2("GET", `planner/events/${uid}/undo_delete_this/${date}/`)(token);

export const getUndoDeleteThisAndFollowingCustomEvent = (uid, date) => (token) =>
  createApiV2("GET", `planner/events/${uid}/undo_delete_this_and_following/${date}/`)(token);

export const getUndoDeleteCustomAllEvent = (uid) => (token) =>
  createApiV2("GET", `planner/events/${uid}/undo_delete_all/`)(token);
