import { STATUS } from "@/utils/constants";

import {
  SET_INVOICE_TYPE,
  SET_INVOICE_DATE,
  SET_INVOICE_DATA,
  SET_PRINT_PARTIAL_INVOICE_PROGRESS,
  SET_PRINTED_PARTIAL_INVOICE,
  SET_PARTIAL_INVOICES_PROGRESS,
  SET_PARTIAL_INVOICES,
  SET_PARTIAL_INVOICE_SUMMARY_OPEN,
  SET_LAST_SUBMITTED_DATA,
  SET_ADDITIONAL_PRODUCTS_PROGRESS,
  SET_ADDITIONAL_PRODUCTS,
  SET_CREATE_PARTIAL_INVOICE_PROGRESS,
  SET_SPECIFIC_AMOUNT,
  SET_PERCENTAGE,
  SET_LAST_ID,
  SET_EMAIL_PARTIAL_INVOICE_PROGRESS,
  SET_EMAIL_PARTIAL_INVOICE,
  SET_INVOICE_SELECTED_ITEMS,
  SET_INVOICE_ITEM_AMOUNTS,
  SET_INVOICE_ITEM_REMAINING_AMOUNTS,
  RESET,
} from "./partialInvoice.actionTypes";

const initialState = {
  selectedInvoiceType: null,
  invoiceData: {},
  lastSubmittedData: {},
  printPartialInvoiceProgress: STATUS.IDLE,
  printedPartialInvoice: null,
  partialInvoicesProgress: STATUS.IDLE,
  partialInvoices: [],
  partialInvoiceSummaryOpen: false,
  additionalProductsProgress: STATUS.IDLE,
  additionalProducts: [],
  createPartialInvoiceProgress: STATUS.IDLE,
  specificAmount: 0,
  percentage: 0,
  lastId: null,
  emailPartialInvoiceProgress: STATUS.IDLE,
  emailPartialInvoice: null,
  invoiceSelectedItems: [],
  invoiceItemAmounts: {},
  invoiceItemRemainingAmounts: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_INVOICE_TYPE: {
      return { ...state, invoiceType: action.payload };
    }

    case SET_INVOICE_DATE: {
      return { ...state, invoiceDate: action.payload };
    }

    case SET_INVOICE_DATA: {
      return { ...state, invoiceData: action.payload };
    }

    case SET_LAST_SUBMITTED_DATA: {
      return { ...state, lastSubmittedData: action.payload };
    }

    case SET_PRINT_PARTIAL_INVOICE_PROGRESS: {
      return { ...state, printPartialInvoiceProgress: action.payload };
    }

    case SET_PRINTED_PARTIAL_INVOICE: {
      return { ...state, printedPartialInvoice: action.payload };
    }

    case SET_PARTIAL_INVOICES_PROGRESS: {
      return { ...state, partialInvoicesProgress: action.payload };
    }

    case SET_PARTIAL_INVOICES: {
      return { ...state, partialInvoices: action.payload };
    }

    case SET_PARTIAL_INVOICE_SUMMARY_OPEN: {
      return { ...state, partialInvoiceSummaryOpen: action.payload };
    }

    case SET_ADDITIONAL_PRODUCTS_PROGRESS: {
      return { ...state, additionalProductsProgress: action.payload };
    }

    case SET_ADDITIONAL_PRODUCTS: {
      return { ...state, additionalProducts: action.payload };
    }

    case SET_CREATE_PARTIAL_INVOICE_PROGRESS: {
      return { ...state, createPartialInvoiceProgress: action.payload };
    }

    case SET_SPECIFIC_AMOUNT: {
      return { ...state, specificAmount: action.payload };
    }

    case SET_PERCENTAGE: {
      return { ...state, percentage: action.payload };
    }

    case SET_LAST_ID: {
      return { ...state, lastId: action.payload };
    }

    case SET_EMAIL_PARTIAL_INVOICE_PROGRESS: {
      return { ...state, emailPartialInvoiceProgress: action.payload };
    }

    case SET_EMAIL_PARTIAL_INVOICE: {
      return { ...state, emailPartialInvoice: action.payload };
    }

    case SET_INVOICE_SELECTED_ITEMS: {
      return { ...state, invoiceSelectedItems: action.payload };
    }

    case SET_INVOICE_ITEM_AMOUNTS: {
      return { ...state, invoiceItemAmounts: action.payload };
    }

    case SET_INVOICE_ITEM_REMAINING_AMOUNTS: {
      return { ...state, invoiceItemRemainingAmounts: action.payload };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
