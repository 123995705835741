import * as workflowSelectors from "../workflow.selectors";
import * as routerSelectors from "../../router/router.selectors";

import { SET_SELECTED_SORTING_OPTION, RESET } from "./sortFilter.actionTypes";

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setSelectedSortingOption = (data) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_SORTING_OPTION,
    payload: data,
  });
};

export const selectSortingOptionFromSetting = () => (dispatch, getState) => {
  const state = getState();
  const settings = workflowSelectors.getSettings(state);

  dispatch(setSelectedSortingOption(settings.defaultSort));
};

export const selectSortingOptionFromQueryParams = () => (dispatch, getState) => {
  const state = getState();
  const queryParams = routerSelectors.getQuery(state);

  if (queryParams.sort_by) {
    dispatch(setSelectedSortingOption(queryParams.sort_by));
  } else {
    dispatch(setSelectedSortingOption(""));
  }
};
