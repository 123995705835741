import { STATUS } from "@/utils/constants";

import {
  SET_IS_FETCHING_SUMMARY_STATUS,
  SET_IS_UPDATING_TIMESHEET_STATUS,
  SET_SUMMARY,
  SET_FROM_DATE,
  SET_TO_DATE,
  SET_DATES,
  SET_SELECTED_USER_IDS,
  SET_MODAL_TYPE,
  SET_ACTIVE_USER,
  RESET,
} from "./timesheets.actionTypes";

const initialState = {
  isFetchingSummaryStatus: STATUS.IDLE,
  summary: [],
  fromDate: null,
  toDate: null,
  dates: [],
  isUpdatingTimesheetStatus: {},
  selectedUserIds: [],
  activeUser: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SUMMARY: {
      return { ...state, summary: action.payload };
    }

    case SET_IS_FETCHING_SUMMARY_STATUS: {
      return { ...state, isFetchingSummaryStatus: action.payload };
    }

    case SET_FROM_DATE: {
      return { ...state, fromDate: action.payload };
    }

    case SET_TO_DATE: {
      return { ...state, toDate: action.payload };
    }

    case SET_DATES: {
      return { ...state, dates: action.payload };
    }

    case SET_IS_UPDATING_TIMESHEET_STATUS: {
      return {
        ...state,
        isUpdatingTimesheetStatus: { ...state.isUpdatingTimesheetStatus, ...action.payload },
      };
    }

    case SET_SELECTED_USER_IDS: {
      return { ...state, selectedUserIds: action.payload };
    }

    case SET_MODAL_TYPE: {
      return { ...state, modalType: action.payload };
    }

    case SET_ACTIVE_USER: {
      return { ...state, activeUser: action.payload };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
