import {
  SET_IS_FETCHING,
  SET_IS_PRINTING,
  SET_ORDER_ATTACHMENTS,
  SET_SELECTED_ATTACHMENTS,
  SET_DRAWINGS_LIST,
  SET_IS_PRODUCT_HAS_NO_PRICE_ALERT_OPEN,
  POST_ADD_ATTACHMENTS,
  REMOVE_SELECTED_ATTACHMENT,
  UPDATE_SELECTED_ATTACHMENTS,
  RESET,
} from "./orderAttachments.actionTypes";

const initialState = {
  isFetching: false,
  isPrinting: false,
  attachments: [],
  selectedAttachments: [],
  newUploadedAttachments: [],
  drawingsList: null,
  isProductHasNoPriceAlertOpen: false,
  isInvoiceEmail: false,
  isInvoicePreview: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_FETCHING: {
      return { ...state, isFetching: action.payload };
    }

    case SET_IS_PRINTING: {
      return { ...state, isPrinting: action.payload };
    }

    case `${SET_ORDER_ATTACHMENTS}`: {
      const allowedFileTypes = [
        "application/pdf",
        "application/dwg",
        "application/dxf",
        "image/vnd.dwg",
        "image/vnd.dxf",
        "image/png",
        "image/jpg",
        "image/jpeg",
      ];
      const filteredAttachments = action.payload.data.filter((attachment) =>
        allowedFileTypes.includes(attachment.type)
      );
      return { ...state, attachments: filteredAttachments };
    }

    case SET_SELECTED_ATTACHMENTS: {
      return {
        ...state,
        selectedAttachments: state.selectedAttachments.concat([
          { id: action.payload.id, type: action.payload.type },
        ]),
      };
    }

    case SET_DRAWINGS_LIST: {
      return {
        ...state,
        drawingsList: action.payload,
      };
    }

    case SET_IS_PRODUCT_HAS_NO_PRICE_ALERT_OPEN: {
      return {
        ...state,
        isProductHasNoPriceAlertOpen: action.payload.status,
        isInvoiceEmail: action.payload.isInvoiceEmail,
        isInvoicePreview: action.payload.isInvoicePreview,
      };
    }

    case `${POST_ADD_ATTACHMENTS}/fulfilled`: {
      return { ...state, newUploadedAttachments: action.payload };
    }

    case REMOVE_SELECTED_ATTACHMENT: {
      const newList = state.selectedAttachments.filter((item) => item.id !== action.payload.id);
      return { ...state, selectedAttachments: newList };
    }

    case UPDATE_SELECTED_ATTACHMENTS: {
      return { ...state, selectedAttachments: action.payload };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
