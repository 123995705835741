import { STATUS } from "@/utils/constants";

import {
  getMyobAccounts,
  getMyobTaxCodes,
  getMyobItems,
  getMyobJobs,
  getQboAccounts,
  getQboTaxCodes,
  getQboItems,
  getUpdateAllData,
  getXeroItems,
  getXeroAccounts,
  getXeroConnectionStatus,
} from "../../apiv2/accounting";
import { handleError } from "../utils/error";

import {
  SET_IS_FETCHING,
  SET_IS_CONNECTING,
  SET_ITEMS,
  SET_MYOB_JOBS,
  SET_TAX_CODES,
  SET_ACCOUNTS,
  SET_SELECTED_MYOB_COMPANY_FILE,
  SET_IS_MYOB_COMPANY_FILE_MODAL_OPEN,
  SET_IS_CONNECTED_ACCOUNTING_MODAL_OPEN,
  SET_IS_DISCONNECT_ACCOUNTING_MODAL_OPEN,
  SET_IS_ACCOUNTS_TAX_CODES_MODAL_OPEN,
  SET_IS_IMPORT_SUPPLIERS_MODAL_OPEN,
  SET_IS_IMPORT_CUSTOMERS_MODAL_OPEN,
  SET_ACCOUNTING_UPDATE_ALL_STATUS,
  SET_IS_XERO_CONNECTED,
} from "./accounting.actionTypes";

export const setIsFetching = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING,
    payload: status,
  });
};

export const setIsConnecting =
  ({ status, accountingName }) =>
  (dispatch) => {
    dispatch({
      type: SET_IS_CONNECTING,
      payload: { status, accountingName },
    });
  };

export const setAccounts = (data) => (dispatch) => {
  dispatch({
    type: SET_ACCOUNTS,
    payload: {
      data,
    },
  });
};

export const setTaxCodes = (data) => (dispatch) => {
  dispatch({
    type: SET_TAX_CODES,
    payload: {
      data,
    },
  });
};

export const setItems = (data) => (dispatch) => {
  dispatch({
    type: SET_ITEMS,
    payload: {
      data,
    },
  });
};

export const setMyobJobs = (data) => (dispatch) => {
  dispatch({
    type: SET_MYOB_JOBS,
    payload: {
      data,
    },
  });
};

export const setAccountingUpdateAllStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_ACCOUNTING_UPDATE_ALL_STATUS,
    payload,
  });
};

export const setIsXeroConnected = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_XERO_CONNECTED,
    payload: status,
  });
};

export const setIsUpdatingAllData = () => async (dispatch) => {
  try {
    dispatch(setAccountingUpdateAllStatus(STATUS.PENDING));
    await getUpdateAllData()();
    dispatch(setAccountingUpdateAllStatus(STATUS.FULFILLED));
  } catch (error) {
    dispatch(setAccountingUpdateAllStatus(STATUS.REJECTED));
    handleError(error, dispatch);
  }
};

export const fetchMyobAccountsTaxCodes = () => async (dispatch) => {
  try {
    dispatch(setIsFetching(true));
    const response = await getMyobAccounts()();
    dispatch(setAccounts(response.data));
    const taxCodesResponse = await getMyobTaxCodes()();
    dispatch(setTaxCodes(taxCodesResponse.data));
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetching(false));
  }
};

export const fetchQboAccountsTaxCodes = () => async (dispatch) => {
  try {
    dispatch(setIsFetching(true));
    const accountsResponse = await getQboAccounts()();
    dispatch(setAccounts(accountsResponse.data));
    const taxCodesResponse = await getQboTaxCodes()();
    dispatch(setTaxCodes(taxCodesResponse.data));
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetching(false));
  }
};

export const fetchXeroAccounts = () => async (dispatch) => {
  try {
    dispatch(setIsFetching(true));
    const accountsResponse = await getXeroAccounts()();
    dispatch(setAccounts(accountsResponse.data));
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetching(false));
  }
};

export const fetchMyobJobs = () => async (dispatch) => {
  try {
    const response = await getMyobJobs()();
    dispatch(setMyobJobs(response.data));
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetching(false));
  }
};

export const fetchMyobItems = () => async (dispatch) => {
  try {
    dispatch(setIsFetching(true));
    const response = await getMyobItems()();
    dispatch(setItems(response.data));
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetching(false));
  }
};

export const fetchQboItems = () => async (dispatch) => {
  try {
    dispatch(setIsFetching(true));
    const response = await getQboItems()();
    dispatch(setItems(response.data));
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetching(false));
  }
};

export const fetchXeroItems = () => async (dispatch) => {
  try {
    dispatch(setIsFetching(true));
    const response = await getXeroItems()();
    dispatch(setItems(response.data));
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetching(false));
  }
};

export const setSelectedMyobCompanyFile = (data) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_MYOB_COMPANY_FILE,
    payload: {
      data,
    },
  });
};

export const setIsMyobCompanyFileModalOpen =
  ({ status, myobCompanyFiles }) =>
  (dispatch) => {
    dispatch({
      type: SET_IS_MYOB_COMPANY_FILE_MODAL_OPEN,
      payload: {
        status,
        myobCompanyFiles,
      },
    });
  };

export const setIsConnectedAccountingModalOpen =
  ({ status, newAccountingDefault }) =>
  (dispatch) => {
    dispatch({
      type: SET_IS_CONNECTED_ACCOUNTING_MODAL_OPEN,
      payload: {
        status,
        newAccountingDefault,
      },
    });
  };

export const setIsDisconnectAccountingModalOpen =
  ({ status }) =>
  (dispatch) => {
    dispatch({
      type: SET_IS_DISCONNECT_ACCOUNTING_MODAL_OPEN,
      payload: {
        status,
      },
    });
  };

export const setIsAccountsTaxCodesModalOpen =
  ({ status }) =>
  (dispatch) => {
    dispatch({
      type: SET_IS_ACCOUNTS_TAX_CODES_MODAL_OPEN,
      payload: {
        status,
      },
    });
  };

export const setIsImportSuppliersModalOpen =
  ({ status }) =>
  (dispatch) => {
    dispatch({
      type: SET_IS_IMPORT_SUPPLIERS_MODAL_OPEN,
      payload: {
        status,
      },
    });
  };

export const setIsImportCustomersModalOpen =
  ({ status }) =>
  (dispatch) => {
    dispatch({
      type: SET_IS_IMPORT_CUSTOMERS_MODAL_OPEN,
      payload: {
        status,
      },
    });
  };

export const fetchXeroConnectionStatus = () => async (dispatch) => {
  try {
    const { data } = await getXeroConnectionStatus()();

    dispatch(setIsXeroConnected(data.isConnected));

    return data;
  } catch (error) {
    handleError(error, dispatch);
  }
};
