import { isInteger } from "lodash";

import { intercomSettings } from "../env";

import { STATUSES_RESERVED } from "./constants";
import { ROLES, DEFAULT_ROLE } from "./roles";

export function priorStatus(order) {
  if (order.isDeleted) {
    return {
      ...STATUSES_RESERVED.archivedOrder,
      combinedStatusString: order.combinedStatusString,
    };
  }

  if (!order.isSubmitted) {
    return {
      ...STATUSES_RESERVED.draft,
      combinedStatusString: order.combinedStatusString,
    };
  }

  if (order.type === STATUSES_RESERVED.quote.type) {
    return {
      ...STATUSES_RESERVED.quote,
      combinedStatusString: order.combinedStatusString,
    };
  }

  if (order.isReceived) {
    return {
      ...STATUSES_RESERVED[order.isReceived],
      combinedStatusString: order.combinedStatusString,
    };
  }

  if (!order.status.name || !order.status.colour) {
    return {
      ...STATUSES_RESERVED.submitted,
      combinedStatusString: order.combinedStatusString,
    };
  }

  return { ...order.status, combinedStatusString: order.combinedStatusString };
}

export function getCapitalLetters(string) {
  const arrayWords = string.replace(/\s\s+/g, " ").trim().split(" ").slice(0, 2);
  const capitalLetters = arrayWords.map((word) => word.charAt(0).toUpperCase());
  return capitalLetters.join("");
}

export function firstLetterUpperCase(string) {
  return string.charAt(0).toUpperCase() + string.slice(1, string.length);
}

export function allWordsFirstLetter(string) {
  return string
    .split(" ")
    .map((part) => firstLetterUpperCase(part))
    .join(" ");
}

export function getNextLetter(letter) {
  return String.fromCharCode(letter.charCodeAt(0) + 1);
}

export function reorder(list, startIndex, endIndex) {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

export function idToUpperCase(string) {
  const arrayWords = string.split("_");
  const firstWord = arrayWords.slice(0, 1);

  const capitalLetters = arrayWords
    .slice(1, arrayWords.length)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1, word.length));
  return firstWord.concat(capitalLetters).join("");
}

export function isNumber(value) {
  const regexp = /^\d+(\.\d{1,2})?$/;
  return regexp.test(value);
}

export function isNumeric(value) {
  return !Number.isNaN(Number(value));
}

export function isPositiveNumber(value) {
  return !Number.isNaN(Number(value)) && parseFloat(value) > 0;
}

export function isAustralianPostcodes(value) {
  const regexp = /^[0-9]{4}/;
  return regexp.test(value);
}

export function isWholeNumber(value) {
  return isInteger(value) && parseFloat(value) > 0;
}

export function genId() {
  return Math.random().toString(36).substring(7);
}

export function isNew(object) {
  return !object.get("uid");
}

function loadOrder(props, isActive) {
  const { ordersState, actions, match } = props;
  const { customerUid, orderUid } = match.params;

  const data = {
    isActive,
    uid: orderUid,
    customer: customerUid,
  };

  if (ordersState.chosenOrder) {
    data.order = ordersState.chosenOrder;
  }

  return actions.orders
    .openOrder(data)
    .then((response) => response)
    .catch((error) => {
      let errorsMessage = error;

      if (error.response) {
        errorsMessage = new Error(
          `For user ${customerUid} order ${orderUid} - ${error.response.data.detail}`
        );

        errorsMessage.name = "LoadOrder";
      }

      throw errorsMessage.toString();
    });
}

export function loadOrderFromProps(props, isActive) {
  const { ordersState, activeCustomer, actions, match, history } = props;
  const { customerUid, orderUid } = match.params;

  if (ordersState.activeOrder) {
    return Promise.resolve();
  }

  // create new order
  if (!orderUid || orderUid === "new") {
    if (!orderUid) history.replace("order/new");

    // if activeCustomer already loaded then creating new order

    if (activeCustomer) {
      actions.orders.createNewOrder();
      return Promise.resolve();
    }

    // create new order after loading customer
    return actions.customers
      .getCustomer(customerUid)
      .then(() => {
        actions.orders.createNewOrder();
        return Promise.resolve();
      })
      .catch((error) => {
        let errorsMessage = error;

        if (error.response) {
          errorsMessage = new Error(`User ${customerUid} - ${error.response.data.detail.errors}`);

          errorsMessage.name = "LoadOrder";
        }

        throw errorsMessage.toString();
      });
  }

  // if chosen saved order

  // if activeCustomer already loaded then load order

  if (activeCustomer) {
    return loadOrder(props, isActive);
  }

  // load order after loading customer
  return actions.customers
    .getCustomer(customerUid)
    .then(() => loadOrder(props, isActive))
    .catch((error) => {
      let errorsMessage = error;

      if (error.response) {
        errorsMessage = new Error(`User ${customerUid} - ${error.response.data.detail}`);

        errorsMessage.name = "LoadOrder";
      }

      throw errorsMessage.toString();
    });
}

export function reloadWhenNewOrderSaved({ prevProps, props, partUrl }) {
  const newStateOrder = props.ordersState.activeOrder;
  const oldStateOrder = prevProps.ordersState.activeOrder;

  if (
    newStateOrder &&
    oldStateOrder &&
    newStateOrder.uid != null &&
    oldStateOrder.uid != null &&
    newStateOrder.uid !== oldStateOrder.uid
  ) {
    const { history, match } = props;
    const pushUrl = partUrl ? `/${partUrl}` : "";

    history.replace(`/customer/${match.params.customerUid}/order/${newStateOrder.uid}${pushUrl}`);
  }
}

export const optimizedResize = (function optimizedResize() {
  let callbacks = [];
  let running = false;

  function resize() {
    if (!running) {
      running = true;

      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(runCallbacks);
      } else {
        setTimeout(runCallbacks, 66);
      }
    }
  }

  function runCallbacks() {
    callbacks.forEach((callback) => {
      callback();
    });

    running = false;
  }

  function addCallback(callback) {
    if (callback) {
      callbacks.push(callback);
    }
  }

  function removeCallback(callback) {
    if (callback) {
      callbacks = callbacks.filter((func) => func !== callback);
    }
  }

  return {
    add: (callback) => {
      if (!callbacks.length) {
        window.addEventListener("resize", resize);
      }

      addCallback(callback);
    },
    remove: (callback) => {
      if (callbacks.length) {
        removeCallback(callback);
      }

      if (!callbacks.length) {
        window.removeEventListener("resize", resize);
      }
    },
  };
})();

export function updateIntercomSettings(options) {
  if (intercomSettings) {
    const w = window;
    const ic = w.Intercom;

    if (options) {
      ic("reattach_activator");

      ic("update", {
        ...intercomSettings,
        ...options,
      });
    } else {
      ic("update", {
        ...intercomSettings,
        hide_default_launcher: true,
      });
    }
  }
}

export default getCapitalLetters;

export function checkAttachmentFile(newFiles, attachedFiles) {
  const newFileList = [];

  newFiles.map((file) => {
    const identicalFile = attachedFiles.findIndex((item) => item.name === file.name);

    if (identicalFile >= 0) {
      return false;
    }

    return newFileList.push(file);
  });

  return newFileList;
}

export function splitMessage(state, selectedMentionUser, editMessage) {
  let convertedMessage = state;
  const allMentionedUsers = new Map();
  selectedMentionUser.map((item) => allMentionedUsers.set(item.name, item));

  if (editMessage) {
    if (editMessage.users.length) {
      editMessage.users.map((item) => allMentionedUsers.set(item.name, item));
    }
  }

  const mentionedUsers = state.split("@");
  const usersList = mentionedUsers.map((items) => {
    if (items.indexOf("<") === 0 && items.indexOf("") === 0) {
      return null;
    }

    if (items.slice(0, items.indexOf(" ")) !== "<") {
      if (items.indexOf("&") > 0 && items.indexOf(" ") < 0) {
        return items.slice(0, items.indexOf("&"));
      }

      if (items.indexOf("&") > 0 && items.indexOf(" ") > 0) {
        if (items.indexOf("&") > items.indexOf(" ")) {
          return items.slice(0, items.indexOf(" "));
        }

        return items.slice(0, items.indexOf("&"));
      }

      return items.slice(0, items.indexOf(" "));
    }

    return null;
  });
  const checkedList = usersList.filter((item1) => item1 !== null);
  const filteredList = checkedList.reduce((acc, user) => {
    if (user !== acc[user]) {
      acc[user] = user;
    }

    return acc;
  }, {});

  Object.keys(filteredList).map((user) => {
    const isHaveUse = allMentionedUsers.get(user);

    if (isHaveUse) {
      const newUser = new RegExp(`@${filteredList[user]}`, "gi");

      convertedMessage = convertedMessage.replace(newUser, `<span>@${user}</span>`);
    }

    return false;
  });

  return { convertedMessage, filteredList };
}

export const hasAccess = (user, company, path) => {
  // Check if the user has permissions for current page
  let { role = DEFAULT_ROLE } = user;
  const { hasEarlyFeatureAccess } = company || { hasEarlyFeatureAccess: true };

  if (hasEarlyFeatureAccess) {
    role = `[BETA] ${role}`;
  }

  const { access, startPage } = ROLES[role];

  // Detect if user has access to the root path
  const accessObject = access.find((currentAccess) => path.startsWith(currentAccess.path));

  // Detect if user has access to the tab (if exists)
  const searchIndex = path.indexOf("?");
  const tab = new URLSearchParams(path.substring(searchIndex)).get("tab");

  // Access to root AND no tabs
  if (accessObject && !tab) {
    return { accessBoolean: true, startPage };
  }

  // Access to root AND tabs AND access to tab
  if (accessObject && tab && accessObject.tabs.includes(tab)) {
    return { accessBoolean: true, startPage };
  }

  return { accessBoolean: false, startPage };
};

export const getSalesOrderStatusName = (isSubmitted, status) => {
  if (!isSubmitted) {
    return "Draft";
  }

  if (isSubmitted) {
    return status.name;
  }
};

export const getPurchaseOrderStatusName = (editStatus, deliveryStatus, isDeleted, isBilled) => {
  // Archived
  if (isDeleted) {
    return "Archived";
  }

  // Draft
  if (editStatus.id === 1) {
    return editStatus.name;
  }

  if (isBilled) {
    return "Billed";
  }

  // Submitted
  if (editStatus.id === 2 && deliveryStatus.id === 3) {
    return editStatus.name;
  }

  // Delivery
  if (
    (editStatus.id === 2 && deliveryStatus.id === 4) ||
    (editStatus.id === 2 && deliveryStatus.id === 5)
  ) {
    return deliveryStatus.name;
  }
};
