import { fromJS, OrderedMap } from "immutable";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import moment from "moment-timezone";

import * as date from "@/utils/date";
import * as customersApi from "../../api/customers";
import * as accountingApi from "../../apiv2/accounting";
import { updateIntercomSettings } from "../../utils/common";
import { intercomSettings } from "../../env";
import { TIME_ZONE } from "../../utils/constants";
import { ROLES } from "../../utils/roles";

import ReduxModule from "./abstract/ReduxModule";
import { withMutations } from "./helpers/reducerWrappers";
import { createFormData } from "./helpers/common";
import alerts from "./alerts";

const ACTIONS = {
  SET_CUSTOMERS_FILTERS: "Set customers filters",
  RESET_CUSTOMERS_FILTERS: "Reset customers filters",

  SEARCH_CUSTOMERS: "Search customers",
  SET_ACTIVE_CUSTOMER: "Set active customer",
  GET_CUSTOMER: "Get customer",
  CREATE_CUSTOMER: "Create customer",
  SEARCH_CUSTOMERS_CONTACTS: "Search customers contacts",

  UPDATE_CUSTOMER: "Update customer",

  ADD_ADDRESS: "Add address",
  REMOVE_ADDRESS: "Remove address",
  UNDO_REMOVE_ADDRESS: "Undo remove address",

  ADD_CONTACT: "Add contact",
  REMOVE_CONTACT: "Remove contact",
  UNDO_REMOVE_CONTACT: "Undo remove contact",

  DELETE_CUSTOMER: "Delete customer",
  UNDO_DELETE_CUSTOMER: "Undo delete customer",

  CHANGE_PASSWORD: "Change password",
  CHANGE_DATA: "Change data",
  GET_CURRENT_USER: "Get user",

  SAVE_COMPANY_DATA: "Save company data",
  GET_COMPANY_DATA: "Get company data",
  SET_COMPANY_DATA: "Set company data",
  UPDATE_COMPANY_SUBSCRIPTION: "Update company subscription",
  SEARCH_COMPANY_USERS: "Get company user",
  CREATE_COMPANY_USER: "Create company user",
  UPDATE_COMPANY_USER: "Update company user",
  DELETE_COMPANY_USER: "Delete company user",
  UNDO_DELETE_COMPANY_USER: "Undo delete company user",
  DISABLE_COMPANY_USER: "Disable company user",
  UNDO_DISABLE_COMPANY_USER: "Undo disable company user",

  GET_MYOB_CUSTOMERS: "Get MYOB customers",
  CHANGE_ACCOUNTING_CUSTOMER: "Change accounting customer",
  CONNECT_MYOB: "Connect MYOB",
  DISCONNECT_MYOB: "Disconnect MYOB",
  COMPLETE_MYOB_AUTH: "Complete MYOB auth",
  REGISTER_MYOB_FILE: "Register MYOB file",

  GET_QBO_CUSTOMERS: "Get QBO customers",
  CONNECT_QBO: "Connect QBO",
  DISCONNECT_QBO: "Disconnect QBO",
  COMPLETE_QBO_AUTH: "Complete QBO auth",

  GET_XERO_CUSTOMERS: "Get XERO customers",
  CONNECT_XERO: "Connect Xero",
  DISCONNECT_XERO: "Disconnect Xero",
  COMPLETE_XERO_AUTH: "Complete Xero auth",

  CLEAR_ACCOUNTING_INTEGRATION: "Clear accounting integration",

  CHANGE_PRICE_LEVEL: "Change price level",

  SET_VIEW_CUSTOMER: "Set view customer",

  SET_DEFAULT_VIEW: "Set default view",

  SUBMIT_INTERCOM_USER_SETTINGS: "Submit intercom user settings",

  RESET_ON_LOGOUT: "Reset customers on logout",
};

const getDayIndex = (day) => {
  const dayIndex = `0${
    ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"].indexOf(
      day.toLowerCase()
    ) + 1
  }`;

  return dayIndex;
};
const getWorkDayTimeValue = ({ oldTime, newTime }) => {
  if (oldTime && newTime) {
    // Check if value is not equal
    if (oldTime !== newTime) {
      // use newTime
      return newTime;
    } else {
      return oldTime;
    }
  } else if (!oldTime && newTime) {
    return newTime;
  } else if (oldTime && !newTime) {
    return oldTime;
  } else {
    return null;
  }

  // else if (oldTime && !newTime) {
  //   return null;
  // } else if (!oldTime && !newTime) {
  //   return null;
  // } else {
  //   return null;
  // }
};

class CustomersModule extends ReduxModule {
  getNamespace() {
    return "[Customers]";
  }

  getInitialState() {
    return OrderedMap({
      activeCustomer: null,
      user: null,
      userSignup: null, // Signup date as a Unix timestamp
      company: null,
      accountingCustomers: {
        customers: [],
        isPending: false,
      },
      dataServer: {
        isPending: false,
        items: [],
      },
      isPending: false,
      filters: {
        page: 0,
      },
      errors: {},
    });
  }

  initialAddressState = () => ({
    contactName: "",
    phone: "",
    email: "",
    mobile: "",
  });

  initialContactState = () => ({
    address1: "",
    address2: "",
    city: "",
    state: "",
    postcode: "",
    countryCode: "",
  });

  searchCustomers = ({ token, fulfilled }, search, withArchived = false) =>
    customersApi
      .searchCustomers(token, { search, with_archived: withArchived })
      .then((response) => {
        const { data } = response;
        fulfilled(fromJS(camelcaseKeys(data, { deep: true })));
        return data;
      });

  searchCustomersContacts = ({ token, getState, fulfilled }, search, type) => {
    const activeCustomer = getState().getIn(["customers", "activeCustomer"]);
    const customerUid = activeCustomer.get("uid");
    return customersApi
      .searchCustomersContacts(
        token,
        customerUid,
        type ? { contact_search: search } : { address_search: search }
      )
      .then((response) => {
        const data = response;
        fulfilled(camelcaseKeys(data, { deep: true }));
        return data;
      });
  };

  getCustomer = ({ token, fulfilled }, uid) =>
    customersApi.getCustomer(token, uid).then((response) => {
      const { data } = response;
      fulfilled(camelcaseKeys(data, { deep: true }));
      return data;
    });

  createCustomer = ({ token, fulfilled }, data) => {
    const {
      name,
      phone,
      email,
      abn,
      state,
      address1,
      address2,
      city,
      postcode,
      createAccountingCustomer,
    } = data;

    return customersApi
      .createCustomer(token, {
        name,
        phone,
        email,
        abn,
        billing_address: {
          state,
          address1,
          address2,
          city,
          postcode,
        },
        create_accounting_customer: createAccountingCustomer,
      })
      .then((response) => {
        fulfilled(response);
        return response.data;
      });
  };

  changeCustomerData = ({ token, getState, fulfilled }, data) => {
    const activeCustomer = getState().getIn(["customers", "activeCustomer"]);
    const customerUid = activeCustomer.get("uid");
    const newData = data || activeCustomer.toJS();
    const { defaultPriceLevel } = newData;

    if (typeof defaultPriceLevel === "object" && defaultPriceLevel !== null) {
      newData.defaultPriceLevel = defaultPriceLevel.uid;
    }

    return customersApi
      .changeCustomerData(token, customerUid, snakecaseKeys(newData))
      .then((response) => {
        fulfilled(response);
        return response.data;
      });
  };

  deleteCustomer = ({ token, dispatch, fulfilled }, customer, withFilter = true) => {
    const { uid } = customer;
    const data = snakecaseKeys({ isDeleted: true });

    return customersApi.changeCustomerData(token, uid, data).then((response) => {
      fulfilled(response);

      dispatch(
        alerts.actions.addAlert({
          type: "dark",
          message: "Customer archived",
          action: {
            label: "Undo",
            callback: (filter) => {
              dispatch(this.actions.undoDeleteCustomer(customer)).then(() => {
                if (filter && withFilter) {
                  const { value, withArchived } = filter;

                  dispatch(this.actions.setActiveCustomer(null));
                  dispatch(this.actions.searchCustomers(value, withArchived));
                }
              });
            },
          },
        })
      );

      return response.data;
    });
  };

  undoDeleteCustomer = ({ token, fulfilled }, customer) => {
    const { uid } = customer;
    const data = snakecaseKeys({ isDeleted: false });

    return customersApi.changeCustomerData(token, uid, data).then((response) => {
      fulfilled(response);
      return response.data;
    });
  };

  removeAddressThunk = (state, id) => this.removePartialDataThunk(state, { type: "address", id });

  removeContactThunk = (state, id) => this.removePartialDataThunk(state, { type: "contact", id });

  removePartialDataThunk = ({ dispatch, getState, fulfilled }, { type, id }) => {
    let data = null;
    let message = "";

    if (type === "address") {
      data = getState().getIn(["customers", "activeCustomer", "shippingAddresses", id]);
      message = "Delivery address deleted";
    } else if (type === "contact") {
      data = getState().getIn(["customers", "activeCustomer", "contacts", id]);
      message = "Contact deleted";
    }

    fulfilled(id);

    dispatch(
      alerts.actions.addAlert({
        type: "danger",
        message,
        closeDelay: 5000,
        action: {
          label: "Undo",
          callback: () => {
            if (type === "address") {
              dispatch(this.actions.undoRemoveAddress({ data, id }));
            } else if (type === "contact") {
              dispatch(this.actions.undoRemoveContact({ data, id }));
            }
          },
        },
      })
    );

    return Promise.resolve();
  };

  undoRemovePartialDataThunk = ({ dispatch, fulfilled }, { data, id }) => {
    fulfilled({ data, id });
    dispatch(this.actions.changeCustomerData());
  };

  addPartialDataThunk = ({ fulfilled }) => {
    fulfilled();

    return Promise.resolve();
  };

  getMyobCustomers = ({ token, getState, fulfilled, dispatch }, { searchString = "" } = {}) => {
    const role = getState().getIn(["customers", "user", "role"]);
    if (ROLES[role].id > 1) {
      return Promise.resolve();
    }

    return accountingApi
      .getMyobCustomers(token, { search: searchString })
      .then((response) => {
        fulfilled({ data: response.data });
        return response.data;
      })
      .catch(() => {
        dispatch(
          alerts.actions.addAlert({
            type: "danger",
            message: "Couldn't fetch MYOB customers, try to refresh page.",
          })
        );
      });
  };

  getQboCustomers = ({ token, getState, fulfilled, dispatch }, { searchString = "" } = {}) => {
    const role = getState().getIn(["customers", "user", "role"]);

    if (ROLES[role].id > 1) {
      return Promise.resolve();
    }

    return accountingApi
      .getQboCustomers(token, { search: searchString })
      .then((response) => {
        fulfilled({ data: response.data });
        return response.data;
      })
      .catch(() => {
        dispatch(
          alerts.actions.addAlert({
            type: "danger",
            message: "Couldn't fetch QBO customers, try to refresh page.",
          })
        );
      });
  };

  getXeroCustomers = ({ token, getState, fulfilled, dispatch }, { searchString = "" } = {}) => {
    const role = getState().getIn(["customers", "user", "role"]);
    if (ROLES[role].id > 1) {
      return Promise.resolve();
    }

    return accountingApi
      .getXeroCustomers(token, { search: searchString })
      .then((response) => {
        fulfilled({ data: response.data });
        return response.data;
      })
      .catch(() => {
        dispatch(
          alerts.actions.addAlert({
            type: "danger",
            message: "Couldn't fetch XERO customers, try to refresh page.",
          })
        );
      });
  };

  connectMyob = ({ token }) => accountingApi.getMyobConnection(token).then((res) => res.data);

  disconnectMyob = ({ token, fulfilled }) =>
    accountingApi.disconnectMyob(token).then(() => {
      fulfilled();
    });

  completeMyobAuth = ({ token, dispatch }, { code = "", state = "" }) =>
    accountingApi
      .completeMyobAuth(token, { code, state })
      .then((res) => res.data)
      .catch(() => {
        dispatch(
          alerts.actions.addAlert({
            type: "danger",
            message: "Couldn't complete MYOB authorization",
          })
        );
      });

  registerMyobFile = ({ token }, data) => accountingApi.registerProfile(token, data);

  connectQbo = ({ token }) => accountingApi.getQboConnection(token).then((res) => res.data);

  disconnectQbo = ({ token, fulfilled }) =>
    accountingApi.disconnectQbo(token).then(() => {
      fulfilled();
    });

  completeQboAuth = ({ token, dispatch }, { code = "", state = "", realmId = "" }) =>
    accountingApi
      .completeQboAuth(token, { code, state, realmId })
      .then((res) => ({ connect: res.status }))
      .catch(() => {
        dispatch(
          alerts.actions.addAlert({
            type: "danger",
            message: "Couldn't complete QBO authorization",
          })
        );
      });

  connectXero = ({ token }) => accountingApi.getXeroConnection(token).then((res) => res.data);

  disconnectXero = ({ token, getState, fulfilled }) => {
    const userId = getState().getIn(["customers", "user", "id"]);

    if (window.Intercom && process.env.ENV === "production") {
      window.Intercom("update", {
        user_id: `${userId}`,
        is_connected_to_xero: false,
      });
    }
    return accountingApi.disconnectXero(token).then(() => {
      fulfilled();
    });
  };

  completeXeroAuth = (
    { token, getState, dispatch },
    { code = "", state = "", scope = "", session_state = "" }
  ) => {
    const userId = getState().getIn(["customers", "user", "id"]);

    if (window.Intercom && process.env.ENV === "production") {
      window.Intercom("update", {
        user_id: `${userId}`,
        is_connected_to_xero: true,
      });
    }
    const requestObj = accountingApi.completeXeroAuth(token, {
      code,
      state,
      scope,
      session_state,
    });
    return requestObj
      .then((res) => res.data)
      .catch(() => {
        dispatch(
          alerts.actions.addAlert({
            type: "danger",
            message: "Couldn't complete XERO authorization",
          })
        );
      });
  };

  getCurrentUserThunk = ({ token, fulfilled }) =>
    customersApi.getUser(token).then((response) => {
      fulfilled(response);

      return response.data;
    });

  submitIntercomUserSettingsThunk = ({ getState }, { user, company }) => {
    if (window.Intercom && intercomSettings?.app_id && process.env.ENV === "production") {
      window.Intercom("boot", {
        app_id: intercomSettings?.app_id ?? "",
        user_role: user.role,
        accounting_package: company.accounting_company_id ? company.accounting_name : "",
        name: user.first_name,
        user_hash: user.user_hash,
        user_id: `${user.id}`,
        email: user.email,
        created_at: user.date_joined,
        is_on_trial: company.on_trial,
        trial_start_date_at: company.on_trial
          ? (moment(company.createdAt).valueOf() / 1000).toFixed(0)
          : null,
        trial_end_date_at: company.on_trial
          ? (moment(company.trial_expiration).valueOf() / 1000).toFixed(0)
          : null,
        company: {
          name: user?.company_name ?? "",
          id: user?.company_uid ?? "",
          created_at: (moment(company.created_at).valueOf() / 1000).toFixed(0),
        },
      });
    }
  };

  saveUserData = ({ token, fulfilled }, data) =>
    customersApi.saveUserData(token, data).then((response) => {
      fulfilled(response);
      return response.data;
    });

  saveCompanyData = ({ token, fulfilled, getState }, values) => {
    const timeTrackingSettings = getState()
      .getIn(["customers", "company", "settings", "timeTrackingSettings"])
      .toJS();

    const { images, ...json } = values;
    const payloadData = {
      ...json,
      settings: {
        ...json.settings,
        timeTrackingSettings: {
          ...json.settings.timeTrackingSettings,
          workDays: json.settings.timeTrackingSettings.workDays.map((w) => {
            const workDay = timeTrackingSettings.workDays.find((s) => s.key === w.key);

            const start = getWorkDayTimeValue({ oldTime: workDay.start, newTime: w.start });
            const end = getWorkDayTimeValue({ oldTime: workDay.end, newTime: w.end });

            return {
              ...w,
              start: start
                ? date.toUtcDateTime({
                    date: `${moment().format("YYYY-MM-DD")}T${start}:00`,
                    timezone: date.getCurrentUserTimezone(),
                    format: "HH:mm",
                  })
                : null,
              end: end
                ? date.toUtcDateTime({
                    date: `${moment().format("YYYY-MM-DD")}T${end}:00`,
                    timezone: date.getCurrentUserTimezone(),
                    format: "HH:mm",
                  })
                : null,
            };
          }),
        },
      },
    };

    const { files } = images;
    const image = files ? files[0] : null;

    const customerData = createFormData(snakecaseKeys(payloadData), image);
    const { imagePromise, data: formData } = customerData;
    return imagePromise
      .then(() => customersApi.saveCompanyData(token, formData))
      .then((response) => {
        const { data } = response;
        const company = camelcaseKeys(data, { deep: true });

        fulfilled(
          fromJS({
            ...company,
            settings: json.settings,
          })
        );
        return data;
      });
  };

  getCompanyData = ({ token, fulfilled }) =>
    customersApi.getCompanyData(token).then((response) => {
      const { data } = response;
      const company = camelcaseKeys(data, { deep: true });

      fulfilled(
        fromJS({
          ...company,
          settings: {
            ...company.settings,
            timeTrackingSettings: {
              ...company.settings.timeTrackingSettings,
              workDays: company.settings.timeTrackingSettings.workDays.map((w) => ({
                ...w,
                start: w.start
                  ? date.toLocalDateTime({
                      date: `${moment().format("YYYY-MM-")}${getDayIndex(w.key)}T${w.start}:00Z`,
                      timezone: date.getCurrentUserTimezone(),
                      format: "HH:mm",
                    })
                  : null,
                end: w.end
                  ? date.toLocalDateTime({
                      date: `${moment().format("YYYY-MM-")}${getDayIndex(w.key)}T${w.end}:00Z`,
                      timezone: date.getCurrentUserTimezone(),
                      format: "HH:mm",
                    })
                  : null,
              })),
            },
          },
        })
      );
      return data;
    });

  searchCompanyUsers = ({ token, fulfilled }, search, withInactive = false) =>
    customersApi
      .searchCompanyUsers(token, { search, with_inactive: withInactive })
      .then((response) => {
        const { data } = response;
        fulfilled(fromJS(camelcaseKeys(data, { deep: true })));
        return data;
      });

  createCompanyUser = ({ token, fulfilled }, data) =>
    customersApi.createCompanyUser(token, snakecaseKeys(data)).then((response) => {
      fulfilled(response);
      return response.data;
    });

  changeCompanyUserData = ({ token, fulfilled }, data) =>
    customersApi.changeCompanyUserData(token, data.id, snakecaseKeys(data)).then((response) => {
      fulfilled(response);
      return response.data;
    });

  deleteCompanyUser = ({ token, fulfilled }, user) => {
    const { id } = user;
    const data = snakecaseKeys({ ...user, isDeleted: true });

    return customersApi.changeCompanyUserData(token, id, data).then((response) => {
      fulfilled(response);
      return response.data;
    });
  };

  undoDeleteCompanyUser = ({ token, fulfilled }, user) => {
    const { id } = user;
    const data = snakecaseKeys({ ...user, isDeleted: false });

    return customersApi.changeCompanyUserData(token, id, data).then((response) => {
      fulfilled(response);
      return response.data;
    });
  };

  disableCompanyUser = ({ token, fulfilled }, user) => {
    const { id } = user;
    const data = snakecaseKeys({ ...user, isActive: false });

    return customersApi.changeCompanyUserData(token, id, data).then((response) => {
      fulfilled(response);
      return response.data;
    });
  };

  undoDisableCompanyUser = ({ token, fulfilled }, user) => {
    const { id } = user;
    const data = snakecaseKeys({ ...user, isActive: true });

    return customersApi.changeCompanyUserData(token, id, data).then((response) => {
      fulfilled(response);
      return response.data;
    });
  };

  defineActions() {
    const submitIntercomUserSettings = this.thunkAction(
      ACTIONS.SUBMIT_INTERCOM_USER_SETTINGS,
      this.submitIntercomUserSettingsThunk
    );
    const updateCompanySubscription = this.createAction(ACTIONS.UPDATE_COMPANY_SUBSCRIPTION);
    const setCustomersFilters = this.createAction(ACTIONS.SET_CUSTOMERS_FILTERS);
    const resetCustomersFilters = this.createAction(ACTIONS.RESET_CUSTOMERS_FILTERS);
    const setActiveCustomer = this.createAction(ACTIONS.SET_ACTIVE_CUSTOMER);
    const setDefaultView = this.createAction(ACTIONS.SET_DEFAULT_VIEW);
    const searchCustomers = this.thunkAction(ACTIONS.SEARCH_CUSTOMERS, this.searchCustomers, true);
    const getCustomer = this.thunkAction(ACTIONS.GET_CUSTOMER, this.getCustomer, true);
    const createCustomer = this.thunkAction(ACTIONS.CREATE_CUSTOMER, this.createCustomer, true);
    const changeCustomerData = this.thunkAction(ACTIONS.UPDATE_CUSTOMER, this.changeCustomerData);
    const searchCustomersContacts = this.thunkAction(
      ACTIONS.SEARCH_CUSTOMERS_CONTACTS,
      this.searchCustomersContacts,
      true
    );

    const addAddress = this.thunkAction(ACTIONS.ADD_ADDRESS, this.addPartialDataThunk);
    const removeAddress = this.thunkAction(ACTIONS.REMOVE_ADDRESS, this.removeAddressThunk);
    const undoRemoveAddress = this.thunkAction(
      ACTIONS.UNDO_REMOVE_ADDRESS,
      this.undoRemovePartialDataThunk
    );

    const addContact = this.thunkAction(ACTIONS.ADD_CONTACT, this.addPartialDataThunk);
    const removeContact = this.thunkAction(ACTIONS.REMOVE_CONTACT, this.removeContactThunk);
    const undoRemoveContact = this.thunkAction(
      ACTIONS.UNDO_REMOVE_CONTACT,
      this.undoRemovePartialDataThunk
    );

    const deleteCustomer = this.thunkAction(ACTIONS.DELETE_CUSTOMER, this.deleteCustomer, true);
    const undoDeleteCustomer = this.thunkAction(
      ACTIONS.UNDO_DELETE_CUSTOMER,
      this.undoDeleteCustomer,
      true
    );

    const getMyobCustomers = this.thunkAction(
      ACTIONS.GET_MYOB_CUSTOMERS,
      this.getMyobCustomers,
      true
    );
    const connectMyob = this.thunkAction(ACTIONS.CONNECT_MYOB, this.connectMyob, true);
    const disconnectMyob = this.thunkAction(ACTIONS.DISCONNECT_MYOB, this.disconnectMyob, true);
    const changeConnectAccount = this.thunkAction(
      ACTIONS.CHANGE_ACCOUNTING_CUSTOMER,
      this.changeCustomerData
    );
    const completeMyobAuth = this.thunkAction(
      ACTIONS.COMPLETE_MYOB_AUTH,
      this.completeMyobAuth,
      true
    );
    const registerMyobFile = this.thunkAction(
      ACTIONS.REGISTER_MYOB_FILE,
      this.registerMyobFile,
      true
    );

    const getQboCustomers = this.thunkAction(ACTIONS.GET_QBO_CUSTOMERS, this.getQboCustomers, true);
    const connectQbo = this.thunkAction(ACTIONS.CONNECT_QBO, this.connectQbo, true);
    const disconnectQbo = this.thunkAction(ACTIONS.DISCONNECT_QBO, this.disconnectQbo, true);
    const completeQboAuth = this.thunkAction(ACTIONS.COMPLETE_QBO_AUTH, this.completeQboAuth, true);
    const getXeroCustomers = this.thunkAction(
      ACTIONS.GET_XERO_CUSTOMERS,
      this.getXeroCustomers,
      true
    );
    const connectXero = this.thunkAction(ACTIONS.CONNECT_XERO, this.connectXero, true);
    const disconnectXero = this.thunkAction(ACTIONS.DISCONNECT_XERO, this.disconnectXero, true);
    const completeXeroAuth = this.thunkAction(
      ACTIONS.COMPLETE_XERO_AUTH,
      this.completeXeroAuth,
      true
    );

    const changePriceLevel = this.thunkAction(ACTIONS.CHANGE_PRICE_LEVEL, this.changeCustomerData);

    const changePassword = this.thunkAction(ACTIONS.CHANGE_PASSWORD, this.changePassword, true);
    const saveUserData = this.thunkAction(ACTIONS.CHANGE_DATA, this.saveUserData, true);
    const getCurrentUser = this.thunkAction(
      ACTIONS.GET_CURRENT_USER,
      this.getCurrentUserThunk,
      true
    );

    const saveCompanyData = this.thunkAction(ACTIONS.SAVE_COMPANY_DATA, this.saveCompanyData);
    const getCompanyData = this.thunkAction(ACTIONS.GET_COMPANY_DATA, this.getCompanyData, true);
    const setCompanyData = this.createAction(ACTIONS.SET_COMPANY_DATA);
    const searchCompanyUsers = this.thunkAction(
      ACTIONS.SEARCH_COMPANY_USERS,
      this.searchCompanyUsers,
      true
    );
    const createCompanyUser = this.thunkAction(ACTIONS.CREATE_COMPANY_USER, this.createCompanyUser);
    const changeCompanyUserData = this.thunkAction(
      ACTIONS.UPDATE_COMPANY_USER,
      this.changeCompanyUserData
    );
    const deleteCompanyUser = this.thunkAction(ACTIONS.DELETE_COMPANY_USER, this.deleteCompanyUser);
    const undoDeleteCompanyUser = this.thunkAction(
      ACTIONS.UNDO_DELETE_COMPANY_USER,
      this.undoDeleteCompanyUser
    );
    const disableCompanyUser = this.thunkAction(
      ACTIONS.DISABLE_COMPANY_USER,
      this.disableCompanyUser
    );
    const undoDisableCompanyUser = this.thunkAction(
      ACTIONS.UNDO_DISABLE_COMPANY_USER,
      this.undoDisableCompanyUser
    );

    const clearAccountingIntegration = this.createAction(ACTIONS.CLEAR_ACCOUNTING_INTEGRATION);

    const resetOnLogout = this.createAction(ACTIONS.RESET_ON_LOGOUT);

    return {
      submitIntercomUserSettings,
      updateCompanySubscription,
      setCustomersFilters,
      resetCustomersFilters,

      searchCustomers,
      searchCustomersContacts,
      setActiveCustomer,
      setDefaultView,
      getCustomer,
      createCustomer,

      changePriceLevel,
      changeCustomerData,

      addAddress,
      removeAddress,
      undoRemoveAddress,

      addContact,
      removeContact,
      undoRemoveContact,

      deleteCustomer,
      undoDeleteCustomer,

      getMyobCustomers,
      connectMyob,
      disconnectMyob,
      changeConnectAccount,
      completeMyobAuth,
      registerMyobFile,

      connectQbo,
      disconnectQbo,
      completeQboAuth,
      getQboCustomers,

      connectXero,
      disconnectXero,
      completeXeroAuth,
      getXeroCustomers,

      changePassword,
      saveUserData,
      getCurrentUser,

      saveCompanyData,
      getCompanyData,
      setCompanyData,
      searchCompanyUsers,
      createCompanyUser,
      changeCompanyUserData,
      deleteCompanyUser,
      undoDeleteCompanyUser,
      disableCompanyUser,
      undoDisableCompanyUser,

      clearAccountingIntegration,
      resetOnLogout,
    };
  }

  getCurrentUserSignUp = (_state, data) => {
    const signUp = moment.tz(data.data.time_zone).unix();
    _state.set("userSignup", signUp);

    this.getCurrentUser(_state, { payload: data });
  };

  getCurrentUser = (_state, { payload: { data } }) => {
    if (window.FS) {
      window.FS.identify(data.id, {
        displayName: data.first_name,
        email: data.email,
      });
    }

    window.localStorage.setItem("user", data.id);

    return _state.set("user", fromJS(camelcaseKeys(data, { deep: true })));
  };

  setActiveCustomer = (_state, { payload }) => {
    if (payload) {
      const activeCustomer = payload;

      if (activeCustomer.shippingAddresses && !activeCustomer.shippingAddresses.length) {
        if (payload.billingAddress) {
          activeCustomer.shippingAddresses = [{ ...payload.billingAddress }];
          delete activeCustomer.shippingAddresses[0].uid;
        }
      }

      return _state.set("activeCustomer", fromJS(activeCustomer));
    }

    return _state.set("activeCustomer", null);
  };

  setDefaultView = (_state, { payload }) => {
    const { page, uid } = payload;
    const user = _state.get("user").toJS();
    return _state.set(
      "user",
      fromJS({
        ...user,
        defaultCustomViews: { ...user.defaultCustomViews, [page]: uid },
      })
    );
  };

  updateActiveCustomer = (_state, { payload: { data } }) => {
    const isActive = _state.get("activeCustomer");

    if (isActive) {
      const parsedData = camelcaseKeys(data, { deep: true });
      return _state.set("activeCustomer", fromJS(parsedData));
    }

    return _state;
  };

  defineReducers() {
    return {
      [ACTIONS.UPDATE_COMPANY_SUBSCRIPTION]: (state, { payload: { key, value } }) => {
        return state.setIn(["company", "subscription", key], value);
      },
      [ACTIONS.SET_CUSTOMERS_FILTERS]: this.mergeInReducer(["filters"]),
      [ACTIONS.RESET_CUSTOMERS_FILTERS]: (state) => state.setIn(["filters"], fromJS({})),

      [ACTIONS.SET_ACTIVE_CUSTOMER]: this.setActiveCustomer,
      [ACTIONS.SET_DEFAULT_VIEW]: this.setDefaultView,
      [`${ACTIONS.SEARCH_CUSTOMERS} fulfilled`]: this.setInReducer(["dataServer", "items"]),

      [`${ACTIONS.SEARCH_CUSTOMERS} pending`]: this.thunkPendingReducer([
        "dataServer",
        "isPending",
      ]),

      [`${ACTIONS.GET_CUSTOMER} fulfilled`]: this.setActiveCustomer,

      [`${ACTIONS.GET_CUSTOMER} pending`]: this.thunkPendingReducer("isPending"),

      [`${ACTIONS.GET_CUSTOMER} rejected`]: this.setReducer("errors"),

      [`${ACTIONS.GET_CURRENT_USER} fulfilled`]: withMutations(this.getCurrentUserSignUp),
      [`${ACTIONS.GET_CURRENT_USER} pending`]: this.thunkPendingReducer("isPending"),
      [`${ACTIONS.GET_CURRENT_USER} rejected`]: this.setReducer("errors"),

      [ACTIONS.RESET_ON_LOGOUT]: () => {
        if (window.Intercom && process.env.ENV === "production") {
          window.Intercom("shutdown");
        }
        return fromJS(this.getInitialState());
      },

      [`${ACTIONS.CHANGE_PASSWORD} fulfilled`]: (state) => state,
      [`${ACTIONS.CHANGE_PASSWORD} pending`]: this.thunkPendingReducer("isPending"),
      [`${ACTIONS.CHANGE_PASSWORD} rejected`]: this.setReducer("errors"),

      [`${ACTIONS.CHANGE_DATA} fulfilled`]: this.getCurrentUser,
      [`${ACTIONS.CHANGE_DATA} pending`]: this.thunkPendingReducer("isPending"),
      [`${ACTIONS.CHANGE_DATA} rejected`]: this.setReducer("errors"),

      [`${ACTIONS.CREATE_CUSTOMER} fulfilled`]: this.updateActiveCustomer,
      [`${ACTIONS.CREATE_CUSTOMER} pending`]: this.thunkPendingReducer("isPending"),
      [`${ACTIONS.CREATE_CUSTOMER} rejected`]: this.setReducer("errors"),

      [`${ACTIONS.UPDATE_CUSTOMER} fulfilled`]: this.updateActiveCustomer,
      [`${ACTIONS.UPDATE_CUSTOMER} pending`]: this.thunkPendingReducer("isPending"),
      [`${ACTIONS.UPDATE_CUSTOMER} rejected`]: this.setReducer("errors"),

      [`${ACTIONS.SEARCH_CUSTOMERS_CONTACTS} fulfilled`]: this.updateActiveCustomer,
      [`${ACTIONS.SEARCH_CUSTOMERS_CONTACTS} pending`]: this.thunkPendingReducer([
        "dataServer",
        "isPending",
      ]),
      [`${ACTIONS.SEARCH_CUSTOMERS_CONTACTS} rejected`]: this.setReducer("errors"),

      [`${ACTIONS.ADD_ADDRESS} fulfilled`]: (state) =>
        state.updateIn(["activeCustomer", "shippingAddresses"], (list) => {
          const item = this.initialAddressState();
          return list.unshift(fromJS(item));
        }),
      [`${ACTIONS.UNDO_REMOVE_ADDRESS} fulfilled`]: (state, { payload: { data, id } }) =>
        state.updateIn(["activeCustomer", "shippingAddresses"], (list) =>
          list.splice(id, 0, data.set("isDeleted", false))
        ),
      [`${ACTIONS.REMOVE_ADDRESS} fulfilled`]: (state, { payload: id }) =>
        state.setIn(["activeCustomer", "shippingAddresses", id, "isDeleted"], true),

      [`${ACTIONS.ADD_CONTACT} fulfilled`]: (state) =>
        state.updateIn(["activeCustomer", "contacts"], (list) => {
          const item = this.initialContactState();
          return list.unshift(fromJS(item));
        }),
      [`${ACTIONS.UNDO_REMOVE_CONTACT} fulfilled`]: (state, { payload: { data, id } }) =>
        state.updateIn(["activeCustomer", "contacts"], (list) =>
          list.splice(id, 0, data.set("isDeleted", false))
        ),
      [`${ACTIONS.REMOVE_CONTACT} fulfilled`]: (state, { payload: id }) =>
        state.setIn(["activeCustomer", "contacts", id, "isDeleted"], true),

      [`${ACTIONS.DELETE_CUSTOMER} fulfilled`]: this.updateActiveCustomer,
      [`${ACTIONS.DELETE_CUSTOMER} pending`]: this.thunkPendingReducer("isPending"),
      [`${ACTIONS.DELETE_CUSTOMER} rejected`]: this.setReducer("errors"),

      [`${ACTIONS.UNDO_DELETE_CUSTOMER} fulfilled`]: this.updateActiveCustomer,
      [`${ACTIONS.UNDO_DELETE_CUSTOMER} pending`]: this.thunkPendingReducer("isPending"),
      [`${ACTIONS.UNDO_DELETE_CUSTOMER} rejected`]: this.setReducer("errors"),

      [`${ACTIONS.SAVE_COMPANY_DATA} fulfilled`]: this.setReducer("company"),
      [`${ACTIONS.SAVE_COMPANY_DATA} pending`]: this.thunkPendingReducer("isPending"),
      [`${ACTIONS.SAVE_COMPANY_DATA} rejected`]: this.setReducer("errors"),

      [ACTIONS.SET_COMPANY_DATA]: (state, { payload }) => state.set("company", fromJS(payload)),

      [`${ACTIONS.GET_COMPANY_DATA} fulfilled`]: this.setReducer("company"),
      [`${ACTIONS.GET_COMPANY_DATA} pending`]: this.thunkPendingReducer("isPending"),
      [`${ACTIONS.GET_COMPANY_DATA} rejected`]: this.setReducer("errors"),

      [`${ACTIONS.SEARCH_COMPANY_USERS} fulfilled`]: this.setInReducer(["dataServer", "items"]),
      [`${ACTIONS.SEARCH_COMPANY_USERS} pending`]: this.thunkPendingReducer([
        "dataServer",
        "isPending",
      ]),
      [`${ACTIONS.GET_MYOB_CUSTOMERS} fulfilled`]: (state, { payload: { data } }) =>
        state.setIn(["accountingCustomers", "customers"], fromJS(camelcaseKeys(data))),
      [`${ACTIONS.GET_QBO_CUSTOMERS} fulfilled`]: (state, { payload: { data } }) =>
        state.setIn(["accountingCustomers", "customers"], fromJS(camelcaseKeys(data))),
      [`${ACTIONS.GET_XERO_CUSTOMERS} fulfilled`]: (state, { payload: { data } }) =>
        state.setIn(["accountingCustomers", "customers"], fromJS(camelcaseKeys(data))),
      [`${ACTIONS.GET_MYOB_CUSTOMERS} pending`]: this.thunkPendingReducer([
        "accountingCustomers",
        "isPending",
      ]),
      [`${ACTIONS.CHANGE_ACCOUNTING_CUSTOMER} fulfilled`]: this.updateActiveCustomer,

      [`${ACTIONS.COMPLETE_MYOB_AUTH} fulfilled`]: (state) =>
        state.setIn(["company", "accountingSyncAllowed"], true),
      [`${ACTIONS.DISCONNECT_MYOB} fulfilled`]: (state) =>
        state.setIn(["company", "accountingSyncAllowed"], false),
      [`${ACTIONS.COMPLETE_QBO_AUTH} fulfilled`]: (state) =>
        state.setIn(["company", "accountingSyncAllowed"], true),
      [`${ACTIONS.DISCONNECT_QBO} fulfilled`]: (state) =>
        state.setIn(["company", "accountingSyncAllowed"], false),
      [`${ACTIONS.COMPLETE_XERO_AUTH} fulfilled`]: (state) =>
        state.setIn(["company", "accountingSyncAllowed"], true),
      [`${ACTIONS.DISCONNECT_XERO} fulfilled`]: (state) =>
        state.setIn(["company", "accountingSyncAllowed"], false),

      [ACTIONS.CLEAR_ACCOUNTING_INTEGRATION]: (state) => {
        return state.mergeIn(["company"], {
          accountingCompanyId: null,
          accountingDefault: null,
          accountingDefault: {},
          accountingLastPull: null,
          accountingName: null,
          accountingSyncAllowed: false,
        });
      },
    };
  }
}

const instance = new CustomersModule();
instance.init();

export default instance;
