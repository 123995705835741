import React from "react";
import * as Sentry from "@sentry/react";
import { render } from "react-dom";

import "./styles/index.scss";
import "./styles/vendors/ReactToastify.scss";

import Root from "./Root";
import { SENTRY_DSN } from "./env";

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],

    // Performance Monitoring
    tracesSampleRate: 0.3, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      /^https:\/\/staging4-dev\.factory\.app/,
      /^https:\/\/rc-dev\.factory\.app/,
      /^https:\/\/app\.factory\.app/,
    ],

    // Session Replay
    // This sets the sample rate at 10%. You may want to change it to 100%
    // while in development and then sample at a lower rate in production.
    replaysSessionSampleRate: 0.1,

    // If you're not already sampling the entire session, change the sample rate to 100%
    // when sampling sessions where errors occur.
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.ENV || window.location.hostname || "prod",
  });
}

render(<Root />, document.getElementById("app"));

if (module.hot) {
  module.hot.accept();
}
