import { handleError } from "../utils/error";
import * as timezonesApi from "../../apiv2/timezones";

import { SET_TIMEZONES } from "./timezones.actionTypes";

export const setTimezones = (data) => (dispatch) => {
  dispatch({
    type: SET_TIMEZONES,
    payload: data,
  });
};

export const fetchTimezones = () => async (dispatch) => {
  try {
    const { data } = await timezonesApi.getTimezones();
    dispatch(setTimezones(data));
  } catch (error) {
    handleError(error, dispatch);
  }
};
