import { STATUS } from "@/utils/constants";

import {
  SET_IS_FETCHING_SUMMARY_STATUS,
  SET_IS_UPDATING_TIME_ENTRY_STATUS,
  SET_SUMMARY,
  SET_INDIVIDUAL_SUMMARY,
  SET_FROM_DATE,
  SET_TO_DATE,
  SET_DATES,
  SET_SELECTED_USER_IDS,
  SET_SELECTED_TIME_ENTRY_UIDS,
  SET_MODAL_TYPE,
  SET_ACTIVE_USER,
  SET_IS_FETCHING_TEAM_MEMBERS_STATUS,
  SET_TEAM_MEMBERS,
  SET_IS_DELETING_STATUS,
  SET_EXPANDED_DATES,
  SET_ACTIVE_TIME_ENTRY,
  RESET,
} from "./individualTimesheets.actionTypes";

const initialState = {
  isFetchingSummaryStatus: STATUS.IDLE,
  individualSummary: null,
  fromDate: null,
  toDate: null,
  dates: [],
  isUpdatingTimeEntryStatus: {},
  selectedUserIds: [],
  activeUser: null,
  selectedTimeEntryUids: [],
  isFetchingTeamMembersStatus: STATUS.IDLE,
  teams: [],
  isDeletingStatus: STATUS.IDLE,
  expandedDates: [],
  activeTimeEntry: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SUMMARY: {
      return { ...state, summary: action.payload };
    }

    case SET_INDIVIDUAL_SUMMARY: {
      return { ...state, individualSummary: action.payload };
    }

    case SET_IS_FETCHING_SUMMARY_STATUS: {
      return { ...state, isFetchingSummaryStatus: action.payload };
    }

    case SET_FROM_DATE: {
      return { ...state, fromDate: action.payload };
    }

    case SET_TO_DATE: {
      return { ...state, toDate: action.payload };
    }

    case SET_DATES: {
      return { ...state, dates: action.payload };
    }

    case SET_IS_UPDATING_TIME_ENTRY_STATUS: {
      return {
        ...state,
        isUpdatingTimeEntryStatus: { ...state.isUpdatingTimeEntryStatus, ...action.payload },
      };
    }

    case SET_SELECTED_USER_IDS: {
      return { ...state, selectedUserIds: action.payload };
    }

    case SET_MODAL_TYPE: {
      return { ...state, modalType: action.payload };
    }

    case SET_ACTIVE_USER: {
      return { ...state, activeUser: action.payload };
    }

    case SET_SELECTED_TIME_ENTRY_UIDS: {
      return {
        ...state,
        selectedTimeEntryUids: action.payload,
      };
    }

    case SET_IS_FETCHING_TEAM_MEMBERS_STATUS: {
      return { ...state, isFetchingTeamMembersStatus: action.payload };
    }

    case SET_TEAM_MEMBERS: {
      return { ...state, teams: action.payload };
    }

    case SET_IS_DELETING_STATUS: {
      return { ...state, isDeletingStatus: action.payload };
    }

    case SET_EXPANDED_DATES: {
      return { ...state, expandedDates: action.payload };
    }

    case SET_ACTIVE_TIME_ENTRY: {
      return { ...state, activeTimeEntry: action.payload };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
