import { MODULE } from "./collaboration.actionTypes";

export const socketInstance = (namespace, state) =>
  state.getIn([`${namespace}/${MODULE}`, "socketInstance"]);

export const reference = (namespace, state) => state.getIn([`${namespace}/${MODULE}`, "reference"]);

export const userId = (namespace, state) => state.getIn([`${namespace}/${MODULE}`, "userId"]);

export const allMessages = (namespace, state) =>
  state.getIn([`${namespace}/${MODULE}`, "allMessages"]);

export const attachedFilesEditor = (namespace, state) =>
  state.getIn([`${namespace}/${MODULE}`, "attachedFilesEditor"]);

export const allChatsNotifications = (namespace, state) =>
  state.getIn([`${namespace}/${MODULE}`, "allChatsNotifications"]);

export const allCompanyUsers = (namespace, state) =>
  state.getIn([`${namespace}/${MODULE}`, "allCompanyUsers"]);

export const toEditMessage = (namespace, state) =>
  state.getIn([`${namespace}/${MODULE}`, "toEditMessage"]);

export const isUploadFile = (namespace, state) =>
  state.getIn([`${namespace}/${MODULE}`, "isUploadFile"]);

export const isPending = (namespace, state) => state.getIn([`${namespace}/${MODULE}`, "isPending"]);

export const attachmentFiles = (namespace, state) =>
  state.getIn([`${namespace}/${MODULE}`, "attachmentFiles"]);
