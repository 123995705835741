import { createApiV2 } from "./common";

export const getSettings = () => (token) => createApiV2("GET", "workflow/")(token);

export const postSettings = (data) => (token) => createApiV2("POST", "workflow/")(token, data);

export const getStatus = () => (token) => createApiV2("GET", "status/")(token);

export const postStatus = (data) => (token) => createApiV2("POST", "status/")(token, data);

export const getColumnsOrders = (data) => (token) =>
  createApiV2("POST", "workflow/orders/search")(token, data);

export const postMoveColumnOrder = (data) => (token) =>
  createApiV2("POST", "workflow/move_column/")(token, data);

export const postMoveCardOrder = (data) => (token) =>
  createApiV2("POST", "workflow/move_order/")(token, data);

export const postMarkOrder = (data) => (token) =>
  createApiV2("POST", "workflow/change_order/")(token, data);
