import { SET_SEARCH, RESET } from "./searchFilter.actionTypes";

const initialState = {
  search: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH: {
      return { ...state, search: action.payload };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
