import React from "react";

import GoogleTagManager from "./GoogleTagManager";
import Intercom from "./Intercom";

const Widgets = () => {
  if (process.env.ENV === "localhost") return null;

  return (
    <>
      <GoogleTagManager />
      <Intercom />
    </>
  );
};

export default Widgets;
