import { MODULE } from "./purchaseOrderTimeline.actionTypes";

export const isFetchingCategories = (state) => state.getIn([MODULE, "isFetchingCategories"]);

export const getSearch = (state) => state.getIn([MODULE, "search"]);

export const getFilters = (state) => state.getIn([MODULE, "filters"]);

export const isFetchingPurchaseOrderTimelines = (state) => state.getIn([MODULE, "isFetchingPurchaseOrderTimelines"]);

export const isFetchingPurchaseOrderTimelineDetails = (state) => state.getIn([MODULE, "isFetchingPurchaseOrderTimelineDetails"]);

export const getPurchaseOrderTimelines = (state) => state.getIn([MODULE, "purchaseOrderTimelines"]);

export const getPurchaseOrderTimelineDetails = (state) => state.getIn([MODULE, "purchaseOrderTimelineDetails"]);
