/*eslint-disable */
const apiVersion = `v${1}`;

const SENTRY_DSN = process.env.SENTRY_DSN;
const ROOT_URL = process.env.ROOT_URL;
const ROOT_URL_SOCKET = process.env.ROOT_URL_SOCKET;
const API_GATEWAY_URL = process.env.API_GATEWAY_URL;
const FUSE_ORGANIZATION_API_KEY = process.env.FUSE_ORGANIZATION_API_KEY;
const FUSE_ORDER_IMPORTER_TEMPLATE_ID = process.env.FUSE_ORDER_IMPORTER_TEMPLATE_ID;

const tagManagerArgs = {
  gtmId: process.env.GOOGLE_TAG_MANAGER_ID || "",
};

const intercomSettings = {
  app_id: process.env.INTERCOM_APP_ID || "",
};

const API_URL = `${ROOT_URL}/api/${apiVersion}/`;
const SOCKET_URL = `${ROOT_URL_SOCKET}/ws/`;

const oauth2 = {
  client_id: process.env.CLIENT_ID,
  client_secret: process.env.CLIENT_SECRET,
};

export default API_URL;

export {
  SENTRY_DSN,
  ROOT_URL,
  ROOT_URL_SOCKET,
  API_URL,
  SOCKET_URL,
  API_GATEWAY_URL,
  oauth2,
  tagManagerArgs,
  intercomSettings,
  FUSE_ORGANIZATION_API_KEY,
  FUSE_ORDER_IMPORTER_TEMPLATE_ID,
};

/* eslint-enable */
