import createActionType from "@/redux/utils/createActionType";

export const MODULE = "timesheets/pinConfirmation";

export const RESET = createActionType(MODULE, "reset");

export const SET_IS_SAVING_STATUS = createActionType(MODULE, "setIsSavingStatus");

export const SET_IS_FETCHING_TEAM_MEMBERS_STATUS = createActionType(
  MODULE,
  "setIsFetchingTeamMembersStatus"
);

export const SET_TEAM_MEMBERS = createActionType(MODULE, "setTeamMembers");
