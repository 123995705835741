import { GET_ALERTS, CREATE_ALERT, DELETE_ALERT } from "./alerts.actionTypes";
import produce from "immer";
import setKeypathValue from "../utils/setKeypathValue";

const initialState = {
  alerts: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    // CREATE ALERT //
    case CREATE_ALERT: {
      const { id, message, autoclose, type, buttonText, callback } =
        action.payload;
      const newAlert = {
        id,
        message,
        autoclose,
        type,
        buttonText,
        callback,
      };

      return {
        ...state,
        alerts: [...state.alerts, newAlert],
      };
    }

    // DELETE ALERT //
    case DELETE_ALERT: {
      const { id } = action.payload;
      const newAlerts = [...state.alerts].filter((alert) => alert.id !== id);

      return {
        ...state,
        alerts: newAlerts,
      };
    }

    default:
      return state;
  }
};
