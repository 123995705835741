import createActionType from "../utils/createActionType";

export const MODULE = "productKits";

export const SET_SEARCH = createActionType(MODULE, "setSearch");

export const SET_IS_FETCHING_CATEGORIES = createActionType(MODULE, "setIsFetchingCategories");

export const SET_IS_FETCHING_PRODUCT_KIT = createActionType(MODULE, "setIsFetchingProductKit");

export const SET_IS_SAVING = createActionType(MODULE, "setIsSaving");

export const SET_CATEGORIES = createActionType(MODULE, "setCategories");

export const SET_PRODUCTS = createActionType(MODULE, "setProducts");

export const SET_EMPTY_PRODUCTS = createActionType(MODULE, "setEmptyProducts");

export const SET_EXPANDED_CATEGORIES = createActionType(MODULE, "setExpandedCategories");

export const SET_EXPANDED_PRODUCTS = createActionType(MODULE, "setExpandedProducts");

export const SET_EXPANDED_SUB_KIT_PRODUCTS = createActionType(MODULE, "setExpandedSubKitProducts");

export const SET_IS_FETCHING_PRODUCTS = createActionType(MODULE, "setIsFetchingProducts");

export const SET_INVENTORY = createActionType(MODULE, "setInventory");

export const SET_SEARCH_PRODUCTS = createActionType(MODULE, "setSearchProducts");

export const SET_SELECTED_PRICE_LEVELS = createActionType(MODULE, "setSelectedPriceLevels");

export const SET_ERROR_IN_CUSTOM_FORMULA = createActionType(MODULE, "setErrorInCustomFormula");

export const SET_ERROR_IN_SUB_KIT_CUSTOM_FORMULA = createActionType(
  MODULE,
  "setErrorInSubKitCustomFormula"
);

export const ADD_PRODUCT_TO_CART = createActionType(MODULE, "addProductToCart");

export const ADD_LABOUR_ITEMS = createActionType(MODULE, "addLabourItems");

export const ADD_LABOUR_ITEM_TO_SUBKIT = createActionType(MODULE, "addLabourLineItemToSubkit");

export const UPDATE_LABOUR_ITEM = createActionType(MODULE, "updateLabourItem");

export const UPDATE_LABOUR_ITEM_IN_SUBKIT = createActionType(
  MODULE,
  "updateLabourLineItemInSubKit"
);

export const ADD_PRODUCT_TO_SUB_KIT_CART = createActionType(MODULE, "addProductToSubKitsCart");

export const ADD_EMPTY_SUB_KIT = createActionType(MODULE, "addEmptySubKit");

export const ADD_SUB_KIT_FROM_KIT = createActionType(MODULE, "addSubKitFromKit");

export const UPDATE_CART_QUANTITY = createActionType(MODULE, "updateCartQuantity");

export const UPDATE_SUB_KIT_CART_QUANTITY = createActionType(MODULE, "updateSubKitCartQuantity");

export const UPDATE_CART_COLOUR = createActionType(MODULE, "updateCartColour");

export const UPDATE_SUB_KIT_CART_COLOUR = createActionType(MODULE, "updateSubKitCartColour");

export const UPDATE_CART_PRICE = createActionType(MODULE, "updateCartPrice");

export const UPDATE_SUB_KIT_CART_PRICE = createActionType(MODULE, "updateSubKitCartPrice");

export const UPDATE_CART = createActionType(MODULE, "updateCart");

export const UPDATE_SUB_KIT_CART = createActionType(MODULE, "updateSubKitCart");

export const UPDATE_CART_ATTRIBUTES = createActionType(MODULE, "updateCartAttributes");

export const UPDATE_SUB_KIT_CART_ATTRIBUTES = createActionType(
  MODULE,
  "updateSubKitCartAttributes"
);

export const DELETE_CART_ATTRIBUTES = createActionType(MODULE, "deleteCartAttributes");

export const DELETE_SUB_KIT_CART_ATTRIBUTES = createActionType(
  MODULE,
  "deleteSubKitCartAttributes"
);

export const DELETE_CART_ITEM = createActionType(MODULE, "deleteCartItem");

export const DELETE_SUB_KIT_CART_ITEM = createActionType(MODULE, "deleteSubKitCartItem");

export const ADD_KIT_LENGTH = createActionType(MODULE, "addKitLength");

export const DELETE_KIT_LENGTH = createActionType(MODULE, "deleteKitLength");

export const ADD_KIT_DIMENSION = createActionType(MODULE, "addKitDimension");

export const DELETE_KIT_DIMENSION = createActionType(MODULE, "deleteKitDimension");

export const ADD_SUB_KIT_LENGTH = createActionType(MODULE, "addSubKitLength");

export const DELETE_SUB_KIT_LENGTH = createActionType(MODULE, "deleteSubKitLength");

export const ADD_SUB_KIT_DIMENSION = createActionType(MODULE, "addSubKitDimension");

export const DELETE_SUB_KIT_DIMENSION = createActionType(MODULE, "deleteSubKitDimension");

export const DELETE_SUB_KIT = createActionType(MODULE, "deleteSubKit");

export const CREATE_PRODUCT_KIT_FROM_CART = createActionType(MODULE, "createProductKitFromCart");

export const CREATE_PRODUCT_KIT_FROM_LABOUR_LINE_ITEMS_CART = createActionType(
  MODULE,
  "createProductKitFromLabourLineItemsCart"
);

export const CREATE_SUB_KIT_FROM_LABOUR_LINE_ITEMS_CART = createActionType(
  MODULE,
  "createSubKitFromLabourLineItemsCart"
);

export const UPDATE_CURRENT_PRODUCT_KIT = createActionType(MODULE, "updateCurrentProductKit");

export const UPDATE_SUB_KIT_TITLE = createActionType(MODULE, "updateSubKitTitle");

export const UPDATE_PRODUCT_KIT_PRICING_TABLE = createActionType(
  MODULE,
  "updateProductKitPricingTable"
);

export const TOGGLE_PRODUCT_KIT_MODAL = createActionType(MODULE, "toggleProductKitModal");

export const SET_TARGET_SUB_KIT_INDEX = createActionType(MODULE, "setTargetSubKitIndex");

export const SET_IS_SUB_KIT_MODAL_OPEN = createActionType(MODULE, "setIsSubKitModalOpen");

export const TOGGLE_CART = createActionType(MODULE, "toggleCart");

export const POST_PRODUCT_KIT = createActionType(MODULE, "postProductKit");

export const SET_PRODUCT_KIT = createActionType(MODULE, "setProductKit");

export const SELECT_COLOUR = createActionType(MODULE, "selectColour");

export const SET_KIT_LABOUR_LINE_ITEMS = createActionType(MODULE, "setKitLabourLineItems");

export const RESET_PRODUCT_KIT = createActionType(MODULE, "resetProductKit");

export const RESET_EXPANDED_PRODUCTS = createActionType(MODULE, "resetExpandedProducts");

export const RESET_EXPANDED_SUB_KIT_PRODUCTS = createActionType(
  MODULE,
  "resetExpandedSubKitProducts"
);

export const RESET = createActionType(MODULE, "reset");
