import { SET_REQUIRED_START_DATE, SET_REQUIRED_END_DATE, RESET } from "./requiredDate.actionTypes";

const initialState = {
  requiredStartDate: null,
  requiredEndDate: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_REQUIRED_START_DATE: {
      return { ...state, requiredStartDate: action.payload };
    }

    case SET_REQUIRED_END_DATE: {
      return { ...state, requiredEndDate: action.payload };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
