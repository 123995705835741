import {
  SET_SETTINGS,
  SET_IS_FETCHING_SETTINGS,
  SET_COLUMNS,
  SET_SELECTED_COLUMNS,
  SET_IS_FETCHING_COLUMNS,
  SET_CARDS,
  SET_IS_FETCHING_CARDS,
  SET_CARDS_IN_COLUMN,
  SET_ACTIVE_VIEW,
  SET_IS_ORDER_MODAL_OPEN,
  RESET,
} from "./workflow.actionTypes";

const initialState = {
  isFetchingSettings: false,
  isFetchingColumns: false,
  isFetchingCards: false,
  isOrderModalOpen: false,
  settings: {
    enableEmailNotifications: false,
    enableSmsNotifications: false,
    notifyWhenDelivered: false,
    replyTo: null,
    deliveryColor: "#20b2aa",
    pickupColor: "#6a5acd",
    reservedNames: [],
  },
  columns: [],
  selectedColumns: [],
  cards: {},
  activeView: "status",
  currentFilter: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SETTINGS: {
      return { ...state, settings: { ...state.settings, ...action.payload } };
    }

    case SET_IS_FETCHING_SETTINGS: {
      return { ...state, isFetchingSettings: action.payload };
    }

    case SET_COLUMNS: {
      return { ...state, columns: action.payload };
    }

    case SET_SELECTED_COLUMNS: {
      return { ...state, selectedColumns: action.payload };
    }

    case SET_IS_FETCHING_COLUMNS: {
      return { ...state, isFetchingColumns: action.payload };
    }

    case SET_CARDS: {
      return { ...state, cards: action.payload };
    }

    case SET_IS_FETCHING_CARDS: {
      return { ...state, isFetchingCards: action.payload };
    }

    case SET_CARDS_IN_COLUMN: {
      const { data, columnId } = action.payload;
      return {
        ...state,
        cards: {
          ...state.cards,
          [columnId]: data,
        },
      };
    }

    case SET_ACTIVE_VIEW: {
      return {
        ...state,
        activeView: action.payload,
      };
    }

    case SET_IS_ORDER_MODAL_OPEN: {
      return {
        ...state,
        isOrderModalOpen: action.payload,
      };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
