import { fromJS } from "immutable";
import camelcaseKeys from "camelcase-keys";
import Decimal from "decimal.js-light";
import { round } from "lodash";

import * as priceApi from "../../api/prices";

import ReduxModule from "./abstract/ReduxModule";
import { withMutations } from "./helpers/reducerWrappers";
import * as helpers from "./helpers/price";
import { getMinLengthProducts, getCompanyTaxRate } from "./helpers/product";

Decimal.config({ rounding: Decimal.ROUND_HALF_UP });

const ACTIONS = {
  CLEAR_ACTIVE_ORDER: "Clear active order",
  RESET_DRAWINGS: "Reset drawings",
  SET_DATA_AFTER_SAVING_DRAWINGS: "Set data after saving drawings",
  CREATE_NEW_ORDER: "Create new order",
  SET_UIDS: "Set uids",
  OPEN_ORDER: "Load data from order",

  ADD_NEW_DRAWING: "Add new drawing",
  DUPLICATE_DRAWING: "Duplicate drawing",
  SET_ACTIVE_DRAWING_TEMP_UID: "Set active drawing temp uid",
  DELETE_DRAWING: "Delete drawing",
  UNDO_DELETE_DRAWING: "Undo delete drawing",

  GET_PRICES_BY_PRODUCT: "Get prices by product",
  SET_PRICE_PER_METER_DRAWING: "Set price per meter drawing",
  SET_TOTAL_PRICE_DRAWING: "Set total price drawing",
  SET_ADDITIONAL_PRICES: "Set additional prices",
  SET_DISCOUNT_PRICES: "Set discount prices",
  SET_MARKUP_PRICES: "Set markup prices",
  UPDATE_PRICES_ORDER: "Update prices order",
  CALC_PRICE_ADDITIONAL_PRODUCTS: "Calc price additional products",
  CALC_PRICE_DRAWINGS: "Calc price drawings",

  SWITCH_CUSTOM_PRICE: "Switch custom price",
  SWTICH_CUSTOM_PRICE_PER_METER: "Switch custom price per meter",
  CHANGE_DRAWING_PRICE: "Change drawing price",
};

class AuthModule extends ReduxModule {
  getNamespace() {
    return "[Prices]";
  }

  getInitialState() {
    return {
      activeDrawingPrices: null,
      startStateDrawings: null, // items: {}
      startStateOrder: null, // orderPrices: {}
      items: {}, // [drawingTempUid]: this.initialDrawingPricesState()
      orderPrices: fromJS(this.initialOrderPricesState()),
      activeDrawingUid: null,
      isChangedPrice: false,
      dataServer: {
        isPending: false,
        items: [],
      },
    };
  }

  initialDrawingPricesState() {
    return {
      isDeleted: false,
      pricePerMeter: "0.00",
      totalPrice: "0.00",
      customPrice: null,
      customPricePerMeter: null,
      data: [], // dataServer.items
    };
  }

  initialOrderPricesState() {
    return {
      additionalPrices: [],
      discountPrices: [],
      markupPrices: [],

      labourAdditionalProducts: "0.00",
      sumAdditionalPrices: "0.00",
      sumDiscountPercent: "0.00",
      sumAdditionalProducts: "0.00",
      sumDrawingsPrices: "0.00",
      sumDrawingsCustomPrices: "0.00",
      gstAdditionalProducts: "0.00",
      costAdditionalProducts: "0.00",
      sumMarkupAdditionalProducts: "0.00",
      gstDrawingsPrices: "0.00",

      labourTotalPrice: "0.00",
      subTotal: "0.00",
      margin: "0.00",
      marginPercentage: "0",
      GST: "0.00",
      totalPrice: "0.00",
    };
  }

  generateOrderDataFromServer(
    drawingsData,
    drawingsItems,
    additionalProductsItems,
    orderPrices,
    minLengthProducts,
    taxRate
  ) {
    const items = {};
    const prices = {};

    drawingsData.forEach((drawing) => {
      const drawingUid = drawing.uid;

      drawingsItems.forEach((item) => {
        if (item.drawing === drawingUid) {
          if (prices[drawingUid]) {
            prices[drawingUid].totalPrice =
              parseFloat(item.total_value) + prices[drawingUid].totalPrice;

            prices[drawingUid].customPrice =
              item.custom_value !== null
                ? parseFloat(item.custom_value) + prices[drawingUid].customPrice
                : item.custom_value;

            prices[drawingUid].customPricePerMeter = item.custom_value_lm
              ? parseFloat(item.custom_value_lm)
              : null;
          } else {
            prices[drawingUid] = {};

            prices[drawingUid].totalPrice = item.total_value
              ? parseFloat(item.total_value)
              : "0.00";

            prices[drawingUid].customPrice = item.custom_value
              ? parseFloat(item.custom_value)
              : null;

            prices[drawingUid].customPricePerMeter = item.custom_value_lm
              ? parseFloat(item.custom_value_lm)
              : null;
          }

          const { totalPrice, customPrice, customPricePerMeter } = prices[drawingUid];

          items[drawingUid] = {
            pricePerMeter: item.value,
            totalPrice: round(totalPrice, 2).toFixed(2),
            customPrice: customPrice !== null ? round(customPrice, 2).toFixed(2) : null,
            customPricePerMeter:
              customPricePerMeter !== null ? round(customPricePerMeter, 2).toFixed(2) : null,
            data: [],
          };
        }
      });

      if (!items[drawingUid]) {
        items[drawingUid] = this.initialDrawingPricesState();
      }
    });

    const updatedOrderPrices = { ...orderPrices };

    if (orderPrices.additionalPrices) {
      updatedOrderPrices.sumAdditionalPrices = round(
        orderPrices.additionalPrices.reduce((sum, item) => sum + parseFloat(item.value), 0),
        2
      ).toFixed(2);
    }

    if (orderPrices.discountPrices) {
      updatedOrderPrices.sumDiscountPercent = round(
        orderPrices.discountPrices.reduce((sum, item) => sum + parseFloat(item.percent), 0),
        2
      ).toFixed(2);
    }

    const {
      labourAdditionalProducts,
      sumAdditionalProducts,
      gstAdditionalProducts,
      costAdditionalProducts,
      sumMarkupAdditionalProducts,
    } = helpers.calcPriceAdditionalProducts(
      additionalProductsItems,
      minLengthProducts,
      updatedOrderPrices.sumDiscountPercent,
      taxRate
    );

    updatedOrderPrices.labourAdditionalProducts = labourAdditionalProducts;
    updatedOrderPrices.sumAdditionalProducts = sumAdditionalProducts;
    updatedOrderPrices.gstAdditionalProducts = gstAdditionalProducts;
    updatedOrderPrices.costAdditionalProducts = costAdditionalProducts;
    updatedOrderPrices.sumMarkupAdditionalProducts = sumMarkupAdditionalProducts;

    const { sumDrawingsPrices, sumDrawingsCustomPrices, gstDrawingsPrices } =
      helpers.calcPricesDrawings(items, updatedOrderPrices.sumDiscountPercent);
    updatedOrderPrices.sumDrawingsPrices = sumDrawingsPrices;
    updatedOrderPrices.sumDrawingsCustomPrices = sumDrawingsCustomPrices;
    updatedOrderPrices.gstDrawingsPrices = gstDrawingsPrices;

    return {
      items,
      orderPrices: updatedOrderPrices,
    };
  }

  createNewOrder = (_state) => {
    _state.merge(fromJS(this.getInitialState()));
  };

  setUids = (_state, { additionalPrices, discountPrices, markupPrices }) => {
    _state.getIn(["orderPrices", "additionalPrices"]).forEach((price, i) => {
      _state.setIn(["orderPrices", "additionalPrices", i, "uid"], additionalPrices[i].uid);
    });

    _state.getIn(["orderPrices", "discountPrices"]).forEach((price, i) => {
      _state.setIn(["orderPrices", "discountPrices", i, "uid"], discountPrices[i].uid);
    });

    _state.getIn(["orderPrices", "markupPrices"]).forEach((price, i) => {
      _state.setIn(["orderPrices", "markupPrices", i, "uid"], markupPrices[i].uid);
    });
  };

  openOrder = (_state, data) => {
    _state.merge(fromJS(this.getInitialState()));

    let { items, orderPrices } = data;
    items = fromJS(items);
    orderPrices = fromJS(orderPrices);

    _state.set("items", items);
    _state.set("startStateDrawings", items);

    const startStateOrder = _state.mergeIn(["orderPrices"], orderPrices);
    _state.set("startStateOrder", startStateOrder.get("orderPrices"));
  };

  getPricesProductsThunk = ({ token, fulfilled, getState, dispatch }, product) => {
    const { productType, name, color, thickness, priceLevel } = product || {};

    const activeDrawing = getState().getIn(["drawings", "activeDrawing"]);

    return priceApi
      .getPrice(token, {
        name,
        product_type: productType,
        color,
        thickness,
        price_level: priceLevel,
      })
      .then((response) => {
        fulfilled(response);
        dispatch(this.actions.setPricesDrawing(activeDrawing));
        return response.data;
      });
  };

  setPricePerMeterDrawingThunk = ({ dispatch, getState, fulfilled }, newDrawing) => {
    const drawing = newDrawing || getState().getIn(["drawings", "activeDrawing"]);

    let drawingUid;

    if (drawing) {
      const numberOfBends = drawing.get("totalBends");
      const totalGirth = drawing.get("totalGirth");
      const tapers = drawing.get("tapers");

      drawingUid = drawing.get("tempUid");

      fulfilled({
        numberOfBends,
        totalGirth,
        drawingUid,
        tapers,
      });
    }

    dispatch(this.actions.setTotalPriceDrawing(drawingUid));
  };

  switchCustomPriceThunk = ({ dispatch, getState, fulfilled }, customPrice) => {
    fulfilled(customPrice);
    const drawingUid = getState().getIn(["drawings", "activeDrawing", "tempUid"]);
    dispatch(this.actions.changeDrawingPrice(true));
    dispatch(this.actions.setTotalPriceDrawing(drawingUid));
  };

  switchCustomPricePerMeterThunk = ({ dispatch, getState, fulfilled }, customPricePerMeter) => {
    fulfilled(customPricePerMeter);
    const drawingUid = getState().getIn(["drawings", "activeDrawing", "tempUid"]);
    dispatch(this.actions.changeDrawingPrice(true));
    dispatch(this.actions.setTotalPriceDrawing(drawingUid));
  };

  setTotalPriceDrawingThunk = ({ dispatch, getState, fulfilled }, drawingUid) => {
    const state = getState();
    const companyTaxRate = getCompanyTaxRate(state);

    if (drawingUid) {
      const totalLength = getState().getIn([
        "drawingsItems",
        "itemsLength",
        drawingUid,
        "forPrice",
      ]);
      fulfilled({ totalLength, drawingUid });
    }

    dispatch(this.actions.calcPricesDrawings());
    dispatch(this.actions.updatePricesOrder(companyTaxRate));
  };

  setAdditionalPricesThunk = ({ dispatch, getState, fulfilled }, additionalPrices) => {
    const state = getState();
    const companyTaxRate = getCompanyTaxRate(state);
    fulfilled(additionalPrices);
    dispatch(this.actions.updatePricesOrder(companyTaxRate));
  };

  setDiscountPricesThunk = ({ dispatch, fulfilled }, discountPrices) => {
    fulfilled(discountPrices);
    dispatch(this.actions.calcPricesDrawings());
    dispatch(this.actions.calcPriceAdditionalProducts());
  };

  setMarkupPricesThunk = ({ dispatch, fulfilled }, markupPrices) => {
    fulfilled(markupPrices);
    dispatch(this.actions.calcPriceAdditionalProducts());
  };

  calcPriceAdditionalProductsThunk = ({ dispatch, getState, fulfilled }) => {
    const state = getState();
    const items = state.getIn(["additionalProducts", "items"]).toJS();
    const minLengthProducts = getMinLengthProducts(state);
    const companyTaxRate = getCompanyTaxRate(state);

    const {
      labourAdditionalProducts,
      sumAdditionalProducts,
      gstAdditionalProducts,
      costAdditionalProducts,
      sumMarkupAdditionalProducts,
    } = helpers.calcPriceAdditionalProducts(items, minLengthProducts, "0", companyTaxRate);

    fulfilled({
      labourAdditionalProducts,
      sumAdditionalProducts,
      gstAdditionalProducts,
      costAdditionalProducts,
      sumMarkupAdditionalProducts,
    });

    dispatch(this.actions.updatePricesOrder(companyTaxRate));
  };

  defineActions() {
    const clearActiveOrder = this.resetToInitialState(ACTIONS.CLEAR_ACTIVE_ORDER);
    const createNewOrder = this.createAction(ACTIONS.CREATE_NEW_ORDER);
    const setUids = this.createAction(ACTIONS.SET_UIDS);
    const openOrder = this.createAction(ACTIONS.OPEN_ORDER);
    const updatePricesOrder = this.createAction(ACTIONS.UPDATE_PRICES_ORDER);

    const resetDrawings = this.createAction(ACTIONS.RESET_DRAWINGS);
    const setDataAfterSavingDrawings = this.createAction(ACTIONS.SET_DATA_AFTER_SAVING_DRAWINGS);
    const addNewDrawing = this.createAction(ACTIONS.ADD_NEW_DRAWING);
    const duplicateDrawing = this.createAction(ACTIONS.DUPLICATE_DRAWING);
    const setDrawingTempUid = this.createAction(ACTIONS.SET_ACTIVE_DRAWING_TEMP_UID);
    const deleteDrawing = this.createAction(ACTIONS.DELETE_DRAWING);
    const undoDeleteDrawing = this.createAction(ACTIONS.UNDO_DELETE_DRAWING);
    const changeDrawingPrice = this.createAction(ACTIONS.CHANGE_DRAWING_PRICE);

    const setPricesDrawing = this.thunkAction(
      ACTIONS.SET_PRICE_PER_METER_DRAWING,
      this.setPricePerMeterDrawingThunk
    );
    const setTotalPriceDrawing = this.thunkAction(
      ACTIONS.SET_TOTAL_PRICE_DRAWING,
      this.setTotalPriceDrawingThunk
    );
    const switchCustomPrice = this.thunkAction(
      ACTIONS.SWITCH_CUSTOM_PRICE,
      this.switchCustomPriceThunk
    );
    const switchCustomPricePerMeter = this.thunkAction(
      ACTIONS.SWTICH_CUSTOM_PRICE_PER_METER,
      this.switchCustomPricePerMeterThunk
    );
    const getPricesProducts = this.thunkAction(
      ACTIONS.GET_PRICES_BY_PRODUCT,
      this.getPricesProductsThunk
    );
    const setAdditionalPrices = this.thunkAction(
      ACTIONS.SET_ADDITIONAL_PRICES,
      this.setAdditionalPricesThunk
    );
    const setDiscountPrices = this.thunkAction(
      ACTIONS.SET_DISCOUNT_PRICES,
      this.setDiscountPricesThunk
    );
    const setMarkupPrices = this.thunkAction(ACTIONS.SET_MARKUP_PRICES, this.setMarkupPricesThunk);
    const calcPriceAdditionalProducts = this.thunkAction(
      ACTIONS.CALC_PRICE_ADDITIONAL_PRODUCTS,
      this.calcPriceAdditionalProductsThunk
    );
    const calcPricesDrawings = this.createAction(ACTIONS.CALC_PRICE_DRAWINGS);

    return {
      clearActiveOrder,
      createNewOrder,
      setUids,
      openOrder,
      updatePricesOrder,

      resetDrawings,
      setDataAfterSavingDrawings,
      addNewDrawing,
      duplicateDrawing,
      setDrawingTempUid,
      deleteDrawing,
      undoDeleteDrawing,

      setPricesDrawing,
      setTotalPriceDrawing,
      switchCustomPrice,
      switchCustomPricePerMeter,
      getPricesProducts,
      setAdditionalPrices,
      setDiscountPrices,
      setMarkupPrices,
      calcPriceAdditionalProducts,
      calcPricesDrawings,
      changeDrawingPrice,
    };
  }

  addNewDrawing = (_state, { drawingTempUid, newDrawingTempUid }) => {
    let prices = drawingTempUid ? _state.getIn(["items", drawingTempUid]) : null;

    if (prices && !prices.getIn(["isDeleted"])) {
      prices = prices.set("pricePerMeter", "0.00");
      prices = prices.set("totalPrice", "0.00");
      prices = prices.set("customPrice", null);
      prices = prices.set("customPricePerMeter", null);
    } else {
      prices = fromJS(this.initialDrawingPricesState());
    }

    _state.set("activeDrawingUid", newDrawingTempUid);

    _state.setIn(["items", newDrawingTempUid], prices);
    _state.set("activeDrawingPrices", prices);
  };

  duplicateDrawing = (_state, { drawingTempUid, newDrawingTempUid }) => {
    const items = _state.getIn(["items", drawingTempUid]);
    _state.setIn(["items", newDrawingTempUid], items);
    _state.setIn(["dataServer", "items"], items.get("data"));
  };

  setPricePerMetreDrawing = (_state, drawingOptions) => {
    const { numberOfBends, totalGirth, drawingUid, tapers } = drawingOptions;

    const value = helpers.findPricePerMetreDrawing({
      numberOfBends,
      totalGirth,
      tapers,
      dataServer: _state.getIn(["dataServer", "items"]),
    });
    _state.setIn(["items", drawingUid, "pricePerMeter"], value);
  };

  setTotalPriceDrawing = (_state, { totalLength, drawingUid }) => {
    const pricePerMeter =
      parseFloat(_state.getIn(["items", drawingUid, "customPricePerMeter"])) >= 0
        ? parseFloat(_state.getIn(["items", drawingUid, "customPricePerMeter"]))
        : _state.getIn(["items", drawingUid, "pricePerMeter"]);
    const totalPrice = helpers.calcTotalPriceDrawing({
      totalLength,
      pricePerMeter,
    });
    _state.setIn(["items", drawingUid, "totalPrice"], totalPrice);

    const drawingPrices = _state.getIn(["items", drawingUid]);
    _state.set("activeDrawingPrices", drawingPrices);
  };

  switchCustomPrice = (_state, payload) => {
    const customPrice = payload && parseFloat(payload) >= 0 ? round(payload, 2).toFixed(2) : null;

    const activeDrawingUid = _state.get("activeDrawingUid");
    _state.setIn(["items", activeDrawingUid, "customPrice"], customPrice);
    _state.setIn(["activeDrawingPrices, customPrice"], customPrice);
  };

  switchCustomPricePerMeter = (_state, payload) => {
    const activeDrawingUid = _state.get("activeDrawingUid");

    const customPricePerMeter =
      payload && parseFloat(payload) >= 0 ? round(payload, 2).toFixed(2) : null;

    _state.setIn(["items", activeDrawingUid, "customPricePerMeter"], customPricePerMeter);
    _state.setIn(["activeDrawingPrices, customPricePerMeter"], customPricePerMeter);
  };

  updatePricesOrder = (_state, companyTaxRate) => {
    const sumAdditionalPrices = parseFloat(_state.getIn(["orderPrices", "sumAdditionalPrices"]));
    const sumAdditionalProducts = parseFloat(
      _state.getIn(["orderPrices", "sumAdditionalProducts"])
    );
    const sumDrawingsPrices = parseFloat(_state.getIn(["orderPrices", "sumDrawingsPrices"]));
    const sumDiscountPercent = parseFloat(_state.getIn(["orderPrices", "sumDiscountPercent"]));
    const labourTotal = parseFloat(_state.getIn(["orderPrices", "labourAdditionalProducts"]));
    const subTotal = helpers.calcSubTotalPrice(
      sumAdditionalPrices,
      sumAdditionalProducts,
      sumDrawingsPrices,
      sumDiscountPercent
    );
    const gstAdditionalProducts = _state.getIn(["orderPrices", "gstAdditionalProducts"]);
    const costAdditionalProducts = _state.getIn(["orderPrices", "costAdditionalProducts"]);
    const sumMarkupAdditionalProducts = _state.getIn([
      "orderPrices",
      "sumMarkupAdditionalProducts",
    ]);
    const gstDrawingsPrices = parseFloat(_state.getIn(["orderPrices", "gstDrawingsPrices"]));
    const GstSubTotal = Decimal(gstAdditionalProducts).plus(gstDrawingsPrices);
    const discountRate = Decimal(sumDiscountPercent).dividedBy(100).toDecimalPlaces(2);
    const GstSubTotalWithDiscount = Decimal(GstSubTotal)
      .times(1 - discountRate)
      .toDecimalPlaces(2);
    const sumMarkupAdditionalProductsWithDiscount = Decimal(sumMarkupAdditionalProducts)
      .times(1 - discountRate)
      .toDecimalPlaces(2);
    const margin = Decimal(sumMarkupAdditionalProductsWithDiscount).minus(costAdditionalProducts);
    const marginPercentage = sumMarkupAdditionalProductsWithDiscount.equals(0)
      ? 0
      : Decimal(margin).dividedBy(sumMarkupAdditionalProductsWithDiscount).times(100);
    const GstDeliveryFee = Decimal(sumAdditionalPrices).times(companyTaxRate).toDecimalPlaces(2);
    const GST = Decimal(GstSubTotalWithDiscount).plus(GstDeliveryFee).toDecimalPlaces(2);
    const totalPrice = subTotal.plus(GST).toDecimalPlaces(2);

    _state.mergeIn(
      ["orderPrices"],
      fromJS({
        labourTotal: labourTotal.toFixed(2),
        subTotal: subTotal.toFixed(2),
        margin: margin.toFixed(2),
        marginPercentage: marginPercentage.toFixed(2),
        GST: GST.toFixed(2),
        totalPrice: totalPrice.toFixed(2),
      })
    );
  };

  setDrawingTempUid = (_state, newDrawingTempUid) => {
    _state.set("activeDrawingUid", newDrawingTempUid);

    if (newDrawingTempUid) {
      const activeDrawingPrices = _state.getIn(["items", newDrawingTempUid]);
      _state.setIn(["dataServer", "items"], activeDrawingPrices.get("data"));
      _state.set("activeDrawingPrices", activeDrawingPrices);
    } else {
      _state.set("activeDrawingPrices", null);
    }
  };

  getPricesProducts = (_state, { data }) => {
    const activeDrawingUid = _state.get("activeDrawingUid");

    const dataFromJS = fromJS(camelcaseKeys(data, { deep: true }));
    _state.setIn(["items", activeDrawingUid, "data"], dataFromJS);
    _state.setIn(["dataServer", "items"], dataFromJS);
  };

  setAdditionalPrices = (_state, payload) => {
    _state.setIn(["orderPrices", "additionalPrices"], fromJS(payload));

    const sumAdditionalPrices = _state
      .getIn(["orderPrices", "additionalPrices"])
      .reduce((sum, price) => {
        if (!price.get("isDeleted")) {
          return sum + parseFloat(price.get("value"));
        }

        return sum;
      }, 0);
    _state.setIn(["orderPrices", "sumAdditionalPrices"], round(sumAdditionalPrices, 2).toFixed(2));
  };

  setDiscountPrices = (_state, payload) => {
    _state.setIn(["orderPrices", "discountPrices"], fromJS(payload));

    const sumDiscountPercent = _state
      .getIn(["orderPrices", "discountPrices"])
      .reduce((sum, price) => {
        if (!price.get("isDeleted")) {
          const percent = price.get("percent");

          if (percent) {
            return sum + parseFloat(percent);
          }
        }

        return sum;
      }, 0);
    _state.setIn(["orderPrices", "sumDiscountPercent"], round(sumDiscountPercent, 2).toFixed(2));
  };

  setMarkupPrices = (_state, payload) => {
    _state.setIn(["orderPrices", "markupPrices"], fromJS(payload));
  };

  calcPricesDrawings = (_state) => {
    const { sumDrawingsPrices, sumDrawingsCustomPrices, gstDrawingsPrices } =
      helpers.calcPricesDrawings(_state.get("items").toJS());
    _state.setIn(["orderPrices", "sumDrawingsPrices"], sumDrawingsPrices);
    _state.setIn(["orderPrices", "sumDrawingsCustomPrices"], sumDrawingsCustomPrices);
    _state.setIn(["orderPrices", "gstDrawingsPrices"], gstDrawingsPrices);
  };

  defineReducers() {
    return {
      [ACTIONS.CREATE_NEW_ORDER]: withMutations(this.createNewOrder),

      [ACTIONS.SET_UIDS]: withMutations(this.setUids),

      [ACTIONS.OPEN_ORDER]: withMutations(this.openOrder),

      [ACTIONS.UPDATE_PRICES_ORDER]: withMutations(this.updatePricesOrder),

      [ACTIONS.RESET_DRAWINGS]: withMutations((_state) => {
        _state.set("items", _state.get("startStateDrawings") || fromJS({}));
        _state.setIn(["dataServer", "items"], []);

        this.calcPricesDrawings(_state);
      }),

      [ACTIONS.SET_DATA_AFTER_SAVING_DRAWINGS]: (state) =>
        state.set("startStateDrawings", state.get("items")),

      [ACTIONS.ADD_NEW_DRAWING]: withMutations(this.addNewDrawing),

      [ACTIONS.DUPLICATE_DRAWING]: withMutations(this.duplicateDrawing),

      [ACTIONS.SET_ACTIVE_DRAWING_TEMP_UID]: withMutations(this.setDrawingTempUid),

      [ACTIONS.DELETE_DRAWING]: (state, { payload: { drawingTempUid, isNewDrawing } }) => {
        if (isNewDrawing) {
          return state.deleteIn(["items", drawingTempUid]);
        }

        return state.setIn(["items", drawingTempUid, "isDeleted"], true);
      },

      [ACTIONS.UNDO_DELETE_DRAWING]: (
        state,
        { payload: { drawingTempUid, items, isNewDrawing } }
      ) => {
        if (isNewDrawing) {
          return state.setIn(["items", drawingTempUid], items);
        }

        return state.setIn(["items", drawingTempUid, "isDeleted"], false);
      },

      [`${ACTIONS.SET_PRICE_PER_METER_DRAWING} fulfilled`]: withMutations(
        this.setPricePerMetreDrawing
      ),

      [`${ACTIONS.SET_TOTAL_PRICE_DRAWING} fulfilled`]: withMutations(this.setTotalPriceDrawing),

      [`${ACTIONS.SWITCH_CUSTOM_PRICE} fulfilled`]: withMutations(this.switchCustomPrice),

      [`${ACTIONS.SWTICH_CUSTOM_PRICE_PER_METER} fulfilled`]: withMutations(
        this.switchCustomPricePerMeter
      ),

      [`${ACTIONS.GET_PRICES_BY_PRODUCT} fulfilled`]: withMutations(this.getPricesProducts),

      [`${ACTIONS.GET_PRICES_BY_PRODUCT} pending`]: (state, { payload: { isPending } }) =>
        state.setIn(["dataServer", "isPending"], isPending),

      [`${ACTIONS.SET_ADDITIONAL_PRICES} fulfilled`]: withMutations(this.setAdditionalPrices),

      [`${ACTIONS.SET_DISCOUNT_PRICES} fulfilled`]: withMutations(this.setDiscountPrices),

      [`${ACTIONS.SET_MARKUP_PRICES} fulfilled`]: withMutations(this.setMarkupPrices),

      [`${ACTIONS.CALC_PRICE_ADDITIONAL_PRODUCTS} fulfilled`]: withMutations(
        (
          _state,
          {
            labourAdditionalProducts,
            sumAdditionalProducts,
            gstAdditionalProducts,
            costAdditionalProducts,
            sumMarkupAdditionalProducts,
          }
        ) => {
          _state.setIn(["orderPrices", "labourAdditionalProducts"], labourAdditionalProducts);
          _state.setIn(["orderPrices", "sumAdditionalProducts"], sumAdditionalProducts);
          _state.setIn(["orderPrices", "gstAdditionalProducts"], gstAdditionalProducts);
          _state.setIn(["orderPrices", "costAdditionalProducts"], costAdditionalProducts);
          _state.setIn(["orderPrices", "sumMarkupAdditionalProducts"], sumMarkupAdditionalProducts);
        }
      ),

      [ACTIONS.CALC_PRICE_DRAWINGS]: withMutations(this.calcPricesDrawings),

      [ACTIONS.CHANGE_DRAWING_PRICE]: (state, { payload }) =>
        state.setIn(["isChangedPrice"], payload),
    };
  }
}

const instance = new AuthModule();
instance.init();

export default instance;
