import { SEARCH_MATERIALS } from "./materials.actionTypes";

const initialState = {
  materials: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    // SEARCH MATERIALS //
    case `${SEARCH_MATERIALS}/fulfilled`: {
      const { data } = action.payload;

      return {
        ...state,
        materials: data,
      };
    }

    default:
      return state;
  }
};
