import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import moment from "moment-timezone";

import * as date from "@/utils/date";
import customers from "@/redux/modules/customers";
import * as timezonesActions from "@/redux/timezones/timezones.actions";
import * as billingActions from "@/redux/settings/billing/billing.actions";
import * as accountingActions from "@/redux/accounting/accounting.actions";
import * as authenticationActions from "@/redux/authentication/authentication.actions";
import BillingSuccessfulPaymentModal from "@/components/BillingSuccessfulPaymentModal";

const UserSession = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { replace } = useHistory();
  const query = queryString.parse(location.search);

  const getCurrentUser = async () => {
    dispatch(customers.actions.getCurrentUser()).then((userResponse) => {
      dispatch(customers.actions.getCompanyData()).then(async (response) => {
        dispatch(
          customers.actions.submitIntercomUserSettings({ user: userResponse, company: response })
        );

        const { accounting_sync_allowed: accountingSyncAllowed, accounting_name: accountingName } =
          response;

        if (accountingSyncAllowed) {
          if (accountingName === "MYOB") {
            const allowFactoryMapToJobs =
              response?.data?.settings?.accounting_settings?.accounting.find(
                (a) => a.key === "ACCOUNTING_MAP_ITEMS_TO_JOBS"
              )?.isSelected ?? false;

            if (allowFactoryMapToJobs) {
              dispatch(accountingActions.fetchMyobJobs());
            }
          } else if (accountingName === "Xero") {
            const xeroConnectionStatus = await dispatch(
              accountingActions.fetchXeroConnectionStatus()
            );

            if (!xeroConnectionStatus.isConnected) {
              dispatch(customers.actions.clearAccountingIntegration());
              dispatch(customers.actions.disconnectXero());
            }
          }
        } else if (!accountingSyncAllowed) {
          if (userResponse.auth_provider === "xero" && response.accounting_company_id !== null) {
            const xeroConnectionStatus = await dispatch(
              accountingActions.fetchXeroConnectionStatus()
            );

            if (!xeroConnectionStatus.isConnected) {
              dispatch(customers.actions.clearAccountingIntegration());
              dispatch(customers.actions.disconnectXero());
            }
          }
        }

        if (!response.is_legacy && response?.subscription_status?.name === "canceled") {
          if (accountingSyncAllowed && accountingName === "Xero") {
            dispatch(customers.actions.clearAccountingIntegration());
            dispatch(customers.actions.disconnectXero());
          } else if (!accountingSyncAllowed || accountingName !== "Xero") {
            if (userResponse.auth_provider === "xero" && response.accounting_company_id !== null) {
              dispatch(customers.actions.clearAccountingIntegration());
              dispatch(customers.actions.disconnectXero());
            }
          }
        }

        dispatch(authenticationActions.setIsCheckingAuth(false));
      });
    });
  };

  useEffect(() => {
    (async () => {
      dispatch(authenticationActions.setIsCheckingAuth(true));

      dispatch(timezonesActions.fetchTimezones());

      // This for payment from stripe
      // when stripe goes back to the factory page
      if (query.session_id) {
        await dispatch(
          billingActions.checkoutSuccess({
            session_id: query.session_id,
          })
        );

        replace("/settings?tab=billing");
      }

      await dispatch(authenticationActions.checkAuth());

      if (!query.token) {
        await getCurrentUser();
      } else {
        dispatch(authenticationActions.setIsCheckingAuth(false));
      }
    })();
  }, []);

  return (
    <>
      <BillingSuccessfulPaymentModal />
    </>
  );
};

export default UserSession;
