import { handleError } from "@/redux/utils/error";
import * as api from "@/apiv2/deliveryScheduling";

import * as orderActions from "../orders/orders.actions";
import * as orderSelectors from "../orders/orders.selectors";

import * as selectors from "./deliveryScheduling.selectors";
import {
  SET_IS_FETCHING_SETTINGS,
  SET_SETTINGS,
  SET_IS_DELIVERY_SETTINGS_VISIBLE,
  SET_IS_DELETE_VEHICLE_MODAL_VISIBLE,
  SET_IS_DELIVERY_RUNSHEET_VISIBLE,
  SET_IS_ASSIGN_LABEL_MENU_VISIBLE,
  SET_IS_ASSIGN_USER_MENU_VISIBLE,
  SET_ASSIGN_USER_DROPDOWN_COORDINATES,
  SET_ASSIGN_LABEL_DROPDOWN_COORDINATES,
  SET_ACTIVE_ORDER,
  SET_IS_ORDER_MODAL_VISIBLE,
  SET_SELECTED_RESOURCES,
  SET_SELECTED_VEHICLE,
  SET_IS_ORDER_COLUMN_COLLAPSE,
  RESET,
} from "./deliveryScheduling.actionTypes";

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setIsAssignUserMenuVisible = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_ASSIGN_USER_MENU_VISIBLE,
    payload: status,
  });
};

export const setIsAssignLabelMenuVisible = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_ASSIGN_LABEL_MENU_VISIBLE,
    payload: status,
  });
};

export const setAssignUserDropdownCoordinates = (data) => (dispatch) => {
  dispatch({
    type: SET_ASSIGN_USER_DROPDOWN_COORDINATES,
    payload: data,
  });
};

export const setAssignLabelDropdownCoordinates = (data) => (dispatch) => {
  dispatch({
    type: SET_ASSIGN_LABEL_DROPDOWN_COORDINATES,
    payload: data,
  });
};

export const setIsFetchingSettings = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_SETTINGS,
    payload: data,
  });
};

export const setSettings = (data) => (dispatch) => {
  dispatch({
    type: SET_SETTINGS,
    payload: data,
  });
};

export const setIsDeliverySettingsVisible = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_DELIVERY_SETTINGS_VISIBLE,
    payload: data,
  });
};

export const setIsDeleteVehicleModalVisible = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_DELETE_VEHICLE_MODAL_VISIBLE,
    payload: data,
  });
};

export const setIsDeliveryRunSheetVisible = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_DELIVERY_RUNSHEET_VISIBLE,
    payload: data,
  });
};

export const setActiveOrder = (data) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_ORDER,
    payload: data,
  });
};

export const setIsOrderModalVisible = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_ORDER_MODAL_VISIBLE,
    payload: data,
  });
};

export const setSelectedResources = (data) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_RESOURCES,
    payload: data,
  });
};

export const setSelectedVehicle = (data) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_VEHICLE,
    payload: data,
  });
};

export const setIsOrderColumnCollapse = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_ORDER_COLUMN_COLLAPSE,
    payload: status,
  });
};

export const showAssignUserMenu =
  ({ isVisible, event }) =>
  (dispatch) => {
    dispatch(setIsAssignUserMenuVisible(isVisible));

    dispatch(
      setAssignUserDropdownCoordinates({
        top: event.clientY,
        left: event.clientX,
      })
    );
  };

export const showAssignLabelMenu =
  ({ isVisible, event }) =>
  (dispatch) => {
    dispatch(setIsAssignLabelMenuVisible(isVisible));

    dispatch(
      setAssignLabelDropdownCoordinates({
        top: event.clientY,
        left: event.clientX,
      })
    );
  };

export const fetchSettings = () => async (dispatch) => {
  try {
    dispatch(setIsFetchingSettings(true));
    const { data } = await api.getSettings();
    dispatch(setSettings(data));

    if (data.vehicles.length > 0) {
      dispatch(setSelectedResources(data.vehicles.map((v) => v.uid)));
      dispatch(setSelectedVehicle(data.vehicles[0].uid));
    }
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetchingSettings(false));
  }
};

export const updateSettings = (payload) => async (dispatch, getState) => {
  try {
    const state = getState();
    dispatch(setIsFetchingSettings(true));
    const { data } = await api.postSettings({
      ...payload,
      vehicles: payload.vehicles.map((v) => (v.isNewlyAdded ? { ...v, uid: undefined } : { ...v })),
    })();
    dispatch(setSettings(data));

    const selectedResources = selectors.getSelectedResources(state);

    if (selectedResources.length > 0) {
      dispatch(
        setSelectedResources(
          selectedResources.filter((s) => data.vehicles.map((v) => v.uid).includes(s))
        )
      );
    } else {
      dispatch(setSelectedResources(data.vehicles.map((v) => v.uid)));
    }

    if (data.vehicles.length > 0) {
      dispatch(setSelectedVehicle(data.vehicles[0].uid));
    } else {
      dispatch(setSelectedVehicle(null));
    }

    dispatch(setIsDeliverySettingsVisible(false));
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetchingSettings(false));
  }
};

export const updateOrderCardAssignees = (values) => async (dispatch, getState) => {
  const state = getState();
  const { order } = values;

  const orders = orderSelectors.getOrders(state);

  dispatch(
    orderActions.setOrders(
      orders.map((o) => (o.uid === order.uid ? { ...o, assignees: order.assignees } : { ...o }))
    )
  );
};

export const assignUser =
  ({ order }) =>
  async (dispatch) => {
    await dispatch(
      updateOrderCardAssignees({
        order: {
          uid: order.uid,
          assignees: order.assignees,
        },
      })
    );
  };

export const updateOrderCardLabels = (values) => async (dispatch, getState) => {
  const state = getState();
  const { order } = values;
  const orders = orderSelectors.getOrders(state);

  dispatch(
    orderActions.setOrders(
      orders.map((o) => (o.uid === order.uid ? { ...o, labels: order.labels } : { ...o }))
    )
  );
};

export const assignLabels =
  ({ order }) =>
  async (dispatch) => {
    await dispatch(
      updateOrderCardLabels({
        order: {
          uid: order.uid,
          labels: order.labels,
        },
      })
    );
  };

export const removeLabelToAllOrderCards = (data) => (dispatch, getState) => {
  const state = getState();
  const orders = orderSelectors.getOrders(state);
  const formattedOrders = orders.map((c) => ({
    ...c,
    labels: c.labels.filter((l) => l.uid !== data.uid),
  }));

  dispatch(orderActions.setOrders(formattedOrders));
};

export const renameLabelToAllOrderCards = (values) => async (dispatch, getState) => {
  const state = getState();
  const orders = orderSelectors.getOrders(state);

  dispatch(
    orderActions.setOrders(
      orders.map((o) => ({
        ...o,
        labels: o.labels.map((l) =>
          l.uid === values.uid ? { ...l, name: values.name, colour: values.colour } : l
        ),
      }))
    )
  );
};

export const setSelectedResourcesForDisplay = (vehicleUid) => (dispatch, getState) => {
  const state = getState();

  const selectedResources = selectors.getSelectedResources(state);
  const vehicles = selectors.getVehicles(state);

  if (vehicleUid === "" && selectedResources.length === vehicles.length) {
    return dispatch(setSelectedResources([]));
  }

  if (
    vehicleUid === "" &&
    (selectedResources.length === 0 || selectedResources.length < vehicles.length)
  ) {
    return dispatch(setSelectedResources(vehicles.map((v) => v.uid)));
  }

  if (vehicleUid === "" && selectedResources.length === 0) {
    return dispatch(setSelectedResources(vehicles.map((v) => v.uid)));
  }

  if (selectedResources.includes(vehicleUid)) {
    return dispatch(setSelectedResources(selectedResources.filter((uid) => uid !== vehicleUid)));
  }

  return dispatch(setSelectedResources([...selectedResources, vehicleUid]));
};

export const printDeliveryRunSheet = (payload) => async (dispatch) => {
  try {
    const data = {
      end_date_of_run: payload.endDateOfRun,
      start_date_of_run: payload.startDateOfRun,
      vehicles: payload.vehicles,
    };

    const response = await api.postPrinDeliveryRunSheet(data)();
    const file = new Blob([response.data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  } catch (error) {
    handleError(error, dispatch);
  }
};
