import { STATUS } from "../../utils/constants";

import {
  SET_ACTIVE_VIEW,
  SET_IS_ASSIGN_USER_MENU_VISIBLE,
  SET_IS_ASSIGN_LABEL_MENU_VISIBLE,
  SET_IS_EDIT_TIME_SLOT_DROPDOWN_VISIBLE,
  SET_ASSIGN_USER_DROPDOWN_COORDINATES,
  SET_ASSIGN_LABEL_DROPDOWN_COORDINATES,
  SET_EDIT_TIME_SLOT_DROPDOWN_COORDINATES,
  SET_IS_ORDER_MODAL_OPEN,
  SET_IS_ORDER_COLUMN_COLLAPSE,
  SET_IS_SETTINGS_MODAL_OPEN,
  SET_SETTINGS_START_TIME,
  SET_SETTINGS_END_TIME,
  SET_IS_SETTINGS_SAVING,
  SET_CREATE_CUSTOM_EVENT_STATUS,
  SET_FETCH_CUSTOM_EVENTS_STATUS,
  SET_FETCH_CUSTOM_EVENT_STATUS,
  SET_CUSTOM_EVENTS,
  SET_CUSTOM_EVENT,
  SET_ACTIVE_CUSTOM_EVENT,
  SET_UPDATE_CUSTOM_EVENT_STATUS,
  SET_IS_CUSTOM_ASSIGN_USER_MENU_VISIBLE,
  SET_IS_CUSTOM_ASSIGN_LABEL_MENU_VISIBLE,
  SET_IS_CUSTOM_EDIT_TIME_SLOT_DROPDOWN_VISIBLE,
  SET_DUPLICATE_EVENT_STATUS,
  SET_DELETE_CUSTOM_EVENT_INSTANCE_STATUS,
  SET_DELETE_THIS_CUSTOM_EVENT_STATUS,
  SET_DELETE_THIS_AND_FOLLOWING_CUSTOM_EVENT_STATUS,
  SET_DELETE_ALL_CUSTOM_EVENT_STATUS,
  SET_UNDO_DELETE_CUSTOM_EVENT_INSTANCE_STATUS,
  SET_UNDO_DELETE_THIS_CUSTOM_EVENT_STATUS,
  SET_UNDO_DELETE_THIS_AND_FOLLOWING_CUSTOM_EVENT_STATUS,
  SET_UNDO_DELETE_ALL_CUSTOM_EVENT_STATUS,
  SET_CUSTOM_EVENT_DATA_TO_UPDATE,
  SET_CUSTOM_EVENT_REMINDERS,
  SET_CUSTOM_EVENT_REMINDER,
  RESET_CUSTOM_EVENT_TRANSACTION_STATE,
  RESET,
} from "./planner.actionTypes";

const initialState = {
  activeView: "team",
  isAssignUserMenuVisible: false,
  isAssignLabelMenuVisible: false,
  isEditTimeslotMenuVisible: false,
  assignUserDropdownCoordinates: null,
  assignLabelDropdownCoordinates: null,
  editTimeslotDropdownCoordinates: null,
  isOrderModalOpen: false,
  isOrderColumnCollapse: false,
  isSettingsModalOpen: false,
  settings: {
    isSaving: false,
    startTime: null,
    endTime: null,
  },
  isCustomAssignUserMenuVisible: false,
  isCustomAssignLabelMenuVisible: false,
  isCustomEditTimeslotMenuVisible: false,
  duplicateEventStatus: STATUS.IDLE,
  deleteCustomEventInstanceStatus: STATUS.IDLE,
  deleteThisCustomEventStatus: STATUS.IDLE,
  deleteThisAndFollowingCustomEventStatus: STATUS.IDLE,
  deleteAllCustomEventStatus: STATUS.IDLE,
  undoDeleteCustomEventInstanceStatus: STATUS.IDLE,
  undoDeleteThisCustomEventStatus: STATUS.IDLE,
  undoDeleteThisAndFollowingCustomEventStatus: STATUS.IDLE,
  undoDeleteAllCustomEventStatus: STATUS.IDLE,
  fetchCustomEventsStatus: STATUS.IDLE,
  fetchCustomEventStatus: STATUS.IDLE,
  customEvents: [],
  customEvent: null,
  createCustomEventStatus: STATUS.IDLE,
  updateCustomEventStatus: STATUS.IDLE,
  customEventDataToUpdate: null,
  customEventReminders: [],
  customEventReminder: null,
  activeCustomEvent: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_VIEW: {
      return {
        ...state,
        activeView: action.payload,
      };
    }

    case SET_IS_ASSIGN_USER_MENU_VISIBLE: {
      return {
        ...state,
        isAssignUserMenuVisible: action.payload,
      };
    }

    case SET_IS_ASSIGN_LABEL_MENU_VISIBLE: {
      return {
        ...state,
        isAssignLabelMenuVisible: action.payload,
      };
    }

    case SET_IS_EDIT_TIME_SLOT_DROPDOWN_VISIBLE: {
      return {
        ...state,
        isEditTimeslotMenuVisible: action.payload,
      };
    }

    case SET_ASSIGN_USER_DROPDOWN_COORDINATES: {
      return {
        ...state,
        assignUserDropdownCoordinates: action.payload,
      };
    }

    case SET_ASSIGN_LABEL_DROPDOWN_COORDINATES: {
      return {
        ...state,
        assignLabelDropdownCoordinates: action.payload,
      };
    }

    case SET_EDIT_TIME_SLOT_DROPDOWN_COORDINATES: {
      return {
        ...state,
        editTimeslotDropdownCoordinates: action.payload,
      };
    }

    case SET_IS_ORDER_MODAL_OPEN: {
      return {
        ...state,
        isOrderModalOpen: action.payload,
      };
    }

    case SET_IS_ORDER_COLUMN_COLLAPSE: {
      return {
        ...state,
        isOrderColumnCollapse: action.payload,
      };
    }

    case SET_IS_SETTINGS_MODAL_OPEN: {
      return {
        ...state,
        isSettingsModalOpen: action.payload,
      };
    }

    case SET_IS_SETTINGS_SAVING: {
      return {
        ...state,
        settings: {
          ...state.settings,
          isSaving: action.payload,
        },
      };
    }

    case SET_SETTINGS_START_TIME: {
      return {
        ...state,
        settings: {
          ...state.settings,
          startTime: action.payload,
        },
      };
    }

    case SET_SETTINGS_END_TIME: {
      return {
        ...state,
        settings: {
          ...state.settings,
          endTime: action.payload,
        },
      };
    }

    case SET_IS_CUSTOM_ASSIGN_USER_MENU_VISIBLE: {
      return {
        ...state,
        isCustomAssignUserMenuVisible: action.payload,
      };
    }

    case SET_IS_CUSTOM_ASSIGN_LABEL_MENU_VISIBLE: {
      return {
        ...state,
        isCustomAssignLabelMenuVisible: action.payload,
      };
    }

    case SET_IS_CUSTOM_EDIT_TIME_SLOT_DROPDOWN_VISIBLE: {
      return {
        ...state,
        isCustomEditTimeslotMenuVisible: action.payload,
      };
    }

    case SET_DUPLICATE_EVENT_STATUS: {
      return {
        ...state,
        duplicateEventStatus: action.payload,
      };
    }

    case SET_DELETE_CUSTOM_EVENT_INSTANCE_STATUS: {
      return {
        ...state,
        deleteCustomEventInstanceStatus: action.payload,
      };
    }

    case SET_DELETE_THIS_CUSTOM_EVENT_STATUS: {
      return {
        ...state,
        deleteThisCustomEventStatus: action.payload,
      };
    }

    case SET_DELETE_THIS_AND_FOLLOWING_CUSTOM_EVENT_STATUS: {
      return {
        ...state,
        deleteThisAndFollowingCustomEventStatus: action.payload,
      };
    }

    case SET_DELETE_ALL_CUSTOM_EVENT_STATUS: {
      return {
        ...state,
        deleteAllCustomEventStatus: action.payload,
      };
    }

    case SET_UNDO_DELETE_CUSTOM_EVENT_INSTANCE_STATUS: {
      return {
        ...state,
        undoDeleteCustomEventInstanceStatus: action.payload,
      };
    }

    case SET_UNDO_DELETE_THIS_CUSTOM_EVENT_STATUS: {
      return {
        ...state,
        undoDeleteThisCustomEventStatus: action.payload,
      };
    }

    case SET_UNDO_DELETE_THIS_AND_FOLLOWING_CUSTOM_EVENT_STATUS: {
      return {
        ...state,
        undoDeleteThisAndFollowingCustomEventStatus: action.payload,
      };
    }

    case SET_UNDO_DELETE_ALL_CUSTOM_EVENT_STATUS: {
      return {
        ...state,
        undoDeleteAllCustomEventStatus: action.payload,
      };
    }

    case SET_FETCH_CUSTOM_EVENTS_STATUS: {
      return {
        ...state,
        fetchCustomEventsStatus: action.payload,
      };
    }

    case SET_FETCH_CUSTOM_EVENT_STATUS: {
      return {
        ...state,
        fetchCustomEventStatus: action.payload,
      };
    }

    case SET_CUSTOM_EVENTS: {
      return {
        ...state,
        customEvents: action.payload,
      };
    }

    case SET_CUSTOM_EVENT: {
      return {
        ...state,
        customEvent: action.payload,
      };
    }

    case SET_CREATE_CUSTOM_EVENT_STATUS: {
      return {
        ...state,
        createCustomEventStatus: action.payload,
      };
    }

    case SET_UPDATE_CUSTOM_EVENT_STATUS: {
      return {
        ...state,
        updateCustomEventStatus: action.payload,
      };
    }

    case SET_CUSTOM_EVENT_DATA_TO_UPDATE: {
      return {
        ...state,
        customEventDataToUpdate: action.payload,
      };
    }

    case SET_CUSTOM_EVENT_REMINDERS: {
      return {
        ...state,
        customEventReminders: action.payload,
      };
    }

    case SET_CUSTOM_EVENT_REMINDER: {
      return {
        ...state,
        customEventReminder: action.payload,
      };
    }

    case SET_ACTIVE_CUSTOM_EVENT: {
      return {
        ...state,
        activeCustomEvent: action.payload,
      };
    }

    case RESET_CUSTOM_EVENT_TRANSACTION_STATE: {
      return {
        ...state,
        deleteCustomEventInstanceStatus: STATUS.IDLE,
        deleteThisCustomEventStatus: STATUS.IDLE,
        deleteThisAndFollowingCustomEventStatus: STATUS.IDLE,
        deleteAllCustomEventStatus: STATUS.IDLE,
        undoDeleteCustomEventInstanceStatus: STATUS.IDLE,
        undoDeleteThisCustomEventStatus: STATUS.IDLE,
        undoDeleteThisAndFollowingCustomEventStatus: STATUS.IDLE,
        undoDeleteAllCustomEventStatus: STATUS.IDLE,
        createCustomEventStatus: STATUS.IDLE,
        updateCustomEventStatus: STATUS.IDLE,
        activeCustomEvent: null,
      };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
