import { STATUS } from "@/utils/constants";

import {
  SET_ITEMS_PROGRESS,
  SET_ITEMS,
  SET_PARENT_ITEM_UPDATE_PROGRESS,
  SET_SUB_KIT_UPDATE_PROGRESS,
  SET_ITEM_UPDATE_PROGRESS,
  SET_STATUS_REFERENCES_PROGRESS,
  SET_STATUS_REFERENCES,
  SET_STATUS_REFERENCE_CREATE_PROGRESS,
  SET_STATUS_REFERENCE_DELETE_PROGRESS,
  SET_STATUS_REFERENCE_UPDATE_PROGRESS,
  SET_STATUS_REFERENCE_TO_DELETE_ID,
  SET_STATUS_REFERENCE_LINE_ITEM_ACTIVE,
  SET_STATUS_REFERENCE_TO_EDIT,
  SET_ADDITIONAL_PRODUCTS_PROGRESS,
  SET_ADDITIONAL_PRODUCTS,
  SET_ACTIVE_LINE_ITEM,
  SET_IS_FETCHING_ACTIVE_LINE_ITEM_UID,
  RESET,
} from "./orderTracking.actionTypes";

const initialState = {
  itemsProgress: STATUS.IDLE,
  items: [],
  parentItemUpdateProgress: STATUS.IDLE,
  subKitUpdateProgress: STATUS.IDLE,
  itemUpdateProgress: STATUS.IDLE,
  statusReferencesProgress: STATUS.IDLE,
  statusReferences: [],
  statusReferenceCreateProgress: STATUS.IDLE,
  statusReferenceDeleteProgress: STATUS.IDLE,
  statusReferenceUpdateProgress: STATUS.IDLE,
  statusReferenceToDeleteId: null,
  statusReferenceLineItemActive: null,
  statusReferenceToEdit: null,
  additionalProductsProgress: STATUS.IDLE,
  additionalProducts: [],
  activeLineItem: null,
  isFetchingActiveLineItemUid: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ITEMS_PROGRESS: {
      return { ...state, itemsProgress: action.payload };
    }

    case SET_ITEMS: {
      return { ...state, items: action.payload };
    }

    case SET_PARENT_ITEM_UPDATE_PROGRESS: {
      return { ...state, parentItemUpdateProgress: action.payload };
    }

    case SET_SUB_KIT_UPDATE_PROGRESS: {
      return { ...state, subKitUpdateProgress: action.payload };
    }

    case SET_ITEM_UPDATE_PROGRESS: {
      return { ...state, itemUpdateProgress: action.payload };
    }

    case SET_STATUS_REFERENCES_PROGRESS: {
      return { ...state, statusReferencesProgress: action.payload };
    }

    case SET_STATUS_REFERENCES: {
      return { ...state, statusReferences: action.payload };
    }

    case SET_STATUS_REFERENCE_CREATE_PROGRESS: {
      return { ...state, statusReferenceCreateProgress: action.payload };
    }

    case SET_STATUS_REFERENCE_DELETE_PROGRESS: {
      return { ...state, statusReferenceDeleteProgress: action.payload };
    }

    case SET_STATUS_REFERENCE_UPDATE_PROGRESS: {
      return { ...state, statusReferenceUpdateProgress: action.payload };
    }

    case SET_STATUS_REFERENCE_TO_DELETE_ID: {
      return { ...state, statusReferenceToDeleteId: action.payload };
    }

    case SET_STATUS_REFERENCE_LINE_ITEM_ACTIVE: {
      return { ...state, statusReferenceLineItemActive: action.payload };
    }

    case SET_STATUS_REFERENCE_TO_EDIT: {
      return { ...state, statusReferenceToEdit: action.payload };
    }

    case SET_ADDITIONAL_PRODUCTS_PROGRESS: {
      return { ...state, additionalProductsProgress: action.payload };
    }

    case SET_ADDITIONAL_PRODUCTS: {
      return { ...state, additionalProducts: action.payload };
    }

    case SET_ACTIVE_LINE_ITEM: {
      return { ...state, activeLineItem: action.payload };
    }

    case SET_IS_FETCHING_ACTIVE_LINE_ITEM_UID: {
      return { ...state, isFetchingActiveLineItemUid: action.payload };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
