import { STATUS } from "@/utils/constants";

import {
  getLatestSuppliers,
  postImportSuppliers,
  postImportAllSuppliers,
} from "../../apiv2/importSuppliers";
import { handleError } from "../utils/error";

import {
  SET_LATEST_SUPPLIERS,
  SET_SEARCH_LATEST_SUPPLIERS,
  SET_IS_LINKED_SUPPLIERS,
  SET_IS_PREVIOUSLY_IMPORTED_SUPPLIERS,
  SET_FETCHING_LATEST_SUPPLIERS_STATUS,
  SET_SUPPLIERS_TO_IMPORT,
  SET_IMPORT_SUPPLIERS_STATUS,
  SET_IMPORT_SUPPLIERS,
  SET_DUPLICATE_SUPPLIERS_TO_IMPORT,
  SET_DUPLICATE_SUPPLIERS_WARNING_MODAL,
  RESET,
  SET_IMPORT_SUPPLIERS_SORT,
  SET_IMPORT_SUPPLIERS_PAGE,
  SET_IMPORT_SUPPLIERS_META,
  SET_IS_LINKED_SUPPLIERS_FILTER,
  SET_IMPORT_ALL_SUPPLIERS_STATUS,
  SET_IMPORT_ALL_SUPPLIERS,
} from "./importSuppliers.actionTypes";
import * as selectors from "./importSuppliers.selectors";

export const setLatestSuppliers = (payload) => (dispatch) => {
  dispatch({
    type: SET_LATEST_SUPPLIERS,
    payload,
  });
};

export const setImportSuppliersSort = (payload) => (dispatch) => {
  dispatch({
    type: SET_IMPORT_SUPPLIERS_SORT,
    payload,
  });
};

export const setImportSuppliersPage = (payload) => (dispatch) => {
  dispatch({
    type: SET_IMPORT_SUPPLIERS_PAGE,
    payload,
  });
};

export const setImportSuppliersMeta = (payload) => (dispatch) => {
  dispatch({
    type: SET_IMPORT_SUPPLIERS_META,
    payload,
  });
};

export const setIsLinkedSuppliersFilter = (payload) => (dispatch) => {
  dispatch({
    type: SET_IS_LINKED_SUPPLIERS_FILTER,
    payload,
  });
};

export const setSearchLatestSuppliers = (payload) => (dispatch) => {
  dispatch({
    type: SET_SEARCH_LATEST_SUPPLIERS,
    payload,
  });
};

export const setIsLinkedSuppliers = (payload) => (dispatch) => {
  dispatch({
    type: SET_IS_LINKED_SUPPLIERS,
    payload,
  });
};

export const setIsPreviouslyImportedSuppliers = (payload) => (dispatch) => {
  dispatch({
    type: SET_IS_PREVIOUSLY_IMPORTED_SUPPLIERS,
    payload,
  });
};

export const setFetchingLatestSuppliersStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_FETCHING_LATEST_SUPPLIERS_STATUS,
    payload,
  });
};

export const fetchLatestSuppliers = (params) => async (dispatch) => {
  try {
    dispatch(setFetchingLatestSuppliersStatus(STATUS.PENDING));
    const response = await getLatestSuppliers(params)();
    dispatch(setImportSuppliersMeta(response.data.meta));
    dispatch(setLatestSuppliers(response.data.results));
    dispatch(setFetchingLatestSuppliersStatus(STATUS.FULFILLED));
  } catch (error) {
    handleError(error, dispatch);
    dispatch(setFetchingLatestSuppliersStatus(STATUS.REJECTED));
  }
};

export const setDuplicateSuppliersToImport = (payload) => (dispatch) => {
  dispatch({
    type: SET_DUPLICATE_SUPPLIERS_TO_IMPORT,
    payload,
  });
};

export const setDuplicateSuppliersWarningModal = (payload) => (dispatch) => {
  dispatch({
    type: SET_DUPLICATE_SUPPLIERS_WARNING_MODAL,
    payload,
  });
};

export const setSuppliersToImport = (payload) => (dispatch) => {
  dispatch(setDuplicateSuppliersToImport(payload.filter((row) => row.is_linked)));

  dispatch({
    type: SET_SUPPLIERS_TO_IMPORT,
    payload,
  });
};

export const setImportSuppliersStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_IMPORT_SUPPLIERS_STATUS,
    payload,
  });
};

export const setImportSuppliers = (payload) => (dispatch) => {
  dispatch({
    type: SET_IMPORT_SUPPLIERS,
    payload,
  });
};

export const importSuppliers = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const suppliersToImport = selectors.getSuppliersToImport(state);

    dispatch(setImportSuppliersStatus(STATUS.PENDING));
    const response = await postImportSuppliers(suppliersToImport)();
    dispatch(setImportSuppliers(response.data));
    dispatch(setImportSuppliersStatus(STATUS.FULFILLED));
  } catch (error) {
    handleError(error, dispatch);
    dispatch(setImportSuppliersStatus(STATUS.REJECTED));
  }
};

export const importSuppliersExcludeDuplicates = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const suppliersToImport = selectors.getSuppliersToImport(state);
    const excludedDuplicateSuppliers = suppliersToImport.filter((row) => !row.is_linked);

    dispatch(setImportSuppliersStatus(STATUS.PENDING));
    const response = await postImportSuppliers(excludedDuplicateSuppliers)();
    dispatch(setImportSuppliers(response.data));
    dispatch(setImportSuppliersStatus(STATUS.FULFILLED));
  } catch (error) {
    handleError(error, dispatch);
    dispatch(setImportSuppliersStatus(STATUS.REJECTED));
  }
};

/* IMPORT ALL */
export const setImportAllSuppliersStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_IMPORT_ALL_SUPPLIERS_STATUS,
    payload,
  });
};

export const setImportAllSuppliers = (payload) => (dispatch) => {
  dispatch({
    type: SET_IMPORT_ALL_SUPPLIERS,
    payload,
  });
};

export const importAllSuppliers = () => async (dispatch) => {
  try {
    dispatch(setImportAllSuppliersStatus(STATUS.PENDING));
    const response = await postImportAllSuppliers()();
    dispatch(setImportAllSuppliers(response.data));
    dispatch(setImportAllSuppliersStatus(STATUS.FULFILLED));
  } catch (error) {
    handleError(error, dispatch);
    dispatch(setImportAllSuppliersStatus(STATUS.REJECTED));
  }
};

export const reset = () => (dispatch) => {
  dispatch({ type: RESET });
};
