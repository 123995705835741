export const hex2Rgba = ({ hex, alpha = 1 } = {}) => {
  if (!hex || !hex.match(/^#[a-fA-F0-9]{6}$/i))
    return {
      r: 255,
      g: 255,
      b: 255,
      alpha: 1,
    };
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
  return {
    r,
    g,
    b,
    alpha,
  };
};

export const isColorLight = ({ r, g, b, alpha }) => {
  const brightness = r * 0.299 + g * 0.587 + b * 0.114 + (1 - alpha) * 255;
  return brightness > 186;
};

export const isColorDark = ({ r, g, b, alpha }) => {
  const brightness = r * 0.299 + g * 0.587 + b * 0.114 + (1 - alpha) * 255;

  return brightness < 186;
};
