import React from "react";
import cx from "classnames";
import TextArea from "react-textarea-autosize";

import styles from "./AutosizableTextArea.module.scss";

const AutosizeTextArea = ({ className, inputClassName, ...rest }) => (
  <TextArea className={cx(styles.textArea, inputClassName)} {...rest} />
);

export default AutosizeTextArea;
