import { fromJS } from "immutable";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import { orderBy, isNaN } from "lodash";
import { evaluate, round as mathjsRound } from "mathjs";
import Decimal from "decimal.js-light";
import { nanoid } from "nanoid";

import ReduxModule from "./abstract/ReduxModule";
import { withMutations } from "./helpers/reducerWrappers";
import { isNew, isNumeric, isPositiveNumber } from "../../utils/common";

import * as additionalProductsApi from "../../api/additional-products";
import * as priceLevelApi from "../../apiv2/priceLevels";
import * as productsApi from "../../apiv2/products";
import { calcItemLength, calculateTotalSquareMeters } from "./helpers/item";
import { sortByMaterialName, getMinLengthProducts, getSortLengthsSetting } from "./helpers/product";
import { createFormData } from "./helpers/common";
import {
  PRICING_STRATEGY,
  PRODUCT_TYPES,
  PRODUCT_TYPE_ADDITIONAL_PRODUCT,
  PRODUCT_TYPE_ADDITIONAL_PRODUCT_LABEL,
  PRODUCT_TYPE_NOTES_ITEM,
  PRODUCT_TYPE_NOTES_ITEM_LABEL,
  PRODUCT_TYPE_ON_THE_FLY_PRODUCT,
  PRODUCT_TYPE_ON_THE_FLY_PRODUCT_LABEL,
  PRODUCT_TYPE_LABOUR_ITEM,
  PRODUCT_TYPE_LABOUR_PRODUCT_LABEL,
  PRODUCT_TYPE_PRODUCT_KIT_LABEL,
  PRICING_STRATEGY_STRING_TO_INT,
  PRICING_STRATEGY_BASIC_QUANTITIES_LABEL,
  PRICING_STRATEGY_LINEAL_METRES_LABEL,
  PRICING_STRATEGY_CUSTOM_FORMULA_LABEL,
  PRICING_STRATEGY_SQUARE_METRES_LABEL,
} from "../../utils/constants";

import alerts from "./alerts";
import prices from "./prices";

Decimal.config({ rounding: Decimal.ROUND_HALF_UP });

const ACTIONS = {
  ADD_ADDITIONAL_PRODUCT: "Add additional product",
  ADD_PRODUCT_KIT_ITEM_LENGTH: "Add product kit item length",
  ADD_SUB_KIT_ITEM_LENGTH: "Add sub kit item length",
  ADD_PRODUCT_KIT_ITEM_DIMENSION: "Add product kit item dimension",
  ADD_SUB_KIT_ITEM_DIMENSION: "Add sub kit item dimension",
  ADD_ON_THE_FLY_PRODUCT_ITEM: "Add on the fly product item",
  ADD_NOTES_LINE_ITEM: "Add notes line item",
  ADD_LABOUR_LINE_ITEM: "Add labour line item",
  EDIT_LABOUR_LINE_ITEM: "Edit Labour line item",
  ADD_PRODUCT_KIT_ITEM_ACTUAL_LENGTH: "Add product kit item actual length",
  ADD_PRODUCT_KIT_ITEM_ACTUAL_DIMENSION: "Add product kit item actual dimension",
  ADD_SUB_KIT_ITEM_ACTUAL_LENGTH: "Add sub kit item actual length",
  ADD_SUB_KIT_ITEM_ACTUAL_DIMENSION: "Add sub kit item actual dimension",
  ADD_PRODUCT_LENGTH: "Add product length",
  ADD_PRODUCT_ACTUAL_LENGTH: "Add product actual length",
  ADD_PRODUCT_PRICE_LEVEL: "Add product price level",
  ADD_PRODUCT_DIMENSION: "Add product dimension",
  ADD_PRODUCT_ACTUAL_DIMENSION: "Add product actual dimension",
  CHANGE_PRICE_VALUE: "Change product price value",
  CHANGE_PRODUCT_QUANTITY: "Change product quantity",
  CHANGE_PRODUCT_ACTUAL_QUANTITY: "Change product actual quantity",
  CHANGE_PRODUCT_KIT_QUANTITY: "Change product kit quantity",
  CHANGE_SUB_KIT_QUANTITY: "Change sub kit quantity",
  CHANGE_PRODUCT_KIT_ACTUAL_QUANTITY: "Change product kit actual quantity",
  CHANGE_SUB_KIT_ACTUAL_QUANTITY: "Change sub kit actual quantity",
  CHANGE_PRODUCT_CATEGORY: "Change product category",
  CHANGE_PRODUCT_IS_TAX_FREE: "Change product is tax free",
  CHANGE_PRODUCT_KIT_ITEM_IS_TAX_FREE: "Change product kit item is tax free",
  CHANGE_SUB_KIT_ITEM_IS_TAX_FREE: "Change sub kit item is tax free",
  CHANGE_PRODUCT_COMPLETED_STATUS: "Change product completed status",
  CHANGE_PRODUCT_KIT_ITEM_COMPLETED_STATUS: "Change product kit item completed status",
  CHANGE_SUB_KIT_ITEM_COMPLETED_STATUS: "Change sub kit item completed status",
  CHANGE_SUB_KIT_COMPLETED_STATUS: "Change sub kit completed status",
  CHANGE_PRODUCT_KIT_DESCRIPTION: "Change product kit description",
  CHANGE_PRODUCT_KIT_ITEM_QUANTITY: "Change product kit item quantity",
  CHANGE_SUB_KIT_ITEM_QUANTITY: "Change sub kit item quantity",
  CHANGE_PRODUCT_KIT_ITEM_ACTUAL_QUANTITY: "Change product kit item actual quantity",
  CHANGE_SUB_KIT_ITEM_ACTUAL_QUANTITY: "Change sub kit item actual quantity",
  CHANGE_PRODUCT_KIT_ITEM_NAME: "Change product kit item name",
  CHANGE_SUB_KIT_ITEM_NAME: "Change sub kit item name",
  CHANGE_PRODUCT_KIT_ITEM_TYPE: "Change product kit item type",
  CHANGE_SUB_KIT_ITEM_TYPE: "Change sub kit item type",
  CHANGE_PRODUCT_KIT_ITEM_PRICE_VALUE: "Change product kit item price value",
  CHANGE_SUB_KIT_ITEM_PRICE_VALUE: "Change sub kit item price value",
  CHANGE_PRODUCT_KIT_ITEM_NOTES: "Change product kit item notes",
  CHANGE_SUB_KIT_ITEM_NOTES: "Change sub kit item notes",
  CHANGE_PRODUCT_KIT_ITEM_DESCRIPTION: "Change product kit item description",
  CHANGE_SUB_KIT_ITEM_DESCRIPTION: "Change sub kit item description",
  CHANGE_PRODUCT_NAME: "Change product name",
  CHANGE_SUB_KIT_TITLE: "Change sub kit title",
  CHANGE_PRODUCT_NOTES: "Change product notes",
  CHANGE_PRODUCT_DESCRIPTION: "Change product description",
  CHANGE_PRODUCT_TYPE: "Change product type",
  CHANGE_ITEM_ORDER_INDEX: "Change item or product kit order index",
  CHANGE_PRODUCT_KIT_ITEM_ORDER_INDEX: "Change product kit item order index",
  CHANGE_SUB_KIT_ITEM_ORDER_INDEX: "Change sub kit item order index",
  CHANGE_SUB_KIT_ORDER_INDEX: "Change sub kit order index",
  SAVE_PRODUCT_TO_CATALOGUE: "Save product to catalogue",
  CLEAR_ACTIVE_ORDER: "Clear active order",
  CREATE_CATEGORY: "Create category",
  CREATE_MATERIAL_TABLE: "Create material table",
  CREATE_NEW_ORDER: "Create new order",
  CREATE_NEW_PRODUCT: "Create new product",
  DELETE_CATEGORY: "Delete category",
  DELETE_PRODUCT_LENGTH: "Delete product length",
  DELETE_PRODUCT_ACTUAL_LENGTH: "Delete product actual length",
  DELETE_PRODUCT_KIT_ITEM_LENGTH: "Delete product kit item length",
  DELETE_PRODUCT_KIT_ITEM_DIMENSION: "Delete product kit item dimension",
  DELETE_SUB_KIT_ITEM_LENGTH: "Delete sub kit item length",
  DELETE_SUB_KIT_ITEM_DIMENSION: "Delete sub kit item dimension",
  DELETE_PRODUCT_KIT_ITEM_ACTUAL_LENGTH: "Delete product kit item actual length",
  DELETE_PRODUCT_KIT_ITEM_ACTUAL_DIMENSION: "Delete product kit item actual dimension",
  DELETE_SUB_KIT_ITEM_ACTUAL_LENGTH: "Delete sub kit item actual length",
  DELETE_SUB_KIT_ITEM_ACTUAL_DIMENSION: "Delete sub kit item actual dimension",
  DELETE_PRODUCT_DIMENSION: "Delete product dimension",
  DELETE_PRODUCT_ACTUAL_DIMENSION: "Delete product actual dimension",
  DUPLICATE_ADDITIONAL_PRODUCT: "Duplicate product price level",
  DUPLICATE_KIT_ITEM: "Duplicate kit item on order",
  DUPLICATE_SUB_KIT_ITEM: "Duplicate sub kit item on order",
  DUPLICATE_PRODUCT: "Duplicate product",
  EDIT_ADDITIONAL_PRODUCT: "Edit additional product",
  GET_CATEGORIES: "Get categories",
  GET_PRODUCT: "Get product",
  OPEN_ORDER: "Open order",
  REMOVE_KIT_ITEM_FROM_ORDER: "Remove kit item from order",
  REMOVE_SUB_KIT_ITEM_FROM_ORDER: "Remove sub kit item from order",
  REMOVE_MATERIAL_TABLE: "Remove material table",
  REMOVE_PRODUCT_FROM_ORDER: "Remove product from order",
  DELETE_SUB_KIT_FROM_ORDER: "Remove sub kit from order",
  RENAME_CATEGORY: "Rename category",
  RESET_ORDER_PRODUCTS: "Reset order products",
  SAVE_EDIT_ADDITIONAL_PRODUCT: "save edit additional product",
  SAVE_PRODUCT_TABLE: "Save product table",
  SEARCH_PRODUCTS: "Get all products",
  SEARCH_PRODUCTS_BY_CATEGORY: "Get all products for a category",
  SELECT_MATERIAL: "Select material",
  SET_UIDS: "Set uids",
  SET_SEARCH_VALUE: "Set search value",
  SET_ACTIVE_TAB: "Set active tab",
  SET_ACTIVE_PRODUCT: "Set active product",
  SET_DELETED_STATUS_PRODUCT: "Set deleted status product",
  SORT_BY_CATEGORY: "Sort by category",
  SUBMIT_PRODUCT: "Submit product",
  SWITCH_IN_LENGTH: "Switch in length",
  UPDATE_UID_OF_CUSTOM_FORMULA: "Update uid of custom formula",
  CHANGE_CUSTOM_FORMULA_VALUES: "Update custom formula values",
  CHANGE_ACTUAL_CUSTOM_FORMULA_VALUES: "Update actual custom formula values",
  ADD_CUSTOM_FORMULA_VALUE: "Add custom formula value",
  REMOVE_CUSTOM_FORMULA_VALUE: "Remove custom formula value",
  ADD_ITEM_TO_KIT: "Add item to kit item on order",
  ADD_ON_THE_FLY_ITEM_TO_KIT: "Add on the fly product to kit item on order",
  ADD_NOTES_ITEM_TO_KIT: "Add on the notes to kit item on order",
  ADD_LABOUR_LINE_ITEM_TO_KIT: "Add labour item to kit on order",
  SELECT_MYOB_JOB: "Select myob job to line item",
  SELECT_MYOB_JOB_TO_KIT_ITEM: "Select myob job to kit item",
  SELECT_MYOB_JOB_TO_SUB_KIT_ITEM: "Select myob job to sub kit item",
  APPLY_MYOB_JOB_TO_ALL_LINE_ITEMS: "Apply Myob Job to all line items",
};

class AdditionalProducts extends ReduxModule {
  getNamespace() {
    return "[AdditionalProducts]";
  }

  getInitialState() {
    return {
      activeProduct: null, // this.initialProductState()
      activeCategory: null,
      startStateOrderProducts: null, // items: []
      searchValue: "",
      activeTabs: {},
      items: [], // { this.initialItemState() }
      editItem: null,
      dataServer: {
        isPending: false,
        items: {},
      },
      categories: [], // {company: '', isDeleted: bool, name: '', uid: '', isPending: bool}
      isPending: false,
      isDuplicating: false,
      errors: {},
    };
  }

  initialProductState() {
    return {
      uid: "",
      category: "Miscellaneous", // if saving - uid category
      isDeleted: false,
      image: null,
      name: "",
      tables: [
        // this.initialOptionsState()
      ],
      inLength: false,
      pricingStrategy: "",
    };
  }

  initialItemState() {
    return {
      additionalProduct: "", // uid additional product
      isDeleted: false,
      isCompleted: false,
      isTaxFree: false,
      uid: "", // uid item
      name: "",
      productDescription: "",
      types: [],
      rowPriceLevel: null,
      subitems: [], // {amount: '0', length: '0'}
      actualLengthItems: [],
      quantity: "0",
      actualQuantity: "0",
      value: "0.00",
      totalValue: "0.00",
      customValue: null,
      inLength: false,
      pricingStrategy: "",
      customFormula: null,
      isSavingToProductCatalogue: false,
      jobs: null,
      // sumLengths: {total: 0, forPrice: 0} if inLength: true
      // actualSumLengths: {total: 0, forPrice: 0} if inLength: true
    };
  }

  initialOptionsState() {
    return {
      name: "",
      materialUid: "",
      productUid: "",
      fields: ["Attribute 0"],
      priceLevels: [{}],
      hiddenPriceLevels: [],
      values: [
        {
          types: [
            {
              name: "Attribute 0",
              value: "",
              isHideOrderSummary: false,
              isHideInvoicePdf: false,
              isHideCustomerView: false,
              isHideWorkOrderPdf: false,
              isHidePurchaseOrder: false,
              isHideInvoicePdf: false,
              isHideDeliveryDocketPdf: false,
              isHidePurchaseOrderPdf: false,
            },
          ], // name: [fields[id]]
          prices: [{ name: "Price (A)", value: "" }], // name: [titles[id]]
          rowPrices: [{}],
          params: [{ name: "Colour", value: "" }],
          myobItem: { number: "" },
        },
      ],
    };
  }

  static generateProductDataForServer(state, { product, newName, imageFile }) {
    const errors = [];

    let activeProduct = product;
    if (!activeProduct.get("name") && !newName) {
      errors.push("Name of product is empty!");
    }

    const materials = state.getIn(["materials", "dataServer"]);
    const tables = sortByMaterialName(activeProduct.get("tables"));

    activeProduct = activeProduct.set("tables", tables);
    activeProduct = activeProduct.toJS();

    if (errors.length) {
      return { errors };
    }

    if (newName) {
      activeProduct.name = newName;
    }
    activeProduct = snakecaseKeys(activeProduct);

    const { image, ...json } = activeProduct;

    return createFormData(json, imageFile || image);
  }

  generateOrderDataForServer(state) {
    const errorsAll = [];
    const isAutoSaving = state.getIn(["orders", "saving", "auto"]);

    const items = state.getIn(["additionalProducts", "items"]);

    const itemsForServer = [];
    let deletedItems = 0;

    const [...keys] = items.keys();
    if (!isAutoSaving && keys.length === 0) {
      errorsAll.push("Empty products");
    } else {
      items.forEach((item, id) => {
        if (!isAutoSaving) {
          const errors = [];
          if (item.get("isDeleted")) {
            deletedItems += 1;
          }

          if (
            item.get("pricingStrategy") === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL &&
            !item.get("isDeleted")
          ) {
            try {
              const formulaOutput = item.get("customFormulaOutput");
              if (isNaN(formulaOutput) || formulaOutput === Infinity) {
                errors.push("has an error in the custom formula");
              }
            } catch (_) {
              errors.push("has an error in the custom formula");
            }
          }

          if (item.get("productType") === PRODUCT_TYPE_PRODUCT_KIT_LABEL) {
            item.get("productKitItems").map((kitItem, kitItemId) => {
              if (
                kitItem.get("pricingStrategy") === PRICING_STRATEGY_LINEAL_METRES_LABEL &&
                !kitItem.get("lengthItems").size &&
                !kitItem.get("product").get("isDeleted")
              ) {
                errors.push(`product kit item #${kitItemId + 1} is missing lengths`);
              }

              if (
                kitItem.get("pricingStrategy") === PRICING_STRATEGY_SQUARE_METRES_LABEL &&
                !kitItem.get("lengthItems").size &&
                !kitItem.get("product").get("isDeleted")
              ) {
                errors.push(`product kit item #${kitItemId + 1} is missing dimensions`);
              }

              if (
                kitItem.get("pricingStrategy") === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL &&
                !kitItem.get("product").get("isDeleted")
              ) {
                const customFormulaOutput = kitItem.get("customFormulaOutput");
                if (isNaN(customFormulaOutput) || customFormulaOutput === Infinity) {
                  errors.push(
                    `product kit item #${kitItemId + 1} has an error in the custom formula`
                  );
                }
              }
            });

            item.get("subKits").map((subKit, subKitIndex) => {
              subKit.get("kitProducts").map((kitItem, kitItemId) => {
                if (
                  kitItem.get("pricingStrategy") === PRICING_STRATEGY_LINEAL_METRES_LABEL &&
                  !kitItem.get("lengthItems").size &&
                  !kitItem.get("product").get("isDeleted")
                ) {
                  errors.push(
                    `sub kit #${subKitIndex + 1} sub kit item #${kitItemId + 1} is missing lengths`
                  );
                }

                if (
                  kitItem.get("pricingStrategy") === PRICING_STRATEGY_SQUARE_METRES_LABEL &&
                  !kitItem.get("lengthItems").size &&
                  !kitItem.get("product").get("isDeleted")
                ) {
                  errors.push(
                    `sub kit #${subKitIndex + 1} sub kit item #${
                      kitItemId + 1
                    } is missing dimensions`
                  );
                }

                if (
                  kitItem.get("pricingStrategy") === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL &&
                  !kitItem.get("product").get("isDeleted")
                ) {
                  const customFormulaOutput = kitItem.get("customFormulaOutput");
                  if (isNaN(customFormulaOutput) || customFormulaOutput === Infinity) {
                    errors.push(
                      `sub kit #${subKitIndex + 1} sub kit item #${
                        kitItemId + 1
                      } has an error in the custom formula`
                    );
                  }
                }
              });
            });
          }

          if (
            item.get("pricingStrategy") === PRICING_STRATEGY_LINEAL_METRES_LABEL &&
            !item.get("subitems").size &&
            !item.get("isDeleted")
          ) {
            errors.push("is missing lengths");
          }

          if (
            item.get("pricingStrategy") === PRICING_STRATEGY_SQUARE_METRES_LABEL &&
            !item.get("subitems").size &&
            !item.get("isDeleted")
          ) {
            errors.push("is missing dimensions");
          }

          if (errors.length > 0) {
            const errorsItem = { [`Product #${id + 1}`]: errors };
            errorsAll.push(errorsItem);
            return errors;
          }
        }
        let product = item.toJS();
        if (product.productKitItems) {
          product.productKitItems = product.productKitItems.map((p) => ({
            ...p,
            types: p.types ? p.types.map((p) => ({ ...p, value: p.value || "" })) : [],
            params: p.params ? p.params.map((p) => ({ ...p, value: p.value || "" })) : [],
          }));
        } else {
          product.types = product.types
            ? product.types.map((p) => ({ ...p, value: p.value || "" }))
            : [];
          product.params = product.params
            ? product.params.map((p) => ({ ...p, value: p.value || "" }))
            : [];
        }

        product.productName = product.name;
        delete product.name;

        if (
          [PRICING_STRATEGY_LINEAL_METRES_LABEL, PRICING_STRATEGY_SQUARE_METRES_LABEL].includes(
            product.pricingStrategy
          )
        ) {
          delete product.quantity;
          delete product.actualQuantity;
        }

        product.pricingStrategy = PRICING_STRATEGY_STRING_TO_INT[product.pricingStrategy];

        if (product.productType === PRODUCT_TYPE_PRODUCT_KIT_LABEL) {
          product.productKitItems = product.productKitItems.map((p) => ({
            ...p,
            pricingStrategy: PRICING_STRATEGY_STRING_TO_INT[p.pricingStrategy],
          }));
          product.subKits = product.subKits.map((s) => ({
            ...s,
            kitProducts: s.kitProducts.map((k) => ({
              ...k,
              pricingStrategy: PRICING_STRATEGY_STRING_TO_INT[k.pricingStrategy],
            })),
          }));
        }
        product = snakecaseKeys(product);
        itemsForServer.push(product);

        return product;
      });
    }

    if (deletedItems > 0 && deletedItems === itemsForServer.length) {
      errorsAll.push("Empty products");
    }

    return { data: itemsForServer, errors: errorsAll };
  }

  generateOrderDataFromServer(itemsData, minLength) {
    const productItems = orderBy(
      [
        ...camelcaseKeys(itemsData, { deep: true }).map((d) => ({
          ...d,

          productKitItems: d.productKitItems ? d.productKitItems : [],
          subKits: d.subKits
            ? d.subKits.map((s) => ({
                ...s,
                kitProducts: s.kitProducts ? s.kitProducts : [],
              }))
            : [],
        })),
      ],
      ["index"],
      ["asc"]
    );

    return productItems.map((item) => {
      const product = camelcaseKeys(item, { deep: true });
      product.name = product.productName;
      delete product.productName;

      product.cost = product.cost || "0";
      product.markup = product.markup || "0";
      product.margin = product.margin || "0";
      product.types = Array.isArray(product.types) ? product.types : [];
      product.params = Array.isArray(product.params) ? product.params : [];

      if (product.pricingStrategy === PRICING_STRATEGY_LINEAL_METRES_LABEL) {
        product.sumLengths = calcItemLength(product.subitems, minLength);
        product.actualSumLengths = calcItemLength(product.actualLengthItems, minLength);
      }

      if (product.pricingStrategy === PRICING_STRATEGY_SQUARE_METRES_LABEL) {
        product.sumLengths = calculateTotalSquareMeters(product.subitems);
        product.actualSumLengths = calculateTotalSquareMeters(product.actualLengthItems);
      }

      if (product.productType === PRODUCT_TYPE_PRODUCT_KIT_LABEL) {
        product.productKitItems = orderBy([...product.productKitItems], ["cartIndex"], ["asc"]).map(
          (kitItem) => {
            if (kitItem.pricingStrategy === PRICING_STRATEGY_BASIC_QUANTITIES_LABEL) {
              return {
                ...kitItem,
                types: Array.isArray(kitItem.types) ? kitItem.types : [],
                params: Array.isArray(kitItem.params) ? kitItem.params : [],
                cost: kitItem.cost || "0",
                markup: kitItem.markup || "0",
                margin: kitItem.margin || "0",
              };
            }

            return {
              ...kitItem,
              types: Array.isArray(kitItem.types) ? kitItem.types : [],
              params: Array.isArray(kitItem.params) ? kitItem.params : [],
              sumLengths:
                kitItem.pricingStrategy === PRICING_STRATEGY_LINEAL_METRES_LABEL
                  ? calcItemLength(kitItem.lengthItems, minLength)
                  : calculateTotalSquareMeters(kitItem.lengthItems),
              actualSumLengths:
                kitItem.pricingStrategy === PRICING_STRATEGY_LINEAL_METRES_LABEL
                  ? calcItemLength(kitItem.actualLengthItems, minLength)
                  : calculateTotalSquareMeters(kitItem.actualLengthItems),
              cost: kitItem.cost || "0",
              markup: kitItem.markup || "0",
              margin: kitItem.margin || "0",
            };
          }
        );

        product.subKits = orderBy([...product.subKits], ["subKitIndex"], ["asc"]).map((subKit) => {
          const subKitItems = orderBy([...subKit.kitProducts], ["cartIndex"], ["asc"]).map(
            (kitItem) => {
              if (kitItem.pricingStrategy === PRICING_STRATEGY_BASIC_QUANTITIES_LABEL) {
                return {
                  ...kitItem,
                  types: Array.isArray(kitItem.types) ? kitItem.types : [],
                  params: Array.isArray(kitItem.params) ? kitItem.params : [],
                  cost: kitItem.cost || "0",
                  markup: kitItem.markup || "0",
                  margin: kitItem.margin || "0",
                };
              }

              return {
                ...kitItem,
                types: Array.isArray(kitItem.types) ? kitItem.types : [],
                params: Array.isArray(kitItem.params) ? kitItem.params : [],
                sumLengths:
                  kitItem.pricingStrategy === PRICING_STRATEGY_LINEAL_METRES_LABEL
                    ? calcItemLength(kitItem.lengthItems, minLength)
                    : calculateTotalSquareMeters(kitItem.lengthItems),
                actualSumLengths:
                  kitItem.pricingStrategy === PRICING_STRATEGY_LINEAL_METRES_LABEL
                    ? calcItemLength(kitItem.actualLengthItems, minLength)
                    : calculateTotalSquareMeters(kitItem.actualLengthItems),
                cost: kitItem.cost || "0",
                markup: kitItem.markup || "0",
                margin: kitItem.margin || "0",
              };
            }
          );

          return {
            ...subKit,
            kitProducts: subKitItems,
            deletedKitItems: [],
          };
        });
      }

      if (product.pricingStrategy === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL) {
        if (product.customFormula) {
          const formula = product.customFormula.elements.map((e) => e.value).join("");
          const formulaOutput = evaluate(formula);
          const roundedFormulaOutput = mathjsRound(formulaOutput, 5);

          if (!isNaN(roundedFormulaOutput) && roundedFormulaOutput !== Infinity) {
            const formattedFormulaOutput = product.customFormula.isRoundedToNearestWholeNumber
              ? Math.round(roundedFormulaOutput)
              : Decimal(roundedFormulaOutput).toDecimalPlaces(4).toFixed(4);
            product.customFormulaOutput = formattedFormulaOutput;
          }
          const actualFormula = product.customFormula.appliedElements.map((e) => e.value).join("");
          const actualFormulaOutput = evaluate(actualFormula);
          const roundedActualFormulaOutput = mathjsRound(actualFormulaOutput, 5);

          if (!isNaN(roundedActualFormulaOutput) && roundedActualFormulaOutput !== Infinity) {
            const formattedFormulaOutput = product.customFormula.isRoundedToNearestWholeNumber
              ? Math.round(roundedActualFormulaOutput)
              : Decimal(roundedActualFormulaOutput).toDecimalPlaces(4).toFixed(4);

            product.actualCustomFormulaOutput = formattedFormulaOutput;
          }
        } else {
          product.customFormulaOutput = Decimal(product.formulaAnswer)
            .toDecimalPlaces(4)
            .toFixed(4);
          product.actualCustomFormulaOutput = Decimal(product.formulaAnswer)
            .toDecimalPlaces(4)
            .toFixed(4);
        }
      }

      if (product.productType === PRODUCT_TYPE_PRODUCT_KIT_LABEL) {
        product.productKitItems = orderBy([...product.productKitItems], ["cartIndex"], ["asc"]).map(
          (kitItem) => {
            if (kitItem.pricingStrategy === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL) {
              if (kitItem.customFormula) {
                const { customFormula } = kitItem;
                const formula = customFormula.elements.map((e) => e.value).join("");
                const formulaOutput = evaluate(formula);
                const roundedFormulaOutput = mathjsRound(formulaOutput, 5);
                const formattedFormulaOutput = customFormula.isRoundedToNearestWholeNumber
                  ? Math.round(roundedFormulaOutput)
                  : Decimal(roundedFormulaOutput).toDecimalPlaces(4).toFixed(4);

                const actualFormula = customFormula.appliedElements.map((e) => e.value).join("");
                const actualFormulaOutput = evaluate(actualFormula);
                const roundedActualFormulaOutput = mathjsRound(actualFormulaOutput, 5);
                const formattedActualFormulaOutput = customFormula.isRoundedToNearestWholeNumber
                  ? Math.round(roundedActualFormulaOutput)
                  : Decimal(roundedActualFormulaOutput).toDecimalPlaces(4).toFixed(4);

                return {
                  ...kitItem,
                  customFormulaOutput: formattedFormulaOutput,
                  actualCustomFormulaOutput: formattedActualFormulaOutput,
                };
              } else {
                return {
                  ...kitItem,
                  customFormulaOutput: Decimal(kitItem.formulaAnswer).toDecimalPlaces(4).toFixed(4),
                  actualCustomFormulaOutput: Decimal(kitItem.formulaAnswer)
                    .toDecimalPlaces(4)
                    .toFixed(4),
                };
              }
            }

            return { ...kitItem };
          }
        );

        product.subKits = orderBy([...product.subKits], ["subKitIndex"], ["asc"]).map((subKit) => {
          const subKitItems = orderBy([...subKit.kitProducts], ["cartIndex"], ["asc"]).map(
            (kitItem) => {
              if (kitItem.pricingStrategy === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL) {
                if (kitItem.customFormula) {
                  const { customFormula } = kitItem;
                  const formula = customFormula.elements.map((e) => e.value).join("");
                  const formulaOutput = evaluate(formula);
                  const roundedFormulaOutput = mathjsRound(formulaOutput, 5);
                  const formattedFormulaOutput = customFormula.isRoundedToNearestWholeNumber
                    ? Math.round(roundedFormulaOutput)
                    : Decimal(roundedFormulaOutput).toDecimalPlaces(4).toFixed(4);

                  const actualFormula = customFormula.appliedElements.map((e) => e.value).join("");
                  const actualFormulaOutput = evaluate(actualFormula);
                  const roundedActualFormulaOutput = mathjsRound(actualFormulaOutput, 5);
                  const formattedActualFormulaOutput = customFormula.isRoundedToNearestWholeNumber
                    ? Math.round(roundedActualFormulaOutput)
                    : Decimal(roundedActualFormulaOutput).toDecimalPlaces(4).toFixed(4);

                  return {
                    ...kitItem,
                    customFormulaOutput: formattedFormulaOutput,
                    actualCustomFormulaOutput: formattedActualFormulaOutput,
                  };
                } else {
                  return {
                    ...kitItem,
                    customFormulaOutput: Decimal(kitItem.formulaAnswer)
                      .toDecimalPlaces(4)
                      .toFixed(4),
                    actualCustomFormulaOutput: Decimal(kitItem.formulaAnswer)
                      .toDecimalPlaces(4)
                      .toFixed(4),
                  };
                }
              }

              return { ...kitItem };
            }
          );

          return {
            ...subKit,
            kitProducts: subKitItems,
            deletedKitItems: [],
          };
        });
      }

      return product;
    });
  }

  createNewOrder = (_state) => {
    _state.merge(fromJS(this.getInitialState()));
  };

  setUids = (_state, data) => {
    const items = orderBy([..._state.get("items")], ["index"], ["asc"]);

    items.forEach((_price, i) => {
      if (data[i]) {
        _state.setIn(["items", i, "uid"], data[i].uid);

        if (data[i].kit_items) {
          orderBy([...data[i].kit_items], ["cart_index"], ["asc"]).forEach((_item, index) => {
            if (data[i].kit_items[index]) {
              _state.setIn(
                ["items", i, "productKitItems", index, "uid"],
                data[i].kit_items[index].uid
              );
            }
          });
        }

        if (data[i].sub_kits) {
          orderBy([...data[i].sub_kits], ["subkit_index"], ["asc"]).forEach(
            (_item, subKitIndex) => {
              _state.setIn(
                ["items", i, "subKits", subKitIndex, "uid"],
                data[i].sub_kits[subKitIndex].uid
              );
              if (data[i].sub_kits[subKitIndex].kit_products) {
                orderBy(
                  [...data[i].sub_kits[subKitIndex].kit_products],
                  ["cart_index"],
                  ["asc"]
                ).forEach((_kitItem, index) => {
                  if (data[i].sub_kits[subKitIndex].kit_products[index]) {
                    _state.setIn(
                      ["items", i, "subKits", subKitIndex, "kitProducts", index, "uid"],
                      data[i].sub_kits[subKitIndex].kit_products[index].uid
                    );
                  }
                });
              }
            }
          );
        }
      }
    });
  };

  openOrder = (_state, additionalProducts) => {
    _state.merge(fromJS(this.getInitialState()));

    _state.set("items", additionalProducts);
    _state.set("startStateOrderProducts", additionalProducts);
  };

  openOrderThunk = ({ dispatch, getState, fulfilled }, data) => {
    fulfilled(fromJS(data));

    const ordersSettings = getState().getIn([
      "customers",
      "company",
      "settings",
      "ordersSettings",
      "orders",
    ]);

    if (ordersSettings) {
      const groupByCategory = ordersSettings.find(
        (setting) => setting.key === "GROUP_PRODUCT_BY_CATEGORY"
      );

      if (groupByCategory && groupByCategory.isSelected) {
        dispatch(this.actions.sortByCategory());
      }
    }
  };

  resetOrderProductsThunk = ({ dispatch, fulfilled }) => {
    fulfilled();
    dispatch(prices.actions.calcPriceAdditionalProducts());
  };

  createNewProduct = (_state) => this.setActiveProduct(_state, this.initialProductState());

  searchProductsThunk = (
    { token, dispatch, pendingAction, fulfilled },
    { search = "", categoryName = "" }
  ) => {
    dispatch(pendingAction({ isPending: true }));
    return additionalProductsApi
      .searchProducts(token, {
        search: search || undefined,
        category: categoryName || undefined,
      })
      .then((response) => {
        dispatch(pendingAction({ isPending: false }));
        fulfilled(response);
        return response.data;
      });
  };

  searchProductsByCategoryThunk = (
    { token, getState, dispatch, pendingAction, fulfilled },
    { categoryUid }
  ) => {
    const id = getState()
      .getIn(["additionalProducts", "categories"])
      .findIndex((item) => item.get("uid") === categoryUid);
    const category = getState().getIn(["additionalProducts", "categories", id, "name"]);
    dispatch(pendingAction({ id, isPending: true }));
    const searchValue = "";
    additionalProductsApi
      .searchProducts(token, {
        searchValue,
        category,
      })
      .then((response) => {
        dispatch(pendingAction({ id, isPending: false }));
        fulfilled(response);
        return response.data;
      });
  };

  getProduct = ({ token, fulfilled }, uid) =>
    additionalProductsApi.getProduct(token, uid).then((response) => {
      fulfilled(camelcaseKeys(response.data, { deep: true }));
      return response.data;
    });

  getCategories = ({ token, fulfilled }, uid) =>
    additionalProductsApi.getCategories(token, uid).then((response) => {
      fulfilled(response);
      return response.data;
    });

  createCategory = ({ token, getState, dispatch, pendingAction, fulfilled }, name) => {
    fulfilled({ name });

    const id = getState()
      .getIn(["additionalProducts", "categories"])
      .findIndex((item) => item.get("name") === name);
    dispatch(pendingAction({ id, isPending: true }));

    return additionalProductsApi.updateCategories(token, { name }).then((response) => {
      const uid = response.data;
      fulfilled({ id, uid });
      dispatch(pendingAction({ id, isPending: false }));
      return uid;
    });
  };

  renameCategoryThunk = ({ token, getState, dispatch, pendingAction, fulfilled }, { id, name }) => {
    const uid = getState().getIn(["additionalProducts", "categories", id, "uid"]);
    dispatch(pendingAction({ id, isPending: true }));

    return additionalProductsApi.updateCategories(token, { uid, name }).then((response) => {
      fulfilled({ id, name });

      const newId = getState()
        .getIn(["additionalProducts", "categories"])
        .findIndex((item) => item.get("name") === name);
      dispatch(pendingAction({ id: newId, isPending: false }));
      return response.data;
    });
  };

  deleteCategory = ({ token, getState, dispatch, fulfilled }, id) => {
    const state = getState();
    const category = state.getIn(["additionalProducts", "categories", id]);

    const { name, uid } = category.toJS();
    const products = state.getIn(["additionalProducts", "dataServer", "items", name]);

    return additionalProductsApi
      .updateCategories(token, { name, uid, is_deleted: true })
      .then(() => {
        fulfilled({ id, isDeleted: true });
        dispatch(this.actions.searchProducts());

        dispatch(
          alerts.actions.addAlert({
            type: "dark",
            message: "Category deleted",
            action: {
              label: "Undo",
              callback: () => {
                dispatch(this.actions.undoDeleteCategory(id, products));
              },
            },
          })
        );

        return category;
      });
  };

  undoDeleteCategory = ({ token, getState, dispatch, fulfilled }, id, products) => {
    const state = getState();
    const category = state.getIn(["additionalProducts", "categories", id]);
    const { name, uid } = category.toJS();

    return additionalProductsApi
      .updateCategories(token, { name, uid, is_deleted: false })
      .then(() => {
        let reSaveProductsPromisesAll = [];
        if (products) {
          reSaveProductsPromisesAll = products.map((product) => {
            const { imagePromise, data } = AdditionalProducts.generateProductDataForServer(state, {
              product: product.set("category", uid),
            });
            return imagePromise.then(() => additionalProductsApi.submitProduct(token, data));
          });
        }

        return Promise.all(reSaveProductsPromisesAll);
      })
      .then(() => {
        fulfilled({ id, isDeleted: false });
        dispatch(this.actions.searchProducts());
        return category;
      });
  };

  submitProduct = ({ token, getState, fulfilled, dispatch }, newName, imageFile) => {
    const state = getState();
    const productData = AdditionalProducts.generateProductDataForServer(state, {
      product: state.getIn(["additionalProducts", "activeProduct"]),
      newName,
      imageFile,
    });
    if (productData.errors) {
      return new Promise((resolve, reject) => {
        const error = new Error("Wrong data");
        error.name = "Sending Error";

        productData.errors.forEach((message) => {
          dispatch(
            alerts.actions.addAlert({
              type: "danger",
              message,
            })
          );
        });
        reject(error);
      });
    }

    const { imagePromise, data } = productData;
    return imagePromise
      .then(() => additionalProductsApi.submitProduct(token, data))
      .then((response) => {
        fulfilled(response);
        return response.data;
      });
  };

  deleteProduct = ({ token, getState, dispatch, pendingAction, fulfilled }, product) => {
    const { uid } = product;

    const state = getState();
    const category = state.getIn(["additionalProducts", "activeCategory", "name"]);
    const items = state.getIn(["additionalProducts", "dataServer", "items", category]) || [];
    const id = items.findIndex((item) => item.get("uid") === uid);
    dispatch(pendingAction({ category, id, isPending: true }));

    return additionalProductsApi
      .deleteProduct(token, uid)
      .then(() => {
        fulfilled({ category, id, isDeleted: true });
        dispatch(pendingAction({ category, id, isPending: false }));

        dispatch(
          alerts.actions.addAlert({
            type: "danger",
            message: "Product deleted",
            action: {
              label: "Undo",
              callback: () => {
                dispatch(this.actions.undoDeleteProduct(product));
              },
            },
          })
        );

        return product;
      })
      .catch(() => dispatch(pendingAction({ category, id, isPending: false })));
  };

  undoDeleteProduct = ({ token, getState, dispatch, pendingAction, fulfilled }, product) => {
    const state = getState();
    const category = state.getIn(["additionalProducts", "activeCategory", "name"]);
    const items = state.getIn(["additionalProducts", "dataServer", "items", category]) || [];
    const id = items.findIndex((item) => item.get("uid") === product.uid);
    fulfilled({ category, id, isDeleted: false });
    dispatch(pendingAction({ category, id, isPending: true }));

    const { imagePromise, data } = AdditionalProducts.generateProductDataForServer(state, {
      product: fromJS(product),
    });

    return imagePromise
      .then(() => additionalProductsApi.submitProduct(token, data))
      .then(() => {
        dispatch(this.actions.searchProducts());
        dispatch(pendingAction({ category, id, isPending: false }));
      });
  };

  addItem = (
    { getState, dispatch, fulfilled },
    { material, row, cellId, isMarkupValue, ...data }
  ) => {
    const state = getState();
    const itemId = state.getIn(["additionalProducts", "items"]).size;
    const activeProduct = state.getIn(["additionalProducts", "activeProduct"]);
    const orderPrices = state.getIn(["prices", "orderPrices"]);
    const newItem = this.initialItemState();

    newItem.additionalProduct = activeProduct.get("uid");
    newItem.productType = PRODUCT_TYPE_ADDITIONAL_PRODUCT_LABEL;
    newItem.isStockTrackedByCompany = activeProduct.get("isStockTrackedByCompany");
    newItem.isTaxFree = activeProduct.get("isTaxFree");
    newItem.name = activeProduct.get("name");
    newItem.inLength = activeProduct.get("inLength");
    newItem.index = itemId;
    newItem.pricingStrategy = PRICING_STRATEGY[`${activeProduct.get("pricingStrategy")}`];

    try {
      const customFormula = activeProduct.get("customFormula").toJS();
      const formula = customFormula.elements.map((e) => e.value).join("");
      const formulaOutput = evaluate(formula);
      const roundedFormulaOutput = mathjsRound(formulaOutput, 5);
      const formattedFormulaOutput = customFormula.isRoundedToNearestWholeNumber
        ? Math.round(roundedFormulaOutput)
        : Decimal(roundedFormulaOutput).toDecimalPlaces(4).toFixed(4);
      if (!isNaN(roundedFormulaOutput) && roundedFormulaOutput !== Infinity) {
        const itemCustomFormula = {
          ...customFormula,
          appliedElements: customFormula.elements,
        };
        newItem.customFormula = itemCustomFormula;
        newItem.customFormulaOutput = formattedFormulaOutput;
        newItem.actualCustomFormulaOutput = formattedFormulaOutput;
      }
    } catch (_) {
      /** Do nothing */
    }

    newItem.additionalProductRow = row.uid;
    newItem.categoryName = state.getIn(["additionalProducts", "activeCategory", "name"]);
    newItem.types = row.types ? row.types.map((p) => ({ ...p, value: p.value || "" })) : [];
    newItem.inventory = row.inventory;
    newItem.cost = row.cost ? row.cost : 0;
    newItem.markup = row.markup;
    newItem.markupIsPercentage = row.markupIsPercentage;

    if (row.params) {
      newItem.params = row.params ? row.params.map((p) => ({ ...p, value: p.value || "" })) : [];
    }

    if (isMarkupValue) {
      newItem.rowPriceLevel = null;
      newItem.value = row.markedUpPrice;
    } else {
      const { uid: rowPriceUid, value: priceValue } = row.rowPrices[cellId];
      newItem.rowPriceLevel = rowPriceUid;
      newItem.value = data?.value || priceValue;
    }

    if (
      [PRICING_STRATEGY_BASIC_QUANTITIES_LABEL, PRICING_STRATEGY_CUSTOM_FORMULA_LABEL].includes(
        newItem.pricingStrategy
      )
    ) {
      newItem.actualQuantity = data?.actualQuantity || "1";
      newItem.quantity = data?.quantity || "1";
      newItem.margin =
        Decimal(newItem.cost) > 0
          ? Decimal(newItem.value).minus(newItem.cost).toDecimalPlaces(2).toFixed(2)
          : 0;
    } else if (
      [PRICING_STRATEGY_LINEAL_METRES_LABEL, PRICING_STRATEGY_SQUARE_METRES_LABEL].includes(
        newItem.pricingStrategy
      )
    ) {
      newItem.sumLengths = { total: 0, forPrice: 0 };
      newItem.actualSumLengths = { total: 0, forPrice: 0 };
      newItem.margin = 0;
      newItem.subitems = [];
      newItem.actualLengthItems = [];
      delete newItem.quantity;
      delete newItem.actualQuantity;
    }

    if (material && material.uid) {
      newItem.materialName = material.name;
    }
    const withAccounting = state.getIn(["customers", "company", "accountingSyncAllowed"]);
    if (withAccounting) {
      newItem.accountingItemName = row.accountingItem?.name ?? "";
    }
    newItem.inventory = row.inventory;

    newItem.value = orderPrices
      .get("markupPrices")
      ?.toJS()
      .reduce((accumulatedValue, markup) => {
        if (!markup.isDeleted) {
          return accumulatedValue * (1 + markup.percent / 100);
        }
        return accumulatedValue;
      }, newItem.value);

    fulfilled(fromJS(newItem));
    dispatch(
      this.actions.changeProductPriceValue({
        itemId,
        type: "value",
        value: newItem.value,
      })
    );
    const ordersSettings = getState().getIn([
      "customers",
      "company",
      "settings",
      "ordersSettings",
      "orders",
    ]);

    if (ordersSettings) {
      const groupByCategory = ordersSettings.find(
        (setting) => setting.key === "GROUP_PRODUCT_BY_CATEGORY"
      );

      if (groupByCategory && groupByCategory.isSelected) {
        dispatch(this.actions.sortByCategory());
      }
    }
  };

  addProductKit = ({ getState, dispatch, fulfilled }, { productKit }) => {
    const state = getState();
    const itemId = state.getIn(["additionalProducts", "items"]).size;
    const orderPrices = state.getIn(["prices", "orderPrices"]);
    const { kitProducts, subKits, row, customPricing } = productKit;
    const newProductKit = this.initialItemState();

    const productKitItems = kitProducts
      .map((kitProduct) => {
        const item = {
          [PRODUCT_TYPE_ADDITIONAL_PRODUCT_LABEL]: {
            productName: kitProduct.product.name,
            kitProductPrice: customPricing
              ? 0
              : orderPrices
                  .get("markupPrices")
                  ?.toJS()
                  .reduce((accumulatedValue, markup) => {
                    if (!markup.isDeleted) {
                      return accumulatedValue * (1 + markup.percent / 100);
                    }
                    return accumulatedValue;
                  }, kitProduct.value),
            cost: customPricing ? 0 : kitProduct?.productRow?.cost ?? 0,
          },
          [PRODUCT_TYPE_LABOUR_PRODUCT_LABEL]: {
            productName: `${kitProduct.firstName} ${kitProduct.lastName}`,
            kitProductPrice: orderPrices
              .get("markupPrices")
              ?.toJS()
              .reduce((accumulatedValue, markup) => {
                if (!markup.isDeleted) {
                  return accumulatedValue * (1 + markup.percent / 100);
                }
                return accumulatedValue;
              }, kitProduct.hourlyRateCharged),
            cost: kitProduct.hourlyRateCost,
          },
        };

        const quantity = [
          PRICING_STRATEGY_LINEAL_METRES_LABEL,
          PRICING_STRATEGY_SQUARE_METRES_LABEL,
        ].includes(kitProduct.pricingStrategy)
          ? 0
          : kitProduct.quantity;
        const customAttributeValues = kitProduct?.customAttributeValues || [];
        const sumLengths =
          kitProduct.sumLengths && !Array.isArray(kitProduct.sumLengths)
            ? kitProduct.sumLengths
            : {
                total: 0,
                forPrice: 0,
              };
        const types =
          kitProduct.productRow?.types?.map((type) => {
            const matchedType = customAttributeValues.find(
              (attribute) => attribute.name === type.name
            );

            return {
              ...type,
              value: matchedType ? matchedType.value || "" : type.value || "",
            };
          }) ?? [];

        return {
          ...kitProduct,
          customValue: null,
          customValueLm: null,
          discount: null,
          isCompleted: false,
          isTaxFree: kitProduct.product.isTaxFree,
          lengthItems: kitProduct.lengthItems ? kitProduct.lengthItems : [],
          actualLengthItems: kitProduct.lengthItems ? kitProduct.lengthItems : [],
          sumLengths,
          actualSumLengths: sumLengths,
          productType: kitProduct.productType,
          pricingStrategy: kitProduct.pricingStrategy,
          productName: item[kitProduct.productType]?.productName,
          types,
          customAttributeValues: kitProduct.customAttributeValues,
          rowPriceLevel: kitProduct.rowPriceUid,
          value: Decimal(item[kitProduct.productType]?.kitProductPrice)
            .toDecimalPlaces(4)
            .toFixed(4),
          totalValue:
            kitProduct.pricingStrategy === PRICING_STRATEGY_LINEAL_METRES_LABEL
              ? Decimal(sumLengths.forPrice)
                  .dividedBy(1000)
                  .toDecimalPlaces(4)
                  .times(item[kitProduct.productType]?.kitProductPrice)
                  .toDecimalPlaces(2)
                  .toFixed(2)
              : Decimal(item[kitProduct.productType]?.kitProductPrice)
                  .times(quantity)
                  .toDecimalPlaces(2)
                  .toFixed(2),
          quantity: quantity,
          baseQuantity: quantity,
          actualQuantity: quantity,
          cost: item[kitProduct.productType]?.cost,
          markup: customPricing ? 0 : kitProduct?.productRow?.markup ?? 0,
          markupIsPercentage: kitProduct?.productRow?.markupIsPercentage ?? true,
          margin: 0,
          uid: null,
          kitProductUid: kitProduct.uid,
          labourUser: kitProduct.user,
        };
      })
      .map((kitProduct) => {
        if (kitProduct.pricingStrategy === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL) {
          const { customFormula } = kitProduct;
          const formula = customFormula.elements.map((e) => e.value).join("");
          const formulaOutput = evaluate(formula);
          const roundedFormulaOutput = mathjsRound(formulaOutput, 5);
          const formattedFormulaOutput = customFormula.isRoundedToNearestWholeNumber
            ? Math.round(roundedFormulaOutput)
            : Decimal(roundedFormulaOutput).toDecimalPlaces(4).toFixed(4);

          const kitCustomFormula = {
            ...customFormula,
            appliedElements: customFormula.elements,
          };

          return {
            ...kitProduct,
            customFormula: kitCustomFormula,
            customFormulaOutput: formattedFormulaOutput,
            actualCustomFormulaOutput: formattedFormulaOutput,
            totalValue: productKit.customPricing
              ? 0
              : Decimal(kitProduct.value)
                  .times(kitProduct.quantity)
                  .times(Decimal(roundedFormulaOutput).toDecimalPlaces(4))
                  .toDecimalPlaces(2)
                  .toFixed(2),
          };
        }

        return kitProduct;
      });

    const subKitItems = subKits.map((subKit) => {
      const subKitProducts = subKit.kitProducts
        .map((kitProduct) => {
          const item = {
            [PRODUCT_TYPE_ADDITIONAL_PRODUCT_LABEL]: {
              productName: kitProduct.product.name,
              kitProductPrice: customPricing
                ? 0
                : orderPrices
                    .get("markupPrices")
                    ?.toJS()
                    .reduce((accumulatedValue, markup) => {
                      if (!markup.isDeleted) {
                        return accumulatedValue * (1 + markup.percent / 100);
                      }
                      return accumulatedValue;
                    }, kitProduct.value),
              cost: customPricing ? 0 : kitProduct?.productRow?.cost ?? 0,
            },
            [PRODUCT_TYPE_LABOUR_PRODUCT_LABEL]: {
              productName: `${kitProduct.firstName} ${kitProduct.lastName}`,
              kitProductPrice: orderPrices
                .get("markupPrices")
                ?.toJS()
                .reduce((accumulatedValue, markup) => {
                  if (!markup.isDeleted) {
                    return accumulatedValue * (1 + markup.percent / 100);
                  }
                  return accumulatedValue;
                }, kitProduct.hourlyRateCharged),
              cost: kitProduct.hourlyRateCost,
            },
          };

          const quantity = [
            PRICING_STRATEGY_LINEAL_METRES_LABEL,
            PRICING_STRATEGY_SQUARE_METRES_LABEL,
          ].includes(kitProduct.pricingStrategy)
            ? 0
            : kitProduct.quantity;
          const customAttributeValues = kitProduct?.customAttributeValues || [];
          const sumLengths =
            kitProduct.sumLengths && !Array.isArray(kitProduct.sumLengths)
              ? kitProduct.sumLengths
              : {
                  total: 0,
                  forPrice: 0,
                };
          const types =
            kitProduct.productRow?.types?.map((type) => {
              const matchedType = customAttributeValues.find(
                (attribute) => attribute.name === type.name
              );

              return {
                ...type,
                value: matchedType ? matchedType.value || "" : type.value || "",
              };
            }) ?? [];

          return {
            ...kitProduct,
            customValue: null,
            customValueLm: null,
            discount: null,
            isCompleted: false,
            isTaxFree: kitProduct.product.isTaxFree,
            lengthItems: kitProduct.lengthItems ? kitProduct.lengthItems : [],
            actualLengthItems: kitProduct.lengthItems ? kitProduct.lengthItems : [],
            sumLengths,
            actualSumLengths: sumLengths,
            productType: kitProduct.productType,
            pricingStrategy: kitProduct.pricingStrategy,
            productName: item[kitProduct.productType]?.productName,
            types,
            customAttributeValues: kitProduct.customAttributeValues,
            rowPriceLevel: kitProduct.rowPriceUid,
            value: Decimal(item[kitProduct.productType]?.kitProductPrice)
              .toDecimalPlaces(4)
              .toFixed(4),
            totalValue:
              kitProduct.pricingStrategy === PRICING_STRATEGY_LINEAL_METRES_LABEL
                ? Decimal(sumLengths.forPrice)
                    .dividedBy(1000)
                    .toDecimalPlaces(4)
                    .times(item[kitProduct.productType]?.kitProductPrice)
                    .toDecimalPlaces(2)
                    .toFixed(2)
                : Decimal(item[kitProduct.productType]?.kitProductPrice)
                    .times(quantity)
                    .toDecimalPlaces(2)
                    .toFixed(2),
            quantity: quantity,
            baseQuantity: quantity,
            actualQuantity: quantity,
            cost: item[kitProduct.productType]?.cost,
            markup: customPricing ? 0 : kitProduct?.productRow?.markup ?? 0,
            markupIsPercentage: kitProduct?.productRow?.markupIsPercentage ?? true,
            margin: 0,
            uid: null,
            kitProductUid: kitProduct.uid,
            labourUser: kitProduct.user,
          };
        })
        .map((kitProduct) => {
          if (kitProduct.pricingStrategy === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL) {
            const { customFormula } = kitProduct;
            const formula = customFormula.elements.map((e) => e.value).join("");
            const formulaOutput = evaluate(formula);
            const roundedFormulaOutput = mathjsRound(formulaOutput, 5);
            const formattedFormulaOutput = customFormula.isRoundedToNearestWholeNumber
              ? Math.round(roundedFormulaOutput)
              : Decimal(roundedFormulaOutput).toDecimalPlaces(4).toFixed(4);

            const kitCustomFormula = {
              ...customFormula,
              appliedElements: customFormula.elements,
            };

            return {
              ...kitProduct,
              customFormula: kitCustomFormula,
              customFormulaOutput: formattedFormulaOutput,
              actualCustomFormulaOutput: formattedFormulaOutput,
              totalValue: productKit.customPricing
                ? 0
                : Decimal(kitProduct.value)
                    .times(kitProduct.quantity)
                    .times(Decimal(roundedFormulaOutput).toDecimalPlaces(4))
                    .toDecimalPlaces(2)
                    .toFixed(2),
            };
          }

          return kitProduct;
        });

      const totalValueOfSubKit = subKitProducts.reduce((accumulator, currentValue) => {
        return Decimal(accumulator).plus(currentValue.totalValue);
      }, 0);

      return {
        ...subKit,
        uid: null,
        subKitUid: subKit.uid,
        kitProducts: subKitProducts,
        quantity: 1,
        actualQuantity: 1,
        totalValue: Decimal(totalValueOfSubKit).toDecimalPlaces(2).toFixed(2),
        value: Decimal(totalValueOfSubKit).toDecimalPlaces(4).toFixed(4),
        deletedKitItems: [],
        isCompleted: false,
      };
    });

    const cost = row && row.cost ? row.cost : 0;
    const cartItemTotals = productKitItems.reduce((prev, current) => {
      return Decimal(prev).plus(current.totalValue);
    }, 0);
    const subKitTotals = subKitItems.reduce((prev, current) => {
      return Decimal(prev).plus(current.totalValue);
    }, 0);
    newProductKit.additionalProduct = productKit.uid;
    newProductKit.productType = PRODUCT_TYPE_PRODUCT_KIT_LABEL;
    newProductKit.name = productKit.name;
    newProductKit.categoryName = productKit.category;
    newProductKit.index = itemId;
    newProductKit.quantity = "1";
    newProductKit.actualQuantity = "1";
    newProductKit.value = customPricing
      ? productKit.selectedValue
      : Decimal(cartItemTotals).plus(subKitTotals).toDecimalPlaces(2).toFixed(2);
    newProductKit.totalValue = customPricing
      ? productKit.selectedValue
      : Decimal(cartItemTotals).plus(subKitTotals).toDecimalPlaces(2).toFixed(2);
    newProductKit.productKitItems = productKitItems;
    newProductKit.deletedKitItems = [];
    newProductKit.productKitDescription = productKit.description;
    newProductKit.subKits = subKitItems;
    newProductKit.customPricing = customPricing;
    newProductKit.displayKitItems = productKit.displayKitItems;
    newProductKit.displaySubKits = productKit.displaySubKits;
    newProductKit.types = customPricing ? row.attributes : [];
    newProductKit.row = customPricing ? row : null;
    newProductKit.rowPriceLevel = customPricing ? productKit.rowPriceUid : null;
    newProductKit.pricingStrategy = productKit.pricingStrategy;
    newProductKit.cost = customPricing ? cost : 0;
    newProductKit.markup = customPricing ? row.markup : 0;
    newProductKit.markupIsPercentage = customPricing ? row.markupIsPercentage : false;
    newProductKit.margin = customPricing ? Decimal(newProductKit.value).minus(cost).toFixed(2) : 0;

    fulfilled(fromJS(newProductKit));

    dispatch(
      this.actions.changeProductPriceValue({
        itemId,
        type: "value",
        value: newProductKit.totalValue,
      })
    );

    // if not custom priced kit then calculate markup for all kit items
    if (!customPricing) {
      productKitItems.map((kitProduct, kitIndex) => {
        dispatch(
          this.actions.changeProductKitItemPriceValue({
            itemIndex: itemId,
            cartIndex: kitIndex,
            type: "value",
            value: kitProduct.value,
          })
        );
      });
      subKitItems.forEach((subKit, subKitIndex) => {
        subKit.kitProducts.forEach((kitProduct, kitIndex) => {
          dispatch(
            this.actions.changeSubKitItemPriceValue({
              itemIndex: itemId,
              subKitIndex,
              cartIndex: kitIndex,
              type: "value",
              value: kitProduct.value,
            })
          );
        });
      });
    }
    const ordersSettings = getState().getIn([
      "customers",
      "company",
      "settings",
      "ordersSettings",
      "orders",
    ]);

    if (ordersSettings) {
      const groupByCategory = ordersSettings.find(
        (setting) => setting.key === "GROUP_PRODUCT_BY_CATEGORY"
      );

      if (groupByCategory && groupByCategory.isSelected) {
        dispatch(this.actions.sortByCategory());
      }
    }
  };

  addOnTheFlyProductItem = ({ dispatch, fulfilled, getState }, data) => {
    const state = getState();
    const items = state.getIn(["additionalProducts", "items"]);
    const itemIndex = items.size;
    const newItem = this.initialItemState();

    newItem.additionalProduct = null;
    newItem.productType = PRODUCT_TYPE_ON_THE_FLY_PRODUCT_LABEL;
    newItem.isStockTrackedByCompany = false;
    newItem.isTaxFree = false;
    newItem.name = data?.name ?? "";
    newItem.description = data?.productDescription || "";
    newItem.productDescription = data?.productDescription || "";
    newItem.index = itemIndex;
    newItem.pricingStrategy = data.pricingStrategy;
    newItem.customFormula = null;

    newItem.additionalProductRow = null; // Change to a uid if saved to a product catalogue;
    newItem.categoryName = "";
    newItem.types = [];
    newItem.inventory = null;
    newItem.cost = 0;
    newItem.markup = 0;
    newItem.markupIsPercentage = true;
    newItem.params = [];
    newItem.rowPriceLevel = null;
    newItem.value = data?.value || 0;
    newItem.materialName = "";
    newItem.rowPriceLevel = null;

    if (newItem.pricingStrategy === PRICING_STRATEGY_BASIC_QUANTITIES_LABEL) {
      newItem.subitems.push({ amount: "1" });
      newItem.actualQuantity = data?.actualQuantity || "1";
      newItem.quantity = data?.quantity || "1";
      newItem.margin = 0;
      newItem.discount = data?.discount || "0";
    } else if (
      [PRICING_STRATEGY_LINEAL_METRES_LABEL, PRICING_STRATEGY_SQUARE_METRES_LABEL].includes(
        newItem.pricingStrategy
      )
    ) {
      newItem.sumLengths = data?.sumLengths || { total: 0, forPrice: 0 };
      newItem.actualSumLengths = data?.actualSumLengths || { total: 0, forPrice: 0 };
      newItem.margin = 0;
      newItem.subitems = data?.subitems || [];
      newItem.actualLengthItems = data?.actualLengthItems || [];
      delete newItem.quantity;
      delete newItem.actualQuantity;
    }

    newItem.accountingItemName = "";

    fulfilled(fromJS(newItem));

    dispatch(
      this.actions.changeProductPriceValue({
        itemId: itemIndex,
        type: "value",
        value: data?.value || 0,
      })
    );

    const groupByCategory = state.getIn([
      "customers",
      "company",
      "settings",
      "ordersSettings",
      "orders",
      1,
      "isSelected",
    ]);
    if (groupByCategory) {
      dispatch(this.actions.sortByCategory());
    }
  };

  addNotesLineItem = ({ getState, token, dispatch, fulfilled }, data) => {
    const state = getState();
    const items = state.getIn(["additionalProducts", "items"]);
    const itemIndex = items.size;
    const newItem = this.initialItemState();

    newItem.notes = data?.notes || "";
    newItem.productType = PRODUCT_TYPE_NOTES_ITEM_LABEL;
    newItem.notesType = data.notesType;
    newItem.index = itemIndex;

    fulfilled(fromJS(newItem));
  };

  addLabourLineItem = ({ dispatch, fulfilled, getState }, data) => {
    const state = getState();
    const items = state.getIn(["additionalProducts", "items"]);
    const itemIndex = items.size;
    const newItem = this.initialItemState();

    newItem.tempUid = nanoid();
    newItem.additionalProduct = null;
    newItem.productType = PRODUCT_TYPE_LABOUR_PRODUCT_LABEL;
    newItem.isStockTrackedByCompany = false;
    newItem.isTaxFree = false;
    newItem.name = `${data.firstName} ${data.lastName}`;
    newItem.description = "";
    newItem.index = itemIndex;
    newItem.pricingStrategy = PRICING_STRATEGY_BASIC_QUANTITIES_LABEL;
    newItem.customFormula = null;

    newItem.labourUser = data.id;
    newItem.additionalProductRow = null; // Change to a uid if saved to a product catalogue;
    newItem.categoryName = "";
    newItem.types = [];
    newItem.inventory = null;
    newItem.cost = data.hourlyRateCost;
    newItem.markup = 0;
    newItem.markupIsPercentage = true;
    newItem.params = [];
    newItem.rowPriceLevel = null;
    newItem.value = data.hourlyRateCharged;
    newItem.materialName = "";
    newItem.rowPriceLevel = null;
    newItem.subitems.push({ amount: "1" });
    newItem.actualQuantity = "1";
    newItem.quantity = "1";
    newItem.margin = 0;

    newItem.accountingItemName = "";

    fulfilled(fromJS(newItem));
    dispatch(
      this.actions.changeProductPriceValue({
        itemId: itemIndex,
        type: "value",
        value: data.hourlyRateCharged,
      })
    );
  };

  editLabourLineItem = ({ dispatch, fulfilled, getState }, data) => {
    const state = getState();
    const editItem = state.getIn(["additionalProducts", "editItem"]).toJS();

    fulfilled({
      itemIndex: editItem.index,
      item: fromJS({
        ...editItem,
        labourUser: data.id,
        margin: 0,
        cost: data.hourlyRateCost,
        value: data.hourlyRateCharged,
        name: `${data.firstName} ${data.lastName}`,
        quantity: 1,
      }),
    });

    dispatch(
      this.actions.changeProductPriceValue({
        itemId: editItem.index,
        type: "value",
        value: data.hourlyRateCharged,
      })
    );
  };

  toggleProductIsTaxFree = ({ dispatch, fulfilled }, { itemIndex, status }) => {
    fulfilled({ itemIndex, status });
    dispatch(prices.actions.calcPriceAdditionalProducts());
  };

  toggleProductKitItemIsTaxFree = ({ dispatch, fulfilled }, { itemIndex, cartIndex, status }) => {
    fulfilled({ itemIndex, cartIndex, status });
    dispatch(prices.actions.calcPriceAdditionalProducts());
  };

  toggleSubKitItemIsTaxFree = (
    { dispatch, fulfilled },
    { itemIndex, subKitIndex, cartIndex, status }
  ) => {
    fulfilled({ itemIndex, subKitIndex, cartIndex, status });
    dispatch(prices.actions.calcPriceAdditionalProducts());
  };

  duplicateItem = ({ getState, token, dispatch, fulfilled }, id) => {
    const state = getState();
    const itemId = state.getIn(["additionalProducts", "items"]).size;
    const duplicateItem = state.getIn(["additionalProducts", "items", id]).toJS();

    const newItem = {
      ...duplicateItem,
      uid: "",
      tempUid: nanoid(),
      totalValue: "0.00",
      discount: "0.00",
      customValue: null,
      quantity: 1,
      actualQuantity: 1,
      margin: 0,
    };

    if (duplicateItem.pricingStrategy === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL) {
      return additionalProductsApi
        .getProduct(token, duplicateItem.additionalProduct)
        .then((response) => {
          const formattedResponse = camelcaseKeys(response.data, { deep: true });
          newItem.customFormula = {
            ...formattedResponse.customFormula,
            appliedElements: formattedResponse.customFormula.elements,
          };

          newItem.index = itemId;

          const formula = formattedResponse.customFormula.elements.map((e) => e.value).join("");
          const formulaOutput = evaluate(formula);
          const roundedFormulaOutput = mathjsRound(formulaOutput, 5);
          const formattedFormulaOutput = formattedResponse.customFormula
            .isRoundedToNearestWholeNumber
            ? Math.round(roundedFormulaOutput)
            : Decimal(roundedFormulaOutput).toDecimalPlaces(4).toFixed(4);

          newItem.customFormulaOutput = formattedFormulaOutput;
          newItem.actualCustomFormulaOutput = formattedFormulaOutput;

          fulfilled(fromJS(newItem));
          dispatch(
            this.actions.changeProductPriceValue({
              itemId,
              type: "value",
              value: newItem.value,
            })
          );
        });
    }

    if (
      [PRICING_STRATEGY_LINEAL_METRES_LABEL, PRICING_STRATEGY_SQUARE_METRES_LABEL].includes(
        duplicateItem.pricingStrategy
      )
    ) {
      newItem.sumLengths = { total: 0, forPrice: 0 };
      newItem.subitems = [];
      newItem.actualSumLengths = { total: 0, forPrice: 0 };
      newItem.actualLengthItems = [];
      delete newItem.quantity;
      delete newItem.actualQuantity;
    }
    newItem.index = itemId;
    fulfilled(fromJS(newItem));
    dispatch(
      this.actions.changeProductPriceValue({
        itemId,
        type: "value",
        value: newItem.value,
      })
    );
  };

  duplicateKitItem = ({ getState, token, fulfilled, dispatch }, data) => {
    const state = getState();
    const { itemIndex, cartIndex } = data;
    const items = state.getIn(["additionalProducts", "items", itemIndex, "productKitItems"]).toJS();
    const duplicateItem = state
      .getIn(["additionalProducts", "items", itemIndex, "productKitItems", cartIndex])
      .toJS();

    const newItem = {
      ...duplicateItem,
      uid: "",
      tempUid: nanoid(),
      totalValue: "0.00",
      quantity: "1",
      actualQuantity: "1",
      sumLengths: { total: 0, forPrice: 0 },
      actualSumlengths: { total: 0, forPrice: 0 },
      lengthItems: [],
      actualLengthItems: [],
    };

    if (duplicateItem.pricingStrategy === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL) {
      newItem.customFormula = {
        ...duplicateItem.customFormula,
        appliedElements: duplicateItem.customFormula.elements,
        uid: null,
        additionalProductUid: null,
        kitProductUid: null,
      };
      newItem.index = items.length;

      const formula = duplicateItem.customFormula.elements.map((e) => e.value).join("");
      const formulaOutput = evaluate(formula);
      const roundedFormulaOutput = mathjsRound(formulaOutput, 5);
      const formattedFormulaOutput = duplicateItem.customFormula.isRoundedToNearestWholeNumber
        ? Math.round(roundedFormulaOutput)
        : Decimal(roundedFormulaOutput).toDecimalPlaces(4).toFixed(4);

      newItem.customFormulaOutput = formattedFormulaOutput;
      newItem.actualCustomFormulaOutput = formattedFormulaOutput;
    }

    const newItems = [...items, newItem];

    fulfilled({ itemIndex, items: fromJS(newItems) });

    dispatch(
      this.actions.changeProductKitItemPriceValue({
        itemIndex,
        cartIndex: newItems.length - 1,
        type: "value",
        value: newItem.value,
      })
    );
  };

  duplicateSubKitItem = ({ getState, token, fulfilled, dispatch }, data) => {
    const state = getState();
    const { itemIndex, subKitIndex, cartIndex } = data;
    const items = state
      .getIn(["additionalProducts", "items", itemIndex, "subKits", subKitIndex, "kitProducts"])
      .toJS();
    const duplicateItem = state
      .getIn([
        "additionalProducts",
        "items",
        itemIndex,
        "subKits",
        subKitIndex,
        "kitProducts",
        cartIndex,
      ])
      .toJS();

    const newItem = {
      ...duplicateItem,
      uid: "",
      tempUid: nanoid(),
      totalValue: "0.00",
      quantity: "1",
      actualQuantity: "1",
      sumLengths: { total: 0, forPrice: 0 },
      actualSumlengths: { total: 0, forPrice: 0 },
      lengthItems: [],
      actualLengthItems: [],
    };

    if (duplicateItem.pricingStrategy === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL) {
      newItem.customFormula = {
        ...duplicateItem.customFormula,
        appliedElements: duplicateItem.customFormula.elements,
        uid: null,
        additionalProductUid: null,
        kitProductUid: null,
      };
      newItem.index = items.length;

      const formula = duplicateItem.customFormula.elements.map((e) => e.value).join("");
      const formulaOutput = evaluate(formula);
      const roundedFormulaOutput = mathjsRound(formulaOutput, 5);
      const formattedFormulaOutput = duplicateItem.customFormula.isRoundedToNearestWholeNumber
        ? Math.round(roundedFormulaOutput)
        : Decimal(roundedFormulaOutput).toDecimalPlaces(4).toFixed(4);

      newItem.customFormulaOutput = formattedFormulaOutput;
      newItem.actualCustomFormulaOutput = formattedFormulaOutput;
    }

    const newItems = [...items, newItem];

    fulfilled({ itemIndex, subKitIndex, items: fromJS(newItems) });

    dispatch(
      this.actions.changeSubKitItemPriceValue({
        itemIndex,
        subKitIndex,
        cartIndex: newItems.length - 1,
        type: "value",
        value: newItem.value,
      })
    );
  };

  addItemToKit = ({ getState, dispatch, fulfilled }, { productKit, payload, itemIndex }) => {
    const state = getState();

    const ordersSettings = state.getIn([
      "customers",
      "company",
      "settings",
      "ordersSettings",
      "orders",
    ]);
    const items = state.getIn(["additionalProducts", "items", itemIndex, "productKitItems"]).toJS();
    const categories = state.getIn(["additionalProducts", "categories"]).toJS();
    const product = state.getIn(["additionalProducts", "activeProduct"]).toJS();

    const { customPricing } = productKit;
    const { material, row, cellId, isMarkupValue } = payload;

    const category = categories?.find((category) => category.uid === product.category) || {};
    const companyId = productKit.productKitItems?.[0]?.product?.company ?? null;

    const kitItemPrice = isMarkupValue ? row.markedUpPrice : row.rowPrices[cellId]?.value || 0;
    const quantity = 1;

    const newItemKit = {
      customValue: null,
      customValueLm: null,
      discount: null,
      isCompleted: false,
      isTaxFree: product.isTaxFree,
      isDeleted: false,
      lengthItems: [],
      actualLengthItems: [],
      sumLengths: { total: 0, forPrice: 0 },
      actualSumLengths: { total: 0, forPrice: 0 },
      pricingStrategy: product.pricingStrategy,
      productType: PRODUCT_TYPES[PRODUCT_TYPE_ADDITIONAL_PRODUCT],
      productName: product.name,
      poCreated: false,
      types: row.types ? row.types.map((p) => ({ ...p, value: p.value || "" })) : [],
      rowPriceLevel: isMarkupValue ? null : row.rowPrices[cellId]?.uid,
      value: customPricing ? 0 : kitItemPrice,
      totalValue: 0,
      cost: customPricing ? 0 : row?.cost ?? 0,
      markup: customPricing ? 0 : row.markup,
      markupIsPercentage: row.markupIsPercentage,
      margin: 0,
      productRow: {
        material: "",
        colourOptions: null,
        colour: null,
        uid: null,
        types: [],
        rowPrices: [],
        inventory: null,
        accountingItem: null,
      },
      product: {
        category: category?.name,
        company: companyId,
        categoryUid: product.categoryUid,
        customFormula: product.customFormula,
        image: product.image,
        inLength: product.inLength,
        isDeleted: product.isDeleted,
        isStockTrackedByCompany: product.isStockTrackedByCompany,
        isTaxFree: product.isTaxFree,
        name: product.name,
        pricingStrategy: product.pricingStrategy,
        uid: product.uid,
      },
      customFormula: product.customFormula,
    };

    if (
      [PRICING_STRATEGY_BASIC_QUANTITIES_LABEL, PRICING_STRATEGY_CUSTOM_FORMULA_LABEL].includes(
        newItemKit.pricingStrategy
      )
    ) {
      newItemKit.actualQuantity = quantity;
      newItemKit.quantity = quantity;
      newItemKit.baseQuantity = quantity;
      newItemKit.margin =
        Decimal(newItemKit.cost) > 0
          ? Decimal(newItemKit.value).minus(newItemKit.cost).toDecimalPlaces(2).toFixed(2)
          : 0;
      newItemKit.totalValue = Decimal(newItemKit.value)
        .times(quantity)
        .toDecimalPlaces(2)
        .toFixed(2);

      if (newItemKit.pricingStrategy === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL) {
        const { customFormula } = product;
        const formula = customFormula.elements.map((e) => e.value).join("");
        const formulaOutput = evaluate(formula);
        const roundedFormulaOutput = mathjsRound(formulaOutput, 5);
        const formattedFormulaOutput = customFormula.isRoundedToNearestWholeNumber
          ? Math.round(roundedFormulaOutput)
          : Decimal(roundedFormulaOutput).toDecimalPlaces(4).toFixed(4);

        newItemKit.customFormula = { ...customFormula, appliedElements: customFormula.elements };
        newItemKit.customFormulaOutput = formattedFormulaOutput;
        newItemKit.actualCustomFormulaOutput = formattedFormulaOutput;
        newItemKit.totalValue = customPricing
          ? 0
          : Decimal(newItemKit.value)
              .times(newItemKit.quantity)
              .times(Decimal(roundedFormulaOutput).toDecimalPlaces(4))
              .toDecimalPlaces(2)
              .toFixed(2);
      }
    } else if (
      [PRICING_STRATEGY_LINEAL_METRES_LABEL, PRICING_STRATEGY_SQUARE_METRES_LABEL].includes(
        newItemKit.pricingStrategy
      )
    ) {
      delete newItemKit.quantity;
      delete newItemKit.baseQuantity;
      delete newItemKit.actualQuantity;
    }

    if (material && material.uid) {
      newItemKit.productRow.material = material.name;

      if (material.colours.length > 0) {
        newItemKit.productRow.colourOptions = orderBy(
          material.colours,
          [(colour) => colour],
          ["asc"]
        ).map((colour) => ({
          label: colour,
          value: colour,
        }));
        newItemKit.productRow.colour = row?.params[0]?.value ?? "";
      }
    }

    if (row) {
      newItemKit.productRow = {
        ...newItemKit.productRow,
        types: row.types,
        rowPrices: row.rowPrices,
        uid: row.uid,
        inventory: row.inventory,
        accountingItem: row.accountingItem,
      };
    }

    const newKitItems = [...items, newItemKit];

    fulfilled({ itemIndex, items: fromJS(newKitItems) });

    // if not custom priced kit then calculate markup for all kit items
    if (!customPricing) {
      dispatch(
        this.actions.changeProductKitItemQuantity({
          itemIndex,
          cartIndex: newKitItems.length - 1,
          quantity,
        })
      );

      newKitItems.forEach(({ value }, cartIndex) => {
        dispatch(
          this.actions.changeProductKitItemPriceValue({
            itemIndex,
            cartIndex,
            type: "value",
            value,
          })
        );
      });
    }

    if (ordersSettings) {
      const groupByCategory = ordersSettings.find(
        (setting) => setting.key === "GROUP_PRODUCT_BY_CATEGORY"
      );

      if (groupByCategory?.isSelected) {
        dispatch(this.actions.sortByCategory());
      }
    }
  };

  addOnTheFlyItemToKit = (
    { dispatch, fulfilled, getState },
    { productKit, pricingStrategy, itemIndex }
  ) => {
    const state = getState();
    const ordersSettings = state.getIn([
      "customers",
      "company",
      "settings",
      "ordersSettings",
      "orders",
    ]);
    const items = state.getIn(["additionalProducts", "items", itemIndex, "productKitItems"]).toJS();
    const { customPricing } = productKit;

    const quantity = 1;
    const newItemKit = {
      customValue: null,
      isCompleted: false,
      isTaxFree: false,
      markup: 0,
      productType: PRODUCT_TYPES[PRODUCT_TYPE_ON_THE_FLY_PRODUCT],
      quantity,
      baseQuantity: quantity,
      actualQuantity: quantity,
      markupIsPercentage: true,
      actualLengthItems: [],
      totalValue: 0,
      productDescription: "",
      cost: 0,
      margin: 0,
      isDeleted: false,
      value: 0,
      customFormula: null,
      rowPriceLevel: null,
      types: [],
      pricingStrategy,
      productName: "",
      discount: null,
      customValueLm: null,
      sumLengths: { total: 0, forPrice: 0 },
      formulaAnswer: null,
      poCreated: false,
      supplierForCreatedPo: null,
      productRow: {},
      product: {},
      actualSumLengths: { total: 0, forPrice: 0 },
      lengthItems: [],
    };

    if (
      [PRICING_STRATEGY_LINEAL_METRES_LABEL, PRICING_STRATEGY_SQUARE_METRES_LABEL].includes(
        pricingStrategy
      )
    ) {
      delete newItemKit.quantity;
      delete newItemKit.baseQuantity;
      delete newItemKit.actualQuantity;
    }

    const newKitItems = [...items, newItemKit];

    fulfilled({ itemIndex, items: fromJS(newKitItems) });

    // if not custom priced kit then calculate markup for all kit items
    if (!customPricing) {
      dispatch(
        this.actions.changeProductKitItemQuantity({
          itemIndex,
          cartIndex: newKitItems.length - 1,
          quantity,
        })
      );
      newKitItems.forEach(({ value }, cartIndex) => {
        dispatch(
          this.actions.changeProductKitItemPriceValue({
            itemIndex,
            cartIndex,
            type: "value",
            value,
          })
        );
      });
    }

    if (ordersSettings) {
      const groupByCategory = ordersSettings.find(
        (setting) => setting.key === "GROUP_PRODUCT_BY_CATEGORY"
      );

      if (groupByCategory?.isSelected) {
        dispatch(this.actions.sortByCategory());
      }
    }
  };

  addLabourLineItemToKit = (
    { dispatch, fulfilled, getState },
    { productKit, itemIndex, labour }
  ) => {
    const state = getState();
    const ordersSettings = state.getIn([
      "customers",
      "company",
      "settings",
      "ordersSettings",
      "orders",
    ]);
    const items = state.getIn(["additionalProducts", "items", itemIndex, "productKitItems"]).toJS();
    const { customPricing } = productKit;

    const quantity = 1;
    const newItemKit = {
      tempUid: nanoid(),
      customValue: null,
      isCompleted: false,
      isTaxFree: false,
      productType: PRODUCT_TYPES[PRODUCT_TYPE_LABOUR_ITEM],
      quantity,
      baseQuantity: quantity,
      actualQuantity: quantity,
      markupIsPercentage: true,
      actualLengthItems: [],
      totalValue: labour.hourlyRateCharged,
      productDescription: "",
      cost: customPricing ? 0 : labour?.hourlyRateCost ?? 0,
      markup: 0,
      margin: 0,
      isDeleted: false,
      value: labour.hourlyRateCharged,
      customFormula: null,
      rowPriceLevel: null,
      types: [],
      pricingStrategy: PRICING_STRATEGY_BASIC_QUANTITIES_LABEL,
      productName: `${labour.firstName} ${labour.lastName}`,
      discount: null,
      customValueLm: null,
      sumLengths: { total: 0, forPrice: 0 },
      formulaAnswer: null,
      poCreated: false,
      supplierForCreatedPo: null,
      productRow: {},
      product: {},
      actualSumLengths: { total: 0, forPrice: 0 },
      lengthItems: [],
      labourUser: labour.id,
    };

    const newKitItems = [...items, newItemKit];

    fulfilled({ itemIndex, items: fromJS(newKitItems) });

    // if not custom priced kit then calculate markup for all kit items

    if (!customPricing) {
      dispatch(
        this.actions.changeProductKitItemQuantity({
          itemIndex,
          cartIndex: newKitItems.length - 1,
          quantity,
        })
      );

      newKitItems.forEach(({ value }, cartIndex) => {
        dispatch(
          this.actions.changeProductKitItemPriceValue({
            itemIndex,
            cartIndex,
            type: "value",
            value,
          })
        );
      });
    }

    if (ordersSettings) {
      const groupByCategory = ordersSettings.find(
        (setting) => setting.key === "GROUP_PRODUCT_BY_CATEGORY"
      );

      if (groupByCategory?.isSelected) {
        dispatch(this.actions.sortByCategory());
      }
    }
  };

  addNotesLineItemToKit = ({ dispatch, fulfilled, getState }, { notesType, itemIndex }) => {
    const state = getState();
    const ordersSettings = state.getIn([
      "customers",
      "company",
      "settings",
      "ordersSettings",
      "orders",
    ]);
    const items = state.getIn(["additionalProducts", "items", itemIndex, "productKitItems"]).toJS();
    const newItemKit = {
      customValue: null,
      isCompleted: false,
      isTaxFree: false,
      markup: 0,
      productType: PRODUCT_TYPES[PRODUCT_TYPE_NOTES_ITEM],
      markupIsPercentage: true,
      actualLengthItems: [],
      totalValue: 0,
      productDescription: "",
      cost: 0,
      margin: 0,
      isDeleted: false,
      value: 0,
      customFormula: null,
      rowPriceLevel: null,
      types: [],
      pricingStrategy: PRICING_STRATEGY_BASIC_QUANTITIES_LABEL,
      productName: "",
      discount: null,
      customValueLm: null,
      sumLengths: { total: 0, forPrice: 0 },
      formulaAnswer: null,
      poCreated: false,
      supplierForCreatedPo: null,
      productRow: {},
      product: {},
      notesType,
      notes: "",
      actualSumLengths: { total: 0, forPrice: 0 },
      lengthItems: [],
      quantity: 0,
      baseQuantity: 0,
      actualQuantity: 0,
    };

    const newKitItems = [...items, newItemKit];

    fulfilled({ itemIndex, items: fromJS(newKitItems) });

    if (ordersSettings) {
      const groupByCategory = ordersSettings.find(
        (setting) => setting.key === "GROUP_PRODUCT_BY_CATEGORY"
      );

      if (groupByCategory?.isSelected) {
        dispatch(this.actions.sortByCategory());
      }
    }
  };

  saveProductEdit = (
    { getState, dispatch, fulfilled },
    { material, row, cellId, isMarkupValue }
  ) => {
    const state = getState();
    const editItem = state.getIn(["additionalProducts", "editItem"]).toJS();
    const itemId = state
      .getIn(["additionalProducts", "items"])
      .findIndex((item) => item.getIn(["uid"]) === editItem.uid);
    editItem.additionalProductRow = row.uid;
    if (row.params) {
      editItem.params = row.params;
    }

    if (isMarkupValue) {
      editItem.rowPriceLevel = null;
      editItem.value = row.markedUpPrice;
    } else {
      const { uid: rowPriceUid, value: priceValue } = row.rowPrices[cellId];
      editItem.rowPriceLevel = rowPriceUid;
      editItem.value = priceValue;
    }

    editItem.categoryName = state.getIn(["additionalProducts", "activeCategory", "name"]);
    editItem.inventory = row.inventory;

    editItem.types = row.types;
    if (material && material.uid) {
      editItem.materialName = material.name;
    }
    const withAccounting = state.getIn(["customers", "company", "accountingSyncAllowed"]);
    if (withAccounting) {
      editItem.accountingItemName = row.accountingItem?.name ?? "";
    }
    fulfilled({ editItem, itemId });
    dispatch(
      this.actions.changeProductPriceValue({
        itemId,
        type: "value",
        value: editItem.value,
      })
    );
  };

  addLengthThunk = ({ getState, dispatch, fulfilled }, { itemId, amount, length }) => {
    const subitemId = getState().getIn(["additionalProducts", "items", itemId, "subitems"]).size;

    fulfilled({
      itemId,
      subitemId,
      amount,
      length,
      minLength: getMinLengthProducts(getState()),
      sortLengths: getSortLengthsSetting(getState()),
    });

    const price = getState().getIn(["additionalProducts", "items", itemId, "value"]);

    dispatch(
      this.actions.changeProductPriceValue({
        itemId,
        type: "value",
        value: price,
      })
    );
    dispatch(prices.actions.calcPriceAdditionalProducts());
  };

  addActualLengthThunk = ({ getState, dispatch, fulfilled }, { itemId, amount, length }) => {
    const subitemId = getState().getIn([
      "additionalProducts",
      "items",
      itemId,
      "actualLengthItems",
    ]).size;

    fulfilled({
      itemId,
      subitemId,
      amount,
      length,
      minLength: getMinLengthProducts(getState()),
      sortLengths: getSortLengthsSetting(getState()),
    });

    const price = getState().getIn(["additionalProducts", "items", itemId, "value"]);
    dispatch(
      this.actions.changeProductPriceValue({
        itemId,
        type: "value",
        value: price,
      })
    );
  };

  addProductKitItemLengthThunk = (
    { getState, dispatch, fulfilled },
    { itemIndex, cartIndex, values }
  ) => {
    const { amount, length } = values;
    const lengthId = getState().getIn([
      "additionalProducts",
      "items",
      itemIndex,
      "productKitItems",
      cartIndex,
      "lengthItems",
    ]).size;

    fulfilled({
      itemIndex,
      cartIndex,
      lengthId,
      amount,
      length,
      minLength: getMinLengthProducts(getState()),
      sortLengths: getSortLengthsSetting(getState()),
    });

    const price = getState().getIn([
      "additionalProducts",
      "items",
      itemIndex,
      "productKitItems",
      cartIndex,
      "value",
    ]);
    dispatch(
      this.actions.changeProductKitItemPriceValue({
        itemIndex,
        cartIndex,
        type: "value",
        value: price,
      })
    );

    dispatch(prices.actions.calcPriceAdditionalProducts());
  };

  addProductKitItemDimensionThunk = (
    { getState, dispatch, fulfilled },
    { itemIndex, cartIndex, values }
  ) => {
    const { length, width } = values;
    const lengthId = getState().getIn([
      "additionalProducts",
      "items",
      itemIndex,
      "productKitItems",
      cartIndex,
      "lengthItems",
    ]).size;

    fulfilled({
      itemIndex,
      cartIndex,
      lengthId,
      length,
      width,
    });

    const price = getState().getIn([
      "additionalProducts",
      "items",
      itemIndex,
      "productKitItems",
      cartIndex,
      "value",
    ]);
    dispatch(
      this.actions.changeProductKitItemPriceValue({
        itemIndex,
        cartIndex,
        type: "value",
        value: price,
      })
    );

    dispatch(prices.actions.calcPriceAdditionalProducts());
  };

  addSubKitItemLengthThunk = (
    { getState, dispatch, fulfilled },
    { itemIndex, subKitIndex, cartIndex, values }
  ) => {
    const { amount, length } = values;
    const lengthId = getState().getIn([
      "additionalProducts",
      "items",
      itemIndex,
      "subKits",
      subKitIndex,
      "kitProducts",
      cartIndex,
      "lengthItems",
    ]).size;

    fulfilled({
      itemIndex,
      subKitIndex,
      cartIndex,
      lengthId,
      amount,
      length,
      minLength: getMinLengthProducts(getState()),
      sortLengths: getSortLengthsSetting(getState()),
    });

    const price = getState().getIn([
      "additionalProducts",
      "items",
      itemIndex,
      "subKits",
      subKitIndex,
      "kitProducts",
      cartIndex,
      "value",
    ]);

    dispatch(
      this.actions.changeSubKitItemPriceValue({
        itemIndex,
        subKitIndex,
        cartIndex,
        type: "value",
        value: price,
      })
    );

    dispatch(prices.actions.calcPriceAdditionalProducts());
  };

  addSubKitItemDimensionThunk = (
    { getState, dispatch, fulfilled },
    { itemIndex, subKitIndex, cartIndex, values }
  ) => {
    const { length, width } = values;
    const lengthId = getState().getIn([
      "additionalProducts",
      "items",
      itemIndex,
      "subKits",
      subKitIndex,
      "kitProducts",
      cartIndex,
      "lengthItems",
    ]).size;

    fulfilled({
      itemIndex,
      subKitIndex,
      cartIndex,
      lengthId,
      length,
      width,
    });

    const price = getState().getIn([
      "additionalProducts",
      "items",
      itemIndex,
      "subKits",
      subKitIndex,
      "kitProducts",
      cartIndex,
      "value",
    ]);

    dispatch(
      this.actions.changeSubKitItemPriceValue({
        itemIndex,
        subKitIndex,
        cartIndex,
        type: "value",
        value: price,
      })
    );

    dispatch(prices.actions.calcPriceAdditionalProducts());
  };

  addProductKitItemActualLengthThunk = (
    { getState, dispatch, fulfilled },
    { itemIndex, cartIndex, values }
  ) => {
    const { amount, length } = values;
    const lengthId = getState().getIn([
      "additionalProducts",
      "items",
      itemIndex,
      "productKitItems",
      cartIndex,
      "actualLengthItems",
    ]).size;

    fulfilled({
      itemIndex,
      cartIndex,
      lengthId,
      amount,
      length,
      minLength: getMinLengthProducts(getState()),
    });

    const price = getState().getIn([
      "additionalProducts",
      "items",
      itemIndex,
      "productKitItems",
      cartIndex,
      "value",
    ]);
    dispatch(
      this.actions.changeProductKitItemPriceValue({
        itemIndex,
        cartIndex,
        type: "value",
        value: price,
      })
    );
  };

  addProductKitItemActualDimensionThunk = (
    { getState, dispatch, fulfilled },
    { itemIndex, cartIndex, values }
  ) => {
    const { length, width } = values;
    const lengthId = getState().getIn([
      "additionalProducts",
      "items",
      itemIndex,
      "productKitItems",
      cartIndex,
      "actualLengthItems",
    ]).size;

    fulfilled({
      itemIndex,
      cartIndex,
      lengthId,
      length,
      width,
    });

    const price = getState().getIn([
      "additionalProducts",
      "items",
      itemIndex,
      "productKitItems",
      cartIndex,
      "value",
    ]);
    dispatch(
      this.actions.changeProductKitItemPriceValue({
        itemIndex,
        cartIndex,
        type: "value",
        value: price,
      })
    );
  };

  addSubKitItemActualLengthThunk = (
    { getState, dispatch, fulfilled },
    { itemIndex, subKitIndex, cartIndex, values }
  ) => {
    const { amount, length } = values;
    const lengthId = getState().getIn([
      "additionalProducts",
      "items",
      itemIndex,
      "subKits",
      subKitIndex,
      "kitProducts",
      cartIndex,
      "actualLengthItems",
    ]).size;

    fulfilled({
      itemIndex,
      subKitIndex,
      cartIndex,
      lengthId,
      amount,
      length,
      minLength: getMinLengthProducts(getState()),
    });

    const price = getState().getIn([
      "additionalProducts",
      "items",
      itemIndex,
      "subKits",
      subKitIndex,
      "kitProducts",
      cartIndex,
      "value",
    ]);
    dispatch(
      this.actions.changeSubKitItemPriceValue({
        itemIndex,
        subKitIndex,
        cartIndex,
        type: "value",
        value: price,
      })
    );
  };

  addSubKitItemActualDimensionThunk = (
    { getState, dispatch, fulfilled },
    { itemIndex, subKitIndex, cartIndex, values }
  ) => {
    const { length, width } = values;
    const lengthId = getState().getIn([
      "additionalProducts",
      "items",
      itemIndex,
      "subKits",
      subKitIndex,
      "kitProducts",
      cartIndex,
      "actualLengthItems",
    ]).size;

    fulfilled({
      itemIndex,
      subKitIndex,
      cartIndex,
      lengthId,
      length,
      width,
    });

    const price = getState().getIn([
      "additionalProducts",
      "items",
      itemIndex,
      "subKits",
      subKitIndex,
      "kitProducts",
      cartIndex,
      "value",
    ]);
    dispatch(
      this.actions.changeSubKitItemPriceValue({
        itemIndex,
        subKitIndex,
        cartIndex,
        type: "value",
        value: price,
      })
    );
  };

  addDimensionThunk = ({ getState, dispatch, fulfilled }, { itemId, length, width }) => {
    const subitemId = getState().getIn(["additionalProducts", "items", itemId, "subitems"]).size;

    fulfilled({
      itemId,
      subitemId,
      length,
      width,
    });

    const price = getState().getIn(["additionalProducts", "items", itemId, "value"]);

    dispatch(
      this.actions.changeProductPriceValue({
        itemId,
        type: "value",
        value: price,
      })
    );
    dispatch(prices.actions.calcPriceAdditionalProducts());
  };

  addActualDimensionThunk = ({ getState, dispatch, fulfilled }, { itemId, length, width }) => {
    const subitemId = getState().getIn([
      "additionalProducts",
      "items",
      itemId,
      "actualLengthItems",
    ]).size;

    fulfilled({
      itemId,
      subitemId,
      length,
      width,
    });

    const price = getState().getIn(["additionalProducts", "items", itemId, "value"]);
    dispatch(
      this.actions.changeProductPriceValue({
        itemId,
        type: "value",
        value: price,
      })
    );
  };

  deleteLengthThunk = ({ getState, dispatch, fulfilled }, { itemId, subitemId }) => {
    fulfilled({
      itemId,
      subitemId,
      minLength: getMinLengthProducts(getState()),
    });

    const price = getState().getIn(["additionalProducts", "items", itemId, "value"]);
    dispatch(
      this.actions.changeProductPriceValue({
        itemId,
        type: "value",
        value: price,
      })
    );
  };

  deleteActualLengthThunk = ({ getState, dispatch, fulfilled }, { itemId, subitemId }) => {
    fulfilled({
      itemId,
      subitemId,
      minLength: getMinLengthProducts(getState()),
    });

    const price = getState().getIn(["additionalProducts", "items", itemId, "value"]);
    dispatch(
      this.actions.changeProductPriceValue({
        itemId,
        type: "value",
        value: price,
      })
    );
  };

  deleteProductKitItemLengthThunk = (
    { getState, dispatch, fulfilled },
    { itemIndex, cartIndex, lengthId }
  ) => {
    fulfilled({
      itemIndex,
      cartIndex,
      lengthId,
      minLength: getMinLengthProducts(getState()),
    });

    const price = getState().getIn([
      "additionalProducts",
      "items",
      itemIndex,
      "productKitItems",
      cartIndex,
      "value",
    ]);
    dispatch(
      this.actions.changeProductKitItemPriceValue({
        itemIndex,
        cartIndex,
        type: "value",
        value: price,
      })
    );
  };

  deleteProductKitItemDimensionThunk = (
    { getState, dispatch, fulfilled },
    { itemIndex, cartIndex, lengthId }
  ) => {
    fulfilled({
      itemIndex,
      cartIndex,
      lengthId,
    });

    const price = getState().getIn([
      "additionalProducts",
      "items",
      itemIndex,
      "productKitItems",
      cartIndex,
      "value",
    ]);
    dispatch(
      this.actions.changeProductKitItemPriceValue({
        itemIndex,
        cartIndex,
        type: "value",
        value: price,
      })
    );
  };

  deleteSubKitItemLengthThunk = (
    { getState, dispatch, fulfilled },
    { itemIndex, subKitIndex, cartIndex, lengthId }
  ) => {
    fulfilled({
      itemIndex,
      subKitIndex,
      cartIndex,
      lengthId,
      minLength: getMinLengthProducts(getState()),
    });

    const price = getState().getIn([
      "additionalProducts",
      "items",
      itemIndex,
      "subKits",
      subKitIndex,
      "kitProducts",
      cartIndex,
      "value",
    ]);
    dispatch(
      this.actions.changeSubKitItemPriceValue({
        itemIndex,
        subKitIndex,
        cartIndex,
        type: "value",
        value: price,
      })
    );
  };

  deleteSubKitItemDimensionThunk = (
    { getState, dispatch, fulfilled },
    { itemIndex, subKitIndex, cartIndex, lengthId }
  ) => {
    fulfilled({
      itemIndex,
      subKitIndex,
      cartIndex,
      lengthId,
    });

    const price = getState().getIn([
      "additionalProducts",
      "items",
      itemIndex,
      "subKits",
      subKitIndex,
      "kitProducts",
      cartIndex,
      "value",
    ]);
    dispatch(
      this.actions.changeSubKitItemPriceValue({
        itemIndex,
        subKitIndex,
        cartIndex,
        type: "value",
        value: price,
      })
    );
  };

  deleteProductKitItemActualLengthThunk = (
    { getState, dispatch, fulfilled },
    { itemIndex, cartIndex, lengthId }
  ) => {
    fulfilled({
      itemIndex,
      cartIndex,
      lengthId,
      minLength: getMinLengthProducts(getState()),
    });

    const price = getState().getIn([
      "additionalProducts",
      "items",
      itemIndex,
      "productKitItems",
      cartIndex,
      "value",
    ]);
    dispatch(
      this.actions.changeProductKitItemPriceValue({
        itemIndex,
        cartIndex,
        type: "value",
        value: price,
      })
    );
  };

  deleteProductKitItemActualDimensionThunk = (
    { getState, dispatch, fulfilled },
    { itemIndex, cartIndex, lengthId }
  ) => {
    fulfilled({
      itemIndex,
      cartIndex,
      lengthId,
    });

    const price = getState().getIn([
      "additionalProducts",
      "items",
      itemIndex,
      "productKitItems",
      cartIndex,
      "value",
    ]);
    dispatch(
      this.actions.changeProductKitItemPriceValue({
        itemIndex,
        cartIndex,
        type: "value",
        value: price,
      })
    );
  };

  deleteSubKitItemActualLengthThunk = (
    { getState, dispatch, fulfilled },
    { itemIndex, subKitIndex, cartIndex, lengthId }
  ) => {
    fulfilled({
      itemIndex,
      subKitIndex,
      cartIndex,
      lengthId,
      minLength: getMinLengthProducts(getState()),
    });

    const price = getState().getIn([
      "additionalProducts",
      "items",
      itemIndex,
      "subKits",
      subKitIndex,
      "kitProducts",
      cartIndex,
      "value",
    ]);
    dispatch(
      this.actions.changeSubKitItemPriceValue({
        itemIndex,
        subKitIndex,
        cartIndex,
        type: "value",
        value: price,
      })
    );
  };

  deleteSubKitItemActualDimensionThunk = (
    { getState, dispatch, fulfilled },
    { itemIndex, subKitIndex, cartIndex, lengthId }
  ) => {
    fulfilled({
      itemIndex,
      subKitIndex,
      cartIndex,
      lengthId,
    });

    const price = getState().getIn([
      "additionalProducts",
      "items",
      itemIndex,
      "subKits",
      subKitIndex,
      "kitProducts",
      cartIndex,
      "value",
    ]);
    dispatch(
      this.actions.changeSubKitItemPriceValue({
        itemIndex,
        subKitIndex,
        cartIndex,
        type: "value",
        value: price,
      })
    );
  };

  deleteDimensionThunk = ({ getState, dispatch, fulfilled }, { itemId, subitemId }) => {
    fulfilled({
      itemId,
      subitemId,
    });

    const price = getState().getIn(["additionalProducts", "items", itemId, "value"]);
    dispatch(
      this.actions.changeProductPriceValue({
        itemId,
        type: "value",
        value: price,
      })
    );
  };

  deleteActualDimensionThunk = ({ getState, dispatch, fulfilled }, { itemId, subitemId }) => {
    fulfilled({
      itemId,
      subitemId,
    });

    const price = getState().getIn(["additionalProducts", "items", itemId, "value"]);
    dispatch(
      this.actions.changeProductPriceValue({
        itemId,
        type: "value",
        value: price,
      })
    );
  };

  changeQuantityThunk = ({ getState, dispatch, fulfilled }, { itemId, value }) => {
    fulfilled({ itemId, value });

    const price = getState().getIn(["additionalProducts", "items", itemId, "value"]);
    dispatch(
      this.actions.changeProductPriceValue({
        itemId,
        type: "value",
        value: price,
      })
    );
  };

  changeActualQuantityThunk = ({ getState, dispatch, fulfilled }, { itemId, value }) => {
    const item = getState().getIn(["additionalProducts", "items", itemId]).toJS();

    if (item.productType === PRODUCT_TYPE_LABOUR_PRODUCT_LABEL) {
      if (
        parseFloat(item.customActualQuantity || "0.0000") === 0 &&
        parseFloat(item.totalTrackedTime || "0.0000") === 0
      ) {
        fulfilled({
          itemId,
          value,
          customActualQuantity: value,
        });
      } else {
        const totalTrackedTime = item.totalTrackedTime
          ? parseFloat(item.totalTrackedTime || "0.0000")
          : 0;
        const customActualQuantity =
          parseFloat(value) -
          item.actualQuantity +
          parseFloat(item.customActualQuantity || "0.0000");
        const actualQuantity = totalTrackedTime + parseFloat(customActualQuantity);
        fulfilled({
          itemId,
          value: actualQuantity,
          customActualQuantity,
        });
      }
    } else {
      fulfilled({ itemId, value });
    }

    const price = getState().getIn(["additionalProducts", "items", itemId, "value"]);
    dispatch(
      this.actions.changeProductPriceValue({
        itemId,
        type: "value",
        value: price,
      })
    );
  };

  changeProductKitQuantityThunk = ({ getState, dispatch, fulfilled }, { itemId, value }) => {
    const currentKit = getState().getIn(["additionalProducts", "items", itemId]).toJS();
    const subKits = getState().getIn(["additionalProducts", "items", itemId, "subKits"]).toJS();
    const kitItems = getState()
      .getIn(["additionalProducts", "items", itemId, "productKitItems"])
      .toJS();
    const newKitItems = kitItems.map((kitItem) => {
      if (
        [PRICING_STRATEGY_LINEAL_METRES_LABEL, PRICING_STRATEGY_SQUARE_METRES_LABEL].includes(
          kitItem.pricingStrategy
        )
      ) {
        return { ...kitItem };
      }
      return {
        ...kitItem,
        quantity: Decimal(kitItem.baseQuantity).times(value).toDecimalPlaces(4).toFixed(4),
        actualQuantity: Decimal(kitItem.baseQuantity).times(value).toDecimalPlaces(4).toFixed(4),
        customActualQuantity: currentKit.customPricing ? "0.0000" : kitItem.customActualQuantity,
        totalTrackedTime: currentKit.customPricing ? "0.0000" : kitItem.totalTrackedTime,
      };
    });

    subKits.forEach((subKit, subKitIndex) => {
      dispatch(
        this.actions.changeSubKitQuantity({
          itemId,
          subKitIndex,
          value,
        })
      );
    });

    fulfilled({ itemId, value, newKitItems: fromJS(newKitItems) });

    newKitItems.map((kitItem, kitIndex) => {
      if (
        ![PRICING_STRATEGY_LINEAL_METRES_LABEL, PRICING_STRATEGY_SQUARE_METRES_LABEL].includes(
          kitItem.pricingStrategy
        )
      ) {
        dispatch(
          this.actions.changeProductKitItemPriceValue({
            itemIndex: itemId,
            cartIndex: kitIndex,
            type: "value",
            value: kitItem.value,
          })
        );
      }
    });

    if (currentKit.customPricing) {
      const price = getState().getIn(["additionalProducts", "items", itemId, "value"]);
      dispatch(
        this.actions.changeProductPriceValue({
          itemId,
          type: "value",
          value: price,
        })
      );
    }
  };

  changeSubKitQuantityThunk = (
    { getState, dispatch, fulfilled },
    { itemId, subKitIndex, value }
  ) => {
    const currentKit = getState().getIn(["additionalProducts", "items", itemId]).toJS();
    const subKitItems = getState()
      .getIn(["additionalProducts", "items", itemId, "subKits", subKitIndex, "kitProducts"])
      .toJS();
    const newSubKitItems = subKitItems.map((kitItem) => {
      if (
        [PRICING_STRATEGY_LINEAL_METRES_LABEL, PRICING_STRATEGY_SQUARE_METRES_LABEL].includes(
          kitItem.pricingStrategy
        )
      ) {
        return { ...kitItem };
      }
      return {
        ...kitItem,
        quantity: Decimal(kitItem.baseQuantity).times(value).toDecimalPlaces(4).toFixed(4),
        actualQuantity: Decimal(kitItem.baseQuantity).times(value).toDecimalPlaces(4).toFixed(4),
        customActualQuantity: currentKit.customPricing ? "0.0000" : kitItem.customActualQuantity,
        totalTrackedTime: currentKit.customPricing ? "0.0000" : kitItem.totalTrackedTime,
      };
    });

    fulfilled({ itemId, subKitIndex, value, newSubKitItems: fromJS(newSubKitItems) });

    newSubKitItems.map((kitItem, kitIndex) => {
      if (
        ![PRICING_STRATEGY_LINEAL_METRES_LABEL, PRICING_STRATEGY_SQUARE_METRES_LABEL].includes(
          kitItem.pricingStrategy
        )
      ) {
        dispatch(
          this.actions.changeSubKitItemPriceValue({
            itemIndex: itemId,
            subKitIndex,
            cartIndex: kitIndex,
            type: "value",
            value: kitItem.value,
          })
        );
      }
    });

    if (currentKit.customPricing) {
      const price = getState().getIn(["additionalProducts", "items", itemId, "value"]);
      dispatch(
        this.actions.changeProductPriceValue({
          itemId,
          type: "value",
          value: price,
        })
      );
    }
  };

  changeProductKitActualQuantityThunk = ({ getState, dispatch, fulfilled }, { itemId, value }) => {
    const currentKit = getState().getIn(["additionalProducts", "items", itemId]).toJS();
    const kitItems = getState()
      .getIn(["additionalProducts", "items", itemId, "productKitItems"])
      .toJS();
    const newKitItems = kitItems.map((kitItem) => {
      if (
        [PRICING_STRATEGY_LINEAL_METRES_LABEL, PRICING_STRATEGY_SQUARE_METRES_LABEL].includes(
          kitItem.pricingStrategy
        )
      ) {
        return { ...kitItem };
      }
      return {
        ...kitItem,
        actualQuantity: Decimal(kitItem.baseQuantity).times(value).toDecimalPlaces(4).toFixed(4),
      };
    });

    fulfilled({ itemId, value, newKitItems: fromJS(newKitItems) });

    newKitItems.map((kitItem, kitIndex) => {
      if (
        ![PRICING_STRATEGY_LINEAL_METRES_LABEL, PRICING_STRATEGY_SQUARE_METRES_LABEL].includes(
          kitItem.pricingStrategy
        )
      ) {
        dispatch(
          this.actions.changeProductKitItemPriceValue({
            itemIndex: itemId,
            cartIndex: kitIndex,
            type: "value",
            value: kitItem.value,
          })
        );
      }
    });

    if (currentKit.customPricing) {
      const price = getState().getIn(["additionalProducts", "items", itemId, "value"]);
      dispatch(
        this.actions.changeProductPriceValue({
          itemId,
          type: "value",
          value: price,
        })
      );
    }
  };

  changeSubKitActualQuantityThunk = (
    { getState, dispatch, fulfilled },
    { itemId, subKitIndex, value }
  ) => {
    const subKitItems = getState()
      .getIn(["additionalProducts", "items", itemId, "subKits", subKitIndex, "kitProducts"])
      .toJS();
    const newSubKitItems = subKitItems.map((kitItem) => {
      if (
        [PRICING_STRATEGY_LINEAL_METRES_LABEL, PRICING_STRATEGY_SQUARE_METRES_LABEL].includes(
          kitItem.pricingStrategy
        )
      ) {
        return { ...kitItem };
      }
      return {
        ...kitItem,
        actualQuantity: Decimal(kitItem.baseQuantity).times(value).toDecimalPlaces(4).toFixed(4),
      };
    });

    fulfilled({ itemId, subKitIndex, value, newSubKitItems: fromJS(newSubKitItems) });
  };

  changeProductKitItemQuantityThunk = (
    { getState, dispatch, fulfilled },
    { itemIndex, cartIndex, quantity }
  ) => {
    const kitQuantity = getState().getIn(["additionalProducts", "items", itemIndex, "quantity"]);
    fulfilled({ itemIndex, cartIndex, kitQuantity, quantity });

    const price = getState().getIn([
      "additionalProducts",
      "items",
      itemIndex,
      "productKitItems",
      cartIndex,
      "value",
    ]);
    dispatch(
      this.actions.changeProductKitItemPriceValue({
        itemIndex,
        cartIndex,
        type: "value",
        value: price,
      })
    );
  };

  changeSubKitItemQuantityThunk = (
    { getState, dispatch, fulfilled },
    { itemIndex, subKitIndex, cartIndex, quantity }
  ) => {
    const subKitQuantity = getState().getIn([
      "additionalProducts",
      "items",
      itemIndex,
      "subKits",
      subKitIndex,
      "quantity",
    ]);
    fulfilled({ itemIndex, subKitIndex, cartIndex, subKitQuantity, quantity });

    const price = getState().getIn([
      "additionalProducts",
      "items",
      itemIndex,
      "subKits",
      subKitIndex,
      "kitProducts",
      cartIndex,
      "value",
    ]);
    dispatch(
      this.actions.changeSubKitItemPriceValue({
        itemIndex,
        subKitIndex,
        cartIndex,
        type: "value",
        value: price,
      })
    );
  };

  changeProductKitItemActualQuantityThunk = (
    { getState, dispatch, fulfilled },
    { itemIndex, cartIndex, actualQuantity }
  ) => {
    const item = getState()
      .getIn(["additionalProducts", "items", itemIndex, "productKitItems", cartIndex])
      .toJS();

    const timeTrackingSettings = getState()
      .getIn(["customers", "company", "settings", "timeTrackingSettings"])
      .toJS();
    const logTrackedTimeAsActual =
      timeTrackingSettings?.settings.find((s) => s.key === "LOG_TRACKED_TIME_AS_ACTUAL")
        ?.isSelected ?? false;

    if (item.productType === PRODUCT_TYPE_LABOUR_PRODUCT_LABEL) {
      if (
        parseFloat(item.customActualQuantity || "0.0000") === 0 &&
        parseFloat(item.totalTrackedTime || "0.0000") === 0
      ) {
        fulfilled({
          itemIndex,
          cartIndex,
          actualQuantity,
          customActualQuantity: actualQuantity,
        });
      } else {
        const totalTrackedTime = item.totalTrackedTime
          ? parseFloat(item.totalTrackedTime || "0.0000")
          : 0;
        const customActualQuantity =
          parseFloat(actualQuantity) -
          item.actualQuantity +
          parseFloat(item.customActualQuantity || "0.0000");
        const newActualQuantity = totalTrackedTime + parseFloat(customActualQuantity);

        fulfilled({
          itemIndex,
          cartIndex,
          actualQuantity: newActualQuantity,
          customActualQuantity,
        });
      }
    } else {
      fulfilled({ itemIndex, cartIndex, actualQuantity });
    }
    const price = getState().getIn([
      "additionalProducts",
      "items",
      itemIndex,
      "productKitItems",
      cartIndex,
      "value",
    ]);
    dispatch(
      this.actions.changeProductKitItemPriceValue({
        itemIndex,
        cartIndex,
        type: "value",
        value: price,
      })
    );
  };

  changeSubKitItemActualQuantityThunk = (
    { getState, dispatch, fulfilled },
    { itemIndex, subKitIndex, cartIndex, actualQuantity }
  ) => {
    const item = getState().getIn([
      "additionalProducts",
      "items",
      itemIndex,
      "subKits",
      subKitIndex,
      "kitProducts",
      cartIndex,
    ]);

    if (item.productType === PRODUCT_TYPE_LABOUR_PRODUCT_LABEL) {
      if (
        parseFloat(item.customActualQuantity || "0.0000") === 0 &&
        parseFloat(item.totalTrackedTime || "0.0000") === 0
      ) {
        fulfilled({
          itemIndex,
          subKitIndex,
          cartIndex,
          actualQuantity,
          customActualQuantity: actualQuantity,
        });
      } else {
        const totalTrackedTime = item.totalTrackedTime
          ? parseFloat(item.totalTrackedTime || "0.0000")
          : 0;
        const customActualQuantity =
          parseFloat(actualQuantity) -
          item.actualQuantity +
          parseFloat(item.customActualQuantity || "0.0000");
        const newActualQuantity = totalTrackedTime + parseFloat(customActualQuantity);

        fulfilled({
          itemIndex,
          subKitIndex,
          cartIndex,
          actualQuantity: newActualQuantity,
          customActualQuantity,
        });
      }
    } else {
      fulfilled({ itemIndex, subKitIndex, cartIndex, actualQuantity });
    }

    const price = getState().getIn([
      "additionalProducts",
      "items",
      itemIndex,
      "subKits",
      subKitIndex,
      "kitProducts",
      cartIndex,
      "value",
    ]);
    dispatch(
      this.actions.changeSubKitItemPriceValue({
        itemIndex,
        subKitIndex,
        cartIndex,
        type: "value",
        value: price,
      })
    );
  };

  changeProductKitDescriptionThunk = ({ fulfilled }, { itemIndex, value }) => {
    fulfilled({ itemIndex, value });
  };

  deleteItemThunk = ({ dispatch, fulfilled }, id) => {
    fulfilled(id);
    dispatch(prices.actions.calcPriceAdditionalProducts());
  };

  deleteSubKitThunk = ({ getState, dispatch, fulfilled }, payload) => {
    const { itemIndex, subKitIndex } = payload;
    fulfilled({ itemIndex, subKitIndex });
    const productKit = getState().getIn(["additionalProducts", "items", itemIndex]).toJS();
    const firstCartItem = productKit.productKitItems[0].value;
    dispatch(
      this.actions.changeProductKitItemPriceValue({
        itemIndex,
        cartIndex: 0,
        type: "value",
        value: firstCartItem,
      })
    );
  };

  deleteKitItemThunk = ({ getState, dispatch, fulfilled }, data) => {
    fulfilled(data);
    const { itemIndex } = data;
    const productKit = getState().getIn(["additionalProducts", "items", itemIndex]).toJS();

    if (!productKit.customPricing) {
      const cartItemTotals = productKit.productKitItems.reduce((prev, current) => {
        return Decimal(prev).plus(current.customValue || current.totalValue);
      }, Decimal(0));

      dispatch(
        this.actions.changeProductPriceValue({
          itemId: itemIndex,
          type: "value",
          value: cartItemTotals
            .toDecimalPlaces(2)
            .dividedBy(productKit.quantity)
            .toDecimalPlaces(4)
            .toFixed(4),
        })
      );
    }
  };

  deleteSubKitItemThunk = ({ getState, dispatch, fulfilled }, data) => {
    fulfilled(data);
    const { itemIndex, subKitIndex } = data;
    const productKit = getState().getIn(["additionalProducts", "items", itemIndex]).toJS();
    const firstSubKitProduct = getState()
      .getIn(["additionalProducts", "items", itemIndex, "subKits", subKitIndex, "kitProducts", 0])
      .toJS();

    if (!productKit.customPricing) {
      dispatch(
        this.actions.changeSubKitItemPriceValue({
          itemIndex,
          subKitIndex,
          cartIndex: 0,
          type: "value",
          value: firstSubKitProduct.value,
        })
      );
    }
  };

  changeProductPriceThunk = ({ dispatch, fulfilled }, { itemId, type, value }) => {
    let price,
      isPercentage = false;
    if (type === "discount") {
      price = isPositiveNumber(value) && value <= 100 ? value : "0";
    } else if (type === "markup") {
      isPercentage = !value.toString().includes("$");
      const newPrice = value.replace("$", "").replace("%", "");
      price = isNumeric(newPrice) ? newPrice : "0.00";
    } else if (type === "cost") {
      price = isPositiveNumber(value) ? value : "0.00";
    } else {
      price = isNumeric(value) ? value : "0.00";
    }

    fulfilled({ itemId, type, value: price, isPercentage });
    dispatch(prices.actions.calcPriceAdditionalProducts());
  };

  changeProductKitItemPriceThunk = (
    { dispatch, fulfilled },
    { itemIndex, cartIndex, type, value }
  ) => {
    let price,
      isPercentage = false;
    if (type === "discount") {
      price = isPositiveNumber(value) && value <= 100 ? value : "0";
    } else if (type === "markup") {
      isPercentage = !value.toString().includes("$");
      const newPrice = value.replace("$", "").replace("%", "");
      price = isNumeric(newPrice) ? newPrice : "0.00";
    } else if (type === "cost") {
      price = isPositiveNumber(value) ? value : "0.00";
    } else {
      price = isNumeric(value) ? value : "0.00";
    }
    fulfilled({ itemIndex, cartIndex, type, value: price, isPercentage });
    dispatch(prices.actions.calcPriceAdditionalProducts());
  };

  changeSubKitItemPriceThunk = (
    { dispatch, fulfilled },
    { itemIndex, subKitIndex, cartIndex, type, value }
  ) => {
    let price,
      isPercentage = false;
    if (type === "discount") {
      price = isPositiveNumber(value) && value <= 100 ? value : "0";
    } else if (type === "markup") {
      isPercentage = !value.toString().includes("$");
      const newPrice = value.replace("$", "").replace("%", "");
      price = isNumeric(newPrice) ? newPrice : "0.00";
    } else if (type === "cost") {
      price = isPositiveNumber(value) ? value : "0.00";
    } else {
      price = isNumeric(value) ? value : "0.00";
    }
    fulfilled({ itemIndex, subKitIndex, cartIndex, type, value: price, isPercentage });
    dispatch(prices.actions.calcPriceAdditionalProducts());
  };

  selectMaterialThunk = ({ dispatch, fulfilled }, { material, isChecked }) => {
    if (material.uid) {
      if (isChecked) {
        fulfilled({ material, isChecked });
        dispatch(this.actions.createMaterialTable(material));
        return Promise.resolve({ material, isChecked });
      }

      fulfilled({ material, isChecked });
      dispatch(this.actions.removeMaterialTable(material));
      return Promise.resolve({ material, isChecked });
    }

    fulfilled({ material, isChecked });
    return Promise.resolve({ material, isChecked });
  };

  changeProductCustomFormulaValueThunk = ({ token, getState, dispatch, fulfilled }, payload) => {
    const { itemIndex, subKitIndex, cartIndex, isProductKit, isSubKit, key } = payload;

    fulfilled(payload);
    dispatch(prices.actions.calcPriceAdditionalProducts());

    const item = isSubKit
      ? getState()
          ?.getIn([
            "additionalProducts",
            "items",
            itemIndex,
            "subKits",
            subKitIndex,
            "kitProducts",
            cartIndex,
          ])
          ?.toJS()
      : isProductKit
      ? getState()
          ?.getIn(["additionalProducts", "items", itemIndex, "productKitItems", cartIndex])
          ?.toJS()
      : getState()?.getIn(["additionalProducts", "items", itemIndex])?.toJS();

    try {
      const formula = item.customFormula.elements.map((e) => e.value).join("");
      const formulaOutput = evaluate(formula);
      const roundedFormulaOutput = mathjsRound(formulaOutput, 5);
      if (isNaN(roundedFormulaOutput) || roundedFormulaOutput === Infinity) return;

      return additionalProductsApi
        .patchSaveCustomFormula(token, item.customFormula.uid, {
          name: item.customFormula.name,
          elements: item.customFormula.elements.map((e) => {
            if (e.type === "variable" && e.name === "") {
              return { ...e, name: "Label" };
            }
            return e;
          }),
          applied_elements: item.customFormula.elements.map((e) => {
            if (e.type === "variable" && e.name === "") {
              return { ...e, name: "Label" };
            }
            return e;
          }),
        })
        .then((response) => {
          if (key === "name") {
            const { data } = response;
            const element = data.elements.find((_, index) => index === payload.index);
            if (element) {
              fulfilled({ ...payload, value: element.name });
            }
          }
        });
    } catch (_) {
      /* Do nothing */
    }
  };

  changeProductActualCustomFormulaValueThunk = (
    { token, getState, dispatch, fulfilled },
    payload
  ) => {
    const { itemIndex, subKitIndex, cartIndex, isProductKit, isSubKit, key } = payload;

    fulfilled(payload);
    dispatch(prices.actions.calcPriceAdditionalProducts());

    const item = isSubKit
      ? getState()
          ?.getIn([
            "additionalProducts",
            "items",
            itemIndex,
            "subKits",
            subKitIndex,
            "kitProducts",
            cartIndex,
          ])
          ?.toJS()
      : isProductKit
      ? getState()
          ?.getIn(["additionalProducts", "items", itemIndex, "productKitItems", cartIndex])
          ?.toJS()
      : getState()?.getIn(["additionalProducts", "items", itemIndex])?.toJS();

    try {
      const formula = item.customFormula.appliedElements.map((e) => e.value).join("");
      const formulaOutput = evaluate(formula);
      const roundedFormulaOutput = mathjsRound(formulaOutput, 5);

      if (isNaN(roundedFormulaOutput) || roundedFormulaOutput === Infinity) return;

      return additionalProductsApi
        .patchSaveCustomFormula(token, item.customFormula.uid, {
          name: item.customFormula.name,
          applied_elements: item.customFormula.appliedElements.map((e) => {
            if (e.type === "variable" && e.name === "") {
              return { ...e, name: "Label" };
            }
            return e;
          }),
        })
        .then((response) => {
          if (key === "name") {
            const { data } = response;
            const element = data.appliedElements.find((_, index) => index === payload.index);
            if (element) {
              fulfilled({ ...payload, value: element.name });
            }
          }
        });
    } catch (_) {
      /* Do nothing */
    }
  };

  addProductCustomFormulaValueThunk = ({ getState, token, dispatch, fulfilled }, payload) => {
    fulfilled(payload);
    dispatch(prices.actions.calcPriceAdditionalProducts());

    const { itemIndex, subKitIndex, cartIndex, isProductKit, isSubKit } = payload;

    const item = isSubKit
      ? getState()
          ?.getIn([
            "additionalProducts",
            "items",
            itemIndex,
            "subKits",
            subKitIndex,
            "kitProducts",
            cartIndex,
          ])
          ?.toJS()
      : isProductKit
      ? getState()
          ?.getIn(["additionalProducts", "items", itemIndex, "productKitItems", cartIndex])
          ?.toJS()
      : getState()?.getIn(["additionalProducts", "items", itemIndex])?.toJS();

    try {
      const formulaOutput = item.customFormulaOutput;
      if (isNaN(formulaOutput) || formulaOutput === Infinity) return;

      return additionalProductsApi.patchSaveCustomFormula(token, item.customFormula.uid, {
        name: item.customFormula.name,
        elements: item.customFormula.elements,
        applied_elements: item.customFormula.elements,
      });
    } catch (_) {
      /* Do nothing */
    }
  };

  removeProductCustomFormulaValueThunk = ({ getState, token, dispatch, fulfilled }, payload) => {
    fulfilled(payload);
    dispatch(prices.actions.calcPriceAdditionalProducts());

    const { itemIndex, subKitIndex, cartIndex, isProductKit, isSubKit } = payload;

    const customFormula = isSubKit
      ? getState()
          ?.getIn([
            "additionalProducts",
            "items",
            itemIndex,
            "subKits",
            subKitIndex,
            "kitProducts",
            cartIndex,
            "customFormula",
          ])
          ?.toJS()
      : isProductKit
      ? getState()
          ?.getIn([
            "additionalProducts",
            "items",
            itemIndex,
            "productKitItems",
            cartIndex,
            "customFormula",
          ])
          ?.toJS()
      : getState()?.getIn(["additionalProducts", "items", itemIndex, "customFormula"])?.toJS();

    return additionalProductsApi.patchSaveCustomFormula(token, customFormula.uid, {
      name: customFormula.name,
      elements: customFormula.elements,
      applied_elements: customFormula.elements,
    });
  };

  saveProductToCatalogueThunk = async ({ dispatch, fulfilled, pendingAction, token }, payload) => {
    dispatch(pendingAction({ id: payload.index, isSavingToProductCatalogue: true }));
    const { data: priceLevels } = await priceLevelApi.getPriceLevels()();
    const { data: categories } = await additionalProductsApi.getCategories(token);
    const miscellaneousCategory = categories.find((c) => c.name === "Miscellaneous");

    const pricingStrategy = Object.keys(PRICING_STRATEGY).find(
      (key) => PRICING_STRATEGY[key] === payload.pricingStrategy
    );
    const data = {
      name: payload.name,
      category_uid: miscellaneousCategory?.uid ?? null,
      tables: [
        {
          material_uid: "",
          colours: [],
          fields: [
            {
              name: "Description",
              is_hide_order_summary: false,
              is_hide_invoice_pdf: false,
              is_hide_customer_view: false,
              is_hide_work_order_pdf: false,
            },
          ],
          values: [
            {
              inventory: [],
              types: [
                {
                  name: "Description",
                  value: payload.productDescription,
                  is_hide_order_summary: false,
                  is_hide_invoice_pdf: false,
                  is_hide_customer_view: false,
                  is_hide_work_order_pdf: false,
                },
              ],
              prices: [
                {
                  name: "Price (A)",
                  value: "",
                },
              ],
              accounting_item: {},
              is_deleted: false,
              suppliers: [],
              row_prices: priceLevels.map((p, index) => ({
                price_level_uid: p.uid,
                value: payload.value,
              })),
              cost: 0,
              markup: 0,
              marked_up_price: 0,
              markup_is_percentage: 0,
            },
          ],
          price_levels: priceLevels,
          hiddenPriceLevels: [],
        },
      ],
      pricing_strategy: parseInt(pricingStrategy),
      is_deleted: false,
      is_purchased_by_company: false,
      is_sold_by_company: true,
      is_stock_tracked_by_company: false,
      is_margin_tracked_by_company: false,
      suppliers: [],
      custom_formula: null,
    };

    const bodyFormData = new FormData();
    bodyFormData.append("json", JSON.stringify(snakecaseKeys(data)));
    bodyFormData.append("image", "");

    const { data: postResponse } = await productsApi.saveProduct(bodyFormData)();

    fulfilled({ ...postResponse, index: payload.index });

    dispatch(
      pendingAction({
        id: payload.index,
        isSavingToProductCatalogue: false,
      })
    );

    dispatch(
      alerts.actions.addAlert({
        type: "success",
        message: `Product saved to catalogue as ${payload.name}`,
        closeDelay: 3000,
      })
    );
  };

  selectMyobJob = ({ fulfilled }, { itemIndex, data }) => {
    fulfilled({ itemIndex, data });
  };

  selectMyobJobKitItem = ({ fulfilled }, { itemIndex, cartIndex, data }) => {
    fulfilled({ itemIndex, cartIndex, data });
  };

  selectMyobJobSubKitItem = ({ fulfilled }, { itemIndex, subKitIndex, cartIndex, data }) => {
    fulfilled({ itemIndex, subKitIndex, cartIndex, data });
  };
  applyMyobJobToAllLineItems = ({ fulfilled, dispatch, getState }, data) => {
    const items = getState().getIn(["additionalProducts", "items"]).toJS();

    items.forEach((item, itemIndex) => {
      dispatch(
        this.actions.selectMyobJob({
          itemIndex,
          data,
        })
      );

      if (item.productType === PRODUCT_TYPE_PRODUCT_KIT_LABEL) {
        if (Array.isArray(item.productKitItems)) {
          item?.productKitItems?.forEach((kitProduct, kitIndex) => {
            dispatch(
              this.actions.selectMyobJobKitItem({
                itemIndex,
                cartIndex: kitIndex,
                data,
              })
            );
          });
        }

        if (Array.isArray(item.subKits)) {
          item?.subKits?.forEach((subKit, subKitIndex) => {
            if (Array.isArray(subKit?.kitProducts)) {
              subKit?.kitProducts?.forEach((_, subkitProductIndex) => {
                dispatch(
                  this.actions.selectMyobJobSubKitItem({
                    itemIndex,
                    subKitIndex,
                    cartIndex: subkitProductIndex,
                    data,
                  })
                );
              });
            }
          });
        }
      }
    });
  };

  defineActions() {
    const clearActiveOrder = this.resetToInitialState(ACTIONS.CLEAR_ACTIVE_ORDER);
    const createNewOrder = this.createAction(ACTIONS.CREATE_NEW_ORDER);
    const setUids = this.createAction(ACTIONS.SET_UIDS);
    const setActiveTab = this.createAction(ACTIONS.SET_ACTIVE_TAB);
    const setSearchValue = this.set(ACTIONS.SET_SEARCH_VALUE, "searchValue");

    const searchProducts = this.thunkAction(
      ACTIONS.SEARCH_PRODUCTS,
      this.searchProductsThunk,
      ({ isPending }) => ({ isPending }),
      false
    );
    const searchProductsByCategory = this.thunkAction(
      ACTIONS.SEARCH_PRODUCTS_BY_CATEGORY,
      this.searchProductsByCategoryThunk,
      ({ isPending, id }) => ({ isPending, id }),
      false
    );
    const getProduct = this.thunkAction(ACTIONS.GET_PRODUCT, this.getProduct, true);
    const openOrder = this.thunkAction(ACTIONS.OPEN_ORDER, this.openOrderThunk);
    const resetOrderChanges = this.thunkAction(
      ACTIONS.RESET_ORDER_PRODUCTS,
      this.resetOrderProductsThunk
    );

    // actions for product editor
    const createNewProduct = this.createAction(ACTIONS.CREATE_NEW_PRODUCT);
    const setActiveProduct = this.createAction(ACTIONS.SET_ACTIVE_PRODUCT);
    const submitProduct = this.thunkAction(ACTIONS.SUBMIT_PRODUCT, this.submitProduct, true);
    const deleteProduct = this.thunkAction(
      ACTIONS.SET_DELETED_STATUS_PRODUCT,
      this.deleteProduct,
      ({ category, isPending, id }) => ({ category, isPending, id }),
      false
    );
    const undoDeleteProduct = this.thunkAction(
      ACTIONS.SET_DELETED_STATUS_PRODUCT,
      this.undoDeleteProduct,
      ({ category, isPending, id }) => ({ category, isPending, id }),
      false
    );
    const saveProductTable = this.createAction(ACTIONS.SAVE_PRODUCT_TABLE);
    const switchInLength = this.setIn(ACTIONS.SWITCH_IN_LENGTH, ["activeProduct", "inLength"]);

    const getCategories = this.thunkAction(ACTIONS.GET_CATEGORIES, this.getCategories, true);
    const createCategory = this.thunkAction(
      ACTIONS.CREATE_CATEGORY,
      this.createCategory,
      ({ isPending, id }) => ({ isPending, id }),
      false
    );
    const renameCategory = this.thunkAction(
      ACTIONS.RENAME_CATEGORY,
      this.renameCategoryThunk,
      ({ isPending, id }) => ({ isPending, id }),
      false
    );
    const deleteCategory = this.thunkAction(
      ACTIONS.DELETE_CATEGORY,
      this.deleteCategory,
      ({ isPending }, id) => ({ id, isPending })
    );
    const undoDeleteCategory = this.thunkAction(
      ACTIONS.DELETE_CATEGORY,
      this.undoDeleteCategory,
      ({ isPending }, id) => ({ id, isPending })
    );
    const changeCategory = this.createAction(ACTIONS.CHANGE_PRODUCT_CATEGORY);

    // actions for adding to order
    const sortByCategory = this.createAction(ACTIONS.SORT_BY_CATEGORY);
    const addItem = this.thunkAction(ACTIONS.ADD_ADDITIONAL_PRODUCT, this.addItem);
    const addProductKit = this.thunkAction(ACTIONS.ADD_ADDITIONAL_PRODUCT, this.addProductKit);
    const addOnTheFlyProductItem = this.thunkAction(
      ACTIONS.ADD_ON_THE_FLY_PRODUCT_ITEM,
      this.addOnTheFlyProductItem
    );
    const addNotesLineItem = this.thunkAction(ACTIONS.ADD_NOTES_LINE_ITEM, this.addNotesLineItem);
    const addLabourLineItem = this.thunkAction(
      ACTIONS.ADD_LABOUR_LINE_ITEM,
      this.addLabourLineItem
    );
    const editLabourLineItem = this.thunkAction(
      ACTIONS.EDIT_LABOUR_LINE_ITEM,
      this.editLabourLineItem
    );
    const setEditItem = this.createAction(ACTIONS.EDIT_ADDITIONAL_PRODUCT);
    const saveProductEdit = this.thunkAction(
      ACTIONS.SAVE_EDIT_ADDITIONAL_PRODUCT,
      this.saveProductEdit
    );
    const duplicateItem = this.thunkAction(
      ACTIONS.DUPLICATE_ADDITIONAL_PRODUCT,
      this.duplicateItem
    );
    const duplicateKitItem = this.thunkAction(ACTIONS.DUPLICATE_KIT_ITEM, this.duplicateKitItem);
    const duplicateSubKitItem = this.thunkAction(
      ACTIONS.DUPLICATE_SUB_KIT_ITEM,
      this.duplicateSubKitItem
    );
    const deleteItem = this.thunkAction(ACTIONS.REMOVE_PRODUCT_FROM_ORDER, this.deleteItemThunk);
    const deleteSubKit = this.thunkAction(
      ACTIONS.DELETE_SUB_KIT_FROM_ORDER,
      this.deleteSubKitThunk
    );
    const deleteKitItem = this.thunkAction(
      ACTIONS.REMOVE_KIT_ITEM_FROM_ORDER,
      this.deleteKitItemThunk
    );
    const deleteSubKitItem = this.thunkAction(
      ACTIONS.REMOVE_SUB_KIT_ITEM_FROM_ORDER,
      this.deleteSubKitItemThunk
    );
    const addLength = this.thunkAction(ACTIONS.ADD_PRODUCT_LENGTH, this.addLengthThunk);
    const addActualLength = this.thunkAction(
      ACTIONS.ADD_PRODUCT_ACTUAL_LENGTH,
      this.addActualLengthThunk
    );

    const addProductKitItemLength = this.thunkAction(
      ACTIONS.ADD_PRODUCT_KIT_ITEM_LENGTH,
      this.addProductKitItemLengthThunk
    );

    const addProductKitItemDimension = this.thunkAction(
      ACTIONS.ADD_PRODUCT_KIT_ITEM_DIMENSION,
      this.addProductKitItemDimensionThunk
    );

    const addSubKitItemLength = this.thunkAction(
      ACTIONS.ADD_SUB_KIT_ITEM_LENGTH,
      this.addSubKitItemLengthThunk
    );
    const addSubKitItemDimension = this.thunkAction(
      ACTIONS.ADD_SUB_KIT_ITEM_DIMENSION,
      this.addSubKitItemDimensionThunk
    );

    const addProductKitItemActualLength = this.thunkAction(
      ACTIONS.ADD_PRODUCT_KIT_ITEM_ACTUAL_LENGTH,
      this.addProductKitItemActualLengthThunk
    );

    const addProductKitItemActualDimension = this.thunkAction(
      ACTIONS.ADD_PRODUCT_KIT_ITEM_ACTUAL_DIMENSION,
      this.addProductKitItemActualDimensionThunk
    );

    const addSubKitItemActualLength = this.thunkAction(
      ACTIONS.ADD_SUB_KIT_ITEM_ACTUAL_LENGTH,
      this.addSubKitItemActualLengthThunk
    );
    const addSubKitItemActualDimension = this.thunkAction(
      ACTIONS.ADD_SUB_KIT_ITEM_ACTUAL_DIMENSION,
      this.addSubKitItemActualDimensionThunk
    );

    const addDimension = this.thunkAction(ACTIONS.ADD_PRODUCT_DIMENSION, this.addDimensionThunk);
    const addActualDimension = this.thunkAction(
      ACTIONS.ADD_PRODUCT_ACTUAL_DIMENSION,
      this.addActualDimensionThunk
    );

    const deleteLength = this.thunkAction(ACTIONS.DELETE_PRODUCT_LENGTH, this.deleteLengthThunk);
    const deleteActualLength = this.thunkAction(
      ACTIONS.DELETE_PRODUCT_ACTUAL_LENGTH,
      this.deleteActualLengthThunk
    );
    const deleteProductKitItemLength = this.thunkAction(
      ACTIONS.DELETE_PRODUCT_KIT_ITEM_LENGTH,
      this.deleteProductKitItemLengthThunk
    );
    const deleteProductKitItemDimension = this.thunkAction(
      ACTIONS.DELETE_PRODUCT_KIT_ITEM_DIMENSION,
      this.deleteProductKitItemDimensionThunk
    );
    const deleteSubKitItemLength = this.thunkAction(
      ACTIONS.DELETE_SUB_KIT_ITEM_LENGTH,
      this.deleteSubKitItemLengthThunk
    );
    const deleteSubKitItemDimension = this.thunkAction(
      ACTIONS.DELETE_SUB_KIT_ITEM_DIMENSION,
      this.deleteSubKitItemDimensionThunk
    );
    const deleteProductKitItemActualLength = this.thunkAction(
      ACTIONS.DELETE_PRODUCT_KIT_ITEM_ACTUAL_LENGTH,
      this.deleteProductKitItemActualLengthThunk
    );
    const deleteProductKitItemActualDimension = this.thunkAction(
      ACTIONS.DELETE_PRODUCT_KIT_ITEM_ACTUAL_DIMENSION,
      this.deleteProductKitItemActualDimensionThunk
    );
    const deleteSubKitItemActualLength = this.thunkAction(
      ACTIONS.DELETE_SUB_KIT_ITEM_ACTUAL_LENGTH,
      this.deleteSubKitItemActualLengthThunk
    );
    const deleteSubKitItemActualDimension = this.thunkAction(
      ACTIONS.DELETE_SUB_KIT_ITEM_ACTUAL_DIMENSION,
      this.deleteSubKitItemActualDimensionThunk
    );

    const deleteDimension = this.thunkAction(
      ACTIONS.DELETE_PRODUCT_DIMENSION,
      this.deleteDimensionThunk
    );
    const deleteActualDimension = this.thunkAction(
      ACTIONS.DELETE_PRODUCT_ACTUAL_DIMENSION,
      this.deleteActualDimensionThunk
    );
    const changeQuantity = this.thunkAction(
      ACTIONS.CHANGE_PRODUCT_QUANTITY,
      this.changeQuantityThunk
    );
    const changeActualQuantity = this.thunkAction(
      ACTIONS.CHANGE_PRODUCT_ACTUAL_QUANTITY,
      this.changeActualQuantityThunk
    );

    const changeProductKitQuantity = this.thunkAction(
      ACTIONS.CHANGE_PRODUCT_KIT_QUANTITY,
      this.changeProductKitQuantityThunk
    );

    const changeSubKitQuantity = this.thunkAction(
      ACTIONS.CHANGE_SUB_KIT_QUANTITY,
      this.changeSubKitQuantityThunk
    );

    const changeProductKitActualQuantity = this.thunkAction(
      ACTIONS.CHANGE_PRODUCT_KIT_ACTUAL_QUANTITY,
      this.changeProductKitActualQuantityThunk
    );

    const changeSubKitActualQuantity = this.thunkAction(
      ACTIONS.CHANGE_SUB_KIT_ACTUAL_QUANTITY,
      this.changeSubKitActualQuantityThunk
    );

    const changeProductKitItemQuantity = this.thunkAction(
      ACTIONS.CHANGE_PRODUCT_KIT_ITEM_QUANTITY,
      this.changeProductKitItemQuantityThunk
    );

    const changeSubKitItemQuantity = this.thunkAction(
      ACTIONS.CHANGE_SUB_KIT_ITEM_QUANTITY,
      this.changeSubKitItemQuantityThunk
    );

    const changeProductKitItemActualQuantity = this.thunkAction(
      ACTIONS.CHANGE_PRODUCT_KIT_ITEM_ACTUAL_QUANTITY,
      this.changeProductKitItemActualQuantityThunk
    );

    const changeSubKitItemActualQuantity = this.thunkAction(
      ACTIONS.CHANGE_SUB_KIT_ITEM_ACTUAL_QUANTITY,
      this.changeSubKitItemActualQuantityThunk
    );

    const changeProductKitDescription = this.thunkAction(
      ACTIONS.CHANGE_PRODUCT_KIT_DESCRIPTION,
      this.changeProductKitDescriptionThunk
    );

    const changeItemOrderIndex = this.createAction(ACTIONS.CHANGE_ITEM_ORDER_INDEX);
    const changeProductKitItemOrderIndex = this.createAction(
      ACTIONS.CHANGE_PRODUCT_KIT_ITEM_ORDER_INDEX
    );
    const changeSubKitItemOrderIndex = this.createAction(ACTIONS.CHANGE_SUB_KIT_ITEM_ORDER_INDEX);
    const changeSubKitOrderIndex = this.createAction(ACTIONS.CHANGE_SUB_KIT_ORDER_INDEX);
    const changeProductName = this.createAction(ACTIONS.CHANGE_PRODUCT_NAME);
    const changeSubKitTitle = this.createAction(ACTIONS.CHANGE_SUB_KIT_TITLE);
    const changeProductNotes = this.createAction(ACTIONS.CHANGE_PRODUCT_NOTES);
    const changeProductDescription = this.createAction(ACTIONS.CHANGE_PRODUCT_DESCRIPTION);
    const changeProductType = this.createAction(ACTIONS.CHANGE_PRODUCT_TYPE);
    const changeProductKitItemName = this.createAction(ACTIONS.CHANGE_PRODUCT_KIT_ITEM_NAME);
    const changeSubKitItemName = this.createAction(ACTIONS.CHANGE_SUB_KIT_ITEM_NAME);
    const changeProductKitItemType = this.createAction(ACTIONS.CHANGE_PRODUCT_KIT_ITEM_TYPE);
    const changeSubKitItemType = this.createAction(ACTIONS.CHANGE_SUB_KIT_ITEM_TYPE);

    const changeProductPriceValue = this.thunkAction(
      ACTIONS.CHANGE_PRICE_VALUE,
      this.changeProductPriceThunk
    );

    const changeProductKitItemPriceValue = this.thunkAction(
      ACTIONS.CHANGE_PRODUCT_KIT_ITEM_PRICE_VALUE,
      this.changeProductKitItemPriceThunk
    );
    const changeSubKitItemPriceValue = this.thunkAction(
      ACTIONS.CHANGE_SUB_KIT_ITEM_PRICE_VALUE,
      this.changeSubKitItemPriceThunk
    );

    const changeProductKitItemNotes = this.createAction(ACTIONS.CHANGE_PRODUCT_KIT_ITEM_NOTES);
    const changeSubKitItemNotes = this.createAction(ACTIONS.CHANGE_SUB_KIT_ITEM_NOTES);
    const changeProductKitItemDescription = this.createAction(
      ACTIONS.CHANGE_PRODUCT_KIT_ITEM_DESCRIPTION
    );
    const changeSubKitItemDescription = this.createAction(ACTIONS.CHANGE_SUB_KIT_ITEM_DESCRIPTION);

    const toggleProductIsTaxFree = this.thunkAction(
      ACTIONS.CHANGE_PRODUCT_IS_TAX_FREE,
      this.toggleProductIsTaxFree
    );
    const toggleProductKitItemIsTaxFree = this.thunkAction(
      ACTIONS.CHANGE_PRODUCT_KIT_ITEM_IS_TAX_FREE,
      this.toggleProductKitItemIsTaxFree
    );
    const toggleSubKitItemIsTaxFree = this.thunkAction(
      ACTIONS.CHANGE_SUB_KIT_ITEM_IS_TAX_FREE,
      this.toggleSubKitItemIsTaxFree
    );

    const toggleProductCompletedStatus = this.createAction(ACTIONS.CHANGE_PRODUCT_COMPLETED_STATUS);

    const toggleProductKitItemCompletedStatus = this.createAction(
      ACTIONS.CHANGE_PRODUCT_KIT_ITEM_COMPLETED_STATUS
    );
    const toggleSubKitItemCompletedStatus = this.createAction(
      ACTIONS.CHANGE_SUB_KIT_ITEM_COMPLETED_STATUS
    );
    const toggleSubKitCompletedStatus = this.createAction(ACTIONS.CHANGE_SUB_KIT_COMPLETED_STATUS);

    const selectMaterial = this.thunkAction(ACTIONS.SELECT_MATERIAL, this.selectMaterialThunk);
    const createMaterialTable = this.createAction(ACTIONS.CREATE_MATERIAL_TABLE);
    const removeMaterialTable = this.createAction(ACTIONS.REMOVE_MATERIAL_TABLE);
    const changeProductCustomFormulaValue = this.thunkAction(
      ACTIONS.CHANGE_CUSTOM_FORMULA_VALUES,
      this.changeProductCustomFormulaValueThunk
    );
    const changeProductActualCustomFormulaValue = this.thunkAction(
      ACTIONS.CHANGE_ACTUAL_CUSTOM_FORMULA_VALUES,
      this.changeProductActualCustomFormulaValueThunk
    );
    const addProductCustomFormulaValue = this.thunkAction(
      ACTIONS.ADD_CUSTOM_FORMULA_VALUE,
      this.addProductCustomFormulaValueThunk
    );

    const removeProductCustomFormulaValue = this.thunkAction(
      ACTIONS.REMOVE_CUSTOM_FORMULA_VALUE,
      this.removeProductCustomFormulaValueThunk
    );
    const updateUidOfCustomFormula = this.createAction(ACTIONS.UPDATE_UID_OF_CUSTOM_FORMULA);
    const saveProductToCatalogue = this.thunkAction(
      ACTIONS.SAVE_PRODUCT_TO_CATALOGUE,
      this.saveProductToCatalogueThunk,
      ({ isSavingToProductCatalogue, id }) => ({ isSavingToProductCatalogue, id }),
      false
    );

    const addItemToKit = this.thunkAction(ACTIONS.ADD_ITEM_TO_KIT, this.addItemToKit);
    const addOnTheFlyItemToKit = this.thunkAction(
      ACTIONS.ADD_ON_THE_FLY_ITEM_TO_KIT,
      this.addOnTheFlyItemToKit
    );
    const addLabourLineItemToKit = this.thunkAction(
      ACTIONS.ADD_LABOUR_LINE_ITEM_TO_KIT,
      this.addLabourLineItemToKit
    );

    const addNotesLineItemToKit = this.thunkAction(
      ACTIONS.ADD_NOTES_ITEM_TO_KIT,
      this.addNotesLineItemToKit
    );

    const selectMyobJob = this.thunkAction(ACTIONS.SELECT_MYOB_JOB, this.selectMyobJob);
    const selectMyobJobKitItem = this.thunkAction(
      ACTIONS.SELECT_MYOB_JOB_TO_KIT_ITEM,
      this.selectMyobJobKitItem
    );
    const selectMyobJobSubKitItem = this.thunkAction(
      ACTIONS.SELECT_MYOB_JOB_TO_SUB_KIT_ITEM,
      this.selectMyobJobSubKitItem
    );
    const applyMyobJobToAllLineItems = this.thunkAction(
      ACTIONS.APPLY_MYOB_JOB_TO_ALL_LINE_ITEMS,
      this.applyMyobJobToAllLineItems
    );
    return {
      clearActiveOrder,
      createNewOrder,
      setUids,
      setActiveTab,
      setSearchValue,
      searchProducts,
      searchProductsByCategory,
      getProduct,
      openOrder,
      resetOrderChanges,

      createNewProduct,
      setActiveProduct,
      submitProduct,
      deleteProduct,
      undoDeleteProduct,
      saveProductTable,
      switchInLength,

      getCategories,
      createCategory,
      renameCategory,
      deleteCategory,
      undoDeleteCategory,
      changeCategory,

      sortByCategory,
      addItem,
      addProductKit,
      addOnTheFlyProductItem,
      addLabourLineItem,
      editLabourLineItem,
      addNotesLineItem,
      duplicateItem,
      duplicateKitItem,
      duplicateSubKitItem,
      setEditItem,
      saveProductEdit,
      addLength,
      addActualLength,
      addProductKitItemLength,
      addProductKitItemDimension,
      addSubKitItemLength,
      addSubKitItemDimension,
      addProductKitItemActualLength,
      addProductKitItemActualDimension,
      addSubKitItemActualLength,
      addSubKitItemActualDimension,
      addDimension,
      addActualDimension,
      deleteLength,
      deleteActualLength,
      deleteProductKitItemLength,
      deleteProductKitItemDimension,
      deleteProductKitItemActualLength,
      deleteProductKitItemActualDimension,
      deleteSubKitItemLength,
      deleteSubKitItemDimension,
      deleteSubKitItemActualLength,
      deleteSubKitItemActualDimension,
      deleteDimension,
      deleteActualDimension,
      changeQuantity,
      changeActualQuantity,
      changeProductKitQuantity,
      changeSubKitQuantity,
      changeProductKitActualQuantity,
      changeSubKitActualQuantity,
      changeProductKitItemQuantity,
      changeSubKitItemQuantity,
      changeProductKitItemActualQuantity,
      changeSubKitItemActualQuantity,
      changeProductKitDescription,
      deleteItem,
      deleteSubKit,
      deleteKitItem,
      deleteSubKitItem,
      changeItemOrderIndex,
      changeProductKitItemOrderIndex,
      changeSubKitItemOrderIndex,
      changeSubKitOrderIndex,
      changeProductName,
      changeSubKitTitle,
      changeProductNotes,
      changeProductDescription,
      changeProductType,
      changeProductKitItemName,
      changeSubKitItemName,
      changeProductKitItemType,
      changeSubKitItemType,

      changeProductPriceValue,
      changeProductKitItemPriceValue,
      changeSubKitItemPriceValue,
      changeProductKitItemNotes,
      changeSubKitItemNotes,
      changeProductKitItemDescription,
      changeSubKitItemDescription,

      selectMaterial,
      createMaterialTable,
      removeMaterialTable,
      toggleProductIsTaxFree,
      toggleProductKitItemIsTaxFree,
      toggleSubKitItemIsTaxFree,

      toggleProductCompletedStatus,
      toggleProductKitItemCompletedStatus,
      toggleSubKitItemCompletedStatus,
      toggleSubKitCompletedStatus,

      changeProductCustomFormulaValue,
      changeProductActualCustomFormulaValue,
      addProductCustomFormulaValue,
      removeProductCustomFormulaValue,
      updateUidOfCustomFormula,
      saveProductToCatalogue,

      addItemToKit,
      addOnTheFlyItemToKit,
      addLabourLineItemToKit,
      addNotesLineItemToKit,
      selectMyobJob,
      selectMyobJobKitItem,
      selectMyobJobSubKitItem,
      applyMyobJobToAllLineItems,
    };
  }

  removeItemFromOrder = (_state, { payload }) => {
    const itemDeleted = _state.getIn(["items", payload]);
    if (isNew(itemDeleted)) {
      const newItems = _state.get("items").delete(payload);
      return _state.set("items", newItems);
    }

    return _state.setIn(["items", payload, "isDeleted"], true);
  };

  removeSubKitFromOrder = (_state, { payload }) => {
    const { itemIndex, subKitIndex } = payload;

    const subKits = _state
      .getIn(["items", itemIndex, "subKits"])
      .delete(subKitIndex)
      .toJS()
      .map((subKit, sIndex) => ({
        ...subKit,
        subKitIndex: sIndex,
      }));

    return _state.setIn(["items", itemIndex, "subKits"], fromJS(subKits));
  };

  removeKitItemFromOrder = (_state, { payload }) => {
    const { itemIndex, cartIndex } = payload;

    const productKit = _state.getIn(["items", itemIndex]).toJS();
    const deletedKitItemUid = _state.getIn([
      "items",
      itemIndex,
      "productKitItems",
      cartIndex,
      "uid",
    ]);
    const oldDeletedKitItems = _state.getIn(["items", itemIndex, "deletedKitItems"]).toJS();
    const deletedKitItems = [...oldDeletedKitItems, deletedKitItemUid];
    const productKitItems = _state
      .getIn(["items", itemIndex, "productKitItems"])
      .delete(cartIndex)
      .toJS();

    const newProductKit = {
      ...productKit,
      productKitItems: productKitItems.map((kitItem, kitIndex) => ({
        ...kitItem,
        cartIndex: kitIndex,
      })),
      deletedKitItems,
    };
    return _state.setIn(["items", itemIndex], fromJS(newProductKit));
  };

  removeSubKitItemFromOrder = (_state, { payload }) => {
    const { itemIndex, subKitIndex, cartIndex } = payload;

    const subKit = _state.getIn(["items", itemIndex, "subKits", subKitIndex]).toJS();
    const deletedKitItemUid = _state.getIn([
      "items",
      itemIndex,
      "subKits",
      subKitIndex,
      "kitProducts",
      cartIndex,
      "uid",
    ]);
    const oldDeletedKitItems = _state
      .getIn(["items", itemIndex, "subKits", subKitIndex, "deletedKitItems"])
      .toJS();
    const deletedKitItems = [...oldDeletedKitItems, deletedKitItemUid];
    const subKitItems = _state
      .getIn(["items", itemIndex, "subKits", subKitIndex, "kitProducts"])
      .delete(cartIndex)
      .toJS();

    const newSubKit = {
      ...subKit,
      kitProducts: subKitItems.map((subKitItem, subKitIndex) => ({
        ...subKitItem,
        subKitIndex,
      })),
      deletedKitItems,
    };

    return _state.setIn(["items", itemIndex, "subKits", subKitIndex], fromJS(newSubKit));
  };

  setActiveProduct = (_state, activeProduct) => {
    if (activeProduct) {
      _state.set(
        "activeProduct",
        fromJS({
          ...activeProduct,
          category: activeProduct.categoryUid,
        })
      );
    } else {
      _state.set("activeProduct", null);
    }
  };

  deleteProductPending = (_state, { category, id, isPending }) => {
    if (id > -1) {
      _state.setIn(["dataServer", "items", category, id, "isPending"], isPending);
    }

    if (_state.get("activeProduct")) {
      _state.setIn(["activeProduct", "isPending"], isPending);
    }
  };

  deleteProductFulfilled = (_state, { category, id, isDeleted }) => {
    if (id > -1) {
      _state.setIn(["dataServer", "items", category, id, "isDeleted"], isDeleted);
    }

    _state.set("activeProduct", null);
    _state.set("activeCategory", null);
  };

  addProductLength = (_state, value) => {
    const { itemId, subitemId, amount, length, minLength, sortLengths } = value;
    _state.setIn(["items", itemId, "subitems", subitemId], fromJS({ amount, length }));

    const items = _state.getIn(["items", itemId, "subitems"]);
    const lengths = calcItemLength(items.toJS(), minLength);
    _state.setIn(["items", itemId, "sumLengths"], fromJS(lengths));
    _state.setIn(["items", itemId, "actualSumLengths"], fromJS(lengths));

    const subitems = _state.getIn(["items", itemId, "subitems"]).toJS();

    if (sortLengths === "largest_to_smallest") {
      const orderedLengths = subitems.sort((a, b) => parseFloat(b.length) - parseFloat(a.length));
      _state.setIn(["items", itemId, "subitems"], fromJS(orderedLengths));
      _state.setIn(["items", itemId, "actualLengthItems"], fromJS(orderedLengths));
    } else if (sortLengths === "smallest_to_largest") {
      const orderedLengths = subitems.sort((a, b) => parseFloat(a.length) - parseFloat(b.length));
      _state.setIn(["items", itemId, "subitems"], fromJS(orderedLengths));
      _state.setIn(["items", itemId, "actualLengthItems"], fromJS(orderedLengths));
    } else {
      _state.setIn(["items", itemId, "subitems"], fromJS(subitems));
      _state.setIn(["items", itemId, "actualLengthItems"], fromJS(subitems));
    }
  };

  addProductActualLength = (_state, value) => {
    const { itemId, subitemId, amount, length, minLength, sortLengths } = value;

    _state.setIn(["items", itemId, "actualLengthItems", subitemId], fromJS({ amount, length }));

    const items = _state.getIn(["items", itemId, "actualLengthItems"]);
    const lengths = calcItemLength(items.toJS(), minLength);
    _state.setIn(["items", itemId, "actualSumLengths"], fromJS(lengths));

    const actualLengthItems = _state.getIn(["items", itemId, "actualLengthItems"]).toJS();

    if (sortLengths === "largest_to_smallest") {
      const orderedLengths = actualLengthItems.sort(
        (a, b) => parseFloat(b.length) - parseFloat(a.length)
      );
      _state.setIn(["items", itemId, "actualLengthItems"], fromJS(orderedLengths));
    } else if (sortLengths === "smallest_to_largest") {
      const orderedLengths = actualLengthItems.sort(
        (a, b) => parseFloat(a.length) - parseFloat(b.length)
      );
      _state.setIn(["items", itemId, "actualLengthItems"], fromJS(orderedLengths));
    } else {
      _state.setIn(["items", itemId, "actualLengthItems"], fromJS(actualLengthItems));
    }
  };

  addProductKitItemLength = (_state, value) => {
    const { itemIndex, cartIndex, lengthId, amount, length, minLength, sortLengths } = value;
    _state.setIn(
      ["items", itemIndex, "productKitItems", cartIndex, "lengthItems", lengthId],
      fromJS({ amount, length })
    );
    _state.setIn(
      ["items", itemIndex, "productKitItems", cartIndex, "actualLengthItems", lengthId],
      fromJS({ amount, length })
    );

    const items = _state.getIn(["items", itemIndex, "productKitItems", cartIndex, "lengthItems"]);

    if (sortLengths === "largest_to_smallest") {
      const orderedLengths = items.sort((a, b) => parseFloat(b.length) - parseFloat(a.length));
      _state.setIn(
        ["items", itemIndex, "productKitItems", cartIndex, "lengthItems"],
        fromJS(orderedLengths)
      );
    } else if (sortLengths === "smallest_to_largest") {
      const orderedLengths = items.sort((a, b) => parseFloat(a.length) - parseFloat(b.length));
      _state.setIn(
        ["items", itemIndex, "productKitItems", cartIndex, "lengthItems"],
        fromJS(orderedLengths)
      );
    } else {
      _state.setIn(
        ["items", itemIndex, "productKitItems", cartIndex, "lengthItems"],
        fromJS(items)
      );
    }

    const lengths = calcItemLength(items.toJS(), minLength);
    _state.setIn(["items", itemIndex, "productKitItems", cartIndex, "sumLengths"], fromJS(lengths));
    _state.setIn(
      ["items", itemIndex, "productKitItems", cartIndex, "actualSumLengths"],
      fromJS(lengths)
    );
  };

  addProductKitItemDimension = (_state, value) => {
    const { itemIndex, cartIndex, lengthId, length, width } = value;
    _state.setIn(
      ["items", itemIndex, "productKitItems", cartIndex, "lengthItems", lengthId],
      fromJS({ length, width })
    );
    _state.setIn(
      ["items", itemIndex, "productKitItems", cartIndex, "actualLengthItems", lengthId],
      fromJS({ length, width })
    );
    const items = _state.getIn(["items", itemIndex, "productKitItems", cartIndex, "lengthItems"]);

    const totalSqm = calculateTotalSquareMeters(items.toJS());
    _state.setIn(
      ["items", itemIndex, "productKitItems", cartIndex, "sumLengths"],
      fromJS(totalSqm)
    );
    _state.setIn(
      ["items", itemIndex, "productKitItems", cartIndex, "actualSumLengths"],
      fromJS(totalSqm)
    );
  };

  addSubKitItemLength = (_state, value) => {
    const { itemIndex, subKitIndex, cartIndex, lengthId, amount, length, minLength, sortLengths } =
      value;
    _state.setIn(
      [
        "items",
        itemIndex,
        "subKits",
        subKitIndex,
        "kitProducts",
        cartIndex,
        "lengthItems",
        lengthId,
      ],
      fromJS({ amount, length })
    );
    _state.setIn(
      [
        "items",
        itemIndex,
        "subKits",
        subKitIndex,
        "kitProducts",
        cartIndex,
        "actualLengthItems",
        lengthId,
      ],
      fromJS({ amount, length })
    );

    const items = _state.getIn([
      "items",
      itemIndex,
      "subKits",
      subKitIndex,
      "kitProducts",
      cartIndex,
      "lengthItems",
    ]);

    if (sortLengths === "largest_to_smallest") {
      const orderedLengths = items.sort((a, b) => parseFloat(b.length) - parseFloat(a.length));
      _state.setIn(
        ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "lengthItems"],
        fromJS(orderedLengths)
      );
    } else if (sortLengths === "smallest_to_largest") {
      const orderedLengths = items.sort((a, b) => parseFloat(a.length) - parseFloat(b.length));
      _state.setIn(
        ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "lengthItems"],
        fromJS(orderedLengths)
      );
    } else {
      _state.setIn(
        ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "lengthItems"],
        fromJS(items)
      );
    }

    const lengths = calcItemLength(items.toJS(), minLength);
    _state.setIn(
      ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "sumLengths"],
      fromJS(lengths)
    );
    _state.setIn(
      ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "actualSumLengths"],
      fromJS(lengths)
    );
  };

  addSubKitItemDimension = (_state, value) => {
    const { itemIndex, subKitIndex, cartIndex, lengthId, length, width } = value;
    _state.setIn(
      [
        "items",
        itemIndex,
        "subKits",
        subKitIndex,
        "kitProducts",
        cartIndex,
        "lengthItems",
        lengthId,
      ],
      fromJS({ length, width })
    );
    _state.setIn(
      [
        "items",
        itemIndex,
        "subKits",
        subKitIndex,
        "kitProducts",
        cartIndex,
        "actualLengthItems",
        lengthId,
      ],
      fromJS({ length, width })
    );

    const items = _state.getIn([
      "items",
      itemIndex,
      "subKits",
      subKitIndex,
      "kitProducts",
      cartIndex,
      "lengthItems",
    ]);

    const totalSqm = calculateTotalSquareMeters(items.toJS());
    _state.setIn(
      ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "sumLengths"],
      fromJS(totalSqm)
    );
    _state.setIn(
      ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "actualSumLengths"],
      fromJS(totalSqm)
    );
  };

  addProductKitItemActualLength = (_state, value) => {
    const { itemIndex, cartIndex, lengthId, amount, length, minLength } = value;
    _state.setIn(
      ["items", itemIndex, "productKitItems", cartIndex, "actualLengthItems", lengthId],
      fromJS({ amount, length })
    );

    const items = _state.getIn([
      "items",
      itemIndex,
      "productKitItems",
      cartIndex,
      "actualLengthItems",
    ]);
    const lengths = calcItemLength(items.toJS(), minLength);
    _state.setIn(
      ["items", itemIndex, "productKitItems", cartIndex, "actualSumLengths"],
      fromJS(lengths)
    );
  };

  addProductKitItemActualDimension = (_state, value) => {
    const { itemIndex, cartIndex, lengthId, length, width } = value;
    _state.setIn(
      ["items", itemIndex, "productKitItems", cartIndex, "actualLengthItems", lengthId],
      fromJS({ length, width })
    );

    const items = _state.getIn([
      "items",
      itemIndex,
      "productKitItems",
      cartIndex,
      "actualLengthItems",
    ]);
    const totalSqm = calculateTotalSquareMeters(items.toJS());
    _state.setIn(
      ["items", itemIndex, "productKitItems", cartIndex, "actualSumLengths"],
      fromJS(totalSqm)
    );
  };

  addSubKitItemActualLength = (_state, value) => {
    const { itemIndex, subKitIndex, cartIndex, lengthId, amount, length, minLength } = value;
    _state.setIn(
      [
        "items",
        itemIndex,
        "subKits",
        subKitIndex,
        "kitProducts",
        cartIndex,
        "actualLengthItems",
        lengthId,
      ],
      fromJS({ amount, length })
    );

    const items = _state.getIn([
      "items",
      itemIndex,
      "subKits",
      subKitIndex,
      "kitProducts",
      cartIndex,
      "actualLengthItems",
    ]);
    const lengths = calcItemLength(items.toJS(), minLength);
    _state.setIn(
      ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "actualSumLengths"],
      fromJS(lengths)
    );
  };

  addSubKitItemActualDimension = (_state, value) => {
    const { itemIndex, subKitIndex, cartIndex, lengthId, length, width } = value;
    _state.setIn(
      [
        "items",
        itemIndex,
        "subKits",
        subKitIndex,
        "kitProducts",
        cartIndex,
        "actualLengthItems",
        lengthId,
      ],
      fromJS({ length, width })
    );

    const items = _state.getIn([
      "items",
      itemIndex,
      "subKits",
      subKitIndex,
      "kitProducts",
      cartIndex,
      "actualLengthItems",
    ]);
    const totalSqm = calculateTotalSquareMeters(items.toJS());
    _state.setIn(
      ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "actualSumLengths"],
      fromJS(totalSqm)
    );
  };

  addProductDimension = (_state, value) => {
    const { itemId, subitemId, length, width } = value;
    _state.setIn(["items", itemId, "subitems", subitemId], fromJS({ length, width }));

    const items = _state.getIn(["items", itemId, "subitems"]);
    const totalSqm = calculateTotalSquareMeters(items.toJS());

    _state.setIn(["items", itemId, "sumLengths"], fromJS(totalSqm));
    _state.setIn(["items", itemId, "actualSumLengths"], fromJS(totalSqm));

    const subitems = _state.getIn(["items", itemId, "subitems"]).toJS();
    _state.setIn(["items", itemId, "subitems"], fromJS(subitems));
    _state.setIn(["items", itemId, "actualLengthItems"], fromJS(subitems));
  };

  addProductActualDimension = (_state, value) => {
    const { itemId, subitemId, length, width } = value;

    _state.setIn(["items", itemId, "actualLengthItems", subitemId], fromJS({ length, width }));

    const items = _state.getIn(["items", itemId, "actualLengthItems"]);
    const totalSqm = calculateTotalSquareMeters(items.toJS());
    _state.setIn(["items", itemId, "actualSumLengths"], fromJS(totalSqm));

    const actualLengthItems = _state.getIn(["items", itemId, "actualLengthItems"]).toJS();

    _state.setIn(["items", itemId, "actualLengthItems"], fromJS(actualLengthItems));
  };

  deleteProductLength = (_state, { itemId, subitemId, minLength }) => {
    _state.deleteIn(["items", itemId, "subitems", subitemId]);
    _state.deleteIn(["items", itemId, "actualLengthItems", subitemId]);

    const items = _state.getIn(["items", itemId, "subitems"]);
    const lengths = calcItemLength(items.toJS(), minLength);
    _state.setIn(["items", itemId, "sumLengths"], fromJS(lengths));
    _state.setIn(["items", itemId, "actualSumLengths"], fromJS(lengths));
  };

  deleteProductActualLength = (_state, { itemId, subitemId, minLength }) => {
    _state.deleteIn(["items", itemId, "actualLengthItems", subitemId]);

    const items = _state.getIn(["items", itemId, "actualLengthItems"]);
    const lengths = calcItemLength(items.toJS(), minLength);
    _state.setIn(["items", itemId, "actualSumLengths"], fromJS(lengths));
  };

  deleteProductKitItemLength = (_state, { itemIndex, cartIndex, lengthId, minLength }) => {
    _state.deleteIn(["items", itemIndex, "productKitItems", cartIndex, "lengthItems", lengthId]);
    _state.deleteIn([
      "items",
      itemIndex,
      "productKitItems",
      cartIndex,
      "actualLengthItems",
      lengthId,
    ]);

    const items = _state.getIn(["items", itemIndex, "productKitItems", cartIndex, "lengthItems"]);
    const lengths = calcItemLength(items.toJS(), minLength);
    _state.setIn(["items", itemIndex, "productKitItems", cartIndex, "sumLengths"], fromJS(lengths));
    _state.setIn(
      ["items", itemIndex, "productKitItems", cartIndex, "actualSumLengths"],
      fromJS(lengths)
    );
  };

  deleteProductKitItemDimension = (_state, { itemIndex, cartIndex, lengthId }) => {
    _state.deleteIn(["items", itemIndex, "productKitItems", cartIndex, "lengthItems", lengthId]);
    _state.deleteIn([
      "items",
      itemIndex,
      "productKitItems",
      cartIndex,
      "actualLengthItems",
      lengthId,
    ]);

    const items = _state.getIn(["items", itemIndex, "productKitItems", cartIndex, "lengthItems"]);
    const totalSqm = calculateTotalSquareMeters(items.toJS());
    _state.setIn(
      ["items", itemIndex, "productKitItems", cartIndex, "sumLengths"],
      fromJS(totalSqm)
    );
    _state.setIn(
      ["items", itemIndex, "productKitItems", cartIndex, "actualSumLengths"],
      fromJS(totalSqm)
    );
  };

  deleteSubKitItemLength = (_state, { itemIndex, subKitIndex, cartIndex, lengthId, minLength }) => {
    _state.deleteIn([
      "items",
      itemIndex,
      "subKits",
      subKitIndex,
      "kitProducts",
      cartIndex,
      "lengthItems",
      lengthId,
    ]);
    _state.deleteIn([
      "items",
      itemIndex,
      "subKits",
      subKitIndex,
      "kitProducts",
      cartIndex,
      "actualLengthItems",
      lengthId,
    ]);

    const items = _state.getIn([
      "items",
      itemIndex,
      "subKits",
      subKitIndex,
      "kitProducts",
      cartIndex,
      "lengthItems",
    ]);
    const lengths = calcItemLength(items.toJS(), minLength);
    _state.setIn(
      ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "sumLengths"],
      fromJS(lengths)
    );
    _state.setIn(
      ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "actualSumLengths"],
      fromJS(lengths)
    );
  };

  deleteSubKitItemDimension = (
    _state,
    { itemIndex, subKitIndex, cartIndex, lengthId, minLength }
  ) => {
    _state.deleteIn([
      "items",
      itemIndex,
      "subKits",
      subKitIndex,
      "kitProducts",
      cartIndex,
      "lengthItems",
      lengthId,
    ]);
    _state.deleteIn([
      "items",
      itemIndex,
      "subKits",
      subKitIndex,
      "kitProducts",
      cartIndex,
      "actualLengthItems",
      lengthId,
    ]);

    const items = _state.getIn([
      "items",
      itemIndex,
      "subKits",
      subKitIndex,
      "kitProducts",
      cartIndex,
      "lengthItems",
    ]);
    const totalSqm = calculateTotalSquareMeters(items.toJS());
    _state.setIn(
      ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "sumLengths"],
      fromJS(totalSqm)
    );
    _state.setIn(
      ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "actualSumLengths"],
      fromJS(totalSqm)
    );
  };

  deleteProductKitItemActualLength = (_state, { itemIndex, cartIndex, lengthId, minLength }) => {
    _state.deleteIn([
      "items",
      itemIndex,
      "productKitItems",
      cartIndex,
      "actualLengthItems",
      lengthId,
    ]);
    const items = _state.getIn([
      "items",
      itemIndex,
      "productKitItems",
      cartIndex,
      "actualLengthItems",
    ]);
    const lengths = calcItemLength(items.toJS(), minLength);
    _state.setIn(
      ["items", itemIndex, "productKitItems", cartIndex, "actualSumLengths"],
      fromJS(lengths)
    );
  };

  deleteProductKitItemActualDimension = (_state, { itemIndex, cartIndex, lengthId }) => {
    _state.deleteIn([
      "items",
      itemIndex,
      "productKitItems",
      cartIndex,
      "actualLengthItems",
      lengthId,
    ]);
    const items = _state.getIn([
      "items",
      itemIndex,
      "productKitItems",
      cartIndex,
      "actualLengthItems",
    ]);
    const totalSqm = calculateTotalSquareMeters(items.toJS());
    _state.setIn(
      ["items", itemIndex, "productKitItems", cartIndex, "actualSumLengths"],
      fromJS(totalSqm)
    );
  };

  deleteSubKitItemActualLength = (
    _state,
    { itemIndex, subKitIndex, cartIndex, lengthId, minLength }
  ) => {
    _state.deleteIn([
      "items",
      itemIndex,
      "subKits",
      subKitIndex,
      "kitProducts",
      cartIndex,
      "actualLengthItems",
      lengthId,
    ]);
    const items = _state.getIn([
      "items",
      itemIndex,
      "subKits",
      subKitIndex,
      "kitProducts",
      cartIndex,
      "actualLengthItems",
    ]);
    const lengths = calcItemLength(items.toJS(), minLength);
    _state.setIn(
      ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "actualSumLengths"],
      fromJS(lengths)
    );
  };

  deleteSubKitItemActualDimension = (
    _state,
    { itemIndex, subKitIndex, cartIndex, lengthId, minLength }
  ) => {
    _state.deleteIn([
      "items",
      itemIndex,
      "subKits",
      subKitIndex,
      "kitProducts",
      cartIndex,
      "actualLengthItems",
      lengthId,
    ]);
    const items = _state.getIn([
      "items",
      itemIndex,
      "subKits",
      subKitIndex,
      "kitProducts",
      cartIndex,
      "actualLengthItems",
    ]);
    const totalSqm = calculateTotalSquareMeters(items.toJS());
    _state.setIn(
      ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "actualSumLengths"],
      fromJS(totalSqm)
    );
  };

  deleteProductDimension = (_state, { itemId, subitemId }) => {
    _state.deleteIn(["items", itemId, "subitems", subitemId]);
    _state.deleteIn(["items", itemId, "actualLengthItems", subitemId]);

    const items = _state.getIn(["items", itemId, "subitems"]);
    const totalSqm = calculateTotalSquareMeters(items.toJS());
    _state.setIn(["items", itemId, "sumLengths"], fromJS(totalSqm));
    _state.setIn(["items", itemId, "actualSumLengths"], fromJS(totalSqm));
  };

  deleteProductActualDimension = (_state, { itemId, subitemId }) => {
    _state.deleteIn(["items", itemId, "actualLengthItems", subitemId]);

    const items = _state.getIn(["items", itemId, "actualLengthItems"]);
    const totalSqm = calculateTotalSquareMeters(items.toJS());
    _state.setIn(["items", itemId, "actualSumLengths"], fromJS(totalSqm));
  };

  changeProductPriceValue = (_state, { itemId, type, value, isPercentage = false }) => {
    const item = _state.getIn(["items", itemId]);
    const pricingStrategy = item.get("pricingStrategy");
    const productType = item.get("productType");
    const quantityValues = {
      quantity: Decimal(0),
      actualQuantity: Decimal(0),
    };

    if (pricingStrategy === PRICING_STRATEGY_LINEAL_METRES_LABEL) {
      quantityValues.quantity = Decimal(item.get("sumLengths").get("forPrice"))
        .dividedBy(1000)
        .toDecimalPlaces(4);
      quantityValues.actualQuantity = Decimal(item.get("actualSumLengths").get("forPrice"))
        .dividedBy(1000)
        .toDecimalPlaces(4);
    } else if (pricingStrategy === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL) {
      const formulaOutput = item.get("customFormulaOutput");
      const actualFormulaOutput = item.get("actualCustomFormulaOutput");
      quantityValues.quantity = Decimal(item.get("quantity"))
        .times(formulaOutput)
        .toDecimalPlaces(4);
      quantityValues.actualQuantity = Decimal(item.get("actualQuantity"))
        .times(actualFormulaOutput)
        .toDecimalPlaces(4);
    } else if (pricingStrategy === PRICING_STRATEGY_SQUARE_METRES_LABEL) {
      quantityValues.quantity = Decimal(item.get("sumLengths").get("total")).toDecimalPlaces(4);
      quantityValues.actualQuantity = Decimal(
        item.get("actualSumLengths").get("total")
      ).toDecimalPlaces(4);
    } else if (
      pricingStrategy === PRICING_STRATEGY_BASIC_QUANTITIES_LABEL ||
      productType === PRODUCT_TYPE_PRODUCT_KIT_LABEL
    ) {
      quantityValues.quantity = Decimal(item.get("quantity")).toDecimalPlaces(4);
      quantityValues.actualQuantity = Decimal(item.get("actualQuantity")).toDecimalPlaces(4);
    }

    const { quantity, actualQuantity } = quantityValues;
    const markupIsPercentage = item.get("markupIsPercentage");
    const cost = Decimal(item.get("cost"));

    if (type === "totalValue") {
      /* Total Value calculation */
      const newValue = Decimal(value).toDecimalPlaces(2);
      const discount = item.get("discount");
      const itemDiscount = Decimal(isPositiveNumber(discount) && discount < 100 ? discount : 0);
      const discountRate = Decimal(100).minus(itemDiscount).toDecimalPlaces(2);
      const itemTotalWithDiscount = newValue.dividedBy(discountRate).times(100).toDecimalPlaces(2);
      const newUnitPrice = itemTotalWithDiscount.dividedBy(quantity).toDecimalPlaces(4);
      _state.setIn(["items", itemId, "value"], newUnitPrice.toFixed(4));
      _state.setIn(["items", itemId, "customValue"], newValue.toFixed(2));
      if (cost && cost > 0) {
        const newMarkup = markupIsPercentage
          ? Decimal(newUnitPrice).minus(cost).dividedBy(cost).times(100).toDecimalPlaces(18)
          : Decimal(newUnitPrice).minus(cost).toDecimalPlaces(4);
        _state.setIn(["items", itemId, "markup"], newMarkup.toString());
      }
    } else if (type === "value") {
      /* Unit price calculation */
      const newValue = Decimal(value).toDecimalPlaces(4);
      _state.setIn(
        ["items", itemId, "value"],
        !isNumeric(newValue.toFixed(4)) ? "0.00" : newValue.toFixed(4)
      );
      if (cost && cost > 0) {
        const newMarkup = markupIsPercentage
          ? Decimal(newValue).minus(cost).dividedBy(cost).times(100).toDecimalPlaces(18)
          : Decimal(newValue).minus(cost).toDecimalPlaces(4);
        _state.setIn(["items", itemId, "markup"], newMarkup.toString());
      }
    } else if (type === "markup") {
      /* Markup price calculation */
      _state.setIn(["items", itemId, "markupIsPercentage"], isPercentage);
      if (isPercentage) {
        const newMarkup = Decimal(value).toDecimalPlaces(2);
        // Edit the price with the new provided value
        _state.setIn(["items", itemId, "markup"], newMarkup.toString());
        // Cost * Markup = Price for %-based markups
        const newPrice = Decimal(cost)
          .dividedBy(100)
          .times(newMarkup)
          .plus(cost)
          .toDecimalPlaces(4);
        _state.setIn(["items", itemId, "value"], parseFloat(newPrice.toFixed(4)));
      } else {
        const newMarkup = Decimal(value).toDecimalPlaces(4);
        // Edit the price with the new provided value
        _state.setIn(["items", itemId, "markup"], parseFloat(newMarkup.toFixed(4)));
        // Cost + Markup = Price for $-based markups
        const newPrice = Decimal(cost).plus(newMarkup).toDecimalPlaces(4);
        _state.setIn(["items", itemId, "value"], parseFloat(newPrice.toFixed(4)));
      }
    } else if (type === "cost") {
      /* Cost price calculation */
      const newCost = value;
      const markup = item.get("markup");
      // Cost * Markup = Price for %-based markups or Cost + Markup = Price for $-based markups
      const newPrice = markupIsPercentage
        ? Decimal(newCost).dividedBy(100).times(markup).plus(newCost).toDecimalPlaces(4)
        : Decimal(newCost).plus(markup).toDecimalPlaces(4);
      _state.setIn(["items", itemId, "value"], parseFloat(newPrice.toFixed(4)));
      _state.setIn(["items", itemId, "cost"], newCost);
    } else if (type === "discount") {
      // Store discount value
      _state.setIn(["items", itemId, "discount"], !isPositiveNumber(value) ? "0.00" : value);
    }

    // Always apply item level discount
    const discount = Decimal(
      isPositiveNumber(_state.getIn(["items", itemId, "discount"])) &&
        _state.getIn(["items", itemId, "discount"]) <= 100
        ? _state.getIn(["items", itemId, "discount"])
        : 0
    ).toDecimalPlaces(2);
    const itemUnitPrice = Decimal(
      isNumeric(_state.getIn(["items", itemId, "value"]))
        ? _state.getIn(["items", itemId, "value"])
        : 0
    ).toDecimalPlaces(4);
    // Compute item price with discount

    const discountRate = discount.dividedBy(100).toDecimalPlaces(2);
    const newTotal = itemUnitPrice.times(quantity).toDecimalPlaces(2);
    const newTotalWithDiscount = newTotal.times(1 - discountRate).toDecimalPlaces(2);

    // Store value
    _state.setIn(["items", itemId, "totalValue"], newTotalWithDiscount.toFixed(2));
    _state.setIn(["items", itemId, "customValue"], null);

    const updatedCost = Decimal(_state.getIn(["items", itemId, "cost"]));
    // Always calculate the margin if cost is greater than 0
    // Item Total Value - (Cost * Qty) = Margin
    if (updatedCost && updatedCost > 0) {
      const itemTotalCost = Decimal(updatedCost).times(actualQuantity).toDecimalPlaces(4);
      const margin = Decimal(newTotalWithDiscount).minus(itemTotalCost).toDecimalPlaces(2);
      _state.setIn(["items", itemId, "margin"], margin.toFixed(2));
    } else if (updatedCost && updatedCost.equals(0)) {
      _state.setIn(["items", itemId, "margin"], "0");
    }
  };

  changeProductKitItemPriceValue = (
    _state,
    { itemIndex, cartIndex, type, value, isPercentage = false }
  ) => {
    const parentKitQuantity = _state.getIn(["items", itemIndex, "quantity"]);
    const kitItem = _state.getIn(["items", itemIndex, "productKitItems", cartIndex]);
    const quantityValues = {
      quantity: Decimal(0),
      actualQuantity: Decimal(0),
    };

    const pricingStrategy = kitItem.get("pricingStrategy");

    if (pricingStrategy === PRICING_STRATEGY_LINEAL_METRES_LABEL) {
      quantityValues.quantity = Decimal(kitItem.get("sumLengths").get("forPrice"))
        .dividedBy(1000)
        .toDecimalPlaces(4);
      quantityValues.actualQuantity = Decimal(kitItem.get("actualSumLengths").get("forPrice"))
        .dividedBy(1000)
        .toDecimalPlaces(4);
    } else if (pricingStrategy === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL) {
      const formulaOutput = kitItem.get("customFormulaOutput");
      const actualFormulaOutput = kitItem.get("actualCustomFormulaOutput");
      quantityValues.quantity = Decimal(kitItem.get("quantity"))
        .times(formulaOutput)
        .toDecimalPlaces(4);
      quantityValues.actualQuantity = Decimal(kitItem.get("actualQuantity"))
        .times(actualFormulaOutput)
        .toDecimalPlaces(4);
    } else if (pricingStrategy === PRICING_STRATEGY_SQUARE_METRES_LABEL) {
      quantityValues.quantity = Decimal(kitItem.get("sumLengths").get("total")).toDecimalPlaces(4);
      quantityValues.actualQuantity = Decimal(
        kitItem.get("actualSumLengths").get("total")
      ).toDecimalPlaces(4);
    } else if (pricingStrategy === PRICING_STRATEGY_BASIC_QUANTITIES_LABEL) {
      quantityValues.quantity = Decimal(kitItem.get("quantity")).toDecimalPlaces(4);
      quantityValues.actualQuantity = Decimal(kitItem.get("actualQuantity")).toDecimalPlaces(4);
    }
    const { quantity, actualQuantity } = quantityValues;
    const cost = Decimal(kitItem.get("cost"));
    const markupIsPercentage = kitItem.get("markupIsPercentage");

    if (type === "totalValue") {
      /* Total Value calculation */
      const newValue = Decimal(value).toDecimalPlaces(2);
      const discount = _state.getIn(["items", itemIndex, "productKitItems", cartIndex, "discount"]);
      const itemDiscount = Decimal(
        isPositiveNumber(discount) && discount < 100 ? discount : 0
      ).toDecimalPlaces(0);
      const discountRate = Decimal(100).minus(itemDiscount).toDecimalPlaces(2);
      const itemTotalWithDiscount = Decimal(value)
        .dividedBy(discountRate)
        .times(100)
        .toDecimalPlaces(4);
      const newUnitPrice = itemTotalWithDiscount.dividedBy(quantity).toDecimalPlaces(4);
      _state.setIn(
        ["items", itemIndex, "productKitItems", cartIndex, "value"],
        newUnitPrice.toFixed(4)
      );
      _state.setIn(
        ["items", itemIndex, "productKitItems", cartIndex, "customValue"],
        newValue.toFixed(2)
      );

      if (cost && cost > 0) {
        const newMarkup = markupIsPercentage
          ? Decimal(newUnitPrice).minus(cost).dividedBy(cost).times(100).toDecimalPlaces(18)
          : Decimal(newUnitPrice).minus(cost).toDecimalPlaces(4);
        _state.setIn(
          ["items", itemIndex, "productKitItems", cartIndex, "markup"],
          newMarkup.toString()
        );
      }
      // update parent kit if standard priced kit
      if (!_state.getIn(["items", itemIndex, "customPricing"])) {
        const kitItems = _state.getIn(["items", itemIndex, "productKitItems"]);
        const kitItemTotals = kitItems.reduce((prev, current) => {
          return Decimal(prev).plus(current.get("customValue") || current.get("totalValue"));
        }, 0);
        const subKits = _state.getIn(["items", itemIndex, "subKits"]);
        const subKitTotals = subKits.reduce((prev, current) => {
          return Decimal(prev).plus(current.get("totalValue"));
        }, 0);
        const parentKitTotal = kitItemTotals.plus(subKitTotals).toDecimalPlaces(2);
        const parentKitUnitPrice = parentKitTotal.dividedBy(parentKitQuantity).toDecimalPlaces(4);
        _state.setIn(["items", itemIndex, "totalValue"], parentKitTotal.toFixed(2));
        _state.setIn(["items", itemIndex, "value"], parentKitUnitPrice.toFixed(4));
        _state.setIn(["items", itemIndex, "markupIsPercentage"], false);
        _state.setIn(["items", itemIndex, "markup"], "0");
        _state.setIn(["items", itemIndex, "cost"], "0");
        _state.setIn(["items", itemIndex, "margin"], "0");
      }
    } else if (type === "value") {
      /* Unit price calculation */
      const newValue = Decimal(value).toDecimalPlaces(4);
      _state.setIn(
        ["items", itemIndex, "productKitItems", cartIndex, type],
        !isNumeric(newValue.toFixed(4)) ? "0.00" : newValue.toFixed(4)
      );
      if (cost && cost > 0) {
        const newMarkup = markupIsPercentage
          ? Decimal(newValue).minus(cost).dividedBy(cost).times(100).toDecimalPlaces(18)
          : Decimal(newValue).minus(cost);
        _state.setIn(
          ["items", itemIndex, "productKitItems", cartIndex, "markup"],
          newMarkup.toString()
        );
      }
    } else if (type === "markup") {
      /* Markup price calculation */
      _state.setIn(
        ["items", itemIndex, "productKitItems", cartIndex, "markupIsPercentage"],
        isPercentage
      );
      if (isPercentage) {
        const newMarkup = Decimal(value).toDecimalPlaces(2);
        // Edit the price with the new provided value
        _state.setIn(
          ["items", itemIndex, "productKitItems", cartIndex, "markup"],
          parseFloat(newMarkup.toFixed(2))
        );
        // Cost * Markup = Price for %-based markups
        const newPrice = Decimal(cost)
          .dividedBy(100)
          .times(newMarkup)
          .plus(cost)
          .toDecimalPlaces(4);
        _state.setIn(
          ["items", itemIndex, "productKitItems", cartIndex, "value"],
          parseFloat(newPrice.toFixed(4))
        );
      } else {
        const newMarkup = Decimal(value).toDecimalPlaces(4);
        // Edit the price with the new provided value
        _state.setIn(
          ["items", itemIndex, "productKitItems", cartIndex, "markup"],
          newMarkup.toString()
        );
        // Cost + Markup = Price for $-based markups
        const newPrice = Decimal(cost).plus(newMarkup).toDecimalPlaces(4);
        _state.setIn(
          ["items", itemIndex, "productKitItems", cartIndex, "value"],
          parseFloat(newPrice.toFixed(4))
        );
      }
    } else if (type === "cost") {
      /* Cost price calculation */
      const newCost = value;
      const markup = kitItem.get("markup");
      // Cost * Markup = Price for %-based markups or Cost + Markup = Price for $-based markups
      const newPrice = markupIsPercentage
        ? Decimal(newCost).dividedBy(100).times(markup).plus(newCost).toDecimalPlaces(4)
        : Decimal(newCost).plus(markup).toDecimalPlaces(4);
      _state.setIn(
        ["items", itemIndex, "productKitItems", cartIndex, "value"],
        parseFloat(newPrice.toFixed(4))
      );
      _state.setIn(["items", itemIndex, "productKitItems", cartIndex, "cost"], newCost);
    } else if (type === "discount") {
      // Save discount values
      _state.setIn(
        ["items", itemIndex, "productKitItems", cartIndex, "discount"],
        !isPositiveNumber(value) ? "0.00" : value
      );
    }

    // Always apply the discount
    const discount = Decimal(
      isPositiveNumber(
        _state.getIn(["items", itemIndex, "productKitItems", cartIndex, "discount"])
      ) && _state.getIn(["items", itemIndex, "productKitItems", cartIndex, "discount"]) < 100
        ? _state.getIn(["items", itemIndex, "productKitItems", cartIndex, "discount"])
        : 0
    ).toDecimalPlaces(0);
    const itemUnitPrice = Decimal(
      isNumeric(_state.getIn(["items", itemIndex, "productKitItems", cartIndex, "value"]))
        ? _state.getIn(["items", itemIndex, "productKitItems", cartIndex, "value"])
        : 0
    ).toDecimalPlaces(4);

    // Compute item price with discount
    const discountRate = Decimal(discount).dividedBy(100).toDecimalPlaces(2);
    const newTotal = Decimal(itemUnitPrice).times(quantity).toDecimalPlaces(2);
    const newTotalWithDiscount = newTotal.times(1 - discountRate).toDecimalPlaces(2);

    // Store value
    _state.setIn(
      ["items", itemIndex, "productKitItems", cartIndex, "totalValue"],
      newTotalWithDiscount.toFixed(2)
    );
    _state.setIn(["items", itemIndex, "productKitItems", cartIndex, "customValue"], null);

    const updatedCost = Decimal(
      _state.getIn(["items", itemIndex, "productKitItems", cartIndex, "cost"])
    );
    // Always calculate the margin if cost is greater than 0
    if (updatedCost && updatedCost > 0) {
      // Item Total Value - (Cost * Qty) = Margin
      const itemTotalCost = Decimal(updatedCost).times(actualQuantity).toDecimalPlaces(4);
      const margin = Decimal(newTotalWithDiscount).minus(itemTotalCost).toDecimalPlaces(2);
      _state.setIn(["items", itemIndex, "productKitItems", cartIndex, "margin"], margin.toFixed(2));
    } else if (updatedCost && updatedCost.equals(0)) {
      _state.setIn(["items", itemIndex, "productKitItems", cartIndex, "margin"], "0");
    }

    // update parent kit if standard priced kit
    if (!_state.getIn(["items", itemIndex, "customPricing"])) {
      const kitItems = _state.getIn(["items", itemIndex, "productKitItems"]);
      const kitItemTotals = kitItems.reduce((prev, current) => {
        return Decimal(prev).plus(current.get("customValue") || current.get("totalValue"));
      }, 0);
      const subKits = _state.getIn(["items", itemIndex, "subKits"]);
      const subKitTotals = subKits.reduce((prev, current) => {
        return Decimal(prev).plus(current.get("totalValue"));
      }, 0);
      const parentKitTotal = kitItemTotals.plus(subKitTotals).toDecimalPlaces(2);
      const parentKitUnitPrice = parentKitTotal.dividedBy(parentKitQuantity).toDecimalPlaces(4);
      _state.setIn(["items", itemIndex, "totalValue"], parentKitTotal.toFixed(2));
      _state.setIn(["items", itemIndex, "value"], parentKitUnitPrice.toFixed(4));
      _state.setIn(["items", itemIndex, "markupIsPercentage"], false);
      _state.setIn(["items", itemIndex, "markup"], "0");
      _state.setIn(["items", itemIndex, "cost"], "0");
      _state.setIn(["items", itemIndex, "margin"], "0");
    }
  };

  changeSubKitItemPriceValue = (
    _state,
    { itemIndex, subKitIndex, cartIndex, type, value, isPercentage = false }
  ) => {
    const parentKitQuantity = _state.getIn(["items", itemIndex, "quantity"]);
    const currentSubKitQuantity = _state.getIn([
      "items",
      itemIndex,
      "subKits",
      subKitIndex,
      "quantity",
    ]);
    const kitItem = _state.getIn([
      "items",
      itemIndex,
      "subKits",
      subKitIndex,
      "kitProducts",
      cartIndex,
    ]);

    const quantityValues = {
      quantity: Decimal(0),
      actualQuantity: Decimal(0),
    };

    const pricingStrategy = kitItem.get("pricingStrategy");

    if (pricingStrategy === PRICING_STRATEGY_LINEAL_METRES_LABEL) {
      quantityValues.quantity = Decimal(kitItem.get("sumLengths").get("forPrice"))
        .dividedBy(1000)
        .toDecimalPlaces(4);
      quantityValues.actualQuantity = Decimal(kitItem.get("actualSumLengths").get("forPrice"))
        .dividedBy(1000)
        .toDecimalPlaces(4);
    } else if (pricingStrategy === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL) {
      const formulaOutput = kitItem.get("customFormulaOutput");
      const actualFormulaOutput = kitItem.get("actualCustomFormulaOutput");
      quantityValues.quantity = Decimal(kitItem.get("quantity"))
        .times(formulaOutput)
        .toDecimalPlaces(4);
      quantityValues.actualQuantity = Decimal(kitItem.get("actualQuantity"))
        .times(actualFormulaOutput)
        .toDecimalPlaces(4);
    } else if (pricingStrategy === PRICING_STRATEGY_SQUARE_METRES_LABEL) {
      quantityValues.quantity = Decimal(kitItem.get("sumLengths").get("total")).toDecimalPlaces(4);
      quantityValues.actualQuantity = Decimal(
        kitItem.get("actualSumLengths").get("total")
      ).toDecimalPlaces(4);
    } else if (pricingStrategy === PRICING_STRATEGY_BASIC_QUANTITIES_LABEL) {
      quantityValues.quantity = Decimal(kitItem.get("quantity")).toDecimalPlaces(4);
      quantityValues.actualQuantity = Decimal(kitItem.get("actualQuantity")).toDecimalPlaces(4);
    }
    const { quantity, actualQuantity } = quantityValues;
    const cost = Decimal(kitItem.get("cost"));
    const markupIsPercentage = kitItem.get("markupIsPercentage");

    if (type === "totalValue") {
      /* Total Value calculation */
      const newValue = Decimal(value).toDecimalPlaces(2);
      const discount = _state.getIn([
        "items",
        itemIndex,
        "subKits",
        subKitIndex,
        "kitProducts",
        cartIndex,
        "discount",
      ]);
      const itemDiscount = Decimal(
        isPositiveNumber(discount) && discount < 100 ? discount : 0
      ).toDecimalPlaces(0);
      const discountRate = Decimal(100).minus(itemDiscount).toDecimalPlaces(2);
      const itemTotalWithDiscount = Decimal(value)
        .dividedBy(discountRate)
        .times(100)
        .toDecimalPlaces(4);
      const newUnitPrice = itemTotalWithDiscount.dividedBy(quantity).toDecimalPlaces(4);
      _state.setIn(
        ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "value"],
        newUnitPrice.toFixed(4)
      );
      _state.setIn(
        ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "customValue"],
        newValue.toFixed(2)
      );

      if (cost && cost > 0) {
        const newMarkup = markupIsPercentage
          ? Decimal(newUnitPrice).minus(cost).dividedBy(cost).times(100).toDecimalPlaces(18)
          : Decimal(newUnitPrice).minus(cost).toDecimalPlaces(4);
        _state.setIn(
          ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "markup"],
          newMarkup.toString()
        );
      }
      // update parent kit if standard priced kit
      if (!_state.getIn(["items", itemIndex, "customPricing"])) {
        const currentSubKitItems = _state.getIn([
          "items",
          itemIndex,
          "subKits",
          subKitIndex,
          "kitProducts",
        ]);
        const currentSubKitItemTotals = currentSubKitItems.reduce((prev, current) => {
          return Decimal(prev).plus(current.get("customValue") || current.get("totalValue"));
        }, 0);
        const currentSubKitTotal = currentSubKitItemTotals.toDecimalPlaces(2);
        const currentSubKitUnitPrice = currentSubKitTotal
          .dividedBy(currentSubKitQuantity)
          .toDecimalPlaces(4);

        _state.setIn(
          ["items", itemIndex, "subKits", subKitIndex, "totalValue"],
          currentSubKitTotal.toFixed(2)
        );
        _state.setIn(
          ["items", itemIndex, "subKits", subKitIndex, "value"],
          currentSubKitUnitPrice.toFixed(4)
        );
        _state.setIn(["items", itemIndex, "subKits", subKitIndex, "markupIsPercentage"], false);
        _state.setIn(["items", itemIndex, "subKits", subKitIndex, "markup"], "0");
        _state.setIn(["items", itemIndex, "subKits", subKitIndex, "cost"], "0");
        _state.setIn(["items", itemIndex, "subKits", subKitIndex, "margin"], "0");

        const kitItems = _state.getIn(["items", itemIndex, "productKitItems"]);
        const kitItemTotals = kitItems.reduce((prev, current) => {
          return Decimal(prev).plus(current.get("customValue") || current.get("totalValue"));
        }, 0);
        const subKits = _state.getIn(["items", itemIndex, "subKits"]);
        const subKitTotals = subKits.reduce((prev, current) => {
          return Decimal(prev).plus(current.get("totalValue"));
        }, 0);
        const parentKitTotal = kitItemTotals.plus(subKitTotals).toDecimalPlaces(2);
        const parentKitUnitPrice = parentKitTotal.dividedBy(parentKitQuantity).toDecimalPlaces(4);
        _state.setIn(["items", itemIndex, "totalValue"], parentKitTotal.toFixed(2));
        _state.setIn(["items", itemIndex, "value"], parentKitUnitPrice.toFixed(4));
        _state.setIn(["items", itemIndex, "markupIsPercentage"], false);
        _state.setIn(["items", itemIndex, "markup"], "0");
        _state.setIn(["items", itemIndex, "cost"], "0");
        _state.setIn(["items", itemIndex, "margin"], "0");
      }
    } else if (type === "value") {
      /* Unit price calculation */
      const newValue = Decimal(value).toDecimalPlaces(4);
      _state.setIn(
        ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, type],
        !isNumeric(newValue.toFixed(4)) ? "0.00" : newValue.toFixed(4)
      );
      if (cost && cost > 0) {
        const newMarkup = markupIsPercentage
          ? Decimal(newValue).minus(cost).dividedBy(cost).times(100).toDecimalPlaces(18)
          : Decimal(newValue).minus(cost);
        _state.setIn(
          ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "markup"],
          newMarkup.toString()
        );
      }
    } else if (type === "markup") {
      /* Markup price calculation */
      _state.setIn(
        [
          "items",
          itemIndex,
          "subKits",
          subKitIndex,
          "kitProducts",
          cartIndex,
          "markupIsPercentage",
        ],
        isPercentage
      );
      if (isPercentage) {
        const newMarkup = Decimal(value).toDecimalPlaces(2);
        // Edit the price with the new provided value
        _state.setIn(
          ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "markup"],
          parseFloat(newMarkup.toFixed(2))
        );
        // Cost * Markup = Price for %-based markups
        const newPrice = Decimal(cost)
          .dividedBy(100)
          .times(newMarkup)
          .plus(cost)
          .toDecimalPlaces(4);
        _state.setIn(
          ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "value"],
          parseFloat(newPrice.toFixed(4))
        );
      } else {
        const newMarkup = Decimal(value).toDecimalPlaces(4);
        // Edit the price with the new provided value
        _state.setIn(
          ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "markup"],
          newMarkup.toString()
        );
        // Cost + Markup = Price for $-based markups
        const newPrice = Decimal(cost).plus(newMarkup).toDecimalPlaces(4);
        _state.setIn(
          ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "value"],
          parseFloat(newPrice.toFixed(4))
        );
      }
    } else if (type === "cost") {
      /* Cost price calculation */
      const newCost = value;
      const markup = kitItem.get("markup");
      // Cost * Markup = Price for %-based markups or Cost + Markup = Price for $-based markups
      const newPrice = markupIsPercentage
        ? Decimal(newCost).dividedBy(100).times(markup).plus(newCost).toDecimalPlaces(4)
        : Decimal(newCost).plus(markup).toDecimalPlaces(4);
      _state.setIn(
        ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "value"],
        parseFloat(newPrice.toFixed(4))
      );
      _state.setIn(
        ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "cost"],
        newCost
      );
    } else if (type === "discount") {
      // Save discount values
      _state.setIn(
        ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "discount"],
        !isPositiveNumber(value) ? "0.00" : value
      );
    }

    // Always apply the discount
    const discount = Decimal(
      isPositiveNumber(
        _state.getIn([
          "items",
          itemIndex,
          "subKits",
          subKitIndex,
          "kitProducts",
          cartIndex,
          "discount",
        ])
      ) &&
        _state.getIn([
          "items",
          itemIndex,
          "subKits",
          subKitIndex,
          "kitProducts",
          cartIndex,
          "discount",
        ]) < 100
        ? _state.getIn([
            "items",
            itemIndex,
            "subKits",
            subKitIndex,
            "kitProducts",
            cartIndex,
            "discount",
          ])
        : 0
    ).toDecimalPlaces(0);
    const itemUnitPrice = Decimal(
      isNumeric(
        _state.getIn([
          "items",
          itemIndex,
          "subKits",
          subKitIndex,
          "kitProducts",
          cartIndex,
          "value",
        ])
      )
        ? _state.getIn([
            "items",
            itemIndex,
            "subKits",
            subKitIndex,
            "kitProducts",
            cartIndex,
            "value",
          ])
        : 0
    ).toDecimalPlaces(4);

    // Compute item price with discount
    const discountRate = Decimal(discount).dividedBy(100).toDecimalPlaces(2);
    const newTotal = Decimal(itemUnitPrice).times(quantity).toDecimalPlaces(2);
    const newTotalWithDiscount = newTotal.times(1 - discountRate).toDecimalPlaces(2);

    // Store value
    _state.setIn(
      ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "totalValue"],
      newTotalWithDiscount.toFixed(2)
    );
    _state.setIn(
      ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "customValue"],
      null
    );

    const updatedCost = Decimal(
      _state.getIn(["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "cost"])
    );
    // Always calculate the margin if cost is greater than 0
    if (updatedCost && updatedCost > 0) {
      // Item Total Value - (Cost * Qty) = Margin
      const itemTotalCost = Decimal(updatedCost).times(actualQuantity).toDecimalPlaces(4);
      const margin = Decimal(newTotalWithDiscount).minus(itemTotalCost).toDecimalPlaces(2);
      _state.setIn(
        ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "margin"],
        margin.toFixed(2)
      );
    } else if (updatedCost && updatedCost.equals(0)) {
      _state.setIn(
        ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "margin"],
        "0"
      );
    }

    // update parent kit if standard priced kit
    if (!_state.getIn(["items", itemIndex, "customPricing"])) {
      const currentSubKitItems = _state.getIn([
        "items",
        itemIndex,
        "subKits",
        subKitIndex,
        "kitProducts",
      ]);
      const currentSubKitItemTotals = currentSubKitItems.reduce((prev, current) => {
        return Decimal(prev).plus(current.get("customValue") || current.get("totalValue"));
      }, 0);
      const currentSubKitTotal = currentSubKitItemTotals.toDecimalPlaces(2);
      const currentSubKitUnitPrice = currentSubKitTotal
        .dividedBy(currentSubKitQuantity)
        .toDecimalPlaces(4);

      _state.setIn(
        ["items", itemIndex, "subKits", subKitIndex, "totalValue"],
        currentSubKitTotal.toFixed(2)
      );
      _state.setIn(
        ["items", itemIndex, "subKits", subKitIndex, "value"],
        currentSubKitUnitPrice.toFixed(4)
      );
      _state.setIn(["items", itemIndex, "subKits", subKitIndex, "markupIsPercentage"], false);
      _state.setIn(["items", itemIndex, "subKits", subKitIndex, "markup"], "0");
      _state.setIn(["items", itemIndex, "subKits", subKitIndex, "cost"], "0");
      _state.setIn(["items", itemIndex, "subKits", subKitIndex, "margin"], "0");

      const kitItems = _state.getIn(["items", itemIndex, "productKitItems"]);
      const kitItemTotals = kitItems.reduce((prev, current) => {
        return Decimal(prev).plus(current.get("customValue") || current.get("totalValue"));
      }, 0);
      const subKits = _state.getIn(["items", itemIndex, "subKits"]);
      const subKitTotals = subKits.reduce((prev, current) => {
        return Decimal(prev).plus(current.get("totalValue"));
      }, 0);
      const parentKitTotal = kitItemTotals.plus(subKitTotals).toDecimalPlaces(2);
      const parentKitUnitPrice = parentKitTotal.dividedBy(parentKitQuantity).toDecimalPlaces(4);
      _state.setIn(["items", itemIndex, "totalValue"], parentKitTotal.toFixed(2));
      _state.setIn(["items", itemIndex, "value"], parentKitUnitPrice.toFixed(4));
      _state.setIn(["items", itemIndex, "markupIsPercentage"], false);
      _state.setIn(["items", itemIndex, "markup"], "0");
      _state.setIn(["items", itemIndex, "cost"], "0");
      _state.setIn(["items", itemIndex, "margin"], "0");
    }
  };

  searchAllProducts = (_state, { payload: { data } }) => {
    const items = {};

    data.map((item) => {
      const { category } = item;
      if (!items[category]) {
        items[category] = [];
      }

      items[category].push(camelcaseKeys(item, { deep: true }));
    });

    return _state.setIn(["dataServer", "items"], fromJS(items));
  };

  searchByCategory = (_state, { payload: { data } }) => {
    const items = {};
    let categoryName = "";
    data.map((item) => {
      const { category } = item;
      categoryName = category;
      if (!items[category]) {
        items[category] = [];
      }

      items[category].push(camelcaseKeys(item, { deep: true }));
    });

    return _state.setIn(["dataServer", "items", categoryName], fromJS(items[categoryName]));
  };

  renameCategory = (_state, { id, name }) => {
    const oldName = _state.getIn(["categories", id, "name"]);
    const items = _state.getIn(["dataServer", "items", oldName]);
    if (items) {
      const updatedItems = items.map((item) => item.set("category", name));
      _state.deleteIn(["dataServer", "items", oldName]);

      _state.setIn(["dataServer", "items", name], updatedItems);
    }

    _state.setIn(["categories", id, "name"], name);
  };

  changeCategory = (_state, category) => {
    _state.setIn(["activeProduct", "category"], category.uid);
    _state.set("activeCategory", fromJS(category));
  };

  selectMaterial = (_state, { material, isChecked }) => {
    const tables = _state.getIn(["activeProduct", "tables"]);
    if (!material.uid && isChecked) {
      const table = this.initialOptionsState();
      delete table.values[0].params;

      return _state.setIn(["activeProduct", "tables"], fromJS([table]));
    }
    return _state.setIn(
      ["activeProduct", "tables"],
      tables.filter((item) => item.get("materialUid"))
    );
  };

  createMaterialTable = (_state, material) => {
    _state.updateIn(["activeProduct", "tables"], (arr) => {
      let sortMaterials = arr.push(
        fromJS({
          ...this.initialOptionsState(),
          materialUid: material.uid,
          name: material.name,
        })
      );
      if (sortMaterials.size > 1) {
        sortMaterials = sortByMaterialName(sortMaterials);
      }

      return sortMaterials;
    });
  };

  removeMaterialTable = (_state, material) => {
    _state.updateIn(["activeProduct", "tables"], (arr) =>
      arr.filter((item) => item.getIn(["materialUid"]) !== material.uid)
    );
  };

  updateUidOfCustomFormula = (_state, data) => {
    // For product kits
    _state.updateIn(["items"], (arr) =>
      arr.map((item) => {
        const parsedItem = { ...item.toJS() };
        if (
          parsedItem.productType === PRODUCT_TYPE_ADDITIONAL_PRODUCT_LABEL &&
          parsedItem.pricingStrategy === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL &&
          !parsedItem.isDeleted
        ) {
          const product = data.find((a) => a.uid === parsedItem.uid);
          if (product) {
            return fromJS({
              ...parsedItem,
              customFormula: { ...parsedItem.customFormula, uid: product.custom_formula.uid },
            });
          }

          return fromJS({ ...parsedItem });
        } else if (
          parsedItem.productType === PRODUCT_TYPE_PRODUCT_KIT_LABEL &&
          !parsedItem.isDeleted
        ) {
          const product = data.find((a) => a.uid === parsedItem.uid);
          return fromJS({
            ...parsedItem,
            productKitItems: parsedItem.productKitItems.map((kitItem) => {
              if (kitItem.pricingStrategy === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL) {
                if (product) {
                  const productKitItem = product.kit_items.find((i) => i.uid === kitItem.uid);
                  if (productKitItem) {
                    return fromJS({
                      ...kitItem,
                      customFormula: {
                        ...kitItem.customFormula,
                        uid: productKitItem.custom_formula.uid,
                      },
                    });
                  }
                }

                return fromJS({ ...kitItem });
              }

              return fromJS({ ...kitItem });
            }),
            subKits: parsedItem.subKits.map((subKit, subKitIndex) => {
              const subKitProducts = subKit.kitProducts.map((kitItem) => {
                if (kitItem.pricingStrategy === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL) {
                  if (product) {
                    const subKitItem = product.sub_kits[subKitIndex].kit_products.find(
                      (i) => i.uid === kitItem.uid
                    );

                    if (subKitItem) {
                      return fromJS({
                        ...kitItem,
                        customFormula: {
                          ...kitItem.customFormula,
                          uid: subKitItem.custom_formula.uid,
                        },
                      });
                    }
                  }

                  return fromJS({ ...kitItem });
                }

                return fromJS({ ...kitItem });
              });

              return fromJS({
                ...subKit,
                kitProducts: subKitProducts,
              });
            }),
          });
        }

        return fromJS({ ...parsedItem });
      })
    );

    // For normal product
    _state.updateIn(["items"], (arr) =>
      arr.map((item) => {
        const parsedItem = { ...item.toJS() };
        if (
          parsedItem.pricingStrategy === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL &&
          !parsedItem.isDeleted
        ) {
          const product = data.find((a) => a.uid === parsedItem.uid);
          if (product) {
            return fromJS({
              ...parsedItem,
              customFormula: { ...parsedItem.customFormula, uid: product.custom_formula.uid },
            });
          }

          return fromJS({ ...parsedItem });
        }

        return fromJS({ ...parsedItem });
      })
    );
  };

  changeCustomFormulaValues = (_state, payload) => {
    const { index, itemIndex, subKitIndex, cartIndex, key, value, isProductKit, isSubKit } =
      payload;

    if (isSubKit) {
      _state.setIn(
        [
          "items",
          itemIndex,
          "subKits",
          subKitIndex,
          "kitProducts",
          cartIndex,
          "customFormula",
          "elements",
          index,
          key,
        ],
        value
      );
      _state.setIn(
        [
          "items",
          itemIndex,
          "subKits",
          subKitIndex,
          "kitProducts",
          cartIndex,
          "customFormula",
          "appliedElements",
          index,
          key,
        ],
        value
      );

      try {
        const customFormula = _state
          .getIn([
            "items",
            itemIndex,
            "subKits",
            subKitIndex,
            "kitProducts",
            cartIndex,
            "customFormula",
          ])
          .toJS();
        const formula = customFormula.elements.map((e) => e.value).join("");
        const formulaOutput = evaluate(formula);
        const roundedFormulaOutput = mathjsRound(formulaOutput, 5);
        const formattedFormulaOutput = customFormula.isRoundedToNearestWholeNumber
          ? Math.round(roundedFormulaOutput)
          : Decimal(roundedFormulaOutput).toDecimalPlaces(4).toFixed(4);

        _state.setIn(
          [
            "items",
            itemIndex,
            "subKits",
            subKitIndex,
            "kitProducts",
            cartIndex,
            "customFormulaOutput",
          ],
          formattedFormulaOutput
        );
        _state.setIn(
          [
            "items",
            itemIndex,
            "subKits",
            subKitIndex,
            "kitProducts",
            cartIndex,
            "actualCustomFormulaOutput",
          ],
          formattedFormulaOutput
        );
      } catch (_) {
        /* Do nothing */
      }
    } else if (isProductKit) {
      _state.setIn(
        ["items", itemIndex, "productKitItems", cartIndex, "customFormula", "elements", index, key],
        value
      );
      _state.setIn(
        [
          "items",
          itemIndex,
          "productKitItems",
          cartIndex,
          "customFormula",
          "appliedElements",
          index,
          key,
        ],
        value
      );

      try {
        const customFormula = _state
          .getIn(["items", itemIndex, "productKitItems", cartIndex, "customFormula"])
          .toJS();
        const formula = customFormula.elements.map((e) => e.value).join("");
        const formulaOutput = evaluate(formula);
        const roundedFormulaOutput = mathjsRound(formulaOutput, 5);
        const formattedFormulaOutput = customFormula.isRoundedToNearestWholeNumber
          ? Math.round(roundedFormulaOutput)
          : Decimal(roundedFormulaOutput).toDecimalPlaces(4).toFixed(4);

        _state.setIn(
          ["items", itemIndex, "productKitItems", cartIndex, "customFormulaOutput"],
          formattedFormulaOutput
        );
        _state.setIn(
          ["items", itemIndex, "productKitItems", cartIndex, "actualCustomFormulaOutput"],
          formattedFormulaOutput
        );
      } catch (_) {
        /* Do nothing */
      }
    } else {
      _state.setIn(["items", itemIndex, "customFormula", "elements", index, key], value);
      _state.setIn(["items", itemIndex, "customFormula", "appliedElements", index, key], value);

      try {
        const customFormula = _state.getIn(["items", itemIndex, "customFormula"]).toJS();
        const formula = customFormula.elements.map((e) => e.value).join("");
        const formulaOutput = evaluate(formula);
        const roundedFormulaOutput = mathjsRound(formulaOutput, 5);
        const formattedFormulaOutput = customFormula.isRoundedToNearestWholeNumber
          ? Math.round(roundedFormulaOutput)
          : Decimal(roundedFormulaOutput).toDecimalPlaces(4).toFixed(4);

        _state.setIn(["items", itemIndex, "customFormulaOutput"], formattedFormulaOutput);
        _state.setIn(["items", itemIndex, "actualCustomFormulaOutput"], formattedFormulaOutput);
      } catch (_) {
        /* Do nothing */
      }
    }

    if (isSubKit) {
      const item = _state
        .getIn(["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex])
        .toJS();
      this.changeSubKitItemPriceValue(_state, {
        itemIndex,
        subKitIndex,
        cartIndex,
        type: "value",
        value: item.value,
      });
    } else if (isProductKit) {
      const item = _state.getIn(["items", itemIndex, "productKitItems", cartIndex]).toJS();
      this.changeProductKitItemPriceValue(_state, {
        itemIndex,
        cartIndex,
        type: "value",
        value: item.value,
      });
    } else {
      const item = _state.getIn(["items", itemIndex]).toJS();
      this.changeProductPriceValue(_state, {
        itemId: itemIndex,
        type: "value",
        value: item.value,
      });
    }
  };

  changeActualCustomFormulaValues = (_state, payload) => {
    const { index, itemIndex, subKitIndex, cartIndex, key, value, isProductKit, isSubKit } =
      payload;

    if (isSubKit) {
      _state.setIn(
        [
          "items",
          itemIndex,
          "subKits",
          subKitIndex,
          "kitProducts",
          cartIndex,
          "customFormula",
          "appliedElements",
          index,
          key,
        ],
        value
      );

      try {
        const customFormula = _state
          .getIn([
            "items",
            itemIndex,
            "subKits",
            subKitIndex,
            "kitProducts",
            cartIndex,
            "customFormula",
          ])
          .toJS();
        const formula = customFormula.appliedElements.map((e) => e.value).join("");
        const formulaOutput = evaluate(formula);
        const roundedFormulaOutput = mathjsRound(formulaOutput, 5);
        const formattedFormulaOutput = customFormula.isRoundedToNearestWholeNumber
          ? Math.round(roundedFormulaOutput)
          : Decimal(roundedFormulaOutput).toDecimalPlaces(4).toFixed(4);
        _state.setIn(
          [
            "items",
            itemIndex,
            "subKits",
            subKitIndex,
            "kitProducts",
            cartIndex,
            "actualCustomFormulaOutput",
          ],
          formattedFormulaOutput
        );
      } catch (_) {
        /* Do nothing */
      }
    } else if (isProductKit) {
      _state.setIn(
        [
          "items",
          itemIndex,
          "productKitItems",
          cartIndex,
          "customFormula",
          "appliedElements",
          index,
          key,
        ],
        value
      );

      try {
        const customFormula = _state
          .getIn(["items", itemIndex, "productKitItems", cartIndex, "customFormula"])
          .toJS();
        const formula = customFormula.appliedElements.map((e) => e.value).join("");
        const formulaOutput = evaluate(formula);
        const roundedFormulaOutput = mathjsRound(formulaOutput, 5);
        const formattedFormulaOutput = customFormula.isRoundedToNearestWholeNumber
          ? Math.round(roundedFormulaOutput)
          : Decimal(roundedFormulaOutput).toDecimalPlaces(4).toFixed(4);
        _state.setIn(
          ["items", itemIndex, "productKitItems", cartIndex, "actualCustomFormulaOutput"],
          formattedFormulaOutput
        );
      } catch (_) {
        /* Do nothing */
      }
    } else {
      _state.setIn(["items", itemIndex, "customFormula", "appliedElements", index, key], value);

      try {
        const customFormula = _state.getIn(["items", itemIndex, "customFormula"]).toJS();
        const formula = customFormula.appliedElements.map((e) => e.value).join("");
        const formulaOutput = evaluate(formula);
        const roundedFormulaOutput = mathjsRound(formulaOutput, 5);
        const formattedFormulaOutput = customFormula.isRoundedToNearestWholeNumber
          ? Math.round(roundedFormulaOutput)
          : Decimal(roundedFormulaOutput).toDecimalPlaces(4).toFixed(4);

        _state.setIn(["items", itemIndex, "actualCustomFormulaOutput"], formattedFormulaOutput);
      } catch (_) {
        /* Do nothing */
      }
    }
  };

  addCustomFormulaValues = (_state, payload) => {
    const { elements, itemIndex, subKitIndex, cartIndex, isProductKit, isSubKit } = payload;
    if (isSubKit) {
      _state.setIn(
        [
          "items",
          itemIndex,
          "subKits",
          subKitIndex,
          "kitProducts",
          cartIndex,
          "customFormula",
          "elements",
        ],
        elements
      );
      _state.setIn(
        [
          "items",
          itemIndex,
          "subKits",
          subKitIndex,
          "kitProducts",
          cartIndex,
          "customFormula",
          "appliedElements",
        ],
        elements
      );

      try {
        const customFormula = _state
          .getIn([
            "items",
            itemIndex,
            "subKits",
            subKitIndex,
            "kitProducts",
            cartIndex,
            "customFormula",
          ])
          .toJS();
        const formula = customFormula.elements.map((e) => e.value).join("");
        const formulaOutput = evaluate(formula);
        const roundedFormulaOutput = mathjsRound(formulaOutput, 5);
        const formattedFormulaOutput = customFormula.isRoundedToNearestWholeNumber
          ? Math.round(roundedFormulaOutput)
          : Decimal(roundedFormulaOutput).toDecimalPlaces(4).toFixed(4);

        _state.setIn(
          [
            "items",
            itemIndex,
            "subKits",
            subKitIndex,
            "kitProducts",
            cartIndex,
            "customFormulaOutput",
          ],
          formattedFormulaOutput
        );
        _state.setIn(
          [
            "items",
            itemIndex,
            "subKits",
            subKitIndex,
            "kitProducts",
            cartIndex,
            "actualCustomFormulaOutput",
          ],
          formattedFormulaOutput
        );
      } catch (_) {
        /* Do nothing */
      }
    } else if (isProductKit) {
      _state.setIn(
        ["items", itemIndex, "productKitItems", cartIndex, "customFormula", "elements"],
        elements
      );
      _state.setIn(
        ["items", itemIndex, "productKitItems", cartIndex, "customFormula", "appliedElements"],
        elements
      );

      try {
        const customFormula = _state
          .getIn(["items", itemIndex, "productKitItems", cartIndex, "customFormula"])
          .toJS();
        const formula = customFormula.elements.map((e) => e.value).join("");
        const formulaOutput = evaluate(formula);
        const roundedFormulaOutput = mathjsRound(formulaOutput, 5);
        const formattedFormulaOutput = customFormula.isRoundedToNearestWholeNumber
          ? Math.round(roundedFormulaOutput)
          : Decimal(roundedFormulaOutput).toDecimalPlaces(4).toFixed(4);

        _state.setIn(
          ["items", itemIndex, "productKitItems", cartIndex, "customFormulaOutput"],
          formattedFormulaOutput
        );
        _state.setIn(
          ["items", itemIndex, "productKitItems", cartIndex, "actualCustomFormulaOutput"],
          formattedFormulaOutput
        );
      } catch (_) {
        /* Do nothing */
      }
    } else {
      _state.setIn(["items", itemIndex, "customFormula", "elements"], elements);
      _state.setIn(["items", itemIndex, "customFormula", "appliedElements"], elements);

      try {
        const customFormula = _state.getIn(["items", itemIndex, "customFormula"]).toJS();
        const formula = customFormula.elements.map((e) => e.value).join("");
        const formulaOutput = evaluate(formula);
        const roundedFormulaOutput = mathjsRound(formulaOutput, 5);
        const formattedFormulaOutput = customFormula.isRoundedToNearestWholeNumber
          ? Math.round(roundedFormulaOutput)
          : Decimal(roundedFormulaOutput).toDecimalPlaces(4).toFixed(4);

        _state.setIn(["items", itemIndex, "customFormulaOutput"], formattedFormulaOutput);
        _state.setIn(["items", itemIndex, "actualCustomFormulaOutput"], formattedFormulaOutput);
      } catch (_) {
        /* Do nothing */
      }
    }

    if (isSubKit) {
      const item = _state
        .getIn(["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex])
        .toJS();
      this.changeSubKitItemPriceValue(_state, {
        itemIndex,
        subKitIndex,
        cartIndex,
        type: "value",
        value: item.value,
      });
    } else if (isProductKit) {
      const item = _state.getIn(["items", itemIndex, "productKitItems", cartIndex]).toJS();
      this.changeProductKitItemPriceValue(_state, {
        itemIndex,
        cartIndex,
        type: "value",
        value: item.value,
      });
    } else {
      const item = _state.getIn(["items", itemIndex]).toJS();
      this.changeProductPriceValue(_state, {
        itemId: itemIndex,
        type: "value",
        value: item.value,
      });
    }
  };

  removeCustomFormulaValues = (_state, payload) => {
    const { index, itemIndex, subKitIndex, cartIndex, isProductKit, isSubKit } = payload;

    if (isSubKit) {
      if (index === 0) {
        _state.updateIn(
          [
            "items",
            itemIndex,
            "subKits",
            subKitIndex,
            "kitProducts",
            cartIndex,
            "customFormula",
            "elements",
          ],
          (arr) => arr.filter((_, elementIndex) => elementIndex > index + 1)
        );
        _state.updateIn(
          [
            "items",
            itemIndex,
            "subKits",
            subKitIndex,
            "kitProducts",
            cartIndex,
            "customFormula",
            "appliedElements",
          ],
          (arr) => arr.filter((_, elementIndex) => elementIndex > index + 1)
        );
      } else {
        _state.updateIn(
          [
            "items",
            itemIndex,
            "subKits",
            subKitIndex,
            "kitProducts",
            cartIndex,
            "customFormula",
            "elements",
          ],
          (arr) => arr.filter((_, elementIndex) => elementIndex < index - 1 || elementIndex > index)
        );
        _state.updateIn(
          [
            "items",
            itemIndex,
            "subKits",
            subKitIndex,
            "kitProducts",
            cartIndex,
            "customFormula",
            "appliedElements",
          ],
          (arr) => arr.filter((_, elementIndex) => elementIndex < index - 1 || elementIndex > index)
        );
      }

      try {
        const customFormula = _state
          .getIn([
            "items",
            itemIndex,
            "subKits",
            subKitIndex,
            "kitProducts",
            cartIndex,
            "customFormula",
          ])
          .toJS();
        const formula = customFormula.elements.map((e) => e.value).join("");
        const formulaOutput = evaluate(formula);
        const roundedFormulaOutput = mathjsRound(formulaOutput, 5);
        const formattedFormulaOutput = customFormula.isRoundedToNearestWholeNumber
          ? Math.round(roundedFormulaOutput)
          : Decimal(roundedFormulaOutput).toDecimalPlaces(4).toFixed(4);

        _state.setIn(
          [
            "items",
            itemIndex,
            "subKits",
            subKitIndex,
            "kitProducts",
            cartIndex,
            "customFormulaOutput",
          ],
          formattedFormulaOutput
        );
        _state.setIn(
          [
            "items",
            itemIndex,
            "subKits",
            subKitIndex,
            "kitProducts",
            cartIndex,
            "actualCustomFormulaOutput",
          ],
          formattedFormulaOutput
        );
      } catch (_) {
        /* Do nothing */
      }
    } else if (isProductKit) {
      if (index === 0) {
        _state.updateIn(
          ["items", itemIndex, "productKitItems", cartIndex, "customFormula", "elements"],
          (arr) => arr.filter((_, elementIndex) => elementIndex > index + 1)
        );
        _state.updateIn(
          ["items", itemIndex, "productKitItems", cartIndex, "customFormula", "appliedElements"],
          (arr) => arr.filter((_, elementIndex) => elementIndex > index + 1)
        );
      } else {
        _state.updateIn(
          ["items", itemIndex, "productKitItems", cartIndex, "customFormula", "elements"],
          (arr) => arr.filter((_, elementIndex) => elementIndex < index - 1 || elementIndex > index)
        );
        _state.updateIn(
          ["items", itemIndex, "productKitItems", cartIndex, "customFormula", "appliedElements"],
          (arr) => arr.filter((_, elementIndex) => elementIndex < index - 1 || elementIndex > index)
        );
      }

      try {
        const customFormula = _state
          .getIn(["items", itemIndex, "productKitItems", cartIndex, "customFormula"])
          .toJS();
        const formula = customFormula.elements.map((e) => e.value).join("");
        const formulaOutput = evaluate(formula);
        const roundedFormulaOutput = mathjsRound(formulaOutput, 5);
        const formattedFormulaOutput = customFormula.isRoundedToNearestWholeNumber
          ? Math.round(roundedFormulaOutput)
          : Decimal(roundedFormulaOutput).toDecimalPlaces(4).toFixed(4);

        _state.setIn(
          ["items", itemIndex, "productKitItems", cartIndex, "customFormulaOutput"],
          formattedFormulaOutput
        );
        _state.setIn(
          ["items", itemIndex, "productKitItems", cartIndex, "actualCustomFormulaOutput"],
          formattedFormulaOutput
        );
      } catch (_) {
        /* Do nothing */
      }
    } else {
      if (index === 0) {
        _state.updateIn(["items", itemIndex, "customFormula", "elements"], (arr) =>
          arr.filter((_, elementIndex) => elementIndex > index + 1)
        );
        _state.updateIn(["items", itemIndex, "customFormula", "appliedElements"], (arr) =>
          arr.filter((_, elementIndex) => elementIndex > index + 1)
        );
      } else {
        _state.updateIn(["items", itemIndex, "customFormula", "elements"], (arr) =>
          arr.filter((_, elementIndex) => elementIndex < index - 1 || elementIndex > index)
        );
        _state.updateIn(["items", itemIndex, "customFormula", "appliedElements"], (arr) =>
          arr.filter((_, elementIndex) => elementIndex < index - 1 || elementIndex > index)
        );
      }

      try {
        const customFormula = _state.getIn(["items", itemIndex, "customFormula"]).toJS();
        const formula = customFormula.elements.map((e) => e.value).join("");
        const formulaOutput = evaluate(formula);
        const roundedFormulaOutput = mathjsRound(formulaOutput, 5);
        const formattedFormulaOutput = customFormula.isRoundedToNearestWholeNumber
          ? Math.round(roundedFormulaOutput)
          : Decimal(roundedFormulaOutput).toDecimalPlaces(4).toFixed(4);

        _state.setIn(["items", itemIndex, "customFormulaOutput"], formattedFormulaOutput);
        _state.setIn(["items", itemIndex, "actualCustomFormulaOutput"], formattedFormulaOutput);
      } catch (_) {
        /* Do nothing */
      }
    }

    if (isSubKit) {
      const item = _state
        .getIn(["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex])
        .toJS();
      this.changeSubKitItemPriceValue(_state, {
        itemIndex,
        subKitIndex,
        cartIndex,
        type: "value",
        value: item.value,
      });
    } else if (isProductKit) {
      const item = _state.getIn(["items", itemIndex, "productKitItems", cartIndex]).toJS();
      this.changeProductKitItemPriceValue(_state, {
        itemIndex,
        cartIndex,
        type: "value",
        value: item.value,
      });
    } else {
      const item = _state.getIn(["items", itemIndex]).toJS();
      this.changeProductPriceValue(_state, {
        itemId: itemIndex,
        type: "value",
        value: item.value,
      });
    }
  };

  saveProductToCatalogue = (_state, payload) => {
    const additionalProduct = payload.uid;
    const additionalProductRow = payload.tables[0].values[0].uid;
    const types = payload.tables[0].values[0].types;
    const rowPriceLevel = payload.tables[0].values[0].rowPrices[0].uid;

    _state.setIn(["items", payload.index, "additionalProduct"], additionalProduct);
    _state.setIn(["items", payload.index, "additionalProductRow"], additionalProductRow);
    _state.setIn(["items", payload.index, "rowPriceLevel"], rowPriceLevel);
    _state.setIn(["items", payload.index, "productType"], PRODUCT_TYPE_ADDITIONAL_PRODUCT_LABEL);
    _state.setIn(["items", payload.index, "types"], types);

    const withAccounting = _state.getIn(["customers", "company", "accountingSyncAllowed"]);
    if (withAccounting) {
      _state.setIn(["items", payload.index, "accountingItemName"], row.accountingItem?.name ?? "");
    }
  };

  defineReducers() {
    return {
      [ACTIONS.CREATE_NEW_ORDER]: withMutations(this.createNewOrder),

      [ACTIONS.SET_UIDS]: withMutations(this.setUids),

      [`${ACTIONS.SEARCH_PRODUCTS} fulfilled`]: this.searchAllProducts,

      [`${ACTIONS.SEARCH_PRODUCTS} pending`]: this.thunkPendingReducer(["dataServer", "isPending"]),

      [`${ACTIONS.SEARCH_PRODUCTS_BY_CATEGORY} fulfilled`]: this.searchByCategory,

      [`${ACTIONS.SEARCH_PRODUCTS_BY_CATEGORY} pending`]: (state, { payload: { isPending, id } }) =>
        state.setIn(["categories", id, "isPending"], isPending),

      [`${ACTIONS.GET_PRODUCT} fulfilled`]: withMutations(this.setActiveProduct),

      [`${ACTIONS.GET_PRODUCT} pending`]: this.thunkPendingReducer("isPending"),

      [`${ACTIONS.OPEN_ORDER} fulfilled`]: withMutations(this.openOrder),

      [`${ACTIONS.RESET_ORDER_PRODUCTS} fulfilled`]: (state) =>
        state.set("items", state.get("startStateOrderProducts")),

      // actions for product editor

      [ACTIONS.CREATE_NEW_PRODUCT]: withMutations(this.createNewProduct),

      [ACTIONS.SAVE_PRODUCT_TABLE]: (state, { payload: { material, table } }) =>
        state.updateIn(["activeProduct", "tables"], (arr) =>
          arr.map((item) => {
            if (item.getIn(["name"]) === material) {
              return item.merge(fromJS(table));
            }
            return item;
          })
        ),

      [`${ACTIONS.SUBMIT_PRODUCT} fulfilled`]: (state) => state,

      [`${ACTIONS.SUBMIT_PRODUCT} pending`]: this.thunkPendingReducer("isPending"),

      [`${ACTIONS.SUBMIT_PRODUCT} rejected`]: (state, { payload: errors }) =>
        state.set("errors", errors),

      [`${ACTIONS.SET_DELETED_STATUS_PRODUCT} fulfilled`]: withMutations(
        this.deleteProductFulfilled
      ),

      [`${ACTIONS.SET_DELETED_STATUS_PRODUCT} pending`]: withMutations(this.deleteProductPending),

      [`${ACTIONS.GET_CATEGORIES} fulfilled`]: (state, { payload: { data } }) =>
        state.set("categories", fromJS(camelcaseKeys(data))),

      [`${ACTIONS.GET_CATEGORIES} pending`]: this.thunkPendingReducer("isPending"),

      [`${ACTIONS.CREATE_CATEGORY} fulfilled`]: (state, { payload: { id, name, uid } }) => {
        if (name) {
          return state.update("categories", (arr) => arr.push(fromJS({ name, uid: "" })));
        }

        return state.setIn(["categories", id, "uid"], uid);
      },

      [`${ACTIONS.CREATE_CATEGORY} pending`]: (state, { payload: { isPending, id } }) =>
        state.setIn(["categories", id, "isPending"], isPending),

      [`${ACTIONS.RENAME_CATEGORY} fulfilled`]: withMutations(this.renameCategory),

      [`${ACTIONS.RENAME_CATEGORY} pending`]: (state, { payload: { isPending, id } }) =>
        state.setIn(["categories", id, "isPending"], isPending),

      [`${ACTIONS.DELETE_CATEGORY} fulfilled`]: (state, { payload: { isDeleted, id } }) =>
        state.setIn(["categories", id, "isDeleted"], isDeleted),

      [`${ACTIONS.DELETE_CATEGORY} pending`]: (state, { payload: { isPending, id } }) =>
        state.setIn(["categories", id, "isPending"], isPending),

      [ACTIONS.CHANGE_PRODUCT_CATEGORY]: withMutations(this.changeCategory),

      [`${ACTIONS.SELECT_MATERIAL} fulfilled`]: withMutations(this.selectMaterial),

      [ACTIONS.CREATE_MATERIAL_TABLE]: withMutations(this.createMaterialTable),

      [ACTIONS.REMOVE_MATERIAL_TABLE]: withMutations(this.removeMaterialTable),

      // actions for adding to order

      [ACTIONS.SET_ACTIVE_PRODUCT]: withMutations(this.setActiveProduct),

      [ACTIONS.SET_ACTIVE_TAB]: (state, { payload }) => state.setIn(["activeTabs"], payload || {}),

      [ACTIONS.SORT_BY_CATEGORY]: (state) =>
        state.update("items", (list) => {
          let defaultCategory = fromJS([]);
          let newList = list.filter((item) => {
            if (item.get("categoryName") !== "Miscellaneous") {
              return true;
            }

            defaultCategory = defaultCategory.push(item);
            return false;
          });

          newList = newList.sort((a, b) => {
            if (!a.get("categoryName")) return 1;
            if (!b.get("categoryName")) return -1;

            const catName1 = a.get("categoryName").toLowerCase();
            const catName2 = b.get("categoryName").toLowerCase();
            if (catName1 === catName2) {
              const prodName1 = a.get("name").toLowerCase();
              const prodName2 = b.get("name").toLowerCase();

              if (prodName1 < prodName2) return -1;
              if (prodName1 > prodName2) return 1;
            }
            if (catName1 < catName2) return -1;
            if (catName1 > catName2) return 1;
            return 0;
          });

          return newList.concat(defaultCategory);
        }),

      [`${ACTIONS.ADD_ADDITIONAL_PRODUCT} fulfilled`]: (state, { payload: item }) =>
        state.update("items", (list) => list.push(item)),

      [`${ACTIONS.ADD_ON_THE_FLY_PRODUCT_ITEM} fulfilled`]: (state, { payload: item }) =>
        state.update("items", (list) => list.push(item)),
      [`${ACTIONS.ADD_LABOUR_LINE_ITEM} fulfilled`]: (state, { payload: item }) =>
        state.update("items", (list) => list.push(item)),
      [`${ACTIONS.EDIT_LABOUR_LINE_ITEM} fulfilled`]: (state, { payload: { itemIndex, item } }) =>
        state.setIn(["items", itemIndex], item),
      [`${ACTIONS.ADD_NOTES_LINE_ITEM} fulfilled`]: (state, { payload: item }) =>
        state.update("items", (list) => list.push(item)),

      [ACTIONS.EDIT_ADDITIONAL_PRODUCT]: (state, { payload }) =>
        state.set("editItem", fromJS(payload) || null),

      [`${ACTIONS.CHANGE_PRODUCT_IS_TAX_FREE} fulfilled`]: (
        state,
        { payload: { itemIndex, status } }
      ) => state.setIn(["items", itemIndex, "isTaxFree"], status),

      [`${ACTIONS.CHANGE_PRODUCT_KIT_ITEM_IS_TAX_FREE} fulfilled`]: (
        state,
        { payload: { itemIndex, cartIndex, status } }
      ) => state.setIn(["items", itemIndex, "productKitItems", cartIndex, "isTaxFree"], status),

      [`${ACTIONS.CHANGE_SUB_KIT_ITEM_IS_TAX_FREE} fulfilled`]: (
        state,
        { payload: { itemIndex, subKitIndex, cartIndex, status } }
      ) =>
        state.setIn(
          ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "isTaxFree"],
          status
        ),

      [`${ACTIONS.DUPLICATE_ADDITIONAL_PRODUCT} fulfilled`]: (state, { payload: item }) =>
        state.update("items", (list) => list.push(item)),

      [`${ACTIONS.DUPLICATE_KIT_ITEM} fulfilled`]: (state, { payload: { itemIndex, items } }) =>
        state.setIn(["items", itemIndex, "productKitItems"], items),

      [`${ACTIONS.DUPLICATE_SUB_KIT_ITEM} fulfilled`]: (
        state,
        { payload: { itemIndex, subKitIndex, items } }
      ) => state.setIn(["items", itemIndex, "subKits", subKitIndex, "kitProducts"], items),

      [`${ACTIONS.SAVE_EDIT_ADDITIONAL_PRODUCT} fulfilled`]: (
        state,
        { payload: { editItem, itemId } }
      ) => state.setIn(["items", itemId], fromJS(editItem)),

      [`${ACTIONS.CHANGE_PRODUCT_QUANTITY} fulfilled`]: (state, { payload: { itemId, value } }) => {
        const item = state.getIn(["items", itemId]).toJS();

        if (item.productType === PRODUCT_TYPE_LABOUR_PRODUCT_LABEL) {
          return state
            .setIn(["items", itemId, "quantity"], `${value}`)
            .setIn(["items", itemId, "actualQuantity"], `${value}`)
            .setIn(["items", itemId, "totalTrackedTime"], "0.0000")
            .setIn(["items", itemId, "customActualQuantity"], "0.0000");
        }

        return state
          .setIn(["items", itemId, "quantity"], `${value}`)
          .setIn(["items", itemId, "actualQuantity"], `${value}`);
      },

      [`${ACTIONS.CHANGE_PRODUCT_ACTUAL_QUANTITY} fulfilled`]: (
        state,
        { payload: { itemId, value, customActualQuantity } }
      ) =>
        state
          .setIn(["items", itemId, "actualQuantity"], `${value}`)
          .setIn(["items", itemId, "customActualQuantity"], `${customActualQuantity}`),

      [`${ACTIONS.CHANGE_PRODUCT_KIT_QUANTITY} fulfilled`]: (
        state,
        { payload: { itemId, value, newKitItems } }
      ) => {
        return state
          .setIn(["items", itemId, "quantity"], `${value}`)
          .setIn(["items", itemId, "actualQuantity"], `${value}`)
          .setIn(["items", itemId, "productKitItems"], newKitItems);
      },

      [`${ACTIONS.CHANGE_SUB_KIT_QUANTITY} fulfilled`]: (
        state,
        { payload: { itemId, subKitIndex, value, newSubKitItems } }
      ) =>
        state
          .setIn(["items", itemId, "subKits", subKitIndex, "quantity"], `${value}`)
          .setIn(["items", itemId, "subKits", subKitIndex, "actualQuantity"], `${value}`)
          .setIn(["items", itemId, "subKits", subKitIndex, "kitProducts"], newSubKitItems),

      [`${ACTIONS.CHANGE_PRODUCT_KIT_ACTUAL_QUANTITY} fulfilled`]: (
        state,
        { payload: { itemId, value, newKitItems } }
      ) =>
        state
          .setIn(["items", itemId, "actualQuantity"], `${value}`)
          .setIn(["items", itemId, "productKitItems"], newKitItems),

      [`${ACTIONS.CHANGE_SUB_KIT_ACTUAL_QUANTITY} fulfilled`]: (
        state,
        { payload: { itemId, subKitIndex, value, newSubKitItems } }
      ) =>
        state
          .setIn(["items", itemId, "subKits", subKitIndex, "actualQuantity"], `${value}`)
          .setIn(["items", itemId, "subKits", subKitIndex, "kitProducts"], newSubKitItems),

      [`${ACTIONS.CHANGE_PRODUCT_KIT_ITEM_QUANTITY} fulfilled`]: (
        state,
        { payload: { itemIndex, cartIndex, kitQuantity, quantity } }
      ) => {
        const item = state.getIn(["items", itemIndex, "productKitItems", cartIndex]).toJS();
        const path = ["items", itemIndex, "productKitItems", cartIndex];

        if (item.productType === PRODUCT_TYPE_LABOUR_PRODUCT_LABEL) {
          return state
            .setIn([...path, "quantity"], `${quantity}`)
            .setIn([...path, "actualQuantity"], `${quantity}`)
            .setIn(
              [...path, "baseQuantity"],
              `${Decimal(quantity).dividedBy(kitQuantity).toDecimalPlaces(4)}`
            )
            .setIn([...path, "totalTrackedTime"], "0.0000")
            .setIn([...path, "customActualQuantity"], "0.0000");
        }

        return state
          .setIn([...path, "quantity"], `${quantity}`)
          .setIn([...path, "actualQuantity"], `${quantity}`)
          .setIn(
            [...path, "baseQuantity"],
            `${Decimal(quantity).dividedBy(kitQuantity).toDecimalPlaces(4)}`
          );
      },

      [`${ACTIONS.CHANGE_SUB_KIT_ITEM_QUANTITY} fulfilled`]: (
        state,
        { payload: { itemIndex, subKitIndex, cartIndex, subKitQuantity, quantity } }
      ) => {
        const item = state
          .getIn(["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex])
          .toJS();

        const path = ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex];
        if (item.productType === PRODUCT_TYPE_LABOUR_PRODUCT_LABEL) {
          return state
            .setIn([...path, "quantity"], `${quantity}`)
            .setIn([...path, "actualQuantity"], `${quantity}`)
            .setIn(
              [...path, "baseQuantity"],
              `${Decimal(quantity).dividedBy(subKitQuantity).toDecimalPlaces(4)}`
            )
            .setIn([...path, "totalTrackedTime"], "0.0000")
            .setIn([...path, "customActualQuantity"], "0.0000");
        }

        return state
          .setIn([...path, "quantity"], `${quantity}`)
          .setIn([...path, "actualQuantity"], `${quantity}`)
          .setIn(
            [...path, "baseQuantity"],
            `${Decimal(quantity).dividedBy(subKitQuantity).toDecimalPlaces(4)}`
          );
      },

      [`${ACTIONS.CHANGE_PRODUCT_KIT_ITEM_ACTUAL_QUANTITY} fulfilled`]: (
        state,
        { payload: { itemIndex, cartIndex, actualQuantity, customActualQuantity = 0 } }
      ) =>
        state
          .setIn(
            ["items", itemIndex, "productKitItems", cartIndex, "actualQuantity"],
            `${actualQuantity}`
          )
          .setIn(
            ["items", itemIndex, "productKitItems", cartIndex, "customActualQuantity"],
            `${customActualQuantity}`
          ),

      [`${ACTIONS.CHANGE_SUB_KIT_ITEM_ACTUAL_QUANTITY} fulfilled`]: (
        state,
        { payload: { itemIndex, subKitIndex, cartIndex, actualQuantity, customActualQuantity = 0 } }
      ) =>
        state
          .setIn(
            [
              "items",
              itemIndex,
              "subKits",
              subKitIndex,
              "kitProducts",
              cartIndex,
              "actualQuantity",
            ],
            `${actualQuantity}`
          )
          .setIn(
            [
              "items",
              itemIndex,
              "subKits",
              subKitIndex,
              "kitProducts",
              cartIndex,
              "customActualQuantity",
            ],
            `${customActualQuantity}`
          ),

      [`${ACTIONS.ADD_PRODUCT_LENGTH} fulfilled`]: withMutations(this.addProductLength),
      [`${ACTIONS.ADD_PRODUCT_ACTUAL_LENGTH} fulfilled`]: withMutations(
        this.addProductActualLength
      ),
      [`${ACTIONS.ADD_PRODUCT_KIT_ITEM_LENGTH} fulfilled`]: withMutations(
        this.addProductKitItemLength
      ),
      [`${ACTIONS.ADD_PRODUCT_KIT_ITEM_DIMENSION} fulfilled`]: withMutations(
        this.addProductKitItemDimension
      ),
      [`${ACTIONS.ADD_SUB_KIT_ITEM_LENGTH} fulfilled`]: withMutations(this.addSubKitItemLength),
      [`${ACTIONS.ADD_SUB_KIT_ITEM_DIMENSION} fulfilled`]: withMutations(
        this.addSubKitItemDimension
      ),
      [`${ACTIONS.ADD_PRODUCT_KIT_ITEM_ACTUAL_LENGTH} fulfilled`]: withMutations(
        this.addProductKitItemActualLength
      ),
      [`${ACTIONS.ADD_PRODUCT_KIT_ITEM_ACTUAL_DIMENSION} fulfilled`]: withMutations(
        this.addProductKitItemActualDimension
      ),
      [`${ACTIONS.ADD_SUB_KIT_ITEM_ACTUAL_LENGTH} fulfilled`]: withMutations(
        this.addSubKitItemActualLength
      ),
      [`${ACTIONS.ADD_SUB_KIT_ITEM_ACTUAL_DIMENSION} fulfilled`]: withMutations(
        this.addSubKitItemActualDimension
      ),
      [`${ACTIONS.ADD_PRODUCT_DIMENSION} fulfilled`]: withMutations(this.addProductDimension),
      [`${ACTIONS.ADD_PRODUCT_ACTUAL_DIMENSION} fulfilled`]: withMutations(
        this.addProductActualDimension
      ),
      [`${ACTIONS.DELETE_PRODUCT_LENGTH} fulfilled`]: withMutations(this.deleteProductLength),
      [`${ACTIONS.DELETE_PRODUCT_ACTUAL_LENGTH} fulfilled`]: withMutations(
        this.deleteProductActualLength
      ),
      [`${ACTIONS.DELETE_PRODUCT_KIT_ITEM_LENGTH} fulfilled`]: withMutations(
        this.deleteProductKitItemLength
      ),
      [`${ACTIONS.DELETE_PRODUCT_KIT_ITEM_DIMENSION} fulfilled`]: withMutations(
        this.deleteProductKitItemDimension
      ),
      [`${ACTIONS.DELETE_SUB_KIT_ITEM_LENGTH} fulfilled`]: withMutations(
        this.deleteSubKitItemLength
      ),
      [`${ACTIONS.DELETE_SUB_KIT_ITEM_DIMENSION} fulfilled`]: withMutations(
        this.deleteSubKitItemDimension
      ),
      [`${ACTIONS.DELETE_PRODUCT_KIT_ITEM_ACTUAL_LENGTH} fulfilled`]: withMutations(
        this.deleteProductKitItemActualLength
      ),
      [`${ACTIONS.DELETE_PRODUCT_KIT_ITEM_ACTUAL_DIMENSION} fulfilled`]: withMutations(
        this.deleteProductKitItemActualDimension
      ),
      [`${ACTIONS.DELETE_SUB_KIT_ITEM_ACTUAL_LENGTH} fulfilled`]: withMutations(
        this.deleteSubKitItemActualLength
      ),
      [`${ACTIONS.DELETE_SUB_KIT_ITEM_ACTUAL_DIMENSION} fulfilled`]: withMutations(
        this.deleteSubKitItemActualDimension
      ),

      [`${ACTIONS.DELETE_PRODUCT_DIMENSION} fulfilled`]: withMutations(this.deleteProductDimension),
      [`${ACTIONS.DELETE_PRODUCT_ACTUAL_DIMENSION} fulfilled`]: withMutations(
        this.deleteProductActualDimension
      ),

      [`${ACTIONS.REMOVE_PRODUCT_FROM_ORDER} fulfilled`]: this.removeItemFromOrder,

      [`${ACTIONS.DELETE_SUB_KIT_FROM_ORDER} fulfilled`]: this.removeSubKitFromOrder,

      [`${ACTIONS.REMOVE_KIT_ITEM_FROM_ORDER} fulfilled`]: this.removeKitItemFromOrder,

      [`${ACTIONS.REMOVE_SUB_KIT_ITEM_FROM_ORDER} fulfilled`]: this.removeSubKitItemFromOrder,

      [ACTIONS.CHANGE_ITEM_ORDER_INDEX]: (state, { payload }) => {
        const orderItems = state.getIn(["items"]).toJS();
        const removed = orderItems.find((_, index) => payload.source.index === index);
        const newList = orderItems.filter((_, index) => payload.source.index !== index);
        newList.splice(payload.destination.index, 0, removed);

        return state.setIn(
          ["items"],
          fromJS(
            newList.map((n, index) => ({
              ...n,
              index,
            }))
          )
        );
      },

      [ACTIONS.CHANGE_PRODUCT_KIT_ITEM_ORDER_INDEX]: (state, { payload }) => {
        const orderItems = state.getIn(["items"]).toJS();
        const [itemPart] = payload.draggableId.split("-");
        const [, itemIndex] = itemPart.split("_");

        const { productKitItems } = orderItems[Number(itemIndex)];

        const removedKitItem = productKitItems.find((_, index) => payload.source.index === index);
        const newList = productKitItems.filter((_, index) => payload.source.index !== index);

        newList.splice(payload.destination.index, 0, removedKitItem);

        orderItems[itemIndex].productKitItems = newList.map((n, index) => ({
          ...n,
          cartIndex: index,
        }));

        return state.setIn(["items"], fromJS(orderItems));
      },

      [ACTIONS.CHANGE_SUB_KIT_ITEM_ORDER_INDEX]: (state, { payload }) => {
        let newState = state;
        const orderItems = state.getIn(["items"]).toJS();
        const [itemPart, subKitPart] = payload.draggableId.split("-");
        const [, itemIndex] = itemPart.split("_");
        const [, subKitIndex] = subKitPart.split("_");
        const { kitProducts } = orderItems[Number(itemIndex)].subKits[Number(subKitIndex)];

        // Remove the dragged item from the list
        const removed = kitProducts[payload.source.index];
        const newList = kitProducts.filter((_, index) => payload.source.index !== index);

        // Insert the dragged item at the new position
        newList.splice(payload.destination.index, 0, removed);

        // Update the orderItems with the new list
        const updatedOrderItems = orderItems.map((item, index) => {
          if (index === Number(itemIndex)) {
            return {
              ...item,
              subKits: item.subKits.map((subKit, subKitIdx) => {
                if (subKitIdx === Number(subKitIndex)) {
                  return {
                    ...subKit,
                    kitProducts: newList.map((n, idx) => ({
                      ...n,
                      cartIndex: idx,
                    })),
                  };
                }
                return subKit;
              }),
            };
          }
          return item;
        });

        // Update state immutably
        newState = newState.setIn(["items"], fromJS(updatedOrderItems));
        return newState;
      },

      [ACTIONS.CHANGE_SUB_KIT_ORDER_INDEX]: (state, { payload }) => {
        const orderItems = state.getIn(["items"]).toJS();
        const [itemPart] = payload.draggableId.split("-");
        const [, itemIndex] = itemPart.split("_");
        const subKits = orderItems[Number(itemIndex)]["subKits"];
        const removed = subKits.find((_, index) => payload.source.index === index);
        const newList = subKits.filter((_, index) => payload.source.index !== index);
        newList.splice(payload.destination.index, 0, removed);

        orderItems[itemIndex].subKits = newList.map((n, index) => ({
          ...n,
          subKitIndex: index,
        }));

        return state.setIn(["items"], fromJS(orderItems));
      },

      [ACTIONS.CHANGE_PRODUCT_NAME]: (state, { payload: { itemId, value } }) =>
        state.setIn(["items", itemId, "name"], value),

      [ACTIONS.CHANGE_SUB_KIT_TITLE]: (state, { payload: { itemId, subKitIndex, value } }) =>
        state.setIn(["items", itemId, "subKits", subKitIndex, "title"], value),

      [ACTIONS.CHANGE_PRODUCT_NOTES]: (state, { payload: { itemId, value } }) =>
        state.setIn(["items", itemId, "notes"], value),

      [ACTIONS.CHANGE_PRODUCT_DESCRIPTION]: (state, { payload: { itemId, value } }) =>
        state.setIn(["items", itemId, "productDescription"], value),

      [ACTIONS.CHANGE_PRODUCT_KIT_ITEM_NAME]: (
        state,
        { payload: { itemIndex, cartIndex, value } }
      ) => state.setIn(["items", itemIndex, "productKitItems", cartIndex, "productName"], value),

      [ACTIONS.CHANGE_SUB_KIT_ITEM_NAME]: (
        state,
        { payload: { itemIndex, subKitIndex, cartIndex, value } }
      ) =>
        state.setIn(
          ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "productName"],
          value
        ),

      [`${ACTIONS.CHANGE_PRODUCT_KIT_DESCRIPTION} fulfilled`]: (
        state,
        { payload: { itemIndex, value } }
      ) => state.setIn(["items", itemIndex, "productKitDescription"], value),

      [ACTIONS.CHANGE_PRODUCT_TYPE]: (state, { payload }) => {
        const { itemId, id, type, value } = payload;
        return state.setIn(["items", itemId, "types", id, type], value);
      },

      [ACTIONS.CHANGE_PRODUCT_KIT_ITEM_TYPE]: (state, { payload }) => {
        const { itemIndex, cartIndex, id, type, value } = payload;
        return state.setIn(
          ["items", itemIndex, "productKitItems", cartIndex, "types", id, type],
          value
        );
      },

      [ACTIONS.CHANGE_SUB_KIT_ITEM_TYPE]: (state, { payload }) => {
        const { itemIndex, subKitIndex, cartIndex, id, type, value } = payload;
        return state.setIn(
          ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "types", id, type],
          value
        );
      },

      [ACTIONS.CHANGE_PRODUCT_KIT_ITEM_NOTES]: (
        state,
        { payload: { itemIndex, cartIndex, value } }
      ) => state.setIn(["items", itemIndex, "productKitItems", cartIndex, "notes"], value),

      [ACTIONS.CHANGE_SUB_KIT_ITEM_NOTES]: (
        state,
        { payload: { itemIndex, subKitIndex, cartIndex, value } }
      ) =>
        state.setIn(
          ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "notes"],
          value
        ),

      [ACTIONS.CHANGE_PRODUCT_KIT_ITEM_DESCRIPTION]: (
        state,
        { payload: { itemIndex, cartIndex, value } }
      ) =>
        state.setIn(
          ["items", itemIndex, "productKitItems", cartIndex, "productDescription"],
          value
        ),

      [ACTIONS.CHANGE_SUB_KIT_ITEM_DESCRIPTION]: (
        state,
        { payload: { itemIndex, subKitIndex, cartIndex, value } }
      ) =>
        state.setIn(
          [
            "items",
            itemIndex,
            "subKits",
            subKitIndex,
            "kitProducts",
            cartIndex,
            "productDescription",
          ],
          value
        ),

      [ACTIONS.CHANGE_PRODUCT_COMPLETED_STATUS]: (state, { payload: { itemId, value } }) =>
        state.setIn(["items", itemId, "isCompleted"], value),

      [ACTIONS.CHANGE_PRODUCT_KIT_ITEM_COMPLETED_STATUS]: (
        state,
        { payload: { itemIndex, cartIndex, status } }
      ) => state.setIn(["items", itemIndex, "productKitItems", cartIndex, "isCompleted"], status),

      [ACTIONS.CHANGE_SUB_KIT_ITEM_COMPLETED_STATUS]: (
        state,
        { payload: { itemIndex, subKitIndex, cartIndex, status } }
      ) =>
        state.setIn(
          ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "isCompleted"],
          status
        ),

      [ACTIONS.CHANGE_SUB_KIT_COMPLETED_STATUS]: (
        state,
        { payload: { itemIndex, subKitIndex, status } }
      ) => state.setIn(["items", itemIndex, "subKits", subKitIndex, "isCompleted"], status),

      [`${ACTIONS.CHANGE_PRICE_VALUE} fulfilled`]: withMutations(this.changeProductPriceValue),

      [`${ACTIONS.CHANGE_PRODUCT_KIT_ITEM_PRICE_VALUE} fulfilled`]: withMutations(
        this.changeProductKitItemPriceValue
      ),

      [`${ACTIONS.CHANGE_SUB_KIT_ITEM_PRICE_VALUE} fulfilled`]: withMutations(
        this.changeSubKitItemPriceValue
      ),

      [`${ACTIONS.CHANGE_CUSTOM_FORMULA_VALUES} fulfilled`]: withMutations(
        this.changeCustomFormulaValues
      ),
      [`${ACTIONS.CHANGE_ACTUAL_CUSTOM_FORMULA_VALUES} fulfilled`]: withMutations(
        this.changeActualCustomFormulaValues
      ),
      [`${ACTIONS.ADD_CUSTOM_FORMULA_VALUE} fulfilled`]: withMutations(this.addCustomFormulaValues),
      [`${ACTIONS.REMOVE_CUSTOM_FORMULA_VALUE} fulfilled`]: withMutations(
        this.removeCustomFormulaValues
      ),
      [ACTIONS.UPDATE_UID_OF_CUSTOM_FORMULA]: withMutations(this.updateUidOfCustomFormula),

      [`${ACTIONS.SAVE_PRODUCT_TO_CATALOGUE} fulfilled`]: withMutations(
        this.saveProductToCatalogue
      ),
      [`${ACTIONS.SAVE_PRODUCT_TO_CATALOGUE} pending`]: (
        state,
        { payload: { isSavingToProductCatalogue, id } }
      ) => state.setIn(["items", id, "isSavingToProductCatalogue"], isSavingToProductCatalogue),

      [`${ACTIONS.ADD_ITEM_TO_KIT} fulfilled`]: (state, { payload: { itemIndex, items } }) =>
        state.setIn(["items", itemIndex, "productKitItems"], items),

      [`${ACTIONS.ADD_ON_THE_FLY_ITEM_TO_KIT} fulfilled`]: (
        state,
        { payload: { itemIndex, items } }
      ) => state.setIn(["items", itemIndex, "productKitItems"], items),

      [`${ACTIONS.ADD_LABOUR_LINE_ITEM_TO_KIT} fulfilled`]: (
        state,
        { payload: { itemIndex, items } }
      ) => state.setIn(["items", itemIndex, "productKitItems"], items),

      [`${ACTIONS.ADD_NOTES_ITEM_TO_KIT} fulfilled`]: (state, { payload: { itemIndex, items } }) =>
        state.setIn(["items", itemIndex, "productKitItems"], items),

      [`${ACTIONS.SELECT_MYOB_JOB} fulfilled`]: (state, { payload: { itemIndex, data } }) =>
        state.setIn(["items", itemIndex, "job"], data.id ? data : null),
      [`${ACTIONS.SELECT_MYOB_JOB_TO_KIT_ITEM} fulfilled`]: (
        state,
        { payload: { itemIndex, cartIndex, data } }
      ) =>
        state.setIn(
          ["items", itemIndex, "productKitItems", cartIndex, "job"],
          data.id ? data : null
        ),
      [`${ACTIONS.SELECT_MYOB_JOB_TO_SUB_KIT_ITEM} fulfilled`]: (
        state,
        { payload: { itemIndex, subKitIndex, cartIndex, data } }
      ) =>
        state.setIn(
          ["items", itemIndex, "subKits", subKitIndex, "kitProducts", cartIndex, "job"],
          data.id ? data : null
        ),
    };
  }
}

const instance = new AdditionalProducts();
instance.init();

export default instance;
