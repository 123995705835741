import createActionType from "../../utils/createActionType";

export const MODULE = "delivery-scheduling/calendar";

export const SET_ORDER_IN_CALENDAR = createActionType(MODULE, "setOrderInCalendar");

export const SET_TO_DATE = createActionType(MODULE, "setToDate");

export const SET_FROM_DATE = createActionType(MODULE, "setFromDate");

export const SET_IS_FETCHING_SCHEDULES = createActionType(MODULE, "setIsFetchingSchedules");

export const SET_IS_CONTEXT_MENU_OPEN = createActionType(MODULE, "setIsContextMenuOpen");

export const SET_CALENDAR_VIEW = createActionType(MODULE, "setCalendarView");

export const SET_ACTIVE_SCHEDULE = createActionType(MODULE, "setActiveSchedule");

export const SET_DATA_IN_CALENDAR_ENTRY = createActionType(MODULE, "setDataInCalendarEntry");

export const RESET = createActionType(MODULE, "reset");
