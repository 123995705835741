import { CREATE_ALERT, DELETE_ALERT } from "./alerts.actionTypes";

export const createAlertAction = (
  id,
  message,
  autoclose,
  type,
  buttonText,
  callback
) => ({
  type: CREATE_ALERT,
  payload: { id, message, autoclose, type, buttonText, callback },
});

export const deleteAlertAction = (id) => ({
  type: DELETE_ALERT,
  payload: { id },
});
