import { MODULE } from "./orderChecklistTemplates.actionTypes";

export const isTemplateModalVisible = (state) => state.getIn([MODULE, "isTemplateModalVisible"]);

export const isFetchingTemplates = (state) => state.getIn([MODULE, "isFetchingTemplates"]);

export const isSavingTemplate = (state) => state.getIn([MODULE, "isSavingTemplate"]);

export const getTemplateToBeAddedInOrder = (state) =>
  state.getIn([MODULE, "templateToBeAddedInOrder"]);

export const isTemplateOverrideModalVisible = (state) =>
  state.getIn([MODULE, "isTemplateOverrideModalVisible"]);

export const isOverridingTemplate = (state) => state.getIn([MODULE, "isOverridingTemplate"]);

export const getTemplates = (state) => state.getIn([MODULE, "templates"]);

export const getTemplatesForSearch = (state) => state.getIn([MODULE, "templateListsForSearch"]);

export const getExpandedTemplateUids = (state) => state.getIn([MODULE, "expandedTemplateUids"]);

export const getTemplateToOverride = (state) => state.getIn([MODULE, "templateToOverride"]);

export const getActiveTemplate = (state) => state.getIn([MODULE, "activeTemplate"]);

export default {};
