import * as orderSelectors from "../orders/orders.selectors";
import * as calendarSelectors from "../calendar/calendar.selectors";

import { MODULE } from "./deliveryScheduling.actionTypes";

export const isFetchingSettings = (state) => state.getIn([MODULE, "isFetchingSettings"]);

export const isPageLoading = (state) =>
  orderSelectors.isFetchingOrders(state) ||
  isFetchingSettings(state) ||
  calendarSelectors.isFetchingSchedules(state);

export const getSettings = (state) => state.getIn([MODULE, "settings"]);

export const getVehicles = (state) => {
  const settings = state.getIn([MODULE, "settings"]);

  if (settings) return settings.vehicles;
  return [];
};

export const isDeliverySettingsVisible = (state) =>
  state.getIn([MODULE, "isDeliverySettingsVisible"]);

export const isDeleteVehicleModalVisible = (state) =>
  state.getIn([MODULE, "isDeleteVehicleModalVisible"]);

export const isDeliveryRunSheetVisible = (state) =>
  state.getIn([MODULE, "isDeliveryRunSheetVisible"]);

export const isAssignUserMenuVisible = (state) => state.getIn([MODULE, "isAssignUserMenuVisible"]);

export const isAssignLabelMenuVisible = (state) =>
  state.getIn([MODULE, "isAssignLabelMenuVisible"]);

export const getAssignUserDropdownCoordinates = (state) =>
  state.getIn([MODULE, "assignUserDropdownCoordinates"]);

export const getAssignLabelDropdownCoordinates = (state) =>
  state.getIn([MODULE, "assignLabelDropdownCoordinates"]);

export const getActiveOrder = (state) => state.getIn([MODULE, "activeOrder"]);

export const isOrderModalVisible = (state) => state.getIn([MODULE, "isOrderModalVisible"]);

export const getSelectedResources = (state) => state.getIn([MODULE, "selectedResources"]);

export const getSelectedVehicle = (state) => state.getIn([MODULE, "selectedVehicle"]);

export const isOrderColumnCollapse = (state) => state.getIn([MODULE, "isOrderColumnCollapse"]);
