import React from "react";
import PropTypes from "prop-types";
import { Spinner } from "reactstrap";

class Loading extends React.Component {
  static propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    size: 3,
    color: "primary",
    className: "",
  };

  render() {
    const { size, color, className } = this.props;
    const _size = `${size}rem`;

    let loadingClassName = "d-flex justify-content-center align-items-center";
    loadingClassName += className ? ` ${className}` : "";

    return (
      <div className={loadingClassName}>
        <Spinner style={{ width: _size, height: _size }} color={color} />
      </div>
    );
  }
}

export default Loading;
