import { handleError } from "../../utils/error";
import * as api from "../../../apiv2/labels";
import * as routerSelectors from "../../router/router.selectors";

import * as selectors from "./labelFilter.selectors";
import {
  SET_IS_FETCHING_LABELS,
  SET_LABELS,
  SET_SELECTED_LABELS,
  RESET,
} from "./labelFilter.actionTypes";

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setLabels = (data) => (dispatch) => {
  dispatch({
    type: SET_LABELS,
    payload: data,
  });
};

export const setSelectedLabels = (data) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_LABELS,
    payload: data,
  });
};

export const setIsFetchingLabels = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_LABELS,
    payload: status,
  });
};

export const fetchLabels = () => async (dispatch) => {
  try {
    dispatch(setIsFetchingLabels(true));
    const response = await api.getLabels()();
    dispatch(setLabels(response.data));
    dispatch(setSelectedLabels([...response.data.map((r) => r.uid), "no_label"]));
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetchingLabels(false));
  }
};

export const setLabelFilter = (uid) => (dispatch, getState) => {
  const state = getState();

  const selectedLabels = selectors.getSelectedLabels(state);
  const allLabels = selectors.getLabels(state);

  if (uid === "" && selectedLabels.length === allLabels.length + 1) {
    return dispatch(setSelectedLabels([]));
  }

  if (uid === "" && (selectedLabels.length === 0 || selectedLabels.length < allLabels.length + 1)) {
    return dispatch(setSelectedLabels([...allLabels.map((l) => l.uid), "no_label"]));
  }

  if (uid === "" && selectedLabels.length === 0) {
    return dispatch(setSelectedLabels([...allLabels.map((l) => l.uid), "no_label"]));
  }

  if (selectedLabels.includes(uid)) {
    return dispatch(setSelectedLabels(selectedLabels.filter((labelUid) => labelUid !== uid)));
  }

  return dispatch(setSelectedLabels([...selectedLabels, uid]));
};

export const selectAllLabels = () => (dispatch, getState) => {
  const state = getState();
  const labels = selectors.getLabels(state);

  dispatch(setSelectedLabels([...labels.map((r) => r.uid), "no_label"]));
};

export const selectAllLabelsFromQueryParams = () => (dispatch, getState) => {
  const state = getState();
  const queryParams = routerSelectors.getQuery(state);

  if (queryParams.labels) {
    dispatch(
      setSelectedLabels(
        Array.isArray(queryParams.labels) ? queryParams.labels : [queryParams.labels]
      )
    );
  } else {
    dispatch(setSelectedLabels([]));
  }
};
