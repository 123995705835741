/*eslint-disable */
export const DEFAULT_ROLE = "standard user";

export const ROLES = {
  administrator: {
    id: 0,
    access: [
      { path: "/profile", tabs: [] },
      {
        path: "/customer",
        tabs: [
          "order_history",
          "billing_address",
          "delivery_addresses",
          "collaborate",
          "contacts",
          "settings",
        ],
      },
      { path: "/flashings", tabs: [] },
      { path: "/customers", tabs: [] },
      { path: "/dashboard", tabs: [] },
      { path: "/workflow", tabs: [] },
      { path: "/planner", tabs: [] },
      { path: "/delivery_scheduling", tabs: [] },
      { path: "/timesheets", tabs: ["manager", "timeline"] },
      { path: "/timesheets/individual", tabs: [] },
      {
        path: "/products",
        tabs: ["product-details", "collaborate", "order-history"],
      },
      {
        path: "/product-kits",
        tabs: ["product-details", "collaborate", "order-history"],
      },
      { path: "/inventory/stocktake", tabs: [] },
      { path: "/materials", tabs: [] },
      { path: "/purchases", tabs: [] },
      {
        path: "/suppliers",
        tabs: [
          "purchase-history",
          "supplier-details",
          "supplied-products",
          "collaborate",
          "contacts",
          "settings",
        ],
      },
      { path: "/orders", tabs: [] },
      {
        path: "/settings",
        tabs: [
          "company",
          "billing",
          "integrations",
          "invoicing",
          "order_confirmations",
          "sales",
          "purchasing",
          "pdf",
          "quotes",
          "team",
          "time_tracking",
          "order_importer",
        ],
      },
      { path: "/dashboard-beta", tabs: [] },
      { path: "/prices", tabs: [] },
    ],
    startPage: "/dashboard",
    description: "Access and make changes to all elements of Factory.",
  },
  "power user": {
    id: 1,
    access: [
      { path: "/profile", tabs: [] },
      {
        path: "/customer",
        tabs: [
          "order_history",
          "billing_address",
          "delivery_addresses",
          "collaborate",
          "contacts",
          "settings",
        ],
      },
      { path: "/flashings", tabs: [] },
      { path: "/dashboard", tabs: [] },
      { path: "/customers", tabs: [] },
      { path: "/workflow", tabs: [] },
      { path: "/planner", tabs: [] },
      { path: "/delivery_scheduling", tabs: [] },
      { path: "/timesheets", tabs: [] },
      { path: "/timesheets/individual", tabs: [] },
      {
        path: "/products",
        tabs: ["product-details", "collaborate", "order-history"],
      },
      {
        path: "/product-kits",
        tabs: ["product-details", "collaborate", "order-history"],
      },
      { path: "/inventory/stocktake", tabs: [] },
      { path: "/materials", tabs: [] },
      { path: "/purchases", tabs: [] },
      {
        path: "/suppliers",
        tabs: [
          "purchase-history",
          "supplier-details",
          "supplied-products",
          "collaborate",
          "contacts",
          "settings",
        ],
      },
      { path: "/orders", tabs: [] },
      {
        path: "/settings",
        tabs: [
          "company",
          "integrations",
          "invoicing",
          "order_confirmations",
          "sales",
          "purchasing",
          "pdf",
          "quotes",
        ],
      },
      { path: "/dashboard-beta", tabs: [] },
      { path: "/prices", tabs: [] },
    ],
    startPage: "/dashboard",
    description: "Access and make changes to all elements of Factory, excluding user management.",
  },
  "standard user": {
    id: 2,
    access: [
      { path: "/profile", tabs: [] },
      {
        path: "/customer",
        tabs: [
          "order_history",
          "billing_address",
          "delivery_addresses",
          "collaborate",
          "contacts",
          "settings",
        ],
      },
      { path: "/flashings", tabs: [] },
      { path: "/dashboard", tabs: [] },
      { path: "/customers", tabs: [] },
      { path: "/workflow", tabs: [] },
      { path: "/planner", tabs: [] },
      { path: "/delivery_scheduling", tabs: [] },
      { path: "/timesheets", tabs: [] },
      { path: "/timesheets/individual", tabs: [] },
      {
        path: "/products",
        tabs: ["product-details", "collaborate", "order-history"],
      },
      {
        path: "/product-kits",
        tabs: ["product-details", "collaborate", "order-history"],
      },
      { path: "/inventory/stocktake", tabs: [] },
      { path: "/materials", tabs: [] },
      { path: "/purchases", tabs: [] },
      {
        path: "/suppliers",
        tabs: [
          "purchase-history",
          "supplier-details",
          "supplied-products",
          "collaborate",
          "contacts",
          "settings",
        ],
      },
      { path: "/orders", tabs: [] },
      { path: "/prices", tabs: [] },
      { path: "/dashboard-beta", tabs: [] },
    ],
    startPage: "/dashboard",
    description: "Create and manage sales orders, purchase orders and invoices.",
  },
  "standard user (No Invoicing)": {
    id: 3,
    access: [
      { path: "/profile", tabs: [] },
      {
        path: "/customer",
        tabs: [
          "order_history",
          "billing_address",
          "delivery_addresses",
          "collaborate",
          "contacts",
          "settings",
        ],
      },
      { path: "/flashings", tabs: [] },
      { path: "/customers", tabs: [] },
      { path: "/dashboard", tabs: [] },
      { path: "/workflow", tabs: [] },
      { path: "/planner", tabs: [] },
      { path: "/delivery_scheduling", tabs: [] },
      { path: "/timesheets/individual", tabs: [] },
      {
        path: "/products",
        tabs: ["product-details", "collaborate", "order-history"],
      },
      {
        path: "/product-kits",
        tabs: ["product-details", "collaborate", "order-history"],
      },
      { path: "/materials", tabs: [] },
      { path: "/purchases", tabs: [] },
      {
        path: "/suppliers",
        tabs: [
          "purchase-history",
          "supplier-details",
          "supplied-products",
          "collaborate",
          "contacts",
          "settings",
        ],
      },
      { path: "/orders", tabs: [] },
      { path: "/prices", tabs: [] },
      { path: "/dashboard-beta", tabs: [] },
    ],
    startPage: "/dashboard",
    description: "Identical to ‘Standard User’, excluding ability to generate or issue invoices.",
  },
  "workflow only": {
    id: 4,
    access: [
      { path: "/profile", tabs: [] },
      { path: "/workflow", tabs: [] },
      { path: "/timesheets", tabs: ["manager", "timeline"] },
      { path: "/timesheets/individual", tabs: [] },
    ],
    startPage: "/workflow",
    description: "Access workflow tool only.",
  },
  "deliveries only": {
    id: 5,
    access: [
      { path: "/profile", tabs: [] },
      { path: "/delivery_scheduling", tabs: [] },
      { path: "/timesheets", tabs: ["manager", "timeline"] },
      { path: "/timesheets/individual", tabs: [] },
    ],
    startPage: "/delivery_scheduling",
    description: "Access delivery scheduling tool only.",
  },
  productivity: {
    id: 6,
    access: [
      { path: "/profile", tabs: [] },
      { path: "/workflow", tabs: [] },
      { path: "/planner", tabs: [] },
      { path: "/delivery_scheduling", tabs: [] },
      { path: "/timesheets", tabs: ["manager", "timeline"] },
      { path: "/timesheets/individual", tabs: [] },
    ],
    startPage: "/workflow",
    description: "Access workflow and delivery scheduling tools.",
  },

  // BETA ROLES //
  "[BETA] administrator": {
    id: 0,
    access: [
      { path: "/profile", tabs: [] },
      {
        path: "/customer",
        tabs: [
          "order_history",
          "billing_address",
          "delivery_addresses",
          "collaborate",
          "contacts",
          "settings",
        ],
      },
      { path: "/flashings", tabs: [] },
      { path: "/customers", tabs: [] },
      { path: "/dashboard", tabs: [] },
      { path: "/workflow", tabs: [] },
      { path: "/planner", tabs: [] },
      { path: "/delivery_scheduling", tabs: [] },
      { path: "/timesheets", tabs: ["manager", "timeline"] },
      { path: "/timesheets/individual", tabs: [] },
      {
        path: "/products",
        tabs: ["product-details", "collaborate", "order-history"],
      },
      {
        path: "/product-kits",
        tabs: ["product-details", "collaborate", "order-history"],
      },
      { path: "/inventory/stocktake", tabs: [] },
      { path: "/materials", tabs: [] },
      { path: "/purchases", tabs: [] },
      {
        path: "/suppliers",
        tabs: [
          "purchase-history",
          "supplier-details",
          "supplied-products",
          "collaborate",
          "contacts",
          "settings",
        ],
      },
      { path: "/orders", tabs: [] },
      {
        path: "/settings",
        tabs: [
          "company",
          "integrations",
          "invoicing",
          "order_confirmations",
          "sales",
          "purchasing",
          "pdf",
          "quotes",
          "team",
          "time_tracking",
        ],
      },
      { path: "/prices", tabs: [] },
    ],
    startPage: "/dashboard",
    description: "Access and make changes to all elements of Factory.",
  },
  "[BETA] power user": {
    id: 1,
    access: [
      { path: "/profile", tabs: [] },
      {
        path: "/customer",
        tabs: [
          "order_history",
          "billing_address",
          "delivery_addresses",
          "collaborate",
          "contacts",
          "settings",
        ],
      },
      { path: "/flashings", tabs: [] },
      { path: "/customers", tabs: [] },
      { path: "/dashboard", tabs: [] },
      { path: "/workflow", tabs: [] },
      { path: "/delivery_scheduling", tabs: [] },
      { path: "/planner", tabs: [] },
      { path: "/timesheets", tabs: ["manager", "timeline"] },
      { path: "/timesheets/individual", tabs: [] },
      {
        path: "/products",
        tabs: ["product-details", "collaborate", "order-history"],
      },
      {
        path: "/product-kits",
        tabs: ["product-details", "collaborate", "order-history"],
      },
      { path: "/inventory/stocktake", tabs: [] },
      { path: "/materials", tabs: [] },
      { path: "/purchases", tabs: [] },
      {
        path: "/suppliers",
        tabs: [
          "purchase-history",
          "supplier-details",
          "supplied-products",
          "collaborate",
          "contacts",
          "settings",
        ],
      },
      { path: "/orders", tabs: [] },
      {
        path: "/settings",
        tabs: [
          "company",
          "integrations",
          "invoicing",
          "order_confirmations",
          "sales",
          "purchasing",
          "pdf",
          "quotes",
        ],
      },
      { path: "/prices", tabs: [] },
    ],
    startPage: "/dashboard",
    description: "Access and make changes to all elements of Factory, excluding user management.",
  },
  "[BETA] standard user": {
    id: 2,
    access: [
      { path: "/profile", tabs: [] },
      {
        path: "/customer",
        tabs: [
          "order_history",
          "billing_address",
          "delivery_addresses",
          "collaborate",
          "contacts",
          "settings",
        ],
      },
      { path: "/flashings", tabs: [] },
      { path: "/customers", tabs: [] },
      { path: "/dashboard", tabs: [] },
      { path: "/workflow", tabs: [] },
      { path: "/delivery_scheduling", tabs: [] },
      { path: "/planner", tabs: [] },
      { path: "/timesheets", tabs: ["manager", "timeline"] },
      { path: "/timesheets/individual", tabs: [] },
      {
        path: "/products",
        tabs: ["product-details", "collaborate", "order-history"],
      },
      {
        path: "/product-kits",
        tabs: ["product-details", "collaborate", "order-history"],
      },
      { path: "/inventory/stocktake", tabs: [] },
      { path: "/materials", tabs: [] },
      { path: "/purchases", tabs: [] },
      {
        path: "/suppliers",
        tabs: [
          "purchase-history",
          "supplier-details",
          "supplied-products",
          "collaborate",
          "contacts",
          "settings",
        ],
      },
      { path: "/orders", tabs: [] },
      { path: "/prices", tabs: [] },
    ],
    startPage: "/dashboard",
    description: "Create and manage sales orders, purchase orders and invoices.",
  },
  "[BETA] standard user (No Invoicing)": {
    id: 3,
    access: [
      { path: "/profile", tabs: [] },
      {
        path: "/customer",
        tabs: [
          "order_history",
          "billing_address",
          "delivery_addresses",
          "collaborate",
          "contacts",
          "settings",
        ],
      },
      { path: "/flashings", tabs: [] },
      { path: "/customers", tabs: [] },
      { path: "/dashboard", tabs: [] },
      { path: "/workflow", tabs: [] },
      { path: "/delivery_scheduling", tabs: [] },
      { path: "/planner", tabs: [] },
      {
        path: "/products",
        tabs: ["product-details", "collaborate", "order-history"],
      },
      {
        path: "/product-kits",
        tabs: ["product-details", "collaborate", "order-history"],
      },
      { path: "/inventory/stocktake", tabs: [] },
      { path: "/materials", tabs: [] },
      { path: "/purchases", tabs: [] },
      {
        path: "/suppliers",
        tabs: [
          "purchase-history",
          "supplier-details",
          "supplied-products",
          "collaborate",
          "contacts",
          "settings",
        ],
      },
      { path: "/orders", tabs: [] },
      { path: "/prices", tabs: [] },
    ],
    startPage: "/dashboard",
    description: "Identical to ‘Standard User’, excluding ability to generate or issue invoices.",
  },
  "[BETA] workflow only": {
    id: 4,
    access: [
      { path: "/profile", tabs: [] },
      { path: "/workflow", tabs: [] },
      { path: "/timesheets", tabs: ["manager", "timeline"] },
      { path: "/timesheets/individual", tabs: [] },
    ],
    startPage: "/workflow",
    description: "Access workflow tool only.",
  },
  "[BETA] deliveries only": {
    id: 5,
    access: [
      { path: "/profile", tabs: [] },
      { path: "/delivery_scheduling", tabs: [] },
      { path: "/timesheets", tabs: ["manager", "timeline"] },
      { path: "/timesheets/individual", tabs: [] },
    ],
    startPage: "/delivery_scheduling",
    description: "Access delivery scheduling tool only.",
  },
  "[BETA] productivity": {
    id: 6,
    access: [
      { path: "/profile", tabs: [] },
      { path: "/workflow", tabs: [] },
      { path: "/planner", tabs: [] },
      { path: "/delivery_scheduling", tabs: [] },
      { path: "/timesheets", tabs: ["manager", "timeline"] },
      { path: "/timesheets/individual", tabs: [] },
    ],
    startPage: "/workflow",
    description: "Access workflow and delivery scheduling tools.",
  },
};

export default ROLES;
