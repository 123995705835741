import * as deliveryScheduling from "./deliveryScheduling";
import * as calendar from "./calendar";
import * as orders from "./orders";
import * as searchFilter from "./searchFilter";
import * as sortFilter from "./sortFilter";
import * as labelsFilter from "./labelFilter";
import * as userFilter from "./userFilter";
import * as requiredDateFilter from "./requiredDateFilter";

export const reducer = () => ({
  [deliveryScheduling.MODULE]: deliveryScheduling.reducer,
  [calendar.MODULE]: calendar.reducer,
  [orders.MODULE]: orders.reducer,
  [searchFilter.MODULE]: searchFilter.reducer,
  [sortFilter.MODULE]: sortFilter.reducer,
  [labelsFilter.MODULE]: labelsFilter.reducer,
  [userFilter.MODULE]: userFilter.reducer,
  [requiredDateFilter.MODULE]: requiredDateFilter.reducer,
});

export * as deliverySchedulingActions from "./deliveryScheduling/deliveryScheduling.actions";
export * as deliverySchedulingSelectors from "./deliveryScheduling/deliveryScheduling.selectors";

export default {};
