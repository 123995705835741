import queryString from "query-string";

import { createApiV2 } from "./common";

export const getLatestCustomers =
  (params = {}) =>
  (token) => {
    const queryParams = queryString.stringify(params);
    return createApiV2("GET", `accounting/customers/?${queryParams}`)(token);
  };

export const postImportCustomers = (data) => (token) =>
  createApiV2("POST", "import_customers/")(token, data);

export const postImportAllCustomers = (data) => (token) =>
  createApiV2("POST", "import_all_customers/")(token, data);
