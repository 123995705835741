import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons";
import { faTrashAlt } from "@fortawesome/pro-duotone-svg-icons";

import { Button } from "@/ui-lib/FormFields";

import modalStyles from "./modal.styles";

class ModalNotify extends React.PureComponent {
  static propTypes = {
    onApply: PropTypes.func.isRequired,
    modalStyles: PropTypes.shape({}),

    header: PropTypes.string,
    confirmText: PropTypes.string,
    lastSymbol: PropTypes.string,
    headerIcon: PropTypes.oneOf([PropTypes.shape({}), PropTypes.node]),

    typeItem: PropTypes.string,
    warningText: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    warningList: PropTypes.arrayOf(PropTypes.string),

    color: PropTypes.string,
    applyColor: PropTypes.string,
    onClose: PropTypes.func,
    cancelText: PropTypes.string,
  };

  static defaultProps = {
    modalStyles: {},
    color: "danger",
    applyColor: "danger",
    headerIcon: faTrashAlt,

    header: "",
    confirmText: "",
    lastSymbol: "",

    typeItem: "",
    warningText: "",
    warningList: [],

    onClose: () => null,
    cancelText: "",
  };

  constructor(props) {
    super(props);

    this.state = {
      _showModal: false,
    };
  }

  onApply = () => {
    const { onApply } = this.props;
    onApply();
    this.toggleModal();
  };

  toggleModal = () => {
    const { onClose } = this.props;
    const { _showModal } = this.state;
    this.setState({ _showModal: !_showModal });

    if (_showModal && onClose) {
      onClose();
    }
  };

  renderBody = () => {
    const { warningList, color } = this.props;

    if (warningList) {
      return warningList.map((text) => (
        <div key={text} className="d-flex pt-2_5">
          <FontAwesomeIcon
            size="lg"
            className={`${color ? `text-${color}` : ""}`}
            icon={faExclamationCircle}
          />
          <span className="ml-2 font-weight-light">{text}</span>
        </div>
      ));
    }

    return null;
  };

  render() {
    const {
      typeItem,
      modalStyles: modalStylesOverride,
      header: customHeader,
      headerIcon,
      applyColor,
      lastSymbol,
      cancelText: customCancelText,
      color,
      warningText,
      confirmText,
    } = this.props;
    const { _showModal } = this.state;

    const header = customHeader || `Delete ${typeItem}`;
    const punctuationMark = lastSymbol || "";
    const confirm = confirmText || header;
    const cancelText = customCancelText || `Keep ${typeItem}`;

    return (
      <Modal
        style={{ ...modalStyles, ...modalStylesOverride }}
        isOpen={_showModal}
        toggle={this.toggleModal}
        ariaHideApp={false}
      >
        <div className="modal-header py-4_5 px-2 d-flex justify-content-center align-items-center border-0">
          <div className="d-flex justify-content-center align-items-center">
            {headerIcon && (
              <div className="modal-header__icon mr-3 d-flex justify-content-center align-items-center">
                <div
                  className={`modal-header__icon-color rounded-circle w-100 h-100 ${
                    color ? `bg-${color}` : ""
                  }`}
                />
                <div className="position-absolute">
                  <FontAwesomeIcon
                    size="lg"
                    className={`${color ? `text-${color}` : ""}`}
                    icon={headerIcon}
                  />
                </div>
              </div>
            )}
            <h5 className="modal-title font-weight-normal">{`${header} ${punctuationMark}`}</h5>
          </div>
        </div>

        <div className="px-4 py-0 d-flex flex-column">
          {typeof warningText === "string" ? (
            <span className="font-weight-light">{warningText}</span>
          ) : (
            warningText
          )}
          {this.renderBody()}
        </div>

        <div className="border-0 py-4_5 d-flex justify-content-center">
          <Button
            className="font-weight-semi-bold mr-3_5"
            size="sm"
            color={applyColor}
            onClick={this.onApply}
          >
            {confirm}
          </Button>
          <Button
            className="font-weight-semi-bold"
            size="sm"
            color="primary"
            outline
            onClick={this.toggleModal}
          >
            {cancelText}
          </Button>
        </div>
      </Modal>
    );
  }
}

export default ModalNotify;
