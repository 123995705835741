import { fromJS } from "immutable";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import { nanoid } from "nanoid";
import { withMutations } from "./helpers/reducerWrappers";
import ReduxModule from "./abstract/ReduxModule";

import { setPending, reorderItems, moveItems } from "./helpers/common";
import { genId } from "../../utils/common";

import * as alertActions from "../alerts/alerts.actions";
import alerts from "./alerts";
import * as cardsApi from "../../api/cards";

const ACTIONS = {
  GET_WORKFLOW_OPTIONS: "Get workflow options",
  GET_ALL_ORDERS_STATUSES: "Get all orders statuses",
  GET_COLUMNS_ORDERS: "Get columns orders",
  SEARCH_ORDERS: "Search orders",

  REORDER_COLUMNS: "Reorder columns",
  REORDER_ITEMS: "Reorder items",
  MOVE_ITEMS: "Move items",
  CHANGE_WORKFLOW_ORDER: "Change workflow order",
  UNDO_CHANGE_WORKFLOW_ORDER: "Undo hange workflow order",

  CREATE_COLUMN: "Create column",
  DELETE_COLUMN: "Delete column",
  CHANGE_COLUMN_NAME: "Change column name",
  CHANGE_STATUS_COLOUR: "Change status colour",
  CHANGE_NOTIFICATION: "Change notification",

  CHANGE_SETTINGS: "Change settings",
  FILTER_ITEMS: "Filter items",
  FILTER_COLUMNS: "Filter columns",
};

class CardsModule extends ReduxModule {
  getNamespace() {
    return "[Cards]";
  }

  getInitialState() {
    return {
      workflow: {
        settings: {
          enableEmailNotifications: false,
          enableSmsNotifications: false,
          notifyWhenDelivered: false,
          replyTo: null,
          deliveryColor: "#20b2aa",
          pickupColor: "#6a5acd",
          reservedNames: [],
        },
        columns: [], // [{ this.getInitialStateColumn() }]
        columnsColours: {}, // {columnsName: settings.colour}
        items: {}, // {columnsUID: [ {OrderModule.initialEmptyOrder()} ] }
      },
      pendings: {
        settings: false,
        columns: false,
        columnsColours: false,
        items: false,
      },
      isPending: false,
      errors: {},
    };
  }

  getInitialStateColumn() {
    return {
      name: "",
      isDeleted: false,
      uid: "",
      settings: {
        colour: "transparent",
        notifications: {
          email: false,
          sms: false,
        },
      },
    };
  }

  getWorkflowOptionsThunk = ({ token, fulfilled }) =>
    cardsApi.getWorkflowOptions(token).then((response) => {
      const { data } = response;
      fulfilled(fromJS(camelcaseKeys(data, { deep: true })));
      return data;
    });

  getAllOrdersStatuses = ({ token, fulfilled }) =>
    cardsApi.getAllOrdersStatuses(token).then((response) => {
      fulfilled(response);
      return response.data;
    });

  getColumnsOrders = ({ token, getState, fulfilled }, search) => {
    const columns = getState().getIn(["cards", "workflow", "columns"]);
    const promises = columns.map((column) =>
      cardsApi.searchColumnsOrders(token, {
        statusUid: column.get("uid"),
        search,
      })
    );

    return Promise.all(promises)
      .then((response) => response.map(({ data }) => data))
      .then((data) => {
        const items = {};
        columns.forEach((column, i) => {
          items[column.get("uid")] = camelcaseKeys(data[i], { deep: true });
        });

        return items;
      })
      .then((response) => {
        fulfilled(response);
        return response;
      });
  };

  searchOrders = ({ token, fulfilled }, search) =>
    cardsApi.searchOrders(token, { search }).then((response) => {
      fulfilled(response);
      return response.data;
    });

  reorderColumns = (
    { token, getState, fulfilled },
    { startIndex, endIndex }
  ) => {
    const columnUid = getState().getIn([
      "cards",
      "workflow",
      "columns",
      startIndex,
      "uid",
    ]);

    fulfilled({ startIndex, endIndex });
    return cardsApi.moveWorkflowColumn(token, {
      column_uid: columnUid,
      new_index: endIndex,
    });
  };

  reorderColumnsItems = (
    { token, getState, fulfilled },
    { droppableId, startIndex, endIndex }
  ) => {
    const orderUid = getState().getIn([
      "cards",
      "workflow",
      "items",
      droppableId,
      startIndex,
      "uid",
    ]);

    fulfilled({ droppableId, startIndex, endIndex });
    return cardsApi.moveWorkflowOrder(token, {
      order_uid: orderUid,
      new_index: endIndex,
    });
  };

  moveColumnsItemsThunk = ({ token, getState, fulfilled }, ids) => {
    const { droppableSourceId, droppableDestinationId, startIndex, endIndex } =
      ids;

    const orederUid = getState().getIn([
      "cards",
      "workflow",
      "items",
      droppableSourceId,
      startIndex,
      "uid",
    ]);
    fulfilled(ids);

    return cardsApi.moveWorkflowOrder(token, {
      order_uid: orederUid,
      new_index: endIndex,
      new_column_uid: droppableDestinationId,
    });
  };

  changeWorkflowOrder = ({ token, getState, dispatch, fulfilled }, values) => {
    const { order, columnUid, message, ...rest } = values;

    const orderUid = order.uid;

    const orderIndex = getState()
      .getIn(["cards", "workflow", "items", columnUid])
      .findIndex((item) => item.get("uid") === orderUid);

    fulfilled({
      orderIndex,
      columnUid,
      ...rest,
    });

    return cardsApi
      .changeWorkflowOrder(token, snakecaseKeys({ orderUid, ...rest }))
      .then(() => {
        if (message) {
          dispatch(
            alertActions.createAlertAction(
              nanoid(),
              message,
              true,
              "success",
              "Undo",
              async () => {
                dispatch(
                  this.actions.undoChangeWorkflowOrder({
                    columnUid,
                    orderIndex,
                    order,
                    ...rest,
                  })
                );
              }
            )
          );
        }
      });
  };

  undoChangeWorkflowOrder = ({ token, fulfilled }, values) => {
    const { orderIndex, order, columnUid, ...rest } = values;

    const prevValues = {};
    Object.keys(rest).forEach((key) => {
      prevValues[key] = order[key];
    });

    fulfilled({
      columnUid,
      orderIndex,
      order,
    });

    return cardsApi.changeWorkflowOrder(
      token,
      snakecaseKeys({ orderUid: order.uid, orderIndex, ...prevValues })
    );
  };

  createColumnThunk = ({ token, fulfilled }, name) => {
    const newColumn = this.getInitialStateColumn();
    newColumn.name = name;

    return cardsApi.updateStatusColumn(token, newColumn).then((response) => {
      fulfilled(response);
      return response.data;
    });
  };

  changeColumnNameThunk = ({ token, getState, fulfilled }, { index, name }) => {
    const uid = getState().getIn([
      "cards",
      "workflow",
      "columns",
      index,
      "uid",
    ]);
    fulfilled({ index, name });
    return cardsApi.updateStatusColumn(token, { uid, name });
  };

  changeColumnStatusColour = (
    { token, getState, fulfilled },
    { index, colour }
  ) => {
    const uid = getState().getIn([
      "cards",
      "workflow",
      "columns",
      index,
      "uid",
    ]);
    fulfilled({ index, colour });
    return cardsApi.updateStatusColumn(token, { uid, settings: { colour } });
  };

  changeColumnNotification = (
    { token, getState, fulfilled },
    { index, type, checked }
  ) => {
    const uid = getState().getIn([
      "cards",
      "workflow",
      "columns",
      index,
      "uid",
    ]);
    fulfilled({ index, type, checked });
    return cardsApi.updateStatusColumn(token, {
      uid,
      settings: { notifications: { [type]: checked } },
    });
  };

  deleteColumnThunk = ({ token, getState, fulfilled }, index) => {
    const uid = getState().getIn([
      "cards",
      "workflow",
      "columns",
      index,
      "uid",
    ]);
    fulfilled(index);
    return cardsApi.updateStatusColumn(token, { uid, is_deleted: true });
  };

  changeCommonSettings = ({ token, fulfilled }, settings) => {
    fulfilled(settings);
    return cardsApi.updateWorkflowOptions(token, {
      ...snakecaseKeys(settings),
    });
  };

  defineActions() {
    const getWorkflowOptions = this.thunkAction(
      ACTIONS.GET_WORKFLOW_OPTIONS,
      this.getWorkflowOptionsThunk
    );
    const getAllOrdersStatuses = this.thunkAction(
      ACTIONS.GET_ALL_ORDERS_STATUSES,
      this.getAllOrdersStatuses,
      true
    );
    const getColumnsOrders = this.thunkAction(
      ACTIONS.GET_COLUMNS_ORDERS,
      this.getColumnsOrders,
      true
    );
    const searchOrders = this.thunkAction(
      ACTIONS.SEARCH_ORDERS,
      this.searchOrders,
      true
    );

    const reorderColumns = this.thunkAction(
      ACTIONS.REORDER_COLUMNS,
      this.reorderColumns
    );
    const reorderColumnsItems = this.thunkAction(
      ACTIONS.REORDER_ITEMS,
      this.reorderColumnsItems
    );
    const moveColumnsItems = this.thunkAction(
      ACTIONS.MOVE_ITEMS,
      this.moveColumnsItemsThunk
    );
    const changeWorkflowOrder = this.thunkAction(
      ACTIONS.CHANGE_WORKFLOW_ORDER,
      this.changeWorkflowOrder
    );
    const undoChangeWorkflowOrder = this.thunkAction(
      ACTIONS.UNDO_CHANGE_WORKFLOW_ORDER,
      this.undoChangeWorkflowOrder
    );

    const createColumn = this.thunkAction(
      ACTIONS.CREATE_COLUMN,
      this.createColumnThunk,
      ({ isPending }, name) => ({ name, isPending })
    );
    const deleteColumn = this.thunkAction(
      ACTIONS.DELETE_COLUMN,
      this.deleteColumnThunk
    );
    const changeColumnName = this.thunkAction(
      ACTIONS.CHANGE_COLUMN_NAME,
      this.changeColumnNameThunk
    );
    const changeColumnStatusColour = this.thunkAction(
      ACTIONS.CHANGE_STATUS_COLOUR,
      this.changeColumnStatusColour
    );
    const changeColumnNotification = this.thunkAction(
      ACTIONS.CHANGE_NOTIFICATION,
      this.changeColumnNotification
    );

    const changeSettings = this.thunkAction(
      ACTIONS.CHANGE_SETTINGS,
      this.changeCommonSettings
    );
    const filterItems = this.createAction(ACTIONS.FILTER_ITEMS);
    const filterColumns = this.createAction(ACTIONS.FILTER_COLUMNS);

    return {
      getWorkflowOptions,
      getAllOrdersStatuses,
      getColumnsOrders,
      searchOrders,

      reorderColumns,
      reorderColumnsItems,
      moveColumnsItems,
      changeWorkflowOrder,
      undoChangeWorkflowOrder,

      createColumn,
      deleteColumn,
      changeColumnName,
      changeColumnStatusColour,
      changeColumnNotification,

      changeSettings,
      filterItems,
      filterColumns,
    };
  }

  moveColumnsItems = (_state, { payload }) => {
    const { droppableSourceId, droppableDestinationId, startIndex, endIndex } =
      payload;

    const source = _state.getIn(["workflow", "items", droppableSourceId]);
    const destination = _state.getIn([
      "workflow",
      "items",
      droppableDestinationId,
    ]);

    let result = moveItems(
      source,
      destination,
      droppableSourceId,
      droppableDestinationId,
      startIndex,
      endIndex
    );
    let newDestination = result.get(droppableDestinationId);

    const columnIndex = _state
      .getIn(["workflow", "columns"])
      .findIndex((column) => column.get("uid") === droppableDestinationId);
    const status = _state.getIn(["workflow", "columns", columnIndex]);
    newDestination = newDestination.update((list) =>
      list.map((item) =>
        item.set(
          "status",
          fromJS({
            name: status.get("name"),
            colour: status.getIn(["settings", "colour"]),
          })
        )
      )
    );
    result = result.set(droppableDestinationId, newDestination);

    return _state.mergeIn(["workflow", "items"], result);
  };

  changeColumnName = (_state, { index, name }) => {
    _state.setIn(["workflow", "columns", index, "name"], name);

    const columnUID = _state.getIn(["workflow", "columns", index, "uid"]);
    _state.updateIn(["workflow", "items", columnUID], (list) =>
      list.map((item) => item.set("status", name))
    );
  };

  createColumn = (_state, { data }) => {
    const { name, uid } = data;

    const columnIndex = _state
      .getIn(["workflow", "columns"])
      .findIndex((column) => column.get("name") === name);

    const columnData = fromJS(camelcaseKeys(data));
    _state.setIn(["workflow", "columns", columnIndex], columnData);
    _state.setIn(["workflow", "items", uid], fromJS([]));
  };

  deleteColumn = (_state, index) => {
    const uid = _state.getIn(["workflow", "columns", index, "uid"]);
    const items = _state.getIn(["workflow", "items", uid]);
    _state.deleteIn(["workflow", "columns", index]);
    _state.deleteIn(["workflow", "items", uid]);

    const firstUid = _state.getIn(["workflow", "columns", 0, "uid"]);
    _state.updateIn(["workflow", "items", firstUid], (list) =>
      list.concat(items)
    );
  };

  filterCardsItems = (_state, value) => {
    const date =
      value === "Created date" || value === "Most recent"
        ? "createdTimestamp"
        : "requiredTimestamp";
    const name = value === "Customer name" && "customerName";

    if (value === "Customer name") {
      _state.updateIn(["workflow", "items"], (list) =>
        list.map((item) =>
          item.sort((a, b) =>
            a.get(name).toLowerCase() < b.get(name).toLowerCase() ? -1 : 1
          )
        )
      );
    } else if (value === "Created date" || value === "Required date") {
      _state.updateIn(["workflow", "items"], (list) =>
        list.map((item) =>
          item.sort((a, b) => new Date(a.get(date)) - new Date(b.get(date)))
        )
      );
    } else if (value === "Most recent") {
      _state.updateIn(["workflow", "items"], (list) =>
        list.map((item) =>
          item.sort((a, b) => new Date(b.get(date)) - new Date(a.get(date)))
        )
      );
    } else {
      _state.updateIn(["workflow", "items"], (list) =>
        list.map((item) =>
          item.sort((a, b) => a.get("purchaseOrder") - b.get("purchaseOrder"))
        )
      );
    }
  };

  filterCardsColumns = (_state, { value, uid }) => {
    const date =
      value === "Created date" || value === "Most recent"
        ? "createdTimestamp"
        : "requiredTimestamp";
    const name = value === "Customer name" && "customerName";

    if (value === "Customer name") {
      _state.updateIn(["workflow", "items", uid], (list) =>
        list.sort((a, b) =>
          a.get(name).toLowerCase() < b.get(name).toLowerCase() ? -1 : 1
        )
      );
    } else if (value === "Created date" || value === "Required date") {
      _state.updateIn(["workflow", "items", uid], (list) =>
        list.sort((a, b) => new Date(a.get(date)) - new Date(b.get(date)))
      );
    } else if (value === "Most recent") {
      _state.updateIn(["workflow", "items", uid], (list) =>
        list.sort((a, b) => new Date(b.get(date)) - new Date(a.get(date)))
      );
    } else {
      _state.updateIn(["workflow", "items", uid], (list) =>
        list.sort((a, b) => a.get("purchaseOrder") - b.get("purchaseOrder"))
      );
    }
  };

  defineReducers() {
    return {
      [`${ACTIONS.GET_WORKFLOW_OPTIONS} fulfilled`]: this.setInReducer([
        "workflow",
        "settings",
      ]),

      [`${ACTIONS.GET_ALL_ORDERS_STATUSES} fulfilled`]: (
        state,
        { payload: { data } }
      ) => state.setIn(["workflow", "columns"], fromJS(camelcaseKeys(data))),

      [`${ACTIONS.GET_ALL_ORDERS_STATUSES} pending`]: setPending("columns"),

      [`${ACTIONS.GET_COLUMNS_ORDERS} fulfilled`]: (state, { payload: data }) =>
        state.setIn(["workflow", "items"], fromJS(data)),

      [`${ACTIONS.GET_COLUMNS_ORDERS} pending`]: setPending("items"),

      [`${ACTIONS.SEARCH_ORDERS} fulfilled`]: (
        _state,
        { payload: { data } }
      ) => {
        const items = {};

        data.forEach((item) => {
          const { status, orders } = item;
          items[status] = camelcaseKeys(orders, { deep: true });
        });

        return _state.setIn(["workflow", "items"], fromJS(items));
      },

      [`${ACTIONS.SEARCH_ORDERS} pending`]: setPending("items"),

      [`${ACTIONS.REORDER_COLUMNS} fulfilled`]: (
        state,
        { payload: { startIndex, endIndex } }
      ) =>
        state.updateIn(["workflow", "columns"], (list) =>
          reorderItems(list, startIndex, endIndex)
        ),

      [`${ACTIONS.REORDER_ITEMS} fulfilled`]: (
        state,
        { payload: { droppableId, startIndex, endIndex } }
      ) =>
        state.updateIn(["workflow", "items", droppableId], (list) =>
          reorderItems(list, startIndex, endIndex)
        ),

      [`${ACTIONS.MOVE_ITEMS} fulfilled`]: this.moveColumnsItems,

      [`${ACTIONS.CHANGE_WORKFLOW_ORDER} fulfilled`]: (
        state,
        { payload: { orderIndex, columnUid } }
      ) => state.deleteIn(["workflow", "items", columnUid, orderIndex]),

      [`${ACTIONS.UNDO_CHANGE_WORKFLOW_ORDER} fulfilled`]: (
        state,
        { payload: { columnUid, orderIndex, order } }
      ) =>
        state.updateIn(["workflow", "items", columnUid], (list) =>
          list.splice(orderIndex, 0, fromJS(order))
        ),

      [`${ACTIONS.CREATE_COLUMN} fulfilled`]: withMutations(this.createColumn),

      [`${ACTIONS.CREATE_COLUMN} pending`]: (
        state,
        { payload: { isPending, name } }
      ) => {
        const columnIndex = state
          .getIn(["workflow", "columns"])
          .findIndex((column) => column.get("name") === name);

        if (columnIndex === -1) {
          return state.updateIn(["workflow", "columns"], (list) =>
            list.push(
              fromJS({
                name,
                uid: genId(),
                isPending,
              })
            )
          );
        }

        return state.setIn(
          ["workflow", "columns", columnIndex, "isPending"],
          isPending
        );
      },

      [`${ACTIONS.CHANGE_COLUMN_NAME} fulfilled`]: withMutations(
        this.changeColumnName
      ),

      [`${ACTIONS.CHANGE_STATUS_COLOUR} fulfilled`]: (
        state,
        { payload: { index, colour } }
      ) =>
        state.setIn(
          ["workflow", "columns", index, "settings", "colour"],
          colour
        ),

      [`${ACTIONS.CHANGE_NOTIFICATION} fulfilled`]: (
        state,
        { payload: { index, type, checked } }
      ) =>
        state.setIn(
          ["workflow", "columns", index, "settings", "notifications", type],
          checked
        ),

      [`${ACTIONS.DELETE_COLUMN} fulfilled`]: withMutations(this.deleteColumn),

      [ACTIONS.FILTER_ITEMS]: withMutations(this.filterCardsItems),

      [ACTIONS.FILTER_COLUMNS]: withMutations(this.filterCardsColumns),

      [`${ACTIONS.CHANGE_SETTINGS} fulfilled`]: (
        state,
        { payload: settings }
      ) => state.mergeIn(["workflow", "settings"], settings),
    };
  }
}

const instance = new CardsModule();
instance.init();

export default instance;
