import {
  SET_IS_FETCHING_PRICE_LEVELS,
  SET_IS_FETCHING_CUSTOMERS,
  SET_IS_DELETE_PRICE_LEVEL_MODAL_OPEN,
  SET_RECENTLY_DELETED_PRICE_LEVEL_UID,
  SET_PRICE_LEVELS,
  SET_CUSTOMERS,
  SET_SEARCH,
  RESET,
} from "./priceLevels.actionTypes";

const initialState = {
  isFetchingPriceLevels: false,
  isFetchingCustomers: false,
  isDeletePriceLevelModalOpen: false,
  toBeDeletedPriceLevelUid: null,
  recentlyDeletedPriceLevelUid: null,
  priceLevels: [],
  customers: [],
  search: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_FETCHING_PRICE_LEVELS: {
      return {
        ...state,
        isFetchingPriceLevels: action.payload,
      };
    }

    case SET_IS_FETCHING_CUSTOMERS: {
      return {
        ...state,
        isFetchingCustomers: action.payload,
      };
    }

    case SET_IS_DELETE_PRICE_LEVEL_MODAL_OPEN: {
      return {
        ...state,
        isDeletePriceLevelModalOpen: action.payload.status,
        toBeDeletedPriceLevelUid: action.payload.priceLevelUid,
      };
    }

    case SET_RECENTLY_DELETED_PRICE_LEVEL_UID: {
      return {
        ...state,
        recentlyDeletedPriceLevelUid: action.payload,
      };
    }

    case SET_PRICE_LEVELS: {
      return {
        ...state,
        priceLevels: action.payload,
      };
    }

    case SET_CUSTOMERS: {
      return {
        ...state,
        customers: action.payload,
      };
    }

    case SET_SEARCH: {
      return {
        ...state,
        search: action.payload,
      };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
