import { fromJS } from "immutable";
import ReduxModule from "./abstract/ReduxModule";

import { genId } from "../../utils/common";

const ACTIONS = {
  ADD_ALERT: "Add alert",
  REMOVE_ALERT: "Remove alert",
  CLEAR_ALERTS: "Clear alerts",
};

class Alerts extends ReduxModule {
  getNamespace() {
    return "[Alerts]";
  }

  getInitialState() {
    return [];
  }

  getInitialStateAlert() {
    return {
      id: "",
      message: "",
      type: "",
      closeDelay: null,
      action: null, // {label: '', callback: func}
      growDown: false,
    };
  }

  defineActions() {
    const addAlert = this.createAction(ACTIONS.ADD_ALERT, (alert) => alert);
    const removeAlert = this.createAction(ACTIONS.REMOVE_ALERT, (id) => id);
    const clearAll = this.resetToInitialState(ACTIONS.CLEAR_ALERTS);

    return {
      addAlert,
      removeAlert,
      clearAll,
    };
  }

  defineReducers() {
    return {
      [ACTIONS.ADD_ALERT]: (state, { payload: alert }) => {
        const alertObj = { ...alert };
        if (!alert.id) {
          alertObj.id = genId();
        }
        if (alert.growDown) {
          return state.concat(fromJS([alertObj]));
        }

        return fromJS([alertObj]).concat(state);
      },

      [ACTIONS.REMOVE_ALERT]: (state, { payload: id }) =>
        state.filter((alert) => alert.get("id") !== id),
    };
  }
}

const alerts = new Alerts();
alerts.init();
export default alerts;
