import { MODULE } from "./forgotPassword.actionTypes";

export const isSaving = (state) => state.getIn([MODULE, "isSaving"]);

export const isSuccess = (state) => state.getIn([MODULE, "isSuccess"]);

export const isValidatingToken = (state) => state.getIn([MODULE, "isValidatingToken"]);

export const isTokenValid = (state) => state.getIn([MODULE, "isTokenValid"]);

export const getEmailFromToken = (state) => state.getIn([MODULE, "emailFromToken"]);
