import { MODULE } from "./dashboard.actionTypes";

export const isEditting = (state) => state.getIn([MODULE, "isEditting"]);
export const widgetsStatus = (state) => state.getIn([MODULE, "widgetsStatus"]);
export const widgets = (state) => state.getIn([MODULE, "widgets"]);
export const chartsStatus = (state) => state.getIn([MODULE, "chartsStatus"]);
export const charts = (state) => state.getIn([MODULE, "charts"]);
export const addUserWidgetStatus = (state) => state.getIn([MODULE, "addUserWidgetStatus"]);
export const userWidgets = (state) => state.getIn([MODULE, "userWidgets"]);

export const userWidgetsStatus = (state) => state.getIn([MODULE, "userWidgetsStatus"]);

export const updateUserWidgetStatus = (state) => state.getIn([MODULE, "updateUserWidgetStatus"]);
export const updateBulkUserWidgetStatus = (state) =>
  state.getIn([MODULE, "updateBulkUserWidgetStatus"]);
export const deleteUserWidgetStatus = (state) => state.getIn([MODULE, "deleteUserWidgetStatus"]);

export const salesOrders = (state) => state.getIn([MODULE, "salesOrders"]);
export const purchaseOrders = (state) => state.getIn([MODULE, "purchaseOrders"]);
export const openQuotes = (state) => state.getIn([MODULE, "openQuotes"]);
export const ordersByRequiredDate = (state) => state.getIn([MODULE, "ordersByRequiredDate"]);
export const ordersByStatus = (state) => state.getIn([MODULE, "ordersByStatus"]);
export const ordersCreatedByMe = (state) => state.getIn([MODULE, "ordersCreatedByMe"]);
export const topCustomers = (state) => state.getIn([MODULE, "topCustomers"]);
export const deliveriesScheduled = (state) => state.getIn([MODULE, "deliveriesScheduled"]);
export const ordersByTeamMember = (state) => state.getIn([MODULE, "ordersByTeamMember"]);
export const allOrdersWorkedOn = (state) => state.getIn([MODULE, "allOrdersWorkedOn"]);
export const ordersActivelyWorkedOn = (state) => state.getIn([MODULE, "ordersActivelyWorkedOn"]);
export const timeWorkedPerTeamMember = (state) => state.getIn([MODULE, "timeWorkedPerTeamMember"]);

export const chartsRequestParams = (state) => state.getIn([MODULE, "chartsRequestParams"]);
export const statuses = (state) => state.getIn([MODULE, "statuses"]);
export const users = (state) => state.getIn([MODULE, "users"]);

export const getLoadingStatus = (state) => state.getIn([MODULE, "loadingStatus"]);
export const getExportToFileLoadingStatus = (state) =>
  state.getIn([MODULE, "exportToFileLoadingStatus"]);
