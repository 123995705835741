import React from "react";

import Alerts from "@/components/Alerts/Alerts";

const NonAuthLayout = ({ children }) => (
  <>
    {children}
    <Alerts />
  </>
);

export default NonAuthLayout;
