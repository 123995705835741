import { handleError } from "../../utils/error";
import * as customersApi from "../../../apiv2/customers";
import * as routerSelectors from "../../router/router.selectors";

import * as selectors from "./userFilter.selectors";
import {
  SET_IS_FETCHING_USERS,
  SET_USERS,
  SET_SELECTED_USERS,
  RESET,
} from "./userFilter.actionTypes";

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setUsers = (data) => (dispatch) => {
  dispatch({
    type: SET_USERS,
    payload: data,
  });
};

export const setSelectedUsers = (data) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_USERS,
    payload: data,
  });
};

export const setIsFetchingUsers = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_USERS,
    payload: status,
  });
};

export const fetchUsers = () => async (dispatch) => {
  try {
    dispatch(setIsFetchingUsers(true));
    const response = await customersApi.getUsers({ with_inactive: true, include_invites: false })();
    dispatch(setUsers(response.data));
    dispatch(setSelectedUsers([...response.data.map((r) => r.id), "no_assignee"]));
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetchingUsers(false));
  }
};

export const setUserFilter = (id) => (dispatch, getState) => {
  const state = getState();

  const selectedUsers = selectors.getSelectedUsers(state);
  const allUsers = selectors.getUsers(state);

  if (id === "" && selectedUsers.length === allUsers.length + 1) {
    return dispatch(setSelectedUsers([]));
  }

  if (id === "" && (selectedUsers.length === 0 || selectedUsers.length < allUsers.length + 1)) {
    return dispatch(setSelectedUsers([...allUsers.map((r) => r.id), "no_assignee"]));
  }

  if (id === "" && selectedUsers.length === 0) {
    return dispatch(setSelectedUsers([...allUsers.map((r) => r.id), "no_assignee"]));
  }

  if (selectedUsers.includes(id)) {
    return dispatch(setSelectedUsers(selectedUsers.filter((userId) => userId !== id)));
  }

  return dispatch(setSelectedUsers([...selectedUsers, id]));
};

export const selectAllUsers = () => (dispatch, getState) => {
  const state = getState();
  const users = selectors.getUsers(state);

  dispatch(setSelectedUsers([...users.map((r) => r.id), "no_assignee"]));
};

export const selectAllUsersFromQueryParams = () => (dispatch, getState) => {
  const state = getState();
  const queryParams = routerSelectors.getQuery(state);

  if (queryParams.assignees) {
    dispatch(
      setSelectedUsers(
        Array.isArray(queryParams.assignees) ? queryParams.assignees : [queryParams.assignees]
      )
    );
  } else {
    dispatch(setSelectedUsers([]));
  }
};
