import queryString from "query-string";

import { createApiV2 } from "./common";

export const getLatestSuppliers =
  (params = {}) =>
  (token) => {
    const queryParams = queryString.stringify(params);
    return createApiV2("GET", `accounting/suppliers/?${queryParams}`)(token);
  };

export const postImportSuppliers = (data) => (token) =>
  createApiV2("POST", "import_suppliers/")(token, data);

export const postImportAllSuppliers = (data) => (token) =>
  createApiV2("POST", "import_all_suppliers/")(token, data);

export default {};
