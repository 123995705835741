import { SET_SELECTED_SORTING_OPTION, RESET } from "./sortFilter.actionTypes";

const initialState = {
  selectedSortingOption: "Most recent",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_SORTING_OPTION: {
      return { ...state, selectedSortingOption: action.payload };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
