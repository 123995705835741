import { MODULE } from "./teams.actionTypes";

export const isSaving = (state) => state.getIn([MODULE, "isSaving"]);

export const isFetching = (state) => state.getIn([MODULE, "isFetching"]);

export const getLists = (state) => state.getIn([MODULE, "lists"]);

export const getActiveTeamMember = (state) => state.getIn([MODULE, "activeTeamMember"]);

export const isFetchingInviteLink = (state) => state.getIn([MODULE, "isFetchingInviteLink"]);

export const getInviteLink = (state) => state.getIn([MODULE, "inviteLink"]);

export const isSendingInvite = (state) => state.getIn([MODULE, "isSendingInvite"]);
