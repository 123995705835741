import { STATUS } from "@/utils/constants";

import {
  SET_IS_SAVING_STATUS,
  SET_IS_FETCHING_TEAM_MEMBERS_STATUS,
  SET_TEAM_MEMBERS,
  RESET,
} from "./pinConfirmation.actionTypes";

const initialState = {
  isSavingStatus: STATUS.IDLE,
  isFetchingTeamMembersStatus: STATUS.IDLE,
  teams: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_SAVING_STATUS: {
      return { ...state, isSavingStatus: action.payload };
    }

    case SET_IS_FETCHING_TEAM_MEMBERS_STATUS: {
      return { ...state, isFetchingTeamMembersStatus: action.payload };
    }

    case SET_TEAM_MEMBERS: {
      return { ...state, teams: action.payload };
    }

    case RESET: {
      return initialState;
    }

    default:
      return state;
  }
};
