import qs from "query-string";
import Decimal from "decimal.js-light";
import snakecaseKeys from "snakecase-keys";
import { nanoid } from "nanoid";
import {
  PRODUCT_TYPE_ADDITIONAL_PRODUCT,
  PRICING_STRATEGY_LINEAL_METRES,
  STATUS,
  PRICING_STRATEGY_SQUARE_METRES,
} from "../../utils/constants";
import { ROOT_URL } from "../../env";
import { companySelector } from "../customers/customers.selectors";
import {
  SEARCH_PURCHASE_ORDERS,
  GET_PURCHASE_ORDER,
  BATCH_EDIT_ACTIVE_PURCHASE_ORDER,
  EDIT_ACTIVE_PURCHASE_ORDER,
  EDIT_ACTIVE_PURCHASE_ORDER_SILENTLY,
  UPDATE_PURCHASE_ORDER,
  RESET_ACTIVE_PURCHASE_ORDER,
  LOAD_EMPTY_PURCHASE_ORDER,
  CREATE_PURCHASE_ORDER,
  DRAFT_PURCHASE_ORDER,
  DELETE_PURCHASE_ORDER,
  BULK_DELETE_PURCHASE_ORDERS,
  EDIT_PURCHASE_ORDER_FILTERS,
  UNDO_PURCHASE_ORDER_ARCHIVE,
  UNDO_PURCHASE_ORDER_BULK_ARCHIVE,
  GET_EXTERNAL_PURCHASE_ORDER,
  GET_LINKED_ORDERS_FROM_PURCHASE_ORDERS,
  RESET_PURCHASE_ORDERS,
  EDIT_DRAWING_SVGS,
  UPDATE_PRODUCTS_INDEX,
  UPDATE_PURCHASE_ORDER_SILENTLY,

  // ATTACHMENTS
  SET_IS_FETCHING,
  SET_ORDER_ATTACHMENTS,
  SET_SELECTED_ATTACHMENTS,
  REMOVE_SELECTED_ATTACHMENT,
  UPDATE_SELECTED_ATTACHMENTS,
  PRINT_PURCHASE_ORDER_PDF_STATUS,
  EMAIL_PURCHASE_ORDER,
  // Pagination
  SET_CURRENT_PAGE,
  SET_TOTAL_COUNT,
  SET_PAGE_OFFSET,
  SET_PREVIOUS_PAGE_OFFSET,
  SET_NEXT_PAGE_OFFSET,
  SET_PAGE_LIMIT,
  SET_SORT,
  SET_IS_DUPLICATING,
  SET_IS_DUPLICATE_CONFIRMATION_ALERT_OPEN,
  SET_IS_CONVERTING_BILL_OR_PO,
  SET_IS_CONVERTING_BILL_OR_PO_CONFIRMATION_ALERT_OPEN,
  SET_DUPLICATE_ORDER_UID,
  EMAIL_PURCHASE_ORDER_STATUS,
  SET_IS_CHANGING_SUPPLIER,
  SET_IS_CHANGING_SUPPLIER_MODAL_OPEN,
  SET_IS_ADD_PRODUCT_MODAL_TYPE,
} from "./purchaseOrders.actionTypes";
import * as selectors from "./purchaseOrders.selectors";
import {
  searchPurchaseOrders,
  getPurchaseOrder,
  updatePurchaseOrder,
  createPurchaseOrder,
  deletePurchaseOrder,
  bulkDeletePurchaseOrders,
  bulkRestorePurchaseOrders,
  getExternalPurchaseOrder,
  getLinkedOrdersFromPurchaseOrder,
  updatedCompletedItems,
  postDuplicateOrder,
  patchUpdateProductsIndex,
  postChangeSupplier,
  // ATTACHMENTS
  getPurchaseOrderAttachments,
  postPrintPurchaseOrderPdf,
  emailPurchaseOrder,
} from "../../apiv2/purchaseOrders";
import * as priceLevelsApi from "../../apiv2/priceLevels";
import * as productsApi from "../../apiv2/products";
import { saveMessageWithFiles, getCollaborationNotesFile } from "../../api/purchase";
import dispatchApi from "../utils/dispatchApi";
import { INTENT } from "../../utils/constants";
import { handleError } from "../utils/error";
import * as alertActions from "../alerts/alerts.actions";

Decimal.config({ rounding: Decimal.ROUND_HALF_UP });

export const searchPurchaseOrdersAction =
  ({ search = "", startDate = "", endDate = "", statuses = [], uid = "" } = {}) =>
  async (dispatch, getState) => {
    const state = getState();

    try {
      const limit = selectors.getPagination(state).limit;
      const offset = selectors.getPagination(state).offset;
      const sort = selectors.getSort(state);
      const params = {
        search: search || undefined,
        start_date: startDate || undefined,
        end_date: endDate || undefined,
        statuses,
        limit,
        offset,
        sort_by: sort !== "" ? sort : undefined,
        uid: uid || undefined,
      };

      const response = await dispatchApi(
        dispatch,
        SEARCH_PURCHASE_ORDERS,
        () =>
          searchPurchaseOrders({
            params,
          }),
        INTENT.PERSISTENT
      );

      dispatch(setTotalCount(response.count));
      dispatch(setCurrentPage(offset / limit));
      if (response.next) {
        const parsedNextPage = qs.parse(response.next);
        dispatch(setNextPageOffset(parsedNextPage?.offset ?? 0));
      }

      if (response.previous) {
        const parsedPreviousPage = qs.parse(response.previous);
        dispatch(setPreviousPageOffset(parsedPreviousPage?.offset ?? 0));
      }
    } catch (e) {
      handleError(error, dispatch);
    }
  };

export const getPurchaseOrderAction = (purchaseOrderUid) => {
  return (dispatch) => {
    dispatchApi(
      dispatch,
      GET_PURCHASE_ORDER,
      () => getPurchaseOrder(purchaseOrderUid),
      INTENT.PERSISTENT
    );
  };
};

export const loadEmptyPurchaseOrderAction = (supplierUid, company, supplier, user) => ({
  type: LOAD_EMPTY_PURCHASE_ORDER,
  payload: { supplierUid, company, supplier, user },
});

export const batchEditActivePurchaseOrderAction = (changes) => ({
  type: BATCH_EDIT_ACTIVE_PURCHASE_ORDER,
  payload: { changes },
});

export const editActivePurchaseOrderAction = (path, value) => ({
  type: EDIT_ACTIVE_PURCHASE_ORDER,
  payload: { path, value },
});

export const editActivePurchaseOrderSilentlyAction = (path, value) => ({
  type: EDIT_ACTIVE_PURCHASE_ORDER_SILENTLY,
  payload: { path, value },
});

export const markActivePurchaseItemAsReceived = (path, value) => ({
  type: MARK_ITEM_AS_RECEIVED,
  payload: { path, value },
});

export const resetActivePurchaseOrderAction = () => ({
  type: RESET_ACTIVE_PURCHASE_ORDER,
  payload: {},
});

export const updatePurchaseOrderAction = (purchaseOrderUid, data) => {
  return async (dispatch) => {
    return await dispatchApi(
      dispatch,
      UPDATE_PURCHASE_ORDER,
      () => updatePurchaseOrder(purchaseOrderUid, data),
      INTENT.PERSISTENT
    );
  };
};

export const updatePurchaseOrderSilently = (purchaseOrderUid) => {
  return async (dispatch, getState) => {
    const state = getState();
    const activePurchaseOrder = selectors.activePurchaseOrderSelector(state);
    const payload = {
      ...activePurchaseOrder,
      editStatus: activePurchaseOrder.editStatus.id,
      requiredDate: activePurchaseOrder.requiredDate ? activePurchaseOrder.requiredDate : null,
      deletedItems: activePurchaseOrder.uid ? activePurchaseOrder.deletedItems : [],
      deletedFlashingItems: activePurchaseOrder.uid ? activePurchaseOrder.deletedFlashingItems : [],
    };
    return await dispatchApi(
      dispatch,
      UPDATE_PURCHASE_ORDER_SILENTLY,
      () => updatePurchaseOrder(purchaseOrderUid, payload),
      INTENT.PERSISTENT
    );
  };
};

export const updateProductsIndex = (purchaseOrderUid, data) => {
  return async (dispatch) => {
    return await dispatchApi(
      dispatch,
      UPDATE_PRODUCTS_INDEX,
      () => patchUpdateProductsIndex(purchaseOrderUid, data),
      INTENT.PERSISTENT
    );
  };
};

const calculateTotalLength = (lengths, minimumLengthSetting) => {
  const minLength =
    minimumLengthSetting && minimumLengthSetting.isSelected
      ? parseFloat(minimumLengthSetting.value)
      : 0;

  return lengths
    .reduce((acc, { lengthInMm, quantity }) => {
      const length =
        minLength > 0 ? Math.max(parseFloat(lengthInMm), minLength) : parseFloat(lengthInMm);
      return acc.plus(Decimal(quantity).times(length));
    }, new Decimal(0))
    .dividedBy(1000)
    .toDecimalPlaces(3);
};

const calculateTotalSquareMeters = (squareMetres) => {
  return squareMetres
    .reduce((acc, { length, width }) => {
      return acc.plus(Decimal(length).times(width));
    }, new Decimal(0))
    .toDecimalPlaces(4);
};

const getQuantity = (item, company) => {
  const minimumLengthSetting = company.settings.productSettings.allProducts.find(
    (setting) => setting.key === "AP_MIN_LENGTH_MM"
  );

  if (item.pricingStrategy === PRICING_STRATEGY_LINEAL_METRES) {
    const totalLength = calculateTotalLength(item.lengths, minimumLengthSetting);
    return totalLength;
  } else if (item.pricingStrategy === PRICING_STRATEGY_SQUARE_METRES) {
    const totalSqm = calculateTotalSquareMeters(item.squareMetres);
    return totalSqm;
  } else {
    return new Decimal(item.quantity);
  }
};

export const recalculateProductLineItemPrices =
  ({ rowIndex, key }) =>
  (dispatch, getState) => {
    const state = getState();
    const company = companySelector(state);
    const activePurchaseOrder = selectors.activePurchaseOrderSelector(state);
    const item = activePurchaseOrder.items.find((_, index) => index === rowIndex);

    if (!item) return;

    const discountRateInPercent = item.discountRateInPercent;
    const discount =
      parseFloat(discountRateInPercent) > 0 && parseFloat(discountRateInPercent) <= 100
        ? discountRateInPercent
        : 0;

    const quantity = getQuantity(item, company);

    if (key === "total") {
      const discountRate = Decimal(100).minus(discount).toDecimalPlaces(2);
      const itemTotalWithDiscount = Decimal(item.total)
        .dividedBy(discountRate)
        .times(100)
        .toDecimalPlaces(2);
      const newUnitPrice = itemTotalWithDiscount.dividedBy(quantity).toDecimalPlaces(4);

      dispatch(
        editActivePurchaseOrderAction(
          ["items", rowIndex, "productRowPrice"],
          newUnitPrice.toFixed(2)
        )
      );
    } else if (key !== "total") {
      const discountRate = Decimal(discount).dividedBy(100).toDecimalPlaces(2);
      const itemUnitPrice =
        item.priceOverrides && item.priceOverrides.itemPrice
          ? Decimal(parseFloat(item.priceOverrides.itemPrice)).toDecimalPlaces(4)
          : Decimal(parseFloat(item.productRowPrice)).toDecimalPlaces(4);

      const newTotal = itemUnitPrice.times(quantity).toDecimalPlaces(2);
      const newTotalWithDiscount = newTotal.times(1 - discountRate).toDecimalPlaces(2);

      // Store value
      if (item.priceOverrides?.itemPrice) {
        dispatch(
          editActivePurchaseOrderAction(
            ["items", rowIndex, "priceOverrides", "itemPrice"],
            itemUnitPrice.toFixed(2)
          )
        );
      }

      if (item.priceOverrides?.total) {
        dispatch(
          editActivePurchaseOrderAction(
            ["items", rowIndex, "priceOverrides", "total"],
            newTotalWithDiscount.toFixed(2)
          )
        );
      }
      dispatch(
        editActivePurchaseOrderAction(["items", rowIndex, "total"], newTotalWithDiscount.toFixed(2))
      );
    }

    dispatch(recalculateOrderSummaryPrice());
  };

export const recalculateOrderSummaryPrice = () => (dispatch, getState) => {
  const state = getState();
  const activePurchaseOrder = selectors.activePurchaseOrderSelector(state);
  const company = companySelector(state);

  const productsPriceTotal = activePurchaseOrder.items
    .filter((item) => !activePurchaseOrder.items.includes(item.id))
    .reduce((acc, val) => {
      const decimalAcc = Decimal(acc);
      const sum = Decimal(val?.priceOverrides?.total ?? val.total).plus(decimalAcc);
      return sum.toDecimalPlaces(4);
    }, Decimal(0));

  const productsGstTotal = activePurchaseOrder.items
    .filter((item) => !activePurchaseOrder.items.includes(item.id) && !item.isTaxFree)
    .reduce((acc, val) => {
      const decimalAcc = Decimal(acc);
      const gstRate = Decimal(company.taxRate);
      const itemTotal = Decimal(val?.priceOverrides?.total ?? val.total).toDecimalPlaces(2);
      const itemGst = itemTotal.times(gstRate).toDecimalPlaces(2);
      const sum = decimalAcc.plus(itemGst);
      return sum.toDecimalPlaces(4);
    }, Decimal(0));

  const flashingPriceTotal = activePurchaseOrder.flashingItems
    .filter((item) => !activePurchaseOrder.deletedFlashingItems.includes(item.id))
    .reduce((acc, val) => {
      const decimalAcc = Decimal(acc);
      const sum = Decimal(val.totalValue).plus(decimalAcc);
      return sum.toDecimalPlaces(4);
    }, Decimal(0));

  const flashingGstTotal = activePurchaseOrder.flashingItems
    .filter((item) => !activePurchaseOrder.deletedFlashingItems.includes(item.id))
    .reduce((acc, val) => {
      const decimalAcc = Decimal(acc);
      const gstRate = Decimal(company.taxRate);
      const itemTotal = Decimal(val.totalValue).toDecimalPlaces(2);
      const itemGst = itemTotal.times(gstRate).toDecimalPlaces(2);
      const sum = decimalAcc.plus(itemGst);
      return sum.toDecimalPlaces(4);
    }, Decimal(0));

  let sumSubTotal = productsPriceTotal.plus(flashingPriceTotal);
  let gstTotal = productsGstTotal.plus(flashingGstTotal);

  const totalDiscountRatePercent = activePurchaseOrder.discountRates.reduce((acc, discount) => {
    if (discount.discountRateInPercent === "") {
      return acc + 0;
    }

    return acc + parseFloat(discount.discountRateInPercent);
  }, 0);

  // Compute discounted GST and subtotal
  if (totalDiscountRatePercent > 0) {
    const totalDiscountRate = Decimal(totalDiscountRatePercent).dividedBy(100);

    sumSubTotal = sumSubTotal.times(1 - totalDiscountRate).toDecimalPlaces(2);

    gstTotal = gstTotal.times(1 - totalDiscountRate).toDecimalPlaces(2);
  }

  const decimalDeliveryFee = Decimal(activePurchaseOrder.deliveryFee).toDecimalPlaces(2);
  const decimalDeliveryFeeGst = decimalDeliveryFee
    .times(Decimal(company.taxRate))
    .toDecimalPlaces(2);

  sumSubTotal = sumSubTotal.plus(decimalDeliveryFee).toDecimalPlaces(2);

  const totalGst = gstTotal.plus(decimalDeliveryFeeGst).toDecimalPlaces(2);
  const totalAmount = sumSubTotal.plus(totalGst).toDecimalPlaces(2);
  const priceChanges = [
    { path: ["itemsAndDelivery"], value: sumSubTotal.toFixed(2) },
    { path: ["gstAmount"], value: totalGst.toFixed(2) },
    { path: ["total"], value: totalAmount.toFixed(2) },
  ];

  dispatch(batchEditActivePurchaseOrderAction(priceChanges));
};

export const updatedCompletedItemsAction = (purchaseOrderUid) => async (dispatch, getState) => {
  const state = getState();
  const activePurchaseOrder = selectors.activePurchaseOrderSelector(state);
  const products = activePurchaseOrder.items.map((item) => item);
  const flashings = activePurchaseOrder.flashingItems.map((item) => item);

  const data = {
    received: products.filter((item) => item.isReceived).map((item) => item.id),
    not_received: products.filter((item) => !item.isReceived).map((item) => item.id),
    flashing_received: flashings
      .filter((flashing) => flashing.isReceived)
      .map((flashing) => flashing.id),
    flashing_not_received: flashings
      .filter((flashing) => !flashing.isReceived)
      .map((flashing) => flashing.id),
  };
  const response = await updatedCompletedItems(purchaseOrderUid, data)();
  if (Array.isArray(response.data)) {
    const inventoryChanges = response.data.map((d) => {
      const itemIndex = activePurchaseOrder.items.findIndex((i) => i.id === d.id);
      return {
        path: ["items", itemIndex, "inventory"],
        value: d.inventory,
      };
    });
    dispatch(batchEditActivePurchaseOrderAction(inventoryChanges));
  }
};

export const createPurchaseOrderAction = (data) => {
  return async (dispatch) => {
    return await dispatchApi(
      dispatch,
      CREATE_PURCHASE_ORDER,
      () => createPurchaseOrder(data),
      INTENT.PERSISTENT
    );
  };
};

export const draftPurchaseOrderAction = (purchaseOrderUid) => {
  return async (dispatch) => {
    return await dispatchApi(
      dispatch,
      DRAFT_PURCHASE_ORDER,
      () => updatePurchaseOrder(purchaseOrderUid, { editStatus: 1 }),
      INTENT.TRANSIENT
    );
  };
};

export const deletePurchaseOrderAction = (purchaseOrderUid) => {
  return async (dispatch) => {
    await dispatchApi(
      dispatch,
      DELETE_PURCHASE_ORDER,
      () => deletePurchaseOrder(purchaseOrderUid),
      INTENT.PERSISTENT
    );
  };
};

export const bulkDeletePurchaseOrdersAction = (purchaseOrderUids) => {
  return async (dispatch) => {
    await dispatchApi(
      dispatch,
      BULK_DELETE_PURCHASE_ORDERS,
      () => bulkDeletePurchaseOrders(purchaseOrderUids),
      INTENT.PERSISTENT
    );
  };
};

export const editPurchaseOrderFiltersAction = (path, value) => ({
  type: EDIT_PURCHASE_ORDER_FILTERS,
  payload: { path, value },
});

export const undoPurchaseOrderArchiveAction = (purchaseOrderUid) => {
  return (dispatch) => {
    dispatchApi(
      dispatch,
      UNDO_PURCHASE_ORDER_ARCHIVE,
      () => updatePurchaseOrder(purchaseOrderUid, { isDeleted: false }),
      INTENT.PERSISTENT
    );
  };
};
export const undoPurchaseOrderBulkArchiveAction = (purchaseOrderUids) => {
  return (dispatch) => {
    dispatchApi(
      dispatch,
      UNDO_PURCHASE_ORDER_BULK_ARCHIVE,
      () => bulkRestorePurchaseOrders(purchaseOrderUids),
      INTENT.PERSISTENT
    );
  };
};

export const getExternalPurchaseOrderAction = (externalToken) => {
  return (dispatch) => {
    dispatchApi(
      dispatch,
      GET_EXTERNAL_PURCHASE_ORDER,
      () => getExternalPurchaseOrder(externalToken),
      INTENT.PERSISTENT
    );
  };
};

export const getLinkedPurchaseOrdersAction = ({ customerUid, orderUid }) => {
  return (dispatch) => {
    dispatchApi(
      dispatch,
      GET_LINKED_ORDERS_FROM_PURCHASE_ORDERS,
      () => getLinkedOrdersFromPurchaseOrder({ customerUid, orderUid }),
      INTENT.PERSISTENT
    );
  };
};

export const resetPurchaseOrdersAction = () => ({
  type: RESET_PURCHASE_ORDERS,
});

// ATTACHMENTS
export const setIsFetching = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING,
    payload: status,
  });
};

export const setSelectedAttachments = (data) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_ATTACHMENTS,
    payload: data,
  });
};

export const setOrderAttachments = (data) => (dispatch) => {
  dispatch({
    type: SET_ORDER_ATTACHMENTS,
    payload: data,
  });
};

export const removeSelectedAttachment = (data) => (dispatch) => {
  dispatch({
    type: REMOVE_SELECTED_ATTACHMENT,
    payload: data,
  });
};

export const updateSelectedAttachments = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_ATTACHMENTS,
    payload: data,
  });
};

export const setPrintPurchaseOrderPdfStatus = (data) => (dispatch) => {
  dispatch({
    type: PRINT_PURCHASE_ORDER_PDF_STATUS,
    payload: data,
  });
};

export const setEmailPurchaseOrderStatus = (data) => (dispatch) => {
  dispatch({
    type: EMAIL_PURCHASE_ORDER_STATUS,
    payload: data,
  });
};

export const addAttachments =
  ({ purchaseOrderUid, file }) =>
  async (dispatch) => {
    dispatch(setIsFetching(true));
    const data = new FormData();
    const token = JSON.parse(window.localStorage.getItem("auth"));

    data.set(
      "json",
      JSON.stringify({
        purchase_order_uid: purchaseOrderUid,
        message: "",
      })
    );

    data.append("files", file);

    await saveMessageWithFiles(token, data).then((res) => {
      // side effects
      const response = res.data;
      const attachmentId = response?.order_chat_message_file[0].id ?? "";
      const attachmentType = response?.order_chat_message_file[0].type ?? "";

      const FILE_TYPES = {
        "image/png": "image",
        "image/jpg": "image",
        "image/jpeg": "image",
        "application/dwg": "dwg",
        "application/dxf": "dxf",
        "image/vnd.dwg": "dwg",
        "image/vnd.dxf": "dxf",
      };

      dispatch(
        setSelectedAttachments({
          id: attachmentId,
          type: FILE_TYPES[attachmentType.toLowerCase()] || "pdf",
        })
      );
    });

    const params = {
      purchase_order_uid: purchaseOrderUid,
    };
    const response = await getPurchaseOrderAttachments({ params })();
    dispatch(setOrderAttachments(response));

    dispatch(setIsFetching(false));
  };

export const viewFile =
  ({ supplierUid, purchaseOrderUid, fileId }) =>
  async (dispatch) => {
    const token = JSON.parse(window.localStorage.getItem("auth"));
    dispatch(setIsFetching(true));

    await getCollaborationNotesFile(supplierUid, purchaseOrderUid, fileId, token).then(
      (response) => {
        const fileUrl = window.URL.createObjectURL(
          new File([response.data], fileId, { type: response.data.type })
        );
        window.open(fileUrl);
      }
    );

    dispatch(setIsFetching(false));
  };

export const fetchPurchaseOrderAttachments =
  ({ purchaseOrderUid }) =>
  async (dispatch) => {
    dispatch(setIsFetching(true));
    try {
      const params = {
        purchase_order_uid: purchaseOrderUid,
      };
      const response = await getPurchaseOrderAttachments({ params })();
      dispatch(setOrderAttachments(response));
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(setIsFetching(false));
    }
  };

export const generatePdf =
  ({ purchaseOrderUid, drawingSVGs, withPrices, imageAttachmentUids, pdfAttachmentUids }) =>
  async (dispatch) => {
    const data = new FormData();

    Object.keys(drawingSVGs).forEach((key) => {
      const drawing = drawingSVGs[key];
      const { uid, drawingSideIndex, svgXml } = drawing;
      const svgBlob = new Blob([svgXml], { type: "image/svg+xml" });
      const file = new File([svgBlob], uid, {
        type: "image/svg+xml",
        lastModified: Date.now(),
      });
      data.set(uid, file);
    });

    data.set(
      "json",
      JSON.stringify({
        with_prices: withPrices,
        attachment_images: imageAttachmentUids,
        attachment_pdfs: pdfAttachmentUids,
      })
    );

    dispatch(setPrintPurchaseOrderPdfStatus(STATUS.PENDING));
    const { data: pdfUrl } = await postPrintPurchaseOrderPdf({ purchaseOrderUid, data })();
    dispatch(setPrintPurchaseOrderPdfStatus(STATUS.FULFILLED));
    return `${ROOT_URL}/${pdfUrl}`;
  };

export const generateEmailPdf =
  ({ purchaseOrderUid, emails, drawingSVGs, withPrices, attachmentUids, customEmailMessage }) =>
  async (dispatch) => {
    const data = new FormData();

    Object.keys(drawingSVGs).forEach((key) => {
      const drawing = drawingSVGs[key];
      const { uid, drawingSideIndex, svgXml } = drawing;
      const svgBlob = new Blob([svgXml], { type: "image/svg+xml" });
      const file = new File([svgBlob], uid, {
        type: "image/svg+xml",
        lastModified: Date.now(),
      });
      data.set(uid, file);
    });

    data.set(
      "json",
      JSON.stringify({
        with_prices: withPrices,
        emails,
        attachments: attachmentUids,
        custom_email_message: customEmailMessage,
      })
    );

    dispatch(setEmailPurchaseOrderStatus(STATUS.PENDING));
    await emailPurchaseOrder({ purchaseOrderUid, data })();
    dispatch(setEmailPurchaseOrderStatus(STATUS.FULFILLED));
  };

// Pagination
export const setCurrentPage = (search) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_PAGE,
    payload: search,
  });
};

export const setTotalCount = (search) => (dispatch) => {
  dispatch({
    type: SET_TOTAL_COUNT,
    payload: search,
  });
};

export const setNextPageOffset = (search) => (dispatch) => {
  dispatch({
    type: SET_NEXT_PAGE_OFFSET,
    payload: search,
  });
};

export const setPreviousPageOffset = (search) => (dispatch) => {
  dispatch({
    type: SET_PREVIOUS_PAGE_OFFSET,
    payload: search,
  });
};

export const setPageOffset = (search) => (dispatch) => {
  dispatch({
    type: SET_PAGE_OFFSET,
    payload: search,
  });
};

export const setPageLimit = (search) => (dispatch) => {
  dispatch({
    type: SET_PAGE_LIMIT,
    payload: search,
  });
};

export const setSort = (sort) => (dispatch) => {
  dispatch({
    type: SET_SORT,
    payload: sort,
  });
};

export const resetPaginationAction = () => (dispatch) => {
  dispatch(setTotalCount(0));
  dispatch(setCurrentPage(0));
  dispatch(setPageOffset(0));
  dispatch(setNextPageOffset(0));
  dispatch(setPreviousPageOffset(0));
};

export const setIsDuplicating = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_DUPLICATING,
    payload: status,
  });
};

export const setDuplicateOrderUid = (uid) => (dispatch) => {
  dispatch({
    type: SET_DUPLICATE_ORDER_UID,
    payload: uid,
  });
};

export const setIsDuplicateConfirmationAlertOpen = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_DUPLICATE_CONFIRMATION_ALERT_OPEN,
    payload: status,
  });
};

export const duplicateOrderAction = (purchaseOrderUid) => async (dispatch) => {
  try {
    dispatch(setIsDuplicating(true));
    await postDuplicateOrder(purchaseOrderUid)();
    dispatch(alertActions.createAlertAction(nanoid(), "Order duplicated", true, "success"));
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsDuplicating(false));
    dispatch(setDuplicateOrderUid(null));
    dispatch(setIsDuplicateConfirmationAlertOpen(false));
  }
};
export const setIsChangingSupplier = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_CHANGING_SUPPLIER,
    payload: status,
  });
};

export const setIsChangingSupplierModalOpen = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_CHANGING_SUPPLIER_MODAL_OPEN,
    payload: status,
  });
};

export const changeSupplierAction =
  ({ purchaseOrderUid, newSupplierUid, purchaseOrderNumber }) =>
  async (dispatch) => {
    try {
      dispatch(setIsChangingSupplier(STATUS.PENDING));
      await postChangeSupplier({ purchaseOrderUid, newSupplierUid })();
      dispatch(
        alertActions.createAlertAction(
          nanoid(),
          "Supplier changed for Purchase Order #" + purchaseOrderNumber,
          true,
          "success"
        )
      );
      dispatch(draftPurchaseOrderAction(purchaseOrderUid));
    } catch (error) {
      dispatch(
        alertActions.createAlertAction(nanoid(), error?.response?.data || error, true, "danger")
      );
    } finally {
      dispatch(setIsChangingSupplier(STATUS.FULFILLED));
      dispatch(setIsChangingSupplierModalOpen(false));
    }
  };

export const setIsConvertingBillOrPO = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_CONVERTING_BILL_OR_PO,
    payload: status,
  });
};

export const setIsConvertingBillOrPOConfirmationAlertOpen = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_CONVERTING_BILL_OR_PO_CONFIRMATION_ALERT_OPEN,
    payload: status,
  });
};

export const editDrawingSVGs = (data) => (dispatch) => {
  dispatch({
    type: EDIT_DRAWING_SVGS,
    payload: data,
  });
};

export const saveProductToCatalogueAction = (payload) => async (dispatch) => {
  try {
    dispatch(
      editActivePurchaseOrderAction(["items", payload.rowIndex, "isSavingToProductCatalogue"], true)
    );
    const { data: priceLevels } = await priceLevelsApi.getPriceLevels()();
    const { data: categories } = await productsApi.getProductCategoriesV2()();

    const miscellaneousCategory = categories.find((c) => c.name === "Miscellaneous");

    const data = {
      name: payload.displayName,
      category_uid: miscellaneousCategory?.uid ?? null,
      tables: [
        {
          material_uid: "",
          colours: [],
          fields: [
            {
              name: "Description",
              is_hide_order_summary: false,
              is_hide_invoice_pdf: false,
              is_hide_customer_view: false,
              is_hide_work_order_pdf: false,
            },
          ],
          values: [
            {
              inventory: [],
              types: [
                {
                  name: "Description",
                  value: payload.productDescription,
                  is_hide_order_summary: false,
                  is_hide_invoice_pdf: false,
                  is_hide_customer_view: false,
                  is_hide_work_order_pdf: false,
                },
              ],
              prices: [
                {
                  name: "Price (A)",
                  value: "",
                },
              ],
              accounting_item: {},
              suppliers: [
                {
                  supplier_uid: payload.supplierUid,
                  price: payload.productRowPrice,
                },
              ],
              is_deleted: false,
              row_prices: priceLevels.map((p, index) => ({
                price_level_uid: p.uid,
                value: payload.productRowPrice,
              })),
            },
          ],
          price_levels: priceLevels,
          hiddenPriceLevels: [],
        },
      ],
      pricing_strategy: payload.pricingStrategy, // Temporary change to actual value once pricing strategy is merge
      is_deleted: false,
      is_purchased_by_company: true,
      is_sold_by_company: false,
      is_stock_tracked_by_company: false,
      is_margin_tracked_by_company: false,
      suppliers: [
        {
          supplier_uid: payload.supplierUid,
          is_preferred_supplier: false,
        },
      ],
      custom_formula: null,
    };

    const bodyFormData = new FormData();
    bodyFormData.append("json", JSON.stringify(snakecaseKeys(data)));
    bodyFormData.append("image", "");

    const { data: postResponse } = await productsApi.saveProduct(bodyFormData)();

    dispatch(
      batchEditActivePurchaseOrderAction([
        { path: ["items", payload.rowIndex, "isSavingToProductCatalogue"], value: false },
        {
          path: ["items", payload.rowIndex, "productRow"],
          value: postResponse.tables[0].values[0].suppliers[0].uid,
        },
        {
          path: ["items", payload.rowIndex, "selectedProductRow"],
          value: postResponse.tables[0].values[0].uid,
        },
        {
          path: ["items", payload.rowIndex, "productType"],
          value: PRODUCT_TYPE_ADDITIONAL_PRODUCT,
        },
        {
          path: ["items", payload.rowIndex, "displayTypes"],
          value: postResponse.tables[0].values[0].types,
        },
      ])
    );

    dispatch(updatePurchaseOrderSilently(payload.purchaseOrderUid));

    dispatch(
      alertActions.createAlertAction(
        nanoid(),
        `Product saved to catalogue as ${payload.displayName}`,
        true,
        "success"
      )
    );
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const setIsAddProductModalType = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_ADD_PRODUCT_MODAL_TYPE,
    payload: status,
  });
};
