import createActionType from "../utils/createActionType";

export const MODULE = "priceLevels";

export const SET_IS_FETCHING_PRICE_LEVELS = createActionType(MODULE, "setIsFetchingPriceLevels");

export const SET_IS_FETCHING_CUSTOMERS = createActionType(MODULE, "setIsFetchingCustomers");

export const SET_IS_DELETE_PRICE_LEVEL_MODAL_OPEN = createActionType(
  MODULE,
  "setIsDeletePriceLevelModalOpen"
);

export const SET_RECENTLY_DELETED_PRICE_LEVEL_UID = createActionType(
  MODULE,
  "setRecentlyDeletedPriceLevelUid"
);

export const SET_PRICE_LEVELS = createActionType(MODULE, "setPriceLevels");

export const SET_CUSTOMERS = createActionType(MODULE, "setCustomers");

export const SET_SEARCH = createActionType(MODULE, "setSearch");

export const RESET = createActionType(MODULE, "reset");
