import { STATUS } from "@/utils/constants";

import * as api from "../../apiv2/orderTracking";
import { handleError } from "../utils/error";

import {
  SET_ITEMS_PROGRESS,
  SET_ITEMS,
  SET_PARENT_ITEM_UPDATE_PROGRESS,
  SET_SUB_KIT_UPDATE_PROGRESS,
  SET_ITEM_UPDATE_PROGRESS,
  SET_STATUS_REFERENCES_PROGRESS,
  SET_STATUS_REFERENCES,
  SET_STATUS_REFERENCE_CREATE_PROGRESS,
  SET_STATUS_REFERENCE_DELETE_PROGRESS,
  SET_STATUS_REFERENCE_UPDATE_PROGRESS,
  SET_STATUS_REFERENCE_TO_DELETE_ID,
  SET_STATUS_REFERENCE_LINE_ITEM_ACTIVE,
  SET_STATUS_REFERENCE_TO_EDIT,
  SET_ADDITIONAL_PRODUCTS_PROGRESS,
  SET_ADDITIONAL_PRODUCTS,
  SET_ACTIVE_LINE_ITEM,
  RESET,
  SET_IS_FETCHING_ACTIVE_LINE_ITEM_UID,
} from "./orderTracking.actionTypes";
import * as selectors from "./orderTracking.selectors";

export const setItemProgress = (payload) => (dispatch) => {
  dispatch({
    type: SET_ITEMS_PROGRESS,
    payload,
  });
};

export const setItems = (payload) => (dispatch) => {
  dispatch({
    type: SET_ITEMS,
    payload,
  });
};

export const setParentItemUpdateProgress = (payload) => (dispatch) => {
  dispatch({
    type: SET_PARENT_ITEM_UPDATE_PROGRESS,
    payload,
  });
};

export const setSubKitUpdateProgress = (payload) => (dispatch) => {
  dispatch({
    type: SET_SUB_KIT_UPDATE_PROGRESS,
    payload,
  });
};

export const setItemUpdateProgress = (payload) => (dispatch) => {
  dispatch({
    type: SET_ITEM_UPDATE_PROGRESS,
    payload,
  });
};

export const setStatusReferencesProgress = (payload) => (dispatch) => {
  dispatch({
    type: SET_STATUS_REFERENCES_PROGRESS,
    payload,
  });
};

export const setStatusReferences = (payload) => (dispatch) => {
  dispatch({
    type: SET_STATUS_REFERENCES,
    payload,
  });
};

export const setStatusReferenceCreateProgress = (payload) => (dispatch) => {
  dispatch({
    type: SET_STATUS_REFERENCE_CREATE_PROGRESS,
    payload,
  });
};

export const setStatusReferenceDeleteProgress = (payload) => (dispatch) => {
  dispatch({
    type: SET_STATUS_REFERENCE_DELETE_PROGRESS,
    payload,
  });
};

export const setStatusReferenceUpdateProgress = (payload) => (dispatch) => {
  dispatch({
    type: SET_STATUS_REFERENCE_UPDATE_PROGRESS,
    payload,
  });
};

export const setStatusReferenceToDeleteId = (payload) => (dispatch) => {
  dispatch({
    type: SET_STATUS_REFERENCE_TO_DELETE_ID,
    payload,
  });
};

export const setStatusReferenceLineItemActive = (payload) => (dispatch) => {
  dispatch({
    type: SET_STATUS_REFERENCE_LINE_ITEM_ACTIVE,
    payload,
  });
};

export const setStatusReferenceToEdit = (payload) => (dispatch) => {
  dispatch({
    type: SET_STATUS_REFERENCE_TO_EDIT,
    payload,
  });
};

export const setAdditionalProductsProgress = (payload) => (dispatch) => {
  dispatch({
    type: SET_ADDITIONAL_PRODUCTS_PROGRESS,
    payload,
  });
};

export const setAdditionalProducts = (payload) => (dispatch) => {
  dispatch({
    type: SET_ADDITIONAL_PRODUCTS,
    payload,
  });
};

export const setActiveLineItem = (payload) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_LINE_ITEM,
    payload,
  });
};

export const setIsFetchingActiveLineItemUid = (payload) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_ACTIVE_LINE_ITEM_UID,
    payload,
  });
};

export const fetchItems =
  ({ customerUid, orderUid, params = {} }) =>
  async (dispatch) => {
    try {
      dispatch(setItemProgress(STATUS.PENDING));
      const response = await api.getLineItemStatuses({ customerUid, orderUid, params })();
      dispatch(setItems(response.data));
      dispatch(setItemProgress(STATUS.FULFILLED));
    } catch (error) {
      handleError(error, dispatch);
      dispatch(setItemProgress(STATUS.REJECTED));
    }
  };

export const updateParentItemStatus = (data) => async (dispatch) => {
  try {
    dispatch(setParentItemUpdateProgress(STATUS.PENDING));
    await api.putParentLineItemStatus(data)();
    dispatch(setParentItemUpdateProgress(STATUS.FULFILLED));
  } catch (error) {
    handleError(error, dispatch);
    dispatch(setParentItemUpdateProgress(STATUS.REJECTED));
  }
};

export const updateItemStatus = (customerId, orderId, uid, data) => async (dispatch) => {
  try {
    dispatch(setItemUpdateProgress(STATUS.PENDING));
    await api.patchLineItemStatus(customerId, orderId, uid, data)();
    dispatch(setItemUpdateProgress(STATUS.FULFILLED));
  } catch (error) {
    handleError(error, dispatch);
    dispatch(setItemUpdateProgress(STATUS.REJECTED));
  }
};

export const fetchStatusReferences = () => async (dispatch) => {
  try {
    dispatch(setStatusReferencesProgress(STATUS.PENDING));
    const response = await api.getLineItemStatusReferences()();
    dispatch(setStatusReferences(response.data));
    dispatch(setStatusReferencesProgress(STATUS.FULFILLED));
  } catch (error) {
    handleError(error, dispatch);
    dispatch(setStatusReferencesProgress(STATUS.REJECTED));
  }
};

export const updateStatusReference = (id, data) => async (dispatch) => {
  try {
    dispatch(setStatusReferenceUpdateProgress(STATUS.PENDING));
    await api.patchLineItemStatusReference(id, data)();
    dispatch(setStatusReferenceUpdateProgress(STATUS.FULFILLED));
  } catch (error) {
    handleError(error, dispatch);
    dispatch(setStatusReferenceUpdateProgress(STATUS.REJECTED));
  }
};

export const createStatusReference = (data) => async (dispatch) => {
  try {
    dispatch(setStatusReferenceCreateProgress(STATUS.PENDING));
    await api.postLineItemStatusReference(data)();
    dispatch(setStatusReferenceCreateProgress(STATUS.FULFILLED));
  } catch (error) {
    handleError(error, dispatch);
    dispatch(setStatusReferenceCreateProgress(STATUS.REJECTED));
  }
};

export const fetchAdditionalProducts =
  ({ customerUid, orderUid, params = {} }) =>
  async (dispatch) => {
    try {
      dispatch(setAdditionalProductsProgress(STATUS.PENDING));
      const response = await api.getLineItemAdditionalProducts({ customerUid, orderUid, params })();
      dispatch(setAdditionalProducts(response.data));
      dispatch(setAdditionalProductsProgress(STATUS.FULFILLED));
    } catch (error) {
      handleError(error, dispatch);
      dispatch(setAdditionalProductsProgress(STATUS.REJECTED));
    }
  };

export const deleteStatusReference = (data) => async (dispatch) => {
  try {
    dispatch(setStatusReferenceDeleteProgress(STATUS.PENDING));
    await api.deleteLineItemStatusReference(data)();
    dispatch(setStatusReferenceDeleteProgress(STATUS.FULFILLED));
  } catch (error) {
    handleError(error, dispatch);
    dispatch(setStatusReferenceDeleteProgress(STATUS.REJECTED));
  }
};

export const updateLineItemTimeTracking =
  ({ customerUid, orderUid, itemUid }) =>
  async (dispatch, getState) => {
    const state = getState();
    const additionalProducts = selectors.additionalProducts(state);

    try {
      dispatch(setAdditionalProductsProgress(STATUS.PENDING));

      const { data } = itemUid
        ? await api.getLineItemAdditionalProduct({
            customerUid,
            orderUid,
            itemUid,
          })()
        : await api.getLineItemAllProductItem({
            customerUid,
            orderUid,
          })();

      if (itemUid) {
        dispatch(
          setAdditionalProducts(additionalProducts.map((a) => (a.uid === itemUid ? data : a)))
        );
      } else {
        dispatch(
          setAdditionalProducts(
            additionalProducts.map((a) => (a.orderId === data.orderId ? data : a))
          )
        );
      }

      dispatch(setAdditionalProductsProgress(STATUS.FULFILLED));
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const reset = () => (dispatch) => {
  dispatch({ type: RESET });
};
