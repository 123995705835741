import createActionType from "../utils/createActionType";

export const MODULE = "workflow";

export const SET_SETTINGS = createActionType(MODULE, "setSettings");

export const SET_IS_FETCHING_SETTINGS = createActionType(MODULE, "setIsFetchingSettings");

export const SET_COLUMNS = createActionType(MODULE, "setColumns");

export const SET_SELECTED_COLUMNS = createActionType(MODULE, "setSelectedColumns");

export const SET_IS_FETCHING_COLUMNS = createActionType(MODULE, "setIsFetchingColumns");

export const SET_CARDS = createActionType(MODULE, "cards");
export const SET_IS_FETCHING_CARDS = createActionType(MODULE, "isFetchingCards");

export const SET_CARDS_IN_COLUMN = createActionType(MODULE, "setCardsInColumn");

export const SET_ACTIVE_VIEW = createActionType(MODULE, "setActiveView");

export const SET_IS_ORDER_MODAL_OPEN = createActionType(MODULE, "setIsOrderModalOpen");

export const RESET = createActionType(MODULE, "reset");
