import createActionType from "@/redux/utils/createActionType";

export const MODULE = "orderTracking";

export const SET_ITEMS_PROGRESS = createActionType(MODULE, "setItemProgress");
export const SET_ITEMS = createActionType(MODULE, "setItems");

export const SET_PARENT_ITEM_UPDATE_PROGRESS = createActionType(
  MODULE,
  "setParentItemUpdateProgress"
);

export const SET_SUB_KIT_UPDATE_PROGRESS = createActionType(MODULE, "setSubKitUpdateProgress");

export const SET_ITEM_UPDATE_PROGRESS = createActionType(MODULE, "setItemUpdateProgress");

export const SET_STATUS_REFERENCES_PROGRESS = createActionType(
  MODULE,
  "setStatusReferencesProgress"
);
export const SET_STATUS_REFERENCES = createActionType(MODULE, "setStatusReferences");

export const SET_STATUS_REFERENCE_CREATE_PROGRESS = createActionType(
  MODULE,
  "setStatusReferenceCreateProgress"
);

export const SET_STATUS_REFERENCE_TO_DELETE_ID = createActionType(
  MODULE,
  "setStatusReferenceToDeleteId"
);
export const SET_STATUS_REFERENCE_LINE_ITEM_ACTIVE = createActionType(
  MODULE,
  "setStatusReferenceLineItemActive"
);

export const SET_STATUS_REFERENCE_TO_EDIT = createActionType(MODULE, "setStatusReferenceToEdit");
export const SET_STATUS_REFERENCE_DELETE_PROGRESS = createActionType(
  MODULE,
  "setStatusReferenceDeleteProgress"
);
export const SET_STATUS_REFERENCE_UPDATE_PROGRESS = createActionType(
  MODULE,
  "setStatusReferenceUpdateProgress"
);

export const SET_ADDITIONAL_PRODUCTS_PROGRESS = createActionType(
  MODULE,
  "setAdditionalProductsProgress"
);
export const SET_ADDITIONAL_PRODUCTS = createActionType(MODULE, "setAdditionalProducts");

export const SET_ACTIVE_LINE_ITEM = createActionType(MODULE, "setActiveLineItem");

export const SET_IS_FETCHING_ACTIVE_LINE_ITEM_UID = createActionType(
  MODULE,
  "setIsFetchingActiveLineItemUid"
);

export const RESET = createActionType(MODULE, "reset");
