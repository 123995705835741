import { escapeRegExp } from "lodash";

export const getInitials = (string) => {
  const initials = string.match(/\b(\w)/g);
  return [initials[0], initials[initials.length - 1]].join("").toUpperCase();
};

export const highlightKeyword = (match, keyword) => {
  const regex = new RegExp(escapeRegExp(keyword), "gi");
  return match.replace(regex, "<b>$&</b>");
};
