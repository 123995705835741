import { STATUS } from "@/utils/constants";

import {
  getGlobalSearchSuppliers,
  getGlobalSearchCustomers,
  getGlobalSearchAll,
  getGlobalSearchSales,
  getGlobalSearchPurchases,
  getGlobalSearchProducts,
} from "../../apiv2/globalSearch";
import { handleError } from "../utils/error";

import * as selectors from "./globalSearch.selectors";
import {
  SET_GLOBAL_SEARCH_SEARCHING_STATUS,
  SET_GLOBAL_SEARCH_MODAL_IS_VISIBLE,
  SET_GLOBAL_SEARCH_TYPE,
  SET_GLOBAL_SEARCH_OPTION_TYPES,
  SET_GLOBAL_SEARCH_POPOVER_OPEN,
  SET_GLOBAL_SEARCH_OPTIONS_OPEN,
  SET_GLOBAL_SEARCH_KEYWORD,
  SET_GLOBAL_SEARCH_IS_SHOW_ALL_CLICKED,
  SET_FETCH_GLOBAL_SEARCH_ALL_STATUS,
  SET_FETCH_GLOBAL_SEARCH_ALL_RESULTS,
  SET_FETCH_GLOBAL_SEARCH_ALL,
  SET_FETCH_GLOBAL_SEARCH_CUSTOMERS_STATUS,
  SET_FETCH_GLOBAL_SEARCH_CUSTOMERS_RESULTS,
  SET_FETCH_GLOBAL_SEARCH_CUSTOMERS,
  SET_FETCH_GLOBAL_SEARCH_SUPPLIERS_STATUS,
  SET_FETCH_GLOBAL_SEARCH_SUPPLIERS_RESULTS,
  SET_FETCH_GLOBAL_SEARCH_SUPPLIERS,
  SET_FETCH_GLOBAL_SEARCH_SALES_STATUS,
  SET_FETCH_GLOBAL_SEARCH_SALES_RESULTS,
  SET_FETCH_GLOBAL_SEARCH_SALES,
  SET_FETCH_GLOBAL_SEARCH_PURCHASES_STATUS,
  SET_FETCH_GLOBAL_SEARCH_PURCHASES_RESULTS,
  SET_FETCH_GLOBAL_SEARCH_PURCHASES,
  SET_FETCH_GLOBAL_SEARCH_PRODUCTS_STATUS,
  SET_FETCH_GLOBAL_SEARCH_PRODUCTS_RESULTS,
  SET_FETCH_GLOBAL_SEARCH_PRODUCTS,
  RESET,
} from "./globalSearch.actionTypes";
import { types } from "./constants";

export const setGlobalSearchSearchingStatus = (namespace, payload) => (dispatch) => {
  dispatch({
    type: `${namespace}${SET_GLOBAL_SEARCH_SEARCHING_STATUS}`,
    payload,
  });
};

export const setGlobalSearchModalIsVisible = (namespace, payload) => (dispatch) => {
  dispatch({
    type: `${namespace}${SET_GLOBAL_SEARCH_MODAL_IS_VISIBLE}`,
    payload,
  });
};

export const setGlobalSearchType = (namespace, payload) => (dispatch) => {
  dispatch({
    type: `${namespace}${SET_GLOBAL_SEARCH_TYPE}`,
    payload,
  });
};

export const setGlobalSearchOptionTypes = (namespace, payload) => (dispatch) => {
  dispatch({
    type: `${namespace}${SET_GLOBAL_SEARCH_OPTION_TYPES}`,
    payload,
  });
};

export const setGlobalSearchPopoverOpen = (namespace, payload) => (dispatch) => {
  dispatch({
    type: `${namespace}${SET_GLOBAL_SEARCH_POPOVER_OPEN}`,
    payload,
  });
};

export const setGlobalSearchOptionsOpen = (namespace, payload) => (dispatch) => {
  dispatch({
    type: `${namespace}${SET_GLOBAL_SEARCH_OPTIONS_OPEN}`,
    payload,
  });
};

export const setGlobalSearchKeyword = (namespace, payload) => (dispatch) => {
  dispatch({
    type: `${namespace}${SET_GLOBAL_SEARCH_KEYWORD}`,
    payload,
  });
};

export const setGlobalSearchIsShowAllClicked = (namespace, payload) => (dispatch) => {
  dispatch({
    type: `${namespace}${SET_GLOBAL_SEARCH_IS_SHOW_ALL_CLICKED}`,
    payload,
  });
};

// Search ALL
export const setFetchGlobalSearchAllStatus = (namespace, payload) => (dispatch) => {
  dispatch({
    type: `${namespace}${SET_FETCH_GLOBAL_SEARCH_ALL_STATUS}`,
    payload,
  });
};

export const setFetchGlobalSearchAllResults = (namespace, payload) => (dispatch) => {
  dispatch({
    type: `${namespace}${SET_FETCH_GLOBAL_SEARCH_ALL_RESULTS}`,
    payload,
  });
};

export const setFetchGlobalSearchAll = (namespace, payload) => (dispatch) => {
  dispatch({
    type: `${namespace}${SET_FETCH_GLOBAL_SEARCH_ALL}`,
    payload,
  });
};

export const fetchGlobalSearchAll = (namespace, params) => async (dispatch) => {
  try {
    dispatch(setFetchGlobalSearchAllStatus(namespace, STATUS.PENDING));
    dispatch(setGlobalSearchSearchingStatus(namespace, STATUS.PENDING));
    const response = await getGlobalSearchAll(params)();
    dispatch(setFetchGlobalSearchAll(namespace, response.data));

    dispatch(
      setGlobalSearchOptionTypes(namespace, [
        types.ALL,
        ...response.data.counts.map(({ key }) =>
          [types.PRODUCT, types.FLASHINGS, types.PRODUCT_KIT, types.MATERIAL].includes(key)
            ? types.PRODUCT
            : key
        ),
      ])
    );

    dispatch(setFetchGlobalSearchAllResults(namespace, response.data.results));
    dispatch(setFetchGlobalSearchAllStatus(namespace, STATUS.FULFILLED));
    dispatch(setGlobalSearchSearchingStatus(namespace, STATUS.FULFILLED));
  } catch (error) {
    handleError(error, dispatch);
    dispatch(setFetchGlobalSearchAllStatus(namespace, STATUS.REJECTED));
    dispatch(setGlobalSearchSearchingStatus(namespace, STATUS.REJECTED));
  }
};

// Search CUSTOMER
export const setFetchGlobalSearchCustomersStatus = (namespace, payload) => (dispatch) => {
  dispatch({
    type: `${namespace}${SET_FETCH_GLOBAL_SEARCH_CUSTOMERS_STATUS}`,
    payload,
  });
};

export const setFetchGlobalSearchCustomersResults = (namespace, payload) => (dispatch) => {
  dispatch({
    type: `${namespace}${SET_FETCH_GLOBAL_SEARCH_CUSTOMERS_RESULTS}`,
    payload,
  });
};

export const setFetchGlobalSearchCustomers = (namespace, payload) => (dispatch) => {
  dispatch({
    type: `${namespace}${SET_FETCH_GLOBAL_SEARCH_CUSTOMERS}`,
    payload,
  });
};

export const fetchGlobalSearchCustomers = (namespace, params) => async (dispatch) => {
  try {
    dispatch(setFetchGlobalSearchCustomersStatus(namespace, STATUS.PENDING));
    dispatch(setGlobalSearchSearchingStatus(namespace, STATUS.PENDING));
    const response = await getGlobalSearchCustomers(params)();
    dispatch(setFetchGlobalSearchCustomers(namespace, response.data));
    dispatch(setFetchGlobalSearchCustomersResults(namespace, response.data.results));
    dispatch(setFetchGlobalSearchCustomersStatus(namespace, STATUS.FULFILLED));
    dispatch(setGlobalSearchSearchingStatus(namespace, STATUS.FULFILLED));
  } catch (error) {
    handleError(error, dispatch);
    dispatch(setFetchGlobalSearchCustomersStatus(namespace, STATUS.REJECTED));
    dispatch(setGlobalSearchSearchingStatus(namespace, STATUS.REJECTED));
  }
};

// Search SUPPLIER
export const setFetchGlobalSearchSuppliersStatus = (namespace, payload) => (dispatch) => {
  dispatch({
    type: `${namespace}${SET_FETCH_GLOBAL_SEARCH_SUPPLIERS_STATUS}`,
    payload,
  });
};

export const setFetchGlobalSearchSuppliersResults = (namespace, payload) => (dispatch) => {
  dispatch({
    type: `${namespace}${SET_FETCH_GLOBAL_SEARCH_SUPPLIERS_RESULTS}`,
    payload,
  });
};

export const setFetchGlobalSearchSuppliers = (namespace, payload) => (dispatch) => {
  dispatch({
    type: `${namespace}${SET_FETCH_GLOBAL_SEARCH_SUPPLIERS}`,
    payload,
  });
};

export const fetchGlobalSearchSuppliers = (namespace, params) => async (dispatch) => {
  try {
    dispatch(setFetchGlobalSearchSuppliersStatus(namespace, STATUS.PENDING));
    dispatch(setGlobalSearchSearchingStatus(namespace, STATUS.PENDING));
    const response = await getGlobalSearchSuppliers(params)();
    dispatch(setFetchGlobalSearchSuppliers(namespace, response.data));
    dispatch(setFetchGlobalSearchSuppliersResults(namespace, response.data.results));
    dispatch(setFetchGlobalSearchSuppliersStatus(namespace, STATUS.FULFILLED));
    dispatch(setGlobalSearchSearchingStatus(namespace, STATUS.FULFILLED));
  } catch (error) {
    handleError(error, dispatch);
    dispatch(setFetchGlobalSearchSuppliersStatus(namespace, STATUS.REJECTED));
    dispatch(setGlobalSearchSearchingStatus(namespace, STATUS.REJECTED));
  }
};

// Search SALES
export const setFetchGlobalSearchSalesStatus = (namespace, payload) => (dispatch) => {
  dispatch({
    type: `${namespace}${SET_FETCH_GLOBAL_SEARCH_SALES_STATUS}`,
    payload,
  });
};

export const setFetchGlobalSearchSalesResults = (namespace, payload) => (dispatch) => {
  dispatch({
    type: `${namespace}${SET_FETCH_GLOBAL_SEARCH_SALES_RESULTS}`,
    payload,
  });
};

export const setFetchGlobalSearchSales = (namespace, payload) => (dispatch) => {
  dispatch({
    type: `${namespace}${SET_FETCH_GLOBAL_SEARCH_SALES}`,
    payload,
  });
};

export const fetchGlobalSearchSales = (namespace, params) => async (dispatch) => {
  try {
    dispatch(setFetchGlobalSearchSalesStatus(namespace, STATUS.PENDING));
    dispatch(setGlobalSearchSearchingStatus(namespace, STATUS.PENDING));
    const response = await getGlobalSearchSales(params)();
    dispatch(setFetchGlobalSearchSales(namespace, response.data));
    dispatch(setFetchGlobalSearchSalesResults(namespace, response.data.results));
    dispatch(setFetchGlobalSearchSalesStatus(namespace, STATUS.FULFILLED));
    dispatch(setGlobalSearchSearchingStatus(namespace, STATUS.FULFILLED));
  } catch (error) {
    handleError(error, dispatch);
    dispatch(setFetchGlobalSearchSalesStatus(namespace, STATUS.REJECTED));
    dispatch(setGlobalSearchSearchingStatus(namespace, STATUS.REJECTED));
  }
};

// Search PURCHASES
export const setFetchGlobalSearchPurchasesStatus = (namespace, payload) => (dispatch) => {
  dispatch({
    type: `${namespace}${SET_FETCH_GLOBAL_SEARCH_PURCHASES_STATUS}`,
    payload,
  });
};

export const setFetchGlobalSearchPurchasesResults = (namespace, payload) => (dispatch) => {
  dispatch({
    type: `${namespace}${SET_FETCH_GLOBAL_SEARCH_PURCHASES_RESULTS}`,
    payload,
  });
};

export const setFetchGlobalSearchPurchases = (namespace, payload) => (dispatch) => {
  dispatch({
    type: `${namespace}${SET_FETCH_GLOBAL_SEARCH_PURCHASES}`,
    payload,
  });
};

export const fetchGlobalSearchPurchases = (namespace, params) => async (dispatch) => {
  try {
    dispatch(setFetchGlobalSearchPurchasesStatus(namespace, STATUS.PENDING));
    dispatch(setGlobalSearchSearchingStatus(namespace, STATUS.PENDING));
    const response = await getGlobalSearchPurchases(params)();
    dispatch(setFetchGlobalSearchPurchases(namespace, response.data));
    dispatch(setFetchGlobalSearchPurchasesResults(namespace, response.data.results));
    dispatch(setFetchGlobalSearchPurchasesStatus(namespace, STATUS.FULFILLED));
    dispatch(setGlobalSearchSearchingStatus(namespace, STATUS.FULFILLED));
  } catch (error) {
    handleError(error, dispatch);
    dispatch(setFetchGlobalSearchPurchasesStatus(namespace, STATUS.REJECTED));
    dispatch(setGlobalSearchSearchingStatus(namespace, STATUS.REJECTED));
  }
};

// Search PRODUCTS
export const setFetchGlobalSearchProductsStatus = (namespace, payload) => (dispatch) => {
  dispatch({
    type: `${namespace}${SET_FETCH_GLOBAL_SEARCH_PRODUCTS_STATUS}`,
    payload,
  });
};

export const setFetchGlobalSearchProductsResults = (namespace, payload) => (dispatch) => {
  dispatch({
    type: `${namespace}${SET_FETCH_GLOBAL_SEARCH_PRODUCTS_RESULTS}`,
    payload,
  });
};

export const setFetchGlobalSearchProducts = (namespace, payload) => (dispatch) => {
  dispatch({
    type: `${namespace}${SET_FETCH_GLOBAL_SEARCH_PRODUCTS}`,
    payload,
  });
};

export const fetchGlobalSearchProducts = (namespace, params) => async (dispatch) => {
  try {
    dispatch(setFetchGlobalSearchProductsStatus(namespace, STATUS.PENDING));
    dispatch(setGlobalSearchSearchingStatus(namespace, STATUS.PENDING));
    const response = await getGlobalSearchProducts(params)();
    dispatch(setFetchGlobalSearchProducts(namespace, response.data));
    dispatch(setFetchGlobalSearchProductsResults(namespace, response.data.results));
    dispatch(setFetchGlobalSearchProductsStatus(namespace, STATUS.FULFILLED));
    dispatch(setGlobalSearchSearchingStatus(namespace, STATUS.FULFILLED));
  } catch (error) {
    handleError(error, dispatch);
    dispatch(setFetchGlobalSearchProductsStatus(namespace, STATUS.REJECTED));
    dispatch(setGlobalSearchSearchingStatus(namespace, STATUS.REJECTED));
  }
};

// Generic Fetch Global Search
export const fetchGlobalSearch = (namespace, params) => async (dispatch, getState) => {
  const state = getState();
  const globalSearchType = selectors.getGlobalSearchType(namespace, state);

  if (globalSearchType === types.ALL) {
    dispatch(fetchGlobalSearchAll(namespace, params));
  } else if (globalSearchType === types.CUSTOMER) {
    dispatch(fetchGlobalSearchCustomers(namespace, params));
  } else if (globalSearchType === types.SUPPLIER) {
    dispatch(fetchGlobalSearchSuppliers(namespace, params));
  } else if (globalSearchType === types.SALES) {
    dispatch(fetchGlobalSearchSales(namespace, { ...params, include_deleted: true }));
  } else if (globalSearchType === types.PURCHASES) {
    dispatch(fetchGlobalSearchPurchases(namespace, params));
  } else if (globalSearchType === types.PRODUCT) {
    dispatch(fetchGlobalSearchProducts(namespace, params));
  }
};

// Generic Clear Results
export const clearGlobalSearchResults = (namespace) => async (dispatch, getState) => {
  const state = getState();
  const globalSearchType = selectors.getGlobalSearchType(namespace, state);
  const globalSearchOptionTypes = selectors.getGlobalSearchOptionTypes(namespace, state);

  if (globalSearchType === types.ALL) {
    dispatch(setFetchGlobalSearchAllResults(namespace, []));
  } else if (
    globalSearchType === types.CUSTOMER &&
    globalSearchOptionTypes.includes(types.CUSTOMER)
  ) {
    dispatch(setFetchGlobalSearchCustomersResults(namespace, []));
  } else if (
    globalSearchType === types.SUPPLIER &&
    globalSearchOptionTypes.includes(types.SUPPLIER)
  ) {
    dispatch(setFetchGlobalSearchSuppliersResults(namespace, []));
  } else if (globalSearchType === types.SALES && globalSearchOptionTypes.includes(types.SALES)) {
    dispatch(setFetchGlobalSearchSalesResults(namespace, []));
  } else if (
    globalSearchType === types.PURCHASES &&
    globalSearchOptionTypes.includes(types.PURCHASES)
  ) {
    dispatch(setFetchGlobalSearchPurchasesResults(namespace, []));
  } else if (
    globalSearchType === types.PRODUCT &&
    globalSearchOptionTypes.includes(types.PRODUCT)
  ) {
    dispatch(setFetchGlobalSearchProductsResults(namespace, []));
  }
};

export const reset = (namespace) => (dispatch) => {
  dispatch({ type: `${namespace}${RESET}` });
};
