import qs from "query-string";
import { createApiV2 } from "./common";


export const getOrderTimelineDetails =
  ({ customerUid, orderUid }) =>
  (token) => {
    return createApiV2(
      "GET",
      `customers/${customerUid}/orders/${orderUid}/timelines/details/`
    )(token);
  };

export const getOrderTimelines =
  ({ customerUid, orderUid, search, eventTypes }) =>
  (token) => {
    return createApiV2(
      "GET",
      `customers/${customerUid}/orders/${orderUid}/timelines/?${qs.stringify(
        {
          search,
          event_types: eventTypes,
        },
        { arrayFormat: "repeat" }
      )}`
    )(token);
  };