import {
  SET_IS_SAVING,
  SET_IS_SUCCESS,
  SET_EMAIL_FROM_TOKEN,
  SET_IS_VALIDATING_TOKEN,
  SET_IS_TOKEN_VALID,
  RESET,
} from "./forgotPassword.actionTypes";

const initialState = {
  isSaving: false,
  isSuccess: false,
  emailFromToken: "",
  isValidatingToken: false,
  isTokenValid: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_SAVING: {
      return {
        ...state,
        isSaving: action.payload,
      };
    }

    case SET_IS_SUCCESS: {
      return {
        ...state,
        isSuccess: action.payload,
      };
    }

    case SET_IS_VALIDATING_TOKEN: {
      return {
        ...state,
        isValidatingToken: action.payload,
      };
    }

    case SET_IS_TOKEN_VALID: {
      return {
        ...state,
        isTokenValid: action.payload,
      };
    }

    case SET_EMAIL_FROM_TOKEN: {
      return {
        ...state,
        emailFromToken: action.payload,
      };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
