import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import styles from "./BannerHeader.module.scss";

const BannerHeader = ({ title, leftContent, rightContent, dark, underline, className }) => (
  <div
    className={cx(
      styles.bannerHeader,
      {
        [styles.bannerHeaderDark]: dark,
        [styles.bannerHeaderUnderline]: underline,
      },
      className
    )}
  >
    <div className={styles.bannerHeaderTitle}>{title}</div>
    <div className={styles.bannerHeaderContent}>
      <div className={styles.bannerHeaderContentLeft}>{leftContent}</div>
      <div className={styles.bannerHeaderContentRight}>{rightContent}</div>
    </div>
  </div>
);

BannerHeader.defaultProps = {
  title: null,
  leftContent: null,
  rightContent: null,
  dark: false,
  underline: false,
  className: null,
};

BannerHeader.propTypes = {
  title: PropTypes.string,
  leftContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  rightContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  dark: PropTypes.bool,
  underline: PropTypes.bool,
  className: PropTypes.string,
};

export default BannerHeader;
