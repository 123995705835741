import createActionType from "../utils/createActionType";

export const MODULE = "orderTimeline";

export const SET_SEARCH = createActionType(MODULE, "setSearch");
export const SET_FILTERS = createActionType(MODULE, "setFilters");


export const SET_IS_FETCHING_TIMELINES = createActionType(
  MODULE,
  "setIsFetchingTimelines"
);

export const SET_IS_FETCHING_TIMELINE_DETAILS = createActionType(
  MODULE,
  "setIsFetchingTimelineDetails"
);

export const SET_TIMELINES = createActionType(MODULE, "setTimelines");
export const SET_TIMELINE_DETAILS = createActionType(MODULE, "setTimelineDetails");


export const RESET = createActionType(MODULE, "reset");
