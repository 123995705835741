import { MODULE } from "./importCustomers.actionTypes";

export const getFetchingLatestCustomersStatus = (state) =>
  state.getIn([MODULE, "fetchingLatestCustomersStatus"]);
export const getLatestCustomers = (state) => state.getIn([MODULE, "latestCustomers"]);
export const getSearchLatestCustomers = (state) => state.getIn([MODULE, "searchLatestCustomers"]);
export const getIsLinkedCustomers = (state) => state.getIn([MODULE, "isLinkedCustomers"]);
export const getIsPreviouslyImportedCustomers = (state) =>
  state.getIn([MODULE, "isPreviouslyImportedCustomers"]);
export const getCustomersToImport = (state) => state.getIn([MODULE, "customersToImport"]);
export const getDuplicateCustomersToImport = (state) =>
  state.getIn([MODULE, "duplicateCustomersToImport"]);
export const getDuplicateCustomersWarningModal = (state) =>
  state.getIn([MODULE, "duplicateCustomersWarningModal"]);
export const getImportCustomersStatus = (state) => state.getIn([MODULE, "importCustomersStatus"]);

export const getImportCustomers = (state) => state.getIn([MODULE, "importCustomers"]);
export const getImportCustomersSort = (state) => state.getIn([MODULE, "sort"]);
export const getImportCustomersPage = (state) => state.getIn([MODULE, "page"]);
export const getImportCustomersMeta = (state) => state.getIn([MODULE, "meta"]);
export const getIsLinkedCustomersFilter = (state) =>
  state.getIn([MODULE, "isLinkedCustomersFilter"]);
export const getImportAllCustomersStatus = (state) =>
  state.getIn([MODULE, "importAllCustomersStatus"]);
export const getImportAllCustomers = (state) => state.getIn([MODULE, "importAllCustomers"]);
