import { createAction } from "redux-actions";

import * as authenticationActions from "../authentication/authentication.actions";

export const tokenInject = (fn) => (dispatch, getState) => {
  const localStorageAuth = window.localStorage.getItem("auth");
  return fn(dispatch, getState, JSON.parse(localStorageAuth));
};

/*eslint-disable */
const thunkAction = (actionName, actionMethod, emitPending = false, autoPending = true) => {
  const fulfilledAction = createAction(`${actionName} fulfilled`);
  const rejectedAction = createAction(`${actionName} rejected`);
  let pendingAction;
  if (emitPending) {
    if (typeof emitPending !== "function") {
      pendingAction = createAction(`${actionName} pending`, (payload) => payload);
    } else {
      pendingAction = createAction(`${actionName} pending`, emitPending);
    }
  }

  return (...args) =>
    tokenInject((dispatch, getState, token) => {
      const fulfilled = (...fArgs) => {
        dispatch(fulfilledAction(...fArgs));
        emitPending &&
          autoPending &&
          dispatch(pendingAction({ isPending: false, success: true }, ...args));
      };

      const rejected = (errors) => {
        let errorsMessage;
        if (errors) {
          if (errors.response) {
            errorsMessage = errors.response.data.detail;

            if (errors.response.status === 401) {
              dispatch(authenticationActions.logout());
            }
          } else {
            console.error(errors);
          }
        }

        dispatch(rejectedAction(errorsMessage));
        emitPending &&
          autoPending &&
          dispatch(pendingAction({ isPending: false, success: false }, errors));
      };

      emitPending &&
        autoPending &&
        dispatch(pendingAction({ isPending: true, success: false }, ...args));

      const result = actionMethod(
        {
          dispatch,
          getState,
          token,
          pendingAction,
          fulfilled,
          rejected,
        },
        ...args
      );
      if (result instanceof Promise) {
        result.catch(rejected);
      }
      return result;
    });
};

/* eslint-enable */

export default thunkAction;
