import { createApiV2 } from "../common";

export const getOrders = (filters) => (token) =>
  createApiV2("POST", "orders/search")(token, filters);

export const getOrder =
  ({ orderUid, customerUid }) =>
  () =>
    createApiV2("GET", `customers/${customerUid}/orders/${orderUid}/`)();

export default {};
