import { MODULE } from "./orderChecklists.actionTypes";

export const isAddChecklistFormVisible = (state) =>
  state.getIn([MODULE, "isAddChecklistFormVisible"]);

export const isAddSubItemFormVisible = (state) => state.getIn([MODULE, "isAddSubItemFormVisible"]);

export const getParentItemToDisplayTheForm = (state) =>
  state.getIn([MODULE, "parentItemToDisplayTheForm"]);

export const isFetchingChecklists = (state) => state.getIn([MODULE, "isFetchingChecklists"]);

export const getChecklists = (state) => state.getIn([MODULE, "checklists"]);

export const getCloseChecklistUids = (state) => state.getIn([MODULE, "closeChecklistUids"]);

export const getCountPerChecklist = (state, uid) => {
  const checklist = getChecklists(state).find((i) => i.uid === uid);
  const formattedChecklist = { ...checklist, subitems: checklist.items };

  const flattenSubItems = (arr) =>
    arr.flatMap(({ subitems, ...rest }) => [].concat(rest, flattenSubItems(subitems)));

  return {
    total: flattenSubItems(formattedChecklist.subitems).length,
    completed: flattenSubItems(formattedChecklist.subitems).filter((i) => i.completedAt !== null)
      .length,
  };
};

export const getCountAllChecklist = (state) => {
  const checklists = getChecklists(state).map((i) => ({ ...i, subitems: i.items }));

  // const flattenSubItems = (arr) =>
  //   arr.reduce(
  //     (r, { subitems = [] }) =>
  //       subitems.length > 0 ? r + subitems.length + flattenSubItems(subitems) : r,
  //     0
  //   );

  const flattenSubItems = (arr) =>
    arr.flatMap(({ subitems }) => [].concat(subitems, flattenSubItems(subitems)));

  return {
    total: flattenSubItems(checklists).length,
    completed: flattenSubItems(checklists).filter((i) => i.completedAt !== null).length,
  };
};

export const getChecklistItemUploading = (state) => state.getIn([MODULE, "checklistItemUploading"]);

export const getAttachments = (state) => state.getIn([MODULE, "attachments"]);
