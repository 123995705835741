import { SET_IS_FETCHING_ORDERS, SET_ORDERS, RESET } from "./orders.actionTypes";

const initialState = {
  isFetchingOrders: false,
  orders: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_FETCHING_ORDERS: {
      return {
        ...state,
        isFetchingOrders: action.payload,
      };
    }

    case SET_ORDERS: {
      return {
        ...state,
        orders: action.payload,
      };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
