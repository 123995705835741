import { handleError } from "../utils/error";
import * as api from "../../apiv2/priceLevels";

import * as selectors from "./priceLevels.selectors";
import {
  SET_IS_FETCHING_PRICE_LEVELS,
  SET_IS_FETCHING_CUSTOMERS,
  SET_IS_DELETE_PRICE_LEVEL_MODAL_OPEN,
  SET_RECENTLY_DELETED_PRICE_LEVEL_UID,
  SET_PRICE_LEVELS,
  SET_CUSTOMERS,
  SET_SEARCH,
  RESET,
} from "./priceLevels.actionTypes";

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setIsFetchingPriceLevels = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_PRICE_LEVELS,
    payload: status,
  });
};

export const setPriceLevels = (data) => (dispatch) => {
  dispatch({
    type: SET_PRICE_LEVELS,
    payload: data,
  });
};

export const fetchPriceLevels = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const search = selectors.getSearch(state);
    dispatch(setIsFetchingPriceLevels(true));
    const response = await api.getPriceLevels({ search })();
    const priceLevels = response.data.map((priceLevel, index) => ({
      ...priceLevel,
      index,
    }));
    dispatch(setPriceLevels(priceLevels));
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetchingPriceLevels(false));
  }
};

export const setIsFetchingCustomers = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_CUSTOMERS,
    payload: status,
  });
};

export const setCustomers = (data) => (dispatch) => {
  dispatch({
    type: SET_CUSTOMERS,
    payload: data,
  });
};

export const fetchCustomers = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const search = selectors.getSearch(state);
    dispatch(setIsFetchingCustomers(true));
    const response = await api.getCustomers({ search })();
    dispatch(setCustomers(response.data));
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetchingCustomers(false));
  }
};

export const setIsDeletePriceLevelModalOpen =
  ({ status, priceLevelUid }) =>
  (dispatch) => {
    dispatch({
      type: SET_IS_DELETE_PRICE_LEVEL_MODAL_OPEN,
      payload: {
        status,
        priceLevelUid,
      },
    });
  };

export const createPriceLevel = (data) => async (dispatch, getState) => {
  try {
    const state = getState();
    const priceLevels = selectors.getPriceLevels(state);
    const response = await api.createPriceLevel({
      name: data,
      colour: "#000000",
      index: priceLevels.length,
    })();
    dispatch(setPriceLevels([...priceLevels, { ...response.data }]));
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const updatePriceLevelIndex =
  ({ priceLevelUid, endIndex }) =>
  (dispatch, getState) => {
    try {
      const state = getState();
      const priceLevels = selectors.getPriceLevels(state);
      const movedPricedLevel = priceLevels.find((priceLevel) => priceLevelUid === priceLevel.uid);
      const filteredPriceLevels = priceLevels.filter(
        (priceLevel) => priceLevelUid !== priceLevel.uid
      );
      const filteredAndMovedPriceLevels = [
        ...filteredPriceLevels.slice(0, endIndex),
        movedPricedLevel,
        ...filteredPriceLevels.slice(endIndex),
      ];
      const newPriceLevels = filteredAndMovedPriceLevels.map((priceLevel, index) => ({
        ...priceLevel,
        index,
      }));
      const newPriceLevelOrder = filteredAndMovedPriceLevels.map((priceLevel, index) => ({
        uid: priceLevel.uid,
        index,
      }));
      api.updatePriceLevelIndex({ data: { priceLevels: newPriceLevelOrder } })();
      dispatch(setPriceLevels(newPriceLevels));
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const updatePriceLevel =
  ({ priceLevelUid, data }) =>
  (dispatch, getState) => {
    try {
      api.updatePriceLevel({ priceLevelUid, data })();
      const state = getState();
      const priceLevels = selectors.getPriceLevels(state);
      const newPriceLevels = priceLevels.map((priceLevel) => {
        if (priceLevel.uid === priceLevelUid) {
          const newPriceLevel = {
            ...priceLevel,
            ...data,
          };
          return newPriceLevel;
        }

        return priceLevel;
      });
      dispatch(setPriceLevels(newPriceLevels));
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const setRecentlyDeletedPriceLevelUid = (data) => (dispatch) => {
  dispatch({
    type: SET_RECENTLY_DELETED_PRICE_LEVEL_UID,
    payload: data,
  });
};

export const deletePriceLevel = (priceLevelUid) => (dispatch, getState) => {
  const state = getState();
  const priceLevels = selectors.getPriceLevels(state);
  const customers = selectors.getCustomers(state);
  const currentPriceLevelCustomers = priceLevels.find(
    (priceLevel) => priceLevel.uid === priceLevelUid
  ).customers;

  try {
    api.deletePriceLevel(priceLevelUid)();
    dispatch(setPriceLevels(priceLevels.filter((priceLevel) => priceLevel.uid !== priceLevelUid)));

    if (currentPriceLevelCustomers.length > 0) {
      dispatch(setCustomers([...customers, ...currentPriceLevelCustomers]));
    }

    dispatch(setRecentlyDeletedPriceLevelUid(priceLevelUid));
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const undoDeletePriceLevel = (priceLevelUid) => async (dispatch) => {
  try {
    await api.undoDeletePriceLevel({ uid: priceLevelUid })();

    dispatch(fetchPriceLevels());
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const assignCustomerToPriceLevel =
  ({ sourcePriceLevelUid, priceLevelUid, customer }) =>
  (dispatch, getState) => {
    try {
      api.postAssignCustomersToPriceLevel({
        priceLevelUid,
        data: {
          customers: [customer.uid],
        },
      })();

      const state = getState();
      const priceLevels = selectors.getPriceLevels(state);
      const newPriceLevels = priceLevels.map((priceLevel) => {
        if (priceLevel.uid === priceLevelUid) {
          const newCustomers = [...priceLevel.customers, customer];
          const newPriceLevel = {
            ...priceLevel,
            customers: newCustomers,
          };
          return newPriceLevel;
        }

        if (priceLevel.uid === sourcePriceLevelUid) {
          const newCustomers = [...priceLevel.customers.filter((c) => c.uid !== customer.uid)];
          const newPriceLevel = {
            ...priceLevel,
            customers: newCustomers,
          };
          return newPriceLevel;
        }

        return priceLevel;
      });
      dispatch(setPriceLevels(newPriceLevels));

      if (sourcePriceLevelUid === "Unassigned") {
        const customers = selectors.getCustomers(state);
        dispatch(setCustomers(customers.filter((c) => c.uid !== customer.uid)));
      }
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const assignCustomerToUnassigned =
  ({ customerUid, sourcePriceLevelUid }) =>
  (dispatch, getState) => {
    try {
      api.postAssignCustomersToUnassigned({
        customers: [customerUid],
      })();

      const state = getState();
      const priceLevels = selectors.getPriceLevels(state);
      const newPriceLevels = priceLevels.map((priceLevel) => {
        if (priceLevel.uid === sourcePriceLevelUid) {
          const newCustomers = [
            ...priceLevel.customers.filter((customer) => customer.uid !== customerUid),
          ];
          const newPriceLevel = {
            ...priceLevel,
            customers: newCustomers,
          };
          return newPriceLevel;
        }

        return priceLevel;
      });
      dispatch(setPriceLevels(newPriceLevels));

      const customers = selectors.getCustomers(state);
      const sourcePriceLevel = priceLevels.find(
        (priceLevel) => priceLevel.uid === sourcePriceLevelUid
      );
      const newCustomer = sourcePriceLevel.customers.find(
        (customer) => customer.uid === customerUid
      );
      dispatch(setCustomers([...customers, newCustomer]));
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const bulkAssignCustomersToPriceLevel =
  ({ priceLevelUid, sourcePriceLevelUid, data }) =>
  async (dispatch, getState) => {
    const customerUids = data.map((customer) => customer.uid);

    try {
      await api.postAssignCustomersToPriceLevel({
        priceLevelUid,
        data: {
          customers: customerUids,
        },
      })();

      const state = getState();
      const priceLevels = selectors.getPriceLevels(state);
      const newPriceLevels = priceLevels.map((priceLevel) => {
        if (priceLevel.uid === priceLevelUid) {
          const newCustomers = [...priceLevel.customers, ...data];
          const newPriceLevel = {
            ...priceLevel,
            customers: newCustomers,
          };
          return newPriceLevel;
        }

        if (priceLevel.uid === sourcePriceLevelUid) {
          const newPriceLevel = {
            ...priceLevel,
            customers: [],
          };
          return newPriceLevel;
        }

        return priceLevel;
      });
      dispatch(setPriceLevels(newPriceLevels));

      if (sourcePriceLevelUid === "Unassigned") {
        dispatch(setCustomers([]));
      }
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const bulkAssignCustomersToUnassigned =
  ({ sourcePriceLevelUid, data }) =>
  async (dispatch, getState) => {
    const customerUids = data.map((customer) => customer.uid);

    try {
      await api.postAssignCustomersToUnassigned({
        customers: customerUids,
      })();

      const state = getState();
      const priceLevels = selectors.getPriceLevels(state);
      const newPriceLevels = priceLevels.map((priceLevel) => {
        if (priceLevel.uid === sourcePriceLevelUid) {
          const newPriceLevel = {
            ...priceLevel,
            customers: [],
          };
          return newPriceLevel;
        }

        return priceLevel;
      });
      dispatch(setPriceLevels(newPriceLevels));

      const customers = selectors.getCustomers(state);
      dispatch(setCustomers([...customers, ...data]));
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const bulkAssignCustomers =
  ({ sourcePriceLevelUid, priceLevelUid, data }) =>
  async (dispatch) => {
    if (priceLevelUid === "Unassigned") {
      await dispatch(bulkAssignCustomersToUnassigned({ sourcePriceLevelUid, data }));
    } else {
      await dispatch(
        bulkAssignCustomersToPriceLevel({
          priceLevelUid,
          sourcePriceLevelUid,
          data,
        })
      );
    }
  };

export const setSearch = (data) => (dispatch) => {
  dispatch({
    type: SET_SEARCH,
    payload: data,
  });
};
