import React from "react";

import App from "./app";
import Widgets from "./widgets";

const Root = () => (
  <>
    <Widgets />
    <App />
  </>
);

export default Root;
