import qs from "query-string";

import { createApiV2 } from "./common";

export const getCategories = () => (token) =>
  createApiV2("GET", "additional_products_categories/")(token);

export const postCategory = (data) => (token) =>
  createApiV2("POST", "additional_products_categories/")(token, data);

export const updateCategory = (data) => (token) =>
  createApiV2("POST", "additional_products_categories/")(token, data);

export const getProductsByCategory = (data) => (token) =>
  createApiV2("GET", `additional_products/?${qs.stringify(data)}`)(token);

export const getProductsByFlashings = () => (token) =>
  createApiV2("GET", "flashing_products/")(token);

export const postBulkArchiveCategories = (data) => (token) =>
  createApiV2("POST", `additional_products_categories/bulk_destroy/`)(token, data);

export const postBulkUnarchiveCategories = (data) => (token) =>
  createApiV2("POST", `additional_products_categories/bulk_undestroy/`)(token, data);

export const bulkArchiveProducts = (data) => (token) =>
  createApiV2("POST", `additional_products/bulk_destroy/`)(token, data);

export const bulkUnarchiveProducts = (data) => (token) =>
  createApiV2("POST", `additional_products/bulk_undestroy/`)(token, data);

export const postBulkAssignProductsToCategory = (data) => (token) =>
  createApiV2("POST", `additional_products_categories/assign/`)(token, data);

export const postDuplicateProduct = (data) => (token) =>
  createApiV2("POST", `additional_products/bulk_duplicate/`)(token, data);

export const getMultipleProducts = (data) => (token) =>
  createApiV2("POST", `additional_products/retrieve_multiple/`)(token, data);
