import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { InputGroup, InputGroupAddon, InputGroupText, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSliders } from "@fortawesome/pro-duotone-svg-icons";

import { Loading } from "../Loading";

import styles from "./Search.module.scss";

const Search = ({
  onChange,
  placeholder,
  defaultValue = "",
  className = "",
  inputGroupClassName = "",
  size,
  inputRef,
  getRealtimeValue,
  debounceUpdate,
  onClickFilter,
  loading,
  appendFilter,
  ...rest
}) => {
  const [_active, setActive] = useState(false);

  const handleFocus = () => {
    setActive(true);
  };

  const handleBlur = () => {
    setActive(false);
  };

  const handleChange = (value) => onChange(value);

  return (
    <div
      className={cx("search-wrapper", className, {
        active: _active,
        [`search-wrapper-${size}`]: size,
      })}
    >
      <InputGroup className={cx("static-group-text", inputGroupClassName)} size="lg">
        <InputGroupAddon className="d-flex align-items-center" addonType="prepend">
          <InputGroupText>
            {loading === true ? (
              <Loading className={styles.loading} spinnerClassName={styles.loadingSpinner} />
            ) : (
              <FontAwesomeIcon size="lg" icon={faSearch} />
            )}
          </InputGroupText>
        </InputGroupAddon>

        <Input
          className={cx(styles.search, {
            [styles.inputAppendFilterPadding]: !!appendFilter,
          })}
          innerRef={inputRef}
          placeholder={placeholder}
          onFocus={handleFocus}
          onBlur={handleBlur}
          defaultValue={defaultValue}
          onChange={({ target: { value } }) => {
            if (typeof getRealtimeValue === "function") {
              getRealtimeValue(value);
            }

            handleChange(value);
          }}
          {...rest}
        />
        {appendFilter && (
          <InputGroupAddon
            className={cx("d-flex", "align-items-center", styles.appendFilter)}
            addonType="append"
            onClick={onClickFilter}
          >
            <FontAwesomeIcon size="lg" icon={faSliders} />
          </InputGroupAddon>
        )}
      </InputGroup>
    </div>
  );
};

Search.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,

  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  defaultValue: PropTypes.string,
  size: PropTypes.string,
  debounceUpdate: PropTypes.number,
  inputGroupClassName: PropTypes.string,
  className: PropTypes.string,
  onClickFilter: PropTypes.func,
  loading: PropTypes.bool,
  appendFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

Search.defaultProps = {
  defaultValue: "",
  size: "",
  debounceUpdate: 500,
  inputGroupClassName: "",
  inputRef: null,
  className: "",
  onClickFilter: () => null,
  loading: false,
  appendFilter: null,
};

export default Search;
