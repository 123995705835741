import {
  SET_IS_USERS_DROPDOWN_VISIBLE,
  SET_IS_FETCHING_USERS,
  SET_USERS,
  SET_SELECTED_USERS,
  SET_UNSELECTED_USERS,
  SET_ACTIVE_USER,
  RESET,
} from "./users.actionTypes";

const initialState = {
  isUsersDropdownVisible: false,
  isFetchingUsers: false,
  users: [],
  selectedUsers: [],
  unSelectedUsers: [],
  activeUser: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_USERS_DROPDOWN_VISIBLE: {
      return {
        ...state,
        isUsersDropdownVisible: action.payload,
      };
    }

    case SET_IS_FETCHING_USERS: {
      return {
        ...state,
        isFetchingUsers: action.payload,
      };
    }

    case SET_USERS: {
      return {
        ...state,
        users: action.payload,
      };
    }

    case SET_SELECTED_USERS: {
      return {
        ...state,
        selectedUsers: action.payload,
      };
    }

    case SET_UNSELECTED_USERS: {
      return {
        ...state,
        unSelectedUsers: action.payload,
      };
    }

    case SET_ACTIVE_USER: {
      return {
        ...state,
        activeUser: action.payload,
      };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
