import { MODULE } from "./supplierFilter.actionTypes";

export const getSuppliers = (state) => {
  return state.getIn([MODULE, "suppliers"]);
};

export const getSelectedSuppliers = (state) => {
  return state.getIn([MODULE, "selectedSuppliers"]);
};

export const getSelectedSuppliersUids = (state) => {
  return state
    .getIn([MODULE, "selectedSuppliers"])
    .map((supplier) => supplier.uid);
};
