import React from "react";
import PropTypes from "prop-types";
import ReactSelect, { createFilter, components } from "react-select";
import AsyncSelect from "react-select/async";
import cx from "classnames";
import { flatMap } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import cssStyles from "./Select.module.scss";
import styles from "./select.styles";

const Select = ({
  hasGroup,
  name,
  options,
  optionsKey,
  optionsLabel,
  customStyles,
  selectedDefault,
  isLoading,
  loadingClassName,
  onChange,
  async,
  loadOptions,
  arrowIcon,
  ...rest
}) => {
  const DropdownIndicator = (props) => {
    if (arrowIcon) {
      return (
        <components.DropdownIndicator {...props}>
          <FontAwesomeIcon icon={arrowIcon} className={cssStyles.arrowIcon} />
        </components.DropdownIndicator>
      );
    }

    return <components.DropdownIndicator {...props} />;
  };

  const Option = ({ children, ..._props }) => (
    <components.Option {..._props}>
      {children}
      {_props.data.description && (
        <span className={cssStyles.description}>{_props.data.description}</span>
      )}
    </components.Option>
  );

  const GroupHeading = (props) => (
    <div>
      <components.GroupHeading {...props} />
    </div>
  );

  const flattenOptions = (opts) => {
    return opts.flatMap((item) => (item.options ? [item, ...flattenOptions(item.options)] : item));
  };

  const value =
    flattenOptions(options)
      .filter((option) => option[optionsKey] === selectedDefault)
      .map((option) => ({
        value: option[optionsKey],
        label: option[optionsLabel],
      }))[0] || null;

  if (isLoading)
    return <div className={cx(cssStyles.loadingWrapper, loadingClassName)}>Loading...</div>;

  if (async) {
    return (
      <AsyncSelect
        defaultOptions
        cacheOptions
        styles={{ ...styles, ...customStyles }}
        value={selectedDefault}
        loadOptions={loadOptions}
        onChange={onChange}
      />
    );
  }

  return (
    <ReactSelect
      captureMenuScroll={false}
      key={`select-key-${name}-${selectedDefault}`}
      styles={{ ...styles, ...customStyles }}
      filterOption={createFilter({ ignoreAccents: false })}
      options={
        hasGroup
          ? options.map((option) => ({
              label: option.label,
              options: option.options.map((option) => ({
                value: option[optionsKey],
                label: option[optionsLabel],
                description: option?.description ?? "",
              })),
            }))
          : options.map((option) => ({
              value: option[optionsKey],
              label: option[optionsLabel],
              description: option?.description ?? "",
            }))
      }
      components={{ DropdownIndicator, Option, GroupHeading }}
      defaultValue={value}
      onChange={(newValue) =>
        onChange(flattenOptions(options).find((option) => option[optionsKey] === newValue.value))
      }
      isLoading={isLoading}
      placeholder={isLoading ? "Loading..." : "Select..."}
      {...rest}
    />
  );
};

Select.defaultProps = {
  hasGroup: false,
  optionsKey: "value",
  optionsLabel: "label",
  options: [],
  selectedDefault: null,
  customStyles: {},
  isLoading: false,
  loadingClassName: "",
  onChange: () => null,
};

Select.propTypes = {
  hasGroup: PropTypes.bool,
  name: PropTypes.string.isRequired,
  optionsKey: PropTypes.string,
  optionsLabel: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
  selectedDefault: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({}), PropTypes.number]),
  customStyles: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  loadingClassName: PropTypes.string,
  onChange: PropTypes.func,
};

export default Select;
