import createActionType from "@/redux/utils/createActionType";

export const MODULE = "collaboration";

export const SET_SOCKET_INSTANCE = createActionType(MODULE, "setSocketInstance");
export const SET_REFERENCE = createActionType(MODULE, "setReference");
export const SET_USER_ID = createActionType(MODULE, "setUserId");
export const SET_ALL_MESSAGES = createActionType(MODULE, "setAllMessages");
export const SET_ATTACHED_FILES_EDITOR = createActionType(MODULE, "setAttachedFilesEditor");
export const SET_ALL_NOTIFICATIONS = createActionType(MODULE, "setAllNotifications");
export const SET_ALL_COMPANY_USERS = createActionType(MODULE, "setAllCompanyUsers");
export const SET_TO_EDIT_MESSAGE = createActionType(MODULE, "setToEditMessage");
export const SET_IS_UPLOAD_FILE = createActionType(MODULE, "setIsUploadFile");
export const SET_IS_PENDING = createActionType(MODULE, "setIsPending");
export const SET_ATTACHMENT_FILES = createActionType(MODULE, "setAttchmentFiles");

export const RESET = createActionType(MODULE, "reset");
