import { fromJS } from "immutable";
import camelcaseKeys from "camelcase-keys";
import moment from "moment-timezone";

import * as date from "@/utils/date";

import * as collaborationNotesPurchasesApi from "../../api/purchase";
import { createPurchaseOrderAction } from "../../redux/purchaseOrders/purchaseOrders.actions";

import ReduxModule from "./abstract/ReduxModule";
import SocketsModule from "./websockets";
import alerts from "./alerts";

// Actions

const ACTIONS = {
  GET_ALL_MESSAGES: "Get all",
  CREATE_MESSAGE: "Create",
  EDIT_MESSAGE: "Edit",
  EDIT_ORDER_MESSAGE: "Edit order message",
  DELETE_MESSAGE: "Delete",
  DELETE_FILE_MESSAGE: "Delete file",
  DELETE_FILE_EDITING_MESSAGE: "Delete editing message file",
  SEND_NEW_MESSAGE_WITH_FILES: "Send new message with attached files",
  SEND_MESSAGE_WITH_FILES: "Send message with attached files",

  ATTACH_FILES_EDITOR: "Attached file to editor",
  UPLOAD_FILE_MESSAGE: "Upload file message",

  GET_ALL_COMPANY_USER: "Get all users",
  GET_ALL_NOTIFICATIONS: "Get all notifications",
  GET_ORDER_CHAT_BY_NOTIFICATION: "Redirect to order chat",
  READ_NOTIFICATION_MESSAGE: "Reading notifications",

  MARK_ALL_NOTIFICATION_AS_READ: "Mark all as read",
  RECEIVING_NOTIFICATION: "Notification",
  DELETE_NOTIFICATION: "Delete notification",

  CLEAR_ALL_MESSAGE: "Clear active order message",
  CLEAR_ALL_ORDER_MESSAGE_FILE: "Clear active order message file",
  CLEAR_EDIT_MESSAGE: "Clear edit message",

  GET_MESSAGE_FILES: "Get message attached files",
  REMOVE_ATTACHED_FILE_MESSAGE: "Remove and undo remove attached file message",
};

class PurchaseModule extends ReduxModule {
  getNamespace() {
    return "[Purchase]";
  }

  getInitialState() {
    return {
      dataServer: {
        isPending: false,
        items: [],
        editMessage: null,
      },
      attachedFilesEditor: null,
      attachedFilesMessages: {},
      allCompanyUsers: [],
      allChatsNotifications: [],
      isUploadFile: false,
      transitionByNotification: false,
      errors: {},
    };
  }

  getAllMessagesThunk = ({ fulfilled }, messages) => {
    const data = messages
      .map((item) =>
        camelcaseKeys(
          {
            ...item,
            created_timestamp_unix: item.created_timestamp
              ? moment(item.created_timestamp).tz(date.getCurrentUserTimezone()).unix()
              : null,
          },
          { deep: true }
        )
      )
      .sort((a, b) => a.createdTimestampUnix - b.createdTimestampUnix);
    fulfilled(fromJS(data));
    return data;
  };

  createMessagesThunk = ({ fulfilled, getState }, newMessage) => {
    const state = getState();
    const allMessage = state.getIn(["purchase", "dataServer", "items"]);
    const message = camelcaseKeys(newMessage, { deep: true });
    const newMessageList = [
      ...allMessage,
      fromJS({
        ...message,
        createdTimestampUnix: message.createdTimestamp
          ? moment(message.createdTimestamp).tz(date.getCurrentUserTimezone()).unix()
          : null,
      }),
    ];
    fulfilled(newMessageList.sort((a, b) => a.createdTimestampUnix - b.createdTimestampUnix));
  };

  editMessagesThunk = ({ getState, fulfilled }, id) => {
    const state = getState();
    const allMessage = state.getIn(["purchase", "dataServer", "items"]);
    const allCompanyUser = state.getIn(["purchase", "allCompanyUsers"]);
    const usersList = new Map();
    allCompanyUser.map((item) => usersList.set(item.getIn(["id"]), item));
    let editMessage = allMessage.filter((item) => item.getIn(["id"]) === id) || null;

    if (editMessage.size) {
      const mentionedUsersId = editMessage.getIn([0, "userId"]);

      if (mentionedUsersId.size) {
        const mentionedUsersFullData = mentionedUsersId.map((item) =>
          usersList.get(item.toString())
        );
        editMessage = editMessage.setIn([0, "users"], mentionedUsersFullData);
      } else {
        editMessage = editMessage.setIn([0, "users"], []);
      }
    }

    return fulfilled(...editMessage);
  };

  sendNewMessageWithAttachedFilesThunk = async (
    { getState, dispatch },
    { messageFiles, message, mentionUserId, customerName = "", isSignature = false }
  ) => {
    const activePurchaseOrderUid = getState().getIn([
      "purchaseOrders",
      "activePurchaseOrder",
      "uid",
    ]);

    if (!activePurchaseOrderUid) {
      const activePurchaseOrder = getState().getIn(["purchaseOrders", "activePurchaseOrder"]);

      // Construct the post object
      const postPurchaseOrder = {
        supplier: activePurchaseOrder.supplier,
        contact: activePurchaseOrder.contact,
        editStatus: activePurchaseOrder.editStatus.id,
        requiredData: activePurchaseOrder.requiredDate,
        notes: activePurchaseOrder.notes,
        pickupNotes: activePurchaseOrder.pickupNotes,
        receptionMethod: activePurchaseOrder.receptionMethod,
        reference: activePurchaseOrder.reference,
        items: activePurchaseOrder.items,
        itemTotal: activePurchaseOrder.itemTotal,
        deliveryFee: activePurchaseOrder.deliveryFee,
        gstAmount: activePurchaseOrder.gstAmount,
        total: activePurchaseOrder.total,
        discountRates: activePurchaseOrder.discountRates,
        billingAddress1: activePurchaseOrder.billingAddress1,
        billingAddress2: activePurchaseOrder.billingAddress2,
        billingSuburb: activePurchaseOrder.billingSuburb,
        billingState: activePurchaseOrder.billingState,
        billingPostcode: activePurchaseOrder.billingPostcode,
        deliveryAddress1: activePurchaseOrder.deliveryAddress1,
        deliveryAddress2: activePurchaseOrder.deliveryAddress2,
        deliverySuburb: activePurchaseOrder.deliverySuburb,
        deliveryState: activePurchaseOrder.deliveryState,
        deliveryPostcode: activePurchaseOrder.deliveryPostcode,
        oneTimeContactName: activePurchaseOrder.oneTimeContactName,
        oneTimeContactEmail: activePurchaseOrder.oneTimeContactEmail,
        oneTimeContactPhoneNumber: activePurchaseOrder.oneTimeContactPhoneNumber,
        oneTimeContactMobileNumber: activePurchaseOrder.oneTimeContactMobileNumber,
      };
      const data = await dispatch(createPurchaseOrderAction(postPurchaseOrder));
      const activePurchaseOrderUid = data.uid;

      if (activePurchaseOrderUid) {
        dispatch(
          this.actions.sendMessageWithFiles({
            messageFiles,
            message,
            mentionUserId,
            activePurchaseOrderUid,
            customerName,
            isSignature,
          })
        ).then(() => {
          dispatch(SocketsModule.actions.purchaseOrderGetAllMessage());
        });
      }
    } else {
      dispatch(
        this.actions.sendMessageWithFiles({
          messageFiles,
          message,
          mentionUserId,
          customerName,
          isSignature,
        })
      ).then(() => {
        dispatch(SocketsModule.actions.purchaseOrderGetAllMessage());
      });
    }
  };

  sendMessageWithFilesThunk = (
    { getState, dispatch, token },
    { messageFiles, message, mentionUserId, editId, purchaseOrderUid, customerName, isSignature }
  ) => {
    const activePurchaseOrderUid = getState().getIn([
      "purchaseOrders",
      "activePurchaseOrder",
      "uid",
    ]);
    const data = new FormData();

    if (editId) {
      data.set(
        "json",
        JSON.stringify(
          mentionUserId
            ? {
                purchase_order_uid: activePurchaseOrderUid,
                message,
                message_id: editId,
                user_id: mentionUserId,
              }
            : {
                purchase_order_uid: activePurchaseOrderUid,
                message,
                message_id: editId,
              }
        )
      );
    } else {
      data.set(
        "json",
        JSON.stringify(
          mentionUserId
            ? {
                purchase_order_uid: activePurchaseOrderUid,
                message,
                user_id: mentionUserId,

                customer_name: customerName,
                is_signature: `${isSignature}`,
              }
            : {
                purchase_order_uid: activePurchaseOrderUid,
                message,
                customer_name: customerName,
                is_signature: `${isSignature}`,
              }
        )
      );
    }

    Object.keys(messageFiles).forEach((key) => {
      const file = messageFiles[key];
      data.append("files", file);
    });

    return collaborationNotesPurchasesApi
      .saveMessageWithFiles(token, data)
      .then(() => dispatch(this.actions.attachedFilesEditor(null)))
      .catch((error) => {
        dispatch(
          alerts.actions.addAlert({
            type: "danger",
            message:
              error.response.status === 413
                ? "Saving: Large size of the attached file - Server Error"
                : "Saving: Files is not saved - Server Error",
          })
        );

        return new Promise((resolve, reject) => {
          reject(new Error(error));
        });
      });
  };

  getAllPOCompanyUsersThunk = (data) =>
    fromJS(data.map((item) => ({ ...item, name: item.firstName })));

  readingNotificationsThunk = ({ getState, dispatch }, data) => {
    const notification = camelcaseKeys(data);
    const notifications = getState().getIn(["purchase", "allChatsNotifications"]);
    const index = notifications.findIndex(
      (message) => message.getIn(["id"]) === notification.notificationId[0]
    );

    if (index >= 0) {
      const newNotificationList = notifications.setIn([index, "isRead"], true);
      dispatch(this.actions.getAllNotification(newNotificationList.toJS()));
    }

    return false;
  };

  markAllNotificationsAsReadThunk = ({ getState, dispatch }) => {
    const notifications = getState().getIn(["purchase", "allChatsNotifications"]);
    const markMessage = notifications.map((item) => item.setIn(["isRead"], true));
    dispatch(this.actions.getAllNotification(markMessage.toJS()));
  };

  getCollaborationNotesFileThunk = ({ getState, token, fulfilled }, fileId, fileName) => {
    const state = getState();
    const activePurchaseOrderUid = getState().getIn([
      "purchaseOrders",
      "activePurchaseOrder",
      "uid",
    ]);
    const activeSupplierUid = getState().getIn([
      "purchaseOrders",
      "activePurchaseOrder",
      "supplier",
    ]);
    return collaborationNotesPurchasesApi
      .getCollaborationNotesFile(activeSupplierUid, activePurchaseOrderUid, fileId, token)
      .then((response) => {
        fulfilled({
          id: fileId,
          url: window.URL.createObjectURL(
            new File([response.data], fileName, { type: response.data.type })
          ),
          type: response.data.type,
          name: fileName,
        });

        return new Blob([response.data], { type: response.data.type });
      });
  };

  defineActions() {
    /* Purchases */
    const getAllMessages = this.thunkAction(
      ACTIONS.GET_ALL_MESSAGES,
      this.getAllMessagesThunk,
      true
    );
    const createMessage = this.thunkAction(ACTIONS.CREATE_MESSAGE, this.createMessagesThunk, true);
    const editMessage = this.thunkAction(ACTIONS.EDIT_MESSAGE, this.editMessagesThunk, true);
    const clearEditMessage = this.createAction(ACTIONS.CLEAR_EDIT_MESSAGE);
    const editOrderMessage = this.createAction(ACTIONS.EDIT_ORDER_MESSAGE);
    const deleteMessage = this.createAction(ACTIONS.DELETE_MESSAGE);
    const deleteFileEditingMessage = this.createAction(ACTIONS.DELETE_FILE_EDITING_MESSAGE);
    const sendNewMessageWithAttachedFiles = this.thunkAction(
      ACTIONS.SEND_NEW_MESSAGE_WITH_FILES,
      this.sendNewMessageWithAttachedFilesThunk,
      true
    );
    const sendMessageWithFiles = this.thunkAction(
      ACTIONS.SEND_MESSAGE_WITH_FILES,
      this.sendMessageWithFilesThunk,
      true
    );

    const attachedFilesEditor = this.createAction(ACTIONS.ATTACH_FILES_EDITOR);
    const uploadFileTrigger = this.createAction(ACTIONS.UPLOAD_FILE_MESSAGE);

    const getAllPOCompanyUsers = this.createAction(
      ACTIONS.GET_ALL_COMPANY_USER,
      this.getAllPOCompanyUsersThunk,
      true
    );
    const getAllNotification = this.createAction(ACTIONS.GET_ALL_NOTIFICATIONS);
    const readingNotifications = this.thunkAction(
      ACTIONS.READ_NOTIFICATION_MESSAGE,
      this.readingNotificationsThunk,
      true
    );
    const setTransitionByNotificationStatus = this.createAction(
      ACTIONS.GET_ORDER_CHAT_BY_NOTIFICATION
    );
    const receivingNotification = this.createAction(ACTIONS.RECEIVING_NOTIFICATION);
    const deleteNotification = this.createAction(ACTIONS.DELETE_NOTIFICATION);
    const markAllNotificationsAsRead = this.thunkAction(
      ACTIONS.MARK_ALL_NOTIFICATION_AS_READ,
      this.markAllNotificationsAsReadThunk,
      true
    );

    const clearAllMessageChat = this.createAction(ACTIONS.CLEAR_ALL_MESSAGE);
    const clearMessageFile = this.createAction(ACTIONS.CLEAR_ALL_ORDER_MESSAGE_FILE);

    const getCollaborationNotesFile = this.thunkAction(
      ACTIONS.GET_MESSAGE_FILES,
      this.getCollaborationNotesFileThunk,
      true
    );
    const removeFileMessage = this.createAction(ACTIONS.REMOVE_ATTACHED_FILE_MESSAGE);

    return {
      /* Purchases */
      getAllMessages,
      createMessage,
      deleteMessage,
      editMessage,
      editOrderMessage,
      clearEditMessage,
      deleteFileEditingMessage,
      sendNewMessageWithAttachedFiles,
      sendMessageWithFiles,

      attachedFilesEditor,
      uploadFileTrigger,

      getAllPOCompanyUsers,

      getAllNotification,
      readingNotifications,
      receivingNotification,
      deleteNotification,
      markAllNotificationsAsRead,

      setTransitionByNotificationStatus,

      clearAllMessageChat,
      clearMessageFile,

      getCollaborationNotesFile,
      removeFileMessage,
    };
  }

  defineReducers() {
    return {
      /* Purchases */
      [`${ACTIONS.GET_ALL_MESSAGES} fulfilled`]: this.setInReducer(["dataServer", "items"]),

      [ACTIONS.REMOVE_ATTACHED_FILE_MESSAGE]: this.setInReducer(["dataServer", "items"]),

      [`${ACTIONS.GET_ALL_MESSAGES} pending`]: this.thunkPendingReducer([
        "dataServer",
        "isPending",
      ]),

      [`${ACTIONS.SEND_MESSAGE_WITH_FILES} pending`]: this.thunkPendingReducer([
        "dataServer",
        "isPending",
      ]),

      [`${ACTIONS.GET_ALL_MESSAGES} rejected`]: this.setReducer("errors"),

      [`${ACTIONS.EDIT_MESSAGE} fulfilled`]: this.setInReducer(["dataServer", "editMessage"]),

      [ACTIONS.CLEAR_EDIT_MESSAGE]: this.setInReducer(["dataServer", "editMessage"], null),

      [`${ACTIONS.CREATE_MESSAGE} pending`]: this.thunkPendingReducer(["dataServer", "isPending"]),

      [`${ACTIONS.CREATE_MESSAGE} fulfilled`]: this.setInReducer(["dataServer", "items"]),

      [ACTIONS.EDIT_ORDER_MESSAGE]: (state, { payload }) =>
        state.updateIn(["dataServer", "items"], (list) => {
          const message = camelcaseKeys(payload, { deep: true });
          const newList = list.filter((item) => item.getIn(["id"]) !== message.id);
          const newAllMessageList = newList.push(
            fromJS({
              ...message,
              createdTimestampUnix: message.createdTimestamp
                ? moment.tz(message.createdTimestamp).unix()
                : null,
            })
          );
          return newAllMessageList.sort(
            (a, b) => a.getIn(["createdTimestampUnix"]) - b.getIn(["createdTimestampUnix"])
          );
        }),

      [ACTIONS.DELETE_MESSAGE]: (state, { payload }) =>
        state.updateIn(["dataServer", "items"], (list) => {
          const deleteId = parseFloat(payload);
          const newList = list.filter((item) => item.getIn(["id"]) !== deleteId);
          return newList.sort(
            (a, b) => a.getIn(["createdTimestampUnix"]) - b.getIn(["createdTimestampUnix"])
          );
        }),

      [ACTIONS.CLEAR_ALL_MESSAGE]: (state) => state.setIn(["dataServer", "items"], []),

      [ACTIONS.GET_ALL_COMPANY_USER]: this.setInReducer(["allCompanyUsers"]),

      [ACTIONS.GET_ALL_NOTIFICATIONS]: (state, { payload }) =>
        state.updateIn(["allChatsNotifications"], () => {
          const message = camelcaseKeys(payload, { deep: true });
          const listMessage = message.map((item) => ({
            ...item,
            createdTimestampUnix: item.createdTimestamp
              ? moment.tz(message.createdTimestamp).unix()
              : null,
          }));
          return fromJS(
            listMessage.sort((a, b) => a.createdTimestampUnix - b.createdTimestampUnix)
          );
        }),

      [ACTIONS.DELETE_NOTIFICATION]: (state, { payload }) =>
        state.updateIn(["allChatsNotifications"], (list) => {
          const data = camelcaseKeys(payload, { deep: true });
          const newList = list.filter((item) => item.getIn(["id"]) !== data.notificationId);
          return newList.sort((a, b) => a.createdTimestampUnix - b.createdTimestampUnix);
        }),

      [ACTIONS.GET_ORDER_CHAT_BY_NOTIFICATION]: this.setInReducer(["transitionByNotification"]),

      [ACTIONS.RECEIVING_NOTIFICATION]: (state, { payload }) =>
        state.updateIn(["allChatsNotifications"], (list) => {
          let newList;
          const message = camelcaseKeys(payload, { deep: true });
          const index = list.findIndex((item) => item.getIn(["id"]) === message.id);

          if (index >= 0) {
            newList = list.setIn(
              [index],
              fromJS({
                ...message,
                createdTimestampUnix: message.createdTimestamp
                  ? moment.tz(message.createdTimestamp).unix()
                  : null,
              })
            );
          } else {
            newList = list.push(
              fromJS({
                ...message,
                createdTimestampUnix: message.createdTimestamp
                  ? moment.tz(message.createdTimestamp).unix()
                  : null,
              })
            );
          }

          return newList.sort(
            (a, b) => b.getIn(["createdTimestampUnix"]) - a.getIn(["createdTimestampUnix"])
          );
        }),

      [`${ACTIONS.GET_MESSAGE_FILES} fulfilled`]: (state, { payload }) =>
        state.setIn(["attachedFilesMessages", payload.id], {
          url: payload.url,
          type: payload.type,
          name: payload.name,
        }),

      [ACTIONS.ATTACH_FILES_EDITOR]: this.setInReducer(["attachedFilesEditor"]),

      [ACTIONS.UPLOAD_FILE_MESSAGE]: this.setInReducer(["isUploadFile"]),

      [ACTIONS.CLEAR_ALL_ORDER_MESSAGE_FILE]: (state) => state.setIn(["attachedFilesMessages"], {}),
    };
  }
}

const instance = new PurchaseModule();
instance.init();

export default instance;
export { instance, ACTIONS };
