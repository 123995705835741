import React from "react";
import Loading from "./Loading";

class PrintPage extends React.Component {
  render() {
    return <Loading className="vh-100" />;
  }
}

export default PrintPage;
