import Vector from "./vector";

export function calculateLength(p1, p2) {
  const v1 = Vector.create(p1);
  const v2 = Vector.create(p2);
  return v1.sub(v2).length();
}

export function calcLine(p1, p2) {
  const v1 = Vector.create(p1);
  const v2 = Vector.create(p2);
  const line = v2.sub(v1);
  const lineLength = line.length();
  if (lineLength === 0) return null;

  const angle = Vector.toDeg(line.angle());

  const middleLine = line.div(2);
  const centerLine = v1.add(middleLine);

  return {
    line,
    lineLength: Math.round(lineLength),
    angle,
    centerLine,
  };
}

export function calcLineLabelActive(p1, p2, shift) {
  const lineLabel = calcLine(p1, p2);
  if (!lineLabel) return null;

  let position = lineLabel.line
    .rotate(Math.PI / 2)
    .mul(shift / lineLabel.lineLength);
  position = lineLabel.centerLine.add(position);

  let orthoLine = position.sub(lineLabel.centerLine);
  let angle = lineLabel.angle;
  if (orthoLine.y > 0) {
    orthoLine = orthoLine.mul(-1);
  } else {
    angle += 180;
  }

  return {
    ...lineLabel,
    position: lineLabel.centerLine.add(orthoLine),
    rotation: angle,
  };
}

export function calcBBox(points) {
  let minX = Infinity;
  let minY = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;
  const keys = Object.keys(points);
  if (keys.length === 0) {
    return {
      x: 0,
      y: 0,
      width: 1,
      height: 1,
    };
  }

  Object.keys(points).forEach((id) => {
    const p = points[id];
    minX = Math.min(minX, p.x);
    minY = Math.min(minY, p.y);
    maxX = Math.max(maxX, p.x);
    maxY = Math.max(maxY, p.y);
  });
  return {
    x: minX,
    y: minY,
    width: maxX - minX,
    height: maxY - minY,
  };
}

export function calcViewBoxWithZoom(
  points,
  dim,
  padding = 5,
  isLargeBoxSizePreview
) {
  const bb = calcBBox(points, padding);
  let zoomX = (dim.x - 2 * padding) / bb.width;
  let zoomY = (dim.y - 2 * padding) / bb.height;
  if (isLargeBoxSizePreview) {
    zoomX = (dim.x / 2.65 - 2 * padding) / bb.width;
    zoomY = (dim.y / 2 - 2 * padding) / bb.height;
  }
  const zoom = Math.min(zoomX, zoomY);

  return {
    x: bb.x - padding / zoom,
    y: bb.y - padding / zoom,
    width: bb.width + (2 * padding) / zoom,
    height: bb.height + (2 * padding) / zoom,
    zoom,
  };
}

export function snapToAngle(mouse, startX, startY) {
  const x2 = mouse.x - startX;
  const y2 = mouse.y - startY;
  const angle = Math.abs(Vector.toDeg(Math.atan(y2 / x2)));

  let angle2 = Vector.toDeg(Math.atan2(y2, x2));
  angle2 = Math.round((angle2 % 360) + 180);
  if (angle > 40 && angle < 50) {
    if ((angle2 > 40 && angle2 < 50) || (angle2 > 220 && angle2 < 230)) {
      mouse.y = mouse.x - startX + startY;
    } else if (
      (angle2 > 130 && angle2 < 140) ||
      (angle2 > 310 && angle2 < 320)
    ) {
      mouse.y = startY + startX - mouse.x;
    }
  } else if (angle > 85 || angle < 5) {
    if ((angle2 > 85 && angle2 < 95) || (angle2 > 265 && angle2 < 275)) {
      mouse.x = startX;
    } else if (angle2 > 355 || angle2 < 5 || (angle2 > 175 && angle2 < 185)) {
      mouse.y = startY;
    }
  }
}

export function checkCrossAnchors(anchor1, anchor2) {
  const { radius: radius1, position: position1 } = anchor1;
  const { radius: radius2, position: position2 } = anchor2;

  const vPosition1 = Vector.create(position1);
  const vPosition2 = Vector.create(position2);
  const distance = vPosition2.sub(vPosition1).length();
  return (
    (distance === 0 && radius1 === radius2) ||
    (radius1 + radius2 >= distance &&
      radius1 + distance >= radius2 &&
      radius2 + distance >= radius1)
  );
}
