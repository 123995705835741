import { STATUS } from "@/utils/constants";
import * as date from "@/utils/date";

import {
  getPartialInvoiceAdditionalProducts,
  getPartialInvoices,
  getPublicPartialInvoices,
  postPartialInvoice,
  postPrintPartialInvoice,
  postEmailPartialInvoice,
} from "../../apiv2/partialInvoice";
import { handleError } from "../utils/error";

import {
  SET_INVOICE_TYPE,
  SET_INVOICE_DATE,
  SET_INVOICE_DATA,
  SET_PRINT_PARTIAL_INVOICE_PROGRESS,
  SET_PRINTED_PARTIAL_INVOICE,
  SET_PARTIAL_INVOICES_PROGRESS,
  SET_PARTIAL_INVOICES,
  SET_PARTIAL_INVOICE_SUMMARY_OPEN,
  SET_LAST_SUBMITTED_DATA,
  SET_ADDITIONAL_PRODUCTS_PROGRESS,
  SET_ADDITIONAL_PRODUCTS,
  SET_CREATE_PARTIAL_INVOICE_PROGRESS,
  SET_SPECIFIC_AMOUNT,
  SET_PERCENTAGE,
  SET_LAST_ID,
  SET_EMAIL_PARTIAL_INVOICE_PROGRESS,
  SET_EMAIL_PARTIAL_INVOICE,
  SET_INVOICE_SELECTED_ITEMS,
  SET_INVOICE_ITEM_AMOUNTS,
  SET_INVOICE_ITEM_REMAINING_AMOUNTS,
  RESET,
} from "./partialInvoice.actionTypes";

export const setInvoiceType = (payload) => (dispatch) => {
  dispatch({
    type: SET_INVOICE_TYPE,
    payload,
  });
};

export const setInvoiceDate = (payload) => (dispatch) => {
  dispatch({
    type: SET_INVOICE_DATE,
    payload,
  });
};

export const setInvoiceData = (payload) => (dispatch) => {
  dispatch({
    type: SET_INVOICE_DATA,
    payload,
  });
};

export const setLastSubmittedData = (payload) => (dispatch) => {
  dispatch({
    type: SET_LAST_SUBMITTED_DATA,
    payload,
  });
};

export const setPartialInvoicesProgress = (payload) => (dispatch) => {
  dispatch({
    type: SET_PARTIAL_INVOICES_PROGRESS,
    payload,
  });
};

export const setPartialInvoices = (payload) => (dispatch) => {
  dispatch({
    type: SET_PARTIAL_INVOICES,
    payload,
  });
};

export const setPrintPartialInvoiceProgress = (payload) => (dispatch) => {
  dispatch({
    type: SET_PRINT_PARTIAL_INVOICE_PROGRESS,
    payload,
  });
};

export const setPartialInvoiceSummaryOpen = (payload) => (dispatch) => {
  dispatch({
    type: SET_PARTIAL_INVOICE_SUMMARY_OPEN,
    payload,
  });
};

export const setPrintedPartialInvoice = (payload) => (dispatch) => {
  dispatch({
    type: SET_PRINTED_PARTIAL_INVOICE,
    payload,
  });
};

export const setAdditionalProductsProgress = (payload) => (dispatch) => {
  dispatch({
    type: SET_ADDITIONAL_PRODUCTS_PROGRESS,
    payload,
  });
};

export const setAdditionalProducts = (payload) => (dispatch) => {
  dispatch({
    type: SET_ADDITIONAL_PRODUCTS,
    payload,
  });
};

export const setCreatePartialInvoiceProgress = (payload) => (dispatch) => {
  dispatch({
    type: SET_CREATE_PARTIAL_INVOICE_PROGRESS,
    payload,
  });
};

export const setSpecificAmount = (payload) => (dispatch) => {
  dispatch({
    type: SET_SPECIFIC_AMOUNT,
    payload,
  });
};

export const setPercentage = (payload) => (dispatch) => {
  dispatch({
    type: SET_PERCENTAGE,
    payload,
  });
};

export const setLastId = (payload) => (dispatch) => {
  const xPartialInvoiceId = payload;

  dispatch({
    type: SET_LAST_ID,
    payload: xPartialInvoiceId !== "None" ? xPartialInvoiceId : null,
  });
};

export const setEmailPartialInvoiceProgress = (payload) => (dispatch) => {
  dispatch({
    type: SET_EMAIL_PARTIAL_INVOICE_PROGRESS,
    payload,
  });
};

export const setEmailPartialInvoice = (payload) => (dispatch) => {
  dispatch({
    type: SET_EMAIL_PARTIAL_INVOICE,
    payload,
  });
};

export const setInvoiceSelectedItems = (payload) => (dispatch) => {
  dispatch({
    type: SET_INVOICE_SELECTED_ITEMS,
    payload,
  });
};

export const setInvoiceItemAmounts = (payload) => (dispatch) => {
  dispatch({
    type: SET_INVOICE_ITEM_AMOUNTS,
    payload,
  });
};

export const setInvoiceItemRemainingAmounts = (payload) => (dispatch) => {
  dispatch({
    type: SET_INVOICE_ITEM_REMAINING_AMOUNTS,
    payload,
  });
};

export const createPartialInvoice = (data) => async (dispatch) => {
  try {
    dispatch(setCreatePartialInvoiceProgress(STATUS.PENDING));
    await postPartialInvoice(data)();
    dispatch(setCreatePartialInvoiceProgress(STATUS.FULFILLED));
  } catch (error) {
    handleError(error, dispatch);
    dispatch(setCreatePartialInvoiceProgress(STATUS.REJECTED));
  }
};

export const printPartialInvoice = (payload) => async (dispatch) => {
  try {
    dispatch(setPrintPartialInvoiceProgress(STATUS.PENDING));

    const data = new FormData();

    data.set(
      "json",
      JSON.stringify({
        ...payload,
        invoiced_date: date.toUtcDateTime({
          date: payload.invoiced_date,
          timezone: date.getCurrentUserTimezone(),
        }),
        pdf_date: date.toUtcDateTime({
          date: payload.invoiced_date,
          timezone: date.getCurrentUserTimezone(),
        }),
      })
    );

    const response = await postPrintPartialInvoice(data)();
    dispatch(setLastId(response.headers?.["x-partial-invoice-id"]));
    dispatch(setPrintedPartialInvoice(response.data));
    dispatch(setPrintPartialInvoiceProgress(STATUS.FULFILLED));
  } catch (error) {
    dispatch(setPrintedPartialInvoice(error?.response?.data));
    dispatch(setPrintPartialInvoiceProgress(STATUS.REJECTED));
  }
};

export const emailPartialInvoice =
  ({ customerUid, orderUid, payload }) =>
  async (dispatch) => {
    try {
      dispatch(setEmailPartialInvoiceProgress(STATUS.PENDING));

      const data = new FormData();

      data.set(
        "json",
        JSON.stringify({
          ...payload,
          invoiced_date: date.toUtcDateTime({
            date: payload.invoiced_date,
            timezone: date.getCurrentUserTimezone(),
          }),
          pdf_date: date.toUtcDateTime({
            date: payload.pdf_date,
            timezone: date.getCurrentUserTimezone(),
          }),
        })
      );

      const response = await postEmailPartialInvoice({
        customerUid,
        orderUid,
        data,
      })();
      dispatch(setLastId(response.headers?.["x-partial-invoice-id"]));
      dispatch(setEmailPartialInvoice(response.data));
      dispatch(setEmailPartialInvoiceProgress(STATUS.FULFILLED));
    } catch (error) {
      dispatch(setEmailPartialInvoice(error?.response?.data));
      dispatch(setEmailPartialInvoiceProgress(STATUS.REJECTED));
    }
  };

export const fetchAdditionalProducts =
  ({ customerUid, orderUid, params = {} }) =>
  async (dispatch) => {
    try {
      dispatch(setAdditionalProductsProgress(STATUS.PENDING));
      const response = await getPartialInvoiceAdditionalProducts({
        customerUid,
        orderUid,
        params,
      })();
      dispatch(setAdditionalProducts(response.data));
      dispatch(setAdditionalProductsProgress(STATUS.FULFILLED));
    } catch (error) {
      handleError(error, dispatch);
      dispatch(setAdditionalProductsProgress(STATUS.REJECTED));
    }
  };

export const fetchPartialInvoices =
  ({ customerUid, orderUid, params = {} }) =>
  async (dispatch) => {
    try {
      dispatch(setPartialInvoicesProgress(STATUS.PENDING));
      const response = await getPartialInvoices({
        customerUid,
        orderUid,
        params,
      })();
      dispatch(setPartialInvoices(response.data));
      dispatch(setPartialInvoicesProgress(STATUS.FULFILLED));
    } catch (error) {
      handleError(error, dispatch);
      dispatch(setPartialInvoicesProgress(STATUS.REJECTED));
    }
  };

export const fetchPublicPartialInvoices =
  ({ token, customerUid, orderUid, params = {} }) =>
  async (dispatch) => {
    try {
      dispatch(setPartialInvoicesProgress(STATUS.PENDING));
      const response = await getPublicPartialInvoices({
        customerUid,
        orderUid,
        params: { ...params, token },
      })();
      dispatch(setPartialInvoices(response.data));
      dispatch(setPartialInvoicesProgress(STATUS.FULFILLED));
    } catch (error) {
      handleError(error, dispatch);
      dispatch(setPartialInvoicesProgress(STATUS.REJECTED));
    }
  };

export const reset = () => (dispatch) => {
  dispatch({ type: RESET });
};
