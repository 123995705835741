import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import cx from "classnames";

import styles from "./SortArrows.module.scss";

const SortArrows = ({ state, onSort, className }) => {
  const [arrowState, setArrowState] = useState("");

  useEffect(() => {
    setArrowState(state);
  }, [state]);

  return (
    <div className={cx(styles.filterArrows, className)}>
      <FontAwesomeIcon
        className={cx(styles.filterArrowsUp, {
          [styles.filterArrowsActive]: arrowState === "up",
        })}
        icon={faSortUp}
        onClick={onSort}
      />
      <FontAwesomeIcon
        className={cx(styles.filterArrowsDown, {
          [styles.filterArrowsActive]: arrowState === "down",
        })}
        icon={faSortDown}
        onClick={onSort}
      />
    </div>
  );
};

SortArrows.propTypes = {
  state: PropTypes.string.isRequired,
  onSort: PropTypes.func,
  className: PropTypes.string.isRequired,
};

SortArrows.defaultProps = {
  onSort: () => null,
};

export default SortArrows;
