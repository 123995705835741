import createActionType from "../../utils/createActionType";

export const MODULE = "planner/calendar";

export const SET_IS_FETCHING_USERS = createActionType(MODULE, "setIsFetchingUsers");

export const SET_USERS = createActionType(MODULE, "setUsers");

export const SET_SELECTED_USERS = createActionType(MODULE, "setSelectedUsers");

export const SET_IS_FETCHING_LABELS = createActionType(MODULE, "setIsFetchingLabels");

export const SET_LABELS = createActionType(MODULE, "setLabels");

export const SET_IS_FETCHING_SCHEDULES = createActionType(MODULE, "setIsFetchingSchedules");

export const SET_ORDER_IN_CALENDAR = createActionType(MODULE, "setOrderInCalendar");

export const SET_TO_DATE = createActionType(MODULE, "setToDate");

export const SET_FROM_DATE = createActionType(MODULE, "setFromDate");

export const SET_ACTIVE_SCHEDULE = createActionType(MODULE, "setActiveSchedule");

export const SET_IS_CREATING_SCHEDULE = createActionType(MODULE, "setIsCreatingSchedule");

export const SET_CALENDAR_VIEW = createActionType(MODULE, "setCalendarView");

export const SET_SHOW_CUSTOM_EVENT_MODAL = createActionType(MODULE, "setShowCustomEventModal");

export const SET_SHOW_DELETE_CUSTOM_EVENT_WARNING_MODAL = createActionType(
  MODULE,
  "setShowDeleteCustomEventWarningModal"
);
export const SET_SHOW_EDIT_CUSTOM_EVENT_WARNING_MODAL = createActionType(
  MODULE,
  "setShowEditCustomEventWarningModal"
);

export const SET_DATA_IN_CALENDAR_ENTRY = createActionType(MODULE, "setDataInCalendarEntry");
export const SET_DELETE_SCOPE = createActionType(MODULE, "setDeleteScope");
export const SET_EDIT_SCOPE = createActionType(MODULE, "setEditScope");
export const SET_INTITIAL_CUSTOM_EVENT_DATA = createActionType(MODULE, "setInitialCustomEventData");
export const RESET = createActionType(MODULE, "reset");
