import { STATUS } from "@/utils/constants";

import {
  SET_GLOBAL_SEARCH_SEARCHING_STATUS,
  SET_GLOBAL_SEARCH_MODAL_IS_VISIBLE,
  SET_GLOBAL_SEARCH_TYPE,
  SET_GLOBAL_SEARCH_OPTION_TYPES,
  SET_GLOBAL_SEARCH_POPOVER_OPEN,
  SET_GLOBAL_SEARCH_OPTIONS_OPEN,
  SET_GLOBAL_SEARCH_KEYWORD,
  SET_GLOBAL_SEARCH_IS_SHOW_ALL_CLICKED,
  SET_FETCH_GLOBAL_SEARCH_ALL_STATUS,
  SET_FETCH_GLOBAL_SEARCH_ALL_RESULTS,
  SET_FETCH_GLOBAL_SEARCH_ALL,
  SET_FETCH_GLOBAL_SEARCH_CUSTOMERS_STATUS,
  SET_FETCH_GLOBAL_SEARCH_CUSTOMERS_RESULTS,
  SET_FETCH_GLOBAL_SEARCH_CUSTOMERS,
  SET_FETCH_GLOBAL_SEARCH_SUPPLIERS_STATUS,
  SET_FETCH_GLOBAL_SEARCH_SUPPLIERS_RESULTS,
  SET_FETCH_GLOBAL_SEARCH_SUPPLIERS,
  SET_FETCH_GLOBAL_SEARCH_SALES_STATUS,
  SET_FETCH_GLOBAL_SEARCH_SALES_RESULTS,
  SET_FETCH_GLOBAL_SEARCH_SALES,
  SET_FETCH_GLOBAL_SEARCH_PURCHASES_STATUS,
  SET_FETCH_GLOBAL_SEARCH_PURCHASES_RESULTS,
  SET_FETCH_GLOBAL_SEARCH_PURCHASES,
  SET_FETCH_GLOBAL_SEARCH_PRODUCTS_STATUS,
  SET_FETCH_GLOBAL_SEARCH_PRODUCTS_RESULTS,
  SET_FETCH_GLOBAL_SEARCH_PRODUCTS,
  RESET,
} from "./globalSearch.actionTypes";
import { types } from "./constants";

const initialState = {
  globaSearchSearchingStatus: STATUS.IDLE,
  globalSearchIsVisible: false,
  globalSearchType: types.ALL,
  globalSearchOptionTypes: Object.values(types),
  globalSearchPopoverOpen: false,
  globalSearchOptionsOpen: false,
  globalSearchKeyword: "",
  globalSearchIsShowAllClicked: {},
  globalSearchAllStatus: STATUS.IDLE,
  globalSearchAllResults: [],
  globalSearchAll: {},
  globalSearchCustomersStatus: STATUS.IDLE,
  globalSearchCustomersResults: [],
  globalSearchCustomers: {},
  globalSearchSuppliersStatus: STATUS.IDLE,
  globalSearchSuppliersResults: [],
  globalSearchSuppliers: {},
  globalSearchSalesStatus: STATUS.IDLE,
  globalSearchSalesResults: [],
  globalSearchSales: {},
  globalSearchPurchasesStatus: STATUS.IDLE,
  globalSearchPurchasesResults: [],
  globalSearchPurchases: {},
  globalSearchProductsStatus: STATUS.IDLE,
  globalSearchProductsResults: [],
  globalSearchProducts: {},
};

export default (namespace) =>
  (state = initialState, action) => {
    switch (action.type) {
      case `${namespace}${SET_GLOBAL_SEARCH_MODAL_IS_VISIBLE}`: {
        return { ...state, globalSearchIsVisible: action.payload };
      }

      case `${namespace}${SET_GLOBAL_SEARCH_TYPE}`: {
        return { ...state, globalSearchType: action.payload };
      }

      case `${namespace}${SET_GLOBAL_SEARCH_OPTION_TYPES}`: {
        return { ...state, globalSearchOptionTypes: action.payload };
      }

      case `${namespace}${SET_GLOBAL_SEARCH_POPOVER_OPEN}`: {
        return { ...state, globalSearchPopoverOpen: action.payload };
      }

      case `${namespace}${SET_GLOBAL_SEARCH_OPTIONS_OPEN}`: {
        return { ...state, globalSearchOptionsOpen: action.payload };
      }

      case `${namespace}${SET_GLOBAL_SEARCH_KEYWORD}`: {
        return { ...state, globalSearchKeyword: action.payload };
      }

      case `${namespace}${SET_GLOBAL_SEARCH_IS_SHOW_ALL_CLICKED}`: {
        return { ...state, globalSearchIsShowAllClicked: action.payload };
      }

      case `${namespace}${SET_GLOBAL_SEARCH_SEARCHING_STATUS}`: {
        return { ...state, globaSearchSearchingStatus: action.payload };
      }

      // Search type ALL
      case `${namespace}${SET_FETCH_GLOBAL_SEARCH_ALL_STATUS}`: {
        return { ...state, globalSearchAllStatus: action.payload };
      }

      case `${namespace}${SET_FETCH_GLOBAL_SEARCH_ALL_RESULTS}`: {
        return { ...state, globalSearchAllResults: action.payload };
      }

      case `${namespace}${SET_FETCH_GLOBAL_SEARCH_ALL}`: {
        return { ...state, globalSearchAll: action.payload };
      }

      // Search type CUSTOMERS
      case `${namespace}${SET_FETCH_GLOBAL_SEARCH_CUSTOMERS_STATUS}`: {
        return { ...state, globalSearchCustomersStatus: action.payload };
      }

      case `${namespace}${SET_FETCH_GLOBAL_SEARCH_CUSTOMERS_RESULTS}`: {
        return { ...state, globalSearchCustomersResults: action.payload };
      }

      case `${namespace}${SET_FETCH_GLOBAL_SEARCH_CUSTOMERS}`: {
        return { ...state, globalSearchCustomers: action.payload };
      }

      // Search type SUPPLIERS
      case `${namespace}${SET_FETCH_GLOBAL_SEARCH_SUPPLIERS_STATUS}`: {
        return { ...state, globalSearchSuppliersStatus: action.payload };
      }

      case `${namespace}${SET_FETCH_GLOBAL_SEARCH_SUPPLIERS_RESULTS}`: {
        return { ...state, globalSearchSuppliersResults: action.payload };
      }

      case `${namespace}${SET_FETCH_GLOBAL_SEARCH_SUPPLIERS}`: {
        return { ...state, globalSearchSuppliers: action.payload };
      }

      // Search type SALES
      case `${namespace}${SET_FETCH_GLOBAL_SEARCH_SALES_STATUS}`: {
        return { ...state, globalSearchSalesStatus: action.payload };
      }

      case `${namespace}${SET_FETCH_GLOBAL_SEARCH_SALES_RESULTS}`: {
        return { ...state, globalSearchSalesResults: action.payload };
      }

      case `${namespace}${SET_FETCH_GLOBAL_SEARCH_SALES}`: {
        return { ...state, globalSearchSales: action.payload };
      }

      // Search type PURCHASES
      case `${namespace}${SET_FETCH_GLOBAL_SEARCH_PURCHASES_STATUS}`: {
        return { ...state, globalSearchPurchasesStatus: action.payload };
      }

      case `${namespace}${SET_FETCH_GLOBAL_SEARCH_PURCHASES_RESULTS}`: {
        return { ...state, globalSearchPurchasesResults: action.payload };
      }

      case `${namespace}${SET_FETCH_GLOBAL_SEARCH_PURCHASES}`: {
        return { ...state, globalSearchPurchases: action.payload };
      }

      // Search type PRODUCTS
      case `${namespace}${SET_FETCH_GLOBAL_SEARCH_PRODUCTS_STATUS}`: {
        return { ...state, globalSearchProductsStatus: action.payload };
      }

      case `${namespace}${SET_FETCH_GLOBAL_SEARCH_PRODUCTS_RESULTS}`: {
        return { ...state, globalSearchProductsResults: action.payload };
      }

      case `${namespace}${SET_FETCH_GLOBAL_SEARCH_PRODUCTS}`: {
        return { ...state, globalSearchProducts: action.payload };
      }

      case `${namespace}${RESET}`: {
        return { ...initialState };
      }

      default:
        return state;
    }
  };
