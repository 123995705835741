import { createApi, createApiV2 } from "./common";

/* MYOB */
export const getMyobConnection = createApi("GET", "myob/authorize");

export const disconnectMyob = createApi("DELETE", "myob/disconnect");

export const registerProfile = createApi("POST", "myob/authenticate_companyfile/");

export const completeMyobAuth = createApi("GET", "myob/complete_authorization");

export const getMyobAccounts = () => (token) => createApiV2("GET", "myob/accounts/")(token);

export const getMyobTaxCodes = () => (token) => createApiV2("GET", "myob/tax_codes/")(token);

export const getMyobItems = () => (token) => createApiV2("GET", "myob/items/")(token);

export const getMyobJobs = () => (token) => createApiV2("GET", "myob/jobs/")(token);

export const getMyobCustomers = createApiV2("GET", "myob/customers/");

export const getMyobSuppliers = (search) => (token) =>
  createApiV2("GET", "myob/suppliers/")(token, search);

/* QBO */
export const getQboConnection = createApi("GET", "qbo/authorize");

export const completeQboAuth = createApi("GET", "qbo/complete_authorization");

export const disconnectQbo = createApi("DELETE", "qbo/disconnect");

export const getQboAccounts = () => (token) => createApiV2("GET", "qbo/accounts")(token);

export const getQboTaxCodes = () => (token) => createApiV2("GET", "qbo/taxcodes")(token);

export const getQboItems = () => (token) => createApiV2("GET", "qbo/items")(token);

export const getQboCustomers = createApiV2("GET", "qbo/customers");

export const getQboSuppliers = (search) => (token) =>
  createApiV2("GET", "qbo/suppliers")(token, search);

/* XERO */
export const getXeroConnection = createApiV2("GET", "xero/authorize");

export const completeXeroAuth = createApiV2("GET", "xero/complete_authorization");

export const disconnectXero = createApiV2("DELETE", "xero/disconnect/");

export const getXeroAccounts = () => (token) => createApiV2("GET", "xero/accounts")(token);

export const getXeroItems = () => (token) => createApiV2("GET", "xero/items/")(token);

export const getXeroCustomers = createApiV2("GET", "xero/customers");

export const getXeroConnectionStatus = () => (token) =>
  createApiV2("GET", "xero/connection_status/")(token);

export const getXeroSuppliers = (search) => (token) =>
  createApiV2("GET", "xero/suppliers")(token, search);

/* ACCOUNTING */
export const getUpdateAllData = () => (token) => createApiV2("GET", "update_all_data/")(token);
