import { checkIfKeywordHasMatch } from "@/utils/string";
import { STATUS } from "@/utils/constants";
import * as api from "@/apiv2/globalSearch";
import * as productApi from "@/apiv2/products";

import { handleError } from "../utils/error";

import * as selectors from "./add-product-to-order-inline.selectors";
import {
  SET_IS_FETCHING_STATUS,
  SET_PRODUCTS,
  SET_IS_SEARCH_RESULT_OPEN,
  SET_SEARCH,
  RESET,
} from "./add-product-to-order-inline.actionTypes";

export const setIsFetchingStatus = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_STATUS,
    payload: data,
  });
};

export const setProducts = (data) => (dispatch) => {
  dispatch({
    type: SET_PRODUCTS,
    payload: data,
  });
};

export const setIsSearchResultOpen = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_SEARCH_RESULT_OPEN,
    payload: data,
  });
};

export const setSearch = (data) => (dispatch) => {
  dispatch({
    type: SET_SEARCH,
    payload: data,
  });
};

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const searchProducts =
  ({ q, pageSize, isSoldByCompany = false, isPurchasedByCompany = false, supplierUid }) =>
  async (dispatch, getState) => {
    const state = getState();
    const search = selectors.getSearch(state);
    dispatch(setIsFetchingStatus(STATUS.PENDING));

    try {
      const { data } = await api.getGlobalSearchProductsForOrder({
        q,
        page_size: pageSize,
        is_sold_by_company: `${isSoldByCompany}`,
        is_purchased_by_company: `${isPurchasedByCompany}`,
        supplier_uid: supplierUid,
      })();

      if (data.results.length > 0) {
        const productsMatchInKeyword = data.results
          .filter((d) => d.index !== "factory_product_variant")
          .filter((d) => d.source.name.toLowerCase().includes(search.toLowerCase()));

        const productsNotMatchInKeyword = data.results
          .filter((d) => d.index !== "factory_product_variant")
          .filter((d) => !d.source.name.toLowerCase().includes(search.toLowerCase()));

        const productVariants = data.results.filter((d) => d.index === "factory_product_variant");
        const products = data.results.filter((d) => d.index !== "factory_product_variant");

        if (productsMatchInKeyword.length > 0) {
          dispatch(
            setProducts([
              ...productsMatchInKeyword,
              ...productVariants,
              ...productsNotMatchInKeyword,
            ])
          );
        } else {
          dispatch(setProducts([...productVariants, ...products]));
        }
      } else {
        dispatch(setProducts([]));
      }
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(setIsFetchingStatus(STATUS.FULFILLED));
    }
  };

export const fetchProductVariantDetails =
  ({ productUid, productVariantUid }) =>
  async () => {
    const { data } = await productApi.getProductVariantDetails({
      productUid,
      productVariantUid,
    })();
    return data;
  };
