import React from "react";
import "./Button.scss";
import cx from "classnames";

const Button = ({
  children,
  className = "",
  onClick = () => {},
  disabled = false,
  disabledClassName = "",
  ...rest
}) => (
  <button
    type="button"
    className={cx("button", className, {
      [disabledClassName]: disabled,
      "button--disabled": disabled,
    })}
    onClick={!disabled ? onClick : () => {}}
    disabled={disabled}
    {...rest}
  >
    {children}
  </button>
);

export default Button;
