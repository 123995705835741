import { createApiV2 } from "./common";

export const postResetPassword = (data) => (token) =>
  createApiV2("POST", "reset_password/")(token, data);

export const postResendResetPassword = (data) => (token) =>
  createApiV2("POST", "reset_password/resend_email/")(token, data);

export const postValidateToken = (data) => (token) =>
  createApiV2("POST", "reset_password/token/")(token, data);

export const postSetNewPassword = (data) => (token) =>
  createApiV2("POST", "reset_password/password/")(token, data);
