import { orderBy } from "lodash";

import { MODULE } from "./timesheets.actionTypes";

export const isFetchingSummaryStatus = (state) => state.getIn([MODULE, "isFetchingSummaryStatus"]);

export const getSummary = (state) => {
  const summary = state.getIn([MODULE, "summary"]);
  return orderBy(summary, [(l) => l.firstName.toLowerCase()]);
};

export const getFromDate = (state) => state.getIn([MODULE, "fromDate"]);

export const getToDate = (state) => state.getIn([MODULE, "toDate"]);

export const getDates = (state) => state.getIn([MODULE, "dates"]);

export const isUpdatingTimesheetStatus = (state) =>
  state.getIn([MODULE, "isUpdatingTimesheetStatus"]);

export const getSelectedUserIds = (state) => state.getIn([MODULE, "selectedUserIds"]);

export const getModalType = (state) => state.getIn([MODULE, "modalType"]);

export const getActiveUser = (state) => state.getIn([MODULE, "activeUser"]);
