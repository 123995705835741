import createActionType from "../utils/createActionType";

export const MODULE = "stocktake";

export const SET_IS_FETCHING_CATEGORIES = createActionType(MODULE, "setIsFetchingCategories");

export const SET_IS_SAVING = createActionType(MODULE, "setIsSaving");

export const SET_CATEGORIES = createActionType(MODULE, "setCategories");

export const SET_PRODUCTS = createActionType(MODULE, "setProducts");

export const SET_EMPTY_PRODUCTS = createActionType(MODULE, "setEmptyProducts");

export const SET_EXPANDED_CATEGORIES = createActionType(MODULE, "setExpandedCategories");

export const SET_EXPANDED_PRODUCTS = createActionType(MODULE, "setExpandedProducts");

export const SET_IS_FETCHING_PRODUCTS = createActionType(MODULE, "setIsFetchingProducts");

export const SET_IS_FETCHING_PRODUCT = createActionType(MODULE, "setIsFetchingProduct");

export const SET_SELECTED_CATEGORIES = createActionType(MODULE, "setSelectedCategories");

export const SET_SELECTED_PRODUCTS = createActionType(MODULE, "setSelectedProducts");

export const SET_INVENTORY = createActionType(MODULE, "setInventory");

export const SET_SEARCH_PRODUCTS = createActionType(MODULE, "setSearchProducts");

export const SET_PRODUCT_INVENTORY = createActionType(MODULE, "setProductInventory");

export const RESET = createActionType(MODULE, "reset");
