import createActionType from "../../utils/createActionType";

export const MODULE = "delivery-scheduling/label-filter";

export const SET_IS_FETCHING_LABELS = createActionType(MODULE, "setIsFetchingLabels");
export const SET_LABELS = createActionType(MODULE, "setLabels");

export const SET_SELECTED_LABELS = createActionType(MODULE, "setSelectedLabels");

export const RESET = createActionType(MODULE, "reset");
