import {
  SET_IS_FETCHING_USERS,
  SET_USERS,
  SET_SELECTED_USERS,
  RESET,
} from "./userFilter.actionTypes";

const initialState = {
  isFetchingUsers: false,
  users: [],
  selectedUsers: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USERS: {
      return { ...state, users: action.payload };
    }

    case SET_SELECTED_USERS: {
      return { ...state, selectedUsers: action.payload };
    }

    case SET_IS_FETCHING_USERS: {
      return { ...state, isFetchingUsers: action.payload };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
