import { STATUS } from "@/utils/constants";

import {
  SET_IS_FETCHING_STATUS,
  SET_IS_CHECKOUT_STATUS,
  SET_PRICING_TABLE,
  SET_BILILNG_DETAILS,
  RESET,
} from "./billing.actionTypes";

const initialState = {
  isFetchingStatus: STATUS.IDLE,
  isCheckoutStatus: STATUS.IDLE,
  pricingTable: [],
  billingDetails: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_FETCHING_STATUS: {
      return {
        ...state,
        isFetchingStatus: action.payload,
      };
    }

    case SET_IS_CHECKOUT_STATUS: {
      return {
        ...state,
        isCheckoutStatus: action.payload,
      };
    }

    case SET_PRICING_TABLE: {
      return {
        ...state,
        pricingTable: action.payload,
      };
    }

    case SET_BILILNG_DETAILS: {
      return {
        ...state,
        billingDetails: action.payload,
      };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
