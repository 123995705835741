export const ACTIONS = {
  GET_ALL_MESSAGES: "Get all",
  CREATE_MESSAGE: "Create",
  EDIT_MESSAGE: "Edit",
  EDIT_ORDER_MESSAGE: "Edit order message",
  DELETE_MESSAGE: "Delete",
  DELETE_FILE_MESSAGE: "Delete file",
  DELETE_FILE_EDITING_MESSAGE: "Delete editing message file",
  SEND_NEW_MESSAGE_WITH_FILES: "Send new message with attached files",
  SEND_MESSAGE_WITH_FILES: "Send message with attached files",

  ATTACH_FILES_EDITOR: "Attached file to editor",
  UPLOAD_FILE_MESSAGE: "Upload file message",

  GET_ALL_COMPANY_USER: "Get all users",
  GET_ALL_NOTIFICATIONS: "Get all notifications",
  GET_ORDER_CHAT_BY_NOTIFICATION: "Redirect to order chat",
  READ_NOTIFICATION_MESSAGE: "Reading notifications",

  MARK_ALL_NOTIFICATION_AS_READ: "Mark all as read",
  RECEIVING_NOTIFICATION: "Notification",
  DELETE_NOTIFICATION: "Delete notification",

  CLEAR_ALL_MESSAGE: "Clear active order message",
  CLEAR_ALL_ORDER_MESSAGE_FILE: "Clear active order message file",
  CLEAR_EDIT_MESSAGE: "Clear edit message",

  GET_MESSAGE_FILES: "Get message attached files",
  REMOVE_ATTACHED_FILE_MESSAGE: "Remove and undo remove attached file message",
};

export const NAMESPACES = {
  ORDER: "order",
  PURCHASE_ORDER: "purchase_order",
  SUPPLIER: "supplier",
  PRODUCT: "product",
  CUSTOMER: "customer",
};

export const REFERENCES = {
  [NAMESPACES.ORDER]: "order_id",
  [NAMESPACES.PURCHASE_ORDER]: "purchase_order_uid",
  [NAMESPACES.SUPPLIER]: "supplier_uid",
  [NAMESPACES.PRODUCT]: "product_uid",
  [NAMESPACES.CUSTOMER]: "customer_uid",
};
