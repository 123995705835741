import React, { Suspense, lazy } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import { Loading } from "@/ui-lib";
import NonAuthLayout from "@/pages/NonAuthLayout";

import styles from "./SignupPage.module.scss";

const LandingPage = lazy(() => import("./LandingPage"));
const PersonalInfoPage = lazy(() => import("./PersonalInfoPage"));
const XeroChooseAccountsPage = lazy(() => import("./XeroChooseAccountsPage"));
const CompanyDetailsPage = lazy(() => import("./CompanyDetailsPage"));
const EmailConfirmationPage = lazy(() => import("./EmailConfirmationPage"));
const CompanyMemberCountPage = lazy(() => import("./CompanyMemberCountPage"));
const GoalInFactoryPage = lazy(() => import("./GoalInFactoryPage"));
const LoadingPage = lazy(() => import("./LoadingPage"));
const XeroSignupRedirectPage = lazy(() => import("./XeroSignupRedirectPage"));

const SignupPage = () => {
  const { path } = useRouteMatch();
  return (
    <NonAuthLayout>
      <div className={styles.wrapper}>
        <Suspense
          fallback={
            <div className={styles.loadingWrapper}>
              <Loading loadingClassName="vh-100" />
            </div>
          }
        >
          <Switch>
            <Route exact path={path} component={LandingPage} />
            <Route exact path={`${path}/personal-info`} component={PersonalInfoPage} />
            <Route exact path={`${path}/email-confirmation`} component={EmailConfirmationPage} />
            <Route exact path={`${path}/choose-xero-accounts`} component={XeroChooseAccountsPage} />
            <Route exact path={`${path}/company`} component={CompanyDetailsPage} />
            <Route exact path={`${path}/company-member-count`} component={CompanyMemberCountPage} />
            <Route exact path={`${path}/company-goals`} component={GoalInFactoryPage} />
            <Route exact path={`${path}/configuring`} component={LoadingPage} />
            <Route exact path={`${path}/xero/redirect`} component={XeroSignupRedirectPage} />
          </Switch>
        </Suspense>
      </div>
    </NonAuthLayout>
  );
};

export default SignupPage;
