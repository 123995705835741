import { createApi } from "./common";

export const getCollaborationNotes = (token) =>
  createApi("GET", "order_chat/messages/")(token);

export const getCollaborationNotesFile = (
  customerUid,
  orderUid,
  fileId,
  token
) =>
  createApi(
    "GET",
    `customers/${customerUid}/orders/${orderUid}/attachments/${fileId}/`,
    { responseType: "blob" }
  )(token);

export const saveMessageWithFiles = (token, data) =>
  createApi("POST", "order/chat/file")(token, data);

export default getCollaborationNotes;
