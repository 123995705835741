import { NAMESPACES } from "@/redux/collaboration/constants";

import { createApi } from "./common";

export const saveMessageWithFiles = (namespace, data) => {
  const token = JSON.parse(window.localStorage.getItem("auth"));

  const API_URL = {
    [NAMESPACES.ORDER]: "order/chat/file/",
    [NAMESPACES.PURCHASE_ORDER]: "purchasing/chat_attachments/",
    [NAMESPACES.SUPPLIER]: "collaborate_attachments/",
    [NAMESPACES.CUSTOMER]: "collaborate_attachments/",
    [NAMESPACES.PRODUCT]: "collaborate_attachments/",
  };

  return createApi("POST", API_URL?.[namespace])(token, data);
};

export const toggleAttachment = ({ attachmentType, fileId }) => {
  const token = JSON.parse(window.localStorage.getItem("auth"));

  return createApi("PUT", `collaborate_attachments/${attachmentType}/${fileId}/toggle/`)(token);
};

export const getAttachmentFiles = (fileId) => {
  const token = JSON.parse(window.localStorage.getItem("auth"));

  return createApi("GET", `collaborate_attachments/${fileId}/`, {
    responseType: "blob",
  })(token);
};
