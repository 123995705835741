import deepEqual from "deep-equal";

const setKeypathValue = (object, keypath, value) => {
  // traverse object along keypath and set value
  // and return whether new value is different from the original value
  // for example if args are {a: 1, b: {c: 3}}, ['b', 'c'], 'newValue'
  // object will be updated to {a: 1, b: {c: 'newValue'}}
  // and the return value will be `true`
  // eslint-disable-next-line eqeqeq
  if (keypath.length == 1 && value !== undefined) {
    const origValue = object[keypath[0]];
    // eslint-disable-next-line no-param-reassign
    object[keypath[0]] = value;

    // return whether value was changed: true for changed, false for not changed
    return !deepEqual(origValue, value);
    // eslint-disable-next-line eqeqeq
  } else if (keypath.length == 0) return false;
  return setKeypathValue(object[keypath[0]], keypath.slice(1), value);
};

export default setKeypathValue;
