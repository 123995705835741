import { STATUS } from "@/utils/constants";
import * as api from "@/apiv2/timeTracking";
import * as plannerApi from "@/apiv2/planner/calendar";
import * as teamsApi from "@/apiv2/teams";
import * as orderTracking from "@/apiv2/orderTracking";
import * as timeTrackingApi from "@/apiv2/timeTracking";
import { handleError } from "@/redux/utils/error";
import * as customerSelectors from "@/redux/customers/customers.selectors";

import {
  SET_IS_FETCHING_SUMMARY_STATUS,
  SET_SUMMARY,
  SET_IS_FETCHING_TEAM_MEMBERS_STATUS,
  SET_TEAM_MEMBERS,
  SET_MODAL_TYPE,
  SET_IS_SAVING_STATUS,
  SET_ACTIVE_TIME_ENTRY,
  SET_IS_FETCHING_ORDER_ITEMS_STATUS,
  SET_ORDER_ITEMS,
  SET_IS_SEARCHING_ORDER_NUMBER_STATUS,
  SET_ORDER_NUMBER_RESULTS,
  SET_USER,
  RESET,
} from "./timeTracking.actionTypes";

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setIsFetchingSummaryStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_SUMMARY_STATUS,
    payload,
  });
};

export const setSummary = (payload) => (dispatch) => {
  dispatch({
    type: SET_SUMMARY,
    payload,
  });
};

export const setTeamMembers = (data) => (dispatch) => {
  dispatch({
    type: SET_TEAM_MEMBERS,
    payload: data,
  });
};

export const setIsFetchingTeamMemberStatus = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_TEAM_MEMBERS_STATUS,
    payload: status,
  });
};

export const setModalType = (payload) => (dispatch) => {
  dispatch({
    type: SET_MODAL_TYPE,
    payload,
  });
};

export const setIsSavingStatus = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_SAVING_STATUS,
    payload: status,
  });
};

export const setActiveTimeEntry = (payload) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_TIME_ENTRY,
    payload,
  });
};

export const setIsFetchingOrderItemsStatus = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_ORDER_ITEMS_STATUS,
    payload: status,
  });
};

export const setOrderItems = (payload) => (dispatch) => {
  dispatch({
    type: SET_ORDER_ITEMS,
    payload,
  });
};

export const setIsSearchingOrderNumberStatus = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_SEARCHING_ORDER_NUMBER_STATUS,
    payload: status,
  });
};

export const setOrderNumberResults = (payload) => (dispatch) => {
  dispatch({
    type: SET_ORDER_NUMBER_RESULTS,
    payload,
  });
};

export const setUser = (payload) => (dispatch) => {
  dispatch({
    type: SET_USER,
    payload,
  });
};

export const searchOrderNumbers = (orderNumber) => async (dispatch) => {
  try {
    dispatch(setIsSearchingOrderNumberStatus(STATUS.PENDING));
    const { data } = await timeTrackingApi.getOrderNumbers({ q: orderNumber })();
    dispatch(setOrderNumberResults(data));
    dispatch(setIsSearchingOrderNumberStatus(STATUS.FULFILLED));
  } catch (error) {
    console.log(error);
    handleError(error, dispatch);
  }
};

export const fetchOrderItems =
  ({ customerUid, orderUid }) =>
  async (dispatch) => {
    try {
      dispatch(setIsFetchingOrderItemsStatus(STATUS.PENDING));
      const { data } = await orderTracking.getLineItemAdditionalProducts({
        customerUid,
        orderUid,
      })();
      dispatch(setOrderItems(data));
      dispatch(setIsFetchingOrderItemsStatus(STATUS.FULFILLED));
    } catch (error) {
      handleError(error, dispatch);
      dispatch(setIsFetchingOrderItemsStatus(STATUS.REJECTED));
    }
  };

export const fetchTimeTrackingSummary =
  ({ customerUid, orderUid }) =>
  async (dispatch) => {
    try {
      dispatch(setIsFetchingSummaryStatus(STATUS.PENDING));
      const { data } = await api.getTimeTrackingSummary({ customerUid, orderUid })();
      dispatch(setSummary(data));
      dispatch(setIsFetchingSummaryStatus(STATUS.FULFILLED));
    } catch (error) {
      handleError(error, dispatch);
      dispatch(setIsFetchingSummaryStatus(STATUS.REJECTED));
    }
  };

export const fetchTeamMembers = () => async (dispatch) => {
  try {
    dispatch(setIsFetchingTeamMemberStatus(STATUS.PENDING));

    const { data } = await teamsApi.getTeams({
      with_inactive: false,
      include_invites: false,
    })();
    dispatch(setTeamMembers(data));
    dispatch(setIsFetchingTeamMemberStatus(STATUS.FULFILLED));
  } catch (error) {
    dispatch(setIsFetchingTeamMemberStatus(STATUS.REJECTED));
    handleError(error, dispatch);
  }
};

export const saveTimeEntry =
  ({ customerUid, orderUid, data, plannerData }) =>
  async (dispatch, getState) => {
    const state = getState();
    const shouldCreatePlannerEntry =
      customerSelectors.getTimeTrackingSettings(state, {
        key: "AUTO_CREATE_PLANNER_ENTRIES",
      })?.isSelected ?? false;

    try {
      dispatch(setIsSavingStatus(STATUS.PENDING));

      const response = await api.postTimeEntry({ customerUid, orderUid, data })();

      if (shouldCreatePlannerEntry && orderUid) {
        const plannerEntryPayload = {
          ...plannerData,
          time_tracking_event: response.data.uid,
          order: orderUid,
          user: data.user,
        };
        await plannerApi.postAssignSchedule(plannerEntryPayload)();
      }

      dispatch(setIsSavingStatus(STATUS.FULFILLED));
      dispatch(setModalType(""));

      return response.data;
    } catch (error) {
      dispatch(setIsSavingStatus(STATUS.REJECTED));
      handleError(error, dispatch);
    }

    return null;
  };

export const updateTimeEntry =
  ({ customerUid, orderUid, timeTrackingUid, data, plannerData } = {}) =>
  async (dispatch, getState) => {
    const state = getState();
    const shouldCreatePlannerEntry =
      customerSelectors.getTimeTrackingSettings(state, {
        key: "AUTO_CREATE_PLANNER_ENTRIES",
      })?.isSelected ?? false;

    try {
      dispatch(setIsSavingStatus(STATUS.PENDING));

      const payload = { ...data };
      const response = await api.patchTimeEntry({
        customerUid,
        orderUid,
        timeTrackingUid,
        data: payload,
      })();

      if (shouldCreatePlannerEntry && data.planner) {
        const plannerEntryPayload = {
          ...plannerData,
          time_tracking_event: response.data.uid,
          uid: data.planner,
          user: data.user,
        };
        await plannerApi.patchAssignSchedule({ uid: data.planner, data: plannerEntryPayload })();
      }

      dispatch(setIsSavingStatus(STATUS.FULFILLED));
      dispatch(setModalType(""));
      return response.data;
    } catch (error) {
      dispatch(setIsSavingStatus(STATUS.REJECTED));
      handleError(error, dispatch);
    }

    return null;
  };

export const removeTimeEntry =
  ({ customerUid, orderUid, uid }) =>
  async (dispatch) => {
    try {
      await api.deleteTimeEntry({ customerUid, orderUid, uid })();
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const removePlannerEntry = (uid) => async () => {
  try {
    await plannerApi.deleteAssignSchedule(uid)();
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const validateUserPin =
  ({ user, pin }) =>
  async (dispatch) => {
    try {
      dispatch(setIsSavingStatus(STATUS.PENDING));
      const { data } = await api.postValidateUserPin({ user, pin })();
      return data;
    } catch (error) {
      return error.response.data;
    } finally {
      dispatch(setIsSavingStatus(STATUS.FULFILLED));
    }
  };
export default {};
