import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import styles from "./Card.module.scss";

const Card = ({ children, className }) => (
  <div className={cx(styles.card, className)}>{children}</div>
);

Card.defaultProps = {
  className: null,
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Card;
