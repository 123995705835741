import Decimal from "decimal.js-light";
import { round } from "lodash";

import {
  PRODUCT_TYPE_LABOUR_PRODUCT_LABEL,
  PRODUCT_TYPE_PRODUCT_KIT_LABEL,
  PRODUCT_TYPE_NOTES_ITEM_LABEL,
  PRICING_STRATEGY_BASIC_QUANTITIES_LABEL,
  PRICING_STRATEGY_LINEAL_METRES_LABEL,
  PRICING_STRATEGY_CUSTOM_FORMULA_LABEL,
  PRICING_STRATEGY_SQUARE_METRES_LABEL,
} from "@/utils/constants";

Decimal.config({ rounding: Decimal.ROUND_HALF_UP });

export const findPricePerMetreDrawing = (options) => {
  const { numberOfBends, totalGirth, dataServer, tapers } = options;

  let value = "0.00";
  const productsGirth = dataServer.filter(
    (item) =>
      totalGirth >= parseFloat(item.get("girthMin")) &&
      totalGirth <= parseFloat(item.get("girthMax"))
  );
  let maxBend = -1;
  let maxProductBend;
  const product = productsGirth.find((item) => {
    const bend = parseFloat(item.get("bends"));

    if (maxBend < bend) {
      maxBend = bend;
      maxProductBend = item;
    }

    if (numberOfBends === bend) {
      return item;
    }

    return null;
  });

  if (product) {
    value = product.get("price");
  } else if (maxProductBend && maxProductBend.get("extraBendFee") > 0) {
    value =
      (numberOfBends - parseFloat(maxProductBend.get("bends"))) *
      maxProductBend.get("extraBendFee");

    value = parseFloat(maxProductBend.get("price")) + value;
    value = round(value, 2).toFixed(2);
  } else if (maxProductBend) {
    value = round(maxProductBend.get("price"), 2).toFixed(2);
  }

  if (tapers && (product || maxProductBend)) {
    const maxProduct = product || maxProductBend;
    const taperedFee = maxProduct.get("taperedFee");

    let tapersValue = parseFloat(taperedFee);

    if (maxProduct.get("addTapersCharge")) {
      tapersValue *= tapers;
    }

    value = parseFloat(value) + tapersValue;
    value = round(value, 2).toFixed(2);
  }

  return value;
};

export const calcPriceAdditionalProducts = (
  items,
  minLengthProducts = 0,
  sumDiscountPercent = "0",
  taxRate = 0.1
) => {
  const sumDiscount = Decimal(sumDiscountPercent);

  // Recalculation of each additional products with deduction of personal and order discounts.
  // To exclude errors in the approximation of hundredths.
  const sumAdditionalProducts = items
    .filter((p) => p.productType !== PRODUCT_TYPE_NOTES_ITEM_LABEL)
    .reduce((sum, item) => {
      if (item.isDeleted) {
        return Decimal(sum);
      }

      if (item.productType === PRODUCT_TYPE_PRODUCT_KIT_LABEL && !item.customPricing) {
        // calculate based on kit items
        const kitItemTotals = item.productKitItems.reduce((kitSum, kitItem) => {
          if (kitItem.customValue) {
            const discountRate = sumDiscount.dividedBy(100).toDecimalPlaces(2);
            const discountedCustomValue = Decimal(kitItem.customValue)
              .times(1 - discountRate)
              .toDecimalPlaces(2);
            return Decimal(kitSum).plus(discountedCustomValue);
          }

          if (!kitItem.value) {
            return Decimal(kitSum);
          }

          const ownDiscount = Decimal(kitItem.discount ? kitItem.discount : 0);
          const discountRate = ownDiscount.dividedBy(100).toDecimalPlaces(2);

          if (kitItem.pricingStrategy === PRICING_STRATEGY_LINEAL_METRES_LABEL) {
            if (!kitItem.lengthItems.length) {
              return Decimal(kitSum);
            }

            const lengthTotal = kitItem.lengthItems.reduce((kitItemSum, kitItemLength) => {
              const length =
                minLengthProducts > kitItemLength.length ? minLengthProducts : kitItemLength.length;
              const totalLength = Decimal(kitItemLength.amount)
                .times(length)
                .dividedBy(1000)
                .toDecimalPlaces(3);
              return Decimal(kitItemSum).plus(totalLength);
            }, 0);
            const lengthProductTotal = Decimal(kitItem.value).times(lengthTotal).toDecimalPlaces(2);
            const productTotalWithDiscount = Decimal(lengthProductTotal)
              .times(1 - discountRate)
              .toDecimalPlaces(2);

            return Decimal(kitSum).plus(productTotalWithDiscount);
          }

          if (kitItem.pricingStrategy === PRICING_STRATEGY_SQUARE_METRES_LABEL) {
            if (!kitItem.lengthItems.length) {
              return Decimal(kitSum);
            }

            const sqmTotal = kitItem.lengthItems.reduce((kitItemSum, kitItemLength) => {
              const totalSqm = Decimal(kitItemLength.length)
                .times(kitItemLength.width)
                .toDecimalPlaces(4);
              return Decimal(kitItemSum).plus(totalSqm);
            }, 0);
            const sqmProductTotal = Decimal(kitItem.value).times(sqmTotal).toDecimalPlaces(2);
            const productTotalWithDiscount = Decimal(sqmProductTotal)
              .times(1 - discountRate)
              .toDecimalPlaces(2);

            return Decimal(kitSum).plus(productTotalWithDiscount);
          }

          if (kitItem.pricingStrategy === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL) {
            const formulaOutput = kitItem.customFormulaOutput;
            const formulaQuantity = Decimal(kitItem.quantity)
              .times(formulaOutput)
              .toDecimalPlaces(4);
            const customFormulaProductTotal = Decimal(kitItem.value)
              .times(formulaQuantity)
              .toDecimalPlaces(2);
            const productTotalWithDiscount = Decimal(customFormulaProductTotal)
              .times(1 - discountRate)
              .toDecimalPlaces(2);

            return Decimal(kitSum).plus(productTotalWithDiscount);
          }

          const total = Decimal(kitItem.value).times(kitItem.quantity).toDecimalPlaces(4);
          const discountedItemTotal = Decimal(total)
            .times(1 - discountRate)
            .toDecimalPlaces(2);
          return Decimal(kitSum).plus(discountedItemTotal);
        }, 0);

        const subKitTotals = item.subKits.reduce((subKitSum, subKit) => {
          const subKitItemTotals = subKit.kitProducts.reduce((kitSum, kitItem) => {
            if (kitItem.customValue) {
              const discountRate = sumDiscount.dividedBy(100).toDecimalPlaces(2);
              const discountedCustomValue = Decimal(kitItem.customValue)
                .times(1 - discountRate)
                .toDecimalPlaces(2);
              return Decimal(kitSum).plus(discountedCustomValue);
            }

            if (!kitItem.value) {
              return Decimal(kitSum);
            }

            const ownDiscount = Decimal(kitItem.discount ? kitItem.discount : 0);
            const discountRate = ownDiscount.dividedBy(100).toDecimalPlaces(2);

            if (kitItem.pricingStrategy === PRICING_STRATEGY_LINEAL_METRES_LABEL) {
              if (!kitItem.lengthItems.length) {
                return Decimal(kitSum);
              }

              const lengthTotal = kitItem.lengthItems.reduce((kitItemSum, kitItemLength) => {
                const length =
                  minLengthProducts > kitItemLength.length
                    ? minLengthProducts
                    : kitItemLength.length;
                const totalLength = Decimal(kitItemLength.amount)
                  .times(length)
                  .dividedBy(1000)
                  .toDecimalPlaces(3);
                return Decimal(kitItemSum).plus(totalLength);
              }, 0);
              const lengthProductTotal = Decimal(kitItem.value)
                .times(lengthTotal)
                .toDecimalPlaces(2);
              const productTotalWithDiscount = Decimal(lengthProductTotal)
                .times(1 - discountRate)
                .toDecimalPlaces(2);
              return Decimal(kitSum).plus(productTotalWithDiscount);
            }

            if (kitItem.pricingStrategy === PRICING_STRATEGY_SQUARE_METRES_LABEL) {
              if (!kitItem.lengthItems.length) {
                return Decimal(kitSum);
              }

              const sqmTotal = kitItem.lengthItems.reduce((kitItemSum, kitItemLength) => {
                const totalSqm = Decimal(kitItemLength.length)
                  .times(kitItemLength.width)
                  .toDecimalPlaces(4);
                return Decimal(kitItemSum).plus(totalSqm);
              }, 0);
              const sqmProductTotal = Decimal(kitItem.value).times(sqmTotal).toDecimalPlaces(2);
              const productTotalWithDiscount = Decimal(sqmProductTotal)
                .times(1 - discountRate)
                .toDecimalPlaces(2);
              return Decimal(kitSum).plus(productTotalWithDiscount);
            }

            if (kitItem.pricingStrategy === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL) {
              const formulaOutput = kitItem.customFormulaOutput;
              const formulaQuantity = Decimal(kitItem.quantity)
                .times(formulaOutput)
                .toDecimalPlaces(4);
              const customFormulaProductTotal = Decimal(kitItem.value)
                .times(formulaQuantity)
                .toDecimalPlaces(2);
              const productTotalWithDiscount = Decimal(customFormulaProductTotal)
                .times(1 - discountRate)
                .toDecimalPlaces(2);
              return Decimal(kitSum).plus(productTotalWithDiscount);
            }

            const total = Decimal(kitItem.value).times(kitItem.quantity).toDecimalPlaces(4);
            const discountedItemTotal = Decimal(total)
              .times(1 - discountRate)
              .toDecimalPlaces(2);
            return Decimal(kitSum).plus(discountedItemTotal);
          }, 0);
          return Decimal(subKitSum).plus(subKitItemTotals);
        }, 0);

        return Decimal(sum).plus(kitItemTotals).plus(subKitTotals);
      }

      if (item.customValue) {
        const discountRate = sumDiscount.dividedBy(100).toDecimalPlaces(2);
        const discountedCustomValue = Decimal(item.customValue)
          .times(1 - discountRate)
          .toDecimalPlaces(2);
        return Decimal(sum).plus(discountedCustomValue);
      }

      if (!item.value) {
        return Decimal(sum);
      }

      const ownDiscount = Decimal(item.discount ? item.discount : 0);
      const discountRate = ownDiscount.dividedBy(100).toDecimalPlaces(2);

      if (item.pricingStrategy === PRICING_STRATEGY_LINEAL_METRES_LABEL) {
        if (!item.subitems.length) {
          return Decimal(sum);
        }

        const lengthTotal = item.subitems.reduce((itemSum, itemLength) => {
          const length =
            minLengthProducts > itemLength.length ? minLengthProducts : itemLength.length;
          const totalLength = Decimal(itemLength.amount)
            .times(length)
            .dividedBy(1000)
            .toDecimalPlaces(3);
          return Decimal(itemSum).plus(totalLength);
        }, 0);
        const lengthProductTotal = Decimal(item.value).times(lengthTotal).toDecimalPlaces(2);
        const productTotalWithDiscount = Decimal(lengthProductTotal)
          .times(1 - discountRate)
          .toDecimalPlaces(2);

        return Decimal(sum).plus(productTotalWithDiscount);
      }

      if (item.pricingStrategy === PRICING_STRATEGY_SQUARE_METRES_LABEL) {
        if (!item.subitems.length) {
          return Decimal(sum);
        }

        const sqmTotal = item.subitems.reduce((itemSum, itemLength) => {
          const totalSqm = Decimal(itemLength.length).times(itemLength.width).toDecimalPlaces(4);
          return Decimal(itemSum).plus(totalSqm);
        }, 0);
        const lengthProductTotal = Decimal(item.value).times(sqmTotal).toDecimalPlaces(2);
        const productTotalWithDiscount = Decimal(lengthProductTotal)
          .times(1 - discountRate)
          .toDecimalPlaces(2);

        return Decimal(sum).plus(productTotalWithDiscount);
      }

      if (item.pricingStrategy === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL) {
        const total = Decimal(item.value)
          .times(item.quantity)
          .times(Decimal(item.customFormulaOutput).toDecimalPlaces(4))
          .toDecimalPlaces(4);

        const discountedItemTotal = Decimal(total)
          .times(1 - discountRate)
          .toDecimalPlaces(2);

        return Decimal(sum).plus(discountedItemTotal);
      }

      const total = Decimal(item.value).times(item.quantity).toDecimalPlaces(4);
      const discountedItemTotal = Decimal(total)
        .times(1 - discountRate)
        .toDecimalPlaces(2);
      return Decimal(sum).plus(discountedItemTotal);
    }, 0);

  const gstAdditionalProducts = items
    .filter((p) => p.productType !== PRODUCT_TYPE_NOTES_ITEM_LABEL && !p.isTaxFree)
    .reduce((sum, item) => {
      if (item.isDeleted) {
        return Decimal(sum);
      }

      if (!item.value) {
        return Decimal(sum);
      }

      if (item.productType === PRODUCT_TYPE_PRODUCT_KIT_LABEL && !item.customPricing) {
        // calculate based on kit items
        const kitGst = item.productKitItems
          .filter((p) => !p.isTaxFree)
          .reduce((kitSum, kitItem) => {
            if (kitItem.customValue) {
              const discountRate = sumDiscount.dividedBy(100).toDecimalPlaces(2);
              const discountedCustomValue = Decimal(kitItem.customValue)
                .times(1 - discountRate)
                .toDecimalPlaces(2);
              const GST = discountedCustomValue.times(taxRate).toDecimalPlaces(2);
              return Decimal(kitSum).plus(GST);
            }

            if (!kitItem.value) {
              return Decimal(kitSum);
            }

            const ownDiscount = Decimal(kitItem.discount ? kitItem.discount : 0);
            const discountRate = ownDiscount.dividedBy(100).toDecimalPlaces(2);

            if (kitItem.pricingStrategy === PRICING_STRATEGY_LINEAL_METRES_LABEL) {
              if (!kitItem.lengthItems.length) {
                return Decimal(kitSum);
              }

              const lengthTotal = kitItem.lengthItems.reduce((kitItemSum, kitItemLength) => {
                const length =
                  minLengthProducts > kitItemLength.length
                    ? minLengthProducts
                    : kitItemLength.length;
                const totalLength = Decimal(kitItemLength.amount)
                  .times(length)
                  .dividedBy(1000)
                  .toDecimalPlaces(3);
                return Decimal(kitItemSum).plus(totalLength);
              }, 0);
              const lengthProductTotal = Decimal(kitItem.value)
                .times(lengthTotal)
                .toDecimalPlaces(2);
              const productTotalWithDiscount = Decimal(lengthProductTotal)
                .times(1 - discountRate)
                .toDecimalPlaces(2);

              const GST = productTotalWithDiscount.times(taxRate).toDecimalPlaces(2);
              return Decimal(kitSum).plus(GST);
            }

            if (kitItem.pricingStrategy === PRICING_STRATEGY_SQUARE_METRES_LABEL) {
              if (!kitItem.lengthItems.length) {
                return Decimal(kitSum);
              }

              const sqmTotal = kitItem.lengthItems.reduce((kitItemSum, kitItemLength) => {
                const totalSqm = Decimal(kitItemLength.length)
                  .times(kitItemLength.width)
                  .toDecimalPlaces(4);
                return Decimal(kitItemSum).plus(totalSqm);
              }, 0);
              const lengthProductTotal = Decimal(kitItem.value).times(sqmTotal).toDecimalPlaces(2);
              const productTotalWithDiscount = Decimal(lengthProductTotal)
                .times(1 - discountRate)
                .toDecimalPlaces(2);

              const GST = productTotalWithDiscount.times(taxRate).toDecimalPlaces(2);
              return Decimal(kitSum).plus(GST);
            }

            if (kitItem.pricingStrategy === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL) {
              const formulaOutput = kitItem.customFormulaOutput;
              const formulaQuantity = Decimal(kitItem.quantity)
                .times(formulaOutput)
                .toDecimalPlaces(4);
              const customFormulaProductTotal = Decimal(kitItem.value)
                .times(formulaQuantity)
                .toDecimalPlaces(2);
              const productTotalWithDiscount = Decimal(customFormulaProductTotal)
                .times(1 - discountRate)
                .toDecimalPlaces(2);

              const GST = productTotalWithDiscount.times(taxRate).toDecimalPlaces(2);
              return Decimal(kitSum).plus(GST);
            }

            const total = Decimal(kitItem.value).times(kitItem.quantity).toDecimalPlaces(4);
            const discountedItemTotal = Decimal(total)
              .times(1 - discountRate)
              .toDecimalPlaces(2);
            const GST = discountedItemTotal.times(taxRate).toDecimalPlaces(2);
            return Decimal(kitSum).plus(GST);
          }, 0);

        const subKitsGst = item.subKits.reduce((subKitSum, subKit) => {
          const subKitGst = subKit.kitProducts
            .filter((p) => !p.isTaxFree)
            .reduce((kitSum, kitItem) => {
              if (kitItem.customValue) {
                const discountRate = sumDiscount.dividedBy(100).toDecimalPlaces(2);
                const discountedCustomValue = Decimal(kitItem.customValue)
                  .times(1 - discountRate)
                  .toDecimalPlaces(2);
                const GST = discountedCustomValue.times(taxRate).toDecimalPlaces(2);
                return Decimal(kitSum).plus(GST);
              }

              if (!kitItem.value) {
                return Decimal(kitSum);
              }

              const ownDiscount = Decimal(kitItem.discount ? kitItem.discount : 0);
              const discountRate = ownDiscount.dividedBy(100).toDecimalPlaces(2);

              if (kitItem.pricingStrategy === PRICING_STRATEGY_LINEAL_METRES_LABEL) {
                if (!kitItem.lengthItems.length) {
                  return Decimal(kitSum);
                }

                const lengthTotal = kitItem.lengthItems.reduce((kitItemSum, kitItemLength) => {
                  const length =
                    minLengthProducts > kitItemLength.length
                      ? minLengthProducts
                      : kitItemLength.length;
                  const totalLength = Decimal(kitItemLength.amount)
                    .times(length)
                    .dividedBy(1000)
                    .toDecimalPlaces(3);
                  return Decimal(kitItemSum).plus(totalLength);
                }, 0);
                const lengthProductTotal = Decimal(kitItem.value)
                  .times(lengthTotal)
                  .toDecimalPlaces(2);
                const productTotalWithDiscount = Decimal(lengthProductTotal)
                  .times(1 - discountRate)
                  .toDecimalPlaces(2);

                const GST = productTotalWithDiscount.times(taxRate).toDecimalPlaces(2);
                return Decimal(kitSum).plus(GST);
              }

              if (kitItem.pricingStrategy === PRICING_STRATEGY_SQUARE_METRES_LABEL) {
                if (!kitItem.lengthItems.length) {
                  return Decimal(kitSum);
                }

                const sqmTotal = kitItem.lengthItems.reduce((kitItemSum, kitItemLength) => {
                  const totalSqm = Decimal(kitItemLength.length)
                    .times(kitItemLength.width)
                    .toDecimalPlaces(4);
                  return Decimal(kitItemSum).plus(totalSqm);
                }, 0);
                const lengthProductTotal = Decimal(kitItem.value)
                  .times(sqmTotal)
                  .toDecimalPlaces(2);
                const productTotalWithDiscount = Decimal(lengthProductTotal)
                  .times(1 - discountRate)
                  .toDecimalPlaces(2);

                const GST = productTotalWithDiscount.times(taxRate).toDecimalPlaces(2);
                return Decimal(kitSum).plus(GST);
              }

              if (kitItem.pricingStrategy === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL) {
                const formulaOutput = kitItem.customFormulaOutput;
                const formulaQuantity = Decimal(kitItem.quantity)
                  .times(formulaOutput)
                  .toDecimalPlaces(4);
                const customFormulaProductTotal = Decimal(kitItem.value)
                  .times(formulaQuantity)
                  .toDecimalPlaces(2);
                const productTotalWithDiscount = Decimal(customFormulaProductTotal)
                  .times(1 - discountRate)
                  .toDecimalPlaces(2);

                const GST = productTotalWithDiscount.times(taxRate).toDecimalPlaces(2);
                return Decimal(kitSum).plus(GST);
              }

              const total = Decimal(kitItem.value).times(kitItem.quantity).toDecimalPlaces(4);
              const discountedItemTotal = Decimal(total)
                .times(1 - discountRate)
                .toDecimalPlaces(2);
              const GST = discountedItemTotal.times(taxRate).toDecimalPlaces(2);
              return Decimal(kitSum).plus(GST);
            }, 0);
          return Decimal(subKitSum).plus(subKitGst);
        }, 0);

        return Decimal(sum).plus(kitGst).plus(subKitsGst);
      }

      if (item.customValue) {
        const discountRate = sumDiscount.dividedBy(100).toDecimalPlaces(2);
        const discountedCustomValue = Decimal(item.customValue)
          .times(1 - discountRate)
          .toDecimalPlaces(2);
        const GST = discountedCustomValue.times(taxRate).toDecimalPlaces(2);
        return Decimal(sum).plus(GST);
      }

      const ownDiscount = Decimal(item.discount ? item.discount : 0);
      const discountRate = ownDiscount.dividedBy(100).toDecimalPlaces(2);

      if (item.pricingStrategy === PRICING_STRATEGY_LINEAL_METRES_LABEL) {
        if (!item.subitems.length) {
          return Decimal(sum);
        }

        const lengthTotal = item.subitems.reduce((itemSum, itemLength) => {
          const length =
            minLengthProducts > itemLength.length ? minLengthProducts : itemLength.length;
          const totalLength = Decimal(itemLength.amount)
            .times(length)
            .dividedBy(1000)
            .toDecimalPlaces(3);
          return Decimal(itemSum).plus(totalLength);
        }, 0);
        const lengthProductTotal = Decimal(item.value).times(lengthTotal).toDecimalPlaces(2);
        const productTotalWithDiscount = Decimal(lengthProductTotal)
          .times(1 - discountRate)
          .toDecimalPlaces(2);

        const GST = productTotalWithDiscount.times(taxRate).toDecimalPlaces(2);
        return Decimal(sum).plus(GST);
      }

      if (item.pricingStrategy === PRICING_STRATEGY_SQUARE_METRES_LABEL) {
        if (!item.subitems.length) {
          return Decimal(sum);
        }

        const sqmTotal = item.subitems.reduce((itemSum, itemLength) => {
          const totalSqm = Decimal(itemLength.length).times(itemLength.width).toDecimalPlaces(3);
          return Decimal(itemSum).plus(totalSqm);
        }, 0);
        const lengthProductTotal = Decimal(item.value).times(sqmTotal).toDecimalPlaces(2);
        const productTotalWithDiscount = Decimal(lengthProductTotal)
          .times(1 - discountRate)
          .toDecimalPlaces(2);

        const GST = productTotalWithDiscount.times(taxRate).toDecimalPlaces(2);
        return Decimal(sum).plus(GST);
      }

      if (item.pricingStrategy === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL) {
        const total = Decimal(item.value)
          .times(item.quantity)
          .times(Decimal(item.customFormulaOutput).toDecimalPlaces(4))
          .toDecimalPlaces(4);

        const discountedItemTotal = Decimal(total)
          .times(1 - discountRate)
          .toDecimalPlaces(2);
        const GST = discountedItemTotal.times(taxRate).toDecimalPlaces(2);
        return Decimal(sum).plus(GST);
      }

      const total = Decimal(item.value).times(item.quantity).toDecimalPlaces(4);
      const discountedItemTotal = Decimal(total)
        .times(1 - discountRate)
        .toDecimalPlaces(2);
      const GST = discountedItemTotal.times(taxRate).toDecimalPlaces(2);
      return Decimal(sum).plus(GST);
    }, 0);

  const costAdditionalProducts = items
    .filter((p) => p.productType !== PRODUCT_TYPE_NOTES_ITEM_LABEL)
    .reduce((sum, item) => {
      if (item.isDeleted) {
        return Decimal(sum);
      }

      // Standard kits calc
      if (item.productType === PRODUCT_TYPE_PRODUCT_KIT_LABEL && !item.customPricing) {
        // calculate based on kit items
        const kitCost = item.productKitItems.reduce((kitSum, kitItem) => {
          const cost = kitItem.cost ? Decimal(kitItem.cost) : null;

          if (!cost || cost <= 0) {
            return Decimal(kitSum);
          }

          if (kitItem.pricingStrategy === PRICING_STRATEGY_BASIC_QUANTITIES_LABEL) {
            const totalCost = cost.times(kitItem.actualQuantity);
            return Decimal(kitSum).plus(totalCost);
          } else if (kitItem.pricingStrategy === PRICING_STRATEGY_LINEAL_METRES_LABEL) {
            const lengthTotal = kitItem.actualLengthItems.reduce((kitItemSum, kitItemLength) => {
              const length =
                minLengthProducts > kitItemLength.length ? minLengthProducts : kitItemLength.length;
              const totalLength = Decimal(kitItemLength.amount)
                .times(length)
                .dividedBy(1000)
                .toDecimalPlaces(3);
              return Decimal(kitItemSum).plus(totalLength);
            }, 0);

            const totalCost = cost.times(lengthTotal);
            return Decimal(kitSum).plus(totalCost);
          } else if (kitItem.pricingStrategy === PRICING_STRATEGY_SQUARE_METRES_LABEL) {
            const sqmTotal = kitItem.actualLengthItems.reduce((kitItemSum, kitItemLength) => {
              const totalSqm = Decimal(kitItemLength.length)
                .times(kitItemLength.width)
                .toDecimalPlaces(4);
              return Decimal(kitItemSum).plus(totalSqm);
            }, 0);

            const totalCost = cost.times(sqmTotal);
            return Decimal(kitSum).plus(totalCost);
          } else if (kitItem.pricingStrategy === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL) {
            const formulaQuantity = Decimal(kitItem.actualQuantity).times(
              kitItem.actualCustomFormulaOutput
            );
            const totalCost = cost.times(formulaQuantity);
            return Decimal(kitSum).plus(totalCost);
          }

          return Decimal(kitSum);
        }, 0);

        const subKitsCost = item.subKits.reduce((subKitSum, subKit) => {
          const subKitCost = subKit.kitProducts.reduce((kitSum, kitItem) => {
            const cost = kitItem.cost ? Decimal(kitItem.cost) : null;

            if (!cost || cost <= 0) {
              return Decimal(kitSum);
            }

            if (kitItem.pricingStrategy === PRICING_STRATEGY_BASIC_QUANTITIES_LABEL) {
              const totalCost = cost.times(kitItem.actualQuantity);
              return Decimal(kitSum).plus(totalCost);
            } else if (kitItem.pricingStrategy === PRICING_STRATEGY_LINEAL_METRES_LABEL) {
              const lengthTotal = kitItem.actualLengthItems.reduce((kitItemSum, kitItemLength) => {
                const length =
                  minLengthProducts > kitItemLength.length
                    ? minLengthProducts
                    : kitItemLength.length;
                const totalLength = Decimal(kitItemLength.amount)
                  .times(length)
                  .dividedBy(1000)
                  .toDecimalPlaces(3);
                return Decimal(kitItemSum).plus(totalLength);
              }, 0);

              const totalCost = cost.times(lengthTotal);
              return Decimal(kitSum).plus(totalCost);
            } else if (kitItem.pricingStrategy === PRICING_STRATEGY_SQUARE_METRES_LABEL) {
              const sqmTotal = kitItem.actualLengthItems.reduce((kitItemSum, kitItemLength) => {
                const totalSqm = Decimal(kitItemLength.length)
                  .times(kitItemLength.width)
                  .toDecimalPlaces(4);
                return Decimal(kitItemSum).plus(totalSqm);
              }, 0);

              const totalCost = cost.times(sqmTotal);
              return Decimal(kitSum).plus(totalCost);
            } else if (kitItem.pricingStrategy === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL) {
              const formulaQuantity = Decimal(kitItem.actualQuantity).times(
                kitItem.actualCustomFormulaOutput
              );
              const totalCost = cost.times(formulaQuantity);
              return Decimal(kitSum).plus(totalCost);
            }

            return Decimal(kitSum);
          }, 0);
          return Decimal(subKitSum).plus(subKitCost);
        }, 0);

        return Decimal(sum).plus(kitCost).plus(subKitsCost);
      }

      const cost = item.cost ? Decimal(item.cost) : null;

      if (!cost || cost <= 0) {
        return Decimal(sum);
      }

      if (
        item.pricingStrategy === PRICING_STRATEGY_BASIC_QUANTITIES_LABEL ||
        (item.productType === PRODUCT_TYPE_PRODUCT_KIT_LABEL && item.customPricing)
      ) {
        const totalCost = cost.times(item.actualQuantity);
        return Decimal(sum).plus(totalCost);
      } else if (item.pricingStrategy === PRICING_STRATEGY_LINEAL_METRES_LABEL) {
        const lengthTotal = item.actualLengthItems.reduce((itemSum, itemLength) => {
          const length =
            minLengthProducts > itemLength.length ? minLengthProducts : itemLength.length;
          const totalLength = Decimal(itemLength.amount)
            .times(length)
            .dividedBy(1000)
            .toDecimalPlaces(3);
          return Decimal(itemSum).plus(totalLength);
        }, 0);

        const totalCost = cost.times(lengthTotal);
        return Decimal(sum).plus(totalCost);
      } else if (item.pricingStrategy === PRICING_STRATEGY_SQUARE_METRES_LABEL) {
        const sqmTotal = item.actualLengthItems.reduce((itemSum, itemLength) => {
          const totalSqm = Decimal(itemLength.length).times(itemLength.width).toDecimalPlaces(4);
          return Decimal(itemSum).plus(totalSqm);
        }, 0);

        const totalCost = cost.times(sqmTotal);
        return Decimal(sum).plus(totalCost);
      } else if (item.pricingStrategy === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL) {
        const formulaQuantity = Decimal(item.actualQuantity).times(item.actualCustomFormulaOutput);
        const totalCost = cost.times(formulaQuantity);
        return Decimal(sum).plus(totalCost);
      }

      return Decimal(sum);
    }, 0);

  const sumMarkupAdditionalProducts = items
    .filter((p) => p.productType !== PRODUCT_TYPE_NOTES_ITEM_LABEL)
    .reduce((sum, item) => {
      if (item.isDeleted) {
        return Decimal(sum);
      }

      if (!item.value) {
        return Decimal(sum);
      }

      if (item.productType === PRODUCT_TYPE_PRODUCT_KIT_LABEL && !item.customPricing) {
        // calculate based on kit items
        const kitMarkup = item.productKitItems.reduce((kitSum, kitItem) => {
          if (!kitItem.cost || Decimal(kitItem.cost) <= 0) {
            return Decimal(kitSum);
          }

          if (kitItem.customValue) {
            const discountRate = sumDiscount.dividedBy(100).toDecimalPlaces(2);
            const discountedCustomValue = Decimal(kitItem.customValue)
              .times(1 - discountRate)
              .toDecimalPlaces(2);
            return Decimal(kitSum).plus(discountedCustomValue);
          }

          if (!kitItem.value) {
            return Decimal(kitSum);
          }

          const ownDiscount = Decimal(kitItem.discount ? kitItem.discount : 0);
          const discountRate = ownDiscount.dividedBy(100).toDecimalPlaces(2);

          if (kitItem.pricingStrategy === PRICING_STRATEGY_LINEAL_METRES_LABEL) {
            if (!kitItem.lengthItems.length) {
              return Decimal(kitSum);
            }

            const lengthTotal = kitItem.lengthItems.reduce((kitItemSum, kitItemLength) => {
              const length =
                minLengthProducts > kitItemLength.length ? minLengthProducts : kitItemLength.length;
              const totalLength = Decimal(kitItemLength.amount)
                .times(length)
                .dividedBy(1000)
                .toDecimalPlaces(3);
              return Decimal(kitItemSum).plus(totalLength);
            }, 0);
            const lengthProductTotal = Decimal(kitItem.value).times(lengthTotal).toDecimalPlaces(2);
            const productTotalWithDiscount = Decimal(lengthProductTotal)
              .times(1 - discountRate)
              .toDecimalPlaces(2);

            return Decimal(kitSum).plus(productTotalWithDiscount);
          } else if (kitItem.pricingStrategy === PRICING_STRATEGY_SQUARE_METRES_LABEL) {
            if (!kitItem.lengthItems.length) {
              return Decimal(kitSum);
            }

            const sqmTotal = kitItem.lengthItems.reduce((kitItemSum, kitItemLength) => {
              const totalSqm = Decimal(kitItemLength.length)
                .times(kitItemLength.width)
                .toDecimalPlaces(4);
              return Decimal(kitItemSum).plus(totalSqm);
            }, 0);
            const lengthProductTotal = Decimal(kitItem.value).times(sqmTotal).toDecimalPlaces(2);
            const productTotalWithDiscount = Decimal(lengthProductTotal)
              .times(1 - discountRate)
              .toDecimalPlaces(2);

            return Decimal(kitSum).plus(productTotalWithDiscount);
          } else if (kitItem.pricingStrategy === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL) {
            const total = Decimal(kitItem.value)
              .times(kitItem.quantity)
              .times(Decimal(kitItem.customFormulaOutput).toDecimalPlaces(4))
              .toDecimalPlaces(4);
            const discountedItemTotal = Decimal(total)
              .times(1 - discountRate)
              .toDecimalPlaces(2);
            return Decimal(kitSum).plus(discountedItemTotal);
          }

          const total = Decimal(kitItem.value).times(kitItem.quantity).toDecimalPlaces(4);
          const discountedItemTotal = Decimal(total)
            .times(1 - discountRate)
            .toDecimalPlaces(2);
          return Decimal(kitSum).plus(discountedItemTotal);
        }, 0);

        const subKitsMarkup = item.subKits.reduce((subKitSum, subKit) => {
          const subKitMarkup = subKit.kitProducts.reduce((kitSum, kitItem) => {
            if (!kitItem.cost || Decimal(kitItem.cost) <= 0) {
              return Decimal(kitSum);
            }

            if (kitItem.customValue) {
              const discountRate = sumDiscount.dividedBy(100).toDecimalPlaces(2);
              const discountedCustomValue = Decimal(kitItem.customValue)
                .times(1 - discountRate)
                .toDecimalPlaces(2);
              return Decimal(kitSum).plus(discountedCustomValue);
            }

            if (!kitItem.value) {
              return Decimal(kitSum);
            }

            const ownDiscount = Decimal(kitItem.discount ? kitItem.discount : 0);
            const discountRate = ownDiscount.dividedBy(100).toDecimalPlaces(2);

            if (kitItem.pricingStrategy === PRICING_STRATEGY_LINEAL_METRES_LABEL) {
              if (!kitItem.lengthItems.length) {
                return Decimal(kitSum);
              }

              const lengthTotal = kitItem.lengthItems.reduce((kitItemSum, kitItemLength) => {
                const length =
                  minLengthProducts > kitItemLength.length
                    ? minLengthProducts
                    : kitItemLength.length;
                const totalLength = Decimal(kitItemLength.amount)
                  .times(length)
                  .dividedBy(1000)
                  .toDecimalPlaces(3);
                return Decimal(kitItemSum).plus(totalLength);
              }, 0);
              const lengthProductTotal = Decimal(kitItem.value)
                .times(lengthTotal)
                .toDecimalPlaces(2);
              const productTotalWithDiscount = Decimal(lengthProductTotal)
                .times(1 - discountRate)
                .toDecimalPlaces(2);

              return Decimal(kitSum).plus(productTotalWithDiscount);
            } else if (kitItem.pricingStrategy === PRICING_STRATEGY_SQUARE_METRES_LABEL) {
              if (!kitItem.lengthItems.length) {
                return Decimal(kitSum);
              }

              const sqmTotal = kitItem.lengthItems.reduce((kitItemSum, kitItemLength) => {
                const totalSqm = Decimal(kitItemLength.length)
                  .times(kitItemLength.width)
                  .toDecimalPlaces(4);
                return Decimal(kitItemSum).plus(totalSqm);
              }, 0);
              const lengthProductTotal = Decimal(kitItem.value).times(sqmTotal).toDecimalPlaces(2);
              const productTotalWithDiscount = Decimal(lengthProductTotal)
                .times(1 - discountRate)
                .toDecimalPlaces(2);

              return Decimal(kitSum).plus(productTotalWithDiscount);
            } else if (kitItem.pricingStrategy === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL) {
              const total = Decimal(kitItem.value)
                .times(kitItem.quantity)
                .times(Decimal(kitItem.customFormulaOutput).toDecimalPlaces(4))
                .toDecimalPlaces(4);
              const discountedItemTotal = Decimal(total)
                .times(1 - discountRate)
                .toDecimalPlaces(2);
              return Decimal(kitSum).plus(discountedItemTotal);
            }

            const total = Decimal(kitItem.value).times(kitItem.quantity).toDecimalPlaces(4);
            const discountedItemTotal = Decimal(total)
              .times(1 - discountRate)
              .toDecimalPlaces(2);
            return Decimal(kitSum).plus(discountedItemTotal);
          }, 0);
          return Decimal(subKitSum).plus(subKitMarkup);
        }, 0);

        return Decimal(sum).plus(kitMarkup).plus(subKitsMarkup);
      }

      if (!item.cost || Decimal(item.cost) <= 0) {
        return Decimal(sum);
      }

      if (item.customValue) {
        const discountRate = sumDiscount.dividedBy(100).toDecimalPlaces(2);
        const discountedCustomValue = Decimal(item.customValue)
          .times(1 - discountRate)
          .toDecimalPlaces(2);
        return Decimal(sum).plus(discountedCustomValue);
      }

      const ownDiscount = Decimal(item.discount ? item.discount : 0);
      const discountRate = ownDiscount.dividedBy(100).toDecimalPlaces(2);

      if (item.pricingStrategy === PRICING_STRATEGY_LINEAL_METRES_LABEL) {
        if (!item.subitems.length) {
          return Decimal(sum);
        }

        const lengthTotal = item.subitems.reduce((itemSum, itemLength) => {
          const length =
            minLengthProducts > itemLength.length ? minLengthProducts : itemLength.length;
          const totalLength = Decimal(itemLength.amount)
            .times(length)
            .dividedBy(1000)
            .toDecimalPlaces(3);
          return Decimal(itemSum).plus(totalLength);
        }, 0);
        const lengthProductTotal = Decimal(item.value).times(lengthTotal).toDecimalPlaces(2);
        const productTotalWithDiscount = Decimal(lengthProductTotal)
          .times(1 - discountRate)
          .toDecimalPlaces(2);

        return Decimal(sum).plus(productTotalWithDiscount);
      } else if (item.pricingStrategy === PRICING_STRATEGY_SQUARE_METRES_LABEL) {
        if (!item.subitems.length) {
          return Decimal(sum);
        }

        const sqmTotal = item.subitems.reduce((itemSum, itemLength) => {
          const totalSqm = Decimal(itemLength.length).times(itemLength.width).toDecimalPlaces(4);
          return Decimal(itemSum).plus(totalSqm);
        }, 0);
        const lengthProductTotal = Decimal(item.value).times(sqmTotal).toDecimalPlaces(2);
        const productTotalWithDiscount = Decimal(lengthProductTotal)
          .times(1 - discountRate)
          .toDecimalPlaces(2);

        return Decimal(sum).plus(productTotalWithDiscount);
      } else if (item.pricingStrategy === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL) {
        const total = Decimal(item.value)
          .times(item.quantity)
          .times(Decimal(item.customFormulaOutput).toDecimalPlaces(4))
          .toDecimalPlaces(4);

        const discountedItemTotal = Decimal(total)
          .times(1 - discountRate)
          .toDecimalPlaces(2);

        return Decimal(sum).plus(discountedItemTotal);
      }

      const total = Decimal(item.value).times(item.quantity).toDecimalPlaces(4);
      const discountedItemTotal = Decimal(total)
        .times(1 - discountRate)
        .toDecimalPlaces(2);
      return Decimal(sum).plus(discountedItemTotal);
    }, 0);

  const labourAdditionalProducts = items
    .filter((p) =>
      [PRODUCT_TYPE_LABOUR_PRODUCT_LABEL, PRODUCT_TYPE_PRODUCT_KIT_LABEL].includes(p.productType)
    )
    .reduce((sum, item) => {
      if (item.isDeleted) {
        return Decimal(sum);
      }

      if (item.productType === PRODUCT_TYPE_PRODUCT_KIT_LABEL) {
        // calculate based on kit items
        const kitMarkup = item.productKitItems
          .filter((kitItem) => kitItem.productType === PRODUCT_TYPE_LABOUR_PRODUCT_LABEL)
          .reduce((kitSum, kitItem) => {
            if (kitItem.customValue) {
              const discountRate = sumDiscount.dividedBy(100).toDecimalPlaces(2);
              const discountedCustomValue = Decimal(kitItem.customValue)
                .times(1 - discountRate)
                .toDecimalPlaces(2);
              return Decimal(kitSum).plus(discountedCustomValue);
            }

            if (!kitItem.value) {
              return Decimal(kitSum);
            }

            const ownDiscount = Decimal(kitItem.discount ? kitItem.discount : 0);
            const discountRate = ownDiscount.dividedBy(100).toDecimalPlaces(2);

            const total = Decimal(kitItem.value).times(kitItem.quantity).toDecimalPlaces(4);
            const discountedItemTotal = Decimal(total)
              .times(1 - discountRate)
              .toDecimalPlaces(2);
            return Decimal(kitSum).plus(discountedItemTotal);
          }, 0);

        // calculate sub kits
        const subKitsMarkup = item.subKits
          .flatMap((i) =>
            i.kitProducts
              .filter((k) => k.productType === PRODUCT_TYPE_LABOUR_PRODUCT_LABEL)
              .map((k) => ({ ...k }))
          )
          .reduce((kitSum, kitItem) => {
            if (kitItem.customValue) {
              const discountRate = sumDiscount.dividedBy(100).toDecimalPlaces(2);
              const discountedCustomValue = Decimal(kitItem.customValue)
                .times(1 - discountRate)
                .toDecimalPlaces(2);
              return Decimal(kitSum).plus(discountedCustomValue);
            }

            if (!kitItem.value) {
              return Decimal(kitSum);
            }

            const ownDiscount = Decimal(kitItem.discount ? kitItem.discount : 0);
            const discountRate = ownDiscount.dividedBy(100).toDecimalPlaces(2);

            const total = Decimal(kitItem.value).times(kitItem.quantity).toDecimalPlaces(4);
            const discountedItemTotal = Decimal(total)
              .times(1 - discountRate)
              .toDecimalPlaces(2);
            return Decimal(kitSum).plus(discountedItemTotal);
          }, 0);

        return Decimal(sum).plus(kitMarkup).plus(subKitsMarkup);
      }

      const ownDiscount = Decimal(item.discount ? item.discount : 0);
      const discountRate = ownDiscount.dividedBy(100).toDecimalPlaces(2);

      const total = Decimal(item.value).times(item.quantity).toDecimalPlaces(4);
      const discountedItemTotal = Decimal(total)
        .times(1 - discountRate)
        .toDecimalPlaces(2);
      return Decimal(sum).plus(discountedItemTotal);
    }, 0);

  return {
    labourAdditionalProducts: labourAdditionalProducts.toFixed(2),
    sumAdditionalProducts: sumAdditionalProducts.toFixed(2),
    gstAdditionalProducts: gstAdditionalProducts.toFixed(2),
    costAdditionalProducts: costAdditionalProducts.toFixed(4),
    sumMarkupAdditionalProducts: sumMarkupAdditionalProducts.toFixed(2),
  };
};

export const calcPricesDrawings = (items) => {
  let sumDrawingsCustomPrices = 0;
  let GST = 0;
  const sumDrawingsPrices = Object.keys(items).reduce((price, key) => {
    let drawingPrice = 0;
    const item = items[key];

    if (!item.isDeleted) {
      const customPrice = item.customPrice ? parseFloat(item.customPrice) : 0;
      sumDrawingsCustomPrices += customPrice;

      if (item.customPrice) {
        drawingPrice = customPrice;
      } else if (item.totalPrice) {
        drawingPrice = item.totalPrice;
      } else {
        drawingPrice = 0;
      }
    }

    const drawingGst = drawingPrice * 0.1;
    GST += round(drawingGst, 2);
    return price + round(drawingPrice, 2);
  }, 0);

  return {
    sumDrawingsCustomPrices: round(sumDrawingsCustomPrices, 2).toFixed(2),
    sumDrawingsPrices: round(sumDrawingsPrices, 2).toFixed(2),
    gstDrawingsPrices: round(GST, 2).toFixed(2),
  };
};

export const calcTotalPriceDrawing = ({ totalLength, pricePerMeter }) => {
  const totalPrice = new Decimal(pricePerMeter).times(totalLength / 1000).toFixed(3, 1);
  return round(totalPrice, 2).toFixed(2);
};

export const calcSubTotalPrice = (
  sumAdditionalPrices,
  sumAdditionalProducts,
  sumDrawingsPrices,
  sumDiscountPercent
) => {
  const totalDiscountRate = Decimal(sumDiscountPercent).dividedBy(100).toDecimalPlaces(2);
  const subTotalWithDiscount = Decimal(sumAdditionalProducts)
    .plus(sumDrawingsPrices)
    .times(1 - totalDiscountRate)
    .toDecimalPlaces(2);
  return subTotalWithDiscount.plus(sumAdditionalPrices).toDecimalPlaces(2);
};

export default findPricePerMetreDrawing;
