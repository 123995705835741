import { createApiV2 } from "./common";

export const getPriceLevels = (data) => (token) => createApiV2("GET", "price_levels/")(token, data);

export const getCustomers = (data) => (token) =>
  createApiV2("GET", "price_levels/unassigned/")(token, data);

export const createPriceLevel = (data) => (token) =>
  createApiV2("POST", "price_levels/")(token, data);

export const updatePriceLevel =
  ({ priceLevelUid, data }) =>
  (token) =>
    createApiV2("PATCH", `price_levels/${priceLevelUid}/`)(token, data);

export const updatePriceLevelIndex =
  ({ data }) =>
  (token) =>
    createApiV2("POST", `price_levels/assign_index/`)(token, data);

export const deletePriceLevel = (priceLevelUid) => (token) =>
  createApiV2("DELETE", `price_levels/${priceLevelUid}/`)(token);

export const undoDeletePriceLevel = (data) => (token) =>
  createApiV2("POST", `price_levels/undestroy/`)(token, data);

export const postAssignCustomersToPriceLevel =
  ({ priceLevelUid, data }) =>
  (token) =>
    createApiV2("POST", `price_levels/${priceLevelUid}/customers/`)(token, data);

export const postAssignCustomersToUnassigned = (data) => (token) =>
  createApiV2("POST", `price_levels/bulk_unassign/`)(token, data);
