import { orderBy } from "lodash";

import { userSelector } from "../../customers/customers.selectors";

import { MODULE } from "./userFilter.actionTypes";

export const isFetchingUsers = (state) => state.getIn([MODULE, "isFetchingUsers"]);

export const getUsers = (state) => {
  const currentUser = userSelector(state);
  const users = state.getIn([MODULE, "users"]);

  const formattedAllUsers = users.map((a) =>
    a.id === currentUser.id ? { ...a, lastName: `${a.lastName} (You)` } : a
  );
  const sortedUsers = [
    ...[formattedAllUsers.find((a) => a.id === currentUser.id)],
    ...orderBy(
      formattedAllUsers.filter((a) => a.id !== currentUser.id),
      [(a) => a.firstName.toLowerCase()],
      "asc"
    ),
  ].filter((a) => !!a);

  return sortedUsers;
};

export const getSelectedUsers = (state) => state.getIn([MODULE, "selectedUsers"]);
