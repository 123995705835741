import { MODULE } from "./priceLevels.actionTypes";

export const isLoaded = (state) =>
  !state.getIn([MODULE, "isFetchingCustomers"]) && !state.getIn([MODULE, "isFetchingPriceLevels"]);

export const isDeletePriceLevelModalOpen = (state) =>
  state.getIn([MODULE, "isDeletePriceLevelModalOpen"]);

export const getPriceLevels = (state) => state.getIn([MODULE, "priceLevels"]);

export const getCustomers = (state) => state.getIn([MODULE, "customers"]);

export const getSearch = (state) => state.getIn([MODULE, "search"]);

export const getToBeDeletedPriceLevelUid = (state) =>
  state.getIn([MODULE, "toBeDeletedPriceLevelUid"]);

export const getRecentlyDeletedPriceLevelUid = (state) =>
  state.getIn([MODULE, "recentlyDeletedPriceLevelUid"]);

export const isFetchingPriceLevels = (state) => state.getIn([MODULE, "isFetchingPriceLevels"]);
