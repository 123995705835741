import {
  SET_IS_FETCHING,
  SET_IS_PRINTING,
  SET_DRAWINGS_LIST,
  SET_PRODUCTS_FROM_SALES_ORDER,
  SET_DRAWINGS_FROM_SALES_ORDER,
  SET_SELECTED_PRODUCT_UIDS,
  SET_SELECTED_DRAWING_UIDS,
  SET_NEW_UPLOADED_ATTACHMENTS,
  SET_ORDER_ATTACHMENTS,
  POST_ADD_ATTACHMENTS,
  SET_SELECTED_ATTACHMENTS,
  REMOVE_SELECTED_ATTACHMENT,
  UPDATE_SELECTED_ATTACHMENTS,
  RESET,
  SET_IS_ATTACHMENTS_LOADING,
  SET_IS_MODAL_LOADING,
} from "./customWorkOrderPrintModal.actionTypes";

const initialState = {
  isPrinting: false,
  isFetching: false,
  items: [],
  drawingItems: [],
  drawingsList: null,
  selectedProductUids: [],
  selectedDrawingUids: [],
  attachments: [],
  selectedAttachments: [],
  isAttachmentsLoading: false,
  newUploadedAttachments: [],
  isModalLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case `${SET_PRODUCTS_FROM_SALES_ORDER}/fulfilled`: {
      const { data } = action.payload;

      const items = data.filter((item) => item.productType !== "Flashing");

      return {
        ...state,
        items,
      };
    }

    case `${SET_DRAWINGS_FROM_SALES_ORDER}/fulfilled`: {
      const { data } = action.payload;

      return {
        ...state,
        drawingItems: data,
      };
    }

    case SET_DRAWINGS_LIST: {
      return {
        ...state,
        drawingsList: action.payload,
      };
    }

    case SET_SELECTED_PRODUCT_UIDS: {
      const { selectedProductUids } = action.payload;
      return { ...state, selectedProductUids };
    }

    case SET_SELECTED_DRAWING_UIDS: {
      const { selectedDrawingUids } = action.payload;
      return { ...state, selectedDrawingUids };
    }

    case SET_IS_FETCHING: {
      return { ...state, isFetching: action.payload };
    }

    case SET_IS_PRINTING: {
      return { ...state, isPrinting: action.payload };
    }

    case SET_NEW_UPLOADED_ATTACHMENTS: {
      return { ...state, newUploadedAttachments: action.payload };
    }

    case `${SET_ORDER_ATTACHMENTS}`: {
      const allowedFileTypes = [
        "application/pdf",
        "application/dwg",
        "application/dxf",
        "image/vnd.dwg",
        "image/vnd.dxf",
        "image/png",
        "image/jpg",
        "image/jpeg",
      ];
      const filteredAttachments = action.payload.data.filter((attachment) =>
        allowedFileTypes.includes(attachment.type)
      );
      return { ...state, attachments: filteredAttachments };
    }

    case `${POST_ADD_ATTACHMENTS}/fulfilled`: {
      return { ...state, newUploadedAttachments: action.payload };
    }

    case SET_SELECTED_ATTACHMENTS: {
      return {
        ...state,
        selectedAttachments: state.selectedAttachments.concat([
          { id: action.payload.id, type: action.payload.type },
        ]),
      };
    }

    case REMOVE_SELECTED_ATTACHMENT: {
      const newList = state.selectedAttachments.filter((item) => item.id !== action.payload.id);
      return { ...state, selectedAttachments: newList };
    }

    case UPDATE_SELECTED_ATTACHMENTS: {
      return { ...state, selectedAttachments: action.payload };
    }

    case SET_IS_ATTACHMENTS_LOADING: {
      return { ...state, isAttachmentsLoading: action.payload };
    }

    case SET_IS_MODAL_LOADING: {
      return { ...state, isModalLoading: action.payload };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
