import createActionType from "@/redux/utils/createActionType";

export const MODULE = "timesheets/individual";

export const RESET = createActionType(MODULE, "reset");

export const SET_IS_FETCHING_SUMMARY_STATUS = createActionType(
  MODULE,
  "setIsFetchingSummaryStatus"
);

export const SET_IS_UPDATING_TIME_ENTRY_STATUS = createActionType(
  MODULE,
  "setIsUpdatingTimeEntryStatus"
);

export const SET_MODAL_TYPE = createActionType(MODULE, "setModalType");

export const SET_INDIVIDUAL_SUMMARY = createActionType(MODULE, "setIndividualSummary");

export const SET_FROM_DATE = createActionType(MODULE, "setFromDate");

export const SET_TO_DATE = createActionType(MODULE, "setToDate");

export const SET_DATES = createActionType(MODULE, "setDates");

export const SET_ACTIVE_USER = createActionType(MODULE, "setActiveUser");

export const SET_SELECTED_TIME_ENTRY_UIDS = createActionType(MODULE, "setSelectedTimeEntryUids");

export const SET_IS_FETCHING_TEAM_MEMBERS_STATUS = createActionType(
  MODULE,
  "setIsFetchingTeamMembersStatus"
);

export const SET_TEAM_MEMBERS = createActionType(MODULE, "setTeamMembers");

export const SET_IS_DELETING_STATUS = createActionType(MODULE, "setIsDeletingStatus");

export const SET_EXPANDED_DATES = createActionType(MODULE, "setExpandedDates");

export const SET_ACTIVE_TIME_ENTRY = createActionType(MODULE, "setActiveTimeEntry");
