import createActionType from "../utils/createActionType";

export const MODULE = "products";

export const SET_IS_SAVING_PRODUCT = createActionType(MODULE, "setIsSavingProduct");
export const GET_PRODUCT = createActionType(MODULE, "getProduct");
export const GET_PRODUCT_TABLES = createActionType(MODULE, "getProductTables");
export const GET_PRODUCT_CATEGORIES = createActionType(MODULE, "getProductCatgories");
export const EDIT_ACTIVE_PRODUCT = createActionType(MODULE, "editActiveProduct");
export const EDIT_SELECTED_PRICE_LEVELS = createActionType(MODULE, "editSelectedPriceLevels");
export const EDIT_SELECTED_ATTRIBUTE_VISIBLITY = createActionType(
  MODULE,
  "editSelectedAttributeVisiblity"
);
export const LOAD_EMPTY_PRODUCT = createActionType(MODULE, "loadEmptyProduct");
export const RESET_ACTIVE_PRODUCT = createActionType(MODULE, "resetActiveProduct");
export const SAVE_PRODUCT = createActionType(MODULE, "saveProduct");
export const DELETE_PRODUCT = createActionType(MODULE, "deleteProduct");
export const UNDO_DELETE_PRODUCT = createActionType(MODULE, "undoDeleteProduct");
export const SET_IS_DUPLICATING = createActionType(MODULE, "isDuplicating");
export const EDIT_VALUE_IN_FORMULA = createActionType(MODULE, "editValueInFormula");
export const SET_IS_SAVING_CUSTOM_FORMULA = createActionType(MODULE, "setIsSavingCustomFormula");
export const SET_ERROR_IN_CUSTOM_FORMULA = createActionType(MODULE, "setErrorInCustomFormula");
export const SET_ACTIVE_PRODUCT_VARIANT = createActionType(MODULE, "setActiveProductVariant");
export const SET_IS_FETCHING_PRODUCT = createActionType(MODULE, "setIsFetchingProduct");
