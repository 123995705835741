import { STATUS } from "@/utils/constants";

import {
  SET_IS_FETCHING,
  SET_IS_CONNECTING,
  SET_ACCOUNTS,
  SET_TAX_CODES,
  SET_ITEMS,
  SET_MYOB_JOBS,
  SET_SELECTED_MYOB_COMPANY_FILE,
  SET_IS_MYOB_COMPANY_FILE_MODAL_OPEN,
  SET_IS_CONNECTED_ACCOUNTING_MODAL_OPEN,
  SET_IS_DISCONNECT_ACCOUNTING_MODAL_OPEN,
  SET_IS_ACCOUNTS_TAX_CODES_MODAL_OPEN,
  SET_IS_IMPORT_SUPPLIERS_MODAL_OPEN,
  SET_IS_IMPORT_CUSTOMERS_MODAL_OPEN,
  SET_ACCOUNTING_UPDATE_ALL_STATUS,
  SET_IS_XERO_CONNECTED,
} from "./accounting.actionTypes";

const initialState = {
  accountingName: null,
  accounts: [],
  taxCodes: [],
  items: [],
  myobJobs: [],
  newAccountingDefault: {},
  isFetching: false,
  isConnecting: false,
  myobCompanyFiles: [],
  selectedMyobCompanyFile: null,
  isMyobCompanyFileModalOpen: false,
  isConnectedAccountingModalOpen: false,
  isDisconnectAccountingModalOpen: false,
  isAccountsTaxCodesModalOpen: false,
  isImportSuppliersModalOpen: false,
  isImportCustomersModalOpen: false,
  accountingUpdateAllStatus: STATUS.IDLE,
  isXeroConnected: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_FETCHING: {
      return {
        ...state,
        isFetching: action.payload,
      };
    }

    case SET_IS_CONNECTING: {
      return {
        ...state,
        isConnecting: action.payload.status,
        accountingName: action.payload.accountingName,
      };
    }

    case SET_ACCOUNTS: {
      const { data } = action.payload;

      return {
        ...state,
        accounts: data,
      };
    }

    case SET_TAX_CODES: {
      const { data } = action.payload;

      return {
        ...state,
        taxCodes: data,
      };
    }

    case SET_ITEMS: {
      const { data } = action.payload;

      return {
        ...state,
        items: data,
      };
    }

    case SET_MYOB_JOBS: {
      const { data } = action.payload;

      return {
        ...state,
        myobJobs: data,
      };
    }

    case SET_SELECTED_MYOB_COMPANY_FILE: {
      const { data } = action.payload;

      return {
        ...state,
        selectedMyobCompanyFile: data,
      };
    }

    case SET_IS_MYOB_COMPANY_FILE_MODAL_OPEN: {
      return {
        ...state,
        myobCompanyFiles: action.payload.myobCompanyFiles,
        isMyobCompanyFileModalOpen: action.payload.status,
      };
    }

    case SET_IS_CONNECTED_ACCOUNTING_MODAL_OPEN: {
      return {
        ...state,
        isConnectedAccountingModalOpen: action.payload.status,
        newAccountingDefault: action.payload.newAccountingDefault,
      };
    }

    case SET_IS_DISCONNECT_ACCOUNTING_MODAL_OPEN: {
      return {
        ...state,
        isDisconnectAccountingModalOpen: action.payload.status,
      };
    }

    case SET_IS_ACCOUNTS_TAX_CODES_MODAL_OPEN: {
      return {
        ...state,
        isAccountsTaxCodesModalOpen: action.payload.status,
      };
    }

    case SET_IS_IMPORT_SUPPLIERS_MODAL_OPEN: {
      return {
        ...state,
        isImportSuppliersModalOpen: action.payload.status,
      };
    }

    case SET_IS_IMPORT_CUSTOMERS_MODAL_OPEN: {
      return {
        ...state,
        isImportCustomersModalOpen: action.payload.status,
      };
    }

    case SET_ACCOUNTING_UPDATE_ALL_STATUS: {
      return {
        ...state,
        accountingUpdateAllStatus: action.payload,
      };
    }

    case SET_IS_XERO_CONNECTED: {
      return {
        ...state,
        isXeroConnected: action.payload,
      };
    }

    default:
      return state;
  }
};
