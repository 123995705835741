import { MODULE } from "./hoursWorked.actionTypes";

export const isFetchingListsStatus = (state) => state.getIn([MODULE, "isFetchingListsStatus"]);

export const getLists = (state) =>
  state.getIn([MODULE, "lists"]).map((l) => ({
    ...l,
    ...l.teamMember,
  }));

export default {};
