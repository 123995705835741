import createActionType from "@/redux/utils/createActionType";

export const MODULE = "importSuppliers";

export const SET_FETCHING_LATEST_SUPPLIERS_STATUS = createActionType(
  MODULE,
  "setFetchingLatestSuppliersStatus"
);
export const SET_LATEST_SUPPLIERS = createActionType(MODULE, "setLatestSuppliers");
export const SET_SEARCH_LATEST_SUPPLIERS = createActionType(MODULE, "setSearchLatestSuppliers");
export const SET_IS_LINKED_SUPPLIERS = createActionType(MODULE, "setIsLinkedSuppliers");
export const SET_IS_PREVIOUSLY_IMPORTED_SUPPLIERS = createActionType(
  MODULE,
  "setIsPreviouslyImportedSuppliers"
);
export const SET_SUPPLIERS_TO_IMPORT = createActionType(MODULE, "setSuppliersToImport");

export const SET_IMPORT_SUPPLIERS_STATUS = createActionType(MODULE, "setImportSuppliersStatus");
export const SET_IMPORT_SUPPLIERS = createActionType(MODULE, "setImportSuppliers");
export const SET_DUPLICATE_SUPPLIERS_TO_IMPORT = createActionType(
  MODULE,
  "setDuplicateSuppliersToImport"
);
export const SET_DUPLICATE_SUPPLIERS_WARNING_MODAL = createActionType(
  MODULE,
  "setDuplicateSuppliersWarningModal"
);

export const SET_IMPORT_SUPPLIERS_SORT = createActionType(MODULE, "setImportSuppliersSort");
export const SET_IMPORT_SUPPLIERS_PAGE = createActionType(MODULE, "setImportSuppliersPage");
export const SET_IMPORT_SUPPLIERS_META = createActionType(MODULE, "setImportSuppliersMeta");
export const SET_IS_LINKED_SUPPLIERS_FILTER = createActionType(
  MODULE,
  "setIsLinkedSuppliersFilter"
);

export const SET_IMPORT_ALL_SUPPLIERS_STATUS = createActionType(
  MODULE,
  "setImportAllSuppliersStatus"
);
export const SET_IMPORT_ALL_SUPPLIERS = createActionType(MODULE, "setImportAllSuppliers");
export const RESET = createActionType(MODULE, "reset");
