import queryString from "query-string";

import { createApiV2 } from "./common";

export const getLineItemStatuses =
  ({ customerUid, orderUid, params = {}, headers = null }) =>
  (token) => {
    const queryParams = queryString.stringify(params);

    return createApiV2(
      "GET",
      `customers/${customerUid}/orders/${orderUid}/line_item_statuses/?${queryParams}`,
      { headers }
    )(token);
  };

export const putParentLineItemStatus = (data) => (token) =>
  createApiV2("PUT", "line_item_parent_statuses/")(token, data);

export const postLineItemStatusReference = (data) => (token) =>
  createApiV2("POST", "line_item_status_references/")(token, data);

export const patchLineItemStatusReference = (id, data) => (token) =>
  createApiV2("PATCH", `line_item_status_references/${id}/`)(token, data);

export const patchLineItemStatus = (customerId, orderId, uid, data) => (token) =>
  createApiV2("PATCH", `customers/${customerId}/orders/${orderId}/line_item_statuses/${uid}/`)(
    token,
    data
  );
export const getLineItemStatusReferences = () => (token) =>
  createApiV2("GET", `line_item_status_references`)(token);

export const getLineItemAdditionalProducts =
  ({ customerUid, orderUid, params = {} }) =>
  (token) => {
    const queryParams = queryString.stringify(params);
    return createApiV2(
      "GET",
      `customers/${customerUid}/orders/${orderUid}/line_item_additional_products/?${queryParams}`
    )(token);
  };

export const deleteLineItemStatusReference =
  ({ id }) =>
  (token) =>
    createApiV2("DELETE", `line_item_status_references/${id}/`)(token);

export const getLineItemAdditionalProduct =
  ({ customerUid, orderUid, itemUid }) =>
  (token) =>
    createApiV2(
      "GET",
      `customers/${customerUid}/orders/${orderUid}/line_item_additional_products/${itemUid}/`
    )(token);

export const getLineItemAllProductItem =
  ({ customerUid, orderUid }) =>
  (token) =>
    createApiV2(
      "GET",
      `customers/${customerUid}/orders/${orderUid}/line_item_additional_products_all_products/`
    )(token);
