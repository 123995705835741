import {
  SET_IS_SAVING,
  SET_IS_FETCHING,
  SET_LISTS,
  SET_ACTIVE_TEAM_MEMBER,
  SET_IS_FETCHING_INVITE_LINK,
  SET_INVITE_LINK,
  SET_IS_SENDING_INVITE,
  RESET,
} from "./teams.actionTypes";

const initialState = {
  isSaving: false,
  isFetching: false,
  isFetchingInviteLink: false,
  isSendingInvite: false,
  lists: [],
  activeTeamMember: null,
  inviteLink: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_SAVING: {
      return {
        ...state,
        isSaving: action.payload,
      };
    }

    case SET_IS_FETCHING: {
      return {
        ...state,
        isFetching: action.payload,
      };
    }

    case SET_LISTS: {
      return {
        ...state,
        lists: action.payload,
      };
    }

    case SET_ACTIVE_TEAM_MEMBER: {
      return {
        ...state,
        activeTeamMember: action.payload,
      };
    }

    case SET_IS_FETCHING_INVITE_LINK: {
      return {
        ...state,
        isFetchingInviteLink: action.payload,
      };
    }

    case SET_INVITE_LINK: {
      return {
        ...state,
        inviteLink: action.payload,
      };
    }

    case SET_IS_SENDING_INVITE: {
      return {
        ...state,
        isSendingInvite: action.payload,
      };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
