import createActionType from "../utils/createActionType";

export const MODULE = "order/checklists";

export const SET_IS_ADD_CHECKLIST_FORM_VISIBLE = createActionType(
  MODULE,
  "setIsAddChecklistFormVisible"
);

export const SET_IS_ADD_SUB_ITEM_FORM_VISIBLE = createActionType(
  MODULE,
  "setIsAddSubItemFormVisible"
);

export const SET_PARENT_ITEM_TO_DISPLAY_THE_FORM = createActionType(
  MODULE,
  "setParentItemToDiplayTheForm"
);

export const SET_IS_FETCHING_CHECKLISTS = createActionType(MODULE, "setIsFetchingChecklists");

export const SET_CHECKLISTS = createActionType(MODULE, "setChecklists");

export const SET_CHECKLIST_ITEM = createActionType(MODULE, "setChecklistItem");

export const SET_CLOSE_CHECKLIST_UIDS = createActionType(MODULE, "setCloseChecklistUids");

export const INDENT_SUB_ITEM = createActionType(MODULE, "indentSubItem");

export const INDENT_BACKWARDS_SUB_ITEM = createActionType(MODULE, "indentBackwardsSubItem");

export const UPDATE_SUB_ITEM_FIELD_VALUE = createActionType(MODULE, "updateSubItemFieldValue");

export const APPEND_SUB_ITEM = createActionType(MODULE, "appendSubItem");

export const REMOVE_SUB_ITEM = createActionType(MODULE, "removeSubItem");

export const MARK_ITEM_AS_COMPLETED = createActionType(MODULE, "markItemAsCompleted");

export const MARK_ITEM_AS_NOT_COMPLETED = createActionType(MODULE, "markItemAsNotCompleted");

export const SET_CHECKLIST_ITEM_UPLOADING = createActionType(MODULE, "setChecklistItemUploading");

export const SET_ALL_CHECKLISTS_ATTACHMENTS = createActionType(
  MODULE,
  "setAllChecklistsAttachments"
);

export const RESET = createActionType(MODULE, "reset");
