import { MODULE } from "./labels.actionTypes";

export const isLabelsDropdownVisible = (state) => {
  return state.getIn([MODULE, "isLabelsDropdownVisible"]);
};

export const isSavingLabel = (state) => {
  return state.getIn([MODULE, "isSavingLabel"]);
};

export const isFetchingLabels = (state) => {
  return state.getIn([MODULE, "isFetchingLabels"]);
};

export const getLabels = (state) => {
  return state.getIn([MODULE, "labels"]);
};

export const getSelectedLabels = (state) => {
  return state.getIn([MODULE, "selectedLabels"]);
};

export const getUnSelectedLabels = (state) => {
  return state.getIn([MODULE, "unSelectedLabels"]);
};

export const getActiveLabel = (state) => {
  return state.getIn([MODULE, "activeLabel"]);
};

export const isCreateLabeLFormVisible = (state) => {
  return state.getIn([MODULE, "isCreateLabeLFormVisible"]);
};

export const isDeleteLabelAlertVisible = (state) => {
  return state.getIn([MODULE, "isDeleteLabelAlertVisible"]);
};
