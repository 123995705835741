import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { faCheck, faMinus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./Checkbox.module.scss";

const Checkbox = ({
  label,
  checked,
  disabled,
  isDisabledUnchecked,
  partial,
  onCheck,
  onUncheck,
  onChange,
  className,
}) => {
  const onClick = () => {
    if (checked) {
      onChange({
        target: {
          checked: false,
        },
      });

      onUncheck();
    }

    if (!checked) {
      onChange({
        target: {
          checked: true,
        },
      });

      onCheck();
    }
  };

  const handleCheckBoxClick = (e) => {
    if (!disabled) {
      e.stopPropagation();
      onClick();
    }
  };

  return (
    <div className={styles.checkboxContainer}>
      <div
        className={cx(
          styles.checkbox,
          {
            [styles.checkboxUnchecked]: !checked,
            [styles.checkboxDisabled]: disabled,
          },
          className
        )}
      >
        <div
          className={cx(styles.checkboxTarget, { [styles.checkboxNoCursor]: disabled })}
          onClick={handleCheckBoxClick}
          aria-hidden="true"
        />

        {disabled && isDisabledUnchecked ? (
          <FontAwesomeIcon
            className={cx(styles.checkboxTick, { [styles.checkboxTickHidden]: !checked })}
            icon={null}
          />
        ) : (
          <FontAwesomeIcon
            className={cx(styles.checkboxTick, { [styles.checkboxTickHidden]: !checked })}
            icon={partial ? faMinus : faCheck}
          />
        )}
      </div>
      {label && <div>{label}</div>}
    </div>
  );
};

Checkbox.defaultProps = {
  checked: undefined,
  disabled: false,
  partial: false,
  className: "",
  onCheck: () => {},
  onUncheck: () => {},
  onChange: () => {},
  label: null,
  isDisabledUnchecked: false,
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  partial: PropTypes.bool,
  className: PropTypes.string,
  onCheck: PropTypes.func,
  onUncheck: PropTypes.func,
  onChange: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isDisabledUnchecked: PropTypes.bool,
};

export default Checkbox;
