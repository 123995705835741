import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import styles from "./FieldGroup.module.scss";

const FieldGroup = ({
  prepend,
  append,
  label,
  children,
  className,
  contentClassName,
  labelClassName,
  childrenClassName,
  prependClassName,
  appendClassName,
  ...rest
}) => (
  <div className={cx(styles.fieldGroup, className)} {...rest}>
    {prepend && <div className={cx(styles.fieldGroupPrepend, prependClassName)}>{prepend}</div>}
    <div className={cx(styles.fieldGroupContent, contentClassName)}>
      {label && <div className={cx(styles.fieldGroupLabel, labelClassName)}>{label}</div>}
      {children && (
        <div className={cx(styles.fieldGroupChildren, childrenClassName)}>{children}</div>
      )}
    </div>
    {append && <div className={cx(styles.fieldGroupAppend, appendClassName)}>{append}</div>}
  </div>
);

FieldGroup.defaultProps = {
  prepend: null,
  append: null,
  label: null,
  className: "",
  contentClassName: "",
  labelClassName: "",
  childrenClassName: "",
  children: null,
  prependClassName: "",
  appendClassName: "",
};

FieldGroup.propTypes = {
  prepend: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  append: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  childrenClassName: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  prependClassName: PropTypes.string,
  appendClassName: PropTypes.string,
};

export default FieldGroup;
