import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Slider from "rc-slider";
import moment from "moment-timezone";
import "rc-slider/assets/index.css";
import cx from "classnames";

import styles from "./TimeRangeSlider.module.scss";

const TimeRangeSlider = ({ min, max, values, className, onAfterChange }) => {
  const getTimeFromMinutes = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const localVal = minutes % 60;

    let displayH;
    let displayM;

    if (hours > 12) {
      displayH = hours - 12;
    } else if (hours < 1) {
      displayH = 12;
    } else {
      displayH = hours;
    }

    if (localVal < 10) {
      displayM = `0${localVal}`;
    } else {
      displayM = localVal;
    }

    return `${displayH}:${displayM}`;
  };

  const getMinutesFromTime = (time) => {
    const duration = moment(time, "h:mma").format("HH:mm");
    return moment.duration(duration).asMinutes();
  };

  const getSliderLabel = (value) => {
    const period = value <= 719 || value === 1439 ? "AM" : "PM";
    const time = getTimeFromMinutes(value, true);
    return [1439, 1440].includes(value) ? "11:59PM" : `${time}${period}`;
  };

  const [startTime, setStartTime] = useState(getMinutesFromTime(values[0]));
  const [endTime, setEndTime] = useState(getMinutesFromTime(values[1]));

  const handleOnChange = (time) => {
    setStartTime(time[0]);
    setEndTime([time[1]]);
  };

  const handleOnAfterChange = (time) => {
    if (onAfterChange) {
      onAfterChange({
        startTime: getSliderLabel(time[0], true),
        finishTime: getSliderLabel(time[1], true),
      });
    }
  };

  useEffect(() => {
    document.getElementsByClassName("rc-slider-mark-text")[0].textContent = getSliderLabel(min);
    document.getElementsByClassName("rc-slider-mark-text")[1].textContent = getSliderLabel(max);
  }, []);

  return (
    <Slider
      range
      allowCross={false}
      min={min}
      max={max}
      step={30}
      className={cx(styles.slider, className)}
      value={[startTime, endTime]}
      onChange={handleOnChange}
      onAfterChange={handleOnAfterChange}
      reverse={false}
      handleRender={(renderProps) => {
        if (renderProps.props.className.includes("rc-slider-handle-1")) {
          return (
            <div {...renderProps.props}>
              <div className={styles.time}>{getSliderLabel(startTime)}</div>
            </div>
          );
        }

        return (
          <div {...renderProps.props}>
            <div className={styles.time}>{getSliderLabel(endTime)}</div>
          </div>
        );
      }}
      marks={{
        [min]: min,
        [max]: max,
      }}
    />
  );
};

TimeRangeSlider.defaultProps = {
  className: "",
  onAfterChange: () => {},
};

TimeRangeSlider.propTypes = {
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  className: PropTypes.string,
  onAfterChange: PropTypes.func,
};

export default TimeRangeSlider;
