import { createApi, createApiV2 } from "./common";

export const getProducts = () => (token) =>
  createApi("GET", "flashing_products/")(token);

export const deleteProduct = (token, productId) =>
  createApi("DELETE", `flashing_products/${productId}/`)(token);

export const bulkArchiveProducts = (data) => (token) =>
  createApiV2("POST", `flashing_products/bulk_destroy/`)(token, data);

export const bulkUnarchiveProducts = (data) => (token) =>
  createApiV2("POST", `flashing_products/bulk_undestroy/`)(token, data);

export const postDuplicateProduct = (data) => (token) =>
  createApiV2("POST", `flashing_products/bulk_duplicate/`)(token, data);
