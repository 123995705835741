import createActionType from "@/redux/utils/createActionType";

export const MODULE = "globalSearch";

// Search Generic Types
export const SET_GLOBAL_SEARCH_SEARCHING_STATUS = createActionType(
  MODULE,
  "setGlobalSearchSearchingStatus"
);

export const SET_GLOBAL_SEARCH_MODAL_IS_VISIBLE = createActionType(
  MODULE,
  "setGlobalSearchModalIsVisible"
);
export const SET_GLOBAL_SEARCH_TYPE = createActionType(MODULE, "setGlobalSearchType");
export const SET_GLOBAL_SEARCH_OPTION_TYPES = createActionType(
  MODULE,
  "setGlobalSearchOptionTypes"
);
export const SET_GLOBAL_SEARCH_KEYWORD = createActionType(MODULE, "setGlobalSerachKeyword");
export const SET_GLOBAL_SEARCH_POPOVER_OPEN = createActionType(
  MODULE,
  "setGlobalSearchPopoverOpen"
);
export const SET_GLOBAL_SEARCH_OPTIONS_OPEN = createActionType(
  MODULE,
  "setGlobalSearchOptionsOpen"
);
export const SET_GLOBAL_SEARCH_IS_SHOW_ALL_CLICKED = createActionType(
  MODULE,
  "setGlobalSearchIsShowAllClicked"
);

// Search ALL types
export const SET_FETCH_GLOBAL_SEARCH_ALL_STATUS = createActionType(
  MODULE,
  "setFetchGlobalSearchAllStatus"
);
export const SET_FETCH_GLOBAL_SEARCH_ALL_RESULTS = createActionType(
  MODULE,
  "setFetchGlobalSearchAllResults"
);
export const SET_FETCH_GLOBAL_SEARCH_ALL = createActionType(MODULE, "setFetchGlobalSearchAll");

// Search CUSTOMERS types
export const SET_FETCH_GLOBAL_SEARCH_CUSTOMERS_STATUS = createActionType(
  MODULE,
  "setFetchGlobalSearchCustomersStatus"
);
export const SET_FETCH_GLOBAL_SEARCH_CUSTOMERS_RESULTS = createActionType(
  MODULE,
  "setFetchGlobalSearchCustomersResults"
);
export const SET_FETCH_GLOBAL_SEARCH_CUSTOMERS = createActionType(
  MODULE,
  "setFetchGlobalSearchCustomers"
);

// Search SUPPLIERS types
export const SET_FETCH_GLOBAL_SEARCH_SUPPLIERS_STATUS = createActionType(
  MODULE,
  "setFetchGlobalSearchSuppliersStatus"
);
export const SET_FETCH_GLOBAL_SEARCH_SUPPLIERS_RESULTS = createActionType(
  MODULE,
  "setFetchGlobalSearchSuppliersResults"
);
export const SET_FETCH_GLOBAL_SEARCH_SUPPLIERS = createActionType(
  MODULE,
  "setFetchGlobalSearchSuppliers"
);

// Search SALES types
export const SET_FETCH_GLOBAL_SEARCH_SALES_STATUS = createActionType(
  MODULE,
  "setFetchGlobalSearchSalesStatus"
);
export const SET_FETCH_GLOBAL_SEARCH_SALES_RESULTS = createActionType(
  MODULE,
  "setFetchGlobalSearchSalesResults"
);
export const SET_FETCH_GLOBAL_SEARCH_SALES = createActionType(MODULE, "setFetchGlobalSearchSales");

// Search PURCHASES types
export const SET_FETCH_GLOBAL_SEARCH_PURCHASES_STATUS = createActionType(
  MODULE,
  "setFetchGlobalSearchPurchasesStatus"
);

export const SET_FETCH_GLOBAL_SEARCH_PURCHASES_RESULTS = createActionType(
  MODULE,
  "setFetchGlobalSearchPurchasesResults"
);

export const SET_FETCH_GLOBAL_SEARCH_PURCHASES = createActionType(
  MODULE,
  "setFetchGlobalSearchPurchases"
);

// Search PRODUCTS types
export const SET_FETCH_GLOBAL_SEARCH_PRODUCTS_STATUS = createActionType(
  MODULE,
  "setFetchGlobalSearchProductsStatus"
);
export const SET_FETCH_GLOBAL_SEARCH_PRODUCTS_RESULTS = createActionType(
  MODULE,
  "setFetchGlobalSearchProductsResults"
);
export const SET_FETCH_GLOBAL_SEARCH_PRODUCTS = createActionType(
  MODULE,
  "setFetchGlobalSearchProducts"
);

export const RESET = createActionType(MODULE, "reset");
