import { nanoid } from "nanoid";
import snakecaseKeys from "snakecase-keys";
import moment from "moment-timezone";

import { STATUS } from "@/utils/constants";
import { getCountryByCode } from "@/utils/countries-with-state";
import { intercomSettings } from "@/env";

import { handleError } from "../utils/error";
import * as api from "../../apiv2/signup";
import * as billingApi from "../../apiv2/billing";
import * as customerApi from "../../apiv2/customers";
import * as countriesApi from "../../apiv2/countries";
import * as timezonesApi from "../../apiv2/timezones";
import * as routerActions from "../router/router.actions";
import * as routerSelectors from "../router/router.selectors";
import * as alertActions from "../alerts/alerts.actions";
import * as authActions from "../authentication/authentication.actions";
import {
  SET_IS_FETCHING,
  SET_IS_SAVING,
  SET_SIGNUP_DATA,
  SET_COUNTRIES,
  SET_TIMEZONES,
  SET_XERO_ACCOUNTS,
  RESET,
  SET_INVITE_PAGE_STATUS,
} from "./signup.actionTypes";
import * as selectors from "./signup.selectors";

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setIsSaving = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_SAVING,
    payload: data,
  });
};

export const setIsFetching = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING,
    payload: data,
  });
};

export const setSignupData = (data) => (dispatch) => {
  dispatch({
    type: SET_SIGNUP_DATA,
    payload: data,
  });
};

export const setCountries = (data) => (dispatch) => {
  dispatch({
    type: SET_COUNTRIES,
    payload: data,
  });
};

export const setTimezones = (data) => (dispatch) => {
  dispatch({
    type: SET_TIMEZONES,
    payload: data,
  });
};

export const setXeroAccounts = (data) => (dispatch) => {
  dispatch({
    type: SET_XERO_ACCOUNTS,
    payload: data,
  });
};

export const setInvitePageStatus = (data) => (dispatch) => {
  dispatch({
    type: SET_INVITE_PAGE_STATUS,
    payload: data,
  });
};

export const validateEmail = (email) => async (dispatch, getState) => {
  try {
    const state = getState();
    const path = routerSelectors.getPath(state);
    const signupData = selectors.getSignupData(state);

    dispatch(setIsSaving(true));

    await api.postValidateEmail({ email })();

    dispatch(
      setSignupData({
        ...signupData,
        email,
      })
    );

    dispatch(routerActions.push("/signup/personal-info"));
  } catch (error) {
    dispatch(
      alertActions.createAlertAction(
        nanoid(),
        error?.response?.data?.error ?? "Something went wrong",
        true,
        "danger"
      )
    );
  } finally {
    dispatch(setIsSaving(false));
  }
};

export const signup = () => async (dispatch, getState) => {
  try {
    dispatch(setIsSaving(true));
    const state = getState();
    const signupData = { ...selectors.getSignupData(state) };
    delete signupData.i_agree;

    const { data } = await api.postSignup(signupData)();
    dispatch(
      setSignupData({
        ...signupData,
        user_sign_up: data.uid,
      })
    );
    dispatch(routerActions.push("/signup/email-confirmation"));
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsSaving(false));
  }
};

export const verification =
  ({ code }) =>
  async (dispatch, getState) => {
    dispatch(setIsSaving(true));
    const state = getState();
    const signupData = selectors.getSignupData(state);

    try {
      await api.postSignupVerification({
        email: signupData?.email ?? undefined,
        verification_code: code,
      })();

      dispatch(routerActions.push("/signup/company"));
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(setIsSaving(false));
    }
  };

export const resendVerificationCode = (data) => async (dispatch) => {
  try {
    dispatch(setIsSaving(true));
    await api.postResendCode(data)();
    dispatch(
      alertActions.createAlertAction(
        nanoid(),
        "Verification code has been resent to your email",
        true,
        "success"
      )
    );
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsSaving(false));
  }
};

export const fetchCountries = () => async (dispatch) => {
  try {
    const { data } = await countriesApi.getCountries();
    dispatch(setCountries(data));
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const fetchTimezones = () => async (dispatch) => {
  try {
    const { data } = await timezonesApi.getTimezones();
    dispatch(setTimezones(data));
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const signupCompany = () => async (dispatch, getState) => {
  const state = getState();
  const signupData = selectors.getSignupData(state);
  try {
    const payload = {
      name: signupData.name,
      country_code: signupData.country_code,
      time_zone: signupData.time_zone,
      website: signupData?.website ?? undefined,
      headcount: signupData?.headcount ?? undefined,
      company_goal: signupData?.company_goal ?? undefined,
      user_sign_up: signupData?.user_sign_up ?? undefined,
      address: signupData?.address ?? "",
      address2: signupData?.address2 ?? "",
      suburb: signupData?.suburb ?? "",
      state: signupData?.state ?? "",
      postcode: signupData?.postcode ?? "",
      google_map_place_id: signupData?.google_map_place_id ?? "",
      google_map_latitude: signupData?.google_map_latitude ?? "",
      google_map_longitude: signupData?.google_map_longitude ?? "",
    };
    const { data } = await api.postCreateCompany(payload)();

    dispatch(
      setSignupData({
        ...signupData,
        name: data.name,
      })
    );

    return data;
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const createCompanyTemplate = (company) => async (dispatch) => {
  try {
    await api.postCompanyTemplate({ company })();
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const exchangeGoogleToken = (code) => async (dispatch, getState) => {
  try {
    dispatch(setIsSaving(true));
    const { data } = await api.postGoogleToken({ code })();
    return data;
  } catch (error) {
    dispatch(setIsSaving(false));
    handleError(error, dispatch);
  }
};

export const signupGoogleUser =
  ({ payload, redirectUrlOnSuccess = "", redirectUrlOnFail = "" }) =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsSaving(true));
      const state = getState();
      const signupData = { ...selectors.getSignupData(state) };
      const { data } = await api.postGoogleSignup({
        ...payload,
        time_zone: payload?.time_zone ?? moment.tz.guess(),
      })();
      dispatch(
        setSignupData({
          ...signupData,
          user_sign_up: data.userSignUp,
          provider: "google",
          token: payload.token,
          time_zone: payload?.time_zone ?? moment.tz.guess(),
        })
      );

      if (redirectUrlOnSuccess !== "") {
        dispatch(routerActions.push(redirectUrlOnSuccess));
      }
    } catch (error) {
      console.log(error);
      if (redirectUrlOnFail !== "") {
        dispatch(routerActions.push(redirectUrlOnFail));
      }
      handleError(error, dispatch);
    } finally {
      dispatch(setIsSaving(false));
    }
  };

export const signupMicrosoftUser =
  ({ payload, redirectUrlOnSuccess = "", redirectUrlOnFail = "" }) =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsSaving(true));
      const state = getState();
      const signupData = { ...selectors.getSignupData(state) };
      const { data } = await api.postMicrosoftSignup({
        ...payload,
        time_zone: payload?.time_zone ?? moment.tz.guess(),
      })();
      dispatch(
        setSignupData({
          ...signupData,
          user_sign_up: data.userSignUp,
          provider: "microsoft",
          token: data.accessToken,
          time_zone: payload?.time_zone ?? moment.tz.guess(),
        })
      );

      if (redirectUrlOnSuccess !== "") {
        dispatch(routerActions.push(redirectUrlOnSuccess));
      }

      return data;
    } catch (error) {
      if (redirectUrlOnFail !== "") {
        dispatch(routerActions.push(redirectUrlOnFail));
      }
      handleError(error, dispatch);
    } finally {
      dispatch(setIsSaving(false));
    }
  };

export const fetchXeroAuthorizationUrl =
  ({ is_xero_referral = false } = {}) =>
  async (dispatch) => {
    try {
      const { data } = await api.getXeroAuthorizationUrl({ is_login: false, is_xero_referral })();
      return data;
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const fetchXeroCompany = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const signupData = selectors.getSignupData(state);

    const { data } = await api.getXeroCompany()();

    if (data.addresses.length > 0) {
      const address = data.addresses.find((a) => a.addressType === "STREET");
      const country = getCountryByCode(address.country);

      dispatch(
        setSignupData({
          ...signupData,
          name: data.companyName,
          country_code: country?.code,
          address: address?.addressLine1 ?? "",
          address2: `${address?.addressLine2 ?? ""} ${address?.addressLine3 ?? ""}` ?? "",
          suburb: address?.city.charAt(0).toUpperCase() + address?.city.slice(1).toLowerCase(),
          state: address?.region ?? "",
          postcode: address?.postalCode ?? "",
        })
      );
    } else {
      dispatch(setSignupData({ ...signupData, name: data.companyName }));
    }

    return data;
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const signupXeroUser = (payload) => async (dispatch, getState) => {
  try {
    dispatch(setIsSaving(true));
    const state = getState();
    const signupData = { ...selectors.getSignupData(state) };
    const {
      data: { token },
    } = await api.getXeroToken(payload)();
    const { data } = await api.postXeroSignup({
      token,
      is_xero_referral: payload?.is_xero_referral ?? false,
      time_zone: payload?.time_zone ?? moment.tz.guess(),
    })();
    dispatch(
      setSignupData({
        ...signupData,
        ...payload,
        user_sign_up: data.userSignUp,
        time_zone: payload?.time_zone ?? moment.tz.guess(),
        provider: "xero",
        token,
      })
    );

    dispatch(routerActions.push("/signup/company"));
  } catch (error) {
    dispatch(routerActions.push("/signup"));
    handleError(error, dispatch);
  } finally {
    dispatch(setIsSaving(false));
  }
};

export const connectToXero = () => async (dispatch) => {
  try {
    await api.postConnectToXero()();
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const fetchXeroAccounts = () => async (dispatch) => {
  try {
    const { data } = await api.getXeroAccounts()();
    dispatch(setXeroAccounts(data));
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const saveXeroAccounts = (data) => async (dispatch, getState) => {
  try {
    const state = getState();
    const signupData = selectors.getSignupData(state);
    dispatch(setIsSaving(true));
    const { data: company } = await api.getCompany()();

    const postData = {
      name: company.companyName,
      phone: company.phoneNumber,
      email: company.email,
      state: company.billingState,
      address1: company.billingAddress1,
      address2: company.billingAddress2,
      city: company.billingCity,
      postcode: company.billingPostcode,
      abn: company.abn,
      bankAccountName: company.bankAccountName,
      bankAccountNumber: company.bankAccountNumber,
      bsb: company.bsb,
      images: company.image,
      settings: company.settings,
      accounting_default: {
        ...company.accountingDefault,
        ...data.accounting_default,
      },
    };
    const formData = new FormData();
    formData.append("json", JSON.stringify(snakecaseKeys(postData)));
    await api.postCompany(formData)();

    const { data: user } = await customerApi.getUserV2()();

    if (window.Intercom) {
      window.Intercom("boot", {
        app_id: intercomSettings?.app_id ?? "",
        name: user.firstName,
        user_id: user.id,
        email: user.email,
        created_at: user.dateJoined,
        is_connected_to_xero: true,
      });
    }

    dispatch(routerActions.push("/signup/configuring"));
  } catch (error) {
    dispatch(routerActions.push("/signup"));
    handleError(error, dispatch);
  } finally {
    dispatch(setIsSaving(false));
  }
};

export const fetchAllAccountingData = () => async (dispatch) => {
  try {
    await api.getAllAccountingData()();
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const fetchInviteLinkDetails = (code) => async (dispatch) => {
  try {
    dispatch(setInvitePageStatus(STATUS.PENDING));
    const { data } = await api.getInviteLinkDetails(code)();
    dispatch(
      setSignupData({
        companyId: data.companyId,
        name: data.companyName,
        email: data.email,
        isInviteLink: data.isInviteLink,
        timeZone: data.timeZone,
      })
    );
    dispatch(setInvitePageStatus(STATUS.FULFILLED));
  } catch (error) {
    dispatch(setInvitePageStatus(STATUS.REJECTED));
  }
};

export const signupViaInvite = (payload) => async (dispatch, getState) => {
  try {
    const state = getState();
    dispatch(setIsSaving(true));
    const signupData = selectors.getSignupData(state);
    await api.postSignupViaInvite(signupData.companyId, {
      email: payload.email,
      first_name: payload.first_name,
      last_name: payload.last_name,
      invite_code: payload.invite_code,
      password: payload.password,
      password_confirmation: payload.password_confirmation,
      pin: payload.pin,
      time_zone: payload.time_zone,
      is_invite_link: payload.is_invite_link,
    })();

    if (signupData.isInviteLink) {
      dispatch(
        setSignupData({
          ...signupData,
          email: payload.email,
          password: payload.password,
        })
      );
      dispatch(routerActions.push("/invite/signup/email-confirmation"));
    } else {
      await dispatch(
        authActions.login({
          email: signupData.email,
          password: payload.password,
        })
      );

      await dispatch(updateBillingSubscriptions());

      window.location = "/dashboard";
    }
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsSaving(false));
  }
};

export const verificationViaInvite =
  ({ code }) =>
  async (dispatch, getState) => {
    dispatch(setIsSaving(true));
    const state = getState();
    const signupData = selectors.getSignupData(state);

    try {
      await api.postInviteSignupVerification(signupData.companyId, {
        email: signupData?.email ?? undefined,
        verification_code: code,
      })();

      await dispatch(
        authActions.login({
          email: signupData.email,
          password: signupData.password,
        })
      );

      await dispatch(updateBillingSubscriptions());
      window.location = "/dashboard";
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(setIsSaving(false));
    }
  };

export const updateBillingSubscriptions = () => async (dispatch) => {
  try {
    await billingApi.putBillingScriptions()();
  } catch (error) {
    handleError(error, dispatch);
  }
};
