/* eslint-disable css-modules/no-unused-class */
import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { Loading } from "@/ui-lib";

import styles from "./Button.module.scss";

const Button = forwardRef((props, ref) => {
  const {
    size,
    type,
    transparent,
    outline,
    ghost,
    color,
    className,
    inputClassName,
    block,
    loading,
    children,
    ...rest
  } = props;

  return (
    <button
      ref={ref}
      type={type}
      className={cx(
        styles.button,
        className,
        styles[size] || "",
        !outline && !ghost && !transparent ? styles[color] : "",
        outline ? styles[`outline-${color}`] : "",
        ghost ? styles[`ghost-${color}`] : "",
        transparent ? styles[`transparent-${color}`] : "",
        block ? styles.block : ""
      )}
      {...rest}
    >
      {children} {loading && <Loading size={1} spinnerClassName={styles.buttonSpinner} />}
    </button>
  );
});

Button.defaultProps = {
  size: "",
  type: "button",
  outline: false,
  color: "primary",
  block: false,
  transparent: false,
  ghost: false,
  loading: false,
};

Button.propTypes = {
  size: PropTypes.string,
  type: PropTypes.string,
  outline: PropTypes.bool,
  ghost: PropTypes.bool,
  color: PropTypes.string,
  block: PropTypes.bool,
  transparent: PropTypes.bool,
  loading: PropTypes.bool,
};

export default Button;
