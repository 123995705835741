import { MODULE } from "./orderTimeline.actionTypes";

export const isFetchingCategories = (state) => {
  return state.getIn([MODULE, "isFetchingCategories"]);
};


export const getSearch = (state) => {
  return state.getIn([MODULE, "search"]);
};


export const getFilters = (state) => {
  return state.getIn([MODULE, "filters"]);
};

export const isFetchingTimelines = (state) => {
  return state.getIn([MODULE, "isFetchingTimelines"]);
};

export const isFetchingTimelineDetails = (state) => {
  return state.getIn([MODULE, "isFetchingTimelineDetails"]);
};

export const getTimelines = (state) => {
  return state.getIn([MODULE, "timelines"]);
};

export const getTimelineDetails = (state) => {
  return state.getIn([MODULE, "timelineDetails"]);
};
