import qs from "query-string";

import { createApiV2 } from "./common";

export const getTimesheetSummary = (params) => (token) =>
  createApiV2("GET", `timesheets/summary/?${qs.stringify(params)}`)(token);

export const patchBulkChangeTimeEntryStatus =
  ({ data }) =>
  (token) =>
    createApiV2("PATCH", `timesheets/bulk_change_status/`)(token, data);

export const getOrderNumbers = (params) => (token) =>
  createApiV2("GET", `order_number/?${qs.stringify(params)}`)(token);

export const postValidateUserPin = (data) => (token) =>
  createApiV2("POST", `users/pin/`)(token, data);

export const deleteTimeEntry = (data) => (token) =>
  createApiV2("DELETE", `timesheets/bulk_delete/`)(token, data);
