export const calcDrawingItemLength = (item, minLength = 0) => {
  let total = 0;
  let forPrice = 0;

  let sumTotalLength = 0;
  let sumLengthForPrice = 0;

  if (item && Object.keys(item).length && !item.isDeleted && item.subitems.length) {
    Object.keys(item.subitems).map((itemDrawing) => {
      const { amount, length } = item.tempUid ? item.subitems[itemDrawing] : item;
      const amountInt = parseInt(amount, 10);
      let lengthInt = parseInt(length, 10);

      sumTotalLength = amountInt * lengthInt;

      if (lengthInt < minLength) {
        lengthInt = minLength;
      }

      sumLengthForPrice = amountInt * lengthInt;

      total += sumTotalLength;
      forPrice += sumLengthForPrice;

      return { total, forPrice };
    });
  }

  return { total, forPrice };
};

export const calcItemLength = (items, minLength = 0) => {
  let total = 0;
  let forPrice = 0;

  items.forEach((item) => {
    let sumTotalLength = 0;
    let sumLengthForPrice = 0;

    if (!item.isDeleted) {
      const { amount, length } = item.tempUid ? item.length : item;
      const amountInt = parseFloat(amount, 10);
      let lengthInt = parseFloat(length, 10);

      sumTotalLength = amountInt * lengthInt;

      if (lengthInt < minLength) {
        lengthInt = minLength;
      }

      sumLengthForPrice = amountInt * lengthInt;
    }

    total += sumTotalLength;
    forPrice += sumLengthForPrice;
  });

  return { total, forPrice };
};

export const calculateTotalSquareMeters = (items) => {
  if (!Array.isArray(items)) {
    throw new Error("Input must be an array of items");
  }

  return items.reduce(
    (accumulator, item) => {
      const { length, width } = item;
      const lengthValue = parseFloat(length);
      const widthValue = parseFloat(width);

      const itemArea = lengthValue * widthValue;

      return {
        total: accumulator.total + itemArea,
      };
    },
    { total: 0 }
  );
};
