import moment from "moment";

import { TIME_ZONE } from "@/utils/constants";

import { SET_REQUIRED_START_DATE, SET_REQUIRED_END_DATE, RESET } from "./requiredDate.actionTypes";

export const setRequiredStartDate = (data) => (dispatch) => {
  dispatch({
    type: SET_REQUIRED_START_DATE,
    payload: data,
  });
};

export const setRequiredEndDate = (data) => (dispatch) => {
  dispatch({
    type: SET_REQUIRED_END_DATE,
    payload: data,
  });
};

export const setRequiredDates =
  ({ startDate, endDate }) =>
  (dispatch) => {
    dispatch(
      setRequiredStartDate(
        startDate ? moment(startDate).tz(TIME_ZONE).startOf("day").format() : null
      )
    );

    dispatch(
      setRequiredEndDate(endDate ? moment(endDate).tz(TIME_ZONE).endOf("day").format() : null)
    );
  };

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};
