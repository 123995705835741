import { handleError } from "../utils/error";
import * as api from "../../apiv2/forgot-password";
import * as authenticationActions from "../authentication/authentication.actions";

import {
  SET_EMAIL_FROM_TOKEN,
  SET_IS_SAVING,
  SET_IS_SUCCESS,
  SET_IS_VALIDATING_TOKEN,
  SET_IS_TOKEN_VALID,
  RESET,
} from "./forgotPassword.actionTypes";

export const setIsSaving = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_SAVING,
    payload: data,
  });
};

export const setIsSuccess = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_SUCCESS,
    payload: data,
  });
};

export const setEmailFromToken = (data) => (dispatch) => {
  dispatch({
    type: SET_EMAIL_FROM_TOKEN,
    payload: data,
  });
};

export const setIsValidatingToken = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_VALIDATING_TOKEN,
    payload: data,
  });
};

export const setIsTokenValid = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_TOKEN_VALID,
    payload: data,
  });
};

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const resetPassword = (data) => async (dispatch) => {
  try {
    dispatch(setIsSaving(true));

    await api.postResetPassword(data)();

    dispatch(setIsSuccess(true));
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsSaving(false));
  }
};

export const resendResetPassword = (data) => async (dispatch) => {
  try {
    dispatch(setIsSaving(true));

    await api.postResendResetPassword(data)();

    dispatch(setIsSuccess(true));
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsSaving(false));
  }
};

export const validateToken = (payload) => async (dispatch) => {
  try {
    dispatch(setIsValidatingToken(true));
    const { data } = await api.postValidateToken(payload)();

    dispatch(setIsTokenValid(true));

    dispatch(setEmailFromToken(data.email));
  } catch (error) {
    dispatch(setIsTokenValid(false));
    handleError(error, dispatch);
  } finally {
    dispatch(setIsValidatingToken(false));
  }
};

export const setNewPassword = (data) => async (dispatch) => {
  try {
    dispatch(setIsSaving(true));

    await api.postSetNewPassword(data)();

    await dispatch(
      authenticationActions.login({
        email: data.email,
        password: data.password,
      })
    );
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsSaving(false));
  }
};
