import { createApiV2, createApiPdf } from "./common";

export const getOrderAttachments =
  ({ customerUid, orderUid } = {}) =>
  (token) =>
    createApiV2("GET", `customers/${customerUid}/orders/${orderUid}/attachments/`)(token);

export const postPrintInvoicePdf = (data) => (token) =>
  createApiPdf("POST", "order/print_invoice", {
    responseType: "arraybuffer",
    Accept: "/application/pdf",
    headers: { "Content-Type": "multipart/form-data" },
  })(token, data);

export const postPrintQuotePdf = (data) => (token) =>
  createApiPdf("POST", "order/print_quote", {
    responseType: "arraybuffer",
    Accept: "/application/pdf",
    headers: { "Content-Type": "multipart/form-data" },
  })(token, data);

export const postSendEmailPdf =
  ({ customerUid, orderUid, data } = {}) =>
  (token) =>
    createApiPdf("POST", `customers/${customerUid}/orders/${orderUid}/notify/`, {
      headers: { "Content-Type": "multipart/form-data" },
    })(token, data);
