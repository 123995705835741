export default {
  control: (provided) => ({
    ...provided,
    width: "327px",
    background: "#f8f8f8",
    borderColor: "#f8f8f8",
    minHeight: "30px",
    height: "30px",
    boxShadow: null,
    paddingLeft: "10px",
    borderRadius: "5px",
  }),

  valueContainer: (provided) => ({
    ...provided,
    height: "30px",
    padding: "0 6px",
    width: 300,
  }),

  input: (provided) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: "30px",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  option: (provided, { isSelected }) => ({
    ...provided,
    "[class*='description']": {
      color: isSelected ? "#fff" : "#8492a6",
    },
  }),
  groupHeading: (provided) => ({
    ...provided,
    color: "#20b2aa",
    fontSize: "0.75rem",
    fontWeight: "600",
    textTransform: "uppercase",
  }),
};
