import { MODULE } from "./add-widgets.actionTypes";

export const search = (state) => state.getIn([MODULE, "search"]);

export const isModalOpen = (state) => state.getIn([MODULE, "isModalOpen"]);

export const activeCategory = (state) => state.getIn([MODULE, "activeCategory"]);

export const widgetsStatus = (state) => state.getIn([MODULE, "widgetsStatus"]);
export const widgets = (state) => state.getIn([MODULE, "widgets"]);
export const widgetsReference = (state) => state.getIn([MODULE, "widgetsReference"]);

export const widgetsCategoriesStatus = (state) => state.getIn([MODULE, "widgetsCategoriesStatus"]);
export const widgetsCategories = (state) => state.getIn([MODULE, "widgetsCategories"]);
