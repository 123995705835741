import createActionType from "@/redux/utils/createActionType";

export const MODULE = "timesheets/timeEntry";

export const RESET = createActionType(MODULE, "reset");

export const SET_IS_SAVING_STATUS = createActionType(MODULE, "setIsSavingStatus");

export const SET_IS_FETCHING_TEAM_MEMBERS_STATUS = createActionType(
  MODULE,
  "setIsFetchingTeamMembersStatus"
);

export const SET_TEAM_MEMBERS = createActionType(MODULE, "setTeamMembers");

export const SET_IS_SEARCHING_ORDER_NUMBER_STATUS = createActionType(
  MODULE,
  "setIsSearchingOrderNumberStatus"
);

export const SET_ORDER_NUMBER_RESULTS = createActionType(MODULE, "setOrderNumberResults");

export const SET_ORDER_ITEMS = createActionType(MODULE, "setOrderItems");

export const SET_IS_FETCHING_ORDER_ITEMS_STATUS = createActionType(
  MODULE,
  "setIsFetchingOrderItemsStatus"
);
