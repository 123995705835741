class WebSocketManager {
  constructor() {
    this.webSockets = {};
  }

  createWebSocket(id, defaultUrl) {
    const auth = JSON.parse(window.localStorage.getItem("auth")) || {};
    const url = `${defaultUrl}/?token=${auth?.access_token}`;

    return new Promise((resolve, reject) => {
      if (!this.webSockets[id] || this.webSockets[id].readyState !== WebSocket.OPEN) {
        const ws = new WebSocket(url);

        ws.onopen = () => {
          this.webSockets[id] = ws;
          resolve(ws);
        };

        ws.onclose = () => {
          delete this.webSockets[id];
          reject(new Error("WebSocket connection closed."));
        };
      } else {
        // If the WebSocket is already open, resolve with the existing instance.
        resolve(this.webSockets[id]);
      }
    });
  }

  getWebSocket(id) {
    return new Promise((resolve, reject) => {
      const ws = this.webSockets[id];

      if (ws) {
        if (ws.readyState === WebSocket.OPEN) {
          resolve(ws); // WebSocket instance is open
        } else {
          reject(new Error("WebSocket instance is not open."));
        }
      } else {
        reject(new Error("WebSocket instance not found."));
      }
    });
  }

  closeWebSocket(id) {
    return new Promise((resolve) => {
      const ws = this.webSockets[id];

      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.onclose = () => {
          delete this.webSockets[id];
          resolve(); // WebSocket closed successfully
        };

        ws.close();
      } else {
        // If the WebSocket is not open, resolve immediately.
        resolve();
      }
    });
  }
}

export default WebSocketManager;
