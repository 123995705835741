import * as teamsApi from "@/apiv2/teams";
import { handleError } from "@/redux/utils/error";
import { STATUS } from "@/utils/constants";

import {
  SET_IS_MODAL_VISIBLE,
  SET_TEAM_MEMBERS,
  SET_IS_FETCHING,
  SET_CART,
  SET_ITEMS_TO_CART,
  SET_KIT_TYPE,
  RESET,
} from "./add-labour-line-items.actionTypes";

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setIsModalVisible = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_MODAL_VISIBLE,
    payload: status,
  });
};

export const setTeamMembers = (data) => (dispatch) => {
  dispatch({
    type: SET_TEAM_MEMBERS,
    payload: data,
  });
};

export const setIsFetching = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING,
    payload: status,
  });
};

export const setCart = (data) => (dispatch) => {
  dispatch({
    type: SET_CART,
    payload: data,
  });
};

export const setItemsToCart = (data) => (dispatch) => {
  dispatch({
    type: SET_ITEMS_TO_CART,
    payload: data,
  });
};

export const setKitType = (data) => (dispatch) => {
  dispatch({
    type: SET_KIT_TYPE,
    payload: data,
  });
};

export const fetchTeamMembers = () => async (dispatch) => {
  try {
    dispatch(setIsFetching(STATUS.PENDING));

    const { data } = await teamsApi.getTeams({
      with_inactive: false,
      include_invites: false,
    })();
    dispatch(setTeamMembers(data));
    dispatch(setIsFetching(STATUS.FULFILLED));
  } catch (error) {
    dispatch(setIsFetching(STATUS.REJECTED));
    handleError(error, dispatch);
  }
};
