import React, { useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal,
} from "@floating-ui/react";

import styles from "./Popover.module.scss";

const Popover = ({ render, button, onBlur, menuClassName, menuPlacement }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: (open) => {
      if (!open) {
        onBlur();
      }

      setIsOpen(open);
    },
    placement: menuPlacement,
    whileElementsMounted: autoUpdate,
    middleware: [offset(10), flip({ fallbackAxisSideDirection: "end" }), shift()],
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "tooltip" });

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);

  return (
    <>
      {button && (
        <div className={styles.button} ref={refs.setReference} {...getReferenceProps()}>
          {button}
        </div>
      )}
      <FloatingPortal>
        {isOpen && (
          <div
            className={cx(styles.menu, menuClassName)}
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            {render && render}
          </div>
        )}
      </FloatingPortal>
    </>
  );
};

Popover.defaultProps = {
  menuText: "",
  onBlur: () => null,
  menuClassName: "",
  disabled: false,
  menuPlacement: "bottom-end",
};

Popover.propTypes = {
  menuText: PropTypes.string,
  onBlur: PropTypes.func,
  menuClassName: PropTypes.string,
  menuPlacement: PropTypes.string,
};

export default Popover;
