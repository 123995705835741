import { MODULE } from "./products.actionTypes";

export const activeProductSelector = (state) => state.getIn([MODULE, "activeProduct"]);

export const getSelectedPriceLevels = (state) => state.getIn([MODULE, "selectedPriceLevels"]);

export const getSelectedAttributeVisibility = (state) =>
  state.getIn([MODULE, "selectedAttributeVisiblity"]);

export const productCategoriesSelector = (state) => state.getIn([MODULE, "productCategories"]);

export const recentlyDeletedProductSelector = (state) =>
  state.getIn([MODULE, "recentlyDeletedProduct"]);

export const additionalProductsSelector = (state) =>
  state.getIn(["additionalProducts", "dataServer", "items"]);

export const isDuplicatingSelector = (state) => state.getIn([MODULE, "isDuplicating"]);

export const getErrorInCustomFormula = (state) => state.getIn([MODULE, "errorInCustomFormula"]);

export const isSavingCustomFormula = (state) => state.getIn([MODULE, "isSavingCustomFormula"]);

export const isSavingProduct = (state) => state.getIn([MODULE, "isSavingProduct"]);

export const getActiveProductVariant = (state) => state.getIn([MODULE, "activeProductVariant"]);

export const isFetchingProduct = (state) => state.getIn([MODULE, "isFetchingProduct"]);
