import * as routerSelectors from "../../router/router.selectors";

import * as selectors from "./showHideOrderFilter.selectors";
import { SET_SELECTED_FILTER, RESET } from "./showHideOrderFilter.actionTypes";

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setSelectedFilter = (data) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_FILTER,
    payload: data,
  });
};

export const setFilter = (option) => (dispatch, getState) => {
  const state = getState();

  const selectedFilter = selectors.getSelectedFilter(state);
  const allOptions = selectors.getOptions(state);

  if (option === "" && selectedFilter.length === allOptions.length) {
    return dispatch(setSelectedFilter([]));
  }

  if (
    option === "" &&
    (selectedFilter.length === 0 || selectedFilter.length < allOptions.length + 1)
  ) {
    return dispatch(setSelectedFilter(allOptions.map((text) => text.value.toLowerCase())));
  }

  if (option === "" && selectedFilter.length === 0) {
    return dispatch(setSelectedFilter(allOptions.map((text) => text.value.toLowerCase())));
  }

  if (selectedFilter.includes(option)) {
    return dispatch(setSelectedFilter(selectedFilter.filter((text) => text !== option)));
  }

  return dispatch(setSelectedFilter([...selectedFilter, option]));
};
