import createActionType from "../utils/createActionType";

const MODULE = "suppliers-settings";

export const IS_UPDATING_SUPPLIER = createActionType(MODULE, "isUpdatingSupplier");
export const IS_FETCHING_SUPPLIERS = createActionType(MODULE, "isFetchingSuppliers");

export const UPDATE_SUPPLIER = createActionType(MODULE, "updateSupplier");

export const SET_ACCOUNTING_SUPPLIERS = createActionType(MODULE, "setAccountingSuppliers");

export const SET_ACCOUNTING_ACTIVE_SUPPLIER = createActionType(
  MODULE,
  "setAccountingActiveSupplier"
);

export const SET_ACCOUNTING_ACTIVE_SUPPLIER_NAME = createActionType(
  MODULE,
  "setAccountingActiveSupplierName"
);
