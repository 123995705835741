import {
  SET_ORDER_IN_CALENDAR,
  SET_IS_FETCHING_SCHEDULES,
  SET_FROM_DATE,
  SET_TO_DATE,
  SET_IS_CONTEXT_MENU_OPEN,
  SET_CALENDAR_VIEW,
  SET_ACTIVE_SCHEDULE,
  SET_DATA_IN_CALENDAR_ENTRY,
  RESET,
} from "./calendar.actionTypes";

const initialState = {
  isFetchingSchedules: false,
  isContextMenuOpen: false,
  isSavingOrderInCalendar: false,
  schedules: [],
  fromDate: null,
  toDate: null,
  calendarView: "week",
  activeSchedule: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_FETCHING_SCHEDULES: {
      return {
        ...state,
        isFetchingSchedules: action.payload,
      };
    }

    case SET_ORDER_IN_CALENDAR: {
      return {
        ...state,
        schedules: action.payload,
      };
    }

    case SET_FROM_DATE: {
      return {
        ...state,
        fromDate: action.payload,
      };
    }

    case SET_TO_DATE: {
      return {
        ...state,
        toDate: action.payload,
      };
    }

    case SET_IS_CONTEXT_MENU_OPEN: {
      return {
        ...state,
        isContextMenuOpen: action.payload,
      };
    }

    case SET_CALENDAR_VIEW: {
      return {
        ...state,
        calendarView: action.payload,
      };
    }

    case SET_ACTIVE_SCHEDULE: {
      return {
        ...state,
        activeSchedule: action.payload,
      };
    }

    case SET_DATA_IN_CALENDAR_ENTRY: {
      const { plannerUid, data } = action.payload;
      return {
        ...state,
        schedules: state.schedules.map((s) => (s.uid === plannerUid ? { ...s, ...data } : s)),
      };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
