import { nanoid } from "nanoid";
import camelcaseKeys from "camelcase-keys";

import { handleError } from "../utils/error";
import * as api from "../../apiv2/additionalProducts";
import * as flashingApi from "../../apiv2/flashingProducts";
import * as alertActions from "../alerts/alerts.actions";

import * as selectors from "./productCatalogue.selectors";

import {
  SET_IS_FETCHING_CATEGORIES,
  SET_IS_ADD_CATEGORY_FORM_VISIBLE,
  SET_CATEGORIES,
  SET_IS_CREATING_CATEGORY,
  SET_IS_UPDATING_CATEGORY,
  SET_PRODUCTS,
  SET_IS_FETCHING_PRODUCTS,
  SET_SEARCH,
  SET_SEARCH_PRODUCTS,
  SET_EXPANDED_CATEGORIES,
  SET_SELECTED_PRODUCTS,
  SET_SELECTED_CATEGORIES,
  SET_SELECTED_FLASHING_PRODUCTS,
  SET_IS_DUPLICATING_PRODUCTS,
  SET_CATEGORY_NAME,
} from "./productCatalogue.actionTypes";

export const setIsFetchingCategories = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_CATEGORIES,
    payload: status,
  });
};

export const setIsAddCategoryFormVisible = (status) => async (dispatch) => {
  await dispatch({
    type: SET_IS_ADD_CATEGORY_FORM_VISIBLE,
    payload: status,
  });
};

export const setIsCreatingCategory = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_CREATING_CATEGORY,
    payload: status,
  });
};

export const setIsUpdatingCategory = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_UPDATING_CATEGORY,
    payload: status,
  });
};

export const setIsFetchingPoducts = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_PRODUCTS,
    payload: {
      data,
    },
  });
};

export const setIsDuplicatingProducts = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_DUPLICATING_PRODUCTS,
    payload: status,
  });
};

export const setExpandedCategories = (categoryUids) => (dispatch) => {
  dispatch({
    type: SET_EXPANDED_CATEGORIES,
    payload: {
      data: categoryUids.filter((item, index) => categoryUids.indexOf(item) === index),
    },
  });
};

export const setSearch = (data) => (dispatch) => {
  dispatch({
    type: SET_SEARCH,
    payload: data,
  });
};

export const setSearchProducts =
  ({ categoryUids, products }) =>
  (dispatch) => {
    if (categoryUids.length > 0) {
      dispatch({
        type: SET_SEARCH_PRODUCTS,
        payload: categoryUids.reduce(
          (acc, curr) => ((acc[curr] = products.filter((item) => item.categoryUid === curr)), acc),
          {}
        ),
      });
    } else {
      dispatch({
        type: SET_SEARCH_PRODUCTS,
        payload: {},
      });
    }
  };

export const setCategoryName = (data) => (dispatch) => {
  dispatch({
    type: SET_CATEGORY_NAME,
    payload: {
      data,
    },
  });
};

export const setCategories = (data) => (dispatch) => {
  dispatch({
    type: SET_CATEGORIES,
    payload: {
      data,
    },
  });
};

export const setProductsByCategory =
  ({ data, categoryUid }) =>
  (dispatch) => {
    dispatch({
      type: SET_PRODUCTS,
      payload: {
        data,
        categoryUid,
      },
    });
  };

export const fetchCategories = () => async (dispatch) => {
  try {
    dispatch(setIsFetchingCategories(true));
    const response = await api.getCategories()();
    dispatch(
      setCategories(
        [...response.data].map((item) => ({
          ...item,
          isFetching: false,
        }))
      )
    );
    return response;
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetchingCategories(false));
  }
};

export const createCategory = (data) => async (dispatch, getState) => {
  const state = getState();
  dispatch(setIsCreatingCategory(true));
  if (["product kits", "flashings", "miscellaneous"].includes(data.name.toLowerCase())) {
    return dispatch(
      alertActions.createAlertAction(
        nanoid(),
        "This word is reserved for another category",
        true,
        "danger"
      )
    );
  }
  try {
    const response = await api.postCategory(data)();

    const categories = selectors.getCategories(state);

    dispatch(
      setCategories([
        ...categories,
        {
          uid: response.data,
          name: data.name,
          isFetching: false,
        },
      ])
    );
    dispatch(setIsAddCategoryFormVisible(false));
    return response;
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsCreatingCategory(false));
  }
};

export const renameCategory = (data) => async (dispatch, getState) => {
  const state = getState();
  const categories = selectors.getCategories(state);
  if (["flashings", "miscellaneous"].includes(data.name.toLowerCase())) {
    dispatch(setCategoryName(data));

    dispatch(
      alertActions.createAlertAction(
        nanoid(),
        "This word is reserved for another category",
        true,
        "danger"
      )
    );

    dispatch(
      setCategoryName({
        uid: data.uid,
        name: categories.find((c) => c.uid === data.uid).name,
      })
    );

    return;
  }
  try {
    await api.updateCategory({ ...data })();
    dispatch(
      setCategories(
        [...categories].map((item) =>
          item.uid === data.uid ? { ...item, name: data.name } : { ...item }
        )
      )
    );
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const deleteCategory = (data) => async (dispatch, getState) => {
  const state = getState();

  const categories = selectors.getCategories(state);
  const expandedCategories = selectors.getExpandedCategories(state);
  const productsForCategory = selectors.getProductsForCategory(state, data.uid).map((p) => p.uid);

  dispatch(setIsUpdatingCategory(true));
  try {
    await api.updateCategory({ ...data, is_deleted: true })();

    if (productsForCategory.length > 0) {
      const miscellaneous = selectors.getMiscellaneousCategory(state);
      if (miscellaneous) {
        await dispatch(
          bulkAssignProductsToCategory([
            {
              category_uid: miscellaneous.uid,
              products: productsForCategory,
            },
          ])
        );

        if (expandedCategories.includes(miscellaneous.uid)) {
          await dispatch(
            fetchProductsByCategory({
              category: miscellaneous.name,
              categoryUid: miscellaneous.uid,
            })
          );
        }
      }
    }
    dispatch(setCategories(categories.filter((c) => c.uid !== data.uid)));

    await dispatch(
      alertActions.createAlertAction(
        nanoid(),
        "Category deleted.",
        true,
        "danger",
        "Undo",
        async () => {
          const response = await dispatch(createCategory({ name: data.name }));
          await dispatch(
            bulkAssignProductsToCategory([
              {
                category_uid: response.data,
                products: productsForCategory,
              },
            ])
          );

          const miscellaneous = selectors.getMiscellaneousCategory(state);
          if (miscellaneous) {
            await dispatch(
              fetchProductsByCategory({
                category: miscellaneous.name,
                categoryUid: miscellaneous.uid,
              })
            );
          }
        }
      )
    );
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsUpdatingCategory(false));
  }
};

export const fetchProductsByCategory = (data) => async (dispatch, getState) => {
  const state = getState();

  if (selectors.getCategories(state).length === 0) {
    const response = await api.getCategories()();
    dispatch(
      setIsFetchingPoducts({
        uid: response.data.find((c) => c.uid === data.categoryUid)?.uid ?? "",
        isFetching: true,
      })
    );
  } else {
    dispatch(
      setIsFetchingPoducts({
        uid: selectors.getCategories(state).find((c) => c.uid === data.categoryUid)?.uid ?? "",
        isFetching: true,
      })
    );
  }

  try {
    const response =
      data.category === "Flashings"
        ? await api.getProductsByFlashings()()
        : await api.getProductsByCategory({
            search: data.search || undefined,
            category: data?.category ?? undefined,
          })();

    dispatch(
      setProductsByCategory({
        data:
          data.category === "Flashings"
            ? response.data.map((data) => ({
                ...data,
                category: "Flashings",
                categoryUid: "Flashings",
              }))
            : response.data,
        categoryUid: data.categoryUid,
      })
    );

    return response;
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(
      setIsFetchingPoducts({
        uid: data.categoryUid,
        isFetching: false,
      })
    );
  }
};

export const searchProducts =
  ({ search }) =>
  async (dispatch) => {
    dispatch(setSearchProducts({ categoryUids: [], products: {} }));

    if (search !== "") {
      try {
        dispatch(setIsFetchingCategories(true));
        const { data } = await api.getProductsByCategory({
          search,
        })();
        const response = camelcaseKeys(data, { deep: true });

        if (response.length > 0) {
          const categories = response.map((item) => item.categoryUid);
          const uniqueCategoryUids = categories.filter(
            (item, index) => categories.indexOf(item) === index
          );

          dispatch(setExpandedCategories(uniqueCategoryUids));
          dispatch(
            setSearchProducts({
              categoryUids: uniqueCategoryUids,
              products: response,
            })
          );
        }
        dispatch(setIsFetchingCategories(false));
      } catch (error) {
        handleError(error, dispatch);
      }
    } else {
      dispatch(fetchCategories());
      dispatch(setExpandedCategories([]));
    }
  };

export const setSelectedCategories = (categoryUids) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_CATEGORIES,
    payload: {
      data: categoryUids,
    },
  });
};

export const setSelectedProducts = (productUids) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_PRODUCTS,
    payload: {
      selectedProducts: productUids,
    },
  });
};

export const setSelectedFlashingProducts = (productUids) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_FLASHING_PRODUCTS,
    payload: {
      selectedFlashingProducts: productUids,
    },
  });
};

export const setSelectedFlashingProduct =
  ({ productUid, checked } = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const selectedProductUids = selectors.getSelectedFlashingProducts(state);

    const productUids = checked
      ? [...selectedProductUids, productUid]
      : selectedProductUids.filter((selected) => selected !== productUid);
    dispatch(setSelectedFlashingProducts(productUids));
  };

export const setSelectedProduct =
  ({ productUid, checked } = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const selectedProductUids = selectors.getSelectedProducts(state);

    const productUids = checked
      ? [...selectedProductUids, productUid]
      : selectedProductUids.filter((selected) => selected !== productUid);
    dispatch(setSelectedProducts(productUids));
  };

export const toggleCategory =
  ({ categoryUid, categoryName, checked } = {}) =>
  async (dispatch, getState) => {
    const state = getState();
    const selectedProducts = selectors.getSelectedProducts(state);
    const selectedFlashingProducts = selectors.getSelectedFlashingProducts(state);
    const selectedCategories = selectors.getSelectedCategories(state);
    const expandedCategories = selectors.getExpandedCategories(state);
    const productsFromCategory = selectors.getProductsForCategory(state, categoryUid);
    const newSelectedCategories = checked
      ? [...selectedCategories, categoryUid]
      : [...selectedCategories.filter((category) => category !== categoryUid)];

    dispatch(setSelectedCategories(newSelectedCategories));

    if (checked) {
      dispatch(setExpandedCategories([...expandedCategories, categoryUid]));
      try {
        if (productsFromCategory.length === 0) {
          const response = await dispatch(
            fetchProductsByCategory({ category: categoryName, categoryUid })
          );
          if (response.data.length) {
            if (categoryName === "Flashings") {
              const productUids = [
                ...selectedFlashingProducts,
                ...response.data.map((product) => product.uid),
              ];
              dispatch(setSelectedFlashingProducts(productUids));
            }
            if (categoryName !== "Flashings") {
              const productUids = [
                ...selectedProducts,
                ...response.data.map((product) => product.uid),
              ];
              dispatch(setSelectedProducts(productUids));
            }
          }
        } else {
          if (categoryName === "Flashings") {
            const productUids = [
              ...selectedFlashingProducts,
              ...productsFromCategory.map((product) => product.uid),
            ];
            dispatch(setSelectedFlashingProducts(productUids));
          }

          if (categoryName !== "Flashings") {
            const productUids = [
              ...selectedProducts,
              ...productsFromCategory.map((product) => product.uid),
            ];
            dispatch(setSelectedProducts(productUids));
          }
        }
      } catch (error) {
        handleError(error, dispatch);
      }
    } else {
      if (categoryName === "Flashings") {
        const productUids = selectedFlashingProducts.filter(
          (selected) => !productsFromCategory.map((product) => product.uid).includes(selected)
        );
        dispatch(setSelectedFlashingProducts(productUids));
      }

      if (categoryName !== "Flashings") {
        const productUids = selectedProducts.filter(
          (selected) => !productsFromCategory.map((product) => product.uid).includes(selected)
        );
        dispatch(setSelectedProducts(productUids));
      }
    }
  };

export const bulkArchiveProducts = ({ selectedProducts, selectedFlashingProducts }) => {
  return async (dispatch, getState) => {
    const state = getState();
    const expandedCategories = selectors.getExpandedCategories(state);
    const selectedCategories = selectors.getSelectedCategories(state);
    const categories = selectors.getCategories(state);
    const products = selectors.getProducts(state);
    const categoriesToFetch = categories.filter((category) =>
      expandedCategories.includes(category.uid)
    );

    try {
      if (selectedProducts.length > 0) {
        await api.bulkArchiveProducts({ uids: selectedProducts })();
      }

      if (selectedFlashingProducts.length > 0) {
        await flashingApi.bulkArchiveProducts({
          uids: selectedFlashingProducts,
        })();
      }

      await Promise.all(
        categoriesToFetch.map((category) =>
          dispatch(
            fetchProductsByCategory({
              categoryUid: category.uid,
              category: category.name,
            })
          )
        )
      );

      if (selectedProducts.length > 0 || selectedFlashingProducts.length > 0) {
        dispatch(
          alertActions.createAlertAction(
            nanoid(),
            "Products deleted.",
            true,
            "danger",
            "Undo",
            async () => {
              await dispatch(
                bulkUnarchiveProducts({
                  selectedProducts,
                  selectedFlashingProducts,
                })
              );
            }
          )
        );
      }

      dispatch(setSelectedCategories([]));
      dispatch(setSelectedProducts([]));
      dispatch(setSelectedFlashingProducts([]));

      const reservedCategories = categories.filter(
        (c) => selectedCategories.includes(c.uid) && ["Miscellaneous", "Flashings"].includes(c.name)
      );
      if (reservedCategories.length > 0) {
        dispatch(
          alertActions.createAlertAction(
            nanoid(),
            "This category cannot be permanently deleted",
            true,
            "danger"
          )
        );
      }

      const filteredSelectedCategories = categories.filter(
        (c) =>
          selectedCategories.includes(c.uid) && !["Miscellaneous", "Flashings"].includes(c.name)
      );
      const filteredSelectedCategoriesUids = filteredSelectedCategories.map((c) => c.uid);
      const categoriesToBeDeleted = filteredSelectedCategoriesUids.filter(
        (c) => !Object.keys(products).includes(c.uid)
      );
      if (categoriesToBeDeleted.length > 0) {
        await dispatch(bulkArchiveCategories(categoriesToBeDeleted));
      }
    } catch (error) {
      handleError(error, dispatch);
    }
  };
};

export const bulkUnarchiveProducts = ({ selectedProducts, selectedFlashingProducts }) => {
  return async (dispatch, getState) => {
    const state = getState();
    const expandedCategories = selectors.getExpandedCategories(state);
    const categories = selectors.getCategories(state);
    const categoriesToFetch = categories.filter((category) =>
      expandedCategories.includes(category.uid)
    );

    try {
      if (selectedProducts.length > 0) {
        await api.bulkUnarchiveProducts({ uids: selectedProducts })();
      }

      if (selectedFlashingProducts.length > 0) {
        await flashingApi.bulkUnarchiveProducts({
          uids: selectedFlashingProducts,
        })();
      }

      await Promise.all(
        categoriesToFetch.map((category) =>
          dispatch(
            fetchProductsByCategory({
              categoryUid: category.uid,
              category: category.name,
              search: "",
            })
          )
        )
      );
    } catch (error) {
      handleError(error, dispatch);
    }
  };
};

export const bulkArchiveCategories = (categoryUids) => async (dispatch, getState) => {
  const state = getState();
  const categories = selectors.getCategories(state);
  try {
    await api.postBulkArchiveCategories({
      uids: categoryUids,
    })();

    dispatch(setCategories(categories.filter((c) => !categoryUids.includes(c.uid))));

    dispatch(
      alertActions.createAlertAction(
        nanoid(),
        "Categories deleted.",
        true,
        "danger",
        "Undo",
        async () => {
          await dispatch(bulkUnarchiveCategories(categoryUids));
        }
      )
    );
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const bulkUnarchiveCategories = (categoryUids) => async (dispatch, getState) => {
  try {
    const state = getState();
    const selectedCategories = selectors.getSelectedCategories(state);
    await api.postBulkUnarchiveCategories({
      uids: categoryUids,
    })();

    dispatch(
      setSelectedCategories(
        selectedCategories.filter((selected) => !categoryUids.includes(selected))
      )
    );

    await dispatch(fetchCategories());
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const bulkAssignProductsToCategory = (data) => async (dispatch) => {
  try {
    await api.postBulkAssignProductsToCategory(data)();
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const duplicateProduct =
  ({ selectedProducts = [], selectedFlashingProducts = [] }) =>
  async (dispatch, getState) => {
    const state = getState();
    const isDuplicatingProducts = selectors.isDuplicatingProducts(state);

    if (isDuplicatingProducts) return;

    if (selectedProducts.length === 0 && selectedFlashingProducts.length === 0) return;

    const expandedCategories = selectors.getExpandedCategories(state);
    const categories = selectors.getCategories(state);
    const categoriesToFetch = categories.filter((category) =>
      expandedCategories.includes(category.uid)
    );

    try {
      dispatch(setIsDuplicatingProducts(true));
      if (selectedProducts.length > 0) {
        await api.postDuplicateProduct({ uids: selectedProducts })();
      }

      if (selectedFlashingProducts.length > 0) {
        await flashingApi.postDuplicateProduct({
          uids: selectedFlashingProducts,
        })();
      }

      dispatch(setIsDuplicatingProducts(false));

      if (
        (selectedProducts.length > 0 && selectedFlashingProducts.length > 0) ||
        (selectedProducts.length > 0 && selectedFlashingProducts.length === 0)
      ) {
        const totalSelectedProducts = selectedProducts.length + selectedFlashingProducts.length;

        dispatch(
          alertActions.createAlertAction(
            nanoid(),
            `Product${totalSelectedProducts > 1 ? "s" : ""} duplicated.`,
            true,
            "success"
          )
        );
      } else if (selectedProducts.length === 0 && selectedFlashingProducts.length > 0) {
        dispatch(
          alertActions.createAlertAction(
            nanoid(),
            `Flashing material${selectedFlashingProducts.length > 1 ? "s" : ""} duplicated.`,
            true,
            "success"
          )
        );
      }
      await Promise.all(
        categoriesToFetch.map((category) =>
          dispatch(
            fetchProductsByCategory({
              categoryUid: category.uid,
              category: category.name,
              search: "",
            })
          )
        )
      );
      dispatch(setSelectedCategories([]));
      dispatch(setSelectedProducts([]));
      dispatch(setSelectedFlashingProducts([]));
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(setIsDuplicatingProducts(false));
    }
  };
