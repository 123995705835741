import { uniq, isNull, orderBy } from "lodash";
import Decimal from "decimal.js-light";

import { handleError } from "../utils/error";
import {
  PRICING_STRATEGY_LINEAL_METRES_LABEL,
  PRICING_STRATEGY_CUSTOM_FORMULA_LABEL,
  PRODUCT_TYPE_ADDITIONAL_PRODUCT_LABEL,
  PRODUCT_TYPE_LABOUR_PRODUCT_LABEL,
} from "../../utils/constants";

import * as api from "../../apiv2/productKits";

import * as selectors from "./productKits.selectors";
import {
  SET_SEARCH,
  SET_IS_FETCHING_CATEGORIES,
  SET_IS_FETCHING_PRODUCT_KIT,
  SET_IS_SAVING,
  SET_IS_FETCHING_PRODUCTS,
  SET_CATEGORIES,
  SET_PRODUCTS,
  SET_EMPTY_PRODUCTS,
  SET_EXPANDED_CATEGORIES,
  SET_EXPANDED_PRODUCTS,
  SET_EXPANDED_SUB_KIT_PRODUCTS,
  SET_PRODUCT_KIT,
  SET_SELECTED_PRICE_LEVELS,
  SET_ERROR_IN_CUSTOM_FORMULA,
  SET_ERROR_IN_SUB_KIT_CUSTOM_FORMULA,
  ADD_PRODUCT_TO_CART,
  ADD_LABOUR_ITEMS,
  ADD_LABOUR_ITEM_TO_SUBKIT,
  UPDATE_LABOUR_ITEM,
  UPDATE_LABOUR_ITEM_IN_SUBKIT,
  ADD_PRODUCT_TO_SUB_KIT_CART,
  ADD_EMPTY_SUB_KIT,
  ADD_SUB_KIT_FROM_KIT,
  UPDATE_CART_QUANTITY,
  UPDATE_SUB_KIT_CART_QUANTITY,
  UPDATE_CART_COLOUR,
  UPDATE_SUB_KIT_CART_COLOUR,
  UPDATE_CART_PRICE,
  UPDATE_SUB_KIT_CART_PRICE,
  UPDATE_CART,
  UPDATE_SUB_KIT_CART,
  UPDATE_CART_ATTRIBUTES,
  UPDATE_SUB_KIT_CART_ATTRIBUTES,
  DELETE_CART_ATTRIBUTES,
  DELETE_SUB_KIT_CART_ATTRIBUTES,
  DELETE_CART_ITEM,
  DELETE_SUB_KIT_CART_ITEM,
  ADD_KIT_LENGTH,
  DELETE_KIT_LENGTH,
  ADD_KIT_DIMENSION,
  DELETE_KIT_DIMENSION,
  ADD_SUB_KIT_LENGTH,
  DELETE_SUB_KIT_LENGTH,
  ADD_SUB_KIT_DIMENSION,
  DELETE_SUB_KIT_DIMENSION,
  DELETE_SUB_KIT,
  CREATE_PRODUCT_KIT_FROM_CART,
  CREATE_PRODUCT_KIT_FROM_LABOUR_LINE_ITEMS_CART,
  CREATE_SUB_KIT_FROM_LABOUR_LINE_ITEMS_CART,
  UPDATE_CURRENT_PRODUCT_KIT,
  UPDATE_SUB_KIT_TITLE,
  UPDATE_PRODUCT_KIT_PRICING_TABLE,
  SET_TARGET_SUB_KIT_INDEX,
  SET_IS_SUB_KIT_MODAL_OPEN,
  TOGGLE_PRODUCT_KIT_MODAL,
  TOGGLE_CART,
  SET_KIT_LABOUR_LINE_ITEMS,
  RESET_PRODUCT_KIT,
  RESET_EXPANDED_PRODUCTS,
  RESET_EXPANDED_SUB_KIT_PRODUCTS,
  RESET,
} from "./productKits.actionTypes";

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setSearch = (data) => (dispatch) => {
  dispatch({
    type: SET_SEARCH,
    payload: data,
  });
};

export const setIsFetchingCategories = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_CATEGORIES,
    payload: status,
  });
};

export const setIsFetchingProductKit = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_PRODUCT_KIT,
    payload: status,
  });
};

export const setIsSaving = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_SAVING,
    payload: status,
  });
};

export const setCategories = (data) => (dispatch) => {
  dispatch({
    type: SET_CATEGORIES,
    payload: {
      data,
    },
  });
};

export const setProductsByCategory =
  ({ data, categoryUid }) =>
  (dispatch) => {
    dispatch({
      type: SET_PRODUCTS,
      payload: {
        data,
        categoryUid,
      },
    });
  };
export const setEmptyProducts = () => (dispatch) => {
  dispatch({
    type: SET_EMPTY_PRODUCTS,
  });
};

export const setExpandedCategories = (categoryUids) => (dispatch) => {
  dispatch({
    type: SET_EXPANDED_CATEGORIES,
    payload: {
      data: categoryUids,
    },
  });
};

export const setExpandedProducts = (productUids) => (dispatch) => {
  dispatch({
    type: SET_EXPANDED_PRODUCTS,
    payload: {
      data: productUids,
    },
  });
};

export const setExpandedSubKitProducts =
  ({ subKitIndex, productUids }) =>
  (dispatch) => {
    dispatch({
      type: SET_EXPANDED_SUB_KIT_PRODUCTS,
      payload: {
        subKitIndex,
        data: productUids,
      },
    });
  };

export const setIsFetchingPoducts = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_PRODUCTS,
    payload: {
      data,
    },
  });
};

export const setSelectedPriceLevels = (data) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_PRICE_LEVELS,
    payload: {
      data,
    },
  });
};

export const setErrorInCustomFormula =
  ({ cartIndex, errorInCustomFormula }) =>
  (dispatch) => {
    dispatch({
      type: SET_ERROR_IN_CUSTOM_FORMULA,
      payload: {
        cartIndex,
        errorInCustomFormula,
      },
    });
  };

export const setErrorInSubKitCustomFormula =
  ({ subKitIndex, cartIndex, errorInCustomFormula }) =>
  (dispatch) => {
    dispatch({
      type: SET_ERROR_IN_SUB_KIT_CUSTOM_FORMULA,
      payload: {
        subKitIndex,
        cartIndex,
        errorInCustomFormula,
      },
    });
  };

export const fetchCategories = () => async (dispatch) => {
  try {
    dispatch(setIsFetchingCategories(true));
    const response = await api.getCategories()();
    const categories = response.data;
    dispatch(
      setCategories(
        [...categories].map((item) => ({
          ...item,
          isFetching: false,
        }))
      )
    );
    return response;
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetchingCategories(false));
  }
};

export const fetchProductsByCategory = (data) => async (dispatch, getState) => {
  const state = getState();
  const search = selectors.getSearch(state);
  const categories = selectors.getCategories(state);

  if (categories.length === 0) {
    const response = await api.getCategories()();
    dispatch(
      setIsFetchingPoducts({
        uid: response.data.find((c) => c.uid === data.uid)?.uid ?? "",
        isFetching: true,
      })
    );
  } else {
    dispatch(
      setIsFetchingPoducts({
        uid: selectors.getCategories(state).find((c) => c.uid === data.uid)?.uid ?? "",
        isFetching: true,
      })
    );
  }

  try {
    const category = categories.find((c) => c.uid === data.uid);
    const response = await api.getProductsByCategory({
      category: category.name ?? undefined,
      search: search || undefined,
    })();

    dispatch(
      setProductsByCategory({
        data: response.data,
        categoryUid: data.uid,
      })
    );

    return response;
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(
      setIsFetchingPoducts({
        uid: data.uid,
        isFetching: false,
      })
    );
  }
};

export const searchProducts = () => async (dispatch, getState) => {
  const state = getState();
  const search = selectors.getSearch(state);

  if (search !== "") {
    try {
      dispatch(setEmptyProducts());
      dispatch(setIsFetchingCategories(true));
      const { data } = await api.getProductsByCategory({
        search: search || undefined,
      })();

      if (data.length > 0) {
        const uniqueCategories = uniq(data.map((item) => item.categoryUid));

        dispatch(setExpandedCategories(uniqueCategories));
        await Promise.all(
          uniqueCategories.map((category) =>
            dispatch(
              setProductsByCategory({
                data: data.filter((item) => item.categoryUid === category),
                categoryUid: category,
              })
            )
          )
        );
      }
    } catch (error) {
      handleError(error);
    } finally {
      dispatch(setIsFetchingCategories(false));
    }
  } else {
    dispatch(fetchCategories());
    dispatch(setExpandedCategories([]));
    dispatch(setEmptyProducts());
  }
};

export const addProductToCart =
  ({ product, productRow }) =>
  (dispatch, getState) => {
    const state = getState();
    const cart = selectors.getCart(state);
    const cartProductRowUids = cart
      .filter((c) => c.productType === PRODUCT_TYPE_ADDITIONAL_PRODUCT_LABEL)
      .map((c) => c.productRow.uid);

    if (!cartProductRowUids.includes(productRow.original.uid)) {
      return dispatch({
        type: ADD_PRODUCT_TO_CART,
        payload: {
          product,
          productRow,
        },
      });
    }

    if (
      cartProductRowUids.includes(productRow.original.uid) &&
      isNull(productRow.original.colour)
    ) {
      // for non colour product
      const cartIndex = cart.findIndex((c) => c.productRow.uid === productRow.original.uid);
      if (cartIndex !== -1) {
        return dispatch(
          updateCartQuantity({
            cartIndex,
            newQuantity: Decimal(cart[cartIndex].quantity).plus(1).toDecimalPlaces(4).toFixed(4),
          })
        );
      }
    } else if (
      cartProductRowUids.includes(productRow.original.uid) &&
      !isNull(productRow.original.colour)
    ) {
      // for colour based product
      if (!!productRow.original.colourOptions && productRow.original.colourOptions.length > 0) {
        const cartIndex = cart.findIndex(
          (c) =>
            c.productRow.uid === productRow.original.uid &&
            c.productRow.colour === productRow.original.colour
        );
        // if the colour of the product is already in the cart
        if (cartIndex !== -1) {
          if (
            product.pricingStrategy === PRICING_STRATEGY_LINEAL_METRES_LABEL &&
            productRow.original.colour.toLowerCase() !== "any"
          ) {
            return dispatch({
              type: ADD_PRODUCT_TO_CART,
              payload: {
                product,
                productRow,
              },
            });
          }
          return dispatch(
            updateCartQuantity({
              cartIndex,
              newQuantity:
                product.pricingStrategy !== PRICING_STRATEGY_LINEAL_METRES_LABEL
                  ? Decimal(cart[cartIndex].quantity).plus(1).toDecimalPlaces(4).toFixed(4)
                  : null,
            })
          );
        } else {
          dispatch({
            type: ADD_PRODUCT_TO_CART,
            payload: {
              product,
              productRow,
            },
          });
        }
      }
    }
  };

export const addLabourItems = (data) => (dispatch) => {
  dispatch({
    type: ADD_LABOUR_ITEMS,
    payload: data,
  });
};

export const addLabourLineItemToSubkit =
  ({ data, subKitIndex }) =>
  (dispatch) => {
    dispatch({
      type: ADD_LABOUR_ITEM_TO_SUBKIT,
      payload: {
        data,
        subKitIndex,
      },
    });
  };

export const updateLabourItem =
  ({ labourItemIndex, data }) =>
  (dispatch) => {
    dispatch({
      type: UPDATE_LABOUR_ITEM,
      payload: {
        labourItemIndex,
        data,
      },
    });
  };

export const updateLabourItemInSubKit =
  ({ subKitIndex, labourItemIndex, data }) =>
  (dispatch) => {
    dispatch({
      type: UPDATE_LABOUR_ITEM_IN_SUBKIT,
      payload: {
        subKitIndex,
        labourItemIndex,
        data,
      },
    });
  };

export const addProductToSubKitCart =
  ({ subKitIndex, product, productRow }) =>
  (dispatch, getState) => {
    const state = getState();
    const subKits = selectors.getSubKits(state);
    const cart = subKits[subKitIndex].kitProducts;
    const cartProductRowUids = cart
      .filter((c) => c.productType === PRODUCT_TYPE_ADDITIONAL_PRODUCT_LABEL)
      .map((c) => c.productRow.uid);

    if (!cartProductRowUids.includes(productRow.original.uid)) {
      return dispatch({
        type: ADD_PRODUCT_TO_SUB_KIT_CART,
        payload: {
          subKitIndex,
          product,
          productRow,
        },
      });
    }

    if (
      cartProductRowUids.includes(productRow.original.uid) &&
      isNull(productRow.original.colour)
    ) {
      // for non colour product
      const cartIndex = cart.findIndex((c) => c.productRow.uid === productRow.original.uid);
      if (cartIndex !== -1) {
        return dispatch(
          updateSubKitCartQuantity({
            subKitIndex,
            cartIndex,
            newQuantity: cart[cartIndex].quantity + 1,
          })
        );
      }
    } else if (
      cartProductRowUids.includes(productRow.original.uid) &&
      !isNull(productRow.original.colour)
    ) {
      // for colour based product
      if (!!productRow.original.colourOptions && productRow.original.colourOptions.length > 0) {
        const cartIndex = cart.findIndex(
          (c) =>
            c.productRow.uid === productRow.original.uid &&
            c.productRow.colour === productRow.original.colour
        );
        // if the colour of the product is already in the cart
        if (cartIndex !== -1) {
          if (
            product.pricingStrategy === PRICING_STRATEGY_LINEAL_METRES_LABEL &&
            productRow.original.colour.toLowerCase() !== "any"
          ) {
            return dispatch({
              type: ADD_PRODUCT_TO_SUB_KIT_CART,
              payload: {
                subKitIndex,
                product,
                productRow,
              },
            });
          }
          return dispatch(
            updateSubKitCartQuantity({
              subKitIndex,
              cartIndex,
              newQuantity:
                product.pricingStrategy !== PRICING_STRATEGY_LINEAL_METRES_LABEL
                  ? cart[cartIndex].quantity + 1
                  : null,
            })
          );
        } else {
          dispatch({
            type: ADD_PRODUCT_TO_SUB_KIT_CART,
            payload: {
              subKitIndex,
              product,
              productRow,
            },
          });
        }
      }
    }
  };

export const addEmptySubKit = () => (dispatch) => {
  dispatch({
    type: ADD_EMPTY_SUB_KIT,
    payload: {},
  });
};

export const addSubKitFromKit =
  ({ productKit }) =>
  (dispatch) => {
    dispatch({
      type: ADD_SUB_KIT_FROM_KIT,
      payload: { productKit },
    });
  };

export const updateCartQuantity =
  ({ cartIndex, newQuantity }) =>
  (dispatch) => {
    dispatch({
      type: UPDATE_CART_QUANTITY,
      payload: {
        cartIndex,
        newQuantity,
      },
    });
  };

export const updateSubKitCartQuantity =
  ({ subKitIndex, cartIndex, newQuantity }) =>
  (dispatch) => {
    dispatch({
      type: UPDATE_SUB_KIT_CART_QUANTITY,
      payload: {
        subKitIndex,
        cartIndex,
        newQuantity,
      },
    });
  };

export const updateCartColour =
  ({ cartIndex, newColour }) =>
  (dispatch) => {
    dispatch({
      type: UPDATE_CART_COLOUR,
      payload: {
        cartIndex,
        newColour,
      },
    });
  };

export const updateSubKitCartColour =
  ({ subKitIndex, cartIndex, newColour }) =>
  (dispatch) => {
    dispatch({
      type: UPDATE_SUB_KIT_CART_COLOUR,
      payload: {
        subKitIndex,
        cartIndex,
        newColour,
      },
    });
  };

export const updateCartPrice =
  ({ cartIndex, newPrice, rowPriceUid }) =>
  (dispatch) => {
    dispatch({
      type: UPDATE_CART_PRICE,
      payload: {
        cartIndex,
        newPrice,
        rowPriceUid,
      },
    });
  };

export const updateSubKitCartPrice =
  ({ subKitIndex, cartIndex, newPrice, rowPriceUid }) =>
  (dispatch) => {
    dispatch({
      type: UPDATE_SUB_KIT_CART_PRICE,
      payload: {
        subKitIndex,
        cartIndex,
        newPrice,
        rowPriceUid,
      },
    });
  };

export const updateCart =
  ({ cartIndex, path, value }) =>
  (dispatch) => {
    dispatch({
      type: UPDATE_CART,
      payload: {
        cartIndex,
        path,
        value,
      },
    });
  };

export const updateSubKitCart =
  ({ subKitIndex, cartIndex, path, value }) =>
  (dispatch) => {
    dispatch({
      type: UPDATE_SUB_KIT_CART,
      payload: {
        subKitIndex,
        cartIndex,
        path,
        value,
      },
    });
  };

export const updateCartAttributeValues =
  ({ cartIndex, newAttribute }) =>
  (dispatch) => {
    dispatch({
      type: UPDATE_CART_ATTRIBUTES,
      payload: {
        cartIndex,
        newAttribute,
      },
    });
  };

export const updateSubKitCartAttributeValues =
  ({ subKitIndex, cartIndex, newAttribute }) =>
  (dispatch) => {
    dispatch({
      type: UPDATE_SUB_KIT_CART_ATTRIBUTES,
      payload: {
        subKitIndex,
        cartIndex,
        newAttribute,
      },
    });
  };

export const deleteCartAttributeValues =
  ({ cartIndex, currentAttributeName }) =>
  (dispatch) => {
    dispatch({
      type: DELETE_CART_ATTRIBUTES,
      payload: {
        cartIndex,
        currentAttributeName,
      },
    });
  };

export const deleteSubKitCartAttributeValues =
  ({ subKitIndex, cartIndex, currentAttributeName }) =>
  (dispatch) => {
    dispatch({
      type: DELETE_SUB_KIT_CART_ATTRIBUTES,
      payload: {
        subKitIndex,
        cartIndex,
        currentAttributeName,
      },
    });
  };
export const deleteCartItem = (cartIndex) => (dispatch) => {
  dispatch({
    type: DELETE_CART_ITEM,
    payload: cartIndex,
  });
};

export const deleteSubKitCartItem =
  ({ subKitIndex, cartIndex }) =>
  (dispatch) => {
    dispatch({
      type: DELETE_SUB_KIT_CART_ITEM,
      payload: { subKitIndex, cartIndex },
    });
  };

export const addKitLength =
  ({ cartIndex, options, minLength, sortLengths }) =>
  (dispatch) => {
    dispatch({
      type: ADD_KIT_LENGTH,
      payload: { cartIndex, options, minLength, sortLengths },
    });
  };

export const deleteKitLength =
  ({ cartIndex, subitemId, minLength }) =>
  (dispatch) => {
    dispatch({
      type: DELETE_KIT_LENGTH,
      payload: { cartIndex, subitemId, minLength },
    });
  };

export const addKitDimension =
  ({ cartIndex, options }) =>
  (dispatch) => {
    dispatch({
      type: ADD_KIT_DIMENSION,
      payload: { cartIndex, options },
    });
  };

export const deleteKitDimension =
  ({ cartIndex, subitemId }) =>
  (dispatch) => {
    dispatch({
      type: DELETE_KIT_DIMENSION,
      payload: { cartIndex, subitemId },
    });
  };

export const addSubKitCartLength =
  ({ subKitIndex, cartIndex, options, minLength, sortLengths }) =>
  (dispatch) => {
    dispatch({
      type: ADD_SUB_KIT_LENGTH,
      payload: { subKitIndex, cartIndex, options, minLength, sortLengths },
    });
  };

export const deleteSubKitCartLength =
  ({ subKitIndex, cartIndex, subitemId, minLength }) =>
  (dispatch) => {
    dispatch({
      type: DELETE_SUB_KIT_LENGTH,
      payload: { subKitIndex, cartIndex, subitemId, minLength },
    });
  };

export const addSubKitCartDimension =
  ({ subKitIndex, cartIndex, options }) =>
  (dispatch) => {
    dispatch({
      type: ADD_SUB_KIT_DIMENSION,
      payload: { subKitIndex, cartIndex, options },
    });
  };

export const deleteSubKitCartDimension =
  ({ subKitIndex, cartIndex, subitemId }) =>
  (dispatch) => {
    dispatch({
      type: DELETE_SUB_KIT_DIMENSION,
      payload: { subKitIndex, cartIndex, subitemId },
    });
  };

export const deleteSubKit =
  ({ subKitIndex }) =>
  (dispatch) => {
    dispatch({
      type: DELETE_SUB_KIT,
      payload: { subKitIndex },
    });
  };

export const createProductKitFromCart = () => (dispatch) => {
  dispatch({
    type: CREATE_PRODUCT_KIT_FROM_CART,
    payload: {},
  });
};

export const createProductKitFromLabourLineItemsCart = () => (dispatch) => {
  dispatch({
    type: CREATE_PRODUCT_KIT_FROM_LABOUR_LINE_ITEMS_CART,
    payload: {},
  });
};

export const createSubKitFromLabourLineItemsCart = () => (dispatch) => {
  dispatch({
    type: CREATE_SUB_KIT_FROM_LABOUR_LINE_ITEMS_CART,
    payload: {},
  });
};

export const updateCurrentProductKit = (path, value) => (dispatch) =>
  dispatch({
    type: UPDATE_CURRENT_PRODUCT_KIT,
    payload: { path, value },
  });

export const updateSubKitTitle =
  ({ subKitIndex, title }) =>
  (dispatch) =>
    dispatch({
      type: UPDATE_SUB_KIT_TITLE,
      payload: { subKitIndex, title },
    });

export const updateProductKitPricingTable = (path, value) => (dispatch) =>
  dispatch({
    type: UPDATE_PRODUCT_KIT_PRICING_TABLE,
    payload: { path, value },
  });

export const toggleProductKitModal = () => (dispatch) => {
  dispatch({
    type: TOGGLE_PRODUCT_KIT_MODAL,
  });
};

export const setTargetSubKitIndex = (data) => (dispatch) => {
  dispatch({
    type: SET_TARGET_SUB_KIT_INDEX,
    payload: data,
  });
};

export const setIsSubKitModalOpen =
  ({ isSubKitModalOpen, subKitIndex }) =>
  (dispatch) => {
    dispatch({
      type: SET_IS_SUB_KIT_MODAL_OPEN,
      payload: {
        isSubKitModalOpen,
        subKitIndex,
      },
    });
  };

export const setProductKit = (data) => (dispatch) => {
  dispatch({
    type: SET_PRODUCT_KIT,
    payload: {
      data: data,
    },
  });
};

export const setKitLabourLineItems = (data) => (dispatch) => {
  dispatch({
    type: SET_KIT_LABOUR_LINE_ITEMS,
    payload: data,
  });
};

export const fetchProductKit =
  ({ productUid, customerPriceLevel }) =>
  async (dispatch) => {
    try {
      dispatch(resetProductKit());
      dispatch(setIsFetchingProductKit(true));
      const { data } = await api.getProductKit(productUid)();
      const payload = {
        ...data,
        productKitItem: {
          ...data.productKitItem,
          kitProducts: data.productKitItem.kitProducts.map((k) => {
            if (k.productType === PRODUCT_TYPE_LABOUR_PRODUCT_LABEL) {
              return {
                ...k,
                ...k.labour,
              };
            }

            return k;
          }),
          subKits: data.productKitItem.subKits.map((s) => ({
            ...s,
            kitProducts: s.kitProducts.map((k) => {
              if (k.productType === PRODUCT_TYPE_LABOUR_PRODUCT_LABEL) {
                return {
                  ...k,
                  ...k.labour,
                };
              }

              return k;
            }),
          })),
        },
        customerPriceLevel,
      };

      dispatch(setProductKit(payload));
      dispatch(createProductKitFromCart());

      return data;
    } catch (error) {
      console.log(error);
      handleError(error, dispatch);
    } finally {
      dispatch(setIsFetchingProductKit(false));
    }
  };

export const postProductKit =
  ({ data, kitProducts, subKits }) =>
  async (dispatch) => {
    try {
      dispatch(setIsSaving(true));
      const response = await api.postProductKit(data)();

      try {
        kitProducts.forEach((kitProduct, kitProductIndex) => {
          if (kitProduct?.product?.pricingStrategy === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL) {
            const newKitProduct = response.data.productKitItem.kitProducts[kitProductIndex];
            const patchPayloadCustomFormula = {
              elements: kitProduct.customFormula.elements,
            };
            const postPayloadCustomFormula = {
              kitProduct: newKitProduct.uid || kitProduct.uid,
              isRoundedToNearestWholeNumber: kitProduct.customFormula.isRoundedToNearestWholeNumber,
              displayFormula: kitProduct.customFormula.displayFormula,
              ...patchPayloadCustomFormula,
            };
            dispatch(
              saveCustomFormula({
                data: kitProduct.uid ? patchPayloadCustomFormula : postPayloadCustomFormula,
                customFormulaUid: kitProduct.uid ? kitProduct.customFormula.uid : null,
              })
            );
          }
        });
      } catch (error) {
        handleError(error, dispatch);
      }

      try {
        subKits.forEach((subKit, subKitIndex) => {
          const newSubKit = response.data.productKitItem.subKits[subKitIndex];
          [...orderBy(subKit.kitProducts, ["pricingStrategy"], ["asc"])].forEach(
            (kitProduct, kitProductIndex) => {
              if (kitProduct?.product?.pricingStrategy === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL) {
                const newKitProduct = orderBy(newSubKit.kitProducts, ["pricingStrategy"], ["asc"])[
                  kitProductIndex
                ];
                const patchPayloadCustomFormula = {
                  elements: kitProduct.customFormula.elements,
                };
                const postPayloadCustomFormula = {
                  kitProduct: newKitProduct.uid || kitProduct.uid,
                  isRoundedToNearestWholeNumber:
                    kitProduct.customFormula.isRoundedToNearestWholeNumber,
                  displayFormula: kitProduct.customFormula.displayFormula,
                  ...patchPayloadCustomFormula,
                };
                dispatch(
                  saveCustomFormula({
                    data: kitProduct.uid ? patchPayloadCustomFormula : postPayloadCustomFormula,
                    customFormulaUid: kitProduct.uid ? kitProduct.customFormula.uid : null,
                  })
                );
              }
            }
          );
        });
      } catch (error) {
        handleError(error, dispatch);
      }
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(setIsSaving(false));
    }
  };

export const saveCustomFormula =
  ({ data, customFormulaUid }) =>
  async (dispatch) => {
    try {
      const payload = {
        ...data,
        elements: data.elements.map((e, index) => {
          if (e.type === "variable" && e.name === "") {
            return { ...e, name: `Label${index + 1}` };
          }

          return { ...e };
        }),
      };

      if (customFormulaUid) {
        await api.patchSaveCustomFormula(customFormulaUid, payload)();
      } else {
        await api.postSaveCustomFormula(payload)();
      }
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const toggleCartDropdown = (showFlag) => (dispatch) => {
  dispatch({
    type: TOGGLE_CART,
    payload: showFlag,
  });
};

export const resetProductKit = () => (dispatch) => {
  dispatch({
    type: RESET_PRODUCT_KIT,
  });
};

export const resetExpandedProducts = () => (dispatch) => {
  dispatch({
    type: RESET_EXPANDED_PRODUCTS,
  });
};

export const resetExpandedSubKitProducts = () => (dispatch) => {
  dispatch({
    type: RESET_EXPANDED_SUB_KIT_PRODUCTS,
  });
};
