import { SET_SELECTED_FILTER, RESET } from "./showHideOrderFilter.actionTypes";

const initialState = {
  selectedFilter: ["true", "false"],
  options: [
    {
      label: "Scheduled",
      value: "true",
    },
    {
      label: "Unscheduled",
      value: "false",
    },
  ],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_FILTER: {
      return { ...state, selectedFilter: action.payload };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
