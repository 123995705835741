import camelcaseKeys from "camelcase-keys";
import moment from "moment-timezone";
import { nanoid } from "nanoid";

import * as date from "@/utils/date";
import { SOCKET_URL } from "@/env";
import { handleError } from "@/redux/utils/error";
import WebSocketManager from "@/utils/websocket";
import * as collaborationApi from "@/api/collaboration";
import orders from "@/redux/modules/orders";
import alerts from "@/redux/modules/alerts";
import { createAlertAction } from "@/redux/alerts/alerts.actions";
import * as notificationsActions from "@/redux/notifications/notifications.actions";

import {
  SET_SOCKET_INSTANCE,
  SET_REFERENCE,
  SET_USER_ID,
  SET_ALL_MESSAGES,
  SET_ATTACHED_FILES_EDITOR,
  SET_ALL_NOTIFICATIONS,
  SET_ALL_COMPANY_USERS,
  SET_TO_EDIT_MESSAGE,
  SET_IS_UPLOAD_FILE,
  SET_IS_PENDING,
  SET_ATTACHMENT_FILES,
  RESET,
} from "./collaboration.actionTypes";
import { ACTIONS, NAMESPACES, REFERENCES } from "./constants";
import * as selectors from "./collaboration.selectors";

const webSocketManager = new WebSocketManager();

export const setSocketInstance = (namespace, payload) => (dispatch) => {
  dispatch({
    type: `${namespace}${SET_SOCKET_INSTANCE}`,
    payload,
  });
};

export const setReference = (namespace, payload) => (dispatch) => {
  dispatch({
    type: `${namespace}${SET_REFERENCE}`,
    payload,
  });
};

export const setUserId = (namespace, payload) => (dispatch) => {
  dispatch({
    type: `${namespace}${SET_USER_ID}`,
    payload,
  });
};

export const setAllMessages =
  (namespace, payload = []) =>
  (dispatch) => {
    dispatch({
      type: `${namespace}${SET_ALL_MESSAGES}`,
      payload: payload.map((message) => ({
        ...message,
        createdTimestampUnix: message.createdTimestamp
          ? moment(message.createdTimestamp).tz(date.getCurrentUserTimezone()).unix()
          : null,
      })),
    });
  };

export const setAllNotifications = (namespace, payload) => (dispatch) => {
  dispatch({
    type: `${namespace}${SET_ALL_NOTIFICATIONS}`,
    payload,
  });
};

export const setAttachedFilesEditor = (namespace, payload) => (dispatch) => {
  dispatch({
    type: `${namespace}${SET_ATTACHED_FILES_EDITOR}`,
    payload,
  });
};

export const setAllCompanyUsers = (namespace, payload) => (dispatch) => {
  dispatch({
    type: `${namespace}${SET_ALL_COMPANY_USERS}`,
    payload: payload.map((user) => ({ ...user, name: user.firstName })),
  });
};

export const setToEditMessage = (namespace, payload) => (dispatch) => {
  dispatch({
    type: `${namespace}${SET_TO_EDIT_MESSAGE}`,
    payload,
  });
};

export const setIsUploadFile = (namespace, payload) => (dispatch) => {
  dispatch({
    type: `${namespace}${SET_IS_UPLOAD_FILE}`,
    payload,
  });
};

export const setIsPending = (namespace, payload) => (dispatch) => {
  dispatch({
    type: `${namespace}${SET_IS_PENDING}`,
    payload,
  });
};

export const listAllNotifications = (namespace, payload) => (dispatch) => {
  const message = camelcaseKeys(payload, { deep: true });
  const allChatNotifications = message
    .map((item) => ({
      ...item,
      createdTimestampUnix: item.createdTimestamp
        ? moment(message.createdTimestamp).tz(date.getCurrentUserTimezone()).unix()
        : null,
    }))
    .sort((a, b) => a.createdTimestampUnix - b.createdTimestampUnix);

  dispatch(setAllNotifications(namespace, allChatNotifications));
};

export const receivingNotification = (namespace, payload) => (dispatch, getState) => {
  const state = getState();
  const allChatsNotifications = selectors.allChatsNotifications(namespace, state);

  const message = camelcaseKeys(payload, { deep: true });
  const index = allChatsNotifications.findIndex((item) => item.id === message.id);

  if (index >= 0) {
    dispatch(
      setAllNotifications(
        namespace,
        allChatsNotifications
          .map((item) => {
            if (item.id === message.id) {
              return {
                ...item,
                createdTimestampUnix: message.createdTimestamp
                  ? moment(message.createdTimestamp).tz(date.getCurrentUserTimezone()).unix()
                  : null,
              };
            }

            return item;
          })
          .sort((a, b) => a.createdTimestampUnix - b.createdTimestampUnix)
      )
    );
  } else {
    dispatch(
      setAllNotifications(
        namespace,
        [
          ...allChatsNotifications,
          {
            ...message,
            createdTimestampUnix: message.createdTimestamp
              ? moment(message.createdTimestamp).tz(date.getCurrentUserTimezone()).unix()
              : null,
          },
        ].sort((a, b) => a.createdTimestampUnix - b.createdTimestampUnix)
      )
    );
  }
};

export const readingNotifications = (namespace, payload) => (dispatch, getState) => {
  const state = getState();
  const notification = camelcaseKeys(payload, { deep: true });
  const notifications = selectors.allChatsNotifications(namespace, state);
  const index = notifications.findIndex(
    (message) => message.id === notification.notificationId?.[0]
  );

  if (index >= 0) {
    dispatch(
      setAllNotifications(
        namespace,
        notifications.map((item, key) => {
          if (key === index) {
            return {
              ...item,
              isRead: true,
            };
          }

          return item;
        })
      )
    );
  }
};

export const markAllNotificationsAsRead = (namespace) => (dispatch, getState) => {
  const state = getState();
  const allChatsNotifications = selectors.allChatsNotifications(namespace, state);

  dispatch(
    setAllNotifications(
      namespace,
      allChatsNotifications.map((item) => ({
        ...item,
        isRead: true,
      }))
    )
  );
};

export const deleteNotification = (namespace, payload) => (dispatch, getState) => {
  const state = getState();
  const notification = camelcaseKeys(payload, { deep: true });
  const allChatsNotifications = selectors.allChatsNotifications(namespace, state);

  dispatch(
    setAllNotifications(
      namespace,
      allChatsNotifications
        .filter((item) => item.id !== notification.notificationId)
        .sort((a, b) => a.createdTimestampUnix - b.createdTimestampUnix)
    )
  );
};

const validateNameSpace = (namespace, dispatch) => {
  if (!Object.values(NAMESPACES).includes(namespace)) {
    handleError({ response: { data: `Invalid namespace ${namespace}` } }, dispatch);
    return false;
  }

  return true;
};

export function addAlert(namespace, { type, message, actionFn, actionLabel = "", ...rest }) {
  return async (dispatch) => {
    if ([NAMESPACES.SUPPLIER, NAMESPACES.PRODUCT, NAMESPACES.PURCHASE_ORDER].includes(namespace)) {
      dispatch(createAlertAction(nanoid(), message, false, type, actionLabel, actionFn));
    } else {
      const alertOptions = {
        type,
        message,
        ...rest,
      };

      if (actionFn) {
        alertOptions.action = { label: actionLabel, callback: actionFn };
      }

      dispatch(alerts.actions.addAlert(alertOptions));
    }
  };
}

export function fetchAllMessages(namespace, reference) {
  return async (dispatch, getState) => {
    if (!validateNameSpace(namespace, dispatch)) {
      return;
    }

    const state = getState();
    const socket = selectors.socketInstance(namespace, state);

    try {
      socket.send(
        JSON.stringify({
          type: ACTIONS.GET_ALL_MESSAGES,
          [REFERENCES[namespace]]: reference,
        })
      );
    } catch (error) {
      handleError(error, dispatch);
    }
  };
}

export const appendNewMessage = (namespace, message) => (dispatch, getState) => {
  if (!validateNameSpace(namespace, dispatch)) {
    return;
  }

  const state = getState();
  const allMessages = selectors.allMessages(namespace, state);

  const newMessageList = [
    ...(allMessages.length > 0 ? allMessages : []),
    {
      ...message,
      createdTimestampUnix: message.createdTimestamp
        ? moment(message.createdTimestamp).tz(date.getCurrentUserTimezone()).unix()
        : null,
    },
  ];

  newMessageList.sort((a, b) => a.createdTimestampUnix - b.createdTimestampUnix);

  dispatch(setAllMessages(namespace, newMessageList));
};

export function sendNewMessage(namespace, reference, { message, mentionUserId = null }) {
  return async (dispatch, getState) => {
    const state = getState();

    if (!validateNameSpace(namespace, dispatch)) {
      return;
    }

    const userId = selectors.userId(namespace, state);

    let referenceId = reference;

    if (referenceId === "new" && namespace === NAMESPACES.ORDER) {
      await dispatch(orders.actions.submitOrder()).then((data) => {
        referenceId = data.order;
        dispatch(setReference(namespace, referenceId));

        // eslint-disable-next-line no-use-before-define
        dispatch(createCollaborateInstance(namespace, referenceId, { userId }));

        setTimeout(() => {
          dispatch(fetchAllMessages(namespace, referenceId));
        }, 100);
      });
    }

    const socket = selectors.socketInstance(namespace, state);

    const data = {
      type: ACTIONS.CREATE_MESSAGE,
      message,
      [REFERENCES[namespace]]: referenceId,
    };

    if (mentionUserId) {
      data.user_id = mentionUserId;
    }

    dispatch(setIsPending(namespace, true));

    socket.send(JSON.stringify(data));
  };
}

export function sendNewMessageWithAttachedFiles(
  namespace,
  reference,
  {
    messageFiles,
    message,
    mentionUserId = null,
    editId = null,
    customerName = "",
    isSignature = false,
  }
) {
  return async (dispatch, getState) => {
    const state = getState();

    if (!validateNameSpace(namespace, dispatch)) {
      return;
    }

    const userId = selectors.userId(namespace, state);

    let referenceId = reference;

    if (referenceId === "new" && namespace === NAMESPACES.ORDER) {
      await dispatch(orders.actions.submitOrder()).then((data) => {
        referenceId = data.order;
        dispatch(setReference(namespace, referenceId));

        // eslint-disable-next-line no-use-before-define
        dispatch(createCollaborateInstance(namespace, referenceId, { userId }));

        setTimeout(() => {
          dispatch(fetchAllMessages(namespace, referenceId));
        }, 100);
      });
    }

    const formData = new FormData();
    const referenceKey = REFERENCES[namespace];
    const data = {
      [referenceKey]: referenceId,
      message,
    };

    if (mentionUserId) {
      data.user_id = mentionUserId;
    }

    if (editId) {
      data.message_id = editId;
    } else {
      data.customer_name = customerName;
      data.is_signature = `${isSignature}`;
    }

    formData.set("json", JSON.stringify(data));

    Object.keys(messageFiles).forEach((key) => {
      const file = messageFiles[key];
      formData.append("files", file);
    });

    dispatch(setIsPending(namespace, true));

    collaborationApi
      .saveMessageWithFiles(namespace, formData)
      .then(() => {
        dispatch(fetchAllMessages(namespace, reference));
        dispatch(setAttachedFilesEditor(namespace, []));
      })
      .catch((error) => {
        dispatch(
          addAlert(namespace, {
            type: "danger",
            message:
              error.response.status === 413
                ? "Saving: Large size of the attached file - Server Error"
                : "Saving: Files is not saved - Server Error",
          })
        );

        return new Promise((resolve, reject) => {
          reject(new Error(error));
        });
      })
      .finally(() => {
        dispatch(setIsPending(namespace, false));
      });
  };
}

export function setToEditMessageData(namespace, id) {
  return async (dispatch, getState) => {
    if (!validateNameSpace(namespace, dispatch)) {
      return;
    }

    const state = getState();
    const allMessages = selectors.allMessages(namespace, state);
    const allCompanyUsers = selectors.allCompanyUsers(namespace, state);
    const usersList = new Map();
    allCompanyUsers.map((item) => usersList.set(item.id, item));

    const toEditMessage = allMessages.filter((item) => item.id === id) || [];

    if (toEditMessage.length) {
      const [editMessageRow] = toEditMessage;
      const { userId: mentionedUsersId } = editMessageRow;

      if (mentionedUsersId.length) {
        const mentionedUsersFullData = mentionedUsersId.map((item) =>
          usersList.get(item.toString())
        );
        toEditMessage[0].users = mentionedUsersFullData;
      } else {
        toEditMessage[0].users = [];
      }
    }

    dispatch(setToEditMessage(namespace, toEditMessage?.[0]));
  };
}

export function setToEditMessages(namespace, updatedMessage) {
  return async (dispatch, getState) => {
    const state = getState();
    const allMessages = selectors.allMessages(namespace, state);

    const updatedMessages = allMessages
      .map((message) => {
        if (message.id === updatedMessage.id) {
          return { ...message, ...updatedMessage };
        }

        return message;
      })
      .map((message) => ({
        ...message,
        createdTimestampUnix: message.createdTimestamp
          ? moment(message.createdTimestamp).tz(date.getCurrentUserTimezone()).unix()
          : null,
      }))
      .sort((a, b) => a.createdTimestampUnix - b.createdTimestampUnix);

    dispatch(setAllMessages(namespace, updatedMessages));
  };
}

export function setAttchmentFiles(namespace, payload) {
  return (dispatch, getState) => {
    const state = getState();
    const attachmentFiles = selectors.attachmentFiles(namespace, state);

    dispatch({
      type: `${namespace}${SET_ATTACHMENT_FILES}`,
      payload: { ...attachmentFiles, ...payload },
    });
  };
}

export function getAttachmentFiles(namespace, fileId, fileName) {
  return async (dispatch, getState) => {
    const state = getState();
    const attachmentFiles = selectors.attachmentFiles(namespace, state);

    collaborationApi.getAttachmentFiles(fileId).then((response) => {
      dispatch(
        setAttchmentFiles(namespace, {
          ...attachmentFiles,
          [fileId]: {
            id: fileId,
            url: window.URL.createObjectURL(
              new File([response.data], fileName, { type: response.data.type })
            ),
            type: response.data.type,
            name: fileName,
          },
        })
      );

      return new Blob([response.data], { type: response.data.type });
    });
  };
}

export function afterDeleteMessage(namespace, id) {
  return async (dispatch, getState) => {
    if (!validateNameSpace(namespace, dispatch)) {
      return;
    }

    const state = getState();
    const allMessages = selectors.allMessages(namespace, state);

    const deleteId = parseFloat(id);

    const newList = allMessages
      .filter((item) => item.id !== deleteId)
      .sort((a, b) => a.createdTimestampUnix - b.createdTimestampUnix);

    dispatch(setAllMessages(namespace, newList));
    dispatch(setIsPending(namespace, false));
  };
}

export function editMessage(namespace, reference, message, selectedMentionUser) {
  return async (dispatch, getState) => {
    if (!validateNameSpace(namespace, dispatch)) {
      return;
    }

    const referenceKey = REFERENCES[namespace];

    const state = getState();
    const socket = selectors.socketInstance(namespace, state);
    const { id: editId } = selectors.toEditMessage(namespace, state);
    const messageFiles = selectors.attachedFilesEditor(namespace, state);
    const allCompanyUsers = selectors.allCompanyUsers(namespace, state);
    const mentionUserId = [];

    Object.keys(selectedMentionUser).forEach((key) => {
      const index = allCompanyUsers.findIndex(
        (item) => item.firstName === selectedMentionUser[key]
      );

      if (index >= 0) {
        mentionUserId.push(parseFloat(allCompanyUsers[index].id));
      }
    });

    if (messageFiles && messageFiles.length > 0) {
      dispatch(
        sendNewMessageWithAttachedFiles(namespace, reference, {
          messageFiles,
          message,
          mentionUserId,
          editId,
        })
      );
    }

    const socketData = {
      type: ACTIONS.EDIT_MESSAGE,
      message,
      message_id: editId,
      [referenceKey]: reference,
    };

    if (mentionUserId) {
      socketData.user_id = mentionUserId;
    }

    dispatch(setIsPending(namespace, true));

    socket.send(JSON.stringify(socketData));

    dispatch(setToEditMessage(namespace, null));
  };
}

export function deleteMessageFile(namespace, reference, { fileId, messageId }) {
  return async (dispatch, getState) => {
    if (!validateNameSpace(namespace, dispatch)) {
      return;
    }

    const state = getState();
    const socket = selectors.socketInstance(namespace, state);
    const referenceKey = REFERENCES[namespace];
    const allMessages = selectors.allMessages(namespace, state);

    const messageIndex = allMessages.findIndex((item) => item.id === messageId);
    const newFileList = allMessages.map((message, index) => ({
      ...message,
      orderChatMessageFile:
        messageIndex === index
          ? message.orderChatMessageFile.filter((file) => file.id !== fileId)
          : message.orderChatMessageFile,
    }));

    dispatch(setAllMessages(namespace, newFileList));

    const deleteFile = setTimeout(() => {
      socket.send(
        JSON.stringify({
          type: ACTIONS.DELETE_FILE_MESSAGE,
          file_id: fileId.toString(),
          [referenceKey]: reference,
        })
      );
    }, 5000);

    dispatch(
      addAlert(namespace, {
        type: "danger",
        message: "Attachment deleted",
        actionFn: () => {
          dispatch(setAllMessages(namespace, allMessages));
          clearTimeout(deleteFile);
        },
        actionLabel: "Undo",
        closeDelay: 5000,
      })
    );
  };
}

export function getAllCompanyUsers(namespace) {
  return async (dispatch, getState) => {
    const state = getState();
    const socket = selectors.socketInstance(namespace, state);

    const allCompanyUsers = selectors.allCompanyUsers(namespace, state);

    if (!allCompanyUsers?.length) {
      socket.send(
        JSON.stringify({
          type: ACTIONS.GET_ALL_COMPANY_USER,
        })
      );
    }
  };
}

export function deleteMessage(namespace, reference, messageId) {
  return async (dispatch, getState) => {
    const state = getState();
    const socket = selectors.socketInstance(namespace, state);
    const referenceKey = REFERENCES[namespace];
    const allMessages = selectors.allMessages(namespace, state);

    const newMessageList = allMessages.filter((item) => item.id !== messageId);

    dispatch(setAllMessages(namespace, newMessageList));

    const deleteMessageAction = setTimeout(() => {
      socket.send(
        JSON.stringify({
          type: ACTIONS.DELETE_MESSAGE,
          message_id: messageId.toString(),
          [referenceKey]: reference,
        })
      );
    }, 5000);

    dispatch(
      addAlert(namespace, {
        type: "danger",
        message: "Message deleted",
        actionFn: () => {
          dispatch(setAllMessages(namespace, allMessages));
          clearTimeout(deleteMessageAction);
        },
        actionLabel: "Undo",
        closeDelay: 5000,
      })
    );
  };
}

export function toggleAttachment({ attachmentType, fileId }) {
  return async (dispatch) => {
    collaborationApi.toggleAttachment({ attachmentType, fileId }).catch((error) => {
      dispatch(
        addAlert(attachmentType, {
          type: "danger",
          message: error?.response?.data?.error || "Oops, something went wrong",
        })
      );
    });
  };
}

export function createCollaborateInstance(namespace, reference, { userId }) {
  return async (dispatch) => {
    if (!validateNameSpace(namespace, dispatch)) {
      return;
    }

    const MAX_RETRIES = 3;
    let retryCount = 0;

    const connect = async () => {
      try {
        const socket = await webSocketManager.createWebSocket(
          namespace,
          `${SOCKET_URL}user_connect/${userId}`
        );

        dispatch(setSocketInstance(namespace, socket));
        retryCount = 0;

        socket.onmessage = (event) => {
          const data = camelcaseKeys(JSON.parse(event.data), { deep: true });

          switch (data.type) {
            case ACTIONS.CREATE_MESSAGE:
              dispatch(setIsPending(namespace, false));
              dispatch(fetchAllMessages(namespace, reference));
              break;
            case ACTIONS.GET_ALL_MESSAGES:
              dispatch(setAllMessages(namespace, data.messages));
              break;
            case ACTIONS.EDIT_MESSAGE:
              dispatch(setToEditMessages(namespace, data.message));
              dispatch(setIsPending(namespace, false));
              break;
            case ACTIONS.DELETE_MESSAGE:
              dispatch(afterDeleteMessage(namespace, data.message));
              break;
            case ACTIONS.GET_ALL_COMPANY_USER:
              dispatch(setAllCompanyUsers(namespace, data.messages));
              break;
            case ACTIONS.GET_ALL_NOTIFICATIONS:
              dispatch(listAllNotifications(namespace, data.messages));
              dispatch(getAllCompanyUsers(namespace));
              break;
            case ACTIONS.RECEIVING_NOTIFICATION:
              dispatch(receivingNotification(namespace, data.message));
              dispatch(notificationsActions.setNotification(namespace, data.message));
              break;
            case ACTIONS.READ_NOTIFICATION_MESSAGE:
              dispatch(readingNotifications(namespace, data.messages));
              break;
            case ACTIONS.MARK_ALL_NOTIFICATION_AS_READ:
              dispatch(markAllNotificationsAsRead(namespace));
              break;
            case ACTIONS.DELETE_NOTIFICATION:
              dispatch(deleteNotification(namespace));
              break;
            default:
              break;
          }
        };

        socket.onerror = () => {
          socket.close();
        };

        socket.onclose = () => {
          if (retryCount < MAX_RETRIES) {
            retryCount += 1;
            setTimeout(() => connect(), 3000);
          } else {
            console.error(`WebSocket: Max retries of ${MAX_RETRIES} reached.`);
          }
        };
      } catch (error) {
        if (retryCount < MAX_RETRIES) {
          retryCount += 1;
          setTimeout(() => connect(), 3000);
        } else {
          console.error(`WebSocket: Max retries of ${MAX_RETRIES} reached.`, error);
        }

        handleError(error, dispatch);
      }
    };

    connect();
  };
}

export function closeCollaborateInstance(namespace) {
  return async (dispatch) => {
    if (!validateNameSpace(namespace, dispatch)) {
      return;
    }

    dispatch(setSocketInstance(namespace, null));
    await webSocketManager.closeWebSocket(namespace);
  };
}

export function reset(namespace) {
  return (dispatch) => {
    dispatch({ type: `${namespace}${RESET}` });
  };
}
