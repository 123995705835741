import createActionType from "../utils/createActionType";

export const MODULE = "labels";

export const SET_IS_LABELS_DROPDOWN_VISIBLE = createActionType(
  MODULE,
  "setIsLabelDropdownVisible"
);

export const SET_IS_SAVING_LABEL = createActionType(MODULE, "setIsSavingLabel");

export const SET_IS_FETCHING_LABELS = createActionType(
  MODULE,
  "setIsFetchingLabels"
);

export const SET_LABELS = createActionType(MODULE, "setLabels");

export const SET_SELECTED_LABELS = createActionType(
  MODULE,
  "setSelectedLabels"
);

export const SET_UNSELECTED_LABELS = createActionType(
  MODULE,
  "setUnSelectedLabels"
);

export const SET_ACTIVE_LABEL = createActionType(MODULE, "setActiveLabel");

export const SET_IS_CREATE_LABEL_FORM_VISIBLE = createActionType(
  MODULE,
  "setIsCreateLabelFormVisible"
);
export const SET_IS_DELETE_LABEL_ALERT_VISIBLE = createActionType(
  MODULE,
  "setIsDeleteLabelAlertVisible"
);

export const RESET = createActionType(MODULE, "reset");
