import { fromJS } from "immutable";
import { calcLine } from "../../../utils/calculations";
import { FINISH_POINTS, SIZES_ELEMENTS_DRAWING } from "../../../utils/constants";
import Vector from "../../../utils/vector";

export const calcTotalGirth = (mainCanvas, sidesCanvases, isFreeDrawing) => {
  let totalGirth = mainCanvas.totalGirthAuto;
  let totalBends = mainCanvas.numberOfBendsAuto;
  if (isFreeDrawing) {
    totalGirth = mainCanvas.totalGirthFree;
    totalBends = mainCanvas.numberOfBendsFree;
  }

  if (sidesCanvases && sidesCanvases.length) {
    sidesCanvases.forEach((side) => {
      if (isFreeDrawing && totalGirth < side.totalGirthFree) {
        totalGirth = side.totalGirthFree;
        totalBends = side.numberOfBendsFree;
      } else if (!isFreeDrawing && totalGirth < side.totalGirthAuto) {
        totalGirth = side.totalGirthAuto;
        totalBends = side.numberOfBendsAuto;
      }
    });
  }

  return {
    totalGirth,
    totalBends,
  };
};

export const updateCanvas = (_state, payload) => {
  const { newCanvasData, isFreeDrawing, type, canvasIndex } = payload;

  if (type === "Far side") {
    _state.mergeIn(["activeDrawing", "canvasState"], newCanvasData);
  } else {
    _state.mergeIn(["activeDrawing", "otherSides", canvasIndex], newCanvasData);
  }

  const mainCanvas = _state.getIn(["activeDrawing", "canvasState"]).toJS();
  let sidesCanvases = _state.getIn(["activeDrawing", "otherSides"]);
  sidesCanvases = sidesCanvases ? sidesCanvases.toJS() : null;
  const { totalGirth, totalBends } = calcTotalGirth(mainCanvas, sidesCanvases, isFreeDrawing);

  _state.setIn(["isChangedDrawing"], true);
  _state.setIn(["activeDrawing", "hasChanges"], true);
  _state.setIn(["activeDrawing", "totalGirth"], totalGirth);
  _state.setIn(["activeDrawing", "totalBends"], totalBends);
};

export const getLinePointKeyByProperty = (arr, property, pointKey) => {
  const reversedKey = pointKey.split("-").reverse().join("-");
  return arr[property][reversedKey] != null ? reversedKey : pointKey;
};

export const updateLinesData = (_state, payload) => {
  const { canvasState, isFreeDrawing, canvasIndex, customPriceBends } = payload;

  const { points, zoom } = canvasState;

  const linesDataAuto = calcLinesDataAuto(points, zoom, customPriceBends);
  const { numberOfBendsAuto, totalGirthAuto, linesValuesAuto, anglesValuesAuto, maxIndex } =
    linesDataAuto;

  let numberOfBendsFree = 0;
  let totalGirthFree = 0;
  const linesDataFree = calcLinesDataFree(
    points,
    canvasState.linesValuesFree,
    canvasState.linesValuesAuto,
    customPriceBends
  );
  numberOfBendsFree = linesDataFree.numberOfBendsFree;
  totalGirthFree = linesDataFree.totalGirthFree;

  const newCanvasData = fromJS({
    ...canvasState,
    maxIndex,

    numberOfBendsFree,
    totalGirthFree,
    linesValuesFree: canvasState.linesValuesFree,
    anglesValuesFree: canvasState.anglesValuesFree,

    numberOfBendsAuto,
    totalGirthAuto,
    linesValuesAuto,
    anglesValuesAuto,
  });

  updateCanvas(_state, {
    type: canvasState.type,
    newCanvasData,
    canvasIndex,
    isFreeDrawing,
  });
};

export const getNeighborsIds = (points, id) => [].concat(points[id].connect, points[id].vectors);

export const getNeighborsPoints = (points, point) => {
  const neighbourPoints = [];
  point.connect.forEach((pointId) => {
    neighbourPoints.push(Vector.create(points[pointId]));
  });
  point.vectors.forEach((pointId) => {
    neighbourPoints.push(Vector.create(points[pointId]));
  });

  return neighbourPoints;
};

export const cleanupPointsWithoutConnections = (points) => {
  Object.keys(points).forEach((pointId) => {
    const maxNeighbors = getNeighborsIds(points, pointId);
    if (maxNeighbors.length === 0) {
      delete points[pointId];
    }
  });
  return points;
};

/**
 * Transform points hash to array
 * @param pointsMap
 * @returns {Array}
 */
export function pointsAsArray(pointsMap) {
  return Object.keys(pointsMap).map((id) => pointsMap[id]);
}

/**
 *
 * @param {Object} points
 * @returns {Array}
 */

export function calcLinesDataAuto(points, zoom, customPriceBends) {
  const linesValuesAuto = {
    values: {},
    positions: {},
    indexes: {},
  };
  const anglesValuesAuto = {
    values: {},
    positions: {},
    indexes: {},
  };
  const tabIndexes = {};

  const marginAnchor = SIZES_ELEMENTS_DRAWING.anchorLabelPadding / zoom;
  const marginLine = SIZES_ELEMENTS_DRAWING.lineLabelPadding / zoom;

  let numberOfBendsAuto = 0;
  let totalGirthAuto = 0;
  let activeTabIndex = 0;

  const pointsKeys = Object.keys(points);
  if (pointsKeys.length) {
    const [firstPointId, secondPointId] = pointsKeys.filter((pointId) => {
      const neighbourPoints = getNeighborsIds(points, pointId);
      return neighbourPoints.length === 1 || !neighbourPoints.length;
    });

    let key = points[firstPointId];
    if (secondPointId) {
      key =
        points[firstPointId].x < points[secondPointId].x
          ? points[firstPointId]
          : points[secondPointId];
    }
    key = key?.id ?? null;
    let i = 0;
    while (i < pointsKeys.length) {
      const point = points[key];
      const neighbourPoints = getNeighborsIds(points, point.id);
      numberOfBendsAuto += neighbourPoints.length > 1 ? 1 : 0;

      const values = getAngle(points, point, neighbourPoints);
      if (values) {
        if (!(values.angle % 45)) {
          values.hidden = true;
        }
        anglesValuesAuto.values[point.id] = values;
        anglesValuesAuto.positions[point.id] = calcAnchorLabel(
          point,
          anglesValuesAuto.values[point.id],
          marginAnchor
        );
      }

      tabIndexes[key] = true;
      if (point.finish) {
        if (point.finish.type === "cf" && customPriceBends) {
          numberOfBendsAuto +=
            customPriceBends && customPriceBends > 0
              ? customPriceBends
              : FINISH_POINTS[point.finish.type].bends;
        } else {
          numberOfBendsAuto += FINISH_POINTS[point.finish.type].bends;
        }
      } else if (values) {
        // point index
        anglesValuesAuto.indexes[point.id] = activeTabIndex;
        activeTabIndex += 1;
      }

      const [first, second] = neighbourPoints;
      key = tabIndexes[first] ? null : first;
      if (!key) {
        key = tabIndexes[second] ? null : second;
      }

      if (key) {
        const nextPoint = points[key];
        const line = calcLineLabel(point, nextPoint, points, marginLine);
        if (line) {
          const lineLength = Math.round(line.lineLength);

          const lineIds = `${point.id}-${key}`;
          linesValuesAuto.values[lineIds] = lineLength;
          linesValuesAuto.positions[lineIds] = line.position;

          if (point.finish) {
            totalGirthAuto += point.finish.size;

            // finish index
            linesValuesAuto.indexes[point.id] = activeTabIndex;
            activeTabIndex += 1;
          }

          totalGirthAuto += lineLength;
          linesValuesAuto.indexes[lineIds] = activeTabIndex;
          activeTabIndex += 1;

          if (nextPoint.finish) {
            totalGirthAuto += nextPoint.finish.size;

            // finish index
            linesValuesAuto.indexes[nextPoint.id] = activeTabIndex;
            activeTabIndex += 1;
          }
        }
      }
      i += 1;
    }
  }

  return {
    linesValuesAuto,
    anglesValuesAuto,
    numberOfBendsAuto,
    totalGirthAuto,
    maxIndex: activeTabIndex - 1,
  };
}

export function calcLinesDataFree(points, linesValuesFree, linesValuesAuto, customPriceBends) {
  let totalGirthFree = 0;
  let numberOfBendsFree = 0;

  const pointsArray = pointsAsArray(points);

  // calc totalGirthFree from linesValuesFree.values
  const isLinesNotDefined = Object.keys(linesValuesAuto.positions).filter((lineIds) => {
    const lineIdsFree = getLinePointKeyByProperty(linesValuesFree, "values", lineIds);
    const lineLength = linesValuesFree.values[lineIdsFree];

    if (!lineLength) return true;

    if (!lineLength.hidden && !lineLength.size) return true;

    const line = lineIdsFree.split("-");
    const point = points[line[0]];
    const nextPoint = points[line[1]];

    if (point && point.finish) {
      totalGirthFree += point.finish.size;
    }

    if (nextPoint && nextPoint.finish) {
      totalGirthFree += nextPoint.finish.size;
    }

    if (lineLength && !lineLength.hidden) {
      totalGirthFree += lineLength.size;
    }

    return false;
  });

  // clear totalGirthFree if have line without value
  if (isLinesNotDefined.length > 0) {
    totalGirthFree = 0;
  } else {
    // calc numberOfBendsFree from anglesValuesFree.values
    pointsArray.forEach(({ id }) => {
      const neighbourPoints = getNeighborsIds(points, id);
      numberOfBendsFree += neighbourPoints.length > 1 ? 1 : 0;

      const point = points[id];
      if (point.finish) {
        if (point.finish.type === "cf" && customPriceBends) {
          numberOfBendsFree +=
            customPriceBends && customPriceBends > 0
              ? customPriceBends
              : FINISH_POINTS[point.finish.type].bends;
        } else {
          numberOfBendsFree += FINISH_POINTS[point.finish.type].bends;
        }
      }
    });
  }
  return {
    linesValuesFree,
    numberOfBendsFree,
    totalGirthFree,
  };
}

/**
 *
 * @param {Object} points - drawing.canvasState.points
 * @param {Object} startPoint - anchor
 * @param {Array} neighborsIds - ids points between which calculate angle
 */
export function getAngle(points, startPoint, neighborsIds) {
  if (!neighborsIds[0] || !neighborsIds[1]) return null;

  const p1 = points[neighborsIds[0]];
  const p2 = points[neighborsIds[1]];
  const v1 = Vector.create(p1).sub(startPoint);
  const v2 = Vector.create(p2).sub(startPoint);
  if (!v1.length() || !v2.length()) return null;

  let angle = Vector.toDeg(v1.angle() - v2.angle());
  if (angle < 0) angle += 360;

  return { angle, v1, v2 };
}

export function calcAnchorLabel(point, angleValues, margin) {
  if (!angleValues) return null;

  let position;
  if (angleValues.angle === 180) {
    position = angleValues.v1.rotate(90).normalize(margin);
  } else {
    position = angleValues.v1.normalize(1).add(angleValues.v2.normalize(1)).normalize(margin);
  }

  position = Vector.create(point).add(position);
  if (angleValues.angle >= 40) {
    position = position.sub(Vector.create({ x: 0, y: -4 }));
  } else if (angleValues.angle > 25) {
    position = position.sub(Vector.create({ x: -4, y: -4 }));
  } else {
    position = position.sub(Vector.create({ x: -8, y: -4 }));
  }
  return position;
}

/**
 *
 * @param {Object} p1 - point on the start line
 * @param {Object} p2 - point on the start line
 * @param {Object} points - drawing.canvasState.points
 * @param {Object} shift label from line
 */
export function calcLineLabel(p1, p2, points, shift) {
  const lineLabel = calcLine(p1, p2);
  if (!lineLabel) return null;

  const neighborsPoints1 = getNeighborsIds(points, p1.id);
  const values1 = getAngle(points, p1, neighborsPoints1);

  const neighborsPoints2 = getNeighborsIds(points, p2.id);
  const values2 = getAngle(points, p2, neighborsPoints2);

  const v1 = Vector.create(p1);
  const v2 = Vector.create(p2);
  let position;

  if (values1 || values2) {
    const anchor1 = values1 && calcAnchorLabel(p1, values1, shift);
    const anchor2 = values2 && calcAnchorLabel(p2, values2, shift);

    position = anchor1 != null ? v1.sub(anchor1) : v2.sub(anchor2);
    position = calcLabelPosition(lineLabel.line, lineLabel.centerLine, position);
    position = position.normalize(shift);
  }

  if ((!values1 && !values2) || !position.length()) {
    position = lineLabel.line.rotate(Math.PI / 2).mul(shift / lineLabel.lineLength);
  }

  return {
    line: lineLabel.line,
    lineLength: Math.round(lineLabel.lineLength),
    position: lineLabel.centerLine.add(position),
  };
}

function calcLabelPosition(line, centerLine, anchorPosition) {
  const lineN = line.normalize(1);
  let position = lineN.mul(lineN.dot(anchorPosition));
  position = centerLine.sub(position).add(anchorPosition);
  return position.sub(centerLine);
}

/**
 *
 * @param {Object} points - drawing.canvasState.points
 * @param {Object} p1 - point on the start line
 * @param {Object} p2 - point on the start line
 * @param {number} newLength
 */
export const setLineLength = (points, p1, p2, newLength) => {
  const hashVisited = {};

  shiftPointLine(points, p1, p2, newLength, hashVisited);
  shiftPointLine(points, p2, p1, newLength, hashVisited);

  return points;
};

/**
 *
 * @param {Object} points - drawing.canvasState.points
 * @param {Object} p1 - point on the start line
 * @param {Object} p2 - point on the start line
 * @param {number} newLength
 * @param {Object} hashVisited - the points that we visited
 */
function shiftPointLine(points, p1, p2, newLength, hashVisited) {
  const v1 = Vector.create(p1);
  const v2 = Vector.create(p2);

  const line = v1.sub(v2);
  const shift = line.mul(newLength / line.length() - 1).div(2);

  shiftNeighborsPoints(points, p1.id, p2.id, hashVisited, (currentPoint) => {
    currentPoint.x += shift.x;
    currentPoint.y += shift.y;
  });
}

/**
 *
 * @param {Object} points - drawing.canvasState.points
 * @param {Object} startPoint - point whose angle is changed
 * @param {number} newDegrees
 */
export const rotatePoints = (points, startPoint, newDegrees) => {
  const neighbourPoints = getNeighborsIds(points, startPoint.id);
  const oldDegrees = getAngle(points, startPoint, neighbourPoints).angle;

  const isInverted = oldDegrees > 180;
  const realNewDegrees = isInverted ? 360 - newDegrees : newDegrees;

  const center = Vector.create(startPoint);
  const angle = Vector.toRad(oldDegrees - realNewDegrees);

  if (angle) {
    const hashVisited = {};

    shiftNeighborsPoints(
      points,
      startPoint.id,
      points[neighbourPoints[0]].id,
      hashVisited,
      (currentPoint) => {
        const shift = Vector.create(currentPoint).sub(center).rotate(angle).add(center);
        currentPoint.x = shift.x;
        currentPoint.y = shift.y;
      }
    );
  }

  return points;
};

/**
 *
 * @param {Object} points -  drawing.canvasState.points
 * @param {string} currentPointId
 * @param {string} forbiddenPointId - point identifier on the start lines
 * @param {Object} hashVisited - the points that we visited
 * @param {Function} callback
 */
function shiftNeighborsPoints(points, currentPointId, forbiddenPointId, hashVisited, callback) {
  if (hashVisited[currentPointId]) return;

  const neighbors = getNeighborsIds(points, currentPointId);
  callback(points[currentPointId]);

  hashVisited[currentPointId] = true;

  neighbors.forEach((pointId) => {
    if (pointId !== forbiddenPointId) {
      shiftNeighborsPoints(points, pointId, forbiddenPointId, hashVisited, callback);
    }
  });
}

export function splitInputTextValue(element, fontSize, padding) {
  const boxWidth = element.offsetWidth;
  const string = element.value.split(/\n/g);
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context.font = `${fontSize}px Open Sans`;

  let indent = 0;
  return string.reduce((acc, item, index) => {
    const metrics = context.measureText(item);
    // if the row is larger than the box width
    if (metrics.width + padding > boxWidth) {
      const newLine = item.split(" ");
      const splitLine = [];
      const split = newLine.reduce((line, elem) => {
        // checking word width
        const widthWord = context.measureText(elem).width + padding;
        const wordWidth =
          context.measureText(line).width + context.measureText(elem).width + 7.5 < boxWidth &&
          widthWord < boxWidth;
        // set word to box width
        if (wordWidth) {
          line = line.length ? (line += ` ${elem}`) : elem;
          return line;
        }
        // word wrap
        if (widthWord > boxWidth) {
          splitLine.push(line);
          const longWord = elem.split("");
          let newWord = "";
          const newRow = [];
          longWord.forEach((symbol) => {
            if (
              context.measureText(newWord).width + context.measureText(symbol).width + padding <=
              boxWidth
            ) {
              newWord = newWord.length ? (newWord += symbol) : symbol;
            } else {
              newRow.push(newWord);
              newWord = symbol;
            }
            return newWord;
          });
          splitLine.push(...newRow);
          line = newWord;
          return line;
        }
        splitLine.push(line);
        line = elem;
        return line;
      }, "");

      if (split.length) {
        splitLine.push(split);
      }
      acc[index] = {
        id: index,
        indent,
        value: [...splitLine],
      };
      indent += splitLine.length - 1;
    } else {
      acc[index] = {
        id: index,
        indent,
        value: [item],
      };
    }
    indent += 1;
    return acc;
  }, []);
}
