import createActionType from "@/redux/utils/createActionType";

export const MODULE = "timeTracking";

export const SET_IS_FETCHING_SUMMARY_STATUS = createActionType(
  MODULE,
  "setIsFetchingSummaryStatus"
);

export const SET_SUMMARY = createActionType(MODULE, "setSummary");

export const SET_TEAM_MEMBERS = createActionType(MODULE, "setTeamMembers");

export const SET_IS_FETCHING_TEAM_MEMBERS_STATUS = createActionType(
  MODULE,
  "setIsFetchingTeamMembersStatus"
);

export const SET_MODAL_TYPE = createActionType(MODULE, "setModalType");

export const SET_IS_SAVING_STATUS = createActionType(MODULE, "setIsSavingStatus");

export const SET_ACTIVE_TIME_ENTRY = createActionType(MODULE, "setActiveTimeEntry");

export const SET_ORDER_ITEMS = createActionType(MODULE, "setOrderItems");

export const SET_IS_FETCHING_ORDER_ITEMS_STATUS = createActionType(
  MODULE,
  "setIsFetchingOrderItemsStatus"
);

export const SET_IS_SEARCHING_ORDER_NUMBER_STATUS = createActionType(
  MODULE,
  "setIsSearchingOrderNumberStatus"
);

export const SET_ORDER_NUMBER_RESULTS = createActionType(MODULE, "setOrderNumberResults");

export const SET_USER = createActionType(MODULE, "setUser");

export const RESET = createActionType(MODULE, "reset");

export default {};
