import camelcaseKeys from "camelcase-keys";
import { nanoid } from "nanoid";

import * as plannerSelectors from "@/redux/planner/planner.selectors";
import * as plannerActions from "@/redux/planner/planner.actions";
import * as websocketsApi from "@/api/websockets";
import { createAlertAction } from "@/redux/alerts/alerts.actions";
import { createPurchaseOrderAction } from "@/redux/purchaseOrders/purchaseOrders.actions";
import * as notificationsActions from "@/redux/notifications/notifications.actions";

import ReduxModule from "./abstract/ReduxModule";
import * as collaborationNotes from "./collaboration-notes";
import * as collaborationNotesPO from "./purchase";
import orders from "./orders";
import alerts from "./alerts";

// Actions

const ACTIONS = {
  ORDER_CHAT_START: "Start order chat",
  ORDER_CHAT_STOP: "Stop order chat",
  ORDER_CHAT_EXIT: "Exit order chat",
  ORDER_CHAT_GET_ALL_MESSAGES: "Get all message",
  ORDER_CHAT_MESSAGES: "Create message",
  ORDER_CHAT_EDIT_MESSAGES: "Edit message",
  ORDER_CHAT_DELETE_MESSAGES: "Delete message",
  ORDER_CHAT_DELETE_FILE_MESSAGES: "Delete message file",

  PO_CHAT_START: "Start purchase order chat",
  PO_CHAT_STOP: "Stop purchase order chat",
  PO_CHAT_EXIT: "Exit purchase order chat",
  PO_CHAT_GET_ALL_MESSAGES: "Get all message",
  PO_CHAT_MESSAGES: "Create  message",
  PO_CHAT_EDIT_MESSAGES: "Edit message",
  PO_CHAT_DELETE_MESSAGES: "Delete message",
  PO_CHAT_DELETE_FILE_MESSAGES: "Delete message file",

  ORDER_GET_COMPANY_USERS: "Get all company users",
  PO_GET_COMPANY_USERS: "Get all company users",
  MARK_ALL_NOTIFICATIONS_READ: "Mark all notifications as read",
  MARK_NOTIFICATION_READ: "Mark notification as read",
  PO_MARK_ALL_NOTIFICATIONS_READ: "Mark all notifications as read",

  CUSTOM_EVENT_REMINDERS_START: "Start custom event reminders",
  CUSTOM_EVENT_REMINDERS_STOP: "Stop custom event reminders",
  CUSTOM_EVENT_REMINDERS_EXIT: "Exit custom event reminders",
};

class SocketsModule extends ReduxModule {
  // eslint-disable-next-line class-methods-use-this
  getNamespace() {
    return "[Socket]";
  }

  getInitialState() {
    return {
      sockets: {
        orderChat: null,
        purchase: null,
        customEventReminders: null,
      },
      pendings: {
        orderChat: false,
        purchase: false,
        customEventReminders: false,
      },
      errors: {
        orderChat: false,
        purchase: false,
        customEventReminders: false,
      },
    };
  }

  orderChatStartThunk = ({ token, dispatch, fulfilled, rejected }, userId) => {
    const socket = websocketsApi.createOrderChatSocket(token.access_token, userId);

    socket.onopen = () => {
      fulfilled(socket);
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);

      switch (data.type) {
        case collaborationNotes.ACTIONS.CREATE_MESSAGE:
          dispatch(collaborationNotes.instance.actions.createMessage(data.message));

          break;
        case collaborationNotes.ACTIONS.GET_ALL_MESSAGES:
          dispatch(collaborationNotes.instance.actions.getAllMessages(data.messages));

          break;
        case collaborationNotes.ACTIONS.EDIT_MESSAGE:
          dispatch(collaborationNotes.instance.actions.editMessage());

          dispatch(collaborationNotes.instance.actions.editOrderMessage(data.message));

          break;
        case collaborationNotes.ACTIONS.DELETE_MESSAGE:
          dispatch(collaborationNotes.instance.actions.deleteOrderMessage(data.message));

          break;
        case collaborationNotes.ACTIONS.GET_ALL_COMPANY_USER:
          dispatch(
            collaborationNotes.instance.actions.getAllCompanyUsers(camelcaseKeys(data.messages))
          );

          break;
        case collaborationNotes.ACTIONS.GET_ALL_NOTIFICATIONS:
          dispatch(
            collaborationNotes.instance.actions.getAllNotification(camelcaseKeys(data.messages))
          );

          dispatch(this.actions.getAllCompanyUser());
          break;
        case collaborationNotes.ACTIONS.RECEIVING_NOTIFICATION:
          dispatch(collaborationNotes.instance.actions.receivingNotification(data.message));
          dispatch(notificationsActions.setNotification("order", camelcaseKeys(data.message)));

          break;
        case collaborationNotes.ACTIONS.READ_NOTIFICATION_MESSAGE:
          dispatch(collaborationNotes.instance.actions.readingNotifications(data.messages));

          break;
        case collaborationNotes.ACTIONS.MARK_ALL_NOTIFICATION_AS_READ:
          dispatch(collaborationNotes.instance.actions.markAllNotificationsAsRead());

          break;

        case collaborationNotes.ACTIONS.MARK_NOTIFICATION_AS_READ:
          dispatch(collaborationNotes.instance.actions.markNotificationAsRead(data.messages));

          break;

        case collaborationNotes.ACTIONS.DELETE_NOTIFICATION:
          dispatch(collaborationNotes.instance.actions.deleteNotification(data.message));

          break;
        default:
          break;
      }
    };

    socket.onclose = () => {
      rejected();
    };
  };

  orderChatStopThunk = ({ getState, fulfilled }) => {
    const socket = getState().getIn(["websockets", "sockets", "orderChat"]);

    if (socket) {
      socket.onclose = () => {
        fulfilled(null);
      };

      socket.close();
    } else {
      fulfilled();
    }
  };

  orderChatExitThunk = ({ getState }) => {
    const socket = getState().getIn(["websockets", "sockets", "orderChat"]);
    const activeOrderUid = getState().getIn(["orders", "activeOrder", "uid"]);

    socket.send(
      JSON.stringify({
        type: "Exit",
        order_id: activeOrderUid,
      })
    );
  };

  orderChatNewMessageThunk = ({ getState, dispatch }, message, selectedMentionUser) => {
    const mentionUserId = selectedMentionUser ? selectedMentionUser.map((item) => item.id) : null;
    const socket = getState().getIn(["websockets", "sockets", "orderChat"]);
    const activeOrderUid = getState().getIn(["orders", "activeOrder", "uid"]);

    if (!activeOrderUid) {
      dispatch(orders.actions.submitOrder()).then((data) => {
        const orderUid = data.order;

        if (orderUid) {
          socket.send(
            JSON.stringify(
              mentionUserId
                ? {
                    type: collaborationNotes.ACTIONS.CREATE_MESSAGE,
                    message,
                    user_id: mentionUserId,
                    order_id: orderUid,
                  }
                : {
                    type: collaborationNotes.ACTIONS.CREATE_MESSAGE,
                    message,
                    order_id: orderUid,
                  }
            )
          );
        }
      });
    } else {
      socket.send(
        JSON.stringify(
          mentionUserId
            ? {
                type: collaborationNotes.ACTIONS.CREATE_MESSAGE,
                message,
                user_id: mentionUserId,
                order_id: activeOrderUid,
              }
            : {
                type: collaborationNotes.ACTIONS.CREATE_MESSAGE,
                message,
                order_id: activeOrderUid,
              }
        )
      );
    }
  };

  orderGetAllMessageThunk = ({ getState }) => {
    const socket = getState().getIn(["websockets", "sockets", "orderChat"]);
    const activeOrderUid = getState().getIn(["orders", "activeOrder", "uid"]);

    socket.send(
      JSON.stringify({
        type: collaborationNotes.ACTIONS.GET_ALL_MESSAGES,
        order_id: activeOrderUid,
      })
    );
  };

  orderChatDeleteMessageThunk = ({ getState, dispatch }, messageId) => {
    const state = getState();
    const socket = state.getIn(["websockets", "sockets", "orderChat"]);
    const activeOrderUid = state.getIn(["orders", "activeOrder", "uid"]);
    const allMessage = state.getIn(["collaborationNotes", "dataServer", "items"]);
    const newMessageList = allMessage.filter((item) => item.getIn(["id"]) !== messageId);

    dispatch(collaborationNotes.instance.actions.removeFileMessage(newMessageList));

    const deleteMessage = setTimeout(() => {
      socket.send(
        JSON.stringify({
          type: collaborationNotes.ACTIONS.DELETE_MESSAGE,
          message_id: messageId.toString(),
          order_id: activeOrderUid,
        })
      );
    }, 5000);

    dispatch(
      alerts.actions.addAlert({
        type: "danger",
        message: "Message deleted",
        closeDelay: 5000,
        action: {
          label: "Undo",
          callback: () => {
            dispatch(collaborationNotes.instance.actions.removeFileMessage(allMessage));

            clearTimeout(deleteMessage);
          },
        },
      })
    );
  };

  orderChatDeleteMessageFileThunk = ({ getState, dispatch }, fileId, messageId) => {
    const state = getState();
    const socket = state.getIn(["websockets", "sockets", "orderChat"]);
    const activeOrderUid = state.getIn(["orders", "activeOrder", "uid"]);
    const allMessage = state.getIn(["collaborationNotes", "dataServer", "items"]);
    const messageIndex = allMessage.findIndex((item) => item.getIn(["id"]) === messageId);
    const messageDeleteFile = allMessage.getIn([messageIndex]);
    const newFileList = messageDeleteFile.updateIn(["orderChatMessageFile"], (list) =>
      list.filter((item) => item.getIn(["id"]) !== fileId)
    );
    const newMessageList = allMessage.setIn([messageIndex], newFileList);

    dispatch(collaborationNotes.instance.actions.removeFileMessage(newMessageList));

    const deleteFile = setTimeout(() => {
      socket.send(
        JSON.stringify({
          type: collaborationNotes.ACTIONS.DELETE_FILE_MESSAGE,
          file_id: fileId.toString(),
          order_id: activeOrderUid,
        })
      );
    }, 5000);

    dispatch(
      alerts.actions.addAlert({
        type: "danger",
        message: "Attachment deleted",
        closeDelay: 5000,
        action: {
          label: "Undo",
          callback: () => {
            dispatch(collaborationNotes.instance.actions.removeFileMessage(allMessage));

            clearTimeout(deleteFile);
          },
        },
      })
    );
  };

  orderChatEditMessageThunk = ({ getState, dispatch }, message, selectedMentionUser) => {
    const state = getState();
    const mentionUserId = [];
    const socket = state.getIn(["websockets", "sockets", "orderChat"]);
    const messageFiles = state.getIn(["collaborationNotes", "attachedFilesEditor"]);
    const activeOrderUid = state.getIn(["orders", "activeOrder", "uid"]);
    const editId = state.getIn(["collaborationNotes", "dataServer", "editMessage", "id"]);

    Object.keys(selectedMentionUser).map((user) => {
      const index = state
        .getIn(["collaborationNotes", "allCompanyUsers"])
        .findIndex((item) => item.getIn(["firstName"]) === selectedMentionUser[user]);

      if (index >= 0) {
        mentionUserId.push(
          parseFloat(state.getIn(["collaborationNotes", "allCompanyUsers", index]).getIn(["id"]))
        );
      }

      return false;
    });

    if (messageFiles) {
      dispatch(
        collaborationNotes.instance.actions.sendMessageWithFiles({
          messageFiles,
          message,
          mentionUserId,
          editId,
        })
      );
    }

    socket.send(
      JSON.stringify(
        mentionUserId
          ? {
              type: collaborationNotes.ACTIONS.EDIT_MESSAGE,
              message,
              message_id: editId,
              order_id: activeOrderUid,
              user_id: mentionUserId,
            }
          : {
              type: collaborationNotes.ACTIONS.EDIT_MESSAGE,
              message,
              message_id: editId,
              order_id: activeOrderUid,
            }
      )
    );

    dispatch(collaborationNotes.instance.actions.clearEditMessage());
  };

  getAllCompanyUserThunk = ({ getState }) => {
    const socket = getState().getIn(["websockets", "sockets", "orderChat"]);
    const companyUsers = getState().getIn(["collaborationNotes", "allCompanyUsers"]);

    if (!companyUsers.size) {
      socket.send(
        JSON.stringify({
          type: collaborationNotes.ACTIONS.GET_ALL_COMPANY_USER,
        })
      );
    }
  };

  markAllMessageAsReadThunk = ({ getState }) => {
    const socket = getState().getIn(["websockets", "sockets", "orderChat"]);

    if (socket.readyState === socket.OPEN) {
      socket.send(
        JSON.stringify({
          type: collaborationNotes.ACTIONS.MARK_ALL_NOTIFICATION_AS_READ,
        })
      );
    }

    const customEventSocket = getState().getIn(["websockets", "sockets", "customEventReminders"]);

    if (customEventSocket.readyState === customEventSocket.OPEN) {
      customEventSocket.send(
        JSON.stringify({
          type: "Mark all as read",
        })
      );
    }
  };

  markMessageAsReadThunk = ({ getState }, message) => {
    const socket = getState().getIn(["websockets", "sockets", "orderChat"]);

    if (!message?.isCustomEvent && socket.readyState === socket.OPEN) {
      socket.send(
        JSON.stringify({
          type: collaborationNotes.ACTIONS.MARK_NOTIFICATION_AS_READ,
          id: message.id,
        })
      );
    }

    const customEventSocket = getState().getIn(["websockets", "sockets", "customEventReminders"]);

    if (message?.isCustomEvent && customEventSocket.readyState === customEventSocket.OPEN) {
      customEventSocket.send(
        JSON.stringify({
          type: "Mark as read",
          uid: message.id,
        })
      );
    }
  };

  /* Purchase Chat */
  purchaseOrderChatStartThunk = ({ token, dispatch, fulfilled, rejected }, userId) => {
    const socket = websocketsApi.createPurchaseChatSocket(token.access_token, userId);

    socket.onopen = () => {
      fulfilled(socket);
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);

      switch (data.type) {
        case collaborationNotesPO.ACTIONS.CREATE_MESSAGE:
          dispatch(collaborationNotesPO.instance.actions.createMessage(data.message));

          break;
        case collaborationNotesPO.ACTIONS.GET_ALL_MESSAGES:
          dispatch(collaborationNotesPO.instance.actions.getAllMessages(data.messages));

          break;
        case collaborationNotesPO.ACTIONS.EDIT_MESSAGE:
          dispatch(collaborationNotesPO.instance.actions.editMessage());

          dispatch(collaborationNotesPO.instance.actions.editMessage(data.message));

          break;
        case collaborationNotesPO.ACTIONS.DELETE_MESSAGE:
          dispatch(collaborationNotesPO.instance.actions.deleteMessage(data.message));

          break;
        case collaborationNotesPO.ACTIONS.GET_ALL_COMPANY_USER:
          dispatch(
            collaborationNotesPO.instance.actions.getAllPOCompanyUsers(camelcaseKeys(data.messages))
          );

          break;
        case collaborationNotesPO.ACTIONS.GET_ALL_NOTIFICATIONS:
          dispatch(
            collaborationNotes.instance.actions.getAllNotification(camelcaseKeys(data.messages))
          );

          dispatch(this.actions.getAllPOCompanyUser());
          break;
        case collaborationNotesPO.ACTIONS.RECEIVING_NOTIFICATION:
          dispatch(collaborationNotes.instance.actions.receivingNotification(data.message));

          dispatch(
            notificationsActions.setNotification("purchase_order", camelcaseKeys(data.message))
          );

          break;
        case collaborationNotesPO.ACTIONS.READ_NOTIFICATION_MESSAGE:
          dispatch(collaborationNotes.instance.actions.readingNotifications(data.messages));

          break;
        case collaborationNotesPO.ACTIONS.MARK_ALL_PO_NOTIFICATIONS_READ:
          dispatch(collaborationNotes.instance.actions.markAllNotificationsAsRead());

          break;
        case collaborationNotesPO.ACTIONS.DELETE_NOTIFICATION:
          dispatch(collaborationNotes.instance.actions.deleteNotification(data.message));

          break;
        default:
          break;
      }
    };

    socket.onclose = () => {
      rejected();
    };
  };

  purchaseOrderChatStopThunk = ({ getState, fulfilled }) => {
    const socket = getState().getIn(["websockets", "sockets", "purchase"]);

    if (socket) {
      socket.onclose = () => {
        fulfilled(null);
      };

      socket.close();
    } else {
      fulfilled();
    }
  };

  purchaseOrderChatExitThunk = ({ getState }) => {
    const socket = getState().getIn(["websockets", "sockets", "purchase"]);
    const activePurchaseOrderUid = getState().getIn([
      "purchaseOrders",
      "activePurchaseOrder",
      "uid",
    ]);

    socket.send(
      JSON.stringify({
        type: "Exit",
        purchase_order_uid: activePurchaseOrderUid,
      })
    );
  };

  purchaseOrderChatNewMessageThunk = async (
    { getState, dispatch },
    message,
    selectedMentionUser
  ) => {
    const mentionUserId = selectedMentionUser ? selectedMentionUser.map((item) => item.id) : null;
    const socket = getState().getIn(["websockets", "sockets", "purchase"]);
    const activePurchaseOrderUid = getState().getIn([
      "purchaseOrders",
      "activePurchaseOrder",
      "uid",
    ]);

    if (!activePurchaseOrderUid) {
      const activePurchaseOrder = getState().getIn(["purchaseOrders", "activePurchaseOrder"]);

      // Construct the post object
      const postPurchaseOrder = {
        supplier: activePurchaseOrder.supplier,
        contact: activePurchaseOrder.contact,
        editStatus: activePurchaseOrder.editStatus.id,
        requiredDate: activePurchaseOrder.requiredDate,
        notes: activePurchaseOrder.notes,
        pickupNotes: activePurchaseOrder.pickupNotes,
        receptionMethod: activePurchaseOrder.receptionMethod,
        reference: activePurchaseOrder.reference,
        items: activePurchaseOrder.items,
        itemTotal: activePurchaseOrder.itemTotal,
        deliveryFee: activePurchaseOrder.deliveryFee,
        gstAmount: activePurchaseOrder.gstAmount,
        total: activePurchaseOrder.total,
        discountRates: activePurchaseOrder.discountRates,
        billingAddress1: activePurchaseOrder.billingAddress1,
        billingAddress2: activePurchaseOrder.billingAddress2,
        billingSuburb: activePurchaseOrder.billingSuburb,
        billingState: activePurchaseOrder.billingState,
        billingPostcode: activePurchaseOrder.billingPostcode,
        deliveryAddress1: activePurchaseOrder.deliveryAddress1,
        deliveryAddress2: activePurchaseOrder.deliveryAddress2,
        deliverySuburb: activePurchaseOrder.deliverySuburb,
        deliveryState: activePurchaseOrder.deliveryState,
        deliveryPostcode: activePurchaseOrder.deliveryPostcode,
        oneTimeContactName: activePurchaseOrder.oneTimeContactName,
        oneTimeContactEmail: activePurchaseOrder.oneTimeContactEmail,
        oneTimeContactPhoneNumber: activePurchaseOrder.oneTimeContactPhoneNumber,
        oneTimeContactMobileNumber: activePurchaseOrder.oneTimeContactMobileNumber,
      };

      // Create the purchase order
      const data = await dispatch(createPurchaseOrderAction(postPurchaseOrder));
      const activePOUid = data.uid;

      if (activePOUid) {
        socket.send(
          JSON.stringify(
            mentionUserId
              ? {
                  type: collaborationNotesPO.ACTIONS.CREATE_MESSAGE,
                  message,
                  user_id: mentionUserId,
                  purchase_order_uid: activePOUid,
                }
              : {
                  type: collaborationNotesPO.ACTIONS.CREATE_MESSAGE,
                  message,
                  purchase_order_uid: activePOUid,
                }
          )
        );

        dispatch(this.actions.purchaseOrderGetAllMessage());
      }
    } else {
      socket.send(
        JSON.stringify(
          mentionUserId
            ? {
                type: collaborationNotesPO.ACTIONS.CREATE_MESSAGE,
                message,
                user_id: mentionUserId,
                purchase_order_uid: activePurchaseOrderUid,
              }
            : {
                type: collaborationNotesPO.ACTIONS.CREATE_MESSAGE,
                message,
                purchase_order_uid: activePurchaseOrderUid,
              }
        )
      );
    }
  };

  purchaseOrderGetAllMessageThunk = ({ getState }) => {
    const socket = getState().getIn(["websockets", "sockets", "purchase"]);
    const activePurchaseOrderUid = getState().getIn([
      "purchaseOrders",
      "activePurchaseOrder",
      "uid",
    ]);

    socket.send(
      JSON.stringify({
        type: collaborationNotesPO.ACTIONS.GET_ALL_MESSAGES,
        purchase_order_uid: activePurchaseOrderUid,
      })
    );
  };

  purchaseOrderChatDeleteMessageThunk = ({ getState, dispatch }, messageId) => {
    const state = getState();
    const socket = state.getIn(["websockets", "sockets", "purchase"]);
    const activePurchaseOrderUid = state.getIn(["purchaseOrders", "activePurchaseOrder", "uid"]);
    const allMessage = state.getIn(["purchase", "dataServer", "items"]);
    const newMessageList = allMessage.filter((item) => item.getIn(["id"]) !== messageId);

    dispatch(collaborationNotesPO.instance.actions.removeFileMessage(newMessageList));

    const deleteMessage = setTimeout(() => {
      socket.send(
        JSON.stringify({
          type: collaborationNotesPO.ACTIONS.DELETE_MESSAGE,
          message_id: messageId.toString(),
          purchase_order_uid: activePurchaseOrderUid,
        })
      );
    }, 5000);

    dispatch(
      createAlertAction(nanoid(), "Message deleted", false, "danger", "Undo", () => {
        dispatch(collaborationNotesPO.instance.actions.removeFileMessage(allMessage));

        clearTimeout(deleteMessage);
      })
    );
  };

  purchaseOrderChatDeleteMessageFileThunk = ({ getState, dispatch }, fileId, messageId) => {
    const state = getState();
    const socket = state.getIn(["websockets", "sockets", "purchase"]);
    const activePurchaseOrderUid = state.getIn(["purchaseOrders", "activePurchaseOrder", "uid"]);
    const allMessage = state.getIn(["purchase", "dataServer", "items"]);
    const messageIndex = allMessage.findIndex((item) => item.getIn(["id"]) === messageId);
    const messageDeleteFile = allMessage.getIn([messageIndex]);
    const newFileList = messageDeleteFile.updateIn(["orderChatMessageFile"], (list) =>
      list.filter((item) => item.getIn(["id"]) !== fileId)
    );
    const newMessageList = allMessage.setIn([messageIndex], newFileList);

    dispatch(collaborationNotesPO.instance.actions.removeFileMessage(newMessageList));

    const deleteFile = setTimeout(() => {
      socket.send(
        JSON.stringify({
          type: collaborationNotesPO.ACTIONS.DELETE_FILE_MESSAGE,
          file_id: fileId.toString(),
          purchase_order_uid: activePurchaseOrderUid,
        })
      );
    }, 5000);

    dispatch(
      createAlertAction(nanoid(), "Attachment deleted", false, "danger", "Undo", () => {
        dispatch(collaborationNotesPO.instance.actions.removeFileMessage(allMessage));

        clearTimeout(deleteFile);
      })
    );
  };

  purchaseOrderChatEditMessageThunk = ({ getState, dispatch }, message, selectedMentionUser) => {
    const state = getState();
    const mentionUserId = [];
    const socket = state.getIn(["websockets", "sockets", "purchase"]);
    const messageFiles = state.getIn(["purchase", "attachedFilesEditor"]);
    const activePurchaseOrderUid = state.getIn(["purchaseOrders", "activePurchaseOrder", "uid"]);
    const editId = state.getIn(["purchase", "dataServer", "editMessage", "id"]);

    Object.keys(selectedMentionUser).map((user) => {
      const index = state
        .getIn(["purchase", "allCompanyUsers"])
        .findIndex((item) => item.getIn(["firstName"]) === selectedMentionUser[user]);

      if (index >= 0) {
        mentionUserId.push(
          parseFloat(state.getIn(["purchase", "allCompanyUsers", index]).getIn(["id"]))
        );
      }

      return false;
    });

    if (messageFiles) {
      dispatch(
        collaborationNotesPO.instance.actions.sendMessageWithFiles({
          messageFiles,
          message,
          mentionUserId,
          editId,
        })
      );
    }

    socket.send(
      JSON.stringify(
        mentionUserId
          ? {
              type: collaborationNotesPO.ACTIONS.EDIT_MESSAGE,
              message,
              message_id: editId,
              purchase_order_uid: activePurchaseOrderUid,
              user_id: mentionUserId,
            }
          : {
              type: collaborationNotesPO.ACTIONS.EDIT_MESSAGE,
              message,
              message_id: editId,
              purchase_order_uid: activePurchaseOrderUid,
            }
      )
    );

    dispatch(collaborationNotesPO.instance.actions.clearEditMessage());
    dispatch(this.actions.purchaseOrderGetAllMessage());
  };

  getAllPOCompanyUserThunk = ({ getState }) => {
    const socket = getState().getIn(["websockets", "sockets", "purchase"]);
    const companyUsers = getState().getIn(["purchase", "allCompanyUsers"]);

    if (!companyUsers.size) {
      socket.send(
        JSON.stringify({
          type: collaborationNotesPO.ACTIONS.GET_ALL_COMPANY_USER,
        })
      );
    }
  };

  markAllPONotificationsAsReadThunk = ({ getState }) => {
    const socket = getState().getIn(["websockets", "sockets", "purchase"]);

    socket.send(
      JSON.stringify({
        type: collaborationNotesPO.ACTIONS.MARK_ALL_NOTIFICATION_AS_READ,
      })
    );
  };

  customEventRemindersThunk = ({ token, dispatch, fulfilled, rejected, getState }, userId) => {
    const connect = async () => {
      try {
        const socket = websocketsApi.createCustomEventRemindersWebSocket(
          token.access_token,
          userId
        );

        socket.onopen = () => {
          fulfilled(socket);
        };

        socket.onmessage = (event) => {
          const state = getState();
          const data = JSON.parse(event.data);
          const messages = camelcaseKeys(data.messages);
          const customEventReminders = plannerSelectors.getCustomEventReminders(state);

          switch (data.type) {
            case "Get all messages":
              dispatch(plannerActions.setCustomEventReminders(messages));
              break;
            case "Get message":
              dispatch(notificationsActions.setNotification("custom_event", messages));
              dispatch(plannerActions.setCustomEventReminder(messages));
              const allNotifications = customEventReminders.concat(messages);
              allNotifications.sort((a, b) => a.createdTimestampUnix - b.createdTimestampUnix);
              dispatch(plannerActions.setCustomEventReminders(allNotifications));
              break;
            default:
              break;
          }
        };

        socket.onerror = () => {
          socket.close();
          rejected();
        };

        socket.onclose = () => {
          setTimeout(() => connect(), 3000);
        };
      } catch {
        setTimeout(() => connect(), 3000);
      }
    };

    connect();
  };

  customEventRemindersStopThunk = ({ getState, fulfilled }) => {
    const socket = getState().getIn(["websockets", "sockets", "customEventReminders"]);

    if (socket) {
      socket.onclose = () => {
        fulfilled(null);
      };

      socket.close();
    } else {
      fulfilled();
    }
  };

  customEventRemindersExitThunk = ({ getState }) => {
    const socket = getState().getIn(["websockets", "sockets", "customEventReminders"]);

    socket.send(
      JSON.stringify({
        type: "Exit",
      })
    );
  };

  getAllCustomEventRemindersThunk = ({ getState }) => {
    const socket = getState().getIn(["websockets", "sockets", "customEventReminders"]);

    if (socket.readyState === socket.OPEN) {
      socket.send(JSON.stringify({ type: "Get all messages" }));
    }
  };

  defineActions() {
    const orderChatStart = this.thunkAction(
      ACTIONS.ORDER_CHAT_START,
      this.orderChatStartThunk,
      true
    );
    const orderChatStop = this.thunkAction(ACTIONS.ORDER_CHAT_STOP, this.orderChatStopThunk, true);
    const orderChatExit = this.thunkAction(ACTIONS.ORDER_CHAT_EXIT, this.orderChatExitThunk, true);

    const orderChatNewMessage = this.thunkAction(
      ACTIONS.ORDER_CHAT_MESSAGES,
      this.orderChatNewMessageThunk
    );
    const orderChatEditMessage = this.thunkAction(
      ACTIONS.ORDER_CHAT_EDIT_MESSAGES,
      this.orderChatEditMessageThunk
    );
    const orderGetAllMessage = this.thunkAction(
      ACTIONS.ORDER_CHAT_GET_ALL_MESSAGES,
      this.orderGetAllMessageThunk
    );
    const orderDeleteMessage = this.thunkAction(
      ACTIONS.ORDER_CHAT_DELETE_MESSAGES,
      this.orderChatDeleteMessageThunk
    );
    const orderDeleteMessageFile = this.thunkAction(
      ACTIONS.ORDER_CHAT_DELETE_FILE_MESSAGES,
      this.orderChatDeleteMessageFileThunk
    );

    const purchaseOrderChatStart = this.thunkAction(
      ACTIONS.PO_CHAT_START,
      this.purchaseOrderChatStartThunk,
      true
    );
    const purchaseOrderChatStop = this.thunkAction(
      ACTIONS.PO_CHAT_STOP,
      this.purchaseOrderChatStopThunk,
      true
    );
    const purchaseOrderChatExit = this.thunkAction(
      ACTIONS.PO_CHAT_EXIT,
      this.purchaseOrderChatExitThunk,
      true
    );
    const purchaseOrderChatNewMessage = this.thunkAction(
      ACTIONS.PO_CHAT_MESSAGES,
      this.purchaseOrderChatNewMessageThunk
    );
    const purchaseOrderChatEditMessage = this.thunkAction(
      ACTIONS.PO_CHAT_EDIT_MESSAGES,
      this.purchaseOrderChatEditMessageThunk
    );
    const purchaseOrderGetAllMessage = this.thunkAction(
      ACTIONS.PO_CHAT_GET_ALL_MESSAGES,
      this.purchaseOrderGetAllMessageThunk
    );
    const purchaseOrderDeleteMessage = this.thunkAction(
      ACTIONS.PO_CHAT_DELETE_MESSAGES,
      this.purchaseOrderChatDeleteMessageThunk
    );
    const purchaseOrderDeleteMessageFile = this.thunkAction(
      ACTIONS.PO_CHAT_DELETE_FILE_MESSAGES,
      this.purchaseOrderChatDeleteMessageFileThunk
    );

    const getAllCompanyUser = this.thunkAction(
      ACTIONS.ORDER_GET_COMPANY_USERS,
      this.getAllCompanyUserThunk
    );

    const getAllPOCompanyUser = this.thunkAction(
      ACTIONS.PO_GET_COMPANY_USERS,
      this.getAllPOCompanyUserThunk
    );

    const markAllMessageAsRead = this.thunkAction(
      ACTIONS.MARK_ALL_NOTIFICATIONS_READ,
      this.markAllMessageAsReadThunk
    );

    const markMessageAsRead = this.thunkAction(
      ACTIONS.MARK_NOTIFICATION_READ,
      this.markMessageAsReadThunk
    );

    const markAllPONotificationsAsRead = this.thunkAction(
      ACTIONS.PO_MARK_ALL_NOTIFICATIONS_READ,
      this.markAllPONotificationsAsReadThunk
    );

    const customEventRemindersStart = this.thunkAction(
      ACTIONS.CUSTOM_EVENT_REMINDERS_START,
      this.customEventRemindersThunk,
      true
    );

    const customEventRemindersStop = this.thunkAction(
      ACTIONS.CUSTOM_EVENT_REMINDERS_STOP,
      this.customEventRemindersStopThunk,
      true
    );
    const customEventRemindersExit = this.thunkAction(
      ACTIONS.CUSTOM_EVENT_REMINDERS_EXIT,
      this.customEventRemindersExitThunk,
      true
    );

    const getAllCustomEventReminders = this.thunkAction(
      ACTIONS.CUSTOM_EVENT_REMINDERS_STOP,
      this.getAllCustomEventRemindersThunk,
      true
    );

    return {
      orderChatStart,
      orderChatStop,
      orderChatExit,
      orderChatNewMessage,
      orderChatEditMessage,
      orderDeleteMessage,
      orderDeleteMessageFile,
      orderGetAllMessage,

      purchaseOrderChatStart,
      purchaseOrderChatStop,
      purchaseOrderChatExit,
      purchaseOrderChatNewMessage,
      purchaseOrderChatEditMessage,
      purchaseOrderDeleteMessage,
      purchaseOrderDeleteMessageFile,
      purchaseOrderGetAllMessage,

      getAllCompanyUser,
      getAllPOCompanyUser,
      markAllMessageAsRead,
      markMessageAsRead,
      markAllPONotificationsAsRead,

      customEventRemindersStart,
      customEventRemindersStop,
      customEventRemindersExit,
      getAllCustomEventReminders,
    };
  }

  defineReducers() {
    return {
      [`${ACTIONS.ORDER_CHAT_START} fulfilled`]: this.setInReducer(["sockets", "orderChat"]),

      [`${ACTIONS.ORDER_CHAT_START} pending`]: this.setInReducer(["pendings", "orderChat"]),

      [`${ACTIONS.ORDER_CHAT_START} rejected`]: this.setInReducer(["errors", "orderChat"]),

      [`${ACTIONS.ORDER_CHAT_STOP} fulfilled`]: this.setInReducer(["sockets", "orderChat"]),

      [`${ACTIONS.ORDER_CHAT_STOP} pending`]: this.setInReducer(["pendings", "orderChat"]),

      [`${ACTIONS.ORDER_CHAT_STOP} rejected`]: this.setInReducer(["errors", "orderChat"]),

      /* Purchase */
      [`${ACTIONS.PO_CHAT_START} fulfilled`]: this.setInReducer(["sockets", "purchase"]),

      [`${ACTIONS.PO_CHAT_START} pending`]: this.setInReducer(["pendings", "purchase"]),

      [`${ACTIONS.PO_CHAT_START} rejected`]: this.setInReducer(["errors", "purchase"]),

      [`${ACTIONS.PO_CHAT_STOP} fulfilled`]: this.setInReducer(["sockets", "purchase"]),

      [`${ACTIONS.PO_CHAT_STOP} pending`]: this.setInReducer(["pendings", "purchase"]),

      [`${ACTIONS.PO_CHAT_STOP} rejected`]: this.setInReducer(["errors", "purchase"]),

      /** Custom event reminders */
      [`${ACTIONS.CUSTOM_EVENT_REMINDERS_START} fulfilled`]: this.setInReducer([
        "sockets",
        "customEventReminders",
      ]),

      [`${ACTIONS.CUSTOM_EVENT_REMINDERS_START} pending`]: this.setInReducer([
        "pendings",
        "customEventReminders",
      ]),

      [`${ACTIONS.CUSTOM_EVENT_REMINDERS_STOP} rejected`]: this.setInReducer([
        "errors",
        "customEventReminders",
      ]),

      [`${ACTIONS.CUSTOM_EVENT_REMINDERS_STOP} fulfilled`]: this.setInReducer([
        "sockets",
        "customEventReminders",
      ]),

      [`${ACTIONS.CUSTOM_EVENT_REMINDERS_STOP} pending`]: this.setInReducer([
        "pendings",
        "customEventReminders",
      ]),

      [`${ACTIONS.CUSTOM_EVENT_REMINDERS_START} rejected`]: this.setInReducer([
        "errors",
        "customEventReminders",
      ]),
    };
  }
}

const instance = new SocketsModule();
instance.init();

export default instance;
