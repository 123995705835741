export default class Vector {
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }

  static create(obj) {
    return new Vector(obj.x, obj.y);
  }

  static toDeg(angle) {
    return Math.floor((angle * 180) / Math.PI);
  }

  static toRad(angle) {
    return (Math.PI / 180) * angle;
  }

  length() {
    return Math.sqrt(this.x * this.x + this.y * this.y);
  }

  dot(v) {
    return this.x * v.x + this.y * v.y;
  }

  // angle to x-axis
  angle() {
    const angle = Math.acos(this.x / this.length());
    if (this.y < 0) return 2 * Math.PI - angle;
    return angle;
  }

  angleBetween(v) {
    return Math.acos(this.dot(v) / (this.length() * v.length()));
  }

  add(v) {
    return new Vector(this.x + v.x, this.y + v.y);
  }

  sub(v) {
    return new Vector(this.x - v.x, this.y - v.y);
  }

  mul(a) {
    return new Vector(this.x * a, this.y * a);
  }

  div(a) {
    if (a === 0) return new Vector(Infinity, Infinity);
    return new Vector(this.x / a, this.y / a);
  }

  rotate(angle) {
    const sin = Math.sin(angle);
    const cos = Math.cos(angle);
    return new Vector(this.x * cos - this.y * sin, this.x * sin + this.y * cos);
  }

  normalize(length) {
    return this.mul(length / this.length());
  }

  equal(v) {
    return v && this.x === v.x && this.y === v.y;
  }
}
