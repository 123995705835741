import { MODULE } from "./productCatalogue.actionTypes";

export const getSearch = (state) => state.getIn([MODULE, "search"]);

export const isFetchingCategories = (state) => state.getIn([MODULE, "isFetchingCategories"]);

export const isAddCategoryVisible = (state) => state.getIn([MODULE, "isAddCategoryVisible"]);

export const getCategories = (state) => state.getIn([MODULE, "categories"]);

export const isCreatingCategory = (state) => state.getIn([MODULE, "isCreatingCategory"]);

export const getProducts = (state) => state.getIn([MODULE, "products"]);

export const getExpandedCategories = (state) => state.getIn([MODULE, "expandedCategories"]);

export const getMiscellaneousCategory = (state) => {
  const data = getCategories(state).find((c) => c.name === "Miscellaneous");

  if (data) return data;
  return null;
};

export const getSelectedCategories = (state) => state.getIn([MODULE, "selectedCategories"]);

export const getSelectedProducts = (state) => state.getIn([MODULE, "selectedProducts"]);

export const getSelectedFlashingProducts = (state) =>
  state.getIn([MODULE, "selectedFlashingProducts"]);

export const getProductsForCategory = (state, categoryUid) =>
  state.getIn([MODULE, "products", categoryUid]) || [];

export const isDuplicatingProducts = (state) => state.getIn([MODULE, "isDuplicatingProducts"]);
