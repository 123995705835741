import {
  SET_IS_FETCHING,
  SET_SUPPLIERS,
  SET_SELECTED_SUPPLIERS,
  RESET,
} from "./supplierFilter.actionTypes";

const initialState = {
  isFetching: false,
  suppliers: [],
  selectedSuppliers: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_FETCHING: {
      return {
        ...state,
        isFetching: action.payload,
      };
    }

    case SET_SELECTED_SUPPLIERS: {
      return {
        ...state,
        selectedSuppliers: action.payload,
      };
    }

    case SET_SUPPLIERS: {
      const { data } = action.payload;

      return {
        ...state,
        suppliers: data,
      };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
