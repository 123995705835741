import { createApi } from "./common";

export const submitProduct = (token, data) =>
  createApi("POST", "flashing_products/")(token, data);

export const deleteProduct = (token, productId) =>
  createApi("DELETE", `flashing_products/${productId}/`)(token);

export const searchProducts = createApi("GET", "flashing_products/");

export const getProduct = (token, productId) =>
  createApi("GET", `flashing_products/${productId}/`)(token);

export const postDuplicateFlashing = (token, data) =>
  createApi("POST", "flashing_products/bulk_duplicate/")(token, data);

export default searchProducts;
