import moment from "moment-timezone";
import { fromJS } from "immutable";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import { orderBy } from "lodash";

import * as date from "@/utils/date";
import { genId } from "../../utils/common";
import { ROOT_URL } from "../../env";
import {
  STATUSES_RESERVED,
  PRODUCT_TYPE_NOTES_ITEM_LABEL,
  PRODUCT_TYPE_PRODUCT_KIT_LABEL,
} from "../../utils/constants";
import * as orderApi from "../../api/orders";
import * as orderItemsApi from "../../api/order-items";
import * as drawingsApi from "../../api/drawings";
import * as cardsApi from "../../api/cards";
import * as customersApi from "../../api/customers";

import { withMutations } from "./helpers/reducerWrappers";
import { getCompanyTaxRate, getMinLengthFlashings, getMinLengthProducts } from "./helpers/product";
import ReduxModule from "./abstract/ReduxModule";
import drawingsItems from "./drawings-items";
import drawings from "./drawings";
import alerts from "./alerts";
import drawingsProducts from "./drawings-products";
import prices from "./prices";
import additionalProducts from "./additional-products";
import customers from "./customers";
import cards from "./cards";

// TODO need refactoring autosave, activeOrder & chosenOrder

const ACTIONS = {
  SET_ORDERS_FILTERS: "Set orders filters",
  RESET_ORDERS_FILTERS: "Reset orders filters",

  SET_ORDERS_SORTING: "Set orders sorting",
  RESET_ORDERS_SORTING: "Reset orders sorting",

  SEARCH_CUSTOMER_ORDERS: "Search customer orders",
  CLEAR_CUSTOMER_ORDERS: "Clear customer orders",
  SEARCH_COMPANY_ORDERS: "Search company orders",
  SET_BILLING_ADDRESS: "Set billing address",
  SET_SHIPPING_ADDRESS: "Set shipping address",
  SET_CONTACT: "Set contact",
  SET_REQUIRED_DATE: "Set required date",
  SET_METHOD_OBTAINING: "Set method obtaining",
  SET_PICKUP_NOTES: "Set pickup notes",
  SET_NOTES: "Set general notes",
  SET_REFERENCE: "Set reference",

  GET_ALL_STATUSES: "Get all statuses",
  CHANGE_STATUS: "Set status",
  CHANGE_EXTENDED_STATUS: "Set extended status",

  TOGGLE_SUBMITTED: "Toggle submitted",
  TOGGLE_SAVING_MODE: "Switch saving type",
  TOGGLE_SAVE_NOT_AMOUNT: "Switch saving for not amount",
  SET_ORDER_TYPE: "Set order type",
  SET_COMBINED_STATUS_STRING: "Set combined status string",

  CLEAR_ACTIVE_ORDER: "Clear active order",
  CREATE_NEW_ORDER: "Create new order",
  RESET_CHANGES: "Reset changes",
  SUBMIT_ORDER: "Submit order",
  PATCH_COMPLETED_ITEMS: "Update completed items",
  PATCH_COMPLETED_KIT_ITEMS: "Update completed kit items",
  PATCH_COMPLETED_SUB_KIT_ITEMS: "Update completed sub kit items",
  PATCH_COMPLETED_SUB_KITS: "Update completed sub kits",
  UPDATE_ITEMS_INDEX: "Update items and products kits index",
  UPDATE_SUB_KITS_INDEX: "Update sub kits index",
  UPDATE_SUB_KIT_ITEMS_INDEX: "Update sub kit items index",
  UPDATE_PRODUCT_KIT_ITEMS_INDEX: "Update product kit items index",
  SET_SAVING_DELAY: "Set saving delay",
  CHOOSE_ORDER: "Chose order",
  OPEN_ORDER: "Open order",
  SET_ORDER: "Set Order",
  DELETE_ORDER: "Delete order",
  UNDO_DELETE_ORDER: "Undo delete order",
  EXPORT_ORDERS: "Export orders data",

  GET_WORK_ORDER_PDF: "Get work order PDF",
  GET_DELIVERY_DOCKET_PDF: "Get delivery docket PDF",
  INVOICE_ORDER: "Order invoiced",
  SET_INVOICE_ORDER_DATE: "Set invoice order date",
  SET_INVOICE_QUOTED_DATE: "Set invoice quoted date",
  LOAD_ORDER_VIEW: "Load order view",

  CHECK_ORDER: "Check order",
  UNCHECK_ORDER: "Uncheck order",
  CHECK_PAGE_ORDERS: "Check page orders",
  DELETE_CHECKED_ORDERS: "Delete checked orders",
  UNDO_DELETE_CHECKED_ORDERS: "Undo delete checked orders",
  STATUS_CHANGE_CHECKED_ORDERS: "Status change checked orders",
  CLEAR_CHECKED_ORDERS: "Clear checked orders",

  DUPLICATE_ORDER: "Duplicate Order",
  CHANGE_CUSTOMER: "Changing Customer",
  FETCH_USERS: "Fetch User",
  TOGGLE_ASSIGNEE: "Toggle Assignee",
  TOGGLE_LABELS: "Toggle Labels",
  ASSIGN_USER: "Assign User",
  SET_PRODUCT_MODAL_TYPE: "Set product modal type",
  CLEAR_ERROR: "Clear errors",
};

class OrderModule extends ReduxModule {
  getNamespace() {
    return "[Orders]";
  }

  getInitialState() {
    return {
      ...this.initialOrdersState(),
      statuses: {
        items: [],
        isPending: false,
      },
      isPending: false,
    };
  }

  initialOrdersState() {
    return {
      productModalType: "",
      activeOrder: null, // this.initialEmptyOrder()
      chosenOrder: null, // order from server
      startStateOrder: null,
      users: [],
      errors: {},
      filters: { limit: 10, offset: 0 },
      sorting: { sortField: "", sortDirection: "" },
      dataServer: {
        isPending: false,
        isDuplicating: false,
        isChangingCustomer: false,
        items: [],
      },
      saving: {
        auto: true,
        saveForNotAmount: false,
        intervalSaving: {
          timer: null,
          type: "second",
          value: 1,
        },
        start: null,
        isPending: false,
      },
      checkedOrders: [],
      removeAssignees: [],
    };
  }

  initialEmptyOrder() {
    return {
      uid: "",
      type: null,
      status: { name: null, colour: null },
      withPrices: true,
      invoiceDate: false,
      reference: "",
      isSubmitted: false,
      isDeleted: false,
      notes: "",
      createdTimestamp: "",
      requiredTimestamp: "",
      submittedTimestamp: "",
      obtainingMethod: "delivery",
      purchaseOrder: 0,
      billingAddress: this.initialAddressState(),
      shippingAddress: this.initialAddressState(),
      contacts: this.initialContactState(),
      pickupNotes: "",
      assignees: [],
      labels: [],
    };
  }

  initialAddressState() {
    return {
      address1: "",
      address2: "",
      city: "",
      state: "",
      postcode: "",
      countryCode: "",
    };
  }

  initialContactState() {
    return {
      contactName: "",
      phone: "",
      email: "",
      mobile: "",
    };
  }

  static generateOrderDataForServer(state) {
    const orderPrices = state.getIn(["prices", "orderPrices"]);
    const saveNotAmount = state.getIn(["orders", "saving", "saveForNotAmount"]);
    const startStateOrder = state.getIn(["prices", "startStateOrder"]);
    const orderState = state.getIn(["orders", "activeOrder"]);

    const order = {
      is_submitted: orderState.get("isSubmitted") ?? false,
      type: orderState.get("type"),

      reference: orderState.get("reference"),
      purchase_order: orderState.get("purchaseOrder"),
      pickup_notes: orderState.get("pickupNotes"),
      notes: orderState.get("notes"),
      obtaining_method: orderState.get("obtainingMethod"),

      contact_name: orderState.getIn(["contact", "contactName"]),
      phone: orderState.getIn(["contact", "phone"]),
      email: orderState.getIn(["contact", "email"]),
      mobile: orderState.getIn(["contact", "mobile"]),

      billing_address1: orderState.getIn(["billingAddress", "address1"]),
      billing_address2: orderState.getIn(["billingAddress", "address2"]),
      billing_city: orderState.getIn(["billingAddress", "city"]),
      billing_postcode: orderState.getIn(["billingAddress", "postcode"]),
      billing_state: orderState.getIn(["billingAddress", "state"]),
      billing_country_code: orderState.getIn(["billingAddress", "countryCode"]),

      shipping_address1: orderState.getIn(["shippingAddress", "address1"]),
      shipping_address2: orderState.getIn(["shippingAddress", "address2"]),
      shipping_city: orderState.getIn(["shippingAddress", "city"]),
      shipping_postcode: orderState.getIn(["shippingAddress", "postcode"]),
      shipping_state: orderState.getIn(["shippingAddress", "state"]),
      shipping_country_code: orderState.getIn(["shippingAddress", "countryCode"]),

      required_timestamp: orderState.get("requiredTimestamp"),

      additional_prices: orderPrices
        .get("additionalPrices")
        .toJS()
        .map((price) => {
          price.is_deleted = !!price.isDeleted;
          delete price.isDeleted;
          return price;
        }),
      delivery_fee: orderPrices.get("sumAdditionalPrices"),

      additional_discounts: orderPrices
        .get("discountPrices")
        .toJS()
        .map((price) => {
          price.is_deleted = !!price.isDeleted;
          delete price.isDeleted;
          return price;
        }),
      discount_value: orderPrices.get("sumDiscountPercent"),

      additional_markups: orderPrices
        .get("markupPrices")
        .toJS()
        .map((price) => {
          price.is_deleted = !!price.isDeleted;
          delete price.isDeleted;
          return price;
        }),

      margin: orderPrices.get("margin"),
      margin_percentage: orderPrices.get("marginPercentage"),

      labour_total: orderPrices.get("labourTotal"),
      subtotal_value: orderPrices.get("subTotal"),
      tax_value: orderPrices.get("GST"),
      total_value: orderPrices.get("totalPrice"),

      customer: state.getIn(["customers", "activeCustomer", "uid"]),
    };

    if (orderState.get("uid")) {
      order.uid = orderState.get("uid");
    }

    let itemsForSend = drawingsItems.generateOrderDataForServer(state);

    if (!itemsForSend.errors.length) {
      itemsForSend = drawings.generateOrderDataForServer(state, itemsForSend.data);
    }

    const additionalProductsItems = additionalProducts.generateOrderDataForServer(state);

    const isAutoSaving = state.getIn(["orders", "saving", "auto"]);

    if (!isAutoSaving && orderState.get("isSubmitted")) {
      order.is_changed = OrderModule.checkPricesChange(orderPrices, startStateOrder);
      const isPriceNotAmount = OrderModule.checkPricesForAmount(state);
      const isHasErrors = OrderModule.checkForErrors(
        orderState,
        additionalProductsItems,
        itemsForSend
      );

      if (isHasErrors) {
        return isHasErrors;
      }

      if (isPriceNotAmount && !saveNotAmount) {
        return { isPriceNotAmount };
      }
    } else if (
      itemsForSend.errors.find(
        (item) =>
          typeof item !== "string" &&
          Object.keys(item).find((key) =>
            item[key].find((error) => error.includes("Too much data"))
          )
      )
    ) {
      return {
        itemsErrors: itemsForSend.errors,
      };
    }

    return {
      order,
      items: itemsForSend.data,
      additional_products: additionalProductsItems.data,
    };
  }

  static checkForErrors(orderState, additionalProductsItems, itemsForSend) {
    const orderErrors = [];

    // * check for 'no products' and 'no drawings' errors
    let noItems = 0;

    if (additionalProductsItems.errors.indexOf("Empty products") > -1) {
      noItems += 1;

      additionalProductsItems.errors.splice(
        additionalProductsItems.errors.indexOf("Empty products"),
        1
      );
    }

    if (itemsForSend.errors.indexOf("Empty drawings") > -1) {
      noItems += 1;
      itemsForSend.errors.splice(itemsForSend.errors.indexOf("Empty drawings"), 1);
    }

    // * if both no drawings and products throw error
    if (noItems === 2) {
      orderErrors.push("No products on order");
    }

    if (orderState.get("reference") && orderState.get("reference").length > 20) {
      orderErrors.push("PO must be no more than 20 characters");
    }

    if (orderErrors.length) {
      return {
        orderErrors,
      };
    }

    if (itemsForSend.errors.length || additionalProductsItems.errors.length) {
      return {
        itemsErrors: [].concat(itemsForSend.errors, additionalProductsItems.errors),
      };
    }

    return false;
  }

  static checkPricesChange(orderPrices, startStateOrder) {
    if (!startStateOrder) {
      return true;
    }

    if (
      orderPrices.get("sumAdditionalProducts") !== startStateOrder.get("sumAdditionalProducts") ||
      orderPrices.get("totalPrice") !== startStateOrder.get("totalPrice") ||
      orderPrices.get("sumDrawingsCustomPrices") !== startStateOrder.get("sumDrawingsCustomPrices")
    ) {
      return true;
    }

    // additionalPrices
    const additionalPrices = orderPrices.get("additionalPrices").reduce((sum, price) => {
      if (!price.get("isDeleted")) {
        return sum + parseFloat(price.get("value"));
      }

      return sum;
    }, 0);

    const startAdditionalPrices = startStateOrder.get("additionalPrices").reduce((sum, price) => {
      if (!price.get("isDeleted")) {
        return sum + parseFloat(price.get("value"));
      }

      return sum;
    }, 0);

    if (additionalPrices !== startAdditionalPrices) {
      return true;
    }

    // discountPrices
    const discountPrices = orderPrices.get("discountPrices").reduce((sum, price) => {
      if (!price.get("isDeleted")) {
        return sum + parseFloat(price.get("value"));
      }

      return sum;
    }, 0);

    const startDiscountPrices = startStateOrder.get("discountPrices").reduce((sum, price) => {
      if (!price.get("isDeleted")) {
        return sum + parseFloat(price.get("value"));
      }

      return sum;
    }, 0);

    if (discountPrices !== startDiscountPrices) {
      return true;
    }

    // markupPrices
    const markupPrices = orderPrices.get("markupPrices").reduce((sum, price) => {
      if (!price.get("isDeleted")) {
        return sum + parseFloat(price.get("value"));
      }

      return sum;
    }, 0);

    const startMarkupPrices = startStateOrder.get("discountPrices").reduce((sum, price) => {
      if (!price.get("isDeleted")) {
        return sum + parseFloat(price.get("value"));
      }

      return sum;
    }, 0);

    if (markupPrices !== startMarkupPrices) {
      return true;
    }

    return false;
  }

  static checkPricesForAmount(state) {
    const orderPrices = state.getIn(["prices", "items"]).toJS();
    const items = additionalProducts.generateOrderDataForServer(state);
    let productPrice = false;
    let drawingPrice = false;

    if (items) {
      items.data
        .filter((item) => item.product_type !== PRODUCT_TYPE_NOTES_ITEM_LABEL)
        .map((item) => {
          if (!Number(item.total_value) > 0 && !item.custom_value && !item.is_deleted) {
            productPrice = true;
          } else if (item.custom_value && !Number(item.custom_value) > 0 && !item.is_deleted) {
            productPrice = true;
          }

          return productPrice;
        });
    }

    if (orderPrices) {
      Object.keys(orderPrices).map((item) => {
        if (
          !orderPrices[item].isDeleted &&
          (orderPrices[item].totalPrice === "0.00" || orderPrices[item].customPrice === "0.00")
        ) {
          drawingPrice = true;
        }

        return drawingPrice;
      });
    }
    return productPrice || drawingPrice;
  }

  static generateOrderDataFromServer(orderData) {
    try {
      const {
        additionalPrices,
        additionalDiscounts: discountPrices,
        additionalMarkups: markupPrices,
        subtotalValue: subTotal,
        taxValue: GST,
        totalValue: totalPrice,
        labourTotal,
        margin,
        marginPercentage,
        ...rest
      } = orderData;

      const order = {
        withPrices: true,
        ...rest,
      };

      const orderPrices = {
        labourTotal,
        additionalPrices,
        discountPrices,
        markupPrices,
        subTotal,
        margin: margin || "0.00",
        marginPercentage: marginPercentage || "0.00",
        GST,
        totalPrice,
      };

      return { order, orderPrices };
    } catch (e) {
      console.warn("Error in order::generateOrderDataFromServer()");
      console.error(e);

      throw e;
    }
  }

  createNewOrder = ({ dispatch, getState, fulfilled }) => {
    dispatch(drawingsItems.actions.createNewOrder());
    dispatch(drawings.actions.createNewOrder());
    dispatch(drawingsProducts.actions.createNewOrder());
    dispatch(prices.actions.createNewOrder());
    dispatch(additionalProducts.actions.createNewOrder());

    const state = getState();
    const user = state.getIn(["customers", "user"]);
    const billingAddress = state.getIn(["customers", "activeCustomer", "billingAddress"]);

    const newOrderState = this.initialOrdersState();
    newOrderState.activeOrder = this.initialEmptyOrder();

    newOrderState.activeOrder.createdByUser = {
      email: user.get("email"),
      firstName: user.get("firstName"),
      lastName: user.get("lastName"),
      username: user.get("username"),
    };

    newOrderState.activeOrder.billingAddress = {
      address1: billingAddress.get("address1") || "",
      address2: billingAddress.get("address2") || "",
      city: billingAddress.get("city") || "",
      state: billingAddress.get("state") || "",
      postcode: billingAddress.get("postcode") || "",
      countryCode: billingAddress.get("countryCode") || "",
    };

    newOrderState.activeOrder.createdTimestamp = date.toUtcDateTime({
      date: moment.tz(date.getCurrentUserTimezone()),
      timezone: date.getCurrentCompanyTimezone(),
    });

    newOrderState.activeOrder.requiredTimestamp = date.toUtcDateTime({
      date: moment.tz(date.getCurrentUserTimezone()),
      timezone: date.getCurrentCompanyTimezone(),
    });

    fulfilled(fromJS(newOrderState));
  };

  clearActiveOrderThunk = ({ dispatch, fulfilled }) => {
    dispatch(prices.actions.clearActiveOrder());
    dispatch(additionalProducts.actions.clearActiveOrder());
    dispatch(drawingsProducts.actions.clearActiveOrder());
    dispatch(drawingsItems.actions.clearActiveOrder());
    dispatch(drawings.actions.clearActiveOrder());

    fulfilled();
  };

  searchCustomerOrders = ({ token, getState, dispatch, fulfilled }, newFilters) => {
    dispatch(this.actions.setOrdersFilters(newFilters));

    const state = getState();
    const customerUid = state.getIn(["customers", "activeCustomer", "uid"]);
    const { page, startDate, endDate, invoiceStartDate, invoiceEndDate, ...filters } = state
      .getIn(["orders", "filters"])
      .toJS();

    if (!customerUid) return;

    return orderApi
      .searchCustomerOrders(
        token,
        customerUid,
        snakecaseKeys({
          ...filters,
          startDate: startDate ? startDate : undefined,
          endDate: endDate ? endDate : undefined,
          invoiceStartDate: invoiceStartDate ? invoiceStartDate : undefined,
          invoiceEndDate: invoiceEndDate ? invoiceEndDate : undefined,
        })
      )
      .then((response) => {
        fulfilled(response);
        return response.data;
      });
  };

  searchCompanyOrders = ({ token, getState, dispatch, fulfilled }, newFilters) => {
    dispatch(this.actions.setOrdersFilters(newFilters));

    const state = getState();
    const { page, startDate, endDate, invoiceStartDate, invoiceEndDate, ...filters } = state
      .getIn(["orders", "filters"])
      .toJS();

    return orderApi
      .searchCompanyOrders(
        token,
        snakecaseKeys({
          ...filters,
          startDate: startDate ? startDate : undefined,
          endDate: endDate ? endDate : undefined,
          invoiceStartDate: invoiceStartDate ? invoiceStartDate : undefined,
          invoiceEndDate: invoiceEndDate ? invoiceEndDate : undefined,
        })
      )
      .then((response) => {
        fulfilled(response);
        return response.data;
      });
  };

  resetChanges = ({ getState, dispatch, fulfilled }) => {
    const order = getState().getIn(["orders", "startStateOrder"]);
    fulfilled(order);

    dispatch(additionalProducts.actions.resetOrderChanges());
    dispatch(drawings.actions.resetChanges());
  };

  submitOrder = ({ token, getState, dispatch, fulfilled, rejected }, isAfterDelayed) => {
    dispatch(this.actions.clearErrors());

    const isSavingStart = getState().getIn(["orders", "saving", "start"]);

    dispatch(this.actionSetSavingDelay());

    // if (isSavingStart) {
    //   return new Promise((resolve, reject) => {
    //     reject(new Error("Set Saving Delay error"));
    //   });
    // }

    const state = getState();
    const activeCustomer = state.getIn(["customers", "activeCustomer"]).toJS();
    const activeOrder = getState().getIn(["orders", "activeOrder"]).toJS();
    const orderData = OrderModule.generateOrderDataForServer(state);

    const { orderErrors, itemsErrors, isPriceNotAmount } = orderData;
    const newOrderData = {
      ...orderData,
      update_accounting: false,
    };

    if (activeOrder?.accountingInvoiceNumber || activeOrder?.accountingQuoteNumber) {
      newOrderData.update_accounting = true;
    } else if (
      !activeOrder?.accountingInvoiceNumber &&
      !activeOrder?.accountingQuoteNumber &&
      !activeCustomer.excludeAccounting
    ) {
      newOrderData.update_accounting = true;
    }

    if (orderErrors) {
      return new Promise((resolve, reject) => {
        orderErrors.forEach((item) => {
          dispatch(
            alerts.actions.addAlert({
              type: "danger",
              message: item,
            })
          );
        });

        reject(new Error("Wrong data"));
      });
    }

    if (itemsErrors) {
      return new Promise((resolve, reject) => {
        itemsErrors.forEach((item) => {
          const numItem = Object.keys(item)[0];

          dispatch(
            alerts.actions.addAlert({
              type: "danger",
              message: `${numItem} ${item[numItem].join(" and ")}`,
            })
          );
        });

        reject(new Error("Wrong data"));
      });
    }

    if (isPriceNotAmount) {
      return new Promise((resolve, reject) => {
        reject(new Error("Not amount"));
      });
    }

    return orderApi
      .submitOrder(token, activeCustomer.uid, newOrderData)
      .then((response) => {
        const { data } = response;
        const drawingsData = {};
        let id = 0;

        data.items.forEach((item) => {
          if (!drawingsData[item.drawing]) {
            drawingsData[item.drawing] = {
              id,
              items: [],
              otherSides: item.other_sides,
            };

            id += 1;
          }

          drawingsData[item.drawing].items.push(item.item);
        });

        dispatch(drawings.actions.setUids(drawingsData));

        dispatch(
          drawingsItems.actions.setUids({
            data: drawingsData,
            drawingsState: getState().getIn(["drawings", "items"]),
          })
        );

        dispatch(
          prices.actions.setUids({
            additionalPrices: data.additional_prices,
            discountPrices: data.additional_discounts,
            markupPrices: data.additional_markups,
          })
        );

        dispatch(additionalProducts.actions.setUids(data.additional_products));
        dispatch(additionalProducts.actions.updateUidOfCustomFormula(data.additional_products));
        dispatch(drawings.actions.setDataAfterSaving());

        let invoiced = getState().getIn(["orders", "activeOrder", "invoiced"]);
        invoiced = invoiced && !orderData.order.is_changed ? invoiced : null;
        dispatch(this.actions.invoiceOrder(invoiced));

        fulfilled({
          uid: data.order,
          customer: activeCustomer.uid,
          purchaseOrder: data.purchase_order,
          createdTimestamp: data.created_timestamp,
          accountingInvoiceNumber:
            data?.accounting_invoice_number || activeOrder?.accountingInvoiceNumber || "",
          accountingQuoteNumber:
            data?.accounting_quote_number || activeOrder?.accountingQuoteNumber || "",
          accountingOrderUrl: data?.accounting_order_url || activeOrder?.accountingOrderUrl || "",
          isAfterDelayed,
        });

        return response.data;
      })
      .catch((errors) => {
        if (errors?.response?.data) {
          dispatch(
            alerts.actions.addAlert({
              type: "danger",
              message: errors?.response?.data || "Saving: Drawing is not saved - Server Error",
            })
          );
        }

        rejected(errors);
        throw errors;
      });
  };

  submitOrderWithNoAccounting = (
    { token, getState, dispatch, fulfilled, rejected },
    isAfterDelayed
  ) => {
    dispatch(this.actions.clearErrors());

    const isSavingStart = getState().getIn(["orders", "saving", "start"]);

    dispatch(this.actionSetSavingDelay());

    if (isSavingStart) {
      return new Promise((resolve, reject) => {
        reject(new Error("Set Saving Delay error"));
      });
    }

    const state = getState();
    const activeCustomer = state.getIn(["customers", "activeCustomer"]).toJS();
    const activeOrder = getState().getIn(["orders", "activeOrder"]).toJS();

    const orderData = OrderModule.generateOrderDataForServer(state);
    const { orderErrors, itemsErrors, isPriceNotAmount } = orderData;
    const newOrderData = {
      ...orderData,
      update_accounting: false,
    };

    if (orderErrors) {
      return new Promise((resolve, reject) => {
        orderErrors.forEach((item) => {
          dispatch(
            alerts.actions.addAlert({
              type: "danger",
              message: item,
            })
          );
        });

        reject(new Error("Wrong data"));
      });
    }

    if (itemsErrors) {
      return new Promise((resolve, reject) => {
        itemsErrors.forEach((item) => {
          const numItem = Object.keys(item)[0];

          dispatch(
            alerts.actions.addAlert({
              type: "danger",
              message: `${numItem} ${item[numItem].join(" and ")}`,
            })
          );
        });

        reject(new Error("Wrong data"));
      });
    }

    if (isPriceNotAmount) {
      return new Promise((resolve, reject) => {
        reject(new Error("Not amount"));
      });
    }

    return orderApi
      .submitOrder(token, activeCustomer.uid, newOrderData)
      .then((response) => {
        const { data } = response;

        const drawingsData = {};
        let id = 0;

        data.items.forEach((item) => {
          if (!drawingsData[item.drawing]) {
            drawingsData[item.drawing] = {
              id,
              items: [],
              otherSides: item.other_sides,
            };

            id += 1;
          }

          drawingsData[item.drawing].items.push(item.item);
        });

        dispatch(drawings.actions.setUids(drawingsData));

        dispatch(
          drawingsItems.actions.setUids({
            data: drawingsData,
            drawingsState: getState().getIn(["drawings", "items"]),
          })
        );

        dispatch(
          prices.actions.setUids({
            additionalPrices: data.additional_prices,
            discountPrices: data.additional_discounts,
            markupPrices: data.additional_markups,
          })
        );

        dispatch(additionalProducts.actions.setUids(data.additional_products));
        dispatch(additionalProducts.actions.updateUidOfCustomFormula(data.additional_products));
        dispatch(drawings.actions.setDataAfterSaving());

        let invoiced = getState().getIn(["orders", "activeOrder", "invoiced"]);
        invoiced = invoiced && !orderData.order.is_changed ? invoiced : null;
        dispatch(this.actions.invoiceOrder(invoiced));

        fulfilled({
          uid: data.order,
          customer: activeCustomer.uid,
          purchaseOrder: data.purchase_order,
          createdTimestamp: data.created_timestamp,
          accountingInvoiceNumber:
            data?.accounting_invoice_number || activeOrder?.accountingInvoiceNumber || "",
          accountingQuoteNumber:
            data?.accounting_quote_number || activeOrder?.accountingQuoteNumber || "",
          accountingOrderUrl: data?.accounting_order_url || activeOrder?.accountingOrderUrl || "",
          isAfterDelayed,
        });

        return response.data;
      })
      .catch((errors) => {
        if (errors?.response?.data) {
          dispatch(
            alerts.actions.addAlert({
              type: "danger",
              message: errors?.response?.data || "Saving: Drawing is not saved - Server Error",
            })
          );
        }

        rejected(errors);
        throw errors;
      });
  };

  // Only order details not including the product
  submitOrderDetails = ({ token, getState, dispatch, fulfilled, rejected }, isAfterDelayed) => {
    dispatch(this.actions.clearErrors());

    const isSavingStart = getState().getIn(["orders", "saving", "start"]);

    dispatch(this.actionSetSavingDelay());

    if (isSavingStart) {
      return new Promise((resolve, reject) => {
        reject(new Error("Set Saving Delay error"));
      });
    }

    const state = getState();
    const activeCustomer = state.getIn(["customers", "activeCustomer"]).toJS();
    const activeOrder = getState().getIn(["orders", "activeOrder"]).toJS();

    const orderData = OrderModule.generateOrderDataForServer(state);
    const { orderErrors, itemsErrors, isPriceNotAmount } = orderData;
    const newOrderData = {
      ...orderData,
      update_accounting: false,
    };

    if (activeOrder?.accountingInvoiceNumber || activeOrder?.accountingQuoteNumber) {
      newOrderData.update_accounting = true;
    } else if (
      !activeOrder?.accountingInvoiceNumber &&
      !activeOrder?.accountingQuoteNumber &&
      !activeCustomer.excludeAccounting
    ) {
      newOrderData.update_accounting = true;
    }

    if (orderErrors) {
      return new Promise((resolve, reject) => {
        orderErrors.forEach((item) => {
          dispatch(
            alerts.actions.addAlert({
              type: "danger",
              message: item,
            })
          );
        });

        reject(new Error("Wrong data"));
      });
    }

    if (itemsErrors) {
      return new Promise((resolve, reject) => {
        itemsErrors.forEach((item) => {
          const numItem = Object.keys(item)[0];

          dispatch(
            alerts.actions.addAlert({
              type: "danger",
              message: `${numItem} ${item[numItem].join(" and ")}`,
            })
          );
        });

        reject(new Error("Wrong data"));
      });
    }

    if (isPriceNotAmount) {
      return new Promise((resolve, reject) => {
        reject(new Error("Not amount"));
      });
    }

    return orderApi
      .submitOrder(token, activeCustomer.uid, newOrderData)
      .then((response) => {
        const { data } = response;

        dispatch(
          prices.actions.setUids({
            additionalPrices: data.additional_prices,
            discountPrices: data.additional_discounts,
            markupPrices: data.additional_markups,
          })
        );

        let invoiced = getState().getIn(["orders", "activeOrder", "invoiced"]);
        invoiced = invoiced && !orderData.order.is_changed ? invoiced : null;
        dispatch(this.actions.invoiceOrder(invoiced));

        fulfilled({
          uid: data.order,
          customer: activeCustomer.uid,
          purchaseOrder: data.purchase_order,
          createdTimestamp: data.created_timestamp,
          accountingInvoiceNumber:
            data?.accounting_invoice_number || activeOrder?.accountingInvoiceNumber || "",
          accountingQuoteNumber:
            data?.accounting_quote_number || activeOrder?.accountingQuoteNumber || "",
          accountingOrderUrl: data?.accounting_order_url || activeOrder?.accountingOrderUrl || "",
          isAfterDelayed,
        });

        return response.data;
      })
      .catch((errors) => {
        rejected(errors);
        throw errors;
      });
  };

  patchCompletedItems = ({ token, getState, dispatch, fulfilled, rejected }, isAfterDelayed) => {
    dispatch(this.actions.clearErrors());
    const state = getState();
    const orderData = OrderModule.generateOrderDataForServer(state);
    const items = {};
    items.complete = [];
    items.incomplete = [];

    orderData.items.map((drawingData) => {
      drawingData.item_data.is_completed
        ? items.complete.push(drawingData.item_data.uid)
        : items.incomplete.push(drawingData.item_data.uid);
    });

    orderData.additional_products.map((itemData) => {
      itemData.is_completed
        ? items.complete.push(itemData.uid)
        : items.incomplete.push(itemData.uid);
    });

    const orderUid = orderData.order.uid;
    const customerId = state.getIn(["customers", "activeCustomer", "uid"]);
    const { orderErrors, itemsErrors } = orderData;

    if (orderErrors) {
      return new Promise((resolve, reject) => {
        orderErrors.map((item) => {
          dispatch(
            alerts.actions.addAlert({
              type: "danger",
              message: item,
            })
          );
        });

        reject(new Error("Wrong data"));
      });
    }

    if (itemsErrors) {
      return new Promise((resolve, reject) => {
        itemsErrors.map((item) => {
          const numItem = Object.keys(item)[0];

          dispatch(
            alerts.actions.addAlert({
              type: "danger",
              message: `${numItem} ${item[numItem].join(" ")}`,
            })
          );
        });

        reject(new Error("Wrong data"));
      });
    }

    return orderApi
      .patchCompletedItems(token, customerId, orderUid, items)
      .then((response) => {
        const data = response.data;

        fulfilled({
          uid: data.order,
          customer: customerId,
          isAfterDelayed,
        });

        return response.data;
      })
      .catch((errors) => {
        rejected(errors);
        throw errors;
      });
  };

  patchCompletedKitItems = (
    { token, getState, dispatch, fulfilled, rejected },
    value,
    isAfterDelayed
  ) => {
    dispatch(this.actions.clearErrors());
    const state = getState();
    const orderData = OrderModule.generateOrderDataForServer(state);
    const items = {};
    items.product_kits = [];

    orderData.additional_products.map((itemData) => {
      itemData.product_type === PRODUCT_TYPE_PRODUCT_KIT_LABEL
        ? items.product_kits.push(itemData)
        : null;
    });

    const orderUid = orderData.order.uid;
    const customerId = state.getIn(["customers", "activeCustomer", "uid"]);
    const { orderErrors, itemsErrors } = orderData;

    if (orderErrors) {
      return new Promise((resolve, reject) => {
        orderErrors.map((item) => {
          dispatch(
            alerts.actions.addAlert({
              type: "danger",
              message: item,
            })
          );
        });

        reject(new Error("Wrong data"));
      });
    }

    if (itemsErrors) {
      return new Promise((resolve, reject) => {
        itemsErrors.map((item) => {
          const numItem = Object.keys(item)[0];

          dispatch(
            alerts.actions.addAlert({
              type: "danger",
              message: `${numItem} ${item[numItem].join(" ")}`,
            })
          );
        });

        reject(new Error("Wrong data"));
      });
    }

    return orderApi
      .patchCompletedKitItems(token, customerId, orderUid, value)
      .then((response) => {
        const data = response.data;

        fulfilled({
          uid: data.order,
          customer: customerId,
          isAfterDelayed,
        });

        return response.data;
      })
      .catch((errors) => {
        rejected(errors);
        throw errors;
      });
  };

  patchCompletedSubKitItems = (
    { token, getState, dispatch, fulfilled, rejected },
    value,
    isAfterDelayed
  ) => {
    dispatch(this.actions.clearErrors());
    const state = getState();
    const orderData = OrderModule.generateOrderDataForServer(state);
    const items = {};
    items.product_kits = [];

    orderData.additional_products.map((itemData) => {
      itemData.product_type === PRODUCT_TYPE_PRODUCT_KIT_LABEL
        ? items.product_kits.push(itemData)
        : null;
    });

    const orderUid = orderData.order.uid;
    const customerId = state.getIn(["customers", "activeCustomer", "uid"]);
    const { orderErrors, itemsErrors } = orderData;

    if (orderErrors) {
      return new Promise((resolve, reject) => {
        orderErrors.map((item) => {
          dispatch(
            alerts.actions.addAlert({
              type: "danger",
              message: item,
            })
          );
        });

        reject(new Error("Wrong data"));
      });
    }

    if (itemsErrors) {
      return new Promise((resolve, reject) => {
        itemsErrors.map((item) => {
          const numItem = Object.keys(item)[0];

          dispatch(
            alerts.actions.addAlert({
              type: "danger",
              message: `${numItem} ${item[numItem].join(" ")}`,
            })
          );
        });

        reject(new Error("Wrong data"));
      });
    }

    return orderApi
      .patchCompletedSubKitItems(token, customerId, orderUid, value)
      .then((response) => {
        const data = response.data;

        fulfilled({
          uid: data.order,
          customer: customerId,
          isAfterDelayed,
        });

        return response.data;
      })
      .catch((errors) => {
        rejected(errors);
        throw errors;
      });
  };

  patchCompletedSubKits = (
    { token, getState, dispatch, fulfilled, rejected },
    value,
    isAfterDelayed
  ) => {
    dispatch(this.actions.clearErrors());
    const state = getState();
    const orderData = OrderModule.generateOrderDataForServer(state);
    const items = {};
    items.product_kits = [];

    orderData.additional_products.map((itemData) => {
      itemData.product_type === PRODUCT_TYPE_PRODUCT_KIT_LABEL
        ? items.product_kits.push(itemData)
        : null;
    });

    const orderUid = orderData.order.uid;
    const customerId = state.getIn(["customers", "activeCustomer", "uid"]);
    const { orderErrors, itemsErrors } = orderData;

    if (orderErrors) {
      return new Promise((resolve, reject) => {
        orderErrors.map((item) => {
          dispatch(
            alerts.actions.addAlert({
              type: "danger",
              message: item,
            })
          );
        });

        reject(new Error("Wrong data"));
      });
    }

    if (itemsErrors) {
      return new Promise((resolve, reject) => {
        itemsErrors.map((item) => {
          const numItem = Object.keys(item)[0];

          dispatch(
            alerts.actions.addAlert({
              type: "danger",
              message: `${numItem} ${item[numItem].join(" ")}`,
            })
          );
        });

        reject(new Error("Wrong data"));
      });
    }

    return orderApi
      .patchCompletedSubKits(token, customerId, orderUid, value)
      .then((response) => {
        const data = response.data;

        fulfilled({
          uid: data.order,
          customer: customerId,
          isAfterDelayed,
        });

        return response.data;
      })
      .catch((errors) => {
        rejected(errors);
        throw errors;
      });
  };

  updateItemsIndex = ({ token, getState }) => {
    const state = getState();
    const orderData = OrderModule.generateOrderDataForServer(state);
    const orderUid = orderData.order.uid;
    const customerUid = state.getIn(["customers", "activeCustomer", "uid"]);

    const payload = {
      items: orderData.additional_products.reduce(
        (previousObject, currentObject) =>
          Object.assign(previousObject, {
            [currentObject.uid]: currentObject.index,
          }),
        {}
      ),
    };

    return orderApi.patchUpdateItemsIndex(token, customerUid, orderUid, payload);
  };

  updateSubKitsIndex = ({ token, getState }, { itemId }) => {
    const state = getState();
    const orderData = OrderModule.generateOrderDataForServer(state);
    const orderUid = orderData.order.uid;
    const customerUid = state.getIn(["customers", "activeCustomer", "uid"]);
    const item = orderData.additional_products.find((item) => item.uid === itemId);

    return orderApi.putUpdateSubKitsIndex(token, customerUid, orderUid, itemId, {
      sub_kits: item.sub_kits.map(({ uid, sub_kit_index }) => ({
        uid,
        sub_kit_index,
      })),
    });
  };

  updateSubKitItemsIndex = ({ token, getState }, { itemId, subKitId }) => {
    const state = getState();
    const orderData = OrderModule.generateOrderDataForServer(state);
    const orderUid = orderData.order.uid;
    const customerUid = state.getIn(["customers", "activeCustomer", "uid"]);
    const item = orderData.additional_products.find((item) => item.uid === itemId);
    const subKit = item.sub_kits.find(
      (sub_kit) => sub_kit.uid === subKitId || sub_kit.sub_kit_uid === subKitId
    );

    return orderApi.putUpdateSubKitItemsIndex(token, customerUid, orderUid, itemId, subKitId, {
      kit_items: subKit.kit_products.map(({ uid, cart_index }) => ({
        uid,
        cart_index,
      })),
    });
  };

  updateProductKitItemsIndex = ({ token, getState }, { itemId }) => {
    const state = getState();
    const orderData = OrderModule.generateOrderDataForServer(state);
    const orderUid = orderData.order.uid;
    const customerUid = state.getIn(["customers", "activeCustomer", "uid"]);

    return orderApi.putUpdateProductKitItemsIndex(token, customerUid, orderUid, itemId, {
      kit_items: (
        orderData.additional_products.find((item) => item.uid === itemId)?.product_kit_items || []
      ).map(({ uid, cart_index }) => ({
        uid,
        cart_index,
      })),
    });
  };

  setSavingDelay = ({ getState, fulfilled, dispatch }) => {
    let timer;
    const saving = getState().getIn(["orders", "saving"]);

    if (saving.get("start")) {
      const intervalSaving = saving.get("intervalSaving");
      let delay;

      switch (intervalSaving.get("type")) {
        case "second":
          delay = intervalSaving.get("value") * 1000;
          break;
        case "minute":
          delay = intervalSaving.get("value") * 1000 * 60;
          break;
        default:
          break;
      }

      clearTimeout(intervalSaving.get("timer"));

      timer = setTimeout(() => {
        dispatch(this.actions.submitOrder(true));
      }, delay);
    }

    fulfilled(timer);
  };

  // Remove dependeny to cards statuses, built this here in orders module
  getAllStatuses = ({ getState, fulfilled, dispatch }) =>
    dispatch(cards.actions.getAllOrdersStatuses()).then(() => {
      const statuses = getState().getIn(["cards", "workflow", "columns"]);
      fulfilled(statuses);
      return statuses.toJS();
    });

  // Remove dependeny to cards statuses, built this here in orders module
  moveWorkflowOrder = ({ token, fulfilled, dispatch }, values) => {
    const { order, endIndex, statusNew } = values;

    const orderUid = order.uid;
    return cardsApi
      .moveWorkflowOrder(token, {
        order_uid: orderUid,
        new_index: endIndex,
        new_column_uid: statusNew.uid,
      })
      .then(() => {
        dispatch(this.actions.changeExtendedStatus({ order, isReceived: null }));
      })
      .then(() => {
        fulfilled(statusNew);
      });
  };

  // Remove dependeny to cards statuses, built this here in orders module
  changeWorkflowOrder = ({ token, fulfilled }, { order, isReceived }) =>
    cardsApi
      .changeWorkflowOrder(token, snakecaseKeys({ orderUid: order.uid, isReceived }))
      .then(() => {
        fulfilled({ isReceived });
      });

  openOrderThunk = (
    { token, getState, fulfilled, dispatch },
    { order, customer, uid, isActive, includeFetchProducts = true }
  ) => {
    let orderPromise;
    const drawingsSettings = getState()
      .getIn(["customers", "company", "settings", "drawingsSettings", "drawings"])
      .toJS();

    const customPriceBends =
      drawingsSettings.find((setting) => setting.key === "CRUSH_AND_FOLD_BEND_NUMBER")?.value ?? 0;

    orderPromise = orderApi
      .getCustomerOrder(token, { customerUid: customer, orderUid: uid })
      .then((response) => camelcaseKeys(response.data, { deep: true }));

    // get company settings
    const minLengthFlashings = getMinLengthFlashings(getState());
    const minLengthProducts = getMinLengthProducts(getState());
    const companyTaxRate = getCompanyTaxRate(getState());

    // get parsed order
    let orderParsed;

    orderPromise.then((data) => {
      if (!getState().getIn(["orders", "chosenOrder"])) {
        dispatch(this.actions.chooseOrder(data));
      }

      orderParsed = OrderModule.generateOrderDataFromServer(data);
      return orderParsed;
    });

    if (includeFetchProducts) {
      // get items with additional products
      const itemsWithAdditionalProductsPromise = orderPromise
        .then(() =>
          orderItemsApi.getItemsOrderWithAdditionalProducts(token, {
            customerUid: customer,
            orderUid: uid,
          })
        )
        .then((response) =>
          additionalProducts.generateOrderDataFromServer(
            orderBy(response.data, ["index"], ["asc"]).map((r, index) => ({ ...r, index })),
            minLengthProducts
          )
        );

      // get items with drawings
      const allDrawingsOrderPromise = orderPromise
        .then(() =>
          drawingsApi.getDrawingsOrder(token, {
            customerUid: customer,
            orderUid: uid,
          })
        )
        .then((response) => response.data);

      // all data of order
      const allDataOrder = Promise.all([
        allDrawingsOrderPromise,
        itemsWithAdditionalProductsPromise,
      ])
        .then((response) => {
          const [allDrawings, additionalProductsItems] = response;
          const items = [];

          const products = allDrawings.map((drawingItem) => {
            items.push(drawingItem.item);
            const {
              product_type: productType,
              product_name: name,
              color,
              thickness,
              price_level: priceLevel,
            } = drawingItem.item;
            return {
              productType,
              name,
              color,
              thickness,
              priceLevel,
              uid: drawingItem.item.product, // set own uid for product
              drawing: drawingItem.item.drawing, // set used a drawing uid for product
            };
          });

          return {
            items,
            products,
            drawings: allDrawings,
            additionalProducts: additionalProductsItems,
          };
        })
        .then((data) => ({
          drawings: drawings.generateOrderDataFromServer(
            data.drawings,
            parseFloat(customPriceBends)
          ),
          items: drawingsItems.generateOrderDataFromServer(
            data.drawings,
            data.items,
            minLengthFlashings
          ),
          products: drawingsProducts.generateOrderDataFromServer(data.drawings, data.products),
          prices: prices.generateOrderDataFromServer(
            data.drawings,
            data.items,
            data.additionalProducts,
            orderParsed.orderPrices,
            minLengthProducts,
            companyTaxRate
          ),
          additionalProducts: data.additionalProducts,
        }));

      // open order with all data
      return allDataOrder.then((data) => {
        dispatch(drawingsItems.actions.openOrder(data.items));
        dispatch(drawingsProducts.actions.openOrder(data.products));
        dispatch(drawings.actions.openOrder(data.drawings));

        dispatch(additionalProducts.actions.openOrder(data.additionalProducts));
        dispatch(prices.actions.openOrder(data.prices));

        if (isActive) {
          if (data.drawings.items[0]) {
            dispatch(drawings.actions.setActiveDrawing(data.drawings.items[0]));
          } else {
            dispatch(drawings.actions.addNewDrawing());
          }
        }

        fulfilled(fromJS(orderParsed.order));
        return orderParsed.order;
      });
    } else {
      orderPromise.then(() => {
        fulfilled(fromJS(orderParsed.order));
        return orderParsed.order;
      });
    }
  };

  deleteOrder = ({ token, dispatch, fulfilled }, order, page) => {
    const { customer, uid, type = STATUSES_RESERVED.order.type } = order;
    const orderType = !type ? "Order" : type;

    return orderApi.deleteOrder(token, { customerUid: customer, orderUid: uid }).then(() => {
      fulfilled();

      dispatch(
        alerts.actions.addAlert({
          type: "dark",
          message: `${orderType} ${STATUSES_RESERVED[`archived${orderType}`].name.toLowerCase()}`,
          action: {
            label: "Undo",
            callback: () => {
              dispatch(this.actions.undoDeleteOrder(order)).then(() => {
                if (page === "customer") {
                  dispatch(this.actions.searchCustomerOrders());
                } else if (page === "orders") {
                  dispatch(this.actions.searchCompanyOrders());
                } else if (page === "single") {
                  dispatch(this.actions.openOrder(order));
                }
              });
            },
          },
        })
      );
    });
  };

  undoDeleteOrder = ({ token }, order) => orderApi.unArchiveOrder(token, order.customer, order.uid);

  duplicateOrder = ({ token, fulfilled, dispatch }, customerUid, orderUid) =>
    orderApi.postDuplicateOrder(token, { customerUid, orderUid }).then(() => {
      fulfilled();

      dispatch(
        alerts.actions.addAlert({
          type: "success",
          message: "Order duplicated.",
          closeDelay: 3000,
        })
      );
    });

  changeCustomer = (
    { token, fulfilled, dispatch, rejected },
    { customerUid, orderUid, newCustomerUid, orderNumber }
  ) =>
    orderApi
      .postChangeCustomer(token, { customerUid, orderUid, newCustomerUid })
      .then(() => {
        fulfilled();

        dispatch(
          alerts.actions.addAlert({
            type: "success",
            message: "Customer changed for Order #" + orderNumber,
            closeDelay: 3000,
          })
        );
      })
      .catch((errors) => {
        if (errors.response.data) {
          dispatch(
            alerts.actions.addAlert({
              type: "danger",
              message: errors.response.data || "Error changing customer",
            })
          );
        }

        rejected(errors);
        throw errors;
      });

  exportOrdersListToFile = ({ token, getState, dispatch }) => {
    const state = getState();
    const { page, startDate, endDate, invoiceStartDate, invoiceEndDate, ...filters } = state
      .getIn(["orders", "filters"])
      .toJS();

    return orderApi
      .exportOrders(
        token,
        snakecaseKeys({
          ...filters,
          startDate: startDate && moment(startDate).tz(date.getCurrentUserTimezone()).format(),
          endDate: endDate && moment(endDate).tz(date.getCurrentUserTimezone()).format(),
          invoiceStartDate:
            invoiceStartDate && moment(invoiceStartDate).tz(date.getCurrentUserTimezone()).format(),
          invoiceEndDate:
            invoiceEndDate && moment(invoiceEndDate).tz(date.getCurrentUserTimezone()).format(),
        })
      )
      .then((response) => `${ROOT_URL}${response.data}`)
      .catch((errors) => {
        dispatch(
          alerts.actions.addAlert({
            type: "danger",
            message: errors.message || "Export: Orders is not exported - Server Error",
          })
        );

        throw errors;
      });
  };

  printWorkOrderPdf = ({ dispatch, token, getState, fulfilled }, { withPrices, drawingsList }) => {
    const state = getState();
    const orderId = state.getIn(["orders", "activeOrder", "uid"]);

    const data = new FormData();
    data.set("json", JSON.stringify({ order_id: orderId, with_prices: withPrices }));

    Object.keys(drawingsList).forEach((key) => {
      const drawing = drawingsList[key];

      drawing.forEach(({ uid, xml }) => {
        const svgBlob = new Blob([xml], { type: "image/svg+xml" });
        const file = new File([svgBlob], uid, {
          type: "image/svg+xml",
          lastModified: Date.now(),
        });
        data.set(uid, file);
      });
    });

    return orderApi
      .postPrintWorkOrderPdf(token, data)
      .then((response) => {
        fulfilled();
        return `${ROOT_URL}${response.data}`;
      })
      .catch((errors) => {
        const errorsMessage = errors.response ? errors.response.data.detail.errors : errors.message;

        if (Array.isArray(errorsMessage)) {
          errorsMessage.forEach((error) => {
            dispatch(
              alerts.actions.addAlert({
                type: "danger",
                message: error || "Print generation error",
              })
            );
          });
        }

        throw errors;
      });
  };

  printDeliveryDocketPdf = (
    { dispatch, token, getState, fulfilled },
    { withPrices, drawingsList }
  ) => {
    const state = getState();
    const orderId = state.getIn(["orders", "activeOrder", "uid"]);

    const data = new FormData();
    data.set("json", JSON.stringify({ order_id: orderId, with_prices: withPrices }));

    Object.keys(drawingsList).forEach((key) => {
      const drawing = drawingsList[key];

      drawing.forEach(({ uid, xml }) => {
        const svgBlob = new Blob([xml], { type: "image/svg+xml" });
        const file = new File([svgBlob], uid, {
          type: "image/svg+xml",
          lastModified: Date.now(),
        });
        data.set(uid, file);
      });
    });

    return orderApi
      .postPrintDeliveryDocketPdf(token, data)
      .then((response) => {
        fulfilled();
        return `${ROOT_URL}${response.data}`;
      })
      .catch((errors) => {
        const errorsMessage = errors.response
          ? errors.response.data.detail.errors || errors.response.data.detail.myob_errors
          : errors.message;

        if (Array.isArray(errorsMessage)) {
          errorsMessage.forEach((error) => {
            dispatch(
              alerts.actions.addAlert({
                type: "danger",
                message: error || "Print generation error",
              })
            );
          });
        }

        throw errors;
      });
  };

  deleteCheckedOrders = ({ token, dispatch, fulfilled }, payload) => {
    const { page, checkedOrders } = payload;

    return this.deleteCheckedOrder(token, dispatch, fulfilled, page, checkedOrders, 0);
  };

  deleteCheckedOrder = (token, dispatch, fulfilled, page, checkedOrders, currentIndex) => {
    if (currentIndex === checkedOrders.length) {
      fulfilled();

      dispatch(
        alerts.actions.addAlert({
          type: "dark",
          message: `You have successfully archived ${currentIndex} orders.`,
          action: {
            label: "Undo",
            callback: () => {
              dispatch(this.actions.undoDeleteCheckedOrders(checkedOrders)).then(() => {
                if (page === "customer") {
                  dispatch(this.actions.searchCustomerOrders());
                }

                if (page === "orders") {
                  dispatch(this.actions.searchCompanyOrders());
                }
              });
            },
          },
        })
      );

      return new Promise((resolve) => {
        resolve();
      });
    }

    const { customer, uid } = checkedOrders[currentIndex];

    return orderApi
      .deleteOrder(token, { customerUid: customer, orderUid: uid })
      .then(() =>
        this.deleteCheckedOrder(token, dispatch, fulfilled, page, checkedOrders, currentIndex + 1)
      );
  };

  undoDeleteCheckedOrders = ({ token, dispatch, fulfilled }, checkedOrders) =>
    this.undoDeleteCheckedOrder(token, dispatch, fulfilled, checkedOrders, 0);

  undoDeleteCheckedOrder = (token, dispatch, fulfilled, checkedOrders, currentIndex) => {
    if (currentIndex === checkedOrders.length) {
      fulfilled();

      dispatch(
        alerts.actions.addAlert({
          type: "dark",
          message: `You have successfully restored ${currentIndex} orders.`,
        })
      );

      return () => {};
    }

    const { customer, uid } = checkedOrders[currentIndex];
    return orderApi
      .unArchiveOrder(token, customer, uid, checkedOrders[currentIndex])
      .then(() =>
        this.undoDeleteCheckedOrder(token, dispatch, fulfilled, checkedOrders, currentIndex + 1)
      );
  };

  statusChangeCheckedOrders = ({ token, dispatch, fulfilled }, payload) => {
    const { status, checkedOrders } = payload;

    // Remove quotes and drafts
    const filteredCheckedOrders = checkedOrders.filter(
      (order) => order.isSubmitted && order.type === "Order"
    );

    return this.statusChangeCheckedOrder(
      token,
      dispatch,
      fulfilled,
      status,
      filteredCheckedOrders,
      0
    );
  };

  statusChangeCheckedOrder = (token, dispatch, fulfilled, status, checkedOrders, currentIndex) => {
    if (currentIndex === checkedOrders.length) {
      fulfilled();

      dispatch(
        alerts.actions.addAlert({
          type: "dark",
          message: `You have successfully changed the status on ${currentIndex} orders.`,
        })
      );

      return new Promise((resolve) => {
        resolve();
      });
    }

    const order = checkedOrders[currentIndex];
    const endIndex = 0; // why is this hardcoded at 0 - investigate later

    if (status.type === "Received") {
      return cardsApi
        .changeWorkflowOrder(token, snakecaseKeys({ orderUid: order.uid, isReceived: status.name }))
        .then(() =>
          this.statusChangeCheckedOrder(
            token,
            dispatch,
            fulfilled,
            status,
            checkedOrders,
            currentIndex + 1
          )
        );
    }

    return cardsApi
      .moveWorkflowOrder(token, {
        order_uid: order.uid,
        new_index: endIndex,
        new_column_uid: status.uid,
      })
      .then(() =>
        cardsApi.changeWorkflowOrder(
          token,
          snakecaseKeys({ orderUid: order.uid, isReceived: null })
        )
      )
      .then(() =>
        this.statusChangeCheckedOrder(
          token,
          dispatch,
          fulfilled,
          status,
          checkedOrders,
          currentIndex + 1
        )
      );
  };

  // eslint-disable-next-line no-unused-vars
  loadOrderViewThunk = ({ fulfilled, dispatch }, data) =>
    orderApi
      .loadOrderView(data)
      .then(({ data: res }) => {
        const order = camelcaseKeys(res);
        const { companyData, customerData, drawingsData, orderData, addProductsData } = order;

        const orderParsed = OrderModule.generateOrderDataFromServer(
          camelcaseKeys(orderData, { deep: true })
        );
        const itemsData = [];

        const products = drawingsData.map((drawingItem) => {
          itemsData.push(drawingItem.item);
          const {
            product_type: productType,
            product_name: name,
            color,
            thickness,
            price_level: priceLevel,
          } = drawingItem.item;
          return {
            productType,
            name,
            color,
            thickness,
            priceLevel,
            uid: drawingItem.item.product, // set own uid for product
            drawing: drawingItem.item.drawing, // set used a drawing uid for product
          };
        });

        dispatch(customers.actions.setActiveCustomer(camelcaseKeys(customerData, { deep: true })));
        dispatch(customers.actions.setCompanyData(camelcaseKeys(companyData, { deep: true })));

        dispatch(
          prices.actions.openOrder(
            prices.generateOrderDataFromServer(
              drawingsData,
              itemsData,
              additionalProducts.generateOrderDataFromServer(
                camelcaseKeys(addProductsData, { deep: true })
              ),
              orderParsed.orderPrices
            )
          )
        );

        dispatch(
          drawingsItems.actions.openOrder(
            drawingsItems.generateOrderDataFromServer(drawingsData, itemsData, 0)
          )
        );

        dispatch(
          drawingsProducts.actions.openOrder(
            drawingsProducts.generateOrderDataFromServer(drawingsData, products)
          )
        );

        dispatch(drawings.actions.openOrder(drawings.generateOrderDataFromServer(drawingsData)));

        dispatch(
          additionalProducts.actions.openOrder(
            additionalProducts.generateOrderDataFromServer(
              camelcaseKeys(addProductsData, { deep: true })
            )
          )
        );

        fulfilled(fromJS({ ...orderParsed.order, withPrices: order.withPrices }));
        return Promise.resolve(order.withPrices);
      })
      .catch((errors) => Promise.reject(errors));

  fetchUsers = ({ token, fulfilled }) =>
    customersApi
      .searchCompanyUsers(token, { with_inactive: false, include_invites: false })
      .then((response) => {
        const { data } = response;
        fulfilled(fromJS(camelcaseKeys(data, { deep: true })));
        return data;
      });

  assignUsers = ({ token, getState }) => {
    const { activeOrder, removeAssignees } = getState().getIn(["orders"]).toJS();

    if (activeOrder.assignees.length === 0 && removeAssignees.length === 0) return;

    return orderApi.postAssignUser(token, {
      users_assigned: activeOrder.assignees.map((a) => a.id),
      users_not_assigned: removeAssignees,
      order: activeOrder.uid,
    });
  };

  toggleLabels = ({ fulfilled, getState }, uids = []) => {
    const state = getState();
    const { labels } = state.getIn(["labels"]);

    const newLabels = labels.filter((l) => uids.includes(l.uid));

    fulfilled(fromJS(newLabels));
  };

  defineActions() {
    const setOrdersFilters = this.createAction(ACTIONS.SET_ORDERS_FILTERS);
    const resetOrdersFilters = this.createAction(ACTIONS.RESET_ORDERS_FILTERS);
    const setOrdersSorting = this.createAction(ACTIONS.SET_ORDERS_SORTING);
    const resetOrdersSorting = this.createAction(ACTIONS.RESET_ORDERS_SORTING);

    const searchCustomerOrders = this.thunkAction(
      ACTIONS.SEARCH_CUSTOMER_ORDERS,
      this.searchCustomerOrders,
      true
    );

    const clearCustomerOrders = this.createAction(ACTIONS.CLEAR_CUSTOMER_ORDERS);

    const searchCompanyOrders = this.thunkAction(
      ACTIONS.SEARCH_COMPANY_ORDERS,
      this.searchCompanyOrders,
      true
    );
    const setBillingAddress = this.mergeIn(ACTIONS.SET_BILLING_ADDRESS, [
      "activeOrder",
      "billingAddress",
    ]);
    const setShippingAddress = this.mergeIn(ACTIONS.SET_SHIPPING_ADDRESS, [
      "activeOrder",
      "shippingAddress",
    ]);
    const setContact = this.mergeIn(ACTIONS.SET_CONTACT, ["activeOrder", "contact"]);
    const setRequiredDate = this.setIn(ACTIONS.SET_REQUIRED_DATE, [
      "activeOrder",
      "requiredTimestamp",
    ]);
    const setMethodObtaining = this.setIn(ACTIONS.SET_METHOD_OBTAINING, [
      "activeOrder",
      "obtainingMethod",
    ]);
    const setPickupNotes = this.setIn(ACTIONS.SET_PICKUP_NOTES, ["activeOrder", "pickupNotes"]);
    const setNotes = this.setIn(ACTIONS.SET_NOTES, ["activeOrder", "notes"]);
    const setReference = this.setIn(ACTIONS.SET_REFERENCE, ["activeOrder", "reference"]);

    const getAllStatuses = this.thunkAction(ACTIONS.GET_ALL_STATUSES, this.getAllStatuses, true);
    const changeStatus = this.thunkAction(ACTIONS.CHANGE_STATUS, this.moveWorkflowOrder, true);
    const changeExtendedStatus = this.thunkAction(
      ACTIONS.CHANGE_EXTENDED_STATUS,
      this.changeWorkflowOrder,
      true
    );

    const toggleSubmitted = this.toggleIn(ACTIONS.TOGGLE_SUBMITTED, ["activeOrder", "isSubmitted"]);

    const toggleSavingMode = this.toggleIn(ACTIONS.TOGGLE_SAVING_MODE, ["saving", "auto"]);
    const setType = this.setIn(ACTIONS.SET_ORDER_TYPE, ["activeOrder", "type"]);
    const setStatusString = this.setIn(ACTIONS.SET_COMBINED_STATUS_STRING, [
      "activeOrder",
      "combinedStatusString",
    ]);
    const toggleSaveNotAmount = this.toggleIn(ACTIONS.TOGGLE_SAVE_NOT_AMOUNT, [
      "saving",
      "saveForNotAmount",
    ]);

    const clearActiveOrder = this.thunkAction(
      ACTIONS.CLEAR_ACTIVE_ORDER,
      this.clearActiveOrderThunk
    );
    const createNewOrder = this.thunkAction(ACTIONS.CREATE_NEW_ORDER, this.createNewOrder);
    const resetChanges = this.thunkAction(ACTIONS.RESET_CHANGES, this.resetChanges);
    const submitOrder = this.thunkAction(ACTIONS.SUBMIT_ORDER, this.submitOrder, true);
    const submitOrderWithNoAccounting = this.thunkAction(
      ACTIONS.SUBMIT_ORDER,
      this.submitOrderWithNoAccounting,
      true
    );
    const submitOrderDetails = this.thunkAction(
      ACTIONS.SUBMIT_ORDER,
      this.submitOrderDetails,
      true
    );
    const patchCompletedItems = this.thunkAction(
      ACTIONS.PATCH_COMPLETED_ITEMS,
      this.patchCompletedItems,
      true
    );
    const patchCompletedKitItems = this.thunkAction(
      ACTIONS.PATCH_COMPLETED_KIT_ITEMS,
      this.patchCompletedKitItems,
      true
    );
    const patchCompletedSubKitItems = this.thunkAction(
      ACTIONS.PATCH_COMPLETED_SUB_KIT_ITEMS,
      this.patchCompletedSubKitItems,
      true
    );
    const patchCompletedSubKits = this.thunkAction(
      ACTIONS.PATCH_COMPLETED_SUB_KITS,
      this.patchCompletedSubKits,
      true
    );
    const updateItemsIndex = this.thunkAction(ACTIONS.UPDATE_ITEMS_INDEX, this.updateItemsIndex);
    const updateSubKitsIndex = this.thunkAction(
      ACTIONS.UPDATE_SUB_KITS_INDEX,
      this.updateSubKitsIndex
    );
    const updateSubKitItemsIndex = this.thunkAction(
      ACTIONS.UPDATE_SUB_KIT_ITEMS_INDEX,
      this.updateSubKitItemsIndex
    );
    const updateProductKitItemsIndex = this.thunkAction(
      ACTIONS.UPDATE_PRODUCT_KIT_ITEMS_INDEX,
      this.updateProductKitItemsIndex
    );
    this.actionSetSavingDelay = this.thunkAction(ACTIONS.SET_SAVING_DELAY, this.setSavingDelay);
    const chooseOrder = this.set(ACTIONS.CHOOSE_ORDER, "chosenOrder");
    const openOrder = this.thunkAction(ACTIONS.OPEN_ORDER, this.openOrderThunk, true);
    const deleteOrder = this.thunkAction(ACTIONS.DELETE_ORDER, this.deleteOrder, true);
    const undoDeleteOrder = this.thunkAction(ACTIONS.UNDO_DELETE_ORDER, this.undoDeleteOrder, true);
    const duplicateOrder = this.thunkAction(ACTIONS.DUPLICATE_ORDER, this.duplicateOrder, true);
    const changeCustomer = this.thunkAction(ACTIONS.CHANGE_CUSTOMER, this.changeCustomer, true);
    const exportOrdersListToFile = this.thunkAction(
      ACTIONS.EXPORT_ORDERS,
      this.exportOrdersListToFile,
      true
    );
    const printWorkOrderPdf = this.thunkAction(
      ACTIONS.GET_WORK_ORDER_PDF,
      this.printWorkOrderPdf,
      true
    );
    const printDeliveryDocketPdf = this.thunkAction(
      ACTIONS.GET_DELIVERY_DOCKET_PDF,
      this.printDeliveryDocketPdf,
      true
    );
    const loadOrderView = this.thunkAction(ACTIONS.LOAD_ORDER_VIEW, this.loadOrderViewThunk, true);
    const invoiceOrder = this.createAction(ACTIONS.INVOICE_ORDER);
    const setInvoiceOrderDate = this.createAction(ACTIONS.SET_INVOICE_ORDER_DATE);
    const setInvoiceQuotedDate = this.createAction(ACTIONS.SET_INVOICE_QUOTED_DATE);

    const checkOrder = this.createAction(ACTIONS.CHECK_ORDER, (orderIndex) => orderIndex);
    const uncheckOrder = this.createAction(ACTIONS.UNCHECK_ORDER, (orderIndex) => orderIndex);
    const checkPageOrders = this.createAction(ACTIONS.CHECK_PAGE_ORDERS, (item) => item);
    const deleteCheckedOrders = this.thunkAction(
      ACTIONS.DELETE_CHECKED_ORDERS,
      this.deleteCheckedOrders,
      true
    );
    const undoDeleteCheckedOrders = this.thunkAction(
      ACTIONS.UNDO_DELETE_CHECKED_ORDERS,
      this.undoDeleteCheckedOrders,
      true
    );
    const statusChangeCheckedOrders = this.thunkAction(
      ACTIONS.STATUS_CHANGE_CHECKED_ORDERS,
      this.statusChangeCheckedOrders,
      true
    );
    const fetchUsers = this.thunkAction(ACTIONS.FETCH_USERS, this.fetchUsers, true);

    const toggleAssignee = this.createAction(ACTIONS.TOGGLE_ASSIGNEE, (data) => data);

    const toggleLabels = this.thunkAction(ACTIONS.TOGGLE_LABELS, this.toggleLabels, true);

    const setOrder = this.createAction(ACTIONS.SET_ORDER, (data) => data);

    const assignUsers = this.thunkAction(ACTIONS.ASSIGN_USER, this.assignUsers, true);
    const clearCheckedOrders = this.createAction(ACTIONS.CLEAR_CHECKED_ORDERS);

    const setProductModalType = this.createAction(ACTIONS.SET_PRODUCT_MODAL_TYPE);

    const clearErrors = this.reset(ACTIONS.CLEAR_ERROR, "errors", {});

    return {
      setOrdersFilters,
      resetOrdersFilters,
      setOrdersSorting,
      resetOrdersSorting,
      searchCustomerOrders,
      clearCustomerOrders,
      searchCompanyOrders,
      setBillingAddress,
      setShippingAddress,
      setContact,
      setRequiredDate,
      setMethodObtaining,
      setPickupNotes,
      setNotes,
      setReference,

      getAllStatuses,
      changeStatus,
      changeExtendedStatus,

      toggleSubmitted,
      toggleSavingMode,
      toggleSaveNotAmount,
      setType,
      setStatusString,

      clearActiveOrder,
      createNewOrder,
      resetChanges,
      submitOrder,
      submitOrderWithNoAccounting,
      submitOrderDetails,
      patchCompletedItems,
      patchCompletedKitItems,
      patchCompletedSubKitItems,
      patchCompletedSubKits,
      updateItemsIndex,
      updateSubKitsIndex,
      updateSubKitItemsIndex,
      updateProductKitItemsIndex,
      chooseOrder,
      openOrder,
      setOrder,
      deleteOrder,
      undoDeleteOrder,
      duplicateOrder,
      changeCustomer,
      exportOrdersListToFile,

      printWorkOrderPdf,
      printDeliveryDocketPdf,
      loadOrderView,
      invoiceOrder,
      setInvoiceOrderDate,
      setInvoiceQuotedDate,

      checkOrder,
      uncheckOrder,
      checkPageOrders,
      deleteCheckedOrders,
      undoDeleteCheckedOrders,
      statusChangeCheckedOrders,
      fetchUsers,
      assignUsers,
      toggleAssignee,
      toggleLabels,
      clearCheckedOrders,
      setProductModalType,
      clearErrors,
    };
  }

  toggleAssignee = (_state, payload) => {
    const removeAssignees = _state.getIn(["removeAssignees"]);
    const chosenOrder = _state.getIn(["chosenOrder"]);
    const { assignees } = _state.getIn(["activeOrder"]).toJS();
    const assigneesIds = assignees.map((a) => a.id);
    const users = _state.getIn(["users"]).toJS();

    if (assigneesIds.includes(payload)) {
      if (!removeAssignees.includes(payload)) {
        _state.setIn(["removeAssignees"], fromJS([...removeAssignees, payload]));
      }
    }

    if (!assigneesIds.includes(payload)) {
      if (removeAssignees.includes(payload)) {
        _state.setIn(["removeAssignees"], fromJS(removeAssignees.filter((a) => a !== payload)));
      }
    }

    const newAssignees = assigneesIds.includes(payload)
      ? assignees.filter((a) => a.id !== payload)
      : [...assignees, ...[users.find((u) => u.id === payload)]];

    _state.setIn(["activeOrder", "assignees"], fromJS(newAssignees));

    if (chosenOrder) {
      _state.setIn(["chosenOrder", "assignees"], fromJS(newAssignees));
    }
  };

  setLabels = (_state, payload) => {
    const chosenOrder = _state.getIn(["chosenOrder"]);
    _state.setIn(["activeOrder", "labels"], payload);

    if (chosenOrder) {
      _state.setIn(["chosenOrder", "labels"], payload);
    }
  };

  setOrder = (_state, payload) => {
    _state.set("activeOrder", fromJS(payload));
    _state.set("startStateOrder", fromJS(payload));
  };

  openOrder = (_state, payload) => {
    _state.set("activeOrder", payload);
    _state.set("startStateOrder", payload);
  };

  setDataAfterSaving = (_state, { isAfterDelayed, ...rest }) => {
    _state.mergeIn(["activeOrder"], fromJS({ ...rest }));
    _state.setIn(["saving", "start"], null);

    if (isAfterDelayed) {
      _state.setIn(["saving", "intervalSaving", "timer"], null);
    }
  };

  setItemDataAfterSaving = (_state, { isAfterDelayed, ...rest }) => {
    _state.setIn(["saving", "start"], null);

    if (isAfterDelayed) {
      _state.setIn(["saving", "intervalSaving", "timer"], null);
    }
  };

  setDataSavingWithError = (_state, errors) => {
    _state.setIn(["saving", "start"], null);
    _state.setIn(["saving", "intervalSaving", "timer"], null);
    _state.set("errors", errors);
  };

  changeStatus = (_state, { name, settings: { colour } }) => {
    _state.setIn(["activeOrder", "status"], fromJS({ name, colour }));
    _state.setIn(["activeOrder", "combinedStatusString"], name);
    _state.setIn(["activeOrder", "isReceived"], null);
  };

  searchOrders = (state, { payload: { data } }) => {
    const camelcaseData = data.results.map((item) =>
      camelcaseKeys(
        {
          ...item,
        },
        { deep: true }
      )
    );

    const paginatedData = {
      ...data,
      results: camelcaseData,
    };

    return state.setIn(["dataServer", "items"], fromJS(paginatedData));
  };

  clearOrders = (state) => {
    const data = {
      isPending: false,
      isDuplicating: false,
      items: [],
    };

    return state.setIn(["dataServer"], data);
  };

  defineReducers() {
    return {
      [ACTIONS.SET_ORDERS_FILTERS]: this.mergeInReducer(["filters"]),
      [ACTIONS.RESET_ORDERS_FILTERS]: (state) =>
        state.setIn(["filters"], fromJS({ limit: 10, page: 0, offset: 0 })),

      [ACTIONS.SET_ORDERS_SORTING]: this.mergeInReducer(["sorting"]),
      [ACTIONS.RESET_ORDERS_SORTING]: (state) =>
        state.setIn(["sorting"], fromJS({ sortField: "", sortDirection: "" })),

      [`${ACTIONS.SEARCH_CUSTOMER_ORDERS} fulfilled`]: this.searchOrders,

      [`${ACTIONS.SEARCH_CUSTOMER_ORDERS} pending`]: this.thunkPendingReducer([
        "dataServer",
        "isPending",
      ]),

      [ACTIONS.CLEAR_CUSTOMER_ORDERS]: this.clearOrders,

      [`${ACTIONS.SEARCH_COMPANY_ORDERS} fulfilled`]: this.searchOrders,

      [`${ACTIONS.SEARCH_COMPANY_ORDERS} pending`]: this.thunkPendingReducer([
        "dataServer",
        "isPending",
      ]),

      [`${ACTIONS.CLEAR_ACTIVE_ORDER} fulfilled`]: (state) => {
        const searchFilter = state.get("filters");
        const purifiedStateWithFilters = fromJS(this.getInitialState());
        return purifiedStateWithFilters.set("filters", searchFilter);
      },

      [`${ACTIONS.CREATE_NEW_ORDER} fulfilled`]: this.mergeReducer(),

      [`${ACTIONS.SET_SAVING_DELAY} fulfilled`]: (state, { payload }) => {
        if (!payload) {
          return state.setIn(["saving", "start"], moment.tz.guess());
        }

        return state.setIn(["saving", "intervalSaving", "timer"], payload);
      },

      [`${ACTIONS.RESET_CHANGES} fulfilled`]: this.setReducer("activeOrder"),

      [`${ACTIONS.GET_ALL_STATUSES} fulfilled`]: (state, { payload: statuses }) => {
        const extendedStatuses = statuses.update((list) =>
          list.concat(
            fromJS([
              { ...STATUSES_RESERVED.Delivered, uid: genId() },
              { ...STATUSES_RESERVED["Picked Up"], uid: genId() },
            ])
          )
        );
        return state.setIn(["statuses", "items"], extendedStatuses);
      },

      [`${ACTIONS.GET_ALL_STATUSES} pending`]: this.thunkPendingReducer(["statuses", "isPending"]),

      [`${ACTIONS.GET_ALL_STATUSES} rejected`]: withMutations(this.setDataSavingWithError),

      [`${ACTIONS.CHANGE_STATUS} fulfilled`]: withMutations(this.changeStatus),

      [`${ACTIONS.CHANGE_STATUS} pending`]: this.thunkPendingReducer(["saving", "isPending"]),

      [`${ACTIONS.CHANGE_STATUS} rejected`]: withMutations(this.setDataSavingWithError),

      [`${ACTIONS.CHANGE_EXTENDED_STATUS} pending`]: this.thunkPendingReducer([
        "saving",
        "isPending",
      ]),

      [`${ACTIONS.CHANGE_EXTENDED_STATUS} fulfilled`]: (state, { payload: options }) =>
        state.mergeIn(["activeOrder"], fromJS(options)),

      [`${ACTIONS.CHANGE_EXTENDED_STATUS} rejected`]: withMutations(this.setDataSavingWithError),

      [`${ACTIONS.SUBMIT_ORDER} fulfilled`]: withMutations(this.setDataAfterSaving),

      [`${ACTIONS.SUBMIT_ORDER} pending`]: this.thunkPendingReducer(["saving", "isPending"]),

      [`${ACTIONS.SUBMIT_ORDER} rejected`]: withMutations(this.setDataSavingWithError),

      [`${ACTIONS.PATCH_COMPLETED_ITEMS} fulfilled`]: withMutations(this.setItemDataAfterSaving),

      [`${ACTIONS.PATCH_COMPLETED_ITEMS} pending`]: this.thunkPendingReducer([
        "saving",
        "isPending",
      ]),

      [`${ACTIONS.PATCH_COMPLETED_ITEMS} rejected`]: withMutations(this.setDataSavingWithError),

      [`${ACTIONS.PATCH_COMPLETED_KIT_ITEMS} fulfilled`]: withMutations(
        this.setItemDataAfterSaving
      ),

      [`${ACTIONS.PATCH_COMPLETED_KIT_ITEMS} pending`]: this.thunkPendingReducer([
        "saving",
        "isPending",
      ]),

      [`${ACTIONS.PATCH_COMPLETED_KIT_ITEMS} rejected`]: withMutations(this.setDataSavingWithError),

      [`${ACTIONS.PATCH_COMPLETED_SUB_KIT_ITEMS} fulfilled`]: withMutations(
        this.setItemDataAfterSaving
      ),

      [`${ACTIONS.PATCH_COMPLETED_SUB_KIT_ITEMS} pending`]: this.thunkPendingReducer([
        "saving",
        "isPending",
      ]),

      [`${ACTIONS.PATCH_COMPLETED_SUB_KIT_ITEMS} rejected`]: withMutations(
        this.setDataSavingWithError
      ),

      [`${ACTIONS.PATCH_COMPLETED_SUB_KITS} fulfilled`]: withMutations(this.setItemDataAfterSaving),

      [`${ACTIONS.PATCH_COMPLETED_SUB_KITS} pending`]: this.thunkPendingReducer([
        "saving",
        "isPending",
      ]),

      [`${ACTIONS.PATCH_COMPLETED_SUB_KITS} rejected`]: withMutations(this.setDataSavingWithError),

      [`${ACTIONS.OPEN_ORDER} fulfilled`]: withMutations(this.openOrder),

      [`${ACTIONS.OPEN_ORDER} pending`]: this.thunkPendingReducer("isPending"),

      [`${ACTIONS.OPEN_ORDER} rejected`]: this.setReducer("errors"),

      [`${ACTIONS.DELETE_ORDER} fulfilled`]: (state) => state,

      [`${ACTIONS.DELETE_ORDER} pending`]: this.thunkPendingReducer(["dataServer", "isPending"]),

      [`${ACTIONS.DELETE_ORDER} rejected`]: this.setReducer("errors"),

      [`${ACTIONS.LOAD_ORDER_VIEW} fulfilled`]: withMutations(this.openOrder),

      [`${ACTIONS.LOAD_ORDER_VIEW} pending`]: this.thunkPendingReducer("isPending"),

      [`${ACTIONS.GET_WORK_ORDER_PDF} fulfilled`]: (state) => state,

      [`${ACTIONS.GET_WORK_ORDER_PDF} pending`]: this.thunkPendingReducer("isPending"),

      [`${ACTIONS.GET_WORK_ORDER_PDF} rejected`]: this.setReducer("errors"),

      [ACTIONS.INVOICE_ORDER]: (state, { payload: invoiced }) => {
        if (invoiced) {
          return state.setIn(["activeOrder", "invoiced"], invoiced);
        }

        return state.setIn(["activeOrder", "invoiced"], null);
      },

      [ACTIONS.SET_INVOICE_ORDER_DATE]: (state, { payload: invoicedDate }) =>
        state.setIn(["activeOrder", "invoicedDate"], invoicedDate),

      [ACTIONS.SET_INVOICE_QUOTED_DATE]: (state, { payload: quotedDate }) =>
        state.setIn(["activeOrder", "quotedDate"], quotedDate),

      [ACTIONS.CHECK_ORDER]: (state, { payload: orderIndex }) => {
        const checkedOrders = state.get("checkedOrders");
        const newOrders = [...checkedOrders, orderIndex];

        return state.set("checkedOrders", newOrders);
      },

      [ACTIONS.UNCHECK_ORDER]: (state, { payload: orderIndex }) => {
        const checkedOrders = state.get("checkedOrders");
        const filteredOrders = checkedOrders.filter(
          (checkedOrderIndex) => orderIndex !== checkedOrderIndex
        );

        return state.set("checkedOrders", filteredOrders);
      },

      [ACTIONS.CHECK_PAGE_ORDERS]: (state, { payload: { page, pageSize } }) => {
        const checkedOrders = state.get("checkedOrders");

        // Caculate indexes that need to be checked
        const pageOrders = [];

        for (let i = page; i < page * pageSize + pageSize; i++) {
          pageOrders.push(i);
        }

        const uncheckedOrdersOnPage = pageOrders.filter(
          (orderIndex) => !checkedOrders.includes(orderIndex)
        );

        // If there are unchecked orders on the page - check the unchecked orders on the page
        if (uncheckedOrdersOnPage.length > 0) {
          const newCheckedOrders = checkedOrders.concat(uncheckedOrdersOnPage);

          return state.set("checkedOrders", newCheckedOrders);
        }

        // If there are no unchecked orders on the page - uncheck all orders on the page
        const newCheckedOrders = checkedOrders.filter(
          (checkedOrderIndex) => !pageOrders.includes(checkedOrderIndex)
        );

        return state.set("checkedOrders", newCheckedOrders);
      },

      [`${ACTIONS.DELETE_CHECKED_ORDERS} fulfilled`]: (state) => state,

      [`${ACTIONS.DELETE_CHECKED_ORDERS} pending`]: this.thunkPendingReducer([
        "dataServer",
        "isPending",
      ]),

      [`${ACTIONS.DELETE_CHECKED_ORDERS} rejected`]: this.setReducer("errors"),

      [`${ACTIONS.STATUS_CHANGE_CHECKED_ORDERS} fulfilled`]: (state) => state,

      [`${ACTIONS.STATUS_CHANGE_CHECKED_ORDERS} pending`]: this.thunkPendingReducer([
        "dataServer",
        "isPending",
      ]),

      [`${ACTIONS.STATUS_CHANGE_CHECKED_ORDERS} rejected`]: this.setReducer("errors"),

      [`${ACTIONS.DUPLICATE_ORDER} pending`]: this.thunkPendingReducer([
        "dataServer",
        "isDuplicating",
      ]),
      [`${ACTIONS.CHANGE_CUSTOMER} pending`]: this.thunkPendingReducer([
        "dataServer",
        "isChangingCustomer",
      ]),
      [`${ACTIONS.FETCH_USERS} fulfilled`]: this.setInReducer(["users"]),
      [ACTIONS.TOGGLE_ASSIGNEE]: withMutations(this.toggleAssignee),
      [`${ACTIONS.TOGGLE_LABELS} fulfilled`]: withMutations(this.setLabels),
      [ACTIONS.SET_ORDER]: withMutations(this.setOrder),
      [ACTIONS.CLEAR_CHECKED_ORDERS]: (state) => state.set("checkedOrders", []),
      [ACTIONS.SET_PRODUCT_MODAL_TYPE]: (state, { payload: type }) =>
        state.setIn(["productModalType"], type),
    };
  }
}

const instance = new OrderModule();
instance.init();

export default instance;
