import axios from "axios";

import history from "@/app/history";

import { API_URL } from "../env";

const onTrialEndedError = ({ status, errorCode }) => {
  if (status === 403 && errorCode === "trial_ended") {
    history.push("/trial-ended");
  }
};

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    onTrialEndedError({
      status: error?.response?.data?.status_code,
      errorCode: error?.response?.data?.code,
    });

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(`%c AJAX response error: ${error.response.status}`, "color: red");

      // check if `errors` property exists in the response data and update the error object accordingly
      if (error.response.data.detail && error.response.data.detail.errors) {
        error.response.data = error.response.data.detail.errors[0];
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log("%c AJAX request error:", "color: red");
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(`%c AJAX unknown error: ${error.message}`, "color: red");
    }

    console.log(error.config);

    const resp = error.response;

    if (resp) {
      error.customErrorMsg =
        resp.data && resp.data.error
          ? `Server error: ${resp.data.error}`
          : resp.statusText || "Unknown error";

      console.log(`%c Custom error msg = ${error.customErrorMsg}`, "color: orange");
    }

    return Promise.reject(error);
  }
);

export const createApi =
  (
    httpMethod,
    url,
    {
      headers = null,
      responseType = null,
      isUrlData = false,
      inputData = (payload) => payload,
      outputData = (response) => response,
    } = {}
  ) =>
  (auth, payload) => {
    const axiosOptions = {
      method: httpMethod,
      url: API_URL + url,
      responseType: responseType || "json",
      headers: headers || {
        Accept: "application/json",
      },
      transformResponse: [outputData],
    };

    if (auth) {
      axiosOptions.headers.Authorization = `${auth.token_type} ${auth.access_token}`;
    }

    if (httpMethod === "GET" || isUrlData) {
      axiosOptions.params = inputData(payload);
    } else {
      axiosOptions.data = inputData(payload);
    }

    return axios(axiosOptions);
  };

export const mockApi =
  (fakeData, timeout = 2000) =>
  () =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(fakeData);
      }, timeout);
    });
