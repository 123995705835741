import React, { useState, Children, useEffect } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/pro-duotone-svg-icons";
import cx from "classnames";

import styles from "./Accordion.module.scss";

const Accordion = ({
  isOpen,
  header,
  children,
  level = 1,
  itemClassName,
  indicatorClassName,
  hideToggleIcon = false,
  headerClassName,
  contentClassName,
  childrenClassName,
  onChange,
}) => {
  const hasChildren = Children.count(children) > 0 && children;
  const [isOpenContent, setIsOpenContent] = useState(false);

  useEffect(() => {
    setIsOpenContent(isOpen);
  }, [isOpen]);

  const toggleAccordion = () => {
    setIsOpenContent(!isOpenContent);
    if (onChange) onChange(!isOpenContent);
  };

  return (
    <div
      className={cx(styles.item, itemClassName, { [styles.active]: hasChildren && isOpenContent })}
    >
      <div className={cx(styles.content, contentClassName)}>
        {!hideToggleIcon && (
          <span
            className={cx(styles.indicator, indicatorClassName)}
            onClick={hasChildren ? toggleAccordion : undefined}
            aria-hidden
          >
            {hasChildren &&
              (isOpenContent ? (
                <FontAwesomeIcon className={styles.toggleIcon} icon={faMinus} />
              ) : (
                <FontAwesomeIcon className={styles.toggleIcon} icon={faPlus} />
              ))}
          </span>
        )}
        <div className={cx(styles.header, headerClassName)}>
          {typeof header === "string"
            ? header
            : Children.map(header, (child, index) =>
                React.cloneElement(child, { level, key: child?.key || index })
              )}
        </div>
      </div>
      {hasChildren && isOpenContent && (
        <div className={cx(styles.children, childrenClassName)}>
          {Children.map(children, (child, index) =>
            React.cloneElement(child, {
              level: level + 1,
              key: child?.key || index,
            })
          )}
        </div>
      )}
    </div>
  );
};

Accordion.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  children: PropTypes.node.isRequired,
  level: PropTypes.number,
  itemClassName: PropTypes.string,
  indicatorClassName: PropTypes.string,
  hideToggleIcon: PropTypes.bool,
  headerClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  childrenClassName: PropTypes.string,
  isOpen: PropTypes.bool,
  onChange: PropTypes.func,
};

Accordion.defaultProps = {
  level: 1,
  itemClassName: "",
  indicatorClassName: "",
  hideToggleIcon: false,
  headerClassName: "",
  contentClassName: "",
  childrenClassName: "",
  isOpen: false,
  onChange: () => {},
};

export default Accordion;
