import { nanoid } from "nanoid";

import * as alertActions from "../alerts/alerts.actions";
import dispatchApi from "../utils/dispatchApi";
import { INTENT, PRICING_STRATEGY_CUSTOM_FORMULA } from "../../utils/constants";
import { handleError } from "../utils/error";
import {
  deleteProduct,
  getProduct,
  getProductSuppliers,
  getProductInventoryAndSuppliers,
  getProductCategories,
  saveProduct,
  restoreProduct,
  postDuplicateProduct,
  postSaveCustomFormula,
  patchSaveCustomFormula,
  getProductRowInventory,
} from "../../apiv2/products";

import * as selectors from "./product.selectors";
import {
  GET_PRODUCT,
  GET_PRODUCT_TABLES,
  GET_PRODUCT_CATEGORIES,
  EDIT_ACTIVE_PRODUCT,
  EDIT_SELECTED_PRICE_LEVELS,
  SET_ACTIVE_PRODUCT_VARIANT,
  RESET_ACTIVE_PRODUCT,
  LOAD_EMPTY_PRODUCT,
  DELETE_PRODUCT,
  UNDO_DELETE_PRODUCT,
  SET_IS_DUPLICATING,
  EDIT_VALUE_IN_FORMULA,
  SET_ERROR_IN_CUSTOM_FORMULA,
  SET_IS_SAVING_CUSTOM_FORMULA,
  SET_IS_SAVING_PRODUCT,
  SET_IS_FETCHING_PRODUCT,
  EDIT_SELECTED_ATTRIBUTE_VISIBLITY,
} from "./products.actionTypes";

// PRODUCTS //
export const setIsDuplicating = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_DUPLICATING,
    payload: status,
  });
};

export const setIsSavingCustomFormula = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_SAVING_CUSTOM_FORMULA,
    payload: status,
  });
};

export const setIsSavingProduct = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_SAVING_PRODUCT,
    payload: status,
  });
};

export const setActiveProductVariant = (data) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_PRODUCT_VARIANT,
    payload: data,
  });
};

export const setIsFetchingProduct = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_PRODUCT,
    payload: status,
  });
};

export const getProductAction = (productUid) => (dispatch) =>
  dispatchApi(dispatch, GET_PRODUCT, () => getProduct(productUid), INTENT.PERSISTENT);

export const getProductSuppliersAction = (productUid) => (dispatch) =>
  dispatchApi(dispatch, GET_PRODUCT, () => getProductSuppliers(productUid), INTENT.PERSISTENT);

export const getProductInventoryAndSuppliersAction = (productUid) => (dispatch) =>
  dispatchApi(
    dispatch,
    GET_PRODUCT,
    () => getProductInventoryAndSuppliers(productUid),
    INTENT.PERSISTENT
  );

export const getProductRowInventoryAction = (rowUid) => async () => {
  const inventory = await getProductRowInventory(rowUid)();
  return inventory;
};

export const getProductSupplierTablesAction = (productUid) => (dispatch) =>
  dispatchApi(
    dispatch,
    GET_PRODUCT_TABLES,
    () => getProductInventoryAndSuppliers(productUid),
    INTENT.PERSISTENT
  );

export const getProductCategoriesAction = () => (dispatch) => {
  dispatchApi(dispatch, GET_PRODUCT_CATEGORIES, () => getProductCategories(), INTENT.PERSISTENT);
};

export const editActiveProductAction = (path, value) => ({
  type: EDIT_ACTIVE_PRODUCT,
  payload: { path, value },
});

export const editSelectedAttributeVisiblity = (path, value) => ({
  type: EDIT_SELECTED_ATTRIBUTE_VISIBLITY,
  payload: { path, value },
});

export const editSelectedPriceLevels = (path, value) => ({
  type: EDIT_SELECTED_PRICE_LEVELS,
  payload: { path, value },
});

export const editValueInFormula = (path, value) => ({
  type: EDIT_VALUE_IN_FORMULA,
  payload: { path, value },
});

export const setErrorInCustomFormula = (payload) => (dispatch) => {
  dispatch({
    type: SET_ERROR_IN_CUSTOM_FORMULA,
    payload,
  });
};

export const loadEmptyProductAction = () => ({
  type: LOAD_EMPTY_PRODUCT,
  payload: {},
});
export const resetActiveProductAction = () => ({
  type: RESET_ACTIVE_PRODUCT,
  payload: {},
});

export const saveCustomFormula =
  ({ customFormula, activeProduct }) =>
  async (dispatch) => {
    try {
      dispatch(setIsSavingCustomFormula(true));

      const payload = {
        ...customFormula,
        elements: customFormula.elements.map((e, index) => {
          if (e.type === "variable" && e.name === "") {
            return { ...e, name: `Label${index + 1}` };
          }

          return { ...e };
        }),
      };

      if (activeProduct.customFormula.uid) {
        await patchSaveCustomFormula(activeProduct.customFormula.uid, payload)();
      } else {
        await postSaveCustomFormula(payload)();
      }
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(setIsSavingCustomFormula(false));
    }
  };

export const saveProductAction = (data) => async (dispatch, getState) => {
  try {
    const state = getState();
    const activeProduct = selectors.activeProductSelector(state);

    dispatch(setIsSavingProduct(true));

    const response = await saveProduct(data)();

    if (activeProduct.pricingStrategy === PRICING_STRATEGY_CUSTOM_FORMULA) {
      const payloadCustomFormula = {
        additionalProduct: !activeProduct.customFormula.uid ? response.data : undefined,
        elements: activeProduct.customFormula.elements,
        isRoundedToNearestWholeNumber: activeProduct.customFormula.isRoundedToNearestWholeNumber,
        displayFormula: activeProduct.customFormula.displayFormula,
      };

      await dispatch(saveCustomFormula({ customFormula: payloadCustomFormula, activeProduct }));
    }
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsSavingProduct(false));
  }
};

export const deleteProductAction = (productUid) => async (dispatch) => {
  await dispatchApi(dispatch, DELETE_PRODUCT, () => deleteProduct(productUid), INTENT.PERSISTENT);
};

export const undoDeleteProductAction = (recentlyDeletedProductUid) => async (dispatch) => {
  await dispatchApi(
    dispatch,
    UNDO_DELETE_PRODUCT,
    () => restoreProduct(recentlyDeletedProductUid),
    INTENT.PERSISTENT
  );
};

export const duplicateProductAction =
  (uid, isProductKit = false) =>
  async (dispatch) => {
    try {
      dispatch(setIsDuplicating(true));
      await postDuplicateProduct({ uids: uid })();

      const successMessage =
        isProductKit === true ? "Product kit duplicated." : "Product duplicated.";
      dispatch(alertActions.createAlertAction(nanoid(), successMessage, true, "success"));
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(setIsDuplicating(false));
    }
  };
