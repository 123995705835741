import { ROLES } from "../../utils/roles";

import { MODULE } from "./customers.actionTypes";

// To be refactor in using customersv2 in the future
export const companySelector = (state) => {
  try {
    const company = state.getIn(["customers", "company"]).toJS();

    return company;
  } catch (_) {
    return null;
  }
};

// To be refactor in using customersv2 in the future
export const userSelector = (state) => {
  try {
    return state.getIn(["customers", "user"]).toJS();
  } catch (_) {
    return null;
  }
};

export const getPlannerSettings = (state, { key }) => {
  const selectedSetting = companySelector(state).settings.plannerSettings.find(
    (p) => p.key === key
  );

  if (selectedSetting) return selectedSetting;

  return null;
};

export const getTimeTrackingSettings = (state, { key }) => {
  const selectedSetting = companySelector(state).settings.timeTrackingSettings.settings.find(
    (p) => p.key === key
  );

  if (selectedSetting) return selectedSetting;

  return null;
};

export const getUserRole = (state) => {
  const user = userSelector(state);

  return ROLES[user.role].id;
};

// CUSTOMER CONTACTS
export const isFetchingCustomerContacts = (state) =>
  state.getIn([MODULE, "isFetchingCustomerContacts"]);
export const isContactsModalOpen = (state) => state.getIn([MODULE, "isContactsModalOpen"]);
export const contactModalType = (state) => state.getIn([MODULE, "contactModalType"]);
export const editContact = (state) => state.getIn([MODULE, "editContact"]);
export const customerContacts = (state) => state.getIn([MODULE, "contacts"]);
export const selectedContacts = (state) => state.getIn([MODULE, "selectedContacts"]);
export const contactsToEmail = (state) => state.getIn([MODULE, "contactsToEmail"]);
export const getSearch = (state) => state.getIn([MODULE, "search"]);
export const getCustomer = (state) => state.getIn([MODULE, "customer"]);

/**
 * @param {object} state redux store state
 * @param {string} params.settingsType primary object key to find settings
 * @param {string} params.subType secondary type to find settings
 * @param {string} params.settingsKey key to find specific record of settings
 * @param {string=} params.specificKey optional key to get the specific details in the record
 * @returns {object | string | boolen | null}
 */
export const getCompanySettings = (
  state,
  { settingsType, subType, settingsKey, specificKey = "" }
) => {
  const settings = companySelector(state)?.settings ?? null;

  if (!settings) return null;

  if (!settings[settingsType] || !settingsKey) {
    return null;
  }

  const { [settingsType]: companySettingsType } = settings;

  if (companySettingsType?.[subType]) {
    const specificSettings = companySettingsType[subType].find(({ key }) => key === settingsKey);

    if (typeof specificSettings === "object" && specificKey in specificSettings) {
      return specificSettings[specificKey];
    }

    return specificSettings || null;
  }

  return null;
};

// Pagination
export const isArchivedCustomerIncluded = (state) =>
  state.getIn([MODULE, "isArchivedCustomerIncluded"]);

export const getSearchCustomer = (state) => state.getIn([MODULE, "searchCustomer"]);

export const getCustomerLists = (state) => state.getIn([MODULE, "customers"]);

export const isFetchingCustomerLists = (state) => state.getIn([MODULE, "isFetchingCustomerLists"]);

export const getPagination = (state) => state.getIn([MODULE, "pagination"]);

export const getSort = (state) => state.getIn([MODULE, "pagination", "sortBy"]);

export const getSortField = (state) => {
  const sort = getSort(state);

  if (sort !== "") {
    return sort.split(":")[0];
  }

  return "";
};

export const getSortDirection = (state) => {
  const sort = getSort(state);

  if (sort !== "") {
    return sort.split(":")[1];
  }

  return "";
};
