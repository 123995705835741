import produce from "immer";

import {
  SET_IS_FETCHING_CATEGORIES,
  SET_IS_FETCHING_PRODUCT,
  SET_IS_FETCHING_PRODUCTS,
  SET_IS_SAVING,
  SET_CATEGORIES,
  SET_EXPANDED_CATEGORIES,
  SET_EXPANDED_PRODUCTS,
  SET_PRODUCTS,
  SET_EMPTY_PRODUCTS,
  SET_SELECTED_CATEGORIES,
  SET_SELECTED_PRODUCTS,
  SET_INVENTORY,
  SET_PRODUCT_INVENTORY,
  RESET,
} from "./stocktake.actionTypes";

const initialState = {
  isFetchingCategories: false,
  fetchingInventoryProductUid: null,
  isSaving: false,
  categories: [],
  expandedCategories: [],
  expandedProducts: [],
  selectedCategories: [],
  selectedProducts: [],
  products: {},
  search: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_FETCHING_CATEGORIES: {
      return {
        ...state,
        isFetchingCategories: action.payload,
      };
    }

    case SET_IS_FETCHING_PRODUCT: {
      return {
        ...state,
        fetchingInventoryProductUid: action.payload,
      };
    }

    case SET_IS_SAVING: {
      return {
        ...state,
        isSaving: action.payload,
      };
    }

    case SET_CATEGORIES: {
      const { data } = action.payload;

      return {
        ...state,
        categories: data,
      };
    }

    case SET_EXPANDED_CATEGORIES: {
      const { data } = action.payload;

      return {
        ...state,
        expandedCategories: data,
      };
    }

    case SET_EXPANDED_PRODUCTS: {
      const { data } = action.payload;

      return {
        ...state,
        expandedProducts: data,
      };
    }

    case SET_IS_FETCHING_PRODUCTS: {
      const { data } = action.payload;
      return {
        ...state,
        categories: state.categories.map((c) =>
          c.uid === data.uid ? { ...c, isFetching: data.isFetching } : { ...c }
        ),
      };
    }

    case SET_PRODUCTS: {
      const { data, categoryUid } = action.payload;

      return {
        ...state,
        products: {
          ...state.products,
          [categoryUid]: data,
        },
      };
    }

    case SET_EMPTY_PRODUCTS: {
      return {
        ...state,
        products: {},
      };
    }

    case SET_SELECTED_CATEGORIES: {
      const { selectedCategories } = action.payload;
      return {
        ...state,
        selectedCategories,
      };
    }

    case SET_SELECTED_PRODUCTS: {
      const { selectedProducts } = action.payload;
      return { ...state, selectedProducts };
    }

    case SET_INVENTORY: {
      const { categoryUid, productIndex, materialIndex, rowIndex, data } = action.payload;

      return produce(state, (draftState) => {
        draftState.products[categoryUid][productIndex].tables[materialIndex].values[
          rowIndex
        ].inventory = data;
      });
    }

    case SET_PRODUCT_INVENTORY: {
      return {
        ...state,
        products: {
          ...state.products,
          [action.payload.categoryUid]: state.products[action.payload.categoryUid].map((product) =>
            product.uid === action.payload.productUid
              ? { ...product, tables: action.payload.tables }
              : product
          ),
        },
      };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
