import * as customersApi from "@/apiv2/customers";
import { handleError } from "@/redux/utils/error";

import * as selectors from "./users.selectors";
import {
  SET_IS_USERS_DROPDOWN_VISIBLE,
  SET_IS_FETCHING_USERS,
  SET_USERS,
  SET_SELECTED_USERS,
  SET_UNSELECTED_USERS,
  SET_ACTIVE_USER,
  RESET,
} from "./users.actionTypes";

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setIsUsersDropdownVisible = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_USERS_DROPDOWN_VISIBLE,
    payload: status,
  });
};

export const setIsFetchingUsers = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_USERS,
    payload: status,
  });
};

export const setUsers = (data) => (dispatch) => {
  dispatch({
    type: SET_USERS,
    payload: data,
  });
};

export const setSelectedUsers = (data) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_USERS,
    payload: data,
  });
};

export const setUnSelectedUsers = (data) => (dispatch) => {
  dispatch({
    type: SET_UNSELECTED_USERS,
    payload: data,
  });
};

export const setActiveUser = (data) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_USER,
    payload: data,
  });
};

export const toggleUsers = (uid) => (dispatch, getState) => {
  const state = getState();
  const selectedUserUids = selectors.getSelectedUsers(state);
  const unSelectedUserUids = selectors.getUnSelectedUsers(state);

  if (selectedUserUids.includes(uid)) {
    if (!unSelectedUserUids.includes(uid)) {
      dispatch(setUnSelectedUsers([...unSelectedUserUids, uid]));
    }
  }

  if (!selectedUserUids.includes(uid)) {
    if (unSelectedUserUids.includes(uid)) {
      dispatch(setUnSelectedUsers(unSelectedUserUids.filter((id) => id !== uid)));
    }
  }

  const newSelectedUserUids = selectedUserUids.includes(uid)
    ? selectedUserUids.filter((id) => id !== uid)
    : [...selectedUserUids, uid];

  dispatch(setSelectedUsers(newSelectedUserUids));
};

export const fetchUsers = () => async (dispatch) => {
  try {
    dispatch(setIsFetchingUsers(true));
    const response = await customersApi.getUsers({ include_invites: false })();
    dispatch(setUsers(response.data));
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetchingUsers(false));
  }
};
