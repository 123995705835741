import {
  SET_IS_FETCHING_VIEWS,
  SET_VIEWS,
  SET_ACTIVE_VIEW,
  SET_IS_CREATE_VIEW_FORM_VISIBLE,
  SET_SELECTED_VIEW,
  SET_DEFAULT_VIEW,
  RESET,
} from "./views.actionTypes";

const initialState = {
  isFetchingViews: false,
  isCreateViewFormVisible: false,
  views: [],
  activeView: null,
  selectedView: { uid: "none", name: "None" },
  defaultView: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_VIEWS: {
      return { ...state, views: action.payload };
    }

    case SET_ACTIVE_VIEW: {
      return { ...state, activeView: action.payload };
    }

    case SET_IS_FETCHING_VIEWS: {
      return { ...state, isFetchingViews: action.payload };
    }

    case SET_IS_CREATE_VIEW_FORM_VISIBLE: {
      return { ...state, isCreateViewFormVisible: action.payload };
    }

    case SET_SELECTED_VIEW: {
      return { ...state, selectedView: action.payload };
    }

    case SET_DEFAULT_VIEW: {
      return { ...state, defaultView: action.payload };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
