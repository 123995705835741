import createActionType from "@/redux/utils/createActionType";

export const MODULE = "dashboard/add-widgets";

export const SET_SEARCH = createActionType(MODULE, "setSearch");

export const SET_IS_MODAL_OPEN = createActionType(MODULE, "setIsModalOpen");

export const SET_ACTIVE_CATEGORY = createActionType(MODULE, "setActiveCategory");

export const SET_WIDGETS_REFERENCE = createActionType(MODULE, "setWidgetsReference");
export const SET_WIDGETS = createActionType(MODULE, "setWidgets");
export const SET_WIDGETS_STATUS = createActionType(MODULE, "setWidgetsStatus");

export const SET_WIDGETS_CATEGORIES = createActionType(MODULE, "setWidgetsCategories");
export const SET_WIDGETS_CATEGORIES_STATUS = createActionType(MODULE, "setWidgetsCategoriesStatus");

export const RESET = createActionType(MODULE, "reset");
