import React from "react";
import { connect } from "react-redux";
import { Iterable } from "immutable";

import { rootActions } from "../root";

// This is recursive version of standard redux bindActionCreators
function bindActionCreatorsRecursive(actions, dispatch) {
  if (typeof dispatch !== "function") {
    throw new TypeError("Action wrapper needs a dispatch function");
  }

  return Object.keys(actions).reduce((acc, key) => {
    if (typeof actions[key] === "function") {
      acc[key] = (...args) => dispatch(actions[key](...args));
    } else if (actions[key] !== null && typeof actions[key] === "object") {
      acc[key] = bindActionCreatorsRecursive(actions[key], dispatch);
    }

    return acc;
  }, {});
}

function toJS(WrappedComponent) {
  return (wrappedComponentProps) => {
    const KEY = 0;
    const VALUE = 1;

    const propsJS = Object.entries(wrappedComponentProps).reduce(
      (newProps, wrappedComponentProp) => {
        newProps[wrappedComponentProp[KEY]] = Iterable.isIterable(wrappedComponentProp[VALUE])
          ? wrappedComponentProp[VALUE].toJS()
          : wrappedComponentProp[VALUE];

        return newProps;
      },
      {}
    );

    return <WrappedComponent {...propsJS} />;
  };
}

export default function reduxConnect(stateMappings) {
  return (component) => {
    const mapStateToProps = (state) => {
      const result = {};

      Object.keys(stateMappings).forEach((propName) => {
        result[propName] = state.getIn(stateMappings[propName]);
      });

      return result;
    };

    const mapDispatchToProps = (dispatch) => ({
      actions: bindActionCreatorsRecursive(rootActions, dispatch),
    });

    return connect(mapStateToProps, mapDispatchToProps)(toJS(component));
  };
}
