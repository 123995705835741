import { companySelector } from "../customers/customers.selectors";

import { MODULE } from "./planner.actionTypes";
import * as calendarSelectors from "./calendar/calendar.selectors";
import * as orderSelectors from "./orders/orders.selectors";

export const isPageLoading = (state) =>
  (orderSelectors.isFetchingOrders(state) && orderSelectors.getOrders(state).length === 0) ||
  calendarSelectors.isFetchingUsers(state);

export const getActiveView = (state) => state.getIn([MODULE, "activeView"]);

export const isAssignUserMenuVisible = (state) => state.getIn([MODULE, "isAssignUserMenuVisible"]);

export const isAssignLabelMenuVisible = (state) =>
  state.getIn([MODULE, "isAssignLabelMenuVisible"]);

export const isEditTimeslotMenuVisible = (state) =>
  state.getIn([MODULE, "isEditTimeslotMenuVisible"]);

export const getAssignUserDropdownCoordinates = (state) =>
  state.getIn([MODULE, "assignUserDropdownCoordinates"]);

export const getAssignLabelDropdownCoordinates = (state) =>
  state.getIn([MODULE, "assignLabelDropdownCoordinates"]);

export const getEditTimeslotDropdownCoordinates = (state) =>
  state.getIn([MODULE, "editTimeslotDropdownCoordinates"]);

export const isOrderModalOpen = (state) => state.getIn([MODULE, "isOrderModalOpen"]);

export const isOrderColumnCollapse = (state) => state.getIn([MODULE, "isOrderColumnCollapse"]);

export const isSettingsModalOpen = (state) => state.getIn([MODULE, "isSettingsModalOpen"]);

export const getSettings = (state) => state.getIn([MODULE, "settings"]);

export const getPlannerWorkingHours = (state) => {
  const settings = getSettings(state);
  const activeCustomer = companySelector(state);

  if (settings.startTime && settings.endTime) {
    return {
      ...settings,
    };
  }

  const plannerWorkHours = activeCustomer.settings.plannerSettings.find(
    (s) => s.key === "PLANNER_WORK_HOURS"
  );

  if (plannerWorkHours) {
    return {
      startTime: plannerWorkHours.value.startTime,
      endTime: plannerWorkHours.value.endTime,
    };
  }

  return null;
};

export const isCustomAssignUserMenuVisible = (state) =>
  state.getIn([MODULE, "isCustomAssignUserMenuVisible"]);

export const isCustomAssignLabelMenuVisible = (state) =>
  state.getIn([MODULE, "isCustomAssignLabelMenuVisible"]);

export const isCustomEditTimeslotMenuVisible = (state) =>
  state.getIn([MODULE, "isCustomEditTimeslotMenuVisible"]);

export const getFetchCustomEventsStatus = (state) =>
  state.getIn([MODULE, "fetchCustomEventsStatus"]);

export const getFetchCustomEventStatus = (state) => state.getIn([MODULE, "fetchCustomEventStatus"]);

export const getDuplicateEventStatus = (state) => state.getIn([MODULE, "duplicateEventStatus"]);

export const getDeleteCustomEventInstanceStatus = (state) =>
  state.getIn([MODULE, "deleteCustomEventInstanceStatus"]);
export const getDeleteThisCustomEventStatus = (state) =>
  state.getIn([MODULE, "deleteThisCustomEventStatus"]);
export const getDeleteThisAndFollowingCustomEventStatus = (state) =>
  state.getIn([MODULE, "deleteThisAndFollowingCustomEventStatus"]);
export const getDeleteAllCustomEventStatus = (state) =>
  state.getIn([MODULE, "deleteAllCustomEventStatus"]);

export const getUndoDeleteCustomEventInstanceStatus = (state) =>
  state.getIn([MODULE, "undoDeleteCustomEventInstanceStatus"]);
export const getUndoDeleteThisCustomEventStatus = (state) =>
  state.getIn([MODULE, "undoDeleteThisCustomEventStatus"]);
export const getUndoDeleteThisAndFollowingCustomEventStatus = (state) =>
  state.getIn([MODULE, "undoDeleteThisAndFollowingCustomEventStatus"]);
export const getUndoDeleteAllCustomEventStatus = (state) =>
  state.getIn([MODULE, "undoDeleteAllCustomEventStatus"]);

export const getCustomEventDataToUpdate = (state) =>
  state.getIn([MODULE, "customEventDataToUpdate"]);

export const customEvents = (state) => state.getIn([MODULE, "customEvents"]);
export const customEvent = (state) => state.getIn([MODULE, "customEvent"]);

export const getCustomEvents = (state) => {
  const events = state.getIn([MODULE, "customEvents"]);
  const users = calendarSelectors.getUsers(state);

  return events.map((event) => ({
    ...event,
    isCustom: true,
    start: event.startDatetime,
    startTime: event.startDatetime,
    end: event.endDatetime,
    endTime: event.endDatetime,
    title: event.title,
    labels: event.labels,
    assignees: event.assignees.map((assignee) => ({
      ...assignee,
      color: users.find((u) => u.id === assignee.id)?.color,
    })),
  }));
};

export const getCustomEventsByTeam = (state) => {
  const events = getCustomEvents(state);

  const team = [];

  events.forEach((event) => {
    event.assignees.forEach((u) => {
      team.push({
        ...event,
        resource: u.id,
      });
    });
  });

  return team;
};

export const getCustomEventsByLabel = (state) => {
  const events = getCustomEvents(state);

  const label = [];

  events.forEach((event) => {
    event.labels.forEach((l) => {
      label.push({
        ...event,
        resource: l.uid,
      });
    });
  });

  return label;
};

export const createCustomEventStatus = (state) => state.getIn([MODULE, "createCustomEventStatus"]);
export const updateCustomEventStatus = (state) => state.getIn([MODULE, "updateCustomEventStatus"]);
export const getCustomEventReminders = (state) => state.getIn([MODULE, "customEventReminders"]);
export const getCustomEventReminder = (state) => state.getIn([MODULE, "customEventReminder"]);

export const getActiveCustomEvent = (state) => state.getIn([MODULE, "activeCustomEvent"]);
