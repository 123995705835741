import { orderBy } from "lodash";
import { userSelector } from "../customers/customers.selectors";
import { MODULE } from "./assignees.actionTypes";

export const isSavingUser = (state) => state.getIn([MODULE, "isSavingUsers"]);

export const isFetchingUsers = (state) => state.getIn([MODULE, "isFetchingUsers"]);

export const getUsers = (state) => state.getIn([MODULE, "users"]);

export const getSelectedUsers = (state) => state.getIn([MODULE, "selectedUsers"]);

export const getUnSelectedUsers = (state) => state.getIn([MODULE, "unSelectedUsers"]);

export const getOptions = (state, activeOrder) => {
  const users = getUsers(state);
  const currentUser = userSelector(state);

  const formattedAssignees = activeOrder.assignees.map((a) =>
    a.id === currentUser.id ? { ...a, lastName: `${a.lastName} (You)` } : a
  );

  const assignedUsers = [
    ...(formattedAssignees.some((a) => a.id === currentUser.id)
      ? [formattedAssignees.find((a) => a.id === currentUser.id) ?? undefined]
      : []),
    ...orderBy(
      formattedAssignees.filter((a) => a.id !== currentUser.id),
      [(a) => a.firstName.toLowerCase()],
      "asc"
    ),
  ].filter((a) => !!a);

  const formattedAllUsers = users.map((a) =>
    a.id === currentUser.id ? { ...a, lastName: `${a.lastName} (You)` } : a
  );

  const unassignUsers = [
    ...(!activeOrder.assignees.some((a) => a.id === currentUser.id)
      ? [formattedAllUsers.find((a) => a.id === currentUser.id) ?? undefined]
      : []),
    ...orderBy(
      users
        .filter((all) => !activeOrder.assignees.map((a) => a.id).includes(all.id))
        .filter((a) => a.id !== currentUser.id),
      [(a) => a.firstName.toLowerCase()],
      "asc"
    ),
  ].filter((a) => !!a);

  return [...assignedUsers, ...unassignUsers];
};
