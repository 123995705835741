import createActionType from "../utils/createActionType";

export const MODULE = "add-product-to-order-inline";

export const SET_IS_FETCHING_STATUS = createActionType(MODULE, "setIsFetchingStatus");

export const SET_PRODUCTS = createActionType(MODULE, "setProducts");

export const SET_IS_SEARCH_RESULT_OPEN = createActionType(MODULE, "setIsSearchResultOpen");

export const SET_SEARCH = createActionType(MODULE, "setSearch");

export const RESET = createActionType(MODULE, "reset");
