import qs from "query-string";

import { createApiV2 } from "./common";

export const postTeams = (data) => (token) => createApiV2("POST", "users/")(token, data);

export const putTeam = (data) => (token) => createApiV2("PUT", `users/${data.id}/`)(token, data);

export const getTeams = (params) => () => createApiV2("GET", `users/?${qs.stringify(params)}`)();

export const postLinkInvite = () => (token) =>
  createApiV2("POST", "companies/link_invite/")(token, {});

export const postEmailInvite = (data) => (token) =>
  createApiV2("POST", "companies/email_invite/")(token, data);

export const deleteEmailInvite = (data) => (token) =>
  createApiV2("DELETE", "companies/invite/")(token, data);
