import { createApi } from "./common";

export const getCollaborationNotes = (token) =>
  createApi("GET", "purchasing/messages/")(token);

export const getCollaborationNotesFile = (
  supplierUid,
  purchaseOrderUid,
  fileId,
  token
) =>
  createApi(
    "GET",
    //`purchasing/chat_attachments/${purchaseOrderUid}/attachments/${fileId}/`,
    `customers/${supplierUid}/orders/${purchaseOrderUid}/attachments/${fileId}/`,
    { responseType: "blob" }
  )(token);

export const saveMessageWithFiles = (token, data) =>
  createApi("POST", "purchasing/chat_attachments/")(token, data);

export default getCollaborationNotes;
