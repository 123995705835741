import React from "react";
import Dropzone from "react-dropzone";
import cx from "classnames";

import { Button } from "@/ui-lib/FormFields";
import { Loading } from "@/ui-lib";

import FileContainer from "./FileContainer";
import styles from "./File.module.scss";

const File = (props) => (
  <FileContainer {...props}>
    {({
      id,
      label,
      containerClassName,
      inputClassName,
      errors,
      buttonText,
      multiple,
      isDisabled,
      drawImages,
      dropzoneRef,
      choseImage,
      outline,
      color,
      size,
      loading,
    }) => (
      <div className={cx(styles.fileContainer, containerClassName)}>
        {label && <div className={styles.fileLabel}>{label}</div>}

        <div className={styles.fileDropzoneContainer}>
          <Dropzone disabled={isDisabled} multiple={multiple} ref={dropzoneRef} onDrop={choseImage}>
            {({ getRootProps, getInputProps }) => (
              <div
                className={[styles.fileDropzone, "dropzone", inputClassName].join(" ")}
                {...getRootProps()}
              >
                {!loading ? drawImages() : <Loading />}
                <input {...getInputProps()} />
              </div>
            )}
          </Dropzone>
        </div>

        {!isDisabled && (
          <Button
            type="button"
            outline={outline}
            color={color}
            size={size}
            onClick={() => dropzoneRef.current.open()}
            className={styles.fileButton}
          >
            {buttonText}
          </Button>
        )}
        {errors[id] &&
          Object.keys(errors[id].errors).map((key) => {
            const error = errors[id].errors[key];
            return <div className={styles.fileError}>{error.join(" ")}</div>;
          })}
      </div>
    )}
  </FileContainer>
);

export default File;
