import countries from "./countries.json";

export const COUNTRIES_WITH_STATE = countries;

export const getCountries = () => COUNTRIES_WITH_STATE.map((c) => ({ name: c.name, code: c.code }));

export const getCountryByCode = (name) =>
  COUNTRIES_WITH_STATE.find((s) => s.name === name || s.code === name);

export const getStatesByCountryCode = (countryCode) =>
  COUNTRIES_WITH_STATE.find((s) => s.code === countryCode)?.states?.map((s) => ({
    ...s,
    name: s.name?.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
  })) ?? [];

export const getStateByCode = ({ countryCode, stateCode }) => {
  const state = getCountryByCode(countryCode)?.states.find((s) => s.code === stateCode);

  if (!state) return "";
  return state;
};
export default {};
