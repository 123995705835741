import qs from "query-string";

import { createApiV2 } from "./common";

export const getPurchaseOrderTimelines =
  ({ purchaseOrderUid, search, eventTypes }) =>
  (token) => createApiV2(
      "GET",
      `purchasing/purchase_orders/${purchaseOrderUid}/events/?${qs.stringify(
        { search, event_types: eventTypes },
        { arrayFormat: "repeat" }
      )}`
    )(token);

export const getPurchaseOrderTimelineDetails =
  ({ purchaseOrderUid }) =>
  (token) => createApiV2(
      "GET",
      `purchasing/purchase_orders/${purchaseOrderUid}/events/details/`
    )(token);
