import {
  SET_IS_FETCHING,
  SET_LISTS,
  SET_SUPPLIERS_LIST,
  SET_SELECTED_PRODUCT_UIDS,
  SET_IS_GROUPED_PRODUCTS,
  SET_IS_CREATING,
  SET_HAS_FORM_SUBMITTED,
  UPDATE_QUANTITY,
  UPDATE_SUPPLIER,
  UPDATE_MULTIPLE_SUPPLIER,
  SET_IS_CREATE_PURCHASE_ORDER_MODAL_OPEN,
  SET_IS_CREATING_PURCHASE_ORDER_MODAL_OPEN,
  SET_IS_CREATED_PURCHASE_ORDER_MODAL_OPEN,
  SET_CREATED_PO_UIDS,
  RESET,
} from "./purchaseProductsModal.actionTypes";

const initialState = {
  lists: [],
  isFetching: false,
  isGroupedProducts: true,
  isCreating: false,
  selectedProductUids: [],
  hasFormSubmitted: false,
  isCreatePurchaseOrderModalOpen: false,
  isCreatingPurchaseOrderModalOpen: false,
  isCreatedPurchaseOrderModalOpen: false,
  createdPoUids: [],
  suppliersList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case `${SET_LISTS}/fulfilled`: {
      const { data } = action.payload;

      return {
        ...state,
        lists: data,
      };
    }

    case `${SET_SUPPLIERS_LIST}/fulfilled`: {
      const { data } = action.payload;

      return {
        ...state,
        suppliersList: data,
      };
    }

    case SET_SELECTED_PRODUCT_UIDS: {
      const { selectedProductUids } = action.payload;
      return { ...state, selectedProductUids };
    }

    case SET_IS_FETCHING: {
      return { ...state, isFetching: action.payload };
    }

    case SET_IS_CREATING: {
      return { ...state, isCreating: action.payload };
    }

    case SET_HAS_FORM_SUBMITTED: {
      return { ...state, hasFormSubmitted: action.payload };
    }

    case SET_IS_GROUPED_PRODUCTS: {
      return { ...state, isGroupedProducts: action.payload };
    }

    case SET_IS_CREATE_PURCHASE_ORDER_MODAL_OPEN: {
      return { ...state, isCreatePurchaseOrderModalOpen: action.payload };
    }

    case SET_IS_CREATING_PURCHASE_ORDER_MODAL_OPEN: {
      return { ...state, isCreatingPurchaseOrderModalOpen: action.payload };
    }

    case SET_IS_CREATED_PURCHASE_ORDER_MODAL_OPEN: {
      return { ...state, isCreatedPurchaseOrderModalOpen: action.payload };
    }

    case SET_CREATED_PO_UIDS: {
      return { ...state, createdPoUids: action.payload };
    }

    case UPDATE_QUANTITY: {
      const { uid, quantity } = action.payload;
      return {
        ...state,
        lists: state.lists.map((list) => {
          if (list.uid === uid) {
            return { ...list, quantity };
          }
          return list;
        }),
      };
    }

    case UPDATE_SUPPLIER: {
      const { uid, supplierForCreatedPo } = action.payload;
      return {
        ...state,
        lists: state.lists.map((list) => {
          if (list.uid === uid) {
            return { ...list, supplierForCreatedPo };
          }
          return list;
        }),
      };
    }

    case UPDATE_MULTIPLE_SUPPLIER: {
      const { productList } = action.payload;
      const selectedProductUids = []
      const lists = state.lists.map((list) => {
        const supplierUidIfExists = productList.find(product => product.productUid === list.uid)
        if (supplierUidIfExists) {
          selectedProductUids.push(supplierUidIfExists.productUid)
          const supplierForCreatedPo = supplierUidIfExists.supplierUid
          return { ...list, supplierForCreatedPo };
        }
        return list;
      })
      return {
        ...state,
        lists,
        selectedProductUids
      };

    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
