import { createApi } from "../api/common";

import { createApiV2 } from "./common";

const headers = {
  "Content-Type": "multipart/form-data",
};

// PRODUCTS //
export const getProduct = (productUid) => (token) =>
  createApi("GET", `additional_products/${productUid}/`)(token);

export const getProductSuppliers = (productUid) => (token) =>
  createApi("GET", `additional_products/${productUid}/retrieve_suppliers/`)(token);

export const getProductInventoryAndSuppliers = (productUid) => (token) =>
  createApi("GET", `additional_products/${productUid}/retrieve_inventory/`)(token);

export const getProductCategories = () => (token) =>
  createApi("GET", "additional_products_categories/")(token);

export const saveProduct = (data) => (token) =>
  createApiV2("POST", "additional_products/", { headers })(token, data);

export const deleteProduct = (productUid) => (token) =>
  createApi("DELETE", `additional_products/${productUid}/`)(token);

export const restoreProduct = (productUid) => (token) =>
  createApi("POST", `additional_products/undestroy/`)(token, {
    uid: productUid,
  });

export const postDuplicateProduct = (data) => (token) =>
  createApiV2("POST", `additional_products/bulk_duplicate/`)(token, data);

export const postSaveCustomFormula = (data) => (token) =>
  createApiV2("POST", `custom_formulas/`)(token, data);

export const patchSaveCustomFormula = (uid, data) => (token) =>
  createApiV2("PATCH", `custom_formulas/${uid}/`)(token, data);

export const getProductCategoriesV2 = () => () =>
  createApiV2("GET", "additional_products_categories/")();

export const getProductVariantDetails =
  ({ productUid, productVariantUid }) =>
  () =>
    createApiV2("GET", `additional_products/${productUid}/variants/${productVariantUid}/`)();

export const getProductRowInventory = (rowUid) => (token) =>
  createApiV2("GET", `additional_product_row/${rowUid}/inventory/`)(token);
