import { createApiV2 } from "./common";

export const getPricingTable = () => (token) => createApiV2("GET", "billing/pricing/")(token);

export const postCheckout = (data) => (token) =>
  createApiV2("POST", "billing/checkout/")(token, data);

export const getXeroReferralCheckout = () => (token) =>
  createApiV2("GET", "xero_billing/checkout/")(token);

export const postCheckoutSuccess = (data) => (token) =>
  createApiV2("POST", "billing/success/")(token, data);

export const getBillingDetails = () => (token) => createApiV2("GET", "billing/")(token);

export const postUpdateBillingInformation = () => (token) =>
  createApiV2("POST", "billing/information/")(token, {});

export const putBillingScriptions = () => (token) =>
  createApiV2("PUT", "billing/subscriptions/")(token, {});

export const postUpdateXeroBillingInformation = () => (token) =>
  createApiV2("POST", "xero_billing/portal/")(token, {});
