import { createApi } from "./common";

export const getDrawingItem = (token, { customerUid, orderUid, itemUid }) =>
  createApi(
    "GET",
    `customers/${customerUid}/orders/${orderUid}/items/${itemUid}/drawings/`
  )(token);

export const getDrawingsOrder = (token, { customerUid, orderUid }) =>
  createApi(
    "GET",
    `customers/${customerUid}/orders/${orderUid}/drawings/`
  )(token);

export default getDrawingItem;
