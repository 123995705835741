import { STATUS } from "@/utils/constants";

import {
  SET_ACTIVE_CATEGORY,
  SET_WIDGETS_REFERENCE,
  SET_WIDGETS,
  SET_WIDGETS_STATUS,
  SET_WIDGETS_CATEGORIES,
  SET_WIDGETS_CATEGORIES_STATUS,
  SET_IS_MODAL_OPEN,
  SET_SEARCH,
  RESET,
} from "./add-widgets.actionTypes";

const initialState = {
  activeCategory: null,
  widgetsStatus: STATUS.IDLE,
  widgetsReference: [],
  widgets: [],
  widgetsCategoriesStatus: STATUS.IDLE,
  widgetsCategories: [],
  isModalOpen: false,
  search: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_CATEGORY: {
      return { ...state, activeCategory: action.payload };
    }

    case SET_WIDGETS: {
      return { ...state, widgets: action.payload };
    }

    case SET_WIDGETS_REFERENCE: {
      return { ...state, widgetsReference: action.payload };
    }

    case SET_WIDGETS_STATUS: {
      return { ...state, widgetsStatus: action.payload };
    }

    case SET_WIDGETS_CATEGORIES: {
      return { ...state, widgetsCategories: action.payload };
    }

    case SET_WIDGETS_CATEGORIES_STATUS: {
      return { ...state, widgetsCategoriesStatus: action.payload };
    }

    case SET_IS_MODAL_OPEN: {
      return { ...state, isModalOpen: action.payload };
    }

    case SET_SEARCH: {
      return { ...state, search: action.payload };
    }

    case RESET: {
      return initialState;
    }

    default:
      return state;
  }
};
