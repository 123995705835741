import qs from "query-string";
import { push as pushUrl } from "connected-react-router";

import { getPath, getQuery } from "./router.selectors";

export const push = (url, params = {}) => {
  const paramsStr = qs.stringify(params);
  return pushUrl(`${url}?${paramsStr}`);
};

export const pushPreserveSearch = (url, search) => pushUrl(`${url}${search}`);

export const setQueryParam = (name, value) => (dispatch, getState) => {
  const state = getState();
  const queryParams = getQuery(state);
  const pathname = getPath(state);
  const queryStr = qs.stringify({ ...queryParams, [name]: value });
  dispatch(pushUrl(`${pathname}?${queryStr}`));
};

export const setQueryParams = (params) => (dispatch, getState) => {
  const state = getState();
  const queryParams = getQuery(state);
  const pathname = getPath(state);
  const queryStr = qs.stringify({ ...queryParams, ...params });
  dispatch(pushUrl(`${pathname}?${queryStr}`));
};

export const replaceQueryParams = (params) => (dispatch, getState) => {
  const state = getState();
  const pathname = getPath(state);
  const queryStr = qs.stringify({ ...params });
  dispatch(pushUrl(`${pathname}?${queryStr}`));
};

export const replaceLastSection = (newSection) => (dispatch, getState) => {
  const state = getState();
  const pathname = getPath(state);
  const pathParts = pathname.split("/");
  const newPathname = [...pathParts.slice(0, -1), newSection].join("/");
  dispatch(pushUrl(newPathname));
};
