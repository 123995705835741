import { fromJS } from "immutable";
import { round } from "lodash";
import { withMutations } from "./helpers/reducerWrappers";
import { genId } from "../../utils/common";
import ReduxModule from "./abstract/ReduxModule";

import prices from "./prices";

import { calcDrawingItemLength } from "./helpers/item";
import { getMinLengthFlashings } from "./helpers/product";

const ACTIONS = {
  CLEAR_ACTIVE_ORDER: "Clear active order",
  CREATE_NEW_ORDER: "Create new order",
  RESET_DRAWINGS: "Reset drawings",
  SET_DATA_AFTER_SAVING_DRAWINGS: "Set data after saving drawings",
  SET_UIDS: "Set uids",
  OPEN_ORDER: "Load data from order",

  ADD_ITEMS: "Add items",
  DELETE_ITEMS: "Change items",
  UPDATE_TOTAL_LENGTH: "Update total length",

  ADD_NEW_DRAWING: "Add new drawing",
  DUPLICATE_DRAWING: "Duplicate drawing",
  SET_ACTIVE_DRAWING_TEMP_UID: "Set active drawing temp uid",
  DELETE_DRAWING: "Delete drawing",
  UNDO_DELETE_DRAWING: "Undo delete drawing",
  CHANGE_DRAWING_COMPLETED_STATUS: "Change drawing completed status",
};

class DrawingsItemsModule extends ReduxModule {
  getNamespace() {
    return "[DrawingsItems]";
  }

  getInitialState() {
    return {
      activeItems: {},
      startStateDrawings: null, // {items: {}, itemsLength: {}}
      items: {}, // [drawingTempUid]: [this.initialItemState()]
      itemsLength: {}, // [drawingTempUid]: {total: '0', forPrice: '0'}
      activeDrawingUid: null,
      dataServer: {
        isPending: false,
        items: [],
      },
      errors: {},
    };
  }

  initialItemState() {
    return {
      isDeleted: false,
      isCompleted: false,
      uid: "",
      tempUid: genId(),
      index: null,
      subitems: {
        amount: "0",
        length: "0",
      },
      drawingTempUid: null,
    };
  }

  generateOrderDataForServer(state) {
    const errorsAll = [];
    const isAutoSaving = state.getIn(["orders", "saving", "auto"]);
    const isSubmitted = state.getIn(["orders", "activeOrder", "isSubmitted"]);

    const items = state.getIn(["drawingsItems", "items"]);
    const itemsTotalLength = state.getIn(["drawingsItems", "itemsLength"]);
    const itemsForServer = [];
    const [...keys] = items.keys();

    if (!isAutoSaving && keys.length === 0) {
      errorsAll.push("Empty drawings");
    } else {
      const drawings = state.getIn(["drawings", "items"]);

      drawings.forEach((drawing, id) => {
        const key = drawing.get("tempUid");

        const itemsByDrawing = items.get(key) ? items.get(key) : fromJS({});
        const totalLength = itemsTotalLength.getIn([key, "total"]) / 1000;

        const drawingPrices = state.getIn(["prices", "items", key]);
        const totalPrice = drawingPrices.get("totalPrice");
        const customPrice = drawingPrices.get("customPrice");
        const customPricePerMeter = drawingPrices.get("customPricePerMeter");
        const pricePerMeter = drawingPrices.get("pricePerMeter");

        const drawingProductByDrawing = state.getIn([
          "drawingsProducts",
          "items",
          key,
          "product",
        ]);
        let itemLength = 0;
        let deletedItems = 0;
        if (
          (itemsByDrawing.get("subitems").size && itemsByDrawing.size) ||
          (!itemsByDrawing.get("subitems").size && !isSubmitted)
        ) {
          const length = itemsByDrawing.get("subitems");

          if (length) {
            length.reduce((sum, item) => {
              sum += (item.get("amount") * item.get("length")) / 1000;
              itemLength = sum;
              return itemLength;
            }, 0);
          }

          let totalValue = 0;
          let customValue = 0;
          if (totalLength > 0) {
            totalValue = (totalPrice / totalLength) * itemLength;
            customValue = round(((customPrice / totalLength) * itemLength), 2).toFixed(2);
          }
          if (!customPrice) {
            customValue = null;
          }

          if (!isAutoSaving && itemsByDrawing.get("isDeleted")) {
            deletedItems += 1;
          }

          const item = {
            item_data: {
              uid: itemsByDrawing.get("uid"),
              temp_uid_drawing: itemsByDrawing.get("drawingTempUid"),
              is_deleted: itemsByDrawing.get("isDeleted"),
              is_completed: itemsByDrawing.get("isCompleted"),
              value: pricePerMeter,
              total_value: round(totalValue, 2).toFixed(2),
              custom_value: customValue,
              custom_value_lm: customPricePerMeter,

              product: drawingProductByDrawing.get("uid"),
              product_type: drawingProductByDrawing.get("productType"),
              product_name: drawingProductByDrawing.get("name"),
              thickness: drawingProductByDrawing.get("thickness"),
              color: drawingProductByDrawing.get("color"),
              price_level: drawingProductByDrawing.get("priceLevel"),

              subitems: length.toJS(),
              total_length: `${itemLength}`,
            },
          };
          if (!item.item_data.uid) {
            item.item_data.temp_uid = genId();
          }
          itemsForServer.push(item);
        }

        const errors = [];
        if (!isAutoSaving && !drawing.get("isDeleted")) {
          if (!drawingProductByDrawing.get("uid")) {
            errors.push(
              "Product not found! Check Type, Material, Color and Thickness."
            );
          }
          if (
            itemsByDrawing.size === 0 ||
            (deletedItems > 0 && deletedItems === itemsByDrawing.size) ||
            !itemsByDrawing.get("subitems").size
          ) {
            errors.push("Is missing lengths");
          }

          if (errors.length > 0) {
            const errorsItem = { [`Drawing #${id + 1}: `]: errors };
            errorsAll.push(errorsItem);
            return errors;
          }
        }

        return drawing;
      });
    }

    return { data: itemsForServer, errors: errorsAll };
  }

  generateOrderDataFromServer(drawingsData, itemsData, minLength) {
    const items = {};
    const itemsLength = {};

    drawingsData.forEach((drawing) => {
      const drawingUid = drawing.uid;
      if (!items[drawingUid]) {
        items[drawingUid] = {};
        itemsLength[drawingUid] = {
          total: 0,
          forPrice: 0,
        };
      }

      itemsData.forEach((item) => {
        if (item.drawing === drawingUid) {
          items[drawingUid] = {
            isDeleted: false,
            isCompleted: item.is_completed,
            uid: item.uid,
            tempUid: item.uid,
            index: items[drawingUid].length,
            subitems: item.subitems,
            drawingTempUid: drawingUid,
          };
        }
        return false;
      });
      itemsLength[drawingUid] = calcDrawingItemLength(
        items[drawingUid],
        minLength
      );
    });
    return { items, itemsLength };
  }

  createNewOrder = (_state) => {
    _state.merge(fromJS(this.getInitialState()));
  };

  setUids = (_state, { data, drawingsState }) => {
    drawingsState.forEach((drawing) => {
      const drawingTempUid = drawing.get("tempUid");

      if (drawing.get("uid") && !drawing.get("isDeleted")) {
        const item = _state.getIn(["items", drawingTempUid]);
        const itemUid = data[drawing.get("uid")];
        if (itemUid) {
          const itemWidthUid = item.set("uid", itemUid.items[0]);
          _state.setIn(["items", drawingTempUid], itemWidthUid);
        }
      }
    });
    if (drawingsState.size > 0 && _state.get("activeItems").size > 0) {
      const activeItemsDrawingTempUid = _state.getIn([
        "activeItems",
        "drawingTempUid",
      ]);
      _state.set(
        "activeItems",
        _state.getIn(["items", activeItemsDrawingTempUid])
      );
    }
  };

  openOrder = (_state, data) => {
    _state.merge(fromJS(this.getInitialState()));

    let { items, itemsLength } = data;
    const startStateDrawings = fromJS({
      items,
      itemsLength,
    });
    items = fromJS(items);
    itemsLength = fromJS(itemsLength);

    _state.set("items", items);
    _state.set("itemsLength", itemsLength);
    _state.set("startStateDrawings", startStateDrawings);
  };

  updateItemsThunk = ({ dispatch, getState, fulfilled }, options) => {
    if (typeof options === "object" && options !== null) {
      dispatch(prices.actions.changeDrawingPrice(true));
    }
    fulfilled(options);

    const minLength = getMinLengthFlashings(getState());
    dispatch(this.actionUpdateTotalLength(minLength));

    const drawingUid = getState().getIn([
      "drawings",
      "activeDrawing",
      "tempUid",
    ]);
    dispatch(prices.actions.setTotalPriceDrawing(drawingUid));
  };

  defineActions() {
    const clearActiveOrder = this.resetToInitialState(
      ACTIONS.CLEAR_ACTIVE_ORDER
    );
    const createNewOrder = this.createAction(ACTIONS.CREATE_NEW_ORDER);
    const setUids = this.createAction(ACTIONS.SET_UIDS);
    const openOrder = this.createAction(ACTIONS.OPEN_ORDER);

    const addItems = this.thunkAction(ACTIONS.ADD_ITEMS, this.updateItemsThunk);
    const deleteItems = this.thunkAction(
      ACTIONS.DELETE_ITEMS,
      this.updateItemsThunk
    );
    this.actionUpdateTotalLength = this.createAction(
      ACTIONS.UPDATE_TOTAL_LENGTH
    );

    const resetDrawings = this.createAction(ACTIONS.RESET_DRAWINGS);
    const setDataAfterSavingDrawings = this.createAction(
      ACTIONS.SET_DATA_AFTER_SAVING_DRAWINGS
    );
    const setDrawingTempUid = this.thunkAction(
      ACTIONS.SET_ACTIVE_DRAWING_TEMP_UID,
      this.updateItemsThunk
    );
    const addNewDrawing = this.createAction(ACTIONS.ADD_NEW_DRAWING);
    const duplicateDrawing = this.createAction(ACTIONS.DUPLICATE_DRAWING);
    const deleteDrawing = this.createAction(ACTIONS.DELETE_DRAWING);
    const undoDeleteDrawing = this.createAction(ACTIONS.UNDO_DELETE_DRAWING);

    const toggleDrawingCompletedStatus = this.createAction(
      ACTIONS.CHANGE_DRAWING_COMPLETED_STATUS
    );
    return {
      clearActiveOrder,
      createNewOrder,
      setUids,
      openOrder,

      addItems,
      deleteItems,

      resetDrawings,
      setDataAfterSavingDrawings,
      setDrawingTempUid,
      addNewDrawing,
      duplicateDrawing,
      deleteDrawing,
      undoDeleteDrawing,
      toggleDrawingCompletedStatus,
    };
  }

  resetDrawings = (_state) => {
    const startStateDrawings = _state.get("startStateDrawings");
    if (startStateDrawings) {
      _state.set("itemsLength", startStateDrawings.get("itemsLength"));
      _state.set("items", startStateDrawings.get("items"));
    } else {
      _state.set("items", fromJS([]));
      _state.set("itemsLength", fromJS({}));
    }
  };

  setDataAfterSavingDrawings = (_state) => {
    const startStateDrawings = _state.get("startStateDrawings");
    if (!startStateDrawings) {
      _state.set("startStateDrawings", fromJS({}));
    }

    _state.setIn(
      ["startStateDrawings", "itemsLength"],
      _state.get("itemsLength")
    );
    _state.setIn(["startStateDrawings", "items"], _state.get("items"));
  };

  addNewDrawing = (_state, newDrawingTempUid) => {
    _state.set("activeDrawingUid", newDrawingTempUid);
    const newDrawingItem = this.initialItemState();
    newDrawingItem.index = 0;
    newDrawingItem.subitems = [];
    newDrawingItem.drawingTempUid = newDrawingTempUid;

    _state.set("activeItems", fromJS(newDrawingItem));

    _state.setIn(
      ["itemsLength", newDrawingTempUid],
      fromJS({
        total: 0,
        forPrice: 0,
      })
    );
    _state.setIn(["items", newDrawingTempUid], fromJS({ ...newDrawingItem }));
  };

  setDrawingTempUid = (_state, newDrawingTempUid) => {
    _state.set("activeDrawingUid", newDrawingTempUid);

    if (newDrawingTempUid) {
      const items = _state.getIn(["items", newDrawingTempUid]);
      _state.set("activeItems", items);
    } else {
      _state.set("activeItems", fromJS({}));
    }
  };

  addItems = (_state, { amount, length }) => {
    const activeDrawingUid = _state.get("activeDrawingUid");
    const lastIndex = _state.getIn(["items"]).size;
    const drawingItemSize = _state.getIn([
      "items",
      activeDrawingUid,
      "subitems",
    ]);

    const newDrawingItem = this.initialItemState();
    newDrawingItem.index = lastIndex;
    newDrawingItem.subitems = [{ amount, length }];
    newDrawingItem.drawingTempUid = activeDrawingUid;

    if (drawingItemSize) {
      if (drawingItemSize.size >= 0) {
        const changeDrawingItem = _state.updateIn(
          ["items", activeDrawingUid, "subitems"],
          (list) => list.push(fromJS({ amount, length }))
        );
        _state.set(
          "activeItems",
          changeDrawingItem.getIn(["items", activeDrawingUid])
        );
      }
    } else {
      _state.setIn(["items"], fromJS({ [activeDrawingUid]: newDrawingItem }));
      _state.setIn(["activeItems"], fromJS(newDrawingItem));
    }
  };

  duplicateDrawing = (
    _state,
    { drawingTempUid, newDrawingTempUid, withLenghts }
  ) => {
    const newItem = this.initialItemState();
    newItem.drawingTempUid = newDrawingTempUid;
    newItem.subitems = [];
    newItem.uid = "";

    if (withLenghts) {
      const itemsLength = _state.getIn(["itemsLength", drawingTempUid]);
      _state.setIn(["items"], fromJS(newItem));
      _state.setIn(["itemsLength", newDrawingTempUid], itemsLength);
    } else {
      _state.setIn(["items", newDrawingTempUid], fromJS(newItem));
      _state.setIn(
        ["itemsLength", newDrawingTempUid],
        fromJS({ total: 0, forPrice: 0 })
      );
    }
  };

  deleteItems = (_state, payload) => {
    const { item: indexSubitems, uid: tempUid } = payload;
    if (indexSubitems >= 0) {
      _state.deleteIn(["items", tempUid, "subitems", indexSubitems]);
    } else {
      _state.setIn(["items", tempUid, "isDeleted"], true);
    }

    const activeItems = _state.getIn(["items", tempUid]);
    _state.set("activeItems", activeItems);
  };

  updateTotalLength = (_state, minLength) => {
    const activeItems = _state.get("activeItems");
    // if(!activeItems) return;
    const lengths = calcDrawingItemLength(activeItems.toJS(), minLength);

    const activeDrawingUid = _state.get("activeDrawingUid");
    _state.setIn(["itemsLength", activeDrawingUid], fromJS(lengths));
  };

  defineReducers() {
    return {
      [ACTIONS.CREATE_NEW_ORDER]: withMutations(this.createNewOrder),

      [ACTIONS.SET_UIDS]: withMutations(this.setUids),

      [ACTIONS.OPEN_ORDER]: withMutations(this.openOrder),

      [`${ACTIONS.ADD_ITEMS} fulfilled`]: withMutations(this.addItems),

      [`${ACTIONS.DELETE_ITEMS} fulfilled`]: withMutations(this.deleteItems),

      [ACTIONS.UPDATE_TOTAL_LENGTH]: withMutations(this.updateTotalLength),

      [ACTIONS.RESET_DRAWINGS]: withMutations(this.resetDrawings),

      [ACTIONS.SET_DATA_AFTER_SAVING_DRAWINGS]: withMutations(
        this.setDataAfterSavingDrawings
      ),

      [`${ACTIONS.SET_ACTIVE_DRAWING_TEMP_UID} fulfilled`]: withMutations(
        this.setDrawingTempUid
      ),

      [ACTIONS.ADD_NEW_DRAWING]: withMutations(this.addNewDrawing),

      [ACTIONS.DUPLICATE_DRAWING]: withMutations(this.duplicateDrawing),

      [ACTIONS.DELETE_DRAWING]: (
        state,
        { payload: { drawingTempUid, isNewDrawing } }
      ) => {
        if (isNewDrawing) {
          return state.deleteIn(["items", drawingTempUid]);
        }
        return state.setIn(["items", drawingTempUid, "isDeleted"], true);
      },

      [ACTIONS.UNDO_DELETE_DRAWING]: (
        state,
        { payload: { drawingTempUid, items, isNewDrawing } }
      ) => {
        if (isNewDrawing) {
          return state.setIn(["items", drawingTempUid], items);
        }
        return state.setIn(["items", drawingTempUid, "isDeleted"], false);
      },

      [ACTIONS.CHANGE_DRAWING_COMPLETED_STATUS]: (
        state,
        { payload: { drawingTempUid, value } }
      ) => state.setIn(["items", drawingTempUid, "isCompleted"], value),
    };
  }
}

const instance = new DrawingsItemsModule();
instance.init();

export default instance;
