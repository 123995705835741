import { createApi } from "./common";

const headers = {
  "Content-Type": "multipart/form-data",
};

export const searchCustomerOrders = (token, customerUid, search) =>
  createApi("GET", `customers/${customerUid}/orders/`)(token, { ...search });

export const searchCompanyOrders = (token, filters) =>
  createApi("POST", "orders/search")(token, filters);

export const exportOrders = (token, filters) => createApi("GET", "orders/export")(token, filters);

export const getCustomerOrder = (token, { customerUid, orderUid }) =>
  createApi("GET", `customers/${customerUid}/orders/${orderUid}/`)(token);

export const submitOrder = (token, customerId, order) =>
  createApi("POST", `customers/${customerId}/orders/`)(token, order);

export const patchCompletedItems = (token, customerId, orderUid, order) =>
  createApi("PATCH", `customers/${customerId}/orders/${orderUid}/patch_completed_items/`)(
    token,
    order
  );

export const patchCompletedKitItems = (token, customerId, orderUid, value) =>
  createApi("PATCH", `customers/${customerId}/orders/${orderUid}/patch_completed_kit_items/`)(
    token,
    value
  );

export const patchCompletedSubKitItems = (token, customerId, orderUid, value) =>
  createApi("PATCH", `customers/${customerId}/orders/${orderUid}/patch_completed_sub_kit_items/`)(
    token,
    value
  );

export const patchCompletedSubKits = (token, customerId, orderUid, value) =>
  createApi("PATCH", `customers/${customerId}/orders/${orderUid}/patch_completed_sub_kits/`)(
    token,
    value
  );

export const deleteOrder = (token, { customerUid, orderUid }) =>
  createApi("DELETE", `customers/${customerUid}/orders/${orderUid}`)(token);

export const unArchiveOrder = (token, customerId, orderUid) =>
  createApi("PATCH", `customers/${customerId}/orders/${orderUid}/undestroy/`)(token);

export const postPrintWorkOrderPdf = (token, data) =>
  createApi("POST", "order/print", { headers })(token, data);

export const postPrintDeliveryDocketPdf = (token, data) =>
  createApi("POST", "order/delivery_docket_print", { headers })(token, data);

export const loadOrderView = ({ orderUid, customerUid, ...data }) =>
  createApi("GET", `customers/${customerUid}/orders/${orderUid}/view`)(null, data);

export const getActualStatuses = createApi("GET", "order/status/");

export const postDuplicateOrder = (token, { customerUid, orderUid }) =>
  createApi("POST", `customers/${customerUid}/orders/${orderUid}/duplicate/`)(token);

export const postChangeCustomer = (token, { customerUid, orderUid, newCustomerUid }) =>
  createApi("POST", `customers/${customerUid}/orders/${orderUid}/change/${newCustomerUid}/`)(token);

export const postAssignUser = (token, data) =>
  createApi("POST", `planner/order_assignments/`)(token, data);

export const patchUpdateItemsIndex = (token, customerId, orderUid, products) =>
  createApi("PATCH", `customers/${customerId}/orders/${orderUid}/update_item_indexes/`)(
    token,
    products
  );

export const putUpdateSubKitsIndex = (token, customerId, orderUid, itemId, subKits) =>
  createApi(
    "PUT",
    `customers/${customerId}/orders/${orderUid}/items/${itemId}/update_sub_kit_indexes/`
  )(token, subKits);

export const putUpdateSubKitItemsIndex = (
  token,
  customerId,
  orderUid,
  itemId,
  subKitUid,
  kitItems
) =>
  createApi(
    "PUT",
    `customers/${customerId}/orders/${orderUid}/items/${itemId}/sub_kit/${subKitUid}/update_sub_kit_item_indexes/`
  )(token, kitItems);

export const putUpdateProductKitItemsIndex = (token, customerId, orderUid, itemId, kitItems) =>
  createApi(
    "PUT",
    `customers/${customerId}/orders/${orderUid}/items/${itemId}/update_kit_item_indexes/`
  )(token, kitItems);

export default searchCustomerOrders;
