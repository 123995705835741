import { uniq } from "lodash";

import { handleError } from "../utils/error";
import * as api from "../../apiv2/orderChecklists";
import * as alertActions from "../alerts/alerts.actions";
import * as orderChecklistActions from "../orderChecklists/orderChecklists.actions";

import * as selectors from "./orderChecklistTemplates.selectors";
import {
  SET_IS_TEMPLATE_MODAL_VISIBLE,
  SET_TEMPLATES,
  SET_TEMPLATES_FOR_SEARCH,
  SET_IS_FETCHING_TEMPLATES,
  SET_IS_OVERRIDING_TEMPLATE,
  SET_EXPANDED_TEMPLATE_UIDS,
  SET_TEMPLATE_TO_BE_ADDED_IN_ORDER,
  SET_IS_TEMPLATE_OVERRIDE_MODAL_VISIBLE,
  SET_TEMPLATE_TO_OVERRIDE,
  SET_ACTIVE_TEMPLATE,
  SET_IS_SAVING_TEMPLATE,
  RESET,
} from "./orderChecklistTemplates.actionTypes";

const flatten = (lists) =>
  lists.reduce((r, { subitems, ...rest }) => {
    r.push(rest);
    if (subitems) r.push(...flatten(subitems));
    return r;
  }, []);

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setIsTemplateModalVisible = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_TEMPLATE_MODAL_VISIBLE,
    payload: data,
  });
};

export const setIsFetchingTemplates = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_TEMPLATES,
    payload: data,
  });
};

export const setIsSavingTemplate = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_SAVING_TEMPLATE,
    payload: data,
  });
};

export const setTemplates = (data) => (dispatch) => {
  dispatch({
    type: SET_TEMPLATES,
    payload: data,
  });
};

export const setTemplatesForSearch = (data) => (dispatch) => {
  dispatch({
    type: SET_TEMPLATES_FOR_SEARCH,
    payload: data,
  });
};

export const setExpandedTemplateUids = (data) => (dispatch) => {
  dispatch({
    type: SET_EXPANDED_TEMPLATE_UIDS,
    payload: data,
  });
};

export const setTemplateToBeAddedInOrder = (data) => (dispatch) => {
  dispatch({
    type: SET_TEMPLATE_TO_BE_ADDED_IN_ORDER,
    payload: data,
  });
};

export const setIsTemplateOverrideModalVisible = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_TEMPLATE_OVERRIDE_MODAL_VISIBLE,
    payload: data,
  });
};

export const setIsOverridingTemplate = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_OVERRIDING_TEMPLATE,
    payload: data,
  });
};

export const setTemplateToOverride = (data) => (dispatch) => {
  dispatch({
    type: SET_TEMPLATE_TO_OVERRIDE,
    payload: data,
  });
};

export const setActiveTemplate = (data) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_TEMPLATE,
    payload: data,
  });
};

export const fetchTemplates = () => async (dispatch) => {
  try {
    dispatch(setIsFetchingTemplates(true));
    const { data } = await api.getChecklistTemplates()();
    dispatch(setTemplates(data));
    dispatch(setTemplatesForSearch(data));
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetchingTemplates(false));
  }
};

export const createTemplate = (checklist) => async (dispatch) => {
  try {
    const { data } = await api.getChecklistTemplates()();
    const templateNames = data.map((t) => t.name.toLowerCase().trim());

    if (templateNames.includes(checklist.name.toLowerCase().trim())) {
      dispatch(setIsTemplateOverrideModalVisible(true));
      const template = data.find(
        (d) => d.name.toLowerCase().trim() === checklist.name.toLowerCase().trim()
      );

      if (template) {
        dispatch(setTemplateToOverride(template));
      }

      return;
    }

    dispatch(setIsSavingTemplate(true));
    await api.postCreateTemplate(checklist.uid, { is_template: true })();
    dispatch(alertActions.createAlertAction(checklist.uid, "Template saved.", true, "success"));
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsSavingTemplate(false));
  }
};

export const attachTemplateToOrder = (uid, payload) => async (dispatch, getState) => {
  try {
    const state = getState();
    const templates = selectors.getTemplates(state);
    const getTemplateByUid = templates.find((t) => t.uid === uid);

    dispatch(setTemplateToBeAddedInOrder(uid));

    if (getTemplateByUid) {
      const { data } = await api.postCreateTemplate(uid, payload)();
      const { data: checklist } = await api.getChecklist(data)();
      dispatch(orderChecklistActions.appendChecklist(checklist));
      dispatch(setIsTemplateModalVisible(false));
    }
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setTemplateToBeAddedInOrder(null));
  }
};

export const removeTemplate = (uid) => (dispatch, getState) => {
  try {
    const state = getState();
    const templates = selectors.getTemplates(state);

    dispatch(setTemplates(templates.filter((c) => c.uid !== uid)));

    api.deleteChecklist(uid)();

    dispatch(alertActions.createAlertAction(uid, "Template deleted", true, "danger"));
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const overrideTemplate =
  ({ sourceUid, targetUid }) =>
  async (dispatch) => {
    try {
      dispatch(setIsOverridingTemplate(true));

      await api.postOverrideTemplate({
        source: sourceUid,
        target: targetUid,
      })();
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(setIsOverridingTemplate(false));
      dispatch(setIsTemplateOverrideModalVisible(false));
      dispatch(setActiveTemplate(null));
      dispatch(setTemplateToOverride(null));
    }
  };

export const searchTemplates = (search) => (dispatch, getState) => {
  const state = getState();
  const templatesForSearch = selectors.getTemplatesForSearch(state);

  const flattenTemplateItems = flatten(templatesForSearch.reduce((a, b) => a.concat(b.items), []));

  const filteredTemplates =
    search.length > 0
      ? templatesForSearch.filter((t) => t.name.toLowerCase().includes(search.toLowerCase()))
      : [];

  const filteredTemplateItems =
    search.length > 0
      ? flattenTemplateItems.filter((t) => t.text.toLowerCase().includes(search.toLowerCase()))
      : [];

  const filteredTemplateUids = filteredTemplates.map((t) => t.uid);
  const filteredTemplateItemUids = filteredTemplateItems.map((t) => t.checklistUid);
  const combinedUids = uniq([...filteredTemplateUids, ...filteredTemplateItemUids]);

  if (combinedUids.length > 0) {
    dispatch(setTemplates(templatesForSearch.filter((t) => combinedUids.includes(t.uid))));
    dispatch(setExpandedTemplateUids(combinedUids));
    return;
  }

  if (combinedUids.length === 0 && search.length > 0) {
    dispatch(setExpandedTemplateUids([]));
    dispatch(setTemplates([]));
  }

  if (search.length === 0) {
    dispatch(setExpandedTemplateUids(templatesForSearch.map((t) => t.uid)));
    dispatch(setTemplates(templatesForSearch));
  }
};
