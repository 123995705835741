import createActionType from "../utils/createActionType";

export const MODULE = "order/add-labour-line-item";

export const SET_IS_MODAL_VISIBLE = createActionType(MODULE, "setIsModalVisible");

export const SET_TEAM_MEMBERS = createActionType(MODULE, "setTeamMembers");

export const SET_IS_FETCHING = createActionType(MODULE, "setIsFetching");

export const SET_TARGET_PRODUCT_TYPE = createActionType(MODULE, "setTargetProductType");

export const SET_TARGET_PRODUCT_KIT = createActionType(MODULE, "setTargetProductKit");

export const SET_ACTIVE_LINE_ITEM = createActionType(MODULE, "setActiveLineItem");

export const RESET = createActionType(MODULE, "reset");
