// All Order Actions
export const HIDE_ALL_ORDER_COLUMNS = "HIDE_ALL_ORDERS_COLUMS";
export const REORDER_ALL_ORDER_COLUMNS = "REORDER_ALL_ORDER_COLUMNS";
export const UPDATE_WIDTH_ALL_ORDER_COLUMNS = "UPDATE_WIDTH_ALL_ORDER_COLUMNS";

// Customer Order Actions
export const HIDE_CUSTOMER_ORDER_COLUMNS = "HIDE_CUSTOMER_ORDERS_COLUMS";
export const REORDER_CUSTOMER_ORDER_COLUMNS = "REORDER_CUSTOMER_ORDER_COLUMNS";
export const UPDATE_WIDTH_CUSTOMER_ORDER_COLUMNS =
  "UPDATE_WIDTH_CUSTOMER_ORDER_COLUMNS";
