import { handleError } from "@/redux/utils/error";
import { STATUS } from "@/utils/constants";
import * as teamsApi from "@/apiv2/teams";
import * as timesheetsApi from "@/apiv2/timesheets";
import * as timeTrackingApi from "@/apiv2/timeTracking";
import * as orderTracking from "@/apiv2/orderTracking";
import * as plannerApi from "@/apiv2/planner/calendar";
import * as customerSelectors from "@/redux/customers/customers.selectors";
import {
  SET_IS_SAVING_STATUS,
  SET_IS_FETCHING_TEAM_MEMBERS_STATUS,
  SET_TEAM_MEMBERS,
  SET_IS_SEARCHING_ORDER_NUMBER_STATUS,
  SET_ORDER_NUMBER_RESULTS,
  SET_ORDER_ITEMS,
  SET_IS_FETCHING_ORDER_ITEMS_STATUS,
  RESET,
} from "./timeEntry.actionTypes";

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};
export const setIsSavingStatus = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_SAVING_STATUS,
    payload: data,
  });
};

export const setIsFetchingTeamMembersStatus = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_TEAM_MEMBERS_STATUS,
    payload: data,
  });
};

export const setTeamMembers = (data) => (dispatch) => {
  dispatch({
    type: SET_TEAM_MEMBERS,
    payload: data,
  });
};

export const setIsSearchingOrderNumberStatus = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_SEARCHING_ORDER_NUMBER_STATUS,
    payload: data,
  });
};

export const setIsFetchingOrderItemsStatus = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_ORDER_ITEMS_STATUS,
    payload: status,
  });
};

export const setOrderNumberResults = (data) => (dispatch) => {
  dispatch({
    type: SET_ORDER_NUMBER_RESULTS,
    payload: data,
  });
};

export const setOrderItems = (payload) => (dispatch) => {
  dispatch({
    type: SET_ORDER_ITEMS,
    payload,
  });
};

export const fetchTeamMembers = () => async (dispatch) => {
  try {
    dispatch(setIsFetchingTeamMembersStatus(STATUS.PENDING));

    const { data } = await teamsApi.getTeams({
      with_inactive: false,
      include_invites: false,
    })();
    dispatch(setTeamMembers(data));
    dispatch(setIsFetchingTeamMembersStatus(STATUS.FULFILLED));
  } catch (error) {
    dispatch(setIsFetchingTeamMembersStatus(STATUS.REJECTED));
    handleError(error, dispatch);
  }
};

export const searchOrderNumbers = (orderNumber) => async (dispatch) => {
  try {
    dispatch(setIsSearchingOrderNumberStatus(STATUS.PENDING));
    const { data } = await timesheetsApi.getOrderNumbers({ q: orderNumber })();
    dispatch(setOrderNumberResults(data));
    dispatch(setIsSearchingOrderNumberStatus(STATUS.FULFILLED));
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const fetchOrderItems =
  ({ customerUid, orderUid }) =>
  async (dispatch) => {
    try {
      dispatch(setIsFetchingOrderItemsStatus(STATUS.PENDING));
      const { data } = await orderTracking.getLineItemAdditionalProducts({
        customerUid,
        orderUid,
      })();
      dispatch(setOrderItems(data));
      dispatch(setIsFetchingOrderItemsStatus(STATUS.FULFILLED));
    } catch (error) {
      handleError(error, dispatch);
      dispatch(setIsFetchingOrderItemsStatus(STATUS.REJECTED));
    }
  };

export const saveTimeEntry =
  ({ data, plannerData }) =>
  async (dispatch, getState) => {
    const state = getState();
    const shouldCreatePlannerEntry =
      customerSelectors.getTimeTrackingSettings(state, {
        key: "AUTO_CREATE_PLANNER_ENTRIES",
      })?.isSelected ?? false;

    try {
      dispatch(setIsSavingStatus(STATUS.PENDING));

      const response = await timeTrackingApi.postTimeEntryV2({ data })();

      if (shouldCreatePlannerEntry && data.order !== "") {
        const plannerEntryPayload = {
          ...plannerData,
          time_tracking_event: response.data.uid,
          order: data.order,
          user: data.user,
        };
        await plannerApi.postAssignSchedule(plannerEntryPayload)();
      }

      dispatch(setIsSavingStatus(STATUS.FULFILLED));
      dispatch(setModalType(""));

      return response.data;
    } catch (error) {
      dispatch(setIsSavingStatus(STATUS.REJECTED));
      handleError(error, dispatch);
    }

    return null;
  };

export const updateTimeEntry =
  ({ customerUid, orderUid, timeTrackingUid, data, plannerData } = {}) =>
  async (dispatch, getState) => {
    const state = getState();
    const shouldCreatePlannerEntry =
      customerSelectors.getTimeTrackingSettings(state, {
        key: "AUTO_CREATE_PLANNER_ENTRIES",
      })?.isSelected ?? false;

    try {
      dispatch(setIsSavingStatus(STATUS.PENDING));

      const payload = { ...data };
      const response = await timeTrackingApi.patchTimeEntryV2({
        customerUid,
        orderUid,
        timeTrackingUid,
        data: payload,
      })();

      if (shouldCreatePlannerEntry && data.planner) {
        const plannerEntryPayload = {
          ...plannerData,
          time_tracking_event: response.data.uid,
          uid: data.planner,
          user: data.user,
        };
        await plannerApi.patchAssignSchedule({ uid: data.planner, data: plannerEntryPayload })();
      }

      dispatch(setIsSavingStatus(STATUS.FULFILLED));
      dispatch(setModalType(""));
      return response.data;
    } catch (error) {
      dispatch(setIsSavingStatus(STATUS.REJECTED));
      handleError(error, dispatch);
    }

    return null;
  };

export const removeTimeEntry =
  ({ customerUid, orderUid, uid }) =>
  async (dispatch) => {
    try {
      await timeTrackingApi.deleteTimeEntry({ customerUid, orderUid, uid })();
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const removePlannerEntry = (uid) => async () => {
  try {
    await plannerApi.deleteAssignSchedule(uid)();
  } catch (error) {
    handleError(error, dispatch);
  }
};
