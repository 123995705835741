import qs from "query-string";

import { createApi } from "./common";

const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
};

export const tokenAuth = (token, data) => createApi("POST", "token/", { headers })(token, data);

export const tokenVerify = (token, data) => createApi("POST", "token/", { headers })(token, data);

export const logout = (data) => createApi("POST", "revoke_token/", { headers })(null, data);

export const googleLogin = (token, data) =>
  createApi("POST", "google/login/", { headers })(token, data);

export const microsoftLogin = (token, data) =>
  createApi("POST", "microsoft/login/", { headers })(token, data);

export const postGoogleToken = (token, data) => createApi("POST", "google/token/")(token, data);

export const getXeroAuthorizationUrl = (data) => () =>
  createApi("GET", `xero/authorization_url/?${qs.stringify(data)}`)();

export const getXeroToken = (data) => createApi("GET", `xero/token/?${qs.stringify(data)}`)();

export const xeroLogin = (token, data) => createApi("POST", "xero/login/")(token, data);
