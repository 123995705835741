import {
  SET_SEARCH,
  SET_FILTERS,
  SET_IS_FETCHING_TIMELINES,
  SET_IS_FETCHING_TIMELINE_DETAILS,
  SET_TIMELINES,
  SET_TIMELINE_DETAILS,
  RESET,
} from "./orderTimeline.actionTypes";

const initialState = {
  search: "",
  filters: [],
  isFetchingTimelines: false,
  isFetchingTimelineDetails: false,
  timelines: [],
  timelineDetails: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH: {
      return {
        ...state,
        search: action.payload,
      };
    }

    case SET_FILTERS: {
      return {
        ...state,
        filters: action.payload,
      };
    }

    case SET_IS_FETCHING_TIMELINES: {
      return {
        ...state,
        isFetchingTimelines: action.payload,
      };
    }

    case SET_IS_FETCHING_TIMELINE_DETAILS: {
      return {
        ...state,
        isFetchingTimelineDetails: action.payload,
      };
    }

    case SET_TIMELINES: {
      const { data } = action.payload;

      return {
        ...state,
        timelines: data,
      };
    }

    case SET_TIMELINE_DETAILS: {
      const { data } = action.payload;


      return {
        ...state,
        timelineDetails: data,
      };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
