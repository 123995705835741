import createActionType from "@/redux/utils/createActionType";

export const MODULE = "timesheets";

export const RESET = createActionType(MODULE, "reset");

export const SET_IS_FETCHING_SUMMARY_STATUS = createActionType(
  MODULE,
  "setIsFetchingSummaryStatus"
);

export const SET_IS_UPDATING_TIMESHEET_STATUS = createActionType(
  MODULE,
  "setIsUpdatingTimesheetStatus"
);

export const SET_MODAL_TYPE = createActionType(MODULE, "setModalType");

export const SET_SUMMARY = createActionType(MODULE, "setSummary");

export const SET_FROM_DATE = createActionType(MODULE, "setFromDate");

export const SET_TO_DATE = createActionType(MODULE, "setToDate");

export const SET_DATES = createActionType(MODULE, "setDates");

export const SET_SELECTED_USER_IDS = createActionType(MODULE, "setSelectedUserIds");

export const SET_ACTIVE_USER = createActionType(MODULE, "setActiveUser");
