import qs from "query-string";

import { createApiV2 } from "./common";

export const getCustomerLists = (params) => (token) =>
  createApiV2("GET", `customers/?${qs.stringify(params)}`)(token);

export const getUsers = (params) => (token) =>
  createApiV2("GET", `users/?${qs.stringify(params)}`)(token);

export const getCustomer = (customerUid) => (token) =>
  createApiV2("GET", `customers/${customerUid}/`)(token);

export const getCustomerContacts =
  ({ customerUid, contactSearch }) =>
  (token) =>
    createApiV2("GET", `customers/${customerUid}/?contact_search=${contactSearch}`)(token);

export const postCustomer = (data) => (token) => createApiV2("POST", "customers/")(token, data);

export const changeCustomerContacts =
  ({ customerUid, data }) =>
  (token) =>
    createApiV2("PUT", `customers/${customerUid}/`)(token, data);

export const changeCustomerData =
  ({ customerUid, data }) =>
  (token) =>
    createApiV2("PUT", `customers/${customerUid}/`)(token, data);

export const getUserV2 = () => () => createApiV2("GET", "customer/userdata/")();
