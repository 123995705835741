import React, { useCallback } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { debounce } from "lodash";

import { PhoneInput as BasePhoneInput } from "@/ui-lib/FormFields";

import styles from "./PhoneInput.module.scss";

const PhoneInput = ({
  setFieldValue,
  withoutError,
  onChange,
  onBlur,
  values,
  placeholder,
  invalid,
  international,
  initialValueFormat,
  id,
  errors,
  defaultCountry,
  containerClassName,
  className,
}) => {
  const error = !!(errors[id] || invalid) && !withoutError;

  const handleChange = useCallback(
    debounce((e) => {
      const { value } = e.target;

      if (onChange) {
        onChange(id, value);
      }
    }, 500),
    []
  );

  return (
    <div
      className={cx(
        styles.inputContainer,
        error && errors[id] ? styles.inputError : "",
        containerClassName
      )}
    >
      <BasePhoneInput
        international={international}
        initialValueFormat={initialValueFormat}
        defaultCountry={defaultCountry}
        id={id}
        className={className}
        placeholder={placeholder}
        value={values[id]}
        onChange={(value) => {
          setFieldValue(id, value || "");
          handleChange({ target: { value } });
        }}
        error={!withoutError ? errors[id] || invalid : ""}
      />
      {error && errors[id] && <div className={styles.errorMessage}>{errors[id]}</div>}
    </div>
  );
};

PhoneInput.defaultProps = {
  setFieldValue: () => null,
  onChange: () => null,
  onBlur: () => null,
  values: {},
  withoutError: false,
  placeholder: "",
  international: false,
  initialValueFormat: "national",
  invalid: false,
  errors: {},
  defaultCountry: "AU",
  containerClassName: "",
  className: "",
};

PhoneInput.propTypes = {
  setFieldValue: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  international: PropTypes.bool,
  initialValueFormat: PropTypes.string,
  id: PropTypes.string.isRequired,
  values: PropTypes.shape({}),
  withoutError: PropTypes.bool,
  placeholder: PropTypes.string,
  invalid: PropTypes.bool,
  errors: PropTypes.shape({}),
  defaultCountry: PropTypes.string,
  containerClassName: PropTypes.string,
  className: PropTypes.string,
};

export default PhoneInput;
