import qs from "query-string";

import { createApiV2 } from "./common";

export const getGlobalSearchSuppliers = (params) => (token) =>
  createApiV2("GET", `global_search/suppliers/?${qs.stringify(params)}`, {
    useApiGatewayUrl: true,
  })(token);

export const getGlobalSearchCustomers = (params) => (token) =>
  createApiV2("GET", `global_search/customers/?${qs.stringify(params)}`, {
    useApiGatewayUrl: true,
  })(token);

export const getGlobalSearchSales = (params) => (token) =>
  createApiV2("GET", `global_search/sales_orders/?${qs.stringify(params)}`, {
    useApiGatewayUrl: true,
  })(token);

export const getGlobalSearchPurchases = (params) => (token) =>
  createApiV2("GET", `global_search/purchase_orders/?${qs.stringify(params)}`, {
    useApiGatewayUrl: true,
  })(token);

export const getGlobalSearchProducts = (params) => (token) =>
  createApiV2("GET", `global_search/products/?${qs.stringify(params)}`, {
    useApiGatewayUrl: true,
  })(token);

export const getGlobalSearchAll = (params) => (token) =>
  createApiV2("GET", `global_search/search/?${qs.stringify(params)}`, {
    useApiGatewayUrl: true,
  })(token);

export const getGlobalSearchProductsForOrder = (params) => (token) =>
  createApiV2("GET", `global_search/search_products/?${qs.stringify(params)}`, {
    useApiGatewayUrl: true,
  })(token);

export const getGlobalSearchOrderItems = (params) => (token) =>
  createApiV2("GET", `global_search/items/?${qs.stringify(params)}`, {
    useApiGatewayUrl: true,
  })(token);

export default {};
