export const mainModalStyles = {
  content: {
    padding: "2rem 2rem",
    boxShadow: "inset 0 -1px 0 0 #eaecf0",
    backgroundColor: "#fff",
    borderRadius: "20px",
    overflowY: "auto",
    maxWidth: "400px",
  },
  overlay: {
    zIndex: 1051,
  },
};

export default {};
