import createActionType from "../../utils/createActionType";

export const MODULE = "stocktake/supplierFilter";

export const SET_IS_FETCHING = createActionType(MODULE, "setIsFetching");

export const SET_SUPPLIERS = createActionType(MODULE, "setSuppliers");

export const SET_SELECTED_SUPPLIERS = createActionType(
  MODULE,
  "setSelectedSuppliers"
);

export const RESET = createActionType(MODULE, "reset");
