import {
  GET_ALL_SUPPLIER_PRODUCTS,
  DELETE_SUPPLIER_PRODUCT,
} from "./supplierProducts.actionTypes";

const initialState = {
  supplierProducts: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    // GET ALL SUPPLIER PRODUCTS //
    case `${GET_ALL_SUPPLIER_PRODUCTS}/fulfilled`: {
      const { data } = action.payload;

      return {
        ...state,
        supplierProducts: data,
      };
    }

    // DELETE SUPPLIER PRODUCT //
    case `${DELETE_SUPPLIER_PRODUCT}/fulfilled`: {
      const { data } = action.payload;

      const deletedSupplierProductUid = data.uid;

      const newSupplierProducts = [...state.supplierProducts].filter(
        (supplierProduct) => supplierProduct.uid !== deletedSupplierProductUid
      );

      return {
        ...state,
        supplierProducts: newSupplierProducts,
      };
    }

    default:
      return state;
  }
};
