import { combineReducers } from "redux-immutable";
import { connectRouter } from "connected-react-router";

import { createNamespacedReducer } from "@/redux/utils/reducer";

import history from "../app/history";

import auth from "./modules/auth";
import customers from "./modules/customers";
import orders from "./modules/orders";
import prices from "./modules/prices";
import drawings from "./modules/drawings";
import drawingsProducts from "./modules/drawings-products";
import drawingsItems from "./modules/drawings-items";
import additionalProducts from "./modules/additional-products";
import flashingProducts from "./modules/flashing-products";
import alerts from "./modules/alerts";
import materials from "./modules/materials";
import cards from "./modules/cards";
import calendar from "./modules/calendar";
import websockets from "./modules/websockets";
import collaborationNotes from "./modules/collaboration-notes";
import purchase from "./modules/purchase";
import preferencesReducer from "./preferences/preferences.reducer";
import { preferencesActions } from "./preferences/preferences.actions";
import suppliersReducer from "./suppliers/suppliers.reducer";
import * as productsActions from "./products/products.actions";
import productsReducer from "./products/products.reducer";
import materialsReducer from "./materials/materials.reducer";
import supplierProductsReducer from "./supplierProducts/supplierProducts.reducer";
import purchaseOrdersReducer from "./purchaseOrders/purchaseOrders.reducer";
import customersReducer from "./customers/customers.reducer";
import linkedOrdersReducer from "./linkedOrders/linkedOrders.reducer";
import authenticationReducer from "./authentication/authentication.reducer";
import * as authenticationActions from "./authentication/authentication.actions";
import supplierSettingsReducer from "./suppliers-settings/suppliers-settings.reducer";
import purchaseProductsModalReducer from "./purchaseProductsModal/purchaseProductsModal.reducer";
import customWorkOrderPrintModal from "./customWorkOrderPrintModal/customWorkOrderPrintModal.reducer";
import customDeliveryDocketPrintModal from "./customDeliveryDocketPrintModal/customDeliveryDocketPrintModal.reducer";
import * as signup from "./signup";
import * as accounting from "./accounting";
import * as productCatalogue from "./productCatalogue";
import * as stocktake from "./stocktake";
import * as stocktakeSupplierFilter from "./stocktake/supplierFilter";
import * as stocktakeSearchFilter from "./stocktake/searchFilter";
import * as productKits from "./productKits";
import * as workflow from "./workflow";
import * as workflowSearchFilter from "./workflow/searchFilter";
import * as workflowSortFilter from "./workflow/sortFilter";
import * as workflowUserFilter from "./workflow/userFilter";
import * as workflowLabelFilter from "./workflow/labelFilter";
import * as orderAttachments from "./orderAttachments";
import * as orderChecklists from "./orderChecklists";
import * as orderChecklistTemplates from "./orderChecklistTemplates";
import * as orderTimeline from "./orderTimeline";
import * as orderTracking from "./orderTracking";
import * as timeTracking from "./orderTracking/timeTracking";
import * as hoursWorked from "./orderTracking/timeTracking/hoursWorked";
import * as hoursScheduled from "./orderTracking/timeTracking/hoursScheduled";
import * as activeTimeEntries from "./orderTracking/timeTracking/activeTimeEntries";
import * as timesheets from "./timesheets";
import * as individualTimesheets from "./timesheets/individualTimesheets";
import * as timeheetsTimeEntry from "./timesheets/timeEntry";
import * as pinConfirmationTimesheets from "./timesheets/pinConfirmation";
import * as orderAddLabourLineItem from "./orderAddLabourLineItem";
import * as partialInvoice from "./partialInvoice";
import * as users from "./users";
import * as views from "./views";
import * as labels from "./labels";
import * as assignees from "./assignees";
import * as planner from "./planner";
import * as plannerCalendar from "./planner/calendar";
import * as plannerOrders from "./planner/orders";
import * as plannerUserFilter from "./planner/userFilter";
import * as plannerLabelFilter from "./planner/labelFilter";
import * as plannerSearchFilter from "./planner/searchFilter";
import * as plannerShowHideOrderFilter from "./planner/showHideOrderFilter";
import * as priceLevels from "./priceLevels";
import * as deliveryScheduling from "./deliveryScheduling";
import * as purchaseOrderTimeline from "./purchaseOrderTimeline";
import * as importSuppliers from "./importSuppliers";
import * as importCustomers from "./importCustomers";
import * as forgotPassword from "./forgotPassword";
import * as dashboard from "./dashboard";
import * as addWidgets from "./dashboard/add-widgets";
import * as globalSearch from "./globalSearch";
import * as teamsSettings from "./settings/teams";
import * as billingSettings from "./settings/billing";
import * as collaboration from "./collaboration";
import * as addProductToOrderInline from "./add-product-to-order-inline";
import * as purchaseOrderProductLists from "./purchaseOrders/productLists";
import * as addLabourLineItems from "./add-labour-line-items";
import * as timezones from "./timezones";
import * as notifications from "./notifications";
import alertsReducer from "./alerts/alerts.reducer";
import loadingReducer from "./loading/loading.reducer";

export const allReducers = combineReducers({
  // OLD REDUCERS //
  alerts: alerts.reducers,
  customers: customers.reducers,
  orders: orders.reducers,
  prices: prices.reducers,
  drawings: drawings.reducers,
  drawingsProducts: drawingsProducts.reducers,
  drawingsItems: drawingsItems.reducers,
  additionalProducts: additionalProducts.reducers,
  flashingProducts: flashingProducts.reducers,
  materials: materials.reducers,
  cards: cards.reducers,
  calendar: calendar.reducers,
  websockets: websockets.reducers,
  collaborationNotes: collaborationNotes.reducers,
  purchase: purchase.reducers,

  // NEW REDUCERS //
  [signup.MODULE]: signup.reducer,
  authentication: authenticationReducer,
  preferences: preferencesReducer,
  suppliers: suppliersReducer,
  products: productsReducer,
  materialsv2: materialsReducer,
  supplierProducts: supplierProductsReducer,
  purchaseOrders: purchaseOrdersReducer,
  customersv2: customersReducer,
  salesOrders: linkedOrdersReducer,
  supplierSettings: supplierSettingsReducer,
  purchaseProductsModal: purchaseProductsModalReducer,
  customWorkOrderPrintModal,
  customDeliveryDocketPrintModal,
  [accounting.MODULE]: accounting.reducer,
  [productCatalogue.MODULE]: productCatalogue.reducer,
  [stocktake.MODULE]: stocktake.reducer,
  [stocktakeSupplierFilter.MODULE]: stocktakeSupplierFilter.reducer,
  [stocktakeSearchFilter.MODULE]: stocktakeSearchFilter.reducer,
  [productKits.MODULE]: productKits.reducer,
  [workflow.MODULE]: workflow.reducer,
  [workflowSearchFilter.MODULE]: workflowSearchFilter.reducer,
  [workflowSortFilter.MODULE]: workflowSortFilter.reducer,
  [workflowUserFilter.MODULE]: workflowUserFilter.reducer,
  [workflowLabelFilter.MODULE]: workflowLabelFilter.reducer,
  [orderAttachments.MODULE]: orderAttachments.reducer,
  [orderChecklists.MODULE]: orderChecklists.reducer,
  [orderChecklistTemplates.MODULE]: orderChecklistTemplates.reducer,
  [orderTimeline.MODULE]: orderTimeline.reducer,
  [orderTracking.MODULE]: orderTracking.reducer,
  [timeTracking.MODULE]: timeTracking.reducer,
  [hoursWorked.MODULE]: hoursWorked.reducer,
  [hoursScheduled.MODULE]: hoursScheduled.reducer,
  [activeTimeEntries.MODULE]: activeTimeEntries.reducer,
  [timesheets.MODULE]: timesheets.reducer,
  [individualTimesheets.MODULE]: individualTimesheets.reducer,
  [pinConfirmationTimesheets.MODULE]: pinConfirmationTimesheets.reducer,
  [timeheetsTimeEntry.MODULE]: timeheetsTimeEntry.reducer,
  [orderAddLabourLineItem.MODULE]: orderAddLabourLineItem.reducer,
  [purchaseOrderTimeline.MODULE]: purchaseOrderTimeline.reducer,
  [partialInvoice.MODULE]: partialInvoice.reducer,

  [views.MODULE]: views.reducer,
  [users.MODULE]: users.reducer,
  [labels.MODULE]: labels.reducer,
  [assignees.MODULE]: assignees.reducer,
  [planner.MODULE]: planner.reducer,
  [plannerCalendar.MODULE]: plannerCalendar.reducer,
  [plannerOrders.MODULE]: plannerOrders.reducer,
  [plannerUserFilter.MODULE]: plannerUserFilter.reducer,
  [plannerLabelFilter.MODULE]: plannerLabelFilter.reducer,
  [plannerSearchFilter.MODULE]: plannerSearchFilter.reducer,
  [plannerShowHideOrderFilter.MODULE]: plannerShowHideOrderFilter.reducer,
  [priceLevels.MODULE]: priceLevels.reducer,
  ...deliveryScheduling.reducer(),
  [importSuppliers.MODULE]: importSuppliers.reducer,
  [importCustomers.MODULE]: importCustomers.reducer,
  [forgotPassword.MODULE]: forgotPassword.reducer,
  [dashboard.MODULE]: dashboard.reducer,
  [addWidgets.MODULE]: addWidgets.reducer,
  [teamsSettings.MODULE]: teamsSettings.reducer,
  [billingSettings.MODULE]: billingSettings.reducer,
  [addProductToOrderInline.MODULE]: addProductToOrderInline.reducer,
  [purchaseOrderProductLists.MODULE]: purchaseOrderProductLists.reducer,
  [addLabourLineItems.MODULE]: addLabourLineItems.reducer,
  [timezones.MODULE]: timezones.reducer,
  [notifications.MODULE]: notifications.reducer,
  alertsv2: alertsReducer,
  loading: loadingReducer,
  router: connectRouter(history),
  ...createNamespacedReducer({
    reducer: globalSearch.reducer,
    module: globalSearch.MODULE,
    namespace: "globalSearch",
  }),
  ...createNamespacedReducer({
    reducer: globalSearch.reducer,
    module: globalSearch.MODULE,
    namespace: "salesOrder",
  }),
  ...createNamespacedReducer({
    reducer: globalSearch.reducer,
    module: globalSearch.MODULE,
    namespace: "purchaseOrder",
  }),
  ...createNamespacedReducer({
    reducer: collaboration.reducer,
    module: collaboration.MODULE,
    namespace: collaboration.constants.NAMESPACES.ORDER,
  }),
  ...createNamespacedReducer({
    reducer: collaboration.reducer,
    module: collaboration.MODULE,
    namespace: collaboration.constants.NAMESPACES.PURCHASE_ORDER,
  }),
  ...createNamespacedReducer({
    reducer: collaboration.reducer,
    module: collaboration.MODULE,
    namespace: collaboration.constants.NAMESPACES.SUPPLIER,
  }),
  ...createNamespacedReducer({
    reducer: collaboration.reducer,
    module: collaboration.MODULE,
    namespace: collaboration.constants.NAMESPACES.CUSTOMER,
  }),
  ...createNamespacedReducer({
    reducer: collaboration.reducer,
    module: collaboration.MODULE,
    namespace: collaboration.constants.NAMESPACES.PRODUCT,
  }),
});

export const rootActions = Object.assign(
  {},
  { auth: auth.actions },
  { authentication: authenticationActions },
  { alerts: alerts.actions },
  { customers: customers.actions },
  { orders: orders.actions },
  { prices: prices.actions },
  { drawings: drawings.actions },
  { drawingsProducts: drawingsProducts.actions },
  { drawingsItems: drawingsItems.actions },
  { additionalProducts: additionalProducts.actions },
  { flashingProducts: flashingProducts.actions },
  { materials: materials.actions },
  { cards: cards.actions },
  { calendar: calendar.actions },
  { websockets: websockets.actions },
  { collaborationNotes: collaborationNotes.actions },
  { purchase: purchase.actions },
  { preferences: preferencesActions },
  { workflow: workflow.actions },
  { products: productsActions },
  { productKits: productKits.actions },
  { orderTimeline: orderTimeline.actions },
  { orderChecklists: orderChecklists.actions },
  { orderChecklistTemplates: orderChecklistTemplates.actions },
  { orderAddLabourLineItem: orderAddLabourLineItem.actions },
  { purchaseOrderTimeline: purchaseOrderTimeline.actions },
  { addProductToOrderInline: addProductToOrderInline.actions }
);

export const rootReducers = (state, action) => {
  if (action.type === "RESET_APP") {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return allReducers(state, action);
};
