import { createApiV2 } from "./common";

const headers = {
  "Content-Type": "multipart/form-data",
};

export const postLabel = (data) => (token) => {
  return createApiV2("POST", "planner/labels/")(token, data);
};

export const patchLabel =
  ({ data, uid }) =>
  (token) => {
    return createApiV2("PATCH", `planner/labels/${uid}/`)(token, data);
  };

export const getLabels = () => (token) => {
  return createApiV2("GET", "planner/labels/")(token);
};

export const postAssignLabelsToOrder = (data) => (token) => {
  return createApiV2("POST", "planner/labels/assign/")(token, data);
};

export const deleteLabel = (uid) => (token) => {
  return createApiV2("DELETE", `planner/labels/${uid}/`)(token);
};
