import { STATUS } from "@/utils/constants";

import {
  SET_IS_MODAL_VISIBLE,
  SET_TEAM_MEMBERS,
  SET_IS_FETCHING,
  SET_TARGET_PRODUCT_TYPE,
  SET_TARGET_PRODUCT_KIT,
  SET_ACTIVE_LINE_ITEM,
  RESET,
} from "./orderAddLabourLineItem.actionTypes";

const initialState = {
  isModalVisible: "",
  isFetching: STATUS.IDLE,
  teamMembers: [],
  targetProductType: "",
  targetProductKit: null,
  activeLineItem: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_MODAL_VISIBLE: {
      return {
        ...state,
        isModalVisible: action.payload,
      };
    }

    case SET_TEAM_MEMBERS: {
      return {
        ...state,
        teamMembers: action.payload,
      };
    }

    case SET_IS_FETCHING: {
      return {
        ...state,
        isFetching: action.payload,
      };
    }

    case SET_TARGET_PRODUCT_TYPE: {
      return {
        ...state,
        targetProductType: action.payload,
      };
    }

    case SET_TARGET_PRODUCT_KIT: {
      return {
        ...state,
        targetProductKit: action.payload,
      };
    }

    case SET_ACTIVE_LINE_ITEM: {
      return {
        ...state,
        activeLineItem: action.payload,
      };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
