import { omit } from "lodash";

import {
  SET_SEARCH,
  SET_SEARCH_PRODUCTS,
  SET_IS_FETCHING_CATEGORIES,
  SET_IS_ADD_CATEGORY_FORM_VISIBLE,
  SET_CATEGORY_NAME,
  SET_CATEGORIES,
  SET_IS_CREATING_CATEGORY,
  SET_IS_UPDATING_CATEGORY,
  SET_PRODUCTS,
  SET_IS_FETCHING_PRODUCTS,
  RESET,
  SET_EXPANDED_CATEGORIES,
  SET_SELECTED_CATEGORIES,
  SET_SELECTED_PRODUCTS,
  SET_SELECTED_FLASHING_PRODUCTS,
  SET_IS_DUPLICATING_PRODUCTS,
} from "./productCatalogue.actionTypes";

const initialState = {
  isFetchingCategories: false,
  isAddCategoryVisible: false,
  isCreatingCategory: false,
  isUpdatingCategory: false,
  isDuplicatingProducts: false,
  categories: [],
  products: {},
  search: "",
  expandedCategories: [],
  selectedCategories: [],
  selectedProducts: [],
  selectedFlashingProducts: [],
  recentlyArchivedProductUids: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_FETCHING_CATEGORIES: {
      return {
        ...state,
        isFetchingCategories: action.payload,
      };
    }

    case SET_IS_ADD_CATEGORY_FORM_VISIBLE: {
      return {
        ...state,
        isAddCategoryVisible: action.payload,
      };
    }

    case SET_IS_CREATING_CATEGORY: {
      return {
        ...state,
        isCreatingCategory: action.payload,
      };
    }

    case SET_IS_UPDATING_CATEGORY: {
      return {
        ...state,
        isUpdatingCategory: action.payload,
      };
    }

    case SET_IS_DUPLICATING_PRODUCTS: {
      return {
        ...state,
        isDuplicatingProducts: action.payload,
      };
    }

    case SET_SEARCH: {
      return {
        ...state,
        search: action.payload,
      };
    }

    case SET_SEARCH_PRODUCTS: {
      return {
        ...state,
        products: action.payload,
      };
    }

    case SET_EXPANDED_CATEGORIES: {
      const { data } = action.payload;
      return {
        ...state,
        expandedCategories: data,
      };
    }

    case SET_CATEGORY_NAME: {
      const { data } = action.payload;
      return {
        ...state,
        categories: state.categories.map((c) =>
          c.uid === data.uid ? { ...c, name: data.name } : { ...c }
        ),
      };
    }

    case SET_CATEGORIES: {
      const { data } = action.payload;

      const miscellaneous = data.find((c) => c.name === "Miscellaneous") || [];

      return {
        ...state,
        categories: [
          ...[{ uid: "Flashings", name: "Flashings", isFetching: false }],
          ...[miscellaneous],
          ...data.filter((category) => !["Miscellaneous", "Flashings"].includes(category.name)),
        ],
      };
    }

    case SET_IS_FETCHING_PRODUCTS: {
      const { data } = action.payload;
      return {
        ...state,
        categories: state.categories.map((c) =>
          c.uid === data.uid ? { ...c, isFetching: data.isFetching } : { ...c }
        ),
      };
    }

    case SET_PRODUCTS: {
      const { data, categoryUid } = action.payload;

      if (data.length === 0) {
        return {
          ...state,
          products: omit(state.products, `${categoryUid}`),
        };
      }

      return {
        ...state,
        products: {
          ...state.products,
          [categoryUid]: data,
        },
      };
    }

    case SET_SELECTED_CATEGORIES: {
      const { data } = action.payload;
      return {
        ...state,
        selectedCategories: data,
      };
    }

    case SET_SELECTED_PRODUCTS: {
      const { selectedProducts } = action.payload;
      return { ...state, selectedProducts };
    }

    case SET_SELECTED_FLASHING_PRODUCTS: {
      const { selectedFlashingProducts } = action.payload;
      return { ...state, selectedFlashingProducts };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
