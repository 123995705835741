import moment from "moment-timezone";
import { nanoid } from "nanoid";

import * as date from "@/utils/date";
import { handleError } from "@/redux/utils/error";
import { STATUS } from "@/utils/constants";
import * as api from "@/apiv2/timesheets";
import * as teamsApi from "@/apiv2/teams";
import * as selectors from "@/redux/timesheets/individualTimesheets/individualTimesheets.selectors";
import * as alertActions from "@/redux/alerts/alerts.actions";

import {
  SET_IS_FETCHING_SUMMARY_STATUS,
  SET_IS_UPDATING_TIME_ENTRY_STATUS,
  SET_IS_FETCHING_TEAM_MEMBERS_STATUS,
  SET_TEAM_MEMBERS,
  SET_INDIVIDUAL_SUMMARY,
  SET_FROM_DATE,
  SET_TO_DATE,
  SET_DATES,
  SET_SELECTED_TIME_ENTRY_UIDS,
  SET_MODAL_TYPE,
  SET_ACTIVE_USER,
  SET_IS_DELETING_STATUS,
  SET_EXPANDED_DATES,
  SET_ACTIVE_TIME_ENTRY,
  RESET,
} from "./individualTimesheets.actionTypes";

const TIME_ENTRY_STATUS = {
  awaiting_approval: "Awaiting Approval",
  approved: "Approved",
  unapproved: "Unapproved",
};

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setIsFetchingSummaryStatus = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_SUMMARY_STATUS,
    payload: data,
  });
};

export const setIsUpdatingTimeEntryStatus = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_UPDATING_TIME_ENTRY_STATUS,
    payload: data,
  });
};

export const setIsDeletingStatus = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_DELETING_STATUS,
    payload: data,
  });
};

export const setIndividualSummary = (data) => (dispatch) => {
  dispatch({
    type: SET_INDIVIDUAL_SUMMARY,
    payload: data,
  });
};

export const setFromDate = (data) => (dispatch) => {
  dispatch({
    type: SET_FROM_DATE,
    payload: data,
  });
};

export const setToDate = (data) => (dispatch) => {
  dispatch({
    type: SET_TO_DATE,
    payload: data,
  });
};

export const setDates = (data) => (dispatch) => {
  dispatch({
    type: SET_DATES,
    payload: data,
  });
};

export const setModalType = (data) => (dispatch) => {
  dispatch({
    type: SET_MODAL_TYPE,
    payload: data,
  });
};

export const setActiveUser = (data) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_USER,
    payload: data,
  });
};

export const setSelectedTimeEntryUids = (data) => (dispatch) => {
  console.log(data);
  dispatch({
    type: SET_SELECTED_TIME_ENTRY_UIDS,
    payload: data,
  });
};

export const setIsFetchingTeamMembersStatus = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_TEAM_MEMBERS_STATUS,
    payload: data,
  });
};

export const setTeamMembers = (data) => (dispatch) => {
  dispatch({
    type: SET_TEAM_MEMBERS,
    payload: data,
  });
};

export const setExpandedDates = (data) => (dispatch) => {
  dispatch({
    type: SET_EXPANDED_DATES,
    payload: data,
  });
};

export const setActiveTimeEntry = (data) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_TIME_ENTRY,
    payload: data,
  });
};

export const fetchTeamMembers = () => async (dispatch) => {
  try {
    dispatch(setIsFetchingTeamMembersStatus(STATUS.PENDING));

    const { data } = await teamsApi.getTeams({
      with_inactive: false,
      include_invites: false,
    })();
    dispatch(setTeamMembers(data));
    dispatch(setIsFetchingTeamMembersStatus(STATUS.FULFILLED));

    return data;
  } catch (error) {
    dispatch(setIsFetchingTeamMembersStatus(STATUS.REJECTED));
    handleError(error, dispatch);
  }
};

export const fetchSummary = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const fromDate = selectors.getFromDate(state);
    const toDate = selectors.getToDate(state);
    const activeUser = selectors.getActiveUser(state);

    dispatch(setIsFetchingSummaryStatus(STATUS.PENDING));
    const { data } = await api.getTimesheetSummary({
      start_date: date.toUtcDateTime({
        date: `${moment(fromDate).format("YYYY-MM-DD")}T00:00:00`,
        timezone: date.getCurrentUserTimezone(),
      }),
      end_date: date.toUtcDateTime({
        date: `${moment(toDate).format("YYYY-MM-DD")}T23:59:59`,
        timezone: date.getCurrentUserTimezone(),
      }),
      user_id: activeUser?.id ?? undefined,
    })();

    dispatch(
      setIndividualSummary({
        ...data[0],
        timesheet: Object.keys(data[0].timesheet).map((t) => ({
          ...data[0].timesheet[t],
          date: date.toLocalDateTime({
            date: moment(t, "YYYYMMDDTHH:mm:ssZ").format("YYYY-MM-DDTHH:mm:ssZ"),
            timezone: date.getCurrentUserTimezone(),
            format: "YYYY-MM-DD",
          }),
        })),
      })
    );

    dispatch(setIsFetchingSummaryStatus(STATUS.FULFILLED));
  } catch (error) {
    dispatch(setIsFetchingSummaryStatus(STATUS.REJECTED));
    handleError(error, dispatch);
  }
};

export const removeTimeEntries =
  ({ timeEntriesUids }) =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const summary = selectors.getIndividualSummary(state);
      dispatch(setIsDeletingStatus(STATUS.PENDING));
      await api.deleteTimeEntry({ uid: timeEntriesUids })();

      dispatch(
        setIndividualSummary({
          ...summary,
          timesheet: summary.timesheet.map((timesheet) => {
            const timeEntries = timesheet.timeEntries.filter(
              (t) => !timeEntriesUids.includes(t.uid)
            );

            const approvedTimeEntries = timeEntries.filter((t) => t.approvalStatus === "approved");
            const unApprovedTimeEntries = timeEntries.filter(
              (t) => t.approvalStatus === "unapproved"
            );
            const awaitingApprovalTimeEntries = timeEntries.filter(
              (t) => t.approvalStatus === "awaiting_approval"
            );

            if (approvedTimeEntries.length === timeEntries.length) {
              return {
                ...timesheet,
                status: timeEntries.length > 0 ? "approved" : "",
                timeEntries,
              };
            } else if (unApprovedTimeEntries.length === timeEntries.length) {
              return {
                ...timesheet,
                status: timeEntries.length > 0 ? "unapproved" : "",
                timeEntries,
              };
            } else if (awaitingApprovalTimeEntries.length === timeEntries.length) {
              return {
                ...timesheet,
                status: timeEntries.length > 0 ? "awaiting_approval" : "",
                timeEntries,
              };
            }

            return {
              ...timesheet,
              status: timeEntries.length > 0 ? "multiple_statuses" : "",
              timeEntries,
            };
          }),
        })
      );

      dispatch(setIsDeletingStatus(STATUS.FULFILLED));
      dispatch(
        alertActions.createAlertAction(
          nanoid(),
          `Timesheet${timeEntriesUids.length > 1 ? "s" : ""} deleted`,
          true,
          "danger"
        )
      );
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const bulkChangeStatusOfTimeEntries =
  ({ timeEntriesUids, status, triggerPlace = "" }) =>
  async (dispatch, getState) => {
    const state = getState();
    const summary = selectors.getIndividualSummary(state);

    try {
      const payload = {
        uid: timeEntriesUids,
        status,
      };

      dispatch(
        setIsUpdatingTimeEntryStatus({
          [triggerPlace === "header" ? "header" : timeEntriesUids[0]]: {
            status,
            loading: STATUS.PENDING,
          },
        })
      );

      await api.patchBulkChangeTimeEntryStatus({ data: payload })();

      dispatch(
        setIsUpdatingTimeEntryStatus({
          [triggerPlace === "header" ? "header" : timeEntriesUids[0]]: {
            status,
            loading: STATUS.FULFILLED,
          },
        })
      );

      dispatch(
        setIndividualSummary({
          ...summary,
          timesheet: summary.timesheet.map((timesheet) => {
            const timeEntries = timesheet.timeEntries.map((t) => {
              if (timeEntriesUids.includes(t.uid)) {
                return {
                  ...t,
                  approvalStatus: status,
                };
              }

              return t;
            });

            const approvedTimeEntries = timeEntries.filter((t) => t.approvalStatus === "approved");
            const unApprovedTimeEntries = timeEntries.filter(
              (t) => t.approvalStatus === "unapproved"
            );
            const awaitingApprovalTimeEntries = timeEntries.filter(
              (t) => t.approvalStatus === "awaiting_approval"
            );

            if (approvedTimeEntries.length === timeEntries.length) {
              return {
                ...timesheet,
                status: timeEntries.length > 0 ? "approved" : "",
                timeEntries,
              };
            } else if (unApprovedTimeEntries.length === timeEntries.length) {
              return {
                ...timesheet,
                status: timeEntries.length > 0 ? "unapproved" : "",
                timeEntries,
              };
            } else if (awaitingApprovalTimeEntries.length === timeEntries.length) {
              return {
                ...timesheet,
                status: timeEntries.length > 0 ? "awaiting_approval" : "",
                timeEntries,
              };
            }

            return {
              ...timesheet,
              status: timeEntries.length > 0 ? "multiple_statuses" : "",
              timeEntries,
            };
          }),
        })
      );
      dispatch(
        alertActions.createAlertAction(
          nanoid(),
          `Timesheets marked as ${TIME_ENTRY_STATUS[status]}`,
          true,
          "success"
        )
      );
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const setPreviousWeekDates = () => (dispatch, getState) => {
  const state = getState();
  const fromDate = selectors.getFromDate(state);
  const fromDatePreviousWeek = moment(fromDate).subtract(1, "weeks").startOf("week");
  const datesOfPreviousWeek = Array.from({ length: 7 }, (_, i) =>
    fromDatePreviousWeek.clone().add(i, "days").format("YYYY-MM-DD")
  );

  dispatch(setDates(datesOfPreviousWeek));
  dispatch(setFromDate(datesOfPreviousWeek[0]));
  dispatch(setToDate(datesOfPreviousWeek[datesOfPreviousWeek.length - 1]));
};

export const setNextWeekDates = () => (dispatch, getState) => {
  const state = getState();
  const fromDate = selectors.getFromDate(state);

  const fromDateNextWeek = moment(fromDate).add(1, "weeks").startOf("week");
  const datesOfNextWeek = Array.from({ length: 7 }, (_, i) =>
    fromDateNextWeek.clone().add(i, "days").format("YYYY-MM-DD")
  );

  dispatch(setDates(datesOfNextWeek));
  dispatch(setFromDate(datesOfNextWeek[0]));
  dispatch(setToDate(datesOfNextWeek[datesOfNextWeek.length - 1]));
};

export const selectTimeEntryUids =
  ({ uid }) =>
  (dispatch, getState) => {
    const state = getState();
    const selectedTimeEntryUids = selectors.getSelectedTimeEntryUids(state);

    if (selectedTimeEntryUids) {
      if (selectedTimeEntryUids.includes(uid)) {
        dispatch(
          setSelectedTimeEntryUids(
            selectedTimeEntryUids.filter((selectedUid) => selectedUid !== uid)
          )
        );
      } else {
        dispatch(setSelectedTimeEntryUids([...selectedTimeEntryUids, uid]));
      }
    } else {
      dispatch(
        setSelectedTimeEntryUids({
          [date]: [uid],
        })
      );
    }
  };
