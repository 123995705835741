import createActionType from "../../utils/createActionType";

export const MODULE = "planner/orders";

export const SET_IS_FETCHING_ORDERS = createActionType(MODULE, "setIsFetchingOrders");

export const SET_ORDERS = createActionType(MODULE, "setOrders");

export const SET_OFFSET = createActionType(MODULE, "setOffset");

export const SET_TOTAL = createActionType(MODULE, "setTotal");

export const RESET = createActionType(MODULE, "reset");
