import { nanoid } from "nanoid";
import { flatten } from "lodash";
import snakecaseKeys from "snakecase-keys";
import qs from "query-string";

import { handleError } from "../utils/error";
import * as api from "../../apiv2/workflow";
import * as alertActions from "../alerts/alerts.actions";
import * as routerSelectors from "../router/router.selectors";
import * as viewSelectors from "../views/views.selectors";

import * as searchFilterActions from "./searchFilter/searchFilter.actions";
import * as searchFilterSelectors from "./searchFilter/searchFilter.selectors";
import * as sortFilterActions from "./sortFilter/sortFilter.actions";
import * as sortFilterSelectors from "./sortFilter/sortFilter.selectors";
import * as userFilterActions from "./userFilter/userFilter.actions";
import * as userFilterSelectors from "./userFilter/userFilter.selectors";
import * as labelFilterActions from "./labelFilter/labelFilter.actions";
import * as labelFilterSelectors from "./labelFilter/labelFilter.selectors";
import {
  SET_SETTINGS,
  SET_IS_FETCHING_SETTINGS,
  SET_COLUMNS,
  SET_SELECTED_COLUMNS,
  SET_IS_FETCHING_COLUMNS,
  SET_CARDS,
  SET_IS_FETCHING_CARDS,
  SET_CARDS_IN_COLUMN,
  SET_ACTIVE_VIEW,
  SET_IS_ORDER_MODAL_OPEN,
  RESET,
} from "./workflow.actionTypes";
import * as selectors from "./workflow.selectors";

const reOrderLists = (list, startIndex, endIndex) => {
  const removed = list.find((_, index) => startIndex === index);
  const newList = list.filter((_, index) => startIndex !== index);
  newList.splice(endIndex, 0, removed);
  return newList;
};

export const moveCards = (
  source,
  destination,
  droppableSourceId,
  droppableDestinationId,
  startIndex,
  endIndex
) => {
  const removed = source.find((_, index) => startIndex === index);
  const newSource = source.filter((_, index) => startIndex !== index);
  destination.splice(endIndex, 0, removed);

  return {
    [droppableSourceId]: newSource,
    [droppableDestinationId]: destination,
  };
};

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setSettings = (data) => (dispatch) => {
  dispatch({
    type: SET_SETTINGS,
    payload: data,
  });
};

export const setColumns = (data) => (dispatch) => {
  dispatch({
    type: SET_COLUMNS,
    payload: data,
  });
};

export const setSelectedColumns = (data) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_COLUMNS,
    payload: data,
  });
};

export const setCards = (data) => (dispatch) => {
  dispatch({
    type: SET_CARDS,
    payload: data,
  });
};

export const setCardsColumn =
  ({ data, columnId }) =>
  (dispatch) => {
    dispatch({
      type: SET_CARDS_IN_COLUMN,
      payload: {
        data,
        columnId,
      },
    });
  };

export const setIsFetchingSettings = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_SETTINGS,
    payload: status,
  });
};

export const setIsFetchingColumns = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_COLUMNS,
    payload: status,
  });
};

export const setIsFetchingCards = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_CARDS,
    payload: status,
  });
};

export const setActiveView = (view) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_VIEW,
    payload: view,
  });
};

export const setIsOrderModalOpen = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_ORDER_MODAL_OPEN,
    payload: data,
  });
};

export const fetchSettings = () => async (dispatch, getState) => {
  try {
    const state = getState();
    dispatch(setIsFetchingSettings(true));
    const { data } = await api.getSettings()();
    dispatch(setSettings(data));

    const queryParams = routerSelectors.getQuery(state);

    if (queryParams.sort_by) {
      dispatch(sortFilterActions.setSelectedSortingOption(queryParams.sort_by));
    }

    if (!queryParams.sort_by && data.defaultSort) {
      dispatch(sortFilterActions.setSelectedSortingOption(data.defaultSort));
    }
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetchingSettings(false));
  }
};

export const fetchColumns = () => async (dispatch) => {
  try {
    dispatch(setIsFetchingColumns(true));
    const response = await api.getStatus()();
    dispatch(setColumns(response.data));
    dispatch(setSelectedColumns(response.data.map((c) => c.uid)));
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetchingColumns(false));
  }
};

export const searchCards = () => async (dispatch, getState) => {
  const state = getState();

  const selectedUsers = userFilterSelectors.getSelectedUsers(state);
  const selectedLabels = labelFilterSelectors.getSelectedLabels(state);
  const selectedSortingOption = sortFilterSelectors.getSelectedSortingOption(state);
  const selectedColumns = selectors.getSelectecdColumns(state);
  const search = searchFilterSelectors.getSearch(state);

  const queryParams = {
    search: search || undefined,
    assignees: selectedUsers,
    labels: selectedLabels,
    sort_by: selectedSortingOption,
    columns: selectedColumns,
  };

  try {
    dispatch(setIsFetchingCards(true));
    const response = await api.getColumnsOrders(queryParams)();

    dispatch(
      setCards(response.data.reduce((acc, cards) => ({ ...acc, [cards.status]: cards.orders }), {}))
    );

    if (queryParams.sort_by) {
      dispatch(filterCards(queryParams.sort_by));
    }
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetchingCards(false));
  }
};

export const reorderColumns =
  ({ startIndex, endIndex }) =>
  (dispatch, getState) => {
    const state = getState();
    const columns = selectors.getColumns(state);
    const columnUid = getState().getIn(["workflow", "columns", startIndex, "uid"]);

    dispatch(setColumns(reOrderLists(columns, startIndex, endIndex)));

    try {
      api.postMoveColumnOrder({
        column_uid: columnUid,
        new_index: endIndex,
      })();
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const reorderColumnCards =
  ({ droppableId, startIndex, endIndex }) =>
  (dispatch, getState) => {
    const state = getState();
    const orderUid = state.getIn(["workflow", "cards", droppableId, startIndex, "uid"]);

    const cards = selectors.getCardsByColumnUid(state, droppableId);

    dispatch(
      setCardsColumn({
        data: reOrderLists(cards, startIndex, endIndex),
        columnId: droppableId,
      })
    );

    try {
      api.postMoveCardOrder({
        order_uid: orderUid,
        new_index: endIndex,
      })();
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const moveCardToOtherColumn =
  ({ droppableSourceId, droppableDestinationId, startIndex, endIndex }) =>
  (dispatch, getState) => {
    const state = getState();

    const orderUid = getState().getIn(["workflow", "cards", droppableSourceId, startIndex, "uid"]);

    const source = state.getIn(["workflow", "cards", droppableSourceId]);
    const destination = state.getIn(["workflow", "cards", droppableDestinationId]);

    const result = moveCards(
      source,
      destination,
      droppableSourceId,
      droppableDestinationId,
      startIndex,
      endIndex
    );
    const columnIndex = state
      .getIn(["workflow", "columns"])
      .findIndex((column) => column.uid === droppableDestinationId);

    const status = state.getIn(["workflow", "columns", columnIndex]);

    dispatch(
      setCardsColumn({
        data: result[droppableDestinationId].map((item) => ({
          ...item,
          status: {
            ...item.status,
            name: status.name,
            colour: status.settings.colour,
          },
        })),
        columnId: droppableDestinationId,
      })
    );

    dispatch(
      setCardsColumn({
        data: result[droppableSourceId],
        columnId: droppableSourceId,
      })
    );

    try {
      api.postMoveCardOrder({
        order_uid: orderUid,
        new_index: endIndex,
        new_column_uid: droppableDestinationId,
      })();
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const changeColumnName =
  ({ index, name }) =>
  async (dispatch, getState) => {
    const state = getState();
    const uid = state.getIn(["workflow", "columns", index, "uid"]);
    const columns = selectors.getColumns(state);
    const cards = selectors.getCardsByColumnUid(state, uid);

    dispatch(
      setColumns(
        columns.map((c, columnIndex) => (columnIndex === index ? { ...c, name } : { ...c }))
      )
    );

    dispatch(
      setCardsColumn({
        data: cards.map((c) => ({ ...c, status: { ...c.status, name } })),
        columnId: uid,
      })
    );

    try {
      await api.postStatus({ uid, name })();
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const changeColumnStatusColour =
  ({ index, colour }) =>
  (dispatch, getState) => {
    const state = getState();
    const columns = selectors.getColumns(state);
    const uid = state.getIn(["workflow", "columns", index, "uid"]);

    dispatch(
      setColumns(
        columns.map((c, columnIndex) =>
          columnIndex === index ? { ...c, settings: { ...c.settings, colour } } : { ...c }
        )
      )
    );

    try {
      api.postStatus({ uid, settings: { colour } })();
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const changeColumnNotification =
  ({ index, type, checked }) =>
  (dispatch, getState) => {
    const state = getState();
    const columns = selectors.getColumns(state);
    const uid = state.getIn(["workflow", "columns", index, "uid"]);

    dispatch(
      setColumns(
        columns.map((c, columnIndex) =>
          columnIndex === index
            ? {
                ...c,
                settings: {
                  ...c.settings,
                  notifications: {
                    ...c.settings.notifications,
                    [type]: checked,
                  },
                },
              }
            : { ...c }
        )
      )
    );

    try {
      api.postStatus({
        uid,
        settings: { notifications: { [type]: checked } },
      })();
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const createNewColumn = (name) => async (dispatch, getState) => {
  const state = getState();
  const payload = {
    name,
    isDeleted: false,
    uid: "",
    settings: {
      colour: "#4169E1",
      notifications: {
        email: false,
        sms: false,
      },
    },
  };

  try {
    const { data } = await api.postStatus(payload)();
    const columns = selectors.getColumns(state);
    const selectedColumns = selectors.getSelectecdColumns(state);
    dispatch(setColumns([...columns, data]));
    dispatch(setSelectedColumns([...selectedColumns, data.uid]));

    dispatch(
      setCardsColumn({
        data: [],
        columnId: data.uid,
      })
    );
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const deleteColumn = (uid) => async (dispatch, getState) => {
  const state = getState();
  const columns = selectors.getColumns(state);
  dispatch(setColumns(columns.filter((c) => c.uid !== uid)));

  try {
    api.postStatus({ uid, is_deleted: true })();
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const filterCards = (value) => (dispatch, getState) => {
  const state = getState();
  const cards = selectors.getCards(state);

  const dateField =
    value === "Created date" || value === "Most recent" ? "createdTimestamp" : "requiredTimestamp";
  const nameField = value === "Customer name" && "customerName";

  if (value === "Customer name") {
    dispatch(
      setCards(
        Object.keys(cards).reduce(
          (acc, curr) => (
            (acc[curr] = cards[curr].sort((a, b) =>
              a[nameField].toLowerCase() < b[nameField].toLowerCase() ? -1 : 1
            )),
            acc
          ),
          {}
        )
      )
    );
  } else if (value === "Created date" || value === "Required date") {
    dispatch(
      setCards(
        Object.keys(cards).reduce(
          (acc, curr) => (
            (acc[curr] = cards[curr].sort(
              (a, b) => new Date(a[dateField]) - new Date(b[dateField])
            )),
            acc
          ),
          {}
        )
      )
    );
  } else if (value === "Most recent") {
    dispatch(
      setCards(
        Object.keys(cards).reduce(
          (acc, curr) => (
            (acc[curr] = cards[curr].sort(
              (a, b) => new Date(b[dateField]) - new Date(a[dateField])
            )),
            acc
          ),
          {}
        )
      )
    );
  } else {
    dispatch(
      setCards(
        Object.keys(cards).reduce(
          (acc, curr) => (
            (acc[curr] = cards[curr].sort((a, b) => a.purchaseOrder - b.purchaseOrder)), acc
          ),
          {}
        )
      )
    );
  }
};

export const filterCardsInColumn =
  ({ value, uid }) =>
  (dispatch, getState) => {
    const state = getState();
    const dateField =
      value === "Created date" || value === "Most recent"
        ? "createdTimestamp"
        : "requiredTimestamp";
    const nameField = value === "Customer name" && "customerName";

    const cards = selectors.getCardsByColumnUid(state, uid);

    if (value === "Customer name") {
      dispatch(
        setCardsColumn({
          data: cards.sort((a, b) =>
            a[nameField].toLowerCase() < b[nameField].toLowerCase() ? -1 : 1
          ),
          columnId: uid,
        })
      );
    } else if (value === "Created date" || value === "Required date") {
      dispatch(
        setCardsColumn({
          data: cards.sort((a, b) => new Date(a[dateField]) - new Date(b[dateField])),
          columnId: uid,
        })
      );
    } else if (value === "Most recent") {
      dispatch(
        setCardsColumn({
          data: cards.sort((a, b) => new Date(b[dateField]) - new Date(a[dateField])),
          columnId: uid,
        })
      );
    } else {
      dispatch(
        setCardsColumn({
          data: cards.sort((a, b) => a.purchaseOrder - b.purchaseOrder),
          columnId: uid,
        })
      );
    }
  };

export const markOrder = (values) => async (dispatch, getState) => {
  const state = getState();

  const { order, columnUid, message, isReceived } = values;
  const orderIndex = state
    .getIn(["workflow", "cards", columnUid])
    .findIndex((item) => item.uid === order.uid);
  const cards = selectors.getCardsByColumnUid(state, columnUid);

  dispatch(
    setCardsColumn({
      data: cards.filter((c) => c.uid !== order.uid),
      columnId: columnUid,
    })
  );

  try {
    await api.postMarkOrder({
      order_uid: order.uid,
      is_received: isReceived,
    })();

    if (message) {
      dispatch(
        alertActions.createAlertAction(nanoid(), message, true, "success", "Undo", async () => {
          await dispatch(
            undoMarkOrder({
              columnUid,
              order,
              orderIndex,
            })
          );
        })
      );
    }
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const undoMarkOrder = (values) => async (dispatch, getState) => {
  const state = getState();
  const { order, columnUid, orderIndex } = values;
  const cards = selectors.getCardsByColumnUid(state, columnUid);

  dispatch(
    setCardsColumn({
      data: [...cards.slice(0, orderIndex), order, ...cards.slice(orderIndex)],
      columnId: columnUid,
    })
  );

  try {
    await api.postMarkOrder({ orderUid: order.uid, is_received: null })();
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const changeSettings = (payload) => async (dispatch, getState) => {
  const state = getState();
  const settings = selectors.getSettings(state);

  dispatch(setSettings({ ...settings, ...payload }));

  try {
    await api.postSettings({ ...snakecaseKeys(payload) })();
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const updateOrderCardAssignees = (values) => async (dispatch, getState) => {
  const state = getState();
  const { order, columnUid } = values;

  const cards = selectors.getCardsByColumnUid(state, columnUid);

  await dispatch(
    setCardsColumn({
      data: cards.map((c) =>
        c.uid === order.uid ? { ...c, assignees: order.assignees } : { ...c }
      ),
      columnId: columnUid,
    })
  );
};

export const updateOrderCardLabels = (values) => async (dispatch, getState) => {
  const state = getState();
  const { order, columnUid } = values;

  const cards = selectors.getCardsByColumnUid(state, columnUid);

  dispatch(
    setCardsColumn({
      data: cards.map((c) => (c.uid === order.uid ? { ...c, labels: order.labels } : { ...c })),
      columnId: columnUid,
    })
  );
};

export const renameOrderCardLabel = (values) => async (dispatch, getState) => {
  const state = getState();
  const statuses = selectors.getColumns(state);
  const cards = selectors.getCards(state);
  const flattenCards = flatten(Object.keys(cards).map((c) => cards[c]));
  const formattedCards = flattenCards.map((c) => ({
    ...c,
    labels: c.labels.map((l) =>
      l.uid === values.uid ? { ...l, name: values.name, colour: values.colour } : l
    ),
  }));

  dispatch(
    setCards(
      statuses.reduce(
        (acc, curr) => (
          (acc[curr.uid] = formattedCards.filter((item) => item.status.name === curr.name)), acc
        ),
        {}
      )
    )
  );
};

export const removeOrderCardLabel =
  ({ uid }) =>
  (dispatch, getState) => {
    const state = getState();
    const statuses = selectors.getColumns(state);
    const cards = selectors.getCards(state);
    const flattenCards = flatten(Object.keys(cards).map((c) => cards[c]));
    const formattedCards = flattenCards.map((c) => ({
      ...c,
      labels: c.labels.filter((l) => l.uid !== uid),
    }));

    dispatch(
      setCards(
        statuses.reduce(
          (acc, curr) => (
            (acc[curr.uid] = formattedCards.filter((item) => item.status.name === curr.name)), acc
          ),
          {}
        )
      )
    );
  };

export const setSelectedColumnsForDisplay = (uid) => (dispatch, getState) => {
  const state = getState();

  const selectedColumns = selectors.getSelectecdColumns(state);
  const allColumns = selectors.getColumns(state);

  if (uid === "" && selectedColumns.length === allColumns.length) {
    return dispatch(setSelectedColumns([]));
  }

  if (uid === "" && (selectedColumns.length === 0 || selectedColumns.length < allColumns.length)) {
    return dispatch(setSelectedColumns(allColumns.map((c) => c.uid)));
  }

  if (uid === "" && selectedColumns.length === 0) {
    return dispatch(setSelectedColumns(allColumns.map((c) => c.uid)));
  }

  if (selectedColumns.includes(uid)) {
    return dispatch(setSelectedColumns(selectedColumns.filter((columnUid) => columnUid !== uid)));
  }

  return dispatch(setSelectedColumns([...selectedColumns, uid]));
};

export const selectedAllColumns = () => (dispatch, getState) => {
  const state = getState();
  const columns = selectors.getColumns(state);

  dispatch(setSelectedColumns(columns.map((c) => c.uid)));
};

export const selectAllColumnsFromQueryParams = () => (dispatch, getState) => {
  const state = getState();
  const queryParams = routerSelectors.getQuery(state);
  const columns = selectors.getColumns(state);

  if (queryParams.columns) {
    dispatch(
      setSelectedColumns(
        Array.isArray(queryParams.columns) ? queryParams.columns : [queryParams.columns]
      )
    );
  } else {
    dispatch(setSelectedColumns(columns.map((c) => c.uid)));
  }
};

export const applyFilterFromUrl = () => (dispatch, getState) => {
  const state = getState();

  const selectedView = viewSelectors.getSelectedView(state);

  if (selectedView && selectedView.name === "None") return;

  const parsedCustomFilter = qs.parse(selectedView.customFilter);

  if (typeof parsedCustomFilter.labels === "string") {
    parsedCustomFilter.labels = [parsedCustomFilter.labels];
  }

  if (typeof parsedCustomFilter.assignees === "string") {
    parsedCustomFilter.assignees = [parsedCustomFilter.assignees];
  }

  if (typeof parsedCustomFilter.columns === "string") {
    parsedCustomFilter.columns = [parsedCustomFilter.columns];
  }

  if (parsedCustomFilter.assignees) {
    dispatch(userFilterActions.setSelectedUsers(parsedCustomFilter.assignees));
  } else {
    dispatch(userFilterActions.setSelectedUsers([]));
  }

  if (parsedCustomFilter.labels) {
    dispatch(labelFilterActions.setSelectedLabels(parsedCustomFilter.labels));
  } else {
    dispatch(labelFilterActions.setSelectedLabels([]));
  }

  if (parsedCustomFilter.search) {
    dispatch(searchFilterActions.setSearch(parsedCustomFilter.search));
  } else {
    dispatch(searchFilterActions.setSearch(""));
  }

  if (parsedCustomFilter.sort_by) {
    dispatch(sortFilterActions.setSelectedSortingOption(parsedCustomFilter.sort_by));
  } else {
    const settings = selectors.getSettings(state);
    dispatch(sortFilterActions.setSelectedSortingOption(settings.defaultSort));
  }

  if (parsedCustomFilter.columns) {
    dispatch(setSelectedColumns(parsedCustomFilter.columns));
  } else {
    const columns = selectors.getColumns(state);
    dispatch(setSelectedColumns(columns.map((c) => c.uid)));
  }
};
