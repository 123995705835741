import moment from "moment-timezone";
import { nanoid } from "nanoid";

import * as date from "@/utils/date";
import { handleError } from "@/redux/utils/error";
import { STATUS } from "@/utils/constants";
import * as api from "@/apiv2/timesheets";
import * as selectors from "@/redux/timesheets/timesheets.selectors";
import * as alertActions from "@/redux/alerts/alerts.actions";

import {
  SET_IS_FETCHING_SUMMARY_STATUS,
  SET_IS_UPDATING_TIMESHEET_STATUS,
  SET_SUMMARY,
  SET_FROM_DATE,
  SET_TO_DATE,
  SET_DATES,
  SET_SELECTED_USER_IDS,
  SET_MODAL_TYPE,
  SET_ACTIVE_USER,
  RESET,
} from "./timesheets.actionTypes";

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setIsFetchingSummaryStatus = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_SUMMARY_STATUS,
    payload: data,
  });
};

export const setIsUpdatingTimesheetStatus = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_UPDATING_TIMESHEET_STATUS,
    payload: data,
  });
};

export const setSummary = (data) => (dispatch) => {
  dispatch({
    type: SET_SUMMARY,
    payload: data,
  });
};

export const setFromDate = (data) => (dispatch) => {
  dispatch({
    type: SET_FROM_DATE,
    payload: data,
  });
};

export const setToDate = (data) => (dispatch) => {
  dispatch({
    type: SET_TO_DATE,
    payload: data,
  });
};

export const setDates = (data) => (dispatch) => {
  dispatch({
    type: SET_DATES,
    payload: data,
  });
};

export const setModalType = (data) => (dispatch) => {
  dispatch({
    type: SET_MODAL_TYPE,
    payload: data,
  });
};

export const setSelectedUserIds = (data) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_USER_IDS,
    payload: data,
  });
};

export const setActiveUser = (data) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_USER,
    payload: data,
  });
};

export const fetchSummary = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const fromDate = selectors.getFromDate(state);
    const toDate = selectors.getToDate(state);
    const activeUser = selectors.getActiveUser(state);

    dispatch(setIsFetchingSummaryStatus(STATUS.PENDING));
    const { data } = await api.getTimesheetSummary({
      start_date: date.toUtcDateTime({
        date: `${moment(fromDate).format("YYYY-MM-DD")}T00:00:00`,
        timezone: date.getCurrentUserTimezone(),
      }),
      end_date: date.toUtcDateTime({
        date: `${moment(toDate).format("YYYY-MM-DD")}T23:59:59`,
        timezone: date.getCurrentUserTimezone(),
      }),
      user_id: activeUser?.id ?? undefined,
    })();

    dispatch(
      setSummary(
        data.map((s) => ({
          ...s,
          timesheet: Object.keys(s.timesheet).map((t) => ({
            ...s.timesheet[t],
            date: date.toLocalDateTime({
              date: moment(t, "YYYYMMDDTHH:mm:ssZ").format("YYYY-MM-DDTHH:mm:ssZ"),
              timezone: date.getCurrentUserTimezone(),
              format: "YYYY-MM-DD",
            }),
          })),
        }))
      )
    );

    dispatch(setIsFetchingSummaryStatus(STATUS.FULFILLED));
  } catch (error) {
    dispatch(setIsFetchingSummaryStatus(STATUS.REJECTED));
    handleError(error, dispatch);
  }
};

export const bulkChangeStatusOfTimeEntries =
  ({ userIds, timeEntriesUids, status, triggerPlace = "" }) =>
  async (dispatch, getState) => {
    const state = getState();
    const summary = selectors.getSummary(state);

    const updatedSummaryState = summary.map((s) => {
      if (userIds.includes(s.userId)) {
        return {
          ...s,
          timesheet: s.timesheet.map((timesheet) => ({
            ...timesheet,
            status: timesheet.timeEntries.length > 0 ? status : "",
            timeEntries: timesheet.timeEntries.map((t) => {
              if (timeEntriesUids.includes(t.uid)) {
                return {
                  ...t,
                  approvalStatus: status,
                };
              }

              return t;
            }),
          })),
        };
      }

      return s;
    });

    dispatch(setSummary(updatedSummaryState));

    try {
      const payload = {
        uid: timeEntriesUids,
        status,
      };

      dispatch(
        setIsUpdatingTimesheetStatus({
          [triggerPlace === "header" ? "header" : userIds[0]]: {
            status,
            loading: STATUS.PENDING,
          },
        })
      );

      await api.patchBulkChangeTimeEntryStatus({ data: payload })();

      dispatch(
        setIsUpdatingTimesheetStatus({
          [triggerPlace === "header" ? "header" : userIds[0]]: {
            status,
            loading: STATUS.FULFILLED,
          },
        })
      );

      dispatch(
        alertActions.createAlertAction(nanoid(), `Timesheets marked as ${status}`, true, "success")
      );

      return updatedSummaryState;
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const setPreviousWeekDates = () => (dispatch, getState) => {
  const state = getState();
  const fromDate = selectors.getFromDate(state);
  const fromDatePreviousWeek = moment(fromDate).subtract(1, "weeks").startOf("week");
  const datesOfPreviousWeek = Array.from({ length: 7 }, (_, i) =>
    fromDatePreviousWeek.clone().add(i, "days").format("YYYY-MM-DD")
  );

  dispatch(setDates(datesOfPreviousWeek));

  dispatch(setFromDate(datesOfPreviousWeek[0]));

  dispatch(setToDate(datesOfPreviousWeek[datesOfPreviousWeek.length - 1]));
};

export const setNextWeekDates = () => (dispatch, getState) => {
  const state = getState();
  const fromDate = selectors.getFromDate(state);

  const fromDateNextWeek = moment(fromDate).add(1, "weeks").startOf("week");
  const datesOfNextWeek = Array.from({ length: 7 }, (_, i) =>
    fromDateNextWeek.clone().add(i, "days").format("YYYY-MM-DD")
  );

  dispatch(setDates(datesOfNextWeek));

  dispatch(setFromDate(datesOfNextWeek[0]));

  dispatch(setToDate(datesOfNextWeek[datesOfNextWeek.length - 1]));
};
