import createActionType from "../../utils/createActionType";

export const MODULE = "purchaseOrders/productLists";

export const SET_SEARCH = createActionType(MODULE, "setSearch");
export const SET_SEARCH_PRODUCTS = createActionType(MODULE, "setSearchProducts");

export const SET_IS_FETCHING_CATEGORIES = createActionType(MODULE, "setIsFetchingCategories");

export const SET_EXPANDED_CATEGORIES = createActionType(MODULE, "setExpandedCategories");

export const SET_CATEGORIES = createActionType(MODULE, "setCategories");

export const SET_IS_FETCHING_PRODUCTS = createActionType(MODULE, "setIsFetchingProducts");

export const SET_PRODUCTS = createActionType(MODULE, "setProducts");

export const RESET = createActionType(MODULE, "reset");
