import { STATUS } from "@/utils/constants";

import {
  SET_IS_SAVING_STATUS,
  SET_IS_FETCHING_TEAM_MEMBERS_STATUS,
  SET_TEAM_MEMBERS,
  SET_IS_SEARCHING_ORDER_NUMBER_STATUS,
  SET_IS_FETCHING_ORDER_ITEMS_STATUS,
  SET_ORDER_ITEMS,
  SET_ORDER_NUMBER_RESULTS,
  RESET,
} from "./timeEntry.actionTypes";

const initialState = {
  isSavingStatus: STATUS.IDLE,
  isFetchingTeamMembersStatus: STATUS.IDLE,
  teams: [],
  isSearchinggOrderNumberStatus: STATUS.IDLE,
  isFetchingOrderItemsStatus: STATUS.IDLE,
  orderItems: [],
  orderNumberResults: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_SAVING_STATUS: {
      return { ...state, isSavingStatus: action.payload };
    }

    case SET_IS_FETCHING_TEAM_MEMBERS_STATUS: {
      return { ...state, isFetchingTeamMembersStatus: action.payload };
    }

    case SET_TEAM_MEMBERS: {
      return { ...state, teams: action.payload };
    }

    case SET_IS_SEARCHING_ORDER_NUMBER_STATUS: {
      return { ...state, isSearchinggOrderNumberStatus: action.payload };
    }

    case SET_ORDER_ITEMS: {
      return { ...state, orderItems: action.payload };
    }

    case SET_IS_FETCHING_ORDER_ITEMS_STATUS: {
      return { ...state, isFetchingOrderItemsStatus: action.payload };
    }

    case SET_ORDER_NUMBER_RESULTS: {
      return { ...state, orderNumberResults: action.payload };
    }

    case RESET: {
      return initialState;
    }

    default:
      return state;
  }
};
