import { createApi } from "./common";


export const getItemsOrderWithAdditionalProducts = (
  token,
  { customerUid, orderUid }
) =>
  createApi(
    "GET",
    `customers/${customerUid}/orders/${orderUid}/additional_products/`
  )(token);

export default getItemsOrderWithAdditionalProducts;
