import { MODULE } from "./views.actionTypes";

export const isFetchingViews = (state) => state.getIn([MODULE, "isFetchingViews"]);

export const isCreateViewFormVisible = (state) => state.getIn([MODULE, "isCreateViewFormVisible"]);

export const getActiveView = (state) => state.getIn([MODULE, "activeView"]);

export const getSelectedView = (state) => state.getIn([MODULE, "selectedView"]);

export const getDefaultView = (state) => state.getIn([MODULE, "defaultView"]);

export const getViews = (state) => state.getIn([MODULE, "views"]);

export const getFormattedViews = (state) => {
  const views = state.getIn([MODULE, "views"]);
  const selectedView = getSelectedView(state);
  const defaultView = getDefaultView(state);

  const noneView = views.find((v) => v.name === "None");

  if (defaultView) {
    return [
      ...(noneView ? [noneView] : []),
      ...(defaultView && defaultView.name !== "None" ? [defaultView] : []),
      ...views.filter((v) => v.name !== "None" && v.uid !== defaultView.uid),
    ];
  }

  return views;
};
