import createActionType from "../utils/createActionType";

const MODULE = "purchase-products-modal";

export const SET_IS_FETCHING = createActionType(MODULE, "setIsFetching");

export const SET_LISTS = createActionType(MODULE, "setLists");

export const SET_SUPPLIERS_LIST = createActionType(MODULE, "setSuppliersList");

export const SET_HAS_FORM_SUBMITTED = createActionType(
  MODULE,
  "setHasFormSubmitted"
);

export const UPDATE_QUANTITY = createActionType(MODULE, "updateQuantity");

export const UPDATE_SUPPLIER = createActionType(MODULE, "updateSupplier");

export const UPDATE_MULTIPLE_SUPPLIER = createActionType(MODULE, "updateMultipleSupplier");

export const CREATE_PURCHASE_ORDER = createActionType(
  MODULE,
  "createPurchaseOrder"
);

export const SET_IS_CREATING = createActionType(MODULE, "setIsCreating");

export const SET_SELECTED_PRODUCT_UIDS = createActionType(
  MODULE,
  "setSelectedProductUids"
);

export const SET_IS_GROUPED_PRODUCTS = createActionType(
  MODULE,
  "setIsGroupedProducts"
);

export const SET_CREATED_PO_UIDS = createActionType(MODULE, "setCreatedPoUids");

export const SET_IS_CREATE_PURCHASE_ORDER_MODAL_OPEN = createActionType(
  MODULE,
  "setIsCreatePurchaseOrderModalOpen"
);
export const SET_IS_CREATING_PURCHASE_ORDER_MODAL_OPEN = createActionType(
  MODULE,
  "setIsCreatingPurchaseOrderModalOpen"
);
export const SET_IS_CREATED_PURCHASE_ORDER_MODAL_OPEN = createActionType(
  MODULE,
  "setIsCreatedPurchaseOrderModalOpen"
);
export const RESET = createActionType(MODULE, "reset");
