import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { Select as BaseSelect } from "@/ui-lib/FormFields";

import styles from "./Select.module.scss";

const Select = ({
  setFieldValue,
  id,
  containerClassName,
  className,
  onChange,
  errors,
  withoutError,
  invalid,
  ...props
}) => {
  const error = !!(errors[id] || invalid) && !withoutError;

  const handleFocus = (e) => {
    const { autoSelected, onFocus } = props;

    if (autoSelected) {
      e.target.select();
    }

    if (onFocus) {
      onFocus(e);
    }
  };

  const handleChange = (option) => {
    const { optionsKey } = props;
    const value = option?.value || option[optionsKey] || option;

    if (onChange) {
      onChange(id, value);
    }

    setFieldValue(id, value || "");
  };

  return (
    <div
      className={cx(
        styles.selectContainer,
        error && errors[id] && props.touched[id] ? styles.selectError : "",
        containerClassName
      )}
    >
      <BaseSelect {...props} onFocus={handleFocus} onChange={handleChange} />
      {error && errors[id] && props.touched[id] && (
        <div className={styles.errorMessage}>{errors[id]}</div>
      )}
    </div>
  );
};

Select.defaultProps = {
  setFieldTouched: () => null,
  setFieldValue: () => null,
  onFocus: () => null,
  onChange: () => null,
  onBlur: () => null,
  withoutError: false,
  invalid: false,
  errors: {},
  containerClassName: "",
  autoSelected: false,
};

Select.propTypes = {
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  id: PropTypes.string.isRequired,
  withoutError: PropTypes.bool,
  invalid: PropTypes.bool,
  errors: PropTypes.shape({}),
  containerClassName: PropTypes.string,
  autoSelected: PropTypes.bool,
};

export default Select;
