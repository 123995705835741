import createActionType from "../utils/createActionType";

export const MODULE = "purchaseOrders";

export const SEARCH_PURCHASE_ORDERS = createActionType(MODULE, "searchPurchaseOrders");
export const GET_PURCHASE_ORDER = createActionType(MODULE, "getPurchaseOrder");
export const LOAD_EMPTY_PURCHASE_ORDER = createActionType(MODULE, "loadEmptyPurchaseOrder");
export const BATCH_EDIT_ACTIVE_PURCHASE_ORDER = createActionType(
  MODULE,
  "batchEditActivePurchaseOrder"
);
export const EDIT_ACTIVE_PURCHASE_ORDER = createActionType(MODULE, "editActivePurchaseOrder");
export const RESET_ACTIVE_PURCHASE_ORDER = createActionType(MODULE, "resetActivePurchaseOrder");
export const UPDATE_PURCHASE_ORDER = createActionType(MODULE, "updatePurchaseOrder");
export const UPDATE_PURCHASE_ORDER_SILENTLY = createActionType(
  MODULE,
  "updatePurchaseOrderSilently"
);

export const EDIT_ACTIVE_PURCHASE_ORDER_SILENTLY = createActionType(
  MODULE,
  "editActivePurchaseOrderSilently"
);
export const UPDATE_PRODUCTS_INDEX = createActionType(MODULE, "updateProductsIndex");
export const CREATE_PURCHASE_ORDER = createActionType(MODULE, "createPurchaseOrder");
export const DRAFT_PURCHASE_ORDER = createActionType(MODULE, "draftPurchaseOrder");
export const DELETE_PURCHASE_ORDER = createActionType(MODULE, "deletePurchaseOrder");
export const BULK_DELETE_PURCHASE_ORDERS = createActionType(MODULE, "bulkDeletePurchaseOrders");
export const EDIT_PURCHASE_ORDER_FILTERS = createActionType(MODULE, "editPurchaseOrderFilters");
export const UNDO_PURCHASE_ORDER_ARCHIVE = createActionType(MODULE, "undoPurchaseOrderArchive");
export const UNDO_PURCHASE_ORDER_BULK_ARCHIVE = createActionType(
  MODULE,
  "undoPurchaseOrderBulkArchive"
);
export const GET_EXTERNAL_PURCHASE_ORDER = createActionType(MODULE, "getExternalPurchaseOrder");
export const GET_LINKED_ORDERS_FROM_PURCHASE_ORDERS = createActionType(
  MODULE,
  "getLinkedOrdersFromPurchaseOrders"
);
export const EDIT_DRAWING_SVGS = createActionType(MODULE, "editDrawingSVGs");
export const RESET_PURCHASE_ORDERS = createActionType(MODULE, "resetPurchaseOrders");

// ATTACHMENTS
export const SET_IS_FETCHING = createActionType(MODULE, "setIsFetching");

export const SET_ORDER_ATTACHMENTS = createActionType(MODULE, "setOrderAttachments");

export const SET_SELECTED_ATTACHMENTS = createActionType(MODULE, "setSelectedAttachments");

export const POST_ADD_ATTACHMENTS = createActionType(MODULE, "addAttachments");

export const REMOVE_SELECTED_ATTACHMENT = createActionType(MODULE, "removeSelectedAttachment");

export const UPDATE_SELECTED_ATTACHMENTS = createActionType(MODULE, "updateSelectedAttachments");

export const PRINT_PURCHASE_ORDER_PDF = createActionType(MODULE, "printPurchaseOrderPdf");

export const PRINT_PURCHASE_ORDER_PDF_STATUS = createActionType(
  MODULE,
  "printPurchaseOrderPdfStatus"
);

export const EMAIL_PURCHASE_ORDER = createActionType(MODULE, "emailPurchaseOrder");

export const EMAIL_PURCHASE_ORDER_STATUS = createActionType(MODULE, "emailPurchaseOrderStatus");

// PAGINATION
export const SET_CURRENT_PAGE = createActionType(MODULE, "setCurrentPage");

export const SET_TOTAL_COUNT = createActionType(MODULE, "setTotalCount");

export const SET_NEXT_PAGE_OFFSET = createActionType(MODULE, "setNextPageOffset");

export const SET_PREVIOUS_PAGE_OFFSET = createActionType(MODULE, "setPreviousPageOffset");

export const SET_PAGE_OFFSET = createActionType(MODULE, "setPageOffset");

export const SET_PAGE_LIMIT = createActionType(MODULE, "setPageLimit");

export const SET_SORT = createActionType(MODULE, "setSort");

// DUPLICATING
export const SET_IS_DUPLICATING = createActionType(MODULE, "setIsDuplicating");

export const SET_DUPLICATE_ORDER_UID = createActionType(MODULE, "setDuplicateOrderUid");

export const SET_IS_DUPLICATE_CONFIRMATION_ALERT_OPEN = createActionType(
  MODULE,
  "setIsDuplicateConfirmationAlertOpen"
);

export const SET_IS_CHANGING_SUPPLIER_MODAL_OPEN = createActionType(
  MODULE,
  "setIsChangingSupplierModalOpen"
);

export const SET_IS_CHANGING_SUPPLIER = createActionType(MODULE, "setIsChangingSupplier");

// CONVERTING
export const SET_IS_CONVERTING_BILL_OR_PO = createActionType(MODULE, "setIsConvertingBillOrPo");

export const SET_IS_CONVERTING_BILL_OR_PO_CONFIRMATION_ALERT_OPEN = createActionType(
  MODULE,
  "setIsConvertingBillOrPoConfirmationAlertOpen"
);

export const SET_IS_ADD_PRODUCT_MODAL_TYPE = createActionType(MODULE, "setIsAddProductModalType");
