import { STATUS } from "@/utils/constants";

import {
  SET_SIGNUP_DATA,
  SET_TIMEZONES,
  SET_COUNTRIES,
  SET_IS_SAVING,
  SET_IS_FETCHING,
  SET_XERO_ACCOUNTS,
  SET_INVITE_PAGE_STATUS,
  RESET,
} from "./signup.actionTypes";

const initialState = {
  data: null,
  isSaving: false,
  isFetching: false,
  timezones: [],
  countries: [],
  xeroAccounts: [],
  invitePageStatus: STATUS.IDLE,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_SAVING: {
      return {
        ...state,
        isSaving: action.payload,
      };
    }

    case SET_IS_FETCHING: {
      return {
        ...state,
        isFetching: action.payload,
      };
    }

    case SET_SIGNUP_DATA: {
      return {
        ...state,
        data: action.payload,
      };
    }

    case SET_COUNTRIES: {
      return {
        ...state,
        countries: action.payload,
      };
    }

    case SET_TIMEZONES: {
      return {
        ...state,
        timezones: action.payload,
      };
    }

    case SET_XERO_ACCOUNTS: {
      return {
        ...state,
        xeroAccounts: action.payload,
      };
    }

    case SET_INVITE_PAGE_STATUS: {
      return {
        ...state,
        invitePageStatus: action.payload,
      };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
