import { createApiV2, createApiPdf } from "./common";

export const getOrderBacklog = (data) => (token) =>
  createApiV2("POST", `deliveries/backlog/`)(token, data);

export const getSettings = () => createApiV2("GET", "deliveries/")();

export const postSettings = (data) => (token) => createApiV2("POST", "deliveries/")(token, data);

export const postMarkOrder = (data) => (token) =>
  createApiV2("POST", "workflow/change_order/")(token, data);

export const getOrdersInCalendar = (data) => (token) =>
  createApiV2("POST", `deliveries/calendar_orders/`)(token, data);

export const postCalendarEvent = (data) => (token) =>
  createApiV2("POST", "deliveries/add_to_calendar/")(token, data);

export const postMovedOrderToBacklog = (data) => (token) =>
  createApiV2("POST", "deliveries/change_order/")(token, data);

export const postPrinDeliveryRunSheet = (data) => (token) =>
  createApiPdf("POST", "deliveries/print_run_sheet", {
    responseType: "arraybuffer",
    Accept: "/application/pdf",
  })(token, data);

export default {};
