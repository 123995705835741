import createActionType from "../utils/createActionType";

export const MODULE = "assignees";

export const SET_IS_USERS_DROPDOWN_VISIBLE = createActionType(MODULE, "setIsUsersDropdownVisible");

export const SET_IS_SAVING_USER = createActionType(MODULE, "setIsSavingUser");

export const SET_IS_FETCHING_USERS = createActionType(MODULE, "setIsFetchingUsers");

export const SET_USERS = createActionType(MODULE, "setUsers");

export const SET_SELECTED_USERS = createActionType(MODULE, "setSelectedUsers");

export const SET_UNSELECTED_USERS = createActionType(MODULE, "setUnSelectedUsers");

export const RESET = createActionType(MODULE, "reset");
