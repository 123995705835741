import { createApi } from "./common";

const headers = {
  "Content-Type": "multipart/form-data",
};

export const getUser = createApi("GET", "customer/userdata/");

export const saveUserData = createApi("POST", "customer/userdata/");

export const saveCompanyData = (token, data) =>
  createApi("POST", "company/", { headers })(token, data);

export const getCompanyData = createApi("GET", "company/");

export const searchCompanyUsers = createApi("GET", "users/");

export const createCompanyUser = createApi("POST", "users/");

export const changeCompanyUserData = (token, userID, data) =>
  createApi("PUT", `users/${userID}/`)(token, data);

export const searchCustomers = createApi("GET", "customers/");

export const searchCustomersContacts = (token, uid, data) =>
  createApi("GET", `customers/${uid}/`)(token, data);

export const getCustomer = (token, customerUID) =>
  createApi("GET", `customers/${customerUID}/`)(token);

export const changeCustomerData = (token, customerUID, data) =>
  createApi("PUT", `customers/${customerUID}/`)(token, data);

export const deleteCustomer = (token, customerUID) =>
  createApi("DELETE", `customers/${customerUID}/`)(token);

export const changePassword = createApi("POST", "customer/password_reset/");

export default searchCustomers;
