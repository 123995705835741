import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import { Formik } from "formik";
import * as Yup from "yup";

import NonAuthLayout from "@/pages/NonAuthLayout";
import { Input } from "@/ui-lib/FormControl/Formik";
import { Button } from "@/ui-lib/FormFields";
import { FieldGroup, Tooltip, Loading } from "@/ui-lib";
import Logo from "@/images/new-factory-logo-black-with-text.png";
import * as actions from "@/redux/forgotPassword/forgotPassword.actions";
import * as selectors from "@/redux/forgotPassword/forgotPassword.selectors";
import * as authenticationSelectors from "@/redux/authentication/authentication.selectors";

import styles from "./NewPasswordPage.module.scss";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Your password must contain at least 8 characters")
    .matches(/\D/, {
      excludeEmptyString: true,
      message: "Your password can't be entirely numeric",
    })
    .test(
      "password",
      "Your password must contain a special character",
      (value) => !value || /[!@#$%&_=]/.test(value)
    )
    .test(
      "password",
      "Your password must contain a uppercase character",
      (value) => !value || /[A-Z]/.test(value)
    ),
  confirm_password: Yup.string().oneOf([Yup.ref("password")], "Password do not match"),
});

const NewPasswordPage = () => {
  const dispatch = useDispatch();
  const { token, encoded_email: encodedEmail } = useParams();

  const isSaving = useSelector(selectors.isSaving);
  const isSuccess = useSelector(selectors.isSuccess);
  const emailFromToken = useSelector(selectors.getEmailFromToken);
  const isAuthenticated = useSelector(authenticationSelectors.isAuthenticated);
  const isValidatingToken = useSelector(selectors.isValidatingToken);
  const isTokenValid = useSelector(selectors.isTokenValid);

  useEffect(() => {
    dispatch(
      actions.validateToken({
        token,
        encoded_email: encodedEmail,
      })
    );
  }, []);

  const onSubmit = (data) => {
    dispatch(
      actions.setNewPassword({
        ...data,
        token,
        email: emailFromToken,
      })
    );
  };

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  if (isValidatingToken) {
    return (
      <div className={styles.loadingWrapper}>
        <Loading />
      </div>
    );
  }

  return (
    <NonAuthLayout>
      <div className={styles.wrapper}>
        <img src={Logo} alt="Factory" title="Factory" className={styles.logo} />
        <div className={styles.content}>
          {isTokenValid ? (
            <>
              <h1 className={styles.title}>Enter your new password</h1>
              <span className={styles.subTitle}>
                You&apos;ll use the new password that you enter below to login moving forward.
              </span>

              <div className={styles.form}>
                <Formik
                  enableReinitialize
                  initialValues={{
                    password: "",
                    confirm_password: "",
                  }}
                  validateOnChange={false}
                  validateOnBlur={false}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                  render={(props) => {
                    const { handleSubmit, ..._props } = props;
                    return (
                      <form className="w-100" onSubmit={handleSubmit}>
                        <FieldGroup
                          label={
                            <Tooltip
                              title="Password must be at least 8 characters long, and must contain at least upper case
                character and one special character."
                            >
                              Password{" "}
                              <FontAwesomeIcon className={styles.icon} icon={faInfoCircle} />
                            </Tooltip>
                          }
                          contentClassName={styles.fieldGroup}
                        >
                          <Input
                            type="password"
                            value={props.values.password}
                            name="password"
                            id="password"
                            containerClassName={styles.formItem}
                            {..._props}
                          />
                        </FieldGroup>
                        <FieldGroup label="Confirm password" contentClassName={styles.fieldGroup}>
                          <Input
                            type="password"
                            value={props.values.confirm_password}
                            name="confirm_password"
                            id="confirm_password"
                            containerClassName={styles.formItem}
                            {..._props}
                          />
                        </FieldGroup>
                        <div className={styles.action}>
                          <Button
                            disabled={isSaving}
                            loading={isSaving}
                            color="primary"
                            type="submit"
                          >
                            Confirm new password
                          </Button>
                        </div>
                      </form>
                    );
                  }}
                />
              </div>
            </>
          ) : (
            <>
              {isSuccess ? (
                <>
                  <h1 className={styles.title}>Password reset sent</h1>
                  <span className={styles.subTitle}>
                    If an account exists for this email address, we&apos;ll send you an email so
                    that you can reset your password.
                  </span>
                  <span className={styles.subTitle}>You may now close this page.</span>
                </>
              ) : (
                <>
                  <h1 className={styles.title}>Link expired</h1>
                  <span className={styles.subTitle}>
                    The link you clicked on has expired and is no longer valid.
                  </span>
                  <div className={styles.resendAction}>
                    <Button
                      onClick={() =>
                        dispatch(
                          actions.resendResetPassword({
                            encoded_email: encodedEmail,
                          })
                        )
                      }
                      disabled={isSaving}
                      loading={isSaving}
                      color="primary"
                      type="submit"
                    >
                      Resend
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </NonAuthLayout>
  );
};

export default NewPasswordPage;
