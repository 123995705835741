import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router/immutable";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";

import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import store from "../redux/store";
import Loading from "../components/common/Loading";
import PrintPage from "../components/common/PrintPage";
import SignupPage from "../pages/SignupPage";
import ForgotPasswordPage from "../pages/ForgotPasswordPage";
import NewPasswordPage from "../pages/ForgotPasswordPage/NewPasswordPage";

import AuthRoutes from "./AuthRoutes";
import history from "./history";

import "./mobiscroll/style.scss";

const LoginPage = lazy(() => import("../pages/LoginPage"));
const RootPage = lazy(() => import("../pages/RootPage"));
const InviteTeamMemberPage = lazy(() => import("../pages/InviteTeamMemberPage"));

const App = () => (
  <Suspense fallback={<Loading className="vh-100" />}>
    <GoogleOAuthProvider clientId={process.env.GOOGLE_CLIENT_ID}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route exact path="/" component={RootPage} />
            <Route exact path="/print" component={PrintPage} />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/forgot-password" component={ForgotPasswordPage} />
            <Route exact path="/set-password/:encoded_email/:token" component={NewPasswordPage} />
            <Route path="/signup" component={SignupPage} />
            <Route path="/invite" component={InviteTeamMemberPage} />
            <AuthRoutes />
          </Switch>
        </ConnectedRouter>
      </Provider>
    </GoogleOAuthProvider>
  </Suspense>
);

export default App;
