import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";

export const parseFlashingToCK = (dataItem) => {
  const item = camelcaseKeys(dataItem);

  item.parameters.thickness.forEach((thickness) =>
    item.priceLevels.forEach((priceLevel) => {
      const table = item.tables[thickness][priceLevel];

      const {
        extra_bend_fee: extraBendFee,
        tapered_fee: taperedFee,
        add_tapers_charge: addTapersCharge,
      } = table;

      table.extraBendFee = {
        value: extraBendFee.value,
        isSelected: extraBendFee.is_selected,
      };

      delete table.extra_bend_fee;

      table.taperedFee = taperedFee;
      table.addTapersCharge = addTapersCharge;
      delete table.tapered_fee;
      delete table.add_tapers_charge;
    })
  );

  return item;
};

export const parseFlashingToSK = (dataItem) => {
  const item = snakecaseKeys(dataItem, { deep: false });

  item.parameters.thickness.forEach((thickness) =>
    item.price_levels.forEach((priceLevel) => {
      const table = item.tables[thickness][priceLevel];

      const { extraBendFee, taperedFee, addTapersCharge } = table;

      table.extra_bend_fee = {
        value: extraBendFee.value,
        is_selected: extraBendFee.isSelected,
      };

      table.tapered_fee = taperedFee;
      table.add_tapers_charge = addTapersCharge;
      delete table.taperedFee;
      delete table.addTapersCharge;
    })
  );

  return item;
};

export const sortByMaterialName = (list) =>
  list.sort((_a, _b) => {
    const a = _a.get("name").toLowerCase();
    const b = _b.get("name").toLowerCase();
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  });

export const getCompanyTaxRate = (state) => {
  const taxRate = state.getIn(["customers", "company", "taxRate"]);
  return taxRate;
};

export const getMinLengthProducts = (state) => {
  const company = state.getIn(["customers", "company"]);
  let minLength = 0;

  if (company) {
    const allProductsSettings = company
      .getIn(["settings", "productSettings", "allProducts"])
      .toJS();

    if (allProductsSettings) {
      const settingsLength = allProductsSettings.find(
        (setting) => setting.key === "AP_MIN_LENGTH_MM"
      );

      if (settingsLength && settingsLength.isSelected) {
        minLength = parseInt(settingsLength.value, 10);
      }
    }
  }

  return minLength;
};

export const getMinLengthFlashings = (state) => {
  const company = state.getIn(["customers", "company"]);
  let minLength = 0;

  if (company) {
    const flashingsSettings = company.getIn(["settings", "productSettings", "flashings"]).toJS();

    if (flashingsSettings) {
      const settingsLength = flashingsSettings.find(
        (setting) => setting.key === "FLASHING_MIN_LENGTH_MM"
      );

      if (settingsLength && settingsLength.isSelected) {
        minLength = parseInt(settingsLength.value, 10);
      }
    }
  }

  return minLength;
};

export const getSortLengthsSetting = (state) => {
  const company = state.getIn(["customers", "company"]);

  if (company) {
    const orderSettings = company.getIn(["settings", "ordersSettings", "orders"]).toJS();

    if (!orderSettings) return null;

    const settingsSortLength = orderSettings.find((setting) => setting.key === "AP_SORT_LENGTHS");

    if (settingsSortLength) {
      const sortLength = settingsSortLength.selected ? settingsSortLength.selected : null;
      return sortLength;
    }

    return null;
  }

  return null;
};

export default parseFlashingToCK;
