import { orderBy } from "lodash";

import { MODULE } from "./timeEntry.actionTypes";

export const isSavingStatus = (state) => state.getIn([MODULE, "isSavingStatus"]);

export const isFetchingTeamMembersStatus = (state) =>
  state.getIn([MODULE, "isFetchingTeamMembersStatus"]);

export const getTeamMembers = (state) =>
  orderBy(state.getIn([MODULE, "teams"]), [(l) => l.firstName.toLowerCase()]);

export const isSearchinggOrderNumberStatus = (state) =>
  state.getIn([MODULE, "isSearchinggOrderNumberStatus"]);

export const getOrderNumberResults = (state) => state.getIn([MODULE, "orderNumberResults"]);

export const isFetchingOrderItemsStatus = (state) =>
  state.getIn([MODULE, "isFetchingOrderItemsStatus"]);

export const getOrderItems = (state) =>
  state.getIn([MODULE, "orderItems"]).map((a) => ({
    ...a,
    productKitItems: a?.productKitItems?.map((p) => ({
      ...p,
      productKitUid: a.uid,
      productType: "Kit item",
    })),
    subKits: a?.subKits?.map((s) => ({
      ...s,
      productKitUid: a.uid,
      productType: "Sub-kit",
      kitProducts: s.kitProducts.map((k) => ({
        ...k,
        productKitUid: a.uid,
        subKitUid: s.uid,
        productType: "Sub-kit item",
      })),
    })),
  }));
