import { MODULE } from "./productKits.actionTypes";

export const isFetchingCategories = (state) => state.getIn([MODULE, "isFetchingCategories"]);

export const isFetchingProductKit = (state) => state.getIn([MODULE, "isFetchingProductKit"]);

export const isSaving = (state) => state.getIn([MODULE, "isSaving"]);

export const isModalVisible = (state) => state.getIn([MODULE, "isModalVisible"]);

export const isCartVisible = (state) => state.getIn([MODULE, "isCartVisible"]);

export const getCategories = (state) => state.getIn([MODULE, "categories"]);

export const getExpandedCategories = (state) => state.getIn([MODULE, "expandedCategories"]);

export const getExpandedProducts = (state) => state.getIn([MODULE, "expandedProducts"]);

export const getExpandedSubKitProducts = (state) => state.getIn([MODULE, "expandedSubKitProducts"]);

export const getProducts = (state) => state.getIn([MODULE, "products"]);

export const getProductsFromCategory = (state, categoryUid) =>
  state.getIn([MODULE, "products", categoryUid]);

export const getCart = (state) => state.getIn([MODULE, "kitProducts"]);

export const getProductKitProducts = (state) => state.getIn([MODULE, "productKitProducts"]);

export const getCurrentProductKit = (state) => state.getIn([MODULE, "currentProductKit"]);

export const getSubKits = (state) => state.getIn([MODULE, "subKits"]);

export const getIsSubKitModalOpen = (state) => state.getIn([MODULE, "isSubKitModalOpen"]);

export const getSubKitIndex = (state) => state.getIn([MODULE, "subKitIndex"]);

export const getSearch = (state) => state.getIn([MODULE, "search"]);

export const getSelectedPriceLevels = (state) => state.getIn([MODULE, "selectedPriceLevels"]);

export const getKitLabourLineItems = (state) => state.getIn([MODULE, "kitLabourLineItems"]);
