import { createApi } from "./common";

export const searchMaterials = createApi("GET", "material/");

export const getMaterial = (token, materialUid) =>
  createApi("GET", `material/${materialUid}/`)(token);

export const createMaterial = createApi("POST", "material/");

export const editMaterial = (token, material) =>
  createApi("PATCH", `material/${material.uid}/`)(token, material);

export const duplicateMaterial = (token, materialUid) =>
  createApi("POST", `material/${materialUid}/duplicate/`)(token);
