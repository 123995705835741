import { STATUS } from "@/utils/constants";

import {
  SET_IS_EDITTING,
  SET_CHARTS,
  SET_CHARTS_STATUS,
  SET_ADD_USER_WIDGET_STATUS,
  SET_USER_WIDGETS,
  SET_USER_WIDGETS_STATUS,
  SET_UPDATE_USER_WIDGET_STATUS,
  SET_UPDATE_BULK_USER_WIDGET_STATUS,
  SET_DELETE_USER_WIDGET_STATUS,
  SET_SALES_ORDERS,
  SET_PURCHASE_ORDERS,
  SET_OPEN_QUOTES,
  SET_ORDERS_BY_REQUIRED_DATE,
  SET_ORDERS_BY_STATUS,
  SET_ORDERS_CREATED_BY_ME,
  SET_TOP_CUSTOMERS,
  SET_DELIVERIES_SCHEDULED,
  SET_ORDERS_BY_TEAM_MEMBER,
  SET_ALL_ORDERS_WORKED_ON,
  SET_ORDERS_ACTIVELY_WORKED_ON,
  SET_TIME_WORKED_PER_TEAM_MEMBER,
  SET_CHARTS_REQUEST_PARAMS,
  SET_ORDER_STATUS,
  SET_USERS,
  SET_CHART_LOADING_STATUS,
  SET_EXPORT_TO_FILE_LOADING_STATUS,
  RESET,
} from "./dashboard.actionTypes";

const initialState = {
  isEditting: false,
  chartsStatus: STATUS.IDLE,
  charts: [],
  addUserWidgetStatus: STATUS.IDLE,
  userWidgets: {},
  userWidgetsStatus: STATUS.IDLE,
  updateUserWidgetStatus: STATUS.IDLE,
  updateBulkUserWidgetStatus: STATUS.IDLE,
  deleteUserWidgetStatus: STATUS.IDLE,
  salesOrders: [],
  purchaseOrders: [],
  openQuotes: [],
  ordersByRequiredDate: [],
  ordersByStatus: [],
  ordersCreatedByMe: [],
  topCustomers: [],
  deliveriesScheduled: [],
  ordersByTeamMember: [],
  allOrdersWorkedOn: [],
  ordersActivelyWorkedOn: [],
  timeWorkedPerTeamMember: [],
  chartsRequestParams: {},
  statuses: [],
  users: [],
  loadingStatus: {},
  exportToFileLoadingStatus: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_EDITTING: {
      return { ...state, isEditting: action.payload };
    }

    case SET_CHARTS: {
      return { ...state, charts: action.payload };
    }

    case SET_CHARTS_STATUS: {
      return { ...state, chartsStatus: action.payload };
    }

    case SET_ADD_USER_WIDGET_STATUS: {
      return { ...state, addUserWidgetStatus: action.payload };
    }

    case SET_USER_WIDGETS: {
      return { ...state, userWidgets: action.payload };
    }

    case SET_USER_WIDGETS_STATUS: {
      return { ...state, userWidgetsStatus: action.payload };
    }

    case SET_UPDATE_USER_WIDGET_STATUS: {
      return { ...state, updateUserWidgetStatus: action.payload };
    }

    case SET_UPDATE_BULK_USER_WIDGET_STATUS: {
      return { ...state, updateBulkUserWidgetStatus: action.payload };
    }

    case SET_DELETE_USER_WIDGET_STATUS: {
      return { ...state, deleteUserWidgetStatus: action.payload };
    }

    case SET_SALES_ORDERS: {
      return { ...state, salesOrders: action.payload };
    }

    case SET_PURCHASE_ORDERS: {
      return { ...state, purchaseOrders: action.payload };
    }

    case SET_OPEN_QUOTES: {
      return { ...state, openQuotes: action.payload };
    }

    case SET_ORDERS_BY_REQUIRED_DATE: {
      return { ...state, ordersByRequiredDate: action.payload };
    }

    case SET_ORDERS_BY_STATUS: {
      return { ...state, ordersByStatus: action.payload };
    }

    case SET_ORDERS_CREATED_BY_ME: {
      return { ...state, ordersCreatedByMe: action.payload };
    }

    case SET_TOP_CUSTOMERS: {
      return { ...state, topCustomers: action.payload };
    }

    case SET_DELIVERIES_SCHEDULED: {
      return { ...state, deliveriesScheduled: action.payload };
    }

    case SET_ORDERS_BY_TEAM_MEMBER: {
      return { ...state, ordersByTeamMember: action.payload };
    }

    case SET_ALL_ORDERS_WORKED_ON: {
      return { ...state, allOrdersWorkedOn: action.payload };
    }

    case SET_ORDERS_ACTIVELY_WORKED_ON: {
      return { ...state, ordersActivelyWorkedOn: action.payload };
    }

    case SET_TIME_WORKED_PER_TEAM_MEMBER: {
      return { ...state, timeWorkedPerTeamMember: action.payload };
    }

    case SET_CHARTS_REQUEST_PARAMS:
      return { ...state, chartsRequestParams: action.payload };

    case SET_ORDER_STATUS:
      return { ...state, statuses: action.payload };

    case SET_USERS:
      return { ...state, users: action.payload };

    case SET_CHART_LOADING_STATUS: {
      return { ...state, loadingStatus: { ...state.loadingStatus, ...action.payload } };
    }

    case SET_EXPORT_TO_FILE_LOADING_STATUS: {
      return {
        ...state,
        exportToFileLoadingStatus: { ...state.exportToFileLoadingStatus, ...action.payload },
      };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
