import { SET_SEARCH, RESET } from "./searchFilter.actionTypes";

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setSearch = (data) => (dispatch) => {
  dispatch({
    type: SET_SEARCH,
    payload: data,
  });
};
