import createActionType from "../utils/createActionType";

export const MODULE = "views";

export const SET_IS_FETCHING_VIEWS = createActionType(MODULE, "setIsFetchingViews");

export const SET_VIEWS = createActionType(MODULE, "setViews");

export const SET_ACTIVE_VIEW = createActionType(MODULE, "setActiveView");

export const SET_IS_CREATE_VIEW_FORM_VISIBLE = createActionType(
  MODULE,
  "setIsCreateViewFormVisible"
);

export const SET_SELECTED_VIEW = createActionType(MODULE, "setSelectedView");

export const SET_DEFAULT_VIEW = createActionType(MODULE, "setDefaultView");

export const RESET = createActionType(MODULE, "reset");
