import { orderBy } from "lodash";

import { userSelector } from "../../customers/customers.selectors";

import { MODULE } from "./orders.actionTypes";

export const isFetchingOrders = (state) => state.getIn([MODULE, "isFetchingOrders"]);

export const getOrders = (state) => state.getIn([MODULE, "orders"]);

export const getPagination = (state) => state.getIn([MODULE, "pagination"]);

export const getSortedAssignees = (state, assignees) => {
  const currentUser = userSelector(state);

  const formattedAssignees = assignees.map((a) =>
    a.id === currentUser.id ? { ...a, lastName: `${a.lastName} (You)` } : a
  );
  const sortedAssignees = [
    ...(formattedAssignees.some((a) => a.id === currentUser.id)
      ? [{ ...formattedAssignees.find((a) => a.id === currentUser.id) }]
      : []),
    ...orderBy(
      formattedAssignees.filter((a) => a.id !== currentUser.id),
      [(a) => a.firstName.toLowerCase()],
      "asc"
    ),
  ];

  return sortedAssignees;
};

export const getSortedLabels = (state, labels) => {
  const sortedLabels = orderBy(labels, [(l) => l.name.toLowerCase()]);
  return sortedLabels;
};
