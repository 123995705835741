import {
  HIDE_ALL_ORDER_COLUMNS,
  REORDER_ALL_ORDER_COLUMNS,
  UPDATE_WIDTH_ALL_ORDER_COLUMNS,
  HIDE_CUSTOMER_ORDER_COLUMNS,
  REORDER_CUSTOMER_ORDER_COLUMNS,
  UPDATE_WIDTH_CUSTOMER_ORDER_COLUMNS,
} from "./preferences.actionTypes";

const initialState = {
  allOrdersColumnState: {
    purchaseOrder: {
      show: true,
      order: 0,
      width: 80,
    },
    customerName: {
      show: true,
      order: 1,
      width: 180,
    },
    createdTime: {
      show: true,
      order: 2,
      width: 120,
    },
    poNumber: {
      show: true,
      order: 3,
      width: 140,
    },
    totalValue: {
      show: true,
      order: 4,
      width: 120,
    },
    createdBy: {
      show: true,
      order: 5,
      width: 100,
    },
    status: {
      show: true,
      order: 6,
      width: 140,
    },
    invoiced: {
      show: true,
      order: 7,
      width: 80,
    },
  },
  customerOrdersColumnState: {
    purchaseOrder: {
      show: true,
      order: 0,
      width: 80,
    },
    createdTime: {
      show: true,
      order: 1,
      width: 130,
    },
    required: {
      show: true,
      order: 2,
      width: 120,
    },
    poNumber: {
      show: true,
      order: 3,
      width: 140,
    },
    totalValue: {
      show: true,
      order: 4,
      width: 100,
    },
    createdBy: {
      show: true,
      order: 5,
      width: 100,
    },
    status: {
      show: true,
      order: 6,
      width: 140,
    },
    invoiced: {
      show: true,
      order: 7,
      width: 80,
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    // ALL ORDERS //

    case HIDE_ALL_ORDER_COLUMNS: {
      const { id } = action.payload;

      // This is a blanket fix for the use of react-immutable combine reducer
      try {
        state = state.toJS();
      } catch {
        // do nothing
      }

      const updatedColumn = {
        ...state.allOrdersColumnState[id],
        show: !state.allOrdersColumnState[id].show,
      };

      return {
        ...state,
        allOrdersColumnState: {
          ...state.allOrdersColumnState,
          [id]: updatedColumn,
        },
      };
    }
    case REORDER_ALL_ORDER_COLUMNS: {
      const { sourceId, destinationId } = action.payload;

      // This is a blanket fix for the use of react-immutable combine reducer
      try {
        state = state.toJS();
      } catch {
        // do nothing
      }

      const allOrdersColumnState = { ...state.allOrdersColumnState };

      // Reorder forward
      if (allOrdersColumnState[sourceId].order < allOrdersColumnState[destinationId].order) {
        let currentColumnId = sourceId;
        let currentIndex = allOrdersColumnState[destinationId].order;
        const endIndex = allOrdersColumnState[sourceId].order;

        while (currentIndex >= endIndex) {
          // Reorder the current column
          allOrdersColumnState[currentColumnId].order = currentIndex;

          // Break out of function when last iteration
          if (currentIndex === endIndex) {
            currentIndex -= 1;
            break;
          }

          // Find the next column to reorder
          currentColumnId = Object.entries(allOrdersColumnState)
            // eslint-disable-next-line no-loop-func
            .find(([key, value]) => key !== currentColumnId && value.order === currentIndex)[0];

          // Move the pointer
          currentIndex -= 1;
        }

        return { ...state, allOrdersColumnState };
      }

      // Reorder Backwards
      if (allOrdersColumnState[sourceId].order > allOrdersColumnState[destinationId].order) {
        let currentColumnId = sourceId;
        let currentIndex = allOrdersColumnState[destinationId].order;
        const endIndex = allOrdersColumnState[sourceId].order;

        while (currentIndex <= endIndex) {
          // Reorder the current column
          allOrdersColumnState[currentColumnId].order = currentIndex;

          // Break out of function when last iteration
          if (currentIndex === endIndex) {
            currentIndex += 1;
            break;
          }

          // Find the next column to reorder
          currentColumnId = Object.entries(allOrdersColumnState)
            // eslint-disable-next-line no-loop-func
            .find(([key, value]) => key !== currentColumnId && value.order === currentIndex)[0];

          // Move the pointer
          currentIndex += 1;
        }
      }

      return { ...state, allOrdersColumnState };
    }
    case UPDATE_WIDTH_ALL_ORDER_COLUMNS: {
      const { id, width } = action.payload;

      // This is a blanket fix for the use of react-immutable combine reducer
      try {
        state = state.toJS();
      } catch {
        // do nothing
      }

      const updatedColumn = {
        ...state.allOrdersColumnState[id],
        width,
      };

      return {
        ...state,
        allOrdersColumnState: {
          ...state.allOrdersColumnState,
          [id]: updatedColumn,
        },
      };
    }

    // CUSTOMER ORDERS //

    case HIDE_CUSTOMER_ORDER_COLUMNS: {
      const { id } = action.payload;

      // This is a blanket fix for the use of react-immutable combine reducer
      try {
        state = state.toJS();
      } catch {
        // do nothing
      }

      const updatedColumn = {
        ...state.custmerOrdersColumnState[id],
        show: !state.customerOrdersColumnState[id].show,
      };

      return {
        ...state,
        customerOrdersColumnState: {
          ...state.customerOrdersColumnState,
          [id]: updatedColumn,
        },
      };
    }
    case REORDER_CUSTOMER_ORDER_COLUMNS: {
      const { sourceId, destinationId } = action.payload;

      // This is a blanket fix for the use of react-immutable combine reducer
      try {
        state = state.toJS();
      } catch {
        // do nothing
      }

      const customerOrdersColumnState = { ...state.customerOrdersColumnState };

      // Reorder forward
      if (
        customerOrdersColumnState[sourceId].order < customerOrdersColumnState[destinationId].order
      ) {
        let currentColumnId = sourceId;
        let currentIndex = customerOrdersColumnState[destinationId].order;
        const endIndex = customerOrdersColumnState[sourceId].order;

        while (currentIndex >= endIndex) {
          // Reorder the current column
          customerOrdersColumnState[currentColumnId].order = currentIndex;

          // Break out of function when last iteration
          if (currentIndex === endIndex) {
            currentIndex -= 1;
            break;
          }

          // Find the next column to reorder
          currentColumnId = Object.entries(customerOrdersColumnState)
            // eslint-disable-next-line no-loop-func
            .find(([key, value]) => key !== currentColumnId && value.order === currentIndex)[0];

          // Move the pointer
          currentIndex -= 1;
        }
      }

      // Reorder Backwards
      if (
        customerOrdersColumnState[sourceId].order > customerOrdersColumnState[destinationId].order
      ) {
        let currentColumnId = sourceId;
        let currentIndex = customerOrdersColumnState[destinationId].order;
        const endIndex = customerOrdersColumnState[sourceId].order;

        while (currentIndex <= endIndex) {
          // Reorder the current column
          customerOrdersColumnState[currentColumnId].order = currentIndex;

          // Break out of function when last iteration
          if (currentIndex === endIndex) {
            currentIndex += 1;
            break;
          }

          // Find the next column to reorder
          currentColumnId = Object.entries(customerOrdersColumnState)
            // eslint-disable-next-line no-loop-func
            .find(([key, value]) => key !== currentColumnId && value.order === currentIndex)[0];

          // Move the pointer
          currentIndex += 1;
        }
      }

      return { ...state, customerOrdersColumnState };
    }
    case UPDATE_WIDTH_CUSTOMER_ORDER_COLUMNS: {
      const { id, width } = action.payload;

      // This is a blanket fix for the use of react-immutable combine reducer
      try {
        state = state.toJS();
      } catch {
        // do nothing
      }

      const updatedColumn = {
        ...state.customerOrdersColumnState[id],
        width,
      };

      return {
        ...state,
        customerOrdersColumnState: {
          ...state.customerOrdersColumnState,
          [id]: updatedColumn,
        },
      };
    }

    default:
      return state;
  }
};
