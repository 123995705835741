import React, { useEffect, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import ToastContainer from "@/ui-lib/ToastContainer";
import * as notificationsSelectors from "@/redux/notifications/notifications.selectors";
import * as notificationsActions from "@/redux/notifications/notifications.actions";

import Notification from "../Notification";

const NotificationContainer = () => {
  const notification = useSelector(notificationsSelectors.getNotification);
  const lastNotification = useSelector(notificationsSelectors.getLastNotification);

  const dispatch = useDispatch();

  useEffect(() => {
    if (notification && notification.id !== lastNotification) {
      toast(<Notification message={notification} />, {
        hideProgressBar: true,
        draggable: false,
        closeButton: false,
        closeOnClick: true,
        autoClose: 5000,
      });

      dispatch(notificationsActions.setLastNotification(notification.id));
    }
  }, [notification]);

  return <ToastContainer />;
};

export default memo(NotificationContainer);
