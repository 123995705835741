import camelcaseKeys from "camelcase-keys";
import { nanoid } from "nanoid";

import { createAlertAction } from "../alerts/alerts.actions";
import {
  DRAFT_PURCHASE_ORDER,
  UPDATE_PURCHASE_ORDER,
  GET_PURCHASE_ORDER,
  GET_EXTERNAL_PURCHASE_ORDER,
  UPDATE_PURCHASE_ORDER_SILENTLY,
} from "../purchaseOrders/purchaseOrders.actionTypes";

const tokenInject = (service) => {
  const token = JSON.parse(window.localStorage.getItem("auth"));
  return service()(token);
};

// eslint-disable-next-line consistent-return
const dispatchApi = async (dispatch, actionName, service, intent) => {
  // Create API actions
  const pendingAction = () => ({
    type: `${actionName}/pending`,
    payload: {},
  });
  const fulfilledAction = (data) => ({
    type: `${actionName}/fulfilled`,
    intent,
    payload: { data },
  });
  const rejectedAction = (data) => ({
    type: `${actionName}/rejected`,
    payload: { data },
  });

  // actions that we want to manually camelCase in the reducer
  const actionNames = [
    DRAFT_PURCHASE_ORDER,
    UPDATE_PURCHASE_ORDER,
    UPDATE_PURCHASE_ORDER_SILENTLY,
    GET_PURCHASE_ORDER,
    GET_EXTERNAL_PURCHASE_ORDER,
  ];

  dispatch(pendingAction());

  try {
    const response = await tokenInject(service);
    const responseData = response?.data;

    if (actionNames.includes(actionName)) {
      dispatch(fulfilledAction(responseData));
      return responseData;
    }

    if (typeof responseData === "string" || !responseData) {
      dispatch(fulfilledAction(responseData));
      return responseData;
    } else if (responseData instanceof ArrayBuffer) {
      const file = new Blob([responseData], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } else {
      dispatch(fulfilledAction(camelcaseKeys(responseData, { deep: true })));
      return responseData;
    }
  } catch (error) {
    console.log("dispatchApi", error.response);
    dispatch(rejectedAction(error));

    // Dispatch alerts action to display error
    if (error.response) {
      // Handle PDF print errors
      if (error.response.data instanceof ArrayBuffer) {
        const decodedResponse = new TextDecoder().decode(error.response.data);

        if (decodedResponse) {
          const responseJson = JSON.parse(decodedResponse);
          dispatch(createAlertAction(nanoid(), responseJson.detail.errors, false, "danger"));
        }
      }

      // check if `errors` property exists in the response data and update the error object accordingly
      else if (error.response.data.detail && error.response.data.detail.errors) {
        dispatch(createAlertAction(nanoid(), error.response.data.detail.errors, false, "danger"));
      } else {
        dispatch(
          createAlertAction(
            nanoid(),
            typeof error.response.data === "string"
              ? error.response.data
              : JSON.stringify(error.response.data),
            false,
            "danger"
          )
        );
      }
    }
  }
};

export default dispatchApi;
