import { MODULE } from "./workflow.actionTypes";

export const getSettings = (state) => state.getIn([MODULE, "settings"]);

export const isFetchingSettings = (state) => state.getIn([MODULE, "isFetchingSettings"]);

export const getColumns = (state) => state.getIn([MODULE, "columns"]);

export const getSelectecdColumns = (state) => state.getIn([MODULE, "selectedColumns"]);

export const isFetchingColumns = (state) => state.getIn([MODULE, "isFetchingColumns"]);

export const getCards = (state) => state.getIn([MODULE, "cards"]);

export const getCardsByColumnUid = (state, columnUid) => state.getIn([MODULE, "cards"])[columnUid];

export const isFetchingCards = (state) => state.getIn([MODULE, "isFetchingCards"]);

export const isPageLoading = (state) =>
  state.getIn([MODULE, "isFetchingCards"]) ||
  state.getIn([MODULE, "isFetchingSettings"]) ||
  state.getIn([MODULE, "isFetchingColumns"]);

export const getActiveView = (state) => state.getIn([MODULE, "activeView"]);

export const isOrderModalOpen = (state) => state.getIn([MODULE, "isOrderModalOpen"]);
