import { STATUS } from "@/utils/constants";

import {
  SET_IS_MODAL_VISIBLE,
  SET_TEAM_MEMBERS,
  SET_IS_FETCHING,
  SET_CART,
  SET_ITEMS_TO_CART,
  SET_KIT_TYPE,
  RESET,
} from "./add-labour-line-items.actionTypes";

const initialState = {
  isModalVisible: "",
  isFetching: STATUS.IDLE,
  teamMembers: [],
  cart: [],
  kitType: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_MODAL_VISIBLE: {
      return {
        ...state,
        isModalVisible: action.payload,
      };
    }

    case SET_TEAM_MEMBERS: {
      return {
        ...state,
        teamMembers: action.payload,
      };
    }

    case SET_IS_FETCHING: {
      return {
        ...state,
        isFetching: action.payload,
      };
    }

    case SET_CART: {
      return {
        ...state,
        cart: action.payload,
      };
    }

    case SET_ITEMS_TO_CART: {
      return {
        ...state,
        cart: action.payload,
      };
    }

    case SET_KIT_TYPE: {
      return {
        ...state,
        kitType: action.payload,
      };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
