import qs from "query-string";

import { createApiV2 } from "./common";

export const getChecklists = (params) => (token) =>
  createApiV2("GET", `checklists/?${qs.stringify(params)}`)(token);

export const getChecklistTemplates = () => () =>
  createApiV2("GET", "checklists/?template_only=true")();

export const postChecklist = (data) => (token) => createApiV2("POST", "checklists/")(token, data);

export const getChecklist = (checklistUid) => () =>
  createApiV2("GET", `checklists/${checklistUid}/`)();

export const patchChecklist = (checklistUid, data) => (token) =>
  createApiV2("PATCH", `checklists/${checklistUid}/`)(token, data);

export const deleteChecklist = (checklistUid) => (token) =>
  createApiV2("DELETE", `checklists/${checklistUid}/`)(token);

export const postChecklistItem = (checklistUid, data) => (token) =>
  createApiV2("POST", `checklists/${checklistUid}/add_item/`)(token, data);

export const patchChecklistItem = (itemUid, data) => (token) =>
  createApiV2("PATCH", `checklists/items/${itemUid}/`)(token, data);

export const postChecklistItemComplete = (itemUid) => (token) =>
  createApiV2("POST", `checklists/items/${itemUid}/mark_completed/`)(token);

export const postChecklistItemNotComplete = (itemUid) => (token) =>
  createApiV2("POST", `checklists/items/${itemUid}/mark_not_completed/`)(token);

export const deleteChecklistItem = (itemUid) => (token) =>
  createApiV2("DELETE", `checklists/items/${itemUid}/`)(token);

export const postCreateTemplate = (itemUid, data) => (token) =>
  createApiV2("POST", `checklists/${itemUid}/duplicate/`)(token, data);

export const postAttachments = (itemUid, data) => (token) =>
  createApiV2("POST", `checklists/items/${itemUid}/add_attachments/`, {
    headers: { "Content-Type": "multipart/form-data" },
  })(token, data);

export const deleteAttachments = (itemUid, data) => (token) =>
  createApiV2("POST", `checklists/items/${itemUid}/remove_attachments/`)(token, data);

export const postOverrideTemplate = (data) => (token) =>
  createApiV2("POST", `checklists/overwrite_template/`)(token, data);
