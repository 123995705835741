import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { Input as BaseInput } from "@/ui-lib/FormFields";

import styles from "./Input.module.scss";

const Input = ({ id, containerClassName, className, errors, withoutError, invalid, ...props }) => {
  const error = !!(errors[id] || invalid) && !withoutError;

  const handleFocus = (e) => {
    const { autoSelected, onFocus } = props;

    if (autoSelected) {
      e.target.select();
    }

    if (onFocus) {
      onFocus(e);
    }
  };

  const handleBlur = (e) => {
    const { onBlur, setFieldValue, setFieldTouched } = props;
    const { value } = e.target;

    if (onBlur) {
      onBlur(id, value);
    }

    setFieldValue(id, value || "");
    setFieldTouched(id, true);
  };

  const handleChange = (e) => {
    const { onChange, setFieldValue } = props;
    const { value } = e.target;

    if (onChange) {
      onChange(id, value);
    }

    setFieldValue(id, value || "");
  };

  return (
    <div
      className={cx(
        styles.inputContainer,
        error && errors[id] && props.touched[id] ? styles.inputError : "",
        containerClassName
      )}
    >
      <BaseInput
        {...props}
        className={cx(className)}
        onFocus={handleFocus}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {error && errors[id] && props.touched[id] && (
        <div className={styles.errorMessage}>{errors[id]}</div>
      )}
    </div>
  );
};

Input.defaultProps = {
  setFieldTouched: () => null,
  setFieldValue: () => null,
  onFocus: () => null,
  onChange: () => null,
  onBlur: () => null,
  withoutError: false,
  invalid: false,
  errors: {},
  containerClassName: "",
  className: "",
  autoSelected: false,
};

Input.propTypes = {
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  id: PropTypes.string.isRequired,
  withoutError: PropTypes.bool,
  invalid: PropTypes.bool,
  errors: PropTypes.shape({}),
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  autoSelected: PropTypes.bool,
};

export default Input;
