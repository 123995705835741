import {
  SET_NOTIFICATION,
  SET_NOTIFICATION_TYPE,
  SET_LAST_NOTIFICATION,
  RESET,
} from "./notifications.actionTypes";

const initialState = {
  notification: null,
  notificationType: null,
  lastNotification: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATION: {
      return {
        ...state,
        notification: action.payload,
      };
    }

    case SET_NOTIFICATION_TYPE: {
      return {
        ...state,
        notificationType: action.payload,
      };
    }

    case SET_LAST_NOTIFICATION: {
      return {
        ...state,
        lastNotification: action.payload,
      };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
