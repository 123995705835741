import React from "react";
import { ToastContainer as BaseToastContainer } from "react-toastify";

import styles from "./ToastContainer.module.scss";

const ToastContainer = (props) => (
  <BaseToastContainer toastClassName={styles.toast} bodyClassName={styles.body} {...props} />
);

export default ToastContainer;
