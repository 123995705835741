import { MODULE } from "./globalSearch.actionTypes";

export const isGlobalSearchModalVisible = (namespace, state) =>
  state.getIn([`${namespace}/${MODULE}`, "globalSearchIsVisible"]);

export const getGlobaSearchSearchingStatus = (namespace, state) =>
  state.getIn([`${namespace}/${MODULE}`, "globaSearchSearchingStatus"]);

export const getGlobalSearchType = (namespace, state) =>
  state.getIn([`${namespace}/${MODULE}`, "globalSearchType"]);

export const getGlobalSearchOptionTypes = (namespace, state) =>
  state.getIn([`${namespace}/${MODULE}`, "globalSearchOptionTypes"]);

export const getGlobalSearchPopoverOpen = (namespace, state) =>
  state.getIn([`${namespace}/${MODULE}`, "globalSearchPopoverOpen"]);

export const getGlobalSearchOptionsOpen = (namespace, state) =>
  state.getIn([`${namespace}/${MODULE}`, "globalSearchOptionsOpen"]);

export const getGlobalSearchKeyword = (namespace, state) =>
  state.getIn([`${namespace}/${MODULE}`, "globalSearchKeyword"]);

export const getGlobalSearchIsShowAllClicked = (namespace, state) =>
  state.getIn([`${namespace}/${MODULE}`, "globalSearchIsShowAllClicked"]);

// Search type ALL
export const getFetchGlobalSearchAllStatus = (namespace, state) =>
  state.getIn([`${namespace}/${MODULE}`, "globalSearchAllStatus"]);

export const getFetchGlobalSearchAllResults = (namespace, state) =>
  state.getIn([`${namespace}/${MODULE}`, "globalSearchAllResults"]);

export const getFetchGlobalSearchAll = (namespace, state) =>
  state.getIn([`${namespace}/${MODULE}`, "globalSearchAll"]);

// Search type CUSTOMERS
export const getFetchGlobalSearchCustomersStatus = (namespace, state) =>
  state.getIn([`${namespace}/${MODULE}`, "globalSearchCustomersStatus"]);

export const getFetchGlobalSearchCustomersResults = (namespace, state) =>
  state.getIn([`${namespace}/${MODULE}`, "globalSearchCustomersResults"]);

export const getFetchGlobalSearchCustomers = (namespace, state) =>
  state.getIn([`${namespace}/${MODULE}`, "globalSearchCustomers"]);

// Search type SUPPLIERS
export const getFetchGlobalSearchSuppliersStatus = (namespace, state) =>
  state.getIn([`${namespace}/${MODULE}`, "globalSearchSuppliersStatus"]);

export const getFetchGlobalSearchSuppliersResults = (namespace, state) =>
  state.getIn([`${namespace}/${MODULE}`, "globalSearchSuppliersResults"]);

export const getFetchGlobalSearchSuppliers = (namespace, state) =>
  state.getIn([`${namespace}/${MODULE}`, "globalSearchSuppliers"]);

// Search type SALES
export const getFetchGlobalSearchSalesStatus = (namespace, state) =>
  state.getIn([`${namespace}/${MODULE}`, "globalSearchSalesStatus"]);

export const getFetchGlobalSearchSalesResults = (namespace, state) =>
  state.getIn([`${namespace}/${MODULE}`, "globalSearchSalesResults"]);

export const getFetchGlobalSearchSales = (namespace, state) =>
  state.getIn([`${namespace}/${MODULE}`, "globalSearchSales"]);

// Search type PURCHASES
export const getFetchGlobalSearchPurchasesStatus = (namespace, state) =>
  state.getIn([`${namespace}/${MODULE}`, "globalSearchPurchasesStatus"]);

export const getFetchGlobalSearchPurchasesResults = (namespace, state) =>
  state.getIn([`${namespace}/${MODULE}`, "globalSearchPurchasesResults"]);

export const getFetchGlobalSearchPurchases = (namespace, state) =>
  state.getIn([`${namespace}/${MODULE}`, "globalSearchPurchases"]);

// Search type PRODUCTS
export const getFetchGlobalSearchProductsStatus = (namespace, state) =>
  state.getIn([`${namespace}/${MODULE}`, "globalSearchProductsStatus"]);

export const getFetchGlobalSearchProductsResults = (namespace, state) =>
  state.getIn([`${namespace}/${MODULE}`, "globalSearchProductsResults"]);

export const getFetchGlobalSearchProducts = (namespace, state) =>
  state.getIn([`${namespace}/${MODULE}`, "globalSearchProducts"]);
