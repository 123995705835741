import React from "react";

import { Checkbox as BaseCheckbox } from "@/ui-lib/FormFields/Checkbox";

import styles from "./Checkbox.module.scss";

const Checkbox = ({
  label,
  checked,
  errors,
  error,
  setFieldValue,
  setFieldTouched,
  id,
  ...props
}) => (
  <div className={styles.wrapper}>
    <BaseCheckbox
      label={label}
      checked={checked}
      {...props}
      className={errors && errors[id] ? styles.inputError : ""}
      onChange={(e) => {
        setFieldValue(id, e.target.checked);
        setFieldTouched(id, true);
      }}
    />
    {errors && errors[id] && <div className={styles.errorMessage}>{errors[id]}</div>}
  </div>
);

export default Checkbox;
