import qs from "query-string";
import moment from "moment-timezone";

import * as date from "@/utils/date";
import * as customerSelectors from "@/redux/customers/customers.selectors";

import { handleError } from "../../utils/error";
import * as api from "../../../apiv2/planner/orders";
import * as searchFilterSelectors from "../searchFilter/searchFilter.selectors";
import * as userFilterSelectors from "../userFilter/userFilter.selectors";
import * as labelFilterSelectors from "../labelFilter/labelFilter.selectors";
import * as showHideOrderFilterSelectors from "../showHideOrderFilter/showHideOrderFilter.selectors";
import * as selectors from "./orders.selectors";
import {
  SET_IS_FETCHING_ORDERS,
  SET_ORDERS,
  SET_OFFSET,
  SET_TOTAL,
  RESET,
} from "./orders.actionTypes";

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setIsFetchingOrders = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_ORDERS,
    payload: status,
  });
};

export const setOrders = (data) => (dispatch) => {
  dispatch({
    type: SET_ORDERS,
    payload: data,
  });
};

export const setOffset = (data) => (dispatch) => {
  dispatch({
    type: SET_OFFSET,
    payload: data,
  });
};

export const setTotal = (data) => (dispatch) => {
  dispatch({
    type: SET_TOTAL,
    payload: data,
  });
};

export const setIsAssignUserMenuVisible = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_ASSIGN_USER_MENU_VISIBLE,
    payload: status,
  });
};

export const setIsAssignLabelMenuVisible = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_ASSIGN_LABEL_MENU_VISIBLE,
    payload: status,
  });
};

export const setAssignUserDropdownCoordinates = (data) => (dispatch) => {
  dispatch({
    type: SET_ASSIGN_USER_DROPDOWN_COORDINATES,
    payload: data,
  });
};

export const setAssignLabelDropdownCoordinates = (data) => (dispatch) => {
  dispatch({
    type: SET_ASSIGN_LABEL_DROPDOWN_COORDINATES,
    payload: data,
  });
};

export const fetchOrders = () => async (dispatch, getState) => {
  const state = getState();
  const orders = selectors.getOrders(state);
  const selectedUsers = userFilterSelectors.getSelectedUsers(state);
  const selectedLabels = labelFilterSelectors.getSelectedLabels(state);
  const search = searchFilterSelectors.getSearch(state);
  const selectedShowHideOrderFilter = showHideOrderFilterSelectors.getSelectedFilter(state);
  const showHideOrderOptions = showHideOrderFilterSelectors.getOptions(state);

  const filters = {
    limit: 20,
    offset: selectors.getPagination(state).offset,
    assignees: selectedUsers.length > 0 ? selectedUsers : undefined,
    labels: selectedLabels.length > 0 ? selectedLabels : undefined,
    search: search !== "" ? search : undefined,
    is_deleted: false,
    is_scheduled:
      selectedShowHideOrderFilter.length === showHideOrderOptions.length
        ? undefined
        : selectedShowHideOrderFilter.toString(),
  };

  dispatch(setIsFetchingOrders(true));
  try {
    const { data } = await api.getOrders(filters)();
    const allOrders = [...orders, ...data.results];

    const companyWorkingHours = customerSelectors.getPlannerSettings(state, {
      key: "PLANNER_WORK_HOURS",
    });

    dispatch(
      setOrders(
        allOrders.map((d) => ({
          ...d,
          length: "1.0h",
          start: "00:00",
          end: "01:00",
        }))
      )
    );
    dispatch(setTotal(data.count));
    if (data.next) {
      const parsedNextPage = qs.parse(data.next);
      dispatch(setOffset(parsedNextPage?.offset ?? 0));
    }
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetchingOrders(false));
  }
};

export const loadMoreOrders = () => async (dispatch, getState) => {
  const state = getState();
  const { offset } = selectors.getPagination(state);

  dispatch(setOffset(parseInt(offset)));
  dispatch(fetchOrders());
};

export const removeOrderFromLists = (uid) => (dispatch, getState) => {
  const state = getState();
  const orders = selectors.getOrders(state);

  dispatch(setOrders(orders.map((o) => (o.uid === uid ? { ...o, isDeleted: true } : { ...o }))));
};
