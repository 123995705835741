import { STATUS, INTENT } from "../../utils/constants";
import {
  RESET_LOADING_STATUS,
  SET_FULFILLED_STATUS,
} from "./loading.actionTypes";

const initialState = {};

export default (state = initialState, action) => {
  // API action is dispatched - set state to pending
  if (action.type.endsWith("/pending")) {
    const actionType = action.type.replace("/pending", "");

    const pendingState = {
      status: STATUS.PENDING,
      error: "",
    };

    return { ...state, [actionType]: pendingState };
  }

  // Action intent is transient - reset status to idle
  if (
    action.type.endsWith("/fulfilled") &&
    action.intent === INTENT.TRANSIENT
  ) {
    const actionType = action.type.replace("/fulfilled", "");

    const idleState = {
      status: STATUS.IDLE,
      error: "",
    };

    return { ...state, [actionType]: idleState };
  }

  // Action intent is persistent - set status to fulfilled
  if (
    action.type.endsWith("/fulfilled") &&
    action.intent === INTENT.PERSISTENT
  ) {
    const actionType = action.type.replace("/fulfilled", "");
    const error = action.payload.data?.error ?? "";
    const fulfilledState = {
      status: STATUS.FULFILLED,
      error,
    };

    return { ...state, [actionType]: fulfilledState };
  }

  // Encountered an error in the API response - set status to rejected and store error message
  if (action.type.endsWith("/rejected")) {
    const actionType = action.type.replace("/rejected", "");
    const { data } = action.payload;

    const rejectedState = {
      status: STATUS.REJECTED,
      error: data.message, // determine how error messages are returned in the backend
    };

    return { ...state, [actionType]: rejectedState };
  }

  // Reset loading state to idle upon reset
  if (action.type === RESET_LOADING_STATUS) {
    const { actionType } = action.payload;

    const idleState = {
      status: STATUS.IDLE,
      error: "",
    };

    return { ...state, [actionType]: idleState };
  }

  // Set loading state to fulfilled upon request
  if (action.type === SET_FULFILLED_STATUS) {
    const { actionType } = action.payload;

    const fulfilledState = {
      status: STATUS.FULFILLED,
      error: "",
    };

    return { ...state, [actionType]: fulfilledState };
  }

  // Default
  return { ...state };
};
