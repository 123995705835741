import { MODULE } from "./users.actionTypes";

export const isUsersDropdownVisible = (state) => state.getIn([MODULE, "isUsersDropdownVisible"]);

export const isFetchingUsers = (state) => state.getIn([MODULE, "isFetchingUsers"]);

export const getUsers = (state) => state.getIn([MODULE, "users"]);

export const getSelectedUsers = (state) => state.getIn([MODULE, "selectedUsers"]);

export const getUnSelectedUsers = (state) => state.getIn([MODULE, "unSelectedUsers"]);

export const getActiveUser = (state) => state.getIn([MODULE, "activeUser"]);
