import createActionType from "../utils/createActionType";

export const MODULE = "order/checklist-templates";

export const SET_IS_TEMPLATE_MODAL_VISIBLE = createActionType(MODULE, "setIsTemplateModalVisible");

export const SET_IS_FETCHING_TEMPLATES = createActionType(MODULE, "setIsFetchingTemplates");

export const SET_IS_OVERRIDING_TEMPLATE = createActionType(MODULE, "setIsOverridingTemplate");

export const SET_TEMPLATES = createActionType(MODULE, "setTemplates");

export const SET_TEMPLATES_FOR_SEARCH = createActionType(MODULE, "setTemplatesForSearch");

export const SET_EXPANDED_TEMPLATE_UIDS = createActionType(MODULE, "setExpandedTemplateUids");

export const SET_TEMPLATE_TO_BE_ADDED_IN_ORDER = createActionType(
  MODULE,
  "setTemplateToBeAddedInOrder"
);

export const SET_IS_TEMPLATE_OVERRIDE_MODAL_VISIBLE = createActionType(
  MODULE,
  "setIsTemplateOverrideModalVisible"
);

export const SET_IS_SAVING_TEMPLATE = createActionType(MODULE, "setIsSavingTemplate");

export const SET_TEMPLATE_TO_OVERRIDE = createActionType(MODULE, "setTemplateToOverride");

export const SET_ACTIVE_TEMPLATE = createActionType(MODULE, "setActiveTemplate");

export const RESET = createActionType(MODULE, "reset");
