import qs from "query-string";

import { createApi, createApiV2, createApiPdf } from "./common";

export const searchPurchaseOrders =
  ({ params } = {}) =>
  (token) =>
    createApi("GET", `purchasing/purchase_orders/?${qs.stringify(params)}`)(token);

export const getPurchaseOrder = (purchaseOrderUid) => (token) =>
  createApi("GET", `purchasing/purchase_orders/${purchaseOrderUid}/`)(token);

export const updatePurchaseOrder = (purchaseOrderUid, data) => (token) =>
  createApi("PATCH", `purchasing/purchase_orders/${purchaseOrderUid}/`)(token, data);

export const patchUpdateProductsIndex = (purchaseOrderUid, data) => (token) =>
  createApi("PATCH", `purchasing/purchase_orders/${purchaseOrderUid}/update_item_indexes/`)(
    token,
    data
  );

export const createPurchaseOrder = (data) => (token) =>
  createApi("POST", "purchasing/purchase_orders/")(token, data);

export const deletePurchaseOrder = (purchaseOrderUid) => (token) =>
  createApi("DELETE", `purchasing/purchase_orders/${purchaseOrderUid}/`)(token);

export const bulkDeletePurchaseOrders = (purchaseOrderUids) => (token) =>
  createApi(
    "DELETE",
    `purchasing/purchase_orders/bulk_destroy/?${qs.stringify({ uids: purchaseOrderUids })}`
  )(token);

export const bulkRestorePurchaseOrders = (purchaseOrderUids) => (token) =>
  createApi("POST", "purchasing/purchase_orders/bulk_undestroy/")(token, {
    uids: purchaseOrderUids,
  });

export const getExternalPurchaseOrder = (externalToken) => (token) =>
  createApi("GET", `purchasing/purchase_orders/${externalToken}/external/`)(token);

export const getLinkedOrdersFromPurchaseOrder =
  ({ customerUid, orderUid }) =>
  (token) =>
    createApi("GET", `customers/${customerUid}/orders/${orderUid}/linked_orders/`)(token);

export const updatedCompletedItems = (purchaseOrderUid, data) => (token) =>
  createApiV2("PATCH", `purchasing/purchase_orders/${purchaseOrderUid}/update_received_items/`)(
    token,
    data
  );

export const postDuplicateOrder = (purchaseOrderUid) => (token) =>
  createApiV2("POST", `purchasing/purchase_orders/${purchaseOrderUid}/duplicate/`)(token);

export const postChangeSupplier =
  ({ purchaseOrderUid, newSupplierUid }) =>
  (token) =>
    createApiV2("POST", `purchasing/purchase_orders/${purchaseOrderUid}/change_supplier/`)(token, {
      uid: newSupplierUid,
    });

// ATTACHMENTS
export const getPurchaseOrderAttachments =
  ({ params } = {}) =>
  (token) =>
    createApiV2("GET", `purchasing/chat_attachments/?${qs.stringify(params)}`)(token);

const headers = {
  "Content-Type": "multipart/form-data",
};

export const postPrintPurchaseOrderPdf =
  ({ purchaseOrderUid, data }) =>
  (token) =>
    createApiPdf("POST", `purchasing/purchase_orders/${purchaseOrderUid}/pdf/`, {
      headers,
    })(token, data);

export const emailPurchaseOrder =
  ({ purchaseOrderUid, data }) =>
  (token) =>
    createApiPdf("POST", `purchasing/purchase_orders/${purchaseOrderUid}/email_pdf/`, {
      headers,
    })(token, data);
