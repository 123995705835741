import React from "react";
import cx from "classnames";

import styles from "./TextArea.module.scss";

const TextArea = ({ className, inputClassName, ...rest }) => (
  <textarea className={cx(styles.textArea, inputClassName)} {...rest} />
);

export default TextArea;
