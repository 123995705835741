import qs from "query-string";

import { createApiV2 } from "./common";

export const postSignup = (data) => (token) => createApiV2("POST", "sign_up/")(token, data);

export const postSignupVerification = (data) => (token) =>
  createApiV2("POST", "sign_up/verification/")(token, data);

export const postCreateCompany = (data) => (token) =>
  createApiV2("POST", "companies/")(token, data);

export const postValidateEmail = (data) => (token) =>
  createApiV2("POST", "sign_up/email/")(token, data);

export const postCompanyTemplate = (data) => (token) =>
  createApiV2("POST", "company/templates/")(token, data);

export const postResendCode = (data) => (token) =>
  createApiV2("POST", "sign_up/resend_code/")(token, data);

export const postGoogleToken = (data) => (token) =>
  createApiV2("POST", "google/token/")(token, data);

export const postGoogleSignup = (data) => (token) =>
  createApiV2("POST", "google/signup/")(token, data);

export const postMicrosoftSignup = (data) => (token) =>
  createApiV2("POST", "microsoft/signup/")(token, data);

export const getXeroAuthorizationUrl = (data) => () =>
  createApiV2("GET", `xero/authorization_url/?${qs.stringify(data)}`)();

export const getXeroToken = (data) => () =>
  createApiV2("GET", `xero/token/?${qs.stringify(data)}`)();

export const getXeroCompany = () => () => createApiV2("GET", "xero/company/")();

export const postXeroSignup = (data) => (token) => createApiV2("POST", "xero/signup/")(token, data);

export const getXeroAccounts = () => () => createApiV2("GET", "xero/accounts/")();

export const postConnectToXero = createApiV2("POST", "xero/connect/");

export const getCompany = () => () => createApiV2("GET", "company/")();

export const postCompany = (data) => (token) =>
  createApiV2("POST", "company/", {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })(token, data);

export const getAllAccountingData = () => (token) => createApiV2("GET", "update_all_data/")(token);

export const getInviteLinkDetails = (code) => () =>
  createApiV2("GET", `companies/invite_link/?invite_code=${code}`)();

export const postSignupViaInvite = (uid, data) => (token) =>
  createApiV2("POST", `companies/${uid}/signup/`)(token, data);

export const postInviteSignupVerification = (uid, data) => (token) =>
  createApiV2("POST", `companies/${uid}/signup/verification/`)(token, data);
