import moment from "moment-timezone";
import { nanoid } from "nanoid";

import * as date from "@/utils/date";

import { handleError } from "../../utils/error";
import * as customersApi from "../../../apiv2/customers";
import * as labelsApi from "../../../apiv2/labels";
import * as calendarApi from "../../../apiv2/planner/calendar";
import * as ordersApi from "../../../apiv2/planner/orders";
import * as orderAssigneesApi from "../../../apiv2/order-assignees";
import * as alertActions from "../../alerts/alerts.actions";
import * as plannerActions from "../planner.actions";
import * as plannerSelectors from "../planner.selectors";
import * as orderActions from "../orders/orders.actions";
import * as orderSelectors from "../orders/orders.selectors";
import * as searchFilterSelectors from "../searchFilter/searchFilter.selectors";
import * as userFilterSelectors from "../userFilter/userFilter.selectors";
import * as labelFilterSelectors from "../labelFilter/labelFilter.selectors";

import {
  SET_IS_FETCHING_USERS,
  SET_USERS,
  SET_SELECTED_USERS,
  SET_IS_FETCHING_LABELS,
  SET_LABELS,
  SET_ORDER_IN_CALENDAR,
  SET_IS_FETCHING_SCHEDULES,
  SET_FROM_DATE,
  SET_TO_DATE,
  SET_ACTIVE_SCHEDULE,
  SET_IS_CREATING_SCHEDULE,
  SET_CALENDAR_VIEW,
  SET_SHOW_CUSTOM_EVENT_MODAL,
  SET_SHOW_DELETE_CUSTOM_EVENT_WARNING_MODAL,
  SET_SHOW_EDIT_CUSTOM_EVENT_WARNING_MODAL,
  SET_DATA_IN_CALENDAR_ENTRY,
  SET_DELETE_SCOPE,
  SET_EDIT_SCOPE,
  SET_INTITIAL_CUSTOM_EVENT_DATA,
  RESET,
} from "./calendar.actionTypes";
import * as selectors from "./calendar.selectors";

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setIsFetchingUsers = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_USERS,
    payload: status,
  });
};

export const setUsers = (data) => (dispatch) => {
  dispatch({
    type: SET_USERS,
    payload: data,
  });
};

export const setIsFetchingLabels = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_LABELS,
    payload: status,
  });
};

export const setLabels = (data) => (dispatch) => {
  dispatch({
    type: SET_LABELS,
    payload: data,
  });
};

export const setIsFetchingSchedules = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_SCHEDULES,
    payload: status,
  });
};

export const setOrderInCalendar = (data) => (dispatch) => {
  dispatch({
    type: SET_ORDER_IN_CALENDAR,
    payload: data,
  });
};

export const setFromDate = (data) => (dispatch) => {
  dispatch({
    type: SET_FROM_DATE,
    payload: data,
  });
};

export const setToDate = (data) => (dispatch) => {
  dispatch({
    type: SET_TO_DATE,
    payload: data,
  });
};

export const setActiveSchedule = (data) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_SCHEDULE,
    payload: data,
  });
};

export const setIsCreatingSchedule = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_CREATING_SCHEDULE,
    payload: data,
  });
};

export const setCalendarView = (data) => (dispatch) => {
  dispatch({
    type: SET_CALENDAR_VIEW,
    payload: data,
  });
};

export const setSelectedUsers = (data) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_USERS,
    payload: data,
  });
};

export const setDataInCalendarEntry = (data) => (dispatch) => {
  dispatch({
    type: SET_DATA_IN_CALENDAR_ENTRY,
    payload: data,
  });
};

export const fetchUsers = () => async (dispatch) => {
  try {
    dispatch(setIsFetchingUsers(true));
    const response = await customersApi.getUsers({
      include_invites: false,
      with_inactive: false,
    })();

    dispatch(
      setUsers(
        response.data.map((d) => ({
          id: d.id,
          firstName: d.firstName,
          lastName: d.lastName,
          color: d.color,
        }))
      )
    );

    dispatch(setSelectedUsers(response.data.map((u) => u.id)));
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetchingUsers(false));
  }
};

export const fetchLabels = () => async (dispatch) => {
  try {
    dispatch(setIsFetchingLabels(true));
    const response = await labelsApi.getLabels()();
    dispatch(setLabels(response.data));
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetchingLabels(false));
  }
};

export const fetchSchedules = () => async (dispatch, getState) => {
  const state = getState();
  const selectedUsers = userFilterSelectors.getSelectedUsers(state);
  const selectedLabels = labelFilterSelectors.getSelectedLabels(state);
  const search = searchFilterSelectors.getSearch(state);

  const filters = {};

  if (selectedLabels.length > 0) {
    filters.labels = selectedLabels;
  }

  if (selectedUsers.length > 0) {
    filters.assignees = selectedUsers;
  }

  if (search !== "") {
    filters.search = search;
  }

  const fromDate = moment(selectors.getFromDate(state)).format("YYYY-MM-DD");
  const toDate = moment(selectors.getToDate(state)).format("YYYY-MM-DD");

  try {
    dispatch(setIsFetchingSchedules(true));
    const { data } = await calendarApi.getAssignSchedule({
      from_date: moment
        .tz(`${fromDate}T00:00:00`, date.getCurrentCompanyTimezone())
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss[Z]"),
      to_date: moment
        .tz(`${toDate}T23:59:59`, date.getCurrentCompanyTimezone())
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss[Z]"),
      ...filters,
    })();

    dispatch(setOrderInCalendar(data));
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetchingSchedules(false));
  }
};

export const assignUser = (data) => async (dispatch, getState) => {
  const state = getState();

  try {
    await orderAssigneesApi.postAssignUser({
      users_assigned: [...data.order.assignees.map((a) => a.id), data.user],
      users_not_assigned: [],
      order: data.order.uid,
    })();

    const schedules = selectors.getSchedules(state);
    const users = selectors.getUsers(state);
    const newAssignee = users.find((u) => u.id === data.user);

    dispatch(
      setOrderInCalendar(
        schedules.map((s) =>
          s.uid === data.uid && s.order.uid === data.order.uid
            ? {
                ...s,
                user: data.user,
                order: {
                  ...s.order,
                  assignees: [...s.order.assignees, newAssignee],
                },
              }
            : { ...s }
        )
      )
    );

    dispatch(
      plannerActions.updateOrderCardAssignees({
        order: {
          uid: data.order.uid,
          assignees: [...data.order.assignees, newAssignee],
        },
      })
    );
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const dropOrderInCalendar = (data) => async (dispatch, getState) => {
  try {
    dispatch(setIsCreatingSchedule(true));
    const state = getState();
    const calendarView = selectors.getCalendarView(state);
    const activeView = plannerSelectors.getActiveView(state);

    const schedules = selectors.getSchedules(state);
    const payload = {
      order: data.uid,
      user: data.resource,
      label:
        activeView === "label" && ["day", "week"].includes(calendarView)
          ? data.resource
          : undefined,
    };

    payload.start_time = date.toUtcDateTime({
      date: data.start,
      timezone: date.getCurrentCompanyTimezone(),
    });

    payload.end_time = date.toUtcDateTime({
      date: data.end,
      timezone: date.getCurrentCompanyTimezone(),
    });

    const response = await calendarApi.postAssignSchedule(payload)();
    dispatch(setOrderInCalendar([...schedules, response.data]));
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsCreatingSchedule(false));
  }
};

export const updateOrderInCalendar =
  ({ uid, data }) =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const schedules = selectors.getSchedules(state);

      const payload = {
        uid: data.uid,
        user: data.user,
      };

      payload.start_time = date.toUtcDateTime({
        date: data.start,
        timezone: date.getCurrentCompanyTimezone(),
      });

      payload.end_time = date.toUtcDateTime({
        date: data.end,
        timezone: date.getCurrentCompanyTimezone(),
      });

      await calendarApi.patchAssignSchedule({ uid, data: payload })();

      dispatch(
        setOrderInCalendar(
          schedules.map((s) =>
            s.uid === uid
              ? { ...s, user: data.user, startTime: payload.start_time, endTime: payload.end_time }
              : { ...s }
          )
        )
      );
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const setDateToPreviousDay = () => async (dispatch, getState) => {
  const state = getState();
  const currentDate = selectors.getFromDate(state);

  await dispatch(setFromDate(new Date(currentDate.getTime() - 24 * 60 * 60 * 1000)));
  await dispatch(setToDate(new Date(currentDate.getTime() - 24 * 60 * 60 * 1000)));
};

export const setDateToNextDay = () => async (dispatch, getState) => {
  const state = getState();
  const currentDate = selectors.getFromDate(state);

  await dispatch(setFromDate(new Date(currentDate.getTime() + 24 * 60 * 60 * 1000)));
  await dispatch(setToDate(new Date(currentDate.getTime() + 24 * 60 * 60 * 1000)));
};

export const removeTimeSlot = (uid) => async (dispatch, getState) => {
  try {
    const state = getState();
    const orders = orderSelectors.getOrders(state);
    const schedules = selectors.getSchedules(state);
    await calendarApi.deleteAssignSchedule(uid)();

    dispatch(setOrderInCalendar(schedules.filter((s) => s.uid !== uid)));

    const selectedSchedule = schedules.find((s) => s.uid === uid);

    if (selectedSchedule) {
      const selectedOrder = orders.find((o) => o.uid === selectedSchedule.order.uid);

      if (selectedOrder) {
        dispatch(
          orderActions.setOrders(
            orders.map((o) => (o.uid === selectedOrder.uid ? { ...o, isDeleted: false } : { ...o }))
          )
        );
      } else {
        dispatch(
          orderActions.setOrders([...orders, { ...selectedSchedule.order, isDeleted: false }])
        );
      }
    }

    dispatch(alertActions.createAlertAction(nanoid(), "Timeslot deleted", true, "danger"));
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const markTimeSlotStatus =
  ({ uid, isCompleted }) =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const schedules = selectors.getSchedules(state);

      await calendarApi.patchAssignSchedule({
        uid,
        data: { is_completed: isCompleted },
      })();

      dispatch(
        setOrderInCalendar(schedules.map((s) => (s.uid === uid ? { ...s, isCompleted } : { ...s })))
      );
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const createAdditionalTimeSlot = (uid) => async (dispatch, getState) => {
  try {
    const state = getState();
    const schedule = selectors.getSchedules(state).find((s) => s.uid === uid);

    if (schedule) {
      const schedules = selectors.getSchedules(state);
      const payload = {
        order: schedule?.order?.uid ?? null,
        user: schedule.user,
      };

      payload.start_time = schedule.startTime;
      payload.end_time = schedule.endTime;

      const response = await calendarApi.postAssignSchedule(payload)();
      dispatch(setOrderInCalendar([...schedules, response.data]));
    }
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const getOrder =
  ({ orderUid, customerUid }) =>
  () =>
    ordersApi.getOrder({ orderUid, customerUid })();

export const setSelectedResourcesForDisplay = (id) => (dispatch, getState) => {
  const state = getState();

  const selectedResources = selectors.getSelectedResources(state);
  const allUsers = selectors.getUsers(state);

  if (id === "" && selectedResources.length === allUsers.length) {
    return dispatch(setSelectedUsers([]));
  }

  if (id === "" && (selectedResources.length === 0 || selectedResources.length < allUsers.length)) {
    return dispatch(setSelectedUsers(allUsers.map((r) => r.id)));
  }

  if (id === "" && selectedResources.length === 0) {
    return dispatch(setSelectedUsers(allUsers.map((r) => r.id)));
  }

  if (selectedResources.includes(id)) {
    return dispatch(setSelectedUsers(selectedResources.filter((userId) => userId !== id)));
  }

  return dispatch(setSelectedUsers([...selectedResources, id]));
};

export const selectAllResources = () => (dispatch, getState) => {
  const state = getState();
  const users = selectors.getUsers(state);

  dispatch(setSelectedUsers(users.map((r) => parseInt(r.id))));
};

export const setShowCustomEventModal = (payload) => (dispatch) => {
  dispatch({
    type: SET_SHOW_CUSTOM_EVENT_MODAL,
    payload,
  });
};

export const setInitialCustomEventData = (payload) => (dispatch) => {
  dispatch({
    type: SET_INTITIAL_CUSTOM_EVENT_DATA,
    payload,
  });
};

export const setShowDeleteCustomEventWarningModal = (payload) => (dispatch) => {
  dispatch({
    type: SET_SHOW_DELETE_CUSTOM_EVENT_WARNING_MODAL,
    payload,
  });
};

export const setShowEditCustomEventWarningModal = (payload) => (dispatch) => {
  dispatch({
    type: SET_SHOW_EDIT_CUSTOM_EVENT_WARNING_MODAL,
    payload,
  });
};

export const setDeleteScope = (payload) => (dispatch) => {
  dispatch({
    type: SET_DELETE_SCOPE,
    payload,
  });
};

export const setEditScope = (payload) => (dispatch) => {
  dispatch({
    type: SET_EDIT_SCOPE,
    payload,
  });
};
