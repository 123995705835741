import { MODULE } from "./orderAddLabourLineItem.actionTypes";

export const isModalVisible = (state) => state.getIn([MODULE, "isModalVisible"]);

export const getTeamMembers = (state) => state.getIn([MODULE, "teamMembers"]);

export const isFetching = (state) => state.getIn([MODULE, "isFetching"]);

export const getTargetProductType = (state) => state.getIn([MODULE, "targetProductType"]);

export const getTargetProductKit = (state) => state.getIn([MODULE, "targetProductKit"]);

export const getActiveLineItem = (state) => state.getIn([MODULE, "activeLineItem"]);

export default {};
