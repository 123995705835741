import { STATUS } from "@/utils/constants";

import {
  getLatestCustomers,
  postImportCustomers,
  postImportAllCustomers,
} from "../../apiv2/importCustomers";
import { handleError } from "../utils/error";

import {
  SET_LATEST_CUSTOMERS,
  SET_SEARCH_LATEST_CUSTOMERS,
  SET_IS_LINKED_CUSTOMERS,
  SET_IS_PREVIOUSLY_IMPORTED_CUSTOMERS,
  SET_FETCHING_LATEST_CUSTOMERS_STATUS,
  SET_CUSTOMERS_TO_IMPORT,
  SET_IMPORT_CUSTOMERS_STATUS,
  SET_IMPORT_CUSTOMERS,
  SET_DUPLICATE_CUSTOMERS_TO_IMPORT,
  SET_DUPLICATE_CUSTOMERS_WARNING_MODAL,
  RESET,
  SET_IMPORT_CUSTOMERS_SORT,
  SET_IMPORT_CUSTOMERS_PAGE,
  SET_IMPORT_CUSTOMERS_META,
  SET_IS_LINKED_CUSTOMERS_FILTER,
  SET_IMPORT_ALL_CUSTOMERS_STATUS,
  SET_IMPORT_ALL_CUSTOMERS,
} from "./importCustomers.actionTypes";
import * as selectors from "./importCustomers.selectors";

export const setLatestCustomers = (payload) => (dispatch) => {
  dispatch({
    type: SET_LATEST_CUSTOMERS,
    payload,
  });
};

export const setImportCustomersSort = (payload) => (dispatch) => {
  dispatch({
    type: SET_IMPORT_CUSTOMERS_SORT,
    payload,
  });
};

export const setImportCustomersPage = (payload) => (dispatch) => {
  dispatch({
    type: SET_IMPORT_CUSTOMERS_PAGE,
    payload,
  });
};

export const setImportCustomersMeta = (payload) => (dispatch) => {
  dispatch({
    type: SET_IMPORT_CUSTOMERS_META,
    payload,
  });
};

export const setIsLinkedCustomersFilter = (payload) => (dispatch) => {
  dispatch({
    type: SET_IS_LINKED_CUSTOMERS_FILTER,
    payload,
  });
};

export const setSearchLatestCustomers = (payload) => (dispatch) => {
  dispatch({
    type: SET_SEARCH_LATEST_CUSTOMERS,
    payload,
  });
};

export const setIsLinkedCustomers = (payload) => (dispatch) => {
  dispatch({
    type: SET_IS_LINKED_CUSTOMERS,
    payload,
  });
};

export const setIsPreviouslyImportedCustomers = (payload) => (dispatch) => {
  dispatch({
    type: SET_IS_PREVIOUSLY_IMPORTED_CUSTOMERS,
    payload,
  });
};

export const setFetchingLatestCustomersStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_FETCHING_LATEST_CUSTOMERS_STATUS,
    payload,
  });
};

export const fetchLatestCustomers = (params) => async (dispatch) => {
  try {
    dispatch(setFetchingLatestCustomersStatus(STATUS.PENDING));
    const response = await getLatestCustomers(params)();
    dispatch(setImportCustomersMeta(response.data.meta));
    dispatch(setLatestCustomers(response.data.results));
    dispatch(setFetchingLatestCustomersStatus(STATUS.FULFILLED));
  } catch (error) {
    handleError(error, dispatch);
    dispatch(setFetchingLatestCustomersStatus(STATUS.REJECTED));
  }
};

export const setDuplicateCustomersToImport = (payload) => (dispatch) => {
  dispatch({
    type: SET_DUPLICATE_CUSTOMERS_TO_IMPORT,
    payload,
  });
};

export const setDuplicateCustomersWarningModal = (payload) => (dispatch) => {
  dispatch({
    type: SET_DUPLICATE_CUSTOMERS_WARNING_MODAL,
    payload,
  });
};

export const setCustomersToImport = (payload) => (dispatch) => {
  dispatch(setDuplicateCustomersToImport(payload.filter((row) => row.is_linked)));

  dispatch({
    type: SET_CUSTOMERS_TO_IMPORT,
    payload,
  });
};

export const setImportCustomersStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_IMPORT_CUSTOMERS_STATUS,
    payload,
  });
};

export const setImportCustomers = (payload) => (dispatch) => {
  dispatch({
    type: SET_IMPORT_CUSTOMERS,
    payload,
  });
};

export const importCustomers = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const customersToImport = selectors.getCustomersToImport(state);

    dispatch(setImportCustomersStatus(STATUS.PENDING));
    const response = await postImportCustomers(customersToImport)();
    dispatch(setImportCustomers(response.data));
    dispatch(setImportCustomersStatus(STATUS.FULFILLED));
  } catch (error) {
    handleError(error, dispatch);
    dispatch(setImportCustomersStatus(STATUS.REJECTED));
  }
};

export const importCustomersExcludeDuplicates = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const customersToImport = selectors.getCustomersToImport(state);
    const excludedDuplicateCustomers = customersToImport.filter((row) => !row.is_linked);

    dispatch(setImportCustomersStatus(STATUS.PENDING));
    const response = await postImportCustomers(excludedDuplicateCustomers)();
    dispatch(setImportCustomers(response.data));
    dispatch(setImportCustomersStatus(STATUS.FULFILLED));
  } catch (error) {
    handleError(error, dispatch);
    dispatch(setImportCustomersStatus(STATUS.REJECTED));
  }
};

/* IMPORT ALL */
export const setImportAllCustomersStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_IMPORT_ALL_CUSTOMERS_STATUS,
    payload,
  });
};

export const setImportAllCustomers = (payload) => (dispatch) => {
  dispatch({
    type: SET_IMPORT_ALL_CUSTOMERS,
    payload,
  });
};

export const importAllCustomers = () => async (dispatch) => {
  try {
    dispatch(setImportAllCustomersStatus(STATUS.PENDING));
    const response = await postImportAllCustomers()();
    dispatch(setImportAllCustomers(response.data));
    dispatch(setImportAllCustomersStatus(STATUS.FULFILLED));
  } catch (error) {
    handleError(error, dispatch);
    dispatch(setImportAllCustomersStatus(STATUS.REJECTED));
  }
};

export const reset = () => (dispatch) => {
  dispatch({ type: RESET });
};
