import { createApi } from "./common";

export const getAllOrdersStatuses = createApi("GET", "status/");

export const updateStatusColumn = createApi("POST", "status/");

export const getWorkflowOptions = createApi("GET", "workflow/");

export const updateWorkflowOptions = createApi("POST", "workflow/");

export const moveWorkflowColumn = createApi("POST", "workflow/move_column/");

export const moveWorkflowOrder = createApi("POST", "workflow/move_order/");

export const changeWorkflowOrder = createApi("POST", "workflow/change_order/");

export const searchColumnsOrders = (token, { statusUid, search }) =>
  createApi("GET", `workflow/orders/${statusUid}/`)(token, { ...search });

export const searchOrders = createApi("GET", "workflow/orders/");

export default searchOrders;
