import {
  faIndustry,
  faBadgeCheck,
  faBadgeDollar,
  faDollyFlatbedAlt,
  faLink,
  faUser,
  faBoxOpen,
  faComments,
  faParachuteBox,
  faList,
  faFileUpload,
  faCheckCircle,
  faFilePdf,
  faClock,
  faCreditCard,
} from "@fortawesome/pro-duotone-svg-icons";

export const STATUS = {
  IDLE: "idle",
  PENDING: "pending",
  FULFILLED: "fulfilled",
  REJECTED: "rejected",
};

export const WEBSOCKET_STATUS = {
  IDLE: "idle",
  CONNECTED: "connected",
  CLOSED: "closed",
};

export const INTENT = {
  TRANSIENT: "transient",
  PERSISTENT: "persistent",
};

export const MENU_SETTINGS = [
  { title: "Company", icon: faIndustry, path: "/settings?tab=company" },
  { title: "Team", icon: faUser, path: "/settings?tab=team" },
  { title: "Billing", icon: faCreditCard, path: "/settings?tab=billing" },
  { title: "Integrations", icon: faLink, path: "/settings?tab=integrations" },
  { title: "Quotes", icon: faBoxOpen, path: "/settings?tab=quotes" },
  {
    title: "Sales",
    icon: faDollyFlatbedAlt,
    path: "/settings?tab=sales",
  },
  {
    title: "Order Confirmations",
    icon: faBadgeCheck,
    path: "/settings?tab=order_confirmations",
  },
  { title: "Invoicing", icon: faBadgeDollar, path: "/settings?tab=invoicing" },
  {
    title: "Purchasing",
    icon: faParachuteBox,
    path: "/settings?tab=purchasing",
  },
  { title: "Time Tracking", icon: faClock, path: "/settings?tab=time_tracking" },
  { title: "PDFs", icon: faFilePdf, path: "/settings?tab=pdf" },
  { title: "Order Importer", icon: faFileUpload, path: "/settings?tab=order_importer" },
];

export const MENU_COLLABORATION = [
  {
    title: "Order details",
    icon: faDollyFlatbedAlt,
    path: "order_details",
  },
  { title: "Collaborate", icon: faComments, path: "collaborate" },
  { title: "Checklists", icon: faCheckCircle, path: "checklists" },
  { title: "Tracking", icon: faClock, path: "tracking" },
  { title: "Linked Orders", icon: faLink, path: "linked_orders" },
  { title: "Timeline", icon: faList, path: "timeline" },
];

export const FORMAT_DATE = "DD[/]MM[/]YY";
export const FORMAT_DATE_MIDDLE = `hh[:]mma ${FORMAT_DATE}`;
export const FORMAT_DATE_FULL = "hh[:]mma DD MMM YYYY";
export const FORMAT_DATE_FULL_CUSTOM = "hh[:]mma dddd DD[/]MM[/]YY";
export const FORMAT_DATE_SHORT = "DD MMM YYYY";
export const FORMAT_DATE_SPECIAL = "MMMM Do YYYY";
export const FORMAT_DATE_CUSTOM = "DD MMM YY";
export const FORMAT_DATE_YEAR = "YYYY-MM-DD";
export const FORMAT_DATE_HOUR_MINUTE = "h:mma";

export const TIME_ZONE = "Australia/Sydney";

export const NAVIGATE = {
  PREVIOUS: "PREV",
  NEXT: "NEXT",
  TODAY: "TODAY",
  DATE: "DATE",
};

export const COLOR_PICKER = [
  "#4169e1",
  "#20b2aa",
  "#f08080",
  "#f6ab2f",
  "#1fb6ff",
  "#13ce66",
  "#ff4949",
  "#ffc82c",
  "#ff69b4",
  "#da70d6",
  "#6a5acd",
  "#273444",
];

export const ARROW_PATH = "M-9,0 L9,-9 L9,-1.5 L55,-1.5 L55,1 L9,1 L9,9Z";

export const FLASHING_PRICE_LEVELS = (function getPriceLevels() {
  const priceLevels = ["A"];

  while (priceLevels[priceLevels.length - 1] !== "Z") {
    const letter = String.fromCharCode(priceLevels[priceLevels.length - 1].charCodeAt(0) + 1);
    priceLevels.push(letter);
  }

  return priceLevels;
})();

export const COLORS_DRAWING = {
  line: "#3b3d4d",
  hover: "#20b2aa",
  anchorStroke: "#20b2aa",
  anchorLabelText: "#4a4a4a",
  lineLabelText: "#4a4a4a",
  anchorFill: "#ffffff",
  arrow: "#3b3d4d",
};

export const SIZES_ELEMENTS_DRAWING = {
  lineLabelPadding: 30,
  lineActiveLabelPadding: 5,
  lineSize: 4,
  lineDash: 10,
  anchorRadius: 15,
  anchorLine: 2,
  anchorLabelPadding: 50,
  labelFontSize: 24,
  fontWeight: "normal",
};

export const SIZES_ELEMENTS_DRAWING_PDF = {
  ...SIZES_ELEMENTS_DRAWING,
  labelFontSize: 28,
  fontWeight: "bold",
};

export const FINISH_POINTS = {
  cf: {
    title: "Crush & Fold",
    size: 10,
    bends: 2,
  },
  oh: {
    title: "Open Hook",
    size: 25,
    bends: 2,
  },
  fe: {
    title: "Feather",
    size: 15,
    bends: 1,
  },
  de: {
    title: "Drip edge",
    size: 10,
    bends: 1,
  },
};

export const AUSTRALIA_STATES = [
  {
    name: "New South Wales",
    abbreviation: "NSW",
  },
  {
    name: "Victoria",
    abbreviation: "VIC",
  },
  {
    name: "Queensland",
    abbreviation: "QLD",
  },
  {
    name: "Tasmania",
    abbreviation: "TAS",
  },
  {
    name: "South Australia",
    abbreviation: "SA",
  },
  {
    name: "Western Australia",
    abbreviation: "WA",
  },
  {
    name: "Northern Territory",
    abbreviation: "NT",
  },
  {
    name: "Australian Capital Territory",
    abbreviation: "ACT",
  },
];

export const CANVAS_OBJECT = {
  ANCHOR: "Anchor",
  LINE: "Line",
  CANVAS: "Canvas",
  FRONT_ARROW: "Front arrow",
  SQUARE_ANGLE: "Square angle",
  TEXT_AREA: "Text area",
};

export const STATUSES_RESERVED = {
  order: {
    type: "Order",
    name: "Submitted",
    bootstrapColour: "primary",
    settings: {
      colour: "#273444",
    },
    colour: "#273444",
  },
  submitted: {
    type: "Order",
    name: "Submitted",
    bootstrapColour: "dark",
    settings: {
      colour: "#273444",
    },
    colour: "#273444",
  },
  quote: {
    type: "Quote",
    name: "Quote",
    bootstrapColour: "primary3",
    settings: {
      colour: "#6a5acd",
    },
    colour: "#6a5acd",
  },
  draft: {
    type: "Order",
    name: "Draft",
    bootstrapColour: "warning",
    settings: {
      colour: "#ffa500",
    },
    colour: "#ffa500",
  },
  archivedOrder: {
    type: "Order",
    name: "Archived",
    bootstrapColour: "danger",
    settings: {
      colour: "#f08080",
    },
    colour: "#f08080",
  },
  archivedQuote: {
    type: "Quote",
    name: "archived",
    bootstrapColour: "danger",
    settings: {
      colour: "#f08080",
    },
    colour: "#f08080",
  },
  Delivered: {
    type: "Received",
    name: "Delivered",
    bootstrapColour: "success",
    settings: {
      colour: "#20b2aa",
    },
    colour: "#20b2aa",
  },
  "Picked Up": {
    type: "Received",
    name: "Picked Up",
    bootstrapColour: "success",
    settings: {
      colour: "#20b2aa",
    },
    colour: "#20b2aa",
  },
};

export const SALES_ORDER_STATUSES_RESERVED = {
  ...STATUSES_RESERVED,
  delivered: {
    name: "Delivered",
    colour: "#20b2aa",
  },
  "picked up": {
    name: "Picked Up",
    colour: "#20b2aa",
  },
};

export const PURCHASE_ORDER_STATUSES_RESERVED = {
  archived: {
    name: "Archived",
    colour: "#f08080",
  },
  draft: {
    name: "Draft",
    colour: "#ffa500",
  },
  submitted: {
    name: "Submitted",
    colour: "#273444",
  },
  "partially received": {
    name: "Partially received",
    colour: "#1fb6ff",
  },
  received: {
    name: "Received",
    colour: "#20b2aa",
  },
};

export const attachFileType = [
  "0xe",
  "89k",
  "a6p",
  "actm",
  "ahk",
  "air",
  "apk",
  "app",
  "arscript",
  "asb",
  "awk",
  "azw2",
  "ba",
  "bat",
  "celx",
  "cgi",
  "cmd",
  "cof",
  "coffee",
  "com",
  "csh",
  "cyw",
  "dld",
  "dmc",
  "ds",
  "e_e",
  "ebm",
  "ebs",
  "ebs2",
  "eham",
  "es",
  "esh",
  "ex",
  "ex4",
  "exe",
  "exopc",
  "ezs",
  "ezt",
  "fas",
  "fky",
  "fpi",
  "frs",
  "fxp",
  "gadget",
  "gpe",
  "gpu",
  "hms",
  "hta",
  "iim",
  "inx",
  "ipa",
  "ipf",
  "isu",
  "jar",
  "jse",
  "jsx",
  "kix",
  "lo",
  "ls",
  "m3g",
  "mcr",
  "mel",
  "mem",
  "mio",
  "mpx",
  "mrc",
  "ms",
  "mst",
  "mxe",
  "otm",
  "paf",
  "phar",
  "pif",
  "plsc",
  "plx",
  "prg",
  "ps1",
  "pvd",
  "pwc",
  "pyc",
  "pyo",
  "qit",
  "rbx",
  "rgs",
  "rox",
  "rpj",
  "rxe",
  "sbs",
  "scar",
  "scb",
  "scr",
  "script",
  "shb",
  "shss",
  "spr",
  "tcp",
  "tlb",
  "tms",
  "u3p",
  "udf",
  "upx",
  "vb",
  "vbe",
  "vbs",
  "vbscript",
  "vexe",
  "vlx",
  "widget",
  "wiz",
  "wpk",
  "ws",
  "wsf",
  "xap",
  "xbap",
  "xqt",
  "xys",
  "zl9",
  "exe1",
  "msl",
  "n",
  "obs",
  "nexe",
  "ore",
  "rfu",
  "s2a",
  "a7r",
  "caction",
  "ksh",
  "paf",
  "8ck",
  "action",
  "applescript",
  "command",
  "hpf",
  "osx",
  "scpt",
  "seed",
  "workflow",
];

export const PRICING_STRATEGY_BASIC_QUANTITIES_LABEL = "Basic Quantities";
export const PRICING_STRATEGY_LINEAL_METRES_LABEL = "Lineal Metres";
export const PRICING_STRATEGY_CUSTOM_FORMULA_LABEL = "Custom Formula";
export const PRICING_STRATEGY_SQUARE_METRES_LABEL = "Square Metres";

export const PRICING_STRATEGY_BASIC_QUANTITIES = 1;
export const PRICING_STRATEGY_LINEAL_METRES = 2;
export const PRICING_STRATEGY_CUSTOM_FORMULA = 3;
export const PRICING_STRATEGY_SQUARE_METRES = 4;
export const PRICING_STRATEGY = {
  [`${PRICING_STRATEGY_BASIC_QUANTITIES}`]: PRICING_STRATEGY_BASIC_QUANTITIES_LABEL,
  [`${PRICING_STRATEGY_LINEAL_METRES}`]: PRICING_STRATEGY_LINEAL_METRES_LABEL,
  [`${PRICING_STRATEGY_CUSTOM_FORMULA}`]: PRICING_STRATEGY_CUSTOM_FORMULA_LABEL,
  [`${PRICING_STRATEGY_SQUARE_METRES}`]: PRICING_STRATEGY_SQUARE_METRES_LABEL,
};
export const PRICING_STRATEGY_STRING_TO_INT = {
  [`${PRICING_STRATEGY_BASIC_QUANTITIES_LABEL}`]: PRICING_STRATEGY_BASIC_QUANTITIES,
  [`${PRICING_STRATEGY_LINEAL_METRES_LABEL}`]: PRICING_STRATEGY_LINEAL_METRES,
  [`${PRICING_STRATEGY_CUSTOM_FORMULA_LABEL}`]: PRICING_STRATEGY_CUSTOM_FORMULA,
  [`${PRICING_STRATEGY_SQUARE_METRES_LABEL}`]: PRICING_STRATEGY_SQUARE_METRES,
};

export const PRODUCT_TYPE_PRODUCT_KIT_LABEL = "Product Kit";
export const PRODUCT_TYPE_PRODUCT_KIT_ITEM_LABEL = "Product Kit Item";
export const PRODUCT_TYPE_PRODUCT_SUB_KIT_LABEL = "Sub Kit";
export const PRODUCT_TYPE_PRODUCT_SUB_KIT_ITEM_LABEL = "Sub Kit Item";
export const PRODUCT_TYPE_ADDITIONAL_PRODUCT_LABEL = "Additional Product";
export const PRODUCT_TYPE_LABOUR_PRODUCT_LABEL = "Labour";
export const PRODUCT_TYPE_NOTES_ITEM_LABEL = "Notes";
export const PRODUCT_TYPE_ON_THE_FLY_PRODUCT_LABEL = "On-the-fly Product";
export const PRODUCT_TYPE_ADDITIONAL_PRODUCT = 1;
export const PRODUCT_TYPE_ON_THE_FLY_PRODUCT = 2;
export const PRODUCT_TYPE_NOTES_ITEM = 3;
export const PRODUCT_TYPE_LABOUR_ITEM = 4;
export const PRODUCT_TYPES = {
  [`${PRODUCT_TYPE_ADDITIONAL_PRODUCT}`]: "Additional Product",
  [`${PRODUCT_TYPE_ON_THE_FLY_PRODUCT}`]: "On-the-fly Product",
  [`${PRODUCT_TYPE_NOTES_ITEM}`]: "Notes",
  [`${PRODUCT_TYPE_LABOUR_ITEM}`]: "Labour",
};

export const INTERNAL_NOTE_TYPE = 1;
export const EXTERNAL_NOTE_TYPE = 2;
export const NOTE_TYPES = {
  [`${INTERNAL_NOTE_TYPE}`]: "Internal",
  [`${EXTERNAL_NOTE_TYPE}`]: "External",
};

export const KIT_PRODUCTS_TYPES = {
  PRODUCT_CATALOGUE: "Product catalogue",
  BASIC_PRODUCT: "Basic product",
  LINEAL_METRE: "Lineal metre product",
  SQUARE_METRE: "Square metre product",
  LABOUR: "Labour",
  NOTES_INTERNAL: "Notes (Internal)",
  NOTES_EXTERNAL: "Notes (External)",
};

export const ACCOUNTING_SETTINGS_KEYS = {
  Quote: "ACCOUNTING_SYNC_QUOTES",
  PurchaseOrder: "ACCOUNTING_SYNC_PURCHASE_ORDERS",
  Bill: "ACCOUNTING_SYNC_BILLS",
  Order: "ACCOUNTING_SYNC_SALES",
  Invoice: "ACCOUNTING_SYNC_INVOICES",
  DISPLAY_ACCOUNTING_PACKAGE_CODES: "ACCOUNTING_DISPLAY_ACCOUNTING_PACKAGE_CODES",
  ACCOUNTING_MAP_ITEMS_TO_JOBS: "ACCOUNTING_MAP_ITEMS_TO_JOBS",
};

export const CURRENT_MONTH = 1;
export const FOLLOWING_MONTH = 2;
export const AFTER_THE_INVOICE_DATE = 3;
export const AFTER_THE_INVOICE_MONTH = 4;

export const PAYMENT_TERMS_OPTIONS = [
  { label: "of the current month", value: CURRENT_MONTH },
  { label: "of the following month", value: FOLLOWING_MONTH },
  { label: "day(s) after the invoice date", value: AFTER_THE_INVOICE_DATE },
  { label: "day(s) after the invoice month", value: AFTER_THE_INVOICE_MONTH },
];

export const DISPLAY_FORMULA_LOCATION = [
  { label: "Customer View", value: "displayOnCustomerOnlineView" },
  { label: "Quote PDF", value: "displayOnQuotePdf" },
  { label: "Proforma Invoice PDF", value: "displayOnProformaInvoicePdf" },
  { label: "Invoice PDF", value: "displayOnInvoicePdf" },
  { label: "Work Order PDF", value: "displayOnWorkOrderPdf" },
  { label: "Delivery Docket PDF", value: "displayOnDeliveryDocketPdf" },
];

export const WIDGET_SIZES = {
  SMALL: { x: 0, y: 0, w: 1, h: 1 },
  MEDIUM: { x: 0, y: 0, w: 2, h: 2 },
  LARGE: { x: 0, y: 0, w: 4, h: 2 },
};

export const WIDGET_TYPE = {
  SALES_ORDERS: "sales_orders",
  ORDERS_BY_REQUIRED_DATE: "orders_by_required_date",
  ORDERS_BY_STATUS: "orders_by_status",
  ORDERS_CREATED_BY_ME: "orders_created_by_me",
  TOP_CUSTOMERS: "top_customers",
  ORDERS_BY_TEAM_MEMBER: "orders_by_team_member",
  PURCHASE_ORDERS: "purchase_orders",
  OPEN_QUOTES: "open_quotes",
  DELIVERIES_SCHEDULED: "deliveries_scheduled",
  ALL_ORDERS_WORKED_ON: "all_orders_worked_on",
  ORDERS_ACTIVELY_WORKED_ON: "orders_actively_worked_on",
  TIME_WORKED_PER_TEAM_MEMBER: "time_worked_per_team_member",
};

export const CHARTS_TYPE = {
  TABLE: "table",
  BAR: "bar",
  LINE: "line",
  HEATMAP: "heatmap",
};
export const PURCHASE_ORDER_EDIT_STATUS_DRAFT = 1;
export const PURCHASE_ORDER_EDIT_STATUS_SUBMITTED = 2;
export const PURCHASE_ORDER_DELIVERY_STATUS_NOT_RECEIVED = 3;
export const PURCHASE_ORDER_DELIVERY_STATUS_PARTIALLY_RECEIVED = 4;
export const PURCHASE_ORDER_DELIVERY_STATUS_RECEIVED = 5;

export const BILLING_TYPE = {
  MONTHLY: 1,
};

export const IMPORTER_TYPE_ON_THE_FLY_ORDER_IMPORTER = "On-the-fly Product";
export const IMPORTER_TYPE_ADDITIONAL_PRODUCT_ORDER_IMPORTER = "Additional Product";

export const IMPORTER_TYPES = [
  IMPORTER_TYPE_ON_THE_FLY_ORDER_IMPORTER,
  IMPORTER_TYPE_ADDITIONAL_PRODUCT_ORDER_IMPORTER,
];

export const IMPORTER_BASIC_TYPES = [
  "Basic product",
  "Lineal metre product",
  "Square metre product",
  "Notes (Internal)",
  "Notes (External)",
];
