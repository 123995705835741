import qs from "query-string";

import { createApiV2 } from "./common";

export const getCategories = () => (token) =>
  createApiV2("GET", "additional_products_categories/with_tracked_products/")(token);

export const getProducts = (data) => (token) =>
  createApiV2(
    "GET",
    `additional_products/inventory/?${qs.stringify(data, {
      arrayFormat: "repeat",
    })}`
  )(token);

export const postProductInventory = (data) => (token) =>
  createApiV2("POST", "additional_products/inventory/")(token, data);

export const getProductInventory = (productUid) => (token) =>
  createApiV2("GET", `additional_products/${productUid}/retrieve_inventory/`)(token);
