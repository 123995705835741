import {
  SET_SEARCH,
  SET_FILTERS,
  SET_IS_FETCHING_PO_TIMELINES,
  SET_IS_FETCHING_PO_TIMELINE_DETAILS,
  SET_PO_TIMELINES,
  SET_PO_TIMELINE_DETAILS,
  RESET,
} from "./purchaseOrderTimeline.actionTypes";

const initialState = {
  search: "",
  filters: [],
  isFetchingPurchaseOrderTimelines: false,
  isFetchingPurchaseOrderTimelineDetails: false,
  purchaseOrderTimelines: [],
  purchaseOrderTimelineDetails: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH: {
      return {
        ...state,
        search: action.payload,
      };
    }

    case SET_FILTERS: {
      return {
        ...state,
        filters: action.payload,
      };
    }

    case SET_IS_FETCHING_PO_TIMELINES: {
      return {
        ...state,
        isFetchingTimelines: action.payload,
      };
    }

    case SET_IS_FETCHING_PO_TIMELINE_DETAILS: {
      return {
        ...state,
        isFetchingTimelineDetails: action.payload,
      };
    }

    case SET_PO_TIMELINES: {
      const { data } = action.payload;

      return {
        ...state,
        purchaseOrderTimelines: data,
      };
    }

    case SET_PO_TIMELINE_DETAILS: {
      const { data } = action.payload;

      return {
        ...state,
        purchaseOrderTimelineDetails: data,
      };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
