import { MODULE } from "./accounting.actionTypes";

export const getIsFetching = (state) => state.getIn([MODULE, "isFetching"]);

export const getIsConnecting = (state) => state.getIn([MODULE, "isConnecting"]);

export const getAccounts = (state) => state.getIn([MODULE, "accounts"]);
export const getTaxCodes = (state) => state.getIn([MODULE, "taxCodes"]);
export const getItems = (state) => state.getIn([MODULE, "items"]);
export const getMyobJobs = (state) => state.getIn([MODULE, "myobJobs"]);
export const getNewAccountingDefault = (state) => state.getIn([MODULE, "newAccountingDefault"]);
export const getAccountingName = (state) => state.getIn([MODULE, "accountingName"]);
export const getIsXeroConnected = (state) => state.getIn([MODULE, "isXeroConnected"]);
export const getSelectedMyobCompanyFile = (state) =>
  state.getIn([MODULE, "selectedMyobCompanyFile"]);

export const getMyobCompanyFiles = (state) => state.getIn([MODULE, "myobCompanyFiles"]);

export const getIsMyobCompanyFileModalOpen = (state) =>
  state.getIn([MODULE, "IsMyobCompanyFileModalOpen"]);

export const getIsConnectedAccountingModalOpen = (state) =>
  state.getIn([MODULE, "isConnectedAccountingModalOpen"]);

export const getIsDisconnectAccountingModalOpen = (state) =>
  state.getIn([MODULE, "isDisconnectAccountingModalOpen"]);

export const getIsAccountsTaxCodesModalOpen = (state) =>
  state.getIn([MODULE, "isAccountsTaxCodesModalOpen"]);

export const getIsImportCustomersModalOpen = (state) =>
  state.getIn([MODULE, "isImportCustomersModalOpen"]);

export const getIsImportSuppliersModalOpen = (state) =>
  state.getIn([MODULE, "isImportSuppliersModalOpen"]);

export const getAccountingUpdateAllStatus = (state) =>
  state.getIn([MODULE, "accountingUpdateAllStatus"]);
