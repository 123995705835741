import { nanoid } from "nanoid";
import { omit, orderBy } from "lodash";

import { handleError } from "../../utils/error";
import * as api from "../../../apiv2/deliveryScheduling";
import * as alertActions from "../../alerts/alerts.actions";
import * as calendarActions from "../calendar/calendar.actions";
import * as searchFilterSelectors from "../searchFilter/searchFilter.selectors";
import * as labelFilterSelectors from "../labelFilter/labelFilter.selectors";
import * as userFilterSelectors from "../userFilter/userFilter.selectors";
import * as requiredDateSelectors from "../requiredDateFilter/requiredDate.selectors";
import * as calendarSelectors from "../calendar/calendar.selectors";

import * as selectors from "./orders.selectors";
import { SET_IS_FETCHING_ORDERS, SET_ORDERS, RESET } from "./orders.actionTypes";

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setIsFetchingOrders = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_ORDERS,
    payload: status,
  });
};

export const setOrders = (data) => (dispatch) => {
  dispatch({
    type: SET_ORDERS,
    payload: data,
  });
};

export const fetchOrders = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const selectedLabels = labelFilterSelectors.getSelectedLabels(state);
    const selectedAssignees = userFilterSelectors.getSelectedUsers(state);
    const search = searchFilterSelectors.getSearch(state);
    const requiredStartDate = requiredDateSelectors.getRequiredStartDate(state);
    const requiredEndDate = requiredDateSelectors.getRequiredEndDate(state);

    const payload = {
      search: search || undefined,
      labels: selectedLabels,
      assignees: selectedAssignees,
      requiredStartDate,
      requiredEndDate,
    };

    dispatch(setIsFetchingOrders(true));
    const { data } = await api.getOrderBacklog(payload)();

    // needed to fake the start and end time with 30 minutes interval
    // so that orders in the left side of the page can be drag to the calendar
    // with a 30 minutes time range

    dispatch(
      setOrders(
        orderBy(
          data.map((d) => ({ ...d, length: "1.0h", start: "00:00", end: "01:00" })),
          ["createdTimestamp"],
          ["desc"]
        )
      )
    );
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetchingOrders(false));
  }
};

export const undoMarkOrder = (values) => async (dispatch, getState) => {
  const state = getState();
  const { order, orderIndex } = values;
  const orders = selectors.getOrders(state);

  dispatch(setOrders([...orders.slice(0, orderIndex), order, ...orders.slice(orderIndex)]));

  try {
    await api.postMarkOrder({ orderUid: order.uid, is_received: null })();
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const markOrder = (values) => async (dispatch, getState) => {
  const state = getState();

  const { order, message, markedStatus } = values;
  const orderIndex = selectors.getOrders(state).findIndex((item) => item.uid === order.uid);
  const orders = selectors.getOrders(state);

  dispatch(setOrders(orders.filter((c) => c.uid !== order.uid)));

  try {
    await api.postMarkOrder({
      order_uid: order.uid,
      is_received: markedStatus,
    })();

    if (message) {
      dispatch(
        alertActions.createAlertAction(nanoid(), message, true, "success", "Undo", async () => {
          await dispatch(
            undoMarkOrder({
              order,
              orderIndex,
            })
          );
        })
      );
    }
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const movedOrderToBacklog = (values) => async (dispatch, getState) => {
  const state = getState();

  const { order } = values;
  const formattedOrder = omit(order, [
    "start",
    "end",
    "deliveryStartTimestamp",
    "deliveryEndTimestamp",
    "allDay",
    "resource",
    "vehicle",
    "id",
    "cssClass",
  ]);
  const orders = selectors.getOrders(state);
  const schedules = calendarSelectors.getSchedules(state);

  dispatch(
    setOrders([{ ...formattedOrder, length: "1.0h", start: "00:00", end: "01:00" }, ...orders])
  );

  dispatch(calendarActions.setOrderInCalendar(schedules.filter((s) => s.uid !== order.uid)));

  try {
    await api.postMovedOrderToBacklog({
      order_uid: order.uid,
      to_delivery_backlog: true,
    })();
  } catch (error) {
    handleError(error, dispatch);
  }
};

// changeWorkflowOrderThunk = ({ token, getState, dispatch, fulfilled }, values) => {
//   const { order, columnUid, message, ...rest } = values;

//   const orderUid = order.uid;

//   const orderIndex = getState()
//     .getIn(["calendar", "deliveries", "backlog"])
//     .findIndex((item) => item.get("uid") === orderUid);

//   fulfilled({
//     orderIndex,
//     orderUid,
//     columnUid,
//     ...rest,
//   });

//   return cardsApi.changeWorkflowOrder(token, snakecaseKeys({ orderUid, ...rest })).then(() => {
//     if (message) {
//       dispatch(
//         alerts.actions.addAlert({
//           type: "success",
//           message,
//           closeDelay: 5000,
//           action: {
//             label: "Undo",
//             callback: (filter) => {
//               dispatch(
//                 this.actions.undoChangeExtendedStatus({
//                   columnUid,
//                   orderIndex,
//                   order,
//                   ...rest,
//                 })
//               ).then(() => {
//                 if (filter) {
//                   const { value, withArchived } = filter;
//                   dispatch(this.actions.searchCustomerOrders(value, withArchived));
//                 }
//               });
//             },
//           },
//         })
//       );
//     }
//   });
// };
