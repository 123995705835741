import createActionType from "../../utils/createActionType";

export const MODULE = "settings/billing";

export const SET_IS_FETCHING_STATUS = createActionType(MODULE, "setIsFetchingStatus");

export const SET_PRICING_TABLE = createActionType(MODULE, "pricingTable");

export const SET_IS_CHECKOUT_STATUS = createActionType(MODULE, "setIsCheckoutStatus");

export const SET_BILILNG_DETAILS = createActionType(MODULE, "billingDetails");

export const RESET = createActionType(MODULE, "reset");
