import { STATUS } from "@/utils/constants";

import {
  SET_IS_FETCHING_LISTS_STATUS,
  SET_LISTS,
  SET_ACTIVE_LINE_ITEM,
  RESET,
} from "./activeTimeEntries.actionTypes";

const initialState = {
  isFetchingListsStatus: STATUS.IDLE,
  lists: [],
  activeLineItem: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_FETCHING_LISTS_STATUS: {
      return { ...state, isFetchingListsStatus: action.payload };
    }

    case SET_LISTS: {
      return { ...state, lists: action.payload };
    }

    case SET_ACTIVE_LINE_ITEM: {
      return { ...state, activeLineItem: action.payload };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
