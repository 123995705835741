import { fromJS } from "immutable";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import moment from "moment-timezone";

import * as calendarApi from "../../api/calendar";
import * as cardsApi from "../../api/cards";
import { ROOT_URL } from "../../env";

import { withMutations } from "./helpers/reducerWrappers";
import ReduxModule from "./abstract/ReduxModule";
import { setPending, reorderItems } from "./helpers/common";
import alerts from "./alerts";

const ACTIONS = {
  SET_ACTIVE_VEHICLE: "Set active vehicle",

  SET_DELIVERIES_OPTIONS: "Set deliveries options",
  SEARCH_ORDERS_BACKLOG: "Search backlog orders",

  SEND_TO_PRINT: "Send to print",

  MOVE_ORDER_BACKLOG: "Move order backlog",
  CHANGE_STATUS: "Set status",
  CHANGE_EXTENDED_STATUS: "Set extended status",
  UNDO_CHANGE_EXTENDED_STATUS: "Undo set extended status",

  ASSIGN_USER: "Assign User",
  ASSIGN_LABELS: "Assign Labels",
  RENAME_LABELS: "Rename Labels",
  REMOVE_LABELS: "Remove Labels",

  SEARCH_ORDERS_CALENDAR: "Search calendar orders",
  CREATE_EVENT: "Create event",
  UPDATE_EVENT: "Update event",
  REMOVE_EVENT: "Remove event",
};

class CalendarModule extends ReduxModule {
  getNamespace() {
    return "[Calendar]";
  }

  getInitialState() {
    return {
      deliveries: {
        pageSize: 3,
        pageNumber: 0,
        search: "",
        pageLoaded: [], // [-2, -1, 0, 1, 2]
        settings: {
          enableEmailNotifications: false,
          notifyWhenTimeChanged: false,
          startTime: "",
          finishTime: "",
          vehicles: [], // [{ uid: '', name: '' }]
        },
        activeVehicle: null, // this.getInitialStateVehicle()
        backlog: [],
        orders: [], // [{ OrderModule.initialEmptyOrder() }]
      },
      pendings: {
        settings: false,
        backlog: false,
        orders: false,
      },
      isPending: false,
      errors: {},
    };
  }

  getInitialStateVehicle() {
    return {
      uid: "",
      name: "",
      isDeleted: false,
    };
  }

  getSettings = ({ token, fulfilled }) =>
    calendarApi.getSettings(token).then(({ data }) => {
      fulfilled(fromJS(camelcaseKeys(data, { deep: true })));
      return data;
    });

  changeSettings = ({ token, dispatch, fulfilled }, settings) =>
    calendarApi.changeSettings(token, snakecaseKeys(settings)).then(({ data }) => {
      fulfilled(fromJS(camelcaseKeys(data, { deep: true })));

      if (!data.vehicles.length) {
        dispatch(this.actions.setActiveVehicle(null));
      }

      return data;
    });

  searchOrdersBacklog = ({ token, fulfilled }, search) =>
    calendarApi.searchOrdersBacklog(token, { search }).then(({ data }) => {
      fulfilled(fromJS(camelcaseKeys(data, { deep: true })));
      return data;
    });

  sendToPrint = ({ dispatch, token, fulfilled }, data) =>
    calendarApi
      .sendToPrint(token, snakecaseKeys(data))
      .then((response) => {
        fulfilled();
        return `${ROOT_URL}${response.data}`;
      })
      .catch((errors) => {
        const errorsMessage = errors.response ? errors.response.data.detail.errors : errors.message;

        if (Array.isArray(errorsMessage)) {
          errorsMessage.forEach((error) => {
            dispatch(
              alerts.actions.addAlert({
                type: "danger",
                message: error || "Print generation error",
              })
            );
          });
        }

        throw errors;
      });

  searchOrdersCalendarThunk = ({ token, getState, fulfilled }, options) => {
    const { page = 0, search = "" } = options || {};

    const deliveries = getState().getIn(["calendar", "deliveries"]);
    const deliveriesSearch = deliveries.get("search");
    const pageLoaded = deliveries.get("pageLoaded");

    const timeToMinutes = (time) => {
      const duration = moment(time, "h:mma").format("HH:mm");
      return moment.duration(duration).asMinutes();
    };

    const settings = deliveries.get("settings");
    const startTime = timeToMinutes(settings.get("startTime"));
    let finishTime = settings.get("finishTime");
    finishTime = finishTime.split(":")[0] === "12" ? 1440 : timeToMinutes(finishTime);

    let pageNumber = 0;
    const pageSize = deliveries.get("pageSize");
    const isPageLoaded = pageLoaded.find((p) => parseInt(p, 10) === page);
    const isSearching = deliveriesSearch !== search;

    if (isPageLoaded == null || isSearching) {
      pageNumber = Math.trunc((page + Math.sign(page) * -1) / pageSize);

      calendarApi
        .searchOrdersCalendar(token, snakecaseKeys({ pageSize, pageNumber, search }))
        .then(({ data }) => {
          const updatedData = data
            .filter((item) => {
              const {
                delivery_start_timestamp: deliveryStartTimestamp,
                delivery_end_timestamp: deliveryEndTimestamp,
              } = item;

              return !(
                timeToMinutes(moment(deliveryStartTimestamp)) < startTime ||
                timeToMinutes(moment(deliveryEndTimestamp)) > finishTime
              );
            })
            .map((item) => {
              const order = camelcaseKeys(item, { deep: true });
              const { deliveryStartTimestamp, deliveryEndTimestamp } = order;
              order.deliveryStartTimestamp = moment(deliveryStartTimestamp).toDate();
              order.deliveryEndTimestamp = moment(deliveryEndTimestamp).toDate();
              return order;
            });

          fulfilled({
            data: fromJS(updatedData),
            pageNumber,
            isSearching,
            search,
          });

          return data;
        });
    } else {
      fulfilled();
    }
  };

  moveOrderBacklog = ({ token, getState, fulfilled }, { droppableId, startIndex, endIndex }) => {
    const orderUid = getState().getIn(["calendar", "deliveries", droppableId, startIndex, "uid"]);
    fulfilled({ droppableId, startIndex, endIndex });

    return calendarApi.moveOrderBacklog(token, {
      order_uid: orderUid,
      new_index: endIndex,
    });
  };

  moveWorkflowOrder = ({ token, getState, fulfilled }, ids) => {
    const { order, droppableDestinationId, endIndex } = ids;
    const status = getState()
      .getIn(["cards", "workflow", "columns"])
      .find((item) => item.get("uid") === droppableDestinationId);

    fulfilled({
      order,
      status: {
        name: status.get("name"),
        colour: status.getIn(["settings", "colour"]),
      },
    });

    const orderUid = order.uid;
    return cardsApi.moveWorkflowOrder(token, {
      order_uid: orderUid,
      new_index: endIndex,
      new_column_uid: droppableDestinationId,
    });
  };

  changeWorkflowOrderThunk = ({ token, getState, dispatch, fulfilled }, values) => {
    const { order, columnUid, message, ...rest } = values;

    const orderUid = order.uid;

    const orderIndex = getState()
      .getIn(["calendar", "deliveries", "backlog"])
      .findIndex((item) => item.get("uid") === orderUid);

    fulfilled({
      orderIndex,
      orderUid,
      columnUid,
      ...rest,
    });

    return cardsApi.changeWorkflowOrder(token, snakecaseKeys({ orderUid, ...rest })).then(() => {
      if (message) {
        dispatch(
          alerts.actions.addAlert({
            type: "success",
            message,
            closeDelay: 5000,
            action: {
              label: "Undo",
              callback: (filter) => {
                dispatch(
                  this.actions.undoChangeExtendedStatus({
                    columnUid,
                    orderIndex,
                    order,
                    ...rest,
                  })
                ).then(() => {
                  if (filter) {
                    const { value, withArchived } = filter;
                    dispatch(this.actions.searchCustomerOrders(value, withArchived));
                  }
                });
              },
            },
          })
        );
      }
    });
  };

  undoChangeWorkflowOrderThunk = ({ token, fulfilled }, values) => {
    const { orderIndex, order, columnUid, ...rest } = values;

    const prevValues = {};

    Object.keys(rest).forEach((key) => {
      prevValues[key] = order[key];
    });

    fulfilled({
      columnUid,
      orderIndex,
      order,
    });

    return cardsApi.changeWorkflowOrder(
      token,
      snakecaseKeys({ orderUid: order.uid, orderIndex, ...prevValues })
    );
  };

  updateEventThunk = ({ token, fulfilled }, event) => {
    const { uid, vehicle, deliveryStartTimestamp, deliveryEndTimestamp } = event;
    fulfilled(event);

    return calendarApi.createCalendarEvent(
      token,
      snakecaseKeys({
        orderUid: uid,
        vehicleUid: vehicle,
        deliveryStartTimestamp,
        deliveryEndTimestamp,
      })
    );
  };

  removeEventThunk = ({ token, fulfilled }, { order }) => {
    const { uid } = order;
    fulfilled(order);

    return calendarApi.removeCalendarEvent(
      token,
      snakecaseKeys({
        orderUid: uid,
        toDeliveryBacklog: true,
      })
    );
  };

  updateOrderCardAssigneesThunk = ({ getState, fulfilled }, values) => {
    const { order } = values;

    const orderIndex = getState()
      .getIn(["calendar", "deliveries", "backlog"])
      .findIndex((item) => item.get("uid") === order.uid);

    fulfilled({
      orderIndex,
      orderUid: order.uid,
      assignees: order.assignees,
    });

    return Promise.resolve();
  };

  updateOrderCardLabelsThunk = ({ getState, fulfilled }, values) => {
    const { order } = values;

    const orderIndex = getState()
      .getIn(["calendar", "deliveries", "backlog"])
      .findIndex((item) => item.get("uid") === order.uid);

    fulfilled({
      orderIndex,
      orderUid: order.uid,
      labels: order.labels,
    });

    return Promise.resolve();
  };

  defineActions() {
    const setActiveVehicle = this.createAction(ACTIONS.SET_ACTIVE_VEHICLE, (vehicle) =>
      vehicle ? fromJS(camelcaseKeys(vehicle, { deep: true })) : null
    );

    const getSettings = this.thunkAction(ACTIONS.SET_DELIVERIES_OPTIONS, this.getSettings, true);
    const changeSettings = this.thunkAction(
      ACTIONS.SET_DELIVERIES_OPTIONS,
      this.changeSettings,
      true
    );

    const generateRunShit = this.thunkAction(ACTIONS.SEND_TO_PRINT, this.sendToPrint, true);

    const searchOrdersBacklog = this.thunkAction(
      ACTIONS.SEARCH_ORDERS_BACKLOG,
      this.searchOrdersBacklog,
      true
    );
    const moveOrderBacklog = this.thunkAction(ACTIONS.MOVE_ORDER_BACKLOG, this.moveOrderBacklog);
    const changeStatus = this.thunkAction(ACTIONS.CHANGE_STATUS, this.moveWorkflowOrder);
    const changeExtendedStatus = this.thunkAction(
      ACTIONS.CHANGE_EXTENDED_STATUS,
      this.changeWorkflowOrderThunk
    );
    const undoChangeExtendedStatus = this.thunkAction(
      ACTIONS.UNDO_CHANGE_EXTENDED_STATUS,
      this.undoChangeWorkflowOrderThunk
    );

    const updateOrderCardAssignees = this.thunkAction(
      ACTIONS.ASSIGN_USER,
      this.updateOrderCardAssigneesThunk
    );

    const updateOrderCardLabels = this.thunkAction(
      ACTIONS.ASSIGN_LABELS,
      this.updateOrderCardLabelsThunk
    );

    const renameOrderCardLabel = this.createAction(ACTIONS.RENAME_LABELS, (data) => data);

    const removeOrderCardLabel = this.createAction(ACTIONS.REMOVE_LABELS, (data) => data);

    const searchOrdersCalendar = this.thunkAction(
      ACTIONS.SEARCH_ORDERS_CALENDAR,
      this.searchOrdersCalendarThunk,
      true
    );
    const createEvent = this.thunkAction(ACTIONS.CREATE_EVENT, this.updateEventThunk);
    const updateEvent = this.thunkAction(ACTIONS.UPDATE_EVENT, this.updateEventThunk);
    const removeEvent = this.thunkAction(ACTIONS.REMOVE_EVENT, this.removeEventThunk);

    return {
      setActiveVehicle,

      getSettings,
      changeSettings,

      generateRunShit,

      searchOrdersBacklog,
      moveOrderBacklog,
      changeStatus,
      changeExtendedStatus,
      undoChangeExtendedStatus,
      updateOrderCardAssignees,
      updateOrderCardLabels,
      renameOrderCardLabel,
      removeOrderCardLabel,

      searchOrdersCalendar,
      createEvent,
      updateEvent,
      removeEvent,
    };
  }

  createEvent = (_state, activeEvent) => {
    _state.updateIn(["deliveries", "backlog"], (list) =>
      list.filter((event) => event.get("uid") !== activeEvent.uid)
    );

    _state.updateIn(["deliveries", "orders"], (list) => list.push(fromJS(activeEvent)));
  };

  removeEvent = (_state, activeEvent) => {
    _state.updateIn(["deliveries", "backlog"], (list) =>
      list.unshift(
        fromJS({
          ...activeEvent,
          deliveryStartTimestamp: null,
          deliveryEndTimestamp: null,
        })
      )
    );

    _state.updateIn(["deliveries", "orders"], (list) =>
      list.filter((event) => event.get("uid") !== activeEvent.uid)
    );
  };

  changeExtendedStatus = (_state, { orderIndex, orderUid, isReceived }) => {
    if (orderIndex > -1) {
      _state.deleteIn(["deliveries", "backlog", orderIndex]);
    }

    _state.updateIn(["deliveries", "orders"], (list) =>
      list.map((event) => {
        if (event.get("uid") === orderUid) {
          return event.set("isReceived", isReceived);
        }

        return event;
      })
    );
  };

  undoChangeExtendedStatus = (_state, { orderIndex, order }) => {
    if (orderIndex > -1) {
      _state.updateIn(["deliveries", "backlog"], (list) =>
        list.splice(orderIndex, 0, fromJS(order))
      );
    }

    _state.updateIn(["deliveries", "orders"], (list) =>
      list.map((event) => {
        if (event.get("uid") === order.uid) {
          return event.set("isReceived", order.isReceived);
        }

        return event;
      })
    );
  };

  searchOrdersCalendar = (_state, options) => {
    const { data, pageNumber, search } = options || {};
    const pageSize = _state.getIn(["deliveries", "pageSize"]);

    if (data) {
      let newList = !pageNumber ? fromJS([0]) : fromJS([]);
      const start = !pageNumber ? 1 : 0;
      const size = !pageNumber ? pageSize + 1 : pageSize;

      const page = pageNumber * pageSize + Math.sign(pageNumber);

      if (pageNumber === 0) {
        for (let i = start; i < size; i++) {
          newList = newList.push(page + i);
          newList = newList.unshift(page - i);
        }
      } else {
        for (let i = 0; i < size; i++) {
          if (pageNumber > 0) {
            newList = newList.push(page + i);
          } else {
            newList = newList.unshift(page - i);
          }
        }
      }

      _state.setIn(["deliveries", "pageNumber"], pageNumber);
      _state.setIn(["deliveries", "search"], search);
      _state.setIn(["deliveries", "orders"], data);
      _state.setIn(["deliveries", "pageLoaded"], newList);
    }
  };

  renameOrderCardLabel = (_state, payload) => {
    const backlogOrders = _state.getIn(["deliveries", "backlog"]).toJS();
    const formattedBacklogOrders = backlogOrders.map((b) => ({
      ...b,
      labels: b.labels.map((l) =>
        l.uid === payload.uid ? { ...l, name: payload.label, colour: payload.colour } : l
      ),
    }));

    return _state.setIn(["deliveries", "backlog"], fromJS(formattedBacklogOrders));
  };

  removeOrderCardLabel = (_state, payload) => {
    const backlogOrders = _state.getIn(["deliveries", "backlog"]).toJS();
    const formattedBacklogOrders = backlogOrders.map((b) => ({
      ...b,
      labels: b.labels.filter((l) => l.uid !== payload.uid),
    }));

    return _state.setIn(["deliveries", "backlog"], fromJS(formattedBacklogOrders));
  };

  defineReducers() {
    return {
      [ACTIONS.SET_ACTIVE_VEHICLE]: this.setInReducer(["deliveries", "activeVehicle"]),

      [`${ACTIONS.SET_DELIVERIES_OPTIONS} fulfilled`]: this.setInReducer([
        "deliveries",
        "settings",
      ]),

      [`${ACTIONS.SET_DELIVERIES_OPTIONS} pending`]: setPending("settings"),

      [`${ACTIONS.SEND_TO_PRINT} fulfilled`]: (state) => state,

      [`${ACTIONS.SEND_TO_PRINT} pending`]: this.thunkPendingReducer("isPending"),

      [`${ACTIONS.SEND_TO_PRINT} rejected`]: this.setReducer("errors"),

      [`${ACTIONS.SEARCH_ORDERS_BACKLOG} fulfilled`]: this.setInReducer(["deliveries", "backlog"]),

      [`${ACTIONS.SEARCH_ORDERS_BACKLOG} pending`]: setPending("backlog"),

      [`${ACTIONS.MOVE_ORDER_BACKLOG} fulfilled`]: (state, { payload: { startIndex, endIndex } }) =>
        state.updateIn(["deliveries", "backlog"], (list) =>
          reorderItems(list, startIndex, endIndex)
        ),

      [`${ACTIONS.CHANGE_STATUS} fulfilled`]: (state, { payload: { order, status } }) =>
        state.updateIn(["deliveries", "orders"], (list) =>
          list.map((event) => {
            if (event.get("uid") === order.uid) {
              return event.set("status", status);
            }

            return event;
          })
        ),

      [`${ACTIONS.CHANGE_EXTENDED_STATUS} fulfilled`]: withMutations(this.changeExtendedStatus),

      [`${ACTIONS.UNDO_CHANGE_EXTENDED_STATUS} fulfilled`]: withMutations(
        this.undoChangeExtendedStatus
      ),

      [`${ACTIONS.ASSIGN_USER} fulfilled`]: (
        state,
        { payload: { assignees, orderUid, orderIndex } }
      ) => {
        const calendarOrderUids = state
          .getIn(["deliveries", "orders"])
          .toJS()
          .map((order) => order.uid);

        if (calendarOrderUids.includes(orderUid)) {
          return state.setIn(["deliveries", "orders", orderIndex, "assignees"], assignees);
        }

        return state.setIn(["deliveries", "backlog", orderIndex, "assignees"], assignees);
      },

      [`${ACTIONS.ASSIGN_LABELS} fulfilled`]: (
        state,
        { payload: { labels, orderUid, orderIndex } }
      ) => {
        const calendarOrderUids = state
          .getIn(["deliveries", "orders"])
          .toJS()
          .map((order) => order.uid);

        if (calendarOrderUids.includes(orderUid)) {
          return state.setIn(["deliveries", "orders", orderIndex, "labels"], labels);
        }

        return state.setIn(["deliveries", "backlog", orderIndex, "labels"], labels);
      },

      [ACTIONS.RENAME_LABELS]: withMutations(this.renameOrderCardLabel),
      [ACTIONS.REMOVE_LABELS]: withMutations(this.removeOrderCardLabel),

      [`${ACTIONS.SEARCH_ORDERS_CALENDAR} fulfilled`]: withMutations(this.searchOrdersCalendar),

      [`${ACTIONS.SEARCH_ORDERS_CALENDAR} pending`]: setPending("orders"),

      [`${ACTIONS.CREATE_EVENT} fulfilled`]: withMutations(this.createEvent),

      [`${ACTIONS.UPDATE_EVENT} fulfilled`]: (state, { payload: newEvent }) =>
        state.updateIn(["deliveries", "orders"], (list) =>
          list.map((event) => {
            if (event.get("uid") === newEvent.uid) {
              return fromJS(newEvent);
            }

            return event;
          })
        ),

      [`${ACTIONS.REMOVE_EVENT} fulfilled`]: withMutations(this.removeEvent),
    };
  }
}

const instance = new CalendarModule();
instance.init();

export default instance;
