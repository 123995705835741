import { STATUS } from "../../utils/constants";

import {
  SET_IS_FETCHING_STATUS,
  SET_PRODUCTS,
  SET_IS_SEARCH_RESULT_OPEN,
  SET_SEARCH,
  RESET,
} from "./add-product-to-order-inline.actionTypes";

const initialState = {
  isFetchingStatus: STATUS.IDLE,
  products: [],
  isSearchResultOpen: false,
  search: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_FETCHING_STATUS: {
      return {
        ...state,
        isFetchingStatus: action.payload,
      };
    }

    case SET_PRODUCTS: {
      return {
        ...state,
        products: action.payload,
      };
    }

    case SET_IS_SEARCH_RESULT_OPEN: {
      return {
        ...state,
        isSearchResultOpen: action.payload,
      };
    }

    case SET_SEARCH: {
      return {
        ...state,
        search: action.payload,
      };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
