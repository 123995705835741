import { STATUS } from "@/utils/constants";

import {
  SET_IS_FETCHING_SUMMARY_STATUS,
  SET_SUMMARY,
  SET_IS_FETCHING_TEAM_MEMBERS_STATUS,
  SET_TEAM_MEMBERS,
  SET_MODAL_TYPE,
  RESET,
  SET_IS_SAVING_STATUS,
  SET_ACTIVE_TIME_ENTRY,
  SET_IS_FETCHING_ORDER_ITEMS_STATUS,
  SET_ORDER_ITEMS,
  SET_IS_SEARCHING_ORDER_NUMBER_STATUS,
  SET_ORDER_NUMBER_RESULTS,
  SET_USER,
} from "./timeTracking.actionTypes";

const initialState = {
  isFetchingSummaryStatus: STATUS.IDLE,
  summary: null,
  isFetchingTeamMembersStatus: STATUS.IDLE,
  teamMembers: [],
  modalType: "",
  isSavingStatus: STATUS.IDLE,
  isFetchingOrderItemsStatus: STATUS.IDLE,
  activeTimeEntry: null,
  isSearchingOrderNumberStatus: STATUS.IDLE,
  orderItems: [],
  orderNumberResults: [],
  user: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_FETCHING_SUMMARY_STATUS: {
      return { ...state, isFetchingSummaryStatus: action.payload };
    }

    case SET_SUMMARY: {
      return { ...state, summary: action.payload };
    }

    case SET_IS_FETCHING_TEAM_MEMBERS_STATUS: {
      return { ...state, isFetchingTeamMembersStatus: action.payload };
    }

    case SET_TEAM_MEMBERS: {
      return { ...state, teamMembers: action.payload };
    }

    case SET_MODAL_TYPE: {
      return { ...state, modalType: action.payload };
    }

    case SET_IS_SAVING_STATUS: {
      return { ...state, isSavingStatus: action.payload };
    }

    case SET_ACTIVE_TIME_ENTRY: {
      return { ...state, activeTimeEntry: action.payload };
    }

    case SET_ORDER_ITEMS: {
      return { ...state, orderItems: action.payload };
    }

    case SET_IS_FETCHING_ORDER_ITEMS_STATUS: {
      return { ...state, isFetchingOrderItemsStatus: action.payload };
    }

    case SET_IS_SEARCHING_ORDER_NUMBER_STATUS: {
      return { ...state, isSearchingOrderNumberStatus: action.payload };
    }

    case SET_ORDER_NUMBER_RESULTS: {
      return { ...state, orderNumberResults: action.payload };
    }

    case SET_USER: {
      return { ...state, user: action.payload };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
