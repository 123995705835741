import createActionType from "../utils/createActionType";

export const MODULE = "customersv2";

export const SET_IS_FETCHING_CUSTOMER_CONTACTS = createActionType(
  MODULE,
  "setIsFetchingCustomerContacts"
);
export const SET_CUSTOMER = createActionType(MODULE, "setCustomer");
export const SET_IS_CONTACTS_MODAL_OPEN = createActionType(MODULE, "setIsContactsModalOpen");
export const SET_CUSTOMER_CONTACTS = createActionType(MODULE, "setCustomerContacts");
export const SET_SELECTED_CONTACTS = createActionType(MODULE, "setSelectedContacts");
export const SET_SEARCH = createActionType(MODULE, "setSearch");
export const UPDATE_SELECTED_CONTACTS = createActionType(MODULE, "updateSelectedContacts");
export const REMOVE_SELECTED_CONTACT = createActionType(MODULE, "removeSelectedContact");

export const SET_IS_ARCHIVED_CUSTOMER_INCLUDED = createActionType(
  MODULE,
  "setIsArchivedCustomerIncluded"
);

export const SET_SEARCH_CUSTOMER = createActionType(MODULE, "setSearchCustomer");
export const SET_IS_FETCHING_CUSTOMER_LISTS = createActionType(
  MODULE,
  "setIsFetchingCustomerLists"
);
export const SET_CUSTOMER_LISTS = createActionType(MODULE, "setCustomerLists");

export const SET_CURRENT_PAGE = createActionType(MODULE, "setCurrentPage");

export const SET_TOTAL_COUNT = createActionType(MODULE, "setTotalCount");

export const SET_NEXT_PAGE_OFFSET = createActionType(MODULE, "setNextPageOffset");

export const SET_PREVIOUS_PAGE_OFFSET = createActionType(MODULE, "setPreviousPageOffset");

export const SET_PAGE_OFFSET = createActionType(MODULE, "setPageOffset");

export const SET_PAGE_LIMIT = createActionType(MODULE, "setPageLimit");

export const SET_SORT = createActionType(MODULE, "setSort");
