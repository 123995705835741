import createActionType from "../utils/createActionType";

export const MODULE = "orderAttachments";

export const SET_IS_FETCHING = createActionType(MODULE, "setIsFetching");

export const SET_IS_PRINTING = createActionType(MODULE, "setIsPrinting");

export const SET_ORDER_ATTACHMENTS = createActionType(MODULE, "setOrderAttachments");

export const SET_SELECTED_ATTACHMENTS = createActionType(MODULE, "setSelectedAttachments");

export const SET_DRAWINGS_LIST = createActionType(MODULE, "setDrawingsList");

export const SET_IS_PRODUCT_HAS_NO_PRICE_ALERT_OPEN = createActionType(
  MODULE,
  "setIsProductHasNoPriceAlertOpen"
);

export const POST_ADD_ATTACHMENTS = createActionType(MODULE, "addAttachments");

export const REMOVE_SELECTED_ATTACHMENT = createActionType(MODULE, "removeSelectedAttachment");

export const UPDATE_SELECTED_ATTACHMENTS = createActionType(MODULE, "updateSelectedAttachments");

export const GET_INVOICE_PDF = createActionType(MODULE, "getInvoicePdf");

export const GET_QUOTE_PDF = createActionType(MODULE, "getQuotePdf");

export const SEND_EMAIL_PDF = createActionType(MODULE, "sendEmailPdf");

export const RESET = createActionType(MODULE, "reset");
