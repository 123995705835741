import createActionType from "../utils/createActionType";

const MODULE = "supplierProducts";

export const GET_ALL_SUPPLIER_PRODUCTS = createActionType(
  MODULE,
  "getAllSupplierProducts"
);
export const DELETE_SUPPLIER_PRODUCT = createActionType(
  MODULE,
  "deleteSupplierProduct"
);
