import { MODULE } from "./purchaseOrders.actionTypes";

export const purchaseOrdersSelector = (state) => state.getIn([MODULE, "purchaseOrders"]);

export const activePurchaseOrderSelector = (state) => state.getIn([MODULE, "activePurchaseOrder"]);

export const purchaseOrderFiltersSelector = (state) => state.getIn([MODULE, "filters"]);

export const recentlyDeletedPurchaseOrderUidSelector = (state) =>
  state.getIn([MODULE, "recentlyDeletedPurchaseOrderUid"]);

export const recentlyBulkDeletedPurchaseOrderUidsSelector = (state) =>
  state.getIn([MODULE, "recentlyBulkDeletedPurchaseOrderUids"]);

export const externalPurchaseOrderSelector = (state) =>
  state.getIn([MODULE, "externalPurchaseOrder"]);

export const linkedOrdersSelector = (state) => state.getIn([MODULE, "linkedOrders"]);

export const isDuplicating = (state) => state.getIn([MODULE, "isDuplicating"]);

export const getDuplicateOrderUid = (state) => state.getIn([MODULE, "duplicateOrderUid"]);

export const isDuplicateConfirmationAlertOpenSelector = (state) =>
  state.getIn([MODULE, "isDuplicateConfirmationAlertOpen"]);

export const isChangingSupplierModalOpenSelector = (state) =>
  state.getIn([MODULE, "isChangingSupplierModalOpen"]);

export const isChangingSupplier = (state) => state.getIn([MODULE, "isChangingSupplier"]);
export const isConvertingBillOrPo = (state) => state.getIn([MODULE, "isConvertingBillOrPo"]);

export const isConvertingBillOrPoConfirmationAlertOpenSelector = (state) =>
  state.getIn([MODULE, "isConvertingBillOrPoConfirmationAlertOpen"]);

export const getCurrentInventorySelector = (state) => {
  const activePurchaseOrder = activePurchaseOrderSelector(state);
  if (!activePurchaseOrder) return [];
  const inventory = activePurchaseOrder.items
    .filter((item) => item.isStockTrackedByCompany)
    .map((item) => ({
      ...item.inventory,
      productRow: item.productRow,
      quantity: item.quantity,
    }))

    .reduce((previous, current) => {
      const productWithColour = previous.find(
        (item) => item.productRow === current.productRow && item.colour === current.colour
      );
      const productWithoutColour = previous.find(
        (item) => item.productRow === current.productRow && !item.colour
      );

      if (productWithoutColour) {
        productWithoutColour.quantity += current.quantity;
      } else if (productWithColour) {
        productWithColour.quantity += current.quantity;
      } else {
        previous.push(current);
      }

      return previous;
    }, []);

  return inventory;
};

export const getDrawingSVGs = (state) => state.getIn([MODULE, "drawingSVGs"]);

export const getDeletedFlashings = (state) =>
  state.getIn([MODULE, "activePurchaseOrder", "deletedFlashingItems"]);

// ATTACHMENTS
export const isFetching = (state) => state.getIn([MODULE, "isFetching"]);
export const orderAttachments = (state) => state.getIn([MODULE, "attachments"]);
export const selectedAttachments = (state) => state.getIn([MODULE, "selectedAttachments"]);

// Pagination
export const getPagination = (state) => state.getIn([MODULE, "pagination"]);

export const getSort = (state) => state.getIn([MODULE, "pagination", "sortBy"]);

export const getSortField = (state) => {
  const sort = getSort(state);

  if (sort !== "") {
    return sort.split(":")[0];
  }

  return "";
};

export const getSortDirection = (state) => {
  const sort = getSort(state);

  if (sort !== "") {
    return sort.split(":")[1];
  }

  return "";
};

export const printPurchaseOrderPdfStatus = (state) =>
  state.getIn([MODULE, "printPurchaseOrderPdfStatus"]);

export const emailPurchaseOrderStatus = (state) =>
  state.getIn([MODULE, "emailPurchaseOrderStatus"]);

export const isAddProductModalType = (state) => state.getIn([MODULE, "isAddProductModalType"]);
