import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";

function PanelSettings({
  menu,
  header,
  labelClassName,
  itemClassName,
  iconClassName,
  activeItem,
  onClick,
}) {
  const [_active, setActive] = useState("");

  useEffect(() => {
    setActive(activeItem);
  }, [activeItem]);

  const handleClick = (path, disabled) => {
    if (!disabled) {
      setActive(path);
      onClick(path);
    }
  };

  const renderItems = (item) => {
    const { title, icon, disabled, description, path } = item;
    const itemTab = new URLSearchParams(path.substring(path.indexOf("?"))).get("tab");

    return (
      <button
        key={title}
        className={cx("settings-item d-flex", itemClassName, {
          active: _active === itemTab || _active === path,
        })}
        onClick={() => handleClick(path, disabled)}
        disabled={disabled}
      >
        {icon && (
          <div className={cx("icon", iconClassName)}>
            <FontAwesomeIcon icon={icon} />
          </div>
        )}
        <div className={cx("text d-flex flex-column justify-content-center", labelClassName)}>
          <div className="title">{title}</div>
          {description && <div className="description">{description}</div>}
        </div>
      </button>
    );
  };

  return (
    <div className="panel-settings">
      <Card>
        {header && (
          <CardHeader className="d-flex justify-content-center align-items-center flex-column bg-light4 p-3_5">
            {header}
          </CardHeader>
        )}
        <CardBody className="px-0">{menu.map(renderItems)}</CardBody>
      </Card>
    </div>
  );
}

PanelSettings.propTypes = {
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      icon: PropTypes.oneOfType([PropTypes.shape(), PropTypes.array, PropTypes.string]),
      disabled: PropTypes.bool,
      path: PropTypes.string,
    })
  ).isRequired,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.shape(), PropTypes.array]),
  labelClassName: PropTypes.string,
  itemClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  activeItem: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

PanelSettings.defaultProps = {
  labelClassName: "",
  itemClassName: "",
  iconClassName: "",
  header: undefined,
  activeItem: undefined,
};

export default PanelSettings;
