import { MODULE } from "./productLists.actionTypes";

export const getSearch = (state) => state.getIn([MODULE, "search"]);

export const isFetchingCategories = (state) => state.getIn([MODULE, "isFetchingCategories"]);

export const getCategories = (state) => state.getIn([MODULE, "categories"]);

export const getProducts = (state) => state.getIn([MODULE, "products"]);

export const getExpandedCategories = (state) => state.getIn([MODULE, "expandedCategories"]);

export const getMiscellaneousCategory = (state) => {
  const data = getCategories(state).find((c) => c.name === "Miscellaneous");

  if (data) return data;
  return null;
};

export const getProductsForCategory = (state, categoryUid) =>
  state.getIn([MODULE, "products", categoryUid]) || [];
