import createActionType from "../utils/createActionType";

const MODULE = "loading";

export const RESET_LOADING_STATUS = createActionType(
  MODULE,
  "resetLoadingStatus"
);

export const SET_FULFILLED_STATUS = createActionType(
  MODULE,
  "setFulfilledStatus"
);
