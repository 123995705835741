import produce from "immer";
import { nanoid } from "nanoid";

import { PRICING_STRATEGY_BASIC_QUANTITIES, DISPLAY_FORMULA_LOCATION } from "@/utils/constants";

import setKeypathValue from "../utils/setKeypathValue";

import {
  EDIT_ACTIVE_PRODUCT,
  EDIT_SELECTED_PRICE_LEVELS,
  EDIT_SELECTED_ATTRIBUTE_VISIBLITY,
  GET_PRODUCT,
  GET_PRODUCT_TABLES,
  GET_PRODUCT_CATEGORIES,
  LOAD_EMPTY_PRODUCT,
  RESET_ACTIVE_PRODUCT,
  DELETE_PRODUCT,
  SET_IS_DUPLICATING,
  EDIT_VALUE_IN_FORMULA,
  SET_ERROR_IN_CUSTOM_FORMULA,
  SET_IS_SAVING_PRODUCT,
  SET_IS_SAVING_CUSTOM_FORMULA,
  SET_ACTIVE_PRODUCT_VARIANT,
  SET_IS_FETCHING_PRODUCT,
} from "./products.actionTypes";

const initialState = {
  activeProduct: null,
  activeProductVariant: null,
  selectedPriceLevels: [],
  selectedAttributeVisiblity: [],
  productCategories: [],
  recentlyDeletedProduct: null,
  isDuplicating: false,
  isSavingCustomFormula: false,
  isSavingProduct: false,
  isFetchingProduct: false,
  errorInCustomFormula: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case `${GET_PRODUCT}/fulfilled`: {
      const { data } = action.payload;

      return {
        ...state,
        activeProduct: {
          ...data,
          customFormula: data.customFormula
            ? {
                ...data.customFormula,
                elements: data.customFormula.elements.map((e) => ({ ...e, uid: nanoid() })),
              }
            : {
                additionalProductUid: null,
                kitProductUid: null,
                displayFormula: DISPLAY_FORMULA_LOCATION.map((location) => location.value),
                elements: [
                  { uid: nanoid(), type: "variable", name: "", value: "0" },
                  { uid: nanoid(), type: "operator", name: "", value: "*" },
                  { uid: nanoid(), type: "variable", name: "", value: "0" },
                ],
              },
          oldCategoryUid: data.categoryUid,
          tables: data.tables.map((table) => {
            const allPriceLevelsHidden =
              table.priceLevels.length === table.hiddenPriceLevels.length;
            const priceLevels = allPriceLevelsHidden
              ? table.priceLevels.map((priceLevel, index) => {
                  if (index === 0) {
                    return {
                      ...priceLevel,
                      isHidden: false,
                    };
                  }

                  return priceLevel;
                })
              : table.priceLevels;
            const hiddenPriceLevels = allPriceLevelsHidden
              ? table.hiddenPriceLevels.filter(
                  (priceLevelUid) => priceLevelUid !== table.priceLevels[0].uid
                )
              : table.hiddenPriceLevels;

            return {
              ...table,
              priceLevels,
              hiddenPriceLevels,
            };
          }),
        },
        selectedAttributeVisiblity: data.tables.map((table) =>
          table.fields.map((field) => ({ ...field, uid: nanoid() }))
        ),
        selectedPriceLevels: data.tables.map((table) =>
          table.priceLevels
            .map((priceLevel) => {
              if (!priceLevel.isHidden) {
                return priceLevel.uid;
              }

              return null;
            })
            .filter((priceLevel) => priceLevel !== null)
        ),
      };
    }

    case `${GET_PRODUCT_TABLES}/fulfilled`: {
      const { data } = action.payload;

      return {
        ...state,
        activeProduct: {
          ...state.activeProduct,
          tables: state.activeProduct.tables.map((table) => {
            const newTable = data.tables.find((t) => t.uid === table.uid);
            const newValues = table.values.map((value) => {
              const newValue = newTable.values.find((v) => value.uid === v.uid);
              return {
                ...value,
                suppliers: newValue?.suppliers ?? [],
                inventory: newValue?.inventory ?? {},
              };
            });
            return {
              ...table,
              values: newValues,
            };
          }),
        },
      };
    }

    // GET PRODUCT CATEGORIES //
    case `${GET_PRODUCT_CATEGORIES}/fulfilled`: {
      const { data } = action.payload;

      return {
        ...state,
        productCategories: data,
      };
    }

    // DELETE PRODUCT //
    case `${DELETE_PRODUCT}/pending`: {
      return {
        ...state,
        recentlyDeletedProduct: state.activeProduct,
      };
    }

    // LOAD EMPTY PRODUCT //
    case LOAD_EMPTY_PRODUCT: {
      const emptyProduct = {
        name: "",
        oldCategoryUid: "",
        tables: [],
        inLength: false,
        pricingStrategy: PRICING_STRATEGY_BASIC_QUANTITIES,
        isDeleted: false,
        isSoldByCompany: false,
        isPurchasedByCompany: false,
        isStockTrackedByCompany: false,
        isMarginTrackedByCompany: false,
        suppliers: [],
        customFormula: {
          additionalProductUid: null,
          kitProductUid: null,
          displayFormula: DISPLAY_FORMULA_LOCATION.map((location) => location.value),
          elements: [
            { uid: nanoid(), type: "variable", name: "", value: "0" },
            { uid: nanoid(), type: "operator", name: "", value: "*" },
            { uid: nanoid(), type: "variable", name: "", value: "0" },
          ],
        },
      };
      return { ...state, activeProduct: emptyProduct };
    }

    // EDIT ACTIVE PRODUCT //
    case EDIT_ACTIVE_PRODUCT: {
      const { path, value } = action.payload;

      return produce(state, (draft) => {
        setKeypathValue(draft, ["activeProduct", ...path], value);
      });
    }

    // EDIT SELECTED PRICE LEVELS //
    case EDIT_SELECTED_PRICE_LEVELS: {
      const { path, value } = action.payload;

      return produce(state, (draft) => {
        setKeypathValue(draft, ["selectedPriceLevels", ...path], value);
      });
    }

    // EDIT SELECTED ATTRIBUTE VISIBLITY //
    case EDIT_SELECTED_ATTRIBUTE_VISIBLITY: {
      const { path, value } = action.payload;

      return produce(state, (draft) => {
        setKeypathValue(draft, ["selectedAttributeVisiblity", ...path], value);
      });
    }

    case EDIT_VALUE_IN_FORMULA: {
      const { path, value } = action.payload;

      return produce(state, (draft) => {
        setKeypathValue(draft, ["activeProduct", "customFormula", "elements", ...path], value);
      });
    }

    // SET IS DUPLICATING //
    case SET_IS_DUPLICATING: {
      return {
        ...state,
        isDuplicating: action.payload,
      };
    }

    case SET_ERROR_IN_CUSTOM_FORMULA: {
      return {
        ...state,
        errorInCustomFormula: action.payload,
      };
    }

    case SET_IS_SAVING_CUSTOM_FORMULA: {
      return {
        ...state,
        isSavingCustomFormula: action.payload,
      };
    }

    case SET_IS_SAVING_PRODUCT: {
      return {
        ...state,
        isSavingProduct: action.payload,
      };
    }

    case SET_ACTIVE_PRODUCT_VARIANT: {
      return {
        ...state,
        activeProductVariant: action.payload,
      };
    }

    case SET_IS_FETCHING_PRODUCT: {
      return {
        ...state,
        isFetchingProduct: action.payload,
      };
    }

    // RESET ACTIVE PRODUCT //
    case RESET_ACTIVE_PRODUCT: {
      return initialState;
    }

    default:
      return state;
  }
};
