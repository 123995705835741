import { nanoid } from "nanoid";
import snakecaseKeys from "snakecase-keys";
import moment from "moment-timezone";

import * as date from "@/utils/date";
import { WIDGET_TYPE, STATUS } from "@/utils/constants";
import * as dashboardApi from "@/apiv2/dashboard";
import { getOrderStatus } from "@/apiv2/status";
import { getUsers } from "@/apiv2/customers";

import { handleError } from "../utils/error";

import {
  SET_IS_EDITTING,
  SET_CHARTS,
  SET_CHARTS_STATUS,
  SET_ADD_USER_WIDGET_STATUS,
  SET_USER_WIDGETS,
  SET_USER_WIDGETS_STATUS,
  SET_UPDATE_USER_WIDGET_STATUS,
  SET_UPDATE_BULK_USER_WIDGET_STATUS,
  SET_DELETE_USER_WIDGET_STATUS,
  SET_SALES_ORDERS,
  SET_PURCHASE_ORDERS,
  SET_OPEN_QUOTES,
  SET_ORDERS_BY_REQUIRED_DATE,
  SET_ORDERS_BY_STATUS,
  SET_ORDERS_CREATED_BY_ME,
  SET_TOP_CUSTOMERS,
  SET_DELIVERIES_SCHEDULED,
  SET_ORDERS_BY_TEAM_MEMBER,
  SET_ALL_ORDERS_WORKED_ON,
  SET_ORDERS_ACTIVELY_WORKED_ON,
  SET_TIME_WORKED_PER_TEAM_MEMBER,
  SET_CHARTS_REQUEST_PARAMS,
  SET_ORDER_STATUS,
  SET_USERS,
  SET_CHART_LOADING_STATUS,
  SET_EXPORT_TO_FILE_LOADING_STATUS,
  RESET,
} from "./dashboard.actionTypes";
import * as selectors from "./dashboard.selectors";

export const setIsEditting = (payload) => (dispatch) => {
  dispatch({
    type: SET_IS_EDITTING,
    payload,
  });
};

export const setCharts = (payload) => (dispatch) => {
  dispatch({
    type: SET_CHARTS,
    payload,
  });
};

export const setChartsStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_CHARTS_STATUS,
    payload,
  });
};

export const setAddUserWidgetStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_ADD_USER_WIDGET_STATUS,
    payload,
  });
};

export const setUserWidgets = (payload) => (dispatch) => {
  dispatch({
    type: SET_USER_WIDGETS,
    payload,
  });
};

export const setUserWidgetsStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_USER_WIDGETS_STATUS,
    payload,
  });
};

export const setUpdateUserWidgetStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_UPDATE_USER_WIDGET_STATUS,
    payload,
  });
};

export const setUpdateBulkUserWidgetStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_UPDATE_BULK_USER_WIDGET_STATUS,
    payload,
  });
};

export const setDeleteUserWidgetStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_DELETE_USER_WIDGET_STATUS,
    payload,
  });
};

export const setOrderStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_ORDER_STATUS,
    payload,
  });
};

export const setUsers = (payload) => (dispatch) => {
  dispatch({
    type: SET_USERS,
    payload,
  });
};

export const setChartLoadingStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_CHART_LOADING_STATUS,
    payload,
  });
};

export const setExportToFileLoadingStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_EXPORT_TO_FILE_LOADING_STATUS,
    payload,
  });
};

export const getCharts = () => async (dispatch) => {
  try {
    dispatch(setChartsStatus(STATUS.PENDING));
    const response = await dashboardApi.getChartsApi()();
    dispatch(setCharts(response.data));
    dispatch(setChartsStatus(STATUS.FULFILLED));
  } catch (error) {
    handleError(error, dispatch);
    dispatch(setChartsStatus(STATUS.REJECTED));
  }
};

export const postAddUserWidget = (payload) => async (dispatch, getState) => {
  const tempUid = nanoid();

  try {
    const state = getState();
    const userWidgets = selectors.userWidgets(state);
    const yCoordinates = Object.keys(userWidgets).map((u) => userWidgets[u].coordinates.y);

    const highestYCoordinates =
      yCoordinates.length > 0 ? Math.max(...yCoordinates) + 2 : payload.coordinates.y;

    dispatch(setAddUserWidgetStatus(STATUS.PENDING));

    const { data } = await dashboardApi.postAddUserWidgetApi({
      ...payload,
      coordinates: {
        ...payload.coordinates,
        y: highestYCoordinates,
      },
    })();
    const newWidgets = {
      ...data,
      tempUid,
      coordinates: {
        h: data.coordinates.h,
        w: data.coordinates.w,
        x: data.coordinates.x,
        y: data.coordinates.y,
        minW: data.coordinates?.minW ?? null,
        minH: data.coordinates?.minH ?? null,
        maxW: data.coordinates?.maxW ?? 4,
        maxH: data.coordinates?.maxH ?? 2,
      },
    };

    dispatch(setUserWidgets({ [tempUid]: newWidgets, ...userWidgets }));

    dispatch(setAddUserWidgetStatus(STATUS.PENDING));
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const getUserWidgets = () => async (dispatch) => {
  try {
    dispatch(setUserWidgetsStatus(STATUS.PENDING));
    const { data } = await dashboardApi.getUserWidgetsApi()();

    const widgets = data.map((d) => ({ ...d, tempUid: nanoid() }));

    dispatch(
      setUserWidgets(
        widgets.reduce(
          (obj, item) =>
            Object.assign(obj, {
              [item.tempUid]: {
                ...item,
                coordinates: {
                  h: item.coordinates.h,
                  w: item.coordinates.w,
                  x: item.coordinates.x,
                  y: item.coordinates.y,
                  minW: item.coordinates?.minW ?? null,
                  minH: item.coordinates?.minH ?? null,
                  maxW: item.coordinates?.maxW ?? 4,
                  maxH: item.coordinates?.maxH ?? 2,
                },
              },
            }),
          {}
        )
      )
    );

    dispatch(setUserWidgetsStatus(STATUS.FULFILLED));
  } catch (error) {
    handleError(error, dispatch);
    dispatch(setUserWidgetsStatus(STATUS.REJECTED));
  }
};

export const updateBulkUserWidget = (data) => async (dispatch, getState) => {
  try {
    const state = getState();
    const userWidgets = selectors.userWidgets(state);
    dispatch(setUpdateBulkUserWidgetStatus(STATUS.PENDING));

    const newCoordinatesInWidgets = Object.values(userWidgets).map((w) => {
      const layout = data.find((d) => d.uid === w.uid);

      if (layout) {
        return { ...w, coordinates: layout.coordinates };
      }

      return w;
    });

    dispatch(
      setUserWidgets(
        newCoordinatesInWidgets.reduce(
          (obj, item) => Object.assign(obj, { [item.tempUid]: item }),
          {}
        )
      )
    );

    await dashboardApi.patchBulkUserWidgetApi(data)();

    dispatch(setUpdateBulkUserWidgetStatus(STATUS.FULFILLED));
  } catch (error) {
    handleError(error, dispatch);
    dispatch(setUpdateBulkUserWidgetStatus(STATUS.REJECTED));
  }
};

export const deleteUserWidget = (tempUid) => async (dispatch, getState) => {
  try {
    const state = getState();
    const userWidgets = selectors.userWidgets(state);
    dispatch(setDeleteUserWidgetStatus(STATUS.PENDING));

    const { [tempUid]: _, ...existingUserWidgets } = userWidgets;
    dispatch(setUserWidgets(existingUserWidgets));

    await dashboardApi.deleteUserWidgetApi(userWidgets[tempUid].uid)();
    dispatch(setDeleteUserWidgetStatus(STATUS.FULFILLED));
  } catch (error) {
    handleError(error, dispatch);
    dispatch(setDeleteUserWidgetStatus(STATUS.REJECTED));
  }
};

export const duplicateWidget = (tempUid) => async (dispatch, getState) => {
  const newTempUid = nanoid();

  try {
    const state = getState();
    const userWidgets = selectors.userWidgets(state);
    const widget = userWidgets[tempUid];

    const { data } = await dashboardApi.postAddUserWidgetApi({
      chart: widget.chart,
      widget: widget.widget.code,
      coordinates: {
        ...widget.coordinates,
        h: 2,
        w: 4,
        x: widget.coordinates.x,
        y: widget.coordinates.y + 2,
      },
    })();

    const newWidgets = {
      ...data,
      tempUid: newTempUid,
      coordinates: {
        h: data.coordinates.h,
        w: data.coordinates.w,
        x: data.coordinates.x,
        y: data.coordinates.y,
        maxH: 2,
      },
    };
    dispatch(setUserWidgets({ [newTempUid]: newWidgets, ...userWidgets }));

    dispatch(setChartLoadingStatus({ [newTempUid]: STATUS.FULFILLED }));
  } catch (error) {
    handleError(error, dispatch);
    dispatch(setChartLoadingStatus({ [newTempUid]: STATUS.REJECTED }));
  }
};

/** Chart data actions */

export const getDataOfCharts =
  ({ data, params = {} } = {}) =>
  (dispatch) => {};

export const setSalesOrders = (payload) => (dispatch) => {
  dispatch({
    type: SET_SALES_ORDERS,
    payload,
  });
};

export const getSalesOrders =
  ({ data, params = {} } = {}) =>
  async (dispatch) => {
    console.log("params", params);
    try {
      dispatch(setChartLoadingStatus({ [data.tempUid]: STATUS.PENDING }));
      const response = await dashboardApi.getUserWidgetChartsDataApi(
        WIDGET_TYPE.SALES_ORDERS,
        snakecaseKeys({
          ...params,
          dateFrom: moment
            .tz(`${params.dateFrom}T00:00:00`, date.getCurrentUserTimezone())
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss[Z]"),
          dateTo: moment
            .tz(`${params.dateTo}T23:59:59`, date.getCurrentUserTimezone())
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss[Z]"),
        })
      )();
      dispatch(setSalesOrders(response.data));
      dispatch(setChartLoadingStatus({ [data.tempUid]: STATUS.FULFILLED }));
    } catch (error) {
      handleError(error, dispatch);
      dispatch(setChartLoadingStatus({ [data.tempUid]: STATUS.REJECTED }));
    }
  };

export const setOrdersByRequiredDate = (payload) => (dispatch) => {
  dispatch({
    type: SET_ORDERS_BY_REQUIRED_DATE,
    payload,
  });
};

export const getOrdersByRequiredDate =
  ({ data, params = {} } = {}) =>
  async (dispatch) => {
    try {
      dispatch(setChartLoadingStatus({ [data.tempUid]: STATUS.PENDING }));

      const response = await dashboardApi.getUserWidgetChartsDataApi(
        WIDGET_TYPE.ORDERS_BY_REQUIRED_DATE,
        snakecaseKeys({
          ...params,
          dateFrom: params.date_from,
          dateTo: params.date_to,
        })
      )();
      dispatch(setOrdersByRequiredDate(response.data));
      dispatch(setChartLoadingStatus({ [data.tempUid]: STATUS.FULFILLED }));
    } catch (error) {
      handleError(error, dispatch);
      dispatch(setChartLoadingStatus({ [data.tempUid]: STATUS.REJECTED }));
    }
  };

export const setOrdersByStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_ORDERS_BY_STATUS,
    payload,
  });
};

export const getOrdersByStatus =
  ({ data, params = {} } = {}) =>
  async (dispatch) => {
    try {
      dispatch(setChartLoadingStatus({ [data.tempUid]: STATUS.PENDING }));
      const response = await dashboardApi.getUserWidgetChartsDataApi(
        WIDGET_TYPE.ORDERS_BY_STATUS,
        snakecaseKeys({
          ...params,
          dateFrom: moment
            .tz(`${params.dateFrom}T00:00:00`, date.getCurrentUserTimezone())
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss[Z]"),
          dateTo: moment
            .tz(`${params.dateTo}T23:59:59`, date.getCurrentUserTimezone())
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss[Z]"),
        })
      )();
      dispatch(setOrdersByStatus(response.data));
      dispatch(setChartLoadingStatus({ [data.tempUid]: STATUS.FULFILLED }));
    } catch (error) {
      handleError(error, dispatch);
      dispatch(setChartLoadingStatus({ [data.tempUid]: STATUS.REJECTED }));
    }
  };

export const setOrdersCreatedByMe = (payload) => (dispatch) => {
  dispatch({
    type: SET_ORDERS_CREATED_BY_ME,
    payload,
  });
};

export const getOrdersCreatedByMe =
  ({ data, params = {} } = {}) =>
  async (dispatch) => {
    try {
      dispatch(setChartLoadingStatus({ [data.tempUid]: STATUS.PENDING }));
      const response = await dashboardApi.getUserWidgetChartsDataApi(
        WIDGET_TYPE.ORDERS_CREATED_BY_ME,
        snakecaseKeys({
          ...params,
          dateFrom: moment
            .tz(`${params.dateFrom}T00:00:00`, date.getCurrentUserTimezone())
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss[Z]"),
          dateTo: moment
            .tz(`${params.dateTo}T23:59:59`, date.getCurrentUserTimezone())
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss[Z]"),
        })
      )();
      dispatch(setOrdersCreatedByMe(response.data));
      dispatch(setChartLoadingStatus({ [data.tempUid]: STATUS.FULFILLED }));
    } catch (error) {
      handleError(error, dispatch);
      dispatch(setChartLoadingStatus({ [data.tempUid]: STATUS.REJECTED }));
    }
  };

export const setPurchaseOrders = (payload) => (dispatch) => {
  dispatch({
    type: SET_PURCHASE_ORDERS,
    payload,
  });
};

export const getPurchaseOrders =
  ({ data, params = {} } = {}) =>
  async (dispatch) => {
    try {
      dispatch(setChartLoadingStatus({ [data.tempUid]: STATUS.PENDING }));
      const response = await dashboardApi.getUserWidgetChartsDataApi(
        WIDGET_TYPE.PURCHASE_ORDERS,
        snakecaseKeys({
          ...params,
          dateFrom: moment
            .tz(`${params.dateFrom}T00:00:00`, date.getCurrentUserTimezone())
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss[Z]"),
          dateTo: moment
            .tz(`${params.dateTo}T23:59:59`, date.getCurrentUserTimezone())
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss[Z]"),
        })
      )();
      dispatch(setPurchaseOrders(response.data));
      dispatch(setChartLoadingStatus({ [data.tempUid]: STATUS.FULFILLED }));
    } catch (error) {
      handleError(error, dispatch);
      dispatch(setChartLoadingStatus({ [data.tempUid]: STATUS.REJECTED }));
    }
  };

export const setOpenQuotes = (payload) => (dispatch) => {
  dispatch({
    type: SET_OPEN_QUOTES,
    payload,
  });
};

export const getOpenQuotes =
  ({ data, params = {} } = {}) =>
  async (dispatch) => {
    try {
      dispatch(setChartLoadingStatus({ [data.tempUid]: STATUS.PENDING }));
      const response = await dashboardApi.getUserWidgetChartsDataApi(
        WIDGET_TYPE.OPEN_QUOTES,
        snakecaseKeys({
          ...params,
          dateFrom: moment
            .tz(`${params.dateFrom}T00:00:00`, date.getCurrentUserTimezone())
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss[Z]"),
          dateTo: moment
            .tz(`${params.dateTo}T23:59:59`, date.getCurrentUserTimezone())
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss[Z]"),
        })
      )();
      dispatch(setOpenQuotes(response.data));
      dispatch(setChartLoadingStatus({ [data.tempUid]: STATUS.FULFILLED }));
    } catch (error) {
      handleError(error, dispatch);
      dispatch(setChartLoadingStatus({ [data.tempUid]: STATUS.REJECTED }));
    }
  };

export const setTopCustomers = (payload) => (dispatch) => {
  dispatch({
    type: SET_TOP_CUSTOMERS,
    payload,
  });
};

export const getTopCustomers =
  ({ data, params = {} } = {}) =>
  async (dispatch) => {
    try {
      dispatch(setChartLoadingStatus({ [data.tempUid]: STATUS.PENDING }));
      const response = await dashboardApi.getUserWidgetChartsDataApi(
        WIDGET_TYPE.TOP_CUSTOMERS,
        snakecaseKeys({
          ...params,
          dateFrom: moment
            .tz(`${params.dateFrom}T00:00:00`, date.getCurrentUserTimezone())
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss[Z]"),
          dateTo: moment
            .tz(`${params.dateTo}T23:59:59`, date.getCurrentUserTimezone())
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss[Z]"),
        })
      )();
      dispatch(setTopCustomers(response.data));
      dispatch(setChartLoadingStatus({ [data.tempUid]: STATUS.FULFILLED }));
    } catch (error) {
      handleError(error, dispatch);
      dispatch(setChartLoadingStatus({ [data.tempUid]: STATUS.REJECTED }));
    }
  };

export const setDeliveriesScheduled = (payload) => (dispatch) => {
  dispatch({
    type: SET_DELIVERIES_SCHEDULED,
    payload,
  });
};

export const getDeliveriesScheduled =
  ({ data, params = {} } = {}) =>
  async (dispatch) => {
    try {
      dispatch(setChartLoadingStatus({ [data.tempUid]: STATUS.PENDING }));
      const response = await dashboardApi.getUserWidgetChartsDataApi(
        WIDGET_TYPE.DELIVERIES_SCHEDULED,
        snakecaseKeys({
          ...params,
          dateFrom: params.date_from,
          dateTo: params.date_to,
        })
      )();
      dispatch(setDeliveriesScheduled(response.data));
      dispatch(setChartLoadingStatus({ [data.tempUid]: STATUS.FULFILLED }));
    } catch (error) {
      handleError(error, dispatch);
      dispatch(setChartLoadingStatus({ [data.tempUid]: STATUS.REJECTED }));
    }
  };

export const setOrdersByTeamMember = (payload) => (dispatch) => {
  dispatch({
    type: SET_ORDERS_BY_TEAM_MEMBER,
    payload,
  });
};

export const getOrdersByTeamMember =
  ({ data, params = {} } = {}) =>
  async (dispatch) => {
    try {
      dispatch(setChartLoadingStatus({ [data.tempUid]: STATUS.PENDING }));
      const response = await dashboardApi.getUserWidgetChartsDataApi(
        WIDGET_TYPE.ORDERS_BY_TEAM_MEMBER,
        snakecaseKeys({
          ...params,
          dateFrom: moment
            .tz(`${params.dateFrom}T00:00:00`, date.getCurrentUserTimezone())
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss[Z]"),
          dateTo: moment
            .tz(`${params.dateTo}T23:59:59`, date.getCurrentUserTimezone())
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss[Z]"),
        })
      )();
      dispatch(setOrdersByTeamMember(response.data));
      dispatch(setChartLoadingStatus({ [data.tempUid]: STATUS.FULFILLED }));
    } catch (error) {
      handleError(error, dispatch);
      dispatch(setChartLoadingStatus({ [data.tempUid]: STATUS.REJECTED }));
    }
  };

export const setOrdersActivelyWorkedOn = (payload) => (dispatch) => {
  dispatch({
    type: SET_ORDERS_ACTIVELY_WORKED_ON,
    payload,
  });
};

export const getOrdersActivelyWorkedOn =
  ({ data, params = {} } = {}) =>
  async (dispatch) => {
    try {
      dispatch(setChartLoadingStatus({ [data.tempUid]: STATUS.PENDING }));
      const response = await dashboardApi.getUserWidgetChartsDataApiInDb(
        WIDGET_TYPE.ORDERS_ACTIVELY_WORKED_ON,
        snakecaseKeys({
          ...params,
          dateFrom: moment
            .tz(`${params.dateFrom}T00:00:00`, date.getCurrentUserTimezone())
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss[Z]"),
          dateTo: moment
            .tz(`${params.dateTo}T23:59:59`, date.getCurrentUserTimezone())
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss[Z]"),
        })
      )();
      dispatch(setOrdersActivelyWorkedOn(response.data));
      dispatch(setChartLoadingStatus({ [data.tempUid]: STATUS.FULFILLED }));
    } catch (error) {
      handleError(error, dispatch);
      dispatch(setChartLoadingStatus({ [data.tempUid]: STATUS.REJECTED }));
    }
  };

export const setTimeWorkedPerTeamMember = (payload) => (dispatch) => {
  dispatch({
    type: SET_TIME_WORKED_PER_TEAM_MEMBER,
    payload,
  });
};

export const getTimeWorkedPerTeamMember =
  ({ data, params = {} } = {}) =>
  async (dispatch) => {
    try {
      dispatch(setChartLoadingStatus({ [data.tempUid]: STATUS.PENDING }));
      const response = await dashboardApi.getUserWidgetChartsDataApiInDb(
        WIDGET_TYPE.TIME_WORKED_PER_TEAM_MEMBER,
        snakecaseKeys({
          ...params,
          dateFrom: moment
            .tz(`${params.dateFrom}T00:00:00`, date.getCurrentUserTimezone())
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss[Z]"),
          dateTo: moment
            .tz(`${params.dateTo}T23:59:59`, date.getCurrentUserTimezone())
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss[Z]"),
        })
      )();
      dispatch(setTimeWorkedPerTeamMember(response.data));
      dispatch(setChartLoadingStatus({ [data.tempUid]: STATUS.FULFILLED }));
    } catch (error) {
      handleError(error, dispatch);
      dispatch(setChartLoadingStatus({ [data.tempUid]: STATUS.REJECTED }));
    }
  };

export const setAllOrdersWorkedOn = (payload) => (dispatch) => {
  dispatch({
    type: SET_ALL_ORDERS_WORKED_ON,
    payload,
  });
};

export const getAllOrdersWorkedOn =
  ({ data, params = {} } = {}) =>
  async (dispatch) => {
    try {
      dispatch(setChartLoadingStatus({ [data.tempUid]: STATUS.PENDING }));
      const response = await dashboardApi.getUserWidgetChartsDataApiInDb(
        WIDGET_TYPE.ALL_ORDERS_WORKED_ON,
        snakecaseKeys({
          ...params,
          dateFrom: moment
            .tz(`${params.dateFrom}T00:00:00`, date.getCurrentUserTimezone())
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss[Z]"),
          dateTo: moment
            .tz(`${params.dateTo}T23:59:59`, date.getCurrentUserTimezone())
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss[Z]"),
        })
      )();
      dispatch(setAllOrdersWorkedOn(response.data));
      dispatch(setChartLoadingStatus({ [data.tempUid]: STATUS.FULFILLED }));
    } catch (error) {
      handleError(error, dispatch);
      dispatch(setChartLoadingStatus({ [data.tempUid]: STATUS.REJECTED }));
    }
  };

export const setChartsRequestParams = (payload) => (dispatch) => {
  dispatch({
    type: SET_CHARTS_REQUEST_PARAMS,
    payload,
  });
};

export const fetchOrderStatus = () => async (dispatch) => {
  try {
    const { data } = await getOrderStatus()();
    dispatch(setOrderStatus(data));
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const fetchUsers = () => async (dispatch) => {
  try {
    const { data } = await getUsers()();
    dispatch(setUsers(data.filter((d) => !d.isPlannerAssigneedOnly)));
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const fetchOrder =
  ({ orderUid, customerUid }) =>
  () =>
    dashboardApi.getOrder({ orderUid, customerUid })();

export const reset = () => (dispatch) => {
  dispatch({ type: RESET });
};

export const exportToFile =
  ({ tempUid, widgetCode, format = "xlsx", filters = {} } = {}) =>
  async (dispatch, getState) => {
    const state = getState();

    try {
      dispatch(setExportToFileLoadingStatus({ [tempUid]: STATUS.PENDING }));
      const filterParams = selectors.chartsRequestParams(state);
      const { data } = await dashboardApi.getUserWidgetChartsDataApi(
        widgetCode,
        snakecaseKeys({
          ...filterParams,
          dateFrom: moment
            .tz(`${params.dateFrom}T00:00:00`, date.getCurrentUserTimezone())
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss[Z]"),
          dateTo: moment
            .tz(`${params.dateTo}T23:59:59`, date.getCurrentUserTimezone())
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss[Z]"),
          ...filters,
          export_as: format,
        })
      )();
      dispatch(setExportToFileLoadingStatus({ [tempUid]: STATUS.FULFILLED }));
      return data;
    } catch (error) {
      dispatch(setChartLoadingStatus({ [tempUid]: STATUS.REJECTED }));
      handleError(error, dispatch);
    }
  };
