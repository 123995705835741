import {
  SET_IS_FETCHING_SETTINGS,
  SET_SETTINGS,
  SET_IS_DELIVERY_SETTINGS_VISIBLE,
  SET_IS_DELETE_VEHICLE_MODAL_VISIBLE,
  SET_IS_DELIVERY_RUNSHEET_VISIBLE,
  SET_IS_ASSIGN_USER_MENU_VISIBLE,
  SET_IS_ASSIGN_LABEL_MENU_VISIBLE,
  SET_ASSIGN_USER_DROPDOWN_COORDINATES,
  SET_ASSIGN_LABEL_DROPDOWN_COORDINATES,
  SET_ACTIVE_ORDER,
  SET_IS_ORDER_MODAL_VISIBLE,
  SET_SELECTED_RESOURCES,
  SET_SELECTED_VEHICLE,
  SET_IS_ORDER_COLUMN_COLLAPSE,
  RESET,
} from "./deliveryScheduling.actionTypes";

const initialState = {
  isFetchingSettings: false,
  isDeliverySettingsVisible: false,
  isDeleteVehicleModalVisible: false,
  isDeliveryRunSheetVisible: false,
  isOrderModalVisible: false,
  isOrderColumnCollapse: false,
  settings: null,
  activeOrder: null,
  selectedResources: [],
  selectedVehicle: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_FETCHING_SETTINGS: {
      return {
        ...state,
        isFetchingSettings: action.payload,
      };
    }

    case SET_SETTINGS: {
      return {
        ...state,
        settings: action.payload,
      };
    }

    case SET_IS_DELIVERY_SETTINGS_VISIBLE: {
      return {
        ...state,
        isDeliverySettingsVisible: action.payload,
      };
    }

    case SET_IS_DELETE_VEHICLE_MODAL_VISIBLE: {
      return {
        ...state,
        isDeleteVehicleModalVisible: action.payload,
      };
    }

    case SET_IS_DELIVERY_RUNSHEET_VISIBLE: {
      return {
        ...state,
        isDeliveryRunSheetVisible: action.payload,
      };
    }

    case SET_IS_ASSIGN_USER_MENU_VISIBLE: {
      return {
        ...state,
        isAssignUserMenuVisible: action.payload,
      };
    }

    case SET_IS_ASSIGN_LABEL_MENU_VISIBLE: {
      return {
        ...state,
        isAssignLabelMenuVisible: action.payload,
      };
    }

    case SET_ASSIGN_USER_DROPDOWN_COORDINATES: {
      return {
        ...state,
        assignUserDropdownCoordinates: action.payload,
      };
    }

    case SET_ASSIGN_LABEL_DROPDOWN_COORDINATES: {
      return {
        ...state,
        assignLabelDropdownCoordinates: action.payload,
      };
    }

    case SET_ACTIVE_ORDER: {
      return {
        ...state,
        activeOrder: action.payload,
      };
    }

    case SET_IS_ORDER_MODAL_VISIBLE: {
      return {
        ...state,
        isOrderModalVisible: action.payload,
      };
    }

    case SET_SELECTED_RESOURCES: {
      return {
        ...state,
        selectedResources: action.payload,
      };
    }

    case SET_SELECTED_VEHICLE: {
      return {
        ...state,
        selectedVehicle: action.payload,
      };
    }

    case SET_IS_ORDER_COLUMN_COLLAPSE: {
      return {
        ...state,
        isOrderColumnCollapse: action.payload,
      };
    }

    case RESET: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};
