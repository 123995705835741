import { createApi } from "../api/common";

import { createApiV2 } from "./common";

export const getAllSupplierProducts = (supplierUid, search) => (token) =>
  createApi("GET", `purchasing/supplier_products/?supplier=${supplierUid}&search=${search}`)(token);

export const deleteSupplierProduct = (supplierProductUid) => (token) =>
  createApi("DELETE", `purchasing/supplier_products/${supplierProductUid}/`)(token);

export const getAllSupplierProductsV2 = (supplierUid, search) => (token) =>
  createApiV2(
    "GET",
    `purchasing/supplier_products/?supplier=${supplierUid}&search=${search}`
  )(token);
