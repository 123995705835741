import {
  SET_IS_FETCHING_ORDERS,
  SET_ORDERS,
  SET_OFFSET,
  SET_TOTAL,
  RESET,
} from "./orders.actionTypes";

const initialState = {
  isFetchingOrders: false,
  orders: [],
  pagination: {
    offset: 0,
    total: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_FETCHING_ORDERS: {
      return {
        ...state,
        isFetchingOrders: action.payload,
      };
    }

    case SET_ORDERS: {
      return {
        ...state,
        orders: action.payload,
      };
    }

    case SET_OFFSET: {
      return {
        ...state,
        pagination: { ...state.pagination, offset: action.payload },
      };
    }

    case SET_TOTAL: {
      return {
        ...state,
        pagination: { ...state.pagination, total: action.payload },
      };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
