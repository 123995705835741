/* eslint-disable no-param-reassign */
import produce from "immer";

import setKeypathValue from "../utils/setKeypathValue";

import {
  SET_IS_ADD_CHECKLIST_FORM_VISIBLE,
  SET_IS_ADD_SUB_ITEM_FORM_VISIBLE,
  SET_PARENT_ITEM_TO_DISPLAY_THE_FORM,
  SET_IS_FETCHING_CHECKLISTS,
  SET_CHECKLISTS,
  SET_CHECKLIST_ITEM,
  SET_CLOSE_CHECKLIST_UIDS,
  INDENT_SUB_ITEM,
  INDENT_BACKWARDS_SUB_ITEM,
  UPDATE_SUB_ITEM_FIELD_VALUE,
  APPEND_SUB_ITEM,
  REMOVE_SUB_ITEM,
  MARK_ITEM_AS_COMPLETED,
  MARK_ITEM_AS_NOT_COMPLETED,
  SET_CHECKLIST_ITEM_UPLOADING,
  SET_ALL_CHECKLISTS_ATTACHMENTS,
  RESET,
} from "./orderChecklists.actionTypes";

const initialState = {
  isAddChecklistFormVisible: false,
  isAddSubItemFormVisible: false,
  isFetchingChecklists: false,
  checklistItemUploading: null,
  parentItemToDisplayTheForm: null,
  checklists: [],
  attachments: [],
  closeChecklistUids: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_FETCHING_CHECKLISTS: {
      return {
        ...state,
        isFetchingChecklists: action.payload,
      };
    }

    case SET_IS_ADD_CHECKLIST_FORM_VISIBLE: {
      return {
        ...state,
        isAddChecklistFormVisible: action.payload,
      };
    }

    case SET_IS_ADD_SUB_ITEM_FORM_VISIBLE: {
      return {
        ...state,
        isAddSubItemFormVisible: action.payload,
      };
    }

    case SET_PARENT_ITEM_TO_DISPLAY_THE_FORM: {
      return {
        ...state,
        parentItemToDisplayTheForm: action.payload,
      };
    }

    case SET_CHECKLISTS: {
      return {
        ...state,
        checklists: action.payload,
      };
    }

    case SET_CHECKLIST_ITEM: {
      return {
        ...state,
        checklists: action.payload,
      };
    }

    case SET_CLOSE_CHECKLIST_UIDS: {
      return {
        ...state,
        closeChecklistUids: action.payload,
      };
    }

    case UPDATE_SUB_ITEM_FIELD_VALUE: {
      const { path, value } = action.payload;

      return produce(state, (draft) => {
        setKeypathValue(draft, path, value);
      });
    }

    case SET_ALL_CHECKLISTS_ATTACHMENTS: {
      return {
        ...state,
        attachments: action.payload,
      };
    }

    case SET_CHECKLIST_ITEM_UPLOADING: {
      return {
        ...state,
        checklistItemUploading: action.payload,
      };
    }

    case INDENT_SUB_ITEM: {
      const { checklistIndex, firstLevelIndex, secondLevelIndex, data, level, currentUser } =
        action.payload;

      // Only level 1 and level 2 can be indent
      // since that is the max level of sub item
      if (level === 1 && firstLevelIndex !== 0) {
        const parentItem = state.checklists[checklistIndex].items[firstLevelIndex - 1];

        return produce(state, (draftState) => {
          draftState.checklists[checklistIndex].items[firstLevelIndex - 1].subitems = [
            ...parentItem.subitems,
            { ...data, parentUid: parentItem.uid, subitems: [] },
            ...data.subitems.map((s) => ({ ...s, parentUid: parentItem.uid })),
          ];

          draftState.checklists[checklistIndex].items.splice(firstLevelIndex, 1);

          // Apply new order index of the first level items
          draftState.checklists[checklistIndex].items = draftState.checklists[
            checklistIndex
          ].items.map((i, index) => ({
            ...i,
            index: index + 1,
          }));

          // Apply new order index of the second level items
          draftState.checklists[checklistIndex].items[firstLevelIndex - 1].subitems =
            draftState.checklists[checklistIndex].items[firstLevelIndex - 1].subitems.map(
              (i, index) => ({
                ...i,
                index: index + 1,
              })
            );

          const previousItem = draftState.checklists[checklistIndex].items[firstLevelIndex - 1];
          const completedItems = previousItem.subitems.filter((i) => i.completedAt !== null);
          const notCompletedItems = previousItem.subitems.filter((i) => i.completedAt === null);

          if (previousItem.subitems.length === completedItems.length) {
            draftState.checklists[checklistIndex].items[firstLevelIndex - 1].completedAt =
              new Date();

            draftState.checklists[checklistIndex].items[firstLevelIndex - 1].completedBy =
              currentUser;
          }

          if (notCompletedItems.length > 0) {
            draftState.checklists[checklistIndex].items[firstLevelIndex - 1].completedAt = null;
            draftState.checklists[checklistIndex].items[firstLevelIndex - 1].completedBy = null;
          }
        });
      } else if (level === 2 && secondLevelIndex !== 0) {
        const parentItem =
          state.checklists[checklistIndex].items[firstLevelIndex].subitems[secondLevelIndex - 1];

        return produce(state, (draftState) => {
          draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
            secondLevelIndex - 1
          ].subitems = [
            ...parentItem.subitems,
            { ...data, parentUid: parentItem.uid, subitems: [] },
            ...data.subitems.map((s) => ({ ...s, parentUid: parentItem.uid })),
          ];

          draftState.checklists[checklistIndex].items[firstLevelIndex].subitems.splice(
            secondLevelIndex,
            1
          );

          // Apply new order index of the second level items
          draftState.checklists[checklistIndex].items[firstLevelIndex].subitems =
            draftState.checklists[checklistIndex].items[firstLevelIndex].subitems.map(
              (i, index) => ({
                ...i,
                index: index + 1,
              })
            );

          // Apply new order index of the third level items
          draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
            secondLevelIndex - 1
          ].subitems = draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
            secondLevelIndex - 1
          ].subitems.map((i, index) => ({
            ...i,
            index: index + 1,
          }));

          const previousItem =
            draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
              secondLevelIndex - 1
            ];
          const completedItems = previousItem.subitems.filter((i) => i.completedAt !== null);
          const notCompletedItems = previousItem.subitems.filter((i) => i.completedAt === null);

          if (previousItem.subitems.length === completedItems.length) {
            draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
              secondLevelIndex - 1
            ].completedAt = new Date();

            draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
              secondLevelIndex - 1
            ].completedBy = currentUser;
          }

          if (notCompletedItems.length > 0) {
            draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
              secondLevelIndex - 1
            ].completedAt = null;

            draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
              secondLevelIndex - 1
            ].completedBy = null;
          }
        });
      }

      return state;
    }

    case INDENT_BACKWARDS_SUB_ITEM: {
      const {
        checklistIndex,
        firstLevelIndex,
        secondLevelIndex,
        thirdLevelIndex,
        data,
        level,
        currentUser,
      } = action.payload;

      if (level === 2) {
        return produce(state, (draftState) => {
          // Delete the item to the current level
          draftState.checklists[checklistIndex].items[firstLevelIndex].subitems.splice(
            secondLevelIndex,
            1
          );

          // Append the item to the previous level
          draftState.checklists[checklistIndex].items.splice(firstLevelIndex + 1, 0, {
            ...data,
            parentUid: null,
          });

          // Apply new order index of the first level items
          draftState.checklists[checklistIndex].items = draftState.checklists[
            checklistIndex
          ].items.map((i, index) => ({
            ...i,
            index: index + 1,
          }));

          // Apply new order index of the sub items of the second level item
          draftState.checklists[checklistIndex].items[firstLevelIndex + 1].subitems =
            draftState.checklists[checklistIndex].items[firstLevelIndex + 1].subitems.map(
              (i, index) => ({
                ...i,
                index: index + 1,
              })
            );

          // const subItemsOfParentItem =
          //   draftState.checklists[checklistIndex].items[firstLevelIndex].subitems;
          // const notCompletedItems = subItemsOfParentItem.filter((i) => i.completedAt === null);

          // if (notCompletedItems.length === 0) {
          //   draftState.checklists[checklistIndex].items[firstLevelIndex].completedAt = new Date();
          //   draftState.checklists[checklistIndex].items[firstLevelIndex].completedBy = currentUser;
          // } else {
          //   draftState.checklists[checklistIndex].items[firstLevelIndex].completedAt = null;
          //   draftState.checklists[checklistIndex].items[firstLevelIndex].completedBy = null;
          // }

          // Apply new order index of the sub items of the third level item
          // draftState.checklists[checklistIndex].items[firstLevelIndex + 1].subitems[
          //   secondLevelIndex
          // ].subitems = draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
          //   secondLevelIndex - 1
          // ].subitems.map((i, index) => ({
          //   ...i,
          //   index: index + 1,
          // }));
        });
      } else if (level === 3) {
        const parentItem = state.checklists[checklistIndex].items[firstLevelIndex];
        return produce(state, (draftState) => {
          // Delete the item to the current level
          draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
            secondLevelIndex
          ].subitems.splice(thirdLevelIndex, 1);

          // Append the sub items to the previous level
          draftState.checklists[checklistIndex].items[firstLevelIndex].subitems.splice(
            secondLevelIndex + 1,
            0,
            ...[{ ...data, parentUid: parentItem.uid }]
          );

          // Apply new order index of the second level items
          draftState.checklists[checklistIndex].items[firstLevelIndex].subitems =
            draftState.checklists[checklistIndex].items[firstLevelIndex].subitems.map(
              (i, index) => ({
                ...i,
                index: index + 1,
              })
            );

          // Apply new order index of the third level items
          draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
            secondLevelIndex
          ].subitems = draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
            secondLevelIndex
          ].subitems.map((i, index) => ({
            ...i,
            index: index + 1,
          }));

          const subItemsOfParentItem =
            draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[secondLevelIndex]
              .subitems;

          if (subItemsOfParentItem.length > 0) {
            const notCompletedItems = subItemsOfParentItem.filter((i) => i.completedAt === null);

            if (notCompletedItems.length === 0) {
              draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
                secondLevelIndex
              ].completedAt = new Date();

              draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
                secondLevelIndex
              ].completedBy = currentUser;
            }
          }
        });
      }

      return state;
    }

    case APPEND_SUB_ITEM: {
      const { checklistIndex, firstLevelIndex, secondLevelIndex, level, data } = action.payload;

      if (level === 1) {
        return produce(state, (draftState) => {
          draftState.checklists[checklistIndex].items.push(data);
        });
      } else if (level === 2) {
        return produce(state, (draftState) => {
          draftState.checklists[checklistIndex].items[firstLevelIndex].completedAt = null;
          draftState.checklists[checklistIndex].items[firstLevelIndex].completedBy = null;
          draftState.checklists[checklistIndex].items[firstLevelIndex].subitems.push(data);
        });
      } else if (level === 3) {
        return produce(state, (draftState) => {
          draftState.checklists[checklistIndex].items[firstLevelIndex].completedAt = null;
          draftState.checklists[checklistIndex].items[firstLevelIndex].completedBy = null;

          draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
            secondLevelIndex
          ].completedAt = null;

          draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
            secondLevelIndex
          ].completedBy = null;

          draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
            secondLevelIndex
          ].subitems.push(data);
        });
      }

      return state;
    }

    case REMOVE_SUB_ITEM: {
      const {
        checklistIndex,
        firstLevelIndex,
        secondLevelIndex,
        thirdLevelIndex,
        item,
        currentUser,
        level,
      } = action.payload;

      if (level === 1) {
        return produce(state, (draftState) => {
          draftState.checklists[checklistIndex].items.splice(firstLevelIndex, 1);

          // Append the sub items to the previous level
          draftState.checklists[checklistIndex].items.splice(firstLevelIndex, 0, ...item.subitems);

          // Apply new order index of the 1st level items
          draftState.checklists[checklistIndex].items = draftState.checklists[
            checklistIndex
          ].items.map((i, index) => ({
            ...i,
            parentUid: null,
            index: index + 1,
          }));
        });
      } else if (level === 2) {
        return produce(state, (draftState) => {
          draftState.checklists[checklistIndex].items[firstLevelIndex].subitems.splice(
            secondLevelIndex,
            1
          );

          // Append the sub items to the previous level
          draftState.checklists[checklistIndex].items[firstLevelIndex].subitems.splice(
            secondLevelIndex,
            0,
            ...item.subitems
          );

          // Apply new order index of the second level items
          draftState.checklists[checklistIndex].items[firstLevelIndex].subitems =
            draftState.checklists[checklistIndex].items[firstLevelIndex].subitems.map(
              (i, index) => ({
                ...i,
                parentUid: item.parentUid,
                index: index + 1,
              })
            );

          const siblingItems =
            draftState.checklists[checklistIndex].items[firstLevelIndex].subitems;

          // Mark First Level Items as not completed
          // if there are remaining sub items that are completed
          if (siblingItems.filter((i) => i.completedAt === null).length > 0) {
            draftState.checklists[checklistIndex].items[firstLevelIndex].completedAt = null;
            draftState.checklists[checklistIndex].items[firstLevelIndex].completedBy = null;
          }

          // Mark First Level Items as completed if all the remaining sub items are completed
          if (siblingItems.filter((i) => i.completedAt === null).length === 0) {
            draftState.checklists[checklistIndex].items[firstLevelIndex].completedAt = new Date();
            draftState.checklists[checklistIndex].items[firstLevelIndex].completedBy = currentUser;
          }
        });
      } else if (level === 3) {
        return produce(state, (draftState) => {
          draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
            secondLevelIndex
          ].subitems.splice(thirdLevelIndex, 1);

          // Apply new order index of the second level items
          draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
            secondLevelIndex
          ].subitems = draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
            secondLevelIndex
          ].subitems.map((i, index) => ({
            ...i,
            index: index + 1,
          }));

          const siblingItems =
            draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[secondLevelIndex]
              .subitems;

          if (siblingItems.filter((i) => i.completedAt === null).length > 0) {
            // First Level Items
            draftState.checklists[checklistIndex].items[firstLevelIndex].completedAt = null;
            draftState.checklists[checklistIndex].items[firstLevelIndex].completedBy = null;

            // Second Level Items
            draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
              secondLevelIndex
            ].completedAt = null;

            draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
              secondLevelIndex
            ].completedBy = null;
          }

          if (siblingItems.filter((i) => i.completedAt === null).length === 0) {
            // First Level Items
            draftState.checklists[checklistIndex].items[firstLevelIndex].completedAt = new Date();
            draftState.checklists[checklistIndex].items[firstLevelIndex].completedBy = currentUser;

            // Second Level Items
            draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
              secondLevelIndex
            ].completedAt = new Date();

            draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
              secondLevelIndex
            ].completedBy = currentUser;
          }
        });
      }

      return state;
    }

    case MARK_ITEM_AS_COMPLETED: {
      const {
        checklistIndex,
        firstLevelIndex,
        secondLevelIndex,
        thirdLevelIndex,
        item,
        level,
        currentUser,
      } = action.payload;
      return produce(state, (draftState) => {
        if (level === 1) {
          // Mark the first level as completed
          const path = [checklistIndex, "items", firstLevelIndex];
          setKeypathValue(draftState.checklists, [...path, "completedAt"], new Date());
          setKeypathValue(draftState.checklists, [...path, "completedBy"], currentUser);

          draftState.checklists[checklistIndex].items[firstLevelIndex].subitems
            .filter((i) => i.completedAt === null)
            .forEach((i) => {
              const itemIndex = draftState.checklists[checklistIndex].items[
                firstLevelIndex
              ].subitems.findIndex((s) => s.uid === i.uid);

              if (itemIndex >= 0) {
                draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
                  itemIndex
                ].completedAt = new Date();

                draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
                  itemIndex
                ].completedBy = currentUser;

                draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
                  itemIndex
                ].subitems
                  .filter((si) => si.completedAt === null)
                  .forEach((si) => {
                    const subItemIndex = draftState.checklists[checklistIndex].items[
                      firstLevelIndex
                    ].subitems[itemIndex].subitems.findIndex(({ uid }) => uid === si.uid);

                    if (subItemIndex >= 0) {
                      draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
                        itemIndex
                      ].subitems[subItemIndex].completedAt = new Date();

                      draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
                        itemIndex
                      ].subitems[subItemIndex].completedBy = currentUser;
                    }
                  });
              }
            });
        } else if (level === 2) {
          // Mark the second level as completed
          const path = [checklistIndex, "items", firstLevelIndex, "subitems", secondLevelIndex];
          setKeypathValue(draftState.checklists, [...path, "completedAt"], new Date());
          setKeypathValue(draftState.checklists, [...path, "completedBy"], currentUser);

          const siblingItems = draftState.checklists[checklistIndex].items[
            firstLevelIndex
          ].subitems.filter((i) => i.uid !== item.uid);

          const completedItems = siblingItems.filter((i) => i.completedAt !== null);

          if (siblingItems.length === completedItems.length) {
            draftState.checklists[checklistIndex].items[firstLevelIndex].completedAt = new Date();
            draftState.checklists[checklistIndex].items[firstLevelIndex].completedBy = currentUser;
          }

          if (item.subitems.length > 0) {
            item.subitems
              .filter((i) => i.completedAt === null)
              .forEach((i) => {
                const itemIndex = draftState.checklists[checklistIndex].items[
                  firstLevelIndex
                ].subitems[secondLevelIndex].subitems.findIndex((s) => s.uid === i.uid);

                if (itemIndex >= 0) {
                  draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
                    secondLevelIndex
                  ].subitems[itemIndex].completedAt = new Date();

                  draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
                    secondLevelIndex
                  ].subitems[itemIndex].completedBy = currentUser;
                }
              });
          }
        } else if (level === 3) {
          // Mark the third level as completed
          const path = [
            checklistIndex,
            "items",
            firstLevelIndex,
            "subitems",
            secondLevelIndex,
            "subitems",
            thirdLevelIndex,
          ];
          setKeypathValue(draftState.checklists, [...path, "completedAt"], new Date());
          setKeypathValue(draftState.checklists, [...path, "completedBy"], currentUser);

          const siblingItems = draftState.checklists[checklistIndex].items[
            firstLevelIndex
          ].subitems[secondLevelIndex].subitems.filter((i) => i.uid !== item.uid);

          const completedItems = siblingItems.filter((i) => i.completedAt !== null);

          if (siblingItems.length === completedItems.length) {
            draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
              secondLevelIndex
            ].completedAt = new Date();

            draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
              secondLevelIndex
            ].completedBy = currentUser;
          }

          // get the second level items.
          const secondLevelItems = draftState.checklists[checklistIndex].items[
            firstLevelIndex
          ].subitems.filter((i) => i.uid !== item.uid);
          const completedSecondLevelItems = secondLevelItems.filter((i) => i.completedAt !== null);

          if (secondLevelItems.length === completedSecondLevelItems.length) {
            draftState.checklists[checklistIndex].items[firstLevelIndex].completedAt = new Date();
            draftState.checklists[checklistIndex].items[firstLevelIndex].completedBy = currentUser;
          }
        }
      });
    }

    case MARK_ITEM_AS_NOT_COMPLETED: {
      const { checklistIndex, firstLevelIndex, secondLevelIndex, thirdLevelIndex, level } =
        action.payload;
      return produce(state, (draftState) => {
        if (level === 1) {
          // Mark the first level as completed
          const path = [checklistIndex, "items", firstLevelIndex];
          setKeypathValue(draftState.checklists, [...path, "completedAt"], null);
          setKeypathValue(draftState.checklists, [...path, "completedBy"], null);

          draftState.checklists[checklistIndex].items[firstLevelIndex].subitems.forEach((i) => {
            const itemIndex = draftState.checklists[checklistIndex].items[
              firstLevelIndex
            ].subitems.findIndex((s) => s.uid === i.uid);

            if (itemIndex >= 0) {
              draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
                itemIndex
              ].completedAt = null;

              draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
                itemIndex
              ].completedBy = null;

              draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
                itemIndex
              ].subitems.forEach((si) => {
                const subItemIndex = draftState.checklists[checklistIndex].items[
                  firstLevelIndex
                ].subitems[itemIndex].subitems.findIndex(({ uid }) => uid === si.uid);

                if (subItemIndex >= 0) {
                  draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
                    itemIndex
                  ].subitems[subItemIndex].completedAt = null;

                  draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
                    itemIndex
                  ].subitems[subItemIndex].completedBy = null;
                }
              });
            }
          });
        } else if (level === 2) {
          // Mark the second level as not completed
          const path = [checklistIndex, "items", firstLevelIndex, "subitems", secondLevelIndex];
          setKeypathValue(draftState.checklists, [...path, "completedAt"], null);
          setKeypathValue(draftState.checklists, [...path, "completedBy"], null);

          // Mark the first level as not completed
          draftState.checklists[checklistIndex].items[firstLevelIndex].completedAt = null;
          draftState.checklists[checklistIndex].items[firstLevelIndex].completedBy = null;

          // sub items of the current item
          draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
            secondLevelIndex
          ].subitems.forEach((i, index) => {
            draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
              secondLevelIndex
            ].subitems[index].completedAt = null;

            draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
              secondLevelIndex
            ].subitems[index].completedBy = null;
          });
        } else if (level === 3) {
          // Mark the third level as completed
          const path = [
            checklistIndex,
            "items",
            firstLevelIndex,
            "subitems",
            secondLevelIndex,
            "subitems",
            thirdLevelIndex,
          ];
          setKeypathValue(draftState.checklists, [...path, "completedAt"], null);
          setKeypathValue(draftState.checklists, [...path, "completedBy"], null);

          // Mark the first level as not completed
          draftState.checklists[checklistIndex].items[firstLevelIndex].completedAt = null;
          draftState.checklists[checklistIndex].items[firstLevelIndex].completedBy = null;

          // Mark the second level as not completed
          draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
            secondLevelIndex
          ].completedAt = null;

          draftState.checklists[checklistIndex].items[firstLevelIndex].subitems[
            secondLevelIndex
          ].completedBy = null;
        }
      });
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
