import { orderBy } from "lodash";

import { MODULE } from "./individualTimesheets.actionTypes";

export const isFetchingSummaryStatus = (state) => state.getIn([MODULE, "isFetchingSummaryStatus"]);

export const getIndividualSummary = (state) => {
  const summary = state.getIn([MODULE, "individualSummary"]);

  if (!summary) return null;

  return {
    ...summary,
    timesheet: summary.timesheet.map((t) => ({
      ...t,
      timeEntries: t.timeEntries.map((e) => ({
        ...e,
        productName: e.productName ? e.productName : "N/A",
      })),
    })),
  };
};

export const getFromDate = (state) => state.getIn([MODULE, "fromDate"]);

export const getToDate = (state) => state.getIn([MODULE, "toDate"]);

export const getDates = (state) => state.getIn([MODULE, "dates"]);

export const isUpdatingTimeEntryStatus = (state) =>
  state.getIn([MODULE, "isUpdatingTimeEntryStatus"]);

export const getSelectedUserIds = (state) => state.getIn([MODULE, "selectedUserIds"]);

export const getModalType = (state) => state.getIn([MODULE, "modalType"]);

export const getActiveUser = (state) => state.getIn([MODULE, "activeUser"]);

export const getSelectedTimeEntryUids = (state) => state.getIn([MODULE, "selectedTimeEntryUids"]);

export const isFetchingTeamMembersStatus = (state) =>
  state.getIn([MODULE, "isFetchingTeamMembersStatus"]);

export const getTeamMembers = (state) =>
  orderBy(state.getIn([MODULE, "teams"]), [(l) => l.firstName.toLowerCase()]);

export const isDeletingStatus = (state) => state.getIn([MODULE, "isDeletingStatus"]);

export const getExpandedDates = (state) => state.getIn([MODULE, "expandedDates"]);

export const getActiveTimeEntry = (state) => state.getIn([MODULE, "activeTimeEntry"]);
