import qs from "query-string";

import { createApiV2 } from "./common";

export const getUsers = (params) => createApiV2("GET", `users/?${qs.stringify(params)}`)();

export const postAssignUser = (data) => (token) =>
  createApiV2("POST", `planner/order_assignments/`)(token, data);

export default {};
