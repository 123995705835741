import { MODULE } from "./partialInvoice.actionTypes";

export const invoiceType = (state) => state.getIn([MODULE, "invoiceType"]);
export const invoiceDate = (state) => state.getIn([MODULE, "invoiceDate"]);
export const invoiceData = (state) => state.getIn([MODULE, "invoiceData"]);
export const lastSubmittedData = (state) => state.getIn([MODULE, "lastSubmittedData"]);
export const printPartialInvoiceProgress = (state) =>
  state.getIn([MODULE, "printPartialInvoiceProgress"]);
export const printedPartialInvoice = (state) => state.getIn([MODULE, "printedPartialInvoice"]);
export const partialInvoicesProgress = (state) => state.getIn([MODULE, "partialInvoicesProgress"]);
export const partialInvoices = (state) => state.getIn([MODULE, "partialInvoices"]);
export const partialInvoiceSummaryOpen = (state) =>
  state.getIn([MODULE, "partialInvoiceSummaryOpen"]);
export const additionalProductsProgress = (state) =>
  state.getIn([MODULE, "additionalProductsProgress"]);
export const additionalProducts = (state) => state.getIn([MODULE, "additionalProducts"]);
export const createPartialInvoiceProgress = (state) =>
  state.getIn([MODULE, "createPartialInvoiceProgress"]);
export const specificAmount = (state) => state.getIn([MODULE, "specificAmount"]);
export const percentage = (state) => state.getIn([MODULE, "percentage"]);
export const lastId = (state) => state.getIn([MODULE, "lastId"]);
export const emailPartialInvoiceProgress = (state) =>
  state.getIn([MODULE, "emailPartialInvoiceProgress"]);
export const emailPartialInvoice = (state) => state.getIn([MODULE, "emailPartialInvoice"]);
export const invoiceSelectedItems = (state) => state.getIn([MODULE, "invoiceSelectedItems"]);
export const invoiceItemAmounts = (state) => state.getIn([MODULE, "invoiceItemAmounts"]);
export const invoiceItemRemainingAmounts = (state) =>
  state.getIn([MODULE, "invoiceItemRemainingAmounts"]);
