import React from "react";
import { Tooltip as ReactTooltip } from "react-tippy";
import PropTypes from "prop-types";
import cx from "classnames";

import "react-tippy/dist/tippy.css?raw";
import "./tippy.scss";

const Tooltip = ({
  title,
  html,
  className,
  children,
  position,
  interactive,
  followCursor,
  distance,
  padding,
}) => (
  <ReactTooltip
    title={title}
    html={
      <>
        {html ? (
          <div
            className="tippy-content"
            style={{
              padding: `${padding?.top ?? 0}rem ${padding?.left ?? 0}rem ${
                padding?.bottom ?? 0
              }rem ${padding?.right ?? 0}rem`,
            }}
          >
            {html}
          </div>
        ) : null}
      </>
    }
    className={cx(className)}
    position={position}
    followCursor={followCursor}
    animation="scale"
    interactive={interactive}
    delay={300}
    distance={distance}
    useContext
  >
    {children}
  </ReactTooltip>
);

Tooltip.defaultProps = {
  interactive: false,
  followCursor: false,
  position: "bottom",
  title: "",
  html: "",
  className: "",
  distance: 10,
  padding: {
    top: 1,
    bottom: 1,
    left: 1,
    right: 1,
  }, // in rem unit
};

Tooltip.propTypes = {
  interactive: PropTypes.bool,
  followCursor: PropTypes.bool,
  position: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  html: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  distance: PropTypes.number,
  padding: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
  }),
};

export default Tooltip;
