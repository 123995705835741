import { nanoid } from "nanoid";
import camelcaseKeys from "camelcase-keys";

import { handleError } from "../../utils/error";
import * as api from "@/apiv2/additionalProducts";
import * as supplierProductsApi from "@/apiv2/supplierProducts";
import * as selectors from "./productLists.selectors";

import {
  SET_IS_FETCHING_CATEGORIES,
  SET_CATEGORIES,
  SET_PRODUCTS,
  SET_IS_FETCHING_PRODUCTS,
  SET_SEARCH,
  SET_SEARCH_PRODUCTS,
  SET_EXPANDED_CATEGORIES,
  RESET,
} from "./productLists.actionTypes";

export const setIsFetchingCategories = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_CATEGORIES,
    payload: status,
  });
};

export const setIsFetchingPoducts = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_PRODUCTS,
    payload: {
      data,
    },
  });
};

export const setExpandedCategories = (categoryUids) => (dispatch) => {
  dispatch({
    type: SET_EXPANDED_CATEGORIES,
    payload: {
      data: categoryUids.filter((item, index) => categoryUids.indexOf(item) === index),
    },
  });
};

export const setSearch = (data) => (dispatch) => {
  dispatch({
    type: SET_SEARCH,
    payload: data,
  });
};

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setSearchProducts =
  ({ categoryUids, products }) =>
  (dispatch) => {
    if (categoryUids.length > 0) {
      dispatch({
        type: SET_SEARCH_PRODUCTS,
        payload: categoryUids.reduce(
          (acc, curr) => ((acc[curr] = products.filter((item) => item.categoryUid === curr)), acc),
          {}
        ),
      });
    } else {
      dispatch({
        type: SET_SEARCH_PRODUCTS,
        payload: {},
      });
    }
  };

export const setCategories = (data) => (dispatch) => {
  dispatch({
    type: SET_CATEGORIES,
    payload: {
      data,
    },
  });
};

export const setProductsByCategory =
  ({ data, categoryUid }) =>
  (dispatch) => {
    dispatch({
      type: SET_PRODUCTS,
      payload: {
        data,
        categoryUid,
      },
    });
  };

export const fetchCategories = () => async (dispatch) => {
  try {
    dispatch(setIsFetchingCategories(true));
    const response = await api.getCategories()();
    dispatch(
      setCategories(
        response.data.map((item) => ({
          ...item,
          isFetching: false,
        }))
      )
    );
    return response;
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetchingCategories(false));
  }
};

export const fetchProductsByCategory = (data) => async (dispatch, getState) => {
  const state = getState();
  try {
    dispatch(
      setIsFetchingPoducts({
        uid: selectors.getCategories(state).find((c) => c.uid === data.categoryUid)?.uid ?? "",
        isFetching: true,
      })
    );

    const response =
      data.category === "Supplied Products"
        ? await supplierProductsApi.getAllSupplierProductsV2(data.supplierUid, "")()
        : await api.getProductsByCategory({
            search: data.search || undefined,
            category: data?.category ?? undefined,
          })();

    dispatch(
      setProductsByCategory({
        data:
          data.category === "Supplied Products"
            ? response.data.map((data) => ({
                ...data,
                category: "Supplied Products",
                categoryUid: "Supplied Products",
                name: data.productName,
                image: data.productImage,
                uid: data.product,
              }))
            : response.data.filter((p) => !p.productKit),
        categoryUid: data.categoryUid,
      })
    );

    return response;
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(
      setIsFetchingPoducts({
        uid: data.categoryUid,
        isFetching: false,
      })
    );
  }
};

export const searchProducts =
  ({ search, supplierUid }) =>
  async (dispatch, getState) => {
    const state = getState();
    const suppliedProducts = selectors
      .getProductsForCategory(state, "Supplied Products")
      .filter((p) => p.name.toLowerCase().includes(search.toLowerCase()));

    dispatch(setSearchProducts({ categoryUids: [], products: {} }));

    if (search !== "") {
      try {
        dispatch(setIsFetchingCategories(true));
        const { data } = await api.getProductsByCategory({
          search,
        })();

        const response = camelcaseKeys(data, { deep: true });

        if (response.length > 0) {
          const categories = response.map((item) => item.categoryUid);

          const uniqueCategoryUids = categories.filter(
            (item, index) => categories.indexOf(item) === index
          );

          const categoriesToExpand = [
            suppliedProducts.length > 0 ? "Supplied Products" : "",
            ...uniqueCategoryUids,
          ];

          dispatch(setExpandedCategories(categoriesToExpand));
          dispatch(
            setSearchProducts({
              categoryUids: categoriesToExpand,
              products: [...suppliedProducts, ...response.filter((r) => !r.productKit)],
            })
          );
        }
        dispatch(setIsFetchingCategories(false));
      } catch (error) {
        handleError(error, dispatch);
      }
    } else {
      dispatch(fetchCategories());
      dispatch(setExpandedCategories(["Supplied Products"]));
      dispatch(
        fetchProductsByCategory({
          supplierUid,
          category: "Supplied Products",
          categoryUid: "Supplied Products",
        })
      );
    }
  };

export const setSelectedCategories = (categoryUids) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_CATEGORIES,
    payload: {
      data: categoryUids,
    },
  });
};

export const setSelectedProducts = (productUids) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_PRODUCTS,
    payload: {
      selectedProducts: productUids,
    },
  });
};
