import qs from "query-string";

import {
  putCustomEvent,
  postCustomEvent,
  getCustomEvents,
  getCustomEvent,
  deleteCustomEventInstanceApi,
  deleteThisCustomEventApi,
  deleteThisAndFollowingCustomEventApi,
  deleteCustomAllEventApi,
  getUndoDeleteCustomEventInstance,
  getUndoDeleteThisCustomEvent,
  getUndoDeleteThisAndFollowingCustomEvent,
  getUndoDeleteCustomAllEvent,
  duplicateCustomEvent,
} from "@/apiv2/planner/calendar";
import { handleError } from "@/redux/utils/error";

import * as viewSelectors from "../views/views.selectors";
import { STATUS } from "../../utils/constants";

import * as searchFilterActions from "./searchFilter/searchFilter.actions";
import * as labelFilterActions from "./labelFilter/labelFilter.actions";
import * as labelFilterSelectors from "./labelFilter/labelFilter.selectors";
import * as userFilterActions from "./userFilter/userFilter.actions";
import * as userFilterSelectors from "./userFilter/userFilter.selectors";
import * as orderActions from "./orders/orders.actions";
import * as orderSelectors from "./orders/orders.selectors";
import * as calendarActions from "./calendar/calendar.actions";
import * as calendarSelectors from "./calendar/calendar.selectors";
import * as showHideOrderFilterActions from "./showHideOrderFilter/showHideOrderFilter.actions";
import {
  SET_ACTIVE_VIEW,
  SET_IS_ASSIGN_LABEL_MENU_VISIBLE,
  SET_IS_ASSIGN_USER_MENU_VISIBLE,
  SET_IS_EDIT_TIME_SLOT_DROPDOWN_VISIBLE,
  SET_ASSIGN_USER_DROPDOWN_COORDINATES,
  SET_ASSIGN_LABEL_DROPDOWN_COORDINATES,
  SET_EDIT_TIME_SLOT_DROPDOWN_COORDINATES,
  SET_IS_ORDER_MODAL_OPEN,
  SET_IS_ORDER_COLUMN_COLLAPSE,
  SET_IS_SETTINGS_MODAL_OPEN,
  SET_SETTINGS_START_TIME,
  SET_SETTINGS_END_TIME,
  SET_CREATE_CUSTOM_EVENT_STATUS,
  SET_UPDATE_CUSTOM_EVENT_STATUS,
  SET_FETCH_CUSTOM_EVENTS_STATUS,
  SET_FETCH_CUSTOM_EVENT_STATUS,
  SET_CUSTOM_EVENTS,
  SET_CUSTOM_EVENT,
  SET_ACTIVE_CUSTOM_EVENT,
  SET_IS_CUSTOM_ASSIGN_USER_MENU_VISIBLE,
  SET_IS_CUSTOM_ASSIGN_LABEL_MENU_VISIBLE,
  SET_IS_CUSTOM_EDIT_TIME_SLOT_DROPDOWN_VISIBLE,
  SET_IS_SETTINGS_SAVING,
  SET_DUPLICATE_EVENT_STATUS,
  SET_DELETE_CUSTOM_EVENT_INSTANCE_STATUS,
  SET_DELETE_THIS_CUSTOM_EVENT_STATUS,
  SET_DELETE_THIS_AND_FOLLOWING_CUSTOM_EVENT_STATUS,
  SET_DELETE_ALL_CUSTOM_EVENT_STATUS,
  SET_UNDO_DELETE_CUSTOM_EVENT_INSTANCE_STATUS,
  SET_UNDO_DELETE_THIS_CUSTOM_EVENT_STATUS,
  SET_UNDO_DELETE_THIS_AND_FOLLOWING_CUSTOM_EVENT_STATUS,
  SET_UNDO_DELETE_ALL_CUSTOM_EVENT_STATUS,
  SET_CUSTOM_EVENT_DATA_TO_UPDATE,
  SET_CUSTOM_EVENT_REMINDERS,
  SET_CUSTOM_EVENT_REMINDER,
  RESET_CUSTOM_EVENT_TRANSACTION_STATE,
  RESET,
} from "./planner.actionTypes";

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const resetCustomEventTransactionState = () => (dispatch) => {
  dispatch({
    type: RESET_CUSTOM_EVENT_TRANSACTION_STATE,
  });
};

export const setActiveView = (data) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_VIEW,
    payload: data,
  });
};

export const setIsAssignUserMenuVisible = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_ASSIGN_USER_MENU_VISIBLE,
    payload: status,
  });
};

export const setIsAssignLabelMenuVisible = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_ASSIGN_LABEL_MENU_VISIBLE,
    payload: status,
  });
};

export const setIsEditTimeslotDropdownVisible = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_EDIT_TIME_SLOT_DROPDOWN_VISIBLE,
    payload: status,
  });
};

export const setAssignUserDropdownCoordinates = (data) => (dispatch) => {
  dispatch({
    type: SET_ASSIGN_USER_DROPDOWN_COORDINATES,
    payload: data,
  });
};

export const setAssignLabelDropdownCoordinates = (data) => (dispatch) => {
  dispatch({
    type: SET_ASSIGN_LABEL_DROPDOWN_COORDINATES,
    payload: data,
  });
};

export const setEditTimeslotDropdownCoordinates = (data) => (dispatch) => {
  dispatch({
    type: SET_EDIT_TIME_SLOT_DROPDOWN_COORDINATES,
    payload: data,
  });
};

export const setIsOrderModalOpen = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_ORDER_MODAL_OPEN,
    payload: data,
  });
};

export const setIsSettingsModalOpen = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_SETTINGS_MODAL_OPEN,
    payload: data,
  });
};

export const setIsOrderColumnCollapse = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_ORDER_COLUMN_COLLAPSE,
    payload: data,
  });
};

export const setSettingsStartTime = (data) => (dispatch) => {
  dispatch({
    type: SET_SETTINGS_START_TIME,
    payload: data,
  });
};

export const setSettingsEndTime = (data) => (dispatch) => {
  dispatch({
    type: SET_SETTINGS_END_TIME,
    payload: data,
  });
};

export const setIsSettingsSaving = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_SETTINGS_SAVING,
    payload: data,
  });
};

export const showAssignUserMenu =
  ({ isVisible, event }) =>
  (dispatch) => {
    dispatch(setIsAssignUserMenuVisible(isVisible));

    dispatch(
      setAssignUserDropdownCoordinates({
        top: event.clientY,
        left: event.clientX,
      })
    );
  };

export const showAssignLabelMenu =
  ({ isVisible, event }) =>
  (dispatch) => {
    dispatch(setIsAssignLabelMenuVisible(isVisible));

    dispatch(
      setAssignLabelDropdownCoordinates({
        top: event.clientY,
        left: event.clientX,
      })
    );
  };

export const showEditTimeslotMenu =
  ({ isVisible, event }) =>
  (dispatch) => {
    dispatch(setIsEditTimeslotDropdownVisible(isVisible));

    dispatch(
      setEditTimeslotDropdownCoordinates({
        top: event.clientY,
        left: event.clientX,
      })
    );
  };

export const updateOrderCardAssignees = (values) => async (dispatch, getState) => {
  const state = getState();
  const { order } = values;

  const orders = orderSelectors.getOrders(state);

  await dispatch(
    orderActions.setOrders(
      orders.map((o) => (o.uid === order.uid ? { ...o, assignees: order.assignees } : { ...o }))
    )
  );

  const schedules = calendarSelectors.getSchedules(state);

  dispatch(
    calendarActions.setOrderInCalendar(
      schedules.map((s) =>
        s.order.uid === order.uid
          ? {
              ...s,
              order: {
                ...s.order,
                assignees: order.assignees,
              },
            }
          : { ...s }
      )
    )
  );
};

export const assignUser =
  ({ order }) =>
  async (dispatch) => {
    await dispatch(
      updateOrderCardAssignees({
        order: {
          uid: order.uid,
          assignees: order.assignees,
        },
      })
    );
  };

export const updateOrderCardLabels = (values) => async (dispatch, getState) => {
  const state = getState();
  const { order } = values;

  const orders = orderSelectors.getOrders(state);

  await dispatch(
    orderActions.setOrders(
      orders.map((o) => (o.uid === order.uid ? { ...o, labels: order.labels } : { ...o }))
    )
  );

  const schedules = calendarSelectors.getSchedules(state);

  dispatch(
    calendarActions.setOrderInCalendar(
      schedules.map((s) =>
        s.order.uid === order.uid
          ? {
              ...s,
              order: {
                ...s.order,
                labels: order.labels,
              },
            }
          : { ...s }
      )
    )
  );
};

export const assignLabels =
  ({ order }) =>
  async (dispatch) => {
    await dispatch(
      updateOrderCardLabels({
        order: {
          uid: order.uid,
          labels: order.labels,
        },
      })
    );
  };

export const removeLabelToAllOrderCards = (data) => (dispatch, getState) => {
  const state = getState();
  const labelsFromFilter = labelFilterSelectors.getLabels(state);
  const selectedLabelsFromFilter = labelFilterSelectors.getSelectedLabels(state);
  const orders = orderSelectors.getOrders(state);
  const formattedOrders = orders.map((c) => ({
    ...c,
    labels: c.labels.filter((l) => l.uid !== data.uid),
  }));

  dispatch(orderActions.setOrders(formattedOrders));

  dispatch(labelFilterActions.setLabels(labelsFromFilter.filter((l) => l.uid !== data.uid)));

  dispatch(
    labelFilterActions.setSelectedLabels(selectedLabelsFromFilter.filter((uid) => uid !== data.uid))
  );
};

export const renameLabelToAllOrderCards = (values) => async (dispatch, getState) => {
  const state = getState();
  const labelsFromFilter = labelFilterSelectors.getLabels(state);
  const selectedLabelsFromFilter = labelFilterSelectors.getSelectedLabels(state);
  const schedules = calendarSelectors.getSchedules(state);
  const orders = orderSelectors.getOrders(state);

  if (labelsFromFilter.map((l) => l.uid).includes(values.uid)) {
    dispatch(
      orderActions.setOrders(
        orders.map((o) => ({
          ...o,
          labels: o.labels.map((l) =>
            l.uid === values.uid ? { ...l, name: values.name, colour: values.colour } : l
          ),
        }))
      )
    );

    dispatch(
      calendarActions.setOrderInCalendar(
        schedules.map((s) => ({
          ...s,
          order: {
            ...s.order,
            labels: s.order.labels.map((l) =>
              l.uid === values.uid ? { ...l, name: values.name, colour: values.colour } : l
            ),
          },
        }))
      )
    );

    dispatch(
      labelFilterActions.setLabels(
        labelsFromFilter.map((l) => (l.uid === values.uid ? { ...values } : { ...l }))
      )
    );
  } else {
    dispatch(labelFilterActions.setLabels([...labelsFromFilter, values]));

    dispatch(labelFilterActions.setSelectedLabels([...selectedLabelsFromFilter, values.uid]));
  }
};

export const applyFilterFromUrl = () => (dispatch, getState) => {
  const state = getState();

  const selectedView = viewSelectors.getSelectedView(state);

  if (selectedView && selectedView.name === "None") return;

  const parsedCustomFilter = qs.parse(selectedView.customFilter);

  if (typeof parsedCustomFilter.labels === "string") {
    parsedCustomFilter.labels = [parsedCustomFilter.labels];
  }

  if (typeof parsedCustomFilter.assignees === "string") {
    parsedCustomFilter.assignees = [parsedCustomFilter.assignees];
  }

  if (typeof parsedCustomFilter.resources === "string") {
    parsedCustomFilter.resources = [parsedCustomFilter.resources];
  }

  if (parsedCustomFilter.assignees) {
    dispatch(
      userFilterActions.setSelectedUsers(
        parsedCustomFilter.assignees.map((a) =>
          Number.isNaN(parseInt(a)) ? "no_assignee" : parseInt(a)
        )
      )
    );
  } else {
    dispatch(userFilterActions.setSelectedUsers([]));
  }

  if (parsedCustomFilter.labels) {
    dispatch(labelFilterActions.setSelectedLabels(parsedCustomFilter.labels));
  } else {
    dispatch(labelFilterActions.setSelectedLabels([]));
  }

  if (parsedCustomFilter.resources) {
    dispatch(
      calendarActions.setSelectedUsers(parsedCustomFilter.resources.map((r) => parseInt(r)))
    );
  } else {
    const users = userFilterSelectors.getUsers(state);
    dispatch(calendarActions.setSelectedUsers(users.map((u) => parseInt(u.id))));
  }

  if (parsedCustomFilter.search) {
    dispatch(searchFilterActions.setSearch(parsedCustomFilter.search));
  } else {
    dispatch(searchFilterActions.setSearch(""));
  }

  if (parsedCustomFilter.is_scheduled) {
    dispatch(showHideOrderFilterActions.setSelectedFilter([parsedCustomFilter.is_scheduled]));
  } else {
    dispatch(showHideOrderFilterActions.setSelectedFilter(["true", "false"]));
  }
};

export const setActiveCustomEvent = (payload) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_CUSTOM_EVENT,
    payload,
  });
};

export const setIsCustomAssignLabelMenuVisible = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_CUSTOM_ASSIGN_LABEL_MENU_VISIBLE,
    payload: status,
  });
};

export const setIsCustomAssignUserMenuVisible = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_CUSTOM_ASSIGN_USER_MENU_VISIBLE,
    payload: status,
  });
};

export const setIsCustomEditTimeslotDropdownVisible = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_CUSTOM_EDIT_TIME_SLOT_DROPDOWN_VISIBLE,
    payload: status,
  });
};

export const showCustomAssignUserMenu =
  ({ isVisible, event }) =>
  (dispatch) => {
    dispatch(setIsCustomAssignUserMenuVisible(isVisible));

    dispatch(
      setAssignUserDropdownCoordinates({
        top: event.clientY,
        left: event.clientX,
      })
    );
  };

export const showCustomAssignLabelMenu =
  ({ isVisible, event }) =>
  (dispatch) => {
    dispatch(setIsCustomAssignLabelMenuVisible(isVisible));

    dispatch(
      setAssignLabelDropdownCoordinates({
        top: event.clientY,
        left: event.clientX,
      })
    );
  };

export const showCustomEditTimeslotMenu =
  ({ isVisible, event }) =>
  (dispatch) => {
    dispatch(setIsCustomEditTimeslotDropdownVisible(isVisible));

    dispatch(
      setEditTimeslotDropdownCoordinates({
        top: event.clientY,
        left: event.clientX,
      })
    );
  };

export const setFetchCustomEventsStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_FETCH_CUSTOM_EVENTS_STATUS,
    payload,
  });
};

export const setCustomEvents = (payload) => (dispatch) => {
  dispatch({
    type: SET_CUSTOM_EVENTS,
    payload,
  });
};

export const fetchCustomEvents = (params) => async (dispatch) => {
  try {
    dispatch(setFetchCustomEventsStatus(STATUS.PENDING));
    const response = await getCustomEvents(params)();
    dispatch(setCustomEvents(response.data));
    dispatch(setFetchCustomEventsStatus(STATUS.FULFILLED));
  } catch (error) {
    dispatch(setFetchCustomEventsStatus(STATUS.REJECTED));
    handleError(error, dispatch);
  }
};

export const setFetchCustomEventStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_FETCH_CUSTOM_EVENT_STATUS,
    payload,
  });
};

export const setCustomEvent = (payload) => (dispatch) => {
  dispatch({
    type: SET_CUSTOM_EVENT,
    payload,
  });
};

export const fetchCustomEvent = (uid) => async (dispatch) => {
  try {
    dispatch(setFetchCustomEventStatus(STATUS.PENDING));
    const response = await getCustomEvent(uid)();
    dispatch(setCustomEvent(response.data));
    dispatch(setFetchCustomEventStatus(STATUS.FULFILLED));
  } catch (error) {
    dispatch(setFetchCustomEventStatus(STATUS.REJECTED));
    handleError(error, dispatch);
  }
};

export const setCreateCustomEventStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_CREATE_CUSTOM_EVENT_STATUS,
    payload,
  });
};

export const createCustomEvent = (data) => async (dispatch) => {
  try {
    dispatch(setCreateCustomEventStatus(STATUS.PENDING));
    await postCustomEvent(data)();
    dispatch(setCreateCustomEventStatus(STATUS.FULFILLED));
  } catch (error) {
    dispatch(setCreateCustomEventStatus(STATUS.REJECTED));
    handleError(error, dispatch);
  }
};

export const setUpdateCustomEventStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_UPDATE_CUSTOM_EVENT_STATUS,
    payload,
  });
};

export const updateCustomEvent = (uid, data) => async (dispatch) => {
  try {
    dispatch(setUpdateCustomEventStatus(STATUS.PENDING));
    await putCustomEvent(uid, data)();
    dispatch(setUpdateCustomEventStatus(STATUS.FULFILLED));
  } catch (error) {
    dispatch(setUpdateCustomEventStatus(STATUS.REJECTED));
    handleError(error, dispatch);
  }
};

export const setDuplicateEventStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_DUPLICATE_EVENT_STATUS,
    payload,
  });
};

export const duplicateEvent =
  (uid, params = {}) =>
  async (dispatch) => {
    try {
      dispatch(setDuplicateEventStatus(STATUS.PENDING));
      await duplicateCustomEvent(uid, params)();
      dispatch(setDuplicateEventStatus(STATUS.FULFILLED));
    } catch (error) {
      dispatch(setDuplicateEventStatus(STATUS.REJECTED));
      handleError(error, dispatch);
    }
  };

export const setDeleteCustomEventInstanceStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_DELETE_CUSTOM_EVENT_INSTANCE_STATUS,
    payload,
  });
};

export const deleteCustomEventInstance = (uid) => async (dispatch) => {
  try {
    dispatch(setDeleteCustomEventInstanceStatus(STATUS.PENDING));
    await deleteCustomEventInstanceApi(uid)();
    dispatch(setDeleteCustomEventInstanceStatus(STATUS.FULFILLED));
  } catch (error) {
    dispatch(setDeleteCustomEventInstanceStatus(STATUS.REJECTED));
    handleError(error, dispatch);
  }
};

export const setDeleteThisCustomEventStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_DELETE_THIS_CUSTOM_EVENT_STATUS,
    payload,
  });
};

export const deleteThisCustomEvent = (uid, date) => async (dispatch) => {
  try {
    dispatch(setDeleteThisCustomEventStatus(STATUS.PENDING));
    await deleteThisCustomEventApi(uid, date)();
    dispatch(setDeleteThisCustomEventStatus(STATUS.FULFILLED));
  } catch (error) {
    dispatch(setDeleteThisCustomEventStatus(STATUS.REJECTED));
    handleError(error, dispatch);
  }
};

export const setDeleteThisAndFollowingCustomEventStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_DELETE_THIS_AND_FOLLOWING_CUSTOM_EVENT_STATUS,
    payload,
  });
};

export const deleteThisAndFollowingCustomEvent = (uid, date) => async (dispatch) => {
  try {
    dispatch(setDeleteThisAndFollowingCustomEventStatus(STATUS.PENDING));
    await deleteThisAndFollowingCustomEventApi(uid, date)();
    dispatch(setDeleteThisAndFollowingCustomEventStatus(STATUS.FULFILLED));
  } catch (error) {
    dispatch(setDeleteThisAndFollowingCustomEventStatus(STATUS.REJECTED));
    handleError(error, dispatch);
  }
};

export const setDeleteAllCustomEventStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_DELETE_ALL_CUSTOM_EVENT_STATUS,
    payload,
  });
};

export const deleteCustomAllEvent = (uid) => async (dispatch) => {
  try {
    dispatch(setDeleteAllCustomEventStatus(STATUS.PENDING));
    await deleteCustomAllEventApi(uid)();
    dispatch(setDeleteAllCustomEventStatus(STATUS.FULFILLED));
  } catch (error) {
    dispatch(setDeleteAllCustomEventStatus(STATUS.REJECTED));
    handleError(error, dispatch);
  }
};

export const setUndoDeleteCustomEventInstanceStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_UNDO_DELETE_CUSTOM_EVENT_INSTANCE_STATUS,
    payload,
  });
};

export const undoDeleteCustomEventInstance = (uid) => async (dispatch) => {
  try {
    dispatch(setUndoDeleteCustomEventInstanceStatus(STATUS.PENDING));
    await getUndoDeleteCustomEventInstance(uid)();
    dispatch(setUndoDeleteCustomEventInstanceStatus(STATUS.FULFILLED));
  } catch (error) {
    dispatch(setUndoDeleteCustomEventInstanceStatus(STATUS.REJECTED));
    handleError(error, dispatch);
  }
};

export const setUndoDeleteThisCustomEventStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_UNDO_DELETE_THIS_CUSTOM_EVENT_STATUS,
    payload,
  });
};

export const undoDeleteThisCustomEvent = (uid, date) => async (dispatch) => {
  try {
    dispatch(setUndoDeleteThisCustomEventStatus(STATUS.PENDING));
    await getUndoDeleteThisCustomEvent(uid, date)();
    dispatch(setUndoDeleteThisCustomEventStatus(STATUS.FULFILLED));
  } catch (error) {
    dispatch(setUndoDeleteThisCustomEventStatus(STATUS.REJECTED));
    handleError(error, dispatch);
  }
};

export const setUndoDeleteThisAndFollowingCustomEventStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_UNDO_DELETE_THIS_AND_FOLLOWING_CUSTOM_EVENT_STATUS,
    payload,
  });
};

export const undoDeleteThisAndFollowingCustomEvent = (uid, date) => async (dispatch) => {
  try {
    dispatch(setUndoDeleteThisAndFollowingCustomEventStatus(STATUS.PENDING));
    await getUndoDeleteThisAndFollowingCustomEvent(uid, date)();
    dispatch(setUndoDeleteThisAndFollowingCustomEventStatus(STATUS.FULFILLED));
  } catch (error) {
    dispatch(setUndoDeleteThisAndFollowingCustomEventStatus(STATUS.REJECTED));
    handleError(error, dispatch);
  }
};

export const setUndoDeleteAllCustomEventStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_UNDO_DELETE_ALL_CUSTOM_EVENT_STATUS,
    payload,
  });
};

export const undoDeleteCustomAllEvent = (uid) => async (dispatch) => {
  try {
    dispatch(setUndoDeleteAllCustomEventStatus(STATUS.PENDING));
    await getUndoDeleteCustomAllEvent(uid)();
    dispatch(setUndoDeleteAllCustomEventStatus(STATUS.FULFILLED));
  } catch (error) {
    dispatch(setUndoDeleteAllCustomEventStatus(STATUS.REJECTED));
    handleError(error, dispatch);
  }
};

export const setCustomEventDataToUpdate = (payload) => (dispatch) => {
  dispatch({
    type: SET_CUSTOM_EVENT_DATA_TO_UPDATE,
    payload,
  });
};

export const setCustomEventReminders = (payload) => (dispatch) => {
  dispatch({
    type: SET_CUSTOM_EVENT_REMINDERS,
    payload,
  });
};

export const setCustomEventReminder = (payload) => (dispatch) => {
  dispatch({
    type: SET_CUSTOM_EVENT_REMINDER,
    payload,
  });
};
