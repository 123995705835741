import createActionType from "@/redux/utils/createActionType";

export const MODULE = "timeTracking/hoursWorked";

export const SET_IS_FETCHING_LISTS_STATUS = createActionType(MODULE, "setIsFetchingListsStatus");

export const SET_LISTS = createActionType(MODULE, "setLists");

export const RESET = createActionType(MODULE, "reset");

export default {};
