import React, { forwardRef } from "react";
import cx from "classnames";
import BaseCurrencyInput from "react-currency-input-field";

import styles from "./CurrencyInput.module.scss";

const CurrencyInput = forwardRef((props, ref) => {
  const { className, inputClassName, ...rest } = props;
  return <BaseCurrencyInput className={cx(styles.input, inputClassName)} ref={ref} {...rest} />;
});

export default CurrencyInput;
