import { handleError } from "../../utils/error";

import * as api from "../../../apiv2/suppliers";

import * as selectors from "./supplierFilter.selectors";
import {
  SET_IS_FETCHING,
  SET_SUPPLIERS,
  SET_SELECTED_SUPPLIERS,
  RESET,
} from "./supplierFilter.actionTypes";

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setIsFetching = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING,
    payload: status,
  });
};

export const setSuppliers = (data) => (dispatch) => {
  dispatch({
    type: SET_SUPPLIERS,
    payload: {
      data,
    },
  });
};

export const setSelectedSuppliers = (uids) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_SUPPLIERS,
    payload: uids,
  });
};
export const selectSupplier = (option) => (dispatch, getState) => {
  const state = getState();
  const selectedSuppliers = selectors.getSelectedSuppliers(state);
  if (
    selectedSuppliers.filter((selected) => selected.uid === option.uid).length >
    0
  ) {
    return dispatch(
      setSelectedSuppliers(
        selectedSuppliers.filter((selected) => selected.uid !== option.uid)
      )
    );
  }

  return dispatch(
    setSelectedSuppliers([
      ...selectedSuppliers,
      { uid: option.uid, name: option.name },
    ])
  );
};

export const fetchSuppliers = () => async (dispatch) => {
  try {
    dispatch(setIsFetching(true));
    const response = await api.searchSuppliers({
      search: "",
      includeArchived: false,
    })();
    dispatch(setSuppliers(response.data));
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetching(false));
  }
};
