/* eslint-disable react/no-danger */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import cx from "classnames";
import { Alert } from "reactstrap";

import Button from "../common/Button/Button";
import { deleteAlertAction } from "../../redux/alerts/alerts.actions";
import { alertsSelector } from "../../redux/alerts/alerts.selectors";

import "./Alerts.scss";

const Alerts = () => {
  const dispatch = useDispatch();
  const alerts = useSelector(alertsSelector);

  const closeAlertHandler = (alertId) => {
    dispatch(deleteAlertAction(alertId));
  };

  const timerCloseHandler = (alertId) => {
    // Close the alert after 5 seconds
    setTimeout(() => {
      closeAlertHandler(alertId);
    }, 5000);
  };

  return (
    <div className="alerts">
      {alerts.map((alert) => (
        <Alert
          key={alert.id}
          className={cx("alerts__alert", {
            "alerts__alert--dark": alert.type === "dark",
            "alerts__alert--danger": alert.type === "danger",
            "alerts__alert--success": alert.type === "success",
          })}
          isOpen
          toggle={() => closeAlertHandler(alert.id)}
        >
          <div dangerouslySetInnerHTML={{ __html: alert.message }} />
          {alert.autoclose && timerCloseHandler(alert.id)}
          {alert.buttonText && (
            <Button
              className="alerts__alert__button"
              onClick={() => {
                alert.callback();
                closeAlertHandler(alert.id);
              }}
            >
              {alert.buttonText}
            </Button>
          )}
        </Alert>
      ))}
    </div>
  );
};

export default Alerts;
