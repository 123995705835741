import createActionType from "../utils/createActionType";

export const MODULE = "planner";

export const SET_ACTIVE_VIEW = createActionType(MODULE, "activeView");

export const SET_IS_ASSIGN_USER_MENU_VISIBLE = createActionType(
  MODULE,
  "setIsAssignUserMenuVisible"
);

export const SET_IS_ASSIGN_LABEL_MENU_VISIBLE = createActionType(
  MODULE,
  "setIsAssignLabelMenuVisible"
);

export const SET_ASSIGN_USER_DROPDOWN_COORDINATES = createActionType(
  MODULE,
  "setAssignUserDropdownCoordinates"
);

export const SET_ASSIGN_LABEL_DROPDOWN_COORDINATES = createActionType(
  MODULE,
  "setAssignLabelDropdownCoordinates"
);

export const SET_IS_EDIT_TIME_SLOT_DROPDOWN_VISIBLE = createActionType(
  MODULE,
  "setIsEditTimeslotDropdownVisible"
);

export const SET_EDIT_TIME_SLOT_DROPDOWN_COORDINATES = createActionType(
  MODULE,
  "setEditTimeslotDropdownCoordinates"
);
export const SET_IS_ORDER_MODAL_OPEN = createActionType(MODULE, "setIsOrderModalOpen");

export const SET_IS_ORDER_COLUMN_COLLAPSE = createActionType(MODULE, "setIsOrderColumnCollapse");

export const SET_IS_SETTINGS_SAVING = createActionType(MODULE, "setIsSettingsSaving");

export const SET_IS_SETTINGS_MODAL_OPEN = createActionType(MODULE, "setIsSettingsModalOpen");

export const SET_SETTINGS_START_TIME = createActionType(MODULE, "setSettingsStartTime");

export const SET_SETTINGS_END_TIME = createActionType(MODULE, "setSettingsEndTime");

export const SET_CREATE_CUSTOM_EVENT_STATUS = createActionType(
  MODULE,
  "setCreateCustomEventStatus"
);

export const SET_UPDATE_CUSTOM_EVENT_STATUS = createActionType(
  MODULE,
  "setUpdateCustomEventStatus"
);

export const SET_FETCH_CUSTOM_EVENTS_STATUS = createActionType(
  MODULE,
  "setFetchCustomEventsStatus"
);

export const SET_FETCH_CUSTOM_EVENT_STATUS = createActionType(MODULE, "setFetchCustomEventStatus");

export const SET_CUSTOM_EVENTS = createActionType(MODULE, "setCustomEvents");

export const SET_CUSTOM_EVENT = createActionType(MODULE, "setCustomEvent");

export const SET_ACTIVE_CUSTOM_EVENT = createActionType(MODULE, "setActiveCustomEvent");

export const SET_IS_CUSTOM_ASSIGN_USER_MENU_VISIBLE = createActionType(
  MODULE,
  "setIsCustomAssignUserMenuVisible"
);

export const SET_IS_CUSTOM_ASSIGN_LABEL_MENU_VISIBLE = createActionType(
  MODULE,
  "setIsCustomAssignLabelMenuVisible"
);

export const SET_IS_CUSTOM_EDIT_TIME_SLOT_DROPDOWN_VISIBLE = createActionType(
  MODULE,
  "setIsCustomEditTimeslotDropdownVisible"
);

export const SET_DUPLICATE_EVENT_STATUS = createActionType(MODULE, "setDuplicateEventStatus");

export const SET_DELETE_CUSTOM_EVENT_INSTANCE_STATUS = createActionType(
  MODULE,
  "setDeleteCustomEventInstanceStatus"
);

export const SET_DELETE_THIS_CUSTOM_EVENT_STATUS = createActionType(
  MODULE,
  "setDeleteThisCustomEventStatus"
);

export const SET_DELETE_THIS_AND_FOLLOWING_CUSTOM_EVENT_STATUS = createActionType(
  MODULE,
  "setDeleteThisAndFollowingCustomEventStatus"
);

export const SET_DELETE_ALL_CUSTOM_EVENT_STATUS = createActionType(
  MODULE,
  "setDeleteAllCustomEventStatus"
);

export const SET_UNDO_DELETE_CUSTOM_EVENT_INSTANCE_STATUS = createActionType(
  MODULE,
  "setUndoDeleteCustomEventInstanceStatus"
);

export const SET_UNDO_DELETE_THIS_CUSTOM_EVENT_STATUS = createActionType(
  MODULE,
  "setUndoDeleteThisCustomEventStatus"
);

export const SET_UNDO_DELETE_THIS_AND_FOLLOWING_CUSTOM_EVENT_STATUS = createActionType(
  MODULE,
  "setUndoDeleteThisAndFollowingCustomEventStatus"
);

export const SET_UNDO_DELETE_ALL_CUSTOM_EVENT_STATUS = createActionType(
  MODULE,
  "setUndoDeleteAllCustomEventStatus"
);

export const SET_CUSTOM_EVENT_DATA_TO_UPDATE = createActionType(
  MODULE,
  "setCustomEventDataToUpdate"
);

export const SET_CUSTOM_EVENT_REMINDERS = createActionType(MODULE, "setCustomEventReminders");

export const SET_CUSTOM_EVENT_REMINDER = createActionType(MODULE, "setCustomEventReminder");

export const RESET_CUSTOM_EVENT_TRANSACTION_STATE = createActionType(
  MODULE,
  "resetCustomEventTransactionState"
);

export const RESET = createActionType(MODULE, "reset");
