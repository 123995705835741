import { handleError } from "../utils/error";
import * as api from "../../apiv2/orderTimeline";

import {
  SET_IS_FETCHING_TIMELINES,
  SET_IS_FETCHING_TIMELINE_DETAILS,
  SET_TIMELINES,
  SET_TIMELINE_DETAILS,
  RESET,
} from "./orderTimeline.actionTypes";

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setIsFetchingTimelines = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_TIMELINES,
    payload: data,
  });
};

export const setIsFetchingTimelineDetails = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_TIMELINE_DETAILS,
    payload: data,
  });
};

export const setTimelines = (data) => (dispatch) => {
  dispatch({
    type: SET_TIMELINES,
    payload: data,
  });
};

export const setTimelineDetails = (data) => (dispatch) => {
  dispatch({
    type: SET_TIMELINE_DETAILS,
    payload: data,
  });
};

export const fetchTimelines = ({customerUid, orderUid, search = "", eventTypes = []}) => async (dispatch, getState) => {
  const state = getState();
  try {
    dispatch(setIsFetchingTimelines(true));
    const response = await api.getOrderTimelines({customerUid, orderUid, search, eventTypes})();
    dispatch(setTimelines(response));
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetchingTimelines(false));
  }
};

export const fetchTimelineDetails = ({customerUid, orderUid}) => async (dispatch) => {
  try {
    dispatch(setIsFetchingTimelineDetails(true));
    const response = await api.getOrderTimelineDetails({customerUid, orderUid})();
    dispatch(setTimelineDetails(response));
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetchingTimelineDetails(false));
  }
};
