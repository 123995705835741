import React from "react";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";
import PropTypes from "prop-types";
import cx from "classnames";

import styles from "./GoogleMapPlaceSearch.module.scss";

const GoogleMapPlaceSearch = ({ id, customStyles, value, ...props }) => {
  const handleChange = async (data) => {
    const { onChange, setFieldValue } = props;

    if (!data) {
      if (onChange) {
        onChange(id, "");
      }

      setFieldValue(id, "");
      return;
    }

    const response = await geocodeByPlaceId(data.value.place_id);

    if (onChange) {
      onChange(id, { ...data, geocodeByPlaceId: response.length > 0 ? response[0] : null });
    }

    setFieldValue(
      id,
      { ...data, geocodeByPlaceId: response.length > 0 ? response[0] : null } || ""
    );
  };

  return (
    <div
      className={cx(
        styles.wrapper,
        props.errors && props.touched[id] && props.errors[id] ? styles.selectError : ""
      )}
    >
      <GooglePlacesAutocomplete
        minLengthAutocomplete={2}
        selectProps={{
          styles: customStyles,
          value,
          onChange: handleChange,
          placeholder: "Search address...",
          isClearable: true,
          noOptionsMessage: () => "No address found",
        }}
        apiKey={process.env.GOOGLE_MAP_CLIENT_ID}
      />
      {props.errors && props.touched[id] && props.errors[id] && (
        <div className={styles.errorMessage}>{props.errors[id]}</div>
      )}
    </div>
  );
};

GoogleMapPlaceSearch.defaultProps = {
  value: null,
  setFieldValue: () => {},
  onChange: () => {},
  errors: {},
  touched: {},
};

GoogleMapPlaceSearch.propTypes = {
  value: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.shape({}).isRequired,
  }),
  errors: PropTypes.shape({}),
  touched: PropTypes.shape({}),
  setFieldValue: PropTypes.func,
  onChange: PropTypes.func,
  id: PropTypes.string.isRequired,
  customStyles: PropTypes.shape({}).isRequired,
};

export default GoogleMapPlaceSearch;
