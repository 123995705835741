import { MODULE } from "./add-labour-line-items.actionTypes";

export const isModalVisible = (state) => state.getIn([MODULE, "isModalVisible"]);

export const getTeamMembers = (state) => state.getIn([MODULE, "teamMembers"]);

export const isFetching = (state) => state.getIn([MODULE, "isFetching"]);

export const getCart = (state) => state.getIn([MODULE, "cart"]);

export const getKitType = (state) => state.getIn([MODULE, "kitType"]);

export default {};
