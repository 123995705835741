import React, { forwardRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Datepicker as ReactDatepicker } from "@mobiscroll/react";
import cx from "classnames";
import moment from "moment-timezone";

import styles from "./Timepicker.module.scss";

const Timepicker = forwardRef(({ className, onChange, placeholder, value, ...rest }, ref) => {
  const [timeValue, setTimeValue] = useState(
    value ? moment(value, "HH:mm").format("hh:mm A") : null
  );

  useEffect(() => {
    setTimeValue(value ? moment(value, "HH:mm").format("hh:mm A") : null);
  }, [value]);

  return (
    <div className={cx(styles.wrapper, className)}>
      <ReactDatepicker
        ref={ref}
        timeFormat="hh:mm A"
        themeVariant="light"
        controls={["time"]}
        theme="ios"
        stepMinute={1}
        value={timeValue}
        onChange={(data) => {
          setTimeValue(data.valueText);
        }}
        onClose={(data) => {
          setTimeValue(data.value);
          onChange(data.value);
        }}
        inputProps={{
          placeholder,
        }}
        {...rest}
      />
    </div>
  );
});

Timepicker.defaultProps = {
  className: "",
  placeholder: "",
  value: "",
};

Timepicker.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default Timepicker;
