import { fromJS, Iterable } from "immutable";

import { genId, isNew, isPositiveNumber } from "../../utils/common";
import { calcBBox } from "../../utils/calculations";

import { withMutations } from "./helpers/reducerWrappers";
import ReduxModule from "./abstract/ReduxModule";
import drawingsItems from "./drawings-items";
import drawingsProducts from "./drawings-products";
import prices from "./prices";
import * as helpers from "./helpers/drawing";
import { getCompanyTaxRate } from "./helpers/product";
import alerts from "./alerts";

// TODO need refactoring - combine actions for FrontArrow and SquareAngle

const ACTIONS = {
  CLEAR_ACTIVE_ORDER: "Clear active order",
  CREATE_NEW_ORDER: "Create new order",
  RESET_DRAWINGS: "Reset drawings",
  ADD_NEW_DRAWING: "Add new drawing",
  SAVE_DRAWING: "Save drawing",
  SET_ACTIVE_DRAWING: "Set active drawing",
  SET_LARGE_BOX_SIZE_PREVIEW_DRAWINGS: "Set preview drawings box size",
  DUPLICATE_DRAWING: "Duplicate drawing",
  SET_UIDS: "Set uids",
  SET_DATA_AFTER_SAVING: "Set data after saving drawings",
  OPEN_ORDER: "Load data from order",
  DELETE_DRAWING: "Delete drawing",
  COMBINING_POINT: "Combining point",
  UNDO_DELETE_DRAWING: "Undo delete drawing",
  SHIFT_DRAWING_POINTS: "Shift drawing points",
  SHIFT_LABEL: "Shift label",

  SET_MODE: "Set canvas mode",

  ADD_POINT: "Add point",
  MODIFY_POINT: "Modify point",
  SET_FINISH_POINT: "Set finish point",
  HIDE_ANGLE_LABEL: "Hide angle label",
  HIDE_LINE_LABEL: "Hide line label",
  DELETE_FINISH_POINT: "Delete finish point",
  FLIP_FINISH_POINT: "Flip finish point",
  MODIFY_DEGREES: "Modify degrees",
  MODIFY_LINES: "Modify lines",
  DELETE_POINT: "Delete point",
  DELETE_CONNECTION: "Delete connection",
  ADD_CONNECTION: "Add connection",
  CALC_TAPERS: "Calc tapers",

  ADD_TEXT_AREA: "Add text area",
  SET_ACTIVE_TEXT_AREA: "Set active textArea item",
  MODIFY_TEXT_AREA: "Modify text area",
  DELETE_TEXT_AREA: "Delete text area",

  ADD_FRONT_ARROW: "Add front arrow",
  MOVE_FRONT_ARROW: "Move front arrow",
  DELETE_FRONT_ARROW: "Delete front arrow",
  ROTATE_FRONT_ARROW: "Rotate front arrow",

  ADD_SQUARE_ANGLE: "Add square angle",
  MOVE_SQUARE_ANGLE: "Move square angle",
  DELETE_SQUARE_ANGLE: "Delete square angle",
  ROTATE_SQUARE_ANGLE: "Rotate square angle",
  DUPLICATE_DRAWING_TAPERED: "Duplicate drawing tapered",
};

export const CANVAS_MODE = {
  SELECT: "Select",
  DRAW: "Draw",
  PUT_FRONT_ARROW: "Put front arrow",
  DRAG_LINE: "Drag line",
  DRAG_ANCHOR: "Drag anchor",
  DRAG_ARROW: "Drag front arrow",
  DRAG_SQUARE: "Drag square angle",
  DRAG_TEXT_AREA: "Drag text area",
  FOCUS_TEXT_AREA: "Focus text area",
};

class DrawingsModule extends ReduxModule {
  getNamespace() {
    return "[Drawings]";
  }

  getInitialState() {
    return {
      activeDrawing: null, // initialItemState()
      startStateDrawings: null, // {items: [], itemsHash: {}}
      mode: CANVAS_MODE.SELECT,
      activeTextArea: "",
      items: [], // [this.initialItemState()]
      itemsHash: {},
      isChangedDrawing: false,
      dataServer: {
        isPending: false,
        items: [],
      },
      errors: {},
    };
  }

  initialItemState() {
    return {
      isDeleted: false,
      isCompleted: false,
      hasChanges: false,
      isLargeBoxSize: false,
      isFreeDrawing: true,
      uid: "",
      tempUid: genId(),
      index: null,
      totalGirth: 0,
      totalBends: 0,
      tapers: 0,
      canvasState: this.initialCanvasState(),
      otherSides: null, // [this.initialCanvasState()]
    };
  }

  initialTextAreaItemState() {
    return {
      x: null,
      y: null,
      width: 85,
      height: 45,
      value: "",
    };
  }

  initialCanvasState() {
    return {
      zoom: 2,
      type: "Far side",
      points: {}, // [id]: {
      //    id: id,
      //    x: num, y: num,
      //    connect: [],
      //    vectors: [],
      //    finish: {type: str, size: num, label: str, flip: bool, position: {x, y, width, height}|null}|null
      // }
      frontArrow: {
        x: null,
        y: null,
        angle: 0,
      },
      squareAngle: {
        x: null,
        y: null,
      },
      textArea: {},
      linesValuesAuto: {
        values: {}, // {[lineStart-lineEnd]: number}
        positions: {}, // {point || [lineStart-lineEnd]: {x, y}}
        indexes: {}, // {[point || lineStart-lineEnd]: {x, y}}
      },
      anglesValuesAuto: {
        values: {}, // {[point]: {angle: number, v1, v2}}
        positions: {}, // {[point]: {x, y}}
        indexes: {}, // {[point]: {x, y}}
      },
      linesValuesFree: {
        values: {}, // {[lineStart-lineEnd]: {size: number|null, hidden: false|true}}
        positions: {}, // {[lineStart-lineEnd]: {x, y, width, height}|null}
      },
      anglesValuesFree: {
        values: {}, // {[point]: {angle: number, hidden: true|null}}
        positions: {}, // {[point]: {x, y, width, height}|null}
      },
      maxIndex: null,
      numberOfBendsAuto: 0,
      totalGirthAuto: 0,
      numberOfBendsFree: 0,
      totalGirthFree: 0,
    };
  }

  generateOrderDataForServer(state, items) {
    let errorsAll = [];
    const isAutoSaving = state.getIn(["orders", "saving", "auto"]);
    const itemsForServer = [];
    let deletedItems = 0;

    const drawings = state.getIn(["drawings", "items"]);

    items.forEach((item) => {
      const errors = [];

      const tempUid = item.item_data.temp_uid_drawing;
      const id = state.getIn(["drawings", "itemsHash", tempUid]);
      const drawing = drawings.get(id);

      const isFreeDrawing = drawing.get("isFreeDrawing");
      const { totalGirthDrawing, lines, angles, points, frontArrow, squareAngle, textArea } =
        this.prepareCanvasData(drawing.get("canvasState"));

      if (
        JSON.stringify(lines).length > 10000 ||
        JSON.stringify(angles).length > 10000 ||
        JSON.stringify(points).length > 10000 ||
        Object.keys(points).length > 102
      ) {
        errors.push("contains too many bends");
      }

      const otherSides = drawing.get("otherSides")
        ? drawing.get("otherSides").map((side) => {
            const {
              lines: linesSide,
              angles: anglesSide,
              points: pointsSide,
              frontArrow: arrowSide,
              squareAngle: squareSide,
              textArea: textAreaSide,
            } = this.prepareCanvasData(side);

            const sideForServer = {
              uid: side.get("uid"),
              type: "Near side",
              is_deleted: drawing.get("isDeleted"),
              is_free_drawing: isFreeDrawing,
              lines: linesSide,
              angles: anglesSide,
              points: pointsSide,
              front_arrow: arrowSide,
              square_angle: squareSide,
              text_area: textAreaSide,
            };

            if (!sideForServer.uid) {
              return {
                ...sideForServer,
                uid: "",
                temp_uid: side.get("tempUid"),
              };
            }

            return sideForServer;
          })
        : [];

      if (!isAutoSaving) {
        const totalGirthSideEmpty =
          otherSides.length && otherSides.filter((side) => side.totalGirthDrawing < 1);

        if (drawing.get("isDeleted")) {
          deletedItems += 1;
        } else if (totalGirthDrawing < 1 || totalGirthSideEmpty) {
          errors.push("Drawing is empty");
        }
      }

      if (errors.length > 0) {
        const errorsItem = { [`Drawing #${id + 1}: `]: errors };
        errorsAll.push(errorsItem);
        return errors;
      }

      const totalBends = drawing.get("totalBends");
      const totalGirth = drawing.get("totalGirth");
      item.item_data.bends = `${totalBends}`;
      item.item_data.total_girth = `${totalGirth}`;

      item.drawing = {
        uid: drawing.get("uid"),
        type: drawing.get("type") || "Far side",
        drawing_number: drawing.get("index"),
        is_free_drawing: isFreeDrawing,
        is_deleted: drawing.get("isDeleted"),
        is_completed: drawing.get("isCompleted"),
        is_large_box_size: drawing.get("isLargeBoxSize"),
        points,
        front_arrow: frontArrow,
        square_angle: squareAngle,
        text_area: textArea,
        lines,
        angles,
      };

      if (!item.drawing.uid) {
        item.drawing.temp_uid = tempUid;
      }

      item.other_sides = otherSides;

      delete item.item_data.temp_uid_drawing;
      itemsForServer.push(item);
      return null;
    });

    if (
      !isAutoSaving &&
      ((deletedItems > 0 && deletedItems === itemsForServer.length) || !items.length)
    ) {
      errorsAll = ["Empty drawings"];
    }

    return {
      errors: errorsAll,
      data: itemsForServer,
    };
  }

  prepareCanvasData = (canvas) => {
    const canvasData = canvas.toJS();
    const totalGirthDrawing = canvasData.totalGirthFree;
    const textArea = canvasData.textArea;
    const lines = canvasData.linesValuesFree;
    lines.positions = this.prepareCanvasDataPositions(lines.positions);

    const angles = canvasData.anglesValuesFree;
    angles.positions = this.prepareCanvasDataPositions(angles.positions);

    const points = Object.keys(canvasData.points).reduce((prevPositions, pointId) => {
      const { x, y, finish } = canvasData.points[pointId];

      prevPositions[pointId] = {
        ...canvasData.points[pointId],
        x,
        y,
      };

      if (finish && finish.position) {
        const { x: finishX, y: finishY, width, height } = prevPositions[pointId].finish.position;

        prevPositions[pointId].finish.position = {
          x: finishX,
          y: finishY,
          width: Math.round(width),
          height: Math.round(height),
        };
      }

      return prevPositions;
    }, {});

    const frontArrow = { ...canvasData.frontArrow };

    if (frontArrow.x != null && frontArrow.y != null) {
      frontArrow.x = Math.round(frontArrow.x);
      frontArrow.y = Math.round(frontArrow.y);
    }

    const squareAngle = { ...canvasData.squareAngle };

    if (squareAngle.x != null && squareAngle.y != null) {
      squareAngle.x = Math.round(squareAngle.x);
      squareAngle.y = Math.round(squareAngle.y);
    }

    return {
      totalGirthDrawing,
      lines,
      angles,
      points,
      frontArrow,
      squareAngle,
      textArea,
    };
  };

  prepareCanvasDataPositions = (positions) =>
    Object.keys(positions).reduce((prevPositions, pointId) => {
      const { x, y, width, height } = positions[pointId];

      prevPositions[pointId] = {
        ...positions[pointId],
        x: Math.round(x),
        y: Math.round(y),
        width: Math.round(width),
        height: Math.round(height),
      };

      return prevPositions;
    }, {});

  generateOrderDataFromServer(drawingsData, customPriceBends) {
    const itemsHash = {};
    const items = [];

    const canvasState = (canvas) => {
      const {
        type,
        points,
        lines: linesValuesFree,
        angles: anglesValuesFree,
        front_arrow: frontArrow,
        square_angle: squareAngle,
        text_area: textArea,
      } = canvas;
      const startState = this.initialItemState();
      const { linesValuesAuto, anglesValuesAuto, maxIndex, numberOfBendsAuto, totalGirthAuto } =
        helpers.calcLinesDataAuto(canvas.points, startState.canvasState.zoom, customPriceBends);

      let numberOfBendsFree = 0;
      let totalGirthFree = 0;

      if (canvas.is_free_drawing) {
        const { numberOfBendsFree: numberOfBends, totalGirthFree: totalGirth } =
          helpers.calcLinesDataFree(points, linesValuesFree, linesValuesAuto, customPriceBends);
        numberOfBendsFree = numberOfBends;
        totalGirthFree = totalGirth;
      }

      return {
        startState,
        canvasState: {
          ...startState.canvasState,
          type,
          points,
          frontArrow,
          squareAngle,
          textArea,

          numberOfBendsAuto,
          totalGirthAuto,
          linesValuesAuto,
          anglesValuesAuto,
          maxIndex,

          numberOfBendsFree,
          totalGirthFree,
          linesValuesFree,
          anglesValuesFree,
        },
      };
    };

    drawingsData.sort((a, b) => a.drawing_number - b.drawing_number);

    drawingsData.map((item) => {
      if (itemsHash[item.uid] != null) {
        return item;
      }

      const index = items.length;
      itemsHash[item.uid] = index;

      const { startState, canvasState: mainCanvas } = canvasState(item);
      const otherSides = item.other_sides.map((side) => ({
        ...canvasState(side).canvasState,
        uid: side.uid,
        tempUid: side.uid,
        mainDrawing: side.main_drawing,
      }));

      const isFreeDrawing = item.is_free_drawing;
      const isLargeBoxSize = item.is_large_box_size;
      const { totalGirth, totalBends } = helpers.calcTotalGirth(
        mainCanvas,
        otherSides,
        isFreeDrawing
      );

      const drawing = {
        ...startState,
        totalGirth,
        totalBends,
        hasChanges: false,
        isFreeDrawing,
        isLargeBoxSize,
        uid: item.uid,
        tempUid: item.uid,
        index,
        canvasState: {
          ...mainCanvas,
          type: "Far side",
        },
        otherSides,
        isChangedDrawing: false,
      };

      items.push(drawing);
      return drawing;
    });

    return {
      itemsHash,
      items,
    };
  }

  createNewOrder = (_state) => {
    _state.merge(fromJS(this.getInitialState()));
  };

  setUids = (_state, data) => {
    const keys = Object.keys(data);

    keys.forEach((uid) => {
      const drawing = data[uid];
      _state.setIn(["items", drawing.id, "uid"], uid);

      if (drawing.otherSides.length) {
        _state.updateIn(["items", drawing.id, "otherSides"], (list) =>
          list.map((side, index) => side.set("uid", drawing.otherSides[index]))
        );
      }
    });

    const activeDrawingTempUid = _state.getIn(["activeDrawing", "tempUid"]);

    if (activeDrawingTempUid) {
      const hashId = _state.getIn(["itemsHash", activeDrawingTempUid]);

      _state.setIn(["activeDrawing", "uid"], _state.getIn(["items", hashId, "uid"]));
    }
  };

  openOrder = (_state, data) => {
    _state.merge(fromJS(this.getInitialState()));
    let { items, itemsHash } = data;

    items.forEach((item, index) => {
      if (item.canvasState.textArea) {
        items[index].canvasState.textArea =
          item.canvasState.textArea.value === "" ? {} : item.canvasState.textArea;
      }

      if (item.otherSides.length) {
        if (item.otherSides[0].textArea) {
          items[index].otherSides[0].textArea =
            item.otherSides[0].textArea.value === "" ? {} : item.otherSides[0].textArea;
        }
      }

      return item;
    });

    const startStateDrawings = fromJS({
      items,
      itemsHash,
    });
    items = fromJS(items);
    itemsHash = fromJS(itemsHash);

    _state.set("items", items);
    _state.set("itemsHash", itemsHash);
    _state.set("startStateDrawings", startStateDrawings);
  };

  resetDrawings = (_state) => {
    const startStateDrawings = _state.get("startStateDrawings");
    _state.set("isChangedDrawing", false);

    if (startStateDrawings) {
      _state.set("items", startStateDrawings.get("items"));
      _state.set("itemsHash", startStateDrawings.get("itemsHash"));
    } else {
      _state.set("items", fromJS([]));
      _state.set("itemsHash", fromJS({}));
    }
  };

  setDataAfterSaving = (_state) => {
    const startStateDrawings = _state.get("startStateDrawings");

    if (!startStateDrawings) {
      _state.set("startStateDrawings", fromJS({}));
    }

    _state.setIn(["startStateDrawings", "items"], _state.get("items"));
    _state.setIn(["startStateDrawings", "itemsHash"], _state.get("itemsHash"));
  };

  addNewDrawing = (_state, { isTapered, isPrevEmpty, index }) => {
    let newDrawing = this.initialItemState();
    newDrawing.index = index;

    if (isTapered) {
      const newCanvas = this.initialCanvasState();
      newCanvas.type = "Near side";
      newCanvas.uid = "";
      newCanvas.tempUid = genId();
      newDrawing.otherSides = [newCanvas];
    }

    newDrawing = fromJS(newDrawing);

    if (isTapered && isPrevEmpty) {
      const itemsHash = {};

      _state.update("items", (list) => {
        const newList = list.splice(index, 0, newDrawing);
        return newList.map((item, id) => {
          itemsHash[item.get("tempUid")] = id;
          return item.set("index", id);
        });
      });

      _state.set("itemsHash", fromJS(itemsHash));
    } else {
      _state.setIn(["itemsHash", newDrawing.get("tempUid")], index);
      _state.update("items", (list) => list.push(newDrawing));
    }

    _state.set("activeDrawing", newDrawing);
  };

  saveDrawing = (_state, indexDrawing) => {
    const activeDrawing = _state.get("activeDrawing");
    const index = indexDrawing != null || activeDrawing.get("index");

    if (index === activeDrawing.get("index") && activeDrawing.get("hasChanges")) {
      let drawing = activeDrawing.set("hasChanges", false);

      if (drawing.getIn(["canvasState", "textArea"]).size) {
        drawing = drawing.updateIn(["canvasState", "textArea"], (list) =>
          list.filter((item) => item !== null)
        );

        if (drawing.getIn(["otherSides"]) && drawing.getIn(["otherSides"]).size) {
          drawing = drawing.updateIn(["otherSides", 0, "textArea"], (list) =>
            list.filter((item) => item !== null)
          );
        }
      }

      _state.setIn(["isChangedDrawing"], false);
      _state.mergeIn(["items", index], drawing);
    }
  };

  duplicateDrawing = (_state, newDrawing) => {
    const lastIndex = _state.get("items").size;
    newDrawing = newDrawing.set("index", lastIndex);
    newDrawing = newDrawing.set("tempUid", genId());
    newDrawing = newDrawing.set("uid", "");

    if (newDrawing.get("otherSides")) {
      newDrawing = newDrawing.update("otherSides", (list) =>
        list.map((side) => side.delete("mainDrawing").merge({ uid: "", tempUid: genId() }))
      );
    }

    _state.setIn(["itemsHash", newDrawing.get("tempUid")], lastIndex);
    _state.update("items", (list) => list.push(newDrawing));
  };

  deleteDrawing = (_state, deletedDrawing) => {
    const index = deletedDrawing.get("index");

    if (isNew(deletedDrawing)) {
      _state.deleteIn(["items", index]);
      const itemsHash = {};
      const items = _state.get("items").map((item, i) => {
        itemsHash[item.get("tempUid")] = i;
        return item.set("index", i);
      });

      _state.set("items", fromJS(items));
      _state.set("itemsHash", fromJS(itemsHash));
    } else {
      _state.setIn(["items", index, "isDeleted"], true);
    }

    _state.set("activeDrawing", null);
  };

  undoDeleteDrawing = (_state, deletedDrawing) => {
    const index = deletedDrawing.get("index");

    if (isNew(deletedDrawing)) {
      const itemsHash = {};

      _state.update("items", (list) => {
        const newList = list.splice(index, 0, deletedDrawing);
        return newList.map((item, i) => {
          itemsHash[item.get("tempUid")] = i;
          return item.set("index", i);
        });
      });

      _state.set("itemsHash", fromJS(itemsHash));
    } else {
      _state.setIn(["items", index, "isDeleted"], false);
    }
  };

  /* THUNK ACTIONS */

  resetDrawingsThunk = ({ dispatch, fulfilled }) => {
    fulfilled();

    dispatch(drawingsProducts.actions.resetDrawings());
    dispatch(drawingsItems.actions.resetDrawings());
    dispatch(prices.actions.resetDrawings());
    const state = getState();
    const companyTaxRate = getCompanyTaxRate(state);

    dispatch(prices.actions.updatePricesOrder(companyTaxRate));
  };

  setDataAfterSavingThunk = ({ dispatch, fulfilled }) => {
    fulfilled();

    dispatch(drawingsProducts.actions.setDataAfterSavingDrawings());
    dispatch(drawingsItems.actions.setDataAfterSavingDrawings());
    dispatch(prices.actions.setDataAfterSavingDrawings());
  };

  addNewDrawingThunk = ({ dispatch, getState, fulfilled }, isTapered) => {
    const oldDrawing = getState().getIn(["drawings", "activeDrawing"]);

    let promiseAllProducts = Promise.resolve();
    const drawingProducts = getState().getIn(["drawingsProducts", "dataServer", "items"]).size;

    if (!drawingProducts) {
      promiseAllProducts = dispatch(drawingsProducts.actions.searchProducts());
    }

    return promiseAllProducts.then(() => {
      let isPrevEmpty = false;
      let index = getState().getIn(["drawings", "items"]).size;

      if (isTapered) {
        const isMainCanvasEmpty = !oldDrawing.getIn(["canvasState", "totalGirthAuto"]);
        const otherSides = oldDrawing.get("otherSides");
        const isSidesCanvasEmpty = !otherSides || !otherSides.size;

        if (isMainCanvasEmpty && isSidesCanvasEmpty) {
          isPrevEmpty = true;
          index = oldDrawing.get("index");

          dispatch(
            this.actions.deleteDrawing({
              drawing: oldDrawing.toJS(),
              replaceEmptyDrawing: isTapered,
            })
          );
        }
      }

      fulfilled({ isTapered, isPrevEmpty, index });

      const tempUid = getState().getIn(["drawings", "activeDrawing", "tempUid"]);
      const options = {
        drawingTempUid: oldDrawing ? oldDrawing.get("tempUid") : null,
        newDrawingTempUid: tempUid,
      };
      dispatch(drawingsItems.actions.addNewDrawing(tempUid));
      dispatch(prices.actions.addNewDrawing(options));
      dispatch(drawingsProducts.actions.addNewDrawing(options));

      const priceLevelCustomer = getState().getIn(["customers", "activeCustomer", "priceLevel"]);

      if (priceLevelCustomer) {
        const allProducts = getState().getIn(["drawingsProducts", "dataServer", "items"]);
        let products = allProducts.filter(
          (product) => product.get("priceLevel") === priceLevelCustomer
        );
        products = products.toJS();

        if (products.length) {
          dispatch(
            drawingsProducts.actions.getProductWithSomePrevOptions({
              changedField: "priceLevel",
              value: priceLevelCustomer,
              products,
            })
          );
        }
      }
    });
  };

  duplicateDrawingThunk = ({ dispatch, getState, fulfilled }, { index }) => {
    const drawing = getState().getIn(["drawings", "items", index]);
    fulfilled(drawing);

    const items = getState().getIn(["drawings", "items"]);
    const newDrawing = items.get(items.size - 1);
    const options = {
      drawingTempUid: drawing.get("tempUid"),
      newDrawingTempUid: newDrawing.get("tempUid"),
    };
    dispatch(prices.actions.duplicateDrawing(options));
    dispatch(drawingsProducts.actions.duplicateDrawing(options));

    dispatch(drawingsItems.actions.duplicateDrawing({ ...options, withLengths: false }));

    dispatch(this.actions.setActiveDrawing(newDrawing));

    dispatch(prices.actions.setPricesDrawing());
  };

  deleteDrawingThunk = (
    { dispatch, getState, fulfilled },
    { drawing: { index }, replaceEmptyDrawing, addNewIsEmptyItems = false }
  ) => {
    const isDrawingOpen = !!getState().getIn(["drawings", "activeDrawing"]);
    const drawing = getState().getIn(["drawings", "items", index]);

    fulfilled(drawing);

    const tempUid = drawing.get("tempUid");

    const pricesItems = getState().getIn(["prices", "items", tempUid]);
    const drawingsProductsItems = getState().getIn(["drawingsProducts", "items", tempUid]);
    const lengthsItems = getState().getIn(["drawingsItems", "items", tempUid]);

    const payload = { drawingTempUid: tempUid, isNewDrawing: isNew(drawing) };
    dispatch(prices.actions.deleteDrawing(payload));
    dispatch(drawingsProducts.actions.deleteDrawing(payload));
    dispatch(drawingsItems.actions.deleteDrawing(payload));

    if (isDrawingOpen) {
      const items = getState().getIn(["drawings", "items"]);
      let activeDrawing;

      // find next undeleted drawing
      for (let i = index; i < items.size; i++) {
        const item = items.get(i);

        if (!item.get("isDeleted")) {
          activeDrawing = item;
          break;
        }
      }

      if (!activeDrawing) {
        // find prev undeleted drawing
        for (let i = index - 1; i >= 0; i--) {
          const item = items.get(i);

          if (!item.get("isDeleted")) {
            activeDrawing = item;
            break;
          }
        }
      }

      if (activeDrawing) {
        dispatch(this.actions.setActiveDrawing(activeDrawing));
      } else if (addNewIsEmptyItems) {
        dispatch(this.actions.addNewDrawing());
      }
    }

    if (!replaceEmptyDrawing) {
      dispatch(prices.actions.setPricesDrawing());
    }

    return new Promise((resolve) => {
      dispatch(
        alerts.actions.addAlert({
          type: "danger",
          message: "Drawing deleted",
          closeDelay: 5000,
          action: {
            label: "Undo",
            callback: () => {
              dispatch(
                this.actions.undoDeleteDrawing({
                  drawing,
                  pricesItems,
                  drawingsProductsItems,
                  lengthsItems,
                })
              );

              resolve();
            },
          },
        })
      );
    });
  };

  undoDeleteDrawingThunk = (
    { dispatch, fulfilled },
    { drawing, pricesItems, drawingsProductsItems, lengthsItems }
  ) => {
    fulfilled(drawing);
    const tempUid = drawing.get("tempUid");
    const payload = { drawingTempUid: tempUid, isNewDrawing: isNew(drawing) };

    dispatch(prices.actions.undoDeleteDrawing({ ...payload, items: pricesItems }));

    dispatch(
      drawingsProducts.actions.undoDeleteDrawing({
        ...payload,
        items: drawingsProductsItems,
      })
    );

    dispatch(
      drawingsItems.actions.undoDeleteDrawing({
        ...payload,
        items: lengthsItems,
      })
    );

    dispatch(prices.actions.setPricesDrawing());
  };

  setActiveDrawingThunk = ({ getState, dispatch, fulfilled }, drawing, activeUid) => {
    let payload = null;

    if (drawing) {
      payload = Iterable.isIterable(drawing) ? drawing : fromJS(drawing);
    }

    fulfilled(payload);

    const tempUid = payload ? payload.get("tempUid") : null;
    dispatch(drawingsProducts.actions.setDrawingTempUid(tempUid));
    dispatch(prices.actions.setDrawingTempUid(tempUid));
    dispatch(drawingsItems.actions.setDrawingTempUid(tempUid));

    if (payload) {
      const activeDrawing = getState().getIn(["drawings", "activeDrawing"]);

      if (activeDrawing.get("otherSides") && activeDrawing.get("otherSides").size) {
        dispatch(this.actions.calcTapers());
      }

      const product = getState().getIn(["drawingsProducts", "activeProduct"]);
      const price = getState().getIn(["prices", "activeDrawingPrices", "data"]);

      if (product.get("uid") && !price.size) {
        dispatch(drawingsProducts.actions.checkActiveProductRelevance());
      } else if (!price.size) {
        dispatch(drawingsProducts.actions.searchProducts());

        if (activeUid) {
          const prevOptions = getState()
            .getIn(["drawingsProducts", "items", activeUid, "product"])
            .toJS();

          dispatch(
            drawingsProducts.actions.getProductWithSomePrevOptions({
              prevOptions,
            })
          );
        }
      }
    }
  };

  changeDrawingPoints = ({ getState, dispatch, fulfilled }, values) => {
    const { canvasIndex, id, x, y } = values;
    const activeDrawing = getState().getIn(["drawings", "activeDrawing"]);
    const canvasState =
      canvasIndex != null && canvasIndex > -1
        ? activeDrawing.getIn(["otherSides", canvasIndex])
        : activeDrawing.get("canvasState");

    const drawingsSettings = getState()
      .getIn(["customers", "company", "settings", "drawingsSettings", "drawings"])
      .toJS();
    const customPriceBends =
      drawingsSettings.find((setting) => setting.key === "CRUSH_AND_FOLD_BEND_NUMBER")?.value ?? 0;
    const { points } = canvasState.toJS();
    const point = Object.keys(points).length && points[id] ? points[id] : {};
    point.x = x;
    point.y = y;
    let deletePoint = null;

    if (point && points) {
      Object.keys(points).forEach((item) => {
        if (
          points[item].x === point.x &&
          points[item].y === point.y &&
          points[item].id !== point.id
        ) {
          deletePoint = point;
        }
      });
    }

    if (deletePoint) {
      dispatch(
        this.actions.combiningPoint({
          id: deletePoint.id,
          canvasState: canvasState.toJS(),
          isFreeDrawing: activeDrawing.get("isFreeDrawing"),
        })
      );

      if (activeDrawing.get("otherSides") && activeDrawing.get("otherSides").size) {
        dispatch(this.actions.calcTapers());
      }

      return dispatch(prices.actions.setPricesDrawing());
    }

    fulfilled({
      ...values,
      customPriceBends: parseFloat(customPriceBends),
      canvasState: canvasState.toJS(),
      isFreeDrawing: activeDrawing.get("isFreeDrawing"),
    });

    if (activeDrawing.get("otherSides") && activeDrawing.get("otherSides").size) {
      dispatch(this.actions.calcTapers());
    }

    return dispatch(prices.actions.setPricesDrawing());
  };

  changeDrawingDirection = ({ getState, fulfilled }, values) => {
    const { canvasIndex, id } = values;
    const activeDrawing = getState().getIn(["drawings", "activeDrawing"]);
    const canvasState =
      canvasIndex != null && canvasIndex > -1
        ? activeDrawing.getIn(["otherSides", canvasIndex])
        : activeDrawing.get("canvasState");

    fulfilled({
      itemId: id,
      ...values,
      canvasState: canvasState.toJS(),
      isFreeDrawing: activeDrawing.get("isFreeDrawing"),
    });
  };

  shiftDrawingLabel = ({ getState, fulfilled }, values) => {
    const { canvasIndex, type, position, pointId } = values;
    const activeDrawing = getState().getIn(["drawings", "activeDrawing"]);
    let canvasState =
      canvasIndex != null && canvasIndex > -1
        ? activeDrawing.getIn(["otherSides", canvasIndex])
        : activeDrawing.get("canvasState");
    canvasState = canvasState.toJS();

    if (type === "line") {
      canvasState.linesValuesFree.positions[pointId] = position;
    } else if (type === "finish") {
      canvasState.points[pointId].finish.position = position;
    } else if (type === "anchor") {
      canvasState.anglesValuesFree.positions[pointId] = position;
    }

    fulfilled({
      canvasIndex,
      canvasState,
    });
  };

  defineActions() {
    const clearActiveOrder = this.resetToInitialState(ACTIONS.CLEAR_ACTIVE_ORDER);
    const createNewOrder = this.createAction(ACTIONS.CREATE_NEW_ORDER);
    const resetChanges = this.thunkAction(ACTIONS.RESET_DRAWINGS, this.resetDrawingsThunk);
    const setUids = this.createAction(ACTIONS.SET_UIDS);
    const setDataAfterSaving = this.thunkAction(
      ACTIONS.SET_DATA_AFTER_SAVING,
      this.setDataAfterSavingThunk
    );
    const addNewDrawing = this.thunkAction(ACTIONS.ADD_NEW_DRAWING, this.addNewDrawingThunk);
    const saveDrawing = this.createAction(ACTIONS.SAVE_DRAWING);
    const setActiveDrawing = this.thunkAction(
      ACTIONS.SET_ACTIVE_DRAWING,
      this.setActiveDrawingThunk
    );
    const duplicateDrawing = this.thunkAction(
      ACTIONS.DUPLICATE_DRAWING,
      this.duplicateDrawingThunk
    );
    const openOrder = this.createAction(ACTIONS.OPEN_ORDER);
    const deleteDrawing = this.thunkAction(ACTIONS.DELETE_DRAWING, this.deleteDrawingThunk);
    const undoDeleteDrawing = this.thunkAction(
      ACTIONS.UNDO_DELETE_DRAWING,
      this.undoDeleteDrawingThunk
    );
    const setMode = this.set(ACTIONS.SET_MODE, "mode");
    const setActiveTextArea = this.set(ACTIONS.SET_ACTIVE_TEXT_AREA, "activeTextArea");

    const addPoint = this.thunkAction(ACTIONS.ADD_POINT, this.changeDrawingPoints);
    const deletePoint = this.thunkAction(ACTIONS.DELETE_POINT, this.changeDrawingPoints);
    const combiningPoint = this.createAction(ACTIONS.COMBINING_POINT);
    const deleteConnection = this.thunkAction(ACTIONS.DELETE_CONNECTION, this.changeDrawingPoints);
    const addConnection = this.thunkAction(ACTIONS.ADD_CONNECTION, this.changeDrawingPoints);
    const modifyLineLength = this.thunkAction(ACTIONS.MODIFY_LINES, this.changeDrawingPoints);
    const setFinishPoint = this.thunkAction(ACTIONS.SET_FINISH_POINT, this.changeDrawingPoints);
    const modifyAnchorAngle = this.thunkAction(ACTIONS.MODIFY_DEGREES, this.changeDrawingPoints);
    const deleteFinishPoint = this.thunkAction(
      ACTIONS.DELETE_FINISH_POINT,
      this.changeDrawingPoints
    );
    const flipFinish = this.thunkAction(ACTIONS.FLIP_FINISH_POINT, this.changeDrawingPoints);
    const modifyPoint = this.thunkAction(ACTIONS.MODIFY_POINT, this.changeDrawingPoints);
    const switchAngleLabel = this.thunkAction(ACTIONS.HIDE_ANGLE_LABEL, this.changeDrawingPoints);
    const switchLineLabel = this.thunkAction(ACTIONS.HIDE_LINE_LABEL, this.changeDrawingPoints);
    const shiftDrawingPoints = this.createAction(ACTIONS.SHIFT_DRAWING_POINTS);
    const shiftLabel = this.thunkAction(ACTIONS.SHIFT_LABEL, this.shiftDrawingLabel);
    const calcTapers = this.createAction(ACTIONS.CALC_TAPERS);

    const addTextArea = this.thunkAction(ACTIONS.ADD_TEXT_AREA, this.changeDrawingDirection);
    const modifyTextArea = this.thunkAction(ACTIONS.MODIFY_TEXT_AREA, this.changeDrawingDirection);
    const deleteTextArea = this.thunkAction(ACTIONS.DELETE_TEXT_AREA, this.changeDrawingDirection);

    const addFrontArrow = this.thunkAction(ACTIONS.ADD_FRONT_ARROW, this.changeDrawingDirection);
    const moveFrontArrow = this.thunkAction(ACTIONS.MOVE_FRONT_ARROW, this.changeDrawingDirection);
    const deleteFrontArrow = this.thunkAction(
      ACTIONS.DELETE_FRONT_ARROW,
      this.changeDrawingDirection
    );
    const rotateFrontArrow = this.thunkAction(
      ACTIONS.ROTATE_FRONT_ARROW,
      this.changeDrawingDirection
    );

    const addSquareAngle = this.thunkAction(ACTIONS.ADD_SQUARE_ANGLE, this.changeDrawingDirection);
    const moveSquareAngle = this.thunkAction(
      ACTIONS.MOVE_SQUARE_ANGLE,
      this.changeDrawingDirection
    );
    const deleteSquareAngle = this.thunkAction(
      ACTIONS.DELETE_SQUARE_ANGLE,
      this.changeDrawingDirection
    );
    const rotateSquareAngle = this.thunkAction(
      ACTIONS.ROTATE_SQUARE_ANGLE,
      this.changeDrawingDirection
    );

    const duplicateDrawingTapered = this.createAction(ACTIONS.DUPLICATE_DRAWING_TAPERED);
    const setLargeBoxSize = this.createAction(ACTIONS.SET_LARGE_BOX_SIZE_PREVIEW_DRAWINGS);

    return {
      clearActiveOrder,
      createNewOrder,
      resetChanges,
      setUids,
      setLargeBoxSize,
      setDataAfterSaving,
      addNewDrawing,
      saveDrawing,
      setActiveDrawing,
      duplicateDrawing,
      openOrder,
      deleteDrawing,
      undoDeleteDrawing,
      shiftDrawingPoints,
      shiftLabel,

      setMode,

      addPoint,
      deletePoint,
      deleteConnection,
      addConnection,
      modifyLineLength,
      setFinishPoint,
      switchAngleLabel,
      switchLineLabel,
      deleteFinishPoint,
      flipFinish,
      modifyPoint,
      modifyAnchorAngle,
      calcTapers,

      addTextArea,
      setActiveTextArea,
      modifyTextArea,
      deleteTextArea,

      addFrontArrow,
      moveFrontArrow,
      deleteFrontArrow,
      rotateFrontArrow,

      addSquareAngle,
      moveSquareAngle,
      deleteSquareAngle,
      rotateSquareAngle,

      duplicateDrawingTapered,
      combiningPoint,
    };
  }

  /** REDUCERS * */
  onAddPoint = (_state, payload) => {
    const { id, x, y, previousId, canvasState, canvasIndex, isFreeDrawing, customPriceBends } =
      payload;

    const newPoint = {
      id,
      x,
      y,
      vectors: [],
      connect: [],
    };

    const { points } = canvasState;
    points[id] = newPoint;

    if (previousId) {
      const prevPoint = points[previousId];
      const maxNeighbors = helpers.getNeighborsIds(points, previousId);

      if (maxNeighbors.length < 2) {
        newPoint.connect.push(previousId);
        prevPoint.vectors.push(id);
      }
    }

    helpers.updateLinesData(_state, {
      canvasState,
      canvasIndex,
      isFreeDrawing,
      customPriceBends,
    });
  };

  onAddConnection = (_state, payload) => {
    const { currentId, previousId, canvasState, canvasIndex, isFreeDrawing, customPriceBends } =
      payload;

    const { points } = canvasState;

    const headPoint = points[currentId];
    const tailPoint = points[previousId];
    headPoint.vectors.push(previousId);
    tailPoint.connect.push(currentId);

    helpers.updateLinesData(_state, {
      canvasState,
      canvasIndex,
      isFreeDrawing,
      customPriceBends,
    });
  };

  onDeletePoint = (_state, payload) => {
    const { id, isFreeDrawing, canvasState, canvasIndex, customPriceBends } = payload;

    const { points, anglesValuesFree, linesValuesFree, linesValuesAuto } = canvasState;

    // remove point itself
    const point = points[id];
    delete points[id];

    // remove all links to that point
    point.vectors.forEach((vectId) => {
      points[vectId].connect = points[vectId].connect.filter((connId) => connId !== id);
    });

    point.connect.forEach((connId) => {
      const connect = points[connId];
      connect.vectors = connect.vectors.filter((vectId) => vectId !== id);
    });

    const deleted = Object.keys(linesValuesAuto.positions).filter((lineIds) => {
      if (lineIds.includes(id)) {
        const lineIdsFreeValues = helpers.getLinePointKeyByProperty(
          linesValuesFree,
          "values",
          lineIds
        );
        const lineIdsFreePositions = helpers.getLinePointKeyByProperty(
          linesValuesFree,
          "positions",
          lineIds
        );
        delete linesValuesFree.values[lineIdsFreeValues];
        delete linesValuesFree.positions[lineIdsFreePositions];
        return true;
      }

      return false;
    });

    deleted.forEach((lineIds) => {
      const line = lineIds.split("-");
      const [currentId, nextId] = line;

      if (anglesValuesFree.positions[currentId] || anglesValuesFree.values[currentId]) {
        delete anglesValuesFree.values[currentId];
        delete anglesValuesFree.positions[currentId];
      }

      if (anglesValuesFree.positions[nextId] || anglesValuesFree.values[nextId]) {
        delete anglesValuesFree.values[nextId];
        delete anglesValuesFree.positions[nextId];
      }
    });

    // delete endpoint

    helpers.cleanupPointsWithoutConnections(points);

    helpers.updateLinesData(_state, {
      canvasState,
      canvasIndex,
      isFreeDrawing,
      customPriceBends,
    });
  };

  onDeleteConnection = (_state, payload) => {
    const { ids, canvasState, canvasIndex, isFreeDrawing, customPriceBends } = payload;
    const { points } = canvasState;

    const point = ids[0];
    const vector = ids[1];

    points[point].vectors = points[point].vectors.filter((connId) => connId !== vector);

    points[vector].connect = points[vector].connect.filter((connId) => connId !== point);

    // delete endpoint

    helpers.cleanupPointsWithoutConnections(points);

    helpers.updateLinesData(_state, {
      canvasState,
      canvasIndex,
      isFreeDrawing,
      customPriceBends,
    });
  };

  onModifyPoint = (_state, payload) => {
    const { id, x, y, isFreeDrawing, canvasState, canvasIndex, customPriceBends } = payload;
    const { points } = canvasState;

    const point = points[id];
    if (!point) return false;
    point.x = x;
    point.y = y;

    return helpers.updateLinesData(_state, {
      canvasState,
      canvasIndex,
      isFreeDrawing,
      customPriceBends,
    });
  };

  onSetLineLength = (_state, payload) => {
    const { pointId, value, isFreeDrawing, canvasState, canvasIndex, customPriceBends } = payload;

    // const { points } = canvasState;
    // if (!isFreeDrawing) {
    //   helpers.setLineLength(points, begin, end, value);
    // } else {
    const lineIds = helpers.getLinePointKeyByProperty(
      canvasState.linesValuesFree,
      "values",
      pointId
    );

    if (!value || !isPositiveNumber(value)) {
      delete canvasState.linesValuesFree.values[lineIds];
    } else {
      canvasState.linesValuesFree.values[lineIds] = {
        size: parseFloat(value),
        hidden: false,
      };
    }

    // }

    helpers.updateLinesData(_state, {
      canvasState,
      canvasIndex,
      isFreeDrawing,
      customPriceBends,
    });
  };

  onSetAngle = (_state, payload) => {
    const { pointId, value, isFreeDrawing, canvasState, canvasIndex, customPriceBends } = payload;

    // const { points } = canvasState;
    // if (!isFreeDrawing) {
    //   helpers.rotatePoints(points, begin, value);
    // } else {
    if (!value || !isPositiveNumber(value)) {
      delete canvasState.anglesValuesFree.values[pointId];
    } else {
      let angle = parseFloat(value);
      angle = angle > 180 ? 360 - angle : angle;
      canvasState.anglesValuesFree.values[pointId] = { angle };
    }

    // }

    helpers.updateLinesData(_state, {
      canvasState,
      canvasIndex,
      isFreeDrawing,
      customPriceBends,
    });
  };

  onSetFinishPoint = (_state, payload) => {
    const { id, finish, canvasState, canvasIndex, isFreeDrawing, customPriceBends } = payload;
    const { points } = canvasState;

    if (!finish.size || !isPositiveNumber(finish.size)) {
      delete canvasState.points[id];
    } else {
      const size = parseFloat(finish.size);

      points[id].finish = {
        ...finish,
        size,
        label: `${finish.type}${size}`,
      };
    }

    helpers.updateLinesData(_state, {
      canvasState,
      canvasIndex,
      isFreeDrawing,
      customPriceBends,
    });
  };

  onDeleteFinishPoint = (_state, payload) => {
    const { id, canvasState, canvasIndex, isFreeDrawing, customPriceBends } = payload;

    delete canvasState.points[id].finish;

    helpers.updateLinesData(_state, {
      canvasState,
      canvasIndex,
      isFreeDrawing,
      customPriceBends,
    });
  };

  onFlipFinishPoint = (_state, payload) => {
    const { id, canvasState, canvasIndex, isFreeDrawing, customPriceBends } = payload;
    const { points } = canvasState;

    const point = points[id];
    point.finish.flip = !point.finish.flip;

    helpers.updateLinesData(_state, {
      canvasState,
      canvasIndex,
      isFreeDrawing,
      customPriceBends,
    });
  };

  switchHiddenAngle = (_state, payload) => {
    const { id, canvasState, canvasIndex, isFreeDrawing, customPriceBends } = payload;

    const freeValues = canvasState.anglesValuesFree.values[id];
    const isFreeHidden = freeValues && !freeValues.hidden;
    const autoHidden = canvasState.anglesValuesAuto.values[id].hidden;

    if (!freeValues) {
      canvasState.anglesValuesFree.values[id] = {};
    }

    canvasState.anglesValuesFree.values[id].hidden = (!freeValues && !autoHidden) || isFreeHidden;

    helpers.updateLinesData(_state, {
      canvasState,
      canvasIndex,
      isFreeDrawing,
      customPriceBends,
    });
  };

  switchHiddenLineLength = (_state, payload) => {
    const { id, canvasState, canvasIndex, isFreeDrawing, customPriceBends } = payload;

    const lineIds = helpers.getLinePointKeyByProperty(canvasState.linesValuesFree, "values", id);

    const freeValues = canvasState.linesValuesFree.values[lineIds];
    const isFreeHidden = freeValues && !freeValues.hidden;

    if (!freeValues) {
      canvasState.linesValuesFree.values[lineIds] = {};
    }

    canvasState.linesValuesFree.values[lineIds].hidden =
      (!freeValues && !isFreeHidden) || isFreeHidden;

    helpers.updateLinesData(_state, {
      canvasState,
      canvasIndex,
      isFreeDrawing,
      customPriceBends,
    });
  };

  shiftDrawing = (_state, payload) => {
    const { canvasWidth, canvasHeight, customPriceBends } = payload;

    const activeDrawing = _state.get("activeDrawing");
    const canvasState = activeDrawing.get("canvasState");
    const otherSides = activeDrawing.get("otherSides");
    const isFreeDrawing = activeDrawing.get("isFreeDrawing");

    const partHeight = otherSides ? canvasHeight / (otherSides.size + 1) : canvasHeight;

    const shiftCanvas = (canvas, canvasIndex) => {
      const points = { ...canvas.points };
      const box = calcBBox(points);

      const centerX = canvasWidth / canvas.zoom / 2;
      const centerY = partHeight / canvas.zoom / 2;
      const shiftX = centerX - (box.width / 2 + box.x);
      const shiftY = centerY - (box.height / 2 + box.y);

      Object.keys(points).forEach((key) => {
        points[key] = { ...canvas.points[key] };
        points[key].x += shiftX;
        points[key].y += shiftY;
      });

      helpers.updateLinesData(_state, {
        canvasState: { ...canvas, points: { ...points } },
        canvasIndex,
        isFreeDrawing,
        customPriceBends,
      });
    };

    shiftCanvas(canvasState.toJS());

    if (otherSides) {
      otherSides.forEach((side, canvasIndex) => {
        shiftCanvas(side.toJS(), canvasIndex);
      });
    }
  };

  shiftLabel = (_state, payload) => {
    const { canvasState, canvasIndex } = payload;

    if (canvasIndex != null && canvasIndex > -1) {
      _state.mergeIn(["activeDrawing", "otherSides", canvasIndex], fromJS(canvasState));
    } else {
      _state.mergeIn(["activeDrawing", "canvasState"], fromJS(canvasState));
    }

    _state.setIn(["activeDrawing", "hasChanges"], true);
  };

  onSetTextArea = (_state, payload) => {
    const { canvasState, canvasIndex, isFreeDrawing, itemId, pointId } = payload;
    const textAreaId = itemId || pointId;
    const isHaveTextDrawing = canvasState.textArea;
    let mergeTextArea = { ...this.initialTextAreaItemState() };

    if (isHaveTextDrawing) {
      mergeTextArea = canvasState.textArea[textAreaId]
        ? { ...canvasState.textArea[textAreaId] }
        : { ...this.initialTextAreaItemState() };
    }

    const {
      x = mergeTextArea.x,
      y = mergeTextArea.y,
      width = mergeTextArea.width,
      height = mergeTextArea.height,
      value = mergeTextArea.value,
      text = mergeTextArea.text,
      id = mergeTextArea.id,
      rotateDeg = mergeTextArea.rotateDeg,
    } = payload;

    let newCanvasData = fromJS(canvasState);

    if (isHaveTextDrawing) {
      if (canvasState.textArea[textAreaId]) {
        newCanvasData = newCanvasData.mergeIn(
          ["textArea", textAreaId],
          fromJS({
            x,
            y,
            width,
            height,
            value,
            text,
            id,
            rotateDeg,
          })
        );
      } else {
        newCanvasData = newCanvasData.setIn(
          ["textArea", textAreaId],
          fromJS({
            x,
            y,
            width,
            height,
            value,
            text,
            id,
            rotateDeg,
          })
        );
      }
    } else {
      newCanvasData = newCanvasData.set("textArea", {
        [textAreaId]: fromJS({
          x,
          y,
          width,
          height,
          value,
          text,
          id,
          rotateDeg,
        }),
      });
    }

    helpers.updateCanvas(_state, {
      type: canvasState.type,
      newCanvasData,
      canvasIndex,
      isFreeDrawing,
    });
  };

  onDeleteTextArea = (_state, payload) => {
    const { canvasState, canvasIndex, isFreeDrawing, id } = payload;
    canvasState.textArea[id] = null;

    helpers.updateCanvas(_state, {
      type: canvasState.type,
      newCanvasData: fromJS(canvasState),
      canvasIndex,
      isFreeDrawing,
    });
  };

  onSetFrontArrow = (_state, payload) => {
    const { x, y, canvasState, canvasIndex, isFreeDrawing } = payload;

    canvasState.frontArrow.x = x;
    canvasState.frontArrow.y = y;

    helpers.updateCanvas(_state, {
      type: canvasState.type,
      newCanvasData: fromJS(canvasState),
      canvasIndex,
      isFreeDrawing,
    });
  };

  onDeleteFrontArrow = (_state, payload) => {
    const { canvasState, canvasIndex, isFreeDrawing } = payload;

    canvasState.frontArrow.x = null;
    canvasState.frontArrow.y = null;
    canvasState.frontArrow.angle = 0;

    helpers.updateCanvas(_state, {
      type: canvasState.type,
      newCanvasData: fromJS(canvasState),
      canvasIndex,
      isFreeDrawing,
    });
  };

  onRotateFrontArrow = (_state, payload) => {
    const { angle, canvasState, canvasIndex, isFreeDrawing } = payload;

    canvasState.frontArrow.angle += angle;
    if (canvasState.frontArrow.angle > 360) canvasState.frontArrow.angle -= 360;
    if (canvasState.frontArrow.angle < 0) canvasState.frontArrow.angle += 360;

    helpers.updateCanvas(_state, {
      type: canvasState.type,
      newCanvasData: fromJS(canvasState),
      canvasIndex,
      isFreeDrawing,
    });
  };

  onSetSquareAngle = (_state, payload) => {
    const { x, y, canvasState, canvasIndex, isFreeDrawing } = payload;

    canvasState.squareAngle.x = x;
    canvasState.squareAngle.y = y;

    helpers.updateCanvas(_state, {
      type: canvasState.type,
      newCanvasData: fromJS(canvasState),
      canvasIndex,
      isFreeDrawing,
    });
  };

  onDeleteSquareAngle = (_state, payload) => {
    const { canvasState, canvasIndex, isFreeDrawing } = payload;

    canvasState.squareAngle.x = null;
    canvasState.squareAngle.y = null;
    canvasState.squareAngle.angle = 0;

    helpers.updateCanvas(_state, {
      type: canvasState.type,
      newCanvasData: fromJS(canvasState),
      canvasIndex,
      isFreeDrawing,
    });
  };

  defineReducers() {
    return {
      [ACTIONS.CREATE_NEW_ORDER]: withMutations(this.createNewOrder),

      [ACTIONS.SET_UIDS]: withMutations(this.setUids),

      [`${ACTIONS.SET_DATA_AFTER_SAVING} fulfilled`]: withMutations(this.setDataAfterSaving),

      [ACTIONS.OPEN_ORDER]: withMutations(this.openOrder),

      [`${ACTIONS.RESET_DRAWINGS} fulfilled`]: withMutations(this.resetDrawings),

      [`${ACTIONS.ADD_NEW_DRAWING} fulfilled`]: withMutations(this.addNewDrawing),

      [ACTIONS.SAVE_DRAWING]: withMutations(this.saveDrawing),

      [`${ACTIONS.SET_ACTIVE_DRAWING} fulfilled`]: this.setReducer("activeDrawing"),

      [`${ACTIONS.DUPLICATE_DRAWING} fulfilled`]: withMutations(this.duplicateDrawing),

      [`${ACTIONS.DELETE_DRAWING} fulfilled`]: withMutations(this.deleteDrawing),

      [`${ACTIONS.UNDO_DELETE_DRAWING} fulfilled`]: withMutations(this.undoDeleteDrawing),

      // change drawing points:

      [`${ACTIONS.ADD_POINT} fulfilled`]: withMutations(this.onAddPoint),

      [`${ACTIONS.ADD_CONNECTION} fulfilled`]: withMutations(this.onAddConnection),

      [`${ACTIONS.DELETE_POINT} fulfilled`]: withMutations(this.onDeletePoint),

      [ACTIONS.COMBINING_POINT]: withMutations(this.onDeletePoint),

      [`${ACTIONS.DELETE_CONNECTION} fulfilled`]: withMutations(this.onDeleteConnection),

      [`${ACTIONS.MODIFY_LINES} fulfilled`]: withMutations(this.onSetLineLength),

      [`${ACTIONS.MODIFY_DEGREES} fulfilled`]: withMutations(this.onSetAngle),

      [`${ACTIONS.MODIFY_POINT} fulfilled`]: withMutations(this.onModifyPoint),

      [`${ACTIONS.SET_FINISH_POINT} fulfilled`]: withMutations(this.onSetFinishPoint),

      [`${ACTIONS.DELETE_FINISH_POINT} fulfilled`]: withMutations(this.onDeleteFinishPoint),

      [`${ACTIONS.FLIP_FINISH_POINT} fulfilled`]: withMutations(this.onFlipFinishPoint),

      [`${ACTIONS.HIDE_ANGLE_LABEL} fulfilled`]: withMutations(this.switchHiddenAngle),

      [`${ACTIONS.HIDE_LINE_LABEL} fulfilled`]: withMutations(this.switchHiddenLineLength),

      [ACTIONS.SHIFT_DRAWING_POINTS]: withMutations(this.shiftDrawing),

      [`${ACTIONS.SHIFT_LABEL} fulfilled`]: withMutations(this.shiftLabel),

      [ACTIONS.DUPLICATE_DRAWING_TAPERED]: (state) => {
        const activeDrawing = state.get("activeDrawing");
        const canvasState = activeDrawing.get("canvasState");

        const duplicateCanvas = canvasState
          .delete("frontArrow")
          .delete("squareAngle")
          .delete("type");

        if (activeDrawing.get("otherSides")) {
          return state.updateIn(["activeDrawing", "otherSides"], (list) =>
            list.map((side) => side.merge(duplicateCanvas))
          );
        }

        return state;
      },

      [ACTIONS.SET_LARGE_BOX_SIZE_PREVIEW_DRAWINGS]: (state, { payload }) => {
        const uid = payload.tempUid;
        const largeBoxSizeStatus = payload.isLargeBoxSize;
        const drawingIndex = state
          .getIn(["items"])
          .findIndex((item) => item.getIn(["tempUid"]) === uid);

        if (drawingIndex >= 0) {
          return state.setIn(["items", drawingIndex, "isLargeBoxSize"], !largeBoxSizeStatus);
        }

        return state;
      },

      [ACTIONS.CALC_TAPERS]: (state) => {
        const activeDrawing = state.get("activeDrawing");
        const canvasState = activeDrawing.get("canvasState");
        const otherSides = activeDrawing.get("otherSides");

        const linesMainIndexes = canvasState.getIn(["linesValuesAuto", "indexes"]);
        const [...linesMain] = linesMainIndexes.keys();

        linesMain.sort((keyA, keyB) => linesMainIndexes.get(keyA) - linesMainIndexes.get(keyB));

        let tapers = 0;

        otherSides.forEach((side) => {
          const lineSidesIndexes = side.getIn(["linesValuesAuto", "indexes"]);
          const [...linesSide] = lineSidesIndexes.keys();

          linesSide.sort((keyA, keyB) => lineSidesIndexes.get(keyA) - lineSidesIndexes.get(keyB));

          if (linesMain.length === linesSide.length) {
            const maxIndexes = linesMain.length;

            for (let i = 0; i < maxIndexes; i++) {
              const sideLineIdRevert = linesSide[i].split("-").reverse().join("-");
              let sideLineValue = side.getIn(["linesValuesFree", "values"]);

              sideLineValue =
                sideLineValue.get(linesSide[i]) || sideLineValue.get(sideLineIdRevert);

              const isHiddenPoints = sideLineValue ? sideLineValue.get("hidden") : 0;
              sideLineValue = sideLineValue ? sideLineValue.get("size") : 0;

              const mainLineIdRevert = linesMain[i].split("-").reverse().join("-");
              let mainLineValue = canvasState.getIn(["linesValuesFree", "values"]);

              mainLineValue =
                mainLineValue.get(linesMain[i]) || mainLineValue.get(mainLineIdRevert);

              mainLineValue = mainLineValue ? mainLineValue.get("size") : 0;

              if (sideLineValue !== mainLineValue && !isHiddenPoints) {
                tapers += 1;
              }
            }
          }
        });

        return state.setIn(["activeDrawing", "tapers"], tapers);
      },

      // change colour side / square angle / text area:

      [`${ACTIONS.ADD_TEXT_AREA} fulfilled`]: withMutations(this.onSetTextArea),

      [`${ACTIONS.DELETE_TEXT_AREA} fulfilled`]: withMutations(this.onDeleteTextArea),

      [`${ACTIONS.MODIFY_TEXT_AREA} fulfilled`]: withMutations(this.onSetTextArea),

      [`${ACTIONS.ADD_FRONT_ARROW} fulfilled`]: withMutations(this.onSetFrontArrow),

      [`${ACTIONS.DELETE_FRONT_ARROW} fulfilled`]: withMutations(this.onDeleteFrontArrow),

      [`${ACTIONS.ROTATE_FRONT_ARROW} fulfilled`]: withMutations(this.onRotateFrontArrow),

      [`${ACTIONS.MOVE_FRONT_ARROW} fulfilled`]: withMutations(this.onSetFrontArrow),

      [`${ACTIONS.ADD_SQUARE_ANGLE} fulfilled`]: withMutations(this.onSetSquareAngle),

      [`${ACTIONS.DELETE_SQUARE_ANGLE} fulfilled`]: withMutations(this.onDeleteSquareAngle),

      [`${ACTIONS.MOVE_SQUARE_ANGLE} fulfilled`]: withMutations(this.onSetSquareAngle),

      [ACTIONS.CHANGE_DRAWING_COMPLETED_STATUS]: (state, { payload: { drawingTempUid, value } }) =>
        state.setIn(["items", drawingTempUid, "isCompleted"], value),
    };
  }
}

const instance = new DrawingsModule();
instance.init();

export default instance;
