import { nanoid } from "nanoid";

import * as billingApi from "@/apiv2/billing";
import { STATUS } from "@//utils/constants";

import * as alertActions from "../../alerts/alerts.actions";
import { handleError } from "../../utils/error";

import {
  SET_IS_FETCHING_STATUS,
  SET_IS_CHECKOUT_STATUS,
  SET_PRICING_TABLE,
  SET_BILILNG_DETAILS,
  RESET,
} from "./billing.actionTypes";

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setIsFetchingStatus = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_STATUS,
    payload: data,
  });
};

export const setIsCheckoutStatus = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_CHECKOUT_STATUS,
    payload: data,
  });
};

export const setPricingTable = (data) => (dispatch) => {
  dispatch({
    type: SET_PRICING_TABLE,
    payload: data,
  });
};

export const setBillingDetails = (data) => (dispatch) => {
  dispatch({
    type: SET_BILILNG_DETAILS,
    payload: data,
  });
};

export const fetchPricingTable = () => async (dispatch) => {
  try {
    dispatch(setIsFetchingStatus(STATUS.PENDING));

    const { data } = await billingApi.getPricingTable()();
    dispatch(setPricingTable(data));
    dispatch(setIsFetchingStatus(STATUS.FULFILLED));
  } catch (error) {
    dispatch(setIsFetchingStatus(STATUS.REJECTED));
    handleError(error, dispatch);
  }
};

export const checkout = (payload) => async (dispatch) => {
  try {
    dispatch(setIsCheckoutStatus(STATUS.PENDING));

    const { data } = payload.is_xero_referral
      ? await billingApi.getXeroReferralCheckout()()
      : await billingApi.postCheckout(payload)();

    window.location = data.checkoutUrl;
  } catch (error) {
    dispatch(setIsCheckoutStatus(STATUS.REJECTED));

    await dispatch(
      alertActions.createAlertAction(nanoid(), "Error while checking out", true, "danger")
    );

    handleError(error, dispatch);
  }
};

export const checkoutSuccess = (payload) => async (dispatch) => {
  try {
    await billingApi.postCheckoutSuccess(payload)();
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const fetchBillingDetails = () => async (dispatch) => {
  try {
    dispatch(setIsFetchingStatus(STATUS.PENDING));

    const { data } = await billingApi.getBillingDetails()();
    dispatch(setBillingDetails(data));
    dispatch(setIsFetchingStatus(STATUS.FULFILLED));
  } catch (error) {
    dispatch(setIsFetchingStatus(STATUS.REJECTED));
    handleError(error, dispatch);
  }
};

export const updateBillingInformation = () => async (dispatch) => {
  try {
    const { data } = await billingApi.postUpdateBillingInformation()();
    window.location = data.customerPortalUrl;
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const updateXeroBillingInformation = () => async (dispatch) => {
  try {
    const { data } = await billingApi.postUpdateXeroBillingInformation()();
    window.location = data.customerPortalUrl;
  } catch (error) {
    handleError(error, dispatch);
  }
};
