import camelcaseKeys from "camelcase-keys";
import produce from "immer";
import { nanoid } from "nanoid";
import { orderBy } from "lodash";
import moment from "moment-timezone";

import * as date from "@/utils/date";

import { STATUS } from "../../utils/constants";
import setKeypathValue from "../utils/setKeypathValue";
import * as helpers from "../modules/helpers/drawing";

import {
  SEARCH_PURCHASE_ORDERS,
  GET_PURCHASE_ORDER,
  BATCH_EDIT_ACTIVE_PURCHASE_ORDER,
  EDIT_ACTIVE_PURCHASE_ORDER,
  EDIT_ACTIVE_PURCHASE_ORDER_SILENTLY,
  RESET_ACTIVE_PURCHASE_ORDER,
  UPDATE_PURCHASE_ORDER,
  UPDATE_PURCHASE_ORDER_SILENTLY,
  LOAD_EMPTY_PURCHASE_ORDER,
  CREATE_PURCHASE_ORDER,
  DRAFT_PURCHASE_ORDER,
  BULK_DELETE_PURCHASE_ORDERS,
  DELETE_PURCHASE_ORDER,
  EDIT_PURCHASE_ORDER_FILTERS,
  GET_EXTERNAL_PURCHASE_ORDER,
  GET_LINKED_ORDERS_FROM_PURCHASE_ORDERS,
  RESET_PURCHASE_ORDERS,
  SET_CURRENT_PAGE,
  SET_TOTAL_COUNT,
  SET_PAGE_OFFSET,
  SET_PREVIOUS_PAGE_OFFSET,
  SET_NEXT_PAGE_OFFSET,
  SET_PAGE_LIMIT,
  SET_SORT,
  SET_IS_DUPLICATING,
  SET_IS_DUPLICATE_CONFIRMATION_ALERT_OPEN,
  SET_DUPLICATE_ORDER_UID,
  SET_IS_CHANGING_SUPPLIER,
  SET_IS_CHANGING_SUPPLIER_MODAL_OPEN,
  SET_IS_CONVERTING_BILL_OR_PO,
  SET_IS_CONVERTING_BILL_OR_PO_CONFIRMATION_ALERT_OPEN,
  EDIT_DRAWING_SVGS,
  SET_IS_FETCHING,
  SET_ORDER_ATTACHMENTS,
  SET_SELECTED_ATTACHMENTS,
  POST_ADD_ATTACHMENTS,
  REMOVE_SELECTED_ATTACHMENT,
  UPDATE_SELECTED_ATTACHMENTS,
  PRINT_PURCHASE_ORDER_PDF_STATUS,
  EMAIL_PURCHASE_ORDER_STATUS,
  SET_IS_ADD_PRODUCT_MODAL_TYPE,
} from "./purchaseOrders.actionTypes";

const initialState = {
  purchaseOrders: [],
  linkedOrders: [],
  activePurchaseOrder: null,
  externalPurchaseOrder: null,
  recentlyDeletedPurchaseOrderUid: null,
  recentlyBulkDeletedPurchaseOrderUids: [],
  filters: {
    search: "",
    startDate: null,
    endDate: null,
    statuses: ["all", "draft", "submitted", "partially Received", "received", "billed"],
  },
  pagination: {
    totalCount: 0,
    limit: 10,
    offset: 0,
    currentPage: 0,
    previousPageOffset: 0,
    nextPageOffset: 0,
    sortBy: "",
  },
  duplicateOrderUid: null,
  isDuplicateConfirmationAlertOpen: false,
  isDuplicating: false,
  isChangingSupplierModalOpen: false,
  isChangingSupplier: STATUS.IDLE,
  isConvertingBillOrPo: false,
  isConvertingBillOrPoConfirmationAlertOpen: false,
  drawingSVGs: [],
  isFetching: false,
  attachments: [],
  selectedAttachments: [],
  newUploadedAttachments: [],
  printPurchaseOrderPdfStatus: STATUS.IDLE,
  emailPurchaseOrderStatus: STATUS.IDLE,
  isAddProductModalType: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case `${SEARCH_PURCHASE_ORDERS}/fulfilled`: {
      const { data } = action.payload;

      return {
        ...state,
        purchaseOrders: data.results,
      };
    }

    // GET PURCHASE ORDER //
    case `${GET_PURCHASE_ORDER}/fulfilled`: {
      const { data } = action.payload;

      const flashingItems = data.flashing_items.map((flashing) => {
        const otherSides = flashing.drawing.other_sides.map((side) => {
          let numberOfBendsFree = 0;
          let totalGirthFree = 0;
          const { linesValuesAuto, anglesValuesAuto, maxIndex, numberOfBendsAuto, totalGirthAuto } =
            helpers.calcLinesDataAuto(side.points, 2, 2);

          if (side.is_free_drawing) {
            const { numberOfBendsFree: numberOfBends, totalGirthFree: totalGirth } =
              helpers.calcLinesDataFree(side.points, side.lines, linesValuesAuto, 2);
            numberOfBendsFree = numberOfBends;
            totalGirthFree = totalGirth;
          }

          return {
            frontArrow: side.front_arrow,
            points: side.points,
            mainDrawing: side.main_drawing,
            numberOfBendsFree,
            numberOfBendsAuto,
            totalGirthFree,
            linesValuesFree: side.lines,
            anglesValuesFree: side.angles,
            maxIndex,
            totalGirthAuto,
            uid: side.uid,
            linesValuesAuto,
            anglesValuesAuto,
            zoom: 2,
            squareAngle: side.square_angle,
            type: "Near side",
            textArea: side.text_area.x === null ? "" : side.text_area,
          };
        });

        const { linesValuesAuto, anglesValuesAuto, maxIndex, numberOfBendsAuto, totalGirthAuto } =
          helpers.calcLinesDataAuto(flashing.drawing.points, 2, 2);

        let numberOfBendsFree = 0;
        let totalGirthFree = 0;

        if (flashing.drawing.is_free_drawing) {
          const { numberOfBendsFree: numberOfBends, totalGirthFree: totalGirth } =
            helpers.calcLinesDataFree(
              flashing.drawing.points,
              flashing.drawing.lines,
              linesValuesAuto,
              2
            );
          numberOfBendsFree = numberOfBends;
          totalGirthFree = totalGirth;
        }

        return {
          ...camelcaseKeys(flashing),
          drawing: {
            ...camelcaseKeys(flashing.drawing),
            points: flashing.drawing.points,
            otherSides,
          },
          canvasState: {
            zoom: 2,
            points: flashing.drawing.points,
            frontArrow: flashing.drawing.front_arrow,
            squareAngle: flashing.drawing.square_angle,
            textArea: flashing.drawing.text_area.x === null ? "" : flashing.drawing.text_area,
            linesValuesFree: flashing.drawing.lines,
            anglesValuesFree: flashing.drawing.angles,
            linesValuesAuto,
            anglesValuesAuto,
            maxIndex,
            numberOfBendsAuto,
            totalGirthAuto,
            numberOfBendsFree,
            totalGirthFree,
          },
        };
      });

      return {
        ...state,
        purchaseOrders: [],
        activePurchaseOrder: {
          ...camelcaseKeys(data),
          items: orderBy(
            data.items.map((item, index) => ({
              ...camelcaseKeys(item, { deep: true }),
              index,
              lengths: item.lengths.map((length) => ({
                ...camelcaseKeys(length),
                id: nanoid(),
              })),
            })),
            ["index"],
            ["asc"]
          ),
          flashingItems,
          dirty: false,
          editedRow: null,
          lengthFocusIndex: null,
          discountRates: data.discount_rates.map((discountRate) => ({
            ...camelcaseKeys(discountRate),
          })),
          discountFocusIndex: null,
          deletedItems: [],
          deletedFlashingItems: [],
        },
      };
    }

    // LOAD EMPTY PURCHASE ORDER //
    case LOAD_EMPTY_PURCHASE_ORDER: {
      const { supplierUid, company, supplier, user } = action.payload;
      const emptyPurchaseOrder = {
        supplier: supplierUid,
        supplierName: supplier.name,
        contact: null,
        editStatus: {
          id: 1,
          name: "Draft",
        },
        requiredDate: null,
        createdByName: user.firstName,
        notes: "",
        pickupNotes: "",
        purchaseOrderNumber: 0,
        receptionMethod: "delivery",
        reference: "",
        items: [],
        flashingItems: [],
        itemTotal: "0.00",
        itemsAndDelivery: "0.00",
        deliveryFee: "0.00",
        gstAmount: "0.00",
        total: "0.00",
        discountRates: [],
        billingAddress1: company.billingAddress1,
        billingAddress2: company.billingAddress2,
        billingSuburb: company.billingCity,
        billingState: company.billingState,
        billingPostcode: company.billingPostcode,
        billingCountryCode: company.billingCountryCode,
        deliveryAddress1: company.billingAddress1,
        deliveryAddress2: company.billingAddress2,
        deliverySuburb: company.billingCity,
        deliveryState: company.billingState,
        deliveryPostcode: company.billingPostcode,
        deliveryCountryCode: company.billingCountryCode,
        deliveryStatus: {
          id: 3,
          name: "Not received",
        },
        dirty: false,
        editedRow: null,
        deletedItems: [],
        deletedFlashingItems: [],
      };

      return {
        ...state,
        purchaseOrders: [],
        activePurchaseOrder: emptyPurchaseOrder,
      };
    }

    // BATCH EDIT ACTIVE PURCHASE ORDER //
    case BATCH_EDIT_ACTIVE_PURCHASE_ORDER: {
      const { changes } = action.payload;

      return produce(state, (draft) => {
        let { dirty } = draft.activePurchaseOrder;

        for (const change of changes) {
          const updated = setKeypathValue(
            draft,
            ["activePurchaseOrder", ...change.path],
            change.value
          );
          dirty = dirty || updated;
        }

        draft.activePurchaseOrder.dirty = dirty;
      });
    }

    // EDIT ACTIVE PURCHASE ORDER //
    case EDIT_ACTIVE_PURCHASE_ORDER: {
      const { path, value } = action.payload;

      return produce(state, (draft) => {
        let { dirty } = draft.activePurchaseOrder;
        const updated = setKeypathValue(draft, ["activePurchaseOrder", ...path], value);
        dirty = dirty || updated;
        draft.activePurchaseOrder.dirty = dirty;
      });
    }

    // UPDATE PURCHASE ORDER //
    case `${UPDATE_PURCHASE_ORDER}/fulfilled`:

    case `${UPDATE_PURCHASE_ORDER_SILENTLY}/fulfilled`: {
      const { data } = action.payload;

      return produce(state, (draft) => {
        // Update existing purchase order
        const purchaseOrderIndex = draft.purchaseOrders.findIndex(
          (purchaseOrder) => purchaseOrder.uid === data.uid
        );
        draft.purchaseOrders[purchaseOrderIndex] = data;

        // Replace active purchase order with updated purchase order
        const flashingItems = data.flashing_items.map((flashing) => {
          const otherSides = flashing.drawing.other_sides.map((side) => {
            let numberOfBendsFree = 0;
            let totalGirthFree = 0;
            const {
              linesValuesAuto,
              anglesValuesAuto,
              maxIndex,
              numberOfBendsAuto,
              totalGirthAuto,
            } = helpers.calcLinesDataAuto(side.points, 2, 2);

            if (side.is_free_drawing) {
              const { numberOfBendsFree: numberOfBends, totalGirthFree: totalGirth } =
                helpers.calcLinesDataFree(side.points, side.lines, linesValuesAuto, 2);
              numberOfBendsFree = numberOfBends;
              totalGirthFree = totalGirth;
            }

            return {
              frontArrow: side.front_arrow,
              points: side.points,
              mainDrawing: side.main_drawing,
              numberOfBendsFree,
              numberOfBendsAuto,
              totalGirthFree,
              linesValuesFree: side.lines,
              anglesValuesFree: side.angles,
              maxIndex,
              totalGirthAuto,
              uid: side.uid,
              linesValuesAuto,
              anglesValuesAuto,
              zoom: 2,
              squareAngle: side.square_angle,
              type: "Near side",
              textArea: side.text_area.x === null ? "" : side.text_area,
            };
          });
          const { linesValuesAuto, anglesValuesAuto, maxIndex, numberOfBendsAuto, totalGirthAuto } =
            helpers.calcLinesDataAuto(flashing.drawing.points, 2, 2);

          let numberOfBendsFree = 0;
          let totalGirthFree = 0;

          if (flashing.drawing.isFreeDrawing) {
            const { numberOfBendsFree: numberOfBends, totalGirthFree: totalGirth } =
              helpers.calcLinesDataFree(
                flashing.drawing.points,
                flashing.drawing.lines,
                linesValuesAuto,
                2
              );
            numberOfBendsFree = numberOfBends;
            totalGirthFree = totalGirth;
          }

          return {
            ...camelcaseKeys(flashing),
            drawing: {
              ...camelcaseKeys(flashing.drawing),
              points: flashing.drawing.points,
              otherSides,
            },
            canvasState: {
              zoom: 2,
              points: flashing.drawing.points,
              frontArrow: flashing.drawing.front_arrow,
              squareAngle: flashing.drawing.square_angle,
              textArea: flashing.drawing.text_area.x === null ? "" : flashing.drawing.text_area,
              linesValuesFree: flashing.drawing.lines,
              anglesValuesFree: flashing.drawing.angles,
              linesValuesAuto,
              anglesValuesAuto,
              maxIndex,
              numberOfBendsAuto,
              totalGirthAuto,
              numberOfBendsFree,
              totalGirthFree,
            },
          };
        });

        const activePurchaseOrder = {
          ...camelcaseKeys(data),
          items: orderBy(
            state.activePurchaseOrder.items.map((item, index) => ({
              ...item,
              id: data.items[index].id,
              lengths: item.lengths.map((length) => ({
                ...camelcaseKeys(length),
                id: nanoid(),
              })),
            })),
            ["index"],
            ["asc"]
          ),
          flashingItems,
          dirty: false,
          editedRow: null,
          lengthFocusIndex: null,
          discountRates: data.discount_rates.map((discountRate) => ({
            ...camelcaseKeys(discountRate),
          })),
          discountFocusIndex: null,
          deletedItems: [],
          deletedFlashingItems: [],
          flashingsLeftColumn: [],
          flashingsRightColumn: [],
        };

        draft.activePurchaseOrder = activePurchaseOrder;
      });
    }

    // CREATE PURCHASE ORDER //
    case `${CREATE_PURCHASE_ORDER}/fulfilled`: {
      const { data } = action.payload;

      return produce(state, (draft) => {
        // Prepend the new purchase order to the list
        draft.purchaseOrders = [data, ...draft.purchaseOrders];

        // Replace active purchase order with created purchase order
        draft.activePurchaseOrder = { ...data, deletedItems: [] };
      });
    }

    // DRAFT PURCHASE ORDER //
    case `${DRAFT_PURCHASE_ORDER}/fulfilled`: {
      // When purchase order patching return correct object - update this
      const { data } = action.payload;

      const flashingItems = data.flashing_items.map((flashing) => {
        const otherSides = flashing.drawing.other_sides.map((side) => {
          let numberOfBendsFree = 0;
          let totalGirthFree = 0;
          const { linesValuesAuto, anglesValuesAuto, maxIndex, numberOfBendsAuto, totalGirthAuto } =
            helpers.calcLinesDataAuto(side.points, 2, 2);

          if (side.is_free_drawing) {
            const { numberOfBendsFree: numberOfBends, totalGirthFree: totalGirth } =
              helpers.calcLinesDataFree(side.points, side.lines, linesValuesAuto, 2);
            numberOfBendsFree = numberOfBends;
            totalGirthFree = totalGirth;
          }

          return {
            frontArrow: side.front_arrow,
            points: side.points,
            mainDrawing: side.main_drawing,
            numberOfBendsFree,
            numberOfBendsAuto,
            totalGirthFree,
            linesValuesFree: side.lines,
            anglesValuesFree: side.angles,
            maxIndex,
            totalGirthAuto,
            uid: side.uid,
            linesValuesAuto,
            anglesValuesAuto,
            zoom: 2,
            squareAngle: side.square_angle,
            type: "Near side",
            textArea: side.text_area.x === null ? "" : side.text_area,
          };
        });

        const { linesValuesAuto, anglesValuesAuto, maxIndex, numberOfBendsAuto, totalGirthAuto } =
          helpers.calcLinesDataAuto(flashing.drawing.points, 2, 2);

        let numberOfBendsFree = 0;
        let totalGirthFree = 0;

        if (flashing.drawing.isFreeDrawing) {
          const { numberOfBendsFree: numberOfBends, totalGirthFree: totalGirth } =
            helpers.calcLinesDataFree(
              flashing.drawing.points,
              flashing.drawing.lines,
              linesValuesAuto,
              2
            );
          numberOfBendsFree = numberOfBends;
          totalGirthFree = totalGirth;
        }

        return {
          ...camelcaseKeys(flashing),
          drawing: {
            ...camelcaseKeys(flashing.drawing),
            points: flashing.drawing.points,
            otherSides,
          },
          canvasState: {
            zoom: 2,
            points: flashing.drawing.points,
            frontArrow: flashing.drawing.front_arrow,
            squareAngle: flashing.drawing.square_angle,
            textArea: flashing.drawing.text_area.x === null ? "" : flashing.drawing.text_area,
            linesValuesFree: flashing.drawing.lines,
            anglesValuesFree: flashing.drawing.angles,
            linesValuesAuto,
            anglesValuesAuto,
            maxIndex,
            numberOfBendsAuto,
            totalGirthAuto,
            numberOfBendsFree,
            totalGirthFree,
          },
        };
      });

      const activePurchaseOrder = {
        ...camelcaseKeys(data),
        items: orderBy(
          data.items.map((item) => ({
            ...camelcaseKeys(item),
            lengths: item.lengths.map((length) => ({
              ...camelcaseKeys(length),
              id: nanoid(),
            })),
          })),
          ["index"],
          ["asc"]
        ),
        flashingItems,
        dirty: false,
        editedRow: null,
        lengthFocusIndex: null,
        discountRates: data.discount_rates.map((discountRate) => ({
          ...camelcaseKeys(discountRate),
        })),
        discountFocusIndex: null,
        deletedItems: [],
        deletedFlashingItems: [],
      };

      return {
        ...state,
        activePurchaseOrder,
        drawingSVGs: [],
      };
    }

    // DELETE PURCHASE ORDER //
    case `${DELETE_PURCHASE_ORDER}/fulfilled`: {
      const { data } = action.payload;

      const deletedPurchaseOrderUid = data.uid;

      // Find the index of deleted purchase order
      const deletedIndex = [...state.purchaseOrders].findIndex(
        (purchaseOrder) => deletedPurchaseOrderUid === purchaseOrder.uid
      );

      // Determine if showing archive
      const showArchive = state.filters.statuses.includes("archived");

      return produce(state, (draft) => {
        draft.recentlyDeletedPurchaseOrderUid = deletedPurchaseOrderUid;

        if (showArchive) {
          // Set is deleted to true
          draft.purchaseOrders[deletedIndex] = {
            ...draft.purchaseOrders[deletedIndex],
            isDeleted: true,
          };
        } else {
          // Remove purchase order from list
          draft.purchaseOrders = [...draft.purchaseOrders].filter(
            (_, index) => index !== deletedIndex
          );
        }
      });
    }

    // BULK DELETE PURCHASE ORDERS //
    case `${BULK_DELETE_PURCHASE_ORDERS}/fulfilled`: {
      const { data } = action.payload;

      const deletedPurchaseOrderUids = data.uids;

      const deletedIndexes = [];

      // Contruct indexes for deleted purchase orders
      deletedPurchaseOrderUids.forEach((deletedPurchaseOrderUid) => {
        const deletedIndex = state.purchaseOrders.findIndex(
          (purchaseOrder) => purchaseOrder.uid === deletedPurchaseOrderUid
        );
        deletedIndexes.push(deletedIndex);
      });

      // Determine if showing archive
      const showArchive = state.filters.statuses.includes("archived");

      return produce(state, (draft) => {
        draft.recentlyBulkDeletedPurchaseOrderUids = deletedPurchaseOrderUids;

        if (showArchive) {
          // Set is deleted to true
          deletedIndexes.forEach((deletedIndex) => {
            draft.purchaseOrders[deletedIndex] = {
              ...draft.purchaseOrders[deletedIndex],
              isDeleted: true,
            };
          });
        } else {
          // Remove purchase order from the list
          draft.purchaseOrders = draft.purchaseOrders.filter(
            (purchaseOrder) => !deletedPurchaseOrderUids.includes(purchaseOrder.uid)
          );
        }
      });
    }

    // RESET ACTIVE PURCHASE ORDER  //
    case RESET_ACTIVE_PURCHASE_ORDER: {
      return { ...state, activePurchaseOrder: null, drawingSVGs: [] };
    }

    // EDIT FILTERS //
    case EDIT_PURCHASE_ORDER_FILTERS: {
      const { path, value } = action.payload;

      return produce(state, (draft) => {
        setKeypathValue(draft, ["filters", ...path], value);
      });
    }

    // GET EXTERNAL PURCHASE ORDER //
    case `${GET_EXTERNAL_PURCHASE_ORDER}/fulfilled`: {
      const { data } = action.payload;

      const flashingItems = data.flashing_items.map((flashing) => {
        const otherSides = flashing.drawing.other_sides.map((side) => {
          let numberOfBendsFree = 0;
          let totalGirthFree = 0;
          const { linesValuesAuto, anglesValuesAuto, maxIndex, numberOfBendsAuto, totalGirthAuto } =
            helpers.calcLinesDataAuto(side.points, 2, 2);

          if (side.is_free_drawing) {
            const { numberOfBendsFree: numberOfBends, totalGirthFree: totalGirth } =
              helpers.calcLinesDataFree(side.points, side.lines, linesValuesAuto, 2);
            numberOfBendsFree = numberOfBends;
            totalGirthFree = totalGirth;
          }

          return {
            frontArrow: side.front_arrow,
            points: side.points,
            mainDrawing: side.main_drawing,
            numberOfBendsFree,
            numberOfBendsAuto,
            totalGirthFree,
            linesValuesFree: side.lines,
            anglesValuesFree: side.angles,
            maxIndex,
            totalGirthAuto,
            uid: side.uid,
            linesValuesAuto,
            anglesValuesAuto,
            zoom: 2,
            squareAngle: side.square_angle,
            type: "Near side",
            textArea: side.text_area.x === null ? "" : side.text_area,
          };
        });
        const { linesValuesAuto, anglesValuesAuto, maxIndex, numberOfBendsAuto, totalGirthAuto } =
          helpers.calcLinesDataAuto(flashing.drawing.points, 2, 2);

        let numberOfBendsFree = 0;
        let totalGirthFree = 0;

        if (flashing.drawing.isFreeDrawing) {
          const { numberOfBendsFree: numberOfBends, totalGirthFree: totalGirth } =
            helpers.calcLinesDataFree(
              flashing.drawing.points,
              flashing.drawing.lines,
              linesValuesAuto,
              2
            );
          numberOfBendsFree = numberOfBends;
          totalGirthFree = totalGirth;
        }

        return {
          ...camelcaseKeys(flashing),
          drawing: {
            ...camelcaseKeys(flashing.drawing),
            points: flashing.drawing.points,
            otherSides,
          },
          canvasState: {
            zoom: 2,
            points: flashing.drawing.points,
            frontArrow: flashing.drawing.front_arrow,
            squareAngle: flashing.drawing.square_angle,
            textArea: flashing.drawing.text_area.x === null ? "" : flashing.drawing.text_area,
            linesValuesFree: flashing.drawing.lines,
            anglesValuesFree: flashing.drawing.angles,
            linesValuesAuto,
            anglesValuesAuto,
            maxIndex,
            numberOfBendsAuto,
            totalGirthAuto,
            numberOfBendsFree,
            totalGirthFree,
          },
        };
      });

      const externalPurchaseOrder = {
        ...camelcaseKeys(data),
        items: orderBy(
          data.items.map((item) => ({
            ...camelcaseKeys(item),
            lengths: item.lengths.map((length) => ({
              ...camelcaseKeys(length),
              id: nanoid(),
            })),
          })),
          ["index"],
          ["asc"]
        ),
        flashingItems,
        dirty: false,
        editedRow: null,
        lengthFocusIndex: null,
        discountRates: data.discount_rates.map((discountRate) => ({
          ...camelcaseKeys(discountRate),
        })),
        discountFocusIndex: null,
        deletedItems: [],
        deletedFlashingItems: [],
        flashingMinLength: camelcaseKeys(data.flashing_min_length, { deep: true }),
      };

      return { ...state, externalPurchaseOrder };
    }

    // GET LINKED PURCHASE ORDER //
    case `${GET_LINKED_ORDERS_FROM_PURCHASE_ORDERS}/fulfilled`: {
      const { data } = action.payload;

      return { ...state, linkedOrders: data };
    }

    // PAGINATION
    case SET_CURRENT_PAGE: {
      return {
        ...state,
        pagination: { ...state.pagination, currentPage: action.payload },
      };
    }

    case SET_TOTAL_COUNT: {
      return {
        ...state,
        pagination: { ...state.pagination, totalCount: action.payload },
      };
    }

    case SET_PAGE_OFFSET: {
      return {
        ...state,
        pagination: { ...state.pagination, offset: action.payload },
      };
    }

    case SET_NEXT_PAGE_OFFSET: {
      return {
        ...state,
        pagination: { ...state.pagination, nextPageOffset: action.payload },
      };
    }

    case SET_PREVIOUS_PAGE_OFFSET: {
      return {
        ...state,
        pagination: { ...state.pagination, previousPageOffset: action.payload },
      };
    }

    case SET_PAGE_LIMIT: {
      return {
        ...state,
        pagination: { ...state.pagination, limit: action.payload },
      };
    }

    case SET_SORT: {
      return {
        ...state,
        pagination: { ...state.pagination, sortBy: action.payload },
      };
    }

    case SET_IS_DUPLICATING: {
      return {
        ...state,
        isDuplicating: action.payload,
      };
    }

    case SET_DUPLICATE_ORDER_UID: {
      return {
        ...state,
        duplicateOrderUid: action.payload,
      };
    }

    case SET_IS_DUPLICATE_CONFIRMATION_ALERT_OPEN: {
      return {
        ...state,
        isDuplicateConfirmationAlertOpen: action.payload,
      };
    }

    case SET_IS_CHANGING_SUPPLIER: {
      return {
        ...state,
        isChangingSupplier: action.payload,
      };
    }

    case SET_IS_CHANGING_SUPPLIER_MODAL_OPEN: {
      return {
        ...state,
        isChangingSupplierModalOpen: action.payload,
      };
    }

    case SET_IS_CONVERTING_BILL_OR_PO: {
      return {
        ...state,
        isConvertingBillOrPo: action.payload,
      };
    }

    case SET_IS_CONVERTING_BILL_OR_PO_CONFIRMATION_ALERT_OPEN: {
      return {
        ...state,
        isConvertingBillOrPoConfirmationAlertOpen: action.payload,
      };
    }

    case EDIT_DRAWING_SVGS: {
      const newDrawingSVGs = [...state.drawingSVGs, action.payload];

      return {
        ...state,
        drawingSVGs: newDrawingSVGs,
      };
    }

    // RESET PURCHASE ORDERS //
    case RESET_PURCHASE_ORDERS: {
      return { ...state, purchaseOrders: [] };
    }

    // ATTACHMENTS
    case SET_IS_FETCHING: {
      return { ...state, isFetching: action.payload };
    }

    case `${SET_ORDER_ATTACHMENTS}`: {
      const allowedFileTypes = [
        "application/pdf",
        "application/dwg",
        "application/dxf",
        "image/vnd.dwg",
        "image/vnd.dxf",
        "image/png",
        "image/jpg",
        "image/jpeg",
      ];
      const filteredAttachments = action.payload.data.filter((attachment) =>
        allowedFileTypes.includes(attachment.type)
      );
      return { ...state, attachments: filteredAttachments };
    }

    case SET_SELECTED_ATTACHMENTS: {
      return {
        ...state,
        selectedAttachments: state.selectedAttachments.concat([
          { id: action.payload.id, type: action.payload.type },
        ]),
      };
    }

    case `${POST_ADD_ATTACHMENTS}/fulfilled`: {
      return { ...state, newUploadedAttachments: action.payload };
    }

    case REMOVE_SELECTED_ATTACHMENT: {
      const newList = state.selectedAttachments.filter((item) => item.id !== action.payload.id);
      return { ...state, selectedAttachments: newList };
    }

    case UPDATE_SELECTED_ATTACHMENTS: {
      return { ...state, selectedAttachments: action.payload };
    }

    case EDIT_ACTIVE_PURCHASE_ORDER_SILENTLY: {
      const { path, value } = action.payload;

      return produce(state, (draft) => {
        setKeypathValue(draft, ["activePurchaseOrder", ...path], value);
        draft.activePurchaseOrder.dirty = false;
      });
    }

    case PRINT_PURCHASE_ORDER_PDF_STATUS: {
      return { ...state, printPurchaseOrderPdfStatus: action.payload };
    }

    case EMAIL_PURCHASE_ORDER_STATUS: {
      return { ...state, emailPurchaseOrderStatus: action.payload };
    }

    case SET_IS_ADD_PRODUCT_MODAL_TYPE: {
      return { ...state, isAddProductModalType: action.payload };
    }

    default:
      return state;
  }
};
