import { legacy_createStore as createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { fromJS } from "immutable";
import { routerMiddleware } from "connected-react-router";

import history from "../app/history";

import { rootReducers } from "./root";
import { loadState, saveState } from "./localStorage";

const initialState = fromJS({});
const enhancers = [];
const middleware = [thunk, routerMiddleware(history)];

if (process.env.NODE_ENV !== "production" || process.env.ENV === "staging4") {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  console.log(
    `NODE ENV - ${process.env.NODE_ENV} - CUSTOM ENV - ${process.env.ENV}. Enable dev tools extension`
  );

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const persistedState = loadState();

const store = createStore(
  rootReducers,
  fromJS(persistedState),
  // initialState,
  composedEnhancers
);

store.subscribe(() => {
  saveState({
    preferences: store.getState().get("preferences"),
  });
});

export default store;
