import { SOCKET_URL } from "../env";

export const createOrderChatSocket = (token, userId) =>
  new WebSocket(`${SOCKET_URL}user_connect/${userId}/?token=${token}`);

export const createPurchaseChatSocket = (token, userId) =>
  new WebSocket(`${SOCKET_URL}user_connect/${userId}/?token=${token}`);

export const createCustomEventRemindersWebSocket = (token, userId) =>
  new WebSocket(`${SOCKET_URL}custom_event_reminders/${userId}/?token=${token}`);
