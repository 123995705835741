import React from "react";
import cx from "classnames";
import BasePhoneInput from "react-phone-number-input";

import styles from "./PhoneInput.module.scss";

const PhoneInput = (props) => {
  const { className, inputClassName, ...rest } = props;
  return <BasePhoneInput className={cx(styles.input, inputClassName)} {...rest} />;
};

export default PhoneInput;
