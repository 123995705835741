import qs from "query-string";

import { createApi, createApiV2 } from "./common";

// SUPPLIERS //
export const searchSuppliers =
  ({ search, includeArchived }) =>
  (token) =>
    createApi(
      "GET",
      `purchasing/suppliers/?search=${search}&include_archived=${includeArchived}`
    )(token);

export const postSupplier = (data) => (token) =>
  createApiV2("POST", "purchasing/suppliers/")(token, data);

export const getSupplier = (supplierUid) => (token) =>
  createApi("GET", `purchasing/suppliers/${supplierUid}/`)(token);

export const updateSupplier = (supplierUid, data) => (token) =>
  createApi("PATCH", `purchasing/suppliers/${supplierUid}/`)(token, data);

export const deleteSupplier = (supplierUid) => (token) =>
  createApi("DELETE", `purchasing/suppliers/${supplierUid}/`)(token);

export const bulkDeleteSuppliers = (supplierUids) => (token) =>
  createApi(
    "DELETE",
    `purchasing/suppliers/bulk_destroy/?${qs.stringify({ uids: supplierUids })}`
  )(token);

export const bulkRestoreSuppliers = (supplierUids) => (token) =>
  createApi("POST", "purchasing/suppliers/bulk_undestroy/")(token, {
    uids: supplierUids,
  });

// SUPPLIER CONTACTS //
export const getAllSupplierContacts =
  ({ supplierUid, search }) =>
  (token) =>
    createApi("GET", `purchasing/suppliers/${supplierUid}/contacts/?search=${search}`)(token);

export const createSupplierContact = (supplierUid, data) => (token) =>
  createApi("POST", `purchasing/suppliers/${supplierUid}/contacts/`)(token, data);

export const updateSupplierContact = (supplierUid, supplierContactUid, data) => (token) =>
  createApi("PATCH", `purchasing/suppliers/${supplierUid}/contacts/${supplierContactUid}/`)(
    token,
    data
  );

export const deleteSupplierContact = (supplierUid, supplierContactUid) => (token) =>
  createApi("DELETE", `purchasing/suppliers/${supplierUid}/contacts/${supplierContactUid}/`)(token);

// SUPPLIER PURCHASE ORDERS //
export const searchSupplierPurchaseOrders = (params) => (token) =>
  createApi("GET", `purchasing/purchase_orders/?${qs.stringify(params)}`)(token);
