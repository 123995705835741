import {
  SET_SOCKET_INSTANCE,
  SET_REFERENCE,
  SET_USER_ID,
  SET_ALL_MESSAGES,
  SET_ATTACHED_FILES_EDITOR,
  SET_ALL_NOTIFICATIONS,
  SET_ALL_COMPANY_USERS,
  SET_TO_EDIT_MESSAGE,
  SET_IS_UPLOAD_FILE,
  SET_IS_PENDING,
  SET_ATTACHMENT_FILES,
  RESET,
} from "./collaboration.actionTypes";

const initialState = {
  socketInstance: null,
  reference: null,
  userId: null,
  allMessages: [],
  attachedFilesEditor: [],
  allChatsNotifications: [],
  allCompanyUsers: [],
  toEditMessage: null,
  isUploadFile: false,
  isPending: false,
  attachmentFiles: {},
};

export default (namespace) =>
  (state = initialState, action) => {
    switch (action.type) {
      case `${namespace}${SET_SOCKET_INSTANCE}`: {
        return { ...state, socketInstance: action.payload };
      }

      case `${namespace}${SET_REFERENCE}`: {
        return { ...state, reference: action.payload };
      }

      case `${namespace}${SET_USER_ID}`: {
        return { ...state, userId: action.payload };
      }

      case `${namespace}${SET_ALL_MESSAGES}`: {
        return { ...state, allMessages: action.payload };
      }

      case `${namespace}${SET_ATTACHED_FILES_EDITOR}`: {
        return { ...state, attachedFilesEditor: action.payload };
      }

      case `${namespace}${SET_ALL_NOTIFICATIONS}`: {
        return { ...state, allChatsNotifications: action.payload };
      }

      case `${namespace}${SET_ALL_COMPANY_USERS}`: {
        return { ...state, allCompanyUsers: action.payload };
      }

      case `${namespace}${SET_TO_EDIT_MESSAGE}`: {
        return { ...state, toEditMessage: action.payload };
      }

      case `${namespace}${SET_IS_UPLOAD_FILE}`: {
        return { ...state, isUploadFile: action.payload };
      }

      case `${namespace}${SET_IS_PENDING}`: {
        return { ...state, isPending: action.payload };
      }

      case `${namespace}${SET_ATTACHMENT_FILES}`: {
        return { ...state, attachmentFiles: action.payload };
      }

      case `${namespace}${RESET}`: {
        return initialState;
      }

      default:
        return state;
    }
  };
