import { orderBy } from "lodash";

import * as orderActions from "../orders/orders.actions";
import * as orderSelectors from "../orders/orders.selectors";

import { SET_SELECTED_SORTING_OPTION, RESET } from "./sortFilter.actionTypes";

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setSelectedSortingOption = (data) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_SORTING_OPTION,
    payload: data,
  });
};

export const sortBacklogOrders = (value) => (dispatch, getState) => {
  const state = getState();
  const orders = orderSelectors.getOrders(state);

  const dateField = ["Created date", "Most recent"].includes(value)
    ? "createdTimestamp"
    : "requiredTimestamp";

  const nameField = value === "Customer name" && "customerName";

  if (value === "Customer name") {
    dispatch(orderActions.setOrders(orderBy(orders, [nameField], ["asc"])));
  } else if (["Created date", "Required date"].includes(value)) {
    dispatch(orderActions.setOrders(orderBy(orders, [dateField], ["asc"])));
  } else if (value === "Most recent") {
    dispatch(orderActions.setOrders(orderBy(orders, [dateField], ["desc"])));
  } else if (value === "Suburb") {
    dispatch(
      orderActions.setOrders([
        ...orderBy(orders, (order) => order.shippingAddress.city || "", ["asc"]).filter(
          (order) => !!order.shippingAddress.city
        ),
        ...orders.filter((order) => !order.shippingAddress.city),
      ])
    );
  } else {
    dispatch(orderActions.setOrders(orderBy(orders, ["purchaseOrder"], ["asc"])));
  }
};
