import React from "react";
import queryString from "query-string";
import { Route, Redirect } from "react-router-dom";

import reduxConnect from "../redux/utils/connect";
import Loading from "../components/common/Loading";
import { hasAccess } from "../utils/common";

const PrivateRoute = ({ component: Component, authenticationState, customersState, ...rest }) => {
  const renderComponent = (routeProps) => {
    const query = queryString.parse(routeProps.location.search);

    if (authenticationState.isCheckingAuth) {
      return <Loading className="vh-100" />;
    }

    // User is authenticated
    if (authenticationState.isAuthenticated) {
      if (!query.token) {
        const { userSignup, user, company } = customersState;

        // Page loading state
        if (!userSignup || !user || !company) {
          return <Loading className="vh-100" />;
        }

        if (!company.isLegacy && !company.onTrial && !company.isSubscribed && company.inAppBilling)
          return <Redirect to="/trial-ended" />;

        // Check if the user has permissions for current page
        const currentPath = routeProps.location.pathname + routeProps.location.search;
        const { accessBoolean, startPage } = hasAccess(user, company, currentPath);

        // User does not have permissions to view the page
        if (!accessBoolean) {
          return (
            <Redirect
              to={{
                pathname: startPage,
                state: { from: routeProps.location },
              }}
            />
          );
        }

        // Render component
        return <Component {...routeProps} />;
      }

      // Render component
      return <Component {...routeProps} />;
    }

    // User is not authenticated
    if (!authenticationState.isAuthenticated) {
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: routeProps.location },
          }}
        />
      );
    }
  };

  return <Route {...rest} render={(routeProps) => renderComponent(routeProps)} />;
};

export default reduxConnect({
  authenticationState: ["authentication"],
  customersState: ["customers"],
})(PrivateRoute);
