import createActionType from "@/redux/utils/createActionType";

export const MODULE = "users";

export const SET_IS_USERS_DROPDOWN_VISIBLE = createActionType(MODULE, "setIsUserDropdownVisible");

export const SET_IS_FETCHING_USERS = createActionType(MODULE, "setIsFetchingUsers");

export const SET_USERS = createActionType(MODULE, "setUsers");

export const SET_SELECTED_USERS = createActionType(MODULE, "setSelectedUsers");

export const SET_UNSELECTED_USERS = createActionType(MODULE, "setUnSelectedUsers");

export const SET_ACTIVE_USER = createActionType(MODULE, "setActiveUser");

export const RESET = createActionType(MODULE, "reset");
