import { handleError } from "../utils/error";
import * as api from "../../apiv2/assignees";

import * as selectors from "./assignees.selectors";
import {
  SET_IS_SAVING_USER,
  SET_IS_FETCHING_USERS,
  SET_USERS,
  SET_SELECTED_USERS,
  SET_UNSELECTED_USERS,
  RESET,
} from "./assignees.actionTypes";

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setIsSavingUser = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_SAVING_USER,
    payload: status,
  });
};

export const setIsFetchingUsers = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_USERS,
    payload: status,
  });
};

export const setUsers = (data) => (dispatch) => {
  dispatch({
    type: SET_USERS,
    payload: data,
  });
};

export const setSelectedUsers = (data) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_USERS,
    payload: data,
  });
};

export const setUnSelectedUsers = (data) => (dispatch) => {
  dispatch({
    type: SET_UNSELECTED_USERS,
    payload: data,
  });
};

export const toggleUsers = (uid) => (dispatch, getState) => {
  const state = getState();
  const selectedUserUids = selectors.getSelectedUsers(state);
  const unSelectedUserUids = selectors.getUnSelectedUsers(state);

  if (selectedUserUids.includes(uid)) {
    if (!unSelectedUserUids.includes(uid)) {
      dispatch(setUnSelectedUsers([...unSelectedUserUids, uid]));
    }
  }

  if (!selectedUserUids.includes(uid)) {
    if (unSelectedUserUids.includes(uid)) {
      dispatch(setUnSelectedUsers(unSelectedUserUids.filter((id) => id !== uid)));
    }
  }

  const newSelectedUserUids = selectedUserUids.includes(uid)
    ? selectedUserUids.filter((id) => id !== uid)
    : [...selectedUserUids, uid];

  dispatch(setSelectedUsers(newSelectedUserUids));
};

export const fetchUsers = () => async (dispatch) => {
  try {
    dispatch(setIsFetchingUsers(true));
    const response = await api.getUsers({ include_invites: false });
    dispatch(setUsers(response.data));
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetchingUsers(false));
  }
};

export const assignUsersToOrder = (uid) => async (dispatch, getState) => {
  try {
    const state = getState();
    const selectedUserUids = selectors.getSelectedUsers(state);
    const unSelectedUserUids = selectors.getUnSelectedUsers(state);

    const data = {
      users_assigned: selectedUserUids,
      users_not_assigned: unSelectedUserUids,
      order: uid,
    };
    await api.postAssignUser(data)();
  } catch (error) {
    handleError(error, dispatch);
  }
};
