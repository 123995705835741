import { MODULE } from "./importSuppliers.actionTypes";

export const getFetchingLatestSuppliersStatus = (state) =>
  state.getIn([MODULE, "fetchingLatestSuppliersStatus"]);
export const getLatestSuppliers = (state) => state.getIn([MODULE, "latestSuppliers"]);
export const getSearchLatestSuppliers = (state) => state.getIn([MODULE, "searchLatestSuppliers"]);
export const getIsLinkedSuppliers = (state) => state.getIn([MODULE, "isLinkedSuppliers"]);
export const getIsPreviouslyImportedSuppliers = (state) =>
  state.getIn([MODULE, "isPreviouslyImportedSuppliers"]);
export const getSuppliersToImport = (state) => state.getIn([MODULE, "suppliersToImport"]);
export const getDuplicateSuppliersToImport = (state) =>
  state.getIn([MODULE, "duplicateSuppliersToImport"]);
export const getDuplicateSuppliersWarningModal = (state) =>
  state.getIn([MODULE, "duplicateSuppliersWarningModal"]);
export const getImportSuppliersStatus = (state) => state.getIn([MODULE, "importSuppliersStatus"]);

export const getImportSuppliers = (state) => state.getIn([MODULE, "importSuppliers"]);
export const getImportSuppliersSort = (state) => state.getIn([MODULE, "sort"]);
export const getImportSuppliersPage = (state) => state.getIn([MODULE, "page"]);
export const getImportSuppliersMeta = (state) => state.getIn([MODULE, "meta"]);
export const getIsLinkedSuppliersFilter = (state) =>
  state.getIn([MODULE, "isLinkedSuppliersFilter"]);
export const getImportAllSuppliersStatus = (state) =>
  state.getIn([MODULE, "importAllSuppliersStatus"]);
export const getImportAllSuppliers = (state) => state.getIn([MODULE, "importAllSuppliers"]);
