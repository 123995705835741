import queryString from "query-string";

import { createApiV2, createApiPdf } from "./common";

export const getPartialInvoiceAdditionalProducts =
  ({ customerUid, orderUid, params = {} }) =>
  (token) => {
    const queryParams = queryString.stringify(params);
    return createApiV2(
      "GET",
      `customers/${customerUid}/orders/${orderUid}/partial_invoice_additional_products/?${queryParams}`
    )(token);
  };

export const getPartialInvoices =
  ({ customerUid, orderUid, params = {} }) =>
  (token) => {
    const queryParams = queryString.stringify(params);
    return createApiV2(
      "GET",
      `customers/${customerUid}/orders/${orderUid}/partial_invoices/?${queryParams}`
    )(token);
  };

export const getPublicPartialInvoices =
  ({ customerUid, orderUid, params = {} }) =>
  (token) => {
    const queryParams = queryString.stringify(params);
    return createApiV2(
      "GET",
      `customers/${customerUid}/orders/${orderUid}/partial_invoices/public?${queryParams}`
    )(token);
  };

export const postPartialInvoice =
  ({ customerUid, orderUid, ...data }) =>
  (token) =>
    createApiV2(
      "POST",
      `customers/${customerUid}/orders/${orderUid}/partial_invoice_additional_products/`
    )(token, data);

export const postPrintPartialInvoice = (data) => (token) =>
  createApiPdf("POST", `order/print_partial_invoice`, {
    responseType: "arraybuffer",
    headers: { "Content-Type": "multipart/form-data" },
  })(token, data);

export const postEmailPartialInvoice =
  ({ customerUid, orderUid, data } = {}) =>
  (token) =>
    createApiPdf("POST", `customers/${customerUid}/orders/${orderUid}/notify/`, {
      headers: { "Content-Type": "multipart/form-data" },
    })(token, data);
