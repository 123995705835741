import { STATUS } from "@/utils/constants";
import * as api from "@/apiv2/timeTracking";
import { handleError } from "@/redux/utils/error";

import {
  SET_IS_FETCHING_LISTS_STATUS,
  SET_LISTS,
  SET_ACTIVE_LINE_ITEM,
  RESET,
} from "./activeTimeEntries.actionTypes";

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setIsFetchingListsStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_LISTS_STATUS,
    payload,
  });
};

export const setLists = (payload) => (dispatch) => {
  dispatch({
    type: SET_LISTS,
    payload,
  });
};

export const setActiveLineItem = (payload) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_LINE_ITEM,
    payload,
  });
};

export const fetchActiveTimeEntries = () => async (dispatch) => {
  try {
    dispatch(setIsFetchingListsStatus(STATUS.PENDING));
    const { data } = await api.getActiveTimeEntries()();
    dispatch(setLists(data));
    dispatch(setIsFetchingListsStatus(STATUS.FULFILLED));
  } catch (error) {
    handleError(error, dispatch);
    dispatch(setIsFetchingListsStatus(STATUS.REJECTED));
  }
};

export const fetchOrder =
  ({ orderUid, customerUid }) =>
  () =>
    api.getOrder({ orderUid, customerUid })();
export default {};
