import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import { cloneDeep, merge } from "lodash";

import { mainModalStyles } from "./Modal.styles";

const Modal = ({ isOpen, children, styles, className, onClose }) => {
  const clonedMainModalStyles = cloneDeep(mainModalStyles);

  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "auto";
  }, [isOpen]);

  useEffect(
    () => () => {
      document.body.style.overflow = "auto";
    },
    []
  );

  return (
    <ReactModal
      ariaHideApp={false}
      style={merge(clonedMainModalStyles, styles)}
      onRequestClose={() => {
        onClose();
      }}
      portalClassName={className}
      isOpen={isOpen}
    >
      {children}
    </ReactModal>
  );
};

Modal.defaultProps = {
  className: "",
  styles: {},
  onClose: () => {},
};

Modal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  styles: PropTypes.shape({}),
  onClose: PropTypes.func,
};

export default Modal;
