import qs from "query-string";
import { pick } from "lodash";
import { nanoid } from "nanoid";

import { handleError } from "../utils/error";
import * as api from "../../apiv2/views";
import * as alertActions from "../alerts/alerts.actions";
import * as routerActions from "../router/router.actions";
import * as routerSelectors from "../router/router.selectors";
import { userSelector } from "../customers/customers.selectors";

import * as selectors from "./views.selectors";
import {
  SET_IS_FETCHING_VIEWS,
  SET_VIEWS,
  SET_ACTIVE_VIEW,
  SET_IS_CREATE_VIEW_FORM_VISIBLE,
  SET_SELECTED_VIEW,
  SET_DEFAULT_VIEW,
  RESET,
} from "./views.actionTypes";

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setViews = (data) => (dispatch) => {
  dispatch({
    type: SET_VIEWS,
    payload: data,
  });
};

export const setActiveView = (data) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_VIEW,
    payload: data,
  });
};

export const setIsFetchingViews = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_VIEWS,
    payload: status,
  });
};

export const setSelectedView = (status) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_VIEW,
    payload: status,
  });
};

export const setDefaultView = (status) => (dispatch) => {
  dispatch({
    type: SET_DEFAULT_VIEW,
    payload: status,
  });
};

export const setIsCreateViewFormVisible = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_CREATE_VIEW_FORM_VISIBLE,
    payload: status,
  });
};

export const fetchViews = (params) => async (dispatch, getState) => {
  try {
    const state = getState();
    const currentUser = userSelector(state);

    dispatch(setIsFetchingViews(true));

    const { data } = await api.getViews(params)();

    dispatch(setViews([{ uid: "none", name: "None" }, ...data]));

    if (currentUser.defaultCustomViews[params.page] !== null) {
      const selectedView = data.find((d) => d.uid === currentUser.defaultCustomViews[params.page]);

      if (selectedView) {
        dispatch(setSelectedView(selectedView));
        dispatch(setDefaultView(selectedView));
        return;
      }
    }

    dispatch(setSelectedView({ uid: "none", name: "None" }));
    dispatch(setDefaultView({ uid: "none", name: "None" }));
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetchingViews(false));
  }
};

export const saveDefaultView =
  ({ uid, page }) =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const views = selectors.getViews(state);
      const view = views.find((v) => v.uid === uid);

      // check if uid belongs to None option
      if (view && view.name === "None") {
        await api.postDefaultView({ uid: null, page })();
      } else {
        await api.postDefaultView({ uid, page })();
      }

      dispatch(setDefaultView(view));
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const saveView =
  ({ payload }) =>
  async (dispatch, getState) => {
    const state = getState();
    const activeView = selectors.getActiveView(state);
    const views = selectors.getViews(state);
    const noneView = views.find((v) => v.name === "None");

    const customFilter =
      payload.custom_filter !== ""
        ? qs.stringify(
            pick(qs.parse(payload.custom_filter), [
              "assignees",
              "labels",
              "sort_by",
              "resources",
              "columns",
              "search",
              "is_scheduled",
            ])
          )
        : payload.customFilter;

    try {
      dispatch(setIsCreateViewFormVisible(false));

      if (payload.name === "None") {
        if (noneView) {
          await dispatch(saveDefaultView({ uid: noneView.uid, page: payload.page }));

          return noneView;
        }
      }

      const { data } = activeView
        ? await api.patchView({
            uid: activeView.uid,
            payload: { ...payload, custom_filter: customFilter },
          })()
        : await api.postView({ ...payload, custom_filter: customFilter })();

      if (activeView) {
        dispatch(setViews(views.map((v) => (v.uid === activeView.uid ? { ...data } : { ...v }))));
      } else {
        dispatch(setViews([...views, data]));
      }

      if (activeView) {
        await dispatch(
          saveDefaultView({ uid: payload.is_default ? data.uid : noneView.uid, page: payload.page })
        );
      }

      if (!activeView && payload.is_default) {
        await dispatch(saveDefaultView({ uid: data.uid, page: payload.page }));
      }

      return data;
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const removeView = (uid) => async (dispatch, getState) => {
  try {
    const state = getState();
    const page = routerSelectors.getPath(state).substring(1);
    const views = selectors.getViews(state);
    const selectedView = selectors.getSelectedView(state);
    const defaultView = selectors.getDefaultView(state);
    const noneView = views.find((v) => v.name === "None");

    await api.deleteView(uid)();
    dispatch(setViews(views.filter((v) => v.uid !== uid)));
    dispatch(alertActions.createAlertAction(uid, "View Deleted", true, "danger"));
    dispatch(setActiveView(null));
    dispatch(setIsCreateViewFormVisible(false));

    // if Default View is being deleted and
    // also the selected View reset back the default view to `None`
    if (selectedView.uid === uid && defaultView.uid === uid) {
      dispatch(setSelectedView(noneView));
      dispatch(saveDefaultView({ uid: noneView.uid, page }));
    }

    // if the selected view is the same with the view being deleted
    // revert back to the default view
    else if (selectedView.uid === uid && defaultView.uid !== uid) {
      dispatch(setSelectedView(defaultView));
      dispatch(saveDefaultView({ uid: defaultView.uid, page }));
    }
  } catch (error) {
    handleError(error, dispatch);
  }
};

// export const getView = (uid) => async (dispatch) => {
//   try {
//     if (uid) {
//       const { data } = await api.getView(uid)();
//       dispatch(setDefaultView(data));
//     } else {
//       dispatch(setDefaultView(view));
//     }
//   } catch (error) {
//     handleError(error, dispatch);
//   }
// };
