import {
  SET_IS_LABELS_DROPDOWN_VISIBLE,
  SET_IS_SAVING_LABEL,
  SET_IS_FETCHING_LABELS,
  SET_LABELS,
  SET_SELECTED_LABELS,
  SET_UNSELECTED_LABELS,
  SET_ACTIVE_LABEL,
  SET_IS_CREATE_LABEL_FORM_VISIBLE,
  SET_IS_DELETE_LABEL_ALERT_VISIBLE,
  RESET,
} from "./labels.actionTypes";

const initialState = {
  isLabelsDropdownVisible: false,
  isSavingLabel: false,
  isFetchingLabels: false,
  labels: [],
  selectedLabels: [],
  unSelectedLabels: [],
  activeLabel: null,
  isCreateLabeLFormVisible: false,
  isDeleteLabelAlertVisible: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_LABELS_DROPDOWN_VISIBLE: {
      return {
        ...state,
        isLabelsDropdownVisible: action.payload,
      };
    }

    case SET_IS_SAVING_LABEL: {
      return {
        ...state,
        isSavingLabel: action.payload,
      };
    }

    case SET_IS_FETCHING_LABELS: {
      return {
        ...state,
        isFetchingLabels: action.payload,
      };
    }

    case SET_LABELS: {
      return {
        ...state,
        labels: action.payload,
      };
    }

    case SET_SELECTED_LABELS: {
      return {
        ...state,
        selectedLabels: action.payload,
      };
    }

    case SET_UNSELECTED_LABELS: {
      return {
        ...state,
        unSelectedLabels: action.payload,
      };
    }

    case SET_ACTIVE_LABEL: {
      return {
        ...state,
        activeLabel: action.payload,
      };
    }
    case SET_IS_CREATE_LABEL_FORM_VISIBLE: {
      return {
        ...state,
        isCreateLabeLFormVisible: action.payload,
      };
    }
    case SET_IS_DELETE_LABEL_ALERT_VISIBLE: {
      return {
        ...state,
        isDeleteLabelAlertVisible: action.payload,
      };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
