import createActionType from "../../utils/createActionType";

export const MODULE = "delivery-scheduling";

export const SET_SETTINGS = createActionType(MODULE, "setSettings");

export const SET_IS_FETCHING_SETTINGS = createActionType(MODULE, "setIsFetchingSettings");

export const SET_IS_ORDER_MODAL_VISIBLE = createActionType(MODULE, "setIsOrderModalVisible");

export const SET_IS_DELIVERY_SETTINGS_VISIBLE = createActionType(
  MODULE,
  "setIsDeliverySettingsVisible"
);

export const SET_IS_DELETE_VEHICLE_MODAL_VISIBLE = createActionType(
  MODULE,
  "setIsDeleteVehicleModalVisible"
);

export const SET_IS_DELIVERY_RUNSHEET_VISIBLE = createActionType(
  MODULE,
  "setIsDeliveryRunSheetVisible"
);

export const SET_IS_ASSIGN_USER_MENU_VISIBLE = createActionType(
  MODULE,
  "setIsAssignUserMenuVisible"
);

export const SET_IS_ASSIGN_LABEL_MENU_VISIBLE = createActionType(
  MODULE,
  "setIsAssignLabelMenuVisible"
);

export const SET_ASSIGN_USER_DROPDOWN_COORDINATES = createActionType(
  MODULE,
  "setAssignUserDropdownCoordinates"
);

export const SET_ASSIGN_LABEL_DROPDOWN_COORDINATES = createActionType(
  MODULE,
  "setAssignLabelDropdownCoordinates"
);

export const SET_ACTIVE_ORDER = createActionType(MODULE, "setActiveOrder");

export const SET_SELECTED_RESOURCES = createActionType(MODULE, "setSelectedResources");

export const SET_SELECTED_VEHICLE = createActionType(MODULE, "setSelectedVehicle");

export const SET_IS_ORDER_COLUMN_COLLAPSE = createActionType(MODULE, "setIsOrderColumnCollapse");

export const RESET = createActionType(MODULE, "reset");
