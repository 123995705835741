import createActionType from "@/redux/utils/createActionType";

export const MODULE = "dashboard";
export const SET_IS_EDITTING = createActionType(MODULE, "setIsEditting");
export const SET_CHARTS = createActionType(MODULE, "setCharts");
export const SET_CHARTS_STATUS = createActionType(MODULE, "setChartsStatus");
export const SET_ADD_USER_WIDGET_STATUS = createActionType(MODULE, "setAddUserWidgetStatus");
export const SET_USER_WIDGETS = createActionType(MODULE, "setUserWidgets");
export const SET_USER_WIDGETS_STATUS = createActionType(MODULE, "setUserWidgetsStatus");
export const SET_UPDATE_USER_WIDGET_STATUS = createActionType(MODULE, "setUpdateUserWidgetStatus");
export const SET_UPDATE_BULK_USER_WIDGET_STATUS = createActionType(
  MODULE,
  "setUpdateBulkUserWidgetStatus"
);
export const SET_DELETE_USER_WIDGET_STATUS = createActionType(MODULE, "setDeleteUserWidgetStatus");

export const SET_SALES_ORDERS = createActionType(MODULE, "setSalesOrders");
export const SET_PURCHASE_ORDERS = createActionType(MODULE, "setPurchaseOrders");
export const SET_OPEN_QUOTES = createActionType(MODULE, "setOpenQuotes");
export const SET_ORDERS_BY_REQUIRED_DATE = createActionType(MODULE, "setOrdersByRequiredDate");
export const SET_ORDERS_BY_STATUS = createActionType(MODULE, "setOrdersByStatus");
export const SET_ORDERS_CREATED_BY_ME = createActionType(MODULE, "setOrdersCreatedByMe");
export const SET_TOP_CUSTOMERS = createActionType(MODULE, "setTopCustomers");
export const SET_DELIVERIES_SCHEDULED = createActionType(MODULE, "setDeliveriesScheduled");
export const SET_ORDERS_BY_TEAM_MEMBER = createActionType(MODULE, "setOrdersByTeamMember");
export const SET_ALL_ORDERS_WORKED_ON = createActionType(MODULE, "setAllOrdersWorkedOn");
export const SET_ORDERS_ACTIVELY_WORKED_ON = createActionType(MODULE, "setOrdersActivelyWorkedOn");
export const SET_TIME_WORKED_PER_TEAM_MEMBER = createActionType(
  MODULE,
  "setTimeWorkedPerTeamMember"
);
export const SET_CHARTS_REQUEST_PARAMS = createActionType(MODULE, "setChartsRequestParams");
export const SET_ORDER_STATUS = createActionType(MODULE, "setOrderStatus");
export const SET_USERS = createActionType(MODULE, "setUsers");
export const RESET = createActionType(MODULE, "reset");

export const SET_CHART_LOADING_STATUS = createActionType(MODULE, "setChartLoadingStatus");

export const SET_EXPORT_TO_FILE_LOADING_STATUS = createActionType(
  MODULE,
  "setExportToFileLoadingStatus"
);
