import qs from "query-string";

import { createApiV2 } from "./common";

const headers = {
  "Content-Type": "multipart/form-data",
};

export const getCategories = () => (token) =>
  createApiV2("GET", "additional_products_categories/")(token);

export const getProductsByCategory = (data) => (token) =>
  createApiV2("GET", `additional_products/product_kits/?${qs.stringify(data)}`)(token);

export const postProductKit = (data) => (token) =>
  createApiV2("POST", "additional_products/", { headers })(token, data);

export const getProductKit = (productUid) => (token) =>
  createApiV2("GET", `additional_products/${productUid}/`)(token);

export const postSaveCustomFormula = (data) => (token) =>
  createApiV2("POST", `custom_formulas/`)(token, data);

export const patchSaveCustomFormula = (uid, data) => (token) =>
  createApiV2("PATCH", `custom_formulas/${uid}/`)(token, data);
