import createActionType from "../utils/createActionType";

const MODULE = "salesOrders";

// Already Linked Orders
export const GET_LINKED_ORDERS_FROM_ORDER = createActionType(MODULE, "getLinkedOrdersFromOrder");

export const SET_LINKED_ORDERS_TYPE = createActionType(MODULE, "setLinkedOrdersType");

export const SET_LINKED_ORDERS_STATUS = createActionType(MODULE, "setLinkedOrdersStatus");

export const SET_LINKED_ORDERS_SEARCH = createActionType(MODULE, "setLinkedOrdersSearch");

export const SET_LINKED_ORDERS_PAGE = createActionType(MODULE, "setLinkedOrdersPage");

export const SET_LINKED_ORDERS_PAGE_SIZE = createActionType(MODULE, "setLinkedOrdersPageSize");

export const SET_LINKED_ORDERS_SELECTED_IDS = createActionType(
  MODULE,
  "setLinkedOrdersSelectedIds"
);

// Linking Orders (MODAL)
export const POST_BULK_LINK_ORDERS_FROM_ORDER = createActionType(
  MODULE,
  "postBulkLinkOrdersFromOrder"
);

export const GET_UNLINKED_ORDERS_FROM_ORDER = createActionType(
  MODULE,
  "getUnlinkedOrdersFromOrder"
);

export const DELETE_BULK_UNLINK_ORDERS_ORDER = createActionType(
  MODULE,
  "deleteBulkUnlinkOrdersFromOrder"
);

export const SET_UNLINKED_ORDERS_TYPE = createActionType(MODULE, "setUnlinkedOrdersType");

export const SET_UNLINKED_ORDERS_STATUS = createActionType(MODULE, "setUnlinkedOrdersStatus");

export const SET_UNLINKED_ORDERS_SEARCH = createActionType(MODULE, "setUnlinkedOrdersSearch");

export const SET_UNLINKED_ORDERS_PAGE = createActionType(MODULE, "setUnlinkedOrdersPage");

export const SET_UNLINKED_ORDERS_PAGE_SIZE = createActionType(MODULE, "setUnlinkedOrdersPageSize");

export const SET_UNLINKED_ORDERS_SELECTED_IDS = createActionType(
  MODULE,
  "setUnlinkedOrdersSelectedIds"
);

export const SET_SORT = createActionType(MODULE, "setSort");

export const GET_ORDER_HISTORY = createActionType(MODULE, "getOrderHistory");

export const SET_ORDER_HISTORY_TYPE = createActionType(MODULE, "setOrderHistoryType");

export const SET_ORDER_HISTORY_STATUS = createActionType(MODULE, "setOrderHistoryStatus");

export const SET_ORDER_HISTORY_SEARCH = createActionType(MODULE, "setOrderHistorySearch");

export const SET_ORDER_HISTORY_PAGE = createActionType(MODULE, "setOrderHistoryPage");

export const SET_ORDER_HISTORY_PAGE_SIZE = createActionType(MODULE, "setOrderHistoryPageSize");

export const SET_ORDER_HISTORY_ROW_PAGE = createActionType(MODULE, "setOrderHistoryRowPage");

export const SET_ORDER_HISTORY_ROW_PAGE_SIZE = createActionType(
  MODULE,
  "setOrderHistoryRowPageSize"
);

export const SET_ORDER_HISTORY_QTY_TOTAL = createActionType(MODULE, "orderHistoryQtyTotal");

export const SET_ORDER_HISTORY_FROM_DATE = createActionType(MODULE, "orderHistoryFromDate");

export const SET_ORDER_HISTORY_TO_DATE = createActionType(MODULE, "orderHistoryToDate");
