import {
  SET_IS_FETCHING_USERS,
  SET_USERS,
  SET_SELECTED_USERS,
  SET_IS_FETCHING_LABELS,
  SET_LABELS,
  SET_ORDER_IN_CALENDAR,
  SET_IS_FETCHING_SCHEDULES,
  SET_FROM_DATE,
  SET_TO_DATE,
  SET_ACTIVE_SCHEDULE,
  SET_IS_CREATING_SCHEDULE,
  SET_CALENDAR_VIEW,
  SET_SHOW_CUSTOM_EVENT_MODAL,
  SET_SHOW_DELETE_CUSTOM_EVENT_WARNING_MODAL,
  SET_SHOW_EDIT_CUSTOM_EVENT_WARNING_MODAL,
  SET_DATA_IN_CALENDAR_ENTRY,
  SET_DELETE_SCOPE,
  SET_EDIT_SCOPE,
  SET_INTITIAL_CUSTOM_EVENT_DATA,
  RESET,
} from "./calendar.actionTypes";

const initialState = {
  isFetchingUsers: false,
  isFetchingLabels: false,
  isFetchingSchedules: false,
  isCreatingSchedule: false,
  users: [],
  selectedUsers: [],
  labels: [],
  schedules: [],
  fromDate: null,
  toDate: null,
  activeSchedule: null,
  calendarView: "day",
  showCustomEventModal: false,
  showDeleteCustomEventWarningModal: false,
  showEditCustomEventWarningModal: false,
  deleteScope: null,
  editScope: null,
  initialCustomEventData: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_FETCHING_USERS: {
      return {
        ...state,
        isFetchingUsers: action.payload,
      };
    }

    case SET_USERS: {
      return {
        ...state,
        users: action.payload,
      };
    }

    case SET_SELECTED_USERS: {
      return {
        ...state,
        selectedUsers: action.payload,
      };
    }

    case SET_IS_FETCHING_LABELS: {
      return {
        ...state,
        isFetchingLabels: action.payload,
      };
    }

    case SET_LABELS: {
      return {
        ...state,
        labels: action.payload,
      };
    }

    case SET_IS_FETCHING_SCHEDULES: {
      return {
        ...state,
        isFetchingSchedules: action.payload,
      };
    }

    case SET_ORDER_IN_CALENDAR: {
      return {
        ...state,
        schedules: action.payload,
      };
    }

    case SET_FROM_DATE: {
      return {
        ...state,
        fromDate: action.payload,
      };
    }

    case SET_TO_DATE: {
      return {
        ...state,
        toDate: action.payload,
      };
    }

    case SET_ACTIVE_SCHEDULE: {
      return {
        ...state,
        activeSchedule: action.payload,
      };
    }

    case SET_IS_CREATING_SCHEDULE: {
      return {
        ...state,
        isCreatingSchedule: action.payload,
      };
    }

    case SET_CALENDAR_VIEW: {
      return {
        ...state,
        calendarView: action.payload,
      };
    }

    case SET_SHOW_CUSTOM_EVENT_MODAL: {
      return {
        ...state,
        showCustomEventModal: action.payload,
      };
    }

    case SET_SHOW_DELETE_CUSTOM_EVENT_WARNING_MODAL: {
      return {
        ...state,
        showDeleteCustomEventWarningModal: action.payload,
      };
    }

    case SET_SHOW_EDIT_CUSTOM_EVENT_WARNING_MODAL: {
      return {
        ...state,
        showEditCustomEventWarningModal: action.payload,
      };
    }

    case SET_DELETE_SCOPE: {
      return {
        ...state,
        deleteScope: action.payload,
      };
    }

    case SET_EDIT_SCOPE: {
      return {
        ...state,
        editScope: action.payload,
      };
    }

    case SET_INTITIAL_CUSTOM_EVENT_DATA: {
      return {
        ...state,
        initialCustomEventData: action.payload,
      };
    }

    case SET_DATA_IN_CALENDAR_ENTRY: {
      const { plannerUid, data } = action.payload;

      return {
        ...state,
        schedules: state.schedules.map((s) =>
          s.uid === plannerUid ? { ...s, order: { ...s.order, ...data } } : s
        ),
      };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
