import createActionType from "../utils/createActionType";

export const MODULE = "forgot-password";

export const SET_IS_SAVING = createActionType(MODULE, "setIsSaving");

export const SET_IS_SUCCESS = createActionType(MODULE, "setIsSuccess");

export const SET_IS_VALIDATING_TOKEN = createActionType(MODULE, "setIsValidatingToken");

export const SET_IS_TOKEN_VALID = createActionType(MODULE, "setIsTokenValid");

export const SET_EMAIL_FROM_TOKEN = createActionType(MODULE, "setEmailFromToken");

export const RESET = createActionType(MODULE, "reset");
