import { MODULE } from "./orderTracking.actionTypes";

export const itemsProgress = (state) => state.getIn([MODULE, "itemsProgress"]);
export const items = (state) => state.getIn([MODULE, "items"]);
export const parentItemUpdateProgress = (state) =>
  state.getIn([MODULE, "parentItemUpdateProgress"]);
export const subKitUpdateProgress = (state) => state.getIn([MODULE, "subKitUpdateProgress"]);
export const itemUpdateProgress = (state) => state.getIn([MODULE, "itemUpdateProgress"]);
export const statusReferencesProgress = (state) =>
  state.getIn([MODULE, "statusReferencesProgress"]);
export const statusReferences = (state) => state.getIn([MODULE, "statusReferences"]);
export const statusReferenceCreateProgress = (state) =>
  state.getIn([MODULE, "statusReferenceCreateProgress"]);
export const statusReferenceDeleteProgress = (state) =>
  state.getIn([MODULE, "statusReferenceDeleteProgress"]);
export const statusReferenceUpdateProgress = (state) =>
  state.getIn([MODULE, "statusReferenceUpdateProgress"]);
export const statusReferenceToDeleteId = (state) =>
  state.getIn([MODULE, "statusReferenceToDeleteId"]);
export const statusReferenceLineItemActive = (state) =>
  state.getIn([MODULE, "statusReferenceLineItemActive"]);
export const statusReferenceToEdit = (state) => state.getIn([MODULE, "statusReferenceToEdit"]);
export const additionalProductsProgress = (state) =>
  state.getIn([MODULE, "additionalProductsProgress"]);
export const activeLineItem = (state) => state.getIn([MODULE, "activeLineItem"]);
export const isFetchingActiveLineItemUid = (state) =>
  state.getIn([MODULE, "isFetchingActiveLineItemUid"]);
export const additionalProducts = (state) =>
  state.getIn([MODULE, "additionalProducts"]).map((a) => ({
    ...a,
    productKitItems: a?.productKitItems?.map((p) => ({
      ...p,
      productKitUid: a.uid,
      productType: "Kit item",
    })),
    subKits: a?.subKits?.map((s) => ({
      ...s,
      productKitUid: a.uid,
      productType: "Sub-kit",
      kitProducts: s.kitProducts.map((k) => ({
        ...k,
        productKitUid: a.uid,
        subKitUid: s.uid,
        productType: "Sub-kit item",
      })),
    })),
  }));
