import { createApi } from "./common";

const headers = {
  "Content-Type": "multipart/form-data",
};

export const submitProduct = (token, data) =>
  createApi("POST", "additional_products/", { headers })(token, data);

export const deleteProduct = (token, productId) =>
  createApi("DELETE", `additional_products/${productId}/`)(token);

export const searchProducts = createApi("GET", "additional_products/");

export const getProduct = (token, productId) =>
  createApi("GET", `additional_products/${productId}/`)(token);

export const getCategories = createApi("GET", "additional_products_categories/");

export const updateCategories = createApi("POST", "additional_products_categories/");

export const deleteCategory = (token, categoryId) =>
  createApi("DELETE", `additional_products_categories/${categoryId}/`)(token);

export const patchSaveCustomFormula = (token, uid, data) =>
  createApi("PATCH", `custom_formulas/${uid}/`)(token, data);

export default searchProducts;
