import { nanoid } from "nanoid";
import { reject, isEmpty } from "lodash";

import * as api from "../../apiv2/orderChecklists";
import { handleError } from "../utils/error";
import * as alertActions from "../alerts/alerts.actions";
import { userSelector } from "../customers/customers.selectors";

import * as selectors from "./orderChecklists.selectors";
import {
  SET_IS_ADD_CHECKLIST_FORM_VISIBLE,
  SET_IS_ADD_SUB_ITEM_FORM_VISIBLE,
  SET_PARENT_ITEM_TO_DISPLAY_THE_FORM,
  SET_IS_FETCHING_CHECKLISTS,
  SET_CHECKLISTS,
  SET_CLOSE_CHECKLIST_UIDS,
  INDENT_SUB_ITEM,
  INDENT_BACKWARDS_SUB_ITEM,
  UPDATE_SUB_ITEM_FIELD_VALUE,
  APPEND_SUB_ITEM,
  REMOVE_SUB_ITEM,
  MARK_ITEM_AS_COMPLETED,
  MARK_ITEM_AS_NOT_COMPLETED,
  SET_ALL_CHECKLISTS_ATTACHMENTS,
  SET_CHECKLIST_ITEM_UPLOADING,
  RESET,
} from "./orderChecklists.actionTypes";

const flatten = (lists) =>
  lists.reduce((r, { subitems, ...rest }) => {
    r.push(rest);
    if (subitems) r.push(...flatten(subitems));
    return r;
  }, []);

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setIsAddChecklistFormVisible = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_ADD_CHECKLIST_FORM_VISIBLE,
    payload: data,
  });
};

export const setIsAddSubItemFormVisible = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_ADD_SUB_ITEM_FORM_VISIBLE,
    payload: data,
  });
};

export const setParentItemToDiplayTheForm = (data) => (dispatch) => {
  dispatch({
    type: SET_PARENT_ITEM_TO_DISPLAY_THE_FORM,
    payload: data,
  });
};

export const setIsFetchingChecklists = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_CHECKLISTS,
    payload: data,
  });
};

export const setCloseChecklistUids = (data) => (dispatch) => {
  dispatch({
    type: SET_CLOSE_CHECKLIST_UIDS,
    payload: data,
  });
};

export const setChecklists = (data) => (dispatch) => {
  dispatch({
    type: SET_CHECKLISTS,
    payload: data,
  });
};

export const indentSubItem = (data) => (dispatch) => {
  dispatch({
    type: INDENT_SUB_ITEM,
    payload: data,
  });
};

export const indentBackwardSubItem = (data) => (dispatch) => {
  dispatch({
    type: INDENT_BACKWARDS_SUB_ITEM,
    payload: data,
  });
};

export const updateSubItemFieldValue = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_SUB_ITEM_FIELD_VALUE,
    payload: data,
  });
};

export const removeSubItem = (data) => (dispatch) => {
  dispatch({
    type: REMOVE_SUB_ITEM,
    payload: data,
  });
};

export const markItemAsCompleted = (data) => (dispatch) => {
  dispatch({
    type: MARK_ITEM_AS_COMPLETED,
    payload: data,
  });
};

export const markItemAsNotCompleted = (data) => (dispatch) => {
  dispatch({
    type: MARK_ITEM_AS_NOT_COMPLETED,
    payload: data,
  });
};

export const setChecklistItemUploading = (data) => (dispatch) => {
  dispatch({
    type: SET_CHECKLIST_ITEM_UPLOADING,
    payload: data,
  });
};

export const setAllChecklistsAttachments = (data) => (dispatch) => {
  dispatch({
    type: SET_ALL_CHECKLISTS_ATTACHMENTS,
    payload: data,
  });
};

export const appendChecklist = (payload) => (dispatch, getState) => {
  const state = getState();
  const checklists = selectors.getChecklists(state);

  const newChecklists = [...checklists, payload];

  dispatch(setChecklists(newChecklists));

  const flattenChecklists = newChecklists.reduce((a, b) => a.concat(b.items), []);

  dispatch(
    setAllChecklistsAttachments(
      flatten(flattenChecklists).reduce((a, b) => a.concat(b.attachments), [])
    )
  );
};

export const updateChecklistState =
  ({ uid, payload }) =>
  (dispatch, getState) => {
    const state = getState();
    const checklists = selectors.getChecklists(state);

    dispatch(
      setChecklists(checklists.map((c) => (c.uid === uid ? { ...c, ...payload } : { ...c })))
    );
  };

export const createChecklist = (payload) => async (dispatch, getState) => {
  const state = getState();
  const checklists = selectors.getChecklists(state);

  try {
    const { data } = await api.postChecklist(payload)();

    dispatch(
      setChecklists([
        ...checklists,
        {
          ...data,
        },
      ])
    );

    dispatch(setIsAddChecklistFormVisible(false));
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const fetchChecklists = (params) => async (dispatch) => {
  dispatch(setIsFetchingChecklists(true));

  try {
    const { data } = await api.getChecklists({ ...params, template_only: false })();

    const checklists = data.reduce((a, b) => a.concat(b.items), []);

    dispatch(
      setAllChecklistsAttachments(flatten(checklists).reduce((a, b) => a.concat(b.attachments), []))
    );

    dispatch(setChecklists(data));

    if (data.length === 0) {
      await dispatch(
        createChecklist({
          ...params,
          name: "Checklist",
        })
      );
    }
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetchingChecklists(false));
  }
};

export const renameChecklist =
  ({ uid, payload }) =>
  async (dispatch) => {
    try {
      await api.patchChecklist(uid, payload)();
      dispatch(setIsAddChecklistFormVisible(false));
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const removeChecklist = (checklistUid) => (dispatch, getState) => {
  try {
    const state = getState();
    const checklists = selectors.getChecklists(state);

    dispatch(setChecklists(checklists.filter((c) => c.uid !== checklistUid)));

    api.deleteChecklist(checklistUid)();

    dispatch(alertActions.createAlertAction(checklistUid, "Checklist deleted", true, "danger"));
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const appendSubItem =
  ({ firstLevelIndex, secondLevelIndex, parentItem, level, payload }) =>
  (dispatch, getState) => {
    const state = getState();
    const checklists = selectors.getChecklists(state);
    const checklistIndex = checklists.findIndex((c) => c.uid === parentItem.checklistUid);

    const data = {
      uid: nanoid(),
      text: payload.text,
      checklistUid: parentItem.checklistUid,
      parentUid: parentItem.uid,
      subitems: [],
      completedAt: null,
      completedBy: null,
      attachments: [],
      index: payload.index,
    };

    dispatch({
      type: APPEND_SUB_ITEM,
      payload: {
        checklistIndex,
        firstLevelIndex,
        secondLevelIndex,
        level,
        data,
      },
    });
  };

export const updateChecklistItem =
  ({
    firstLevelIndex,
    secondLevelIndex,
    thirdLevelIndex,
    checklistUid,
    checklistItemUid,
    level,
    fields,
    payload = {},
  }) =>
  (dispatch, getState) => {
    try {
      const state = getState();
      const checklists = selectors.getChecklists(state);
      const checklistIndex = checklists.findIndex((c) => c.uid === checklistUid);

      if (level === 1) {
        fields.forEach((f) => {
          dispatch(
            updateSubItemFieldValue({
              path: ["checklists", checklistIndex, "items", firstLevelIndex, f.key],
              value: f.value,
            })
          );
        });
      } else if (level === 2) {
        fields.forEach((f) => {
          dispatch(
            updateSubItemFieldValue({
              path: [
                "checklists",
                checklistIndex,
                "items",
                firstLevelIndex,
                "subitems",
                secondLevelIndex,
                f.key,
              ],
              value: f.value,
            })
          );
        });
      } else if (level === 3) {
        fields.forEach((f) => {
          dispatch(
            updateSubItemFieldValue({
              path: [
                "checklists",
                checklistIndex,
                "items",
                firstLevelIndex,
                "subitems",
                secondLevelIndex,
                "subitems",
                thirdLevelIndex,
                f.key,
              ],
              value: f.value,
            })
          );
        });
      }

      if (Object.keys(payload).length > 0) {
        api.patchChecklistItem(checklistItemUid, payload)();
      }
    } catch (error) {
      handleError(error, dispatch);
    }
  };

// Create items and sub items
export const createChecklistSubItem =
  ({
    checklistUid,
    firstLevelIndex,
    secondLevelIndex,
    thirdLevelIndex,
    level,
    parentItem,
    payload,
  }) =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const checklists = selectors.getChecklists(state);
      const checklistIndex = checklists.findIndex((c) => c.uid === checklistUid);

      dispatch(
        appendSubItem({
          firstLevelIndex,
          secondLevelIndex,
          parentItem,
          level,
          payload,
        })
      );

      const { data } = await api.postChecklistItem(checklistUid, payload)();

      if (level === 1) {
        dispatch(
          updateChecklistItem({
            firstLevelIndex: checklists[checklistIndex].items.length,
            checklistUid: data.checklistUid,
            checklistItemUid: data.uid,
            level,
            fields: [{ key: "uid", value: data.uid }],
          })
        );
      } else if (level === 2) {
        dispatch(
          updateChecklistItem({
            firstLevelIndex,
            secondLevelIndex: parseInt(secondLevelIndex) + 1,
            checklistUid: data.checklistUid,
            checklistItemUid: data.uid,
            level,
            fields: [{ key: "uid", value: data.uid }],
          })
        );

        api.postChecklistItemNotComplete(parentItem.uid)();
      } else if (level === 3) {
        dispatch(
          updateChecklistItem({
            firstLevelIndex,
            secondLevelIndex,
            thirdLevelIndex: parseInt(thirdLevelIndex) + 1,
            checklistUid: data.checklistUid,
            checklistItemUid: data.uid,
            level,
            fields: [{ key: "uid", value: data.uid }],
          })
        );

        // Mark parent item (1st level) as not completed if new sub item is created
        api.postChecklistItemNotComplete(checklists[checklistIndex].items[firstLevelIndex].uid)();

        // Mark parent item (2nd level) as not completed if new sub item is created
        api.postChecklistItemNotComplete(parentItem.uid)();
      }
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const markItemComplete =
  ({
    firstLevelIndex,
    secondLevelIndex,
    thirdLevelIndex,
    checklistUid,
    checklistItemUid,
    level,
    item,
  }) =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const currentUser = userSelector(state);
      const checklists = selectors.getChecklists(state);
      const checklistIndex = checklists.findIndex((c) => c.uid === checklistUid);

      dispatch(
        markItemAsCompleted({
          checklistIndex,
          firstLevelIndex,
          secondLevelIndex,
          thirdLevelIndex,
          level,
          item,
          currentUser,
        })
      );

      if (level === 1) {
        // if (item.subitems.length > 0) {
        // Promise.all(
        //   item.subitems
        //     .filter((i) => i.completedAt === null)
        //     .map((i) => api.postChecklistItemComplete(i.uid)())
        // );
        // }

        // get the second level items
        const flattedNestedSubItems = (arr) =>
          arr.flatMap(({ subitems, ...rest }) => [].concat(rest, flattedNestedSubItems(subitems)));

        Promise.all(
          flattedNestedSubItems(item.subitems)
            .filter((i) => i.completedAt === null)
            .map((i) => api.postChecklistItemComplete(i.uid)())
        );
      } else if (level === 2) {
        const siblingItems = checklists[checklistIndex].items[firstLevelIndex].subitems.filter(
          (i) => i.uid !== item.uid
        );
        const completedItems = siblingItems.filter((i) => i.completedAt !== null);

        if (item.subitems.length > 0) {
          Promise.all(
            item.subitems
              .filter((i) => i.completedAt === null)
              .map((i) => api.postChecklistItemComplete(i.uid)())
          );
        }

        if (siblingItems.length === completedItems.length) {
          api.postChecklistItemComplete(item.parentUid)();
        }
      } else if (level === 3) {
        const siblingItems = checklists[checklistIndex].items[firstLevelIndex].subitems[
          secondLevelIndex
        ].subitems.filter((i) => i.uid !== item.uid);
        const completedItems = siblingItems.filter((i) => i.completedAt !== null);

        if (siblingItems.length === completedItems.length) {
          api.postChecklistItemComplete(item.parentUid)();

          // get the second level items.
          const secondLevelItems = checklists[checklistIndex].items[
            firstLevelIndex
          ].subitems.filter((i) => i.uid !== item.uid);
          const completedSecondLevelItems = secondLevelItems.filter((i) => i.completedAt !== null);

          if (secondLevelItems.length === completedSecondLevelItems.length + 1) {
            api.postChecklistItemComplete(checklists[checklistIndex].items[firstLevelIndex].uid)();
          }
        }
      }

      api.postChecklistItemComplete(checklistItemUid)();
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const markItemNotComplete =
  ({
    firstLevelIndex,
    secondLevelIndex,
    thirdLevelIndex,
    checklistUid,
    checklistItemUid,
    level,
    item,
  }) =>
  (dispatch, getState) => {
    try {
      const state = getState();
      const checklists = selectors.getChecklists(state);
      const checklistIndex = checklists.findIndex((c) => c.uid === checklistUid);

      dispatch(
        markItemAsNotCompleted({
          checklistIndex,
          firstLevelIndex,
          secondLevelIndex,
          thirdLevelIndex,
          level,
          item,
        })
      );

      if (level === 1) {
        const flattedNestedSubItems = (arr) =>
          arr.flatMap(({ subitems, ...rest }) => [].concat(rest, flattedNestedSubItems(subitems)));

        Promise.all(
          flattedNestedSubItems(item.subitems)
            .filter((i) => i.completedAt === null)
            .map((i) => api.postChecklistItemNotComplete(i.uid)())
        );
      } else if (level === 2) {
        // Mark parent item (2nd level) as not completed
        api.postChecklistItemNotComplete(item.parentUid)();

        Promise.all(
          item.subitems
            .filter((i) => i.completedAt !== null)
            .map((i) => api.postChecklistItemNotComplete(i.uid)())
        );
      } else if (level === 3) {
        // Mark parent item (1st level) as not completed
        api.postChecklistItemNotComplete(checklists[checklistIndex].items[firstLevelIndex].uid)();

        // Mark parent item (2nd level) as not completed
        api.postChecklistItemNotComplete(item.parentUid)();
      }

      api.postChecklistItemNotComplete(checklistItemUid)();
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const removeChecklistItem =
  ({
    firstLevelIndex,
    secondLevelIndex,
    thirdLevelIndex,
    checklistUid,
    checklistItemUid,
    item,
    level,
  }) =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const currentUser = userSelector(state);
      const checklists = selectors.getChecklists(state);
      const checklistIndex = checklists.findIndex((c) => c.uid === checklistUid);

      dispatch(
        removeSubItem({
          checklistIndex,
          firstLevelIndex,
          secondLevelIndex,
          thirdLevelIndex,
          item,
          level,
          currentUser,
        })
      );

      if (level === 1) {
        // if the current item has other items at the same level re-order the index via api
        const siblingItems = checklists[checklistIndex].items.filter(
          (_, index) => index > firstLevelIndex
        );

        // Update the parent of the sub items of the current item
        // This will conver the sub item of the current item to a 2nd level item
        const combinedItems = [...item.subitems, ...siblingItems];

        await Promise.all(
          combinedItems.map((i, index) =>
            api.patchChecklistItem(i.uid, {
              parent: null,
              index: item.index + index,
              text: i.text,
            })()
          )
        );

        // if (siblingItems.length > 0) {
        //   Promise.all(
        //     siblingItems.map((i) =>
        //       api.patchChecklistItem(i.uid, {
        //         index: i.index - 1,
        //         text: i.text,
        //       })()
        //     )
        //   );
        // }
      } else if (level === 2) {
        const siblingItems = checklists[checklistIndex].items[firstLevelIndex].subitems;

        // Update the parent of the sub items of the current item
        // This will convert the sub item of the current item to a 2nd level item
        const combinedItems = [
          ...item.subitems,

          // if the current item has other items at the same level re-order the index via api
          ...siblingItems.filter((_, index) => index > secondLevelIndex),
        ];

        await Promise.all(
          combinedItems.map((i, index) =>
            api.patchChecklistItem(i.uid, {
              parent: item.parentUid,
              index: item.index + index,
              text: i.text,
            })()
          )
        );

        // if there are not completed item, mark the parent item as not completed
        if (
          [...item.subitems, ...siblingItems]
            .filter((i) => i.uid !== item.uid)
            .filter((i) => i.completedAt === null).length > 0
        ) {
          api.postChecklistItemNotComplete(item.parentUid)();
        }

        // if all items are completed, mark the parent item as completed
        if (
          [...item.subitems, ...siblingItems]
            .filter((i) => i.uid !== item.uid)
            .filter((i) => i.completedAt === null).length === 0
        ) {
          api.postChecklistItemComplete(item.parentUid)();
        }
      } else if (level === 3) {
        // if the current item has other items at the same level re-order the index via api
        const siblingItems =
          checklists[checklistIndex].items[firstLevelIndex].subitems[secondLevelIndex].subitems;

        if (siblingItems.filter((_, index) => index > thirdLevelIndex).length > 0) {
          Promise.all(
            siblingItems.map((i) =>
              api.patchChecklistItem(i.uid, {
                index: i.index - 1,
                text: i.text,
              })()
            )
          );
        }

        // if there are not completed item, mark the parent item as not completed
        if (
          siblingItems.filter((i) => i.uid !== item.uid).filter((i) => i.completedAt === null)
            .length > 0
        ) {
          // Mark parent item (2nd level) as not completed
          api.postChecklistItemNotComplete(item.parentUid)();

          // Mark parent item (1st level) as not completed if new sub item is deleted
          api.postChecklistItemNotComplete(checklists[checklistIndex].items[firstLevelIndex].uid)();
        }

        // if all items are completed, mark the parent item as completed
        if (
          siblingItems.filter((i) => i.uid !== item.uid).filter((i) => i.completedAt === null)
            .length === 0
        ) {
          api.postChecklistItemComplete(item.parentUid)();

          // get second level sub items
          const secondLevelSubItems = checklists[checklistIndex].items[
            firstLevelIndex
          ].subitems.filter((i) => i.uid !== item.parentUid);
          const completedSecondLevelItems = secondLevelSubItems.filter(
            (i) => i.completedAt !== null
          );

          if (secondLevelSubItems.length === completedSecondLevelItems.length) {
            // Mark parent item (1st level) as not completed if new sub item is deleted
            api.postChecklistItemComplete(checklists[checklistIndex].items[firstLevelIndex].uid)();
          }
        }

        // Mark parent item (2nd level) as not completed if new sub item is deleted
        // api.postChecklistItemNotComplete(item.parentUid)();
      }

      api.deleteChecklistItem(checklistItemUid)();
    } catch (error) {
      handleError(error, dispatch);
    }
  };

export const indentChecklistItem =
  ({ firstLevelIndex, secondLevelIndex, thirdLevelIndex, item, level }) =>
  async (dispatch, getState) => {
    const state = getState();
    const currentUser = userSelector(state);
    const checklists = selectors.getChecklists(state);
    const checklistIndex = checklists.findIndex((c) => c.uid === item.checklistUid);

    await dispatch(
      indentSubItem({
        checklistIndex,
        firstLevelIndex,
        secondLevelIndex,
        thirdLevelIndex,
        data: item,
        level,
        currentUser,
      })
    );

    // Item can only be appended in level 1 and level 2 since level 3 is the maximum level
    if (level === 3) return;

    if (level === 1) {
      // get the previous item to make it as the parent
      const parentItem = checklists[checklistIndex].items[firstLevelIndex - 1];

      if (parentItem) {
        const newIndex =
          parentItem.subitems.length > 0
            ? parentItem.subitems[parentItem.subitems.length - 1].index + 1
            : 1;

        api.patchChecklistItem(item.uid, {
          parent: parentItem.uid,
          index: newIndex,
          text: item.text,
        })();

        // // API CALL to update the parent of the first level item if it has a subitem(s)
        if (item.subitems.length > 0 || parentItem.subitems.length > 0) {
          Promise.all(
            reject([...parentItem.subitems, item, ...item.subitems], isEmpty).map((i, index) =>
              api.patchChecklistItem(i.uid, {
                parent: parentItem.uid,
                index: index + 1,
                text: i.text,
              })()
            )
          );
        }

        // // API CALL to Update the index order of first level after indenting.
        Promise.all(
          checklists[checklistIndex].items
            .filter((_, index) => index > firstLevelIndex)
            .map((i) =>
              api.patchChecklistItem(i.uid, {
                index: i.index - 1,
                text: i.text,
              })()
            )
        );

        // previous item
        const previousItem = checklists[checklistIndex].items[firstLevelIndex - 1];
        const notCompletedItems = previousItem.subitems.filter((i) => i.completedAt === null);

        if (previousItem.subitems.length === 0) {
          if (item.completedAt !== null) {
            api.postChecklistItemComplete(previousItem.uid)();
          }
        } else if (previousItem.subitems.length > 0) {
          if (notCompletedItems.length > 0) {
            api.postChecklistItemNotComplete(previousItem.uid)();
          } else if (notCompletedItems.length === 0 && item.completedAt === null) {
            api.postChecklistItemNotComplete(previousItem.uid)();
          } else if (notCompletedItems.length === 0 && item.completedAt !== null) {
            // check if this can be removed
            api.postChecklistItemComplete(previousItem.uid)();
          }
        }
      }
    } else if (level === 2) {
      const parentItem =
        checklists[checklistIndex].items[firstLevelIndex].subitems[secondLevelIndex - 1];

      if (parentItem) {
        const newIndex =
          parentItem.subitems.length > 0
            ? parentItem.subitems[parentItem.subitems.length - 1].index + 1
            : 1;

        api.patchChecklistItem(item.uid, {
          parent: parentItem.uid,
          index: newIndex,
          text: item.text,
        })();

        // Change the parent uid of the sub items of the current item
        if (item.subitems.length > 0 || parentItem.subitems.length > 0) {
          Promise.all(
            reject([...parentItem.subitems, item, ...item.subitems], isEmpty).map((i, index) =>
              api.patchChecklistItem(i.uid, {
                parent: parentItem.uid,
                index: index + 1,
                text: i.text,
              })()
            )
          );
        }

        // API CALL to Update the index order of second level after indenting.
        Promise.all(
          checklists[checklistIndex].items[firstLevelIndex].subitems
            .filter((_, index) => index > secondLevelIndex)
            .map((i) =>
              api.patchChecklistItem(i.uid, {
                index: i.index - 1,
                text: i.text,
              })()
            )
        );

        // previous item
        const previousItem =
          checklists[checklistIndex].items[firstLevelIndex].subitems[secondLevelIndex - 1];
        const notCompletedItems = previousItem.subitems.filter((i) => i.completedAt === null);

        if (previousItem.subitems.length === 0) {
          if (item.completedAt !== null) {
            api.postChecklistItemComplete(previousItem.uid)();
          }
        } else if (previousItem.subitems.length > 0) {
          if (notCompletedItems.length > 0) {
            api.postChecklistItemNotComplete(previousItem.uid)();
          } else if (notCompletedItems.length === 0 && item.completedAt === null) {
            api.postChecklistItemNotComplete(previousItem.uid)();
          } else if (notCompletedItems.length === 0 && item.completedAt !== null) {
            api.postChecklistItemComplete(previousItem.uid)();
          }
        }
      }
    }
  };

export const indentBackwardsChecklistItem =
  ({ firstLevelIndex, secondLevelIndex, thirdLevelIndex, item, level }) =>
  async (dispatch, getState) => {
    const state = getState();
    const currentUser = userSelector(state);
    const checklists = selectors.getChecklists(state);
    const checklistIndex = checklists.findIndex((c) => c.uid === item.checklistUid);

    if (level === 1) return;

    await dispatch(
      indentBackwardSubItem({
        checklistIndex,
        firstLevelIndex,
        secondLevelIndex,
        thirdLevelIndex,
        data: item,
        level,
        currentUser,
      })
    );

    if (level === 2) {
      const parentItem = checklists[checklistIndex].items[firstLevelIndex];

      api.patchChecklistItem(item.uid, {
        parent: null,
        index: parseInt(parentItem.index) + 1,
        text: item.text,
        checklist: item.checklistUid,
      })();

      // API CALL to Update the index order of first level after indenting backwards.
      Promise.all(
        checklists[checklistIndex].items
          .filter((_, index) => index > firstLevelIndex)
          .map((i) =>
            api.patchChecklistItem(i.uid, {
              index: i.index + 1,
              text: i.text,
            })()
          )
      );

      // API CALL to update the index of the second level of the parent item (2nd level)
      Promise.all(
        checklists[checklistIndex].items[firstLevelIndex].subitems
          .filter((_, index) => index !== secondLevelIndex)
          .map((i) =>
            api.patchChecklistItem(i.uid, {
              index: i.index + 1,
              text: i.text,
            })()
          )
      );
    } else if (level === 3) {
      const parentItem =
        checklists[checklistIndex].items[firstLevelIndex].subitems[secondLevelIndex];

      if (parentItem) {
        const newIndex =
          parentItem.subitems.length > 0
            ? parentItem.subitems[parentItem.subitems.length - 1].index + 1
            : 1;

        api.patchChecklistItem(item.uid, {
          parent: parentItem.parentUid,
          index: newIndex,
          text: item.text,
        })();

        // API CALL to update the index of the second level items
        Promise.all(
          checklists[checklistIndex].items[firstLevelIndex].subitems
            .filter((_, index) => index > secondLevelIndex)
            .map((i) =>
              api.patchChecklistItem(i.uid, {
                index: i.index + 1,
                text: i.text,
              })()
            )
        );

        // API CALL to update the index of the 3rd level items
        Promise.all(
          checklists[checklistIndex].items[firstLevelIndex].subitems[secondLevelIndex].subitems
            .filter((_, index) => index > thirdLevelIndex)
            .map((i) =>
              api.patchChecklistItem(i.uid, {
                index: i.index - 1,
                text: i.text,
              })()
            )
        );

        // subitems of the parent item
        const subItemsOfParentItem = parentItem.subitems.filter((i) => i.uid !== item.uid);

        if (subItemsOfParentItem.length > 0) {
          const notCompletedItems = subItemsOfParentItem.filter((i) => i.completedAt === null);

          if (notCompletedItems.length === 0) {
            api.postChecklistItemComplete(parentItem.uid)();
          }
        }
      }
    }
  };

export const uploadAttachments =
  ({ uid, files }) =>
  async (dispatch, getState) => {
    if (!files) return;

    const state = getState();
    const formData = new FormData();

    [...files].forEach((f) => {
      formData.append(`files`, f);
    });

    try {
      dispatch(setChecklistItemUploading(uid));
      const { data } = await api.postAttachments(uid, formData)();
      const attachments = selectors.getAttachments(state);

      dispatch(setAllChecklistsAttachments([...attachments, ...data]));
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(setChecklistItemUploading(null));
    }
  };

export const removeAttachment =
  ({ itemUid, attachmentUid }) =>
  (dispatch, getState) => {
    const state = getState();
    const attachments = selectors.getAttachments(state);

    dispatch(setAllChecklistsAttachments(attachments.filter((a) => a.uid !== attachmentUid)));

    try {
      api.deleteAttachments(itemUid, { attachments: [attachmentUid] })();
    } catch (error) {
      handleError(error, dispatch);
    }
  };
