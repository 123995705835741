/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useOnClickOutside from "@/hooks/useOnClickOutside";
import { Button } from "@/ui-lib/FormFields";
import { Loading } from "@/ui-lib";

import styles from "./UncontrolledDropdownMenu.module.scss";

const UncontrolledDropdownMenu = ({
  menuText,
  icon,
  disabled,
  options,
  outline,
  color,
  onBlur,
  wrapperClassName,
  menuClassName,
  iconClassName,
  buttonClassName,
  isTogglePrevented,
  node,
}) => {
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    if (isTogglePrevented) return;

    setIsOpen(!isOpen);

    if (!isOpen) {
      onBlur();
    }
  };

  const onClickItem = async (o) => {
    if (o.onClick) {
      await o.onClick();
      if (o?.hideMenuOnClick) {
        toggle();
      }
    }
    if (o.hideMenuOnClick && !("isLoading" in o)) toggle();
  };

  useOnClickOutside(ref, () => {
    if (isOpen) toggle();
    onBlur();
  });

  return (
    <div
      ref={ref}
      className={cx(styles.dropdown, wrapperClassName)}
      onClick={(e) => e.stopPropagation()}
    >
      <Button
        disabled={disabled}
        outline={outline}
        color={color}
        className={cx(styles.button, buttonClassName, {
          [styles.buttonWithIcon]: !!icon && menuText === "",
          [styles.buttonWithIconAndText]: !!icon && menuText !== "",
        })}
        onClick={() => toggle()}
      >
        {icon && !menuText ? (
          <FontAwesomeIcon className={cx(styles.icon, iconClassName)} icon={icon} />
        ) : null}
        {menuText ? (
          <>
            {menuText}{" "}
            {icon ? (
              <FontAwesomeIcon className={cx(styles.icon, iconClassName)} icon={icon} />
            ) : null}
          </>
        ) : null}
      </Button>
      {node ? (
        <div className={cx(styles.menu, menuClassName, { [styles.hide]: !isOpen })}>
          {node({ isOpen })}
        </div>
      ) : (
        <>
          {isOpen && (
            <div className={cx(styles.menu, menuClassName)}>
              {options.map((o) => (
                <button
                  key={o.label}
                  onClick={() => onClickItem(o)}
                  className={cx(styles.menuItem, o?.menuItemClassName ?? "", {
                    [styles.disabled]: o.disabled,
                    [styles.header]: o.header,
                  })}
                  disabled={!!o?.disabled || !!o?.isLoading}
                >
                  <div className={styles.menuItemTextWrapper}>
                    {o.label}{" "}
                    {o.description && <span className={styles.description}>{o.description}</span>}
                  </div>
                  {!!o?.isLoading && (
                    <Loading spinnerClassName={styles.spinner} size={1} className={styles.loader} />
                  )}
                </button>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

UncontrolledDropdownMenu.defaultProps = {
  menuText: "",
  icon: null,
  color: "",
  outline: false,
  onBlur: () => null,
  options: [],
  wrapperClassName: "",
  menuClassName: "",
  iconClassName: "",
  buttonClassName: "",
  disabled: false,
  node: null,
  isTogglePrevented: false,
};

UncontrolledDropdownMenu.propTypes = {
  disabled: PropTypes.bool,
  menuText: PropTypes.oneOfType(PropTypes.string, PropTypes.element),
  // eslint-disable-next-line react/forbid-prop-types
  icon: PropTypes.shape(),
  color: PropTypes.string,
  outline: PropTypes.bool,
  onBlur: PropTypes.func,
  wrapperClassName: PropTypes.string,
  menuClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.bool,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
      description: PropTypes.string,
      onClick: PropTypes.func.isRequired,
      disabled: PropTypes.bool,
    })
  ),
  node: PropTypes.node,
  isTogglePrevented: PropTypes.bool,
};

export default UncontrolledDropdownMenu;
