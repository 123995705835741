import createActionType from "../../utils/createActionType";

export const MODULE = "settings/teams";

export const SET_IS_SAVING = createActionType(MODULE, "setIsSaving");

export const SET_IS_FETCHING = createActionType(MODULE, "setIsFetching");

export const SET_LISTS = createActionType(MODULE, "lists");

export const SET_ACTIVE_TEAM_MEMBER = createActionType(MODULE, "setActiveTeamMember");

export const SET_IS_FETCHING_INVITE_LINK = createActionType(MODULE, "setIsFetchingInviteLink");

export const SET_INVITE_LINK = createActionType(MODULE, "setInviteLink");

export const SET_IS_SENDING_INVITE = createActionType(MODULE, "setIsSendingInvite");

export const RESET = createActionType(MODULE, "reset");
