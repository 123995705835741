import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";

const Image = ({ src, imageWrapperClassName, fallbackIcon, iconWrapperClassName }) => {
  const [hasError, setHasError] = useState(false);
  return (
    <>
      {(!src || hasError) && fallbackIcon && (
        <div className={cx(iconWrapperClassName)}>
          <FontAwesomeIcon icon={fallbackIcon} />
        </div>
      )}

      {!hasError && src && (
        <div className={cx(imageWrapperClassName)}>
          <img
            src={src}
            alt="Factory"
            onError={(event) => {
              event.target.src = "";
              setHasError(true);
            }}
          />
        </div>
      )}
    </>
  );
};

export default Image;
