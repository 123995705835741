import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";

import NonAuthLayout from "@/pages/NonAuthLayout";
import { Input } from "@/ui-lib/FormControl/Formik";
import { FieldGroup } from "@/ui-lib";
import { Button } from "@/ui-lib/FormFields";
import Logo from "@/images/new-factory-logo-black-with-text.png";
import * as actions from "@/redux/forgotPassword/forgotPassword.actions";
import * as selectors from "@/redux/forgotPassword/forgotPassword.selectors";

import styles from "./ForgotPasswordPage.module.scss";

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required("Required"),
});

const ForgotPasswordPage = () => {
  const dispatch = useDispatch();
  const isSaving = useSelector(selectors.isSaving);
  const isSuccess = useSelector(selectors.isSuccess);

  useEffect(
    () => () => {
      dispatch(actions.reset());
    },
    []
  );

  const onSubmit = (data) => {
    dispatch(actions.resetPassword(data));
  };

  return (
    <NonAuthLayout>
      <div className={styles.wrapper}>
        <img src={Logo} alt="Factory" title="Factory" className={styles.logo} />
        <div className={styles.content}>
          {!isSuccess ? (
            <>
              <h1 className={styles.title}>Enter your email address</h1>
              <span className={styles.subTitle}>
                Please enter the email / username that you use to login to Factory.
              </span>

              <div className={styles.form}>
                <Formik
                  initialValues={{
                    email: "",
                  }}
                  validationSchema={validationSchema}
                  validateOnChange
                  validateOnBlur
                  onSubmit={onSubmit}
                  render={(props) => {
                    const { handleSubmit, ..._props } = props;
                    return (
                      <form className="w-100" onSubmit={handleSubmit}>
                        <FieldGroup label="Email address" contentClassName="w-100">
                          <Input
                            id="email"
                            name="email"
                            containerClassName={styles.formItem}
                            value={props.values.email}
                            {..._props}
                          />
                        </FieldGroup>
                        <div className={styles.action}>
                          <Button
                            disabled={isSaving}
                            loading={isSaving}
                            color="primary"
                            type="submit"
                          >
                            Reset password
                          </Button>
                        </div>
                      </form>
                    );
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <h1 className={styles.title}>Password reset sent</h1>
              <span className={styles.subTitle}>
                If an account exists for this email address, we&apos;ll send you an email so that
                you can reset your password.
              </span>
              <span className={styles.subTitle}>You may now close this page.</span>
            </>
          )}
        </div>
      </div>
    </NonAuthLayout>
  );
};

export default ForgotPasswordPage;
