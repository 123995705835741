import {
  IS_FETCHING_SUPPLIERS,
  IS_UPDATING_SUPPLIER,
  SET_ACCOUNTING_SUPPLIERS,
  SET_ACCOUNTING_ACTIVE_SUPPLIER,
  SET_ACCOUNTING_ACTIVE_SUPPLIER_NAME,
} from "./suppliers-settings.actionTypes";

const initialState = {
  suppliers: [],
  accountingActiveSupplier: null,
  accountingActiveSupplierName: null,
  isFetchingSuppliers: false,
  isUpdatingSupplier: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case IS_FETCHING_SUPPLIERS: {
      return { ...state, isFetchingSuppliers: action.payload };
    }

    case IS_UPDATING_SUPPLIER: {
      return { ...state, isUpdatingSupplier: action.payload };
    }

    case `${SET_ACCOUNTING_SUPPLIERS}/fulfilled`: {
      const { data } = action.payload;

      return { ...state, suppliers: data };
    }

    case SET_ACCOUNTING_ACTIVE_SUPPLIER: {
      return { ...state, accountingActiveSupplier: action.payload };
    }

    case SET_ACCOUNTING_ACTIVE_SUPPLIER_NAME: {
      return { ...state, accountingActiveSupplierName: action.payload };
    }

    default:
      return state;
  }
};
