import moment from "moment-timezone";

import * as authApi from "../../api/auth";
import { oauth2 } from "../../env";
import { handleError } from "../utils/error";

import {
  RESET_APP,
  IS_LOGGING_IN,
  IS_AUTHENTICATED,
  IS_CHECKING_AUTH,
  SET_ERROR_MESSAGE,
} from "./authentication.actionTypes";

export const setIsLoggingIn = (status) => (dispatch) => {
  dispatch({
    type: IS_LOGGING_IN,
    payload: status,
  });
};

export const setIsAuthenticated = (status) => (dispatch) => {
  dispatch({
    type: IS_AUTHENTICATED,
    payload: status,
  });
};

export const setIsCheckingAuth = (status) => (dispatch) => {
  dispatch({
    type: IS_CHECKING_AUTH,
    payload: status,
  });
};

export const setErrorMessage = (payload) => (dispatch) => {
  dispatch({
    type: SET_ERROR_MESSAGE,
    payload,
  });
};

export const resetApp = () => (dispatch) => {
  dispatch({
    type: RESET_APP,
  });
};

export const reset = () => (dispatch) => {
  window.localStorage.removeItem("auth");
  window.localStorage.removeItem("user");
  dispatch(setIsAuthenticated(false));
  dispatch(setIsCheckingAuth(false));
  dispatch(setIsLoggingIn(false));
  dispatch(resetApp());
};

export const login =
  ({ email, password }) =>
  async (dispatch) => {
    const data = {
      grant_type: "password",
      username: email,
      password,
      ...oauth2,
    };
    const formData = new FormData();

    Object.keys(data).forEach((item) => {
      formData.append(item, data[item]);
    });

    dispatch(setIsLoggingIn(true));

    try {
      const { data } = await authApi.tokenAuth(null, formData);
      const authData = { ...data, time_sign_up: moment.tz(moment.tz.guess()).unix() };

      window.localStorage.setItem("auth", JSON.stringify(authData));
      dispatch(setIsAuthenticated(true));
    } catch (errors) {
      const { response } = errors;
      if (response) {
        dispatch(setErrorMessage(response?.data?.error_description ?? "Something went wrong"));
        throw response.data;
      }

      dispatch(setErrorMessage("Something went wrong"));
      throw errors;
    } finally {
      dispatch(setIsLoggingIn(false));
    }
  };

export const checkAuth = () => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("auth"));

  if (token) {
    const today = moment.tz(moment.tz.guess()).unix();

    if (token.time_sign_up && token.expires_in && today - token.time_sign_up > token.expires_in) {
      try {
        await dispatch(refreshToken());
      } catch (_) {
        dispatch(reset());
      }
    }

    dispatch(setIsAuthenticated(true));
  } else {
    // Logout
    dispatch(logout());
    dispatch(reset());
  }
};

export const refreshToken = () => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("auth"));
  const data = {
    grant_type: "refresh_token",
    refresh_token: token.refresh_token,
    ...oauth2,
  };
  const formData = new FormData();

  Object.keys(data).forEach((item) => {
    formData.append(item, data[item]);
  });

  try {
    const { data } = await authApi.tokenVerify(null, formData);
    const authData = { ...data, time_sign_up: moment.tz(moment.tz.guess()).unix() };
    window.localStorage.setItem("auth", JSON.stringify(authData));
  } catch (_) {
    dispatch(logout());
    dispatch(reset());
  }
};

export const logout = () => async (dispatch) => {
  const token = JSON.parse(localStorage.getItem("auth"));
  const data = {
    token: JSON.stringify(token),
    ...oauth2,
  };
  const formData = new FormData();

  Object.keys(data).forEach((item) => {
    formData.append(item, data[item]);
  });

  if (token) {
    try {
      await authApi.logout(formData);
    } catch (error) {
      throw error;
    } finally {
      dispatch(reset());
    }
  } else {
    dispatch(reset());
  }
};

export const generateGoogleToken = (payload) => async (dispatch) => {
  try {
    const { data } = await authApi.postGoogleToken(null, payload);

    dispatch(
      googleLogin({
        authToken: data.id_token,
      })
    );
  } catch (error) {
    dispatch(setErrorMessage("Something went wrong"));
  }
};

export const googleLogin =
  ({ authToken }) =>
  async (dispatch) => {
    const data = {
      token: authToken,
    };
    const formData = new FormData();

    Object.keys(data).forEach((item) => {
      formData.append(item, data[item]);
    });

    dispatch(setIsLoggingIn(true));

    try {
      const { data } = await authApi.googleLogin(null, formData);
      const authData = { ...data, time_sign_up: moment.tz(moment.tz.guess()).unix() };
      window.localStorage.setItem("auth", JSON.stringify(authData));
      dispatch(setIsAuthenticated(true));
    } catch (errors) {
      const { response } = errors;
      if (response) {
        dispatch(setErrorMessage(response?.data?.error[0] ?? "Something went wrong"));
        throw response.data;
      }

      dispatch(setErrorMessage("Something went wrong"));
      throw errors;
    } finally {
      dispatch(setIsLoggingIn(false));
    }
  };

export const microsoftLogin = (payload) => async (dispatch) => {
  const formData = new FormData();

  Object.keys(payload).forEach((item) => {
    formData.append(item, payload[item]);
  });

  dispatch(setIsLoggingIn(true));

  try {
    const { data } = await authApi.microsoftLogin(null, formData);
    const authData = { ...data, time_sign_up: moment.tz(moment.tz.guess()).unix() };
    window.localStorage.setItem("auth", JSON.stringify(authData));
    dispatch(setIsAuthenticated(true));
  } catch (errors) {
    const { response } = errors;
    if (response) {
      dispatch(setErrorMessage(response?.data?.error[0] ?? "Something went wrong"));
      throw response.data;
    }

    dispatch(setErrorMessage("Something went wrong"));
  } finally {
    dispatch(setIsLoggingIn(false));
  }
};

export const fetchXeroAuthorizationUrl = () => async (dispatch) => {
  try {
    dispatch(setIsLoggingIn(true));
    const { data } = await authApi.getXeroAuthorizationUrl({ is_login: true })();
    return data;
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsLoggingIn(false));
  }
};

export const fetchXeroToken = () => async (dispatch) => {
  try {
    dispatch(setIsLoggingIn(true));
    const { data } = await authApi.getXeroAuthorizationUrl({ is_login: true })();
    return data;
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsLoggingIn(false));
  }
};

export const xeroLogin = (payload) => async (dispatch) => {
  const formData = new FormData();

  Object.keys(payload).forEach((item) => {
    formData.append(item, payload[item]);
  });

  dispatch(setIsLoggingIn(true));

  try {
    const {
      data: { token },
    } = payload.isLogin ? await authApi.getXeroToken(payload) : { data: { token: payload.token } };
    const { data } = await authApi.xeroLogin(null, { token });
    const authData = { ...data, time_sign_up: moment.tz(moment.tz.guess()).unix() };
    window.localStorage.setItem("auth", JSON.stringify(authData));
    dispatch(setIsAuthenticated(true));
  } catch (errors) {
    const { response } = errors;
    if (response) {
      dispatch(setErrorMessage(response?.data?.error[0] ?? "Something went wrong"));
      throw response.data;
    }

    dispatch(setErrorMessage("Something went wrong"));
  } finally {
    dispatch(setIsLoggingIn(false));
  }
};
