import React, { useState, forwardRef } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";

import { Datepicker as BaseDatepicker } from "@/ui-lib/FormFields/Datepicker";

const RangeDatepicker = forwardRef(
  (
    {
      className,
      onChange,
      placeholder,
      startDate,
      endDate,
      select,
      rangeStartLabel,
      rangeEndLabel,
      ...rest
    },
    ref
  ) => {
    const [start, setStart] = useState(startDate ? moment(startDate).format("YYYY-MM-DD") : null);
    const [end, setEnd] = useState(endDate ? moment(endDate).format("YYYY-MM-DD") : null);

    return (
      <BaseDatepicker
        className={className}
        value={[start, end]}
        dateFormat="DD/MM/YY"
        themeVariant="light"
        controls={["calendar"]}
        theme="ios"
        select={select}
        rangeStartLabel={rangeStartLabel}
        rangeEndLabel={rangeEndLabel}
        onChange={(data) => {
          const { value } = data;

          if (select === "range") {
            const startDateValue = value && value[0] ? moment(value[0]).format("YYYY-MM-DD") : null;
            const endDatevalue = value && value[1] ? moment(value[1]).format("YYYY-MM-DD") : null;
            setStart(startDateValue);
            setEnd(endDatevalue);

            if (startDateValue && endDatevalue) {
              onChange({
                startDate: startDateValue,
                endDate: endDatevalue,
              });
            }
          }
        }}
        placeholder={placeholder}
        ref={ref}
        {...rest}
      />
    );
  }
);

RangeDatepicker.defaultProps = {
  className: "",
  placeholder: "",
  select: "date",
  rangeStartLabel: "From",
  rangeEndLabel: "To",
  startDate: "",
  endDate: "",
};

RangeDatepicker.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  select: PropTypes.oneOf(["date", "range"]),
  rangeStartLabel: PropTypes.string,
  rangeEndLabel: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};

export default RangeDatepicker;
