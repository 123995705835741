import { handleError } from "@/redux/utils/error";
import { STATUS } from "@/utils/constants";
import * as teamsApi from "@/apiv2/teams";
import * as timesheetsApi from "@/apiv2/timesheets";

import {
  SET_IS_SAVING_STATUS,
  SET_IS_FETCHING_TEAM_MEMBERS_STATUS,
  SET_TEAM_MEMBERS,
  RESET,
} from "./pinConfirmation.actionTypes";

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setIsSavingStatus = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_SAVING_STATUS,
    payload: data,
  });
};

export const setIsFetchingTeamMembersStatus = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_TEAM_MEMBERS_STATUS,
    payload: data,
  });
};

export const setTeamMembers = (data) => (dispatch) => {
  dispatch({
    type: SET_TEAM_MEMBERS,
    payload: data,
  });
};

export const fetchTeamMembers = () => async (dispatch) => {
  try {
    dispatch(setIsFetchingTeamMembersStatus(STATUS.PENDING));

    const { data } = await teamsApi.getTeams({
      with_inactive: false,
      include_invites: false,
    })();
    dispatch(setTeamMembers(data));
    dispatch(setIsFetchingTeamMembersStatus(STATUS.FULFILLED));
  } catch (error) {
    dispatch(setIsFetchingTeamMembersStatus(STATUS.REJECTED));
    handleError(error, dispatch);
  }
};

export const validateUserPin =
  ({ user, pin }) =>
  async (dispatch) => {
    try {
      dispatch(setIsSavingStatus(STATUS.PENDING));
      const { data } = await timesheetsApi.postValidateUserPin({ user, pin })();
      return data;
    } catch (error) {
      return error.response.data;
    } finally {
      dispatch(setIsSavingStatus(STATUS.FULFILLED));
    }
  };
