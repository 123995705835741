/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useOnClickOutside from "@/hooks/useOnClickOutside";
import { Button } from "@/ui-lib/FormFields";

import styles from "./DropdownMenu.module.scss";

const DropdownMenu = ({
  menuText,
  icon,
  disabled,
  options,
  outline,
  color,
  isOpen,
  onToggle,
  menuClassName,
  buttonClassName,
}) => {
  const ref = useRef();

  const toggle = () => onToggle(!isOpen);

  const onClickItem = (o) => {
    if (o.onClick) o.onClick();
    if (o.hideMenuOnClick) toggle();
  };

  useOnClickOutside(ref, () => {
    if (isOpen) toggle();
  });

  return (
    <div ref={ref} className={styles.dropdown} onClick={(e) => e.stopPropagation()}>
      <Button
        disabled={disabled}
        outline={outline}
        color={color}
        className={cx(styles.button, buttonClassName, {
          [styles.buttonWithIcon]: !!icon,
        })}
        onClick={() => toggle()}
      >
        {icon ? <FontAwesomeIcon className={styles.icon} icon={icon} /> : menuText}
      </Button>
      {isOpen && (
        <div className={cx(styles.menu, menuClassName)}>
          {options.map((o) => (
            <button
              key={o.label}
              onClick={() => onClickItem(o)}
              className={cx(styles.menuItem, {
                [styles.disabled]: o.disabled,
                [styles.header]: o.header,
              })}
            >
              {o.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

DropdownMenu.defaultProps = {
  menuText: "Add",
  icon: null,
  color: "",
  outline: false,
  options: [],
  menuClassName: "",
  buttonClassName: "",
  disabled: false,
};

DropdownMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  menuText: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  icon: PropTypes.shape(),
  color: PropTypes.string,
  outline: PropTypes.bool,
  menuClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.bool,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
      onClick: PropTypes.func.isRequired,
      disabled: PropTypes.bool,
    })
  ),
};

export default DropdownMenu;
