import { STATUS } from "@/utils/constants";

import {
  SET_LATEST_SUPPLIERS,
  SET_SEARCH_LATEST_SUPPLIERS,
  SET_FETCHING_LATEST_SUPPLIERS_STATUS,
  SET_IS_LINKED_SUPPLIERS,
  SET_IS_PREVIOUSLY_IMPORTED_SUPPLIERS,
  SET_SUPPLIERS_TO_IMPORT,
  SET_IMPORT_SUPPLIERS_STATUS,
  SET_IMPORT_SUPPLIERS,
  SET_DUPLICATE_SUPPLIERS_TO_IMPORT,
  SET_DUPLICATE_SUPPLIERS_WARNING_MODAL,
  SET_IMPORT_SUPPLIERS_SORT,
  SET_IMPORT_SUPPLIERS_PAGE,
  SET_IMPORT_SUPPLIERS_META,
  SET_IS_LINKED_SUPPLIERS_FILTER,
  SET_IMPORT_ALL_SUPPLIERS_STATUS,
  SET_IMPORT_ALL_SUPPLIERS,
  RESET,
} from "./importSuppliers.actionTypes";

const initialState = {
  fetchingLatestSuppliersStatus: STATUS.IDLE,
  latestSuppliers: [],
  searchLatestSuppliers: "",
  isLinkedSuppliers: [true, false],
  isPreviouslyImportedSuppliers: [true, false],
  suppliersToImport: [],
  importSuppliersStatus: STATUS.IDLE,
  importSuppliers: null,
  duplicateSuppliersToImport: [],
  duplicateSuppliersWarningModal: false,
  sort: "name",
  page: 1,
  meta: { num_pages: 1, page: 1, count: 0 },
  isLinkedSuppliersFilter: "",
  importAllSuppliersStatus: STATUS.IDLE,
  importAllSuppliers: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LATEST_SUPPLIERS: {
      return {
        ...state,
        latestSuppliers: action.payload,
      };
    }

    case SET_SEARCH_LATEST_SUPPLIERS: {
      return { ...state, searchLatestSuppliers: action.payload };
    }

    case SET_FETCHING_LATEST_SUPPLIERS_STATUS: {
      return { ...state, fetchingLatestSuppliersStatus: action.payload };
    }

    case SET_IS_LINKED_SUPPLIERS: {
      return { ...state, isLinkedSuppliers: action.payload };
    }

    case SET_IS_PREVIOUSLY_IMPORTED_SUPPLIERS: {
      return { ...state, isPreviouslyImportedSuppliers: action.payload };
    }

    case SET_SUPPLIERS_TO_IMPORT: {
      return { ...state, suppliersToImport: action.payload };
    }

    case SET_IMPORT_SUPPLIERS_STATUS: {
      return { ...state, importSuppliersStatus: action.payload };
    }

    case SET_IMPORT_SUPPLIERS: {
      return { ...state, importSuppliers: action.payload };
    }

    case SET_DUPLICATE_SUPPLIERS_TO_IMPORT: {
      return { ...state, duplicateSuppliersToImport: action.payload };
    }

    case SET_DUPLICATE_SUPPLIERS_WARNING_MODAL: {
      return { ...state, duplicateSuppliersWarningModal: action.payload };
    }

    case SET_IMPORT_SUPPLIERS_SORT: {
      return { ...state, sort: action.payload };
    }

    case SET_IMPORT_SUPPLIERS_PAGE: {
      return { ...state, page: action.payload };
    }

    case SET_IMPORT_SUPPLIERS_META: {
      return { ...state, meta: action.payload };
    }

    case SET_IS_LINKED_SUPPLIERS_FILTER: {
      return { ...state, isLinkedSuppliersFilter: action.payload };
    }

    case SET_IMPORT_ALL_SUPPLIERS_STATUS: {
      return { ...state, importAllSuppliersStatus: action.payload };
    }

    case SET_IMPORT_ALL_SUPPLIERS: {
      return { ...state, importAllSuppliers: action.payload };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
