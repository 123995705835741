/* eslint-disable no-param-reassign */
import produce from "immer";

import {
  PRICING_STRATEGY_LINEAL_METRES_LABEL,
  PRICING_STRATEGY_CUSTOM_FORMULA_LABEL,
  PRODUCT_TYPE_ADDITIONAL_PRODUCT_LABEL,
  PRODUCT_TYPE_LABOUR_PRODUCT_LABEL,
} from "@/utils/constants";
import { calcItemLength, calculateTotalSquareMeters } from "@/redux/modules/helpers/item";

import setKeypathValue from "../utils/setKeypathValue";

import {
  SET_SEARCH,
  SET_IS_FETCHING_CATEGORIES,
  SET_IS_FETCHING_PRODUCT_KIT,
  SET_IS_FETCHING_PRODUCTS,
  SET_IS_SAVING,
  SET_CATEGORIES,
  SET_EXPANDED_CATEGORIES,
  SET_EXPANDED_PRODUCTS,
  SET_EXPANDED_SUB_KIT_PRODUCTS,
  SET_PRODUCTS,
  SET_EMPTY_PRODUCTS,
  SET_PRODUCT_KIT,
  SET_SELECTED_PRICE_LEVELS,
  SET_ERROR_IN_CUSTOM_FORMULA,
  SET_ERROR_IN_SUB_KIT_CUSTOM_FORMULA,
  ADD_PRODUCT_TO_CART,
  ADD_LABOUR_ITEMS,
  UPDATE_LABOUR_ITEM,
  UPDATE_LABOUR_ITEM_IN_SUBKIT,
  ADD_LABOUR_ITEM_TO_SUBKIT,
  ADD_PRODUCT_TO_SUB_KIT_CART,
  UPDATE_CART_QUANTITY,
  UPDATE_SUB_KIT_CART_QUANTITY,
  UPDATE_CART_COLOUR,
  UPDATE_SUB_KIT_CART_COLOUR,
  UPDATE_CART_PRICE,
  UPDATE_SUB_KIT_CART_PRICE,
  UPDATE_CART,
  UPDATE_SUB_KIT_CART,
  UPDATE_CART_ATTRIBUTES,
  UPDATE_SUB_KIT_CART_ATTRIBUTES,
  DELETE_CART_ATTRIBUTES,
  DELETE_SUB_KIT_CART_ATTRIBUTES,
  DELETE_CART_ITEM,
  DELETE_SUB_KIT_CART_ITEM,
  ADD_KIT_LENGTH,
  DELETE_KIT_LENGTH,
  ADD_KIT_DIMENSION,
  DELETE_KIT_DIMENSION,
  ADD_SUB_KIT_LENGTH,
  DELETE_SUB_KIT_LENGTH,
  ADD_SUB_KIT_DIMENSION,
  DELETE_SUB_KIT_DIMENSION,
  DELETE_SUB_KIT,
  CREATE_PRODUCT_KIT_FROM_CART,
  CREATE_PRODUCT_KIT_FROM_LABOUR_LINE_ITEMS_CART,
  CREATE_SUB_KIT_FROM_LABOUR_LINE_ITEMS_CART,
  UPDATE_CURRENT_PRODUCT_KIT,
  UPDATE_SUB_KIT_TITLE,
  UPDATE_PRODUCT_KIT_PRICING_TABLE,
  SET_IS_SUB_KIT_MODAL_OPEN,
  SET_TARGET_SUB_KIT_INDEX,
  TOGGLE_PRODUCT_KIT_MODAL,
  TOGGLE_CART,
  SET_KIT_LABOUR_LINE_ITEMS,
  RESET_PRODUCT_KIT,
  RESET_EXPANDED_PRODUCTS,
  RESET_EXPANDED_SUB_KIT_PRODUCTS,
  RESET,
  ADD_EMPTY_SUB_KIT,
  ADD_SUB_KIT_FROM_KIT,
} from "./productKits.actionTypes";

const initialState = {
  kitProducts: [],
  kitLabourLineItems: [],
  productKitProducts: {},
  currentProductKit: {
    image: "",
    title: "",
    description: "",
    customPricing: false,
    categoryUid: null,
    category: null,
    displayKitItems: [
      "displayOnAllViews",
      "displayOnWorkOrderPdf",
      "displayOnDeliveryDocketPdf",
      "displayOnInvoicePdf",
      "displayOnQuotePdf",
      "displayOnProformaInvoicePdf",
      "displayOnCustomerOnlineView",
      "displayOnAccountingPackage",
    ],
    displaySubKits: [
      "displayOnAllViews",
      "displayOnWorkOrderPdf",
      "displayOnDeliveryDocketPdf",
      "displayOnInvoicePdf",
      "displayOnQuotePdf",
      "displayOnProformaInvoicePdf",
      "displayOnCustomerOnlineView",
      "displayOnAccountingPackage",
    ],
    productKitRows: [
      {
        attributes: [
          {
            name: "Description",
            value: "",
            isHideOrderSummary: false,
            isHideInvoicePdf: false,
            isHideCustomerView: false,
            isHideWorkOrderPdf: false,
          },
        ],
        rowPriceLevels: [],
        accountingItem: null,
      },
    ],
    fields: [
      {
        name: "Description",
        isHideOrderSummary: false,
        isHideInvoicePdf: false,
        isHideCustomerView: false,
        isHideWorkOrderPdf: false,
      },
    ],
    priceLevels: [],
    hiddenPriceLevels: [],
  },
  isModalVisible: false,
  isCartVisible: false,
  isFetchingCategories: false,
  isFetchingProductKit: false,
  isSaving: false,
  categories: [],
  expandedCategories: [],
  expandedProducts: [],
  expandedSubKitProducts: {},
  products: {},
  search: "",
  selectedPriceLevels: [],
  subKits: [],
  isSubKitModalOpen: false,
  subKitIndex: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH: {
      return {
        ...state,
        search: action.payload,
      };
    }

    case SET_IS_FETCHING_CATEGORIES: {
      return {
        ...state,
        isFetchingCategories: action.payload,
      };
    }

    case SET_IS_FETCHING_PRODUCT_KIT: {
      return {
        ...state,
        isFetchingProductKit: action.payload,
      };
    }

    case SET_IS_SAVING: {
      return {
        ...state,
        isSaving: action.payload,
      };
    }

    case SET_CATEGORIES: {
      const { data } = action.payload;

      return {
        ...state,
        categories: data,
      };
    }

    case SET_EXPANDED_CATEGORIES: {
      const { data } = action.payload;

      return {
        ...state,
        expandedCategories: data,
      };
    }

    case SET_EXPANDED_PRODUCTS: {
      const { data } = action.payload;

      return {
        ...state,
        expandedProducts: data,
      };
    }

    case SET_EXPANDED_SUB_KIT_PRODUCTS: {
      const { subKitIndex, data } = action.payload;

      return {
        ...state,
        expandedSubKitProducts: {
          ...state.expandedSubKitProducts,
          [subKitIndex]: data,
        },
      };
    }

    case SET_IS_FETCHING_PRODUCTS: {
      const { data } = action.payload;
      return {
        ...state,
        categories: state.categories.map((c) =>
          c.uid === data.uid ? { ...c, isFetching: data.isFetching } : { ...c }
        ),
      };
    }

    case SET_PRODUCTS: {
      const { data, categoryUid } = action.payload;

      return {
        ...state,
        products: {
          ...state.products,
          [categoryUid]: data,
        },
      };
    }

    case SET_EMPTY_PRODUCTS: {
      return {
        ...state,
        products: {},
      };
    }

    case SET_ERROR_IN_CUSTOM_FORMULA: {
      const { cartIndex, errorInCustomFormula } = action.payload;
      const newCartItem = {
        ...state.kitProducts[cartIndex],
        errorInCustomFormula,
      };

      return produce(state, (draftState) => {
        draftState.kitProducts[cartIndex] = newCartItem;
      });
    }

    case SET_ERROR_IN_SUB_KIT_CUSTOM_FORMULA: {
      const { subKitIndex, cartIndex, errorInCustomFormula } = action.payload;
      const newCartItem = {
        ...state.subKits[subKitIndex].kitProducts[cartIndex],
        errorInCustomFormula,
      };

      return produce(state, (draftState) => {
        draftState.subKits[subKitIndex].kitProducts[cartIndex] = newCartItem;
      });
    }

    case ADD_PRODUCT_TO_CART: {
      const { product, productRow } = action.payload;
      const newProduct = {
        ...product,
        suppliers: [],
      };
      const newProductRow = {
        ...productRow.original,
      };
      const newCustomFormula = {
        ...product.customFormula,
        uid: null,
        kitProductUid: null,
        additionalProductUid: null,
      };
      const customRowPrices = newProductRow.rowPrices.map((rowPrice) => ({
        ...rowPrice,
        uid: null,
      }));
      const newCartItem = {
        product: newProduct,
        productRow: newProductRow,
        productType: PRODUCT_TYPE_ADDITIONAL_PRODUCT_LABEL,
        quantity: product.pricingStrategy === PRICING_STRATEGY_LINEAL_METRES_LABEL ? null : 1.0,
        actualQuantity:
          product.pricingStrategy === PRICING_STRATEGY_LINEAL_METRES_LABEL ? null : 1.0,
        isCompleted: false,
        lengthItems: [],
        actualLengthItems: [],
        sumLengths: [],
        actualSumLengths: [],
        discount: 0.0,
        customValue: null,
        totalValue: 0.0,
        types: newProductRow.types,
        productName: newProduct.name,
        customFormula:
          product.pricingStrategy === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL
            ? newCustomFormula
            : null,
        errorInCustomFormula: "",
        uid: null,
        customAttributeValues: [],
        customRowPrices,
        cartIndex: state.kitProducts.length,
      };

      return {
        ...state,
        kitProducts: [...state.kitProducts, newCartItem],
      };
    }

    case ADD_LABOUR_ITEMS: {
      const data = action.payload;
      return {
        ...state,
        kitProducts: data,
      };
    }

    case UPDATE_LABOUR_ITEM: {
      const { labourItemIndex, data } = action.payload;
      const newCartItem = {
        ...state.kitProducts[labourItemIndex],
        ...data,
      };

      return produce(state, (draftState) => {
        draftState.kitProducts[labourItemIndex] = newCartItem;
      });
    }

    case UPDATE_LABOUR_ITEM_IN_SUBKIT: {
      const { subKitIndex, labourItemIndex, data } = action.payload;

      const item = {
        ...state.subKits[subKitIndex].kitProducts[labourItemIndex],
        ...data,
      };

      return produce(state, (draftState) => {
        draftState.subKits[subKitIndex].kitProducts[labourItemIndex] = item;
      });
    }

    case ADD_LABOUR_ITEM_TO_SUBKIT: {
      const { subKitIndex, data } = action.payload;

      return produce(state, (draftState) => {
        draftState.subKits[subKitIndex].kitProducts = data.map((d) => ({
          ...d,
          cartIndex: state.subKits[subKitIndex].kitProducts.length,
        }));
      });
    }

    case ADD_PRODUCT_TO_SUB_KIT_CART: {
      const { subKitIndex, product, productRow } = action.payload;
      const newProduct = {
        ...product,
        suppliers: [],
      };
      const newProductRow = {
        ...productRow.original,
      };
      const newCustomFormula = {
        ...product.customFormula,
        uid: null,
        kitProductUid: null,
        additionalProductUid: null,
      };
      const customRowPrices = newProductRow.rowPrices.map((rowPrice) => ({
        ...rowPrice,
        uid: null,
      }));
      const newCartItem = {
        product: newProduct,
        productRow: newProductRow,
        productType: PRODUCT_TYPE_ADDITIONAL_PRODUCT_LABEL,
        quantity: product.pricingStrategy === PRICING_STRATEGY_LINEAL_METRES_LABEL ? null : 1.0,
        actualQuantity:
          product.pricingStrategy === PRICING_STRATEGY_LINEAL_METRES_LABEL ? null : 1.0,
        isCompleted: false,
        lengthItems: [],
        actualLengthItems: [],
        sumLengths: [],
        actualSumLengths: [],
        discount: 0.0,
        customValue: null,
        totalValue: 0.0,
        types: newProductRow.types,
        productName: newProduct.name,
        customFormula:
          product.pricingStrategy === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL
            ? newCustomFormula
            : null,
        errorInCustomFormula: "",
        uid: null,
        customAttributeValues: [],
        customRowPrices,
        cartIndex: state.subKits[subKitIndex].kitProducts.length,
      };

      return produce(state, (draftState) => {
        draftState.subKits[subKitIndex].kitProducts = [
          ...state.subKits[subKitIndex].kitProducts,
          newCartItem,
        ];
      });
    }

    case ADD_EMPTY_SUB_KIT: {
      const newSubKit = {
        title: "",
        kitProducts: [],
        productKitProducts: {},
      };
      const newSubKits = [...state.subKits, newSubKit];

      return produce(state, (draftState) => {
        draftState.subKits = newSubKits;
      });
    }

    case ADD_SUB_KIT_FROM_KIT: {
      const { productKit } = action.payload;
      const { subKits } = productKit;

      const newSubKitsFromKit = subKits.map((subKit) => {
        const kitProducts = subKit.kitProducts.map((cartItem, cartIndex) => {
          const { product, productRow } = cartItem;

          if (cartItem.productType === PRODUCT_TYPE_ADDITIONAL_PRODUCT_LABEL) {
            const newProduct = {
              ...product,
              suppliers: [],
            };
            const newCustomFormula = {
              ...newProduct.customFormula,
              uid: null,
              kitProductUid: null,
              additionalProductUid: null,
            };
            const customRowPrices = productRow.rowPrices.map((rowPrice) => ({
              ...rowPrice,
              uid: null,
            }));
            return {
              productType: PRODUCT_TYPE_ADDITIONAL_PRODUCT_LABEL,
              product: newProduct,
              productRow,
              quantity:
                newProduct.pricingStrategy === PRICING_STRATEGY_LINEAL_METRES_LABEL ? null : 1.0,
              actualQuantity:
                newProduct.pricingStrategy === PRICING_STRATEGY_LINEAL_METRES_LABEL ? null : 1.0,
              isCompleted: false,
              lengthItems: [],
              actualLengthItems: [],
              sumLengths: [],
              actualSumLengths: [],
              discount: 0.0,
              customValue: null,
              totalValue: 0.0,
              types: productRow.types,
              productName: newProduct.name,
              customFormula:
                newProduct.pricingStrategy === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL
                  ? newCustomFormula
                  : null,
              errorInCustomFormula: "",
              uid: null,
              customAttributeValues: [],
              customRowPrices,
              cartIndex,
            };
          } else if (cartItem.productType === PRODUCT_TYPE_LABOUR_PRODUCT_LABEL) {
            return {
              ...cartItem,
              ...cartItem.labour,
              uid: null,
              cartIndex,
            };
          }

          return [];
        }, []);

        return {
          ...subKit,
          uid: null,
          kitProducts,
        };
      });

      const kitProducts = productKit.kitProducts.map((cartItem, cartIndex) => {
        const { product, productRow } = cartItem;

        if (cartItem.productType === PRODUCT_TYPE_ADDITIONAL_PRODUCT_LABEL) {
          const newProduct = {
            ...product,
            suppliers: [],
          };
          const newCustomFormula = {
            ...newProduct.customFormula,
            uid: null,
            kitProductUid: null,
            additionalProductUid: null,
          };
          const customRowPrices = productRow.rowPrices.map((rowPrice) => ({
            ...rowPrice,
            uid: null,
          }));
          return {
            productType: cartItem.productType,
            product: newProduct,
            productRow,
            quantity:
              newProduct.pricingStrategy === PRICING_STRATEGY_LINEAL_METRES_LABEL ? null : 1.0,
            actualQuantity:
              newProduct.pricingStrategy === PRICING_STRATEGY_LINEAL_METRES_LABEL ? null : 1.0,
            isCompleted: false,
            lengthItems: [],
            actualLengthItems: [],
            sumLengths: [],
            actualSumLengths: [],
            discount: 0.0,
            customValue: null,
            totalValue: 0.0,
            types: productRow.types,
            productName: newProduct.name,
            customFormula:
              newProduct.pricingStrategy === PRICING_STRATEGY_CUSTOM_FORMULA_LABEL
                ? newCustomFormula
                : null,
            errorInCustomFormula: "",
            uid: null,
            customAttributeValues: [],
            customRowPrices,
            cartIndex,
          };
        } else if (cartItem.productType === PRODUCT_TYPE_LABOUR_PRODUCT_LABEL) {
          return {
            ...cartItem,
            ...cartItem.labour,
            uid: null,
            cartIndex,
          };
        }

        return [];
      }, []);

      const newSubKit = {
        ...productKit,
        uid: null,
        title: productKit.name,
        kitProducts,
      };

      const newSubKits = [...state.subKits, newSubKit, ...newSubKitsFromKit];

      return produce(state, (draftState) => {
        draftState.subKits = newSubKits;
      });
    }

    case UPDATE_CART_QUANTITY: {
      const { cartIndex, newQuantity } = action.payload;
      const newCartItem = {
        ...state.kitProducts[cartIndex],
        quantity: newQuantity,
        totalValue: state.kitProducts[cartIndex].value * newQuantity,
      };

      return produce(state, (draftState) => {
        draftState.kitProducts[cartIndex] = newCartItem;
      });
    }

    case UPDATE_SUB_KIT_CART_QUANTITY: {
      const { subKitIndex, cartIndex, newQuantity } = action.payload;

      const newCartItem = {
        ...state.subKits[subKitIndex].kitProducts[cartIndex],
        quantity: newQuantity,
        totalValue: state.subKits[subKitIndex].kitProducts[cartIndex].value * newQuantity,
      };

      return produce(state, (draftState) => {
        draftState.subKits[subKitIndex].kitProducts[cartIndex] = newCartItem;
      });
    }

    case UPDATE_CART_COLOUR: {
      const { cartIndex, newColour } = action.payload;
      const newProductRow = {
        ...state.kitProducts[cartIndex].productRow,
        colour: newColour,
      };
      const newCartItem = {
        ...state.kitProducts[cartIndex],
        productRow: newProductRow,
      };
      return produce(state, (draftState) => {
        draftState.kitProducts[cartIndex] = newCartItem;
      });
    }

    case UPDATE_SUB_KIT_CART_COLOUR: {
      const { subKitIndex, cartIndex, newColour } = action.payload;
      const newProductRow = {
        ...state.subKits[subKitIndex].kitProducts[cartIndex].productRow,
        colour: newColour,
      };
      const newCartItem = {
        ...state.subKits[subKitIndex].kitProducts[cartIndex],
        productRow: newProductRow,
      };
      return produce(state, (draftState) => {
        draftState.subKits[subKitIndex].kitProducts[cartIndex] = newCartItem;
      });
    }

    case UPDATE_CART_PRICE: {
      const { cartIndex, newPrice, rowPriceUid } = action.payload;
      const newCartItem = {
        ...state.kitProducts[cartIndex],
        rowPriceUid,
        value: newPrice,
        totalValue: newPrice * state.kitProducts[cartIndex].quantity,
      };
      return produce(state, (draftState) => {
        draftState.kitProducts[cartIndex] = newCartItem;
      });
    }

    case UPDATE_SUB_KIT_CART_PRICE: {
      const { subKitIndex, cartIndex, newPrice, rowPriceUid } = action.payload;
      const newCartItem = {
        ...state.subKits[subKitIndex].kitProducts[cartIndex],
        rowPriceUid,
        value: newPrice,
        totalValue: newPrice * state.subKits[subKitIndex].kitProducts[cartIndex].quantity,
      };
      return produce(state, (draftState) => {
        draftState.subKits[subKitIndex].kitProducts[cartIndex] = newCartItem;
      });
    }

    case UPDATE_CART: {
      const { cartIndex, path, value } = action.payload;

      return produce(state, (draft) => {
        setKeypathValue(draft, ["kitProducts", [cartIndex], ...path], value);
      });
    }

    case UPDATE_SUB_KIT_CART: {
      const { subKitIndex, cartIndex, path, value } = action.payload;

      return produce(state, (draft) => {
        setKeypathValue(
          draft,
          ["subKits", [subKitIndex], "kitProducts", [cartIndex], ...path],
          value
        );
      });
    }

    case UPDATE_CART_ATTRIBUTES: {
      const { cartIndex, newAttribute } = action.payload;

      return produce(state, (draftState) => {
        const existingCustomAttributeValues =
          draftState.kitProducts[cartIndex].customAttributeValues || [];

        const updatedCustomAttributeValues = existingCustomAttributeValues.map(
          (existingAttribute) => {
            if (existingAttribute.name === newAttribute.name) {
              return {
                ...existingAttribute,
                value: newAttribute.value,
              };
            }

            return existingAttribute;
          }
        );

        if (!existingCustomAttributeValues.some((attr) => attr.name === newAttribute.name)) {
          updatedCustomAttributeValues.push(newAttribute);
        }

        draftState.kitProducts[cartIndex].customAttributeValues = updatedCustomAttributeValues;
      });
    }

    case UPDATE_SUB_KIT_CART_ATTRIBUTES: {
      const { subKitIndex, cartIndex, newAttribute } = action.payload;

      return produce(state, (draftState) => {
        const existingCustomAttributeValues =
          draftState.subKits[subKitIndex].kitProducts[cartIndex].customAttributeValues || [];

        const updatedCustomAttributeValues = existingCustomAttributeValues.map(
          (existingAttribute) => {
            if (existingAttribute.name === newAttribute.name) {
              return {
                ...existingAttribute,
                value: newAttribute.value,
              };
            }

            return existingAttribute;
          }
        );

        if (!existingCustomAttributeValues.some((attr) => attr.name === newAttribute.name)) {
          updatedCustomAttributeValues.push(newAttribute);
        }

        draftState.subKits[subKitIndex].kitProducts[cartIndex].customAttributeValues =
          updatedCustomAttributeValues;
      });
    }

    case DELETE_CART_ATTRIBUTES: {
      const { cartIndex, currentAttributeName } = action.payload;

      return produce(state, (draftState) => {
        const existingCustomAttributeValues =
          draftState.kitProducts[cartIndex].customAttributeValues || [];

        const updatedCustomAttributeValues = existingCustomAttributeValues.filter(
          (existingAttribute) => existingAttribute.name !== currentAttributeName
        );

        draftState.kitProducts[cartIndex].customAttributeValues = updatedCustomAttributeValues;
      });
    }

    case DELETE_SUB_KIT_CART_ATTRIBUTES: {
      const { subKitIndex, cartIndex, currentAttributeName } = action.payload;

      return produce(state, (draftState) => {
        const existingCustomAttributeValues =
          draftState.subKits[subKitIndex].kitProducts[cartIndex].customAttributeValues || [];

        const updatedCustomAttributeValues = existingCustomAttributeValues.filter(
          (existingAttribute) => existingAttribute.name !== currentAttributeName
        );

        draftState.subKits[subKitIndex].kitProducts[cartIndex].customAttributeValues =
          updatedCustomAttributeValues;
      });
    }

    case DELETE_CART_ITEM: {
      return {
        ...state,
        kitProducts: state.kitProducts.filter(
          (cartItem, cartIndex) => cartIndex !== action.payload
        ),
      };
    }

    case DELETE_SUB_KIT_CART_ITEM: {
      const { subKitIndex, cartIndex } = action.payload;

      return produce(state, (draftState) => {
        draftState.subKits[subKitIndex] = {
          ...state.subKits[subKitIndex],
          kitProducts: state.subKits[subKitIndex].kitProducts.filter(
            (_, kitProductIndex) => kitProductIndex !== cartIndex
          ),
        };
      });
    }

    case ADD_KIT_LENGTH: {
      const { cartIndex, options, minLength, sortLengths } = action.payload;

      if (sortLengths === "largest_to_smallest") {
        return produce(state, (draftState) => {
          const existingLengths = draftState.kitProducts[cartIndex].lengthItems || [];
          const items = [...existingLengths, options];
          const lengths = calcItemLength(items, minLength);
          const orderedLengths = items.sort((a, b) => parseFloat(b.length) - parseFloat(a.length));

          draftState.kitProducts[cartIndex].sumLengths = lengths;
          draftState.kitProducts[cartIndex].lengthItems = orderedLengths;
        });
      } else if (sortLengths === "smallest_to_largest") {
        return produce(state, (draftState) => {
          const existingLengths = draftState.kitProducts[cartIndex].lengthItems || [];
          const items = [...existingLengths, options];
          const lengths = calcItemLength(items, minLength);
          const orderedLengths = items.sort((a, b) => parseFloat(a.length) - parseFloat(b.length));

          draftState.kitProducts[cartIndex].sumLengths = lengths;
          draftState.kitProducts[cartIndex].lengthItems = orderedLengths;
        });
      }

      return produce(state, (draftState) => {
        const existingLengths = draftState.kitProducts[cartIndex].lengthItems || [];
        const items = [...existingLengths, options];
        const lengths = calcItemLength(items, minLength);

        draftState.kitProducts[cartIndex].sumLengths = lengths;
        draftState.kitProducts[cartIndex].lengthItems = items;
      });
    }

    case DELETE_KIT_LENGTH: {
      const { cartIndex, subitemId, minLength } = action.payload;

      return produce(state, (draftState) => {
        // Filter out the specified lengthItem
        draftState.kitProducts[cartIndex].lengthItems = draftState.kitProducts[
          cartIndex
        ].lengthItems.filter((length, lengthIndex) => lengthIndex !== subitemId);

        const existingLengths = draftState.kitProducts[cartIndex].lengthItems || [];
        const lengths = calcItemLength(existingLengths, minLength);
        draftState.kitProducts[cartIndex].sumLengths = lengths;
      });
    }

    case ADD_KIT_DIMENSION: {
      const { cartIndex, options } = action.payload;

      return produce(state, (draftState) => {
        const existingDimensions = draftState.kitProducts[cartIndex].lengthItems || [];
        const items = [...existingDimensions, options];
        const totalSqm = calculateTotalSquareMeters(items);

        draftState.kitProducts[cartIndex].sumLengths = totalSqm;
        draftState.kitProducts[cartIndex].lengthItems = items;
      });
    }

    case DELETE_KIT_DIMENSION: {
      const { cartIndex, subitemId } = action.payload;

      return produce(state, (draftState) => {
        draftState.kitProducts[cartIndex].lengthItems = draftState.kitProducts[
          cartIndex
        ].lengthItems.filter((length, lengthIndex) => lengthIndex !== subitemId);

        const existingDimensions = draftState.kitProducts[cartIndex].lengthItems || [];
        const totalSqm = calculateTotalSquareMeters(existingDimensions);
        draftState.kitProducts[cartIndex].sumLengths = totalSqm;
      });
    }

    case ADD_SUB_KIT_LENGTH: {
      const { subKitIndex, cartIndex, options, minLength, sortLengths } = action.payload;

      if (sortLengths === "largest_to_smallest") {
        return produce(state, (draftState) => {
          const existingLengths =
            draftState.subKits[subKitIndex].kitProducts[cartIndex].lengthItems || [];
          const items = [...existingLengths, options];
          const lengths = calcItemLength(items, minLength);
          const orderedLengths = items.sort((a, b) => parseFloat(b.length) - parseFloat(a.length));

          draftState.subKits[subKitIndex].kitProducts[cartIndex].sumLengths = lengths;
          draftState.subKits[subKitIndex].kitProducts[cartIndex].lengthItems = orderedLengths;
        });
      } else if (sortLengths === "smallest_to_largest") {
        return produce(state, (draftState) => {
          const existingLengths =
            draftState.subKits[subKitIndex].kitProducts[cartIndex].lengthItems || [];
          const items = [...existingLengths, options];
          const lengths = calcItemLength(items, minLength);
          const orderedLengths = items.sort((a, b) => parseFloat(a.length) - parseFloat(b.length));

          draftState.subKits[subKitIndex].kitProducts[cartIndex].sumLengths = lengths;
          draftState.subKits[subKitIndex].kitProducts[cartIndex].lengthItems = orderedLengths;
        });
      }

      return produce(state, (draftState) => {
        const existingLengths =
          draftState.subKits[subKitIndex].kitProducts[cartIndex].lengthItems || [];
        const items = [...existingLengths, options];
        const lengths = calcItemLength(items, minLength);

        draftState.subKits[subKitIndex].kitProducts[cartIndex].sumLengths = lengths;
        draftState.subKits[subKitIndex].kitProducts[cartIndex].lengthItems = items;
      });
    }

    case DELETE_SUB_KIT_LENGTH: {
      const { subKitIndex, cartIndex, subitemId, minLength } = action.payload;

      return produce(state, (draftState) => {
        draftState.subKits[subKitIndex].kitProducts[cartIndex].lengthItems = draftState.subKits[
          subKitIndex
        ].kitProducts[cartIndex].lengthItems.filter(
          (length, lengthIndex) => lengthIndex !== subitemId
        );

        const existingLengths =
          draftState.subKits[subKitIndex].kitProducts[cartIndex].lengthItems || [];
        const lengths = calcItemLength(existingLengths, minLength);
        draftState.subKits[subKitIndex].kitProducts[cartIndex].sumLengths = lengths;
      });
    }

    case ADD_SUB_KIT_DIMENSION: {
      const { subKitIndex, cartIndex, options } = action.payload;

      return produce(state, (draftState) => {
        const existingDimensions =
          draftState.subKits[subKitIndex].kitProducts[cartIndex].lengthItems || [];
        const items = [...existingDimensions, options];
        const totalSqm = calculateTotalSquareMeters(items);

        draftState.subKits[subKitIndex].kitProducts[cartIndex].sumLengths = totalSqm;
        draftState.subKits[subKitIndex].kitProducts[cartIndex].lengthItems = items;
      });
    }

    case DELETE_SUB_KIT_DIMENSION: {
      const { subKitIndex, cartIndex, subitemId } = action.payload;

      return produce(state, (draftState) => {
        draftState.subKits[subKitIndex].kitProducts[cartIndex].lengthItems = draftState.subKits[
          subKitIndex
        ].kitProducts[cartIndex].lengthItems.filter(
          (length, lengthIndex) => lengthIndex !== subitemId
        );

        const existingDimensions =
          draftState.subKits[subKitIndex].kitProducts[cartIndex].lengthItems || [];
        const totalSqm = calculateTotalSquareMeters(existingDimensions);
        draftState.subKits[subKitIndex].kitProducts[cartIndex].sumLengths = totalSqm;
      });
    }

    case DELETE_SUB_KIT: {
      const { subKitIndex } = action.payload;
      return produce(state, (draftState) => {
        draftState.subKits = draftState.subKits.filter(
          (subKit, sKitIndex) => sKitIndex !== subKitIndex
        );
      });
    }

    case CREATE_PRODUCT_KIT_FROM_CART: {
      return produce(state, (draftState) => {
        const subKits = state.subKits.map((subKit) => {
          const transformedData = {};

          subKit.kitProducts.forEach((cartItem, cartIndex) => {
            const {
              product,
              productRow,
              productType,
              quantity,
              customAttributeValues,
              customRowPrices,
              customFormula,
              errorInCustomFormula,
              lengthItems,
              sumLengths,
            } = cartItem;

            const newProductRow = {
              ...productRow,
              quantity,
              cartIndex,
              customAttributeValues,
              customRowPrices,
              customFormula,
              errorInCustomFormula,
              lengthItems,
              sumLengths,
            };

            if (cartItem.productType === PRODUCT_TYPE_ADDITIONAL_PRODUCT_LABEL) {
              const productRows = transformedData[product?.uid]
                ? [...transformedData[product?.uid].productRows, newProductRow]
                : [newProductRow];

              const newProductKitProducts = {
                ...transformedData[product?.uid],
                ...product,
                productType,
                suppliers: [],
                productRows,
              };

              transformedData[product?.uid] = newProductKitProducts;
            }

            if (cartItem.productType === PRODUCT_TYPE_LABOUR_PRODUCT_LABEL) {
              const labourLineItem = {
                uid: cartItem.uid,
                quantity: parseFloat(cartItem.quantity),
                productType: cartItem.productType,
                hourlyRateCost: cartItem.hourlyRateCost,
                hourlyRateCharged: cartItem.hourlyRateCharged,
                user: cartItem.user,
                firstName: cartItem.firstName,
                lastName: cartItem.lastName,
                cartIndex,
              };

              if (!labourLineItem.uid) {
                delete labourLineItem.uid;
              }

              const productRows = transformedData.Labour
                ? [...transformedData.Labour.productRows, { ...labourLineItem }]
                : [{ ...labourLineItem }];

              const newProductKitProducts = {
                ...transformedData.Labour,
                uid: PRODUCT_TYPE_LABOUR_PRODUCT_LABEL,
                name: PRODUCT_TYPE_LABOUR_PRODUCT_LABEL,
                productType,
                productRows,
              };

              transformedData.Labour = newProductKitProducts;
            }
          }, []);

          return {
            ...subKit,
            productKitProducts: transformedData,
            kitProducts: subKit.kitProducts.map((kitProduct, cartIndex) => ({
              ...kitProduct,
              cartIndex,
            })),
          };
        });

        state.kitProducts.forEach((cartItem, cartIndex) => {
          const {
            product,
            productType,
            productRow,
            quantity,
            customAttributeValues,
            customRowPrices,
            customFormula,
            errorInCustomFormula,
            lengthItems,
            sumLengths,
          } = cartItem;

          const newProductRow = {
            ...productRow,
            quantity,
            cartIndex,
            customAttributeValues,
            customRowPrices,
            customFormula,
            errorInCustomFormula,
            lengthItems,
            sumLengths,
          };

          if (cartItem.productType === PRODUCT_TYPE_ADDITIONAL_PRODUCT_LABEL) {
            const productRows = state.productKitProducts[product.uid]
              ? [...state.productKitProducts[product.uid].productRows, newProductRow]
              : [newProductRow];

            const newProductKitProducts = {
              ...state.productKitProducts[product.uid],
              ...product,
              productType,
              suppliers: [],
              productRows,
            };

            state.productKitProducts = {
              ...state.productKitProducts,
              [product.uid]: newProductKitProducts,
            };
          }

          if (cartItem.productType === PRODUCT_TYPE_LABOUR_PRODUCT_LABEL) {
            const labourLineItem = {
              uid: cartItem.uid,
              quantity: parseFloat(cartItem.quantity),
              productType: cartItem.productType,
              hourlyRateCost: cartItem.hourlyRateCost,
              hourlyRateCharged: cartItem.hourlyRateCharged,
              user: cartItem.user,
              firstName: cartItem.firstName,
              lastName: cartItem.lastName,
              cartIndex,
            };

            if (!labourLineItem.uid) {
              delete labourLineItem.uid;
            }

            const productRows = state.productKitProducts.Labour
              ? [...state.productKitProducts.Labour.productRows, { ...labourLineItem }]
              : [{ ...labourLineItem }];

            const newProductKitProducts = {
              ...state.productKitProducts.Labour,
              uid: PRODUCT_TYPE_LABOUR_PRODUCT_LABEL,
              name: PRODUCT_TYPE_LABOUR_PRODUCT_LABEL,
              productType,
              productRows,
            };

            state.productKitProducts = {
              ...state.productKitProducts,
              Labour: newProductKitProducts,
            };
          }
        });

        draftState.productKitProducts = state.productKitProducts;

        draftState.kitProducts = state.kitProducts.map((kitProduct, cartIndex) => ({
          ...kitProduct,
          cartIndex,
        }));

        draftState.subKits = subKits;
      });
    }

    case SET_TARGET_SUB_KIT_INDEX: {
      return {
        ...state,
        subKitIndex: action.payload,
      };
    }

    case SET_IS_SUB_KIT_MODAL_OPEN: {
      const { isSubKitModalOpen, subKitIndex } = action.payload;

      return {
        ...state,
        isSubKitModalOpen,
        subKitIndex,
      };
    }

    case CREATE_SUB_KIT_FROM_LABOUR_LINE_ITEMS_CART: {
      return produce(state, (draftState) => {
        draftState.subKits[draftState.subKitIndex].productKitProducts.Labour = {
          uid: PRODUCT_TYPE_LABOUR_PRODUCT_LABEL,
          name: PRODUCT_TYPE_LABOUR_PRODUCT_LABEL,
          productType: PRODUCT_TYPE_LABOUR_PRODUCT_LABEL,
          productRows: state.subKits[state.subKitIndex].kitProducts.filter(
            (p) => p.productType === PRODUCT_TYPE_LABOUR_PRODUCT_LABEL
          ),
        };
      });
    }

    case CREATE_PRODUCT_KIT_FROM_LABOUR_LINE_ITEMS_CART: {
      return {
        ...state,
        productKitProducts: {
          ...state.productKitProducts,
          Labour: {
            uid: PRODUCT_TYPE_LABOUR_PRODUCT_LABEL,
            name: PRODUCT_TYPE_LABOUR_PRODUCT_LABEL,
            productType: PRODUCT_TYPE_LABOUR_PRODUCT_LABEL,
            productRows: state.kitProducts.filter(
              (p) => p.productType === PRODUCT_TYPE_LABOUR_PRODUCT_LABEL
            ),
          },
        },
      };
    }

    case TOGGLE_PRODUCT_KIT_MODAL: {
      return {
        ...state,
        isModalVisible: !state.isModalVisible,
      };
    }

    case TOGGLE_CART: {
      return {
        ...state,
        isCartVisible: action.payload,
      };
    }

    case UPDATE_CURRENT_PRODUCT_KIT: {
      const { path, value } = action.payload;
      const newCurrentProductKit = {
        ...state.currentProductKit,
        [path]: value,
      };

      return {
        ...state,
        currentProductKit: newCurrentProductKit,
      };
    }

    case UPDATE_SUB_KIT_TITLE: {
      const { subKitIndex, title } = action.payload;

      const newSubKit = {
        ...state.subKits[subKitIndex],
        title,
      };

      return produce(state, (draftState) => {
        draftState.subKits[subKitIndex] = newSubKit;
      });
    }

    case UPDATE_PRODUCT_KIT_PRICING_TABLE: {
      const { path, value } = action.payload;

      return produce(state, (draft) => {
        setKeypathValue(draft, ["currentProductKit", ...path], value);
      });
    }

    case SET_PRODUCT_KIT: {
      const { data } = action.payload;
      const { productKitItem, image, customerPriceLevel } = data;
      const allPriceLevelsHidden =
        productKitItem.priceLevels.length === productKitItem.hiddenPriceLevels.length;

      const priceLevels = allPriceLevelsHidden
        ? productKitItem.priceLevels.map((priceLevel, index) =>
            index === 0 ? { ...priceLevel, isHidden: false } : priceLevel
          )
        : productKitItem.priceLevels;

      const hiddenPriceLevels = allPriceLevelsHidden
        ? productKitItem.hiddenPriceLevels.filter(
            (priceLevelUid) => priceLevelUid !== productKitItem.priceLevels[0].uid
          )
        : productKitItem.hiddenPriceLevels;

      const currentProductKit = {
        ...data,
        title: productKitItem.title,
        description: productKitItem.description,
        customPricing: productKitItem.customPricing,
        displayKitItems: productKitItem.displayKitItems,
        displaySubKits: productKitItem.displaySubKits,
        productKitRows: productKitItem.productKitRows,
        fields: productKitItem.fields,
        category: data.category,
        categoryUid: data.categoryUid,
        oldCategoryUid: data.categoryUid,
        image,
        priceLevels,
        hiddenPriceLevels,
      };

      if (!currentProductKit.customPricing) {
        const newKitProducts = productKitItem.kitProducts.map((kitProduct) => {
          if (kitProduct.productType === PRODUCT_TYPE_ADDITIONAL_PRODUCT_LABEL) {
            const currentTable = kitProduct.product.tables.find(
              (table) => table.name === kitProduct.productRow.material
            );
            const notHiddenPrices = currentTable.priceLevels.filter(
              (priceLevel) => !priceLevel.isHidden
            );
            const firstPrice = kitProduct.productRow.rowPrices.find(
              (rowPrice) => rowPrice.priceLevelUid === notHiddenPrices[0].uid
            );
            const firstCustomPrice = kitProduct.customRowPrices.find(
              (customRowPrice) => customRowPrice.priceLevelUid === notHiddenPrices[0].uid
            );

            if (customerPriceLevel) {
              const customerPriceLevelIsHidden = !notHiddenPrices.some(
                (priceLevel) => priceLevel.uid === customerPriceLevel.uid
              );

              const price = customerPriceLevelIsHidden
                ? firstPrice
                : kitProduct.productRow.rowPrices.find(
                    (p) => p.priceLevelUid === customerPriceLevel?.uid
                  );

              const customPrice = customerPriceLevelIsHidden
                ? firstCustomPrice
                : kitProduct.customRowPrices.find(
                    (customRowPrice) => customRowPrice.priceLevelUid === customerPriceLevel?.uid
                  );

              const value = customPrice.value !== 0 ? customPrice.value : price.value;

              return {
                ...kitProduct,
                value,
                totalValue: kitProduct.quantity * value,
                rowPriceUid: price.uid,
              };
            }

            const customPrice = kitProduct.customRowPrices.find(
              (customRowPrice) => customRowPrice.priceLevelUid === notHiddenPrices[0].uid
            );

            const value = customPrice.value !== 0 ? customPrice.value : firstPrice.value;

            return {
              ...kitProduct,
              value,
              totalValue: kitProduct.quantity * value,
              rowPriceUid: firstPrice.uid,
            };
          }

          if (kitProduct.productType === PRODUCT_TYPE_LABOUR_PRODUCT_LABEL) {
            return {
              ...kitProduct,
              ...kitProduct.labour,
            };
          }

          return [];
        });

        const newSubKits = productKitItem.subKits.map((subKit) => {
          const newSubKitProducts = subKit.kitProducts.map((kitProduct, cartIndex) => {
            if (kitProduct.productType === PRODUCT_TYPE_ADDITIONAL_PRODUCT_LABEL) {
              const currentTable = kitProduct.product.tables.find(
                (table) => table.name === kitProduct.productRow.material
              );
              const notHiddenPrices = currentTable.priceLevels.filter(
                (priceLevel) => !priceLevel.isHidden
              );
              const firstPrice = kitProduct.productRow.rowPrices.find(
                (rowPrice) => rowPrice.priceLevelUid === notHiddenPrices[0].uid
              );
              const firstCustomPrice = kitProduct.customRowPrices.find(
                (customRowPrice) => customRowPrice.priceLevelUid === notHiddenPrices[0].uid
              );

              if (customerPriceLevel) {
                const customerPriceLevelIsHidden = !notHiddenPrices.some(
                  (priceLevel) => priceLevel.uid === customerPriceLevel.uid
                );
                const price = customerPriceLevelIsHidden
                  ? firstPrice
                  : kitProduct.productRow.rowPrices.find(
                      (p) => p.priceLevelUid === customerPriceLevel?.uid
                    );
                const customPrice = customerPriceLevelIsHidden
                  ? firstCustomPrice
                  : kitProduct.customRowPrices.find(
                      (customRowPrice) => customRowPrice.priceLevelUid === customerPriceLevel?.uid
                    );
                const value = customPrice.value !== 0 ? customPrice.value : price.value;

                return {
                  ...kitProduct,
                  value,
                  totalValue: kitProduct.quantity * value,
                  rowPriceUid: price.uid,
                  pricingStrategy: kitProduct.pricingStrategy,
                  cartIndex,
                };
              }

              const customPrice = kitProduct.customRowPrices.find(
                (customRowPrice) => customRowPrice.priceLevelUid === notHiddenPrices[0].uid
              );
              const value = customPrice.value !== 0 ? customPrice.value : firstPrice.value;

              return {
                ...kitProduct,
                value,
                totalValue: kitProduct.quantity * value,
                rowPriceUid: firstPrice.uid,
                pricingStrategy: kitProduct.pricingStrategy,
                cartIndex,
              };
            }

            if (kitProduct.productType === PRODUCT_TYPE_LABOUR_PRODUCT_LABEL) {
              return {
                ...kitProduct,
                ...kitProduct.labour,
              };
            }

            return [];
          });

          return {
            ...subKit,
            kitProducts: newSubKitProducts,
          };
        });

        return {
          ...state,
          currentProductKit,
          kitProducts: newKitProducts,
          subKits: newSubKits,
          selectedPriceLevels: priceLevels
            .filter((price) => !price.isHidden)
            .map((price) => price.uid),
        };
      }

      return {
        ...state,
        currentProductKit,
        kitProducts: productKitItem.kitProducts.map((kitProduct, cartIndex) => ({
          ...kitProduct,
          cartIndex,
        })),
        subKits: productKitItem.subKits.map((subKit) => ({
          ...subKit,
          kitProducts: subKit.kitProducts.map((subKitProduct, cartIndex) => ({
            ...subKitProduct,
            cartIndex,
          })),
        })),
        selectedPriceLevels: priceLevels
          .filter((price) => !price.isHidden)
          .map((price) => price.uid),
      };
    }

    case SET_KIT_LABOUR_LINE_ITEMS: {
      return {
        ...state,
        kitLabourLineItems: action.payload,
      };
    }

    case SET_SELECTED_PRICE_LEVELS: {
      const { data } = action.payload;

      return {
        ...state,
        selectedPriceLevels: data,
      };
    }

    case RESET_PRODUCT_KIT: {
      return {
        ...state,
        productKitProducts: {},
      };
    }

    case RESET_EXPANDED_PRODUCTS: {
      return {
        ...state,
        expandedProducts: [],
      };
    }

    case RESET_EXPANDED_SUB_KIT_PRODUCTS: {
      return {
        ...state,
        expandedSubKitProducts: {},
      };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
