import {
  SET_IS_FETCHING_CUSTOMER_CONTACTS,
  SET_IS_CONTACTS_MODAL_OPEN,
  SET_CUSTOMER_CONTACTS,
  SET_SELECTED_CONTACTS,
  SET_CUSTOMER,
  SET_SEARCH,
  UPDATE_SELECTED_CONTACTS,
  REMOVE_SELECTED_CONTACT,
  SET_IS_FETCHING_CUSTOMER_LISTS,
  SET_SEARCH_CUSTOMER,
  SET_IS_ARCHIVED_CUSTOMER_INCLUDED,
  SET_CUSTOMER_LISTS,
  SET_CURRENT_PAGE,
  SET_TOTAL_COUNT,
  SET_PAGE_OFFSET,
  SET_PREVIOUS_PAGE_OFFSET,
  SET_NEXT_PAGE_OFFSET,
  SET_PAGE_LIMIT,
  SET_SORT,
} from "./customers.actionTypes";

const initialState = {
  company: null,
  user: null,
  isFetchingCustomerContacts: false,
  isContactsModalOpen: false,
  contactModalType: null,
  editContact: {},
  customer: null,
  selectedContacts: [],
  contactsToEmail: [],
  contacts: [],
  search: "",
  customers: [],
  isFetchingCustomerLists: false,
  isArchivedCustomerIncluded: false,
  searchCustomer: "",
  pagination: {
    totalCount: 0,
    limit: 10,
    offset: 0,
    currentPage: 0,
    previousPageOffset: 0,
    nextPageOffset: 0,
    sortBy: "",
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_FETCHING_CUSTOMER_CONTACTS: {
      return { ...state, isFetching: action.payload };
    }

    case SET_IS_CONTACTS_MODAL_OPEN: {
      return {
        ...state,
        isContactsModalOpen: action.payload.status,
        contactModalType: action.payload.type,
        editContact: action.payload.contact,
      };
    }

    case SET_SELECTED_CONTACTS: {
      return {
        ...state,
        selectedContacts: state.selectedContacts.concat([{ uid: action.payload.uid }]),
        contactsToEmail: state.contactsToEmail.concat([action.payload.email]),
      };
    }

    case SET_SEARCH: {
      return {
        ...state,
        search: action.payload,
      };
    }

    case SET_CUSTOMER: {
      return {
        ...state,
        customer: action.payload,
      };
    }

    case REMOVE_SELECTED_CONTACT: {
      const selectedContacts = state.selectedContacts.filter(
        (item) => item.uid !== action.payload.uid
      );
      const contactsToEmail = state.contactsToEmail.filter((item) => item !== action.payload.email);
      return { ...state, selectedContacts, contactsToEmail };
    }

    case UPDATE_SELECTED_CONTACTS: {
      return { ...state, selectedContacts: action.payload, contactsToEmail: action.payload };
    }

    case `${SET_CUSTOMER_CONTACTS}`: {
      return { ...state, contacts: action.payload.data.contacts };
    }

    // PAGINATION
    case SET_IS_ARCHIVED_CUSTOMER_INCLUDED: {
      return {
        ...state,
        isArchivedCustomerIncluded: action.payload,
      };
    }

    case SET_SEARCH_CUSTOMER: {
      return {
        ...state,
        searchCustomer: action.payload,
      };
    }

    case SET_IS_FETCHING_CUSTOMER_LISTS: {
      return {
        ...state,
        isFetchingCustomerLists: action.payload,
      };
    }

    case SET_CUSTOMER_LISTS: {
      return {
        ...state,
        customers: action.payload,
      };
    }

    case SET_CURRENT_PAGE: {
      return {
        ...state,
        pagination: { ...state.pagination, currentPage: action.payload },
      };
    }

    case SET_TOTAL_COUNT: {
      return {
        ...state,
        pagination: { ...state.pagination, totalCount: action.payload },
      };
    }

    case SET_PAGE_OFFSET: {
      return {
        ...state,
        pagination: { ...state.pagination, offset: action.payload },
      };
    }

    case SET_NEXT_PAGE_OFFSET: {
      return {
        ...state,
        pagination: { ...state.pagination, nextPageOffset: action.payload },
      };
    }

    case SET_PREVIOUS_PAGE_OFFSET: {
      return {
        ...state,
        pagination: { ...state.pagination, previousPageOffset: action.payload },
      };
    }

    case SET_PAGE_LIMIT: {
      return {
        ...state,
        pagination: { ...state.pagination, limit: action.payload },
      };
    }

    case SET_SORT: {
      return {
        ...state,
        pagination: { ...state.pagination, sortBy: action.payload },
      };
    }

    default:
      return state;
  }
};
