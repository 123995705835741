/* eslint-disable no-nested-ternary */
import moment from "moment";
import { nanoid } from "nanoid";

import * as date from "@/utils/date";

import { handleError } from "../../utils/error";
import * as calendarApi from "../../../apiv2/deliveryScheduling";
import * as ordersApi from "../../../apiv2/planner/orders";
import * as deliverySchedulingSelectors from "../deliveryScheduling/deliveryScheduling.selectors";
import * as orderActions from "../orders/orders.actions";
import * as orderSelectors from "../orders/orders.selectors";
import * as alertActions from "../../alerts/alerts.actions";
import * as searchFilterSelectors from "../searchFilter/searchFilter.selectors";
import * as labelFilterSelectors from "../labelFilter/labelFilter.selectors";
import * as userFilterSelectors from "../userFilter/userFilter.selectors";
import * as requiredDateSelectors from "../requiredDateFilter/requiredDate.selectors";

import {
  SET_ORDER_IN_CALENDAR,
  SET_IS_FETCHING_SCHEDULES,
  SET_FROM_DATE,
  SET_TO_DATE,
  SET_IS_CONTEXT_MENU_OPEN,
  SET_CALENDAR_VIEW,
  SET_ACTIVE_SCHEDULE,
  SET_DATA_IN_CALENDAR_ENTRY,
  RESET,
} from "./calendar.actionTypes";
import * as selectors from "./calendar.selectors";

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setIsFetchingSchedules = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_SCHEDULES,
    payload: status,
  });
};

export const setOrderInCalendar = (data) => (dispatch) => {
  dispatch({
    type: SET_ORDER_IN_CALENDAR,
    payload: data,
  });
};

export const setFromDate = (data) => (dispatch) => {
  dispatch({
    type: SET_FROM_DATE,
    payload: data,
  });
};

export const setToDate = (data) => (dispatch) => {
  dispatch({
    type: SET_TO_DATE,
    payload: data,
  });
};

export const setIsContextMenuOpen = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_CONTEXT_MENU_OPEN,
    payload: data,
  });
};

export const setCalendarView = (data) => (dispatch) => {
  dispatch({
    type: SET_CALENDAR_VIEW,
    payload: data,
  });
};

export const setActiveSchedule = (data) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_SCHEDULE,
    payload: data,
  });
};

export const setDataInCalendarEntry = (data) => (dispatch) => {
  dispatch({
    type: SET_DATA_IN_CALENDAR_ENTRY,
    payload: data,
  });
};

export const fetchSchedules = () => async (dispatch, getState) => {
  const state = getState();
  const fromDate = selectors.getFromDate(state);
  const toDate = selectors.getToDate(state);
  const search = searchFilterSelectors.getSearch(state);
  const selectedLabels = labelFilterSelectors.getSelectedLabels(state);
  const selectedAssignees = userFilterSelectors.getSelectedUsers(state);
  const calendarView = selectors.getCalendarView(state);
  const selectedVehicle = deliverySchedulingSelectors.getSelectedVehicle(state);
  const isFetchingSchedules = selectors.isFetchingSchedules(state);
  const requiredStartDate = requiredDateSelectors.getRequiredStartDate(state);
  const requiredEndDate = requiredDateSelectors.getRequiredEndDate(state);

  if (isFetchingSchedules) return;

  try {
    dispatch(setIsFetchingSchedules(true));

    const payload = {
      from_date: date.toUtcDateTime({
        date: `${moment(fromDate).format("YYYY-MM-DD")}T00:00:00`,
        timezone: date.getCurrentCompanyTimezone(),
      }),
      to_date: date.toUtcDateTime({
        date: `${moment(toDate).format("YYYY-MM-DD")}T23:59:59`,
        timezone: date.getCurrentCompanyTimezone(),
      }),
      search: search || undefined,
      labels: selectedLabels,
      assignees: selectedAssignees,
      requiredStartDate,
      requiredEndDate,
    };

    if (calendarView === "week") {
      payload.vehicle = selectedVehicle;
    }

    const { data } = await calendarApi.getOrdersInCalendar(payload)();

    dispatch(setOrderInCalendar(data));
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetchingSchedules(false));
  }
};

export const dropOrderInCalendar = (data) => async (dispatch, getState) => {
  try {
    const state = getState();
    const schedules = selectors.getSchedules(state);
    const selectedVehicle = deliverySchedulingSelectors.getSelectedVehicle(state);
    const calendarView = selectors.getCalendarView(state);

    const payload = {
      order_uid: data.uid,
    };

    payload.delivery_start_timestamp = date.toUtcDateTime({
      date: data.start,
      timezone: date.getCurrentCompanyTimezone(),
    });

    payload.delivery_end_timestamp = date.toUtcDateTime({
      date: data.end,
      timezone: date.getCurrentCompanyTimezone(),
    });

    if (calendarView === "week") {
      payload.vehicle_uid = selectedVehicle;
    }

    if (calendarView === "vehicle") {
      payload.vehicle_uid = data.resource;
    }

    const orders = orderSelectors.getOrders(state);
    dispatch(orderActions.setOrders(orders.filter((o) => o.uid !== data.uid)));

    await calendarApi.postCalendarEvent(payload)();

    dispatch(
      setOrderInCalendar([
        ...schedules,
        {
          ...data,
          vehicle: payload.vehicle_uid,
          deliveryStartTimestamp: payload.delivery_start_timestamp,
          deliveryEndTimestamp: payload.delivery_end_timestamp,
        },
      ])
    );
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const updateOrderInCalendar = (data) => async (dispatch, getState) => {
  try {
    const state = getState();
    const schedules = selectors.getSchedules(state);
    const startTime = `${moment(data.start).utc().format("YYYY-MM-DDTHH:mm:ss")}.000Z`;
    const endTime = `${moment(data.end).utc().format("YYYY-MM-DDTHH:mm:ss")}.000Z`;

    const payload = {
      order_uid: data.uid,
      delivery_start_timestamp: startTime,
      delivery_end_timestamp: endTime,
      vehicle_uid: data.resource,
    };

    dispatch(
      setOrderInCalendar(
        schedules.map((s) =>
          s.uid === data.uid
            ? {
                ...s,
                vehicle: payload.vehicle_uid,
                deliveryStartTimestamp: startTime,
                deliveryEndTimestamp: endTime,
              }
            : { ...s }
        )
      )
    );

    await calendarApi.postCalendarEvent(payload)();
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const undoMarkOrder = (values) => async (dispatch, getState) => {
  const state = getState();
  const { order } = values;
  const schedules = selectors.getSchedules(state);

  dispatch(
    setOrderInCalendar(
      schedules.map((s) => (s.uid === order.uid ? { ...s, isReceived: null } : { ...s }))
    )
  );

  try {
    await calendarApi.postMarkOrder({ orderUid: order.uid, is_received: null })();
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const markOrder = (values) => async (dispatch, getState) => {
  const state = getState();

  const { order, message, markedStatus } = values;
  const schedules = selectors.getSchedules(state);

  dispatch(
    setOrderInCalendar(
      schedules.map((s) => (s.uid === order.uid ? { ...s, isReceived: markedStatus } : { ...s }))
    )
  );

  try {
    await calendarApi.postMarkOrder({
      order_uid: order.uid,
      is_received: markedStatus,
    })();

    if (message) {
      dispatch(
        alertActions.createAlertAction(nanoid(), message, true, "success", "Undo", async () => {
          await dispatch(
            undoMarkOrder({
              order,
            })
          );
        })
      );
    }
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const getOrder =
  ({ orderUid, customerUid }) =>
  () =>
    ordersApi.getOrder({ orderUid, customerUid })();
