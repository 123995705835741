import { STATUS } from "@/utils/constants";

import {
  SET_LATEST_CUSTOMERS,
  SET_SEARCH_LATEST_CUSTOMERS,
  SET_FETCHING_LATEST_CUSTOMERS_STATUS,
  SET_IS_LINKED_CUSTOMERS,
  SET_IS_PREVIOUSLY_IMPORTED_CUSTOMERS,
  SET_CUSTOMERS_TO_IMPORT,
  SET_IMPORT_CUSTOMERS_STATUS,
  SET_IMPORT_CUSTOMERS,
  SET_DUPLICATE_CUSTOMERS_TO_IMPORT,
  SET_DUPLICATE_CUSTOMERS_WARNING_MODAL,
  SET_IMPORT_CUSTOMERS_SORT,
  SET_IMPORT_CUSTOMERS_PAGE,
  SET_IMPORT_CUSTOMERS_META,
  SET_IS_LINKED_CUSTOMERS_FILTER,
  SET_IMPORT_ALL_CUSTOMERS_STATUS,
  SET_IMPORT_ALL_CUSTOMERS,
  RESET,
} from "./importCustomers.actionTypes";

const initialState = {
  fetchingLatestCustomersStatus: STATUS.IDLE,
  latestCustomers: [],
  searchLatestCustomers: "",
  isLinkedCustomers: [true, false],
  isPreviouslyImportedCustomers: [true, false],
  customersToImport: [],
  importCustomersStatus: STATUS.IDLE,
  importCustomers: null,
  duplicateCustomersToImport: [],
  duplicateCustomersWarningModal: false,
  sort: "name",
  page: 1,
  meta: { num_pages: 1, page: 1, count: 0 },
  isLinkedCustomersFilter: "",
  importAllCustomersStatus: STATUS.IDLE,
  importAllCustomers: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LATEST_CUSTOMERS: {
      return {
        ...state,
        latestCustomers: action.payload,
      };
    }

    case SET_SEARCH_LATEST_CUSTOMERS: {
      return { ...state, searchLatestCustomers: action.payload };
    }

    case SET_FETCHING_LATEST_CUSTOMERS_STATUS: {
      return { ...state, fetchingLatestCustomersStatus: action.payload };
    }

    case SET_IS_LINKED_CUSTOMERS: {
      return { ...state, isLinkedCustomers: action.payload };
    }

    case SET_IS_PREVIOUSLY_IMPORTED_CUSTOMERS: {
      return { ...state, isPreviouslyImportedCustomers: action.payload };
    }

    case SET_CUSTOMERS_TO_IMPORT: {
      return { ...state, customersToImport: action.payload };
    }

    case SET_IMPORT_CUSTOMERS_STATUS: {
      return { ...state, importCustomersStatus: action.payload };
    }

    case SET_IMPORT_CUSTOMERS: {
      return { ...state, importCustomers: action.payload };
    }

    case SET_DUPLICATE_CUSTOMERS_TO_IMPORT: {
      return { ...state, duplicateCustomersToImport: action.payload };
    }

    case SET_DUPLICATE_CUSTOMERS_WARNING_MODAL: {
      return { ...state, duplicateCustomersWarningModal: action.payload };
    }

    case SET_IMPORT_CUSTOMERS_SORT: {
      return { ...state, sort: action.payload };
    }

    case SET_IMPORT_CUSTOMERS_PAGE: {
      return { ...state, page: action.payload };
    }

    case SET_IMPORT_CUSTOMERS_META: {
      return { ...state, meta: action.payload };
    }

    case SET_IS_LINKED_CUSTOMERS_FILTER: {
      return { ...state, isLinkedCustomersFilter: action.payload };
    }

    case SET_IMPORT_ALL_CUSTOMERS_STATUS: {
      return { ...state, importAllCustomersStatus: action.payload };
    }

    case SET_IMPORT_ALL_CUSTOMERS: {
      return { ...state, importAllCustomers: action.payload };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
