import createActionType from "../utils/createActionType";

export const MODULE = "signup";

export const SET_IS_FETCHING = createActionType(MODULE, "setIsFetching");

export const SET_IS_SAVING = createActionType(MODULE, "setIsSaving");

export const SET_SIGNUP_DATA = createActionType(MODULE, "setSignupData");

export const SET_TIMEZONES = createActionType(MODULE, "setTimezones");

export const SET_COUNTRIES = createActionType(MODULE, "setCountries");

export const SET_XERO_ACCOUNTS = createActionType(MODULE, "setXeroAccounts");

export const SET_INVITE_PAGE_STATUS = createActionType(MODULE, "setInvitePageStatus");

export const RESET = createActionType(MODULE, "reset");
