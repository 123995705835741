import * as routerSelectors from "../../router/router.selectors";

import { SET_SEARCH, RESET } from "./searchFilter.actionTypes";

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setSearch = (data) => (dispatch) => {
  dispatch({
    type: SET_SEARCH,
    payload: data,
  });
};

export const selectSearchFromQueryParams = () => (dispatch, getState) => {
  const state = getState();
  const queryParams = routerSelectors.getQuery(state);

  if (queryParams.search) {
    dispatch(setSearch(queryParams.search));
  } else {
    dispatch(setSearch(""));
  }
};
