import qs from "query-string";

import { createApiV2 } from "./common";

export const getViews = (params) => () =>
  createApiV2("GET", `custom_views/?${qs.stringify(params)}`)();

export const getView = (uid) => () => createApiV2("GET", `custom_views/${uid}/`)();

export const postView = (payload) => (token) =>
  createApiV2("POST", "custom_views/")(token, payload);

export const patchView =
  ({ uid, payload }) =>
  (token) =>
    createApiV2("PATCH", `custom_views/${uid}/`)(token, payload);

export const deleteView = (uid) => (token) => createApiV2("DELETE", `custom_views/${uid}/`)(token);

export const postDefaultView = (payload) => (token) =>
  createApiV2("POST", "custom_views/set_default/")(token, payload);
