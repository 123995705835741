import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { Datepicker as ReactDatepicker } from "@mobiscroll/react";
import cx from "classnames";

import styles from "./Timepicker.module.scss";

const Timepicker = forwardRef(({ className, onChange, placeholder, value, ...rest }, ref) => (
  <div className={cx(styles.wrapper, className)}>
    <ReactDatepicker
      ref={ref}
      timeFormat="hh:mm A"
      themeVariant="light"
      controls={["time"]}
      theme="ios"
      stepMinute={1}
      value={value}
      defalutValue={value}
      onChange={onChange}
      onClose={onChange}
      inputProps={{
        placeholder,
      }}
      {...rest}
    />
  </div>
));

Timepicker.defaultProps = {
  className: "",
  placeholder: "",
  value: "",
};

Timepicker.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default Timepicker;
