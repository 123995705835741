import { useEffect } from "react";

import { intercomSettings } from "../env";

const InitializeTagManager = () => {
  useEffect(() => {
    if (intercomSettings) {
      const w = window;
      const ic = w.Intercom;

      if (typeof ic !== "function") {
        const d = document;

        var i = function () {
          i.c(arguments);
        };

        i.q = [];

        i.c = function (args) {
          i.q.push(args);
        };

        w.Intercom = i;

        const l = function () {
          const s = d.createElement("script");
          s.type = "text/javascript";
          s.async = true;
          s.src = `https://widget.intercom.io/widget/${intercomSettings.app_id}`;
          const x = d.getElementsByTagName("script")[0];
          x.parentNode.insertBefore(s, x);
        };

        if (w.attachEvent) {
          w.attachEvent("onload", l);
        } else {
          w.addEventListener("load", l, false);
        }
      }
    }
  }, []);

  return null;
};

export default InitializeTagManager;
