import createActionType from "../utils/createActionType";

export const MODULE = "suppliers";

// SUPPLIERS //
export const SEARCH_SUPPLIERS = createActionType(MODULE, "seachSuppliers");
export const GET_SUPPLIER = createActionType(MODULE, "getSupplier");
export const UPDATE_SUPPLIER = createActionType(MODULE, "updateSupplier");
export const DELETE_SUPPLIER = createActionType(MODULE, "deleteSupplier");
export const BULK_DELETE_SUPPLIER = createActionType(MODULE, "bulkDeleteSupplier");
export const UNDO_SUPPLIER_ARCHIVE = createActionType(MODULE, "undoSupplierArchive");
export const UNDO_SUPPLIER_BULK_ARCHIVE = createActionType(MODULE, "undoSupplierBulkArchive");
export const REMOVE_ACTIVE_SUPPLIER = createActionType(MODULE, "removeActiveSupplier");

// SUPPLIER CONTACTS //
export const GET_ALL_SUPPLIER_CONTACTS = createActionType(MODULE, "getAllSupplierContacts");
export const SET_SUPPLIER_CONTACTS = createActionType(MODULE, "setSupplierContacts");
export const CREATE_SUPPLIER_CONTACT = createActionType(MODULE, "createSupplierContact");
export const UPDATE_SUPPLIER_CONTACT = createActionType(MODULE, "updateSupplierContact");
export const DELETE_SUPPLIER_CONTACT = createActionType(MODULE, "deleteSupplierContact");
export const UNDO_DELETE_SUPPLIER_CONTACT = createActionType(MODULE, "undoDeleteSupplierContact");
export const REMOVE_ACTIVE_SUPPLIER_CONTACTS = createActionType(
  MODULE,
  "removeActiveSupplierContacts"
);

// SUPPLIER PURCHASE ORDER //
export const GET_ALL_SUPPLIER_PURCHASE_ORDERS = createActionType(
  MODULE,
  "getAllSupplierPurchaseOrders"
);
export const DELETE_SUPPLIER_PURCHASE_ORDER = createActionType(
  MODULE,
  "deleteSupplierPurchaseOrder"
);
export const BULK_DELETE_SUPPLIER_PURCHASE_ORDERS = createActionType(
  MODULE,
  "bulkDeleteSupplierPurchaseOrders"
);
export const UPDATE_SUPPLIER_PURCHASE_ORDER = createActionType(
  MODULE,
  "updateSupplierPurchaseOrder"
);
export const EDIT_SUPPLIER_PURCHASE_ORDER_FILTERS = createActionType(
  MODULE,
  "updateSupplierPurchaseOrderFilters"
);
export const UNDO_SUPPLIER_PURCHASE_ORDER_ARCHIVE = createActionType(
  MODULE,
  "undoSupplierPurchaseOrderArchive"
);
export const UNDO_SUPPLIER_PURCHASE_ORDER_BULK_ARCHIVE = createActionType(
  MODULE,
  "undoSupplierPurchaseOrderBulkArchive"
);
export const RESET_SUPPLIER_PURCHASE_ORDERS = createActionType(
  MODULE,
  "resetSupplierPurchaseOrders"
);

export const SET_IS_DUPLICATING = createActionType(MODULE, "setIsDuplicating");

export const SET_DUPLICATE_ORDER_UID = createActionType(MODULE, "setDuplicateOrderUid");

export const SET_IS_DUPLICATE_CONFIRMATION_ALERT_OPEN = createActionType(
  MODULE,
  "setIsDuplicateConfirmationAlertOpen"
);
