import { orderBy } from "lodash";

import * as date from "@/utils/date";
import { userSelector } from "@/redux/customers/customers.selectors";

import { MODULE } from "./calendar.actionTypes";

export const isFetchingSchedules = (state) => state.getIn([MODULE, "isFetchingSchedules"]);

export const getSchedules = (state) => state.getIn([MODULE, "schedules"]);

export const getFormattedSchedules = (state) =>
  getSchedules(state).map((d) => {
    const startTime = date.toLocalDateTime({
      date: d.deliveryStartTimestamp,
      timezone: date.getCurrentCompanyTimezone(),
      format: "YYYY-MM-DDTHH:mm:ssZ",
    });

    const endTime = date.toLocalDateTime({
      date: d.deliveryEndTimestamp,
      timezone: date.getCurrentCompanyTimezone(),
      format: "YYYY-MM-DDTHH:mm:ssZ",
    });

    return {
      ...d,
      id: d.uid,
      deliveryStartTimestamp: startTime,
      deliveryEndTimestamp: endTime,
      start: startTime,
      end: endTime,
      resource: d.vehicle,
      color: d.isReceived ? "#d4d7da" : "#273444",
      cssClass: d.isReceived ? "is-received" : "",
    };
  });

export const getFromDate = (state) => state.getIn([MODULE, "fromDate"]);

export const getToDate = (state) => state.getIn([MODULE, "toDate"]);

export const getCalendarView = (state) => state.getIn([MODULE, "calendarView"]);

export const isContextMenuOpen = (state) => state.getIn([MODULE, "isContextMenuOpen"]);

export const getSortedAssignees = (state, assignees) => {
  const currentUser = userSelector(state);

  const formattedAssignees = assignees.map((a) =>
    a.id === currentUser.id ? { ...a, lastName: `${a.lastName} (You)` } : a
  );
  const sortedAssignees = [
    ...(formattedAssignees.some((a) => a.id === currentUser.id)
      ? [{ ...formattedAssignees.find((a) => a.id === currentUser.id) }]
      : []),
    ...orderBy(
      formattedAssignees.filter((a) => a.id !== currentUser.id),
      [(a) => a.firstName.toLowerCase()],
      "asc"
    ),
  ];

  return sortedAssignees;
};

export const getLabels = (state) => {
  const labels = state.getIn([MODULE, "labels"]);
  return orderBy(labels, [(l) => l.name.toLowerCase()], "asc");
};

export const getSortedAssignedLabels = (labels) =>
  orderBy(labels, [(l) => l.name.toLowerCase()], "asc");

export const getActiveSchedule = (state) => state.getIn([MODULE, "activeSchedule"]);
