import {
  SET_NOTIFICATION,
  SET_NOTIFICATION_TYPE,
  SET_LAST_NOTIFICATION,
  RESET,
} from "./notifications.actionTypes";

export const setNotificationType = (payload) => (dispatch) => {
  dispatch({
    type: SET_NOTIFICATION_TYPE,
    payload,
  });
};

export const setNotification = (type, message) => (dispatch) => {
  dispatch(setNotificationType(type));

  dispatch({
    type: SET_NOTIFICATION,
    payload: message,
  });
};

export const setLastNotification = (payload) => (dispatch) => {
  dispatch({
    type: SET_LAST_NOTIFICATION,
    payload,
  });
};

export const reset = () => (dispatch) => {
  dispatch({ type: RESET });
};
