import {
  HIDE_ALL_ORDER_COLUMNS,
  REORDER_ALL_ORDER_COLUMNS,
  UPDATE_WIDTH_ALL_ORDER_COLUMNS,
  HIDE_CUSTOMER_ORDER_COLUMNS,
  REORDER_CUSTOMER_ORDER_COLUMNS,
  UPDATE_WIDTH_CUSTOMER_ORDER_COLUMNS,
} from "./preferences.actionTypes";

// All Order Actions

export const hideAllOrderColumnsAction = (id) => ({
  type: HIDE_ALL_ORDER_COLUMNS,
  payload: { id },
});

export const reorderAllOrderColumnsAction = (sourceId, destinationId) => ({
  type: REORDER_ALL_ORDER_COLUMNS,
  payload: { sourceId, destinationId },
});

export const updateWidthAllOrderColumnsAction = (id, width) => ({
  type: UPDATE_WIDTH_ALL_ORDER_COLUMNS,
  payload: { id, width },
});

// Customer Order Actions

export const hideCustomerOrderColumnsAction = (id) => ({
  type: HIDE_CUSTOMER_ORDER_COLUMNS,
  payload: { id },
});

export const reorderCustomerOrderColumnsAction = (sourceId, destinationId) => ({
  type: REORDER_CUSTOMER_ORDER_COLUMNS,
  payload: { sourceId, destinationId },
});

export const updateWidthCustomerOrderColumnsAction = (id, width) => ({
  type: UPDATE_WIDTH_CUSTOMER_ORDER_COLUMNS,
  payload: { id, width },
});

export const preferencesActions = {
  hideAllOrderColumnsAction,
  reorderAllOrderColumnsAction,
  updateWidthAllOrderColumnsAction,
  hideCustomerOrderColumnsAction,
  reorderCustomerOrderColumnsAction,
  updateWidthCustomerOrderColumnsAction,
};
