import { MODULE } from "./stocktake.actionTypes";

export const isFetchingCategories = (state) => state.getIn([MODULE, "isFetchingCategories"]);

export const isSaving = (state) => state.getIn([MODULE, "isSaving"]);

export const getCategories = (state) => state.getIn([MODULE, "categories"]);

export const getExpandedCategories = (state) => state.getIn([MODULE, "expandedCategories"]);

export const getExpandedProducts = (state) => state.getIn([MODULE, "expandedProducts"]);

export const getProducts = (state) => state.getIn([MODULE, "products"]);

export const getSelectedCategories = (state) => state.getIn([MODULE, "selectedCategories"]);

export const getSelectedProducts = (state) => state.getIn([MODULE, "selectedProducts"]);

export const getProductsFromCategory = (state, categoryUid) =>
  state.getIn([MODULE, "products", categoryUid]);

export const getFetchingInventoryProductUid = (state) =>
  state.getIn([MODULE, "fetchingInventoryProductUid"]);
