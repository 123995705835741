import createActionType from "@/redux/utils/createActionType";

export const MODULE = "add-labour-line-items";

export const SET_IS_MODAL_VISIBLE = createActionType(MODULE, "setIsModalVisible");

export const SET_TEAM_MEMBERS = createActionType(MODULE, "setTeamMembers");

export const SET_IS_FETCHING = createActionType(MODULE, "setIsFetching");

export const SET_CART = createActionType(MODULE, "setCart");

export const SET_ITEMS_TO_CART = createActionType(MODULE, "setItemsToCart");

// Product Kits or Sub Kits
export const SET_KIT_TYPE = createActionType(MODULE, "setKitType");

export const RESET = createActionType(MODULE, "reset");
