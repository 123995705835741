import Decimal from "decimal.js-light";

import {
  GET_LINKED_ORDERS_FROM_ORDER,
  GET_UNLINKED_ORDERS_FROM_ORDER,
  SET_UNLINKED_ORDERS_TYPE,
  SET_UNLINKED_ORDERS_STATUS,
  SET_UNLINKED_ORDERS_SEARCH,
  SET_UNLINKED_ORDERS_PAGE,
  SET_UNLINKED_ORDERS_PAGE_SIZE,
  SET_UNLINKED_ORDERS_SELECTED_IDS,
  SET_LINKED_ORDERS_TYPE,
  SET_LINKED_ORDERS_STATUS,
  SET_LINKED_ORDERS_SEARCH,
  SET_LINKED_ORDERS_PAGE,
  SET_LINKED_ORDERS_PAGE_SIZE,
  SET_LINKED_ORDERS_SELECTED_IDS,
  SET_SORT,
  GET_ORDER_HISTORY,
  SET_ORDER_HISTORY_TYPE,
  SET_ORDER_HISTORY_STATUS,
  SET_ORDER_HISTORY_SEARCH,
  SET_ORDER_HISTORY_PAGE,
  SET_ORDER_HISTORY_PAGE_SIZE,
  SET_ORDER_HISTORY_ROW_PAGE,
  SET_ORDER_HISTORY_ROW_PAGE_SIZE,
  SET_ORDER_HISTORY_QTY_TOTAL,
  SET_ORDER_HISTORY_FROM_DATE,
  SET_ORDER_HISTORY_TO_DATE,
} from "./linkedOrders.actionTypes";

Decimal.config({ rounding: Decimal.ROUND_HALF_UP });

const initialState = {
  linkedOrders: {
    count: 0,
    results: [],
  },
  linkedOrdersType: ["Sale", "Purchase"],
  linkedOrdersStatus: [],
  linkedOrdersSearch: "",
  linkedOrdersSelectedIds: [],
  linkedOrdersPage: 0,
  linkedOrdersPageSize: 10,
  unlinkedOrders: {
    count: 0,
    results: [],
  },
  unlinkedOrdersType: ["Sale", "Purchase"],
  unlinkedOrdersStatus: [],
  unlinkedOrdersSearch: "",
  unlinkedOrdersPage: 0,
  unlinkedOrdersPageSize: 10,
  unlinkedOrdersSelectedIds: [],
  sortBy: "created_timestamp:desc",
  orderHistory: {
    count: 0,
    results: [],
  },
  orderHistoryType: ["Sale", "Purchase"],
  orderHistoryStatus: [],
  orderHistorySearch: "",
  orderHistoryPage: 0,
  orderHistoryPageSize: 10,
  orderHistoryRowPage: 0,
  orderHistoryRowPageSize: 10,
  orderHistoryQtyTotal: 0,
  orderHistoryFromDate: null,
  orderHistoryToDate: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case `${GET_LINKED_ORDERS_FROM_ORDER}/fulfilled`: {
      const { data } = action.payload;

      return { ...state, linkedOrders: data };
    }

    case SET_LINKED_ORDERS_TYPE: {
      return { ...state, linkedOrdersType: action.payload };
    }

    case SET_LINKED_ORDERS_STATUS: {
      return { ...state, linkedOrdersStatus: action.payload };
    }

    case SET_LINKED_ORDERS_SEARCH: {
      return { ...state, linkedOrdersSearch: action.payload };
    }

    case SET_LINKED_ORDERS_SELECTED_IDS: {
      return { ...state, linkedOrdersSelectedIds: action.payload };
    }

    case SET_LINKED_ORDERS_PAGE: {
      return { ...state, linkedOrdersPage: action.payload };
    }

    case SET_LINKED_ORDERS_PAGE_SIZE: {
      return { ...state, linkedOrdersPageSize: action.payload };
    }

    case `${GET_UNLINKED_ORDERS_FROM_ORDER}/fulfilled`: {
      const { data } = action.payload;

      return { ...state, unlinkedOrders: data };
    }

    case SET_UNLINKED_ORDERS_TYPE: {
      return { ...state, unlinkedOrdersType: action.payload };
    }

    case SET_UNLINKED_ORDERS_STATUS: {
      return { ...state, unlinkedOrdersStatus: action.payload };
    }

    case SET_UNLINKED_ORDERS_SEARCH: {
      return { ...state, unlinkedOrdersSearch: action.payload };
    }

    case SET_UNLINKED_ORDERS_SELECTED_IDS: {
      return { ...state, unlinkedOrdersSelectedIds: action.payload };
    }

    case SET_UNLINKED_ORDERS_PAGE: {
      return { ...state, unlinkedOrdersPage: action.payload };
    }

    case SET_UNLINKED_ORDERS_PAGE_SIZE: {
      return { ...state, unlinkedOrdersPageSize: action.payload };
    }

    case SET_SORT: {
      return { ...state, sortBy: action.payload };
    }

    case `${GET_ORDER_HISTORY}/fulfilled`: {
      const { data } = action.payload;

      return {
        ...state,
        orderHistory: data,
        orderHistoryQtyTotal: parseFloat(
          Decimal(data.results.reduce((sum, row) => sum + row.qtyOnOrder, 0))
            .toDecimalPlaces(4)
            .toFixed(4)
        ),
      };
    }

    case SET_ORDER_HISTORY_TYPE: {
      return { ...state, orderHistoryType: action.payload };
    }

    case SET_ORDER_HISTORY_STATUS: {
      return { ...state, orderHistoryStatus: action.payload };
    }

    case SET_ORDER_HISTORY_SEARCH: {
      return { ...state, orderHistorySearch: action.payload };
    }

    case SET_ORDER_HISTORY_PAGE: {
      return { ...state, orderHistoryPage: action.payload };
    }

    case SET_ORDER_HISTORY_PAGE_SIZE: {
      return { ...state, orderHistoryPageSize: action.payload };
    }

    case SET_ORDER_HISTORY_ROW_PAGE: {
      return { ...state, orderHistoryRowPage: action.payload };
    }

    case SET_ORDER_HISTORY_ROW_PAGE_SIZE: {
      return { ...state, orderHistoryRowPageSize: action.payload };
    }

    case SET_ORDER_HISTORY_QTY_TOTAL: {
      return { ...state, orderHistoryQtyTotal: action.payload };
    }

    case SET_ORDER_HISTORY_FROM_DATE: {
      return { ...state, orderHistoryFromDate: action.payload };
    }

    case SET_ORDER_HISTORY_TO_DATE: {
      return { ...state, orderHistoryToDate: action.payload };
    }

    default:
      return state;
  }
};
