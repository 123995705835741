import React, { useState, forwardRef, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { momentTimezone } from "@mobiscroll/react";

import { Datepicker as BaseDatepicker } from "@/ui-lib/FormFields/Datepicker";

momentTimezone.moment = moment;

const Datepicker = forwardRef(
  ({ className, onChange, placeholder, select, dateFormat, value, ...rest }, ref) => {
    const [dateValue, setDateValue] = useState(null);

    useEffect(() => {
      if (Array.isArray(value)) {
        setDateValue([value[0], value[1]]);
      } else {
        setDateValue(value ? moment(value).format("YYYY-MM-DD") : null);
      }
    }, [value]);

    return (
      <BaseDatepicker
        className={className}
        value={dateValue}
        dateFormat={dateFormat}
        themeVariant="light"
        controls={["calendar"]}
        theme="ios"
        select={select}
        onChange={(data) => {
          if (Array.isArray(data.value)) {
            setDateValue([
              moment(data.value[0]).format("YYYY-MM-DD"),
              moment(data.value[1]).format("YYYY-MM-DD"),
            ]);
          } else {
            setDateValue(value ? moment(value).format("YYYY-MM-DD") : null);
          }

          onChange(data.value);
        }}
        placeholder={placeholder}
        ref={ref}
        {...rest}
      />
    );
  }
);

Datepicker.defaultProps = {
  className: "",
  placeholder: "",
  value: "",
  endDate: "",
  select: "date",
};

Datepicker.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  select: PropTypes.string,
};

export default Datepicker;
