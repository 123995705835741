import { flattenDeep, omit, orderBy } from "lodash";
import moment from "moment-timezone";

import * as date from "@/utils/date";

import { userSelector } from "../../customers/customers.selectors";

import { MODULE } from "./calendar.actionTypes";

export const isFetchingUsers = (state) => state.getIn([MODULE, "isFetchingUsers"]);
export const isFetchingSchedules = (state) => state.getIn([MODULE, "isFetchingSchedules"]);
export const isFetchingLabels = (state) => state.getIn([MODULE, "isFetchingLabels"]);
export const isCreatingSchedule = (state) => state.getIn([MODULE, "isCreatingSchedule"]);

export const isCalendarLoading = (state) =>
  isFetchingSchedules(state) || isFetchingLabels(state) || isFetchingUsers(state);

export const getUsers = (state) => {
  const users = state.getIn([MODULE, "users"]);
  const currentUser = userSelector(state);

  const formattedUsers = users.map((a) =>
    a.id === currentUser.id ? { ...a, lastName: `${a.lastName} (You)` } : a
  );
  const sortedAssignees = [
    ...(formattedUsers.some((a) => a.id === currentUser.id)
      ? [{ ...formattedUsers.find((a) => a.id === currentUser.id) }]
      : []),
    ...orderBy(
      formattedUsers.filter((a) => a.id !== currentUser.id),
      [(a) => a.firstName.toLowerCase()],
      "asc"
    ),
  ];

  return sortedAssignees;
};

export const getSortedAssignees = (state, assignees) => {
  if (!assignees) return [];

  const currentUser = userSelector(state);

  const formattedAssignees = assignees?.map((a) =>
    a.id === currentUser.id ? { ...a, lastName: `${a.lastName} (You)` } : a
  );
  const sortedAssignees = [
    ...(formattedAssignees?.some((a) => a.id === currentUser.id)
      ? [{ ...formattedAssignees?.find((a) => a.id === currentUser.id) }]
      : []),
    ...orderBy(
      formattedAssignees?.filter((a) => a.id !== currentUser.id),
      [(a) => a.firstName.toLowerCase()],
      "asc"
    ),
  ];

  return sortedAssignees;
};

export const getLabels = (state) => {
  const labels = state.getIn([MODULE, "labels"]);
  return orderBy(labels, [(l) => l.name.toLowerCase()], "asc");
};

export const getSortedAssignedLabels = (labels) => {
  if (!labels) return [];
  return orderBy(labels, [(l) => l.name.toLowerCase()], "asc");
};

export const getSchedules = (state) => state.getIn([MODULE, "schedules"]).filter((s) => !!s.order);

export const getFormattedSchedules = (state) =>
  getSchedules(state).map((d) => {
    const startTime = date.toLocalDateTime({
      date: d.startTime,
      timezone: date.getCurrentCompanyTimezone(),
      format: "YYYY-MM-DDTHH:mm:ssZ",
    });

    const endTime = date.toLocalDateTime({
      date: d.endTime,
      timezone: date.getCurrentCompanyTimezone(),
      format: "YYYY-MM-DDTHH:mm:ssZ",
    });

    return {
      ...omit(d, ["order"]),
      ...omit(d.order, ["uid"]),
      orderUid: d?.order?.uid ?? null,
      startTime,
      start: startTime,
      endTime,
      end: endTime,
    };
  });

export const getSchedulesFilterByUser = (state) =>
  getFormattedSchedules(state).map((s) => ({
    ...s,
    resource: s.user,
    title: `${moment(s.startTime).format("hh:mm A")} ${s.customerName}`,
  }));

export const getSchedulesFilterByLabel = (state) => {
  const flattedSchedules = flattenDeep(
    getFormattedSchedules(state).map(
      (s) =>
        s?.labels?.map((l) => ({
          ...l,
          scheduleUid: s.uid,
        })) ?? []
    )
  );

  const formattedSchedules = flattedSchedules.map((s) => ({
    ...getFormattedSchedules(state).find((f) => f.uid === s.scheduleUid),
    resource: s.uid,
  }));

  return formattedSchedules;
};

export const getFromDate = (state) => state.getIn([MODULE, "fromDate"]);

export const getToDate = (state) => state.getIn([MODULE, "toDate"]);

export const getActiveSchedule = (state) => state.getIn([MODULE, "activeSchedule"]);

export const getCalendarView = (state) => state.getIn([MODULE, "calendarView"]);

export const getSelectedResources = (state) => state.getIn([MODULE, "selectedUsers"]);

export const showCustomEventModal = (state) => state.getIn([MODULE, "showCustomEventModal"]);

export const showDeleteCustomEventWarningModal = (state) =>
  state.getIn([MODULE, "showDeleteCustomEventWarningModal"]);

export const showEditCustomEventWarningModal = (state) =>
  state.getIn([MODULE, "showEditCustomEventWarningModal"]);

export const getDeleteScope = (state) => state.getIn([MODULE, "deleteScope"]);

export const getEditScope = (state) => state.getIn([MODULE, "editScope"]);

export const initialCustomEventData = (state) => state.getIn([MODULE, "initialCustomEventData"]);
