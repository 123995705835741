import { PRODUCT_TYPE_NOTES_ITEM_LABEL } from "@/utils/constants";

import { MODULE } from "./orderAttachments.actionTypes";

export const isFetching = (state) => state.getIn([MODULE, "isFetching"]);
export const isPrinting = (state) => state.getIn([MODULE, "isPrinting"]);
export const orderAttachments = (state) => state.getIn([MODULE, "attachments"]);
export const selectedAttachments = (state) => state.getIn([MODULE, "selectedAttachments"]);
export const getDrawingsList = (state) => state.getIn([MODULE, "drawingsList"]);
export const isProductHasNoPriceAlertOpen = (state) =>
  state.getIn([MODULE, "isProductHasNoPriceAlertOpen"]);
export const isInvoiceEmail = (state) => state.getIn([MODULE, "isInvoiceEmail"]);
export const isInvoicePreview = (state) => state.getIn([MODULE, "isInvoicePreview"]);

export const productOnOrderHasNoPrice = (state) => {
  const drawings = state.getIn(["drawings", "items"]);
  let drawingPrice = false;

  if (drawings) {
    drawings.forEach((drawing) => {
      const key = drawing.get("tempUid");
      const drawingPrices = state.getIn(["prices", "items", key]);
      const totalPrice = drawingPrices.get("totalPrice");
      const customPrice = drawingPrices.get("customPrice");
      const isDeleted = drawingPrices.get("isDeleted");

      if (!isDeleted && totalPrice === "0.00" && (!customPrice || customPrice === "0.00")) {
        drawingPrice = true;
      }
    });
  }

  const additionalProducts = state
    .getIn(["additionalProducts", "items"])
    .toJS()
    .filter((i) => i.productType !== PRODUCT_TYPE_NOTES_ITEM_LABEL);
  let productPrice = false;

  if (additionalProducts) {
    additionalProducts.forEach((item) => {
      const { totalValue, customValue, isDeleted } = item;

      if (!isDeleted && totalValue === "0.00" && !customValue) {
        productPrice = true;
      }
    });
  }

  return productPrice || drawingPrice;
};
