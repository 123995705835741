import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { Datepicker as BaseDatepicker } from "@/ui-lib/FormFields";

import styles from "./Datepicker.module.scss";

const Datepicker = ({
  id,
  containerClassName,
  errors,
  withoutError,
  invalid,
  setFieldValue,
  setFieldTouched,
  ...props
}) => {
  const handleBlur = (e) => {
    const { value } = e;

    setFieldValue(id, value || "");
    setFieldTouched(id, true);
  };

  const error = !!(errors[id] || invalid) && !withoutError;

  return (
    <div
      className={cx(
        styles.inputContainer,
        error && errors[id] && props.touched[id] ? styles.inputError : "",
        containerClassName
      )}
    >
      <BaseDatepicker {...props} onClose={handleBlur} />
      {error && errors[id] && props.touched[id] && (
        <div className={styles.errorMessage}>{errors[id]}</div>
      )}
    </div>
  );
};

Datepicker.defaultProps = {
  setFieldTouched: () => null,
  setFieldValue: () => null,
  onBlur: () => null,
  withoutError: false,
  invalid: false,
  errors: {},
  containerClassName: "",
  className: "",
};

Datepicker.propTypes = {
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  onBlur: PropTypes.func,
  id: PropTypes.string.isRequired,
  withoutError: PropTypes.bool,
  invalid: PropTypes.bool,
  errors: PropTypes.shape({}),
  containerClassName: PropTypes.string,
  className: PropTypes.string,
};

export default Datepicker;
