import { omit } from "lodash";

import {
  SET_SEARCH,
  SET_SEARCH_PRODUCTS,
  SET_IS_FETCHING_CATEGORIES,
  SET_CATEGORIES,
  SET_PRODUCTS,
  SET_IS_FETCHING_PRODUCTS,
  RESET,
  SET_EXPANDED_CATEGORIES,
} from "./productLists.actionTypes";

const initialState = {
  isDuplicatingProducts: false,
  categories: [],
  products: {},
  search: "",
  expandedCategories: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_FETCHING_CATEGORIES: {
      return {
        ...state,
        isFetchingCategories: action.payload,
      };
    }

    case SET_SEARCH: {
      return {
        ...state,
        search: action.payload,
      };
    }

    case SET_SEARCH_PRODUCTS: {
      return {
        ...state,
        products: action.payload,
      };
    }

    case SET_EXPANDED_CATEGORIES: {
      const { data } = action.payload;
      return {
        ...state,
        expandedCategories: data,
      };
    }

    case SET_CATEGORIES: {
      const { data } = action.payload;

      const miscellaneous = data.find((c) => c.name === "Miscellaneous") || [];

      return {
        ...state,
        categories: [
          ...[{ uid: "Supplied Products", name: "Supplied Products", isFetching: false }],
          ...[miscellaneous],
          ...data.filter(
            (category) => !["Miscellaneous", "Supplied Products"].includes(category.name)
          ),
        ],
      };
    }

    case SET_IS_FETCHING_PRODUCTS: {
      const { data } = action.payload;
      return {
        ...state,
        categories: state.categories.map((c) =>
          c.uid === data.uid ? { ...c, isFetching: data.isFetching } : { ...c }
        ),
      };
    }

    case SET_PRODUCTS: {
      const { data, categoryUid } = action.payload;

      if (data.length === 0) {
        return {
          ...state,
          products: omit(state.products, `${categoryUid}`),
        };
      }

      return {
        ...state,
        products: {
          ...state.products,
          [categoryUid]: data,
        },
      };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
