/* eslint-disable no-unused-vars */
import { fromJS } from "immutable";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import ReduxModule from "./abstract/ReduxModule";
import * as materialsApi from "../../api/materials";
import { genId } from "../../utils/common";

import alerts from "./alerts";

// TODO: need refactoring

const ACTIONS = {
  SEARCH_MATERIALS: "Get all materials",
  GET_MATERIAL: "Get single material",
  DUPLICATE_MATERIAL: "Duplicate material",
  CREATE_NEW_MATERIAL: "Create new material",

  ARCHIVE_MATERIAL: "Archive material",
  UNARCHIVE_MATERIAL: "Unarchive material",

  SUBMIT_MATERIAL: "SUBMIT_MATERIAL",
};

class MaterialsModule extends ReduxModule {
  getNamespace() {
    return "[Materials]";
  }

  getInitialState() {
    return {
      activeMaterial: null,
      dataServer: {
        isPending: false,
        items: [],
        itemsHash: {},
      },
      isPending: false,
      isSubmitting: false,
      isDuplicating: false,
    };
  }

  searchMaterialsThunk({ token, fulfilled }, query, withArchived) {
    return materialsApi
      .searchMaterials(token, { search: query, with_archived: withArchived })
      .then((res) => {
        const data = camelcaseKeys(res.data);
        fulfilled(data);
        return Promise.resolve(data);
      });
  }

  getMaterialThunk({ getState, token, fulfilled }, uid) {
    const itemsHash = getState().getIn([
      "materials",
      "dataServer",
      "itemsHash",
    ]);
    const itemIndex = itemsHash.get(uid);

    if (itemIndex || itemIndex === 0) {
      const items = getState().getIn(["materials", "dataServer", "items"]);
      const item = items.get(itemIndex);
      fulfilled(item);
      return Promise.resolve(item.toJS());
    }

    return materialsApi.getMaterial(token, uid).then((res) => {
      const item = camelcaseKeys(res.data);

      fulfilled(item);
      return Promise.resolve(item);
    });
  }

  duplicateMaterial = ({ dispatch, fulfilled, token }, uid) => {
    return materialsApi
      .duplicateMaterial(token, uid)
      .then(() => {
        fulfilled();
        dispatch(
          alerts.actions.addAlert({
            type: "success",
            message: "Material duplicated.",
            closeDelay: 5000,
          })
        );
      })
      .catch((e) => {
        dispatch(
          alerts.actions.addAlert({
            type: "danger",
            message: "Can't duplicate material",
            closeDelay: 5000,
          })
        );
      });
  };

  archiveMaterial = (
    { getState, dispatch, fulfilled, token },
    uid,
    material
  ) => {
    if (material) {
      const data = snakecaseKeys({
        ...material,
        isDeleted: true,
      });
      return materialsApi.editMaterial(token, data);
    }
    const materials = getState().get("materials");
    const itemsHash = materials.getIn(["dataServer", "itemsHash"]);
    const itemIndex = itemsHash.get(uid.toString());
    const item = materials.getIn(["dataServer", "items", itemIndex]).toJS();
    const data = snakecaseKeys({
      ...item,
      isDeleted: true,
    });

    materialsApi
      .editMaterial(token, data)
      .then(() => {
        fulfilled(itemIndex);
        dispatch(
          alerts.actions.addAlert({
            type: "danger",
            message: `${item.name} material archived`,
            action: {
              label: "Undo",
              callback: () => {
                dispatch(this.actions.unarchiveMaterial(uid));
              },
            },
            closeDelay: 5000,
          })
        );
      })
      .catch((e) => {
        dispatch(
          alerts.actions.addAlert({
            type: "danger",
            message: `Can't archive material ${item.name}`,
          })
        );
      });
    return Promise.resolve(itemIndex);
  };

  unarchiveMaterial({ getState, fulfilled, dispatch, token }, uid, material) {
    if (material) {
      const data = snakecaseKeys({
        ...material,
        isDeleted: false,
      });
      return materialsApi.editMaterial(token, data);
    }
    const materials = getState().get("materials");
    const itemsHash = materials.getIn(["dataServer", "itemsHash"]);
    const itemIndex = itemsHash.get(uid.toString());
    const item = materials.getIn(["dataServer", "items", itemIndex]).toJS();
    const data = snakecaseKeys({
      ...item,
      isDeleted: false,
    });

    materialsApi
      .editMaterial(token, data)
      .then(() => {
        fulfilled(itemIndex);
      })
      .catch((e) => {
        dispatch(
          alerts.actions.addAlert({
            type: "danger",
            message: `Can't unarchive material ${item.name}`,
          })
        );
      });
    return Promise.resolve(itemIndex);
  }

  // ? deprecated?

  createMaterial = ({ fulfilled }, materialName) => {
    const material = {
      uid: genId(),
      name: materialName,
      colours: [],
    };

    fulfilled(material);
  };

  submitMaterialThunk = ({ dispatch, fulfilled, token }, material) => {
    const data = snakecaseKeys(material);
    // * if material has uid, post material
    if (material.uid && material.name) {
      return materialsApi
        .editMaterial(token, data)
        .then((res) => {
          fulfilled(material);
          return Promise.resolve(res.data.uid);
        })
        .catch((e) => {
          dispatch(
            alerts.actions.addAlert({
              type: "danger",
              message: "Materials was not save",
            })
          );
        });
    }
    // * else patch it
    if (material.name) {
      return materialsApi.createMaterial(token, data).then((res) => {
        fulfilled(res.data);
        return Promise.resolve(res.data);
      });
    }

    return Promise.resolve("");
  };

  defineActions() {
    const searchMaterials = this.thunkAction(
      ACTIONS.SEARCH_MATERIALS,
      this.searchMaterialsThunk,
      true
    );
    const getMaterial = this.thunkAction(
      ACTIONS.GET_MATERIAL,
      this.getMaterialThunk,
      true
    );
    const archiveMaterial = this.thunkAction(
      ACTIONS.ARCHIVE_MATERIAL,
      this.archiveMaterial
    );
    const duplicateMaterial = this.thunkAction(
      ACTIONS.DUPLICATE_MATERIAL,
      this.duplicateMaterial,
      true
    );
    const unarchiveMaterial = this.thunkAction(
      ACTIONS.UNARCHIVE_MATERIAL,
      this.unarchiveMaterial
    );
    const createMaterial = this.thunkAction(
      ACTIONS.CREATE_NEW_MATERIAL,
      this.createMaterial
    );
    const submitMaterial = this.thunkAction(
      ACTIONS.SUBMIT_MATERIAL,
      this.submitMaterialThunk,
      true
    );

    return {
      searchMaterials,
      getMaterial,
      archiveMaterial,
      unarchiveMaterial,
      createMaterial,
      submitMaterial,
      duplicateMaterial,
    };
  }

  searchMaterials(_state, { payload: items }) {
    const itemsHash = {};
    items.forEach((item, i) => {
      const { uid } = item;
      itemsHash[uid] = i;
    });
    return _state
      .setIn(["dataServer", "items"], fromJS(items))
      .setIn(["dataServer", "itemsHash"], fromJS(itemsHash));
  }

  createMaterialReducer = (_state, { payload: material }) => {
    const newIndex = _state.getIn(["dataServer", "items"]).size;
    return _state
      .updateIn(["dataServer", "items"], (items) =>
        items.push(fromJS(material))
      )
      .setIn(["dataServer", "itemsHash", material.id], newIndex);
  };

  defineReducers() {
    return {
      [`${ACTIONS.SEARCH_MATERIALS} pending`]: this.thunkPendingReducer([
        "dataServer",
        "isPending",
      ]),
      [`${ACTIONS.SEARCH_MATERIALS} fulfilled`]: this.searchMaterials,

      [`${ACTIONS.GET_MATERIAL} pending`]: this.thunkPendingReducer([
        "isPending",
      ]),
      [`${ACTIONS.GET_MATERIAL} fulfilled`]: (state, { payload: item }) =>
        state.set("activeMaterial", fromJS(item)),

      [`${ACTIONS.ARCHIVE_MATERIAL} fulfilled`]: (state, { payload: index }) =>
        state.setIn(["dataServer", "items", index, "isDeleted"], true),

      [`${ACTIONS.UNARCHIVE_MATERIAL} fulfilled`]: (
        state,
        { payload: index }
      ) => state.setIn(["dataServer", "items", index, "isDeleted"], false),

      [`${ACTIONS.SUBMIT_MATERIAL} pending`]: this.thunkPendingReducer([
        "isSubmitting",
      ]),

      [`${ACTIONS.CREATE_NEW_MATERIAL} fulfilled`]: this.createMaterialReducer,

      [`${ACTIONS.DUPLICATE_MATERIAL} pending`]: this.thunkPendingReducer([
        "isDuplicating",
      ]),
    };
  }
}

const instance = new MaterialsModule();
instance.init();

export default instance;
