import produce from "immer";

import setKeypathValue from "../utils/setKeypathValue";

import {
  SEARCH_SUPPLIERS,
  GET_SUPPLIER,
  REMOVE_ACTIVE_SUPPLIER,
  GET_ALL_SUPPLIER_CONTACTS,
  SET_SUPPLIER_CONTACTS,
  CREATE_SUPPLIER_CONTACT,
  UPDATE_SUPPLIER_CONTACT,
  DELETE_SUPPLIER_CONTACT,
  UPDATE_SUPPLIER,
  GET_ALL_SUPPLIER_PURCHASE_ORDERS,
  DELETE_SUPPLIER,
  UPDATE_SUPPLIER_PURCHASE_ORDER,
  BULK_DELETE_SUPPLIER,
  DELETE_SUPPLIER_PURCHASE_ORDER,
  BULK_DELETE_SUPPLIER_PURCHASE_ORDERS,
  EDIT_SUPPLIER_PURCHASE_ORDER_FILTERS,
  RESET_SUPPLIER_PURCHASE_ORDERS,
  REMOVE_ACTIVE_SUPPLIER_CONTACTS,
  SET_IS_DUPLICATING,
  SET_IS_DUPLICATE_CONFIRMATION_ALERT_OPEN,
  SET_DUPLICATE_ORDER_UID,
} from "./suppliers.actionTypes";

const initialState = {
  suppliers: [],
  createdSupplier: null,
  activeSupplier: null,
  activeSupplierContacts: [],
  activeSupplierPurchaseOrders: [],
  recentlyDeletedSupplierPurchaseOrderUid: null,
  recentlyBulkDeletedSupplierPurchaseOrderUids: [],
  recentlyDeletedSupplierUid: null,
  recentlyBulkDeletedSupplierUids: [],
  filters: {
    search: "",
    startDate: null,
    endDate: null,
    statuses: ["all", "draft", "submitted", "partially Received", "received", "billed"],
  },
  duplicateOrderUid: null,
  isDuplicateConfirmationAlertOpen: false,
  isDuplicating: false,
  selectedContacts: [],
  contactsToEmail: [],
  contacts: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case `${SEARCH_SUPPLIERS}/fulfilled`: {
      const { data } = action.payload;

      return {
        ...state,
        suppliers: data,
      };
    }

    // GET SUPPLIER //
    case `${GET_SUPPLIER}/fulfilled`: {
      const { data } = action.payload;

      return {
        ...state,
        activeSupplier: data,
      };
    }

    // UPDATE SUPPLIER //
    case `${UPDATE_SUPPLIER}/fulfilled`: {
      const { data } = action.payload;

      // Update entry in suppliers list (if exists)
      const supplierUid = data.uid;
      const newSuppliers = [...state.suppliers];

      const supplierIndex = newSuppliers.findIndex((supplier) => supplier.uid === supplierUid);

      newSuppliers[supplierIndex] = data;

      // Update entry in active supplier
      const newActiveSupplier = data;

      return {
        ...state,
        suppliers: newSuppliers,
        activeSupplier: newActiveSupplier,
      };
    }

    // DELETE SUPPLIER //
    case `${DELETE_SUPPLIER}/fulfilled`: {
      const { data } = action.payload;

      const deletedSupplierUid = data.uid;

      // Find the index of deleted supplier
      const deletedIndex = [...state.suppliers].findIndex(
        (supplier) => deletedSupplierUid === supplier.uid
      );

      // Determine if showing archive
      const showArchive = state.filters.statuses.includes("archived");

      return produce(state, (draft) => {
        draft.recentlyDeletedSupplierUid = deletedSupplierUid;

        if (showArchive) {
          // Set is deleted to true
          draft.suppliers[deletedIndex] = {
            ...draft.suppliers[deletedIndex],
            isDeleted: true,
          };
        } else {
          // Remove supplier from list
          draft.suppliers = [...draft.suppliers].filter((_, index) => index !== deletedIndex);
        }
      });
    }

    // BULK DELETE SUPPLIERS //
    case `${BULK_DELETE_SUPPLIER}/fulfilled`: {
      const { data } = action.payload;

      const deletedSupplierUids = data.uids;

      const deletedIndexes = [];

      // Construct indexes for deleted purchase orders
      deletedSupplierUids.forEach((deletedSupplierUid) => {
        const deletedIndex = state.suppliers.findIndex(
          (supplier) => supplier.uid === deletedSupplierUid
        );
        deletedIndexes.push(deletedIndex);
      });

      // Determine if showing archive
      const showArchive = state.filters.statuses.includes("archived");

      return produce(state, (draft) => {
        draft.recentlyBulkDeletedSupplierUids = deletedSupplierUids;

        if (showArchive) {
          // Set is deleted to true
          deletedIndexes.forEach((deletedIndex) => {
            draft.suppliers[deletedIndex] = {
              ...draft.suppliers[deletedIndex],
              isDeleted: true,
            };
          });
        } else {
          // Remove purchase order from the list
          draft.suppliers = draft.suppliers.filter(
            (supplier, index) => !deletedSupplierUids.includes(supplier.uid)
          );
        }
      });
    }

    // REMOVE ACTIVE SUPPLIER //
    case REMOVE_ACTIVE_SUPPLIER: {
      return { ...state, activeSupplier: null, activeSupplierContacts: [] };
    }

    case REMOVE_ACTIVE_SUPPLIER_CONTACTS: {
      return { ...state, activeSupplierContacts: [] };
    }

    // GET ALL SUPPLIER CONTACTS //
    case `${GET_ALL_SUPPLIER_CONTACTS}/fulfilled`: {
      const { data } = action.payload;

      return {
        ...state,
        activeSupplierContacts: data,
      };
    }

    case SET_SUPPLIER_CONTACTS: {
      return {
        ...state,
        activeSupplierContacts: action.payload,
      };
    }

    // CREATE SUPPLIER CONTACT //
    case `${CREATE_SUPPLIER_CONTACT}/fulfilled`: {
      const { data } = action.payload;

      const newActiveSupplierContacts = [data, ...state.activeSupplierContacts];

      return {
        ...state,
        activeSupplierContacts: newActiveSupplierContacts,
      };
    }

    // UPDATE SUPPLIER CONTACT //
    case `${UPDATE_SUPPLIER_CONTACT}/fulfilled`: {
      const { data } = action.payload;

      const contactUid = data.uid;
      const newContacts = [...state.activeSupplierContacts];

      const existingContactIndex = newContacts.findIndex((contact) => contact.uid === contactUid);

      newContacts[existingContactIndex] = data;

      return {
        ...state,
        activeSupplierContacts: newContacts,
      };
    }

    // DELETE SUPPLIER CONTACT //
    case `${DELETE_SUPPLIER_CONTACT}/fulfilled`: {
      const { data } = action.payload;

      const deletedContactUid = data.uid;
      const newContacts = [...state.activeSupplierContacts].filter(
        (contact) => contact.uid !== deletedContactUid
      );

      return {
        ...state,
        activeSupplierContacts: newContacts,
        recentlyDeletedSupplierContactUid: deletedContactUid,
      };
    }

    // GET ALL SUPPLIER PURCHASE ORDERS //
    case `${GET_ALL_SUPPLIER_PURCHASE_ORDERS}/fulfilled`: {
      const { data } = action.payload;

      return {
        ...state,
        activeSupplierPurchaseOrders: data,
      };
    }

    // DELETE SUPPLIER PURCHASE ORDER //
    case `${DELETE_SUPPLIER_PURCHASE_ORDER}/fulfilled`: {
      const { data } = action.payload;

      const deletedPurchaseOrderUid = data.uid;

      // Find the index of deleted purchase order
      const deletedIndex = [...state.activeSupplierPurchaseOrders.results].findIndex(
        (purchaseOrder) => purchaseOrder.uid === deletedPurchaseOrderUid
      );

      // Determine if showing archive
      const showArchive = state.filters.statuses.includes("archived");

      return produce(state, (draft) => {
        draft.recentlyDeletedSupplierPurchaseOrderUid = deletedPurchaseOrderUid;

        if (showArchive) {
          // Set is deleted to true
          draft.activeSupplierPurchaseOrders.results[deletedIndex] = {
            ...draft.activeSupplierPurchaseOrders.results[deletedIndex],
            isDeleted: true,
          };
        } else {
          // Remove purchase order from list
          draft.activeSupplierPurchaseOrders.results = [
            ...draft.activeSupplierPurchaseOrders.results,
          ].filter((_, index) => index !== deletedIndex);
        }
      });
    }

    // BULK DELETE SUPPLIER PURCHASE ORDERS //
    case `${BULK_DELETE_SUPPLIER_PURCHASE_ORDERS}/fulfilled`: {
      const { data } = action.payload;

      const deletedPurchaseOrderUids = data.uids;

      const deletedIndexes = [];

      // Contruct indexes for deleted purchase orders
      deletedPurchaseOrderUids.forEach((deletedPurchaseOrderUid) => {
        const deletedIndex = state.activeSupplierPurchaseOrders.results.findIndex(
          (purchaseOrder) => purchaseOrder.uid === deletedPurchaseOrderUid
        );
        deletedIndexes.push(deletedIndex);
      });

      // Determine if showing archive
      const showArchive = state.filters.statuses.includes("archived");

      return produce(state, (draft) => {
        draft.recentlyBulkDeletedSupplierPurchaseOrderUids = deletedPurchaseOrderUids;

        if (showArchive) {
          // Set is deleted to true
          deletedIndexes.forEach((deletedIndex) => {
            draft.activeSupplierPurchaseOrders.results[deletedIndex] = {
              ...draft.activeSupplierPurchaseOrders.results[deletedIndex],
              isDeleted: true,
            };
          });
        } else {
          // Remove purchase order from the list
          draft.activeSupplierPurchaseOrders.results =
            draft.activeSupplierPurchaseOrders.results.filter(
              (purchaseOrder, index) => !deletedPurchaseOrderUids.includes(purchaseOrder.uid)
            );
        }
      });
    }

    // UPDATE SUPPLIER PURCHASE ORDER //
    case `${UPDATE_SUPPLIER_PURCHASE_ORDER}/fulfilled`: {
      const { data } = action.payload;

      const updatedPurchaseOrderUid = data.uid;

      // Find the index of deleted purchase order
      const deletedIndex = [...state.activeSupplierPurchaseOrders].findIndex(
        (purchaseOrder) => purchaseOrder.uid === updatedPurchaseOrderUid
      );

      return produce(state, (draft) => {
        draft.activeSupplierPurchaseOrders.results[deletedIndex] = data;
      });
    }

    // EDIT FILTERS //
    case EDIT_SUPPLIER_PURCHASE_ORDER_FILTERS: {
      const { path, value } = action.payload;

      return produce(state, (draft) => {
        setKeypathValue(draft, ["filters", ...path], value);
      });
    }

    case SET_IS_DUPLICATING: {
      return {
        ...state,
        isDuplicating: action.payload,
      };
    }

    case SET_DUPLICATE_ORDER_UID: {
      return {
        ...state,
        duplicateOrderUid: action.payload,
      };
    }

    case SET_IS_DUPLICATE_CONFIRMATION_ALERT_OPEN: {
      return {
        ...state,
        isDuplicateConfirmationAlertOpen: action.payload,
      };
    }

    // RESET SUPPLIER PURCHASE ORDERS //
    case RESET_SUPPLIER_PURCHASE_ORDERS: {
      return { ...state, activeSupplierPurchaseOrders: [] };
    }

    default:
      return state;
  }
};
