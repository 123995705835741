const SUPPLIER = "factory_supplier";
const CUSTOMER = "factory_customer";
const SALES = "factory_sales_order";
const PRODUCT = "factory_product";
const PURCHASES = "factory_purchase_order";
const FLASHINGS = "factory_flashing";
const PRODUCT_KIT = "factory_product_kit";
const MATERIAL = "factory_material";
const ALL = "all";

export const types = {
  SUPPLIER,
  CUSTOMER,
  SALES,
  PRODUCT,
  PURCHASES,
  FLASHINGS,
  PRODUCT_KIT,
  MATERIAL,
  ALL,
};

export default {};
