import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentQuote, faClose } from "@fortawesome/pro-solid-svg-icons";
import { faCalendarLines } from "@fortawesome/pro-duotone-svg-icons";

import reduxConnect from "@/redux/utils/connect";
import { playBellAudio } from "@/utils/sounds";
import * as date from "@/utils/date";
import { FORMAT_DATE_MIDDLE } from "@/utils/constants";

import styles from "./Notification.module.scss";

const Notification = ({ closeToast = () => null, message = {}, history, actions }) => {
  useEffect(() => {
    playBellAudio();
  }, []);

  const redirectTo = () => () => {
    actions.websockets.markMessageAsRead(message);

    if (message?.isCustomEvent) {
      history.push(`/planner?eventUid=${message.eventUid}`);
    } else if (message.orderId) {
      actions.collaborationNotes.setTransitionByNotificationStatus(true);
      history.push(`/customer/${message.customerId}/order/${message.orderId}`);
    } else if (message.purchaseOrderId) {
      actions.purchase.setTransitionByNotificationStatus(true);

      history.push(
        `/suppliers/${message.supplierId}/purchase-order/${message.purchaseOrderId}?potab=collaborate`
      );
    } else if (message.supplierUid) {
      history.push(`/suppliers/${message.supplierUid}?tab=collaborate`);
    } else if (message.customerUid) {
      history.push(`/customer/${message.customerUid}?tab=collaborate`);
    } else if (message.productUid) {
      history.push(`/products/${message.productUid}?tab=collaborate`);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content} onClick={redirectTo(message)} aria-hidden>
        {message?.isCustomEvent ? (
          <>
            <div className={styles.notificationCalendarIconWrapper}>
              <FontAwesomeIcon icon={faCalendarLines} className={styles.calendaerIcon} />
            </div>
            <div className={styles.contentMessage}>
              <div>
                <span className={styles.bold}>{message.title}</span> is due to start at{" "}
                {date.toLocalDateTime({
                  date: message.start,
                  timezone: date.getCurrentUserTimezone(),
                  format: FORMAT_DATE_MIDDLE,
                })}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.notificationIconWrapper}>
              <div className={styles.iconWhiteBg} />
              <FontAwesomeIcon icon={faCommentQuote} className={styles.quoteIcon} />
            </div>
            <div className={styles.contentMessage}>
              <div className={styles.initialMessage}>
                <span className={styles.bold}>{message.authorFirstName}</span> mentioned you in a
                message
              </div>
              {message.supplierName ? (
                <div className={styles.bold}>{message.supplierName}</div>
              ) : null}
              {message.customerName ? (
                <div className={styles.bold}>{message.customerName}</div>
              ) : null}

              {message.orderId ? (
                <>
                  {message.orderNumber ? (
                    <div>
                      <span className={styles.bold}>Order </span>#{message.orderNumber}
                    </div>
                  ) : null}

                  {message.reference ? (
                    <div>
                      <span className={styles.bold}>PO </span>
                      {message.reference}
                    </div>
                  ) : null}
                </>
              ) : null}

              {message.purchaseOrderId ? (
                <>
                  {message.purchaseOrderNumber ? (
                    <div>
                      <span className={styles.bold}>Purchase Order </span>#
                      {message.purchaseOrderNumber}
                    </div>
                  ) : null}
                  {message.reference ? (
                    <div>
                      <span className={styles.bold}>Reference </span>
                      {message.reference}
                    </div>
                  ) : null}
                </>
              ) : null}

              {message.productName ? (
                <div>
                  <span className={styles.bold}>Product </span>
                  {message.productName}
                </div>
              ) : null}
            </div>
          </>
        )}
      </div>

      <div>
        <FontAwesomeIcon onClick={closeToast} icon={faClose} className={styles.closeIcon} />
      </div>
    </div>
  );
};

Notification.propTypes = {
  closeToast: PropTypes.func,
  toastProps: PropTypes.shape({}),
  message: PropTypes.shape({}),
  history: PropTypes.shape({}),
  actions: PropTypes.shape({}),
};

export default withRouter(reduxConnect({})(Notification));
