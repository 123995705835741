import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { debounce, isEqual } from "lodash";
import { Dropdown, DropdownMenu, DropdownToggle, DropdownItem, CustomInput } from "reactstrap";
import { nanoid } from "nanoid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-duotone-svg-icons";
import { faTimes, faCaretDown } from "@fortawesome/free-solid-svg-icons";

import Checkbox from "../Checkbox/Checkbox";
import "./SelectCheckbox.scss";

export const SelectCheckbox = ({
  label,
  value,
  onChange,
  options,
  withChips,
  inputPlaceHolder,
  emptyPlaceholder,
  className,
  inputClassName,
  dropdownMenuClassName,
  noneSelectedText,
  selectedText,
  singleSelect,
  centered,
  rtl,
  toggleElement,
  searchable,
  onClose,
  optionContainer,
  showCount,
  customDropdownicon,
  dropdownMenuPositionFixed,
  shouldSortOnCheck,
}) => {
  const [dropdownOptions, setDropdownOptions] = useState(options);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [previousSelectedItems, setPreviousSelectedItems] = useState([]);

  useEffect(() => {
    if (search === "" && searchable) {
      setDropdownOptions(options);
    }

    if (!searchable) {
      setDropdownOptions(options);
    }
  }, [options, search]);

  const alwaysOnTopItems = dropdownOptions.filter((option) => option.isAlwaysOnTop);

  const selectedItems = dropdownOptions.filter(
    (option) =>
      (value.includes(option.value) || option.isSelected) &&
      option.value !== "" &&
      !option.isAlwaysOnTop
  );

  const notSelectedItems = dropdownOptions.filter(
    (option) => !option.isAlwaysOnTop && option.value !== "" && !value.includes(option.value)
  );

  const toggle = () => {
    if (!dropdownOpen) {
      setPreviousSelectedItems(value);
    }

    if (dropdownOpen && onClose) {
      // This will prevent from calling the `onClose` function
      // if there is no changes has been made
      if (!isEqual(previousSelectedItems, value)) {
        onClose(value);
      }
    }

    setDropdownOpen((prevState) => !prevState);
  };

  const selectItemHandler = (option) => {
    onChange(option.value);
  };

  const renderChip = (option) => {
    if (option.disableChip !== true) {
      return (
        <div
          key={option.label}
          onClick={() => selectItemHandler(option)}
          className="select-checkbox__chip"
          aria-hidden
        >
          <div>{option.label}</div>
          <FontAwesomeIcon className="select-checkbox__chip__icon" icon={faTimes} />
        </div>
      );
    }

    return <React.Fragment />;
  };

  const renderText = () => {
    const showLabelItem = selectedItems.find((selectedItem) => selectedItem.showLabel === true);

    if (showLabelItem) {
      return showLabelItem.label;
    }

    if (singleSelect) {
      return noneSelectedText;
    }

    if (!showCount) {
      return selectedText;
    }

    if (value.length > 0) {
      return `${value.length} ${selectedText}`;
    }

    return noneSelectedText;
  };

  const debounceSearch = useCallback(
    debounce((query) => {
      if (query !== "") {
        setDropdownOptions(
          options.filter((o) => o.label.toLowerCase().includes(query.toLowerCase()))
        );
      }
    }, 500),
    [dropdownOptions]
  );

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} key="click">
      <div className="select-checkbox__row">
        <div className={cx("select-checkbox", className)}>
          {label && (
            <label className="select-checkbox__label" htmlFor={className}>
              {label}
            </label>
          )}
          {toggleElement ? (
            <DropdownToggle tag="div" className="select-checkbox__custom-toggle">
              {toggleElement}
            </DropdownToggle>
          ) : (
            <DropdownToggle
              tag="div"
              className={cx("select-checkbox__select", inputClassName, {
                "select-checkbox__select--centered": centered,
              })}
            >
              <div className={cx({ "select-checkbox__select__value": centered })}>
                {renderText()}
              </div>
              <FontAwesomeIcon
                icon={customDropdownicon || faCaretDown}
                className="select-checkbox__arrow-down"
              />
            </DropdownToggle>
          )}
        </div>
        <div className="select-checkbox__chip-container">
          {value && withChips && selectedItems.map((option) => renderChip(option))}
        </div>
      </div>

      <DropdownMenu
        className={cx("select-checkbox__select__menu", dropdownMenuClassName)}
        positionFixed={dropdownMenuPositionFixed}
      >
        {searchable && (
          <div className="select-checkbox__search-wrapper">
            <div className="select-checkbox__search">
              <FontAwesomeIcon icon={faSearch} className="select-checkbox__search--icon" />
              <CustomInput
                type="text"
                id={label || "custom-search-input"}
                defaultValue={search}
                placeholder={inputPlaceHolder}
                className="select-checkbox__search--input"
                onChange={(e) => {
                  setSearch(e.target.value);
                  debounceSearch(e.target.value);
                }}
              />
            </div>
          </div>
        )}
        {dropdownOptions.length === 0 && (
          <div className="select-checkbox__select__item" onClick={toggle} aria-hidden>
            <div>{emptyPlaceholder}</div>
          </div>
        )}
        {dropdownOpen &&
          (shouldSortOnCheck
            ? [...alwaysOnTopItems, ...selectedItems, ...notSelectedItems]
            : dropdownOptions
          ).map((item) => (
            <DropdownItem
              key={`${item.value}${item.label}`}
              toggle={false}
              className={cx("select-checkbox__select__item", {
                "select-checkbox__select__rightToLeft": rtl,
              })}
              onClick={() => selectItemHandler(item)}
            >
              {optionContainer(item) ? (
                optionContainer(item)
              ) : (
                <div className="select-checkbox__select__item-label">{item.label}</div>
              )}
              <Checkbox
                checked={
                  selectedItems.find((selectedItem) => selectedItem.value === item.value) ||
                  item.isSelected
                }
                onCheck={() => selectItemHandler(item)}
                onUncheck={() => selectItemHandler(item)}
              />
            </DropdownItem>
          ))}
      </DropdownMenu>
    </Dropdown>
  );
};

SelectCheckbox.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})),
  label: PropTypes.string,
  withChips: PropTypes.bool,
  singleSelect: PropTypes.bool,
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onChange: PropTypes.func,
  inputPlaceHolder: PropTypes.string,
  emptyPlaceholder: PropTypes.string,
  inputClassName: PropTypes.string,
  dropdownMenuClassName: PropTypes.string,
  noneSelectedText: PropTypes.string,
  selectedText: PropTypes.string,
  centered: PropTypes.bool,
  rtl: PropTypes.bool,
  toggleElement: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.number]),
  searchable: PropTypes.bool,
  onClose: PropTypes.func,
  optionContainer: PropTypes.func,
  showCount: PropTypes.bool,
  customDropdownicon: PropTypes.node,
  dropdownMenuPositionFixed: PropTypes.bool,
  shouldSortOnCheck: PropTypes.bool,
};

SelectCheckbox.defaultProps = {
  label: "",
  value: null,
  onChange: () => null,
  options: [],
  withChips: false,
  inputPlaceHolder: "",
  emptyPlaceholder: "No options...",
  className: "",
  inputClassName: "",
  dropdownMenuClassName: "",
  noneSelectedText: "Select",
  selectedText: "Selected",
  singleSelect: false,
  centered: false,
  rtl: false,
  toggleElement: null,
  searchable: false,
  onClose: () => {},
  optionContainer: () => null,
  showCount: true,
  customDropdownicon: null,
  dropdownMenuPositionFixed: false,
  shouldSortOnCheck: false,
};

export default SelectCheckbox;
