import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import styles from "./Spinner.module.scss";

const Spinner = ({ style, className }) => (
  <div className={cx(styles.spinner, className)} style={style}>
    <div style={style} />
    <div style={style} />
  </div>
);

Spinner.propTypes = {
  style: PropTypes.shape({}).isRequired,
  className: PropTypes.string.isRequired,
};

export default Spinner;
