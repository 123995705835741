import { fromJS } from "immutable";
import * as drawingHelpers from "./drawing";

/* WRAPPERS FOR REDUCERS */

/* Generic wrapper, make state mutable & unpack payload from action */
export const withMutations =
  (fn) =>
  (state, { payload }) =>
    state.withMutations((_state) => {
      fn(_state, payload);
    });

/* Auto-add current state to history */
export const withHistory = (fn) => (_state, payload) => {
  fn(_state, payload);
  const activeDrawing = _state.get("activeDrawing");
  const currentState = activeDrawing.get("canvasState");
  const historyPosition = activeDrawing.get("historyPosition") + 1;
  _state.updateIn(["activeDrawing", "history"], (history) =>
    history.slice(0, historyPosition).push(currentState)
  );
  _state.setIn(["activeDrawing", "historyPosition"], historyPosition);
};

/* Wrapper for operations with points, autoupdate linesData and price */
export const withPoints = (fn, skipUpdates) => (_state, payload) => {
  let canvasState = _state.getIn(["activeDrawing", "canvasState"]).toJS();
  try {
    canvasState = fn(canvasState, {
      ...payload,
      isFreeDrawing: _state.getIn(["activeDrawing", "isFreeDrawing"]),
    });
  } catch (e) {
    console.warn("Error in workplace::withPoints()");
    console.error(e);
  }
  _state.setIn(["activeDrawing", "canvasState"], fromJS(canvasState));
  _state.setIn(["activeDrawing", "hasChanges"], true);
  if (!skipUpdates) {
    drawingHelpers.updateLinesData(_state);
  }
};

/* Wrapper for operations with front arrow */
export const withFrontArrow = (fn) => (_state, payload) => {
  const activeDrawing = _state.get("activeDrawing");
  let frontArrow = activeDrawing.getIn(["canvasState", "frontArrow"]).toJS();
  try {
    frontArrow = fn(frontArrow, payload);
  } catch (e) {
    console.warn("Error in workplace::withFrontArrow()");
    console.error(e);
  }
  _state.setIn(
    ["activeDrawing", "canvasState", "frontArrow"],
    fromJS(frontArrow)
  );
  _state.setIn(["activeDrawing", "hasChanges"], true);
};

export const fullArrowReducer = (fn) =>
  withMutations(withHistory(withFrontArrow(fn)));
