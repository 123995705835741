import { MODULE } from "./timezones.actionTypes";

export const getTimezones = (state) => {
  const timezones = state.getIn([MODULE, "timezones"]);

  return [
    ...timezones.filter((t) => t.name.includes("Australia")),
    ...timezones.filter((t) => !t.name.includes("Australia")),
  ].filter((t) => t.name !== "UTC");
};

export default {};
