import React from "react";
import PropTypes from "prop-types";

import { Spinner } from "@/ui-lib/Spinner";

const Loading = ({ size, spinnerClassName, color, className }) => {
  let loadingClassName = "d-flex justify-content-center align-items-center";
  loadingClassName += className ? ` ${className}` : "";

  return (
    <div className={loadingClassName}>
      <Spinner
        style={{ width: `${size}rem`, height: `${size}rem` }}
        className={spinnerClassName}
        color={color}
      />
    </div>
  );
};

Loading.defaultProps = {
  spinnerClassName: "",
  size: 3.2,
  color: "primary",
  className: "",
};

Loading.propTypes = {
  spinnerClassName: PropTypes.string,
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
};

export default Loading;
