import { nanoid } from "nanoid";

import * as teamsApi from "@/apiv2/teams";
import * as billingApi from "@/apiv2/billing";

import * as alertActions from "../../alerts/alerts.actions";
import { handleError } from "../../utils/error";

import {
  SET_IS_SAVING,
  SET_IS_FETCHING,
  SET_LISTS,
  SET_ACTIVE_TEAM_MEMBER,
  SET_INVITE_LINK,
  SET_IS_FETCHING_INVITE_LINK,
  SET_IS_SENDING_INVITE,
  RESET,
} from "./teams.actionTypes";
import * as selectors from "./teams.selectors";

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setIsSaving = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_SAVING,
    payload: data,
  });
};

export const setIsFetching = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING,
    payload: data,
  });
};

export const setLists = (data) => (dispatch) => {
  dispatch({
    type: SET_LISTS,
    payload: data,
  });
};

export const setActiveTeamMember = (data) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_TEAM_MEMBER,
    payload: data,
  });
};

export const setInviteLink = (data) => (dispatch) => {
  dispatch({
    type: SET_INVITE_LINK,
    payload: data,
  });
};

export const setIsFetchingInviteLink = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_INVITE_LINK,
    payload: data,
  });
};

export const setIsSendingInvite = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_SENDING_INVITE,
    payload: data,
  });
};

export const fetchTeams = (params) => async (dispatch) => {
  try {
    dispatch(setIsFetching(true));

    const { data } = await teamsApi.getTeams(params)();
    dispatch(setLists(data));
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetching(false));
  }
};

export const createTeamMember = (data) => async (dispatch) => {
  try {
    dispatch(setIsSaving(true));

    await teamsApi.postTeams({
      ...data,
    })();
  } catch (error) {
    if (error.response.data?.username) {
      dispatch(
        alertActions.createAlertAction(nanoid(), error.response.data?.username, true, "danger")
      );

      throw new Error(JSON.stringify(error.response.data?.username));
    } else {
      handleError(error, dispatch);
    }
  } finally {
    dispatch(setIsSaving(false));
  }
};

export const updateTeamMember = (data) => async (dispatch, getState) => {
  try {
    const state = getState();
    const teams = selectors.getLists(state);
    dispatch(setIsSaving(true));

    await teamsApi.putTeam(data)();

    dispatch(
      setLists(
        teams.map((t) =>
          t.id === data.id
            ? {
                ...t,
                email: data.email,
                firstName: data.first_name,
                lastName: data.last_name,
                role: data.role,
                isPlannerAssigneeOnly: data.is_planner_assignee_only,
                isActive: data.is_active,
              }
            : t
        )
      )
    );
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsSaving(false));
  }
};

export const fetchInviteLink = () => async (dispatch) => {
  try {
    dispatch(setIsFetchingInviteLink(true));
    const { data } = await teamsApi.postLinkInvite()();
    dispatch(setInviteLink(`${process.env.BASE_URL}/invite/${data.inviteCode}`));
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetchingInviteLink(false));
  }
};

export const sendInviteToEmails = (payload) => async (dispatch) => {
  try {
    dispatch(setIsSendingInvite(true));
    await teamsApi.postEmailInvite(payload)();

    dispatch(
      alertActions.createAlertAction(nanoid(), "Invite link successfully sent.", true, "success")
    );
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsSendingInvite(false));
  }
};

export const removeInvitedTeam = (payload) => async (dispatch) => {
  try {
    await teamsApi.deleteEmailInvite(payload)();

    dispatch(
      alertActions.createAlertAction(nanoid(), "Invite link has been cancelled.", true, "error")
    );
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const updateBillingSubscriptions = () => async (dispatch) => {
  try {
    await billingApi.putBillingScriptions()();
  } catch (error) {
    handleError(error, dispatch);
  }
};
