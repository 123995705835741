import createActionType from "../utils/createActionType";

const MODULE = "custom-work-order-print-modal";

export const SET_IS_FETCHING = createActionType(MODULE, "setIsFetching");

export const SET_IS_PRINTING = createActionType(MODULE, "setIsPrinting");

export const SET_DRAWINGS_LIST = createActionType(MODULE, "setDrawingsList");

export const SET_PRODUCTS_FROM_SALES_ORDER = createActionType(MODULE, "setProductsFromSalesOrder");

export const SET_DRAWINGS_FROM_SALES_ORDER = createActionType(MODULE, "setDrawingsFromSalesOrder");

export const SET_SELECTED_PRODUCT_UIDS = createActionType(MODULE, "setSelectedProductUids");

export const SET_SELECTED_DRAWING_UIDS = createActionType(MODULE, "setSelectedDrawingUids");

export const GET_CUSTOM_WORK_ORDER_PDF = createActionType(MODULE, "getCustomWorkOrderPDF");

export const SET_NEW_UPLOADED_ATTACHMENTS = createActionType(MODULE, "setNewUploadedAttachements");

export const SET_ORDER_ATTACHMENTS = createActionType(MODULE, "setOrderAttachments");

export const SET_NEW_ATTACHMENTS = createActionType(MODULE, "newAttachments");

export const POST_ADD_ATTACHMENTS = createActionType(MODULE, "addAttachmnets");

export const SET_SELECTED_ATTACHMENTS = createActionType(MODULE, "selectedAttachments");

export const REMOVE_SELECTED_ATTACHMENT = createActionType(MODULE, "removeSelectedAttachment");

export const UPDATE_SELECTED_ATTACHMENTS = createActionType(MODULE, "updateSelectedAttachments");

export const SET_IS_ATTACHMENTS_LOADING = createActionType(MODULE, "setIsAttachmentsLoading");

export const SET_IS_MODAL_LOADING = createActionType(MODULE, "setIsModalLoading");

export const RESET = createActionType(MODULE, "reset");
