import { createApi } from "./common";

export const searchOrdersBacklog = createApi("GET", "deliveries/backlog/");

export const moveOrderBacklog = createApi("POST", "deliveries/move_order/");

export const getSettings = createApi("GET", "deliveries/");

export const changeSettings = createApi("POST", "deliveries/");

export const searchOrdersCalendar = createApi(
  "GET",
  "deliveries/calendar_orders/"
);

export const createCalendarEvent = createApi(
  "POST",
  "deliveries/add_to_calendar/"
);

export const removeCalendarEvent = createApi("POST", "deliveries/change_order");

export const sendToPrint = createApi("POST", "deliveries/print_run_sheet");

export default searchOrdersBacklog;
