import createActionType from "../utils/createActionType";

export const MODULE = "purchaseOrderTimeline";

export const SET_SEARCH = createActionType(MODULE, "setSearch");
export const SET_FILTERS = createActionType(MODULE, "setFilters");

export const SET_IS_FETCHING_PO_TIMELINES = createActionType(
  MODULE,
  "setIsFetchingPurchaseOrderTimelines"
);

export const SET_IS_FETCHING_PO_TIMELINE_DETAILS = createActionType(
  MODULE,
  "setIsFetchingPurchaseOrderTimelineDetails"
);

export const SET_PO_TIMELINES = createActionType(MODULE, "setPurchaseOrderTimelines");
export const SET_PO_TIMELINE_DETAILS = createActionType(MODULE, "setPurchaseOrderTimelineDetails");

export const RESET = createActionType(MODULE, "reset");
