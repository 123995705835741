import { orderBy } from "lodash";

import { MODULE } from "./pinConfirmation.actionTypes";

export const isSavingStatus = (state) => state.getIn([MODULE, "isSavingStatus"]);

export const isFetchingTeamMembersStatus = (state) =>
  state.getIn([MODULE, "isFetchingTeamMembersStatus"]);

export const getTeamMembers = (state) =>
  orderBy(state.getIn([MODULE, "teams"]), [(l) => l.firstName.toLowerCase()]);
