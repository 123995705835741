import { fromJS } from "immutable";
import { round } from "lodash";
import snakecaseKeys from "snakecase-keys";

import { isPositiveNumber } from "../../utils/common";
import * as flashingProductsApi from "../../api/flashing-products";
import * as productCatalogueActions from "../productCatalogue/productCatalogue.actions";

import ReduxModule from "./abstract/ReduxModule";
import { withMutations } from "./helpers/reducerWrappers";
import { addItemWithSort } from "./helpers/common";
import { parseFlashingToCK, parseFlashingToSK } from "./helpers/product";
import alerts from "./alerts";

const ACTIONS = {
  SEARCH_PRODUCTS: "Get all flashings",
  GET_PRODUCT: "Get flashing",

  CREATE_NEW_PRODUCT: "Create new flashing",
  SET_ACTIVE_PRODUCT: "Set active flashing",
  SUBMIT_PRODUCT: "Submit flashing",
  SET_DELETED_STATUS_PRODUCT: "Set deleted status flashing",
  DUPLICATE_PRODUCT: "Duplicate flashing",

  ADD_PRODUCT_PRICE_LEVEL: "Add flashing price level",
  DELETE_PRODUCT_PRICE_LEVEL: "Delete flashing price level",
  ADD_PRODUCT_PARAMETER: "Add flashing parameter",
  SAVE_PRODUCT_TABLE: "Save product table",
  SAVE_PRODUCT_ACCOUNTING: "Save product accounting item",
  DELETE_PRODUCT_PARAMETER: "Delete flashing parameter",
  RESTORE_PRODUCT_PARAMETER: "Undo delete flashing",
  SET_EXTRA_BEND_FEE: "Set extra bend fee",
  CLEAR_ACTIVE_FLASHING: "Clear active flashing",
};

class FlashingProducts extends ReduxModule {
  getNamespace() {
    return "[FlashingProducts]";
  }

  getInitialState() {
    return {
      activeProduct: null, // this.initialProductState()
      dataServer: {
        isPending: false,
        items: [],
      },
      isPending: false,
      isDuplicating: false,
      errors: {},
    };
  }

  initialProductState() {
    return {
      uid: "", // uid flashing
      isDeleted: false,
      name: "",
      parameters: {
        thickness: [],
        colour: [],
      },
      tables: {
        // thickness[id]: {
        //    priceLevels[id]: {...this.initialOptionsState()}
        // }
      },
      accountingItem: {},
      priceLevels: [],
    };
  }

  initialOptionsState() {
    return {
      fields: ["Girth min", "Girth max"], // headers (grey cells)
      titles: ["0 bends"], // headers (white cells)
      values: [
        {
          types: [
            { name: "Girth min", value: "" },
            { name: "Girth max", value: "" },
          ], // name: [fields[id]]
          prices: [{ name: "0 bends", value: "" }], // name: [titles[id]]
        },
      ],
      extraBendFee: {
        value: "",
        isSelected: false,
      },
      taperedFee: "0.00",
      addTapersCharge: true,
    };
  }

  static generateFlashingDataForServer(state, productName) {
    if (!productName) {
      return { errors: ["Name of product is empty!"] };
    }

    const activeProduct = state.getIn(["flashingProducts", "activeProduct"]).toJS();
    const thicknesses = activeProduct.parameters.thickness;

    if (!thicknesses.length) {
      return { errors: ["Thickness is empty!"] };
    }

    const { colour } = activeProduct.parameters;

    if (!colour.length) {
      return { errors: ["Colour is empty!"] };
    }

    const errors = { errors: [] };
    const wrongTable = thicknesses.find((thickness) =>
      activeProduct.priceLevels.find((priceLevel) => {
        const table = activeProduct.tables[thickness][priceLevel];

        const { values } = table;
        const wrongValues = values.find((row) => {
          const wrongType = row.types.find((type) => {
            const { value } = type;
            return !isPositiveNumber(value);
          });

          const wrongPrice = row.prices.find((type) => {
            const { value } = type;
            return !isPositiveNumber(value);
          });

          if (wrongType || wrongPrice) {
            errors.errors.push("Information missing from pricing table.");
            return true;
          }

          return null;
        });

        if (wrongValues) {
          return true;
        }

        const { extraBendFee, taperedFee, addTapersCharge } = table;

        table.extra_bend_fee = {
          is_selected: extraBendFee.isSelected,
          value: extraBendFee.value,
        };

        delete table.extraBendFee;

        if (!isPositiveNumber(extraBendFee.value)) {
          table.extra_bend_fee.value = "";

          if (extraBendFee.isSelected) {
            errors.errors.push("Wrong Extra Bend Fee!");
            return true;
          }
        } else {
          table.extra_bend_fee.value = round(table.extra_bend_fee.value, 2).toFixed(2);
        }

        table.add_tapers_charge = addTapersCharge;
        table.tapered_fee = taperedFee;
        delete table.addTapersCharge;
        delete table.taperedFee;

        if (!isPositiveNumber(taperedFee) && taperedFee !== "0.00" && taperedFee !== "") {
          table.tapered_fee = "0.00";
          errors.errors.push("Charge for additional tapers!");
          return true;
        }

        if (taperedFee === "") {
          table.tapered_fee = "0.00";
        }

        table.tapered_fee = round(table.tapered_fee, 2).toFixed(2);

        return null;
      })
    );

    if (wrongTable) {
      return errors;
    }

    activeProduct.name = productName;

    if (activeProduct.accountingItem) {
      activeProduct.accountingItem = snakecaseKeys(activeProduct.accountingItem, {
        deep: false,
      });
    }

    return snakecaseKeys(activeProduct, { deep: false });
  }

  createNewProduct = (_state) => _state.set("activeProduct", fromJS(this.initialProductState()));

  searchProductsThunk = ({ token, fulfilled }, search) =>
    flashingProductsApi.searchProducts(token, { search }).then((response) => {
      fulfilled(response);
      return response.data;
    });

  getProduct = ({ token, fulfilled }, uid) =>
    flashingProductsApi.getProduct(token, uid).then((response) => {
      fulfilled(parseFlashingToCK(response.data));
      return response.data;
    });

  submitProduct = ({ token, getState, fulfilled, dispatch }, productName) => {
    const state = getState();
    const productData = FlashingProducts.generateFlashingDataForServer(state, productName);

    if (productData.errors) {
      return new Promise((resolve, reject) => {
        const error = new Error("Wrong data");
        error.name = "Sending Error";

        error.response = {
          data: {
            detail: {
              errors: productData.errors,
            },
          },
        };

        dispatch(
          alerts.actions.addAlert({
            type: "danger",
            message: productData.errors,
          })
        );

        reject(error);
      });
    }

    return flashingProductsApi
      .submitProduct(token, productData)
      .then((response) => {
        fulfilled(response);
        return response.data;
      })
      .then((response) => {
        dispatch(
          productCatalogueActions.fetchProductsByCategory({
            categoryUid: "Flashings",
            category: "Flashings",
          })
        );

        return response;
      });
  };

  deleteProduct = ({ token, getState, dispatch, pendingAction, fulfilled }, flashing) => {
    const { uid } = flashing;

    const items = getState().getIn(["flashingProducts", "dataServer", "items"]);
    const id = items.findIndex((item) => item.get("uid") === uid);
    dispatch(pendingAction({ id, isPending: true }));

    return flashingProductsApi
      .deleteProduct(token, uid)
      .then(() => {
        fulfilled({ id, isDeleted: true });
        dispatch(pendingAction({ id, isPending: false }));

        dispatch(
          alerts.actions.addAlert({
            type: "danger",
            message: "Flashing product deleted",
            action: {
              label: "Undo",
              callback: () => {
                dispatch(this.actions.undoDeleteProduct(flashing));
              },
            },
          })
        );

        dispatch(
          productCatalogueActions.fetchProductsByCategory({
            categoryUid: "Flashings",
            category: "Flashings",
          })
        );

        return flashing;
      })
      .catch(() => dispatch(pendingAction({ id, isPending: false })));
  };

  undoDeleteProduct = ({ token, getState, dispatch, pendingAction, fulfilled }, flashing) => {
    const { uid } = flashing;

    const items = getState().getIn(["flashingProducts", "dataServer", "items"]);
    const id = items.findIndex((item) => item.get("uid") === uid);
    fulfilled({ id, isDeleted: false });
    dispatch(pendingAction({ id, isPending: true }));

    return flashingProductsApi.submitProduct(token, parseFlashingToSK(flashing)).then(() => {
      dispatch(this.actions.searchProducts());
      dispatch(pendingAction({ id, isPending: false }));
    });
  };

  deleteProductParameterThunk = ({ getState, dispatch, fulfilled }, { type, id }) => {
    const activeProduct = getState().getIn(["flashingProducts", "activeProduct"]);

    const value = activeProduct.getIn(["parameters", type, id]);
    const tables = type === "thickness" ? activeProduct.getIn(["tables", value]) : undefined;
    fulfilled({ type, id });

    dispatch(
      alerts.actions.addAlert({
        type: "dark",
        message: `${type.charAt(0).toUpperCase() + type.slice(1)} deleted`,
        action: {
          label: "Undo",
          callback: () => {
            dispatch(this.actions.undoDeleteProductParameter({ type, value, id }, tables));
          },
        },
      })
    );
  };

  undoDeleteProductParameterThunk = ({ fulfilled }, parameter, tables) => {
    fulfilled({ ...parameter, tables });
  };

  duplicateFlashingThunk = ({ token, getState, dispatch, fulfilled }) => {
    const activeProduct = getState().getIn(["flashingProducts", "activeProduct"]);

    return flashingProductsApi
      .postDuplicateFlashing(token, { uids: [activeProduct.getIn(["uid"])] })
      .then((response) => {
        fulfilled(response);

        dispatch(
          alerts.actions.addAlert({
            type: "success",
            message: "Flashing material duplicated",
            closeDelay: 5000,
          })
        );

        dispatch(
          productCatalogueActions.fetchProductsByCategory({
            categoryUid: "Flashings",
            category: "Flashings",
          })
        );
      })
      .catch((e) => {
        dispatch(
          alerts.actions.addAlert({
            type: "danger",
            message: "Can't duplicate flashing",
            closeDelay: 5000,
          })
        );
      });
  };

  defineActions() {
    const clearActiveFlashing = this.resetToInitialState(ACTIONS.CLEAR_ACTIVE_FLASHING);
    const searchProducts = this.thunkAction(
      ACTIONS.SEARCH_PRODUCTS,
      this.searchProductsThunk,
      true
    );
    const getProduct = this.thunkAction(ACTIONS.GET_PRODUCT, this.getProduct, true);

    const duplicateProduct = this.thunkAction(
      ACTIONS.DUPLICATE_PRODUCT,
      this.duplicateFlashingThunk,
      true
    );

    // actions for flashing editor
    const createNewProduct = this.createAction(ACTIONS.CREATE_NEW_PRODUCT);
    const setActiveProduct = this.createAction(ACTIONS.SET_ACTIVE_PRODUCT);
    const submitProduct = this.thunkAction(ACTIONS.SUBMIT_PRODUCT, this.submitProduct, true);
    const deleteProduct = this.thunkAction(
      ACTIONS.SET_DELETED_STATUS_PRODUCT,
      this.deleteProduct,
      ({ isPending, id }) => ({ isPending, id }),
      false
    );
    const undoDeleteProduct = this.thunkAction(
      ACTIONS.SET_DELETED_STATUS_PRODUCT,
      this.undoDeleteProduct,
      ({ isPending, id }) => ({ isPending, id }),
      false
    );

    const addProductPriceLevel = this.createAction(ACTIONS.ADD_PRODUCT_PRICE_LEVEL);
    const deleteProductPriceLevel = this.createAction(ACTIONS.DELETE_PRODUCT_PRICE_LEVEL);
    const addProductParameter = this.createAction(ACTIONS.ADD_PRODUCT_PARAMETER);
    const saveProductTable = this.createAction(ACTIONS.SAVE_PRODUCT_TABLE);
    const saveProductAccounting = this.createAction(ACTIONS.SAVE_PRODUCT_ACCOUNTING);
    const deleteProductParameter = this.thunkAction(
      ACTIONS.DELETE_PRODUCT_PARAMETER,
      this.deleteProductParameterThunk
    );
    const undoDeleteProductParameter = this.thunkAction(
      ACTIONS.RESTORE_PRODUCT_PARAMETER,
      this.undoDeleteProductParameterThunk
    );
    const setExtraBendFee = this.createAction(ACTIONS.SET_EXTRA_BEND_FEE);

    return {
      clearActiveFlashing,
      searchProducts,
      getProduct,
      duplicateProduct,
      createNewProduct,
      setActiveProduct,
      submitProduct,
      deleteProduct,
      undoDeleteProduct,
      addProductPriceLevel,
      deleteProductPriceLevel,
      addProductParameter,
      saveProductTable,
      saveProductAccounting,
      deleteProductParameter,
      undoDeleteProductParameter,
      setExtraBendFee,
    };
  }

  addProductPriceLevel = (_state, nextLevel) => {
    _state.getIn(["activeProduct", "parameters", "thickness"]).forEach((thickness) => {
      const levelFirst = _state.getIn(["activeProduct", "priceLevels", 0]);
      let table = _state.getIn(["activeProduct", "tables", thickness, levelFirst]);

      const newValues = table.get("values").map((row) => {
        const newPrices = row.get("prices").map((col) => col.set("value", ""));
        return row.set("prices", newPrices);
      });

      table = table.set("values", newValues);

      _state.setIn(["activeProduct", "tables", thickness, nextLevel], table);
    });

    _state.updateIn(["activeProduct", "priceLevels"], (list) => list.push(nextLevel).sort());
  };

  deleteProductPriceLevel = (_state, id) => {
    const priceLevel = _state.getIn(["activeProduct", "priceLevels", id]);
    _state.deleteIn(["activeProduct", "priceLevels", id]);

    _state.getIn(["activeProduct", "parameters", "thickness"]).forEach((thickness) => {
      _state.deleteIn(["activeProduct", "tables", thickness, priceLevel]);
    });
  };

  addProductParameter = (_state, { type, value }) => {
    _state.updateIn(["activeProduct", "parameters", type], (list) => addItemWithSort(list, value));

    if (type === "thickness") {
      if (!_state.getIn(["activeProduct", "priceLevels"]).size) {
        _state.updateIn(["activeProduct", "priceLevels"], (list) => list.push("A"));
      }

      _state.getIn(["activeProduct", "priceLevels"]).forEach((priceLevel) => {
        _state.setIn(
          ["activeProduct", "tables", value, priceLevel],
          fromJS(this.initialOptionsState())
        );
      });
    }
  };

  deleteProductParameter = (_state, { type, id }) => {
    if (type === "thickness") {
      const value = _state.getIn(["activeProduct", "parameters", type, id]);
      _state.deleteIn(["activeProduct", "tables", value]);
    }

    _state.deleteIn(["activeProduct", "parameters", type, id]);
  };

  restoreProductParameter = (_state, payload) => {
    const { type, value, id, tables } = payload;

    _state.updateIn(["activeProduct", "parameters", type], (list) => list.splice(id, 0, value));

    if (type === "thickness") {
      _state.setIn(["activeProduct", "tables", value], tables);
    }
  };

  setExtraBendFee = (_state, { isSelected, value }) => {
    if (isSelected != null) {
      _state.setIn(["activeProduct", "extraBendFee", "isSelected"], isSelected);
    }

    if (value != null) {
      _state.setIn(["activeProduct", "extraBendFee", "value"], value);
    }
  };

  setActiveProduct = (_state, { payload }) => {
    if (payload) {
      return _state.set("activeProduct", fromJS(payload));
    }

    return _state.set("activeProduct", null);
  };

  searchProducts = (_state, { payload: { data } }) => {
    const items = data.map((item) => parseFlashingToCK(item));
    return _state.setIn(["dataServer", "items"], fromJS(items));
  };

  deleteProductPending = (_state, { id, isPending }) => {
    if (id > -1) {
      _state.setIn(["dataServer", "items", id, "isPending"], isPending);
    }

    if (_state.get("activeProduct")) {
      _state.setIn(["activeProduct", "isPending"], isPending);
    }
  };

  deleteProductFulfilled = (_state, { id, isDeleted }) => {
    if (id > -1) {
      _state.setIn(["dataServer", "items", id, "isDeleted"], isDeleted);
    }

    _state.set("activeProduct", null);
  };

  defineReducers() {
    return {
      [`${ACTIONS.GET_PRODUCT} fulfilled`]: this.setActiveProduct,

      [`${ACTIONS.GET_PRODUCT} pending`]: this.thunkPendingReducer("isPending"),

      [`${ACTIONS.SEARCH_PRODUCTS} fulfilled`]: this.searchProducts,

      [`${ACTIONS.SEARCH_PRODUCTS} pending`]: this.thunkPendingReducer(["dataServer", "isPending"]),

      [ACTIONS.ADD_PRODUCT_PRICE_LEVEL]: withMutations(this.addProductPriceLevel),

      [ACTIONS.DELETE_PRODUCT_PRICE_LEVEL]: withMutations(this.deleteProductPriceLevel),

      [ACTIONS.ADD_PRODUCT_PARAMETER]: withMutations(this.addProductParameter),

      [ACTIONS.SAVE_PRODUCT_TABLE]: (state, { payload: { thickness, priceLevel, table } }) =>
        state.setIn(["activeProduct", "tables", thickness, priceLevel], fromJS(table)),
      [ACTIONS.SAVE_PRODUCT_ACCOUNTING]: (state, { payload: accountingItem }) =>
        state.setIn(["activeProduct", "accountingItem"], accountingItem),

      [`${ACTIONS.DELETE_PRODUCT_PARAMETER} fulfilled`]: withMutations(this.deleteProductParameter),

      [`${ACTIONS.RESTORE_PRODUCT_PARAMETER} fulfilled`]: withMutations(
        this.restoreProductParameter
      ),

      [ACTIONS.SET_EXTRA_BEND_FEE]: withMutations(this.setExtraBendFee),

      [ACTIONS.CREATE_NEW_PRODUCT]: this.createNewProduct,

      [ACTIONS.SET_ACTIVE_PRODUCT]: this.setActiveProduct,

      [`${ACTIONS.SUBMIT_PRODUCT} fulfilled`]: (state) => state,

      [`${ACTIONS.SUBMIT_PRODUCT} pending`]: this.thunkPendingReducer("isPending"),

      [`${ACTIONS.SUBMIT_PRODUCT} rejected`]: (state, { payload: errors }) =>
        state.set("errors", errors),

      [`${ACTIONS.SET_DELETED_STATUS_PRODUCT} fulfilled`]: withMutations(
        this.deleteProductFulfilled
      ),

      [`${ACTIONS.SET_DELETED_STATUS_PRODUCT} pending`]: withMutations(this.deleteProductPending),

      [`${ACTIONS.DUPLICATE_PRODUCT} pending`]: this.thunkPendingReducer("isDuplicating"),
    };
  }
}

const instance = new FlashingProducts();
instance.init();

export default instance;
