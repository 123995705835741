import * as teamsApi from "@/apiv2/teams";
import { handleError } from "@/redux/utils/error";
import { STATUS } from "@/utils/constants";

import {
  SET_IS_MODAL_VISIBLE,
  SET_TEAM_MEMBERS,
  SET_IS_FETCHING,
  SET_TARGET_PRODUCT_TYPE,
  SET_TARGET_PRODUCT_KIT,
  SET_ACTIVE_LINE_ITEM,
  RESET,
} from "./orderAddLabourLineItem.actionTypes";

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setIsModalVisible = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_MODAL_VISIBLE,
    payload: status,
  });
};

export const setTeamMembers = (data) => (dispatch) => {
  dispatch({
    type: SET_TEAM_MEMBERS,
    payload: data,
  });
};

export const setIsFetching = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING,
    payload: status,
  });
};

export const setTargetProductType = (data) => (dispatch) => {
  dispatch({
    type: SET_TARGET_PRODUCT_TYPE,
    payload: data,
  });
};

export const setTargetProductKit = (data) => (dispatch) => {
  dispatch({
    type: SET_TARGET_PRODUCT_KIT,
    payload: data,
  });
};

export const setActiveLineItem = (data) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_LINE_ITEM,
    payload: data,
  });
};

export const fetchTeamMembers = () => async (dispatch) => {
  try {
    dispatch(setIsFetching(STATUS.PENDING));

    const { data } = await teamsApi.getTeams({
      with_inactive: false,
      include_invites: false,
    })();
    dispatch(setTeamMembers(data));
    dispatch(setIsFetching(STATUS.FULFILLED));
  } catch (error) {
    dispatch(setIsFetching(STATUS.REJECTED));
    handleError(error, dispatch);
  }
};
