import { uniq, flatMap, flattenDeep } from "lodash";
import { nanoid } from "nanoid";

import { handleError } from "../utils/error";
import * as api from "../../apiv2/stocktake";
import * as routerActions from "../router/router.actions";
import * as alertActions from "../alerts/alerts.actions";
import * as stocktakeSupplierFilterSelectors from "./supplierFilter/supplierFilter.selectors";
import * as stocktakeSearchFilterSelectors from "./searchFilter/searchFilter.selectors";
import {
  SET_IS_FETCHING_CATEGORIES,
  SET_IS_SAVING,
  SET_IS_FETCHING_PRODUCT,
  SET_IS_FETCHING_PRODUCTS,
  SET_CATEGORIES,
  SET_PRODUCTS,
  SET_EMPTY_PRODUCTS,
  SET_EXPANDED_CATEGORIES,
  SET_EXPANDED_PRODUCTS,
  SET_SELECTED_PRODUCTS,
  SET_SELECTED_CATEGORIES,
  SET_INVENTORY,
  SET_PRODUCT_INVENTORY,
  RESET,
} from "./stocktake.actionTypes";

import * as selectors from "./stocktake.selectors";

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setIsFetchingCategories = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_CATEGORIES,
    payload: status,
  });
};

export const setIsFetchingProductUid = (uid) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_PRODUCT,
    payload: uid,
  });
};

export const setIsSaving = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_SAVING,
    payload: status,
  });
};

export const setCategories = (data) => (dispatch) => {
  dispatch({
    type: SET_CATEGORIES,
    payload: {
      data,
    },
  });
};

export const setProductsByCategory =
  ({ data, categoryUid }) =>
  (dispatch) => {
    dispatch({
      type: SET_PRODUCTS,
      payload: {
        data,
        categoryUid,
      },
    });
  };
export const setEmptyProducts = () => (dispatch) => {
  dispatch({
    type: SET_EMPTY_PRODUCTS,
  });
};

export const setExpandedCategories = (categoryUids) => (dispatch) => {
  dispatch({
    type: SET_EXPANDED_CATEGORIES,
    payload: {
      data: categoryUids,
    },
  });
};

export const setExpandedProducts = (productUids) => (dispatch) => {
  dispatch({
    type: SET_EXPANDED_PRODUCTS,
    payload: {
      data: productUids,
    },
  });
};

export const setIsFetchingProducts = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_PRODUCTS,
    payload: {
      data,
    },
  });
};

export const setSelectedCategories = (categoryUids) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_CATEGORIES,
    payload: {
      selectedCategories: categoryUids,
    },
  });
};

export const setSelectedProducts = (productUids) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_PRODUCTS,
    payload: {
      selectedProducts: productUids,
    },
  });
};

export const setInventory =
  ({ categoryUid, productIndex, materialIndex, rowIndex, data }) =>
  (dispatch, getState) => {
    const state = getState();
    dispatch({
      type: SET_INVENTORY,
      payload: {
        categoryUid,
        productIndex,
        materialIndex,
        rowIndex,
        data,
      },
    });

    const products = selectors.getProductsFromCategory(state, categoryUid);

    dispatch(
      setSelectedProduct({
        productUid: products[productIndex].uid,
        checked: true,
      })
    );
  };

export const setSelectedProduct =
  ({ productUid, checked } = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const selectedProductUids = selectors.getSelectedProducts(state);

    const productUids = checked
      ? [...selectedProductUids, productUid]
      : selectedProductUids.filter((selected) => selected !== productUid);
    dispatch(setSelectedProducts(productUids));
  };

export const toggleCategory =
  ({ categoryUid, checked } = {}) =>
  async (dispatch, getState) => {
    const state = getState();
    const selectedProducts = selectors.getSelectedProducts(state);
    const selectedCategories = selectors.getSelectedCategories(state);
    const expandedCategories = selectors.getExpandedCategories(state);
    const productsFromCategory = selectors.getProductsFromCategory(state, categoryUid);
    const newSelectedCategories = checked
      ? [...selectedCategories, categoryUid]
      : [...selectedCategories.filter((category) => category !== categoryUid)];

    dispatch(setSelectedCategories(newSelectedCategories));

    if (checked) {
      dispatch(setExpandedCategories([...expandedCategories, categoryUid]));
      try {
        if (!productsFromCategory) {
          const response = await dispatch(fetchProductsByCategory({ uid: categoryUid }));
          if (response.data) {
            const productUids = [
              ...selectedProducts,
              ...response.data.map((product) => product.uid),
            ];
            dispatch(setSelectedProducts(productUids));
          }
        } else {
          const productUids = [
            ...selectedProducts,
            ...productsFromCategory.map((product) => product.uid),
          ];
          dispatch(setSelectedProducts(productUids));
        }
      } catch (error) {
        handleError(error);
      }
    } else {
      const productUids = selectedProducts.filter(
        (selected) => !productsFromCategory.map((product) => product.uid).includes(selected)
      );
      dispatch(setSelectedProducts(productUids));
    }
  };

export const fetchCategories = () => async (dispatch) => {
  try {
    dispatch(setIsFetchingCategories(true));
    const response = await api.getCategories()();
    dispatch(
      setCategories(
        [...response.data].map((item) => ({
          ...item,
          isFetching: false,
        }))
      )
    );
    return response;
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetchingCategories(false));
  }
};

export const fetchProductsByCategory = (data) => async (dispatch, getState) => {
  const state = getState();
  const search = stocktakeSearchFilterSelectors.getSearch(state);
  const suppliers = stocktakeSupplierFilterSelectors.getSelectedSuppliersUids(state);
  if (selectors.getCategories(state).length === 0) {
    const response = await api.getCategories()();
    dispatch(
      setIsFetchingProducts({
        uid: response.data.find((c) => c.uid === data.uid)?.uid ?? "",
        isFetching: true,
      })
    );
  } else {
    dispatch(
      setIsFetchingProducts({
        uid: selectors.getCategories(state).find((c) => c.uid === data.uid)?.uid ?? "",
        isFetching: true,
      })
    );
  }

  try {
    const response = await api.getProducts({
      categories: data?.uid ?? undefined,
      search: search || undefined,
      suppliers: suppliers.length > 0 ? suppliers : undefined,
    })();

    dispatch(
      setProductsByCategory({
        data: response.data,
        categoryUid: data.uid,
      })
    );

    return response;
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(
      setIsFetchingProducts({
        uid: data.uid,
        isFetching: false,
      })
    );
  }
};

export const updateInventory = () => async (dispatch, getState) => {
  const state = getState();

  const selectedProducts = selectors.getSelectedProducts(state);
  const products = flatMap(selectors.getProducts(state));

  const data = products
    .filter((product) => selectedProducts.includes(product.uid))
    .map((product) => {
      return product.tables.map((table) => {
        return table.values.map((value) => {
          return {
            row_uid: value.uid,
            inventory: value.inventory,
          };
        });
      });
    });

  try {
    dispatch(setIsSaving(true));
    await api.postProductInventory(flattenDeep(data))();

    dispatch(alertActions.createAlertAction(nanoid(), "Stock levels updated.", true, "dark"));
    dispatch(routerActions.push("/products"));
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(setIsSaving(false));
  }
};

export const searchProducts = () => async (dispatch, getState) => {
  const state = getState();
  const categories = selectors.getCategories(state);
  const search = stocktakeSearchFilterSelectors.getSearch(state);
  const suppliers = stocktakeSupplierFilterSelectors.getSelectedSuppliersUids(state);

  if (search !== "" || suppliers.length > 0) {
    try {
      dispatch(setIsFetchingCategories(true));
      const { data } = await api.getProducts({
        search: search || undefined,
        suppliers: suppliers.length > 0 ? suppliers : undefined,
      })();

      if (data.length > 0) {
        const uniqueCategories = uniq(data.map((item) => item.categoryUid));
        dispatch(
          setCategories(categories.filter((category) => uniqueCategories.includes(category.uid)))
        );
        dispatch(setExpandedCategories(uniqueCategories));
        await Promise.all(
          uniqueCategories.map((category) =>
            dispatch(
              setProductsByCategory({
                data: data.filter((item) => item.categoryUid === category),
                categoryUid: category,
              })
            )
          )
        );
      }
    } catch (error) {
      handleError(error);
    } finally {
      dispatch(setIsFetchingCategories(false));
    }
  } else {
    dispatch(fetchCategories());
    dispatch(setExpandedCategories([]));
    dispatch(setSelectedCategories([]));
    dispatch(setSelectedProducts([]));
    dispatch(setEmptyProducts());
  }
};

export const fetchProductInventory = (productUid) => async (dispatch, getState) => {
  const state = getState();
  try {
    dispatch(setIsFetchingProductUid(productUid));
    const response = await api.getProductInventory(productUid)();
    const { categoryUid, tables } = response.data;

    dispatch(updateProductInventory(categoryUid, productUid, tables));

    return response;
  } catch (error) {
    handleError(error, dispatch);
  } finally {
    dispatch(setIsFetchingProductUid(null));
  }
};

export const updateProductInventory = (categoryUid, productUid, tables) => ({
  type: SET_PRODUCT_INVENTORY,
  payload: { categoryUid, productUid, tables },
});
