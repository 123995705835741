import { fromJS } from "immutable";
import axios from "axios";
import { withMutations } from "./reducerWrappers";

export const sortItems = (list, key) => {
  const mapped = list.map((el, i) => {
    const value = key ? el.get(key) : el;

    return {
      index: i,
      value: typeof value === "string" ? value.toLowerCase() : value,
    };
  });
  const mappedList = mapped.sort((a, b) => {
    if (a.value > b.value) {
      return 1;
    }
    if (a.value < b.value) {
      return -1;
    }
    return 0;
  });

  return mappedList.map((el) => list.get(el.index));
};

export const addItemWithSort = (list, item, key) => {
  const newList = list.push(item);
  return sortItems(newList, key);
};

export const commonPending = (pendings) => {
  const [...keys] = pendings.keys();
  const isPending = keys.find((key) => pendings.get(key));
  return !!isPending;
};

export const setPending = (key) =>
  withMutations((_state, { isPending }) => {
    const pendings = _state.setIn(["pendings", key], isPending).get("pendings");
    _state.set("isPending", commonPending(pendings));
  });

export const reorderItems = (list, startIndex, endIndex) => {
  const removed = list.get(startIndex);
  const newList = list.delete(startIndex);
  return newList.splice(endIndex, 0, removed);
};

export const moveItems = (
  source,
  destination,
  droppableSourceId,
  droppableDestinationId,
  startIndex,
  endIndex
) => {
  const removed = source.get(startIndex);
  const newSource = source.delete(startIndex);
  const newDestination = destination.splice(endIndex, 0, removed);

  let result = fromJS({
    [droppableSourceId]: [],
    [droppableDestinationId]: [],
  });
  result = result.set(droppableSourceId, newSource);
  result = result.set(droppableDestinationId, newDestination);

  return result;
};

export const createFormData = (json, imageData) => {
  const data = new FormData();
  data.set("json", JSON.stringify({ ...json }));

  let imagePromise;
  if (imageData && typeof imageData === "string") {
    const axiosOptions = {
      method: "GET",
      url: imageData,
      responseType: "blob",
    };

    imagePromise = axios(axiosOptions)
      .then((response) => {
        const fileName = imageData.match(/[^\\/]+$/)[0];
        return new File([response.data], fileName, {
          type: response.headers["content-type"],
          lastModified: Date.now(),
        });
      })
      .then((file) => {
        if (file) {
          data.set("image", file);
        }
        return data;
      });
  } else {
    data.set("image", imageData);
    imagePromise = Promise.resolve(data);
  }

  return { imagePromise, data };
};

export class Cancelable extends Promise {
  constructor(executor) {
    super((resolve, reject) => {
      executor((value) => {
        if (this.canceled) {
          reject(new Error("Cancelled"));
          return;
        }
        resolve(value);
      }, reject);
    });
    this.canceled = false;
  }

  cancel() {
    this.canceled = true;
  }
}

export default addItemWithSort;
