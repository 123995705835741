import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { faCircleCheck } from "@fortawesome/pro-light-svg-icons";
import { faX } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import qs from "query-string";

import { Modal } from "@/ui-lib";
import { Button } from "@/ui-lib/FormFields";

import { mainModalStyles } from "./modal.styles";
import styles from "./BillingSuccessfulPaymentModal.module.scss";

const BillingSuccessfulPaymentModal = () => {
  const location = useLocation();
  const { push } = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const params = qs.parse(location.search);

  useEffect(() => {
    if (params.session_id || params.subscriptionId) {
      setIsOpen(true);
    }
  }, []);

  return (
    <Modal isOpen={isOpen} styles={mainModalStyles}>
      <div className={styles.wrapper}>
        <FontAwesomeIcon className={styles.closeIcon} icon={faX} onClick={() => setIsOpen(false)} />
        <FontAwesomeIcon icon={faCircleCheck} className={styles.icon} />
        <span className={styles.title}>Successfully subscribed</span>
        <p className={styles.description}>Congratulations, your subscription is now active!</p>
        <Button
          color="success"
          onClick={() => {
            setIsOpen(false);
            push("/settings?tab=billing");
          }}
        >
          Let&apos;s go!
        </Button>
      </div>
    </Modal>
  );
};

export default BillingSuccessfulPaymentModal;
