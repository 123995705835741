import {
  SET_IS_TEMPLATE_MODAL_VISIBLE,
  SET_IS_FETCHING_TEMPLATES,
  SET_TEMPLATE_TO_BE_ADDED_IN_ORDER,
  SET_TEMPLATES,
  SET_EXPANDED_TEMPLATE_UIDS,
  SET_IS_TEMPLATE_OVERRIDE_MODAL_VISIBLE,
  SET_IS_OVERRIDING_TEMPLATE,
  SET_TEMPLATE_TO_OVERRIDE,
  SET_ACTIVE_TEMPLATE,
  SET_IS_SAVING_TEMPLATE,
  RESET,
  SET_TEMPLATES_FOR_SEARCH,
} from "./orderChecklistTemplates.actionTypes";

const initialState = {
  isTemplateModalVisible: false,
  isFetchingTemplates: false,
  isTemplateOverrideModalVisible: false,
  isOverridingTemplate: false,
  isSavingTemplate: false,
  templateToOverride: null,
  templateToBeAddedInOrder: null,
  activeTemplate: null,
  templates: [],
  templateListsForSearch: [],
  expandedTemplateUids: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_TEMPLATE_MODAL_VISIBLE: {
      return {
        ...state,
        isTemplateModalVisible: action.payload,
      };
    }

    case SET_IS_FETCHING_TEMPLATES: {
      return {
        ...state,
        isFetchingTemplates: action.payload,
      };
    }

    case SET_IS_SAVING_TEMPLATE: {
      return {
        ...state,
        isSavingTemplate: action.payload,
      };
    }

    case SET_IS_OVERRIDING_TEMPLATE: {
      return {
        ...state,
        isOverridingTemplate: action.payload,
      };
    }

    case SET_TEMPLATE_TO_BE_ADDED_IN_ORDER: {
      return {
        ...state,
        templateToBeAddedInOrder: action.payload,
      };
    }

    case SET_IS_TEMPLATE_OVERRIDE_MODAL_VISIBLE: {
      return {
        ...state,
        isTemplateOverrideModalVisible: action.payload,
      };
    }

    case SET_TEMPLATE_TO_OVERRIDE: {
      return {
        ...state,
        templateToOverride: action.payload,
      };
    }

    case SET_ACTIVE_TEMPLATE: {
      return {
        ...state,
        activeTemplate: action.payload,
      };
    }

    case SET_TEMPLATES: {
      return {
        ...state,
        templates: action.payload,
      };
    }

    case SET_TEMPLATES_FOR_SEARCH: {
      return {
        ...state,
        templateListsForSearch: action.payload,
      };
    }

    case SET_EXPANDED_TEMPLATE_UIDS: {
      return {
        ...state,
        expandedTemplateUids: action.payload,
      };
    }

    case RESET: {
      return initialState;
    }

    default:
      return state;
  }
};
