import createActionType from "../../utils/createActionType";

export const MODULE = "delivery-scheduling/user-filter";

export const SET_IS_FETCHING_USERS = createActionType(MODULE, "setIsFetchingUsers");
export const SET_USERS = createActionType(MODULE, "setUsers");

export const SET_SELECTED_USERS = createActionType(MODULE, "setSelectedUsers");

export const RESET = createActionType(MODULE, "reset");
