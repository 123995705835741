import * as date from "@/utils/date";
import { handleError } from "../utils/error";
import dispatchApi from "../utils/dispatchApi";
import { INTENT } from "../../utils/constants";
import {
  getOrderAttachments,
  postPrintInvoicePdf,
  postPrintQuotePdf,
  postSendEmailPdf,
} from "../../apiv2/orderAttachments";
import { saveMessageWithFiles, getCollaborationNotesFile } from "../../api/collaboration-notes";

import * as selectors from "./orderAttachments.selectors";
import {
  SET_IS_FETCHING,
  SET_IS_PRINTING,
  SET_ORDER_ATTACHMENTS,
  SET_SELECTED_ATTACHMENTS,
  SET_DRAWINGS_LIST,
  SET_IS_PRODUCT_HAS_NO_PRICE_ALERT_OPEN,
  REMOVE_SELECTED_ATTACHMENT,
  UPDATE_SELECTED_ATTACHMENTS,
  GET_INVOICE_PDF,
  GET_QUOTE_PDF,
  SEND_EMAIL_PDF,
  RESET,
} from "./orderAttachments.actionTypes";

export const setIsFetching = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING,
    payload: status,
  });
};

export const setIsPrinting = (status) => (dispatch) => {
  dispatch({
    type: SET_IS_PRINTING,
    payload: status,
  });
};

export const setSelectedAttachments = (data) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_ATTACHMENTS,
    payload: data,
  });
};

export const setOrderAttachments = (data) => (dispatch) => {
  dispatch({
    type: SET_ORDER_ATTACHMENTS,
    payload: data,
  });
};

export const setDrawingsList = (drawingsList) => (dispatch) => {
  dispatch({
    type: SET_DRAWINGS_LIST,
    payload: drawingsList,
  });
};

export const setIsProductHasNoPriceAlertOpen =
  ({ status, isInvoiceEmail, isInvoicePreview }) =>
  (dispatch) => {
    dispatch({
      type: SET_IS_PRODUCT_HAS_NO_PRICE_ALERT_OPEN,
      payload: { status, isInvoiceEmail, isInvoicePreview },
    });
  };

export const removeSelectedAttachment = (data) => (dispatch) => {
  dispatch({
    type: REMOVE_SELECTED_ATTACHMENT,
    payload: data,
  });
};

export const updateSelectedAttachments = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_ATTACHMENTS,
    payload: data,
  });
};

export const fetchOrderAttachments =
  ({ customerUid, orderUid }) =>
  async (dispatch) => {
    dispatch(setIsFetching(true));
    try {
      const response = await getOrderAttachments({ customerUid, orderUid })();
      dispatch(setOrderAttachments(response));
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(setIsFetching(false));
    }
  };

export const addAttachments =
  ({ customerUid, orderUid, file }) =>
  async (dispatch) => {
    dispatch(setIsFetching(true));
    const data = new FormData();
    const token = JSON.parse(window.localStorage.getItem("auth"));

    data.set(
      "json",
      JSON.stringify({
        order_id: orderUid,
        message: "",
      })
    );

    data.append("files", file);

    await saveMessageWithFiles(token, data).then((res) => {
      // side effects
      const response = res.data;
      const attachmentId = response?.order_chat_message_file[0].id ?? "";
      const attachmentType = response?.order_chat_message_file[0].type ?? "";

      const FILE_TYPES = {
        "image/png": "image",
        "image/jpg": "image",
        "image/jpeg": "image",
        "application/dwg": "dwg",
        "application/dxf": "dxf",
        "image/vnd.dwg": "dwg",
        "image/vnd.dxf": "dxf",
      };

      dispatch(
        setSelectedAttachments({
          id: attachmentId,
          type: FILE_TYPES[attachmentType.toLowerCase()] || "pdf",
        })
      );
    });

    const response = await getOrderAttachments({ customerUid, orderUid })();
    dispatch(setOrderAttachments(response));

    dispatch(setIsFetching(false));
  };

export const viewFile =
  ({ customerUid, orderUid, fileId }) =>
  async (dispatch) => {
    const token = JSON.parse(window.localStorage.getItem("auth"));
    dispatch(setIsFetching(true));

    await getCollaborationNotesFile(customerUid, orderUid, fileId, token).then((response) => {
      const fileUrl = window.URL.createObjectURL(
        new File([response.data], fileId, { type: response.data.type })
      );
      window.open(fileUrl);
    });

    dispatch(setIsFetching(false));
  };

export const generateInvoicePrint =
  ({ imageAttachmentUids = [], pdfAttachmentUids = [], isPreview, invoicedDate }) =>
  async (dispatch, getState) => {
    const state = getState();
    const drawingsList = selectors.getDrawingsList(state);
    const orderId = state.getIn(["orders", "activeOrder", "uid"]);
    const data = new FormData();

    dispatch(setIsPrinting(true));

    data.set(
      "json",
      JSON.stringify({
        order_id: orderId,
        attachment_images: imageAttachmentUids,
        attachment_pdfs: pdfAttachmentUids,
        invoiced_date: date.toUtcDateTime({
          date: invoicedDate,
          timezone: date.getCurrentUserTimezone(),
        }),
        is_preview: isPreview,
      })
    );

    Object.keys(drawingsList).forEach((key) => {
      const drawing = drawingsList[key];

      drawing.forEach(({ uid, xml }) => {
        const svgBlob = new Blob([xml], { type: "image/svg+xml" });
        const file = new File([svgBlob], uid, {
          type: "image/svg+xml",
          lastModified: Date.now(),
        });
        data.set(uid, file);
      });
    });

    return dispatchApi(
      dispatch,
      GET_INVOICE_PDF,
      () => postPrintInvoicePdf(data),
      INTENT.PERSISTENT
    ).then(() => dispatch(setIsPrinting(false)));
  };

export const generateQuotePrint =
  ({ imageAttachmentUids = [], pdfAttachmentUids = [], isPreview, quoteDate }) =>
  async (dispatch, getState) => {
    const state = getState();
    const drawingsList = selectors.getDrawingsList(state);
    const orderId = state.getIn(["orders", "activeOrder", "uid"]);
    const data = new FormData();

    dispatch(setIsPrinting(true));

    data.set(
      "json",
      JSON.stringify({
        order_id: orderId,
        attachment_images: imageAttachmentUids,
        attachment_pdfs: pdfAttachmentUids,
        is_preview: isPreview,
        quoted_date: date.toUtcDateTime({
          date: quoteDate,
          timezone: date.getCurrentUserTimezone(),
        }),
      })
    );

    Object.keys(drawingsList).forEach((key) => {
      const drawing = drawingsList[key];

      drawing.forEach(({ uid, xml }) => {
        const svgBlob = new Blob([xml], { type: "image/svg+xml" });
        const file = new File([svgBlob], uid, {
          type: "image/svg+xml",
          lastModified: Date.now(),
        });
        data.set(uid, file);
      });
    });

    return dispatchApi(
      dispatch,
      GET_QUOTE_PDF,
      () => postPrintQuotePdf(data),
      INTENT.PERSISTENT
    ).then(() => dispatch(setIsPrinting(false)));
  };

export const generateEmailPdf =
  ({ type, contactsToEmail, attachmentUids, pdfDate, addAlert, customEmailMessage }) =>
  async (dispatch, getState) => {
    const state = getState();
    const drawingsList = selectors.getDrawingsList(state);
    const orderUid = state.getIn(["orders", "activeOrder", "uid"]);
    const customerUid = state.getIn(["customers", "activeCustomer", "uid"]);

    dispatch(setIsPrinting(true));

    const data = new FormData();

    data.set(
      "json",
      JSON.stringify({
        type,
        attachments: attachmentUids,
        emails: contactsToEmail,
        pdf_date: date.toUtcDateTime({
          date: pdfDate,
          timezone: date.getCurrentUserTimezone(),
        }),
        custom_email_message: customEmailMessage,
      })
    );

    Object.keys(drawingsList).forEach((key) => {
      const drawing = drawingsList[key];

      drawing.forEach(({ uid, xml }) => {
        const svgBlob = new Blob([xml], { type: "image/svg+xml" });
        const file = new File([svgBlob], uid, {
          type: "image/svg+xml",
          lastModified: Date.now(),
        });
        data.set(uid, file);
      });
    });

    return dispatchApi(
      dispatch,
      SEND_EMAIL_PDF,
      () =>
        postSendEmailPdf({
          orderUid,
          customerUid,
          data,
        }),
      INTENT.PERSISTENT
    ).then((response) => {
      dispatch(setIsPrinting(false));

      if (response !== undefined) {
        addAlert({
          type: "success",
          message: "Email sent",
        });
      }
    });
  };

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};
