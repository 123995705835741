import React, { useEffect } from "react";
import TagManager from "react-gtm-module";

import { tagManagerArgs } from "../env";

const GoogleTagManager = () => {
  useEffect(() => {
    if (tagManagerArgs) {
      TagManager.initialize(tagManagerArgs);
    }
  }, []);

  return null;
};

export default GoogleTagManager;
