import moment from "moment-timezone";

import * as date from "@/utils/date";

import * as authApi from "../../api/auth";
import { oauth2 } from "../../env";

import customers from "./customers";
import ReduxModule from "./abstract/ReduxModule";

const ACTIONS = {
  LOGIN: "Login",
  LOGOUT: "Logout",
  CHECK_AUTH: "Check Auth",

  CLEAR_ERROR: "Clear Error",
};

class AuthModule extends ReduxModule {
  getNamespace() {
    return "[Auth]";
  }

  getInitialState() {
    return {
      authenticated: false,
      isPending: false,
      errors: null,
    };
  }

  checkAuth = ({ token, dispatch, fulfilled }) =>
    new Promise((resolve) => {
      if (token) {
        const today = moment.tz(date.getCurrentUserTimezone()).unix();

        if (
          token.time_sign_up &&
          token.expires_in &&
          today - token.time_sign_up < token.expires_in
        ) {
          fulfilled({
            data: token,
          });

          dispatch(customers.actions.getCurrentUser());
        } else {
          dispatch(this.actions.refreshToken());
        }
      } else {
        dispatch(this.actions.logout());
      }

      resolve();
    });

  refreshToken = ({ token, dispatch, fulfilled }) => {
    const data = {
      grant_type: "refresh_token",
      refresh_token: token.refresh_token,
      ...oauth2,
    };
    const formData = new FormData();

    Object.keys(data).forEach((item) => {
      formData.append(item, data[item]);
    });

    authApi
      .tokenVerify(null, formData)
      .then((response) => {
        fulfilled(response);
        dispatch(customers.actions.getCurrentUser());
        return response.data;
      })
      .catch((errors) => {
        const { response } = errors;
        if (response) throw response.data;
        throw errors;
      });
  };

  login = ({ dispatch, fulfilled }, email, password) => {
    const data = {
      grant_type: "password",
      username: email,
      password,
      ...oauth2,
    };
    const formData = new FormData();

    Object.keys(data).forEach((item) => {
      formData.append(item, data[item]);
    });

    return authApi
      .tokenAuth(null, formData)
      .then((response) => {
        fulfilled(response);

        dispatch(customers.actions.getCurrentUser()).then((userResponse) => ({
          ...response.data,
          timeZone: userResponse.time_zone,
        }));
      })
      .catch((errors) => {
        const { response } = errors;
        if (response) throw response.data;
        throw errors;
      });
  };

  logout = ({ token, fulfilled }) => {
    const data = {
      token: JSON.stringify(token),
      ...oauth2,
    };
    const formData = new FormData();

    Object.keys(data).forEach((item) => {
      formData.append(item, data[item]);
    });

    if (token) {
      return authApi.logout(formData).then(() => {
        fulfilled();
      });
    }

    fulfilled();
    return new Promise((resolve) => resolve);
  };

  defineActions() {
    const login = this.thunkAction(ACTIONS.LOGIN, this.login, true);
    const logout = this.thunkAction(ACTIONS.LOGOUT, this.logout, true);
    const checkAuth = this.thunkAction(ACTIONS.LOGIN, this.checkAuth, true);
    const refreshToken = this.thunkAction(ACTIONS.LOGIN, this.refreshToken, true);

    const clearError = this.reset(ACTIONS.CLEAR_ERROR, "errors", null);

    return {
      login,
      logout,
      checkAuth,
      refreshToken,

      clearError,
    };
  }

  defineReducers() {
    return {
      [`${ACTIONS.LOGIN} fulfilled`]: (state, { payload: { data } }) => {
        const authData = {
          ...data,
          time_sign_up: moment.tz(data.timeZone).unix(),
        };

        window.localStorage.setItem("auth", JSON.stringify(authData));

        return state.set("authenticated", true);
      },
      [`${ACTIONS.LOGIN} pending`]: this.thunkPendingReducer("isPending"),
      [`${ACTIONS.LOGIN} rejected`]: this.setReducer("errors"),

      [`${ACTIONS.LOGOUT} fulfilled`]: (state) => {
        window.localStorage.removeItem("auth");
        window.localStorage.removeItem("user");

        return state.set("authenticated", false);
      },
      [`${ACTIONS.LOGOUT} pending`]: this.thunkPendingReducer("isPending"),
      [`${ACTIONS.LOGOUT} rejected`]: this.setReducer("errors"),
    };
  }
}

const instance = new AuthModule();
instance.init();

export default instance;
