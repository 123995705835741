import { handleError } from "../utils/error";
import * as api from "../../apiv2/purchaseOrderTimeline";

import {
  SET_IS_FETCHING_PO_TIMELINES,
  SET_IS_FETCHING_PO_TIMELINE_DETAILS,
  SET_PO_TIMELINES,
  SET_PO_TIMELINE_DETAILS,
  RESET,
} from "./purchaseOrderTimeline.actionTypes";

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setIsFetchingPurchaseOrderTimelines = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_PO_TIMELINES,
    payload: data,
  });
};

export const setIsFetchingPurchaseOrderTimelineDetails = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_FETCHING_PO_TIMELINE_DETAILS,
    payload: data,
  });
};

export const setPurchaseOrderTimelines = (data) => (dispatch) => {
  dispatch({
    type: SET_PO_TIMELINES,
    payload: data,
  });
};

export const setPurchaseOrderTimelineDetails = (data) => (dispatch) => {
  dispatch({
    type: SET_PO_TIMELINE_DETAILS,
    payload: data,
  });
};

export const fetchPurchaseOrderTimelines =
  ({ purchaseOrderUid, search = "", eventTypes = [] }) =>
  async (dispatch) => {
    try {
      dispatch(setIsFetchingPurchaseOrderTimelines(true));
      const response = await api.getPurchaseOrderTimelines({
        purchaseOrderUid,
        search,
        eventTypes,
      })();
      dispatch(setPurchaseOrderTimelines(response));
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(setIsFetchingPurchaseOrderTimelines(false));
    }
  };

export const fetchPurchaseOrderTimelineDetails =
  ({ purchaseOrderUid }) =>
  async (dispatch) => {
    try {
      dispatch(setIsFetchingPurchaseOrderTimelineDetails(true));
      const response = await api.getPurchaseOrderTimelineDetails({ purchaseOrderUid })();
      dispatch(setPurchaseOrderTimelineDetails(response));
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      dispatch(setIsFetchingPurchaseOrderTimelineDetails(false));
    }
  };
