import { STATUS } from "@/utils/constants";
import * as dashboardApi from "@/apiv2/dashboard";

import { handleError } from "../../utils/error";

import {
  SET_ACTIVE_CATEGORY,
  SET_WIDGETS_REFERENCE,
  SET_WIDGETS,
  SET_WIDGETS_STATUS,
  SET_WIDGETS_CATEGORIES,
  SET_WIDGETS_CATEGORIES_STATUS,
  SET_IS_MODAL_OPEN,
  SET_SEARCH,
  RESET,
} from "./add-widgets.actionTypes";

export const setActiveCategory = (payload) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_CATEGORY,
    payload,
  });
};

export const setWidgetsReference = (payload) => (dispatch) => {
  dispatch({
    type: SET_WIDGETS_REFERENCE,
    payload,
  });
};

export const setWidgets = (payload) => (dispatch) => {
  dispatch({
    type: SET_WIDGETS,
    payload,
  });
};

export const setWidgetsStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_WIDGETS_STATUS,
    payload,
  });
};

export const setWidgetsCategories = (payload) => (dispatch) => {
  dispatch({
    type: SET_WIDGETS_CATEGORIES,
    payload,
  });
};

export const setWidgetsCategoriesStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_WIDGETS_CATEGORIES_STATUS,
    payload,
  });
};

export const setIsModalOpen = (payload) => (dispatch) => {
  dispatch({
    type: SET_IS_MODAL_OPEN,
    payload,
  });
};

export const setSearch = (payload) => (dispatch) => {
  dispatch({
    type: SET_SEARCH,
    payload,
  });
};

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const groupWidgetsbyCategoryUid = (widgets) => {
  const categories = widgets.map((item) => item.category.uid);
  const uniqueCategoryUids = categories.filter((item, index) => categories.indexOf(item) === index);

  const groupedWidgets = uniqueCategoryUids.reduce(
    (acc, curr) => ((acc[curr] = widgets.filter((item) => item.category.uid === curr)), acc),
    {}
  );

  return groupedWidgets;
};

export const fetchWidgets = () => async (dispatch) => {
  try {
    dispatch(setWidgetsStatus(STATUS.PENDING));
    const response = await dashboardApi.getWidgetsApi()();

    dispatch(setWidgets(groupWidgetsbyCategoryUid(response.data)));
    dispatch(setWidgetsReference(response.data));
    dispatch(setWidgetsStatus(STATUS.FULFILLED));
  } catch (error) {
    handleError(error, dispatch);
    dispatch(setWidgetsStatus(STATUS.REJECTED));
  }
};

export const fetchWidgetsCategories = () => async (dispatch) => {
  try {
    dispatch(setWidgetsCategoriesStatus(STATUS.PENDING));
    const response = await dashboardApi.getWidgetsCategories()();
    dispatch(setWidgetsCategories(response.data));
    dispatch(setWidgetsCategoriesStatus(STATUS.FULFILLED));
  } catch (error) {
    handleError(error, dispatch);
    dispatch(setWidgetsCategoriesStatus(STATUS.REJECTED));
  }
};
