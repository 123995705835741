import createActionType from "../utils/createActionType";

export const MODULE = "productCatalogue";

export const SET_SEARCH = createActionType(MODULE, "setSearch");
export const SET_SEARCH_PRODUCTS = createActionType(MODULE, "setSearchProducts");

export const SET_IS_FETCHING_CATEGORIES = createActionType(MODULE, "setIsFetchingCategories");

export const SET_EXPANDED_CATEGORIES = createActionType(MODULE, "setExpandedCategories");

export const SET_IS_ADD_CATEGORY_FORM_VISIBLE = createActionType(
  MODULE,
  "setIsAddCategoryFormVisible"
);

export const SET_CATEGORY_NAME = createActionType(MODULE, "setCategoryName");
export const SET_CATEGORIES = createActionType(MODULE, "setCategories");
export const CREATE_CATEGORY = createActionType(MODULE, "createCategory");
export const SET_IS_CREATING_CATEGORY = createActionType(MODULE, "setIsCreatingCategory");
export const SET_IS_UPDATING_CATEGORY = createActionType(MODULE, "setIsUpdatingCategory");

export const SET_IS_FETCHING_PRODUCTS = createActionType(MODULE, "setIsFetchingProducts");

export const SET_IS_DUPLICATING_PRODUCTS = createActionType(MODULE, "setIsDuplicatingProducts");

export const SET_PRODUCTS = createActionType(MODULE, "setProducts");

export const SET_SELECTED_CATEGORIES = createActionType(MODULE, "setSelectedCategories");

export const SET_SELECTED_PRODUCTS = createActionType(MODULE, "setSelectedProducts");

export const SET_SELECTED_FLASHING_PRODUCTS = createActionType(
  MODULE,
  "setSelectedFlashingProducts"
);

export const RESET = createActionType(MODULE, "reset");
