import { fromJS } from "immutable";
import camelcaseKeys from "camelcase-keys";

import { withMutations } from "./helpers/reducerWrappers";
import ReduxModule from "./abstract/ReduxModule";

import * as productsApi from "../../api/drawings-products";

import prices from "./prices";

const ACTIONS = {
  CLEAR_ACTIVE_ORDER: "Clear active order",
  CREATE_NEW_ORDER: "Create new order",
  RESET_DRAWINGS: "Reset drawings",
  SET_DATA_AFTER_SAVING_DRAWINGS: "Set data after saving drawings",
  OPEN_ORDER: "Load data from order",
  CHECK_PRODUCT_RELEVANCE: "Check product relevance",

  SEARCH_PRODUCTS: "Search products",
  GET_PRODUCT_WITH_SOME_PREV_OPTIONS: "Get product with some prev options",

  DELETE_DRAWING: "Delete drawing",
  UNDO_DELETE_DRAWING: "Undo delete drawing",
  ADD_NEW_DRAWING: "Add new drawing",
  DUPLICATE_DRAWING: "Duplicate drawing",
  SET_ACTIVE_DRAWING_TEMP_UID: "Set active drawing temp uid",
  CHANGE_DRAWING_PRODUCT: "Change drawing product",
};

class DrawingsProductsModule extends ReduxModule {
  getNamespace() {
    return "[DrawingsProducts]";
  }

  getInitialState() {
    return {
      activeProduct: fromJS(this.initialProductState()),
      startStateDrawings: null, // items: {}
      items: {}, // [drawingTempUid]: {product: this.initialProductState(), isDeleted: bool}
      activeDrawingUid: null,
      isChangedProduct: false,
      dataServer: {
        isPending: false,
        items: [],
      },
    };
  }

  initialProductState() {
    return {
      uid: "",
      productType: "Flashing",
      name: "",
      color: "",
      thickness: "",
      priceLevel: "",
    };
  }

  generateOrderDataFromServer(drawingsData, drawingsProductsData) {
    const items = {};

    drawingsData.forEach((drawing) => {
      const drawingUid = drawing.uid;

      const product = drawingsProductsData.find(
        (productData) => productData.drawing === drawingUid
      );
      if (product) {
        items[drawingUid] = {
          product,
          isDeleted: false,
        };
      } else {
        items[drawingUid] = {
          product: this.initialProductState(),
          isDeleted: false,
        };
      }
    });

    return items;
  }

  createNewOrder = (_state) => {
    _state.merge(fromJS(this.getInitialState()));
  };

  openOrder = (_state, data) => {
    _state.merge(fromJS(this.getInitialState()));
    const items = fromJS(data);
    _state.set("items", items);
    _state.set("startStateDrawings", items);
  };

  checkActiveProductRelevance = ({ token, getState, dispatch, pendingAction, fulfilled }) => {
    const {
      productType,
      name,
      color: oldColor,
      thickness,
      priceLevel,
    } = getState().getIn(["drawingsProducts", "activeProduct"]).toJS();

    dispatch(pendingAction({ isPending: true }));

    let product;
    return productsApi
      .searchProducts(token, {
        product_type: productType,
        name,
        thickness,
        price_level: priceLevel,
      })
      .then((response) => {
        if (response.data.length) {
          const { uid, colors } = response.data[0];
          const color = colors.find((colorObj) => colorObj.title === oldColor);

          product = {
            uid,
            productType,
            name,
            color: color?.title ?? colors[0].title,
            thickness,
            priceLevel,
          };
        } else {
          product = {
            ...this.initialProductState(),
          };
        }

        fulfilled(product);
        return dispatch(prices.actions.getPricesProducts(product));
      })
      .then(() => dispatch(this.actions.searchProducts()))
      .then(() => {
        dispatch(pendingAction({ isPending: false }));
        return product;
      });
  };

  searchProductsThunk = ({ token, fulfilled }, options) => {
    const { productType, name, thickness, priceLevel } = options || {};

    return productsApi
      .searchProducts(token, {
        product_type: productType,
        name,
        thickness,
        price_level: priceLevel,
      })
      .then((response) => {
        fulfilled(response);
        return response.data;
      });
  };

  getProductWithSomePrevOptions = (
    { getState, dispatch, fulfilled },
    { changedField, value, products, prevOptions }
  ) => {
    const activeProduct = getState().getIn(["drawingsProducts", "activeProduct"]);

    if (changedField) {
      dispatch(prices.actions.changeDrawingPrice(true));
    }

    if (prevOptions) {
      fulfilled(prevOptions);
      return dispatch(prices.actions.getPricesProducts(prevOptions));
    }

    let product = activeProduct.toJS();
    if (changedField !== "color") {
      const fields = {
        priceLevel: ["name", "thickness"],
        name: ["priceLevel", "thickness"],
        thickness: ["priceLevel", "name"],
      };

      product = fields[changedField].reduce((nextState, field) => {
        const matches = nextState.filter((item) => item[field] === activeProduct.get(field));

        if (matches.length) {
          return matches;
        }
        return nextState;
      }, products);

      product = product[0];

      let color = product.colors.find((item) => item.title === activeProduct.get("color"));
      color = (color && color.title) || product.colors[0].title;
      product.color = color;
    }

    const {
      uid,
      productType = activeProduct.get("productType"),
      color,
      name,
      thickness,
      priceLevel = activeProduct.get("priceLevel"),
    } = product;
    const options = {
      uid,
      productType,
      name,
      color,
      thickness,
      priceLevel,
      [changedField]: value,
    };

    fulfilled(options);
    return dispatch(prices.actions.getPricesProducts(options));
  };

  defineActions() {
    const clearActiveOrder = this.resetToInitialState(ACTIONS.CLEAR_ACTIVE_ORDER);
    const createNewOrder = this.createAction(ACTIONS.CREATE_NEW_ORDER);
    const openOrder = this.createAction(ACTIONS.OPEN_ORDER);
    const checkActiveProductRelevance = this.thunkAction(
      ACTIONS.CHECK_PRODUCT_RELEVANCE,
      this.checkActiveProductRelevance,
      true,
      false
    );

    const searchProducts = this.thunkAction(
      ACTIONS.SEARCH_PRODUCTS,
      this.searchProductsThunk,
      true
    );
    const getProductWithSomePrevOptions = this.thunkAction(
      ACTIONS.GET_PRODUCT_WITH_SOME_PREV_OPTIONS,
      this.getProductWithSomePrevOptions,
      true
    );

    const resetDrawings = this.createAction(ACTIONS.RESET_DRAWINGS);
    const setDataAfterSavingDrawings = this.createAction(ACTIONS.SET_DATA_AFTER_SAVING_DRAWINGS);
    const addNewDrawing = this.createAction(ACTIONS.ADD_NEW_DRAWING);
    const duplicateDrawing = this.createAction(ACTIONS.DUPLICATE_DRAWING);
    const setDrawingTempUid = this.createAction(ACTIONS.SET_ACTIVE_DRAWING_TEMP_UID);
    const deleteDrawing = this.createAction(ACTIONS.DELETE_DRAWING);
    const undoDeleteDrawing = this.createAction(ACTIONS.UNDO_DELETE_DRAWING);
    const changeDrawingProduct = this.createAction(ACTIONS.CHANGE_DRAWING_PRODUCT);

    return {
      clearActiveOrder,
      createNewOrder,
      openOrder,
      checkActiveProductRelevance,

      searchProducts,
      getProductWithSomePrevOptions,

      resetDrawings,
      setDataAfterSavingDrawings,
      addNewDrawing,
      duplicateDrawing,
      setDrawingTempUid,
      deleteDrawing,
      undoDeleteDrawing,
      changeDrawingProduct,
    };
  }

  addNewDrawing = (_state, { drawingTempUid, newDrawingTempUid }) => {
    let product = drawingTempUid ? _state.getIn(["items", drawingTempUid, "product"]) : null;
    if (!product) {
      product = fromJS(this.initialProductState());
    }

    _state.set("activeDrawingUid", newDrawingTempUid);
    _state.set("activeProduct", product);

    _state.setIn(["items", newDrawingTempUid, "product"], product);
    _state.setIn(["items", newDrawingTempUid, "isDeleted"], false);
    _state.setIn(["isChangedProduct"], true);
  };

  duplicateDrawing = (_state, { drawingTempUid, newDrawingTempUid }) => {
    const product = _state.getIn(["items", drawingTempUid, "product"]);
    _state.setIn(["items", newDrawingTempUid, "product"], product);
    _state.setIn(["isChangedProduct"], true);
  };

  setDrawingTempUid = (_state, newDrawingTempUid) => {
    _state.set("activeDrawingUid", newDrawingTempUid);
    if (newDrawingTempUid) {
      const product = _state.getIn(["items", newDrawingTempUid, "product"]);
      _state.set("activeProduct", product);
    } else {
      _state.set("activeProduct", fromJS(this.initialProductState()));
    }
  };

  setProductOptions = (_state, options) => {
    const activeDrawingUid = _state.get("activeDrawingUid");
    const product = fromJS(options);
    _state.setIn(["items", activeDrawingUid, "product"], product);
    _state.set("activeProduct", product);
  };

  defineReducers() {
    return {
      [ACTIONS.CREATE_NEW_ORDER]: withMutations(this.createNewOrder),

      [ACTIONS.OPEN_ORDER]: withMutations(this.openOrder),

      [`${ACTIONS.CHECK_PRODUCT_RELEVANCE} fulfilled`]: withMutations(this.setProductOptions),

      [`${ACTIONS.CHECK_PRODUCT_RELEVANCE} pending`]: this.thunkPendingReducer([
        "dataServer",
        "isPending",
      ]),

      [`${ACTIONS.GET_PRODUCT_WITH_SOME_PREV_OPTIONS} fulfilled`]: withMutations(
        this.setProductOptions
      ),

      [`${ACTIONS.GET_PRODUCT_WITH_SOME_PREV_OPTIONS} pending`]: this.thunkPendingReducer([
        "dataServer",
        "isPending",
      ]),

      [`${ACTIONS.SEARCH_PRODUCTS} fulfilled`]: (state, { payload: { data } }) =>
        state.setIn(["dataServer", "items"], fromJS(camelcaseKeys(data))),

      [`${ACTIONS.SEARCH_PRODUCTS} pending`]: this.thunkPendingReducer(["dataServer", "isPending"]),

      [ACTIONS.RESET_DRAWINGS]: (state) =>
        state.set("items", state.get("startStateDrawings") || fromJS({})),

      [ACTIONS.SET_DATA_AFTER_SAVING_DRAWINGS]: (state) =>
        state.set("startStateDrawings", state.get("items")),

      [ACTIONS.ADD_NEW_DRAWING]: withMutations(this.addNewDrawing),

      [ACTIONS.DUPLICATE_DRAWING]: withMutations(this.duplicateDrawing),

      [ACTIONS.SET_ACTIVE_DRAWING_TEMP_UID]: withMutations(this.setDrawingTempUid),

      [ACTIONS.DELETE_DRAWING]: (state, { payload: { drawingTempUid, isNewDrawing } }) => {
        if (isNewDrawing) {
          return state.deleteIn(["items", drawingTempUid]);
        }
        return (
          state.setIn(["items", drawingTempUid, "isDeleted"], true),
          state.setIn(["isChangedProduct"], true)
        );
      },

      [ACTIONS.UNDO_DELETE_DRAWING]: (
        state,
        { payload: { drawingTempUid, items, isNewDrawing } }
      ) => {
        if (isNewDrawing) {
          return state.setIn(["items", drawingTempUid], items);
        }
        return state.setIn(["items", drawingTempUid, "isDeleted"], false);
      },

      [ACTIONS.CHANGE_DRAWING_PRODUCT]: (state, { payload }) =>
        state.setIn(["isChangedProduct"], payload),
    };
  }
}

const instance = new DrawingsProductsModule();
instance.init();

export default instance;
