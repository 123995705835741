import createActionType from "../utils/createActionType";

export const MODULE = "accounting";

export const SET_ACCOUNTS = createActionType(MODULE, "setAccounts");
export const SET_TAX_CODES = createActionType(MODULE, "setTaxCodes");
export const SET_ITEMS = createActionType(MODULE, "setItems");
export const SET_MYOB_JOBS = createActionType(MODULE, "setMyobJobs");
export const SET_IS_FETCHING = createActionType(MODULE, "setIsFetching");
export const SET_IS_CONNECTING = createActionType(MODULE, "setIsConnecting");
export const SET_IS_XERO_CONNECTED = createActionType(MODULE, "setIsXeroConnected");
export const SET_SELECTED_MYOB_COMPANY_FILE = createActionType(
  MODULE,
  "setSelectedMyobCompanyFile"
);

export const SET_IS_MYOB_COMPANY_FILE_MODAL_OPEN = createActionType(
  MODULE,
  "setIsMyobCompanyFileModalOpen"
);
export const SET_IS_CONNECTED_ACCOUNTING_MODAL_OPEN = createActionType(
  MODULE,
  "setIsConnectedAccountingModalOpen"
);

export const SET_IS_DISCONNECT_ACCOUNTING_MODAL_OPEN = createActionType(
  MODULE,
  "setIsDisconnectAccountingModalOpen"
);

export const SET_IS_ACCOUNTS_TAX_CODES_MODAL_OPEN = createActionType(
  MODULE,
  "setIsAccountsTaxCodesModalOpen"
);

export const SET_IS_IMPORT_SUPPLIERS_MODAL_OPEN = createActionType(
  MODULE,
  "setIsImportSuppliersModalOpen"
);

export const SET_IS_IMPORT_CUSTOMERS_MODAL_OPEN = createActionType(
  MODULE,
  "setIsImportCustomersModalOpen"
);

export const SET_ACCOUNTING_UPDATE_ALL_STATUS = createActionType(
  MODULE,
  "setAccountingUpdateAllStatus"
);
