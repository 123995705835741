import { flattenDeep } from "lodash";

import { orderBy } from "lodash";
import { MODULE } from "./timeTracking.actionTypes";

export const isFetchingSummaryStatus = (state) => state.getIn([MODULE, "isFetchingSummaryStatus"]);

export const getSummary = (state) => state.getIn([MODULE, "summary"]);

export const getTeamMembers = (state) =>
  orderBy(state.getIn([MODULE, "teamMembers"]), [(t) => t.firstName.toLowerCase()], ["asc"]);

export const isFetchingTeamMembersStatus = (state) =>
  state.getIn([MODULE, "isFetchingTeamMembersStatus"]);

export const getModalType = (state) => state.getIn([MODULE, "modalType"]);

export const getActiveLineItem = (state) => state.getIn([MODULE, "activeLineItem"]);

export const isSavingStatus = (state) => state.getIn([MODULE, "isSavingStatus"]);

export const getActiveTimeEntry = (state) => state.getIn([MODULE, "activeTimeEntry"]);

export const getUsersWithPendingClockOff = (lineItem) =>
  flattenDeep(
    lineItem?.timeTracking.map((u) =>
      u.timeTrackingEntries
        .filter((t) => t.clockOn !== null && t.clockOff === null)
        .map((t) => ({
          uid: t.uid,
          color: u.color,
          firstName: u.firstName,
          lastName: u.lastName,
          user: u.user,
          clockOn: t.clockOn,
          clockOff: t.clockOff,
        }))
    ) ?? []
  );

export const getHoursEstimatedPerUser = (state) =>
  state.getIn([MODULE, "summary"]).hoursEstimatedPerUser;

export const isFetchingOrderItemsStatus = (state) =>
  state.getIn([MODULE, "isFetchingOrderItemsStatus"]);

export const getOrderItems = (state) =>
  state.getIn([MODULE, "orderItems"]).map((a) => ({
    ...a,
    productKitItems: a?.productKitItems?.map((p) => ({
      ...p,
      productKitUid: a.uid,
      productType: "Kit item",
    })),
    subKits: a?.subKits?.map((s) => ({
      ...s,
      productKitUid: a.uid,
      productType: "Sub-kit",
      kitProducts: s.kitProducts.map((k) => ({
        ...k,
        productKitUid: a.uid,
        subKitUid: s.uid,
        productType: "Sub-kit item",
      })),
    })),
  }));

export const isSearchingOrderNumberStatus = (state) =>
  state.getIn([MODULE, "isSearchingOrderNumberStatus"]);

export const getOrderNumberResults = (state) => state.getIn([MODULE, "orderNumberResults"]);

export const getUser = (state) => state.getIn([MODULE, "user"]);

export default {};
