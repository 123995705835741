import React, { lazy } from "react";
import { Switch, Route } from "react-router-dom";

import UserSession from "../components/UserSession";
import NotificationContainer from "../components/common/NotificationContainer";

import PrivateRoute from "./PrivateRoute";

const AllCustomersPage = lazy(() => import("../pages/AllCustomersPage"));
const POCDashboardPage = lazy(() => import("../pages/POCDashboardPage"));
const OrdersAllPageContainer = lazy(() =>
  import("../components/OrdersAll/OrdersAllPageContainer/OrdersAllPageContainer")
);
const WorkflowPage = lazy(() => import("../pages/WorkflowPage"));
const PlannerPage = lazy(() => import("../pages/PlannerPage"));
const Deliveries = lazy(() => import("../pages/DeliverySchedulingPage"));
const TimesheetsPage = lazy(() => import("../pages/TimesheetsPage"));
const IndividualTimesheets = lazy(() => import("../pages/TimesheetsPage/IndividualTimesheets"));
const Materials = lazy(() => import("../components/Materials/MaterialsPage"));

const FlashingEdit = lazy(() => import("../components/Flashing/FlashingEdit"));
const MaterialEdit = lazy(() => import("../components/Materials/MaterialEdit"));

const Profile = lazy(() => import("../components/Profile/ProfilePage"));

const Customer = lazy(() => import("../components/Customer/CustomerPage"));
const Order = lazy(() => import("../components/Order/OrderPage"));
const Drawing = lazy(() => import("../components/Drawing/DrawingPage"));

const AllPurchasesPage = lazy(() => import("../pages/AllPurchasesPage/AllPurchasesPage"));
const AllSuppliersPage = lazy(() => import("../pages/AllSuppliersPage/AllSuppliersPage"));
const SupplierProfilePage = lazy(() => import("../pages/SupplierProfilePage/SupplierProfilePage"));
const PurchaseOrderPage = lazy(() => import("../pages/PurchaseOrderPage/PurchaseOrderPage"));
const ProductPage = lazy(() => import("../pages/ProductPage/ProductPage"));
const ExternalPurchaseOrderPage = lazy(() =>
  import("../pages/ExternalPurchaseOrderPage/ExternalPurchaseOrderPage")
);
const ProductCataloguePage = lazy(() => import("../pages/ProductCataloguePage"));
const ProductInventoryStocktakePage = lazy(() => import("../pages/ProductInventoryStocktakePage"));
const ProductKitsPage = lazy(() => import("../pages/ProductKitsPage"));
const PriceLevelManagerPage = lazy(() => import("../pages/PriceLevelManagerPage"));
const SettingsPage = lazy(() => import("../pages/SettingsPage"));

const TrialEndedPage = lazy(() => import("../pages/TrialEndedPage"));

const AuthRoutes = () => (
  <>
    <UserSession />
    <NotificationContainer />
    <Switch>
      <PrivateRoute exact path="/customers" component={AllCustomersPage} />
      <PrivateRoute exact path="/dashboard" component={POCDashboardPage} />
      <PrivateRoute exact path="/orders" component={OrdersAllPageContainer} />
      <PrivateRoute exact path="/workflow" component={WorkflowPage} />
      <PrivateRoute exact path="/planner" component={PlannerPage} />
      <PrivateRoute exact path="/delivery_scheduling" component={Deliveries} />
      <PrivateRoute exact path="/timesheets" component={TimesheetsPage} />
      <PrivateRoute exact path="/timesheets/individual" component={IndividualTimesheets} />
      <PrivateRoute exact path="/settings" component={SettingsPage} />

      <PrivateRoute path="/prices" component={PriceLevelManagerPage} />

      <PrivateRoute exact path="/products" component={ProductCataloguePage} />

      <PrivateRoute exact path="/products/new" component={ProductPage} />
      <PrivateRoute path="/products/:productUid" component={ProductPage} />

      <PrivateRoute exact path="/flashings/new" component={FlashingEdit} />
      <PrivateRoute path="/flashings/:productUid" component={FlashingEdit} />

      <PrivateRoute exact path="/product-kits/new" component={ProductKitsPage} />
      <PrivateRoute path="/product-kits/:productUid" component={ProductKitsPage} />

      <PrivateRoute exact path="/inventory/stocktake" component={ProductInventoryStocktakePage} />

      <PrivateRoute exact path="/materials" component={Materials} />
      <PrivateRoute path="/materials/:materialUid" component={MaterialEdit} />

      <PrivateRoute exact path="/profile" component={Profile} />

      <PrivateRoute path="/customer/:customerUid/order/:orderUid/drawing" component={Drawing} />
      <Route path="/customer/:customerUid/order/:orderUid/:viewType" component={Order} />
      <PrivateRoute path="/customer/:customerUid/order/:orderUid" component={Order} />
      <PrivateRoute path="/customer/:customerUid/order" component={Order} />
      <PrivateRoute path="/customer/:customerUid" component={Customer} />

      {/* PURCHASING ROUTES */}
      <PrivateRoute
        path="/suppliers/:supplierUid/purchase-order/new"
        component={PurchaseOrderPage}
      />
      <PrivateRoute
        path="/suppliers/:supplierUid/purchase-order/:purchaseOrderUid"
        component={PurchaseOrderPage}
      />
      <PrivateRoute path="/suppliers/:supplierUid" component={SupplierProfilePage} />
      <PrivateRoute path="/suppliers" component={AllSuppliersPage} />

      <PrivateRoute exact path="/purchases" component={AllPurchasesPage} />

      <Route
        path="/purchase_orders/:externalToken/:withPrices"
        component={ExternalPurchaseOrderPage}
      />
      <Route path="/trial-ended" component={TrialEndedPage} />
    </Switch>
  </>
);

export default AuthRoutes;
