import React, { forwardRef } from "react";
import cx from "classnames";

import styles from "./Input.module.scss";

const Input = forwardRef((props, ref) => {
  const { className, inputClassName, ...rest } = props;
  return <input className={cx(styles.input, inputClassName)} ref={ref} {...rest} />;
});

export default Input;
