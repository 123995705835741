import createActionType from "@/redux/utils/createActionType";

export const MODULE = "partialInvoice";

export const SET_INVOICE_TYPE = createActionType(MODULE, "setInvoiceType");
export const SET_INVOICE_DATE = createActionType(MODULE, "setInvoiceDate");
export const SET_INVOICE_DATA = createActionType(MODULE, "setInvoiceData");
export const SET_INVOICE_SELECTED_ITEMS = createActionType(MODULE, "setInvoiceSelectedItems");
export const SET_INVOICE_ITEM_AMOUNTS = createActionType(MODULE, "setInvoiceItemAmounts");
export const SET_INVOICE_ITEM_REMAINING_AMOUNTS = createActionType(
  MODULE,
  "setInvoiceItemRemainingAmounts"
);
export const SET_PRINT_PARTIAL_INVOICE_PROGRESS = createActionType(
  MODULE,
  "setPrintPartialInvoiceProgress"
);
export const SET_PRINTED_PARTIAL_INVOICE = createActionType(MODULE, "setPrintedPartialInvoice");
export const SET_PARTIAL_INVOICES_PROGRESS = createActionType(MODULE, "setPartialInvoicesProgress");
export const SET_PARTIAL_INVOICES = createActionType(MODULE, "setPartialInvoices");
export const SET_LAST_SUBMITTED_DATA = createActionType(MODULE, "setLastSubmittedData");
export const SET_ADDITIONAL_PRODUCTS_PROGRESS = createActionType(
  MODULE,
  "setAdditionalProductsProgress"
);
export const SET_PARTIAL_INVOICE_SUMMARY_OPEN = createActionType(
  MODULE,
  "setPartialInvoiceSummaryOpen"
);
export const SET_ADDITIONAL_PRODUCTS = createActionType(MODULE, "setAdditionalProducts");
export const SET_CREATE_PARTIAL_INVOICE_PROGRESS = createActionType(
  MODULE,
  "setCreatePartialInvoiceProgress"
);
export const SET_SPECIFIC_AMOUNT = createActionType(MODULE, "setSpecificAmount");
export const SET_PERCENTAGE = createActionType(MODULE, "setPercentage");
export const SET_LAST_ID = createActionType(MODULE, "setLastId");

export const SET_EMAIL_PARTIAL_INVOICE_PROGRESS = createActionType(
  MODULE,
  "setEmailPartialInvoice"
);
export const SET_EMAIL_PARTIAL_INVOICE = createActionType(MODULE, "setEmailPartialInvoiceProgress");
export const RESET = createActionType(MODULE, "reset");
