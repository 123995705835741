import { nanoid } from "nanoid";
import { isArray, isString } from "lodash";

import { createAlertAction } from "../alerts/alerts.actions";
import * as authenticationActions from "../authentication/authentication.actions";

const stripQuotes = (str) => {
  return str.startsWith('"') && str.endsWith('"') ? str.slice(1, -1) : str;
};

export const handleError = (error, dispatch) => {
  if (error.response) {
    if (error.response.data?.error && isArray(error.response.data?.error)) {
      dispatch(
        createAlertAction(
          nanoid(),
          stripQuotes(JSON.stringify(error.response.data?.error[0] ?? "Something went wrong")),
          true,
          "danger"
        )
      );

      throw new Error(JSON.stringify(error.response.data));
    }

    if (error.response.data?.errors && isArray(error.response.data?.errors)) {
      dispatch(
        createAlertAction(
          nanoid(),
          stripQuotes(JSON.stringify(error.response.data?.errors[0] ?? "Something went wrong")),
          true,
          "danger"
        )
      );

      throw new Error(JSON.stringify(error.response.data));
    }

    if (error.response.data?.error && isString(error.response.data?.error)) {
      dispatch(
        createAlertAction(
          nanoid(),
          stripQuotes(JSON.stringify(error.response.data?.error ?? "Something went wrong")),
          true,
          "danger"
        )
      );

      throw new Error(JSON.stringify(error.response.data));
    }

    if (error.response.data) {
      dispatch(
        createAlertAction(
          nanoid(),
          stripQuotes(JSON.stringify(error.response.data ?? "Something went wrong")),
          true,
          "danger"
        )
      );

      throw new Error(JSON.stringify(error.response.data));
    }

    if (error.response.status === 401) {
      setTimeout(() => {
        dispatch(authenticationActions.logout());
      }, 500);
    }
  }
};

export default {};
