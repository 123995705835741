import createActionType from "@/redux/utils/createActionType";

export const MODULE = "importCustomers";

export const SET_FETCHING_LATEST_CUSTOMERS_STATUS = createActionType(
  MODULE,
  "setFetchingLatestCustomersStatus"
);
export const SET_LATEST_CUSTOMERS = createActionType(MODULE, "setLatestCustomers");
export const SET_SEARCH_LATEST_CUSTOMERS = createActionType(MODULE, "setSearchLatestCustomers");
export const SET_IS_LINKED_CUSTOMERS = createActionType(MODULE, "setIsLinkedCustomers");
export const SET_IS_PREVIOUSLY_IMPORTED_CUSTOMERS = createActionType(
  MODULE,
  "setIsPreviouslyImportedCustomers"
);
export const SET_CUSTOMERS_TO_IMPORT = createActionType(MODULE, "setCustomersToImport");

export const SET_IMPORT_CUSTOMERS_STATUS = createActionType(MODULE, "setImportCustomersStatus");
export const SET_IMPORT_CUSTOMERS = createActionType(MODULE, "setImportCustomers");
export const SET_DUPLICATE_CUSTOMERS_TO_IMPORT = createActionType(
  MODULE,
  "setDuplicateCustomersToImport"
);
export const SET_DUPLICATE_CUSTOMERS_WARNING_MODAL = createActionType(
  MODULE,
  "setDuplicateCustomersWarningModal"
);

export const SET_IMPORT_CUSTOMERS_SORT = createActionType(MODULE, "setImportCustomersSort");
export const SET_IMPORT_CUSTOMERS_PAGE = createActionType(MODULE, "setImportCustomersPage");
export const SET_IMPORT_CUSTOMERS_META = createActionType(MODULE, "setImportCustomersMeta");
export const SET_IS_LINKED_CUSTOMERS_FILTER = createActionType(
  MODULE,
  "setIsLinkedCustomersFilter"
);

export const SET_IMPORT_ALL_CUSTOMERS_STATUS = createActionType(
  MODULE,
  "setImportAllCustomersStatus"
);
export const SET_IMPORT_ALL_CUSTOMERS = createActionType(MODULE, "setImportAllCustomers");
export const RESET = createActionType(MODULE, "reset");
