import { MODULE } from "./signup.actionTypes";

export const isSaving = (state) => state.getIn([MODULE, "isSaving"]);

export const isFetching = (state) => state.getIn([MODULE, "isFetching"]);

export const getSignupData = (state) => state.getIn([MODULE, "data"]);

export const getTimezones = (state) => {
  const timezones = state.getIn([MODULE, "timezones"]);

  return [
    ...timezones.filter((t) => t.name.includes("Australia")),
    ...timezones.filter((t) => !t.name.includes("Australia")),
  ].filter((t) => t.name !== "UTC");
};

export const getCountries = (state) => {
  const countries = state.getIn([MODULE, "countries"]);

  return [
    ...countries.filter((c) => c.name.includes("Australia")),
    ...countries.filter((c) => !c.name.includes("Australia")),
  ];
};

export const getXeroAccounts = (state) => state.getIn([MODULE, "xeroAccounts"]);

export const getInvitePageStatus = (state) => state.getIn([MODULE, "invitePageStatus"]);
