import {
  IS_LOGGING_IN,
  IS_AUTHENTICATED,
  IS_CHECKING_AUTH,
  SET_ERROR_MESSAGE,
} from "./authentication.actionTypes";

const initialState = {
  isLoggingIn: false,
  isAuthenticated: false,
  isCheckingAuth: true,
  errorMessage: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case IS_LOGGING_IN: {
      return { ...state, isLoggingIn: action.payload };
    }

    case IS_AUTHENTICATED: {
      return { ...state, isAuthenticated: action.payload };
    }

    case IS_CHECKING_AUTH: {
      return { ...state, isCheckingAuth: action.payload };
    }

    case SET_ERROR_MESSAGE: {
      return { ...state, errorMessage: action.payload };
    }

    default:
      return state;
  }
};
