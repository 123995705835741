import { STATUS } from "@/utils/constants";

import { SET_IS_FETCHING_LISTS_STATUS, SET_LISTS, RESET } from "./hoursScheduled.actionTypes";

const initialState = {
  isFetchingListsStatus: STATUS.IDLE,
  lists: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_FETCHING_LISTS_STATUS: {
      return { ...state, isFetchingListsStatus: action.payload };
    }

    case SET_LISTS: {
      return { ...state, lists: action.payload };
    }

    case RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
