import moment from "moment-timezone";

import * as customerSelectors from "@/redux/customers/customers.selectors";
import store from "@/redux/store";

export const getCurrentUserTimezone = () => {
  const state = store.getState();
  const user = customerSelectors.userSelector(state);

  return user?.timeZone ?? moment.tz.guess();
};

export const getCurrentCompanyTimezone = () => {
  const state = store.getState();
  const company = customerSelectors.companySelector(state);

  return company?.timeZone ?? "";
};

export const toLocalDateTime = ({ date, timezone, format = "" }) => {
  const dateTime = moment.tz(date, timezone);

  return format !== "" ? dateTime.format(format) : dateTime.format();
};

export const toUtcDateTime = ({ date, timezone, format = "" }) => {
  const dateTime = moment.tz(date, timezone).utc();
  return format !== "" ? dateTime.format(format) : dateTime.format();
};

export const toNaiveDateAndLocalize = ({ date, timezone }) => {
  const dateString = date.split("+")?.[0] || date;
  const [datePart, timePart] = dateString.split("T");
  const dateTime = new Date(`${datePart} ${timePart}`);

  return toLocalDateTime({ date: dateTime, timezone });
};

export const toNaiveDateAndUTC = ({ date, timezone }) => {
  const momentDate = moment(date);

  // Get naive date value
  const dateValue = momentDate.format("YYYY-MM-DDTHH:mm:ss");

  // Get timezone zulu value
  const dateZulu = momentDate.tz(timezone).format("Z");

  return toUtcDateTime({
    date: new Date(`${dateValue}${dateZulu}`),
    timezone,
  });
};

export default {};
