import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";

const FileContainer = ({
  id,
  label,
  containerClassName,
  inputClassName,
  errors,
  buttonText,
  buttonStyle,
  multiple,
  isDisabled,
  setFieldValue,
  values,
  maxFileSize,
  onChange,
  imgExtension,
  defaultImage,
  children,
}) => {
  const { color, outline, size } = buttonStyle;
  const dropzoneRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [pictures, setPictures] = useState([]);
  const [fileEerrors, setFileErrors] = useState({
    fileTypes: [],
    fileSizes: [],
  });
  const [loading, setLoading] = useState(false);

  const loadImages = () => {
    if (values) {
      const value = values[id];

      if (typeof value === "string") {
        setLoading(true);

        const axiosOptions = {
          method: "GET",
          url: value,
          responseType: "blob",
          headers: { "Cache-Control": "no-cache" },
        };

        axios(axiosOptions).then((response) => {
          const fileName = value.match(/[^\\/]+$/)[0];
          const file = new File([response.data], fileName, {
            type: response.headers["content-type"],
            lastModified: Date.now(),
          });

          setPictures((prevState) => [...prevState, value]);
          setFiles((prevState) => [...prevState, file]);
          setLoading(false);

          if (setFieldValue) {
            setFieldValue(id, {
              pictures: [...pictures, value],
              files: [...files, file],
              loading: false,
              errors: fileEerrors,
            });
          }

          return response.data;
        });
      }
    }
  };

  const readFile = (file) =>
    new Promise((resolve) => {
      const reader = new FileReader();

      // Read the image via FileReader API and save image result in state.
      reader.onload = (e) => {
        // Add the file name to the data URL
        let dataURL = e.target.result;
        dataURL = dataURL.replace(";base64", `;name=${file.name};base64`);
        resolve({ file, dataURL });
      };

      reader.readAsDataURL(file);
    });

  const hasExtension = (fileName) => {
    const pattern = `(${imgExtension.join("|").replace(/\./g, "\\.")})$`;
    return new RegExp(pattern, "i").test(fileName);
  };

  const choseImage = async (accepted) => {
    const allFilePromises = [];
    const fileTypes = [];
    const fileSizes = [];
    const picturesData = [];
    const filesData = [];

    // Iterate over all uploaded files
    for (let i = 0; i < accepted.length; i += 1) {
      const f = accepted[i];

      // Check for file extension
      const isWrongType = !hasExtension(f.name);

      if (isWrongType) {
        fileTypes.push(f.name);
      }

      // Check for file size
      const isWrongSize = f.size / 1000 > maxFileSize;

      if (isWrongSize) {
        fileSizes.push(f.name);
      }

      if (!isWrongType && !isWrongSize) {
        allFilePromises.push(readFile(f));
      }
    }

    return Promise.all(allFilePromises).then(async (newFilesData) => {
      newFilesData.forEach((newFileData) => {
        picturesData.push(newFileData.dataURL);
        filesData.push(newFileData.file);
      });

      await Promise.all([
        setPictures(picturesData),
        setFiles(filesData),
        setFileErrors({ fileTypes, fileSizes }),
      ]);

      setFieldValue(id, {
        pictures: picturesData,
        files: filesData,
        errors: fileEerrors,
        loading,
      });

      if (onChange) {
        onChange();
      }
    });
  };

  const drawImages = () => {
    if (defaultImage && !pictures.length) {
      return <img className="dropzone_default-image" src={defaultImage} alt="" />;
    }

    return pictures.map((imgUrl) => <img key={imgUrl} src={imgUrl} alt="" />);
  };

  useEffect(() => {
    loadImages();
  }, []);

  return children({
    id,
    label,
    containerClassName,
    inputClassName,
    errors,
    buttonText,
    multiple,
    isDisabled,
    drawImages,
    dropzoneRef,
    choseImage,
    outline,
    color,
    size,
    loading,
  });
};

FileContainer.defaultProps = {
  imgExtension: [".jpg", ".jpeg", ".png"],
  onChange: () => null,
  setFieldValue: () => null,
  multiple: false,
  isDisabled: false,
  maxFileSize: 5 * 8000, // 5MB in kB
  defaultImage: "",
  values: null,
  buttonText: "Choose File",
  buttonStyle: {
    color: "primary",
    outline: true,
    size: "sm",
  },
  label: "",
  errors: {},
  containerClassName: "",
  inputClassName: "",
};

FileContainer.propTypes = {
  id: PropTypes.string.isRequired,
  containerClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  errors: PropTypes.shape({}),
  onChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  buttonText: PropTypes.string,
  buttonStyle: PropTypes.shape({}),
  defaultImage: PropTypes.string,
  values: PropTypes.shape({}),
  imgExtension: PropTypes.arrayOf(PropTypes.string),
  multiple: PropTypes.bool,
  maxFileSize: PropTypes.number,
};

export default FileContainer;
